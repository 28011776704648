import {
  ApiMessageStack,
  LeadStatusSelectOptionsRenderer,
  SelectInputOptionsController,
} from "components";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { observer } from "mobx-react";
import useSelect from "utils/react-hooks/useSelect.hook";
import { TLeadStatus } from "types/lead-status.type";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";

interface IProps {
  leadsStore: AccountLeadsStore;
  leadStatusesStore: AccountLeadStatusesStore;
  selectedRowIds: number[];
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const BulkUpdateStatusMenu: FC<IProps> = observer(
  ({ leadsStore, selectedRowIds, setIsSubmitting, leadStatusesStore }) => {
    const toast = useToast();
    const [status, setStatus] = useState<TLeadStatus | null>(null);
    const statusRef = useRef<TLeadStatus | null>(null);
    const leadStatusOptions = leadStatusesStore.accountLeadStatusOptions;

    const handleChange = useCallback((newStatus: TLeadStatus) => {
      setStatus(newStatus);
      statusRef.current = newStatus;
    }, []);

    const { visibleOptions, getOptionProps, selectedOption } = useSelect({
      multi: false,
      options: leadStatusOptions,
      value: status,
      onChange: handleChange,
    });

    useEffect(() => {
      const cleanup = async () => {
        if (statusRef.current) {
          try {
            setIsSubmitting(true);
            await leadsStore.updateLeads(selectedRowIds, {
              statusId: statusRef.current?.id || undefined,
            });
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          } finally {
            setIsSubmitting(false);
          }
        }
      };

      return () => {
        cleanup();
      };
    }, [leadsStore, selectedRowIds, toast, setIsSubmitting]);

    return (
      <Box height={"320px"} cursor={"pointer"}>
        <SelectInputOptionsController<TLeadStatus>
          selectedOption={selectedOption}
          visibleOptions={visibleOptions}
          getOptionProps={getOptionProps}
          optionsRenderer={optionProps => (
            <LeadStatusSelectOptionsRenderer optionProps={optionProps} />
          )}
        />
      </Box>
    );
  }
);
