import { observer } from "mobx-react";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { Box, Button, useToast } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { TReapitIRSyncOfficeExtendedData } from "types/reapit-internet-registration.type";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { isEmpty, keyBy } from "lodash";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { syncReapitIROfficeTableColumnDef } from "./syncReapitIROfficeTableColumnDef";
import { SyncStatusFilter } from "../../SyncStatusFilter";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
}

export const ReapitIRIntegrationOfficesConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const toast = useToast();
    const [validationErrors, setValidationErrors] = useState<
      Record<string, string>
    >({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TReapitIRSyncOfficeExtendedData>
    >({});

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsReapitIRStore =
      accountIntegrationsStore.accountIntegrationsReapitIRStore;
    const {
      reapitIRSyncOfficeData,
      getSyncStatusFilter,
      setSyncStatusFilter,
      filteredReapitIRSyncOfficeData,
    } = accountIntegrationsReapitIRStore;

    const onInputValidation = useCallback(
      (key: string, message: string | null) => {
        const errors = { ...validationErrors };
        delete errors[key];

        if (!!message) {
          errors[key] = message;
        }

        setValidationErrors(errors);
      },
      [validationErrors]
    );

    const submitSyncPairs = useCallback(async () => {
      if (!isEmpty(validationErrors)) return;
      try {
        setIsSubmitting(true);
        await accountIntegrationsReapitIRStore.updateAccountReapitIRSyncedOffices(
          Object.values(newRelations)
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "Reapit Internet Registrations office mappings updated."
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [
      accountIntegrationsReapitIRStore,
      newRelations,
      toast,
      validationErrors,
    ]);

    useEffect(() => {
      const relations = keyBy<TReapitIRSyncOfficeExtendedData>(
        reapitIRSyncOfficeData,
        syncData => syncData.officeId
      );

      setNewRelations({
        ...relations,
      });
    }, [reapitIRSyncOfficeData]);

    const updateSyncPair = useCallback(
      (syncPair: TReapitIRSyncOfficeExtendedData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.officeId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    const columnsDef = useMemo(() => {
      return syncReapitIROfficeTableColumnDef(
        updateSyncPair,
        onInputValidation
      );
    }, [updateSyncPair, onInputValidation]);

    const dataSource = useMemo(() => {
      return filteredReapitIRSyncOfficeData.map(syncData => {
        if (!!newRelations[syncData.officeId])
          return newRelations[syncData.officeId];

        return syncData;
      });
    }, [filteredReapitIRSyncOfficeData, newRelations]);

    const banner = useMemo(() => {
      const notSyncedArray = reapitIRSyncOfficeData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your offices are not configured.`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [reapitIRSyncOfficeData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box mr={8} fontSize={"xl"}>
          Office mappings
        </Box>
        {banner}
        <TableV2<TReapitIRSyncOfficeExtendedData>
          globalFilterInputPlaceholder={"Search offices by name"}
          dataSource={dataSource}
          columns={columnsDef}
          additionalActions={
            <SyncStatusFilter
              isChecked={getSyncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
