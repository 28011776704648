import React, { useEffect, useMemo, useRef } from "react";
import {
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
  useGlobalFilter,
  useRowSelect,
  UseTableHooks,
  ColumnInstance,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
} from "react-table";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Paginator, Loader, Error } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  Center,
  ToastId,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { RowSelectionCheckboxCell } from "./cells/RowSelectionCheckboxCell";
import { isEmpty } from "lodash";
import {
  ExtendedTableInstance,
  ExtendedTableState,
  IDAbleObject,
  TableProps,
} from "./table-props";
import appStore from "store/App.store";
import { Provider as MobxProvider } from "mobx-react";

export function Table<D extends IDAbleObject>({
  columns,
  data,
  total,
  getRowId,
  onRowClick,
  selectedRowId,
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
  className,
  onPageChange,
  pageIndex: pageIndexProp,
  pageSize: pageSizeProp = 100,
  defaultSort,
  onSortByChange,
  globalFilterRenderer,
  enableRowSelection,
  EmptyView,
  PopupControlView,
  customFooter,
  popupControlViewProps,
}: TableProps<D>) {
  const toast = useToast();
  const toastIdRef = useRef<ToastId>("");

  const extendedUseTable = useTable as (
    ...args: any[]
  ) => ExtendedTableInstance<D>;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = extendedUseTable(
    {
      columns,
      data,
      getRowId,
      manualSortBy: !!onSortByChange,
      pageCount: Math.ceil(total / pageSizeProp),
      manualPagination: !!onPageChange,
      useControlledState: (state: ExtendedTableState<D>) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: !!onPageChange ? pageIndexProp : state.pageIndex,
          }),
          // eslint-disable-next-line
          [pageIndexProp, state]
        );
      },
      initialState: {
        pageIndex: 0,
        pageSize: pageSizeProp,
        sortBy: defaultSort ? defaultSort : [],
      },
      autoResetSelectedRows: false,
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: UseTableHooks<D>) => {
      if (enableRowSelection) {
        hooks.visibleColumns.push((restOfColumns: ColumnInstance<D>[]) => {
          return [
            {
              id: "selection",
              width: "50px",
              minWidth: "50px",
              maxWidth: "50px",
              Header: ({
                getToggleAllRowsSelectedProps,
              }: UseRowSelectInstanceProps<D>) => (
                <RowSelectionCheckboxCell
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              Cell: ({ row }: { row: UseRowSelectRowProps<D> }) => (
                <RowSelectionCheckboxCell
                  {...row.getToggleRowSelectedProps()}
                />
              ),
            },
            ...restOfColumns,
          ] as ColumnInstance<D>[];
        });
      }
    }
  );

  useEffect(() => {
    if (onSortByChange) {
      onSortByChange(sortBy);
    }
  }, [onSortByChange, sortBy]);

  useEffect(() => {
    if (!!PopupControlView && !!popupControlViewProps) {
      const popupOptions: UseToastOptions = {
        position: "bottom",
        duration: null,
        isClosable: true,
        render: () => (
          <MobxProvider store={appStore}>
            <PopupControlView
              selectedRowIds={Object.keys(selectedRowIds)}
              onClose={() => {
                toast.close(toastIdRef.current);
                toggleAllRowsSelected(false);
              }}
              {...popupControlViewProps}
            />
          </MobxProvider>
        ),
        onCloseComplete: () => {
          toastIdRef.current = "";
        },
      };

      if (!isEmpty(selectedRowIds)) {
        if (!toastIdRef.current) {
          toastIdRef.current = toast(popupOptions) || "";
        } else {
          toast.update(toastIdRef.current, popupOptions);
        }
      } else if (!!toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    }
  }, [
    PopupControlView,
    popupControlViewProps,
    selectedRowIds,
    toggleAllRowsSelected,
    toast,
  ]);

  useEffect(() => {
    return function cleanup() {
      toast.close(toastIdRef.current);
    };
  }, [toast]);

  const content = useMemo(() => {
    if (loadingStatus === ApiRequestStatusEnum.PENDING) {
      return (
        <Box
          position={"absolute"}
          top={0}
          bottom={0}
          left={0}
          right={0}
          width={"100%"}
          height={"100%"}
        >
          <Loader />
        </Box>
      );
    }

    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return <Error />;
    }

    if (loadingStatus === ApiRequestStatusEnum.SUCCESS && !page.length) {
      return !!EmptyView ? (
        <EmptyView />
      ) : (
        <Center width={"100%"} height={"100%"}>
          No results.
        </Center>
      );
    }

    if (page.length) {
      return page.map((row, index: number) => {
        prepareRow(row);
        return (
          <Box
            {...row.getRowProps()}
            onClick={() => (onRowClick ? onRowClick(row.original) : null)}
            borderBottom={"1px solid"}
            borderColor={"gray.100"}
            cursor={onRowClick ? "pointer" : "default"}
            bg={
              row.id === selectedRowId
                ? "blue.200"
                : index % 2 === 0
                ? ""
                : "leadpro.900"
            }
            _hover={{ bg: row.id === selectedRowId ? "blue.200" : "blue.50" }}
          >
            {row.cells.map((cell: any) => {
              return (
                <Box
                  {...cell.getCellProps()}
                  px={2}
                  py={1}
                  borderColor={"gray.100"}
                  className={cell.column.className}
                  width={cell.column.width}
                  minWidth={cell.column.minWidth}
                  maxWidth={cell.column.maxWidth}
                >
                  {cell.render("Cell")}
                </Box>
              );
            })}
          </Box>
        );
      });
    }

    return null;
    // eslint-disable-next-line
  }, [
    loadingStatus,
    onRowClick,
    page,
    prepareRow,
    selectedRowId,
    selectedRowIds,
    EmptyView,
  ]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      fontSize={"sm"}
    >
      {!!globalFilterRenderer && globalFilterRenderer(setGlobalFilter)}
      <Box
        flexGrow={1}
        overflowY={"hidden"}
        overflowX={"auto"}
        display={"flex"}
      >
        <Box
          {...getTableProps()}
          className={className}
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
        >
          <Box bg={"leadpro.50"} color={"leadpro.700"}>
            {headerGroups.map(headerGroup => (
              <Box {...headerGroup.getHeaderGroupProps()} marginRight={"15px"}>
                {headerGroup.headers.map((column: any) => (
                  <Box
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.className}
                    _last={{ borderRight: "none" }}
                    padding={2}
                    textAlign={"left"}
                    lineHeight={1}
                    fontWeight={500}
                    width={column.width}
                    minWidth={column.minWidth}
                    maxWidth={column.maxWidth}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      flexWrap={"nowrap"}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      {column.render("Header")}
                      {!column.disableSortBy && (
                        <Box px={1}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faArrowDown} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box
            {...getTableBodyProps()}
            overflowX={"hidden"}
            overflowY={"scroll"}
            height={"100%"}
            flexGrow={1}
            position={"relative"}
          >
            {content}
          </Box>
        </Box>
      </Box>
      {!!total &&
        (customFooter || (
          <Box px={5} borderTop="1px solid" borderTopColor="gray.200">
            <Paginator
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              gotoPage={page => {
                onPageChange
                  ? onPageChange({ pageIndex: page })
                  : gotoPage(page);
              }}
              pageIndex={pageIndex}
              pageSize={pageSize}
              total={total}
            />
          </Box>
        ))}
    </Box>
  );
}
