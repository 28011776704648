import React, { ChangeEvent, useCallback, useMemo } from "react";
import { Avatar, Box, Switch, Text } from "@chakra-ui/react";
import {
  TLeadSourceTypeData,
  TNormalizedLeadSourceData,
} from "types/lead-source.type";
import { TPortalData } from "types/portal.type";
import Styles from "components/avatar/Avatar.module.scss";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { DEFAULT_PORTAL_IMAGE } from "constants/image-paths";

interface IProps {
  value: boolean;
  leadSourceType: TLeadSourceTypeData;
  leadSource: TNormalizedLeadSourceData;
  portal?: TPortalData;
  onConfigToggle: (leadSourceTypeId: number, isOn: boolean) => void;
  isDisabled?: boolean;
}
export const EmailNotificationsSourceLeadTypeToggle: React.FC<IProps> = ({
  value,
  leadSourceType,
  leadSource,
  portal,
  onConfigToggle,
  isDisabled,
}) => {
  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onConfigToggle(leadSourceType.id, event.target.checked);
    },
    [leadSourceType.id, onConfigToggle]
  );

  const label = useMemo(() => {
    switch (leadSource.source) {
      case LeadSourceEnum.ivt:
        return "Enable email notifications";
    }

    return portal?.name || leadSource.name;
  }, [leadSource, portal]);

  return (
    <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
      <Switch
        isChecked={value}
        onChange={handleToggle}
        isDisabled={isDisabled}
      />
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        ml={4}
      >
        <Avatar
          name={label}
          src={portal?.logoUrl || DEFAULT_PORTAL_IMAGE}
          size={"sm"}
          rounded={"sm"}
          className={Styles.squareAvatar}
          mr={4}
        />
        <Text textTransform={"none"}>{label}</Text>
      </Box>
    </Box>
  );
};
