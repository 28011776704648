import { stageColors } from "constants/colors";
import React from "react";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { Tag, TagLabel, TagProps } from "@chakra-ui/react";
import { LeadStageLabel } from "constants/lead-stage-label";

interface ILeadStageTagProps extends TagProps {
  stage: LeadStageEnum;
}

export const LeadStageTag: React.FC<ILeadStageTagProps> = ({
  stage,
  ...rest
}) => {
  return (
    <Tag colorScheme={stageColors[stage]} {...rest}>
      <TagLabel>{LeadStageLabel[stage]}</TagLabel>
    </Tag>
  );
};
