import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { TAccountApiRequestData } from "types/account-developers.type";
import { FormControlLabel, JSONViewer } from "components";
import { DATE_TIME_DETAIL_FORMAT } from "constants/date";
import moment from "moment";
import { RequestStatusCodeTag } from "components/tags";
import { getResponseBodyAsObject } from "utils/api.utils";

interface IProps {
  request: TAccountApiRequestData;
}

export const DevelopersConfigApiRequestItem: FC<IProps> = ({ request }) => {
  const responseBody = useMemo(
    () => getResponseBodyAsObject(request.responseBody),
    [request.responseBody]
  );

  return (
    <AccordionItem id={request.id.toString()}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <HStack spacing={2}>
            <RequestStatusCodeTag code={request.responseStatusCode} />
            <Tag>{request.method}</Tag>
            <Tag>{`IP ${request.ip}`}</Tag>
            <Box flexGrow={1} noOfLines={1}>
              {request.originalUrl}
            </Box>
            <Box>
              {moment(request.createdAt).format(DATE_TIME_DETAIL_FORMAT)}
            </Box>
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <VStack spacing={2}>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Request headers</FormControlLabel>
            <JSONViewer json={request.requestHeaders} />
          </Box>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Request body</FormControlLabel>
            <JSONViewer json={request.requestBody} />
          </Box>
          {!!responseBody && (
            <Box width={"100%"}>
              <FormControlLabel mb={2}>Response body</FormControlLabel>
              <JSONViewer json={responseBody} />
            </Box>
          )}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};
