import { Box, HStack } from "@chakra-ui/react";
import {
  DelayedInput,
  MultiSelectInput,
  SingleSelectInput,
  StandardIconButton,
  TagInput,
  Tooltip,
} from "components";
import { SelectInputSizes } from "components/select-input/select-input-size";
import {
  Expression,
  expressionOperatorDefinitions,
  ExpressionOperators,
  ExpressionOperatorTypes,
  FormFieldRadio,
  FormFieldType,
  validateFormEndingPages,
} from "@leadpro/forms";
import {
  DEFAULT_VALUE_MAP_PER_OPERATOR_TYPE,
  FormToolPageActionConditionExpressionOperatorOptions,
} from "constants/form-tool-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/sharp-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";
import { TSelectValue } from "types/select-input.type";
import { observer } from "mobx-react";
import { getErrorForConditionExpression } from "utils/form-tool-page.utils";

const TEXT_BASED_INPUTS = [
  FormFieldType.TEXT,
  FormFieldType.TEXT_AREA,
  FormFieldType.EMAIL,
  FormFieldType.PHONE,
  FormFieldType.POSTCODE,
];

const SELECT_BASED_INPUTS = [FormFieldType.RADIO];

interface IProps {
  childIndex: number;
  expression: Expression;
  onChange: (childIndex: number, newConfiguration?: Expression) => void;
  validationResult: ReturnType<typeof validateFormEndingPages>;
  validationPath: (string | number)[];
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsConfigConditionExpression: FC<IProps> = observer(
  ({
    childIndex,
    expression,
    onChange,
    validationResult,
    validationPath,
    formToolPageWIPFormStore,
  }) => {
    const errorMessage = useMemo(() => {
      return getErrorForConditionExpression(
        [...validationPath],
        validationResult
      );
    }, [validationResult, validationPath]);

    const onOperatorChange = useCallback(
      (operator: TSelectValue<ExpressionOperators>) => {
        if (!operator) return;

        const inputTypeFromOperator =
          expressionOperatorDefinitions[operator].type;
        onChange(childIndex, {
          ...expression,
          operator,
          value: DEFAULT_VALUE_MAP_PER_OPERATOR_TYPE[inputTypeFromOperator],
        });
      },
      [childIndex, expression, onChange]
    );

    const handleRemoveExpression = useCallback(() => {
      onChange(childIndex);
    }, [childIndex, onChange]);

    const onFieldChange = useCallback(
      (field: TSelectValue<string>) => {
        if (!field) return;

        const inputTypeFromOperator =
          expressionOperatorDefinitions[expression.operator].type;
        onChange(childIndex, {
          ...expression,
          field,
          value: DEFAULT_VALUE_MAP_PER_OPERATOR_TYPE[inputTypeFromOperator],
        });
      },
      [childIndex, expression, onChange]
    );

    const onTextValueChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(childIndex, { ...expression, value: event.target.value });
      },
      [onChange, childIndex, expression]
    );

    const onMultiTextValueChange = useCallback(
      (value: string[]) => {
        onChange(childIndex, { ...expression, value });
      },
      [onChange, childIndex, expression]
    );

    const onSingleSelectValueChange = useCallback(
      (value: TSelectValue<string>) => {
        onChange(childIndex, { ...expression, value });
      },
      [onChange, childIndex, expression]
    );

    const onMultiSelectValueChange = useCallback(
      (value: TSelectValue<string>[]) => {
        onChange(childIndex, { ...expression, value });
      },
      [onChange, childIndex, expression]
    );

    const valueInput = useMemo(() => {
      const fieldId = expression.field;
      const field = formToolPageWIPFormStore.wipFormSchemaJS.fields[fieldId];
      if (!fieldId || !field) return null;

      const inputTypeFromOperator =
        expressionOperatorDefinitions[expression.operator];

      switch (inputTypeFromOperator.type) {
        case ExpressionOperatorTypes.SingleValue:
          if (TEXT_BASED_INPUTS.includes(field.type)) {
            return (
              <DelayedInput
                delay={1000}
                size={"sm"}
                inputValue={expression.value as string}
                placeholder={"some text here"}
                onChange={onTextValueChange}
              />
            );
          } else if (SELECT_BASED_INPUTS.includes(field.type)) {
            const options = (field as FormFieldRadio).options;
            return (
              <SingleSelectInput<string>
                size={SelectInputSizes.SMALL}
                value={expression.value as string}
                onChange={onSingleSelectValueChange}
                options={options}
                placeholder={"select value"}
              />
            );
          }
          return null;
        case ExpressionOperatorTypes.MultiValue:
          if (TEXT_BASED_INPUTS.includes(field.type)) {
            return (
              <TagInput
                value={expression.value as string[]}
                placeholder={"Separate values with enter or comma"}
                onChange={onMultiTextValueChange}
              />
            );
          } else if (SELECT_BASED_INPUTS.includes(field.type)) {
            const options = (field as FormFieldRadio).options;
            return (
              <MultiSelectInput<string>
                size={SelectInputSizes.SMALL}
                value={expression.value as string[]}
                onChange={onMultiSelectValueChange}
                options={options}
                placeholder={"select values"}
              />
            );
          }
          return null;
        case ExpressionOperatorTypes.Regex:
          if (TEXT_BASED_INPUTS.includes(field.type)) {
            return (
              <DelayedInput
                delay={5000}
                size={"sm"}
                inputValue={expression.value as string}
                placeholder={"some text here"}
                onChange={onTextValueChange}
              />
            );
          }
          return null;
        case ExpressionOperatorTypes.NoValue:
          return null;
      }
    }, [
      expression.field,
      expression.value,
      expression.operator,
      onTextValueChange,
      onMultiTextValueChange,
      onSingleSelectValueChange,
      onMultiSelectValueChange,
      formToolPageWIPFormStore.wipFormSchemaJS.fields,
    ]);

    return (
      <HStack spacing={2} width={"100%"} pr={6}>
        <Box width={4} height={"2px"} background={"gray.100"} />
        <Box flexGrow={1} flexShrink={0} maxWidth={"500px"}>
          <SingleSelectInput<string>
            size={SelectInputSizes.SMALL}
            value={expression.field}
            onChange={onFieldChange}
            options={formToolPageWIPFormStore.allQuestionsAsOptions}
            placeholder={"select question"}
          />
        </Box>
        <Box flexShrink={0} width={"200px"}>
          <SingleSelectInput<ExpressionOperators>
            size={SelectInputSizes.SMALL}
            value={expression.operator}
            onChange={onOperatorChange}
            options={FormToolPageActionConditionExpressionOperatorOptions}
            placeholder={"select operator"}
          />
        </Box>
        <Box flexGrow={1} flexShrink={0}>
          {valueInput}
        </Box>
        <HStack spacing={1} alignItems={"center"}>
          {errorMessage && (
            <Tooltip
              aria-label={`expression-${childIndex}-tooltip`}
              label={errorMessage}
            >
              <Box color={"red.500"} fontSize={"lg"}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Box>
            </Tooltip>
          )}
          <StandardIconButton
            onClick={handleRemoveExpression}
            aria-label={"remove-condition-expression"}
            icon={<FontAwesomeIcon icon={faXmark} />}
          />
        </HStack>
      </HStack>
    );
  }
);
