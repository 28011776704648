import { TQuestionnaireToolSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { Control } from "react-hook-form";
import { Box, Text } from "@chakra-ui/react";
import { PageToolPageSettingsInputLayout } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolPageSettingsInputLayout";
import { FormControlMeta, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { useCallback } from "react";
import { TSelectOption } from "types/select-input.type";
import { filterOptionsByLabel } from "utils/select.utils";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

type TFieldValues = TQuestionnaireToolSettingsFieldValues;

interface IProps<T extends TFieldValues> {
  control: Control<T>;
  accountStore: UserAccountStore;
}

export function PlainQuestionnaireToolPageTriggerSettings<
  T extends TFieldValues
>({ control, accountStore }: IProps<T>) {
  const accountPersistentFiltersStore =
    accountStore.accountPersistentFiltersStore;

  const handleFilteringPersistantFilterOptions = useCallback(
    (options: TSelectOption<number>[], searchTerm: string) => {
      return filterOptionsByLabel<number>(options, searchTerm);
    },
    []
  );

  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        Trigger settings
      </Text>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Enable this questionnaire"
          description={
            "Enabling it will allow LeadPro algorithm to consider this questionnaire when determining the right one to send."
          }
        />
        <FormControlV2<TFieldValues>
          name={"active"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label={"Questionnaire trigger"}
          description={
            "Define lead properties that will trigger this questionnaire"
          }
        />
        <FormControlV2<TFieldValues>
          name={"leadFilterId"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.SINGLE_SELECT}
          additionalProps={{
            options: accountPersistentFiltersStore.persistentFiltersAsOptions,
            filterFn: handleFilteringPersistantFilterOptions,
            clearable: true,
            placeholder: "Select lead filter",
          }}
        />
      </PageToolPageSettingsInputLayout>
    </Box>
  );
}

export const QuestionnaireToolPageTriggerSettings = observer(
  PlainQuestionnaireToolPageTriggerSettings
);
