import { FormControlError, SingleSelectInput } from "components";
import React, { FC, useCallback, useEffect } from "react";
import { AccountOfficesStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffices.store";
import { observer } from "mobx-react";
import { FieldError } from "react-hook-form";

interface IProps {
  value: number | null;
  onChange: (...event: any[]) => void;
  error?: FieldError;
  postcode: string;
  accountOfficesStore: AccountOfficesStore;
}

export const OfficeAutosuggestSelect: FC<IProps> = observer(
  ({ value, onChange, error, postcode, accountOfficesStore }) => {
    const {
      accountOfficesOptionsArray,
      suggestOfficeIdFromPostcode,
    } = accountOfficesStore;

    useEffect(() => {
      const suggestedOfficeId = suggestOfficeIdFromPostcode(postcode);

      if (!!suggestedOfficeId) {
        onChange(suggestedOfficeId);
      }
    }, [onChange, postcode, suggestOfficeIdFromPostcode]);

    const handleChange = useCallback(
      value => {
        onChange(value);
      },
      [onChange]
    );

    return (
      <>
        <SingleSelectInput
          options={accountOfficesOptionsArray}
          value={value}
          onChange={handleChange}
          clearable={true}
        />
        {!!error && <FormControlError error={error} />}
      </>
    );
  }
);
