import { TContentfulArticleData } from "types/contentful-article.type";
import { ContentfulClient } from "services/contentful.service";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "./App.store";
import { keyBy } from "lodash";

export class GuidesStore {
  private readonly root: AppStore;
  private articles: IObservableArray<TContentfulArticleData>;

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.articles = observable.array<TContentfulArticleData>();
  }

  get articlesArray() {
    return this.articles.slice();
  }

  get articlesMap() {
    return keyBy(this.articlesArray, article => article.fields.slug);
  }

  private setArticles(articles: TContentfulArticleData[]) {
    this.articles.replace(articles);
  }

  private setArticle(article: TContentfulArticleData) {
    this.articlesMap[article.fields.slug] = article;
  }

  public async fetchArticles(query?: string) {
    const { items } = await ContentfulClient.getEntries({
      content_type: "article",
      ...(!!query && { "fields.content[match]": query }),
    });

    this.setArticles(items);
  }

  public async fetchArticle(articleSlug?: string) {
    const { items } = await ContentfulClient.getEntries({
      content_type: "article",
      "fields.slug[in]": articleSlug,
    });

    if (!!items?.[0]) {
      this.setArticle(items[0]);
    }
  }
}
