import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import { FormToolPageQuestionTypeLabels } from "constants/form-tool-page";
import { FormToolPageSettingsSelectedQuestionConfiguratorMenu } from "./FormToolPageSettingsSelectedQuestionConfiguratorMenu/FormToolPageSettingsSelectedQuestionConfiguratorMenu";
import { FormFieldType } from "@leadpro/forms";
import { Input } from "components";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  index: number;
  questionKey: string;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsSelectedQuestionConfigurator: FC<IProps> = observer(
  ({ index, questionKey, formToolPageWIPFormStore }) => {
    const question =
      formToolPageWIPFormStore.wipFormSchemaJS.fields[questionKey];

    const customElement = useMemo(() => {
      if (question.type === FormFieldType.RADIO) {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"12px"}
            pt={"10px"}
          >
            {question.options.map(option => (
              <Box
                key={option.value}
                display={"flex"}
                alignItems={"center"}
                gap={"12px"}
                color={"leadpro.500"}
              >
                <FontAwesomeIcon icon={faSquare} fontSize={18} />
                <Box>{option.label}</Box>
              </Box>
            ))}
          </Box>
        );
      }
    }, [question]);

    return (
      <Box
        border={"1px solid"}
        borderColor={"leadpro.200"}
        rounded={"sm"}
        display={"flex"}
        flexDirection={"row"}
        bg={"white"}
        shadow={"sm"}
      >
        <Box
          width={"90px"}
          color={"leadpro.400"}
          flexShrink={0}
          fontSize={"xl"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {index + 1}
        </Box>
        <Box
          display={"flex"}
          gap={"6px"}
          flexDirection={"column"}
          flexGrow={1}
          bg={"leadpro.50"}
          padding={"20px 16px"}
          fontSize={"md"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              {question.label}
              {question.required && (
                <Box as={"span"} color={"leadpro.500"}>
                  {" "}
                  *
                </Box>
              )}
            </Box>
            <Box fontSize={"xs"} color={"leadpro.500"}>
              {FormToolPageQuestionTypeLabels[question.type]}
            </Box>
          </Box>
          <Input
            size={"sm"}
            value={question.placeholder || ""}
            isDisabled={true}
          />
          {customElement}
        </Box>
        <FormToolPageSettingsSelectedQuestionConfiguratorMenu
          questionKey={questionKey}
          formToolPageWIPFormStore={formToolPageWIPFormStore}
        />
      </Box>
    );
  }
);
