import { observer } from "mobx-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { LeadAttributionReportSegmentSummary } from "./LeadAttributionReportSegmentSummary";
import { LeadAttributionReportSegmentDetails } from "./LeadAttributionReportSegmentDetails/LeadAttributionReportSegmentDetails";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { useOnScreen } from "utils/react-hooks/useOnScreen.hook";
import { TAnalyticsDateRangeFilterConfiguration } from "types/account-analytics.type";

interface IProps {
  accountAnalyticsStore: AccountAnalyticsStore;
  leadAttributionType: LeadAttributionTypesEnum;
}

export const LeadAttributionReportSegment: FC<IProps> = observer(
  ({ accountAnalyticsStore, leadAttributionType }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const [
      lastUsedDateRange,
      setLastUsedDateRange,
    ] = useState<TAnalyticsDateRangeFilterConfiguration | null>();
    const [
      loadingLeadAttributionAnalyticsStatsStatus,
      setLoadingLeadAttributionAnalyticsStatsStatus,
    ] = useState(ApiRequestStatusEnum.NONE);

    const {
      accountAnalyticsFiltersStore: { dateRange },
      accountAnalyticsLeadAttributionStore: {
        fetchAccountLeadAttributionStatsByType,
      },
    } = accountAnalyticsStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLastUsedDateRange(dateRange);
          setLoadingLeadAttributionAnalyticsStatsStatus(
            ApiRequestStatusEnum.PENDING
          );
          await fetchAccountLeadAttributionStatsByType(
            leadAttributionType,
            dateRange
          );
          setLoadingLeadAttributionAnalyticsStatsStatus(
            ApiRequestStatusEnum.SUCCESS
          );
        } catch (e) {
          setLoadingLeadAttributionAnalyticsStatsStatus(
            ApiRequestStatusEnum.ERROR
          );
        }
      };

      if (
        isVisible &&
        dateRange !== lastUsedDateRange &&
        loadingLeadAttributionAnalyticsStatsStatus !==
          ApiRequestStatusEnum.PENDING
      ) {
        fetchData();
      }
    }, [
      loadingLeadAttributionAnalyticsStatsStatus,
      isVisible,
      lastUsedDateRange,
      dateRange,
      leadAttributionType,
      fetchAccountLeadAttributionStatsByType,
    ]);

    return (
      <Box
        ref={ref}
        width={"100%"}
        borderWidth={"1px"}
        borderColor={"leadpro.200"}
        px={6}
        py={5}
        position={"relative"}
      >
        {[ApiRequestStatusEnum.PENDING, ApiRequestStatusEnum.SUCCESS].includes(
          loadingLeadAttributionAnalyticsStatsStatus
        ) && (
          <HStack width={"100%"} spacing={5} alignItems={"stretch"}>
            <LeadAttributionReportSegmentSummary
              accountAnalyticsStore={accountAnalyticsStore}
              leadAttributionType={leadAttributionType}
            />
            <LeadAttributionReportSegmentDetails
              accountAnalyticsStore={accountAnalyticsStore}
              leadAttributionType={leadAttributionType}
            />
          </HStack>
        )}
        {loadingLeadAttributionAnalyticsStatsStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingLeadAttributionAnalyticsStatsStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
      </Box>
    );
  }
);
