import {
  ILeadResponderEmailBlockDefinition,
  LeadResponderEmailBlockTypes,
} from "utils/hoc/leadResponderEmailBlock.hoc";
import {
  IParsingAndRoutingBlockDefinition,
  ParsingAndRoutingBlock,
} from "./blocks/ParsingAndRoutingBlock";
import { EmailDetailsBlock } from "./blocks/EmailsDetailsBlock";
import { ContentBlock } from "./blocks/ContentBlock";
import { AccountLeadResponderEmailStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmail.store";
import { AccountOfficesStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffices.store";

export const leadResponderEmailBlockDefinitionFactory = (
  leadResponderEmailStore: AccountLeadResponderEmailStore,
  accountOfficesStore: AccountOfficesStore
): ILeadResponderEmailBlockDefinition[] => {
  const definitions: ILeadResponderEmailBlockDefinition[] = [];

  const email = leadResponderEmailStore.email;

  const parsingAndRoutingBlockDefinition: IParsingAndRoutingBlockDefinition = {
    blockType: LeadResponderEmailBlockTypes.ParsingAndRoutingBlock,
    accountLeadResponderEmailStore: leadResponderEmailStore,
    accountOfficesStore,
    email,
  };

  definitions.push(parsingAndRoutingBlockDefinition);

  const emailRoutingBlockDefinition: ILeadResponderEmailBlockDefinition = {
    blockType: LeadResponderEmailBlockTypes.EmailDetailsBlock,
    accountLeadResponderEmailStore: leadResponderEmailStore,
    email,
  };

  definitions.push(emailRoutingBlockDefinition);

  definitions.push({
    blockType: LeadResponderEmailBlockTypes.ContentBlock,
    accountLeadResponderEmailStore: leadResponderEmailStore,
    email,
  });

  return definitions;
};

export const components = {
  [LeadResponderEmailBlockTypes.EmailDetailsBlock]: EmailDetailsBlock,
  [LeadResponderEmailBlockTypes.ParsingAndRoutingBlock]: ParsingAndRoutingBlock,
  [LeadResponderEmailBlockTypes.ContentBlock]: ContentBlock,
};
