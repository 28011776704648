import { Box, Divider, HStack, Progress } from "@chakra-ui/react";
import { ITablePopupControlsProps } from "components/table/table-props";
import React, { FC, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { faTrashCan, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faList, faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import { StandardIconButton, Tooltip } from "components";
import { BulkUpdateMenu } from "./BulkUpdateMenu";
import { BulkUpdateNextActionDateMenu } from "./BulkUpdateNextActionDateMenu";
import { TLead } from "types/lead.type";
import { BulkUpdateDeleteLeads } from "./BulkUpdateDeleteLeads";
import { BulkUpdateStatusMenu } from "./BulkUpdateStatusMenu";
import { BulkUpdateAssignedTo } from "./BulkUpdateAssignedTo";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

const ICON_SIZE = 20;

export const LeadsTableBulkUpdateControls: FC<ITablePopupControlsProps<
  TLead
>> = observer(({ selectedRowIds, onClose, leadsStore }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();

  if (!selectedAccountStore) return null;

  return (
    <Box
      color="white"
      p={3}
      bg="black"
      rounded={"full"}
      px={8}
      py={4}
      height={"72px"}
      width={"445px"}
      position={"relative"}
    >
      {isSubmitting ? (
        <Box
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          width={"85%"}
          transform={"translate(-50%, -50%)"}
        >
          <Progress
            size="sm"
            hasStripe={true}
            isIndeterminate={true}
            rounded={"full"}
          />
        </Box>
      ) : (
        <HStack spacing={6}>
          <HStack spacing={6}>
            <Box>
              <Box color={"gray.400"} fontSize={"xs"}>
                Bulk update
              </Box>
              <Box color={"white"} fontSize={"sm"}>
                {Object.keys(selectedRowIds).length} lead(s)
              </Box>
            </Box>

            <BulkUpdateMenu
              ariaLabel={"Update Next action date"}
              icon={
                <FontAwesomeIcon
                  icon={faCalendar}
                  fontSize={ICON_SIZE}
                  color={"white"}
                />
              }
            >
              <BulkUpdateNextActionDateMenu
                leadsStore={leadsStore}
                selectedRowIds={selectedRowIds}
                setIsSubmitting={setIsSubmitting}
              />
            </BulkUpdateMenu>

            <BulkUpdateMenu
              ariaLabel={"Update Assigned to"}
              icon={
                <FontAwesomeIcon
                  icon={faUserGroup}
                  fontSize={ICON_SIZE}
                  color={"white"}
                />
              }
            >
              <BulkUpdateAssignedTo
                leadsStore={leadsStore}
                selectedRowIds={selectedRowIds}
                setIsSubmitting={setIsSubmitting}
              />
            </BulkUpdateMenu>

            <BulkUpdateMenu
              ariaLabel={"Update Status"}
              icon={
                <FontAwesomeIcon
                  icon={faList}
                  fontSize={ICON_SIZE}
                  color={"white"}
                />
              }
            >
              <BulkUpdateStatusMenu
                leadsStore={leadsStore}
                leadStatusesStore={
                  selectedAccountStore.accountLeadStatusesStore
                }
                selectedRowIds={selectedRowIds}
                setIsSubmitting={setIsSubmitting}
              />
            </BulkUpdateMenu>

            <BulkUpdateMenu
              ariaLabel={"Delete"}
              icon={
                <FontAwesomeIcon
                  icon={faTrashCan}
                  fontSize={ICON_SIZE}
                  color={"white"}
                />
              }
            >
              <BulkUpdateDeleteLeads
                leadsStore={leadsStore}
                selectedRowIds={selectedRowIds}
                leadStatusesStore={
                  selectedAccountStore.accountLeadStatusesStore
                }
                setIsSubmitting={setIsSubmitting}
              />
            </BulkUpdateMenu>
          </HStack>
          <Divider orientation="vertical" height={"38px"} />
          <Tooltip
            label={"Close and deselect"}
            aria-label={"Close and deselect"}
            portalProps={{
              appendToParentPortal: true,
              containerRef: closeBtnRef,
            }}
          >
            <StandardIconButton
              colorScheme={"red"}
              background={undefined}
              _hover={undefined}
              onClick={onClose}
              aria-label={"bulk-update-close-controls"}
              icon={
                <FontAwesomeIcon
                  icon={faXmark}
                  fontSize={ICON_SIZE}
                  color={"white"}
                />
              }
              ref={closeBtnRef}
            />
          </Tooltip>
        </HStack>
      )}
    </Box>
  );
});
