import { TSelectOption } from "types/select-input.type";
import { TAssignableToLeadUserData } from "types/user-data.type";

export function getUserFullName(
  firstName: string | null,
  lastName: string | null,
  fallback?: string
) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName) return firstName;
  if (lastName) return lastName;

  return fallback;
}

export const assigneeOptionsFilter = (
  options: TSelectOption<TAssignableToLeadUserData>[],
  searchTerm: string
) => {
  return options.filter(option => {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return (
      getUserFullName(option.value.firstName, option.value.lastName)
        ?.toLowerCase()
        .includes(lowercaseSearchTerm) ||
      option.value.email.toLowerCase().includes(lowercaseSearchTerm)
    );
  });
};
