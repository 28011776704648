import React from "react";
import { Box, Divider } from "@chakra-ui/react";
import { ReactourStepContentArgs } from "reactour";
import { ITourStepDefinition, TourStepFooter } from "./TourStepFooter";

interface IProps {
  stepContentArgs: ReactourStepContentArgs;
  stepDefinition: ITourStepDefinition;
  totalSteps: number;
  isStepSubmitting: boolean;
}

export const TourStepBase: React.FC<IProps> = ({
  stepContentArgs,
  stepDefinition,
  totalSteps,
  isStepSubmitting,
}) => {
  return (
    <Box>
      <Box p={6} fontWeight={400}>
        {stepDefinition.content}
      </Box>
      <Divider />
      {totalSteps > 1 && (
        <TourStepFooter
          stepContentArgs={stepContentArgs}
          stepDefinition={stepDefinition}
          totalSteps={totalSteps}
          isStepSubmitting={isStepSubmitting}
        />
      )}
    </Box>
  );
};
