import { observer } from "mobx-react";
import { VStack } from "@chakra-ui/react";
import { PropertyDetailsInfoSection } from "./PropertyDetailsInfoSection";
import { FC } from "react";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import { PropertyDetailsLeadSourcesFilter } from "./PropertyDetailsLeadSourcesFilter";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PropertyDetailsDisclaimer } from "./PropertyDetailsDisclaimer";
import { PropertyDetailsQuestionnaireFilter } from "./PropertyDetailsQuestionnaireFilter";

interface IProps {
  accountStore: UserAccountStore;
  propertyStore: AccountPropertyStore;
}

export const PropertyDetailsCard: FC<IProps> = observer(
  ({ accountStore, propertyStore }) => {
    return (
      <VStack
        spacing={12}
        p={10}
        height={"100%"}
        overflowY={"auto"}
        background={
          "linear-gradient(270deg, #FAFBFF 0%, rgba(255, 255, 255, 0.00) 35px)"
        }
      >
        <PropertyDetailsInfoSection propertyStore={propertyStore} />
        <PropertyDetailsLeadSourcesFilter
          accountStore={accountStore}
          propertyStore={propertyStore}
        />
        <PropertyDetailsQuestionnaireFilter
          accountStore={accountStore}
          propertyStore={propertyStore}
        />
        <PropertyDetailsDisclaimer propertyStore={propertyStore} />
      </VStack>
    );
  }
);
