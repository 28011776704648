import { OfficeCategory } from "enums/office-type.enum";

export const addPrefixToId = (id: number, prefix: string): string =>
  `${prefix}-${id}`;

export const removePrefixFromId = (id: string): number =>
  parseInt(id.split("-")[1]);

export const removePrefixFromOfficeGroupAndOfficeIds = (
  prefixedIds: string[]
): Record<string, number[]> => {
  let officeIds: number[] = [];
  let officeGroupIds: number[] = [];

  prefixedIds.forEach(id => {
    if (id.includes(OfficeCategory.GROUP)) {
      officeGroupIds.push(removePrefixFromId(id));
    } else if (id.includes(OfficeCategory.OFFICE)) {
      officeIds.push(removePrefixFromId(id));
    }
  });

  return {
    officeIds,
    officeGroupIds,
  };
};

export const getOfficeCategoryFromPrefixId = (prefixId: string) => {
  if (prefixId.includes(OfficeCategory.OFFICE)) {
    return OfficeCategory.OFFICE;
  }

  if (prefixId.includes(OfficeCategory.GROUP)) {
    return OfficeCategory.GROUP;
  }
};
