import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TOpenViewSyncLeadSourceExtendedData } from "types/openview.type";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { syncOpenViewLeadSourceTableColumnDef } from "./syncOpenViewLeadSourceTableColumnDef";
import { SyncStatusFilter } from "../../SyncStatusFilter";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
}

export const OpenViewIntegrationsLeadSourcesConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TOpenViewSyncLeadSourceExtendedData>
    >({});

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationOpenViewStore =
      accountIntegrationsStore.accountIntegrationOpenViewStore;
    const accountIntegrationOpenViewLeadSourcesStore =
      accountIntegrationOpenViewStore.accountIntegrationOpenViewLeadSourcesStore;
    const {
      openViewSyncLeadSourceData,
      setSyncStatusFilter,
      getSyncStatusFilter,
      filteredOpenViewSyncLeadSourceData,
    } = accountIntegrationOpenViewLeadSourcesStore;

    const updateSyncPair = useCallback(
      (syncPair: TOpenViewSyncLeadSourceExtendedData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.leadSourceId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    useEffect(() => {
      const suggestedArray = openViewSyncLeadSourceData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy<TOpenViewSyncLeadSourceExtendedData>(
        suggestedArray,
        syncData => syncData.leadSourceId
      );
      setNewRelations({
        ...suggestedMap,
      });
    }, [openViewSyncLeadSourceData]);

    const columnsDef = useMemo(() => {
      return syncOpenViewLeadSourceTableColumnDef(accountStore, updateSyncPair);
    }, [accountStore, updateSyncPair]);

    const dataSource = useMemo(() => {
      return filteredOpenViewSyncLeadSourceData.map(syncData => {
        if (!!newRelations[syncData.leadSourceId])
          return newRelations[syncData.leadSourceId];

        return syncData;
      });
    }, [filteredOpenViewSyncLeadSourceData, newRelations]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await accountIntegrationOpenViewLeadSourcesStore.updateAccountOpenViewSyncedLeadSources(
          Object.values(newRelations)
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "iamproperty CRM integration lead sources configuration updated"
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [newRelations, toast, accountIntegrationOpenViewLeadSourcesStore]);

    const banner = useMemo(() => {
      const notSyncedArray = openViewSyncLeadSourceData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.NOT_SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your lead sources are not syncing to LeadPro`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [openViewSyncLeadSourceData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>Lead source mappings</Box>
        {banner}
        <TableV2<TOpenViewSyncLeadSourceExtendedData>
          globalFilterInputPlaceholder={"Search sources by name"}
          dataSource={dataSource}
          additionalActions={
            <SyncStatusFilter
              isChecked={getSyncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          columns={columnsDef}
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
