import { Box, Divider, Portal, SimpleGrid, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, MutableRefObject, useCallback } from "react";
import { AccountEmailNotificationsItem } from "./AccountEmailNotificationsItem";
import { FormFooter } from "components/form";
import { ApiMessageStack, TagInput } from "components";
import {
  accountEmailNotificationTilesConfig,
  accountLeadAutoAssignEmailNotificationTilesConfig,
} from "./accountEmailNotificationTilesConfig";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { AccountEmailNotificationsLocalStore } from "./AccountEmailNotifications.local.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";

interface IProps {
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AccountEmailNotifications: FC<IProps> = observer(
  ({ accountStore, containerRef }) => {
    const toast = useToast();
    const emailNotificationsLocalStore = useLocalStore(
      AccountEmailNotificationsLocalStore,
      accountStore
    );

    const {
      currentEmailNotificationPreferences,
      currentIsAssigneeEmailNotificationsEnabled,
      currentEmailsList,
      isSubmitting,
      dirty,
      setIsSubmitting,
      setCurrentEmailNotificationPreferences,
      setCurrentIsAssigneeEmailNotificationsEnabled,
      setCurrentEmailsList,
      handleSubmit,
      resetForm,
    } = emailNotificationsLocalStore;

    const handleSubmitForm = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await handleSubmit();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={"Account email notifications preferences updated."}
            />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [setIsSubmitting, handleSubmit, toast]);

    return (
      <>
        <Box mb={5}>
          <Box fontSize={"xl"} fontWeight={"bold"} color={"leadpro.700"}>
            Lead email notifications (to your offices)
          </Box>
          <Box fontSize={"sm"} color={"leadpro.500"}>
            These notifications will be sent to the office contact email
            addresses, associated with a lead upon its creation.
          </Box>
        </Box>
        <SimpleGrid columns={2} spacing={8} p={1}>
          {accountEmailNotificationTilesConfig.map(config => (
            <AccountEmailNotificationsItem
              key={config.value}
              selectedValue={currentEmailNotificationPreferences}
              onClick={setCurrentEmailNotificationPreferences}
              {...config}
            />
          ))}
        </SimpleGrid>
        <Divider my={10} />
        <Box mb={5}>
          <Box fontSize={"xl"} fontWeight={"bold"} color={"leadpro.700"}>
            Lead assignment email notifications (to your agents)
          </Box>
          <Box fontSize={"sm"} color={"leadpro.500"}>
            These notifications will be sent to agents when they are assigned to
            a lead through auto-assign.
          </Box>
        </Box>
        <SimpleGrid columns={2} spacing={8} p={1}>
          {accountLeadAutoAssignEmailNotificationTilesConfig.map(config => (
            <AccountEmailNotificationsItem
              key={config.value}
              selectedValue={currentIsAssigneeEmailNotificationsEnabled}
              onClick={setCurrentIsAssigneeEmailNotificationsEnabled}
              {...config}
            />
          ))}
        </SimpleGrid>
        <Divider my={10} />
        <Box px={1}>
          <Box fontSize={"xl"} fontWeight={"bold"} color={"leadpro.700"} mb={4}>
            Technical notifications
          </Box>

          <Box fontSize={"sm"} mb={4} color={"gray.500"}>
            If you like to receive email notifications about technical aspects
            of your Leadpro system, such as webhook expiry warnings, provide one
            or more email addresses below.
          </Box>

          <TagInput
            value={currentEmailsList}
            onChange={value => setCurrentEmailsList(value)}
            placeholder={
              "Provide one or more email addresses here to enable technical notifications"
            }
          />
        </Box>
        {dirty && (
          <Portal containerRef={containerRef}>
            <FormFooter
              isSubmitting={isSubmitting}
              submitForm={handleSubmitForm}
              resetForm={resetForm}
            />
          </Portal>
        )}
      </>
    );
  }
);
