import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { LeadResponderEmailSimpleFilterTypes } from "routes/dashboard/routes/tools/routes/lead-responder/components/LeadResponderLeadEmails/LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenuItem";
import { SortingRule } from "react-table";
import { LeadResponderEmailDateRangeFilterEnum } from "routes/dashboard/routes/tools/routes/lead-responder/components/LeadResponderLeadEmails/LeadResponderEmailTableFilterHeaders/FilterEmailsDateRangeHeader";
import moment from "moment/moment";
import {
  TLeadResponderEmailDateRangeFilterConfiguration,
  TLeadResponderEmailFilterMenuItemValue,
  TLeadResponderEmailsFilter,
} from "types/lead-responder-email.type";
import { AppStore } from "../../../App.store";

const filterInitialState: TLeadResponderEmailsFilter = {
  dateReceivedRange: { type: LeadResponderEmailDateRangeFilterEnum.ALL },
  offices: [],
  portals: [],
  statuses: [],
};

export class AcccountLeadResponderEmailsFiltersStore {
  private readonly accountId: number;
  private root: AppStore;
  private readonly toggleFilterMap: Record<
    LeadResponderEmailSimpleFilterTypes,
    (value: any) => void
  >;
  private pendingFilterData: TLeadResponderEmailsFilter;
  private sortBy: IObservableArray<SortingRule<string>>;
  private activeFilterData?: TLeadResponderEmailsFilter;
  constructor(accountId: number, root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountId = accountId;
    this.pendingFilterData = {
      ...filterInitialState,
    };
    this.root = root;
    this.sortBy = observable.array<SortingRule<string>>();
    this.toggleFilterMap = {
      [LeadResponderEmailSimpleFilterTypes.OFFICES]: this
        .togglePendingOfficesFilter,
      [LeadResponderEmailSimpleFilterTypes.DATE_RECEIVED]: this
        .setPendingFilterEmailDateRange,
      [LeadResponderEmailSimpleFilterTypes.PORTAL]: this
        .togglePendingPortalFilter,
      [LeadResponderEmailSimpleFilterTypes.STATUS]: this
        .togglePendingStatusFilter,
    };
  }

  get pendingFilter() {
    return toJS(this.pendingFilterData);
  }

  get activeFilter() {
    return toJS(this.activeFilterData);
  }

  get sortByArray() {
    return this.sortBy.slice();
  }

  private setPendingFilterEmailDateRange(
    config: TLeadResponderEmailDateRangeFilterConfiguration
  ) {
    const fullConfiguration: TLeadResponderEmailDateRangeFilterConfiguration = {
      type: config.type,
    };

    if (
      fullConfiguration.type === LeadResponderEmailDateRangeFilterEnum.CUSTOM
    ) {
      const { from, to } = config;

      if (!from && !to) {
        fullConfiguration.from = moment()
          .subtract(1, "day")
          .startOf("day")
          .toISOString();
        fullConfiguration.to = moment()
          .endOf("day")
          .toISOString();
      } else {
        fullConfiguration.from = config.from;
        fullConfiguration.to = config.to;
      }
    }

    this.pendingFilterData.dateReceivedRange = fullConfiguration;
  }

  private togglePendingOfficesFilter(
    value: TLeadResponderEmailFilterMenuItemValue
  ) {
    const officeId = value as number;
    if (this.pendingFilterData.offices.includes(officeId)) {
      this.pendingFilterData.offices = this.pendingFilterData.offices.filter(
        officeId => officeId !== value
      );
    } else {
      this.pendingFilterData.offices.push(officeId);
    }
  }

  private togglePendingPortalFilter(
    value: TLeadResponderEmailFilterMenuItemValue
  ) {
    const portalName = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].portalsMap[value].name;

    if (this.pendingFilterData.portals.includes(portalName)) {
      this.pendingFilterData.portals = this.pendingFilterData.portals.filter(
        portal => portal !== portalName
      );
    } else {
      this.pendingFilterData.portals.push(portalName);
    }
  }

  private togglePendingStatusFilter(
    value: TLeadResponderEmailFilterMenuItemValue
  ) {
    const status = value as string;
    if (this.pendingFilterData.statuses.includes(status)) {
      this.pendingFilterData.statuses = this.pendingFilterData.statuses.filter(
        status => status !== value
      );
    } else {
      this.pendingFilterData.statuses.push(status);
    }
  }

  public setActiveFromPendingFilter() {
    this.resetMeta();
    this.activeFilterData = toJS(this.pendingFilterData);
  }

  public resetMeta() {
    this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].leadResponderStore.leadResponderEmailsStore.resetMeta();
  }

  public setSortBy(sortBy: SortingRule<string> | null) {
    if (!sortBy) return this.sortBy.clear();
    this.sortBy.replace([sortBy]);
  }

  public togglePendingFilterByType<T>(
    value: T,
    type: LeadResponderEmailSimpleFilterTypes
  ) {
    const toggleFilter = this.toggleFilterMap[type];
    toggleFilter.call(this, value);
  }
}
