import { LeadStageEnum } from "../enums/lead-stage.enum";
import React from "react";

import {
  BusinessIcon,
  DuplicateIcon,
  InProgressIcon,
  NoBusinessIcon,
  UnreadIcon,
} from "../components";

export const stageIcons: {
  [key in LeadStageEnum]: React.ElementType;
} = {
  [LeadStageEnum.UNREAD]: UnreadIcon,
  [LeadStageEnum.IN_PROGRESS]: InProgressIcon,
  [LeadStageEnum.BUSINESS]: BusinessIcon,
  [LeadStageEnum.NO_BUSINESS]: NoBusinessIcon,
  [LeadStageEnum.DUPLICATE]: DuplicateIcon,
};
