import { Tag } from "@chakra-ui/react";
import { FC, useMemo } from "react";

interface IRequestStatusCodeTagProps {
  code?: number | null;
}

export const RequestStatusCodeTag: FC<IRequestStatusCodeTagProps> = ({
  code,
}) => {
  const { colorScheme, status } = useMemo(
    () => ({
      colorScheme: code?.toString().startsWith("2") ? "green" : "red",
      status: code || "No response",
    }),
    [code]
  );

  return <Tag colorScheme={colorScheme}>{status}</Tag>;
};
