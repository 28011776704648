import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { Box } from "@chakra-ui/react";
import { LeaderboardsContent } from "./LeaderboardsContent";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
}

export const Leaderboards: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatusForStats, setLoadingStatusForStats] = useState(
    ApiRequestStatusEnum.NONE
  );
  const [
    loadingStatusForLeadContacts,
    setLoadingStatusForLeadContacts,
  ] = useState(ApiRequestStatusEnum.NONE);

  const { accountAnalyticsStore } = accountStore;

  const {
    accountAnalyticsFiltersStore: { officeIdsArray, dateRange },
    accountAnalyticsLeaderboardStore: {
      statsDataRaw,
      loadAnalyticsData,
      loadTotalLeadContacts,
    },
  } = accountAnalyticsStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatusForStats(ApiRequestStatusEnum.PENDING);
        await loadAnalyticsData(dateRange);
        setLoadingStatusForStats(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatusForStats(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [dateRange, loadAnalyticsData, loadTotalLeadContacts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.PENDING);
        await loadTotalLeadContacts(dateRange, officeIdsArray);
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatusForLeadContacts(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [dateRange, officeIdsArray, loadAnalyticsData, loadTotalLeadContacts]);

  const loadingStatus = useMemo(() => {
    if (
      loadingStatusForStats === ApiRequestStatusEnum.PENDING ||
      loadingStatusForLeadContacts === ApiRequestStatusEnum.PENDING
    ) {
      return ApiRequestStatusEnum.PENDING;
    }

    if (
      loadingStatusForStats === ApiRequestStatusEnum.ERROR ||
      loadingStatusForLeadContacts === ApiRequestStatusEnum.ERROR
    ) {
      return ApiRequestStatusEnum.ERROR;
    }

    if (
      loadingStatusForStats === ApiRequestStatusEnum.SUCCESS &&
      loadingStatusForLeadContacts === ApiRequestStatusEnum.SUCCESS
    ) {
      return ApiRequestStatusEnum.SUCCESS;
    }

    return ApiRequestStatusEnum.NONE;
  }, [loadingStatusForStats, loadingStatusForLeadContacts]);

  if (loadingStatus === ApiRequestStatusEnum.ERROR) {
    return <Error />;
  }

  return (
    <>
      {loadingStatus === ApiRequestStatusEnum.PENDING && (
        <Box
          display={"flex"}
          position={"absolute"}
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={10}
        >
          <Loader />
        </Box>
      )}
      {!!statsDataRaw && (
        <LeaderboardsContent analyticsStore={accountAnalyticsStore} />
      )}
    </>
  );
});
