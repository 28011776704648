import React, { FC } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  SimpleGrid,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components/stats/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import * as Yup from "yup";
import { AutocallerDemoLeadData } from "constants/autocaller-demo-lead-data";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { isValidPhone } from "utils/custom-yup-validators.utils";
import {
  ApiMessageStack,
  FormControlV2,
  FormControlDescription,
} from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountAutocallerStore } from "store/UserAccounts/UserAccount/AccountAutocaller/AccountAutocaller.store";

type TFieldValues = {
  phoneNumber: string;
};

const initialValues: TFieldValues = {
  phoneNumber: "",
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
    .required(REQUIRED_FIELD),
});

interface IProps {
  autocallerStore: AccountAutocallerStore;
}
export const AutocallerDemoCard: FC<IProps> = observer(
  ({ autocallerStore }) => {
    const toast = useToast();
    const theme = useTheme();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = async ({ phoneNumber }: TFieldValues) => {
      try {
        if (phoneNumber) {
          await autocallerStore.performAutocallerDemo({
            leadType: LeadTypeEnum.Vendor,
            agentLine: phoneNumber,
            customerLine: AutocallerDemoLeadData.phone,
            customerName: `${AutocallerDemoLeadData.firstName} ${AutocallerDemoLeadData.lastName}`,
            propertyAddress: AutocallerDemoLeadData.address,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description:
              "The phone number you provided should receive a call shortly.",
          });
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    return (
      <Card width={"400px"}>
        <Box width={"100%"} padding={6} background={"rgb(242,249,250)"}>
          <Box fontSize={"xl"} fontWeight={500} lineHeight={"30px"} pb={2}>
            Rightmove + Zoopla vendor leads are sent to 5 agents.{" "}
            <Box as={"span"} color={"purple.600"}>
              Autocaller
            </Box>{" "}
            turns these leads into calls.
          </Box>
          <SimpleGrid columns={2} spacingY={3}>
            {[...Array(5)].map((v, i) => (
              <Box key={i} display={"flex"} alignItems={"center"}>
                <Center
                  width={"46px"}
                  height={"46px"}
                  background={"white"}
                  border={"1px solid"}
                  borderColor={"purple.200"}
                  rounded={"sm"}
                >
                  <FontAwesomeIcon
                    icon={faBuilding}
                    color={theme.colors.purple[400]}
                  />
                </Center>
                <Box pl={3}>Agent {i + 1}</Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        <Box width={"100%"} padding={6}>
          <Box fontSize={"lg"} fontWeight={500} pb={4}>
            Vendor lead details
          </Box>
          <SimpleGrid columns={2} spacingY={4}>
            <Box fontWeight={500}>
              <Box color={"gray.400"} pb={1}>
                First name
              </Box>
              <Box>{AutocallerDemoLeadData.firstName}</Box>
            </Box>
            <Box fontWeight={500}>
              <Box color={"gray.400"} pb={1}>
                Last name
              </Box>
              <Box>{AutocallerDemoLeadData.lastName}</Box>
            </Box>
            <Box fontWeight={500}>
              <Box color={"gray.400"} pb={1}>
                Address
              </Box>
              <Box>{AutocallerDemoLeadData.address}</Box>
            </Box>
          </SimpleGrid>

          <Box py={6}>
            <Divider />
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box fontSize={"lg"} fontWeight={500} pb={2}>
              Your number for this demo call
            </Box>
            <Box pb={6}>
              <FormControlV2<TFieldValues>
                name={"phoneNumber"}
                control={control}
                isDisabled={isSubmitting}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "XXX XXXX XXXX",
                }}
              />
              <FormControlDescription>
                We’ll ring this number and play the above details.
              </FormControlDescription>
            </Box>
            <Button
              size={"lg"}
              type={"submit"}
              width={"full"}
              colorScheme={"purple"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Simulate call
            </Button>
          </form>
        </Box>
      </Card>
    );
  }
);
