import React, { FC, useCallback, useState } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ActionPromptContainer, ApiMessageStack, Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { TLeadResponderWorkflowCoreData } from "types/lead-responder-workflow.type";
import { TemplateUsedInWorkflowNotice } from "components/notice/TemplateUsedInWorkflowNotice";

interface IProps {
  closePrompt: () => void;
  workflows: TLeadResponderWorkflowCoreData[];
  onDelete: () => Promise<void>;
}

export const DeleteTemplatePrompt: FC<IProps> = observer(
  ({ closePrompt, workflows, onDelete }) => {
    const toast = useToast();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleDeleteSmsTemplate = useCallback(async () => {
      try {
        setIsRemoving(true);
        await onDelete();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Template has been deleted."} />
          ),
        });
        closePrompt();
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [closePrompt, onDelete, toast]);

    return (
      <ActionPromptContainer
        header={"Confirm deletion"}
        body={
          !!workflows.length ? (
            <Message status={AlertStatusEnum.ERROR}>
              <TemplateUsedInWorkflowNotice
                description={
                  "You first need to remove the template from following workflow(s) before you can delete it:"
                }
                workflows={workflows}
              />
            </Message>
          ) : (
            <Box>
              <Box mb={5}>
                <Box>Are you sure you want to delete this template?</Box>
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  onClick={handleDeleteSmsTemplate}
                  colorScheme={"red"}
                  isDisabled={isRemoving}
                  isLoading={isRemoving}
                >
                  Delete template
                </Button>
              </Box>
            </Box>
          )
        }
      />
    );
  }
);
