import {
  DateRangePickerInput,
  Error,
  Loader,
  RelativeDrawer,
  StandardDrawerBody,
  StandardDrawerClose,
  StandardDrawerHeader,
  StandardDrawerTitle,
} from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  PanelsGroupLayout,
  RightSideLayout,
} from "routes/dashboard/components";
import { OfficesLeagueBoard } from "./components/OfficesLeagueBoard";
import {
  getLeaderboardDataByLeaderboardType,
  getLeadsDataByLeaderboardType,
  IOfficeLeaderboardStats,
} from "utils/office-leaderboard.utils";
import { OfficeLeagueStats } from "./components/OfficeLeagueStats";
import { Box, HStack, Switch } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { TODAY } from "constants/date";

interface IProps {
  accountStore: UserAccountStore;
}

export const League: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const [
    selectedOfficeStats,
    setSelectedOfficeStats,
  ] = useState<IOfficeLeaderboardStats | null>();
  const [isOfficeGroups, setIsOfficeGroups] = useState<boolean>(false);
  const {
    accountOfficeGroupsStore: {
      fetchAllAccountOfficeGroups,
      accountOfficeGroupsArray,
    },
    accountAnalyticsStore: {
      resetAnalyticStores,
      accountAnalyticsFiltersStore: {
        dateRange: { startDate, endDate },
        changeDateRange,
      },
      accountAnalyticsLeaderboardStore: {
        leadsByOfficeDataFilteredByOfficesMap,
        leadsByOfficeGroupDataFilteredByOfficesMap,
        leaderboardDataFilteredByOfficesArray,
        leaderboardDataAggregatedByOfficeGroupsArray,
        loadLeagueData,
      },
    },
  } = accountStore;

  const onItemClick = (officeStats: IOfficeLeaderboardStats) => {
    setSelectedOfficeStats(officeStats);
  };

  const closeOfficeStatsDrawer = useCallback(() => {
    setSelectedOfficeStats(null);
  }, [setSelectedOfficeStats]);

  useEffect(() => {
    return () => {
      resetAnalyticStores();
    };
  }, [resetAnalyticStores]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        setSelectedOfficeStats(null);
        await Promise.all([
          loadLeagueData({ startDate, endDate }),
          fetchAllAccountOfficeGroups(),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [loadLeagueData, startDate, endDate, fetchAllAccountOfficeGroups]);

  const leaderboardData = useMemo(() => {
    return getLeaderboardDataByLeaderboardType(
      isOfficeGroups,
      leaderboardDataFilteredByOfficesArray,
      leaderboardDataAggregatedByOfficeGroupsArray
    );
  }, [
    isOfficeGroups,
    leaderboardDataAggregatedByOfficeGroupsArray,
    leaderboardDataFilteredByOfficesArray,
  ]);

  const content = useMemo(() => {
    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return <Error />;
    }

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            display={"flex"}
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={10}
          >
            <Loader />
          </Box>
        )}
        <Box overflow={"hidden"} mb={6} height={"100%"}>
          {!!leaderboardData.length && (
            <OfficesLeagueBoard
              data={leaderboardData}
              onItemClick={onItemClick}
            />
          )}
        </Box>
      </>
    );
  }, [loadingStatus, leaderboardData]);

  const handleOfficeGroupsToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsOfficeGroups(event.target.checked);
      closeOfficeStatsDrawer();
    },
    [closeOfficeStatsDrawer]
  );

  const officeLeadsData = useMemo(() => {
    return getLeadsDataByLeaderboardType(
      isOfficeGroups,
      leadsByOfficeDataFilteredByOfficesMap,
      leadsByOfficeGroupDataFilteredByOfficesMap,
      selectedOfficeStats
    );
  }, [
    isOfficeGroups,
    selectedOfficeStats,
    leadsByOfficeGroupDataFilteredByOfficesMap,
    leadsByOfficeDataFilteredByOfficesMap,
  ]);

  return (
    <RightSideLayout>
      <PanelsGroupLayout maxW={"6xl"}>
        <Box display={"flex"} justifyContent={"flex-end"} mb={5} width={"100%"}>
          <HStack spacing={4}>
            {!!accountOfficeGroupsArray.length && (
              <HStack>
                <Switch
                  title={"Toggle Office Groups"}
                  isChecked={isOfficeGroups}
                  onChange={handleOfficeGroupsToggle}
                />

                <Box>Filter by office groups</Box>
              </HStack>
            )}
            <Box shadow={"sm"} rounded={"md"}>
              <DateRangePickerInput
                startDate={startDate}
                endDate={endDate}
                onDatesChange={dateRange => changeDateRange(dateRange)}
                maxDate={TODAY}
              />
            </Box>
          </HStack>
        </Box>
        {content}
      </PanelsGroupLayout>
      <RelativeDrawer isOpen={!!selectedOfficeStats} width={"600px"}>
        <StandardDrawerHeader>
          <StandardDrawerTitle>
            {selectedOfficeStats?.office.name}
          </StandardDrawerTitle>
          <StandardDrawerClose onClose={closeOfficeStatsDrawer} />
        </StandardDrawerHeader>
        <StandardDrawerBody>
          {!!selectedOfficeStats && (
            <OfficeLeagueStats
              officeStats={selectedOfficeStats}
              officeLeadsData={officeLeadsData}
            />
          )}
        </StandardDrawerBody>
      </RelativeDrawer>
    </RightSideLayout>
  );
});
