import React from "react";
import {
  Box,
  Heading,
  Image,
  OrderedList,
  Text,
  Link,
  UnorderedList,
} from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { EmbedTypes } from "enums/contentful-type.enum";
import ContentfulTable from "./ContentfulTable";
import Embed from "./Embed";
import { Document as RichTextDocument } from "@contentful/rich-text-types";
import ContentfulCodeBlock from "./ContentfulCodeBlock";

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      return (
        <Link color="blue.500" href={data.uri} isExternal>
          {children}
        </Link>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return (
        <Text mt={4} fontSize="base">
          {children}
        </Text>
      );
    },
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <Heading as="h2" mt={4} fontSize={["xl", "xl"]} fontWeight={600} mb={2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <Heading as="h3" fontSize={["lg", "2xl"]} fontWeight={600} mb={4}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <Heading as="h4" fontSize={["lg", "2xl"]} fontWeight={600} mb={4} mt={6}>
        {children}
      </Heading>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const file = node.data.target.fields.file;

      if (file.contentType.includes(EmbedTypes.image)) {
        return (
          <Box my={5}>
            <Image src={node.data.target.fields.file.url} />
          </Box>
        );
      }

      if (file.contentType.includes(EmbedTypes.video)) {
        return <Embed node={node} />;
      }

      return null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      if (node.data.target.fields.codeBlock) {
        const blockData =
          node.data.target.fields.codeBlock.content[0].content[0].value;
        return <ContentfulCodeBlock data={blockData}></ContentfulCodeBlock>;
      }

      if (node.data.target.fields.table) {
        const tableData = node.data.target.fields.table.tableData;
        return <ContentfulTable data={tableData} />;
      }

      return null;
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => {
      return (
        <Box py={2} px={8}>
          <OrderedList>{children}</OrderedList>
        </Box>
      );
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <Box py={2} px={8}>
          <UnorderedList>{children}</UnorderedList>
        </Box>
      );
    },
  },
};

interface IRichTextProps {
  data: RichTextDocument;
}

export const RichText: React.FC<IRichTextProps> = ({ data }) => {
  return <Box>{documentToReactComponents(data, options)}</Box>;
};
