import React, { FC } from "react";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { InstantValuationToolPages } from "./components/InstantValuationToolPages";
import { InstantValuationToolSplashScreen } from "./components/InstantValuationToolSplashScreen/InstantValuationToolSplashScreen";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { Route, Switch } from "react-router-dom";
import {
  TOOLS_IVT_PAGE_DETAILS_ROUTE,
  TOOLS_IVT_ROUTE,
} from "constants/routes";
import { InstantValuationToolPageSettings } from "./components/InstantValuationToolPageSettings/InstantValuationToolPageSettings";

interface IProps {
  accountStore: UserAccountStore;
}

export const InstantValuationTool: FC<IProps> = observer(({ accountStore }) => {
  return (
    <PremiumFeatureBlock
      productId={ApplicationProductEnum.INSTANT_VALUATION_TOOL}
      render={isEnabled => (
        <Switch>
          <Route path={TOOLS_IVT_ROUTE} exact>
            <InstantValuationToolPages
              accountStore={accountStore}
              isEnabled={isEnabled}
            />
          </Route>
          <Route path={TOOLS_IVT_PAGE_DETAILS_ROUTE} exact>
            <InstantValuationToolPageSettings accountStore={accountStore} />
          </Route>
        </Switch>
      )}
      alternativeRender={() => (
        <InstantValuationToolSplashScreen accountStore={accountStore} />
      )}
    />
  );
});
