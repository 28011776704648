import { AchievementBadge } from "./AchievementBadge";
import { Box, Divider, HStack } from "@chakra-ui/react";
import React, { FC } from "react";
import { AchievementsConfigItem } from "./achievements-config";
import { Card } from "components/stats/Card";

export const AchievementsItem: FC<AchievementsConfigItem> = ({
  threshold,
  badge,
  text,
}) => (
  <Card mb={4}>
    <HStack spacing={2} p={5}>
      <AchievementBadge threshold={threshold} image={badge} completed />
      <Divider orientation="vertical" height={70} />
      <Box fontWeight={700}>{text}</Box>
    </HStack>
  </Card>
);
