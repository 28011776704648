export enum LeadsTableColumns {
  DATE = "DATE",
  LEAD_STATUS = "LEAD_STATUS",
  PERSON = "PERSON",
  LEAD_SOURCE = "LEAD_SOURCE",
  JOURNEY = "JOURNEY",
  CAMPAIGN = "CAMPAIGN",
  LEAD_TYPE = "LEAD_TYPE",
  SUBJECT = "SUBJECT",
  ASSIGNED_TO = "ASSIGNED_TO",
  OFFICE = "OFFICE",
  NEXT_ACTION_DATE = "NEXT_ACTION_DATE",
}
