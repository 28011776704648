import { ApiMessageStack, TableV2 } from "components";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { UserFormPrompt } from "routes/dashboard/components/prompts/UserFormPrompt/UserFormPrompt";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { accountUsersTableColumnDef } from "./accountUsersTableColumnDef";
import { TAccountUsersTableData } from "store/UserAccounts/UserAccount/AccountUsers.store";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountUsersList: FC<IProps> = observer(({ accountStore }) => {
  const toast = useToast();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const { setModal, unSetModal } = useActionPrompt();
  const {
    authStore: { authUser },
  } = useAppStore();
  const accountUsersStore = accountStore.accountUsersStore;
  const accountUsersTableData = accountUsersStore.accountUsersTableData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await accountUsersStore.fetchAccountUsers();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [accountUsersStore]);

  const handleResendUserInvite = useCallback(
    (userId: number) => async () => {
      try {
        await accountUsersStore.resendUserInvite(userId);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Invite sent!",
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [accountUsersStore, toast]
  );

  const handleRemoveUserFromAccount = useCallback(
    (userId: number) => () => {
      setModal(
        <ConfirmActionPrompt
          text={"Are you sure you want to remove this user?"}
          onConfirm={async () =>
            await accountUsersStore.removeUserFromAccount(userId)
          }
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, accountUsersStore]
  );

  const handleOpenEditUserModal = useCallback(
    (userId: number) => async () => {
      try {
        await accountUsersStore.fetchAccountUser(userId);
        setModal(
          <UserFormPrompt
            accountStore={accountStore}
            existingUserId={userId}
            closePrompt={unSetModal}
          />
        );
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [accountUsersStore, setModal, unSetModal, toast, accountStore]
  );

  const columnsDef = useMemo(() => {
    return accountUsersTableColumnDef(
      authUser?.id || null,
      handleOpenEditUserModal,
      handleRemoveUserFromAccount,
      handleResendUserInvite
    );
  }, [
    authUser,
    handleOpenEditUserModal,
    handleRemoveUserFromAccount,
    handleResendUserInvite,
  ]);

  return (
    <Box>
      <TableV2<TAccountUsersTableData>
        loadingStatus={loadingStatus}
        globalFilterInputPlaceholder={"Search users by name or email"}
        dataSource={accountUsersTableData}
        columns={columnsDef}
        pageSize={DEFAULT_PAGE_SIZE}
        striped={true}
      />
    </Box>
  );
});
