import { makeAutoObservable, IObservableArray, observable } from "mobx";
import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TUserAccountData } from "types/user-account.type";

export class AccountEmailNotificationsLocalStore {
  private accountStore: UserAccountStore;
  private accountData: TUserAccountData;
  currentEmailNotificationPreferences: AccountEmailNotificationPreferenceEnum;
  currentIsAssigneeEmailNotificationsEnabled: AccountEmailNotificationPreferenceEnum;
  currentEmailsList: IObservableArray<string>;
  isSubmitting = false;

  constructor(accountStore: UserAccountStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountStore = accountStore;
    this.accountData = this.accountStore.account;
    this.currentEmailNotificationPreferences = this.accountData.emailNotificationPreferences;
    this.currentIsAssigneeEmailNotificationsEnabled = this.enumValueForAssigneeEmailNotifications;
    this.currentEmailsList = observable.array<string>(
      this.accountStore.accountContactEmails
    );
  }

  get enumValueForAssigneeEmailNotifications(): AccountEmailNotificationPreferenceEnum {
    return this.accountData.isAssigneeEmailNotificationsEnabled
      ? AccountEmailNotificationPreferenceEnum.EMAILS_ON
      : AccountEmailNotificationPreferenceEnum.EMAILS_OFF;
  }

  get dirty() {
    return (
      this.currentEmailNotificationPreferences !==
        this.accountData.emailNotificationPreferences ||
      this.currentIsAssigneeEmailNotificationsEnabled !==
        this.enumValueForAssigneeEmailNotifications ||
      this.currentEmailsList.join(",") !==
        this.accountStore.accountContactEmails.join(",")
    );
  }

  public setCurrentEmailNotificationPreferences(
    value: AccountEmailNotificationPreferenceEnum
  ) {
    this.currentEmailNotificationPreferences = value;
  }

  public setCurrentIsAssigneeEmailNotificationsEnabled(
    value: AccountEmailNotificationPreferenceEnum
  ) {
    this.currentIsAssigneeEmailNotificationsEnabled = value;
  }

  public setCurrentEmailsList(emails: string[]) {
    this.currentEmailsList.replace(emails);
  }

  public setIsSubmitting(value: boolean) {
    this.isSubmitting = value;
  }

  public async handleSubmit() {
    await this.accountStore.update({
      emailNotificationPreferences: this.currentEmailNotificationPreferences,
      contactEmails: this.currentEmailsList.length
        ? this.currentEmailsList.join(",")
        : null,
      isAssigneeEmailNotificationsEnabled:
        this.currentIsAssigneeEmailNotificationsEnabled ===
        AccountEmailNotificationPreferenceEnum.EMAILS_ON,
    });
  }

  resetForm() {
    this.currentEmailNotificationPreferences = this.accountData.emailNotificationPreferences;
    this.currentIsAssigneeEmailNotificationsEnabled = this.enumValueForAssigneeEmailNotifications;
    this.currentEmailsList.replace(this.accountStore.accountContactEmails);
  }
}
