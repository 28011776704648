import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Card } from "./Card";

interface IGraphCard {
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
}

export const GraphCard: React.FC<IGraphCard> = ({
  title,
  action,
  children,
}) => {
  return (
    <Card>
      <Box px={6} py={4} borderBottom={"1px solid"} borderColor={"gray.100"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box fontWeight={600} fontSize="md">
            {title}
          </Box>
          {!!action && <Box>{action}</Box>}
        </Flex>
      </Box>
      <Box p={6}>{children}</Box>
    </Card>
  );
};
