import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useEffect, useMemo, useState } from "react";
import { RightSideLayout } from "../../../components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useParams } from "react-router-dom";
import { PropertyDetailsContent } from "./components/PropertyDetailsContent";
import { Error, Loader } from "components";

interface IProps {
  accountStore: UserAccountStore;
}

export const PropertyDetails: FC<IProps> = observer(({ accountStore }) => {
  let { id } = useParams<{ id: string }>();
  const propertyId = useMemo(() => parseInt(id!), [id]);
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const { accountPropertiesStore } = accountStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        // TODO PROPERTIES: uncomment once we have endpoint to fetch property details
        // await accountPropertiesStore.getPropertyWithDetails(propertyId),
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [accountPropertiesStore]);

  return (
    <RightSideLayout
      headerProps={{
        title: "Property Leads",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        overflow={"hidden"}
      >
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <PropertyDetailsContent
            accountStore={accountStore}
            accountPropertiesStore={accountPropertiesStore}
            propertyId={propertyId}
          />
        )}
      </Box>
    </RightSideLayout>
  );
});
