import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import {
  DefaultRowCell,
  UserStatusRowCell,
} from "components/table-v2/row-cells";
import { BasicUserInfo, EmailLink } from "components";
import { UserRoleLabel } from "constants/user-role-label";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faCheck,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { faClipboard } from "@fortawesome/pro-regular-svg-icons";
import { TAccountUsersTableData } from "store/UserAccounts/UserAccount/AccountUsers.store";
import { UserInvitationStatusEnum } from "enums/user-invitation-status.enum";
import React from "react";
import { AuthStoreTypeEnum } from "enums/auth-store-type.enum";
import { ShowForAuthTypes } from "routes/dashboard/components/ShowFor/ShowForAuthTypes";

export const accountUsersTableColumnDef = (
  authUserId: number | null,
  handleOpenEditUserModal: (id: number) => () => void,
  handleRemoveUserFromAccount: (id: number) => () => void,
  handleResendUserInvite: (id: number) => () => void
): ITableV2Column<TAccountUsersTableData>[] => {
  return [
    {
      accessor: "fullName",
      Header: <DefaultHeaderCell>Full name</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <BasicUserInfo user={row.user} hideEmail={true} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "email",
      Header: <DefaultHeaderCell>Email</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <EmailLink email={row.email} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "roleId",
      excludeFromGlobalFilter: true,
      width: "70px",
      Header: <DefaultHeaderCell>Role</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell textTransform={"capitalize"}>
          {UserRoleLabel[row.roleId]}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "sms2FAEnabled",
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Security</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {row.isEcosystemUser && (
            <Tag colorScheme={"yellow"}>
              <TagLeftIcon
                boxSize="12px"
                as={() => <FontAwesomeIcon icon={faClipboard} />}
              />
              <TagLabel ml={2}>Unknown</TagLabel>
            </Tag>
          )}
          {!row.isEcosystemUser && (
            <Tag colorScheme={row.sms2FAEnabled ? "green" : "red"}>
              <TagLeftIcon
                boxSize="12px"
                as={() => (
                  <FontAwesomeIcon
                    icon={row.sms2FAEnabled ? faCheck : faXmark}
                  />
                )}
              />
              <TagLabel ml={2}>SMS 2FA</TagLabel>
            </Tag>
          )}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "userInvitationStatus",
      width: "90px",
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => {
        return (
          <UserStatusRowCell userInvitationStatus={row.userInvitationStatus} />
        );
      },
    },
    {
      accessor: "id",
      width: "30px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell
          justifyContent={"flex-end"}
          onClick={event => event.stopPropagation()}
        >
          {authUserId !== row.id && (
            <Menu isLazy={true} placement={"bottom-end"}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={
                  <FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />
                }
                size="xs"
                variant="ghost"
              />
              <MenuList>
                {row.userInvitationStatus !==
                  UserInvitationStatusEnum.ACTIVE && (
                  <ShowForAuthTypes authTypes={[AuthStoreTypeEnum.ECOSYSTEM]}>
                    <MenuItem onClick={handleResendUserInvite(row.id)}>
                      Re-send invite
                    </MenuItem>
                  </ShowForAuthTypes>
                )}
                <MenuItem onClick={handleOpenEditUserModal(row.id)}>
                  Edit
                </MenuItem>
                <MenuItem onClick={handleRemoveUserFromAccount(row.id)}>
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </DefaultRowCell>
      ),
    },
  ];
};
