import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import React from "react";
import { Box } from "@chakra-ui/react";

const styles = {
  padding: "1rem",
  borderRadius: "15px",
};

interface IContentfulCodeBlockProps {
  data: any;
}

const ContentfulCodeBlock: React.FC<IContentfulCodeBlockProps> = ({ data }) => {
  return (
    <Box my={5}>
      <SyntaxHighlighter
        customStyle={styles}
        language="javascript"
        style={docco}
      >
        {data}
      </SyntaxHighlighter>
    </Box>
  );
};

export default ContentfulCodeBlock;
