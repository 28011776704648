import {
  TAccountWebhookRequestData,
  TAccountWebhookRequestResponse,
} from "types/account-developers.type";
import React, { FC } from "react";
import { DevelopersConfigWebhookRequestItem } from "./DevelopersConfigWebhookRequestItem";
import { observer } from "mobx-react";
import { AccountDevelopersWebhookStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhook.store";
import { DevelopersConfigRequests } from "routes/dashboard/routes/settings/routes/developers-config/components/DevelopersConfigRequests";

interface IProps {
  accountDevelopersWebhookStore: AccountDevelopersWebhookStore;
}

export const DevelopersConfigWebhookRequests: FC<IProps> = observer(
  ({ accountDevelopersWebhookStore }) => {
    return (
      <DevelopersConfigRequests<TAccountWebhookRequestResponse>
        label={"Requests"}
        description={"Here you can see your previous webhook requests."}
        requests={accountDevelopersWebhookStore.webhookRequests}
        loadRequests={accountDevelopersWebhookStore.fetchWebhookRequests}
        resetRequests={accountDevelopersWebhookStore.resetWebhookRequests}
        renderRequest={(request: TAccountWebhookRequestData) => (
          <DevelopersConfigWebhookRequestItem
            key={request.id}
            request={request}
          />
        )}
      />
    );
  }
);
