import { observer } from "mobx-react";
import { QuestionnaireToolPageWIPFormStore } from "../../../../stores/QuestionnaireToolPageWIPForm.store";
import React, { FC, useCallback, useMemo } from "react";
import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { SingleSelectInput } from "components";
import { FormToolPageSettingsActionConfiguratorItem } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionConfiguratorItem";
import { QuestionnaireActionUpdatePostcode } from "@leadpro/forms";
import { isActionParamFieldInError } from "utils/form-tool-page.utils";

interface IProps {
  actionIndex: number;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsUpdatePostcodeParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, questionnaireToolPageWIPFormStore }) => {
    const action = questionnaireToolPageWIPFormStore.wipFormActionsJS[
      actionIndex
    ] as QuestionnaireActionUpdatePostcode;

    const onMappablePropertyChange = useCallback(
      (property: keyof QuestionnaireActionUpdatePostcode["params"]) => (
        questionKey: string | null
      ) => {
        if (!questionKey) return;

        questionnaireToolPageWIPFormStore.updateActionParams(actionIndex, {
          [property]: { mapping: questionKey },
        });
      },
      [actionIndex, questionnaireToolPageWIPFormStore]
    );

    const errors = useMemo((): {
      [key in keyof Omit<
        QuestionnaireActionUpdatePostcode["params"],
        "leadType"
      >]: boolean;
    } => {
      const validationResult =
        questionnaireToolPageWIPFormStore.wipFormActionsValidationResult;

      return {
        postcode: isActionParamFieldInError(
          "postcode",
          actionIndex,
          validationResult
        ),
      };
    }, [
      questionnaireToolPageWIPFormStore.wipFormActionsValidationResult,
      actionIndex,
    ]);

    return (
      <VStack width={"100%"} spacing={2} pt={8}>
        <Box color={"leadpro.500"} width={"100%"} mb={4}>
          <HStack>
            <Box fontWeight={"bold"} fontSize={"sm"} flexShrink={0}>
              Map postcode field
            </Box>
            <Divider flexGrow={1} />
          </HStack>
          <Box fontSize={"xs"}>
            Chose which question you’d like the responses to use to update the
            lead postcode.
          </Box>
        </Box>
        <FormToolPageSettingsActionConfiguratorItem
          label={"Postcode"}
          showError={errors.postcode}
          input={
            <Box width={"100%"} maxWidth={"500px"}>
              <SingleSelectInput<string>
                value={action.params.postcode.mapping}
                onChange={onMappablePropertyChange("postcode")}
                options={
                  questionnaireToolPageWIPFormStore.postcodeQuestionsAsOptions
                }
                placeholder={"select question"}
              />
            </Box>
          }
        />
      </VStack>
    );
  }
);
