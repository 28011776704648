import { BoxProps } from "@chakra-ui/react";
import { Alert } from "../../index";
import React, { useMemo } from "react";
import { FieldError } from "react-hook-form";
import { AlertStatusEnum } from "enums/alert-status.enum";

interface IFormAlertProps extends BoxProps {
  error: FieldError;
}

export const FormControlError: React.FC<IFormAlertProps> = ({
  error,
  ...rest
}) => {
  const messages = useMemo(() => {
    if (Array.isArray(error)) {
      return error.map(errorSegment => errorSegment.message);
    }

    if (typeof error === "object") {
      return [error.message];
    }

    return [];
  }, [error]);

  return (
    <>
      {messages.map((text, index) => {
        if (!!text) {
          return (
            <Alert
              key={index}
              status={AlertStatusEnum.ERROR}
              fontSize={"sm"}
              px={3}
              py={1}
              mt={1}
              mb={1}
              border={"none"}
              rounded={"sm"}
            >
              {text}
            </Alert>
          );
        }

        return null;
      })}
    </>
  );
};
