import React, { useCallback, useMemo } from "react";
import {
  SELECT_INPUT_SIZE_CONFIG,
  SelectInputSizes,
} from "./select-input-size";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR } from "styles/input-default-pseudobox-behaviour";
import {
  Box,
  BoxProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

interface ISelectInputBaseProps extends BoxProps {
  onClose: () => void;
  disabled: boolean;
  size: SelectInputSizes;
  inputValueElement: React.ReactNode;
  selectControlsElement: React.ReactNode;
  popoverContentRenderer: (close: () => void) => React.ReactNode;
  noBorder?: boolean;
  secondary?: boolean;
}

export const SelectInputBase = React.forwardRef<any, ISelectInputBaseProps>(
  (
    {
      onClose,
      disabled,
      size,
      inputValueElement,
      selectControlsElement,
      popoverContentRenderer,
      noBorder,
      secondary,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setOpen] = React.useState(false);
    const open = useCallback(() => setOpen(!isOpen), [setOpen, isOpen]);
    const close = useCallback(() => {
      setOpen(false);
      onClose();
    }, [setOpen, onClose]);
    const cancelMouseDown = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
      },
      []
    );
    const styleProps = useMemo(() => {
      return {
        tabIndex: disabled ? undefined : 0,
        display: "flex",
        borderRadius: noBorder ? "none" : "md",
        border: noBorder ? "none" : "1px solid",
        borderColor: "leadpro.200",
        minHeight: SELECT_INPUT_SIZE_CONFIG[size].height,
        fontSize: SELECT_INPUT_SIZE_CONFIG[size].fontSize,
        cursor: disabled ? undefined : "pointer",
        opacity: disabled ? 0.6 : undefined,
        ...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR,
        bg: secondary ? "leadpro.50" : "white",
      };
    }, [size, disabled, noBorder, secondary]);

    return (
      <Popover
        isOpen={isOpen}
        onClose={close}
        closeOnBlur={true}
        closeOnEsc={true}
        returnFocusOnClose={false}
        isLazy={true}
        matchWidth={true}
        computePositionOnMount={false}
        placement="bottom-start"
        lazyBehavior={"unmount"}
        openDelay={0}
      >
        <PopoverTrigger>
          <Box width={"100%"} height={"100%"}>
            <Box
              {...styleProps}
              {...rest}
              onMouseDown={cancelMouseDown}
              onClick={!disabled ? open : undefined}
              onFocus={!disabled ? open : undefined}
              onKeyDown={undefined}
            >
              {inputValueElement}
              {selectControlsElement}
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent width={"1px"} minWidth={"100%"} maxWidth={"100%"}>
          {popoverContentRenderer(close)}
        </PopoverContent>
      </Popover>
    );
  }
);
