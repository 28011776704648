import { Box, Heading, Tag, Button } from "@chakra-ui/react";
import { Loader, NavigationPrompt, RichText } from "components";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { GUIDES_ROUTE } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Card } from "components/stats/Card";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";

export const ArticlePage = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const {
    guidesStore: { articlesMap, fetchArticle },
  } = useAppStore();

  useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true);
      await fetchArticle(slug);
      setIsLoading(false);
    };

    fetchArticles();
  }, [fetchArticle, slug]);

  if (isLoading)
    return (
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"500px"}
        textAlign={"center"}
      >
        <Loader />
      </Box>
    );

  if (slug && articlesMap[slug]) {
    return (
      <Box>
        <Card mb={8}>
          <Box p={12} width="100%">
            <Tag colorScheme="blue" mb={2}>
              {articlesMap[slug].fields.product &&
                articlesMap[slug].fields.product.fields.name}
            </Tag>
            <Heading fontSize="2xl" mb={6}>
              {articlesMap[slug].fields.title}
            </Heading>
            <RichText data={articlesMap[slug].fields.content} />
          </Box>
        </Card>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          mb={5}
          width={"100%"}
        >
          <Button
            variant={"outline"}
            colorScheme={"blue"}
            onClick={() => history.push(GUIDES_ROUTE)}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <Box ml={2}>Back</Box>
            </Box>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      position={"absolute"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      textAlign={"center"}
    >
      <NavigationPrompt
        text={"Oops, we can not find that article."}
        ctaText={"Browse all guides."}
        ctaNavigateTo={GUIDES_ROUTE}
      />
    </Box>
  );
});
