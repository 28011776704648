import { Box, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import React from "react";

interface INavigationPromptProps {
  text: string;
  ctaText: string;
  ctaNavigateTo: string;
  asButton?: boolean;
}

export const NavigationPrompt: React.FC<INavigationPromptProps> = ({
  text,
  ctaText,
  ctaNavigateTo,
  asButton,
}) => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Box mb={2}>{text}</Box>
      <NavLink exact={true} to={ctaNavigateTo}>
        {asButton ? (
          <Button colorScheme={"blue"}>{ctaText}</Button>
        ) : (
          <Box
            color="gray.600"
            mb={4}
            mt={2}
            fontSize="sm"
            _hover={{ textDecoration: "underline" }}
          >
            {ctaText}
          </Box>
        )}
      </NavLink>
    </Box>
  );
};
