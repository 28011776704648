import React, { FC, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useToast } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ActionPromptContainer, ApiMessageStack } from "components";
import { LeadResponderWorkflowEditorPromptHeader } from "./LeadResponderWorkflowEditorPromptHeader";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { LeadResponderWorkflowEditor } from "./LeadResponderWorkflowEditor/LeadResponderWorkflowEditor";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { getWorkflowDataValidationErrors } from "utils/lead-responder-workflow.utils";

const DEFAULT_WIP_WORKFLOW_DATA: Partial<TLeadResponderWorkflow> = {
  name: "Untitled",
  active: true,
};

interface IProps {
  workflow?: TLeadResponderWorkflow;
  accountStore: UserAccountStore;
  closePrompt: () => void;
}

export const LeadResponderWorkflowEditorPrompt: FC<IProps> = observer(
  ({ workflow, accountStore, closePrompt }) => {
    const toast = useToast();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [isSubmittingWorkflow, setIsSubmittingWorkflow] = useState(false);
    const [wipWorkflow, setWipWorkflow] = useState({
      ...DEFAULT_WIP_WORKFLOW_DATA,
    });

    const leadResponderStore = accountStore.leadResponderStore;

    useEffect(() => {
      if (!!workflow) {
        setWipWorkflow({ ...workflow });
      }
    }, [workflow, setWipWorkflow]);

    useEffect(() => {
      const setupData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          if (!!workflow?.id) {
            await leadResponderStore.loadWorkflow(workflow.id);
          }
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
          closePrompt();
        }
      };

      setupData();
    }, [
      workflow?.id,
      leadResponderStore,
      setLoadingStatus,
      closePrompt,
      toast,
    ]);

    const handleSave = useCallback(async () => {
      const errorMessages = getWorkflowDataValidationErrors(wipWorkflow);

      if (!!errorMessages.length) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          title: "Workflow state is invalid.",
          description: <ApiMessageStack messageStack={errorMessages} />,
        });
        return;
      }

      try {
        setIsSubmittingWorkflow(true);
        await leadResponderStore.persistWorkflow(wipWorkflow);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={`Workflow has been ${
                wipWorkflow.id ? "updated" : "created"
              }.`}
            />
          ),
        });
        setIsSubmittingWorkflow(false);
        closePrompt();
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
        setIsSubmittingWorkflow(false);
      }
    }, [toast, wipWorkflow, leadResponderStore, closePrompt]);

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        contentStyle={{
          height: "100%",
          width: "100%",
          margin: 0,
          rounded: "none",
        }}
        bodyStyle={{
          padding: 0,
        }}
        header={
          loadingStatus === ApiRequestStatusEnum.SUCCESS && (
            <LeadResponderWorkflowEditorPromptHeader
              wipWorkflow={wipWorkflow}
              updateWipWorkflow={setWipWorkflow}
              handleSave={handleSave}
              isSubmittingWorkflow={isSubmittingWorkflow}
            />
          )
        }
        body={
          <LeadResponderWorkflowEditor
            wipWorkflow={wipWorkflow}
            accountStore={accountStore}
            updateWipWorkflow={setWipWorkflow}
          />
        }
      />
    );
  }
);
