import {
  ChildLeadType,
  FormSchema,
  QuestionnaireActionType,
} from "@leadpro/forms";
import { TPageData } from "types/page.type";
import { TQuestionnaireToolSettingsFieldValues } from "./validation-schemas/page-settings.validation";
import { ChildLeadOfficeRoutingType } from "@leadpro/forms/dist/types/actions/questionnaire/questionnaire-action-create-child-lead";

export const generateQuestionnaireToolPageDefaultFormSchema = (): FormSchema => {
  return {
    fields: {},
    order: [],
    version: "1.0",
    logic: [],
  };
};

export const generateQuestionnaireToolDefaultPageData = (
  page?: TPageData
): TQuestionnaireToolSettingsFieldValues => {
  return {
    name: page?.name || "",
    host: page?.host || "",
    color: page?.color || "",
    facebookPixelId: page?.facebookPixelId || "",
    googleAnalyticsId: page?.googleAnalyticsId || "",
    googleAdsConversionId: page?.googleAdsConversionId || "",
    googleAdsConversionLabelId: page?.googleAdsConversionLabelId || "",
    privacyPolicyLink: page?.privacyPolicyLink || "",
    gdprCheckboxEnabled: page?.gdprCheckboxEnabled || false,
    gdprCheckboxLabel: page?.gdprCheckboxLabel || "",
    gdprCheckboxDescription: page?.gdprCheckboxDescription || "",
    gdprCheckboxAcceptanceRequired:
      page?.gdprCheckboxAcceptanceRequired || false,
    photoFile: null,
    customLogoFile: null,
    active: !!page ? page.pageQuestionnaireConfig.active : true,
    isSlideshowModeEnabled: !!page
      ? page.pageQuestionnaireConfig.isSlideshowModeEnabled
      : false,
    leadFilterId: page?.pageQuestionnaireConfig.leadFilterId || null,
    saleQuestionnaireId:
      page?.pageQuestionnaireConfig.saleQuestionnaireId || null,
    letQuestionnaireId:
      page?.pageQuestionnaireConfig.letQuestionnaireId || null,
    vendorQuestionnaireId:
      page?.pageQuestionnaireConfig.vendorQuestionnaireId || null,
    landlordQuestionnaireId:
      page?.pageQuestionnaireConfig.landlordQuestionnaireId || null,
    mortgageRequestQuestionnaireId:
      page?.pageQuestionnaireConfig.mortgageRequestQuestionnaireId || null,
    customThankYouPageHeader:
      page?.pageQuestionnaireConfig.customThankYouPageHeader || "",
    customThankYouPageBody:
      page?.pageQuestionnaireConfig.customThankYouPageBody || "",
    customThankYouPageCtaLabel:
      page?.pageQuestionnaireConfig.customThankYouPageCtaLabel || "",
    customThankYouPageCtaUrl:
      page?.pageQuestionnaireConfig.customThankYouPageCtaUrl || "",
  };
};

export const generateDefaultUpdatePostcodeQuestionnaireActionParams = () => {
  return {
    postcode: { mapping: "" },
  };
};

export const generateDefaultCreateChildLeadQuestionnaireActionParams = (
  routingType: ChildLeadOfficeRoutingType = ChildLeadOfficeRoutingType.ParentOffice,
  leadType: ChildLeadType = ChildLeadType.Vendor
) => {
  return {
    leadType: { value: leadType },
    routingType: {
      value: routingType,
    },
  };
};

export const generateQuestionnaireActionStub: any = (
  actionType: QuestionnaireActionType
) => {
  const actionStub: any = {
    type: actionType,
  };

  if (!!defaultActionParamsGenerator[actionType]) {
    actionStub.params = defaultActionParamsGenerator[actionType]?.();
  }

  return actionStub;
};

const defaultActionParamsGenerator: Partial<Record<
  QuestionnaireActionType,
  () => any
>> = {
  [QuestionnaireActionType.CREATE_CHILD_LEAD]: generateDefaultCreateChildLeadQuestionnaireActionParams,
  [QuestionnaireActionType.UPDATE_POSTCODE]: generateDefaultUpdatePostcodeQuestionnaireActionParams,
};
