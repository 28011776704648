import { ReactChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface NavButtonProps extends BoxProps {
  id?: string;
  children: ReactChildren | string;
}

export const NavButton = ({ id, children, ...rest }: NavButtonProps) => {
  return (
    <Box
      as="button"
      id={id}
      width={"full"}
      color="leadpro.600"
      fontSize={"sm"}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      minHeight={"56px"}
      borderTop={"1px solid"}
      borderColor={"leadpro.200"}
      _hover={{ bg: "blue.50" }}
      _active={{ bg: "blue.100" }}
      {...rest}
    >
      {children}
    </Box>
  );
};
