import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { LeadStatusTag } from "components";
import { TLeadStatusBasic } from "types/lead-status.type";

interface ILeadStatusCellProps {
  status: TLeadStatusBasic;
}

export const LeadStatusCell: FC<ILeadStatusCellProps> = ({ status }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      <LeadStatusTag status={status} />
    </Box>
  );
};
