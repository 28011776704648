import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TAccountOfficeData } from "types/account-office.type";
import { AccountOfficeStore } from "./AccountOffice.store";
import { keyBy, sortBy } from "lodash";
import { AppStore } from "store/App.store";
import { TAccountSubentityIntegrationsConfig } from "types/account-integration.type";
import { officeBasicValidationSchema } from "utils/validation-schemas/office-basic.validation";
import { AccountOfficesApi } from "api/account-offices.api";
import { addPrefixToId } from "utils/office-group.utils";
import { OfficeCategory } from "enums/office-type.enum";
import { TSelectOption } from "types/select-input.type";
import { IAccountOfficePortalActivity } from "types/account-portal-link-status-per-office.type";

const officesApi = new AccountOfficesApi();

export class AccountOfficesStore {
  private readonly root: AppStore;
  public readonly accountId: number;
  private readonly accountOfficesPortalActivity: IObservableArray<
    IAccountOfficePortalActivity
  >;
  public accountOfficeStores: IObservableArray<AccountOfficeStore>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountOfficesPortalActivity = observable.array<
      IAccountOfficePortalActivity
    >();
    this.accountOfficeStores = observable.array<AccountOfficeStore>();
  }

  get accountOfficesPortalActivityArray() {
    return this.accountOfficesPortalActivity.slice();
  }

  get problematicOfficeActivity() {
    return this.accountOfficesPortalActivity.filter(officeActivity => {
      return !!officeActivity.problematicTypes.length;
    });
  }

  get accountOfficeStoresArray() {
    return this.accountOfficeStores.slice();
  }

  get accountOfficeStoresMap() {
    return keyBy(
      this.accountOfficeStores,
      officeStore => officeStore.office.id
    );
  }

  get accountOfficesDataArray() {
    return this.accountOfficeStores.map(
      accountOfficeStore => accountOfficeStore.office
    );
  }

  get accountOfficesDataMap() {
    return keyBy(this.accountOfficesDataArray, office => office.id);
  }

  get accountOfficesOptionsArray(): TSelectOption<number>[] {
    return sortBy(this.accountOfficesDataArray, office =>
      office.name.toLowerCase()
    ).map(office => ({
      value: office.id,
      label: office.name,
    }));
  }

  get accountOfficeOptionsWithPrefix() {
    return sortBy(this.accountOfficesDataArray, office =>
      office.name.toLowerCase()
    ).map(office => {
      return {
        value: addPrefixToId(office.id, OfficeCategory.OFFICE),
        label: office.name,
      };
    });
  }

  get areExistingOfficesValid() {
    return this.accountOfficeStores.every(officeStore =>
      officeBasicValidationSchema.isValidSync({
        ...officeStore.office,
        postcodes: officeStore.office.postcodes?.split(","),
        salesEmails: officeStore.office.salesEmails?.split(","),
        lettingsEmails: officeStore.office.lettingsEmails?.split(","),
        vendorEmails: officeStore.office.vendorEmails?.split(","),
        landlordEmails: officeStore.office.landlordEmails?.split(","),
      })
    );
  }

  private setOffices(offices: TAccountOfficeData[]) {
    this.accountOfficeStores.replace(
      offices.map(office => new AccountOfficeStore(office))
    );
  }

  private removeOffice(officeId: number) {
    const index = this.accountOfficeStores.findIndex(
      officeStore => officeStore.office.id === officeId
    );
    if (index > -1) {
      this.accountOfficeStores.splice(index, 1);
    }
  }

  private addOffice(office: TAccountOfficeData) {
    this.accountOfficeStores.push(new AccountOfficeStore(office));
  }

  public setAccountOfficesPortalActivity(
    activity: IAccountOfficePortalActivity[]
  ) {
    this.accountOfficesPortalActivity.replace(activity);
  }

  public suggestOfficeIdFromPostcode(
    queriedPostcode: string | null
  ): TAccountOfficeData["id"] | undefined {
    if (!queriedPostcode) return;

    const matchedOffice = this.accountOfficeStores.filter(officeStore => {
      return officeStore.officePostcodesArray.find(postcode =>
        postcode.toLowerCase().includes(queriedPostcode.toLowerCase())
      );
    });
    if (matchedOffice.length) return matchedOffice[0].office.id;
  }

  public async fetchAccountOfficesPortalActivity() {
    const officePortalActivities = await officesApi.fetchAccountOfficesPortalActivity(
      this.accountId
    );

    this.setAccountOfficesPortalActivity(officePortalActivities);
  }

  public async fetchAccountOffices() {
    const offices = await officesApi.fetchAccountOffices(this.accountId);
    this.setOffices(offices);
  }

  public async createAccountOffice(
    officeData: {
      name: string;
      website: string | null;
      accountId: number;
      isSales: boolean;
      isLettings: boolean;
      isMortgage: boolean;
      postcodes: string;
      salesEmails: string;
      lettingsEmails: string;
      vendorEmails: string;
      landlordEmails: string;
      salesPhoneNumber: string;
      lettingsPhoneNumber: string;
      vendorPhoneNumber: string;
      landlordPhoneNumber: string;
      mortgagePhoneNumber: string;
      officeGroupIds: number[];
    },
    integrations: TAccountSubentityIntegrationsConfig = {}
  ) {
    const office = await officesApi.createAccountOffice(
      this.accountId,
      officeData
    );
    this.addOffice(office);

    // reapit integration
    await this.trySyncAccountOfficeWithReapit(office.id, integrations.reapitId);
  }

  public async deleteOffice(officeId: number) {
    await officesApi.removeOfficeFromAccount(this.accountId, officeId);
    this.removeOffice(officeId);
  }

  public async fetchAccountOffice(officeId: number) {
    if (!!this.accountOfficeStoresMap[officeId]) {
      await this.accountOfficeStoresMap[officeId].fetchDetails();
    } else {
      const officeData = await officesApi.fetchAccountOffice(
        this.accountId,
        officeId
      );
      this.addOffice(officeData);
    }
  }

  public async trySyncAccountOfficeWithReapit(
    officeId: number,
    reapitId: TAccountSubentityIntegrationsConfig["reapitId"]
  ) {
    // reapit integration
    const userAccountsStore = this.root.userAccountsStore;
    const userAccountStore = userAccountsStore.userAccountsMap[this.accountId];
    const hasReapitIntegration =
      userAccountStore.accountIntegrationsStore.hasActiveReapitIntegration;
    if (hasReapitIntegration && reapitId !== undefined) {
      const reapitIntegrationOfficesStore =
        userAccountStore.accountIntegrationsStore.accountIntegrationReapitStore
          .accountIntegrationReapitOfficesStore;

      await reapitIntegrationOfficesStore.updateSyncedAccountOffices([
        {
          officeId: officeId,
          branchId: reapitId,
        },
      ]);
    }
  }
}
