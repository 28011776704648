import { TSelectOption, TSelectOptionValue } from "types/select-input.type";

export const filterOptionsByLabel = <T extends TSelectOptionValue>(
  options: TSelectOption<T>[],
  searchTerm: string
) => {
  return options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
};
