import React, { FC } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

export const Bullet: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box display={"flex"} alignItems={"center"} lineHeight={"none"} {...rest}>
      <Box mr={2} fontSize="xl" color="green.500">
        <FontAwesomeIcon icon={faCircleCheck} />
      </Box>
      <Text>{children}</Text>
    </Box>
  );
};
