import React from "react";
import { Text } from "@chakra-ui/react";

export const ConversionRateTooltip = () => {
  return (
    <Text mb={2} textAlign={"justify"}>
      This displays a percentage of how many leads you convert to business! As
      it's a percentage not a point total, it allows for fair comparison between
      high and low volume offices.
    </Text>
  );
};
