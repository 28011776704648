import * as Yup from "yup";
import {
  TWO_FACTOR_AUTH_CODE_LENGTH,
  TWO_FACTOR_AUTH_CODE_REQUIRED_TO_ACCESS,
} from "constants/validator-messages";

export const twoFactorAuthCodeValidationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .length(6, TWO_FACTOR_AUTH_CODE_LENGTH)
    .required(TWO_FACTOR_AUTH_CODE_REQUIRED_TO_ACCESS),
});
