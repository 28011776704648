import { Box, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import React, { useCallback, useMemo } from "react";
import {
  ITableV2Column,
  ITableV2SortBy,
  TableV2SortByDirection,
} from "./table-v2";

interface IHeaderStubProps<T> {
  column: ITableV2Column<T>;
  sortByData: ITableV2SortBy<T> | null;
  setSortByData: (sortByData: ITableV2SortBy<T> | null) => void;
}

export function HeaderStub<T>({
  column,
  sortByData,
  setSortByData,
}: IHeaderStubProps<T>) {
  const isSortable = useMemo(() => column.disableSortBy !== true, [column]);
  const isCurrentlyActiveSort = useMemo(
    () => sortByData && column.accessor === sortByData.field,
    [sortByData, column]
  );

  const toggleSortBy = useCallback(
    (column: ITableV2Column<T>) => {
      if (column.disableSortBy === true) return;

      if (!sortByData || sortByData.field !== column.accessor) {
        setSortByData({
          field: column.accessor,
          direction: TableV2SortByDirection.ASC,
        });
        return;
      }

      if (sortByData.direction === TableV2SortByDirection.DESC) {
        setSortByData(null);
      } else {
        setSortByData({
          ...sortByData,
          direction: TableV2SortByDirection.DESC,
        });
      }
    },
    [sortByData, setSortByData]
  );

  return (
    <HStack
      spacing={1}
      onClick={isSortable ? () => toggleSortBy(column) : undefined}
      cursor={isSortable ? "pointer" : "default"}
    >
      {column.Header}
      {isCurrentlyActiveSort && (
        <Box color={"gray.400"}>
          {sortByData?.direction === TableV2SortByDirection.ASC ? (
            <FontAwesomeIcon icon={faArrowUp} />
          ) : (
            <FontAwesomeIcon icon={faArrowDown} />
          )}
        </Box>
      )}
    </HStack>
  );
}
