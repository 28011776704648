import { Box, Text } from "@chakra-ui/react";
import { FormControlMeta, FormControlV2, InternalLink } from "components";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { TPageData } from "types/page.type";
import { Control } from "react-hook-form";
import React, { FC } from "react";
import { TInstantValuationToolSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { GUIDES_ROUTE } from "constants/routes";
import { PageToolPageSettingsInputLayout } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageSettingsInputLayout";

type TFieldValues = TInstantValuationToolSettingsFieldValues;

const PRESET_VALUATION_TYPE_OPTIONS = [
  {
    value: null,
    label: "Sales & lettings valuation",
  },
  {
    value: LeadTypeEnum.Landlord,
    label: "Lettings valuation only",
  },
  {
    value: LeadTypeEnum.Vendor,
    label: "Sales valuation only",
  },
];

interface IProps {
  page?: TPageData;
  control: Control<TFieldValues>;
}

export const InstantValuationToolBasicSettings: FC<IProps> = ({
  page,
  control,
}) => {
  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        Advanced
      </Text>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Valuation types"
          description={
            "Select the types of valuation to show on the results page."
          }
        />
        <FormControlV2<TFieldValues>
          name={"presetValuationType"}
          control={control}
          type={FormControlsTypeEnum.RADIO}
          additionalProps={{
            options: PRESET_VALUATION_TYPE_OPTIONS,
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Show 'When do you plan to move?' question"
          description={
            "Enable this if you want the lead to specify period in which they would like to move."
          }
        />
        <FormControlV2<TFieldValues>
          name={"etaEnabled"}
          control={control}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Show 'Bathrooms' and 'Receptions' questions"
          description={
            "Enable these fields to capture additional details about the number of rooms within your prospect's valuation property."
          }
        />
        <FormControlV2<TFieldValues>
          name={"showBathroomsAndReceptions"}
          control={control}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Enable Incomplete Valuation Leads Emails"
          description={
            <Box as={"span"}>
              Enable this if you want to receive email notifications containing
              the addresses of the incomplete valuation leads who have opted out
              before submitting their contact details.
              <br />
              <InternalLink
                href={`${GUIDES_ROUTE}/incomplete-valuation-leads-notifications-setup`}
              >
                Here's how to set those emails up.
              </InternalLink>
            </Box>
          }
        />
        <FormControlV2<TFieldValues>
          name={"incompleteValuationLeadEmailsEnabled"}
          control={control}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Allow customer to download valuation report"
          description={
            "If enabled, customer will have the ability to download a PDF copy of instant valuation upon completion."
          }
        />
        <FormControlV2<TFieldValues>
          name={"reportDownloadEnabled"}
          control={control}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
    </Box>
  );
};
