import { FormControlV2 } from "components";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { LOGIN_ROUTE } from "constants/routes";
import { useRecaptchaV2 } from "utils/react-hooks/useRecaptchaV2.hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  email: string;
};

const initialValues: TFieldValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
});

export const PasswordResetForm = observer(() => {
  const { reCaptchaV2 } = useRecaptchaV2();
  const {
    authStore: {
      leadProAuthStore: { resetPassword },
    },
  } = useAppStore();
  const [resetPasswordStatus, setResetPasswordStatus] = useState<
    ApiRequestStatusEnum
  >(ApiRequestStatusEnum.NONE);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({ email }: TFieldValues) => {
      try {
        if (!!reCaptchaV2) {
          const reCaptchaToken = (await reCaptchaV2.executeAsync()) || "";
          reCaptchaV2.reset();

          setResetPasswordStatus(ApiRequestStatusEnum.PENDING);
          await resetPassword(email, reCaptchaToken);
          setResetPasswordStatus(ApiRequestStatusEnum.SUCCESS);
        }
      } catch (e) {
        setResetPasswordStatus(ApiRequestStatusEnum.ERROR);
      }
    },
    [reCaptchaV2, setResetPasswordStatus, resetPassword]
  );

  const renderPasswordResetSuccess = useCallback(() => {
    return (
      <Box>
        <Heading fontSize="2xl" textAlign="center" lineHeight="none" mb={6}>
          Password reset
        </Heading>
        <Text mb={4}>
          An email with instructions on how to reset your password has been sent
          to {getValues("email")}. Check your spam or junk folder if you don’t
          see the email in your inbox.
        </Text>

        <Text>
          If you no longer have access to this email account, please contact us.
        </Text>
      </Box>
    );
  }, [getValues]);

  const renderPasswordResetPending = useCallback(
    () => (
      <>
        <Heading fontSize="2xl" textAlign="center" lineHeight="none" mb={6}>
          Reset your password
        </Heading>
        <Box mb={3}>
          <FormControlV2<TFieldValues>
            name={"email"}
            control={control}
            type={FormControlsTypeEnum.TEXT}
            additionalProps={{
              placeholder: "Email address",
            }}
          />
        </Box>
        <Button
          type={"submit"}
          width={"full"}
          colorScheme={"blue"}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Reset password
        </Button>
      </>
    ),
    [control, isSubmitting]
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {resetPasswordStatus === ApiRequestStatusEnum.SUCCESS
          ? renderPasswordResetSuccess()
          : renderPasswordResetPending()}
      </form>
      <Box mt={3}>
        <NavLink
          to={LOGIN_ROUTE}
          style={{ textAlign: "center", display: "block" }}
        >
          <Button width={"full"} variant="outline" colorScheme={"blue"}>
            Back to login
          </Button>
        </NavLink>
      </Box>
    </Box>
  );
});
