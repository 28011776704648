import { FC } from "react";
import ReactJson from "react-json-view";

interface IJSONViewerProps {
  json: object;
}

export const JSONViewer: FC<IJSONViewerProps> = ({ json }) => {
  return (
    <ReactJson
      name={false}
      iconStyle={"square"}
      displayDataTypes={false}
      src={json}
    />
  );
};
