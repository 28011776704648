import { array, object, string, lazy, mixed } from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import {
  PersistentFilterExpressionOperator,
  PersistentFilterExpressionSchemaType,
  PersistentFilterGroupOperator,
} from "enums/persistent-filter.enum";
import { TFilterExpression, TFilterGroup } from "types/persistent-filters.type";
import * as Yup from "yup";

const persistentFilterExpressionValidationSchema = object().shape({
  property: string().required(REQUIRED_FIELD),
  path: Yup.mixed().when("property", {
    is: (property: string) =>
      property === PersistentFilterExpressionSchemaType.META,
    then: string().required(REQUIRED_FIELD),
  }),
  operator: mixed<PersistentFilterExpressionOperator>().oneOf(
    Object.values(PersistentFilterExpressionOperator)
  ),
  value: mixed().required(REQUIRED_FIELD),
});

const alternatives: any = mixed().test({
  name: "child-test",
  test: (value: TFilterGroup | TFilterExpression) => {
    if (value.hasOwnProperty("children")) {
      return lazy(() =>
        persistentFilterGroupValidationSchema.default(undefined)
      ).isValidSync(value);
    }

    return persistentFilterExpressionValidationSchema.isValidSync(value);
  },
});

const persistentFilterGroupValidationSchema = object().shape({
  operator: mixed<PersistentFilterGroupOperator>().oneOf(
    Object.values(PersistentFilterGroupOperator)
  ),
  children: array()
    .of(alternatives)
    .min(1),
});

export const persistentFilterValidationSchema = object().shape({
  name: string().required(REQUIRED_FIELD),
  group: persistentFilterGroupValidationSchema.required(REQUIRED_FIELD),
});
