import React, { useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { GUIDES_ROUTE } from "constants/routes";
import { InternalLink } from "components";

interface IProps {
  closePrompt: () => void;
}

export const ConnectZapierForm: React.FC<IProps> = ({ closePrompt }) => {
  const handleClick = useCallback(() => closePrompt(), [closePrompt]);

  return (
    <Box py={5} color={"gray.400"}>
      <Box>
        <span>{`To learn how to integrate with Zapier, please `}</span>
        <InternalLink
          href={`${GUIDES_ROUTE}/zapier-integration`}
          display={"inline"}
          onClick={handleClick}
        >
          read this guide.
        </InternalLink>
      </Box>
    </Box>
  );
};
