import React, { FC } from "react";
import { Box, Center, HStack, VStack } from "@chakra-ui/react";
import { ExternalLink } from "components";

const FONT_COLOR = "gray.600";
const FONT_SIZE = "sm";
const STEPS = [
  <Box>
    Install the LeadPro app in the{" "}
    {
      <ExternalLink
        href={
          "https://marketplace.reapit.cloud/apps/bda6bbed-9ed1-4984-bb24-e302e28a613b"
        }
        label={"Reapit Foundations marketplace"}
      />
    }
  </Box>,
  "Schedule a Reapit integration set up call with your LeadPro account manager.",
];

interface IProps {
  closePrompt: () => void;
}

export const ConnectReapitForm: FC<IProps> = () => {
  return (
    <VStack
      spacing={4}
      py={5}
      fontWeight={400}
      fontSize={FONT_SIZE}
      color={FONT_COLOR}
      width={"100%"}
    >
      {STEPS.map((step, index) => (
        <HStack spacing={2} width={"100%"} key={index}>
          <Center
            border={"1px solid"}
            borderColor={FONT_COLOR}
            rounded={"full"}
            color={FONT_COLOR}
            width={"26px"}
            height={"26px"}
            flexShrink={0}
          >
            {index + 1}
          </Center>
          <Box fontSize={"sm"}>{step}</Box>
        </HStack>
      ))}
    </VStack>
  );
};
