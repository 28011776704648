import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";

export type TSmeIntegrationConfigFieldValues = {
  smeApiKey: string;
};

export const smeIntegrationInitialValues: TSmeIntegrationConfigFieldValues = {
  smeApiKey: "",
};

export const smeIntegrationConnectValidationSchema = Yup.object().shape({
  smeApiKey: Yup.string().required(REQUIRED_FIELD),
});
