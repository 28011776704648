import { TLeadsFilter } from "../types/leads-filter.type";
import { LeadsTableColumns } from "../enums/leads-table-columns.enum";

const LOCAL_STORAGE_TOKEN_KEY = "token";
const LOCAL_STORAGE_DEFAULT_ACCOUNT_KEY = "defaultAccount";
const FILTERS_LOCAL_STORAGE_KEY = "lead-filters-v2";
const LEADS_TABLE_HIDDEN_COLUMNS_KEY = "leads-table-hidden-columns";
const LEADS_TABLE_HIDDEN_COLUMNS_DEFAULT = [
  LeadsTableColumns.JOURNEY,
  LeadsTableColumns.CAMPAIGN,
];

export const setLeadProAuthTokenFromLocalStorage = (token: string) => {
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
};

export const getLeadProAuthTokenFromLocalStorage = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const clearLeadProAuthTokenFromLocalStorage = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const setDefaultAccount = (account_id: number) => {
  window.localStorage.setItem(
    LOCAL_STORAGE_DEFAULT_ACCOUNT_KEY,
    `${account_id}`
  );
};

export const getDefaultAccount = () => {
  return Number(window.localStorage.getItem(LOCAL_STORAGE_DEFAULT_ACCOUNT_KEY));
};

export const clearDefaultAccountFromLocalStorage = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_DEFAULT_ACCOUNT_KEY);
};

export const getAccountLeadTableFiltersFromLocalStorage = (
  accountId: number
): TLeadsFilter => {
  return JSON.parse(
    window.localStorage.getItem(`${accountId}/${FILTERS_LOCAL_STORAGE_KEY}`) ||
      "{}"
  );
};

export const setAccountLeadTableFiltersToLocalStorage = (
  accountId: number,
  filterData: TLeadsFilter
) => {
  window.localStorage.setItem(
    `${accountId}/${FILTERS_LOCAL_STORAGE_KEY}`,
    JSON.stringify(filterData)
  );
};

export const getAccountLeadTableHiddenColumnsFromLocalStorage = (
  accountId: number
): LeadsTableColumns[] => {
  return JSON.parse(
    window.localStorage.getItem(
      `${accountId}/${LEADS_TABLE_HIDDEN_COLUMNS_KEY}`
    ) || JSON.stringify(LEADS_TABLE_HIDDEN_COLUMNS_DEFAULT)
  );
};

export const setAccountLeadTableHiddenColumnsFromLocalStorage = (
  accountId: number,
  shownColumns: LeadsTableColumns[]
) => {
  window.localStorage.setItem(
    `${accountId}/${LEADS_TABLE_HIDDEN_COLUMNS_KEY}`,
    JSON.stringify(shownColumns)
  );
};
