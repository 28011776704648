import {
  getInvalidUKPostcodeMessage,
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
  URL_FIELD,
  URL_FIELD_MISSING_PROTOCOL,
} from "constants/validator-messages";
import { TManualAddLeadFieldValues } from "types/manual-add-lead.type";
import {
  doesUrlContainProtocol,
  isValidPhone,
} from "utils/custom-yup-validators.utils";
import { UK_POSTCODE_REGEX } from "utils/regexs.utils";
import * as Yup from "yup";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { isTypeSaleOrLet } from "utils/manual-add-lead.utils";

export const manualAddLeadInitialValues: TManualAddLeadFieldValues = {
  type: LeadTypeEnum.Sale,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  postcode: "",
  advertAddress: "",
  advertPostcode: "",
  advertUrl: "",
  propertyReference: "",
  message: "",
  generalEnquiry: false,
  officeId: null,
};

export const manualAddLeadValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
  phone: Yup.string()
    .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
    .required(REQUIRED_FIELD),
  postcode: Yup.string()
    .transform(value => (!value ? null : value)) // turn empty string to `null`
    .nullable()
    .matches(UK_POSTCODE_REGEX, getInvalidUKPostcodeMessage)
    .when("type", {
      is: (type: LeadTypeEnum) => !isTypeSaleOrLet(type),
      then: schema => schema.required(REQUIRED_FIELD),
    }),
  address: Yup.string().when("type", {
    is: (type: LeadTypeEnum) => !isTypeSaleOrLet(type),
    then: schema => schema.required(REQUIRED_FIELD),
  }),
  advertAddress: Yup.string().when(["generalEnquiry", "type"], {
    is: (
      generalEnquiry: TManualAddLeadFieldValues["generalEnquiry"],
      type: TManualAddLeadFieldValues["type"]
    ) => {
      return isTypeSaleOrLet(type) && !generalEnquiry;
    },
    then: schema => {
      return schema.required(REQUIRED_FIELD);
    },
  }),
  advertPostcode: Yup.string()
    .transform(value => (!value ? null : value)) // turn empty string to `null`
    .nullable()
    .when(["generalEnquiry", "type"], {
      is: (
        generalEnquiry: TManualAddLeadFieldValues["generalEnquiry"],
        type: TManualAddLeadFieldValues["type"]
      ) => {
        return isTypeSaleOrLet(type) && !generalEnquiry;
      },
      then: schema => {
        return schema
          .matches(UK_POSTCODE_REGEX, getInvalidUKPostcodeMessage)
          .required(REQUIRED_FIELD);
      },
    }),
  officeId: Yup.number()
    .nullable()
    .when(["generalEnquiry", "type"], {
      is: (
        generalEnquiry: TManualAddLeadFieldValues["generalEnquiry"],
        type: TManualAddLeadFieldValues["type"]
      ) => {
        return isTypeSaleOrLet(type) && generalEnquiry;
      },
      then: schema => {
        return schema.required(REQUIRED_FIELD);
      },
    }),
  propertyReference: Yup.string(),
  advertUrl: Yup.string()
    .test("is-valid-url", URL_FIELD_MISSING_PROTOCOL, doesUrlContainProtocol)
    .url(URL_FIELD),
  message: Yup.string().max(8192),
});
