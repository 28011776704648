import { Box, BoxProps } from "@chakra-ui/react";
import React, { FC } from "react";

export const PanelBody: FC<BoxProps> = ({ children }) => {
  return (
    <Box px={6} py={4}>
      {children}
    </Box>
  );
};
