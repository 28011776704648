import { Box } from "@chakra-ui/react";
import React from "react";

interface IFormRadioProps {
  name: string;
  label: string;
  onChange: () => void;
  isChecked: boolean;
  description?: string;
  isDisabled?: boolean;
}

export const RadioInput: React.FC<IFormRadioProps> = ({
  name,
  label,
  onChange,
  isChecked,
  description,
  isDisabled,
}) => {
  return (
    <Box
      position="relative"
      p={4}
      bg="gray.50"
      mb={1}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      cursor={isDisabled ? "not-allowed" : "pointer"}
      opacity={isDisabled ? 0.5 : 1}
    >
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Box
            borderRadius="100%"
            minWidth={4}
            height={4}
            bg="white"
            cursor="pointer"
            border="1px solid"
            borderColor="gray.400"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {isChecked && (
              <Box
                borderRadius="100%"
                width="12px"
                height="12px"
                bg="black"
              ></Box>
            )}
          </Box>
          <input
            style={{
              opacity: 0,
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            checked={isChecked}
            type="radio"
            name={name}
            onChange={onChange}
            disabled={isDisabled}
          />
        </Box>
        <Box lineHeight="none">{label}</Box>
      </Box>
      <Box>
        {!!description && (
          <Box fontSize="sm" mt={2} pl={6} color="gray.500" lineHeight="none">
            {description}
          </Box>
        )}
      </Box>
    </Box>
  );
};
