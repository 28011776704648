import { Box, Button } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TPersistentFilter } from "types/persistent-filters.type";
import { persistentFilterTableColumnDef } from "./persistentFilterTableColumnDef";
import { TableV2 } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { PersistentFilterFormPrompt } from "routes/dashboard/components/prompts/PersistentFilterPrompt/PersistentFilterFormPrompt";
import { AccountPersistentFiltersStore } from "store/UserAccounts/UserAccount/AccountPersistentFilters.store";
import { DeletePersistentFilterPrompt } from "routes/dashboard/components/prompts/PersistentFilterPrompt/DeletePersistentFilterPrompt";
import { observer } from "mobx-react";

interface IProps {
  persistentFiltersStore: AccountPersistentFiltersStore;
}

export const PersistentFilters: FC<IProps> = observer(
  ({ persistentFiltersStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const { setModal, unSetModal } = useActionPrompt();

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await persistentFiltersStore.loadPersistentFilters();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [persistentFiltersStore]);

    const handleCreateOrEditPersistentFilter = useCallback(
      (persistentFilter?: TPersistentFilter) => () => {
        setModal(
          <PersistentFilterFormPrompt
            closePrompt={unSetModal}
            persistentFilter={persistentFilter}
            persistentFiltersStore={persistentFiltersStore}
          />
        );
      },
      [persistentFiltersStore, setModal, unSetModal]
    );

    const handleRemovePersistentFilter = useCallback(
      (persistentFilter: TPersistentFilter) => () => {
        setModal(
          <DeletePersistentFilterPrompt
            closePrompt={unSetModal}
            persistentFilter={persistentFilter}
            persistentFiltersStore={persistentFiltersStore}
          />
        );
      },
      [persistentFiltersStore, setModal, unSetModal]
    );

    const handleOnRowClick = useCallback(
      (filter: TPersistentFilter) => {
        handleCreateOrEditPersistentFilter(filter)();
      },
      [handleCreateOrEditPersistentFilter]
    );

    const columnsDef = useMemo(() => {
      return persistentFilterTableColumnDef(
        handleCreateOrEditPersistentFilter,
        handleRemovePersistentFilter
      );
    }, [handleCreateOrEditPersistentFilter, handleRemovePersistentFilter]);

    return (
      <Box>
        <TableV2<TPersistentFilter>
          dataSource={persistentFiltersStore.persistentFiltersArray}
          columns={columnsDef}
          pageSize={20}
          loadingStatus={loadingStatus}
          globalFilterInputPlaceholder={"Type filter name"}
          striped={true}
          onRowClick={handleOnRowClick}
          additionalActions={
            <Box flexGrow={1}>
              <Button
                variant={"link"}
                colorScheme={"blue"}
                onClick={handleCreateOrEditPersistentFilter()}
              >
                + Create filter
              </Button>
            </Box>
          }
        />
      </Box>
    );
  }
);
