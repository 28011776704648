import { useCallback } from "react";
import { Box, Heading, SlideFade, Button, useToast } from "@chakra-ui/react";
import { OfficeSetupItem } from "./OfficeSetupItem";
import { OfficeFormPrompt } from "routes/dashboard/components/prompts/OfficePrompt/OfficeFormPrompt";
import { ApiMessageStack, Message } from "components";
import { AccountSetupStepEnum } from "enums/account-setup-step.enum";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { useForm } from "react-hook-form";

export const AccountOfficesStep = observer(() => {
  const { setModal, unSetModal } = useActionPrompt();
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();
  const accountOfficesStore = selectedAccountStore?.accountOfficesStore;
  const accountOfficeStores =
    accountOfficesStore?.accountOfficeStoresArray || [];
  const areExistingOfficesValid =
    accountOfficesStore?.areExistingOfficesValid || false;
  const toast = useToast();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = async () => {
    if (!!selectedAccountStore) {
      try {
        await selectedAccountStore.update({
          setupStepsCompleted: [
            ...selectedAccountStore.account.setupStepsCompleted,
            AccountSetupStepEnum.OFFICES,
          ],
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }
  };

  const handleOpenOfficeCreationPrompt = useCallback(() => {
    if (!selectedAccountStore) return;

    setModal(
      <OfficeFormPrompt
        accountStore={selectedAccountStore}
        closePrompt={unSetModal}
      />,
      { closeOnOverlayClick: false }
    );
  }, [selectedAccountStore, setModal, unSetModal]);

  return (
    <SlideFade in={true}>
      {!!selectedAccountStore && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box textAlign="center" mb={10}>
            <Heading fontSize="3xl" fontWeight={"500"} lineHeight="none">
              Add your offices
            </Heading>
          </Box>
          <Box mt={10} width={"100%"}>
            {accountOfficeStores.map(officeStore => (
              <OfficeSetupItem
                key={officeStore.office.id}
                accountStore={selectedAccountStore}
                officeStore={officeStore}
              />
            ))}
            {!accountOfficeStores.length && (
              <Message>Please add at least one office to your account.</Message>
            )}
            {!areExistingOfficesValid && (
              <Message>
                Please finish creating your offices by filling in mandatory
                data.
              </Message>
            )}
          </Box>
          <Box mt={3} width={"100%"} textAlign={"left"}>
            <Button
              type={"button"}
              variant="link"
              textDecoration={"underline"}
              onClick={handleOpenOfficeCreationPrompt}
            >
              {`Add an${accountOfficeStores.length ? "other" : ""} office`}
            </Button>
          </Box>
          <Box mt={10}>
            <Button
              type={"submit"}
              width={"full"}
              colorScheme={"blue"}
              size={"md"}
              isDisabled={
                !accountOfficeStores.length || !areExistingOfficesValid
              }
              isLoading={isSubmitting}
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </SlideFade>
  );
});
