import React from "react";
import { UserRoleEnum } from "enums/user-role.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";

interface IProps {
  roles: UserRoleEnum[];
  children: React.ReactNode;
  showInstead?: React.ReactNode;
}
export const ShowForRoles: React.FC<IProps> = observer(
  ({ roles, children, showInstead }) => {
    const {
      userAccountsStore: { selectedAccountStore },
    } = useAppStore();

    return (
      <>
        {!!selectedAccountStore &&
          roles.includes(selectedAccountStore.account.roleId) &&
          children}
        {!!selectedAccountStore &&
          !roles.includes(selectedAccountStore.account.roleId) &&
          showInstead}
      </>
    );
  }
);
