import {
  Box,
  BoxProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";
import React from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Loader } from "../loader/Loader";
import { Error } from "../error/Error";

interface IActionPromptContainerProps {
  header: React.ReactNode;
  body: React.ReactNode;
  loadingStatus?: ApiRequestStatusEnum;
  contentStyle?: BoxProps;
  bodyStyle?: BoxProps;
}

export const ActionPromptContainer: React.FC<IActionPromptContainerProps> = ({
  header,
  body,
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
  contentStyle,
  bodyStyle,
}) => {
  return (
    <ModalContent {...contentStyle}>
      <ModalHeader
        display={"flex"}
        borderBottom={"1px solid"}
        borderColor={"gray.100"}
        roundedTop={"md"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={4}
        py={3}
      >
        <Box flexGrow={1}>
          {loadingStatus === ApiRequestStatusEnum.PENDING && "Loading..."}
          {loadingStatus === ApiRequestStatusEnum.ERROR && "Error"}
          {loadingStatus === ApiRequestStatusEnum.SUCCESS && header}
        </Box>
        <ModalCloseButton position={"relative"} top={0} right={0} />
      </ModalHeader>
      <ModalBody px={5} py={4} {...bodyStyle}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box pos={"relative"} width={"100%"} height={"200px"}>
            <Loader />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.ERROR && (
          <Box pos={"relative"} width={"100%"} height={"200px"}>
            <Error />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && body}
      </ModalBody>
    </ModalContent>
  );
};
