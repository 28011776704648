import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TSmsTemplate } from "types/sms-template.type";
import { keyBy } from "lodash";
import { TSelectOption } from "types/select-input.type";
import { SmsTemplatesApi } from "api/sms-templates.api";
import {
  TTemplateMergeTag,
  TTemplateMergeTagGroup,
} from "types/template-merge-tag.type";
import { SuggestionDataItem } from "react-mentions";

const smsTemplatesApi = new SmsTemplatesApi();

export class AccountSmsTemplatesStore {
  public readonly accountId: number;
  public readonly smsTemplates: IObservableArray<TSmsTemplate>;
  private readonly mergeTagGroups: IObservableArray<TTemplateMergeTagGroup>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.smsTemplates = observable.array<TSmsTemplate>();
    this.mergeTagGroups = observable.array<TTemplateMergeTagGroup>();
  }

  get smsTemplatesArray() {
    return this.smsTemplates.slice();
  }

  get smsTemplatesMap() {
    return keyBy(this.smsTemplatesArray, smsTemplate => smsTemplate.id);
  }

  get smsTemplatesAsOptions(): TSelectOption<number>[] {
    return this.smsTemplatesArray.map(smsTemplate => ({
      value: smsTemplate.id,
      label: smsTemplate.name,
    }));
  }

  get mergeTagGroupsArray() {
    return this.mergeTagGroups.slice();
  }

  get mergeTagsFlatArray() {
    const mergeTags: TTemplateMergeTag[] = [];
    this.mergeTagGroupsArray.forEach(mergeTagGroup =>
      mergeTags.push(...mergeTagGroup.items)
    );

    return mergeTags;
  }

  get mergeTagGroupOptionsArray(): SuggestionDataItem[] {
    const options: SuggestionDataItem[] = [];
    this.mergeTagGroupsArray.forEach(group => {
      group.items.forEach(tag => {
        options.push({
          id: tag.value,
          display: group.name + " - " + tag.text,
        });
      });
    });

    return options;
  }

  private setSmsTemplates(smsTemplates: TSmsTemplate[]) {
    this.smsTemplates.replace(smsTemplates);
  }

  private setMergeTagGroups(mergeTagGroups: TTemplateMergeTagGroup[]) {
    this.mergeTagGroups.replace(mergeTagGroups);
  }

  private upsertSmsTemplate(smsTemplateData: TSmsTemplate) {
    const index = this.smsTemplates.findIndex(
      smsTemplate => smsTemplate.id === smsTemplateData.id
    );
    if (index > -1) {
      this.smsTemplates[index] = {
        ...this.smsTemplates[index],
        ...smsTemplateData,
      };
    } else {
      this.smsTemplates.push(smsTemplateData);
    }
  }

  public deleteSmsTemplate(templateId: number) {
    const index = this.smsTemplates.findIndex(
      template => template.id === templateId
    );
    if (index > -1) {
      this.smsTemplates.splice(index, 1);
    }
  }

  public async loadSmsTemplates() {
    const smsTemplates = await smsTemplatesApi.fetchAll(this.accountId);
    this.setSmsTemplates(smsTemplates);
  }

  public async createSmsTemplate(smsTemplate: Partial<TSmsTemplate>) {
    const createdTemplate = await smsTemplatesApi.create(
      this.accountId,
      smsTemplate
    );
    this.upsertSmsTemplate(createdTemplate);
  }

  public async updateSmsTemplate(
    templateId: number,
    smsTemplate: Partial<TSmsTemplate>
  ) {
    const updatedTemplate = await smsTemplatesApi.update(
      this.accountId,
      templateId,
      smsTemplate
    );
    this.upsertSmsTemplate(updatedTemplate);
  }

  public async removeSmsTemplate(templateId: number) {
    await smsTemplatesApi.delete(this.accountId, templateId);
    this.deleteSmsTemplate(templateId);
  }

  public async loadSmsTemplateMergeTags() {
    const mergeTagGroups = await smsTemplatesApi.fetchMergeTags(this.accountId);
    this.setMergeTagGroups(mergeTagGroups);
  }

  public async sendTestSms(templateId: number, phoneNumber: string) {
    await smsTemplatesApi.sendTestSms(this.accountId, templateId, phoneNumber);
  }
}
