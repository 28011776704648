import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
  offCenter?: boolean;
}

export const LeadInfoRowLabel: React.FC<IProps> = props => {
  const { children, offCenter } = props;

  return (
    <Box
      color={"gray.500"}
      minWidth={"150px"}
      alignSelf={offCenter ? "flex-start" : "center"}
    >
      {children}
    </Box>
  );
};
