import { Heading } from "@chakra-ui/react";
import React from "react";

interface IPanelTitleProps {
  children: React.ReactNode;
}
const PanelTitle: React.FC<IPanelTitleProps> = ({ children }) => {
  return (
    <Heading fontSize="lg" lineHeight="none" fontWeight={500}>
      {children}
    </Heading>
  );
};

export default PanelTitle;
