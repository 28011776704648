import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountPropertiesFiltersStore } from "./AccountPropertiesFilters.store";
import {
  TGetPropertiesResponse,
  TPropertiesListMeta,
  TProperty,
  TPropertyTableData,
} from "types/property.type";
import { PropertiesApi } from "api/properties.api";
import { AccountPropertyStore } from "./AccountProperty.store";
import { keyBy } from "lodash";

const propertiesApi = new PropertiesApi();
let latestPropertiesLoadingAttempt: number | null;

export class AccountPropertiesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly propertyStores: IObservableArray<AccountPropertyStore>;
  private propertiesMeta: TPropertiesListMeta;
  public propertiesFilterStore: AccountPropertiesFiltersStore;
  public selectedPropertyId: number | null;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.selectedPropertyId = null;
    this.propertiesFilterStore = new AccountPropertiesFiltersStore(accountId);
    this.propertyStores = observable.array<AccountPropertyStore>();
    this.propertiesMeta = {
      count: 0,
      offset: 0,
      limit: 50,
    };
  }

  get propertiesTableData(): TPropertyTableData[] {
    return this.propertyStores.map(propertyStore => ({
      ...propertyStore.property,
      totalLeadsCount: propertyStore.property.sources.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.count;
        },
        0
      ),
    }));
  }

  get meta() {
    return {
      currentPage: this.propertiesMeta.offset / this.propertiesMeta.limit,
      ...this.propertiesMeta,
    };
  }

  get propertyStoresMap() {
    return keyBy(
      this.propertyStores,
      propertyStore => propertyStore.property.id
    );
  }

  public async selectProperty(propertyId: number | null) {
    this.selectedPropertyId = propertyId;

    if (!!propertyId) {
      await this.getPropertyWithDetails(propertyId);
    }
  }

  public async loadProperties(
    searchQuery: string,
    pageSize: number,
    page?: number
  ) {
    const now = Date.now();
    latestPropertiesLoadingAttempt = now;

    if (page === undefined) {
      this.resetMetaOffset();
    }

    const data = await propertiesApi.getProperties(
      this.accountId,
      searchQuery,
      pageSize,
      page !== undefined ? page : this.meta.currentPage
    );

    if (now === latestPropertiesLoadingAttempt) {
      this.setProperties(data);
    }
  }

  public async getPropertyWithDetails(propertyId: number) {
    if (!!this.propertyStoresMap[propertyId]) {
      await this.propertyStoresMap[propertyId].fetchDetails();
    } else {
      const propertyData = await propertiesApi.getPropertyWithDetails(
        this.accountId,
        propertyId
      );
      this.addProperty(propertyData);
    }
  }

  private setProperties(propertiesResponse: TGetPropertiesResponse) {
    const { data, meta } = propertiesResponse;
    this.propertyStores.replace(
      data.map(
        property =>
          new AccountPropertyStore(this.root, this.accountId, property)
      )
    );
    this.propertiesMeta = meta;
  }

  private addProperty(propertyData: TProperty) {
    this.propertyStores.push(
      new AccountPropertyStore(this.root, this.accountId, propertyData)
    );
  }

  private resetMetaOffset() {
    if (!!this.propertiesMeta) {
      this.propertiesMeta.offset = 0;
    }
  }
}
