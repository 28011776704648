import { Box, Button, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import React, { FC, useCallback } from "react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { IovoxCallerStore } from "./LeadCallButton.local.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, Tooltip } from "components";
import { TLead } from "types/lead.type";

interface IProps {
  accountId: number;
  leadStore: AccountLeadStore;
  disabled?: boolean;
  onCallSuccessCallback: (lead: TLead) => Promise<void>;
  isStrictModeEnabled?: boolean;
}

export const LeadCallButton: FC<IProps> = observer(
  ({
    accountId,
    leadStore,
    disabled,
    onCallSuccessCallback,
    isStrictModeEnabled,
  }) => {
    const toast = useToast();
    const iovoxCallerStore = useLocalStore(IovoxCallerStore, {
      accountId,
      leadStore,
    });

    const hasBeenCalled = iovoxCallerStore.hasExistingCall;
    const isMakingCallInProgress = iovoxCallerStore.isMakingCallInProgress;

    const manageIOVOXCall = useCallback(async () => {
      try {
        await iovoxCallerStore.makeIOVOXCall(async (lead: TLead) => {
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "Call initiated successfully",
          });
          if (isStrictModeEnabled) {
            await onCallSuccessCallback(lead);
          }
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [iovoxCallerStore, onCallSuccessCallback, toast, isStrictModeEnabled]);

    return (
      <Tooltip
        label={hasBeenCalled && "Lead has already been called"}
        textAlign={"center"}
        aria-label="A tooltip"
      >
        <Box>
          <Button
            background={"leadpro.600"}
            color={"white"}
            _hover={{ background: "leadpro.500" }}
            _focus={{ background: "leadpro.500" }}
            _disabled={{
              pointerEvents: "none",
              background: "leadpro.300",
            }}
            fontSize={16}
            fontWeight={"bold"}
            height={"50px"}
            width={"325px"}
            loadingText={"Calling..."}
            disabled={disabled || isMakingCallInProgress || hasBeenCalled}
            isLoading={isMakingCallInProgress}
            onClick={manageIOVOXCall}
          >
            Call this lead now
          </Button>
        </Box>
      </Tooltip>
    );
  }
);
