import { Box, CloseButton } from "@chakra-ui/react";
import React from "react";

interface IStandardDrawerCloseProps {
  onClose: () => void;
}

export const StandardDrawerClose: React.FC<IStandardDrawerCloseProps> = ({
  onClose,
}) => {
  return (
    <Box ml={3}>
      <CloseButton onClick={onClose} />
    </Box>
  );
};
