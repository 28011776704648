import React, { FC, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadResponderWorkflows } from "./LeadResponderWorkflows/LeadResponderWorkflows";
import { TabsGroupLayout } from "../../../../../components/Layouts/TabsGroupLayout";
import { LeadResponderQuestionnaires } from "./LeadResponderQuestionnaires/LeadResponderQuestionnaires";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Route, Switch } from "react-router-dom";
import {
  TOOLS_LEAD_RESONDER_LEAD_EMAILS_ROUTE,
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
} from "constants/routes";
import { LeadResponderQuestionnairePages } from "../routes/lead-responder-questionnaire-pages/LeadResponderQuestionnairePages";
import { observer } from "mobx-react";
import { LeadResponderLeadEmailsDetail } from "../routes/lead-responder-lead-emails/LeadResponderLeadEmailsDetail";
import { LeadResponderLeadEmails } from "./LeadResponderLeadEmails/LeadResponderLeadEmails";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderSettings: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const accountPersistentFiltersStore =
      accountStore.accountPersistentFiltersStore;
    const accountOfficesStore = accountStore.accountOfficesStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountPersistentFiltersStore.loadPersistentFilters(),
            accountOfficesStore.fetchAccountOffices(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [setLoadingStatus, accountPersistentFiltersStore, accountOfficesStore]);

    const tabsGroupLayoutConfig = useMemo(() => {
      const config = [
        {
          tabName: "Workflows",
          tabHash: "#workflows",
          tabComponent: <LeadResponderWorkflows accountStore={accountStore} />,
        },
        {
          tabName: "Lead Inbox",
          tabHash: "#lead-inbox",
          tabComponent: <LeadResponderLeadEmails accountStore={accountStore} />,
        },
      ];

      if (accountStore.account.useNewQuestionnaires) {
        config.push({
          tabName: "Questionnaires",
          tabHash: "#questionnaires",
          tabComponent: (
            <LeadResponderQuestionnaires accountStore={accountStore} />
          ),
        });
      }

      return config;
    }, [accountStore]);

    return (
      <Switch>
        <Route path={TOOLS_LEAD_RESPONDER_ROUTE} exact>
          <TabsGroupLayout
            title={"Lead Responder Settings"}
            loadingStatus={loadingStatus}
            config={tabsGroupLayoutConfig}
          />
        </Route>
        <Route path={TOOLS_LEAD_RESONDER_LEAD_EMAILS_ROUTE}>
          <LeadResponderLeadEmailsDetail accountStore={accountStore} />
        </Route>
        {accountStore.account.useNewQuestionnaires && (
          <Route path={TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE}>
            <LeadResponderQuestionnairePages accountStore={accountStore} />
          </Route>
        )}
      </Switch>
    );
  }
);
