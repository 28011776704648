import { TOfficeAutocallerConfiguration } from "types/autocaller.type";
import { LeadTypeEnum } from "../enums/lead-type.enum";

export type AutocallerAutoAttendantTypes = keyof Pick<
  TOfficeAutocallerConfiguration,
  | "salesAutoAttendant"
  | "lettingsAutoAttendant"
  | "vendorAutoAttendant"
  | "landlordAutoAttendant"
  | "mortgagesAutoAttendant"
>;

export const autoAttendantFieldNamesByLeadType: Record<
  string,
  AutocallerAutoAttendantTypes
> = {
  [LeadTypeEnum.Sale]: "salesAutoAttendant",
  [LeadTypeEnum.Let]: "lettingsAutoAttendant",
  [LeadTypeEnum.Vendor]: "vendorAutoAttendant",
  [LeadTypeEnum.Landlord]: "landlordAutoAttendant",
  [LeadTypeEnum.MortgageRequest]: "mortgagesAutoAttendant",
};

export const combinePhoneNumberAndAutoAttendant = (
  number: string,
  autoAttendant?: string | null
) => {
  return `${number}${!!autoAttendant ? "," + autoAttendant : ""}`;
};
