import { Box, useTheme, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { faFiles, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

interface IProps {
  Icon?: IconDefinition;
  label: string;
}
export const EmptyScreen: FC<IProps> = ({ Icon = faFiles, label }) => {
  const theme = useTheme();
  return (
    <VStack spacing={4} color={theme.colors.gray[400]} fontSize={"sm"}>
      <FontAwesomeIcon icon={Icon} fontSize={36} />
      <Box>{label}</Box>
    </VStack>
  );
};
