import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
}

export const LeadControlRowLabel: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Box color={"leadpro.500"} mr={2}>
      {children}
    </Box>
  );
};
