import { observer } from "mobx-react";
import { Box, HStack } from "@chakra-ui/react";
import React, { FC, ReactNode, useMemo } from "react";
import { capitalize } from "lodash";
import {
  getPropertyOptionsFromAttribution,
  propertyAttributionKeys,
} from "../lead-attribution.utils";
import { TLeadAttribution } from "types/lead-attribution.type";

interface LeadAttributionPropertyContentProps {
  icon: ReactNode;
  attribution: TLeadAttribution;
}

export const LeadAttributionPropertyContent: FC<LeadAttributionPropertyContentProps> = observer(
  ({ attribution, icon }) => {
    const isPropertyDefined = useMemo(() => {
      return propertyAttributionKeys.some(key => Boolean(attribution[key]));
    }, [attribution]);

    const options = useMemo(() => {
      return getPropertyOptionsFromAttribution(attribution);
    }, [attribution]);

    if (!isPropertyDefined) return null;

    return (
      <HStack
        mt={3}
        borderRadius="lg"
        borderColor="leadpro.200"
        backgroundColor="leadpro.50"
        p={3}
        w="100%"
      >
        <Box flex="1" display={"flex"} justifyContent={"center"}>
          {icon}
        </Box>
        <Box flex="4" textAlign="left" fontSize={"xs"}>
          {options.map(data => (
            <Box>
              <Box as="span" fontWeight="bold" fontSize={"xs"}>
                {capitalize(data.label)}
              </Box>
              <Box as="span" ml={2}>
                {data.value}
              </Box>
            </Box>
          ))}
        </Box>
      </HStack>
    );
  }
);
