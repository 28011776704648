import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
  secondary?: boolean;
}

export const LeadControlRow: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      border={"1px solid"}
      borderColor={"leadpro.200"}
      px={3}
      bg={props.secondary ? "leadpro.50" : "white"}
    >
      {children}
    </Box>
  );
};
