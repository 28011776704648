import React, { useCallback, useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { FormControlDescription, Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { observer } from "mobx-react";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";

interface IProps {
  accountBillingStore: AccountBillingStore;
}

export const AccountBillingPaymentIssues: React.FC<IProps> = observer(
  ({ accountBillingStore }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe();

    const accountBilling = accountBillingStore.billing;
    const accountIssues = accountBillingStore.accountBillingIssues;

    useEffect(() => {
      setIsProcessing(false);
    }, [accountIssues]);

    const handlePayment = useCallback(async () => {
      try {
        if (
          !!stripe &&
          !!accountBilling?.stripePaymentMethodId &&
          !!accountIssues.length &&
          accountIssues[0].stripePaymentIntentClientSecret
        ) {
          setIsProcessing(true);
          await stripe.confirmCardPayment(
            accountIssues[0].stripePaymentIntentClientSecret,
            {
              payment_method: accountBilling.stripePaymentMethodId,
            }
          );
          setIsProcessing(true);
        }
      } catch (e) {
        setIsProcessing(false);
      }
    }, [accountBilling, accountIssues, stripe]);

    if (!!accountIssues.length) {
      return (
        <Message status={AlertStatusEnum.ERROR}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box pr={4}>{accountIssues[0].message}</Box>
            <Button
              onClick={handlePayment}
              colorScheme={"red"}
              isDisabled={isProcessing || !stripe}
              isLoading={isProcessing}
            >
              {accountIssues[0].cta}
            </Button>
          </Box>
          {!stripe && (
            <Box mt={5}>
              <FormControlDescription>
                Action temporarily disabled due to issue with Stripe. Please
                reload to try again.
              </FormControlDescription>
            </Box>
          )}
        </Message>
      );
    }

    return null;
  }
);
