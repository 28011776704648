import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AccountDevelopersWebhooksStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhooks.store";
import { ApiMessageStack, InternalLink, Message, TableV2 } from "components";
import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import { TAccountDevelopersWebhookConfig } from "types/account-developers.type";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { developersConfigWebhooksTableColumnDef } from "./developersConfigWebhooksTableColumnDef";
import { DevelopersConfigWebhookFormPrompt } from "routes/dashboard/components/prompts/DevelopersConfigPrompt/DevelopersConfigWebhookFormPrompt";
import { AlertStatusEnum } from "enums/alert-status.enum";
import {
  GUIDES_ROUTE,
  SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE,
  SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE,
} from "constants/routes";
import { useHistory } from "react-router-dom";

interface IProps {
  accountDevelopersWebhooksStore: AccountDevelopersWebhooksStore;
  internal?: boolean;
}

export const DevelopersConfigWebhooks: FC<IProps> = observer(
  ({ accountDevelopersWebhooksStore, internal }) => {
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();
    const history = useHistory();

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountDevelopersWebhooksStore.fetchAllWebhooks();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountDevelopersWebhooksStore, toast]);

    const dataSource = internal
      ? accountDevelopersWebhooksStore.internalWebhooksDataArray
      : accountDevelopersWebhooksStore.externalWebhooksDataArray;

    const onDeleteWebhook = useCallback(
      (webhookId: number) => async () => {
        try {
          await accountDevelopersWebhooksStore.deleteWebhook(webhookId);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"Webhook successfully removed."} />
            ),
          });
        } catch (err) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={err.message} />,
          });
        }
      },
      [accountDevelopersWebhooksStore, toast]
    );

    const handleCreateWebhookFormPrompt = useCallback(() => {
      setModal(
        <DevelopersConfigWebhookFormPrompt
          accountDevelopersWebhooksStore={accountDevelopersWebhooksStore}
          closePrompt={unSetModal}
        />
      );
    }, [accountDevelopersWebhooksStore, setModal, unSetModal]);

    const handleDeleteWebhookPrompt = useCallback(
      (webhookData: TAccountDevelopersWebhookConfig) => () => {
        setModal(
          <ConfirmActionPrompt
            text={`Please confirm that you want to remove ${webhookData.name} webhook.`}
            onConfirm={onDeleteWebhook(webhookData.id)}
            closePrompt={unSetModal}
          />
        );
      },
      [onDeleteWebhook, setModal, unSetModal]
    );

    const handleEditWebhook = useCallback(
      (webhookData: TAccountDevelopersWebhookConfig) => () => {
        if (internal) {
          history.push(
            `${SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE}/${webhookData.id}`
          );
          return;
        }
        history.push(`${SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE}/${webhookData.id}`);
      },
      [history, internal]
    );

    const handleOnRowClick = useCallback(
      (webhookData: TAccountDevelopersWebhookConfig) =>
        handleEditWebhook(webhookData)(),
      [handleEditWebhook]
    );

    const columnDef = useMemo(() => {
      return developersConfigWebhooksTableColumnDef(
        handleDeleteWebhookPrompt,
        handleEditWebhook,
        internal
      );
    }, [handleDeleteWebhookPrompt, handleEditWebhook, internal]);

    return (
      <VStack spacing={10}>
        {!internal && (
          <Message status={AlertStatusEnum.INFO}>
            <VStack spacing={2} align={"flex-start"}>
              <Box>
                You can configure your LeadPro account to send events to a
                designated endpoints when a lead is either created or updated.
              </Box>
              <InternalLink href={`${GUIDES_ROUTE}/using-webhooks`} pl={1}>
                Read the guide.
              </InternalLink>
            </VStack>
          </Message>
        )}
        <TableV2<TAccountDevelopersWebhookConfig>
          dataSource={dataSource}
          globalFilterInputPlaceholder="Search webhooks"
          columns={columnDef}
          pageSize={20}
          loadingStatus={loadingStatus}
          striped={true}
          onRowClick={handleOnRowClick}
          additionalActions={
            !internal && (
              <Box flexGrow={1}>
                <Button
                  variant={"link"}
                  colorScheme={"blue"}
                  onClick={handleCreateWebhookFormPrompt}
                >
                  + Create webhook
                </Button>
              </Box>
            )
          }
        />
      </VStack>
    );
  }
);
