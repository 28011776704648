import { observer } from "mobx-react";
import ReactFlow, {
  Controls,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import "reactflow/dist/style.css";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { QuestionnaireToolPageWIPFormStore } from "../stores/QuestionnaireToolPageWIPForm.store";
import React, { FC, useCallback, useEffect } from "react";
import JumpEdge from "./elements/JumpEdge";
import QuestionNode from "./elements/QuestionNode";
import { Box, useTheme } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { NewLogicElementPrompt } from "./elements/NewLogicElementPrompt";
import { COMMON_EDGE_SETTINGS } from "constants/questionnaire-branching";
import EndNode from "./elements/EndNode";
import {
  QuestionnaireBranchingEdgeTypes,
  QuestionnaireBranchingNodeTypes,
} from "enums/questionnaire-branching.enum";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";

interface IProps {
  isExpanded: boolean;
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

const edgeTypes: Record<QuestionnaireBranchingEdgeTypes, FC<any>> = {
  [QuestionnaireBranchingEdgeTypes.JUMP_EDGE]: JumpEdge,
};

const nodeTypes: Record<QuestionnaireBranchingNodeTypes, FC<any>> = {
  [QuestionnaireBranchingNodeTypes.QUESTION_NODE]: QuestionNode,
  [QuestionnaireBranchingNodeTypes.END_NODE]: EndNode,
};

export const QuestionnaireToolPageSettingsQuestionnairesBranchingContent: FC<IProps> = observer(
  ({ isExpanded, leadType, questionnaireToolPageWIPFormStore }) => {
    const theme = useTheme();
    const { setModal, unSetModal } = useActionPrompt();
    const store = useStoreApi();
    const { getNode, setCenter } = useReactFlow();
    const nodesState = useNodesState(
      questionnaireToolPageWIPFormStore.nodesAndEdgesFromQuestions.nodes
    );
    const nodes = nodesState[0];
    const onNodesChange = nodesState[2];

    useEffect(() => {
      setTimeout(() => {
        if (isExpanded) {
          const { nodeInternals } = store.getState();
          const nodes = Array.from(nodeInternals).map(([, node]) => node);

          if (nodes.length > 0) {
            const node = nodes[0];
            const x = node.position.x + (node.width || 0) / 2;
            const y = node.position.y + (node.height || 0) / 2;
            const zoom = 1;

            setCenter(x, y + 300, { zoom, duration: 500 });
          }
        }
      }, 500);
    }, [isExpanded, setCenter, store]);

    const onConnect = useCallback(
      params => {
        const fromNode = getNode(params.source) as Node<
          TQuestionNodeData,
          QuestionnaireBranchingNodeTypes
        >;
        const toNode = getNode(params.target) as Node<
          TQuestionNodeData,
          QuestionnaireBranchingNodeTypes
        >;
        if (!fromNode || !toNode) return;

        setModal(
          <NewLogicElementPrompt
            fromNode={fromNode}
            toNode={toNode}
            closePrompt={unSetModal}
            questionnaireToolPageWIPFormStore={
              questionnaireToolPageWIPFormStore
            }
          />
        );
      },
      [setModal, unSetModal, getNode, questionnaireToolPageWIPFormStore]
    );

    return (
      <Box height={"800px"} key={leadType}>
        <ReactFlow
          id={leadType}
          style={{ background: theme.colors.gray[50] }}
          nodes={nodes}
          onNodesChange={onNodesChange}
          edges={
            questionnaireToolPageWIPFormStore.nodesAndEdgesFromQuestions.edges
          }
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          connectionLineStyle={COMMON_EDGE_SETTINGS.style}
        >
          <Controls />
        </ReactFlow>
      </Box>
    );
  }
);
