import * as Yup from "yup";
import {
  INVALID_HEX_COLOR,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import { HEX_COLOR_REGEX } from "utils/regexs.utils";

export const accountDetailsValidationSchema = Yup.object().shape({
  registeredCompany: Yup.object()
    .shape({
      companyName: Yup.string(),
      companyRegistrationNumber: Yup.string(),
      companyAddress: Yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD),
  name: Yup.string().required(REQUIRED_FIELD),
  website: Yup.string().url(),
  portalIds: Yup.array().of(Yup.number()),
  primaryColor: Yup.string().matches(HEX_COLOR_REGEX, INVALID_HEX_COLOR),
});
