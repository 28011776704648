import { getUserFullName } from "utils/account-user.utils";
import { TLead } from "types/lead.type";
import React, { FC, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import NA from "components/na/NA";
import { EmailLink } from "components/links/EmailLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComet } from "@fortawesome/pro-solid-svg-icons";

interface ILeadFromCellProps {
  lead: TLead;
}

export const LeadFromCell: FC<ILeadFromCellProps> = ({ lead }) => {
  const { email, phone, fullName, questionnaireAnswered } = useMemo(() => {
    return {
      email: lead.person?.email,
      phone: lead.person?.phone || null,
      fullName:
        lead.person?.fullName ||
        getUserFullName(lead.person?.firstName, lead.person?.lastName),
      questionnaireAnswered: lead.questionnaireAnswered,
    };
  }, [lead]);

  if (!fullName && !email && !phone) {
    return <NA />;
  }

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection={"column"}
      height={"100%"}
      justifyContent={"center"}
      color={"leadpro.400"}
      py={2}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box fontWeight={"bold"} fontSize={"md"} color={"leadpro.700"}>
          {fullName || <NA />}
          {questionnaireAnswered && (
            <Box as={"span"} color={"leadpro.800"} ml={"5px"} fontSize={"sm"}>
              <FontAwesomeIcon icon={faComet} />
            </Box>
          )}
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
        <Box noOfLines={1}>
          {email ? (
            <EmailLink email={email.toLowerCase()} color={"leadpro.400"} />
          ) : (
            <NA />
          )}
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        color={"leadpro.400"}
      >
        <Box>{phone ? phone : <NA />}</Box>
      </Box>
    </Box>
  );
};
