import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  streetIntegrationConnectValidationSchema,
  streetIntegrationInitialValues,
  TStreetIntegrationConfigFieldValues,
} from "utils/validation-schemas/street-integration-validation";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectStreetForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TStreetIntegrationConfigFieldValues>({
      defaultValues: streetIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(streetIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TStreetIntegrationConfigFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TStreetIntegrationConfigFieldValues
          >({
            type: AccountIntegrationTypeEnum.STREET,
            active: true,
            integrationConfig,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Street successfully connected."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TStreetIntegrationConfigFieldValues>
              label={"Token"}
              type={FormControlsTypeEnum.TEXT}
              name={"token"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlLabel>Test mode</FormControlLabel>
            <FormControlV2<TStreetIntegrationConfigFieldValues>
              name={"testMode"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
