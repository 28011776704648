import { Box } from "@chakra-ui/react";
import { SearchQueryInput } from "components/input/SearchQueryInput";
import { observer } from "mobx-react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { PanelsGroupLayout } from "routes/dashboard/components";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { ArticleList } from "./ArticleList";

export const Articles = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { guidesStore } = useAppStore();
  const articleArray = guidesStore.articlesArray;

  const onSearchInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true);
      await guidesStore.fetchArticles(searchQuery);
      setIsLoading(false);
    };

    fetchArticles();
  }, [guidesStore, searchQuery]);

  return (
    <PanelsGroupLayout>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <SearchQueryInput
          onChange={onSearchInputChange}
          placeholder={"Search for a guide"}
        />
      </Box>
      <ArticleList isLoading={isLoading} articleList={articleArray} />
    </PanelsGroupLayout>
  );
});
