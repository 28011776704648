import { observer } from "mobx-react";
import { Box, Divider } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  formToolPageSettingsValidationSchema,
  TFormToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import { PageToolPageBasicSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageBasicSettings";
import { PageToolPageAnalyticsSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageAnalyticsSettings";
import { PageToolPageGDPRCheckboxSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageGDPRCheckboxSettings";
import { FormToolPageSettingsEditorStore } from "../FormToolPageSettingsEditor.store";
import { FormToolPageSettingsDetailsThankYouPageForm } from "./FormToolPageSettingsDetailsThankYouPageForm";

type TFieldValues = TFormToolSettingsFieldValues;

interface IProps {
  formToolPageSettingsEditorStore: FormToolPageSettingsEditorStore;
}

export const FormToolPageSettingsDetailsForm: FC<IProps> = observer(
  ({ formToolPageSettingsEditorStore }) => {
    const page = formToolPageSettingsEditorStore.page || undefined;

    const { control, watch } = useForm<TFieldValues>({
      defaultValues: formToolPageSettingsEditorStore.wipPageJS,
      mode: "onBlur",
      resolver: yupResolver(formToolPageSettingsValidationSchema),
    });

    useEffect(() => {
      const subscription = watch(value => {
        formToolPageSettingsEditorStore.setWipPage(value as TFieldValues);
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [watch, formToolPageSettingsEditorStore]);

    return (
      <Box height={"100%"}>
        <form style={{ height: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            height={"100%"}
          >
            <Box p={"65px"} flexGrow={1} overflow={"auto"}>
              <PageToolPageBasicSettings
                page={page}
                control={control}
                watch={watch}
              />
              <Divider />
              <PageToolPageAnalyticsSettings control={control} />
              <Divider />
              <PageToolPageGDPRCheckboxSettings control={control} />
              <Divider />
              <FormToolPageSettingsDetailsThankYouPageForm control={control} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  }
);
