import { IntegrationEntitySyncTag } from "components";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { TIntegrationSyncOfficeExtendedData } from "types/integration-sync-office.type";
import { IntegrationOfficeSyncSelect } from "./IntegrationOfficeSyncSelect";
import React from "react";
import { AccountIntegrationOfficesStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import { TAccountIntegrationsStaticData } from "types/account-integration.type";

export const syncSelectIntegrationOfficeTableColumnDef = (
  integrationOfficesStore: AccountIntegrationOfficesStore,
  updateSyncPair: (syncPair: TIntegrationSyncOfficeExtendedData) => void,
  integrationData: TAccountIntegrationsStaticData
): ITableV2Column<TIntegrationSyncOfficeExtendedData>[] => {
  return [
    {
      accessor: "officeName",
      Header: <DefaultHeaderCell>LeadPro office</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.officeName}</DefaultRowCell>,
    },
    {
      accessor: "branchId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: (
        <DefaultHeaderCell>{`${integrationData.name} office`}</DefaultHeaderCell>
      ),
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationOfficeSyncSelect
            integrationOfficesStore={integrationOfficesStore}
            onChange={updateSyncPair}
            record={row}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
