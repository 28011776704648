import React, { useMemo } from "react";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { PremiumFeatureBlockedOverlay } from "./PremiumFeatureBlockedOverlay";
import { isApplicationProductUnlocked } from "utils/account-billing.utils";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";

interface IProps {
  render: (isEnabled: boolean) => React.ReactElement;
  alternativeRender?: () => React.ReactElement;
  productId: ApplicationProductEnum;
  ctaNavigateTo?: string;
}

export const PremiumFeatureBlock: React.FC<IProps> = observer(
  ({ render, alternativeRender, productId, ctaNavigateTo }) => {
    const {
      userAccountsStore: { selectedAccountStore },
    } = useAppStore();
    const accountBilling = selectedAccountStore?.accountBillingStore.billing;

    const premiumFeatureEnabled = useMemo(() => {
      return isApplicationProductUnlocked(productId, accountBilling);
    }, [productId, accountBilling]);

    if (premiumFeatureEnabled) {
      return render(true);
    } else if (!!alternativeRender) {
      return alternativeRender();
    } else {
      return (
        <PremiumFeatureBlockedOverlay
          render={render}
          ctaNavigateTo={ctaNavigateTo}
        />
      );
    }
  }
);
