import React, { useMemo } from "react";
import { LeadConnectedLeadsBlock } from "./blocks/LeadConnectedLeadsBlock/LeadConnectedLeadsBlock";
import { LeadPortalBlock } from "./blocks/LeadPortalBlock";
import { LeadQuestionnaireBlock } from "./blocks/LeadQuestionnaireBlock";
import { LeadValuationBlock } from "./blocks/LeadValuationBlock";
import { leadBlockDefinitionFactory } from "./leadBlockDefinitionFactory";
import { LeadBasicInfoBlock } from "./blocks/LeadBasicInfoBlock";
import { LeadMetaBlock } from "./blocks/LeadMetaBlock";
import { LeadSettingsBlock } from "./blocks/LeadSettingsBlock/LeadSettingsBlock";
import { AlertIcon, AlertTitle, Box } from "@chakra-ui/react";
import { Alert } from "components";
import { getMilesFromMeters } from "utils/distance.utils";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { LeadInfoBanner } from "routes/dashboard/routes/leads/components/LeadDrawer/LeadInfoBanner";
import {
  ILeadBlockComponentProps,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { LeadBookingRequestBlock } from "./blocks/LeadBookingRequestBlock";
import { LeadUTMBlock } from "./blocks/LeadUTMBlock";
import { LeadSourceDetailsBlock } from "./blocks/LeadSourceDetailsBlock";
import { LeadBYMDataBlock } from "./blocks/LeadBYMDataBlock";

interface IProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

const components: {
  [key in LeadBlockTypes]: React.JSXElementConstructor<
    ILeadBlockComponentProps<ILeadBlockDefinition>
  >;
} = {
  [LeadBlockTypes.BasicBlock]: LeadBasicInfoBlock,
  [LeadBlockTypes.SettingsBlock]: LeadSettingsBlock,
  [LeadBlockTypes.PortalBlock]: LeadPortalBlock,
  [LeadBlockTypes.ValuationBlock]: LeadValuationBlock,
  [LeadBlockTypes.QuestionnaireBlock]: LeadQuestionnaireBlock,
  [LeadBlockTypes.ConnectedLeadsBlock]: LeadConnectedLeadsBlock,
  [LeadBlockTypes.BookingRequest]: LeadBookingRequestBlock,
  [LeadBlockTypes.MetaBlock]: LeadMetaBlock,
  [LeadBlockTypes.UTMBlock]: LeadUTMBlock,
  [LeadBlockTypes.SourceBlock]: LeadSourceDetailsBlock,
  [LeadBlockTypes.BYMBlock]: LeadBYMDataBlock,
};

export const LeadBlocks: React.FC<IProps> = observer(
  ({ leadStore, accountStore }) => {
    const definitions = useMemo(
      () => leadBlockDefinitionFactory(accountStore, leadStore),
      [accountStore, leadStore]
    );

    return (
      <>
        {!!leadStore.lead.outOfAreaDistance && (
          <Alert status={AlertStatusEnum.WARNING}>
            <AlertIcon />
            <AlertTitle>
              This Lead is{" "}
              {getMilesFromMeters(leadStore.lead.outOfAreaDistance).toFixed(1)}{" "}
              Miles outside your postcode patch.
            </AlertTitle>
          </Alert>
        )}

        <LeadInfoBanner leadStore={leadStore} />

        {definitions.map((definition, i) => {
          const Component = components[definition.blockType];
          return (
            <Box key={i} mt={i > 0 ? 6 : 0}>
              <Component key={i} leadBlockDefinition={definition} />
            </Box>
          );
        })}
      </>
    );
  }
);
