import { AppStore } from "../../../App.store";
import {
  TAccountStatsData,
  TAccountStatsDataRaw,
  TComplexLeadStatRaw,
  TLeaderboardOfficeStats,
  TLeaderboardOfficeStatsByLeadType,
  TLeadsByOffice,
  TLeadsByOfficeStats,
  TLeadsStats,
  TOfficeLeaderboardDataPoint,
} from "types/account-analytics.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountAnalyticsFiltersStore } from "./AccountAnalyticsFilters.store";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { keyBy } from "lodash";
import { TSerializedDateRange } from "types/date.type";
import { AnalyticsApi } from "api/analytics.api";

const analyticsApi = new AnalyticsApi();

export class AccountAnalyticsLeaderboardStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore;
  private statsDataRawData: TAccountStatsDataRaw | null;
  public totalLeadContacts: number;
  private readonly leaderboardDataRaw: IObservableArray<
    TOfficeLeaderboardDataPoint
  >;
  private readonly leadsByOfficeDataRaw: IObservableArray<TLeadsByOffice>;

  constructor(
    root: AppStore,
    accountId: number,
    accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountAnalyticsFiltersStore = accountAnalyticsFiltersStore;
    this.statsDataRawData = null;
    this.totalLeadContacts = 0;
    this.leaderboardDataRaw = observable.array<TOfficeLeaderboardDataPoint>();
    this.leadsByOfficeDataRaw = observable.array<TLeadsByOffice>();
  }

  get statsDataRaw() {
    return this.statsDataRawData;
  }

  get leaderboardDataRawArray() {
    return this.leaderboardDataRaw.slice();
  }

  get leadsByOfficeDataRawArray() {
    return this.leadsByOfficeDataRaw.slice();
  }

  get statsDataFilteredByOffices(): TAccountStatsData | null {
    const rawData = this.statsDataRawData;
    if (!rawData) return null;

    const sourceStatsReducer = (
      accumulator: TLeadsStats<LeadSourceEnum>,
      officeStat: TComplexLeadStatRaw<LeadSourceEnum>
    ) => {
      const keys = Object.keys(officeStat.stats) as LeadSourceEnum[];
      keys.forEach(key => {
        if (!accumulator[key] || !accumulator[key].total) {
          accumulator[key] = { total: 0, hosts: {} };
        }
        accumulator[key].total += officeStat.stats[key].total || 0;
        const subKeys = Object.keys(
          officeStat.stats[key].hosts
        ) as (keyof TLeadsStats<LeadSourceEnum>)[];
        if (subKeys.length > 0) {
          subKeys.forEach(subKey => {
            if (!accumulator[key].hosts[subKey]) {
              accumulator[key].hosts[subKey] = 0;
            }
            accumulator[key].hosts[subKey] +=
              officeStat.stats[key].hosts[subKey] || 0;
          });
        }
      });
      return accumulator;
    };

    const typeStatsReducer = (
      accumulator: TLeadsStats<LeadTypeEnum>,
      officeStat: TComplexLeadStatRaw<LeadTypeEnum>
    ) => {
      const keys = Object.keys(officeStat.stats) as LeadTypeEnum[];
      keys.forEach(key => {
        if (!accumulator[key] || !accumulator[key].total) {
          accumulator[key] = { total: 0, hosts: {} };
        }
        accumulator[key].total += officeStat.stats[key].total || 0;
      });
      return accumulator;
    };

    const filteredByOfficeIds = this.accountAnalyticsFiltersStore
      .officeIdsArray;

    const totalLeads = rawData.totalLeads
      .filter(
        leadsByOffice =>
          !filteredByOfficeIds.length ||
          filteredByOfficeIds.includes(leadsByOffice.officeId)
      )
      .reduce((accumulator, officeStat) => accumulator + officeStat.count, 0);
    const leadsByDay = rawData.leadsByDay.map(leadByDayByOffice => {
      return {
        date: leadByDayByOffice.date,
        leads: leadByDayByOffice.leads
          .filter(
            lead =>
              !filteredByOfficeIds.length ||
              filteredByOfficeIds.includes(lead.officeId)
          )
          .reduce(
            (accumulator, officeStat) => accumulator + officeStat.count,
            0
          ),
      };
    });
    const leadsBySource = rawData.leadsBySource
      .filter(
        leadsBySourceByOffice =>
          !filteredByOfficeIds.length ||
          filteredByOfficeIds.includes(leadsBySourceByOffice.officeId)
      )
      .reduce((accumulator, officeStat) => {
        return sourceStatsReducer(accumulator, officeStat);
      }, {} as TLeadsStats<LeadSourceEnum>);

    const leadsByType = rawData.leadsByType
      .filter(
        leadsByTypeByOffice =>
          !filteredByOfficeIds.length ||
          filteredByOfficeIds.includes(leadsByTypeByOffice.officeId)
      )
      .reduce((accumulator, officeStat) => {
        return typeStatsReducer(accumulator, officeStat);
      }, {} as TLeadsStats<LeadTypeEnum>);
    const leadsByTypeByDay = rawData.leadsByTypeByDay.map(
      leadByTypeByDayByOffice => {
        return {
          date: leadByTypeByDayByOffice.date,
          leads: leadByTypeByDayByOffice.leads
            .filter(
              lead =>
                !filteredByOfficeIds.length ||
                filteredByOfficeIds.includes(lead.officeId)
            )
            .reduce((accumulator, officeStat) => {
              return typeStatsReducer(accumulator, officeStat);
            }, {} as TLeadsStats<LeadTypeEnum>),
        };
      }
    );
    const leadsBySourceByDay = rawData.leadsBySourceByDay.map(
      leadBySourceByDayByOffice => {
        return {
          date: leadBySourceByDayByOffice.date,
          leads: leadBySourceByDayByOffice.leads
            .filter(
              lead =>
                !filteredByOfficeIds.length ||
                filteredByOfficeIds.includes(lead.officeId)
            )
            .reduce((accumulator, officeStat) => {
              return sourceStatsReducer(accumulator, officeStat);
            }, {} as TLeadsStats<LeadSourceEnum>),
        };
      }
    );
    const leadsBySourceCount = {
      previous30Days: rawData.leadsBySourceCount.previous30Days
        .filter(
          lead =>
            !filteredByOfficeIds.length ||
            filteredByOfficeIds.includes(lead.officeId)
        )
        .reduce((accumulator, officeStat) => {
          return sourceStatsReducer(accumulator, officeStat);
        }, {} as TLeadsStats<LeadSourceEnum>),
      previous60Days: rawData.leadsBySourceCount.previous60Days
        .filter(
          lead =>
            !filteredByOfficeIds.length ||
            filteredByOfficeIds.includes(lead.officeId)
        )
        .reduce((accumulator, officeStat) => {
          return sourceStatsReducer(accumulator, officeStat);
        }, {} as TLeadsStats<LeadSourceEnum>),
    };
    const leadsByTypeCount = {
      previous30Days: rawData.leadsByTypeCount.previous30Days
        .filter(
          lead =>
            !filteredByOfficeIds.length ||
            filteredByOfficeIds.includes(lead.officeId)
        )
        .reduce((accumulator, officeStat) => {
          return typeStatsReducer(accumulator, officeStat);
        }, {} as TLeadsStats<LeadTypeEnum>),
      previous60Days: rawData.leadsByTypeCount.previous60Days
        .filter(
          lead =>
            !filteredByOfficeIds.length ||
            filteredByOfficeIds.includes(lead.officeId)
        )
        .reduce((accumulator, officeStat) => {
          return typeStatsReducer(accumulator, officeStat);
        }, {} as TLeadsStats<LeadTypeEnum>),
    };

    return {
      totalLeads,
      leadsByDay,
      leadsBySource,
      leadsByType,
      leadsByTypeByDay,
      leadsBySourceByDay,
      leadsBySourceCount,
      leadsByTypeCount,
    };
  }

  get leaderboardDataAggregatedByOfficeGroupsArray(): TOfficeLeaderboardDataPoint[] {
    const officeGroupStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].accountOfficeGroupsStore;
    const officeGroupsArray = officeGroupStore.accountOfficeGroupsArray;

    return officeGroupsArray.map(group => {
      const filteredData = this.leaderboardDataFilteredByOfficesArray.filter(
        office => group.officeIds.includes(office.office.id)
      );

      const aggregatedStats = filteredData.reduce(
        (acc: TLeaderboardOfficeStatsByLeadType, item) => {
          const keys = Object.keys(item.stats) as LeadTypeEnum[];

          keys.forEach(key => {
            acc[key] = acc[key] ?? {
              total: 0,
              business: 0,
              noBusiness: 0,
              speedScoreSum: 0,
            };

            const subKeys = Object.keys(
              item.stats[key]!
            ) as (keyof TLeaderboardOfficeStats)[];

            subKeys.forEach(subKey => {
              acc[key]![subKey] =
                (acc[key]![subKey] ?? 0) + item.stats[key]![subKey]!;
            });
          });

          return acc;
        },
        {}
      );

      return {
        office: {
          id: group.id,
          name: officeGroupStore.officeGroupMapById[group.id]?.name,
        },
        stats: aggregatedStats,
      };
    });
  }

  get leaderboardDataFilteredByOfficesArray(): TOfficeLeaderboardDataPoint[] {
    const leaderboardData = this.leaderboardDataRawArray;
    const filteredByOfficeIds = this.accountAnalyticsFiltersStore
      .officeIdsArray;

    if (!!filteredByOfficeIds.length) {
      return leaderboardData.filter(leaderboardDataPerOffice =>
        filteredByOfficeIds.includes(leaderboardDataPerOffice.office.id)
      );
    } else {
      return leaderboardData;
    }
  }

  get leadsByOfficeDataFilteredByOfficesArray(): TLeadsByOffice[] {
    const leadsByOfficeData = this.leadsByOfficeDataRawArray;
    const filteredByOfficeIds = this.accountAnalyticsFiltersStore
      .officeIdsArray;

    if (!!filteredByOfficeIds.length) {
      return leadsByOfficeData.filter(leadsByOffice =>
        filteredByOfficeIds.includes(leadsByOffice.officeId)
      );
    } else {
      return leadsByOfficeData;
    }
  }

  get leadsByOfficeDataAggregatedByOfficeGroups(): TLeadsByOffice[] {
    const officeGroupsArray = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].accountOfficeGroupsStore.accountOfficeGroupsArray;

    return officeGroupsArray.map(group => {
      const officesInGroup = this.leadsByOfficeDataFilteredByOfficesArray.filter(
        office => group.officeIds.includes(office.officeId)
      );

      const aggregatedStats = officesInGroup.reduce(
        (acc: TLeadsByOfficeStats[], office) => {
          office.stats.forEach(stat => {
            const existingStatIndex = acc.findIndex(
              accStat =>
                accStat.type === stat.type && accStat.stage === stat.stage
            );

            if (existingStatIndex !== -1) {
              acc[existingStatIndex].count += stat.count;
            } else {
              acc.push({ ...stat });
            }
          });
          return acc;
        },
        []
      );
      return {
        officeId: group.id,
        officeName: group.name,
        stats: aggregatedStats,
      };
    });
  }

  get leadsByOfficeGroupDataFilteredByOfficesMap() {
    return keyBy(
      this.leadsByOfficeDataAggregatedByOfficeGroups,
      dataPoint => dataPoint.officeId
    );
  }

  get leadsByOfficeDataFilteredByOfficesMap() {
    return keyBy(
      this.leadsByOfficeDataFilteredByOfficesArray,
      dataPoint => dataPoint.officeId
    );
  }

  private setStatsDataRawData(data: TAccountStatsDataRaw) {
    const {
      totalLeads,
      leadsByDay,
      leadsBySource,
      leadsByType,
      leadsByTypeByDay,
      leadsBySourceByDay,
      leadsBySourceCount,
      leadsByTypeCount,
    } = data;

    this.statsDataRawData = {
      totalLeads,
      leadsByDay,
      leadsBySource,
      leadsByType,
      leadsByTypeByDay,
      leadsBySourceByDay,
      leadsBySourceCount,
      leadsByTypeCount,
    };
  }

  public setTotalLeadContacts(totalLeadContact: number) {
    this.totalLeadContacts = totalLeadContact;
  }

  public setLeaderboardStats(leaderboardData: TOfficeLeaderboardDataPoint[]) {
    this.leaderboardDataRaw.replace(leaderboardData);
  }

  public setLeadsByOfficeStats(leadsByOffice: TLeadsByOffice[]) {
    this.leadsByOfficeDataRaw.replace(leadsByOffice);
  }

  public async loadAnalyticsData(dateRange: TSerializedDateRange) {
    const data = await Promise.all([
      analyticsApi.getStats(this.accountId, dateRange),
      analyticsApi.getLeaderboardStats(this.accountId, dateRange),
      analyticsApi.getLeadsByOfficeStats(this.accountId, dateRange),
    ]);

    this.setStatsDataRawData(data[0]);
    this.setLeaderboardStats(data[1]);
    this.setLeadsByOfficeStats(data[2]);
  }

  public async loadTotalLeadContacts(
    dateRange: TSerializedDateRange,
    officeIds: number[]
  ) {
    const totalLeadContacts = await analyticsApi.getTotalLeadContacts(
      this.accountId,
      dateRange,
      officeIds
    );
    this.setTotalLeadContacts(totalLeadContacts);
  }

  public async loadLeagueData(dateRange: TSerializedDateRange) {
    const data = await Promise.all([
      analyticsApi.getLeaderboardStats(this.accountId, dateRange),
      analyticsApi.getLeadsByOfficeStats(this.accountId, dateRange),
    ]);

    this.setLeaderboardStats(data[0]);
    this.setLeadsByOfficeStats(data[1]);
  }
}
