import React from "react";
import { IOfficeLeaderboardStats } from "utils/office-leaderboard.utils";
import { OfficeLeagueStatsTable } from "./OfficeLeagueStatsTable";
import { GaugeStat } from "components/stats/GaugeStat";
import { Box } from "@chakra-ui/react";
import { NA } from "components";
import { observer } from "mobx-react";
import { TLeadsByOffice } from "types/account-analytics.type";
import { ResponseTimeTooltip } from "../../analytics/components/Leaderboards/OfficesLeaderboard/tooltips/ResponseTimeTooltip";
import { ConversionRateTooltip } from "../../analytics/components/Leaderboards/OfficesLeaderboard/tooltips/ConversionRateTooltip";
import { OverallScoreTooltip } from "../../analytics/components/Leaderboards/OfficesLeaderboard/tooltips/OverallScoreTooltip";

interface IProps {
  officeStats: IOfficeLeaderboardStats;
  officeLeadsData?: TLeadsByOffice;
}

export const OfficeLeagueStats: React.FC<IProps> = observer(
  ({ officeStats, officeLeadsData }) => {
    if (!officeLeadsData || !officeLeadsData.stats.length)
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <NA />
        </Box>
      );

    return (
      <Box width={"100%"} px={4} py={8}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"nowrap"}
          mb={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <GaugeStat
            value={officeStats.averageResponseTime}
            label={"RESPONSE TIME"}
            tooltip={<ResponseTimeTooltip />}
          />
          <GaugeStat
            value={officeStats.conversionRate}
            label={"BUSINESS WON"}
            tooltip={<ConversionRateTooltip />}
          />
          <GaugeStat
            value={officeStats.overallScore}
            label={"OVERALL SCORE"}
            tooltip={<OverallScoreTooltip />}
          />
        </Box>
        <Box width={"100%"} mt={8}>
          <OfficeLeagueStatsTable officeLeadsData={officeLeadsData} />
        </Box>
      </Box>
    );
  }
);
