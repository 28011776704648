import { Table } from "components/table";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TPropertyTableData } from "types/property.type";
import { propertiesTableColumnDef } from "./propertiesTableColumnDef";
import { PROPERTY_DETAILS_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";

const PAGE_SIZE = 50;

interface IProps {
  accountStore: UserAccountStore;
}

export const PropertiesTable: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const history = useHistory();
  const {
    accountPropertiesStore: {
      propertiesFilterStore,
      selectedPropertyId,
      propertiesTableData,
      loadProperties,
      meta,
    },
    portalsMap,
    account: { id, logoUrl },
    accountLeadSourcesStore: { leadSourcesMapBySource },
  } = accountStore;
  const { searchQuery } = propertiesFilterStore;

  const fetchProperties = useCallback(
    async (pageSize: number, page?: number) => {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      try {
        await loadProperties(searchQuery, pageSize, page);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    },
    [loadProperties, searchQuery]
  );

  const goToPropertyDetails = useCallback(
    (propertyId: number) => {
      history.push(
        PROPERTY_DETAILS_ROUTE.replace(":id", propertyId.toString())
      );
    },
    [history]
  );

  useEffect(() => {
    fetchProperties(PAGE_SIZE);
  }, [fetchProperties]);

  const columns = useMemo(
    () => propertiesTableColumnDef(leadSourcesMapBySource, portalsMap, logoUrl),
    [leadSourcesMapBySource, logoUrl, portalsMap]
  );

  return (
    <Table<TPropertyTableData>
      key={id}
      enableRowSelection={true}
      getRowId={row => `${row.id}`}
      selectedRowId={selectedPropertyId?.toString()}
      onRowClick={property => goToPropertyDetails(property.id)}
      onPageChange={({ pageIndex }) => fetchProperties(PAGE_SIZE, pageIndex)}
      pageIndex={meta.currentPage}
      pageSize={PAGE_SIZE}
      total={meta.count}
      data={propertiesTableData}
      loadingStatus={loadingStatus}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
      columns={columns}
    />
  );
});
