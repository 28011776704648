import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC } from "react";
import { Accordion, Box } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { QuestionnaireToolPageSettingsSelectedListSection } from "../QuestionnaireToolPageSettingsSelectedListSection/QuestionnaireToolPageSettingsSelectedListSection";
import { QuestionnaireToolPageSettingsQuestionnairesBranchingContent } from "./QuestionnaireToolPageSettingsQuestionnairesBranchingContent";
import { ReactFlowProvider } from "reactflow";
import { QuestionnaireToolPageAccordionSections } from "constants/questionnaire-tool-page";
import { QuestionnaireToolPageSettingsQuestionnairesBranchingSelectedListSectionHeader } from "./QuestionnaireToolPageSettingsQuestionnairesBranchingSelectedListSectionHeader";

interface IProps {
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsQuestionnairesBranching: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore }) => {
    return (
      <Box display={"flex"} flexDirection={"row"} height={"100%"}>
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.SUCCESS && (
          <Box
            flexGrow={1}
            overflowY={"auto"}
            display={"flex"}
            justifyContent={"center"}
            px={10}
            py={"65px"}
          >
            <Accordion width={"100%"} maxW={"1200px"}>
              {QuestionnaireToolPageAccordionSections.map(leadType => (
                <ReactFlowProvider key={leadType}>
                  <QuestionnaireToolPageSettingsSelectedListSection
                    leadType={leadType}
                    questionnaireToolPageSettingsEditorStore={
                      questionnaireToolPageSettingsEditorStore
                    }
                    Header={
                      QuestionnaireToolPageSettingsQuestionnairesBranchingSelectedListSectionHeader
                    }
                    Content={
                      QuestionnaireToolPageSettingsQuestionnairesBranchingContent
                    }
                  />
                </ReactFlowProvider>
              ))}
            </Accordion>
          </Box>
        )}
      </Box>
    );
  }
);
