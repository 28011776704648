import { TCreateOrUpdatePageData, TPageData } from "types/page.type";
import { makeAutoObservable } from "mobx";
import { AccountPagesApi } from "api/account-pages.api";

const pagesApi = new AccountPagesApi();
export class AccountQuestionnairePageStore {
  private readonly accountId: number;
  private pageData: TPageData;

  constructor(accountId: number, pageData: TPageData) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.pageData = pageData;
  }

  get page() {
    return this.pageData;
  }

  public async updatePage(data: Partial<TCreateOrUpdatePageData>) {
    const updatedPage = await pagesApi.update(
      this.accountId,
      this.page.id,
      data
    );
    this.upsertPage(updatedPage);
  }

  public upsertPage(pageData: TPageData) {
    this.pageData = pageData;
  }
}
