import {
  Avatar,
  AvatarProps,
  Box,
  Tag,
  TagLabel,
  TagLabelProps,
  TagProps,
} from "@chakra-ui/react";
import Styles from "../avatar/Avatar.module.scss";
import React, { FC } from "react";

interface IAvatarTagProps extends TagProps {
  label: string;
  labelStyling?: TagLabelProps;
  avatarUrl?: string;
  avatarStyling?: AvatarProps;
}

export const AvatarTag: FC<IAvatarTagProps> = ({
  label,
  labelStyling,
  avatarUrl,
  avatarStyling,
  ...rest
}) => {
  return (
    <Tag pl={0} size="md" borderRadius={"md"} {...rest}>
      <Box p={2} pr={2.5}>
        <Avatar
          src={avatarUrl}
          name={label}
          size={"xs"}
          rounded={"sm"}
          className={Styles.mdRoundedAvatar}
          {...avatarStyling}
        />
      </Box>
      <TagLabel textTransform={"capitalize"} {...labelStyling}>
        {label}
      </TagLabel>
    </Tag>
  );
};
