import { createIcon } from "@chakra-ui/react";
import { withIconProps } from "utils/hoc/chakra-svg-icon.hoc";

export const UnreadIconCreateIcon = createIcon({
  displayName: "UnreadIcon",
  viewBox: "0 0 13 11",
  path: (
    <path
      id="Polygon 1"
      d="M0.869344 1.53207C0.568883 0.656944 1.51585 -0.12177 2.31646 0.342076L12.209 6.07346C12.9329 6.49286 12.8524 7.56281 12.0739 7.86924L5.09264 10.6172C4.56257 10.8258 3.96555 10.5502 3.78057 10.0114L0.869344 1.53207Z"
    />
  ),
});

export const UnreadIcon = withIconProps(UnreadIconCreateIcon);
