import runtimeEnv from "@mars/heroku-js-runtime-env";
import { AuthApi } from "api/auth.api";
import { WebPushNotificationPermissionTypeEnum } from "../enums/web-push-notification-permission-type.enum";

const env = runtimeEnv();
const userApi = new AuthApi();

export const isWebPushNotificationStackSupported = (): boolean => {
  return (
    "PushManager" in window &&
    "BroadcastChannel" in window &&
    "serviceWorker" in navigator &&
    "showNotification" in ServiceWorkerRegistration.prototype
  );
};

export const requestWebPushNotificationPermission = async (): Promise<
  NotificationPermission | undefined
> => {
  if (isWebPushNotificationStackSupported()) {
    return Notification?.requestPermission();
  }
};

export const getWebPushNotificationPermission = ():
  | NotificationPermission
  | undefined => {
  if (isWebPushNotificationStackSupported()) {
    return Notification?.permission;
  }
};

export const sendMessageToWebPushWorker = async (message: any) => {
  if (isWebPushNotificationStackSupported()) {
    const serviceWorker = await navigator.serviceWorker.ready;
    serviceWorker?.active?.postMessage(message);
  }
};

export const removeWebPushSubscription = async () => {
  if (isWebPushNotificationStackSupported()) {
    try {
      const serviceWorker = await navigator.serviceWorker.ready;
      const subscription = await serviceWorker.pushManager.getSubscription();
      await subscription?.unsubscribe();
    } catch (err) {
      return console.log(err);
    }
  }
};

export const createWebPushSubscription = async () => {
  try {
    const serviceWorker = await navigator.serviceWorker.ready;

    await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: env.REACT_APP_WEB_PUSH_PUBLIC_VAPID_KEY,
    });

    const pushSub = await serviceWorker.pushManager.getSubscription();

    if (pushSub) {
      await userApi.createWebPushSubscription(pushSub);
    }
  } catch (err) {
    return console.log(err);
  }
};

export const createWebPushSubscriptionIfGranted = async () => {
  const notificationsPermission = getWebPushNotificationPermission();
  if (
    notificationsPermission === WebPushNotificationPermissionTypeEnum.GRANTED
  ) {
    await createWebPushSubscription();
  }
};
