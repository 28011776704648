import React, { FC, useMemo } from "react";
import { Box, Center, Tooltip } from "@chakra-ui/react";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../../SelectInputOptionBase";

export const LeadOfficeSelectOptionCondensed: FC<ISelectInputOptionProps<
  number
>> = ({ optionProps: { option, selectedOption, ...rest } }) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <Center>
        <Tooltip label={option.label} aria-label={option.label}>
          <Box
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            width={"160px"}
          >
            {option.label}
          </Box>
        </Tooltip>
      </Center>
    </SelectInputOptionBase>
  );
};
