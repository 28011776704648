import { observer } from "mobx-react";
import { Radio, VStack } from "@chakra-ui/react";
import { FormControlMeta } from "components";
import { TOfficeEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import React, { ChangeEvent, FC, useCallback } from "react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { EmailNotificationsForm } from "./EmailNotificationsForm/EmailNotificationsForm";

interface IProps {
  isEnabled: boolean;
  isChangePending: boolean;
  formName: string;
  setFormActions: (
    formName: string,
    formActions: { submitForm: () => Promise<void>; resetForm: () => void }
  ) => void;
  officeStore: AccountOfficeStore;
  accountStore: UserAccountStore;
}

export const OfficeInstantValuationToolEmailNotificationsForm: FC<IProps> = observer(
  ({
    isEnabled,
    isChangePending,
    formName,
    setFormActions,
    officeStore,
    accountStore,
  }) => {
    const officeEmailNotificationsConfiguration =
      officeStore.officeEmailNotificationsConfiguration;
    const accountEmailNotificationsConfiguration =
      accountStore.accountSettingsStore.accountEmailNotificationsConfiguration;

    const handleToggleEmailNotificationsConfig = useCallback(
      async (leadSourceTypeId: number, isOn: boolean) => {
        if (isEnabled) {
          await officeStore.updateOfficeEmailNotificationsConfiguration({
            leadSourceTypes: {
              [leadSourceTypeId]: isOn,
            },
          });
        }
      },
      [officeStore, isEnabled]
    );

    const handleToggleCustomEmailNotificationsSettings = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        if (isEnabled) {
          await officeStore.updateOfficeEmailNotificationsConfiguration({
            useOfficeLeadSourceTypes: !!event.target.value,
          });
        }
      },
      [officeStore, isEnabled]
    );

    if (
      !officeEmailNotificationsConfiguration ||
      !accountEmailNotificationsConfiguration
    )
      return null;

    return (
      <VStack spacing={8} align={"stretch"}>
        <VStack spacing={2} align={"stretch"}>
          <FormControlMeta label={"Select portals & sources configuration"} />
          <VStack spacing={4}>
            <Radio
              alignItems={"flex-start"}
              onChange={handleToggleCustomEmailNotificationsSettings}
              isChecked={
                !officeEmailNotificationsConfiguration?.useOfficeLeadSourceTypes
              }
              isDisabled={!isEnabled}
              value={""}
            >
              <FormControlMeta
                label={"Account-level"}
                description={
                  "Enables Instant Valuation Tool, and allows you to specify email addresses for every portal configured at the account level."
                }
              />
            </Radio>
            <Radio
              alignItems={"flex-start"}
              onChange={handleToggleCustomEmailNotificationsSettings}
              isChecked={
                !!officeEmailNotificationsConfiguration?.useOfficeLeadSourceTypes
              }
              isDisabled={!isEnabled}
              value={"true"}
            >
              <FormControlMeta
                label={"Office-level"}
                description={
                  "Enables Instant Valuation Tool, and allows you to specify email addresses for every portal configured under the Integrations tab of this office."
                }
              />
            </Radio>
          </VStack>
        </VStack>
        <VStack spacing={2} align={"stretch"}>
          <FormControlMeta label={"Configure email notifications"} />
          <EmailNotificationsForm
            isDisabled={
              !officeEmailNotificationsConfiguration.useOfficeLeadSourceTypes ||
              !isEnabled
            }
            isChangePending={isChangePending}
            formName={formName}
            setFormActions={setFormActions}
            emailNotificationsConfiguration={
              officeEmailNotificationsConfiguration.useOfficeLeadSourceTypes
                ? officeEmailNotificationsConfiguration
                : (accountEmailNotificationsConfiguration as TOfficeEmailNotificationsConfiguration)
            }
            onConfigToggle={handleToggleEmailNotificationsConfig}
            accountStore={accountStore}
            officeStore={officeStore}
          />
        </VStack>
      </VStack>
    );
  }
);
