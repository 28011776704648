import React, { FC, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { TLead } from "types/lead.type";

interface IProps {
  lead: TLead;
}

export const LeadJourneyCell: FC<IProps> = ({ lead }) => {
  const label = useMemo(() => {
    const leadJourneys = lead.journeys;
    if (leadJourneys && leadJourneys?.length > 0) {
      return leadJourneys?.slice(0, 2).join(", ");
    }

    return null;
  }, [lead.journeys]);

  const moreLabel = useMemo(() => {
    const leadJourneys = lead.journeys;
    if (leadJourneys && leadJourneys.length > 2) {
      return `+${leadJourneys.length - 2} more`;
    }
  }, [lead.journeys]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"start"}
      justifyContent={"center"}
      height={"100%"}
    >
      <Box>{!!label && label}</Box>
      <Box color={"leadpro.400"}>{!!moreLabel && moreLabel}</Box>
    </Box>
  );
};
