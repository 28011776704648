import { LeadStageEnum } from "enums/lead-stage.enum";
import {
  LEAD_SUBROUTE_ALL,
  LEAD_SUBROUTE_BUSINESS,
  LEAD_SUBROUTE_IN_PROGRESS,
  LEAD_SUBROUTE_NO_BUSINESS,
  LEAD_SUBROUTE_UNREAD,
} from "../constants/routes";

export const ALL_LEAD_SUBROUTES = [
  LEAD_SUBROUTE_BUSINESS,
  LEAD_SUBROUTE_IN_PROGRESS,
  LEAD_SUBROUTE_NO_BUSINESS,
  LEAD_SUBROUTE_UNREAD,
  LEAD_SUBROUTE_ALL,
];

export function leadSubrouteToStage(subroute: string): LeadStageEnum | null {
  if (subroute === LEAD_SUBROUTE_UNREAD) return LeadStageEnum.UNREAD;
  if (subroute === LEAD_SUBROUTE_IN_PROGRESS) return LeadStageEnum.IN_PROGRESS;
  if (subroute === LEAD_SUBROUTE_BUSINESS) return LeadStageEnum.BUSINESS;
  if (subroute === LEAD_SUBROUTE_NO_BUSINESS) return LeadStageEnum.NO_BUSINESS;

  return null;
}
