import React from "react";
import {
  ExternalLink,
  InternalLink,
  Message,
  StandardIconButton,
} from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Box, HStack, useClipboard, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { GUIDES_ROUTE } from "constants/routes";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const ZOOPLA_PUSH_SERVICE_GUIDE =
  "https://support.zoopla.co.uk/hc/en-gb/articles/4415890363281-Leads-API-and-Push-Delivery-Service";
const ZOOPLA_ENDPOINT = env.REACT_APP_NEW_API_BASE_URL + "/zoopla/leads";

export const ZooplaPushServiceDetails = () => {
  const { onCopy, hasCopied } = useClipboard(ZOOPLA_ENDPOINT);
  return (
    <Message status={AlertStatusEnum.INFO} mb={5}>
      <VStack spacing={2} align={"flex-start"}>
        <Box>
          You can receive Zoopla leads into your LeadPro Dashboard through their{" "}
          <ExternalLink
            href={ZOOPLA_PUSH_SERVICE_GUIDE}
            label={"Push Service"}
          />
          .
        </Box>
        <Box>{`In order to do that you would need to provide Zoopla with the following endpoint:`}</Box>
        <HStack spacing={1} pl={5}>
          <Box fontWeight={"bold"}>{ZOOPLA_ENDPOINT}</Box>
          <StandardIconButton
            onClick={onCopy}
            aria-label={`copy-endpoint`}
            colorScheme={hasCopied ? "green" : undefined}
            icon={<FontAwesomeIcon icon={faCopy} />}
          />
        </HStack>
        <Box>
          and the API key that can be found below and is unique to your account.
        </Box>
        <InternalLink href={`${GUIDES_ROUTE}/zoopla-api-integration`} mt={5}>
          Read the guide.
        </InternalLink>
      </VStack>
    </Message>
  );
};
