const isBlobOrFile = (obj: any): obj is Blob | File => {
  return obj instanceof Blob || obj instanceof File;
};

export const extractTextFromFileBlob = async (
  fileBlob?: Blob
): Promise<string> => {
  if (!isBlobOrFile(fileBlob)) {
    throw new Error("The provided object is neither a Blob nor a File.");
  }
  return await fileBlob.text();
};
