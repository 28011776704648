import React, { FC, useCallback, useEffect, useMemo } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Accordion, Box, Center, HStack, VStack } from "@chakra-ui/react";
import {
  Error,
  Loader,
  Message,
  Paginator,
  SingleSelectInput,
  StandardIconButton,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { QuestionnaireToolPageResponsesStore } from "./QuestionnaireToolPageResponses.store";
import { QuestionnaireToolPageResponseItem } from "./QuestionnaireToolPageResponseItem";
import { observer } from "mobx-react";
import { AccountQuestionnairePagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountQuestionnairePagesStore/AccountQuestionnairePages.store";
import { AlertStatusEnum } from "enums/alert-status.enum";

const PAGE_SIZE = 10;

interface IProps {
  accountQuestionnairePagesStore: AccountQuestionnairePagesStore;
  pageId: number | null;
}

export const QuestionnaireToolPageResponses: FC<IProps> = observer(
  ({ accountQuestionnairePagesStore, pageId }) => {
    const questionnaireToolPageResponsesStore = useLocalStore(
      QuestionnaireToolPageResponsesStore,
      {
        accountQuestionnairePagesStore,
        pageId,
      }
    );

    const showWhenPendingOrSuccess = useMemo(() => {
      const listData = questionnaireToolPageResponsesStore.listData;
      if (!questionnaireToolPageResponsesStore.selectedQuestionnaireId) {
        return (
          <Message status={AlertStatusEnum.INFO}>
            <Center width={"100%"}>Please select questionnaire type.</Center>
          </Message>
        );
      }

      if (!listData) return null;

      if (!!listData.data.length) {
        return (
          <>
            <Accordion width={"100%"} allowToggle={true}>
              {listData.data.map(response => (
                <QuestionnaireToolPageResponseItem
                  key={response.id}
                  response={response}
                />
              ))}
            </Accordion>
            <Paginator
              border={"none"}
              total={listData.total}
              pageSize={listData.pageSize}
              pageIndex={listData.pageIndex}
              canPreviousPage={listData.canPreviousPage}
              canNextPage={listData.canNextPage}
              gotoPage={questionnaireToolPageResponsesStore.handleGoToPage}
            />
          </>
        );
      } else if (!listData.data.length) {
        return (
          <Message status={AlertStatusEnum.INFO}>
            <Center width={"100%"}>No data.</Center>
          </Message>
        );
      }
    }, [
      questionnaireToolPageResponsesStore.listData,
      questionnaireToolPageResponsesStore.selectedQuestionnaireId,
      questionnaireToolPageResponsesStore.handleGoToPage,
    ]);

    const handleInitialFetch = useCallback(() => {
      questionnaireToolPageResponsesStore.loadData(0, PAGE_SIZE);
    }, [questionnaireToolPageResponsesStore]);

    const onQuestionnaireChange = useCallback(
      id => {
        questionnaireToolPageResponsesStore.setSelectedQuestionnaireId(id);
        questionnaireToolPageResponsesStore.loadData(0, PAGE_SIZE);
      },
      [questionnaireToolPageResponsesStore]
    );

    useEffect(() => {
      questionnaireToolPageResponsesStore.loadData(0, PAGE_SIZE);
    }, [questionnaireToolPageResponsesStore, pageId]);

    return (
      <VStack
        width={"100%"}
        position={"relative"}
        minHeight={"400px"}
        p={"65px"}
        overflowY={"auto"}
        height={"100%"}
        spacing={0}
      >
        <HStack
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={"45px"}
          width={"100%"}
        >
          <Box>
            <Box fontSize={"20px"} fontWeight={"bold"}>
              Responses
            </Box>
            <Box color={"leadpro.500"} fontSize={"sm"}>
              These are the responses captured from your lead questionnaire.
            </Box>
          </Box>
          <HStack spacing={4}>
            <SingleSelectInput<number>
              value={
                questionnaireToolPageResponsesStore.selectedQuestionnaireId ||
                null
              }
              onChange={onQuestionnaireChange}
              options={questionnaireToolPageResponsesStore.questionnaireOptions}
              placeholder={"questionnaire type"}
              width={"250px"}
            />
            <StandardIconButton
              onClick={handleInitialFetch}
              isDisabled={
                questionnaireToolPageResponsesStore.loadingStatus ===
                ApiRequestStatusEnum.PENDING
              }
              aria-label={"reload-requests"}
              icon={<FontAwesomeIcon icon={faArrowRotateLeft} fontSize={24} />}
            />
          </HStack>
        </HStack>
        {questionnaireToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && (
          <Box position={"relative"} height={"100%"}>
            <Error />
          </Box>
        )}
        {questionnaireToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {(questionnaireToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING ||
          questionnaireToolPageResponsesStore.loadingStatus ===
            ApiRequestStatusEnum.SUCCESS) &&
          showWhenPendingOrSuccess}
      </VStack>
    );
  }
);
