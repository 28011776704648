import { Box } from "@chakra-ui/react";
import React, {
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  useCallback,
  useState,
} from "react";
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR } from "styles/input-default-pseudobox-behaviour";
import { TagInputControls } from "./TagInputControls";

const sizes = {
  sm: {
    height: "35px",
    fontSize: "sm",
    padding: 3,
  },
  base: {
    height: "42px",
    fontSize: "base",
    padding: 4,
  },
};

type SizeConfig = typeof sizes;

export interface ITagInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder: string;
  size?: keyof SizeConfig;
  onBlur?: () => void;
  isDisabled?: boolean;
  clearable?: boolean;
}
export const TagInput: React.FC<ITagInputProps> = ({
  value,
  onChange,
  placeholder,
  size = "base",
  onBlur,
  clearable,
  isDisabled,
}) => {
  const [intermediateValue, setIntermediateValue] = useState("");

  const handleIntermediateValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIntermediateValue(event.target.value);
    },
    []
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key?.toLowerCase();

      if (key === "enter" || key === "," || key === ";") {
        event.preventDefault();
        event.stopPropagation();
        if (intermediateValue) {
          onChange([...value, intermediateValue.trim()]);
          setIntermediateValue("");
        }
      }
    },
    [intermediateValue, onChange, value]
  );

  const handleBlur = useCallback(() => {
    if (intermediateValue) {
      onChange([...value, intermediateValue.trim()]);
      setIntermediateValue("");
    }
    onBlur?.();
  }, [intermediateValue, onBlur, onChange, value]);

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const rawValues = event.clipboardData?.getData("text") || "";
      const refinedValues = rawValues
        .split(/[;,]+/)
        .map((val: string) => val.trim())
        .filter((val: string) => !!val);
      setIntermediateValue("");
      onChange([...value, ...refinedValues]);
    },
    [onChange, value, setIntermediateValue]
  );

  return (
    <Box
      opacity={isDisabled ? 0.4 : 1}
      cursor={isDisabled ? "not-allowed" : "auto"}
    >
      <Box
        pointerEvents={isDisabled ? "none" : undefined}
        display={"flex"}
        position={"relative"}
        borderRadius="md"
        minHeight={sizes[size].height}
        fontSize={sizes[size].fontSize}
        pl={sizes[size].padding}
        pr={"28px"}
        py={1}
        border="1px solid"
        borderColor="gray.300"
        {...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR}
      >
        <TagInputControls
          onChange={onChange}
          values={value}
          clearable={clearable}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          flexBasis={"100%"}
        >
          {value.map((val, index) => {
            return (
              <Tag key={index} size={"sm"} mr={1} my={1}>
                <TagLabel>{val}</TagLabel>
                <TagCloseButton
                  isDisabled={isDisabled}
                  type={"button"}
                  onClick={() => onChange(value.filter((d, i) => i !== index))}
                />
              </Tag>
            );
          })}

          <input
            disabled={isDisabled}
            value={intermediateValue}
            onBlur={handleBlur}
            onChange={handleIntermediateValue}
            onKeyDown={handleKeyDown}
            placeholder={!value.length ? placeholder : ""}
            onPaste={handlePaste}
            style={{
              width: "100%",
              outline: "none",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TagInput;
