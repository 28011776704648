import { makeAutoObservable, toJS } from "mobx";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountFormPagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountFormPagesStore/AccountFormPages.store";
import { generateFormToolDefaultPageData } from "utils/form-tool-page.utils";
import {
  formToolPageSettingsValidationSchema,
  TFormToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import { TCreateOrUpdatePageData, TPageFormConfig } from "types/page.type";
import { IPageToolPageSettingsEditorStore } from "interfaces/IPageToolPageSettingsEditorStore";
import { FormToolPageWIPFormStore } from "./FormToolPageWIPForm.store";

interface IFormToolPageSettingsQuestionsEditorStore {
  pageId: number | null;
  accountFormPagesStore: AccountFormPagesStore;
}

export class FormToolPageSettingsEditorStore
  implements IPageToolPageSettingsEditorStore {
  public loadingStatus: ApiRequestStatusEnum;
  public isSubmitting: boolean;
  public readonly pageId: number | null;
  private readonly accountFormPagesStore: AccountFormPagesStore;
  private wipPage: TFormToolSettingsFieldValues;
  public wipFormStore: FormToolPageWIPFormStore;

  constructor({
    pageId,
    accountFormPagesStore,
  }: IFormToolPageSettingsQuestionsEditorStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loadingStatus = ApiRequestStatusEnum.NONE;
    this.isSubmitting = false;
    this.pageId = pageId;
    this.accountFormPagesStore = accountFormPagesStore;
    this.wipPage = generateFormToolDefaultPageData();
    this.wipFormStore = new FormToolPageWIPFormStore();
  }

  get page() {
    return !!this.pageId
      ? this.accountFormPagesStore.accountPagesMap[this.pageId]
      : null;
  }

  get pageStore() {
    return !!this.page
      ? this.accountFormPagesStore.accountFormPageStoresMap[this.page.id]
      : null;
  }

  get pageFormStore() {
    return !!this.page
      ? this.accountFormPagesStore.accountFormPageFormStoresMap[
          this.page.pageFormConfig.formId
        ]
      : null;
  }

  get wipPageJS() {
    return toJS(this.wipPage);
  }

  get isWIPPageValid() {
    return formToolPageSettingsValidationSchema.isValidSync(this.wipPageJS);
  }

  get hasWIPErrors() {
    return (
      !this.isWIPPageValid ||
      !this.wipFormStore.isWIPFormSchemaValid ||
      !this.wipFormStore.isWIPFormActionsValid
    );
  }

  get wipPageDataForCreateOrUpdate(): Partial<TCreateOrUpdatePageData> {
    return {
      name: this.wipPageJS.name,
      color: this.wipPageJS.color || null,
      facebookPixelId: this.wipPageJS.facebookPixelId || null,
      googleAnalyticsId: this.wipPageJS.googleAnalyticsId || null,
      googleAdsConversionId: this.wipPageJS.googleAdsConversionId || null,
      googleAdsConversionLabelId:
        this.wipPageJS.googleAdsConversionLabelId || null,
      host: this.wipPageJS.host,
      privacyPolicyLink: this.wipPageJS.privacyPolicyLink || null,
      gdprCheckboxEnabled: this.wipPageJS.gdprCheckboxEnabled,
      gdprCheckboxLabel: this.wipPageJS.gdprCheckboxLabel || null,
      gdprCheckboxDescription: this.wipPageJS.gdprCheckboxDescription || null,
      gdprCheckboxAcceptanceRequired: this.wipPageJS
        .gdprCheckboxAcceptanceRequired,
      photoFile: this.wipPageJS.photoFile || undefined,
      customLogoFile: this.wipPageJS.customLogoFile || undefined,
      pageFormConfig: {
        formId: this.pageStore?.page.pageFormConfig.formId,
        customThankYouPageHeader:
          this.wipPageJS.customThankYouPageHeader || null,
        customThankYouPageBody: this.wipPageJS.customThankYouPageBody || null,
        customThankYouPageCtaLabel:
          this.wipPageJS.customThankYouPageCtaLabel || null,
        customThankYouPageCtaUrl:
          this.wipPageJS.customThankYouPageCtaUrl || null,
      },
    };
  }

  public setWipPage(pageData: TFormToolSettingsFieldValues) {
    this.wipPage = pageData;
  }

  public async loadData() {
    if (!this.pageId) {
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      return;
    }

    try {
      this.setLoadingStatus(ApiRequestStatusEnum.PENDING);
      await this.accountFormPagesStore.loadPage(this.pageId);

      if (!!this.page) {
        await this.accountFormPagesStore.loadForm(
          this.page.pageFormConfig.formId
        );
      }
      if (!!this.page) {
        this.setWipPage(generateFormToolDefaultPageData(this.page));
        this.wipFormStore.setWIPFormSchema(
          this.pageFormStore?.form.schema || null
        );
        this.wipFormStore.setWipFormActions(
          this.pageFormStore?.form.actions || []
        );
      }
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (error) {
      this.setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }

  public async updatePageAndPageComponents() {
    if (this.hasWIPErrors) return;

    try {
      this.setIsSubmitting(true);
      await Promise.all([this.updateForm(), this.updatePage()]);
    } catch (error) {
      throw error;
    } finally {
      this.setIsSubmitting(false);
    }
  }

  public async createPageAndPageComponents(): Promise<number | undefined> {
    if (this.hasWIPErrors) return;

    try {
      this.setIsSubmitting(true);
      await this.checkIfHostIsAvailable();
      const newFormId = await this.createForm();
      return await this.createPage(newFormId);
    } catch (error) {
      throw error;
    } finally {
      this.setIsSubmitting(false);
    }
  }

  private setLoadingStatus(loadingStatus: ApiRequestStatusEnum) {
    this.loadingStatus = loadingStatus;
  }

  private setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  private async checkIfHostIsAvailable() {
    await this.accountFormPagesStore.checkIfHostIsAvailable(
      this.wipPageJS.host
    );
  }

  public async createForm(): Promise<number> {
    return await this.accountFormPagesStore.createForm({
      schema: this.wipFormStore.wipFormSchemaJS,
      actions: this.wipFormStore.wipFormActionsJS,
    });
  }

  private async createPage(newFormId: number): Promise<number> {
    const partialPageFormConfig: Partial<TPageFormConfig> = {
      formId: newFormId,
    };
    const page = {
      ...this.wipPageDataForCreateOrUpdate,
      pageFormConfig: {
        ...this.wipPageDataForCreateOrUpdate.pageFormConfig,
        ...(partialPageFormConfig as TPageFormConfig),
      },
    };

    return await this.accountFormPagesStore.createPage(page);
  }

  private async updateForm() {
    if (!this.pageFormStore?.form) return;

    await this.pageFormStore.updateForm({
      schema: this.wipFormStore.wipFormSchemaJS,
      actions: this.wipFormStore.wipFormActionsJS,
    });
  }

  private async updatePage() {
    if (!this.pageStore) return;

    await this.pageStore.updatePage(this.wipPageDataForCreateOrUpdate);
  }
}
