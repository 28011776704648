import badge10 from "./badges/10.png";
import badge50 from "./badges/50.png";
import badge100 from "./badges/100.png";
import badge250 from "./badges/250.png";
import badge500 from "./badges/500.png";
import badge1000 from "./badges/1000.png";

export interface AchievementsConfigItem {
  threshold: number;
  text: string;
  badge: string;
}

export const achievementsConfig = [
  {
    threshold: 10,
    text: "You’ve managed 10 leads, that’s a great start!",
    badge: badge10,
  },
  {
    threshold: 50,
    text: "Wow, brilliant work! You’ve managed 50 leads.",
    badge: badge50,
  },
  {
    threshold: 100,
    text: "Triple digits, you’re a machine! That is 100 leads managed.",
    badge: badge100,
  },
  {
    threshold: 250,
    text: "There is no stopping you, 250 leads managed, congratulations!",
    badge: badge250,
  },
  {
    threshold: 500,
    text:
      "It looks like you’re a veteran agent, you’ve managed over 500 leads, incredible work!",
    badge: badge500,
  },
  {
    threshold: 1000,
    text:
      "It’s time to ask for a promotion, you’ve just hit 1000 leads managed, you are a power agent!",
    badge: badge1000,
  },
];
