import { Box } from "@chakra-ui/react";
import { FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FC } from "react";
import { Control } from "react-hook-form";
import { TReapitSyncFormFieldValues as TFieldValues } from "../reapit-sync-form-field.type";

interface IProps {
  control: Control<TFieldValues, any>;
  isApplicant: boolean;
}

export const LeadAddressForm: FC<IProps> = ({ control, isApplicant }) => {
  return (
    <>
      <Box
        width={"full"}
        py={2}
        px={5}
        mt={2}
        mb={4}
        background={"leadpro.50"}
        fontSize={"sm"}
      >
        Lead address
      </Box>
      <Box mb={4} px={4}>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"buildingName"}
            control={control}
            label={"Property Name"}
            isOptional
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"buildingNumber"}
            control={control}
            label={"Property Number"}
            isOptional={isApplicant}
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"line1"}
            control={control}
            label={"Address line 1"}
            isOptional={isApplicant}
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"line2"}
            control={control}
            label={"Address line 2"}
            isOptional
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"line3"}
            control={control}
            label={"Address line 3"}
            isOptional
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"line4"}
            control={control}
            label={"Address line 4"}
            isOptional
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
        <Box mb={5}>
          <FormControlV2<TFieldValues>
            name={"postcode"}
            control={control}
            label={"Postcode"}
            isOptional={isApplicant}
            type={FormControlsTypeEnum.TEXT}
          />
        </Box>
      </Box>
    </>
  );
};
