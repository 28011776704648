import { Handle, NodeProps, Position } from "reactflow";
import { Box, Flex } from "@chakra-ui/react";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { Text } from "@chakra-ui/react";
import { FormToolPageQuestionTypeLabels } from "constants/form-tool-page";
import React from "react";

const handleStyle = {
  width: "15px",
  height: "15px",
  borderRadius: "30%",
  background: "#3B68AA", // leadpro.600
};

export default function QuestionNode({ data }: NodeProps<TQuestionNodeData>) {
  return (
    <Box
      width={"200px"}
      height={"80px"}
      p={2}
      border={"1px solid"}
      borderColor={"leadpro.200"}
      borderRadius={"md"}
      background={"white"}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...handleStyle, top: "-17px" }}
      />
      <Flex
        width={"100%"}
        height={"100%"}
        flexFlow={"column"}
        justifyContent={"space-between"}
        alignItems={"left"}
        fontSize={"xs"}
      >
        <Text noOfLines={2}>
          {" "}
          {data.index + 1}. {data.field.label}
        </Text>
        <Box as={"span"} fontSize={"9px"}>
          {FormToolPageQuestionTypeLabels[data.field.type]}{" "}
          {data.field.required && (
            <Box as={"span"} color={"leadpro.500"}>
              {" "}
              *
            </Box>
          )}
        </Box>
      </Flex>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...handleStyle, bottom: "-17px" }}
      />
    </Box>
  );
}
