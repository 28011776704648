import React, { FC } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { AccountIntegrationItem } from "./AccountIntegrationItem";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { observer } from "mobx-react";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const AccountIntegrationList: FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const availableAccountIntegrations =
      accountIntegrationsStore.availableIntegrationsSortedArray;

    return (
      <Box width={"100%"} height={"100%"} py={12} overflowY={"auto"}>
        <VStack
          spacing={4}
          align="stretch"
          maxW={"3xl"}
          margin={"auto"}
          padding={0}
        >
          {availableAccountIntegrations.map((staticData, index) => (
            <AccountIntegrationItem
              key={index}
              staticData={staticData}
              accountIntegrationsStore={accountIntegrationsStore}
            />
          ))}
        </VStack>
      </Box>
    );
  }
);
