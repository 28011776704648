import { ITableV2Column } from "components/table-v2/table-v2";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import {
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  LeadResponderWorkflowActiveStatusTag,
  StandardIconButton,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-solid-svg-icons";

import React from "react";

const ICON_SIZE = 18;

export const leadResponderWorkflowTableColumnDef = (
  handleEditWorkflow: (row: TLeadResponderWorkflow) => () => void,
  handleRemoveWorkflow: (row: TLeadResponderWorkflow) => () => void,
  handleIncreaseWorkflowPriority: (row: TLeadResponderWorkflow) => () => void,
  handleDecreaseWorkflowPriority: (row: TLeadResponderWorkflow) => () => void,
  isReordering: boolean
): ITableV2Column<TLeadResponderWorkflow>[] => {
  return [
    {
      accessor: "name",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "active",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <LeadResponderWorkflowActiveStatusTag isActive={row.active} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "createdAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Created</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "updatedAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Last edited</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.updatedAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "priority",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "70px",
      Header: <DefaultHeaderCell>Priority</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell onClick={event => event.stopPropagation()}>
          <ButtonGroup spacing={1} isDisabled={isReordering}>
            <StandardIconButton
              fontSize={"xl"}
              aria-label={"priority-up"}
              icon={<FontAwesomeIcon icon={faAngleUp} fontSize={ICON_SIZE} />}
              onClick={handleIncreaseWorkflowPriority(row)}
            />
            <StandardIconButton
              fontSize={"xl"}
              aria-label={"priority-down"}
              icon={<FontAwesomeIcon icon={faAngleDown} fontSize={ICON_SIZE} />}
              onClick={handleDecreaseWorkflowPriority(row)}
            />
          </ButtonGroup>
        </DefaultRowCell>
      ),
    },
    {
      accessor: "id",
      width: "30px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell
          justifyContent={"flex-end"}
          onClick={event => event.stopPropagation()}
        >
          <Menu isLazy={true} placement={"bottom-end"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  fontSize={ICON_SIZE}
                />
              }
              size="xs"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={handleEditWorkflow(row)}>Edit</MenuItem>
              <MenuItem onClick={handleRemoveWorkflow(row)}>Remove</MenuItem>
            </MenuList>
          </Menu>
        </DefaultRowCell>
      ),
    },
  ];
};
