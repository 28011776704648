import { ITableV2SortBy } from "../components/table-v2/table-v2";
import { SortingRule } from "react-table";

// Transformer to convert  ITableV2SortBy interface to SortingRule Table V1 interface
export const transformSortBy = <T>(
  sortBy: ITableV2SortBy<T> | null
): SortingRule<string> | null => {
  if (!sortBy) return null;
  return {
    desc: sortBy.direction === "desc",
    id: String(sortBy.field),
  };
};
