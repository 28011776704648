import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { Box } from "@chakra-ui/react";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import { LeadInfoRowValue } from "../LeadInfoRow";

export interface LeadQuestionnaireBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.QuestionnaireBlock;
  questions: string[][];
}

export const LeadQuestionnaireBlock = leadBlockHoc<
  LeadQuestionnaireBlockDefinition
>(props => {
  const {
    leadBlockDefinition: { questions },
  } = props;

  return (
    <>
      <LeadBlockHeader>Qualification questionnaire</LeadBlockHeader>
      <LeadBlockBody>
        {questions.map((q, i) => {
          return (
            <Box key={i} mb={5}>
              <Box as={"h3"} mb={2} color={"gray.500"} lineHeight={1}>
                {q[0]}
              </Box>
              <LeadInfoRowValue>{q[1]}</LeadInfoRowValue>
            </Box>
          );
        })}
      </LeadBlockBody>
    </>
  );
});
