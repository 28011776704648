import { makeAutoObservable } from "mobx";
import { LeadIovoxCallerApi } from "api/leads-iovox-caller.api";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { TLead } from "types/lead.type";

interface ILeadCallButtonLocalStore {
  accountId: number;
  leadStore: AccountLeadStore;
}

const leadIovoxCallerApi = new LeadIovoxCallerApi();

export class IovoxCallerStore {
  private readonly accountId: number;
  private readonly lead: TLead;
  private hasBeenCalled: boolean;

  public isMakingCallInProgress: boolean;

  constructor({ accountId, leadStore }: ILeadCallButtonLocalStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.lead = leadStore.lead;
    this.hasBeenCalled = !!this.lead.call;
    this.isMakingCallInProgress = false;
  }

  get hasExistingCall() {
    return this.hasBeenCalled;
  }

  public async makeIOVOXCall(
    onCallSuccessCallback: (lead: TLead) => Promise<void>
  ) {
    if (!this.isMakingCallInProgress) {
      try {
        this.isMakingCallInProgress = true;
        const updatedLead = await leadIovoxCallerApi.makeIovoxCall(
          this.accountId,
          this.lead.id
        );

        await onCallSuccessCallback(updatedLead);
      } catch (error) {
        throw error;
      } finally {
        this.hasBeenCalled = true;
        this.isMakingCallInProgress = false;
      }
    }
  }
}
