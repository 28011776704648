import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect } from "react";
import { FormToolPageSettingsConfiguratorDeletePopover } from "../../../../../../../../components/FormTool/FormToolPageSettingsConfiguratorDeletePopover";
import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { QuestionnaireToolPageSettingsCustomEndingPageConditionConfigurator } from "./FormToolPageSettingsCustomEndingPageConditionConfigurator/QuestionnaireToolPageSettingsCustomEndingPageConditionConfigurator";
import {
  customEndingPageParamsSchema,
  FormCustomEndingPageConfig,
} from "@leadpro/forms";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { PageToolThankYouPageSettings } from "../../../../../../../../components/PageTool/PageToolPageSettings/PageToolThankYouPageSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { StandardIconButton, Tooltip } from "components";

type TFieldValues = FormCustomEndingPageConfig["params"];

interface IProps {
  customEndingPageIndex: number;
  customEndingPageConfig: FormCustomEndingPageConfig;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsCustomEndingPage: FC<IProps> = observer(
  ({ customEndingPageIndex, questionnaireToolPageWIPFormStore }) => {
    const customEndingPage =
      questionnaireToolPageWIPFormStore.wipFormCustomEndingPagesJSWithTempId[
        customEndingPageIndex
      ];

    const { control, watch } = useForm<TFieldValues>({
      defaultValues: customEndingPage.params,
      mode: "onBlur",
      resolver: joiResolver(customEndingPageParamsSchema),
    });

    const swapEndingPagesOrder = useCallback(
      (index1: number, index2: number) => () =>
        questionnaireToolPageWIPFormStore.swapEndingPagesOrder(index1, index2),
      [questionnaireToolPageWIPFormStore]
    );

    useEffect(() => {
      const subscription = watch(value => {
        questionnaireToolPageWIPFormStore.updateWipPageCustomEndingPageConfigContent(
          customEndingPageIndex,
          value as TFieldValues
        );
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [watch, questionnaireToolPageWIPFormStore, customEndingPageIndex]);

    return (
      <Box
        py={7}
        px={10}
        bg={"leadpro.50"}
        border={"1px solid"}
        borderColor={"leadpro.200"}
        rounded={"sm"}
        width={"100%"}
        position={"relative"}
      >
        <HStack
          spacing={2}
          width={"100%"}
          position={"absolute"}
          top={0}
          left={0}
          px={"10px"}
          py={"10px"}
          flexDirection={"row-reverse"}
        >
          <FormToolPageSettingsConfiguratorDeletePopover
            handleRemove={() =>
              questionnaireToolPageWIPFormStore.removeWipFormCustomEndingPageConfig(
                customEndingPageIndex
              )
            }
            noun={"page"}
          />
          <Tooltip label="Move down" placement={"top"}>
            <StandardIconButton
              aria-label={"priority-down"}
              icon={<FontAwesomeIcon icon={faAngleDown} />}
              onClick={swapEndingPagesOrder(
                customEndingPageIndex,
                customEndingPageIndex + 1
              )}
            />
          </Tooltip>
          <Tooltip label="Move up" placement={"top"}>
            <StandardIconButton
              aria-label={"priority-up"}
              icon={<FontAwesomeIcon icon={faAngleUp} />}
              onClick={swapEndingPagesOrder(
                customEndingPageIndex,
                customEndingPageIndex - 1
              )}
            />
          </Tooltip>
        </HStack>
        <VStack width={"100%"} spacing={2} mt={5}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box fontSize={"sm"} fontWeight={700} flexShrink={0}>
              Criteria
            </Box>
            <Divider flexGrow={1} mx={5} />
          </Box>
          <QuestionnaireToolPageSettingsCustomEndingPageConditionConfigurator
            customEndingPageIndex={customEndingPageIndex}
            questionnaireToolPageWIPFormStore={
              questionnaireToolPageWIPFormStore
            }
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box fontSize={"sm"} fontWeight={700} flexShrink={0}>
              Ending page details
            </Box>
            <Divider flexGrow={1} mx={5} />
          </Box>
          <Box width={"100%"} py={5}>
            <PageToolThankYouPageSettings
              control={control}
              titleField={"customEndingPageHeader"}
              bodyField={"customEndingPageBody"}
              ctaLabelField={"customEndingPageCtaLabel"}
              ctaUrlField={"customEndingPageCtaUrl"}
            />
          </Box>
        </VStack>
      </Box>
    );
  }
);
