import { Box } from "@chakra-ui/react";
import { NavLinkItem } from "./NavLinkItem";
import {
  ACHIEVEMENTS_ROUTE,
  ANALYTICS_ROUTE,
  GUIDES_ROUTE,
  LEADS_ROUTE,
  LEAGUE_ROUTE,
  PROPERTIES_ROUTE,
  SETTINGS_ACCOUNT_ROUTE,
  SETTINGS_BILLING_ROUTE,
  SETTINGS_DEVELOPERS_ROUTE,
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_OFFICES_ROUTE,
  SETTINGS_USERS_ROUTE,
  TOOLS_AUTOCALLER_ROUTE,
  TOOLS_FACEBOOK_ADS_ROUTE,
  TOOLS_FORMS_ROUTE,
  TOOLS_IVT_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
  TOOLS_MORTGAGE_REFERRALS_ROUTE,
} from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faAwardSimple,
  faBook,
  faBuildingColumns,
  faChartSimple,
  faCircleCheck,
  faCode,
  faCreditCard,
  faEnvelope,
  faHouse,
  faHouseCircleCheck,
  faLocationDot,
  faPhone,
  faPlug,
  faTableRows,
  faTriangleExclamation,
  faTrophy,
  faUsers,
} from "@fortawesome/sharp-solid-svg-icons";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { UserRoleEnum } from "enums/user-role.enum";
import React, { FC } from "react";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { observer } from "mobx-react";
import { NavigationSection } from "./NavigationSection";
import { NavLinkIcon } from "./NavLinkIcon";
import { ShowForRoles } from "../ShowFor/ShowForRoles";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ShowForAccounts } from "../ShowFor/ShowForAccounts";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const ICON_SIZE = 16;

interface IProps {
  accountStore: UserAccountStore;
}

export const Navigation: FC<IProps> = observer(({ accountStore }) => {
  const {
    accountBillingStore: { accountBillingIssues },
    accountLeadsStore: {
      meta: { unreadTotalLeads },
    },
    accountPropertiesStore: {
      meta: { count },
    },
  } = accountStore;

  return (
    <Box overflowY={"auto"}>
      <NavigationSection>
        <NavLinkItem path={LEADS_ROUTE} exact={false}>
          <NavLinkIcon>
            <FontAwesomeIcon icon={faCircleCheck} fontSize={ICON_SIZE} />
          </NavLinkIcon>
          Leads
          {!!unreadTotalLeads && (
            <Box ml={"auto"} color={"leadpro.400"} fontSize="xs">
              {unreadTotalLeads}
            </Box>
          )}
        </NavLinkItem>

        <ShowForAccounts
          envVariable={env.REACT_APP_ACCOUNTS_WITH_PROPERTIES_ENABLED}
        >
          <NavLinkItem path={PROPERTIES_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faHouse} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Properties
            {!!count && (
              <Box ml={"auto"} color={"leadpro.400"} fontSize="xs">
                {count}
              </Box>
            )}
          </NavLinkItem>
        </ShowForAccounts>

        <NavLinkItem path={LEAGUE_ROUTE} exact={true}>
          <NavLinkIcon>
            <FontAwesomeIcon icon={faTrophy} fontSize={ICON_SIZE} />
          </NavLinkIcon>
          League
        </NavLinkItem>

        <NavLinkItem path={ANALYTICS_ROUTE} exact={true}>
          <NavLinkIcon>
            <FontAwesomeIcon icon={faChartSimple} fontSize={ICON_SIZE} />
          </NavLinkIcon>
          Analytics
        </NavLinkItem>

        <NavLinkItem path={ACHIEVEMENTS_ROUTE} exact={true}>
          <NavLinkIcon>
            <FontAwesomeIcon icon={faAwardSimple} fontSize={ICON_SIZE} />
          </NavLinkIcon>
          Achievements
        </NavLinkItem>

        <NavLinkItem path={GUIDES_ROUTE} exact={false}>
          <NavLinkIcon>
            <FontAwesomeIcon icon={faBook} fontSize={ICON_SIZE} />
          </NavLinkIcon>
          Guides
        </NavLinkItem>
      </NavigationSection>

      <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
        <NavigationSection {...{ tour: `${DashboardTourEnum.GENERAL_TOUR}-3` }}>
          <NavLinkItem path={TOOLS_IVT_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faHouseCircleCheck} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Instant Valuation Tool
          </NavLinkItem>
          <ShowForAccounts envVariable={env.REACT_APP_FORM_TOOL_ACCOUNTS}>
            <NavLinkItem path={TOOLS_FORMS_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faTableRows} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              Lead Forms
            </NavLinkItem>
          </ShowForAccounts>
          <NavLinkItem path={TOOLS_LEAD_RESPONDER_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faMessage} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Lead Responder
          </NavLinkItem>
          <NavLinkItem path={TOOLS_AUTOCALLER_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faPhone} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Autocaller
          </NavLinkItem>
          <NavLinkItem path={TOOLS_MORTGAGE_REFERRALS_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faEnvelope} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Mortgages
          </NavLinkItem>
          <NavLinkItem path={TOOLS_FACEBOOK_ADS_ROUTE} exact={true}>
            <NavLinkIcon>
              <FontAwesomeIcon icon={faFacebook} fontSize={ICON_SIZE} />
            </NavLinkIcon>
            Facebook Ads
          </NavLinkItem>
        </NavigationSection>
      </ShowForRoles>

      <ShowForRoles roles={[UserRoleEnum.ADMIN, UserRoleEnum.LEADER]}>
        <NavigationSection pb={2}>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <NavLinkItem path={SETTINGS_ACCOUNT_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  fontSize={ICON_SIZE}
                />
              </NavLinkIcon>
              <Box>Account</Box>
            </NavLinkItem>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <NavLinkItem path={SETTINGS_BILLING_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faCreditCard} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              <Box>Billing</Box>
              {!!accountBillingIssues.length && (
                <Box ml={2} color={"red.500"}>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    fontSize={ICON_SIZE}
                  />
                </Box>
              )}
            </NavLinkItem>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <NavLinkItem path={SETTINGS_OFFICES_ROUTE}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faLocationDot} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              Offices
            </NavLinkItem>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleEnum.ADMIN, UserRoleEnum.LEADER]}>
            <NavLinkItem path={SETTINGS_USERS_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faUsers} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              Users
            </NavLinkItem>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <NavLinkItem path={SETTINGS_INTEGRATIONS_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faPlug} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              Integrations
            </NavLinkItem>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <NavLinkItem path={SETTINGS_DEVELOPERS_ROUTE} exact={true}>
              <NavLinkIcon>
                <FontAwesomeIcon icon={faCode} fontSize={ICON_SIZE} />
              </NavLinkIcon>
              Developers
            </NavLinkItem>
          </ShowForRoles>
        </NavigationSection>
      </ShowForRoles>
    </Box>
  );
});
