import { TReapitOffice } from "types/reapit.type";
import { AccountIntegrationReapitStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapit.store";
import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { SingleSelectInput, IntegrationSyncSelectOption } from "components";

interface IProps {
  value: TReapitOffice["reapitId"] | null;
  officeName: string;
  existingOfficeId?: number;
  onChange: (value: TReapitOffice["reapitId"] | null) => void;
  reapitIntegrationStore: AccountIntegrationReapitStore;
}

export const ReapitOfficeAutosuggestSelect: FC<IProps> = observer(
  ({
    value,
    officeName,
    existingOfficeId,
    onChange,
    reapitIntegrationStore,
  }) => {
    const accountIntegrationReapitOfficesStore =
      reapitIntegrationStore.accountIntegrationReapitOfficesStore;
    const reapitOfficeRelationsMapByLeadProOfficeId =
      accountIntegrationReapitOfficesStore.officeRelationsMapByLeadProOfficeId;
    const availableReapitOfficesMapByName =
      accountIntegrationReapitOfficesStore.availableIntegrationOfficesMapByName;
    const reapitOfficeOptions =
      accountIntegrationReapitOfficesStore.integrationOfficesOptions || [];

    // this effects presets the reapit office id if exists
    useEffect(() => {
      if (!!existingOfficeId) {
        const relation =
          reapitOfficeRelationsMapByLeadProOfficeId[existingOfficeId];
        onChange(relation?.branchId || null);
      }
    }, [existingOfficeId, reapitOfficeRelationsMapByLeadProOfficeId, onChange]);

    // this effect suggests reapit office
    useEffect(() => {
      const suggestedOffice = availableReapitOfficesMapByName[officeName];

      if (!!suggestedOffice) {
        onChange(suggestedOffice.id);
      }
    }, [availableReapitOfficesMapByName, officeName, onChange]);

    return (
      <SingleSelectInput
        value={value}
        options={reapitOfficeOptions}
        placeholder={"Select Reapit office"}
        clearable
        onChange={onChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
