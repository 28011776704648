import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { WebhookEventTypes } from "enums/account-developer-webhook-events.enum";
import React from "react";
import { webhookEventConfigDescriptionMap } from "./webhookEventConfigDescriptionMap";
import { DeveloperConfigWebhookEventTableActionCell } from "./DeveloperConfigWebhookEventTableActionCell";
import { TDeveloperWebhookEventConfigData } from "types/account-developers.type";

export const webhookEventConfigTableColumnDef = (
  toggleWebhookEvent: (id: WebhookEventTypes, value: boolean) => void
): ITableV2Column<TDeveloperWebhookEventConfigData>[] => {
  return [
    {
      accessor: "id",
      width: "300px",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Event Name</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell pl={2} fontWeight={"bold"}>
          {row.id}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "value",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Event Description</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell fontSize={"xs"} py={4}>
          {webhookEventConfigDescriptionMap[row.id]}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "id",
      width: "100px",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Active</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell pl={1}>
          <DeveloperConfigWebhookEventTableActionCell
            row={row}
            onWebhookEventChange={toggleWebhookEvent}
          />
        </DefaultRowCell>
      ),
    },
  ];
};
