import {
  TAvailableLeadsFilters,
  TLeadDateRangeFilterConfiguration,
  TLeadFilterMenuItemValue,
  TLeadsFilter,
  TNextActionDateFilterConfiguration,
} from "types/leads-filter.type";
import { SortingRule } from "react-table";
import {
  LeadDateRangeFilterEnum,
  LeadNextActionDateFilterEnum,
} from "enums/lead-next-action-date-filter.enum";
import { LeadsApi } from "api/leads.api";
import { intersection, keyBy, keys, xor } from "lodash";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { LeadStageEnum } from "enums/lead-stage.enum";
import moment from "moment";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { LeadQuestionnaireAnsweredEnum } from "enums/lead-questionnaire-answered.enum";
import { getObjectKeys } from "utils/object.utils";
import {
  getAccountLeadTableFiltersFromLocalStorage,
  setAccountLeadTableFiltersToLocalStorage,
} from "utils/local-storage.utils";
import {
  getLeadJourneyFromUTMConfig,
  LEAD_JOURNEY_TO_UTM_CONFIG_MAP,
  LeadJourneyEnum,
} from "utils/utm-journey.utils";

const leadsApi = new LeadsApi();
const filterInitialState: TLeadsFilter = {
  types: [],
  stages: [],
  statuses: [],
  sources: {},
  offices: [],
  assignedTo: [],
  officeGroups: [],
  questionnaire: {},
  questionnaireAnswered: [],
  utmSources: [],
  utmMediums: [],
  utmCampaigns: [],
  nextActionDate: { type: LeadNextActionDateFilterEnum.ALL },
  dateRange: { type: LeadDateRangeFilterEnum.ALL },
  properties: [],
  filterIds: [],
  leadIds: [],
};

export class AccountLeadsFiltersStore {
  private readonly skipSyncWithLocalStorage: boolean;
  private readonly accountId: number;
  private availableFiltersData: TAvailableLeadsFilters | null;
  private activeFilterData: TLeadsFilter | null;
  private pendingFilterData: TLeadsFilter;
  private sortBy: IObservableArray<SortingRule<string>>;
  private searchQueryData: string;

  constructor(
    accountId: number,
    skipPreloadingFiltersFromStorage: boolean = false
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.skipSyncWithLocalStorage = skipPreloadingFiltersFromStorage;
    this.accountId = accountId;
    this.availableFiltersData = null;
    this.activeFilterData = null;
    this.pendingFilterData = {
      ...filterInitialState,
      ...(skipPreloadingFiltersFromStorage
        ? {}
        : getAccountLeadTableFiltersFromLocalStorage(accountId)),
    };
    this.searchQueryData = "";
    this.sortBy = observable.array<SortingRule<string>>();
  }

  get availableFilters() {
    return toJS(this.availableFiltersData);
  }

  get activeFilter() {
    return toJS(this.activeFilterData);
  }

  get pendingFilter() {
    return toJS(this.pendingFilterData);
  }

  get sortByArray() {
    return this.sortBy.slice();
  }

  get searchQuery() {
    return this.searchQueryData;
  }

  get leadJourneyFromPendingUTMFilters(): LeadJourneyEnum | null {
    const sources = this.pendingFilter[LeadSimpleFilterTypes.UTM_SOURCES];
    const mediums = this.pendingFilter[LeadSimpleFilterTypes.UTM_MEDIUMS];
    const campaigns = this.pendingFilter[LeadSimpleFilterTypes.UTM_CAMPAIGNS];

    if (sources.length > 1 || mediums.length > 1 || campaigns.length > 1)
      return null;

    return getLeadJourneyFromUTMConfig({
      source: sources[0],
      medium: mediums[0],
      campaign: campaigns[0],
    });
  }

  public setPendingUTMFiltersFromLeadJourney(leadJourney: LeadJourneyEnum) {
    const config = LEAD_JOURNEY_TO_UTM_CONFIG_MAP[leadJourney];
    this.pendingFilterData.utmSources = config.source;
    this.pendingFilterData.utmMediums = config.medium;
    this.pendingFilterData.utmCampaigns = config.campaign;
  }

  public upsertAvailableFilters(filters: Partial<TAvailableLeadsFilters>) {
    this.availableFiltersData = {
      ...this.availableFiltersData,
      ...filters,
    };

    // removes potential non-existent filter options from pending filters
    getObjectKeys(filters).forEach(filterType => {
      if (Array.isArray(this.pendingFilterData[filterType])) {
        this.pendingFilterData[filterType] = intersection(
          this.pendingFilterData[filterType] as unknown[],
          filters[filterType]?.map(ft => ft.id)
        ) as any;
      } else if (filterType === LeadSimpleFilterTypes.SOURCES) {
        const pendingFilterDataProperty = this.pendingFilterData[
          LeadSimpleFilterTypes.SOURCES
        ];
        const avaiableLeadSourcesMap = keyBy(
          filters[LeadSimpleFilterTypes.SOURCES],
          entry => entry.id
        );
        keys(pendingFilterDataProperty).forEach(key => {
          if (!avaiableLeadSourcesMap[key]) {
            delete pendingFilterDataProperty[key];
          } else {
            pendingFilterDataProperty[key] = intersection(
              pendingFilterDataProperty[key],
              avaiableLeadSourcesMap[key].hosts.map(host => host.id)
            );
          }
        });
      }
    });
  }

  // TOGGLE PENDING FILTERS
  public togglePendingFilterType(leadType: LeadTypeEnum) {
    const existingType = this.pendingFilterData.types.find(
      item => leadType === item
    );
    if (existingType) {
      this.pendingFilterData.types = this.pendingFilterData.types.filter(
        item => leadType !== item
      );
    } else {
      this.pendingFilterData.types.push(leadType);
    }
  }

  public togglePendingFilterSourceAndHost(
    leadSource: LeadSourceEnum,
    host: string
  ) {
    let existingSource = toJS(this.pendingFilterData.sources[leadSource]);
    if (!!existingSource && !!host) {
      this.pendingFilterData.sources[leadSource] = xor(existingSource, [host]);
    } else if (!!existingSource) {
      delete this.pendingFilterData.sources[leadSource];
    } else {
      this.pendingFilterData.sources = {
        ...this.pendingFilterData.sources,
        [leadSource]: [],
      };
    }
  }

  public togglePendingFilterStage(leadStage: LeadStageEnum) {
    const existingStage = this.pendingFilterData.stages.find(
      item => leadStage === item
    );
    if (existingStage) {
      this.pendingFilterData.stages = this.pendingFilterData.stages.filter(
        item => leadStage !== item
      );
    } else {
      this.pendingFilterData.stages.push(leadStage);
    }
  }

  public togglePendingFilterStatus(statusId: number) {
    const existingStatus = this.pendingFilterData.statuses.find(
      item => statusId === item
    );
    if (existingStatus) {
      this.pendingFilterData.statuses = this.pendingFilterData.statuses.filter(
        item => statusId !== item
      );
    } else {
      this.pendingFilterData.statuses.push(statusId);
    }
  }

  public togglePendingFilterOffice(officeId: number) {
    const existingOffice = this.pendingFilterData.offices.find(
      item => officeId === item
    );
    if (existingOffice) {
      this.pendingFilterData.offices = this.pendingFilterData.offices.filter(
        item => officeId !== item
      );
    } else {
      this.pendingFilterData.offices.push(officeId);
    }
  }

  public togglePendingFilterOfficeGroup(officeGroupId: number) {
    const existingOffice = this.pendingFilterData.officeGroups.find(
      item => officeGroupId === item
    );

    if (existingOffice) {
      this.pendingFilterData.officeGroups = this.pendingFilterData.officeGroups.filter(
        item => officeGroupId !== item
      );
    } else {
      this.pendingFilterData.officeGroups.push(officeGroupId);
    }
  }

  public togglePendingFilterAssignedTo(userId: number) {
    const existingUser = this.pendingFilterData.assignedTo.find(
      item => userId === item
    );
    if (existingUser) {
      this.pendingFilterData.assignedTo = this.pendingFilterData.assignedTo.filter(
        item => userId !== item
      );
    } else {
      this.pendingFilterData.assignedTo.push(userId);
    }
  }

  public togglePendingFilterProperty(propertyId: number) {
    const existingProperty = this.pendingFilterData.properties.find(
      item => propertyId === item
    );
    if (existingProperty) {
      this.pendingFilterData.properties = this.pendingFilterData.properties.filter(
        item => propertyId !== item
      );
    } else {
      this.pendingFilterData.properties.push(propertyId);
    }
  }

  public setPendingFilterNextActionDate(
    config: TNextActionDateFilterConfiguration
  ) {
    const fullConfiguration: TNextActionDateFilterConfiguration = {
      type: config.type,
    };

    if (fullConfiguration.type === LeadNextActionDateFilterEnum.TODAY) {
      fullConfiguration.includeNotSpecified = true;
    }

    if (fullConfiguration.type === LeadNextActionDateFilterEnum.CUSTOM) {
      const { from, to } = config;

      if (!from && !to) {
        fullConfiguration.from = moment()
          .subtract(1, "day")
          .startOf("day")
          .toISOString();
        fullConfiguration.to = moment()
          .endOf("day")
          .toISOString();
      } else {
        fullConfiguration.from = config.from;
        fullConfiguration.to = config.to;
      }
    }

    this.pendingFilterData.nextActionDate = fullConfiguration;
  }

  public togglePendingFilterUTMSource(utmSource: string) {
    const existingUTMSource = this.pendingFilterData.utmSources.find(
      item => utmSource === item
    );
    if (existingUTMSource) {
      this.pendingFilterData.utmSources = this.pendingFilterData.utmSources.filter(
        item => utmSource !== item
      );
    } else {
      this.pendingFilterData.utmSources.push(utmSource);
    }
  }

  public togglePendingFilterUTMMedium(utmMedium: string) {
    const existingUTMMedium = this.pendingFilterData.utmMediums.find(
      item => utmMedium === item
    );
    if (existingUTMMedium) {
      this.pendingFilterData.utmMediums = this.pendingFilterData.utmMediums.filter(
        item => utmMedium !== item
      );
    } else {
      this.pendingFilterData.utmMediums.push(utmMedium);
    }
  }

  public togglePendingFilterUTMCampaign(utmCampaign: string) {
    const existingUTMCampaign = this.pendingFilterData.utmCampaigns.find(
      item => utmCampaign === item
    );
    if (existingUTMCampaign) {
      this.pendingFilterData.utmCampaigns = this.pendingFilterData.utmCampaigns.filter(
        item => utmCampaign !== item
      );
    } else {
      this.pendingFilterData.utmCampaigns.push(utmCampaign);
    }
  }

  public togglePendingFilterJourney(id: number) {
    const existingFilterId = this.pendingFilterData.filterIds.find(
      item => id === item
    );

    if (existingFilterId) {
      this.pendingFilterData.filterIds = this.pendingFilterData.filterIds.filter(
        item => id !== item
      );
    } else {
      this.pendingFilterData.filterIds.push(id);
    }
  }

  public setPendingFilterLeadDateRange(
    config: TLeadDateRangeFilterConfiguration
  ) {
    const fullConfiguration: TLeadDateRangeFilterConfiguration = {
      type: config.type,
    };

    if (fullConfiguration.type === LeadDateRangeFilterEnum.CUSTOM) {
      const { from, to } = config;

      if (!from && !to) {
        fullConfiguration.from = moment()
          .subtract(1, "day")
          .startOf("day")
          .toISOString();
        fullConfiguration.to = moment()
          .endOf("day")
          .toISOString();
      } else {
        fullConfiguration.from = config.from;
        fullConfiguration.to = config.to;
      }
    }

    this.pendingFilterData.dateRange = fullConfiguration;
  }

  public setPendingFilterQuestionAnswer(question: string, answers?: string[]) {
    if (!answers) {
      delete this.pendingFilterData[LeadSimpleFilterTypes.QUESTIONNAIRE][
        question
      ];
    } else {
      this.pendingFilterData[LeadSimpleFilterTypes.QUESTIONNAIRE][
        question
      ] = answers;
    }
  }

  public togglePendingQuestionnaireStatus(
    status: LeadQuestionnaireAnsweredEnum
  ) {
    const currentValue = this.pendingFilterData.questionnaireAnswered[0];
    this.pendingFilterData.questionnaireAnswered.splice(0);
    if (currentValue !== status) {
      this.pendingFilterData.questionnaireAnswered.push(status);
    }
  }

  // TODO NENAD: SEE TO REMOVE THIS AND FIND BETTER WAY PASS FUNCTIONS TO COMBINED FILTER MENU COMPONENT
  public togglePendingFilterOnFilterType(
    value: TLeadFilterMenuItemValue,
    type: LeadSimpleFilterTypes
  ) {
    switch (type) {
      case LeadSimpleFilterTypes.OFFICE_GROUPS:
        return this.togglePendingFilterOfficeGroup(value as number);
      case LeadSimpleFilterTypes.OFFICES:
        return this.togglePendingFilterOffice(value as number);
      case LeadSimpleFilterTypes.ASSIGNED_TO:
        return this.togglePendingFilterAssignedTo(value as number);
      case LeadSimpleFilterTypes.STAGES:
        return this.togglePendingFilterStage(value as LeadStageEnum);
      case LeadSimpleFilterTypes.STATUSES:
        return this.togglePendingFilterStatus(value as number);
      case LeadSimpleFilterTypes.TYPES:
        return this.togglePendingFilterType(value as LeadTypeEnum);
      case LeadSimpleFilterTypes.QUESTIONNAIRE_ANSWERED:
        return this.togglePendingQuestionnaireStatus(
          value as LeadQuestionnaireAnsweredEnum
        );
      case LeadSimpleFilterTypes.UTM_SOURCES:
        return this.togglePendingFilterUTMSource(value as string);
      case LeadSimpleFilterTypes.UTM_MEDIUMS:
        return this.togglePendingFilterUTMMedium(value as string);
      case LeadSimpleFilterTypes.UTM_CAMPAIGNS:
        return this.togglePendingFilterUTMCampaign(value as string);
    }
  }

  // GENERAL ACTIVE/PENDING FILTER ACTIONS
  public upsertPendingFilter(filterUpdate: Partial<TLeadsFilter>) {
    this.pendingFilterData = {
      ...this.pendingFilterData,
      ...filterUpdate,
    };
  }

  public resetPendingFilter() {
    this.pendingFilterData = filterInitialState;
  }

  public setActiveFromPendingFilter() {
    this.activeFilterData = toJS(this.pendingFilterData);

    if (!this.skipSyncWithLocalStorage) {
      setAccountLeadTableFiltersToLocalStorage(
        this.accountId,
        this.pendingFilterData
      );
    }
  }

  public resetActiveFilter() {
    this.activeFilterData = null;
  }

  public setSearchQuery(searchQuery: string) {
    this.searchQueryData = searchQuery;
  }

  public setSortBy(sortBy: SortingRule<string>[]) {
    this.sortBy.replace(sortBy);
  }

  public async loadAvailableFilters(
    subset?: LeadSimpleFilterTypes | LeadSimpleFilterTypes[],
    properties?: number[]
  ) {
    const data = await leadsApi.getLeadsAvailableFilters(
      this.accountId,
      subset,
      properties
    );

    // HOTFIX - page unresponsive due to large volume of secondary sources in homepage under some accounts
    if (
      subset === LeadSimpleFilterTypes.SOURCES ||
      subset?.includes(LeadSimpleFilterTypes.SOURCES)
    ) {
      const homepageSourcesIndex = data.sources?.findIndex(
        source => source.id === LeadSourceEnum.homepage
      );

      if (data.sources && homepageSourcesIndex && homepageSourcesIndex > -1) {
        data.sources[homepageSourcesIndex].hosts = [];
      }
    }

    this.upsertAvailableFilters(data);
  }
}
