import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { NA } from "components";

interface IProps {
  children: ReactNode;
}

export const LeadInfoRowValue: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Box whiteSpace={"pre-line"} flexGrow={1} overflow={"hidden"}>
      {children || <NA />}
    </Box>
  );
};
