import { observer } from "mobx-react";
import { Flex } from "@chakra-ui/react";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import {
  TItemStatsSummary,
  TLeadAttributionByItem,
} from "types/account-lead-attribution-analyticis.type";
import { FC, useMemo } from "react";
import { LeadAttributionReportSegmentDetailsItemBarItemDetails } from "./LeadAttributionReportSegmentDetailsItemBarItemDetails";
import { LeadAttributionReportSegmentDetailsItemBarEstimatedValue } from "./LeadAttributionReportSegmentDetailsItemBarEstimatedValue";
import { LeadAttributionReportSegmentDetailsItemBarSegmentDetails } from "./LeadAttributionReportSegmentDetailsItemBarSegment";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import { LeadAttributionReportSegmentDetailsItemBarTemplate } from "./LeadAttributionReportSegmentDetailsItemBarTemplate";
import { uniq } from "lodash";

interface IProps {
  item: TItemStatsSummary<TLeadAttributionByItem>;
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
}

export const LeadAttributionReportSegmentDetailsItemBar: FC<IProps> = observer(
  ({ item, leadAttributionType, accountAnalyticsLeadAttributionStore }) => {
    const segmentsToShow =
      accountAnalyticsLeadAttributionStore
        .accountAnalyticsLeadAttributionFiltersStore
        .leadAttributionGroupsMapByType[leadAttributionType];

    const filteredStatsPerItem = useMemo(() => {
      return item.preparedStatsPerItem.filter(statCalculation =>
        segmentsToShow.includes(statCalculation.title)
      );
    }, [item.preparedStatsPerItem, segmentsToShow]);

    const filteredStatsSizeMap = useMemo(() => {
      const sortedTotalCount = [...filteredStatsPerItem]
        .sort((a, b) => a.totalCountByStat - b.totalCountByStat)
        .map(item => item.totalCountByStat);
      const uniqueSortedTotalCount = uniq(sortedTotalCount);

      const uniqueSortedTotalCountMap: Record<number, number> = {};
      uniqueSortedTotalCount.forEach(
        (item, index) => (uniqueSortedTotalCountMap[item] = index + 1)
      );

      const sortedTotalCountMap: Record<number, number> = {};
      sortedTotalCount.forEach(
        totalCount =>
          (sortedTotalCountMap[totalCount] =
            uniqueSortedTotalCountMap[totalCount])
      );

      return sortedTotalCountMap;
    }, [filteredStatsPerItem]);

    return (
      <Flex flexDirection="row" alignItems="flex-end">
        <LeadAttributionReportSegmentDetailsItemBarItemDetails
          item={item}
          leadAttributionType={leadAttributionType}
        />
        {!filteredStatsPerItem.length && (
          <LeadAttributionReportSegmentDetailsItemBarTemplate />
        )}
        {filteredStatsPerItem.map(statCalculation => (
          <LeadAttributionReportSegmentDetailsItemBarSegmentDetails
            key={statCalculation.title}
            size={filteredStatsSizeMap[statCalculation.totalCountByStat]}
            statCalculation={statCalculation}
          />
        ))}
        <LeadAttributionReportSegmentDetailsItemBarEstimatedValue item={item} />
      </Flex>
    );
  }
);
