import { observer } from "mobx-react";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import {
  faCube,
  faLocationDot,
  faSeedling,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadAttributionReportSegmentDetailsActionsItemFilterContent } from "./LeadAttributionReportSegmentDetailsActionsItemFilterContent";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";

interface IProps {
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
}

export const LeadAttributionReportSegmentDetailsActionsItemFilter: FC<IProps> = observer(
  ({ accountAnalyticsLeadAttributionStore, leadAttributionType }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    const secondaryActionIconMap: Record<
      LeadAttributionTypesEnum,
      React.ReactElement
    > = useMemo(() => {
      return {
        [LeadAttributionTypesEnum.OFFICE]: (
          <FontAwesomeIcon icon={faLocationDot} />
        ),
        [LeadAttributionTypesEnum.SOURCE]: <FontAwesomeIcon icon={faCube} />,
        [LeadAttributionTypesEnum.CAMPAIGN]: (
          <FontAwesomeIcon icon={faSeedling} />
        ),
        [LeadAttributionTypesEnum.JOURNEY]: (
          <FontAwesomeIcon icon={faSeedling} />
        ),
      };
    }, []);

    return (
      <Popover
        closeOnBlur={true}
        isLazy={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Button
            width={"210px"}
            leftIcon={secondaryActionIconMap[leadAttributionType]}
            borderRadius={"sm"}
            colorScheme={"blue"}
            variant={"outline"}
          >
            Add / remove {leadAttributionType}s
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width={"645px"}>
            <PopoverArrow />
            <PopoverHeader fontSize={"md"} fontWeight={700} color={"blue.500"}>
              Add or remove {leadAttributionType}s
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody pt={5}>
              <LeadAttributionReportSegmentDetailsActionsItemFilterContent
                leadAttributionType={leadAttributionType}
                accountAnalyticsLeadAttributionStore={
                  accountAnalyticsLeadAttributionStore
                }
                onClose={onClose}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
