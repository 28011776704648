import React, { ChangeEvent, FC, useCallback, useState } from "react";
import {
  LeadResponderWorkflowEditorBlock,
  TriggerablePopoverBlock,
} from "../common-blocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimer } from "@fortawesome/pro-regular-svg-icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Input } from "components";

interface IProps {
  delay: number | null;
  onApply: (delay: number) => void;
  isDisabled?: boolean;
}

export const LeadResponderWorkflowEditorDelayBlock: FC<IProps> = ({
  delay,
  onApply,
  isDisabled,
}) => {
  const [newDelay, setNewDelay] = useState(delay || 0);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const textValue = event.target.value;
      const numberValue = !textValue ? 0 : Number.parseInt(event.target.value);
      if (isNaN(numberValue)) return;
      setNewDelay(numberValue);
    },
    [setNewDelay]
  );

  const handleApply = useCallback(
    (onClose: () => void) => () => {
      onApply(newDelay);
      onClose();
    },
    [newDelay, onApply]
  );

  return (
    <TriggerablePopoverBlock
      triggerElement={
        <LeadResponderWorkflowEditorBlock
          placeholder={"Set delay"}
          iconColor={"gray"}
          icon={<FontAwesomeIcon icon={faTimer} />}
          isDisabled={isDisabled}
        >
          <VStack spacing={-1} pt={2}>
            <Box fontSize={"lg"} fontWeight={"medium"}>
              {delay || 0}
            </Box>
            <Box>minutes</Box>
          </VStack>
        </LeadResponderWorkflowEditorBlock>
      }
      headerElement={"Set delay"}
      bodyElement={
        <HStack spacing={2}>
          <Box flexGrow={1}>
            <Input
              value={newDelay}
              onChange={onChange}
              placeholder={"Enter delay in minutes"}
              size={"sm"}
            />
          </Box>
          <Box>minutes</Box>
        </HStack>
      }
      handleApply={handleApply}
    />
  );
};
