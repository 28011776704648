import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  graingerSalesforceIntegrationConnectValidationSchema,
  graingerSalesforceIntegrationInitialValues,
  TGraingerSalesforceIntegrationConfigFieldValues,
} from "utils/validation-schemas/grainger-salesforce-integration.validation";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";

type TFieldValues = TGraingerSalesforceIntegrationConfigFieldValues;

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectGraingerSalesforceForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: graingerSalesforceIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(
        graingerSalesforceIntegrationConnectValidationSchema
      ),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<TFieldValues>(
            {
              type: AccountIntegrationTypeEnum.GRAINGER_SALESFORCE,
              active: true,
              integrationConfig,
            }
          );

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Grainger Salesforce successfully connected."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Client ID"}
              type={FormControlsTypeEnum.TEXT}
              name={"clientId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Username"}
              type={FormControlsTypeEnum.TEXT}
              name={"userName"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Certificate"}
              description={"upload your .key certificate file"}
              type={FormControlsTypeEnum.CERTIFICATE_UPLOAD_INPUT}
              name={"certificatePrivateKey"}
              control={control}
              additionalProps={{
                accept: ".key",
              }}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel>Test mode</FormControlLabel>
            <FormControlV2<TFieldValues>
              name={"testMode"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
