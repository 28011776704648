import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface IFormDescriptionProps extends BoxProps {
  children: React.ReactNode;
}

export const FormControlDescription: React.FC<IFormDescriptionProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      lineHeight="normal"
      fontSize="sm"
      mt={2}
      mb={1}
      display="block"
      color="leadpro.500"
      {...rest}
    >
      {children}
    </Box>
  );
};
