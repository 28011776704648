import { observer } from "mobx-react";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import React, { FC } from "react";
import { Button, HStack } from "@chakra-ui/react";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadAttributionReportSegmentDetailsActionsItemFilter } from "./LeadAttributionReportSegmentDetailsActionsItemFilter";

interface IProps {
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
}

export const LeadAttributionReportSegmentDetailsActions: FC<IProps> = observer(
  ({ leadAttributionType, accountAnalyticsLeadAttributionStore }) => {
    const {
      accountAnalyticsLeadAttributionFiltersStore: {
        haveMoreItemsToShow,
        setNextBatchOfItemsSortedByEstValue,
      },
    } = accountAnalyticsLeadAttributionStore;

    return (
      <HStack spacing={2}>
        <Button
          disabled={haveMoreItemsToShow[leadAttributionType]}
          width={"200px"}
          leftIcon={<FontAwesomeIcon icon={faArrowDown} />}
          borderRadius={"sm"}
          colorScheme={"blue"}
          onClick={() =>
            setNextBatchOfItemsSortedByEstValue(leadAttributionType)
          }
        >
          Show more {leadAttributionType}s
        </Button>
        <LeadAttributionReportSegmentDetailsActionsItemFilter
          leadAttributionType={leadAttributionType}
          accountAnalyticsLeadAttributionStore={
            accountAnalyticsLeadAttributionStore
          }
        />
      </HStack>
    );
  }
);
