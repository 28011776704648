import {
  Badge,
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { ApiRequestStatusEnum } from "../../enums/api-request-status.enum";
import { DropdownFilterLoadingSkeleton } from "./DropdownFilterLoadingSkeleton";

interface IDropdownFilterMenuShellProps {
  title?: string;
  icon: React.ReactNode;
  badgeCount: number;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose: () => void;
  loadingStatus?: ApiRequestStatusEnum;
}

export const DropdownFilterMenuShell: FC<IDropdownFilterMenuShellProps> = ({
  title,
  icon,
  badgeCount,
  children,
  onOpen,
  onClose,
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
}) => {
  return (
    <HStack spacing={2} onClick={event => event.stopPropagation()}>
      <Menu
        closeOnSelect={false}
        autoSelect={false}
        isLazy={true}
        onClose={onClose}
        onOpen={onOpen}
      >
        <MenuButton
          as={IconButton}
          fontSize="sm"
          bg="none"
          border="none"
          color={badgeCount ? "leadpro.800" : "leadpro.400"}
          _hover={{ bg: "none" }}
          _active={{ bg: "none" }}
          _focus={{ boxShadow: "none" }}
          icon={
            <Box position="relative">
              {icon}
              {!!badgeCount && (
                <Badge
                  position="absolute"
                  top="-30%"
                  right="-30%"
                  color={"white"}
                  bg="leadpro.800"
                  fontSize="0.6rem"
                  height="0.8rem"
                  width="0.8rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                  border="1px white solid"
                >
                  {badgeCount}
                </Badge>
              )}
            </Box>
          }
        />
        <Portal>
          <MenuList
            minWidth={"350px"}
            borderRadius={"none"}
            overflowY="auto"
            px={0}
            py={3}
          >
            {loadingStatus === ApiRequestStatusEnum.PENDING && (
              <DropdownFilterLoadingSkeleton />
            )}

            {loadingStatus === ApiRequestStatusEnum.SUCCESS && children}
          </MenuList>
        </Portal>
      </Menu>
      {!!title && <Box>{title}</Box>}
    </HStack>
  );
};
