import { ChangeEvent, useCallback } from "react";
import { InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { Input } from "components";
import {
  TSelectAndInputValue,
  TSelectOption,
  TSelectOptionValue,
  TSelectValue,
} from "types/select-input.type";
import { SingleSelectInput } from "components";
import { SelectInputSizes } from "../select-input/select-input-size";

interface ISelectAndInputProps<D extends TSelectOptionValue> {
  name: string;
  onChange: (value: TSelectAndInputValue<D>) => void;
  value: TSelectAndInputValue<D>;
  options: TSelectOption<D>[];
  placeholder?: { select: string; input: string };
  disabled?: boolean;
  clearable?: boolean;
}

export function SelectAndInput<D extends TSelectOptionValue>({
  name,
  onChange,
  value,
  options = [],
  placeholder,
  disabled,
  clearable,
}: ISelectAndInputProps<D>) {
  const onSelectChange = useCallback(
    (selectedValue: TSelectValue<D>) => {
      onChange({
        selectValue: selectedValue,
        inputValue: value.inputValue,
      });
    },
    [onChange, value.inputValue]
  );

  const onInputChangeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        selectValue: value.selectValue,
        inputValue: event.target.value,
      });
    },
    [onChange, value.selectValue]
  );

  return (
    <InputGroup
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      flexWrap={"nowrap"}
    >
      <InputLeftAddon
        margin={0}
        padding={0}
        height={""}
        border={"none"}
        width={"50%"}
      >
        <SingleSelectInput<D>
          size={SelectInputSizes.SMALL}
          value={value.selectValue}
          options={options}
          onChange={onSelectChange}
          borderBottomRightRadius={0}
          borderTopRightRadius={0}
          borderRight={"none"}
          placeholder={placeholder?.select}
          disabled={disabled}
          clearable={clearable}
          bg={"white"}
          _hover={undefined}
          _invalid={undefined}
          _focus={{ outline: "none" }}
        />
      </InputLeftAddon>
      <Input
        name={name}
        size={"sm"}
        onChange={onInputChangeChange}
        borderLeft={"none"}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        value={value.inputValue}
        placeholder={placeholder?.input}
      />
    </InputGroup>
  );
}
