import React, { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Loader } from "components";
import { Box, Center } from "@chakra-ui/react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TAccountIntegrationData,
  TStreetConfig,
} from "types/account-integration.type";
import { OfficeSelectIntegrationTable } from "../../AccountIntegrationConfigurator/OfficeSelectIntegrationTable";

interface IProps {
  accountStore: UserAccountStore;
}

export const StreetIntegrationOfficesConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const streetIntegrationStore =
      accountIntegrationsStore.accountIntegrationStreetStore;

    const streetIntegrationConfig = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.STREET
    ] as TAccountIntegrationData<TStreetConfig>;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            streetIntegrationStore.fetchAccountIntegrationOfficesAndRelations(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      streetIntegrationStore,
      accountOfficesStore,
      streetIntegrationConfig.integrationConfig,
    ]);

    return (
      <Box position={"relative"} minHeight={"200px"}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && (
          <Center position={"absolute"} top={0} bottom={0} left={0} right={0}>
            <Box>
              {`Your Street token is used to load your offices. Check the token`}
              {streetIntegrationConfig.active
                ? "."
                : " and enable this integration."}
            </Box>
          </Center>
        )}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <OfficeSelectIntegrationTable
            accountIntegrationsStore={accountIntegrationsStore}
            accountIntegrationOfficesStore={streetIntegrationStore}
            integrationType={AccountIntegrationTypeEnum.STREET}
          />
        )}
      </Box>
    );
  }
);
