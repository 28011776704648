import { Table } from "components/table";
import { TLead } from "types/lead.type";
import { SortingRule } from "react-table";
import React, { FC, useCallback, useEffect, useState } from "react";
import { EmptyLeadsTable } from "./EmptyLeadsTable/EmptyLeadsTable";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { LeadsTableBulkUpdateControls } from "./LeadsTableBulkUpdateControls/LeadsTableBulkUpdateControls";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { ExtendedColumn } from "components/table/table-props";

const PAGE_SIZE = 50;

interface IProps {
  accountStore: UserAccountStore;
  accountLeadsStore: AccountLeadsStore;
  tableColumns: ExtendedColumn<TLead>[];
  includeSpeedScores: boolean;
}

export const LeadsTable: FC<IProps> = observer(
  ({ accountStore, accountLeadsStore, tableColumns, includeSpeedScores }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const {
      uiStore: { isDemoModeOn },
    } = useAppStore();
    const {
      account: { id },
    } = accountStore;
    const {
      selectedLeadId,
      selectLead,
      leadsDataArray,
      loadLeads,
      meta,
    } = accountLeadsStore;
    const leadsFilterStore = accountLeadsStore.leadsFilterStore;
    const {
      activeFilter,
      sortByArray,
      searchQuery,
      setSortBy,
    } = leadsFilterStore;

    const fetchLeads = useCallback(
      async (pageSize: number, page?: number) => {
        if (!!activeFilter) {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          try {
            await loadLeads(
              activeFilter,
              sortByArray,
              searchQuery,
              includeSpeedScores,
              pageSize,
              page
            );
            setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
          } catch (e) {
            setLoadingStatus(ApiRequestStatusEnum.ERROR);
          }
        }
      },
      [loadLeads, activeFilter, sortByArray, searchQuery, includeSpeedScores]
    );

    useEffect(() => {
      fetchLeads(PAGE_SIZE);
    }, [isDemoModeOn, fetchLeads]);

    const onSortByChange = useCallback(
      (sortBy: SortingRule<string>[]) => {
        setSortBy(sortBy);
      },
      [setSortBy]
    );

    return (
      <Table<TLead>
        key={id}
        enableRowSelection={true}
        getRowId={row => `${row.id}`}
        selectedRowId={selectedLeadId?.toString()}
        onRowClick={lead => selectLead(lead.id)}
        onPageChange={({ pageIndex }) => fetchLeads(PAGE_SIZE, pageIndex)}
        pageIndex={meta.currentPage}
        pageSize={PAGE_SIZE}
        total={meta.currentSelectionTotalLeads}
        data={leadsDataArray}
        onSortByChange={onSortByChange}
        loadingStatus={loadingStatus}
        defaultSort={[
          {
            id: "createdAt",
            desc: true,
          },
        ]}
        columns={tableColumns}
        EmptyView={EmptyLeadsTable}
        PopupControlView={LeadsTableBulkUpdateControls}
        popupControlViewProps={{ leadsStore: accountLeadsStore }}
      />
    );
  }
);
