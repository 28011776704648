import { observer } from "mobx-react";
import { Box, VStack } from "@chakra-ui/react";
import { TNormalizedUserData, TUserData } from "types/user-data.type";
import React, { FC, useEffect, useMemo } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { useForm } from "react-hook-form";
import { FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";

type TFieldValues = Partial<TUserData>;

interface IProps {
  index: number;
  user: TNormalizedUserData;
  setFormSettings: (
    data: {
      isDirty: boolean;
      reset: () => void;
      submit: () => Promise<void>;
    },
    index: number
  ) => void;
}

export const UserAssigneeAvailabilitySettings: FC<IProps> = observer(
  ({ index, user, setFormSettings }) => {
    const {
      authStore: { updateAuthUser },
    } = useAppStore();

    const initialValues: TFieldValues = useMemo(
      () => ({
        availableForLeadAssignment: user.availableForLeadAssignment || false,
      }),
      [user]
    );

    const {
      handleSubmit,
      control,
      reset,
      watch,
      formState: { isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
    });

    const watchAvailableForLeadAssignment = watch("availableForLeadAssignment");

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    useEffect(() => {
      setFormSettings(
        {
          isDirty,
          reset,
          submit: handleSubmit(updateAuthUser),
        },
        index
      );
    }, [setFormSettings, isDirty, reset, handleSubmit, updateAuthUser, index]);

    return (
      <VStack spacing={10} width={"100%"} alignItems={"stretch"}>
        <Box color={"leadpro.700"} fontSize={"lg"} fontWeight={"bold"}>
          {`Lead assignment availability (${
            watchAvailableForLeadAssignment ? "Enabled" : "Disabled"
          })`}
        </Box>
        <Box width={"100%"}>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={4}
            maxW={"100%"}
          >
            <Box flexGrow={1} color={"leadpro.500"} fontSize={"md"}>
              Lead assignment availability means your user is available for
              other agents to assign you leads. Disabling this will take you off
              of the assignee list for leads. Admins will still be able to
              assign you leads. This will not alter your current assignment to
              any of your existing leads.
            </Box>
            <Box flexShrink={0}>
              <FormControlV2<TFieldValues>
                name={"availableForLeadAssignment"}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
              />
            </Box>
          </Box>
        </Box>
      </VStack>
    );
  }
);
