import { observer } from "mobx-react";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import React, { FC } from "react";
import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import { faDiamondTurnRight } from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropertyDetailsLeadSourcesFilterItem } from "./PropertyDetailsLeadSourcesFilterItem";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
  propertyStore: AccountPropertyStore;
}

export const PropertyDetailsLeadSourcesFilter: FC<IProps> = observer(
  ({ accountStore, propertyStore }) => {
    const {
      accountLeadSourcesStore: { leadSourcesMapBySource },
      portalsMap,
      account: { logoUrl },
    } = accountStore;

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={4}
        width={"100%"}
        borderRadius={"sm"}
        bg={"leadpro.50"}
        p={3}
      >
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box color={"leadpro.500"} mr={3}>
            <FontAwesomeIcon icon={faDiamondTurnRight} fontSize={16} />
          </Box>
          <Box fontSize={"sm"}>Lead sources</Box>
        </Box>
        <Wrap spacing={6}>
          {propertyStore.property.sources.map(propertySource => (
            <WrapItem key={propertySource.source}>
              <PropertyDetailsLeadSourcesFilterItem
                propertyStore={propertyStore}
                propertySource={propertySource}
                leadSourcesMapBySource={leadSourcesMapBySource}
                portalsMap={portalsMap}
                accountLogoUrl={logoUrl}
              />
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    );
  }
);
