import React, { useMemo } from "react";
import { TPortalData } from "types/portal.type";
import { Box } from "@chakra-ui/react";
import { Tooltip } from "components";
import { PortalLinkStatusTooltip } from "../tooltips/PortalLinkStatusTooltip";
import {
  IAccountOfficePortalActivity,
  TOfficeActivitySupportedLeadTypes,
} from "types/account-portal-link-status-per-office.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import {
  OFFICE_NOT_SUPPORTED,
  PORTAL_NOT_SUPPORTED,
} from "constants/account-activity-data";

interface IPortalLInkStatusCellProps {
  officeActivity: IAccountOfficePortalActivity;
  displayForType: TOfficeActivitySupportedLeadTypes;
  portals: Record<number, TPortalData>;
  leadSources: Record<number, TNormalizedLeadSourceData>;
}
export const PortalLinkStatusCell: React.FC<IPortalLInkStatusCellProps> = ({
  officeActivity,
  displayForType,
  portals,
  leadSources,
}) => {
  const typeNotSupportedMessage = useMemo(() => {
    const typeData = officeActivity.types[displayForType];

    if (!typeData) {
      return OFFICE_NOT_SUPPORTED;
    }

    if (!typeData.length) {
      return PORTAL_NOT_SUPPORTED;
    }

    return "";
  }, [officeActivity, displayForType]);

  const isLeadTypeActivityProblematic = useMemo(() => {
    return officeActivity.problematicTypes.includes(displayForType);
  }, [officeActivity, displayForType]);

  const tooltipLabel = useMemo(() => {
    if (!!typeNotSupportedMessage) return typeNotSupportedMessage;

    const typeData = officeActivity.types[displayForType];

    if (!!typeData) {
      return (
        <PortalLinkStatusTooltip
          typeActivities={typeData}
          portals={portals}
          leadSources={leadSources}
        />
      );
    }
  }, [
    displayForType,
    leadSources,
    officeActivity.types,
    portals,
    typeNotSupportedMessage,
  ]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      cursor={"pointer"}
    >
      <Tooltip aria-label={"portal-link-status"} label={tooltipLabel}>
        {!!typeNotSupportedMessage ? (
          <Box>
            <FontAwesomeIcon icon={faClose} />
          </Box>
        ) : (
          <Box
            width="12px"
            height="12px"
            bg={`${isLeadTypeActivityProblematic ? "red" : "green"}.500`}
            rounded="full"
          />
        )}
      </Tooltip>
    </Box>
  );
};
