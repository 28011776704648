import { observer } from "mobx-react";
import { Box, VStack } from "@chakra-ui/react";
import { SmsTemplates } from "../SmsTemplates/SmsTemplates";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { EmailTemplates } from "../EmailTemplates/EmailTemplates";

interface IProps {
  accountStore: UserAccountStore;
}
export const Templates: FC<IProps> = observer(({ accountStore }) => {
  return (
    <VStack spacing={6} align={"stretch"}>
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"md"} fontWeight={"bold"}>
          Email templates
        </Box>
        <EmailTemplates accountStore={accountStore} />
      </VStack>
      <VStack spacing={4} align={"stretch"}>
        <Box fontSize={"md"} fontWeight={"bold"}>
          SMS templates
        </Box>
        <SmsTemplates accountStore={accountStore} />
      </VStack>
    </VStack>
  );
});
