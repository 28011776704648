import { Box, Button, Collapse, useDisclosure } from "@chakra-ui/react";
import { TLeadBYM } from "types/lead.type";
import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { LeadBlockBody } from "../LeadBlockBody";
import { LeadBlockHeader } from "../LeadBlockHeader";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadInfoRow";

export interface ILeadBYMDataBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes;
  bym: TLeadBYM;
}

export const LeadBYMDataBlock = leadBlockHoc<ILeadBYMDataBlockDefinition>(
  ({ leadBlockDefinition }) => {
    const {
      emailContents,
      emailInteractedLink,
      emailSubject,
      alternateId,
    } = leadBlockDefinition.bym;

    const { isOpen, onToggle } = useDisclosure();

    return (
      <>
        <LeadBlockHeader>BriefYourMarket Campaign</LeadBlockHeader>
        <LeadBlockBody>
          <Box color={"gray.500"} mb={4}>
            This lead has generated from an expression of interest in one of
            your BriefYourMarket campaigns, see details below.
          </Box>
          {!!emailSubject && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Subject</LeadInfoRowLabel>
              <LeadInfoRowValue>{emailSubject}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!emailInteractedLink && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Interacted Link</LeadInfoRowLabel>
              <LeadInfoRowValue>{emailInteractedLink}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!alternateId && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Alternate ID</LeadInfoRowLabel>
              <LeadInfoRowValue>{alternateId}</LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!emailContents && (
            <LeadInfoRow>
              <LeadInfoRowLabel offCenter>Contents</LeadInfoRowLabel>
              <LeadInfoRowValue>
                <Box mr={8} border={"1px solid"} borderColor={"leadpro.200"}>
                  <Box position={"relative"}>
                    <Box
                      display={isOpen ? "none" : "block"}
                      position={"absolute"}
                      inset={0}
                      zIndex={10}
                      bg={
                        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"
                      }
                    ></Box>
                    <Collapse startingHeight={"100px"} in={isOpen}>
                      <Box fontSize={"sm"} lineHeight={1.2} p={4}>
                        {emailContents}
                      </Box>
                    </Collapse>
                  </Box>
                  <Button
                    variant={"ghost"}
                    width={"full"}
                    color={"leadpro.800"}
                    mt={2}
                    borderRadius={0}
                    onClick={onToggle}
                  >
                    {isOpen ? "Hide contents" : "Expand contents"}
                  </Button>
                </Box>
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
        </LeadBlockBody>
      </>
    );
  }
);
