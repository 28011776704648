import {
  TEmailTemplateCoreData,
  TEmailTemplateWorkflowData,
} from "types/email-template.type";
import { makeAutoObservable, toJS } from "mobx";
import { aggregateEmailTemplateWorkflows } from "utils/email-template.utils";
import { AppStore } from "store/App.store";
import { replaceUIMergeTagsWithAccountValues } from "utils/template.utils";

const DEFAULT_NEW_EMAIL_TEMPLATE: TEmailTemplateCoreData = {
  id: undefined,
  name: "Untitled",
  subject: "",
  design: undefined,
  workflows: undefined,
};

export class WIPEmailTemplateStore {
  private root: AppStore;
  private readonly accountId: number;
  public id: number | undefined;
  public name: string;
  public subject: string;
  private design: object | undefined;
  private workflows: TEmailTemplateWorkflowData | undefined;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.id = DEFAULT_NEW_EMAIL_TEMPLATE.id;
    this.name = DEFAULT_NEW_EMAIL_TEMPLATE.name;
    this.subject = DEFAULT_NEW_EMAIL_TEMPLATE.subject;
    this.design = DEFAULT_NEW_EMAIL_TEMPLATE.design;
    this.workflows = DEFAULT_NEW_EMAIL_TEMPLATE.workflows;
  }

  get emailTemplateData() {
    return {
      id: this.id,
      name: this.name,
      subject: this.subject,
      design: toJS(this.design),
      workflows: toJS(this.workflows),
    };
  }

  get designWithPreviewData() {
    const accountStore = this.root.userAccountsStore.selectedAccountStore;
    let designJS = toJS(this.design);

    if (!!accountStore && !!designJS) {
      const mergeTags =
        accountStore.accountEmailTemplatesStore.uiMergeTagsFlatArray;
      designJS = replaceUIMergeTagsWithAccountValues(designJS, mergeTags);
    }

    return designJS;
  }

  get isTemplateCreatedPreviously() {
    return !!this.id;
  }

  get isWipTemplateDeletable() {
    return (
      this.isTemplateCreatedPreviously &&
      !!this.workflows &&
      !aggregateEmailTemplateWorkflows(this.workflows).length
    );
  }

  public setWIPTemplate(
    template: TEmailTemplateCoreData = DEFAULT_NEW_EMAIL_TEMPLATE
  ) {
    this.id = template.id;
    this.name = template.name;
    this.subject = template.subject;
    this.design = template.design;
    this.workflows = template.workflows;
  }

  public setWIPTemplateName(name: string) {
    this.name = name;
  }

  public setWIPTemplateSubject(subject: string) {
    this.subject = subject;
  }

  public copyTemplate() {
    this.id = undefined;
    this.name = this.name + " - Copy";
    this.workflows = undefined;
  }
}
