import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faBuildings } from "@fortawesome/pro-regular-svg-icons";
import { CenteredMessage } from "components/message/CenteredMessage";
import { Box, MenuDivider, MenuGroup } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { isEqual, uniq } from "lodash";
import { AnalyticsOfficesFilterItem } from "./AnalyticsOfficesFilterItem";
import { AccountAnalyticsFiltersStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsFilters.store";

const ICONS_SIZE = 16;

interface IProps {
  accountStore: UserAccountStore;
  accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore;
}

export const AnalyticsOfficesFilter: FC<IProps> = observer(
  ({ accountStore, accountAnalyticsFiltersStore }) => {
    const { accountOfficesStore, accountOfficeGroupsStore } = accountStore;
    const officeOptions = accountOfficesStore.accountOfficesOptionsArray;
    const officeGroupOptions =
      accountOfficeGroupsStore.accountOfficeGroupsOptions;

    const [pendingOfficeIds, setPendingOfficeIds] = useState<number[]>([]);
    const [pendingOfficeGroupIds, setPendingOfficeGroupIds] = useState<
      number[]
    >([]);

    const handleClose = useCallback(() => {
      const officeIdsFromOfficeGroups = accountOfficeGroupsStore.getOfficeIdsForOfficeGroupIds(
        pendingOfficeGroupIds
      );
      const combinedUniqueIds = uniq([
        ...pendingOfficeIds,
        ...officeIdsFromOfficeGroups,
      ]);

      if (
        !isEqual(combinedUniqueIds, accountAnalyticsFiltersStore.officeIdsArray)
      ) {
        accountAnalyticsFiltersStore.setOfficeIds(combinedUniqueIds);
      }
    }, [
      pendingOfficeIds,
      pendingOfficeGroupIds,
      accountAnalyticsFiltersStore,
      accountOfficeGroupsStore,
    ]);

    const onItemToggle = useCallback(
      (
        pendingIds: number[],
        setPendingIds: (ids: number[]) => void,
        toggledItem: number
      ) => (event: ChangeEvent<unknown>) => {
        event.preventDefault();
        event.stopPropagation();

        const existingItemIndex = pendingIds.findIndex(
          itemInPending => itemInPending === toggledItem
        );

        if (existingItemIndex > -1) {
          setPendingIds(
            pendingIds.filter((item, index) => index !== existingItemIndex)
          );
        } else {
          setPendingIds([...pendingIds, toggledItem]);
        }
      },
      []
    );

    return (
      <DropdownFilterMenuShell
        title={"Office"}
        icon={<FontAwesomeIcon icon={faBuilding} fontSize={ICONS_SIZE} />}
        badgeCount={pendingOfficeIds.length + pendingOfficeGroupIds.length}
        onClose={handleClose}
      >
        <>
          {!officeOptions.length && !officeGroupOptions.length ? (
            <CenteredMessage
              height={"100px"}
              message={<DropdownNoFilterMessage />}
            />
          ) : (
            <Box maxHeight={"500px"} width={"350px"} overflowY={"auto"}>
              <MenuGroup title={"Office Groups"}>
                {!officeGroupOptions.length && (
                  <CenteredMessage
                    height={"100px"}
                    message={<DropdownNoFilterMessage />}
                  />
                )}
                {officeGroupOptions.map(option => (
                  <AnalyticsOfficesFilterItem
                    key={option.value}
                    option={option}
                    onClick={onItemToggle(
                      pendingOfficeGroupIds,
                      setPendingOfficeGroupIds,
                      option.value
                    )}
                    selectedValues={pendingOfficeGroupIds}
                    icon={<FontAwesomeIcon icon={faBuildings} />}
                  />
                ))}
              </MenuGroup>
              <MenuDivider mb={0} />
              <MenuGroup title={"Offices"}>
                {!officeOptions.length && (
                  <CenteredMessage
                    height={"100px"}
                    message={<DropdownNoFilterMessage />}
                  />
                )}
                {officeOptions.map(option => (
                  <AnalyticsOfficesFilterItem
                    key={option.value}
                    option={option}
                    onClick={onItemToggle(
                      pendingOfficeIds,
                      setPendingOfficeIds,
                      option.value
                    )}
                    selectedValues={pendingOfficeIds}
                    icon={<FontAwesomeIcon icon={faBuilding} />}
                  />
                ))}
              </MenuGroup>
            </Box>
          )}
        </>
      </DropdownFilterMenuShell>
    );
  }
);
