import { Button, Divider, HStack, useToast, VStack } from "@chakra-ui/react";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from "react";
import { ActionPromptContainer, ApiMessageStack } from "components";
import { OfficeUsersList } from "../OfficeUsersList";
import { getUserFullName } from "utils/account-user.utils";
import { SelectableOfficeUsersListItem } from "./SelectableOfficeUsersListItem";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { UserFormPrompt } from "routes/dashboard/components/prompts/UserFormPrompt/UserFormPrompt";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
  officeStore: AccountOfficeStore;
  closePrompt: () => void;
}
export const AddUserToOfficePrompt: FC<IProps> = observer(
  ({ accountStore, officeStore, closePrompt }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();
    const [isSubmittingUsers, setIsSubmittingUsers] = useState<boolean>(false);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>();

    const accountUsersStore = accountStore.accountUsersStore;
    const selectedAccountUsers = accountUsersStore.accountUsersArray;

    useEffect(() => {
      accountUsersStore.fetchAccountUsers();
    }, [accountUsersStore]);

    const filteredOfficeUsers = useMemo(() => {
      if (!searchQuery) return selectedAccountUsers;

      return selectedAccountUsers.filter(
        user =>
          getUserFullName(user.firstName, user.lastName)?.includes(
            searchQuery
          ) || user.email.toLowerCase().includes(searchQuery)
      );
    }, [searchQuery, selectedAccountUsers]);

    const filterUsers = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
      },
      [setSearchQuery]
    );

    const handleToggleUser = useCallback(
      (userId: number) => {
        if (selectedUserIds.includes(userId)) {
          setSelectedUserIds(
            selectedUserIds.filter(selectedUserId => selectedUserId !== userId)
          );
        } else {
          setSelectedUserIds([...selectedUserIds, userId]);
        }
      },
      [selectedUserIds, setSelectedUserIds]
    );

    const onUserInvitedSuccess = useCallback(async () => {
      await officeStore.fetchOfficeUsers();
    }, [officeStore]);

    const openInviteUserPrompt = useCallback(() => {
      setModal(
        <UserFormPrompt
          accountStore={accountStore}
          closePrompt={unSetModal}
          onSuccess={onUserInvitedSuccess}
        />
      );
    }, [setModal, unSetModal, onUserInvitedSuccess, accountStore]);

    const handleSubmitUsers = useCallback(async () => {
      try {
        if (!!selectedUserIds.length) {
          setIsSubmittingUsers(true);
          await officeStore.addUsersToOffice(selectedUserIds);
          setIsSubmittingUsers(false);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "Users will receive email notification shortly.",
          });
          closePrompt();
        }
      } catch (e) {
        setIsSubmittingUsers(false);
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [
      selectedUserIds,
      closePrompt,
      toast,
      setIsSubmittingUsers,
      officeStore,
    ]);

    return (
      <ActionPromptContainer
        header={"Add users to office"}
        body={
          <VStack spacing={4} divider={<Divider />} align={"stretch"}>
            <OfficeUsersList
              handleAddItem={openInviteUserPrompt}
              addItemLabel={"Create new user"}
              handleFiltering={filterUsers}
              renderItems={() =>
                filteredOfficeUsers.map(user => (
                  <SelectableOfficeUsersListItem
                    key={user.id}
                    user={user}
                    isChecked={selectedUserIds.includes(user.id)}
                    onToggle={handleToggleUser}
                  />
                ))
              }
            />
            <HStack justify={"space-between"} align={"center"} width={"100%"}>
              <Button
                variant={"outline"}
                onClick={closePrompt}
                disabled={isSubmittingUsers}
              >
                Cancel
              </Button>
              {!!selectedUserIds.length && (
                <Button
                  colorScheme={"teal"}
                  onClick={handleSubmitUsers}
                  disabled={isSubmittingUsers}
                  isLoading={isSubmittingUsers}
                >
                  Add {selectedUserIds.length} user(s)
                </Button>
              )}
            </HStack>
          </VStack>
        }
      />
    );
  }
);
