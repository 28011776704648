import { observer } from "mobx-react";
import { LeadBlockBody } from "../LeadBlocks/LeadBlockBody";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadBlocks/LeadInfoRow";
import { LeadSourceTag } from "components";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { Box } from "@chakra-ui/react";
import { LeadBlockHeader } from "../LeadBlocks/LeadBlockHeader";
interface ILeadAttributionOriginProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}
export const LeadAttributionOrigin: FC<ILeadAttributionOriginProps> = observer(
  ({ leadStore, accountStore }) => {
    const lead = leadStore.lead;
    const portalsMap = accountStore.portalsMap;
    const leadSourcesMapBySource =
      accountStore.accountLeadSourcesStore.leadSourcesMapBySource;
    const accountLogoUrl = accountStore.account.logoUrl;

    return (
      <>
        <LeadBlockHeader>Lead origin</LeadBlockHeader>
        <LeadBlockBody>
          <LeadInfoRow>
            <LeadInfoRowLabel>
              <Box as="span" fontWeight="bold" color={"leadpro.700"}>
                Source
              </Box>
            </LeadInfoRowLabel>
            <LeadInfoRowValue>
              <LeadSourceTag
                source={lead.source}
                leadSourcesMapBySource={leadSourcesMapBySource}
                portalsMap={portalsMap}
                accountLogoUrl={accountLogoUrl}
              />
            </LeadInfoRowValue>
          </LeadInfoRow>
        </LeadBlockBody>
      </>
    );
  }
);
