import { observer } from "mobx-react";
import React, { FC, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ApiMessageStack, FormControlMeta } from "components";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { TNormalizedUserData } from "types/user-data.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { TwoFactorAuthSetupPrompt } from "routes/dashboard/components/prompts/TwoFactorAuthSetupPrompt/TwoFactorAuthSetupPrompt";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";

interface IProps {
  user: TNormalizedUserData;
}

export const SecuritySettings: FC<IProps> = observer(({ user }) => {
  const toast = useToast();
  const { setModal, unSetModal } = useActionPrompt();
  const {
    authStore: {
      leadProAuthStore: { disable2FA },
    },
  } = useAppStore();

  const on2FADisable = useCallback(async () => {
    try {
      await disable2FA();
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: (
          <ApiMessageStack
            messageStack={"Two-factor authentication disabled successfully!"}
          />
        ),
      });
    } catch (error) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={error.message} />,
      });
    }
  }, [disable2FA, toast]);

  const handle2FADisable = useCallback(async () => {
    setModal(
      <ConfirmActionPrompt
        text={
          "You are about to disable Two-factor authentication. Are you sure?"
        }
        onConfirm={on2FADisable}
        closePrompt={unSetModal}
      />
    );
  }, [on2FADisable, setModal, unSetModal]);

  const handle2FAEnable = useCallback(() => {
    setModal(<TwoFactorAuthSetupPrompt closePrompt={unSetModal} user={user} />);
  }, [user, setModal, unSetModal]);

  const handle2FAToggle = useCallback(async () => {
    if (user.sms2FAEnabled) {
      await handle2FADisable();
    } else {
      handle2FAEnable();
    }
  }, [user.sms2FAEnabled, handle2FADisable, handle2FAEnable]);

  return (
    <Box
      position={"relative"}
      minHeight={"100%"}
      overflow={"hidden"}
      pr={1}
      pt={1}
    >
      <VStack spacing={10} align={"stretch"} divider={<Divider />}>
        <SimpleGrid columns={2} spacing={4}>
          <FormControlMeta
            label="Two-factor authentication"
            description={
              "Two-factor authentication is an extra layer of security for your LeadPro Dashboard designed to ensure that you're the only person who can access your account, even if someone knows your password."
            }
          />
          <Box>
            <Button
              onClick={handle2FAToggle}
              colorScheme={user.sms2FAEnabled ? "red" : "green"}
            >
              {user.sms2FAEnabled ? "Disable" : "Enable"} 2FA
            </Button>
          </Box>
        </SimpleGrid>
        <Box />
      </VStack>
    </Box>
  );
});
