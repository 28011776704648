import { Tag, TagLabel } from "@chakra-ui/react";
import React, { FC } from "react";
import { getWorkflowActivityLabel } from "utils/lead-responder-workflow.utils";

interface ILeadResponderWorkflowActiveStatusTagProps {
  isActive: boolean;
}

export const LeadResponderWorkflowActiveStatusTag: FC<ILeadResponderWorkflowActiveStatusTagProps> = ({
  isActive,
}) => {
  return (
    <Tag
      variant={"subtle"}
      rounded={"full"}
      colorScheme={isActive ? "green" : "red"}
    >
      <TagLabel>{getWorkflowActivityLabel(isActive)}</TagLabel>
    </Tag>
  );
};
