import { Box, Button } from "@chakra-ui/react";
import { TableV2 } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DeleteJourneyPrompt } from "routes/dashboard/components/prompts/JourneyFormPrompt/DeleteJourneyPrompt";
import { JourneyFormPrompt } from "routes/dashboard/components/prompts/JourneyFormPrompt/JourneyFormPrompt";
import { AccountJourneysStore } from "store/UserAccounts/UserAccount/AccountJourneys.store";
import { AccountPersistentFiltersStore } from "store/UserAccounts/UserAccount/AccountPersistentFilters.store";
import { TAccountJourney } from "types/account-journeys.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { journeyTableColumnDef } from "./journeyTableColumnDef";

interface IProps {
  accountJourneysStore: AccountJourneysStore;
  accountPersistentFiltersStore: AccountPersistentFiltersStore;
}

export const Journeys: FC<IProps> = observer(
  ({ accountJourneysStore, accountPersistentFiltersStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const { setModal, unSetModal } = useActionPrompt();
    const {
      persistentFiltersAsOptions,
      availablePersistentFiltersAsOptions,
      loadPersistentFilters,
    } = accountPersistentFiltersStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountJourneysStore.fetchAllAccountJourneys();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountJourneysStore]);

    const handleCreateOrEditJourney = useCallback(
      (journey?: TAccountJourney) => () => {
        setModal(
          <JourneyFormPrompt
            closePrompt={unSetModal}
            accountJourneysStore={accountJourneysStore}
            journey={journey}
            filtersOptions={persistentFiltersAsOptions}
            availableFiltersOptions={availablePersistentFiltersAsOptions}
            fetchFilters={loadPersistentFilters}
          />
        );
      },
      [
        setModal,
        unSetModal,
        accountJourneysStore,
        persistentFiltersAsOptions,
        availablePersistentFiltersAsOptions,
        loadPersistentFilters,
      ]
    );

    const handleDeleteJourney = useCallback(
      (journey: TAccountJourney) => () => {
        setModal(
          <DeleteJourneyPrompt
            closePrompt={unSetModal}
            journey={journey}
            journeysStore={accountJourneysStore}
            fetchFilters={loadPersistentFilters}
          />
        );
      },
      [setModal, unSetModal, accountJourneysStore, loadPersistentFilters]
    );

    const handleOnRowClick = useCallback(
      (journey: TAccountJourney) => {
        handleCreateOrEditJourney(journey)();
      },
      [handleCreateOrEditJourney]
    );

    const columnsDef = useMemo(() => {
      return journeyTableColumnDef(
        persistentFiltersAsOptions,
        handleCreateOrEditJourney,
        handleDeleteJourney
      );
    }, [
      persistentFiltersAsOptions,
      handleCreateOrEditJourney,
      handleDeleteJourney,
    ]);

    return (
      <Box>
        <TableV2<TAccountJourney>
          dataSource={accountJourneysStore.accountJourneysArray}
          columns={columnsDef}
          pageSize={20}
          loadingStatus={loadingStatus}
          globalFilterInputPlaceholder={"Type filter name"}
          striped={true}
          onRowClick={handleOnRowClick}
          additionalActions={
            <Box flexGrow={1}>
              <Button
                variant={"link"}
                colorScheme={"blue"}
                onClick={handleCreateOrEditJourney()}
              >
                + Create Journey
              </Button>
            </Box>
          }
        />
      </Box>
    );
  }
);
