import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  LeadResponderWorkflowActiveStatusTag,
  NA,
  StandardIconButton,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-solid-svg-icons";

import React from "react";
import { TQuestionnairePageTableData } from "types/questionnaire-tool-page.type";
import { TPersistentFilter } from "../../../../../../../../types/persistent-filters.type";

const ICON_SIZE = 18;

export const leadResponderQuestionnairePageTableColumnDef = (
  persistentFiltersMap: Record<number, TPersistentFilter>,
  handleEditQuestionnairePage: (row: TQuestionnairePageTableData) => () => void,
  handleRemoveQuestionnairePage: (
    row: TQuestionnairePageTableData
  ) => () => void,
  handleIncreaseQuestionnairePagePriority: (
    row: TQuestionnairePageTableData
  ) => () => void,
  handleDecreaseQuestionnairePagePriority: (
    row: TQuestionnairePageTableData
  ) => () => void,
  handleGoToResponses: (row: TQuestionnairePageTableData) => () => void,
  duplicateQuestionnairePage: (row: TQuestionnairePageTableData) => () => void,
  isSubmitting: boolean
): ITableV2Column<TQuestionnairePageTableData>[] => {
  return [
    {
      accessor: "name",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "active",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <LeadResponderWorkflowActiveStatusTag isActive={row.active} />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "leadFilterId",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Filter</DefaultHeaderCell>,
      Cell: ({ row }) => {
        return (
          <DefaultRowCell>
            {row.leadFilterId ? (
              <Box>
                {persistentFiltersMap[row.leadFilterId]?.name || (
                  <NA verb={"found"} />
                )}
              </Box>
            ) : row.default ? (
              <Box color="gray.400">Default</Box>
            ) : (
              <NA verb={"set"} />
            )}
          </DefaultRowCell>
        );
      },
    },
    {
      accessor: "createdAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Created</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "updatedAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Last edited</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.updatedAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "priority",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "70px",
      Header: <DefaultHeaderCell>Priority</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell onClick={event => event.stopPropagation()}>
          <ButtonGroup spacing={1} isDisabled={isSubmitting}>
            <StandardIconButton
              fontSize={"xl"}
              aria-label={"priority-up"}
              icon={<FontAwesomeIcon icon={faAngleUp} fontSize={ICON_SIZE} />}
              onClick={handleIncreaseQuestionnairePagePriority(row)}
              isDisabled={row.default}
            />
            <StandardIconButton
              fontSize={"xl"}
              aria-label={"priority-down"}
              icon={<FontAwesomeIcon icon={faAngleDown} fontSize={ICON_SIZE} />}
              onClick={handleDecreaseQuestionnairePagePriority(row)}
              isDisabled={row.default}
            />
          </ButtonGroup>
        </DefaultRowCell>
      ),
    },
    {
      accessor: "id",
      width: "30px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell
          justifyContent={"flex-end"}
          onClick={event => event.stopPropagation()}
        >
          <Menu isLazy={true} placement={"bottom-end"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  fontSize={ICON_SIZE}
                />
              }
              size="xs"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={handleEditQuestionnairePage(row)}>
                {row.default ? "View" : "Edit"}
              </MenuItem>
              <MenuItem onClick={duplicateQuestionnairePage(row)}>
                Duplicate
              </MenuItem>
              <MenuItem onClick={handleGoToResponses(row)}>Responses</MenuItem>
              <MenuItem
                onClick={handleRemoveQuestionnairePage(row)}
                isDisabled={row.default}
              >
                Remove
              </MenuItem>
            </MenuList>
          </Menu>
        </DefaultRowCell>
      ),
    },
  ];
};
