import { observer } from "mobx-react";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";
import React, { FC } from "react";
import { FormToolPageSettingsActionConfiguratorItem } from "./FormToolPageSettingsActionConfiguratorItem";
import { Box } from "@chakra-ui/react";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsActionType: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore }) => {
    return (
      <FormToolPageSettingsActionConfiguratorItem
        label={"Action type"}
        showError={false}
        input={
          <Box>
            {formToolPageWIPFormStore.getActionLabelForAction(actionIndex)}
          </Box>
        }
      />
    );
  }
);
