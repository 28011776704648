export enum LeadAttributionStatusEnum {
  VIEWING_BOOKED = "Viewing booked",
  VALUATION_BOOKED = "Valuation booked",
  SELLING_INSTRUCTED_TO_MARKET = "Selling instructed to market",
  LETTING_INSTRUCTED_TO_MARKET = "Letting instructed to market",
  OFFER_MADE = "Offer made",
  OFFER_RECEIVED = "Offer received",
  OFFER_ACCEPTED = "Offer accepted",
  TENANCY_REQUESTED_BY_CONTACT = "Tenancy requested by contact",
  TENANCY_REQUESTED_TO_CONTACT = "Tenancy requested to contact",
  TENANCY_ACCEPTED = "Tenancy accepted",
  PROPERTY_EXCHANGED = "Property exchanged",
  PROPERTY_COMPLETED = "Property completed",
  LEAD_CREATED = "Lead created",
  LEAD_SYNCED = "Lead synced",
  UNDEFINED_STATUS = "Undefined status",
}

export enum LeadAttributionGroupedEnum {
  APPOINTMENTS = "Appointments",
  VALUATIONS = "Valuations",
  OFFERS = "Offers",
  INSTRUCTIONS = "Instructions",
  TENANCIES = "Tenancies",
  EXCHANGES = "Exchanges",
  COMPLETIONS = "Completions",
  OTHERS = "Others",
}

export enum LeadAttributionTypesEnum {
  OFFICE = "office",
  SOURCE = "source",
  CAMPAIGN = "campaign",
  JOURNEY = "journey",
}
