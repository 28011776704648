import { UserRoleEnum } from "enums/user-role.enum";
import { UserRoleLabel } from "./user-role-label";

export const ROLE_INPUT_OPTIONS = [
  {
    value: UserRoleEnum.ADMIN,
    description: "Full account access",
    label: UserRoleLabel[UserRoleEnum.ADMIN],
  },
  {
    value: UserRoleEnum.LEADER,
    description:
      "Only able to a subset of offices. No access to billing or other settings.",
    label: UserRoleLabel[UserRoleEnum.LEADER],
  },
  {
    value: UserRoleEnum.AGENT,
    description: "Only able to view one office.",
    label: UserRoleLabel[UserRoleEnum.AGENT],
  },
];
