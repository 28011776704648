import * as Yup from "yup";
import {
  REQUIRED_FIELD,
  URL_FIELD,
  URL_FIELD_MISSING_PROTOCOL,
} from "constants/validator-messages";
import { doesUrlContainProtocol } from "../custom-yup-validators.utils";

export const developersConfigWebhookValidation = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  webhookUrl: Yup.string()
    .test("is-valid-url", URL_FIELD_MISSING_PROTOCOL, doesUrlContainProtocol)
    .url(URL_FIELD)
    .required(REQUIRED_FIELD),
});
