import { Input } from "components/index";
import { observer } from "mobx-react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { Box } from "@chakra-ui/react";
import { TIntegrationSyncOfficeExtendedData } from "../../types/integration-sync-office.type";

interface IProps {
  record: TIntegrationSyncOfficeExtendedData;
  onChange: (syncPair: TIntegrationSyncOfficeExtendedData) => void;
  placeholder?: string;
}

export const IntegrationOfficeSyncInput: FC<IProps> = observer(
  ({ record, onChange, placeholder }) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...record,
          branchId: event.target.value || null,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <Box px={1}>
        <Input
          value={record.branchId || ""}
          placeholder={placeholder || "Enter integration branch ID"}
          onChange={handleChange}
        />
      </Box>
    );
  }
);
