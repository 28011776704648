import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TCreateOrUpdatePageData, TPageData } from "types/page.type";
import { AccountPagesApi } from "api/account-pages.api";
import { keyBy } from "lodash";
import { PageToolTypeEnum } from "enums/page-tool-type.enum";
import { IAccountPageToolStore } from "./IAccountPageToolStore";

const pagesApi = new AccountPagesApi();

export class AccountIVTPagesStore implements IAccountPageToolStore {
  private readonly accountId: number;
  private readonly accountPages: IObservableArray<TPageData>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.accountPages = observable.array<TPageData>();
  }

  get accountPagesArray() {
    return this.accountPages.slice();
  }

  get accountPagesMap() {
    return keyBy(this.accountPages, page => page.id);
  }

  public setPages(pages: TPageData[]) {
    this.accountPages.replace(pages);
  }

  public deletePage(pageId: number) {
    const index = this.accountPages.findIndex(page => page.id === pageId);
    if (index > -1) {
      this.accountPages.splice(index, 1);
    }
  }

  public upsertPage(pageData: TPageData) {
    const index = this.accountPages.findIndex(page => page.id === pageData.id);
    if (index > -1) {
      this.accountPages[index] = pageData;
    } else {
      this.accountPages.push(pageData);
    }
  }

  public async loadPages() {
    const pages = await pagesApi.fetchAll(
      this.accountId,
      PageToolTypeEnum.INSTANT_VALUATION_TOOL
    );

    this.setPages(pages);
  }

  public async loadPage(id: number) {
    const page = await pagesApi.fetchOne(
      id,
      this.accountId,
      PageToolTypeEnum.INSTANT_VALUATION_TOOL
    );

    this.upsertPage(page);
  }

  public async createPage(data: Partial<TCreateOrUpdatePageData>) {
    const newPage = await pagesApi.create(this.accountId, data);
    this.upsertPage(newPage);

    return newPage.id;
  }

  public async updatePage(
    pageId: number,
    data: Partial<TCreateOrUpdatePageData>
  ) {
    const updatedPage = await pagesApi.update(this.accountId, pageId, data);
    this.upsertPage(updatedPage);
  }

  public async removePage(pageId: number) {
    await pagesApi.delete(this.accountId, pageId);
    this.deletePage(pageId);
  }
}
