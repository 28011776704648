import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useEffect, useState } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { LeadAttributionCalculationValuesSettings } from "./LeadAttributionCalculationValuesSettings/LeadAttributionCalculationValuesSettings";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { LeadAttributionReportSegment } from "./LeadAttributionReportSegment/LeadAttributionReportSegment";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadAttributionReporting: FC<IProps> = observer(
  ({ accountStore }) => {
    const [
      loadingLeadAttributionAnalyticsCalculationConfigStatus,
      setLoadingLeadAttributionAnalyticsCalculationConfigStatus,
    ] = useState(ApiRequestStatusEnum.NONE);

    const accountAnalyticsStore = accountStore.accountAnalyticsStore;
    const {
      accountAnalyticsLeadAttributionStore: {
        fetchLeadAttributionAnalyticsCalculationConfig,
      },
    } = accountAnalyticsStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingLeadAttributionAnalyticsCalculationConfigStatus(
            ApiRequestStatusEnum.PENDING
          );
          await fetchLeadAttributionAnalyticsCalculationConfig();
          setLoadingLeadAttributionAnalyticsCalculationConfigStatus(
            ApiRequestStatusEnum.SUCCESS
          );
        } catch (e) {
          setLoadingLeadAttributionAnalyticsCalculationConfigStatus(
            ApiRequestStatusEnum.ERROR
          );
        }
      };

      fetchData();
    }, [fetchLeadAttributionAnalyticsCalculationConfig]);

    return (
      <>
        {loadingLeadAttributionAnalyticsCalculationConfigStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingLeadAttributionAnalyticsCalculationConfigStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {loadingLeadAttributionAnalyticsCalculationConfigStatus ===
          ApiRequestStatusEnum.SUCCESS && (
          <VStack spacing={6} width={"100%"}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap={10}
              width={"100%"}
            >
              <Box maxWidth={"890px"} flexGrow={1} color={"leadpro.500"}>
                Lead attribution reporting uses your CRM data from the Nurtur
                Data Warehouse to attribute your agency’s business activity back
                to the leads you’ve had generated.
              </Box>
              <Box flexShrink={0}>
                <LeadAttributionCalculationValuesSettings
                  accountAnalyticsStore={accountAnalyticsStore}
                />
              </Box>
            </Flex>
            <VStack spacing={6} width={"100%"}>
              <LeadAttributionReportSegment
                accountAnalyticsStore={accountAnalyticsStore}
                leadAttributionType={LeadAttributionTypesEnum.OFFICE}
              />
              <LeadAttributionReportSegment
                accountAnalyticsStore={accountAnalyticsStore}
                leadAttributionType={LeadAttributionTypesEnum.SOURCE}
              />
              <LeadAttributionReportSegment
                accountAnalyticsStore={accountAnalyticsStore}
                leadAttributionType={LeadAttributionTypesEnum.CAMPAIGN}
              />
              <LeadAttributionReportSegment
                accountAnalyticsStore={accountAnalyticsStore}
                leadAttributionType={LeadAttributionTypesEnum.JOURNEY}
              />
            </VStack>
          </VStack>
        )}
      </>
    );
  }
);
