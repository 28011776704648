import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const Panel: React.FC<BoxProps> = ({
  children,
  overflow = "hidden",
  ...rest
}) => {
  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      rounded="lg"
      bg="white"
      overflow={overflow}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Panel;
