import { BaseApi } from "./base.api";
import {
  TAccountReapitIntegrationConfigSyncData,
  TReapitArea,
  TReapitContact,
  TReapitLeadSource,
  TReapitLeadSourceRelation,
  TReapitNegotiator,
  TReapitNegotiatorRelation,
  TReapitNegotiatorRelationData,
  TReapitOffice,
  TReapitOfficeRelation,
  TReapitSourceRelationDataExtended,
} from "types/reapit.type";
import { TIntegrationOffice } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import {
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "../types/integration-sync-office.type";
import {
  reapitOfficeDataToIntegrationOfficeDataMapper,
  mapReapitOfficeRelationsToIntegrationOfficeRelations,
  mapIntegrationOfficeRelationsToReapitOfficeRelations,
} from "utils/reapit.utils";

export class AccountReapitApi extends BaseApi {
  async getReapitConfig(
    accountId: number
  ): Promise<TAccountReapitIntegrationConfigSyncData> {
    return await this.get(`/accounts/${accountId}/reapit/reapit-config`);
  }

  async fetchAccountOffices(
    accountId: number,
    officeGroupId?: number
  ): Promise<TIntegrationOffice[]> {
    const data = await this.get(
      `/accounts/${accountId}/reapit/reapit-offices`,
      {
        params: {
          officeGroupId,
        },
      }
    );
    const mappedData = reapitOfficeDataToIntegrationOfficeDataMapper(
      data as TReapitOffice[]
    );
    return mappedData as TIntegrationOffice[];
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    const data = await this.get(
      `/accounts/${accountId}/reapit/office-relations`
    );
    const mappedData = mapReapitOfficeRelationsToIntegrationOfficeRelations(
      data as TReapitOfficeRelation[]
    );
    return mappedData as TIntegrationOfficeRelation[];
  }

  async fetchAccountReapitNegotiators(
    accountId: number,
    officeGroupId?: number
  ): Promise<TReapitNegotiator[]> {
    return this.get(`/accounts/${accountId}/reapit/reapit-negotiators`, {
      params: {
        officeGroupId,
      },
    });
  }

  async fetchAccountReapitNegotiatorRelations(
    accountId: number
  ): Promise<TReapitNegotiatorRelation[]> {
    return this.get(`/accounts/${accountId}/reapit/negotiator-relations`);
  }

  async fetchAccountReapitLeadSources(
    accountId: number,
    officeGroupId?: number
  ): Promise<TReapitLeadSource[]> {
    return this.get(`/accounts/${accountId}/reapit/reapit-sources`, {
      params: {
        officeGroupId,
      },
    });
  }

  async fetchAccountReapitLeadSourceRelations(
    accountId: number,
    officeGroupId?: number
  ): Promise<TReapitLeadSourceRelation[]> {
    return this.get(`/accounts/${accountId}/reapit/source-relations`, {
      params: {
        officeGroupId,
      },
    });
  }

  async fetchAccountReapitAreas(
    accountId: number,
    leadId: number
  ): Promise<TReapitArea[]> {
    return this.get(
      `/accounts/${accountId}/leads/${leadId}/reapit/reapit-areas`
    );
  }

  async fetchAccountReapitContacts(
    accountId: number,
    leadId: number,
    officeGroupId?: number
  ): Promise<TReapitContact[]> {
    return this.get(
      `/accounts/${accountId}/leads/${leadId}/reapit/related-contacts`,
      {
        params: {
          officeGroupId,
        },
      }
    );
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/reapit/office-relations`, {
      items: mapIntegrationOfficeRelationsToReapitOfficeRelations(
        relationsData
      ),
    });
  }

  async updateAccountReapitSyncedNegotiators(
    accountId: number,
    relationsData: TReapitNegotiatorRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/reapit/negotiator-relations`, {
      items: relationsData,
    });
  }

  async updateAccountReapitSyncedLeadSources(
    accountId: number,
    relationsData: TReapitSourceRelationDataExtended[]
  ) {
    await this.post(`/accounts/${accountId}/reapit/source-relations`, {
      items: relationsData,
    });
  }
}
