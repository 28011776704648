import { Loader, Error, TableV2 } from "components";
import { TAccountOfficeData } from "types/account-office.type";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { DeleteOfficePrompt } from "routes/dashboard/components/prompts/OfficePrompt/DeleteOfficePrompt";
import { observer } from "mobx-react";
import { accountOfficesTableColumnDef } from "./accountOfficesTableColumnDef";
import { SETTINGS_OFFICES_ROUTE } from "constants/routes";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeFormPrompt } from "routes/dashboard/components/prompts/OfficePrompt/OfficeFormPrompt";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
}

export const OfficesList: FC<IProps> = observer(({ accountStore }) => {
  const history = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
    ApiRequestStatusEnum.NONE
  );
  const officesStore = accountStore.accountOfficesStore;
  const { setModal, unSetModal } = useActionPrompt();
  const accountOfficesDataArray = officesStore.accountOfficesDataArray;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await officesStore.fetchAccountOffices();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };
    fetchData();
  }, [officesStore]);

  const handleRowClick = useCallback(
    (office: TAccountOfficeData) => {
      history.push(`${SETTINGS_OFFICES_ROUTE}/${office.id}`);
    },
    [history]
  );

  const handleRemoveOfficeFromAccount = useCallback(
    (office: TAccountOfficeData) => async (
      event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      event.stopPropagation();
      event.preventDefault();

      setModal(
        <DeleteOfficePrompt
          office={office}
          officesStore={officesStore}
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, officesStore]
  );

  const handleOpenOfficeCreationPrompt = useCallback(
    () => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      setModal(
        <OfficeFormPrompt
          closePrompt={unSetModal}
          accountStore={accountStore}
        />
      );
    },
    [setModal, unSetModal, accountStore]
  );

  const columnsDef = useMemo(() => {
    return accountOfficesTableColumnDef(handleRemoveOfficeFromAccount);
  }, [handleRemoveOfficeFromAccount]);

  return (
    <>
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <Box>
          <TableV2<TAccountOfficeData>
            globalFilterInputPlaceholder={"Search offices by name"}
            dataSource={accountOfficesDataArray}
            columns={columnsDef}
            pageSize={DEFAULT_PAGE_SIZE}
            striped={true}
            onRowClick={handleRowClick}
            additionalActions={
              <Box flexGrow={1}>
                <Button
                  variant={"link"}
                  colorScheme={"blue"}
                  onClick={handleOpenOfficeCreationPrompt()}
                >
                  + Create office
                </Button>
              </Box>
            }
          />
        </Box>
      )}
    </>
  );
});
