import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, Error, FormControlV2, Loader } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  TUrlSyncIntegrationConfigFieldValues,
  urlSyncIntegrationConnectValidationSchema,
} from "utils/validation-schemas/url-sync-integration.validation";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

type TFieldValues = {
  urlTemplate: string;
};

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

const initialValues = {
  urlTemplate: "",
};

export const ConnectUrlSyncForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();
    const urlSyncStore =
      accountIntegrationsStore.accountIntegrationUrlSyncStore;
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const mergeTagOptions = urlSyncStore.mergeTagGroupOptionsArray;

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<any>({
      defaultValues: initialValues,
      resolver: yupResolver(urlSyncIntegrationConnectValidationSchema),
      mode: "onSubmit",
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await urlSyncStore.loadUrlSyncMergeTags();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (err) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
        await urlSyncStore.loadUrlSyncMergeTags();
      };

      fetchData();
    }, [urlSyncStore]);

    const onSubmit = useCallback(
      async (integrationConfig: TUrlSyncIntegrationConfigFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TUrlSyncIntegrationConfigFieldValues
          >({
            type: AccountIntegrationTypeEnum.URL_SYNC,
            active: true,
            integrationConfig: integrationConfig,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Url Sync successfully connected."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box h={"200px"}>
            <Loader />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.ERROR && (
          <Box h={"200px"}>
            <Error />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"urlTemplate"}
                  control={control}
                  label={"Base Url"}
                  type={FormControlsTypeEnum.MERGE_TAG_INPUT}
                  additionalProps={{
                    singleLine: false,
                    mergeTagOptions: mergeTagOptions,
                  }}
                />
                <Box mt={3}>
                  Use our lead templator to choose what lead data you want to
                  include in your parameters.Type {"{"} to see the available
                  merge tags.
                </Box>
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Connect
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </>
    );
  }
);
