import { Box, Center, Divider, VStack } from "@chakra-ui/react";
import { Loader } from "components";
import React from "react";
import { TExportRequest } from "types/export-request.type";
import { ExportRequestItem } from "./ExportRequestItem";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

interface IProps {
  exportRequests: TExportRequest[];
  loadingStatus: ApiRequestStatusEnum;
}

export const ExportRequestList: React.FC<IProps> = observer(
  ({ exportRequests, loadingStatus }) => {
    return (
      <Box position={"relative"}>
        <VStack
          spacing={2}
          align={"stretch"}
          divider={<Divider />}
          maxHeight={"500px"}
          overflowY={"auto"}
          position={"relative"}
        >
          {!!exportRequests.length ? (
            exportRequests.map(request => (
              <ExportRequestItem key={request.id} request={request} />
            ))
          ) : (
            <Center width={"100%"} height={"100px"}>
              <Box color={"gray.500"}>No active exports.</Box>
            </Center>
          )}
        </VStack>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      </Box>
    );
  }
);
