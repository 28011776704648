import { IObservableArray, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { AnalyticsDateRangeFilterEnum } from "enums/analytics-date-range-filter.enum";
import { DATE_FORMAT } from "constants/date";
import { TAnalyticsDateRangeFilterConfiguration } from "types/account-analytics.type";

const DEFAULT_DATE_RANGE = {
  type: AnalyticsDateRangeFilterEnum.CUSTOM,
  startDate: moment()
    .subtract(31, "day")
    .startOf("day")
    .toISOString(),
  endDate: moment()
    .subtract(1, "day")
    .endOf("day")
    .toISOString(),
};

export class AccountAnalyticsFiltersStore {
  private dateConfig: TAnalyticsDateRangeFilterConfiguration;
  private officeIds: IObservableArray<number>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.dateConfig = DEFAULT_DATE_RANGE;
    this.officeIds = observable.array<number>();
  }

  get dateRange(): TAnalyticsDateRangeFilterConfiguration {
    return this.dateConfig;
  }

  get dateRangeFormated() {
    return {
      startDate: moment(this.dateConfig.startDate).format(DATE_FORMAT),
      endDate: moment(this.dateConfig.endDate).format(DATE_FORMAT),
    };
  }

  get officeIdsArray() {
    return this.officeIds.slice();
  }

  public changeDateRange(config: TAnalyticsDateRangeFilterConfiguration) {
    this.dateConfig = {
      type: config.type || AnalyticsDateRangeFilterEnum.CUSTOM,
      startDate: config.startDate,
      endDate: config.endDate,
    };
  }

  public setOfficeIds(officeIds: number[]) {
    this.officeIds.replace(officeIds);
  }
}
