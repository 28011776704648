import React, { useMemo } from "react";
import { AlertDescription, AlertIcon, Box } from "@chakra-ui/react";
import { Alert } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { LeadTypeEnum } from "enums/lead-type.enum";

interface IProps {
  leadStore: AccountLeadStore;
}

const leadTypeToVerb: Partial<Record<LeadTypeEnum, string>> = {
  [LeadTypeEnum.Landlord]: "to let",
  [LeadTypeEnum.Vendor]: "to sell",
};

export const LeadInfoBanner: React.FC<IProps> = observer(({ leadStore }) => {
  const { type, leadReferredByAccount, leadReferredByOffice } = leadStore.lead;
  const isChildLead = leadStore.isChildLead;
  const isLeadReferred = leadStore.isLeadReferred;

  const referredByInsert = useMemo(() => {
    if (!!leadReferredByOffice && !!leadReferredByAccount) {
      return (
        <Box as={"span"}>
          the <Box as={"strong"}>{leadReferredByOffice.name}</Box> office (
          <Box as={"strong"}>{leadReferredByAccount.name}</Box> account)
        </Box>
      );
    }

    if (!!leadReferredByOffice) {
      return (
        <Box as={"span"}>
          the <Box as={"strong"}>{leadReferredByOffice.name}</Box> office
        </Box>
      );
    }

    if (!!leadReferredByAccount) {
      return (
        <Box as={"span"}>
          the <Box as={"strong"}>{leadReferredByAccount.name}</Box> account
        </Box>
      );
    }

    return null;
  }, [leadReferredByAccount, leadReferredByOffice]);

  const message = useMemo(() => {
    if (!isChildLead && !isLeadReferred) return null;
    if (
      ![LeadTypeEnum.Landlord, LeadTypeEnum.Vendor].includes(type) &&
      isChildLead
    )
      return (
        <AlertDescription>
          This lead has been created from a smart question from LeadPro's
          prequalification questionnaire.
        </AlertDescription>
      );

    if ([LeadTypeEnum.Landlord, LeadTypeEnum.Vendor].includes(type)) {
      if (!isChildLead && !!leadReferredByAccount && !leadReferredByOffice) {
        return (
          <AlertDescription>
            The {type} has originally submitted an enquiry with{" "}
            {referredByInsert} but is in your postcode coverage area.
          </AlertDescription>
        );
      }

      return (
        <AlertDescription>
          The {type} has enquired to view a property from{" "}
          {referredByInsert || "your office"}, but has also said they have a
          property {leadTypeToVerb[type]}.
        </AlertDescription>
      );
    }
  }, [
    isChildLead,
    isLeadReferred,
    leadReferredByAccount,
    leadReferredByOffice,
    referredByInsert,
    type,
  ]);

  if (!message) return null;

  return (
    <Alert status={AlertStatusEnum.INFO}>
      <AlertIcon />
      {message}
    </Alert>
  );
});
