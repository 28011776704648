export const formatMoney = (
  value: number,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0
): string => {
  return new Intl.NumberFormat("en-GB", {
    maximumFractionDigits,
    minimumFractionDigits,
    style: "currency",
    currency: "GBP",
  }).format(value);
};

interface IFormatNumberProps {
  number: any;
}

export const formatNumber = (props: IFormatNumberProps) => {
  let { number } = props;

  let formatter = new Intl.NumberFormat("en-GB");

  return formatter.format(number);
};
