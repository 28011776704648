import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/react";
import { FormControlMeta, FormControlV2 } from "components/form";
import { PageCtaEnum } from "enums/page-cta.enum";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TInstantValuationToolSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { Control } from "react-hook-form";
import { PageToolPageSettingsInputLayout } from "routes/dashboard/routes/tools/components/PageTool/PageToolPageSettings/PageToolPageSettingsInputLayout";

type TFieldValues = TInstantValuationToolSettingsFieldValues;

interface IProps {
  control: Control<TFieldValues>;
}

export const InstantValuationToolCTASettings: FC<IProps> = ({ control }) => {
  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        Call to action
      </Text>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Call to action"
          description={
            "Specify the type of call to action that shows on the final results page."
          }
        />
        <FormControlV2
          name={"ctaType"}
          control={control}
          type={FormControlsTypeEnum.RADIO}
          additionalProps={{
            options: [
              {
                value: PageCtaEnum.BOOKING_CALENDAR,
                label: "Booking Calendar",
                description:
                  "Allows the lead to request a valuation appointment after viewing their valuation.",
              },
              {
                value: PageCtaEnum.CUSTOM,
                label: "Custom",
                description: (
                  <Box color="gray.900">
                    <Box mb={2} mt={4}>
                      <FormControlV2<TFieldValues>
                        name={"customCtaLabel"}
                        control={control}
                        label={"Label"}
                        type={FormControlsTypeEnum.TEXT}
                        additionalProps={{
                          placeholder: "e.g Click here",
                        }}
                      />
                    </Box>
                    <Box mb={2}>
                      <FormControlV2<TFieldValues>
                        name={"customCtaUrl"}
                        control={control}
                        label={"URL"}
                        type={FormControlsTypeEnum.TEXT}
                        additionalProps={{
                          placeholder: "http://",
                        }}
                      />
                    </Box>
                  </Box>
                ),
              },
              {
                value: PageCtaEnum.NONE,
                label: "No call to action",
              },
            ],
          }}
        />
      </PageToolPageSettingsInputLayout>
    </Box>
  );
};
