import { Checkbox, HStack } from "@chakra-ui/react";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import { FC, useCallback, MouseEvent } from "react";
import { OfficeUsersListItem } from "../OfficeUsersListItem";

interface IProps {
  user: IAccountUserDataWithDetails;
  isChecked: boolean;
  onToggle: (userId: number) => void;
}
export const SelectableOfficeUsersListItem: FC<IProps> = ({
  user,
  isChecked,
  onToggle,
}) => {
  const handleToggle = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onToggle(user.id);
    },
    [onToggle, user]
  );

  return (
    <HStack
      width={"100%"}
      spacing={2}
      align={"center"}
      justify={"flex-start"}
      onClick={handleToggle}
      cursor={"pointer"}
    >
      <Checkbox isChecked={isChecked} />
      <OfficeUsersListItem user={user} roleId={user.userAccount.roleId} />
    </HStack>
  );
};
