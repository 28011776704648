import {
  ApiMessageStack,
  FormControlMeta,
  FormControlV2,
  PortalSelectOption,
  RegisteredCompanySelectOption,
} from "components";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  Divider,
  Portal,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { TUserAccountData } from "types/user-account.type";
import { TRegisteredCompanyData } from "types/account.type";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TPortalData } from "types/portal.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormFooter } from "components/form";
import {
  getDataFromFormValues,
  getFormValuesFromData,
  TWorkingHoursFieldValues,
  workingHoursValidationSchema,
} from "utils/validation-schemas/office-working-hours.validation";
import { OfficeWorkingHoursForm } from "routes/dashboard/routes/settings/routes/offices/routes/office-settings/components/OfficeDetails/OfficeWorkingHoursForm";
import { AccountLogoInput } from "routes/dashboard/components";
import { accountDetailsValidationSchema } from "utils/validation-schemas/account-details.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = TWorkingHoursFieldValues & {
  registeredCompany: TRegisteredCompanyData | null;
  name: TUserAccountData["name"];
  website: TUserAccountData["website"];
  portalIds: TUserAccountData["portalIds"];
  primaryColor: TUserAccountData["primaryColor"];
};

interface IProps {
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AccountDetails: FC<IProps> = observer(
  ({ accountStore, containerRef }) => {
    const { uiStore } = useAppStore();
    const accountData = accountStore.account;
    const registeredCompaniesOptions = uiStore.registeredCompaniesOptionsArray;
    const portalOptions = accountStore.portalsAsSelectOptions;
    const toast = useToast();

    const initialValues: TFieldValues = useMemo(
      () => ({
        registeredCompany: accountData.companyName
          ? {
              companyName: accountData.companyName,
              companyRegistrationNumber: accountData.companyRegistrationNumber,
              companyAddress: accountData.companyAddress,
            }
          : null,
        name: accountData.name,
        website: accountData.website || "",
        portalIds: accountData.portalIds,
        primaryColor: accountData.primaryColor || "",
        ...getFormValuesFromData(accountData.workingHours),
      }),
      [accountData]
    );

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(
        accountDetailsValidationSchema.concat(workingHoursValidationSchema)
      ),
    });

    const onCompanySearch = useCallback(
      async (searchTerm: string) => {
        await uiStore.fetchRegisteredCompaniesOptions(searchTerm);
      },
      [uiStore]
    );

    const onSubmit = async ({
      registeredCompany,
      name,
      website,
      portalIds,
      primaryColor,
      ...workingHours
    }: TFieldValues) => {
      try {
        const isAccountNameUpdated = name !== accountData.name;
        await accountStore.update({
          companyName: registeredCompany?.companyName,
          companyRegistrationNumber:
            registeredCompany?.companyRegistrationNumber,
          companyAddress: registeredCompany?.companyAddress,
          name,
          portalIds,
          website: website || null,
          primaryColor: primaryColor || null,
          workingHours: getDataFromFormValues(workingHours),
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={`Account updated! ${
                isAccountNameUpdated
                  ? "Your account name is updated, but it might take up to 24 hours for the updated name to appear on all parts of your Dashboard."
                  : ""
              }`}
            />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    useEffect(() => {
      if (
        accountData.companyName &&
        accountData.companyRegistrationNumber &&
        accountData.companyAddress
      ) {
        uiStore.setRegisteredCompaniesOptions([
          {
            value: {
              companyName: accountData.companyName,
              companyRegistrationNumber: accountData.companyRegistrationNumber,
              companyAddress: accountData.companyAddress,
            },
            label: accountData.companyName,
          },
        ]);
      }
    }, [uiStore, accountData]);

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={10} align={"stretch"} divider={<Divider />}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Registered company"
                description={
                  "The private or public company that is registered with companies house."
                }
              />
              <FormControlV2<TFieldValues>
                name={"registeredCompany"}
                control={control}
                type={FormControlsTypeEnum.SINGLE_SELECT}
                additionalProps={{
                  options: registeredCompaniesOptions,
                  asyncFilterFn: onCompanySearch,
                  placeholder: "Select your registered company",
                  filterPlaceholder: "Lookup company name from Companies House",
                  optionComponent: (
                    optionProps: IGetOptionPropsData<TRegisteredCompanyData>
                  ) => (
                    <RegisteredCompanySelectOption optionProps={optionProps} />
                  ),
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Account name"
                description={
                  "This is the umbrella name for your account. This account can have multiple offices."
                }
              />
              <FormControlV2<TFieldValues>
                name={"name"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter the name of your account",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Website"
                description={
                  "Your business website address that will be used by LeadPro tools."
                }
              />
              <FormControlV2<TFieldValues>
                name={"website"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "https://www.your-website.com",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Portals"
                description={
                  "Some of our tools integrate with various portals, please select all that apply to your business."
                }
              />
              <FormControlV2<TFieldValues>
                name={"portalIds"}
                control={control}
                type={FormControlsTypeEnum.MULTI_SELECT}
                additionalProps={{
                  options: portalOptions,
                  placeholder: "Account portals",
                  clearable: true,
                  optionComponent: (
                    optionProps: IGetOptionPropsData<number, TPortalData>
                  ) => (
                    <PortalSelectOption
                      optionProps={optionProps}
                      fallbackLogo={accountData.logoUrl || undefined}
                    />
                  ),
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Working hours"}
                description={
                  "If you have Autocaller enabled, calls won't be triggered outside of working hours."
                }
              />
              <OfficeWorkingHoursForm<TFieldValues>
                account={accountData}
                control={control}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Primary color"}
                description={
                  "Primary color used as an accent color throughout the valuation tool interface."
                }
              />
              <FormControlV2<TFieldValues>
                name={"primaryColor"}
                control={control}
                type={FormControlsTypeEnum.COLOR_INPUT}
                additionalProps={{
                  placeholder: "#000000",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Account logo"}
                description={
                  "The logo specified here will be used in any branded emails sent, and as the default logo for any Instant Online Valuation tool pages you create."
                }
              />
              <AccountLogoInput accountStore={accountStore} />
            </SimpleGrid>
          </VStack>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </form>
      </Box>
    );
  }
);
