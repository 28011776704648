import React from "react";
import { Droppable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
} from "@chakra-ui/react";
import { DraggableElement } from "components";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { FormToolPageSettingsTemplateAction } from "./FormToolPageSettingsTemplateAction";
import { TFormToolPageActionTemplate } from "types/form-tool-page.type";

interface IProps<T extends string> {
  templateOptions: TFormToolPageActionTemplate<T>[];
}

export function FormToolPageSettingsTemplateActionList<T extends string>({
  templateOptions,
}: IProps<T>) {
  return (
    <Droppable
      droppableId={FormToolPageDroppableContainerEnum.TEMPLATE_ACTIONS}
      isDropDisabled={true}
    >
      {provided => (
        <Accordion defaultIndex={[0]} allowMultiple width={"100%"}>
          <AccordionItem key={"actions"} border={"none"}>
            <AccordionButton px={0}>
              <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                Actions
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <VStack
                spacing={4}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {templateOptions.map((actionTemplate, index) => (
                  <DraggableElement
                    id={`${FormToolPageDroppableContainerEnum.TEMPLATE_ACTIONS}:${actionTemplate.type}`}
                    key={actionTemplate.type}
                    index={index}
                  >
                    <FormToolPageSettingsTemplateAction
                      actionTemplate={actionTemplate}
                    />
                  </DraggableElement>
                ))}
                {provided.placeholder}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Droppable>
  );
}
