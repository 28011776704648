import { VStack } from "@chakra-ui/react";
import { FormControlV2, FormControlDescription } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React from "react";
import { Path } from "react-hook-form";
import { Control } from "react-hook-form";

interface IProps<T> {
  emailFieldName: Path<T>;
  control: Control<T>;
  label: string;
  placeholder?: string;
  multiple?: boolean;
  isDisabled?: boolean;
  description?: string;
}

export function OfficeNotificationsInput<T>({
  emailFieldName,
  control,
  label,
  placeholder,
  multiple,
  isDisabled,
  description,
}: IProps<T>) {
  return (
    <VStack align={"stretch"}>
      <FormControlV2<T>
        name={emailFieldName}
        control={control}
        label={label}
        type={
          multiple ? FormControlsTypeEnum.TAG_INPUT : FormControlsTypeEnum.TEXT
        }
        isDisabled={isDisabled}
        additionalProps={{
          placeholder,
        }}
      />
      <FormControlDescription>{description}</FormControlDescription>
    </VStack>
  );
}
