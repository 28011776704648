import { Box, HStack, useTheme } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/pro-regular-svg-icons";

interface IProps {
  name: ReactNode;
  oldValue: ReactNode;
  newValue: ReactNode;
}

export const LeadActivityEventContent: FC<IProps> = ({
  name,
  oldValue,
  newValue,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Box color={"gray.500"}>{name}</Box>
      {!!oldValue && !!newValue && (
        <HStack spacing={2}>
          <Box textDecoration={"line-through"} color={"gray.400"}>
            {oldValue}
          </Box>
          <FontAwesomeIcon
            icon={faRightLong}
            fontSize={16}
            color={theme.colors.gray["500"]}
          />
          <Box>{newValue}</Box>
        </HStack>
      )}
    </Box>
  );
};
