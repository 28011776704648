import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Box, Portal, SimpleGrid, VStack } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, FormControlMeta, Loader } from "components";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeInstantValuationToolEmailNotificationsForm } from "./OfficeInstantValuationToolEmailNotificationsForm/OfficeInstantValuationToolEmailNotificationsForm";
import { IncompleteValuationLeadsEmailNotificationsForm } from "./IncompleteValuationLeadsEmailNotificationsForm/IncompleteValuationLeadsEmailNotificationsForm";
import { isEmpty } from "lodash";
import { FormFooter } from "components/form";

const IVT_EMAIL_NOTIFICATIONS_FORM_NAME = "IVT_EMAIL_NOTIFICATIONS_FORM_NAME";
const INCOMPLETE_VALUATION_FORM_NAME = "INCOMPLETE_VALUATION_FORM_NAME";

interface IProps {
  officeStore: AccountOfficeStore;
  accountStore: UserAccountStore;
  isEnabled: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}
export const OfficeInstantValuationToolSettings: React.FC<IProps> = observer(
  ({ officeStore, accountStore, isEnabled, containerRef }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formsActions, setFormsActions] = useState<
      Record<string, { submitForm: () => Promise<void>; resetForm: () => void }>
    >({});
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const handleAddFormActions = useCallback(
      (
        formName: string,
        formActions: { submitForm: () => Promise<void>; resetForm: () => void }
      ) => {
        const newFormsActions = { ...formsActions };
        newFormsActions[formName] = formActions;
        setFormsActions(newFormsActions);
      },
      [formsActions]
    );

    const handleSubmit = useCallback(async () => {
      const promises: Promise<void>[] = [];

      setIsSubmitting(true);
      Object.values(formsActions).forEach(formsActions => {
        promises.push(formsActions.submitForm());
      });
      await Promise.all(promises);
      setFormsActions({});
      setIsSubmitting(false);
    }, [formsActions]);

    const handleReset = useCallback(() => {
      Object.values(formsActions).forEach(formActions => {
        formActions.resetForm();
      });
      setFormsActions({});
    }, [formsActions]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            await officeStore.fetchOfficeEmailNotificationsConfiguration(),
            await officeStore.fetchIncompleteValuationLeadsEmailNotificationsConfiguration(),
            await accountStore.accountSettingsStore.fetchAccountEmailNotificationsConfiguration(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [officeStore, accountStore, officeStore.office.portalIds]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            textAlign={"center"}
          >
            <Loader />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <VStack spacing={20}>
            <SimpleGrid columns={2} spacing={4} pt={1}>
              <FormControlMeta
                label="Lead types & sources"
                description={
                  "Configure the lead types and sources you want Instant Valuation Tool email notifications to work for. For each type, you also need to specify the email."
                }
              />
              <OfficeInstantValuationToolEmailNotificationsForm
                isEnabled={isEnabled}
                isChangePending={formsActions.hasOwnProperty(
                  IVT_EMAIL_NOTIFICATIONS_FORM_NAME
                )}
                formName={IVT_EMAIL_NOTIFICATIONS_FORM_NAME}
                setFormActions={handleAddFormActions}
                officeStore={officeStore}
                accountStore={accountStore}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Incomplete Valuation leads email notifications"
                description={
                  "Configure which email addresses would you like to receive the Incomplete Valuation leads to. If these are turned on, you will receive email notifications about the prospects who have filled in their address into your Instant Valuation Tool but have failed to submit their full contact details."
                }
              />
              <IncompleteValuationLeadsEmailNotificationsForm
                isEnabled={isEnabled}
                isChangePending={formsActions.hasOwnProperty(
                  INCOMPLETE_VALUATION_FORM_NAME
                )}
                formName={INCOMPLETE_VALUATION_FORM_NAME}
                setFormActions={handleAddFormActions}
                officeStore={officeStore}
              />
            </SimpleGrid>
          </VStack>
        )}
        {!isEmpty(formsActions) && (
          <Portal containerRef={containerRef}>
            <FormFooter
              isSubmitting={isSubmitting}
              submitForm={handleSubmit}
              resetForm={handleReset}
            />
          </Portal>
        )}
      </Box>
    );
  }
);
