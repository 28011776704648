import React, { useEffect, useMemo, useState } from "react";
import { Box, Center, Flex, Progress } from "@chakra-ui/react";
import { getNextAccountSetupStep } from "./stepsConfig";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Loader, Error } from "components";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { ACCOUNT_SETUP_STEPS_ORDER } from "constants/account-setup-steps-order";

export const AccountSetup = observer(() => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const {
    userAccountsStore: { selectedAccountStore },
    uiStore: { userAccountLoadingState },
  } = useAppStore();

  const isLoadingPending = useMemo(() => {
    return (
      userAccountLoadingState === ApiRequestStatusEnum.PENDING ||
      loadingStatus === ApiRequestStatusEnum.PENDING
    );
  }, [userAccountLoadingState, loadingStatus]);

  const isLoadingError = useMemo(() => {
    return (
      userAccountLoadingState === ApiRequestStatusEnum.ERROR ||
      loadingStatus === ApiRequestStatusEnum.ERROR
    );
  }, [userAccountLoadingState, loadingStatus]);

  const isLoadingSuccess = useMemo(() => {
    return (
      userAccountLoadingState === ApiRequestStatusEnum.SUCCESS &&
      loadingStatus === ApiRequestStatusEnum.SUCCESS
    );
  }, [userAccountLoadingState, loadingStatus]);

  // preload here everything you need from account once it exists
  useEffect(() => {
    const fetchData = async () => {
      if (!!selectedAccountStore) {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        try {
          await Promise.all([
            selectedAccountStore.accountOfficesStore.fetchAccountOffices(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      } else {
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      }
    };

    fetchData();
  }, [selectedAccountStore]);

  const StepComponent = useMemo(() => {
    return getNextAccountSetupStep(
      selectedAccountStore?.account.setupStepsCompleted || []
    );
  }, [selectedAccountStore?.account.setupStepsCompleted]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
      bg="gray.50"
    >
      <Box
        width={"100%"}
        minHeight={"100%"}
        overflowY={"auto"}
        position={"relative"}
      >
        <Flex
          maxW={"sm"}
          minHeight={"100%"}
          margin={"auto"}
          py={10}
          flexDirection={"column"}
        >
          <Progress
            width={"100%"}
            rounded={"md"}
            value={
              (((selectedAccountStore?.account.setupStepsCompleted?.length ||
                0) +
                1) /
                (ACCOUNT_SETUP_STEPS_ORDER.length + 1)) *
              100
            }
            size={"xs"}
          />
          <Center flexGrow={1}>
            {isLoadingPending && <Loader />}
            {isLoadingError && <Error />}
            {isLoadingSuccess && !!StepComponent && (
              <Box width={"100%"}>
                <StepComponent />
              </Box>
            )}
          </Center>
        </Flex>
      </Box>
    </Box>
  );
});
