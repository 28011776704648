import { Error, FormControlDescription, Loader, Message } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AutocallerConfigurator } from "./AutocallerConfigurator/AutocallerConfigurator";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  isEnabled: boolean;
  accountStore: UserAccountStore;
}

export const AutocallerBasicSettings: React.FC<IProps> = observer(
  ({ isEnabled, accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const accountAutocallerStore = accountStore.accountAutocallerStore;

    const autocallerConfiguration =
      accountAutocallerStore.accountAutocallerConfiguration;

    const handleToggleAutocallerConfig = useCallback(
      async (leadSourceTypeId: number, isOn: boolean) => {
        if (isEnabled) {
          await accountAutocallerStore.updateAccountAutocallerConfiguration({
            leadSourceTypes: {
              [leadSourceTypeId]: isOn,
            },
          });
        }
      },
      [isEnabled, accountAutocallerStore]
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountAutocallerStore.fetchAccountAutocallerConfiguration();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountAutocallerStore]);

    return (
      <Box>
        <Box mb={5}>
          <Message status={AlertStatusEnum.INFO}>
            Autocaller is an email to telephone notification system that
            converts emails to phone calls. When Autocaller is turned on an
            email lead causes the phone to ring in the office and plays a
            message explaining the lead type, and person and prompts the agent
            to press 1 to call through and speak to the lead.
          </Message>
        </Box>
        <Box mb={5}>
          <Text fontWeight={600}>Lead types & sources</Text>
          <FormControlDescription>
            Select the lead types and sources you want Autocaller to be enabled
            for.
          </FormControlDescription>
        </Box>
        <Box position={"relative"} minHeight={"200px"}>
          {loadingStatus === ApiRequestStatusEnum.PENDING && (
            <Box
              position={"absolute"}
              top={0}
              bottom={0}
              left={0}
              right={0}
              textAlign={"center"}
            >
              <Loader />
            </Box>
          )}
          {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
          {loadingStatus === ApiRequestStatusEnum.SUCCESS &&
            !!autocallerConfiguration && (
              <AutocallerConfigurator
                accountStore={accountStore}
                autocallerConfiguration={autocallerConfiguration}
                onConfigToggle={handleToggleAutocallerConfig}
                isDisabled={!isEnabled}
              />
            )}
        </Box>
      </Box>
    );
  }
);
