import { MEMBERSHIP_REQUIRED_FIELD } from "constants/validator-messages";
import { TSelectAndInputValue } from "types/select-input.type";
import * as Yup from "yup";
import { TOfficeMembership } from "types/account-office.type";

export type TOfficeMembershipFieldValues = {
  [key: string]: TSelectAndInputValue<number>;
};

export const MEMBERSHIP_FIELDS_BASE = "membership";

const officeMembershipValidationSchema = Yup.object().shape({
  selectValue: Yup.number().required(MEMBERSHIP_REQUIRED_FIELD),
  inputValue: Yup.number(),
});

export const getAllMembershipsValidationSchema = (membershipIds: number[]) => {
  const validationSchema = Yup.object().shape({});

  membershipIds.forEach(membershipId => {
    validationSchema.concat(
      Yup.object().shape({
        [getMembershipFieldName(
          membershipId
        )]: officeMembershipValidationSchema,
      })
    );
  });

  validationSchema.concat(
    Yup.object().shape({
      [getMembershipFieldName()]: officeMembershipValidationSchema,
    })
  );

  return validationSchema;
};

export const getMembershipFieldName = (membershipId?: number) => {
  if (!!membershipId) {
    return MEMBERSHIP_FIELDS_BASE + membershipId;
  }

  return MEMBERSHIP_FIELDS_BASE;
};

export const getInitialMembershipFormData = (
  officeMemberships: TOfficeMembership[]
) => {
  const initialValue: { [key: string]: TSelectAndInputValue<number> } = {};
  officeMemberships.forEach(officeMembership => {
    initialValue[getMembershipFieldName(officeMembership.id)] = {
      selectValue: officeMembership.membership.id || null,
      inputValue: officeMembership.externalId?.toString() || "",
    };
  });

  initialValue[getMembershipFieldName()] = {
    selectValue: null,
    inputValue: "",
  };

  return initialValue;
};
