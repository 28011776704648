import React from "react";
import { Tag, TagLabel, TagProps } from "@chakra-ui/react";

interface IProps extends TagProps {
  isEnabled: boolean;
}

export const EnabledOrDisabledTag: React.FC<IProps> = ({
  isEnabled,
  ...tagProps
}) => {
  return (
    <Tag
      size="sm"
      variant="subtle"
      colorScheme={isEnabled ? "green" : "red"}
      {...tagProps}
    >
      <TagLabel>{isEnabled ? "ENABLED" : "DISABLED"}</TagLabel>
    </Tag>
  );
};
