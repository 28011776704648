import { Box, HStack, MenuItem, Switch } from "@chakra-ui/react";
import React, { ChangeEvent, FC } from "react";
import { TSelectOption } from "types/select-input.type";

interface IProps {
  option: TSelectOption<number>;
  onClick: (event: ChangeEvent<unknown>) => void;
  selectedValues: number[];
  icon: React.ReactNode;
}

export const AnalyticsOfficesFilterItem: FC<IProps> = ({
  option,
  onClick,
  selectedValues,
  icon,
}) => {
  return (
    <MenuItem key={option.value} onClick={onClick}>
      <HStack justify={"space-between"} width={"100%"}>
        <HStack spacing={2} align={"center"}>
          <Switch
            size={"sm"}
            onClick={onClick}
            isChecked={selectedValues.includes(option.value)}
          />
          <Box textTransform={"capitalize"}>
            <HStack spacing={2}>
              {icon}
              <Box>{option.label}</Box>
            </HStack>
          </Box>
        </HStack>
      </HStack>
    </MenuItem>
  );
};
