import { LeadByOption } from "enums/lead-by-option.enum";
import {
  TColorsMap,
  TLeadsStats,
  TLeadStatsGroupedByDate,
  TVisibilityMap,
} from "types/account-analytics.type";
import { toSnakeCase, toSpace } from "./to-snake-case.utils";
import { getLeadTypeLabel } from "./lead-type.utils";
import { getLeadSourceLabel } from "./lead-source.utils";
import { LeadTypeEnum } from "../enums/lead-type.enum";
import { sourceColors, STAT_COLORS, typesColors } from "../constants/colors";
import { LeadSourceEnum } from "../enums/lead-source.enum";

export function getLegendForLeadsByOption<T extends string>(
  statType: LeadByOption,
  options: T[],
  colorDefinitions: TColorsMap<T>,
  visibleGraphSeriesForOption: TVisibilityMap<T>,
  labelFunction: (option: string) => string
) {
  return options.map(option => ({
    key: option,
    label: labelFunction(toSnakeCase(option)),
    toggled: visibleGraphSeriesForOption[option],
    color: colorDefinitions[option],
  }));
}

export function getSeriesForLeadsByOptionByDay<T extends string>(
  options: T[],
  leadsByOptionByDay: TLeadStatsGroupedByDate<TLeadsStats<T>>[]
) {
  return options.map(option =>
    leadsByOptionByDay.map(({ leads }) =>
      leads[option] ? leads[option].total : 0
    )
  );
}

export const getStatLabelFunction = (statType: LeadByOption) => {
  if (statType === LeadByOption.TYPE) {
    return getLeadTypeLabel;
  }

  if (statType === LeadByOption.SOURCE) {
    return getLeadSourceLabel;
  }

  return toSpace;
};

export const mapLeadTypeColorsToKeys = (keys: LeadTypeEnum[]) =>
  keys.reduce<TColorsMap<LeadTypeEnum>>((acc, k, i) => {
    acc[k] = typesColors[k]?.statsColor || STAT_COLORS[i];

    return acc;
  }, {} as TColorsMap<LeadTypeEnum>);

export const mapLeadSourceColorsToKeys = (keys: LeadSourceEnum[]) =>
  keys.reduce<TColorsMap<LeadSourceEnum>>((acc, k, i) => {
    acc[k] = sourceColors[k]?.statsColor || STAT_COLORS[i];

    return acc;
  }, {} as TColorsMap<LeadSourceEnum>);
