import React, { FC } from "react";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { DevelopersConfigWebhooks } from "./components/DevelopersConfigWebhooks/DevelopersConfigWebhooks";
import { DevelopersConfigApiKey } from "./components/DevelopersConfigApiKeys/DevelopersConfigApiKey/DevelopersConfigApiKey";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  SETTINGS_DEVELOPERS_ROUTE,
  SETTINGS_DEVELOPERS_WEBHOOK_DETAILS_ROUTE,
  SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE,
  SETTINGS_DEVELOPERS_API_KEYS_ROUTE,
  SETTINGS_DEVELOPERS_API_KEYS_DETAILS_ROUTE,
  SETTINGS_DEVELOPERS_INTERNAL_WEBHOOK_DETAILS_ROUTE,
  SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE,
} from "constants/routes";
import { Redirect, Route, Switch } from "react-router-dom";
import { DevelopersConfigWebhook } from "./components/DevelopersConfigWebhooks/DevelopersConfigWebhook/DevelopersConfigWebhook";
import { DevelopersConfigApiKeys } from "./components/DevelopersConfigApiKeys/DevelopersConfigApiKeys";

interface IProps {
  accountStore: UserAccountStore;
}

export const DevelopersConfig: FC<IProps> = observer(({ accountStore }) => {
  const accountDevelopersStore = accountStore.accountDevelopersStore;
  const accountDevelopersApiKeysStore =
    accountDevelopersStore.accountDevelopersApiKeysStore;
  const accountDevelopersWebhooksStore =
    accountDevelopersStore.accountDevelopersWebhooksStore;

  return (
    <Switch>
      <Route path={SETTINGS_DEVELOPERS_ROUTE} exact>
        <TabsGroupLayout
          title={"Developers"}
          config={[
            {
              tabHash: "#api-keys",
              tabName: "API keys",
              tabComponent: (
                <DevelopersConfigApiKeys
                  accountDevelopersApiKeysStore={accountDevelopersApiKeysStore}
                />
              ),
            },
            {
              tabHash: "#webhooks",
              tabName: "Webhooks",
              tabComponent: (
                <DevelopersConfigWebhooks
                  accountDevelopersWebhooksStore={
                    accountDevelopersWebhooksStore
                  }
                />
              ),
            },
            {
              tabHash: "#internal-webhooks",
              tabName: "Internal Webhooks",
              tabComponent: (
                <DevelopersConfigWebhooks
                  accountDevelopersWebhooksStore={
                    accountDevelopersWebhooksStore
                  }
                  internal
                />
              ),
            },
          ]}
        />
      </Route>
      <Route path={SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE} exact={true}>
        <Redirect to={`${SETTINGS_DEVELOPERS_ROUTE}#webhooks`} />
      </Route>
      <Route path={SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE} exact={true}>
        <Redirect to={`${SETTINGS_DEVELOPERS_ROUTE}#internal-webhooks`} />
      </Route>
      <Route path={SETTINGS_DEVELOPERS_WEBHOOK_DETAILS_ROUTE} exact={true}>
        <DevelopersConfigWebhook
          accountDevelopersWebhooksStore={accountDevelopersWebhooksStore}
        />
      </Route>
      <Route
        path={SETTINGS_DEVELOPERS_INTERNAL_WEBHOOK_DETAILS_ROUTE}
        exact={true}
      >
        <DevelopersConfigWebhook
          accountDevelopersWebhooksStore={accountDevelopersWebhooksStore}
        />
      </Route>
      <Route path={SETTINGS_DEVELOPERS_API_KEYS_ROUTE} exact={true}>
        <Redirect to={`${SETTINGS_DEVELOPERS_ROUTE}#api-keys`} />
      </Route>
      <Route path={SETTINGS_DEVELOPERS_API_KEYS_DETAILS_ROUTE} exact={true}>
        <DevelopersConfigApiKey
          accountDevelopersApiKeysStore={accountDevelopersApiKeysStore}
        />
      </Route>
    </Switch>
  );
});
