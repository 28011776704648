import {
  LEADS_ALL_ROUTE,
  LEADS_BUSINESS_ROUTE,
  LEADS_IN_PROGRESS_ROUTE,
  LEADS_NO_BUSINESS_ROUTE,
  LEADS_UNREAD_ROUTE,
  LEAD_SUBROUTE_ALL,
  LEAD_SUBROUTE_BUSINESS,
  LEAD_SUBROUTE_IN_PROGRESS,
  LEAD_SUBROUTE_NO_BUSINESS,
  LEAD_SUBROUTE_UNREAD,
} from "constants/routes";
import { useHistory, useParams } from "react-router-dom";
import { LeadStageTab } from "./LeadStageTab";
import { Box, HStack } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC } from "react";
import { observer } from "mobx-react";
import {
  AllBusinessIcon,
  BusinessIcon,
  InProgressIcon,
  NoBusinessIcon,
  UnreadIcon,
} from "components";
import { stageColors } from "constants/colors";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { ManualAddLeadButton } from "../ManualAddLeadButton";
import { CustomizeTableColumnsMenu } from "../CustomizeTableColumnsMenu/CustomizeTableColumnsMenu";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadStageTabs: FC<IProps> = observer(({ accountStore }) => {
  let { leadSubroute } = useParams<{ leadSubroute: string }>();
  const history = useHistory();

  const {
    unreadTotalLeads,
    inProgressTotalLeads,
    businessTotalLeads,
    noBusinessTotalLeads,
    overallTotalLeads,
  } = accountStore.accountLeadsStore.meta;

  return (
    <HStack
      spacing={0}
      bg="pageHeader"
      width={"100%"}
      justifyContent={"space-evenly"}
    >
      <LeadStageTab
        label="Unread"
        icon={
          <UnreadIcon boxSize={3} color={stageColors[LeadStageEnum.UNREAD]} />
        }
        count={unreadTotalLeads}
        isActive={leadSubroute === LEAD_SUBROUTE_UNREAD}
        onClick={() => history.push(LEADS_UNREAD_ROUTE)}
      />
      <LeadStageTab
        label="In progress"
        icon={
          <InProgressIcon
            boxSize={3}
            color={stageColors[LeadStageEnum.IN_PROGRESS]}
          />
        }
        count={inProgressTotalLeads}
        isActive={leadSubroute === LEAD_SUBROUTE_IN_PROGRESS}
        onClick={() => history.push(LEADS_IN_PROGRESS_ROUTE)}
      />
      <LeadStageTab
        label="Business"
        icon={
          <BusinessIcon
            boxSize={3}
            color={stageColors[LeadStageEnum.BUSINESS]}
          />
        }
        count={businessTotalLeads}
        isActive={leadSubroute === LEAD_SUBROUTE_BUSINESS}
        onClick={() => history.push(LEADS_BUSINESS_ROUTE)}
      />
      <LeadStageTab
        label="No business"
        icon={
          <NoBusinessIcon
            boxSize={3}
            color={stageColors[LeadStageEnum.NO_BUSINESS]}
          />
        }
        count={noBusinessTotalLeads}
        isActive={leadSubroute === LEAD_SUBROUTE_NO_BUSINESS}
        onClick={() => history.push(LEADS_NO_BUSINESS_ROUTE)}
      />
      <LeadStageTab
        label="All"
        icon={<AllBusinessIcon boxSize={3} color={"#F29967"} />}
        count={overallTotalLeads}
        isActive={leadSubroute === LEAD_SUBROUTE_ALL}
        onClick={() => history.push(LEADS_ALL_ROUTE)}
      />
      <Box flexShrink={1} p={2}>
        <CustomizeTableColumnsMenu accountStore={accountStore} />
        <ManualAddLeadButton accountStore={accountStore} />
      </Box>
    </HStack>
  );
});
