import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Error, Loader } from "components";

export const EcosystemFinalizeInvite = observer(() => {
  const {
    authStore: { ecosystemAuthStore },
  } = useAppStore();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);

  const location = useLocation();
  const stateToken = useMemo(() => {
    const state = queryString.parse(location.search)?.state as string;
    return state === "undefined" ? "" : atob(state).replaceAll('"', "");
  }, [location]);

  useEffect(() => {
    const finalizeInvite = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await ecosystemAuthStore.finalizeInvite(stateToken);
        await ecosystemAuthStore.login();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    if (!!stateToken) {
      finalizeInvite();
    }
  }, [ecosystemAuthStore, stateToken]);

  return (
    <>
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
    </>
  );
});
