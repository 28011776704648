import { observer } from "mobx-react";
import { Box, Flex } from "@chakra-ui/react";
import { leadAttributionGroupColorMap } from "constants/account-lead-attribution";
import { TItemStatCalculation } from "types/account-lead-attribution-analyticis.type";
import { FC } from "react";
import { Tooltip } from "components";
import { LeadAttributionReportSegmentDetailsItemBarSegmentTooltipContent } from "./LeadAttributionReportSegmentDetailsItemBarSegmentTooltipContent";
import { LeadAttributionGroupedEnum } from "enums/account-lead-attribution.enum";
import { LeadAttributionReportSegmentDetailsItemBarTemplate } from "./LeadAttributionReportSegmentDetailsItemBarTemplate";

interface IProps {
  statCalculation: TItemStatCalculation;
  size?: number;
}

export const LeadAttributionReportSegmentDetailsItemBarSegmentDetails: FC<IProps> = observer(
  ({ statCalculation, size = 1 }) => {
    const shouldHaveTooltip = [
      LeadAttributionGroupedEnum.VALUATIONS,
      LeadAttributionGroupedEnum.INSTRUCTIONS,
      LeadAttributionGroupedEnum.COMPLETIONS,
    ].includes(statCalculation.title);

    return (
      <LeadAttributionReportSegmentDetailsItemBarTemplate
        flexGrow={size}
        background={leadAttributionGroupColorMap[statCalculation.title]}
        topChildren={
          <Tooltip
            placement={"top"}
            hasArrow={true}
            isDisabled={!shouldHaveTooltip}
            label={
              <LeadAttributionReportSegmentDetailsItemBarSegmentTooltipContent
                statCalculation={statCalculation}
              />
            }
          >
            <Flex
              flexGrow={1}
              flexDirection={"column"}
              alignItems={"center"}
              cursor={shouldHaveTooltip ? "pointer" : "default"}
              role={shouldHaveTooltip ? "group" : "none"}
            >
              <Box fontWeight={"bold"} _groupHover={{ color: "leadpro.800" }}>
                {statCalculation.totalCountByStat}
              </Box>
              <Box
                color={"leadpro.400"}
                fontSize={"xs"}
                _groupHover={{ color: "leadpro.800" }}
              >
                {statCalculation.title}
              </Box>
            </Flex>
          </Tooltip>
        }
        lineChildren={
          <Box
            width={"14px"}
            height={"14px"}
            borderRadius={"full"}
            background={leadAttributionGroupColorMap[statCalculation.title]}
            pos={"absolute"}
            top={"-50%"}
            left={"50%"}
            transform={"translate(-8px, -3px)"}
          />
        }
      />
    );
  }
);
