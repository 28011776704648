import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import {
  leadTypeNotificationEmails,
  notificationEmailsValidationSchema,
} from "utils/validation-schemas/notification-emails.validation";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useToast,
} from "@chakra-ui/react";
import { TEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TLeadSourceTypeData } from "types/lead-source.type";
import { EmailNotificationsSourceLeadTypeToggle } from "./EmailNotificationsSourceLeadTypeToggle";
import { groupBy, pick } from "lodash";

const IVT_ID = 11;

type TFieldValues = {
  salesEmails: string[];
  lettingsEmails: string[];
  vendorEmails: string[];
  landlordEmails: string[];
};

interface IProps {
  isDisabled?: boolean;
  isChangePending: boolean;
  formName: string;
  setFormActions: (
    formName: string,
    formActions: { submitForm: () => Promise<void>; resetForm: () => void }
  ) => void;
  emailNotificationsConfiguration: TEmailNotificationsConfiguration;
  onConfigToggle: (leadSourceTypeId: number, isOn: boolean) => void;
  accountStore: UserAccountStore;
  officeStore?: AccountOfficeStore;
}

export const EmailNotificationsForm: FC<IProps> = observer(
  ({
    isDisabled,
    isChangePending,
    formName,
    setFormActions,
    emailNotificationsConfiguration,
    onConfigToggle,
    accountStore,
    officeStore,
  }) => {
    const toast = useToast();
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const leadSourceTypesDictionary =
      accountLeadSourcesStore.leadSourceTypesMap;
    const leadSourcesDictionary = accountLeadSourcesStore.leadSourcesMap;
    const portalsDictionary = accountStore.portalsMap;
    const officeEmailNotificationsConfiguration =
      officeStore?.officeEmailNotificationsConfiguration;

    const initialValues: TFieldValues = useMemo(
      () => ({
        salesEmails: officeEmailNotificationsConfiguration?.salesEmails || [],
        lettingsEmails:
          officeEmailNotificationsConfiguration?.lettingsEmails || [],
        vendorEmails: officeEmailNotificationsConfiguration?.vendorEmails || [],
        landlordEmails:
          officeEmailNotificationsConfiguration?.landlordEmails || [],
      }),
      [officeEmailNotificationsConfiguration]
    );

    // TODO NENAD: FIX THIS DATA QUERY MESS
    const filteredSourceTypesGroupedByLeadType = useMemo(() => {
      const leadSourceTypeIds = Object.keys(
        emailNotificationsConfiguration.leadSourceTypes
        // TODO NENAD: THIS FILTER IS TEMPORARY BECAUSE WE ONLY WANT TO SHOW FOR IVT
      ).filter(leadTypeId =>
        leadSourcesDictionary[IVT_ID]?.leadSourceTypes.includes(
          Number(leadTypeId)
        )
      );

      const filteredLeadSourceTypesDictionary = pick(
        leadSourceTypesDictionary,
        leadSourceTypeIds
      );

      return groupBy(
        Object.values(filteredLeadSourceTypesDictionary),
        (lst: TLeadSourceTypeData) => lst.type
      ) as { [key: string]: TLeadSourceTypeData[] };
    }, [
      emailNotificationsConfiguration,
      leadSourceTypesDictionary,
      leadSourcesDictionary,
    ]);

    const filteredSourceTypesGroupedByLeadTypeKeys = useMemo(() => {
      return Object.keys(filteredSourceTypesGroupedByLeadType);
    }, [filteredSourceTypesGroupedByLeadType]);

    const {
      handleSubmit,
      control,
      reset,
      formState: { isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(notificationEmailsValidationSchema),
    });

    const onSubmit = useCallback(
      async ({
        salesEmails,
        lettingsEmails,
        landlordEmails,
        vendorEmails,
      }: TFieldValues) => {
        if (!!officeStore) {
          try {
            await officeStore.updateOfficeEmailNotificationsConfiguration({
              salesEmails,
              lettingsEmails,
              landlordEmails,
              vendorEmails,
            });
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description:
                "Your office notification emails settings have been successfully updated.",
            });
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          }
        }
      },
      [officeStore, toast]
    );

    useEffect(() => {
      if (!isChangePending && isDirty) {
        setFormActions(formName, {
          submitForm: handleSubmit(onSubmit),
          resetForm: reset,
        });
      }
    }, [
      isChangePending,
      formName,
      setFormActions,
      isDirty,
      reset,
      handleSubmit,
      onSubmit,
    ]);

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion defaultIndex={[0]} allowMultiple>
            {!!filteredSourceTypesGroupedByLeadTypeKeys.length &&
              filteredSourceTypesGroupedByLeadTypeKeys.map(leadType => (
                <AccordionItem key={leadType}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                      {`${getLeadTypeLabel(leadType)} leads`}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {!!officeStore && (
                      <FormControlV2<TFieldValues>
                        name={leadTypeNotificationEmails[leadType]}
                        control={control}
                        label={"Emails"}
                        isDisabled={isDisabled}
                        type={FormControlsTypeEnum.TAG_INPUT}
                        additionalProps={{
                          placeholder: "Enter email and press enter or comma",
                        }}
                      />
                    )}
                    {filteredSourceTypesGroupedByLeadType[leadType].map(
                      (leadSourceType: TLeadSourceTypeData) => {
                        const value =
                          emailNotificationsConfiguration.leadSourceTypes[
                            leadSourceType.id
                          ];
                        const leadSource = leadSourcesDictionary[
                          leadSourceType.leadSourceId
                        ]!;
                        const portal = leadSource.portalId
                          ? portalsDictionary[leadSource?.portalId]
                          : undefined;
                        return (
                          <Box key={leadSourceType.id} mt={4}>
                            <EmailNotificationsSourceLeadTypeToggle
                              value={value}
                              leadSourceType={leadSourceType}
                              leadSource={leadSource}
                              portal={portal}
                              onConfigToggle={onConfigToggle}
                              isDisabled={isDisabled}
                            />
                          </Box>
                        );
                      }
                    )}
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </form>
      </Box>
    );
  }
);
