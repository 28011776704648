import { observer } from "mobx-react";
import {
  Box,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  TAccountIntegrationData,
  TAltoConfig,
  TMRIRadarConfig,
} from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { useCallback } from "react";
import { IntegrationNames } from "constants/integration-names";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  accountIntegration: TAccountIntegrationData<TMRIRadarConfig | TAltoConfig>;
}

export const SyncConfigurator: React.FC<IProps> = observer(
  ({ accountIntegrationsStore, accountIntegration }) => {
    const accountIntegrationName = IntegrationNames[accountIntegration.type];

    const integrationConfig = accountIntegration.integrationConfig;
    delete integrationConfig.id;
    delete integrationConfig.createdAt;
    delete integrationConfig.updatedAt;
    delete integrationConfig.deletedAt;

    const toast = useToast();

    const handleManuallyTriggeredChange = useCallback(
      async (value: string) => {
        const isManuallyTriggered = value === "true";
        try {
          await accountIntegrationsStore.updateAccountIntegration(
            accountIntegration.id,
            {
              type: accountIntegration.type,
              active: true,
              isManuallyTriggered: isManuallyTriggered,
              integrationConfig,
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={`${accountIntegrationName} integration configuration successfully updated.`}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration,
        accountIntegrationsStore,
        toast,
        accountIntegrationName,
        integrationConfig,
      ]
    );
    return (
      <RadioGroup
        onChange={value => handleManuallyTriggeredChange(value)}
        value={accountIntegration.isManuallyTriggered ? "true" : "false"}
      >
        <Grid templateColumns="75% 25%">
          <VStack alignItems={"left"}>
            <Box fontSize={"lg"}>Automatic</Box>
            <Box fontSize={"sm"} color={"leadpro.400"}>
              All leads that are created in your Leadpro dashboard will be
              attempted to be automatically sync'd into {accountIntegrationName}
              .
            </Box>
          </VStack>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            height={"100px"}
          >
            <Radio size={"lg"} value="false" />
          </Flex>
          <VStack alignItems={"left"}>
            <Box fontSize={"lg"}>Manual</Box>
            <Box fontSize={"sm"} color={"leadpro.400"}>
              No leads will be automatically sync'd into{" "}
              {accountIntegrationName} upon creation. Instead, your agents will
              be required to press the 'Sync to {accountIntegrationName}' button
              available at the top right of a lead's details panel.
            </Box>
          </VStack>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            height={"100px"}
          >
            <Radio size={"lg"} value="true" />
          </Flex>
        </Grid>
      </RadioGroup>
    );
  }
);
