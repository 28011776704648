import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IReapitAreasOptionProps {
  optionProp: IGetOptionPropsData<string>;
}

const ReapitAreasOption: FC<IReapitAreasOptionProps> = ({ optionProp }) => {
  const formattedOption = optionProp.option.label.replace(
    `(${optionProp.option.value})`,
    ""
  );

  return (
    <Box
      px={2}
      py={1}
      onClick={optionProp.onClick}
      cursor={"pointer"}
      _hover={{ bg: "blue.50" }}
    >
      <Box>
        {formattedOption}
        <Box display={"inline"} color={"gray.400"}>
          ({optionProp.option.value})
        </Box>
      </Box>
    </Box>
  );
};

export default ReapitAreasOption;
