import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { formatMoney } from "utils/formating.utils";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadInfoRow";

export interface LeadValuationBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.ValuationBlock;
  source: string;
  minSalesPrice?: number;
  estSalesPrice?: number;
  maxSalesPrice?: number;
  estRentPrice?: number;
  minRentPrice?: number;
  maxRentPrice?: number;
  exactPrice?: number;
}

export const LeadValuationBlock = leadBlockHoc<LeadValuationBlockDefinition>(
  props => {
    const { leadBlockDefinition } = props;

    return (
      <>
        <LeadBlockHeader>Valuation report</LeadBlockHeader>
        <LeadBlockBody>
          {!!leadBlockDefinition.exactPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {/* price is in pennies */}
                {formatMoney(leadBlockDefinition.exactPrice / 100)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.minSalesPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Min. sales price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.minSalesPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.estSalesPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Est. sales price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.estSalesPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.maxSalesPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Max. sales price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.maxSalesPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.minRentPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Min. rent price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.minRentPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.estRentPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Est. rent price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.estRentPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
          {!!leadBlockDefinition.maxRentPrice && (
            <LeadInfoRow>
              <LeadInfoRowLabel>Max. rent price</LeadInfoRowLabel>
              <LeadInfoRowValue>
                {formatMoney(leadBlockDefinition.maxRentPrice)}
              </LeadInfoRowValue>
            </LeadInfoRow>
          )}
        </LeadBlockBody>
      </>
    );
  }
);
