import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import { AccountZooplaApi } from "api/account-zoopla.api";
import {
  TAccountIntegrationData,
  TZooplaConfig,
} from "types/account-integration.type";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TIntegrationOfficeRelationData,
  TIntegrationSyncOfficeExtendedData,
} from "types/integration-sync-office.type";
import { AccountIntegrationOfficesStore } from "./AccountIntegrationOfficesStore.store";

const accountZooplaApi = new AccountZooplaApi();

export class AccountIntegrationZooplaStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  accountIntegrationOfficesStore: AccountIntegrationOfficesStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountIntegrationOfficesStore = new AccountIntegrationOfficesStore(
      root,
      accountId,
      accountZooplaApi
    );
  }

  get zooplaApiKey(): string | null {
    const accountStore = this.root.userAccountsStore.selectedAccountStore;
    const accountIntegrationsStore = accountStore?.accountIntegrationsStore;

    if (!accountIntegrationsStore) return null;

    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.ZOOPLA
    ] as TAccountIntegrationData<TZooplaConfig>;

    return accountIntegration?.integrationConfig.apiKey || null;
  }
  get zooplaSyncOfficeData(): TIntegrationSyncOfficeExtendedData[] {
    return this.accountIntegrationOfficesStore.accountSyncOfficeData;
  }

  public async regenerateZooplaApiKey() {
    const accountStore = this.root.userAccountsStore.selectedAccountStore;
    const accountIntegrationsStore = accountStore?.accountIntegrationsStore;

    const accountIntegrationData = await accountZooplaApi.regenerateZooplaApiKey(
      this.accountId
    );
    accountIntegrationsStore?.upsertAccountIntegration(accountIntegrationData);
  }

  public async fetchAccountOfficeRelations() {
    return await this.accountIntegrationOfficesStore.fetchAccountOfficeRelations();
  }

  public async updateSyncedAccountOffices(
    syncedPairs: TIntegrationOfficeRelationData[]
  ) {
    await this.accountIntegrationOfficesStore.updateSyncedAccountOffices(
      syncedPairs
    );
  }
}
