import React, { FC, useCallback, useMemo, useState } from "react";
import { Box, HStack, MenuDivider, MenuGroup } from "@chakra-ui/react";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { isEqual } from "lodash";
import { CenteredMessage } from "components/message/CenteredMessage";
import {
  TLeadFilterMenuItemData,
  TLeadFilterMenuItemValue,
} from "types/leads-filter.type";
import { LeadFilterSimpleMenuItem } from "./LeadFilterSimpleMenuItem";
import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import { ResetLeadFilters } from "./ResetLeadFilters";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
  icon: React.ReactNode;
  filterTypes: LeadSimpleFilterTypes[];
  availableOptions: Partial<
    Record<LeadSimpleFilterTypes, TLeadFilterMenuItemData[]>
  >;
  availableOptionLabels: Partial<Record<LeadSimpleFilterTypes, string>>;
  selectedOptions: Partial<
    Record<LeadSimpleFilterTypes, TLeadFilterMenuItemValue[]>
  >;
  appliedOptions: Partial<
    Record<LeadSimpleFilterTypes, TLeadFilterMenuItemValue[]>
  >;
  showResetFilters?: boolean;
  title?: string;
  leftMenu?: React.ReactNode;
}

export const LeadFilterSimpleCombinedMenu: FC<IProps> = observer(
  ({
    leadsFilterStore,
    icon,
    filterTypes,
    availableOptions,
    availableOptionLabels,
    selectedOptions,
    appliedOptions,
    showResetFilters = true,
    title,
    leftMenu,
  }) => {
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    const { togglePendingFilterOnFilterType } = leadsFilterStore;

    const onMenuOpen = useCallback(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await leadsFilterStore.loadAvailableFilters(filterTypes);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [leadsFilterStore, filterTypes]);

    const handleClose = useCallback(() => {
      if (!isEqual(selectedOptions, appliedOptions)) {
        leadsFilterStore.setActiveFromPendingFilter();
      }
    }, [leadsFilterStore, selectedOptions, appliedOptions]);

    const onMenuItemClick = useCallback(
      (
        value: TLeadFilterMenuItemValue,
        category?: LeadSimpleFilterTypes
      ) => () => {
        if (!!category) {
          togglePendingFilterOnFilterType(value, category);
        }
      },
      [togglePendingFilterOnFilterType]
    );

    const avaliableOptionKeys = useMemo(() => {
      return Object.keys(availableOptions);
    }, [availableOptions]) as LeadSimpleFilterTypes[];

    const totalSelectedOptions = useMemo(() => {
      let sum = 0;
      avaliableOptionKeys.forEach(
        optionsKey => (sum += selectedOptions[optionsKey]?.length || 0)
      );
      return sum;
    }, [avaliableOptionKeys, selectedOptions]);

    return (
      <DropdownFilterMenuShell
        title={title}
        icon={icon}
        badgeCount={totalSelectedOptions}
        onOpen={onMenuOpen}
        onClose={handleClose}
        loadingStatus={loadingStatus}
      >
        <HStack alignItems={"flex-start"}>
          {!!leftMenu && (
            <Box borderRight={"1px solid"} borderColor={"gray.200"}>
              {leftMenu}
            </Box>
          )}
          <Box maxHeight={"500px"} width={"350px"} overflowY={"auto"}>
            {!!avaliableOptionKeys.length &&
              avaliableOptionKeys.map((availableOptionKey, index) => {
                return (
                  <Box key={index}>
                    <MenuGroup>
                      <HStack
                        width={"100%"}
                        justifyContent={"space-between"}
                        gap={2}
                        pl={5}
                        pr={3}
                        py={3}
                      >
                        <Box fontWeight={800} color={"leadpro.700"}>
                          {availableOptionLabels[availableOptionKey]}
                        </Box>
                        {showResetFilters && (
                          <ResetLeadFilters
                            leadsFilterStore={leadsFilterStore}
                          />
                        )}
                      </HStack>
                      {!availableOptions[availableOptionKey]?.length && (
                        <CenteredMessage
                          height={"50px"}
                          message={<DropdownNoFilterMessage />}
                        />
                      )}
                      {availableOptions[availableOptionKey]?.map(
                        availableOption => {
                          return (
                            <LeadFilterSimpleMenuItem
                              key={`${availableOptionKey}-${availableOption.value}`}
                              availableOption={availableOption}
                              onMenuItemClick={onMenuItemClick}
                              selectedOptions={
                                selectedOptions[availableOptionKey] || []
                              }
                              category={availableOptionKey}
                            />
                          );
                        }
                      )}
                    </MenuGroup>
                    {index < avaliableOptionKeys.length - 1 && (
                      <MenuDivider mb={0} />
                    )}
                  </Box>
                );
              })}
          </Box>
        </HStack>
      </DropdownFilterMenuShell>
    );
  }
);
