import React, { FC, useMemo } from "react";
import { Box, Center } from "@chakra-ui/react";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../../SelectInputOptionBase";

export const LeadOfficeSelectOption: FC<ISelectInputOptionProps<number>> = ({
  optionProps: { option, selectedOption, ...rest },
}) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <Center>
        <Box>{option.label}</Box>
      </Center>
    </SelectInputOptionBase>
  );
};
