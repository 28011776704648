import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { TSmsTemplate } from "types/sms-template.type";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import {
  ALPHANUMERIC_CHARS_ONLY,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { isValidSmsTemplateContent } from "utils/template.utils";
import { VALID_SMS_ORIGIN_REGEX } from "utils/regexs.utils";
import { AccountSmsTemplatesStore } from "store/UserAccounts/UserAccount/AccountSmsTemplates.store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = Pick<TSmsTemplate, "name" | "content" | "origin">;

interface IProps {
  smsTemplate?: TSmsTemplate;
  smsTemplatesStore: AccountSmsTemplatesStore;
  closePrompt: () => void;
}

export const SmsTemplateEditorPrompt: FC<IProps> = observer(
  ({ smsTemplate, smsTemplatesStore, closePrompt }) => {
    const toast = useToast();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await smsTemplatesStore.loadSmsTemplateMergeTags();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [smsTemplatesStore]);

    const initialValues: TFieldValues = useMemo(
      () => ({
        name: smsTemplate?.name || "",
        origin: smsTemplate?.origin || "",
        content: smsTemplate?.content || "",
      }),
      [smsTemplate]
    );

    const validationSchema = useMemo(() => {
      return Yup.object().shape({
        name: Yup.string().required(REQUIRED_FIELD),
        origin: Yup.string()
          .min(1)
          .max(11)
          .matches(VALID_SMS_ORIGIN_REGEX, ALPHANUMERIC_CHARS_ONLY)
          .required(REQUIRED_FIELD),
        content: Yup.string()
          .test(isValidSmsTemplateContent(smsTemplatesStore.mergeTagsFlatArray))
          .required(REQUIRED_FIELD),
      });
    }, [smsTemplatesStore.mergeTagsFlatArray]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const handleUpdateSmsTemplate = useCallback(
      async (templateId: number, values: TFieldValues) => {
        await smsTemplatesStore.updateSmsTemplate(templateId, values);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"SMS template updated."} />
          ),
        });
      },
      [smsTemplatesStore, toast]
    );

    const handleCreateSmsTemplate = useCallback(
      async (values: TFieldValues) => {
        await smsTemplatesStore.createSmsTemplate(values);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"SMS template created."} />
          ),
        });
      },
      [smsTemplatesStore, toast]
    );

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          if (!!smsTemplate) {
            await handleUpdateSmsTemplate(smsTemplate.id, values);
          } else {
            await handleCreateSmsTemplate(values);
          }
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        smsTemplate,
        toast,
        closePrompt,
        handleCreateSmsTemplate,
        handleUpdateSmsTemplate,
      ]
    );

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        header={`${!!smsTemplate ? "Edit" : "Create"} SMS template`}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Enter SMS template name",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"origin"}
                  control={control}
                  label={"Origin"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Name to display as sender",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"content"}
                  control={control}
                  label={"Content"}
                  type={FormControlsTypeEnum.MERGE_TAG_INPUT}
                  additionalProps={{
                    singleLine: false,
                    mergeTagOptions:
                      smsTemplatesStore.mergeTagGroupOptionsArray,
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!smsTemplate ? "Update" : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
