import React, { useCallback } from "react";
import { TimePicker } from "./TimePicker";
import { Box } from "@chakra-ui/react";
import { StandardIconButton } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faXmark } from "@fortawesome/pro-solid-svg-icons";

type TimeRange = {
  from: string;
  to: string;
};

interface ITimeRangePickerProps {
  name: string;
  timeRange: TimeRange;
  onChange: (timeRange: TimeRange) => void;
  disabled?: boolean;
}

export const TimeRangePicker: React.FC<ITimeRangePickerProps> = ({
  name,
  timeRange,
  onChange,
  disabled,
}) => {
  const handleFromTimeChange = useCallback(
    (value: string) => {
      onChange({
        from: value,
        to: timeRange.to,
      });
    },
    [timeRange.to, onChange]
  );

  const handleToTimeChange = useCallback(
    (value: string) => {
      onChange({
        from: timeRange.from,
        to: value,
      });
    },
    [timeRange.from, onChange]
  );

  const handleClear = useCallback(() => {
    if (disabled) return;

    onChange({
      from: "",
      to: "",
    });
  }, [disabled, onChange]);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      flexWrap={"nowrap"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <TimePicker
        name={`${name}-from`}
        time={timeRange.from}
        onChange={handleFromTimeChange}
        disabled={disabled}
      />
      <Box mx={2} color={disabled ? "gray.400" : undefined}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Box>
      <TimePicker
        name={`${name}-to`}
        time={timeRange.to}
        onChange={handleToTimeChange}
        disabled={disabled}
      />
      <Box ml={2}>
        <StandardIconButton
          icon={<FontAwesomeIcon icon={faXmark} />}
          aria-label={"clear-time-range"}
          onClick={handleClear}
          isDisabled={disabled}
          type={"button"}
        />
      </Box>
    </Box>
  );
};
