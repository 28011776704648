import { observer } from "mobx-react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  AccountIntegrationTypeEnum,
  ManuallyTriggerableIntegrationTypes,
} from "enums/account-integration-type.enum";
import { SETTINGS_INTEGRATIONS_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";

interface IProps {
  closePrompt: () => void;
  accountIntegrationsStore: AccountIntegrationsStore;
  integrationType: AccountIntegrationTypeEnum;
}

export const ConnectIntegrationConfirmation: FC<IProps> = observer(
  ({ closePrompt, accountIntegrationsStore, integrationType }) => {
    const history = useHistory();
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const staticIntegrationData =
      accountIntegrationsStore.availableAccountIntegrationsMapByType[
        integrationType
      ];
    const handleConfirm = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await accountIntegrationsStore.createAccountIntegration({
          type: integrationType,
          active: true,
          isManuallyTriggered: ManuallyTriggerableIntegrationTypes.includes(
            integrationType
          ),
          integrationConfig: {},
        });
        setIsSubmitting(false);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: `Successfully initiated ${staticIntegrationData?.name} integration. Please consult guide for further steps.`,
        });
        closePrompt();
        history.push(`${SETTINGS_INTEGRATIONS_ROUTE}/${integrationType}`);
      } catch (e) {
        setIsSubmitting(false);
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [
      integrationType,
      closePrompt,
      accountIntegrationsStore,
      staticIntegrationData,
      history,
      toast,
    ]);

    return (
      <Box>
        <Box mb={5}>
          <Box>
            {`Please confirm that you want to activate ${staticIntegrationData?.name} integration.`}
          </Box>
        </Box>
        <Divider mb={4} />
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
        >
          <Button
            type={"submit"}
            colorScheme={"teal"}
            onClick={handleConfirm}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    );
  }
);
