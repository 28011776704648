import React, { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TRightmoveConfig,
} from "types/account-integration.type";
import {
  rightmoveIntegrationConnectValidationSchema,
  TRightmoveIntegrationConfigFieldValues,
} from "utils/validation-schemas/rightmove-integration.validation";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { ToggleIntegrationActiveSwitchInput } from "../ToggleIntegrationActiveSwitchInput";

type TFieldValues = TRightmoveIntegrationConfigFieldValues & {
  active: boolean;
};

const validationSchema = rightmoveIntegrationConnectValidationSchema.concat(
  Yup.object().shape({
    active: Yup.boolean().required(REQUIRED_FIELD),
  })
);

interface IConnectRightmoveFormProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt?: () => void;
}

export const RightmoveIntegrationForm: FC<IConnectRightmoveFormProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.RIGHTMOVE
    ] as TAccountIntegrationData<TRightmoveConfig>;

    const initialValues = useMemo(() => {
      return {
        brandId: accountIntegration.integrationConfig.brandId.toString(),
        networkId: accountIntegration.integrationConfig.networkId.toString(),
        certificate: "",
        passphrase: "",
        testMode: accountIntegration.integrationConfig.testMode,
        active: accountIntegration.active,
      };
    }, [accountIntegration]);

    const {
      handleSubmit,
      watch,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const handleUpdateIntegrationConfig = useCallback(
      async ({ active, ...rest }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<
            TRightmoveIntegrationConfigFieldValues
          >(accountIntegration.id, {
            type: accountIntegration.type,
            active: active,
            integrationConfig: rest,
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Rightmove integration configuration updated. "}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(handleUpdateIntegrationConfig)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connecting with Rightmove"}
            control={control}
            value={active}
            name={"active"}
          />

          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Brand ID"}
              type={FormControlsTypeEnum.TEXT}
              name={"brandId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Network Id"}
              type={FormControlsTypeEnum.TEXT}
              name={"networkId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Certificate"}
              type={FormControlsTypeEnum.CERTIFICATE_UPLOAD_INPUT}
              name={"certificate"}
              control={control}
              additionalProps={{
                accept: ".pem",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Passphrase"}
              type={FormControlsTypeEnum.TEXT}
              name={"passphrase"}
              control={control}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel>Test mode</FormControlLabel>
            <FormControlV2<TFieldValues>
              name={"testMode"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          ></Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
