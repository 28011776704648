import { BaseApi } from "./base.api";
import {
  TAccountLeadAttributionCalculationConfig,
  TLeadAttributionByJourneyStatsDto,
  TLeadAttributionByOfficeStatsDto,
  TLeadAttributionBySourceStatsDto,
  TLeadAttributionByUtmCampaignStatsDto,
} from "../types/account-lead-attribution-analyticis.type";
import { TSerializedDateRange } from "../types/date.type";

export class AccountLeadAttributionAnalyticsApi extends BaseApi {
  // ACCOUNT LEAD ATTRIBUTION CALCULATION CONFIG
  async fetchAccountLeadAttributionCalculationConfig(
    accountId: number
  ): Promise<TAccountLeadAttributionCalculationConfig> {
    return this.get(
      `/accounts/${accountId}/lead-attribution-calculation-config`
    );
  }

  async updateAccountLeadAttributionCalculationConfig(
    accountId: number,
    leadAttributionCalculationConfig: TAccountLeadAttributionCalculationConfig
  ): Promise<TAccountLeadAttributionCalculationConfig> {
    return this.post(
      `/accounts/${accountId}/lead-attribution-calculation-config`,
      leadAttributionCalculationConfig
    );
  }

  //   STATS
  async fetchAccountLeadAttributionStatsByOffice(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadAttributionByOfficeStatsDto[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/attributions-by-office`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async fetchAccountLeadAttributionStatsBySource(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadAttributionBySourceStatsDto[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/attributions-by-source`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async fetchAccountLeadAttributionStatsByUTMCampaign(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadAttributionByUtmCampaignStatsDto[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/attributions-by-utm-campaign`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }

  async fetchAccountLeadAttributionStatsByJourney(
    accountId: number,
    dateRange: TSerializedDateRange
  ): Promise<TLeadAttributionByJourneyStatsDto[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/stats/attributions-by-journey`,
      {
        params: {
          from: dateRange.startDate || undefined,
          to: dateRange.endDate || undefined,
        },
      }
    );

    return data;
  }
}
