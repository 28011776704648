import { observer } from "mobx-react";
import "reactflow/dist/style.css";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { QuestionnaireToolPageWIPFormStore } from "../stores/QuestionnaireToolPageWIPForm.store";
import React, { FC, useCallback } from "react";
import { Box, Button, Center, VStack } from "@chakra-ui/react";
import { QuestionnaireToolPageSettingsCustomEndingPage } from "./QuestionnaireToolPageSettingsEndingPage/QuestionnaireToolPageSettingsCustomEndingPage";

interface IProps {
  isExpanded: boolean;
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsEndingPagesSelectedListSectionContent: FC<IProps> = observer(
  ({ leadType, questionnaireToolPageWIPFormStore }) => {
    const customEndingPagesForType =
      questionnaireToolPageWIPFormStore.wipFormCustomEndingPagesJSWithTempId;

    const handleAddPageConfig = useCallback(() => {
      questionnaireToolPageWIPFormStore.addWipPageCustomEndingPageConfig();
    }, [questionnaireToolPageWIPFormStore]);

    return (
      <Box minHeight={"400px"} width={"100%"} key={leadType}>
        {!customEndingPagesForType.length && (
          <Center width={"100%"} height={"400px"}>
            <Button
              variant={"link"}
              colorScheme={"blue"}
              onClick={handleAddPageConfig}
            >
              + Add alternate ending page
            </Button>
          </Center>
        )}
        {!!customEndingPagesForType.length && (
          <VStack width={"100%"} alignItems={"start"} spacing={10}>
            {customEndingPagesForType.map((page, index) => (
              <QuestionnaireToolPageSettingsCustomEndingPage
                key={page.tempId}
                customEndingPageIndex={index}
                customEndingPageConfig={page}
                questionnaireToolPageWIPFormStore={
                  questionnaireToolPageWIPFormStore
                }
              />
            ))}
            <Button
              variant={"link"}
              colorScheme={"blue"}
              onClick={handleAddPageConfig}
            >
              + Add alternate ending page
            </Button>
          </VStack>
        )}
      </Box>
    );
  }
);
