import { useState } from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserSignupForm } from "./UserSignupForm";

export const UserSignup = observer(() => {
  const [inviteSentToEmail, setInviteSentToEmail] = useState("");

  if (inviteSentToEmail) {
    return (
      <Box textAlign="center">
        <Heading fontSize="2xl" mb={3} lineHeight="none">
          Please check your email
        </Heading>
        <Text color="gray.600">
          We've sent a verification email to{" "}
          <strong>{inviteSentToEmail}</strong>. Please follow the instructions
          (and check your spam folder) to complete registration.
        </Text>
      </Box>
    );
  } else {
    return <UserSignupForm onSubmitSuccess={setInviteSentToEmail} />;
  }
});
