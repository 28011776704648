import { BaseApi } from "./base.api";
import {
  TAccountData,
  TAccountIVTConfigData,
  TRawAccountData,
} from "types/account.type";
import { TEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import { TPortalData } from "types/portal.type";
import { TLeadSourceData } from "types/lead-source.type";
import {
  TLeadResponderLeadEmailAdditionalData,
  TLeadResponderLeadEmailsResponse,
} from "../types/account-lead-emails.type";
import {
  transformLeadResponderEmailDateRangeQueryParam,
  transformSortByQueryParam,
} from "../utils/api.utils";
import { TLeadResponderEmailsFilter } from "../types/lead-responder-email.type";
import { SortingRule } from "react-table";

export class AccountApi extends BaseApi {
  // ACCOUNTS
  async fetchAccountLeadSources(accountId: number): Promise<TLeadSourceData[]> {
    return this.get(`/accounts/${accountId}/lead-sources`);
  }

  async fetchCurrentlyActiveAccountLeadSources(
    accountId: number
  ): Promise<TLeadSourceData[]> {
    return this.get(`/accounts/${accountId}/lead-sources/available`);
  }

  async createAccount(
    accountData: Partial<TAccountData>
  ): Promise<TRawAccountData> {
    return this.post("/accounts", accountData);
  }

  async fetchUserAccount(accountId: number): Promise<TRawAccountData> {
    return this.get(`/accounts/${accountId}/`);
  }

  async updateAccount(
    accountId: number,
    data: Partial<TAccountData>
  ): Promise<TRawAccountData> {
    return this.put(`/accounts/${accountId}/`, data);
  }

  // ACCOUNT EMAIL NOTIFICATIONS
  async fetchAccountEmailNotificationsConfiguration(
    accountId: number
  ): Promise<TEmailNotificationsConfiguration> {
    return this.get(`/accounts/${accountId}/email-notifications`);
  }

  async fetchPortals(accountId: number): Promise<TPortalData[]> {
    return this.get(`/accounts/${accountId}/portals/available`);
  }

  async fetchAccountLeadEmails(
    accountId: number,
    sortBy: SortingRule<string>[],
    filter?: TLeadResponderEmailsFilter,
    offset?: number,
    limit?: number
  ): Promise<TLeadResponderLeadEmailsResponse> {
    const dateRangeTransformed = transformLeadResponderEmailDateRangeQueryParam(
      filter?.dateReceivedRange
    );

    const sortByTransformed = transformSortByQueryParam(sortBy);

    const adaptedFilter = {
      ...filter,
      dateReceivedRange: dateRangeTransformed,
    };
    return await this.get<TLeadResponderLeadEmailsResponse>(
      `/accounts/${accountId}/lead-emails`,
      {
        params: {
          ...adaptedFilter,
          sortBy: sortByTransformed,
          limit: limit,
          offset: offset,
        },
      }
    );
  }

  async fetchOneAccountLeadEmail(
    accountId: number,
    emailId: number
  ): Promise<TLeadResponderLeadEmailAdditionalData> {
    return await this.get(`/accounts/${accountId}/lead-emails/get-one`, {
      params: {
        id: emailId,
      },
    });
  }

  // ACCOUNT IVT CONFIGURATION
  async fetchAccountIVTConfig(
    accountId: number
  ): Promise<TAccountIVTConfigData> {
    return this.get(`/accounts/${accountId}/ivt-config`);
  }

  async updateAccountIVTConfig(
    accountId: number,
    accountIVTConfig: TAccountIVTConfigData
  ): Promise<TAccountIVTConfigData> {
    return this.put(`/accounts/${accountId}/ivt-config`, accountIVTConfig);
  }
}
