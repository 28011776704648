import { PageToolPageSettingsLayout } from "../../../../components/PageTool/PageToolPageSettings/PageToolPageSettingsLayout";
import { InstantValuationToolPageSettingsDetailsForm } from "./InstantValuationToolPageSettingsDetailsForm/InstantValuationToolPageSettingsDetailsForm";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

interface IProps {
  accountStore: UserAccountStore;
}
export const InstantValuationToolPageSettings: FC<IProps> = observer(
  ({ accountStore }) => {
    const { id } = useParams<{ id: string }>();
    const pageId = useMemo(() => parseInt(id!), [id]);
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const accountIVTPagesStore =
      accountStore.accountPagesStore.accountIVTPagesStore;
    const page = accountIVTPagesStore.accountPagesMap[pageId];

    useEffect(() => {
      const fetchData = async () => {
        if (!pageId) {
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
          return;
        }

        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountIVTPagesStore.loadPage(pageId);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountIVTPagesStore, pageId]);

    return (
      <PageToolPageSettingsLayout
        title={`${!!page ? "Updating" : "Creating new"} IVT page`}
        loadingStatus={loadingStatus}
        config={[
          {
            tabHeader: "IVT details",
            tabHash: "#details",
            tabComponent: (
              <InstantValuationToolPageSettingsDetailsForm
                accountStore={accountStore}
                pageId={pageId}
              />
            ),
          },
        ]}
      />
    );
  }
);
