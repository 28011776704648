import React, { useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { LeadBlocks } from "./LeadBlocks/LeadBlocks";
import { LeadActivity } from "./LeadActivity/LeadActivity";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadAttribution } from "./LeadAttributions/LeadAttribution";

interface IProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

export const LeadDetails: React.FC<IProps> = observer(
  ({ accountStore, leadStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const hasEcosystemIntegration =
      accountStore.accountIntegrationsStore.hasEcosystemIntegration;
    const {
      uiStore: { isDemoModeOn },
    } = useAppStore();
    const leadData = leadStore.lead;

    useEffect(() => {
      const fetchData = async () => {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        try {
          await leadStore.fetchAssignableUsers();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountStore, leadStore, isDemoModeOn]);

    const content = useMemo(() => {
      if (loadingStatus === ApiRequestStatusEnum.PENDING) {
        return <Loader />;
      }

      if (loadingStatus === ApiRequestStatusEnum.ERROR) {
        return <Error />;
      }

      if (
        loadingStatus === ApiRequestStatusEnum.SUCCESS &&
        !!leadData.additionalData
      ) {
        return (
          <Tabs
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            isFitted
            borderTop={0}
            height={"100%"}
            width={"100%"}
            isLazy={true}
            colorScheme={"leadpro"}
          >
            <TabList>
              <Tab>Lead Details</Tab>
              {hasEcosystemIntegration && <Tab>Progression</Tab>}
              <Tab>Activity</Tab>
            </TabList>
            <TabPanels
              display={"flex"}
              flexDirection={"column"}
              flexGrow={1}
              overflow={"hidden"}
              tabIndex={undefined}
            >
              <TabPanel overflowY={"auto"} p={0}>
                <LeadBlocks leadStore={leadStore} accountStore={accountStore} />
              </TabPanel>
              {hasEcosystemIntegration && (
                <TabPanel overflowY={"auto"} p={0}>
                  <LeadAttribution
                    leadStore={leadStore}
                    accountStore={accountStore}
                  />
                </TabPanel>
              )}
              <TabPanel minHeight={"100%"} p={0}>
                <LeadActivity
                  leadStore={leadStore}
                  accountStore={accountStore}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        );
      }

      if (!leadData) {
        return <Box>No data available.</Box>;
      }
    }, [
      leadStore,
      accountStore,
      leadData,
      loadingStatus,
      hasEcosystemIntegration,
    ]);

    return (
      <Box
        overflow={"hidden"}
        width={"100%"}
        position={"relative"}
        flexGrow={1}
      >
        {content}
      </Box>
    );
  }
);
