import { ChildLeadType, QuestionnaireActionType } from "@leadpro/forms";
import { TFormToolPageActionTemplate } from "types/form-tool-page.type";
import { TSelectOption } from "../types/select-input.type";
import { ChildLeadOfficeRoutingType } from "@leadpro/forms/dist/types/actions/questionnaire/questionnaire-action-create-child-lead";
import { getLeadTypeLabel } from "../utils/lead-type.utils";
import { capitalize } from "lodash";
import { QuestionnaireLeadTypes } from "../types/questionnaire-tool-page.type";
import { LeadTypeEnum } from "../enums/lead-type.enum";

export const QuestionnaireToolPageActionTypeLabels: {
  [key in QuestionnaireActionType]: string;
} = {
  [QuestionnaireActionType.CREATE_CHILD_LEAD]: "Create child lead",
  [QuestionnaireActionType.UPDATE_POSTCODE]: "Update postcode",
};

export const QuestionnaireToolPageActionTemplateOptions: TFormToolPageActionTemplate<
  QuestionnaireActionType
>[] = [
  {
    type: QuestionnaireActionType.CREATE_CHILD_LEAD,
    label:
      QuestionnaireToolPageActionTypeLabels[
        QuestionnaireActionType.CREATE_CHILD_LEAD
      ],
  },
  {
    type: QuestionnaireActionType.UPDATE_POSTCODE,
    label:
      QuestionnaireToolPageActionTypeLabels[
        QuestionnaireActionType.UPDATE_POSTCODE
      ],
  },
];

export const QuestionnaireToolPageCreateChildLeadActionRoutingTypeOptions: TSelectOption<
  ChildLeadOfficeRoutingType
>[] = [
  {
    label: "Parent Office",
    value: ChildLeadOfficeRoutingType.ParentOffice,
  },
  {
    label: "From Postcode",
    value: ChildLeadOfficeRoutingType.FromPostcode,
  },
  {
    label: "Specific Office",
    value: ChildLeadOfficeRoutingType.SpecificOffice,
  },
];

export const QuestionnaireToolPageCreateChildLeadActionChildLeadTypeOptions: TSelectOption<
  ChildLeadType
>[] = Object.values(ChildLeadType).map(leadType => ({
  label: capitalize(getLeadTypeLabel(leadType)),
  value: leadType,
}));

export const QuestionnaireToolPageAccordionSections: QuestionnaireLeadTypes[] = [
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Let,
  LeadTypeEnum.MortgageRequest,
  LeadTypeEnum.Sale,
  LeadTypeEnum.Vendor,
];
