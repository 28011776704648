import React from "react";
import { Box } from "@chakra-ui/react";

interface IStandardDrawerBodyProps {
  children: React.ReactNode;
}

export const StandardDrawerBody: React.FC<IStandardDrawerBodyProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box display="flex" overflow="hidden" flexGrow={1} flexDirection="column">
      {children}
    </Box>
  );
};
