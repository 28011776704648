import React, { FC, useCallback, useState } from "react";
import { AccountPersistentFiltersStore } from "store/UserAccounts/UserAccount/AccountPersistentFilters.store";
import {
  Box,
  Button,
  Divider,
  ListItem,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { ActionPromptContainer, ApiMessageStack, Message } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { TPersistentFilter } from "types/persistent-filters.type";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { observer } from "mobx-react";

interface IProps {
  closePrompt: () => void;
  persistentFilter: TPersistentFilter;
  persistentFiltersStore: AccountPersistentFiltersStore;
}

export const DeletePersistentFilterPrompt: FC<IProps> = observer(
  ({ closePrompt, persistentFilter, persistentFiltersStore }) => {
    const toast = useToast();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleDeletePersistentFilter = useCallback(async () => {
      try {
        setIsRemoving(true);
        await persistentFiltersStore.removePersistentFilter(
          persistentFilter.id
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Filter has been deleted."} />
          ),
        });
        closePrompt();
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [closePrompt, persistentFilter, persistentFiltersStore, toast]);

    return (
      <ActionPromptContainer
        header={"Confirm deletion"}
        body={
          !!persistentFilter.workflows.length ||
          !!persistentFilter.journeys.length ? (
            <Message status={AlertStatusEnum.ERROR}>
              <Box lineHeight={8}>
                <Box>This filter is referenced in the following places:</Box>
                {!!persistentFilter.workflows.length && (
                  <>
                    <Box>Workflows:</Box>
                    <UnorderedList spacing={4}>
                      {persistentFilter.workflows.map(workflow => (
                        <ListItem key={workflow.id}>{workflow.name}</ListItem>
                      ))}
                    </UnorderedList>
                  </>
                )}
                {!!persistentFilter.journeys.length && (
                  <>
                    <Box>Journeys:</Box>
                    <UnorderedList spacing={4}>
                      {persistentFilter.journeys.map(journey => (
                        <ListItem key={journey.id}>{journey.name}</ListItem>
                      ))}
                    </UnorderedList>
                  </>
                )}
                <Box>
                  You first need to remove the above reference(s) before you can
                  delete it.
                </Box>
              </Box>
            </Message>
          ) : (
            <Box>
              <Box mb={5}>
                <Box>Are you sure you want to remove this filter?</Box>
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  onClick={handleDeletePersistentFilter}
                  colorScheme={"red"}
                  isDisabled={isRemoving}
                  isLoading={isRemoving}
                >
                  Delete filter
                </Button>
              </Box>
            </Box>
          )
        }
      />
    );
  }
);
