import { ITableV2Column } from "components/table-v2/table-v2";
import { TPersistentFilter } from "types/persistent-filters.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

export const persistentFilterTableColumnDef = (
  handleEditPersistentFilter: (row: TPersistentFilter) => () => void,
  handleRemovePersistentFilter: (row: TPersistentFilter) => () => void
): ITableV2Column<TPersistentFilter>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Created</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "updatedAt",
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Last edited</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.updatedAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "workflows",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Used in</DefaultHeaderCell>,
      Cell: ({ row }) => {
        const usedIn = row.workflows.length + row.journeys.length;
        return <DefaultRowCell>{usedIn + " place(s)"}</DefaultRowCell>;
      },
    },
    {
      accessor: "id",
      width: "30px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell
          justifyContent={"flex-end"}
          onClick={event => event.stopPropagation()}
        >
          <Menu isLazy={true} placement={"bottom-end"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />
              }
              size="xs"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={handleEditPersistentFilter(row)}>
                Edit
              </MenuItem>
              <MenuItem onClick={handleRemovePersistentFilter(row)}>
                Remove
              </MenuItem>
            </MenuList>
          </Menu>
        </DefaultRowCell>
      ),
    },
  ];
};
