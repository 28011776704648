import React, { FC } from "react";
import { TLead } from "types/lead.type";
import { NA } from "components";
import { Box } from "@chakra-ui/react";

interface ILeadAddressCellProps {
  lead: TLead;
}

export const LeadAddressCell: FC<ILeadAddressCellProps> = ({ lead }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      {lead.advertAddress || lead.address || <NA />}
    </Box>
  );
};
