import { BaseApi } from "./base.api";
import {
  TAltoDefaultNegotiatorId,
  TAltoIntention,
  TAltoIntentionRelationData,
  TAltoLeadSource,
  TAltoLeadSourceRelation,
  TAltoLeadSourceRelationData,
  TAltoNegotiator,
  TAltoNegotiatorRelationData,
  TAltoOffice,
  TAltoOfficeRelation,
} from "types/alto.type";
import { TIntegrationOffice } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import {
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "../types/integration-sync-office.type";
import {
  altoOfficeDataToIntegrationOfficeDataMapper,
  mapAltoOfficeRelationsToIntegrationOfficeRelations,
  mapIntegrationOfficeRelationsToAltoOfficeRelations,
} from "utils/alto.utils";

export class AccountAltoApi extends BaseApi {
  async fetchAccountOffices(accountId: number): Promise<TIntegrationOffice[]> {
    const data = await this.get(`/accounts/${accountId}/alto/alto-offices`);
    const mappedData = altoOfficeDataToIntegrationOfficeDataMapper(
      data as TAltoOffice[]
    );
    return mappedData as TIntegrationOffice[];
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    const data = await this.get(`/accounts/${accountId}/alto/office-relations`);
    const mappedData = mapAltoOfficeRelationsToIntegrationOfficeRelations(
      data as TAltoOfficeRelation[]
    );
    return mappedData as TIntegrationOfficeRelation[];
  }

  async fetchAccountAltoLeadSources(
    accountId: number
  ): Promise<TAltoLeadSource[]> {
    return this.get(`/accounts/${accountId}/alto/alto-sources`);
  }

  async fetchAccountAltoLeadSourceRelations(
    accountId: number
  ): Promise<TAltoLeadSourceRelation[]> {
    return this.get(`/accounts/${accountId}/alto/source-relations`);
  }

  async fetchAccountAltoIntentions(
    accountId: number
  ): Promise<TAltoIntention[]> {
    return this.get(`accounts/${accountId}/alto/alto-intentions`);
  }

  async fetchAccountAltoIntentionRelations(
    accountId: number
  ): Promise<TAltoIntentionRelationData[]> {
    return this.get(`/accounts/${accountId}/alto/intention-relations`);
  }

  async fetchAccountAltoNegotiators(
    accountId: number
  ): Promise<TAltoNegotiator[]> {
    return this.get(`/accounts/${accountId}/alto/alto-negotiators`);
  }

  async fetchAccountAltoNegotiatorRelations(
    accountId: number
  ): Promise<TAltoNegotiatorRelationData[]> {
    return this.get(`/accounts/${accountId}/alto/negotiator-relations`);
  }

  async fetchAccountDefaultAltoNegotiatorId(
    accountId: number
  ): Promise<TAltoDefaultNegotiatorId> {
    return this.get(`/accounts/${accountId}/alto/default-negotiator`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/alto/office-relations`, {
      items: mapIntegrationOfficeRelationsToAltoOfficeRelations(relationsData),
    });
  }

  async updateAccountAltoSyncedLeadSources(
    accountId: number,
    relationsData: TAltoLeadSourceRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/alto/source-relations`, {
      items: relationsData,
    });
  }

  async updateAccountAltoSyncedIntentions(
    accountId: number,
    relationsData: TAltoIntentionRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/alto/intention-relations`, {
      items: relationsData,
    });
  }

  async updateAccountAltoSyncedNegotiators(
    accountId: number,
    relationsData: TAltoNegotiatorRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/alto/negotiator-relations`, {
      items: relationsData,
    });
  }

  async updateAccountDefaultAltoNegotiatorId(
    accountId: number,
    altoId: string | null
  ) {
    await this.post(`/accounts/${accountId}/alto/default-negotiator`, {
      altoId,
    });
  }
}
