import { RightSideLayout } from "routes/dashboard/components";
import React, { useMemo } from "react";
import { UserDetails } from "./components/UserDetails";
import { ChangePassword } from "./components/ChangePassword";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { Box } from "@chakra-ui/react";
import { SecuritySettings } from "./components/SecuritySettings";
import { AuthStoreTypeEnum } from "enums/auth-store-type.enum";
import { UserSettings } from "./components/UserSettings/UserSettings";

export const UserProfile = observer(() => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const {
    authStore: { authUser, selectedAuthStoreType },
  } = useAppStore();

  if (!authUser) return null;

  const configs = useMemo(() => {
    const configs = [
      {
        tabHash: "#user-settings",
        tabName: "User settings",
        tabComponent: <UserSettings user={authUser} containerRef={ref} />,
      },
    ];
    if (selectedAuthStoreType === AuthStoreTypeEnum.LEAD_PRO) {
      configs.push(
        {
          tabHash: "#details",
          tabName: "Details",
          tabComponent: <UserDetails user={authUser} containerRef={ref} />,
        },
        {
          tabHash: "#security",
          tabName: "Security",
          tabComponent: <SecuritySettings user={authUser} />,
        },
        {
          tabHash: "#change-password",
          tabName: "Change password",
          tabComponent: <ChangePassword containerRef={ref} />,
        }
      );
    }

    return [configs[1], configs[2], configs[0], configs[3]].filter(
      config => !!config
    );
  }, [selectedAuthStoreType, authUser]);

  return (
    <RightSideLayout>
      <Box
        position={"relative"}
        height={"100%"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        overflowY={"hidden"}
        ref={ref}
      >
        <TabsGroupLayout title={"User Profile"} config={configs} />
      </Box>
    </RightSideLayout>
  );
});
