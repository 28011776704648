import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  DelayedInput,
  SingleSelectInput,
  StandardIconButton,
} from "components";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TFilterExpression,
  TPersistentFilterPropertyValueSelectOption,
  TPersistentFiltersExpressionSchema,
} from "types/persistent-filters.type";
import {
  PersistentFilterExpressionOperator,
  PersistentFilterExpressionSchemaType,
} from "enums/persistent-filter.enum";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import {
  EXPRESSION_OPERATOR_OPTIONS,
  EXTENDED_EXPRESSION_OPERATOR_OPTIONS,
  UTM_ATTRIBUTE_OPTIONS,
} from "constants/persistent-filters";
import { PersistentFilterPropertyValueSelectOption } from "../select-input/custom-options";
import { UtmAttributeEnum } from "enums/utm-attributes.enum";

export const NEW_DEFAULT_FILTER_EXPRESSION: TFilterExpression = {
  property: "",
  operator: PersistentFilterExpressionOperator.EQUALS,
  value: "",
};

interface IPersistentFilterExpressionProps {
  configuration: TFilterExpression;
  expressionSchemaOptions: TSelectOption<string>[];
  expressionSchemaMap: Record<string, TPersistentFiltersExpressionSchema>;
  onChange: (childIndex: number, newConfiguration?: TFilterExpression) => void;
  childIndex: number;
}

export const PersistentFilterExpression: FC<IPersistentFilterExpressionProps> = ({
  configuration,
  expressionSchemaOptions,
  expressionSchemaMap,
  onChange,
  childIndex,
}) => {
  const [
    selectedPropertyExpressionSchema,
    setSelectedPropertyExpressionSchema,
  ] = useState<TPersistentFiltersExpressionSchema | null>(
    expressionSchemaMap[configuration.property] || null
  );

  useEffect(() => {
    setSelectedPropertyExpressionSchema(
      expressionSchemaMap[configuration.property] || null
    );
  }, [expressionSchemaMap, configuration.property]);

  // Handlers
  const onPropertyChange = useCallback(
    (property: TSelectValue<string>) => {
      if (property === null) return;

      const schema = expressionSchemaMap[property];
      onChange(childIndex, {
        ...NEW_DEFAULT_FILTER_EXPRESSION,
        property,
        path:
          schema.type === PersistentFilterExpressionSchemaType.META
            ? ""
            : undefined,
      });
      setSelectedPropertyExpressionSchema(schema);
    },
    [onChange, childIndex, expressionSchemaMap]
  );

  const onPropertyPathChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(childIndex, { ...configuration, path: event.target.value });
    },
    [onChange, childIndex, configuration]
  );

  const onPropertyUtmPathChange = useCallback(
    (value: TSelectValue<UtmAttributeEnum>) => {
      if (value === null) return;

      onChange(childIndex, { ...configuration, path: value });
    },
    [onChange, configuration, childIndex]
  );

  const onOperatorChange = useCallback(
    (operator: TSelectValue<PersistentFilterExpressionOperator>) => {
      if (operator === null) return;

      onChange(childIndex, { ...configuration, operator });
    },
    [onChange, configuration, childIndex]
  );

  const onPropertyValueChangeSelectInput = useCallback(
    (value: TSelectValue<any>) => {
      onChange(childIndex, { ...configuration, value });
    },
    [onChange, configuration, childIndex]
  );

  const onPropertyValueChangeTextInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(childIndex, { ...configuration, value: event.target.value });
    },
    [onChange, childIndex, configuration]
  );

  const handleRemoveFilterExpression = useCallback(() => {
    onChange(childIndex);
  }, [onChange, childIndex]);

  // Elements
  const propertySelectElement = useMemo(() => {
    return (
      <SingleSelectInput<string>
        value={configuration.property}
        onChange={onPropertyChange}
        options={expressionSchemaOptions}
        placeholder={"property"}
      />
    );
  }, [expressionSchemaOptions, configuration, onPropertyChange]);

  const propertyPathSelectElement = useMemo(() => {
    switch (selectedPropertyExpressionSchema?.type) {
      case PersistentFilterExpressionSchemaType.META:
        return (
          <DelayedInput
            inputValue={configuration.path}
            onChange={onPropertyPathChange}
            placeholder={"path"}
          />
        );
      default:
        return null;
    }
  }, [selectedPropertyExpressionSchema, configuration, onPropertyPathChange]);

  const propertyUtmAtrributeSelectElement = useMemo(() => {
    switch (selectedPropertyExpressionSchema?.type) {
      case PersistentFilterExpressionSchemaType.UTM:
        return (
          <SingleSelectInput<UtmAttributeEnum>
            value={configuration.path as UtmAttributeEnum}
            onChange={onPropertyUtmPathChange}
            options={UTM_ATTRIBUTE_OPTIONS}
          ></SingleSelectInput>
        );
    }
  }, [
    configuration.path,
    onPropertyUtmPathChange,
    selectedPropertyExpressionSchema?.type,
  ]);

  const operatorSelectElement = useMemo(() => {
    switch (selectedPropertyExpressionSchema?.type) {
      case PersistentFilterExpressionSchemaType.UTM:
      case PersistentFilterExpressionSchemaType.META:
        return (
          <SingleSelectInput<PersistentFilterExpressionOperator>
            value={configuration.operator}
            onChange={onOperatorChange}
            options={EXTENDED_EXPRESSION_OPERATOR_OPTIONS}
          />
        );
      default:
        return (
          <SingleSelectInput<PersistentFilterExpressionOperator>
            value={configuration.operator}
            onChange={onOperatorChange}
            options={EXPRESSION_OPERATOR_OPTIONS}
          />
        );
    }
  }, [configuration, onOperatorChange, selectedPropertyExpressionSchema]);

  const propertyValueElement = useMemo(() => {
    switch (selectedPropertyExpressionSchema?.type) {
      case PersistentFilterExpressionSchemaType.UTM:
      case PersistentFilterExpressionSchemaType.META:
        return (
          <DelayedInput
            inputValue={configuration.value}
            onChange={onPropertyValueChangeTextInput}
            placeholder={"value"}
          />
        );
      case PersistentFilterExpressionSchemaType.ENUM:
        return (
          <SingleSelectInput<any, TPersistentFilterPropertyValueSelectOption>
            value={configuration.value}
            onChange={onPropertyValueChangeSelectInput}
            placeholder={"value"}
            options={
              selectedPropertyExpressionSchema?.options.map(option => ({
                label: option.label,
                value: option.value,
                data: option,
              })) || []
            }
            optionComponent={optionProps => (
              <PersistentFilterPropertyValueSelectOption
                optionProps={optionProps}
              />
            )}
          />
        );
      default:
        return null;
    }
  }, [
    selectedPropertyExpressionSchema,
    configuration,
    onPropertyValueChangeTextInput,
    onPropertyValueChangeSelectInput,
  ]);

  // STYLE
  const gridTemplateColumns = useMemo(() => {
    switch (selectedPropertyExpressionSchema?.type) {
      case PersistentFilterExpressionSchemaType.UTM:
        return "120px auto 110px auto 32px";
      case PersistentFilterExpressionSchemaType.META:
        return "120px auto 110px auto 32px";
      default:
        return "120px 70px auto 32px";
    }
  }, [selectedPropertyExpressionSchema]);

  return (
    <SimpleGrid
      spacing={2}
      width={"100%"}
      gridTemplateColumns={gridTemplateColumns}
      alignItems={"center"}
    >
      <Box>{propertySelectElement}</Box>
      {!!propertyPathSelectElement && <Box>{propertyPathSelectElement}</Box>}
      {!!propertyUtmAtrributeSelectElement && (
        <Box>{propertyUtmAtrributeSelectElement}</Box>
      )}
      <Box>{operatorSelectElement}</Box>
      <Box>{propertyValueElement}</Box>
      <Box>
        <StandardIconButton
          onClick={handleRemoveFilterExpression}
          aria-label={"remove-filter-expression"}
          icon={<FontAwesomeIcon icon={faXmark} />}
        />
      </Box>
    </SimpleGrid>
  );
};
