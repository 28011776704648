import { observer } from "mobx-react";
import { Box, VStack } from "@chakra-ui/react";
import React, { FC } from "react";
import { FormToolPageSettingsActionConditionConfigurator } from "./FormToolPageSettingsActionConditionConfigurator";
import { FormToolPageSettingsConfiguratorDeletePopover } from "../FormToolPageSettingsConfiguratorDeletePopover";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";
import { FormToolPageSettingsActionType } from "./FormToolPageSettingsActionType";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
  additionalConfigurators?: React.ReactNode;
}

export const FormToolPageSettingsSelectedActionConfigurator: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore, additionalConfigurators }) => {
    return (
      <Box
        py={7}
        px={10}
        bg={"leadpro.50"}
        border={"1px solid"}
        borderColor={"leadpro.200"}
        rounded={"sm"}
        width={"100%"}
        position={"relative"}
      >
        <Box position={"absolute"} top={"10px"} right={"10px"}>
          <FormToolPageSettingsConfiguratorDeletePopover
            handleRemove={() =>
              formToolPageWIPFormStore.removeAction(actionIndex)
            }
            noun={"action"}
          />
        </Box>
        <VStack width={"100%"} spacing={2}>
          <FormToolPageSettingsActionType
            actionIndex={actionIndex}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
          />
          <FormToolPageSettingsActionConditionConfigurator
            actionIndex={actionIndex}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
          />
          {additionalConfigurators}
        </VStack>
      </Box>
    );
  }
);
