import React, { useCallback } from "react";
import { ApiMessageStack, FormControlV2, StandardIconButton } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Box, HStack, useToast } from "@chakra-ui/react";
import { TSelectOption } from "types/select-input.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { ConfirmActionPrompt } from "../../../../../../../../components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { Control } from "react-hook-form";
import { TOfficeMembershipFieldValues } from "utils/validation-schemas/office-membership.validation";

interface IProps {
  officeStore: AccountOfficeStore;
  membershipId?: number;
  name: string;
  options: TSelectOption<number>[];
  control: Control<TOfficeMembershipFieldValues>;
}

export const SingleOfficeMembershipInput: React.FC<IProps> = observer(
  ({ officeStore, membershipId, name, options, control }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();

    const handleRemoveMembership = useCallback(() => {
      if (!!membershipId) {
        setModal(
          <ConfirmActionPrompt
            text={"Are you sure you want to remove this membership?"}
            onConfirm={async () => {
              try {
                await officeStore.removeOfficeMembership(membershipId);
                toast({
                  ...DEFAULT_SUCCESS_TOAST_OPTIONS,
                  description: `You have successfully removed a membership.`,
                });
              } catch (e) {
                toast({
                  ...DEFAULT_ERROR_TOAST_OPTIONS,
                  description: <ApiMessageStack messageStack={e.message} />,
                });
              }
            }}
            closePrompt={unSetModal}
          />
        );
      }
    }, [setModal, unSetModal, toast, membershipId, officeStore]);

    return (
      <HStack spacing={2} align={"center"} width={"100%"}>
        <Box flexGrow={1}>
          <FormControlV2
            label=""
            name={name}
            control={control}
            type={FormControlsTypeEnum.SELECT_AND_INPUT}
            isDisabled={!!membershipId}
            additionalProps={{
              options,
              placeholder: {
                select: "Provider",
                input: "ID (optional)",
              },
            }}
          />
        </Box>
        <StandardIconButton
          icon={<FontAwesomeIcon icon={faXmark} />}
          aria-label={"remove-membership"}
          onClick={handleRemoveMembership}
          isDisabled={!membershipId}
          type={"button"}
        />
      </HStack>
    );
  }
);
