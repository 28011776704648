import { Handle, NodeProps, Position } from "reactflow";
import { Box, Center } from "@chakra-ui/react";
import { TEndNodeData } from "types/questionnaire-branching.type";
import { Text } from "@chakra-ui/react";
import React from "react";

const handleStyle = {
  width: "15px",
  height: "15px",
  borderRadius: "30%",
  background: "#3B68AA", // leadpro.600
};

export default function EndNode({ data }: NodeProps<TEndNodeData>) {
  return (
    <Box
      width={"200px"}
      height={"80px"}
      p={2}
      border={"1px solid"}
      borderColor={"leadpro.200"}
      borderRadius={"md"}
      background={"white"}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...handleStyle, top: "-17px" }}
      />
      <Center
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"leadpro.400"}
      >
        <Text noOfLines={2}>END</Text>
      </Center>
    </Box>
  );
}
