import { Box } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { TUserAccountData } from "types/user-account.type";
import { getEmailTemplateEditorPremadeBlocks } from "utils/template.utils";
import {
  TTemplateMergeTag,
  TTemplateMergeTagGroup,
} from "types/template-merge-tag.type";

const env = runtimeEnv();
const apiToken = env.REACT_APP_TOPOL_API_TOKEN;
const PARENT_ELEMENT_ID = "topol-plugin";
const { TopolPlugin } = window as any;

interface IEmailTemplateEditorV3Props {
  account: TUserAccountData;
  mergeTagGroups: TTemplateMergeTagGroup[];
  uiMergeTags: Record<string, TTemplateMergeTag>;
  templateDesign?: object;
  onSave: (design: object) => void;
}

export const EmailTemplateEditorV3: FC<IEmailTemplateEditorV3Props> = ({
  account,
  mergeTagGroups,
  uiMergeTags,
  templateDesign,
  onSave,
}) => {
  useEffect(() => {
    const TOPOL_OPTIONS = {
      id: `#${PARENT_ELEMENT_ID}`,
      authorize: {
        apiKey: apiToken,
        userId: account.id,
      },
      mainMenuAlign: "right",
      disableAlerts: true,
      light: true,
      title: false,
      removeTopBar: true,
      mergeTags: mergeTagGroups,
      savedBlocks: false, // [],
      premadeBlocks: getEmailTemplateEditorPremadeBlocks(uiMergeTags),
      callbacks: {
        onSave: onSave,
      },
    };

    TopolPlugin?.init(TOPOL_OPTIONS);
    if (!!templateDesign) {
      TopolPlugin?.load(JSON.stringify(templateDesign));
    }

    return () => {
      TopolPlugin?.destroy();
    };
  }, [account, onSave, mergeTagGroups, templateDesign, uiMergeTags]);

  return (
    <Box id={PARENT_ELEMENT_ID} width={"100%"} height={"100%"} flexGrow={1} />
  );
};
