import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { LeadAttributionItem } from "./LeadAttributionItem";
import { TLeadAttributionConfig } from "./lead-attribution.utils";
import { LeadBlockHeader } from "../LeadBlocks/LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlocks/LeadBlockBody";

interface ILeadAttributionListProps {
  leadAttributionConfig: TLeadAttributionConfig[];
}

export const LeadAttributionList: FC<ILeadAttributionListProps> = observer(
  ({ leadAttributionConfig }) => {
    const leadAttributionsItems = useMemo(() => {
      return leadAttributionConfig.map(item => (
        <LeadAttributionItem key={item.id} {...item} />
      ));
    }, [leadAttributionConfig]);

    return (
      <>
        <LeadBlockHeader>Lead Progression</LeadBlockHeader>
        <LeadBlockBody>{leadAttributionsItems}</LeadBlockBody>
      </>
    );
  }
);
