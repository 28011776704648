import { LeadStageEnum } from "enums/lead-stage.enum";
import { LeadStageLabel } from "./lead-stage-label";

export const LEAD_STAGES_ORDER = [
  LeadStageEnum.UNREAD,
  LeadStageEnum.IN_PROGRESS,
  LeadStageEnum.BUSINESS,
  LeadStageEnum.NO_BUSINESS,
  LeadStageEnum.DUPLICATE,
];

export const LEAD_STAGE_OPTIONS = [
  {
    value: LeadStageEnum.UNREAD,
    label: LeadStageLabel[LeadStageEnum.UNREAD],
  },
  {
    value: LeadStageEnum.IN_PROGRESS,
    label: LeadStageLabel[LeadStageEnum.IN_PROGRESS],
  },
  {
    value: LeadStageEnum.BUSINESS,
    label: LeadStageLabel[LeadStageEnum.BUSINESS],
  },
  {
    value: LeadStageEnum.NO_BUSINESS,
    label: LeadStageLabel[LeadStageEnum.NO_BUSINESS],
  },
  {
    value: LeadStageEnum.DUPLICATE,
    label: LeadStageLabel[LeadStageEnum.DUPLICATE],
  },
];
