import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { IntegrationEntitySyncTag } from "components";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { TAltoIntentionRelationExtendedData } from "types/alto.type";
import { AltoIntentionSyncSelect } from "./AltoIntentionSyncSelect";

export const syncAltoIntentionTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TAltoIntentionRelationExtendedData) => void
): ITableV2Column<TAltoIntentionRelationExtendedData>[] => {
  return [
    {
      accessor: "type",
      Header: <DefaultHeaderCell>LeadPro type</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell textTransform={"capitalize"}>{row.type}</DefaultRowCell>
      ),
    },
    {
      accessor: "intentionId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Alto intention</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <AltoIntentionSyncSelect
            accountStore={accountStore}
            onChange={updateSyncPair}
            record={row}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
