import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";

export const getWorkflowDataValidationErrors = (
  workflow: Partial<TLeadResponderWorkflow>
) => {
  const errorMessages = [];

  if (!workflow.name)
    errorMessages.push("Please provide a name for the workflow.");
  if (!workflow.leadFilterId)
    errorMessages.push("Please provide a filter for the workflow.");
  if (!workflow.replyToEmailTemplateId)
    errorMessages.push("Please provide an email template for the workflow.");
  if (workflow.smsReminderEnabled && !workflow.smsTemplateId)
    errorMessages.push("Please provide an sms template for the sms remainder.");

  if (workflow.followUpReminderEnabled && !workflow.followUpEmailTemplateId)
    errorMessages.push(
      "Please provide an email template for the follow up remainder."
    );

  return errorMessages;
};

export const getWorkflowActivityLabel = (active?: boolean) => {
  return active ? "active" : "inactive";
};
