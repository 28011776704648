import {
  TAccountExportRequestsApi,
  TExportRequest,
} from "types/export-request.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { ExportRequestStatusEnum } from "enums/export-request-status.enum";
import {
  getMonthsAndYearsBetweenDates,
  isDateCorrectFormat,
} from "utils/date.utils";
import { DATE_MONTH_YEAR, DATE_YEAR, DATE_YEAR_MONTH } from "constants/date";
import moment from "moment";
import { AppStore } from "store/App.store";
import { TSelectOption } from "types/select-input.type";

export class AccountExportRequestsStore {
  private api: TAccountExportRequestsApi;
  private root: AppStore;
  private readonly accountId;
  private readonly requests: IObservableArray<TExportRequest>;

  constructor(
    root: AppStore,
    accountId: number,
    api: TAccountExportRequestsApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.api = api;
    this.requests = observable.array<TExportRequest>();
  }

  get exportRequestsArray() {
    return this.requests.slice();
  }

  get areTherePendingExportRequests(): boolean {
    return !!this.requests.find(
      request => request.status === ExportRequestStatusEnum.PENDING
    );
  }

  get exportPeriodOptions() {
    const periods = this.calculateAvailablePeriods();
    return this.createSelectOptionsFromPeriods(periods);
  }

  public setExportRequests(requests: TExportRequest[]) {
    this.requests.replace(requests);
  }

  public async fetchExportRequests() {
    const requests = await this.api.fetchExportRequests(this.accountId);
    this.setExportRequests(requests);
  }

  public async createExportRequest(period: string) {
    const request = await this.api.createExportRequest(this.accountId, period);
    this.upsertExportRequest(request);
  }

  private upsertExportRequest(request: TExportRequest) {
    const index = this.requests.findIndex(req => req.id === request.id);

    if (index !== -1) {
      this.requests[index] = request;
    } else {
      this.requests.push(request);
    }
  }

  private calculateAvailablePeriods(): string[] {
    const account = this.root.userAccountsStore.userAccountsMap[this.accountId];
    const exportRequestsPeriod = this.requests.map(request => request.period);
    const now = moment();
    const currentYear = now.format(DATE_YEAR);
    const currentMonth = now.startOf("month").format(DATE_YEAR_MONTH);
    const lastMonth = now
      .subtract(1, "month")
      .startOf("month")
      .format(DATE_YEAR_MONTH);

    return getMonthsAndYearsBetweenDates(account.account.createdAt)
      .filter(
        period =>
          !exportRequestsPeriod.includes(period) ||
          period === currentMonth ||
          period === lastMonth ||
          period === currentYear
      )
      .reverse();
  }

  private createSelectOptionsFromPeriods(
    periods: string[]
  ): TSelectOption<string>[] {
    return periods.map(period => ({
      label: isDateCorrectFormat(period, DATE_YEAR)
        ? `Full Year ${period}`
        : moment(period).format(DATE_MONTH_YEAR),
      value: period,
    }));
  }
}
