import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
}

export const LeadInfoRow: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      mb={3}
      alignItems={"flex"}
    >
      {children}
    </Box>
  );
};
