import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC } from "react";
import { observer } from "mobx-react";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { LeadResponderSettings } from "./components/LeadResponderSettings";
import { LeadResponderSplashScreen } from "./components/LeadResponderSplashScreen/LeadResponderSplashScreen";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponder: FC<IProps> = observer(({ accountStore }) => {
  return (
    <PremiumFeatureBlock
      productId={ApplicationProductEnum.LEAD_RESPONDER}
      render={() => <LeadResponderSettings accountStore={accountStore} />}
      alternativeRender={() => (
        <LeadResponderSplashScreen accountStore={accountStore} />
      )}
    />
  );
});
