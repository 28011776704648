import { Button, ButtonProps, Link } from "@chakra-ui/react";
import { FC } from "react";

interface IButtonLinkProps extends ButtonProps {
  href: string;
  label: string;
}

export const ButtonLink: FC<IButtonLinkProps> = ({ href, label, ...rest }) => {
  return (
    <Link href={href} isExternal={true} _hover={{ textDecoration: "none" }}>
      <Button {...rest}>{label}</Button>
    </Link>
  );
};
