import { Box, Button, Image } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ActionPromptContainer } from "components";
import {
  LeadResponderEmailExample,
  WEBFORM_LINK,
} from "./LeadResponderEmailExample";
import { ButtonLink } from "components";
import { LEAD_RESPONDER_DIAGRAM } from "constants/image-paths";

export const LeadResponderHowItWorks = () => {
  const { setModal } = useActionPrompt();

  const handleOpenEmailExample = useCallback(() => {
    setModal(
      <ActionPromptContainer
        header={"Email example"}
        body={<LeadResponderEmailExample />}
      />
    );
  }, [setModal]);

  return (
    <Box position={"relative"}>
      <Image src={LEAD_RESPONDER_DIAGRAM} />
      <Button
        transform={"translateX(-50%)"}
        variant="outline"
        rounded={"full"}
        shadow={"md"}
        position={"absolute"}
        top={"65%"}
        left={"40%"}
        onClick={handleOpenEmailExample}
      >
        View example
      </Button>
      <ButtonLink
        href={WEBFORM_LINK}
        label={"View example"}
        transform={"translateX(-50%)"}
        variant="outline"
        rounded={"full"}
        shadow={"md"}
        position={"absolute"}
        top={"65%"}
        left={"56%"}
      />
    </Box>
  );
};
