import { Box, Button, VStack } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { SingleSelectInput } from "components";
import { TSelectValue } from "types/select-input.type";
import { FormLogicJump, FormLogicJumpOperator } from "@leadpro/forms";
import {
  DEFAULT_JUMP_DATA,
  OPERATOR_OPTIONS,
} from "constants/questionnaire-branching";
import { LogicElementJumpConfiguratorIs } from "./LogicElementJumpConfiguratorIs";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";

interface IProps {
  fromNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  toNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  formLogicJump: FormLogicJump;
  onChange: (formLogicJump: FormLogicJump | null) => void;
  isRemovable?: boolean;
}

export const LogicElementJumpConfigurator: FC<IProps> = ({
  fromNode,
  toNode,
  formLogicJump,
  onChange,
  isRemovable,
}) => {
  const fromField = fromNode.data.field;
  const fromQuestionLabel = fromField.label;
  const toField = toNode.data.field;
  const toQuestionLabel = toField.label;

  const handleOperatorChange = useCallback(
    (newOperatorValue: TSelectValue<FormLogicJumpOperator>) => {
      if (!newOperatorValue) return;

      onChange(DEFAULT_JUMP_DATA[newOperatorValue](fromNode.id, toNode.id));
    },
    [onChange, fromNode.id, toNode.id]
  );

  return (
    <Box>
      <Box mb={5}>
        <Box>When person answers:</Box>
        <Box color={"gray.500"}>
          - <i>{fromQuestionLabel}</i>
        </Box>
      </Box>
      <Box mb={5}>
        <VStack spacing={4} width={"100%"}>
          <SingleSelectInput<FormLogicJumpOperator>
            value={formLogicJump.operator as FormLogicJumpOperator}
            onChange={handleOperatorChange}
            options={OPERATOR_OPTIONS}
            placeholder={"property"}
          />
          {formLogicJump.operator === FormLogicJumpOperator.IS && (
            <Box width={"100%"}>
              <LogicElementJumpConfiguratorIs
                fromNode={fromNode}
                toNode={toNode}
                formLogicJump={formLogicJump}
                onChange={onChange}
              />
            </Box>
          )}
        </VStack>
      </Box>
      <Box mb={5}>
        <Box>Go to:</Box>
        <Box color={"gray.500"}>
          - <i>{toQuestionLabel}</i>
        </Box>
      </Box>
      {isRemovable && (
        <Box textAlign={"right"}>
          <Button
            variant={"link"}
            colorScheme={"blue"}
            onClick={() => onChange(null)}
          >
            Remove
          </Button>
        </Box>
      )}
    </Box>
  );
};
