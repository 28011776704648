import {
  Expression,
  FormCustomEndingPageConfig,
  FormFieldType,
  FormSchema,
  Group,
  QuestionnaireAction,
  QuestionnaireActionType,
  QuestionnaireActionUpdatePostcode,
  validateFormEndingPages,
  validateFormSchema,
  validateQuestionnaireActions,
} from "@leadpro/forms";
import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { TSelectOption } from "types/select-input.type";
import {
  generateQuestionnaireActionStub,
  generateQuestionnaireToolPageDefaultFormSchema,
} from "utils/questionnaire-tool-page.utils";
import { keyBy, remove, uniqueId, unset } from "lodash";
import { DefaultFormToolPageQuestionTemplates } from "constants/form-tool-page";
import { QuestionnaireToolPageActionTypeLabels } from "constants/questionnaire-tool-page";
import dagre from "@dagrejs/dagre";
import {
  COMMON_EDGE_SETTINGS,
  QUESTIONNAIRE_BRANCHING_END_NODE,
} from "constants/questionnaire-branching";
import {
  FormLogicElement,
  FormLogicJump,
  FormLogicJumpIs,
} from "@leadpro/forms";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";

export class QuestionnaireToolPageWIPFormStore {
  private wipFormSchema: FormSchema;
  private wipFormActions: IObservableArray<QuestionnaireAction>;
  private wipFormCustomEndingPages: IObservableArray<
    FormCustomEndingPageConfig & { tempId: string }
  >;

  constructor({
    form,
    actions,
    customEndingPages,
  }: {
    form?: FormSchema;
    actions?: QuestionnaireAction[];
    customEndingPages?: FormCustomEndingPageConfig[];
  }) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.wipFormSchema = form
      ? toJS(form)
      : generateQuestionnaireToolPageDefaultFormSchema();
    this.wipFormActions = observable.array(actions ? toJS(actions) : []);
    this.wipFormCustomEndingPages = observable.array(
      customEndingPages
        ? toJS(customEndingPages.map(page => ({ ...page, tempId: uniqueId() })))
        : []
    );
  }

  get wipFormSchemaJS() {
    return toJS(this.wipFormSchema);
  }

  get wipFormActionsJS() {
    return toJS(this.wipFormActions);
  }

  get wipFormCustomEndingPagesJSWithTempId() {
    return toJS(this.wipFormCustomEndingPages);
  }

  get wipFormCustomEndingPagesJSWithoutTempId() {
    return toJS(this.wipFormCustomEndingPages).map(page => {
      unset(page, "tempId");
      return page;
    });
  }

  get isIgnored() {
    return !this.wipFormSchemaJS.order.length;
  }

  get wipFormSchemaValidationResult() {
    return validateFormSchema(this.wipFormSchemaJS);
  }

  get wipFormSchemaWithoutLogicValidationResult() {
    return validateFormSchema({
      ...this.wipFormSchemaJS,
      logic: undefined,
    });
  }

  get wipFormActionsValidationResult() {
    return validateQuestionnaireActions(
      this.wipFormSchemaJS,
      this.wipFormActionsJS
    );
  }

  get wipFormCustomEndingPagesValidationResult() {
    return validateFormEndingPages(
      this.wipFormSchemaJS,
      this.wipFormCustomEndingPagesJSWithoutTempId
    );
  }

  get isWIPQuestionnaireFormSchemaAndActionsValid() {
    return (
      this.isWIPQuestionnaireFormSchemaValid &&
      this.areWIPQuestionnaireFormActionsValid
    );
  }

  get isWIPQuestionnaireFormSchemaWithoutLogicAndWithActionsValid() {
    return (
      this.isWIPQuestionnaireFormSchemaWithoutLogicValid &&
      this.areWIPQuestionnaireFormActionsValid
    );
  }

  get isWIPQuestionnaireFormSchemaValid() {
    return this.wipFormSchemaValidationResult.isValid;
  }

  get isWIPQuestionnaireFormSchemaWithoutLogicValid() {
    return this.wipFormSchemaWithoutLogicValidationResult.isValid;
  }

  get isWIPQuestionnaireFormSchemaLogicValid() {
    return !(
      !this.isWIPQuestionnaireFormSchemaValid &&
      this.isWIPQuestionnaireFormSchemaWithoutLogicValid
    );
  }

  get areWIPQuestionnaireFormActionsValid() {
    return this.wipFormActionsValidationResult.isValid;
  }

  // OPTIONS
  get allQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];

    this.wipFormSchema.order.forEach((questionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[questionKey];

      options.push({
        label: `Question #${questionIndex + 1}: ${question.label}`,
        value: questionKey,
      });
    });

    return options;
  }

  get postcodeQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((qestionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[qestionKey];

      if (question?.required && question?.type === FormFieldType.POSTCODE) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: qestionKey,
        });
      }
    });

    return options;
  }

  get nodesAndEdgesFromQuestions() {
    if (!this.wipFormSchemaJS.order.length) {
      return {
        edges: [],
        nodes: [],
      };
    }

    const graph = new dagre.graphlib.Graph({ multigraph: true });
    graph.setGraph({ align: "DR" });
    graph.setDefaultEdgeLabel(function() {
      return {};
    });

    // nodes
    this.wipFormSchemaJS.order?.forEach((field, index) => {
      graph.setNode(field, {
        width: 200,
        height: 200,
      });
    });

    // set END node
    graph.setNode(QUESTIONNAIRE_BRANCHING_END_NODE, {
      width: 200,
      height: 200,
    });

    const fieldsWithAlwaysBranch: string[] = [];
    // branching flows edges
    this.wipFormSchemaJS.logic?.forEach((formLogicElement, logicIndex) => {
      formLogicElement.jumps.forEach((jump, jumpIndex) => {
        // remember fields with "always" branches so that we can omit default edges for that fields
        if (jump.operator === "always")
          fieldsWithAlwaysBranch.push(formLogicElement.field);

        const existingEdge = graph.edge(formLogicElement.field, jump.to);
        //  in the case when there are multiple jumps for the same from-to.
        if (!!existingEdge) {
          graph.setEdge(formLogicElement.field, jump.to, {
            logicIndex,
            jumpIndexes: [...existingEdge.jumpIndexes, jumpIndex],
            jumps: [...existingEdge.jumps, jump],
          });
        } else {
          graph.setEdge(formLogicElement.field, jump.to, {
            logicIndex,
            jumpIndexes: [jumpIndex],
            jumps: [jump],
          });
        }
      });
    });

    const defaultReachableFields = this.wipFormSchemaJS.order;
    defaultReachableFields.forEach((field, index) => {
      if (index + 1 !== defaultReachableFields.length) {
        const existingEdge = graph.edge(
          field,
          defaultReachableFields[index + 1]
        );

        // omit default edge if there is an existing edge and there is no "always" jump from that field
        if (!existingEdge && !fieldsWithAlwaysBranch.includes(field)) {
          graph.setEdge(field, defaultReachableFields[index + 1], {
            label: "DEFAULT",
          });
        }
      }
    });

    // set edge from the last question to the END node
    graph.setEdge(
      this.wipFormSchemaJS.order[this.wipFormSchemaJS.order.length - 1],
      QUESTIONNAIRE_BRANCHING_END_NODE,
      {
        label: "DEFAULT",
      }
    );
    dagre.layout(graph);

    return {
      nodes: graph.nodes().map((node, index) => {
        const nodeData = graph.node(node);
        const field =
          node === QUESTIONNAIRE_BRANCHING_END_NODE
            ? { label: "End" }
            : this.wipFormSchemaJS.fields[node];
        const type =
          node === QUESTIONNAIRE_BRANCHING_END_NODE
            ? QuestionnaireBranchingNodeTypes.END_NODE
            : QuestionnaireBranchingNodeTypes.QUESTION_NODE;

        return {
          id: node,
          data: { index, field },
          position: { x: nodeData.x, y: nodeData.y },
          type,
        };
      }),
      edges: graph.edges().map(edge => {
        const edgeData = graph.edge(edge);
        return {
          id: JSON.stringify(edgeData.points),
          source: edge.v,
          target: edge.w,
          label: edgeData.label,
          data: {
            logicIndex: edgeData.logicIndex,
            jumpIndexes: edgeData.jumpIndexes,
            jumps: edgeData.jumps,
            questionnaireToolPageWIPFormStore: this,
          },
          ...COMMON_EDGE_SETTINGS,
        };
      }),
    };
  }

  get logicElementsMappedByField() {
    if (!this.wipFormSchema.logic) return {};

    return keyBy(
      toJS(this.wipFormSchema.logic),
      logicElement => logicElement.field
    );
  }

  // JUMPS
  public addLogicElement(
    field: FormLogicElement["field"],
    jump: FormLogicJump
  ) {
    if (!this.wipFormSchema.logic) {
      this.wipFormSchema.logic = [];
    }

    const existingLogicForFieldIndex = this.wipFormSchema.logic.findIndex(
      fieldLogic => fieldLogic.field === field
    );
    if (existingLogicForFieldIndex !== -1) {
      this.wipFormSchema.logic[existingLogicForFieldIndex].jumps = [
        ...this.wipFormSchema.logic[existingLogicForFieldIndex].jumps,
        jump,
      ];
    } else {
      this.wipFormSchema.logic.push({
        field,
        jumps: [jump],
      });
    }
  }

  public setLogicElementJumpsForField(
    field: FormLogicElement["field"],
    jumps: FormLogicJump[]
  ) {
    if (!this.wipFormSchema.logic) return;

    const existingLogicForField = this.wipFormSchema.logic.find(
      fieldLogic => fieldLogic.field === field
    );
    if (!existingLogicForField) return;

    existingLogicForField.jumps = jumps;
  }

  // QUESTIONS
  public addQuestion(type: FormFieldType, position: number) {
    const questionKey = `q_${new Date().getTime()}`;
    this.wipFormSchema.fields[questionKey] = {
      ...DefaultFormToolPageQuestionTemplates[type],
    };
    this.wipFormSchema.order.splice(position, 0, questionKey);
  }

  public swapQuestionsOrder(index1: number, index2: number) {
    if (!this.wipFormSchema.order[index1] || !this.wipFormSchema.order[index2])
      return;

    this.wipFormSchema.order[index1] = this.wipFormSchema.order.splice(
      index2,
      1,
      this.wipFormSchema.order[index1]
    )[0];
  }

  public removeQuestion(questionKey: string) {
    const newSchema = this.wipFormSchemaJS;
    // delete from fields
    delete newSchema.fields[questionKey];

    // delete from order
    remove(newSchema.order, value => value === questionKey);

    // delete from logic
    newSchema.logic = newSchema.logic?.filter(
      logicElement => logicElement.field !== questionKey
    );
    newSchema.logic?.forEach(logicElement => {
      logicElement.jumps = logicElement.jumps.filter(
        jump =>
          jump.to !== questionKey &&
          (jump as FormLogicJumpIs).field !== questionKey
      );
    });

    this.setWIPFormSchema(newSchema);
  }

  public updateQuestion(
    questionKey: string,
    values: FormSchema["fields"]["key"]
  ) {
    this.wipFormSchema.fields[questionKey] = {
      ...this.wipFormSchema.fields[questionKey],
      ...values,
    };
  }

  // ACTIONS
  public addAction(actionType: QuestionnaireActionType) {
    this.wipFormActions.push(generateQuestionnaireActionStub(actionType));
  }

  public setActionCondition(
    actionIndex: number,
    groupOrExpression?: Group | Expression
  ) {
    const action = this.wipFormActions[actionIndex];
    if (!action) return;

    action.condition = groupOrExpression;
  }

  public setActionParams<T extends QuestionnaireAction>(
    actionIndex: number,
    actionParams?: Partial<T["params"]>
  ) {
    const action = this.wipFormActions[actionIndex] as T;
    if (!action) return;

    action.params = {
      ...action.params,
      ...actionParams,
    };
  }

  public updateActionParams<T extends QuestionnaireAction>(
    actionIndex: number,
    actionParams?: Partial<T["params"]>
  ) {
    const action = this.wipFormActions[actionIndex] as T;
    if (!action) return;

    if (!!actionParams) {
      const updatePostcodeAction = action as QuestionnaireActionUpdatePostcode;
      updatePostcodeAction.params = {
        ...updatePostcodeAction.params,
        ...actionParams,
      };
    }
  }

  public removeAction(actionIndex: number) {
    if (!this.wipFormActions[actionIndex]) return;
    this.wipFormActions.splice(actionIndex, 1);
  }

  // ENDING PAGES
  public addWipPageCustomEndingPageConfig() {
    this.wipFormCustomEndingPages.push({
      tempId: uniqueId(),
      params: {
        customEndingPageHeader: "",
        customEndingPageBody: "",
        customEndingPageCtaLabel: "",
        customEndingPageCtaUrl: "",
      },
    });
  }

  public removeWipFormCustomEndingPageConfig(pageIndex: number) {
    this.wipFormCustomEndingPages.replace(
      this.wipFormCustomEndingPages.filter(
        (pageConfig, index) => index !== pageIndex
      )
    );
  }

  public updateWipFormCustomEndingPageConfigCondition(
    pageIndex: number,
    condition: FormCustomEndingPageConfig["condition"]
  ) {
    const pageConfig = this.wipFormCustomEndingPages[pageIndex];
    if (!!pageConfig) {
      pageConfig.condition = condition;
    }
  }
  public updateWipPageCustomEndingPageConfigContent(
    pageIndex: number,
    content: FormCustomEndingPageConfig["params"]
  ) {
    const pageConfig = this.wipFormCustomEndingPages[pageIndex];
    if (!!pageConfig) {
      pageConfig.params = content;
    }
  }

  public swapEndingPagesOrder(index1: number, index2: number) {
    if (
      !this.wipFormCustomEndingPages[index1] ||
      !this.wipFormCustomEndingPages[index2]
    )
      return;

    this.wipFormCustomEndingPages[
      index1
    ] = this.wipFormCustomEndingPages.splice(
      index2,
      1,
      this.wipFormCustomEndingPages[index1]
    )[0];
  }

  public setWIPFormSchema(schema: FormSchema | null) {
    this.wipFormSchema =
      schema || generateQuestionnaireToolPageDefaultFormSchema();
  }

  public setWipFormActions(actions: QuestionnaireAction[]) {
    this.wipFormActions.replace(actions);
  }

  public setWipFormCustomEndingPages(
    customEndingPages: FormCustomEndingPageConfig[]
  ) {
    this.wipFormCustomEndingPages.replace(
      customEndingPages.map(page => ({ ...page, tempId: uniqueId() }))
    );
  }

  public getActionLabelForAction(actionIndex: number) {
    const action = this.wipFormActionsJS[actionIndex];

    return QuestionnaireToolPageActionTypeLabels[action.type];
  }
}
