import { Box, VStack, Container } from "@chakra-ui/react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useParams } from "react-router-dom";
import { Error, Loader } from "components";
import { LeadEmailBlocks } from "../LeadResponderEmailBlocks/LeadEmailBlocks";

interface ILeadEmailDetailsProps {
  accountStore: UserAccountStore;
}

export const LeadEmailDetails: FC<ILeadEmailDetailsProps> = observer(
  ({ accountStore }) => {
    const { id } = useParams<{ id: string }>();

    const emailId = useMemo(() => parseInt(id!), [id]);

    const [apiRequestStatus, setApiRequestStatus] = useState<
      ApiRequestStatusEnum
    >(ApiRequestStatusEnum.NONE);

    const leadResponderEmailsStore =
      accountStore.leadResponderStore.leadResponderEmailsStore;

    const accountOfficesStore = accountStore.accountOfficesStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setApiRequestStatus(ApiRequestStatusEnum.PENDING);
          await leadResponderEmailsStore.getEmailWithDetails(emailId);
          setApiRequestStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setApiRequestStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [leadResponderEmailsStore, emailId]);

    const accountEmailStore = useMemo(() => {
      return leadResponderEmailsStore.emailStoresMap[emailId];
    }, [leadResponderEmailsStore.emailStoresMap, emailId]);

    if (!accountEmailStore) return null;

    return (
      <Box overflow={"auto"} w={"100%"} h={"100%"}>
        <Container maxW="container.lg" p={4} h={"100%"}>
          {apiRequestStatus === ApiRequestStatusEnum.PENDING && <Loader />}
          {apiRequestStatus === ApiRequestStatusEnum.ERROR && <Error />}
          {apiRequestStatus === ApiRequestStatusEnum.SUCCESS && (
            <VStack spacing={10} align={"left"}>
              <Box as="h2" fontSize="xl" fontWeight="bold">
                Lead Responder Settings
              </Box>
              <LeadEmailBlocks
                leadResponderEmailStore={accountEmailStore}
                accountOfficesStore={accountOfficesStore}
              />
            </VStack>
          )}
        </Container>
      </Box>
    );
  }
);
