import * as Yup from "yup";
import { INVALID_PHONE_AUTO_ATTENDANT } from "constants/validator-messages";
import { PHONE_AUTO_ATTENDANT_REGEX } from "../regexs.utils";

export type TAutocallerPhonesFieldValues = {
  salesAutoAttendant: string;
  lettingsAutoAttendant: string;
  vendorAutoAttendant: string;
  landlordAutoAttendant: string;
  mortgagesAutoAttendant: string;
};

export const autocallerValidationSchema = Yup.object().shape({
  salesAutoAttendant: Yup.string().matches(
    PHONE_AUTO_ATTENDANT_REGEX,
    INVALID_PHONE_AUTO_ATTENDANT
  ),
  lettingsAutoAttendant: Yup.string().matches(
    PHONE_AUTO_ATTENDANT_REGEX,
    INVALID_PHONE_AUTO_ATTENDANT
  ),
  vendorAutoAttendant: Yup.string().matches(
    PHONE_AUTO_ATTENDANT_REGEX,
    INVALID_PHONE_AUTO_ATTENDANT
  ),
  landlordAutoAttendant: Yup.string().matches(
    PHONE_AUTO_ATTENDANT_REGEX,
    INVALID_PHONE_AUTO_ATTENDANT
  ),
  mortgagesAutoAttendant: Yup.string().matches(
    PHONE_AUTO_ATTENDANT_REGEX,
    INVALID_PHONE_AUTO_ATTENDANT
  ),
});
