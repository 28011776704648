import { OfficeCategory } from "enums/office-type.enum";

export const OfficeCategoryLabel: Record<OfficeCategory, string> = {
  [OfficeCategory.GROUP]: "Office Group",
  [OfficeCategory.OFFICE]: "Office",
};

export const OFFICE_CATEGORY_ORDER: OfficeCategory[] = [
  OfficeCategory.GROUP,
  OfficeCategory.OFFICE,
];
