import { Box, BoxProps, VStack } from "@chakra-ui/react";
import React, { FC } from "react";

export interface ISectionLayoutProps extends BoxProps {
  title?: string;
  overrideMaxW?: string;
}

export const SectionLayout: FC<ISectionLayoutProps> = ({
  title,
  children,
  overrideMaxW,
  ...rest
}) => {
  return (
    <Box
      flex={"1 1 0%"}
      overflowY={"hidden"}
      height={"100%"}
      overflow={"auto"}
      p={6}
      pb={10}
      {...rest}
    >
      <VStack
        spacing={12}
        maxW={overrideMaxW || "5xl"}
        mx="auto"
        width="100%"
        minHeight={"100%"}
        align={"stretch"}
      >
        {!!title && (
          <Box fontSize={"3xl"} fontWeight={700}>
            {title}
          </Box>
        )}
        <Box display={"flex"} flexGrow={1}>
          {children}
        </Box>
      </VStack>
    </Box>
  );
};
