import { Box, BoxProps, Circle, Divider, VStack } from "@chakra-ui/react";
import { DATE_TIME_DAY_OF_THE_WEEK_FORMAT } from "constants/date";
import moment from "moment";
import { FC, ReactNode, useEffect } from "react";

interface IProps {
  style?: BoxProps["style"];
  author: ReactNode;
  createdAt: string;
  iconColor: string;
  icon: ReactNode;
  content: ReactNode;
  isLast: boolean;
  onLoad?: () => void;
}

export const LeadActivityItem: FC<IProps> = ({
  onLoad,
  style,
  author,
  createdAt,
  iconColor,
  icon,
  content,
  isLast,
}) => {
  useEffect(() => {
    !!onLoad && onLoad();
  }, [onLoad]);

  return (
    <Box style={style}>
      <Box display={"flex"} width={"100%"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          mr={3}
        >
          <Circle size="28px" bg={iconColor} color="white">
            {icon}
          </Circle>
          {!isLast && (
            <Box py={1} height={"100%"} width={"2px"}>
              <Divider orientation={"vertical"} borderLeftWidth={"2px"} />
            </Box>
          )}
        </Box>

        <VStack spacing={2} align={"flexStart"}>
          <Box>
            <Box fontSize={"sm"} lineHeight={"none"}>
              {author}
            </Box>
            <Box fontSize={"xs"} color={"gray.500"}>
              {moment(createdAt).format(DATE_TIME_DAY_OF_THE_WEEK_FORMAT)}
            </Box>
          </Box>
          <Box pb={8} pr={3} overflow={"hidden"}>
            {content}
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};
