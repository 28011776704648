import React, { useMemo } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import {
  TSelectOption,
  TSelectOptionValue,
  TSelectValue,
} from "types/select-input.type";
import { IGetOptionPropsData } from "../SelectInputOptionsController";
import { isNil } from "lodash";

interface ISingleSelectInputValueProps<
  D extends TSelectOptionValue,
  S = unknown
> extends Omit<BoxProps, "onChange"> {
  value: TSelectValue<D>;
  selectedOption: TSelectOption<D, S>;
  getOptionProps: (props: object) => IGetOptionPropsData<D, S>;
  onChange: (value: TSelectValue<D>) => void;
  optionComponent: (
    optionProps: IGetOptionPropsData<D, S>,
    displayingAsInputValue?: boolean
  ) => React.ReactNode;
  placeholder?: string;
}

export function SingleSelectInputValue<
  D extends TSelectOptionValue,
  S = unknown
>({
  placeholder,
  optionComponent,
  selectedOption,
  getOptionProps,
}: ISingleSelectInputValueProps<D, S>) {
  const { option, key } = useMemo(() => {
    return getOptionProps({
      index: 0,
      option: selectedOption,
    });
  }, [getOptionProps, selectedOption]);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      flexBasis={"100%"}
      overflow={"hidden"}
    >
      {isNil(selectedOption.value) && (
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          color={"gray.400"}
          px={2}
        >
          {placeholder}
        </Box>
      )}
      {!isNil(selectedOption.value) &&
        optionComponent(
          {
            option,
            key,
            onClick: undefined,
            onMouseEnter: undefined,
          },
          true
        )}
    </Box>
  );
}
