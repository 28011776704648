export const enum LeadSourceEnum {
  manually_added = "manually_added",
  yourmove = "yourmove",
  yourmove_valuation = "yourmove_valuation",
  zoopla = "zoopla",
  zoopla_valuation = "zoopla_valuation",
  rightmove = "rightmove",
  rightmove_valuation = "rightmove_valuation",
  reedsrains = "reedsrains",
  reedsrains_valuation = "reedsrains_valuation",
  onthemarket = "onthemarket",
  boomin = "boomin",
  ivt = "ivt",

  // new ones, not fully supported, ask for change of color and for proper block factory
  gumtree = "gumtree",
  s1 = "s1",
  lettingweb = "lettingweb",
  propertypal = "propertypal",
  propertynews = "propertynews",
  homepage = "homepage",

  // deprecated?
  signup = "signup",
}
