import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { leadResponderQuestionnairePageTableColumnDef } from "./leadResponderQuestionnairePageTableColumnDef";
import { observer } from "mobx-react";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TQuestionnairePageTableData } from "types/questionnaire-tool-page.type";
import { useHistory } from "react-router-dom";
import {
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_RESPONSES_ROUTE,
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE,
} from "constants/routes";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderQuestionnaires: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();
    const history = useHistory();

    const accountPersistentFiltersStore =
      accountStore.accountPersistentFiltersStore;
    const accountQuestionnairePagesStore =
      accountStore.accountPagesStore.accountQuestionnairePagesStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountPersistentFiltersStore.loadPersistentFilters(),
            accountQuestionnairePagesStore.loadPages(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      setLoadingStatus,
      accountPersistentFiltersStore,
      accountQuestionnairePagesStore,
    ]);

    const handleCreateOrEditQuestionnairePage = useCallback(
      (questionnairePage?: TQuestionnairePageTableData) => () => {
        history.push(
          TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE.replace(
            ":id",
            questionnairePage?.id.toString() || "new"
          )
        );
      },
      [history]
    );

    const onRemoveQuestionnairePage = useCallback(
      (questionnairePageId: number) => async () => {
        try {
          await accountQuestionnairePagesStore.removePage(questionnairePageId);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Questionnaire page was removed successfully."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountQuestionnairePagesStore, toast]
    );

    const handleRemoveQuestionnairePage = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => () => {
        setModal(
          <ConfirmActionPrompt
            text={`Please confirm that you want to remove ${questionnairePage.name} questionnaire page.`}
            onConfirm={onRemoveQuestionnairePage(questionnairePage.id)}
            closePrompt={unSetModal}
          />
        );
      },
      [onRemoveQuestionnairePage, setModal, unSetModal]
    );

    const handleIncreaseQuestionnairePagePriority = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => async () => {
        try {
          setIsSubmitting(true);
          await accountQuestionnairePagesStore.handleIncreaseQuestionnairePagePriority(
            questionnairePage.pageQuestionnaireConfigId
          );
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      [accountQuestionnairePagesStore, toast]
    );

    const handleDecreaseQuestionnairePagePriority = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => async () => {
        try {
          setIsSubmitting(true);
          await accountQuestionnairePagesStore.handleDecreaseQuestionnairePriority(
            questionnairePage.pageQuestionnaireConfigId
          );
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      [accountQuestionnairePagesStore, toast]
    );

    const handleGoToResponses = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => () => {
        history.push(
          TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_RESPONSES_ROUTE.replace(
            ":id",
            questionnairePage.id.toString()
          )
        );
      },
      [history]
    );

    const handleOnRowClick = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => {
        handleCreateOrEditQuestionnairePage(questionnairePage)();
      },
      [handleCreateOrEditQuestionnairePage]
    );

    const duplicateQuestionnairePage = useCallback(
      (questionnairePage: TQuestionnairePageTableData) => async () => {
        try {
          setIsSubmitting(true);
          await accountQuestionnairePagesStore.duplicatePage(
            questionnairePage.id
          );
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      [accountQuestionnairePagesStore, toast]
    );

    const columnsDef = useMemo(() => {
      return leadResponderQuestionnairePageTableColumnDef(
        accountPersistentFiltersStore.persistentFiltersMap,
        handleCreateOrEditQuestionnairePage,
        handleRemoveQuestionnairePage,
        handleIncreaseQuestionnairePagePriority,
        handleDecreaseQuestionnairePagePriority,
        handleGoToResponses,
        duplicateQuestionnairePage,
        isSubmitting
      );
    }, [
      accountPersistentFiltersStore.persistentFiltersMap,
      handleCreateOrEditQuestionnairePage,
      handleRemoveQuestionnairePage,
      handleIncreaseQuestionnairePagePriority,
      handleDecreaseQuestionnairePagePriority,
      handleGoToResponses,
      duplicateQuestionnairePage,
      isSubmitting,
    ]);

    return (
      <VStack spacing={4} width={"100%"}>
        <Message status={AlertStatusEnum.INFO}>
          <Box>
            <Box>
              Create and manage your questionnaire forms sent out to new lead
              recipients. Use filters to specify lead types and offices that
              need to match to send your questionnaires to the correct leads.
              Questionnaires will evaluate in the descending order displayed
              below, and you can re-order your questionnaires to change this.
            </Box>
            <Box>
              If two or more questionnaires contain overlapping filters, the
              questionnaire higher up on the below list will trigger, and the
              ones below that will be ignored.
            </Box>
          </Box>
        </Message>
        <TableV2<TQuestionnairePageTableData>
          dataSource={
            accountQuestionnairePagesStore.accountPagesTableDataSortedByPriorityDesc
          }
          columns={columnsDef}
          pageSize={20}
          loadingStatus={loadingStatus}
          globalFilterInputPlaceholder={"Type filter name"}
          striped={true}
          onRowClick={handleOnRowClick}
          additionalActions={
            <Box flexGrow={1}>
              <Button
                variant={"link"}
                colorScheme={"blue"}
                onClick={handleCreateOrEditQuestionnairePage()}
              >
                + Create questionnaire
              </Button>
            </Box>
          }
        />
      </VStack>
    );
  }
);
