import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { BasicUserInfo } from "components/basic-user-info/BasicUserInfo";
import React, { FC, useMemo } from "react";
import { TUserBasicData } from "types/user-data.type";

interface IUserSelectOptionProps
  extends ISelectInputOptionProps<TUserBasicData> {
  condensed?: boolean;
  displayingAsInputValue?: boolean;
}

export const UserSelectOption: FC<IUserSelectOptionProps> = ({
  optionProps: { option, selectedOption, ...rest },
  condensed,
  displayingAsInputValue,
}) => {
  const isSelected = useMemo(
    () => selectedOption?.value?.id === option.value.id,
    [selectedOption, option]
  );
  const isDisabled = option.isDisabled;

  if (!displayingAsInputValue && option.isHidden) return null;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <BasicUserInfo condensed={condensed} user={option.value} />
    </SelectInputOptionBase>
  );
};
