import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { ReapitIntegrationUsersConfigurator } from "./ReapitIntegrationUsersConfigurator/ReapitIntegrationUsersConfigurator";
import { Alert, Error, Loader } from "components";
import { ReapitIntegrationsLeadSourcesConfigurator } from "./ReapitIntegrationsSourcesConfigurator/ReapitIntegrationsLeadSourcesConfigurator";
import { OfficeSelectIntegrationTable } from "../AccountIntegrationConfigurator/OfficeSelectIntegrationTable";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AlertIcon, Box, Divider, Select } from "@chakra-ui/react";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { ReapitApplicantRequirementsConfigurator } from "./ReapitApplicantRequirementsConfigurator";
import { IntegrationEcosystemConfigurator } from "../AccountIntegrationConfigurator/IntegrationEcosystemConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const ReapitIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const [selectedOfficeGroupId, setSelectedOfficeGroupId] = useState<
      number
    >();

    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsReapitStore =
      accountIntegrationsStore.accountIntegrationReapitStore;
    const accountIntegrationOfficesStore =
      accountIntegrationsReapitStore.accountIntegrationReapitOfficesStore;
    const accountIntegrationLeadSourcesStore =
      accountIntegrationsReapitStore.accountIntegrationReapitLeadSourcesStore;
    const accountIntegrationsReapitNegotiatorsStore =
      accountIntegrationsReapitStore.accountIntegrationReapitNegotiatorsStore;
    const accountOfficeGroupsStore = accountStore.accountOfficeGroupsStore;
    const isMultiIntegrationEnabled =
      accountIntegrationsReapitStore.isMultiIntegrationEnabled;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            accountUsersStore.fetchAccountUsers(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
            accountOfficeGroupsStore.fetchAllAccountOfficeGroups(),
          ]);

          if (isMultiIntegrationEnabled) {
            if (!selectedOfficeGroupId) {
              setSelectedOfficeGroupId(
                accountOfficeGroupsStore.accountOfficeGroupsArray[0]?.id
              );
            }
            await Promise.all([
              accountIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations(
                selectedOfficeGroupId ??
                  accountOfficeGroupsStore.accountOfficeGroupsArray[0]?.id
              ),
              accountIntegrationsReapitNegotiatorsStore.fetchAccountReapitNegotiatorsAndRelations(
                selectedOfficeGroupId ??
                  accountOfficeGroupsStore.accountOfficeGroupsArray[0]?.id
              ),
              accountIntegrationLeadSourcesStore.fetchAccountReapitLeadSourcesAndRelations(
                selectedOfficeGroupId ??
                  accountOfficeGroupsStore.accountOfficeGroupsArray[0]?.id
              ),
              accountOfficeGroupsStore.fetchAllAssociatedUserIdsForOfficeGroupId(
                selectedOfficeGroupId ??
                  accountOfficeGroupsStore.accountOfficeGroupsArray[0]?.id
              ),
            ]);
          } else {
            await Promise.all([
              accountIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations(),
              accountIntegrationsReapitNegotiatorsStore.fetchAccountReapitNegotiatorsAndRelations(),
              accountIntegrationLeadSourcesStore.fetchAccountReapitLeadSourcesAndRelations(),
            ]);
          }

          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountIntegrationsReapitStore,
      accountOfficesStore,
      accountUsersStore,
      accountLeadSourcesStore,
      accountOfficeGroupsStore,
      isMultiIntegrationEnabled,
      accountIntegrationOfficesStore,
      accountIntegrationsReapitNegotiatorsStore,
      accountIntegrationLeadSourcesStore,
      selectedOfficeGroupId,
    ]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <ReapitApplicantRequirementsConfigurator
              accountIntegrationsStore={accountIntegrationsStore}
              isMultiIntegrationEnabled={isMultiIntegrationEnabled}
            />
            {isMultiIntegrationEnabled && (
              <Box>
                <Alert status={AlertStatusEnum.INFO} mb={4}>
                  <AlertIcon />
                  <Box>
                    This account has office group level integrations enabled.
                    Please select the office group you would like to configure.
                  </Box>
                </Alert>
                <Select
                  value={selectedOfficeGroupId}
                  onChange={event => {
                    setSelectedOfficeGroupId(Number(event.target.value));
                  }}
                >
                  {accountOfficeGroupsStore.accountOfficeGroupsOptions.map(
                    option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    )
                  )}
                </Select>
              </Box>
            )}
            <OfficeSelectIntegrationTable
              accountIntegrationOfficesStore={accountIntegrationOfficesStore}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.REAPIT}
              selectedOfficeGroupId={selectedOfficeGroupId}
            />
            <ReapitIntegrationUsersConfigurator
              selectedOfficeGroupId={selectedOfficeGroupId}
              accountStore={accountStore}
            />
            <ReapitIntegrationsLeadSourcesConfigurator
              selectedOfficeGroupId={selectedOfficeGroupId}
              accountStore={accountStore}
            />
            <Divider my={8} />
            <IntegrationEcosystemConfigurator
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.REAPIT}
            />
          </>
        )}
      </>
    );
  }
);
