import React, { FC } from "react";
import { observer } from "mobx-react";
import { Box, HStack } from "@chakra-ui/react";
import { LeadResponderEmailFilterSimpleMenu } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadResponderEmailSimpleFilterTypes } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenuItem";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import { LeadResponderEmailState } from "constants/lead-responder-email-status";
import { TLeadResponderEmailFilterMenuItemData } from "types/lead-responder-email.type";

const EmailStatusOptions: TLeadResponderEmailFilterMenuItemData[] = [
  {
    value: LeadResponderEmailState.FAILED,
    label: (
      <HStack spacing={2}>
        <Box>{LeadResponderEmailState.FAILED}</Box>
      </HStack>
    ),
  },
  {
    value: LeadResponderEmailState.IGNORED,
    label: (
      <HStack spacing={2}>
        <Box>{LeadResponderEmailState.IGNORED}</Box>
      </HStack>
    ),
  },
  {
    value: LeadResponderEmailState.PROCESSED,
    label: (
      <HStack spacing={2}>
        <Box>{LeadResponderEmailState.PROCESSED}</Box>
      </HStack>
    ),
  },
  {
    value: LeadResponderEmailState.UNPROCESSABLE,
    label: (
      <HStack spacing={2}>
        <Box>{LeadResponderEmailState.UNPROCESSABLE}</Box>
      </HStack>
    ),
  },
  {
    value: LeadResponderEmailState.RECEIVED,
    label: (
      <HStack spacing={2}>
        <Box>{LeadResponderEmailState.RECEIVED}</Box>
      </HStack>
    ),
  },
];

interface IProps {
  emailFiltersStore: AcccountLeadResponderEmailsFiltersStore;
}

export const FiltersLeadResponderEmailsStatusHeader: FC<IProps> = observer(
  ({ emailFiltersStore }) => {
    const {
      pendingFilter,
      togglePendingFilterByType,
      activeFilter,
    } = emailFiltersStore;

    return (
      <>
        <HStack spacing={2}>
          <Box
            fontSize={"sm"}
            color={"gray.500"}
            fontWeight={500}
            py={2}
            w={"100%"}
          >
            Status
          </Box>
          <LeadResponderEmailFilterSimpleMenu
            optionLabel={"Status"}
            icon={
              <FontAwesomeIcon
                icon={faFilter}
                fontSize={LEAD_FILTER_ICON_SIZE}
              />
            }
            availableOptions={EmailStatusOptions}
            selectedOptions={pendingFilter.statuses}
            appliedOptions={activeFilter?.statuses}
            handleFilterItemToggle={(value: LeadResponderEmailState) => {
              togglePendingFilterByType(
                value,
                LeadResponderEmailSimpleFilterTypes.STATUS
              );
            }}
            filterStore={emailFiltersStore}
          />
        </HStack>
      </>
    );
  }
);
