import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { TOpenViewContactData } from "types/openview.type";
import { IGetOptionPropsData } from "../SelectInputOptionsController";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { openViewNewContactValue } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOpenView/AccountIntegrationOpenViewContacts.store";

interface IOpenViewRelatedContactOptionProps {
  optionProp: IGetOptionPropsData<string, TOpenViewContactData>;
  hoverEffect?: boolean;
}

export const OpenViewRelatedContactsOption: FC<IOpenViewRelatedContactOptionProps> = ({
  optionProp,
  hoverEffect,
}) => {
  const { value, data } = optionProp.option;

  const contactTypesString = data?.contactTypes
    .join(", ")
    .replace(LeadTypeEnum.Sale, "sales applicant")
    .replace(LeadTypeEnum.Let, "lettings applicant");

  return (
    <Box
      key={optionProp.option.value}
      onClick={optionProp.onClick}
      py={1}
      px={2}
      fontSize={"14px"}
      minHeight={"56px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={hoverEffect ? { bg: "blue.50" } : { bg: "transparent" }}
    >
      {value === openViewNewContactValue ? (
        <Box display={"flex"} gap={2}>
          <Box color={"black"}>Create a new contact record</Box>
        </Box>
      ) : (
        <>
          <Box display={"flex"} gap={2}>
            <Box color={"black"}>{data?.fullName}</Box>
            <Box color={"leadpro.500"}>{data?.email},</Box>
            <Box color={"leadpro.500"}>{data?.phoneNumber}</Box>
          </Box>
          <Box display={"inline"} color={"leadpro.400"}>
            {value} | {contactTypesString}
          </Box>
        </>
      )}
    </Box>
  );
};

export default OpenViewRelatedContactsOption;
