import { useClipboard } from "@chakra-ui/react";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StandardIconButton } from "components/standard-icon-button/StandardIconButton";
import React, { FC } from "react";

interface ICopyButtonProps {
  valueToCopy: string;
  ariaLabel: string;
}

const CopyButton: FC<ICopyButtonProps> = ({ valueToCopy, ariaLabel }) => {
  const { onCopy } = useClipboard(valueToCopy);
  return (
    <StandardIconButton
      onClick={onCopy}
      aria-label={ariaLabel}
      fontSize={"16px"}
      color={"leadpro.800"}
      icon={<FontAwesomeIcon icon={faCopy} />}
    />
  );
};

export default CopyButton;
