import { Panel, PanelHeader, PanelTitle, PanelBody } from "components";
import React, { FC, useCallback } from "react";
import { AccountUsersList } from "./components/AccountUsersList";
import { UserFormPrompt } from "routes/dashboard/components/prompts/UserFormPrompt/UserFormPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { PanelsGroupLayout } from "routes/dashboard/components/Layouts/PanelsGroupLayout";
import { Button } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
}

export const Users: FC<IProps> = observer(({ accountStore }) => {
  const { setModal, unSetModal } = useActionPrompt();

  const openInviteUserPrompt = useCallback(() => {
    setModal(
      <UserFormPrompt accountStore={accountStore} closePrompt={unSetModal} />
    );
  }, [setModal, unSetModal, accountStore]);

  return (
    <PanelsGroupLayout>
      <Panel>
        <PanelHeader
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <PanelTitle>Users</PanelTitle>
          <Button
            colorScheme={"blue"}
            variant={"outline"}
            onClick={openInviteUserPrompt}
          >
            Add user
          </Button>
        </PanelHeader>

        <PanelBody>
          <AccountUsersList accountStore={accountStore} />
        </PanelBody>
      </Panel>
    </PanelsGroupLayout>
  );
});
