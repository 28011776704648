import {
  TIntegrationOffice,
  TIntegrationOfficeRelation,
} from "types/integration-sync-office.type";
import {
  TOpenViewContact,
  TOpenViewLeadSource,
  TOpenViewLeadSourceRelation,
  TOpenViewLeadSourceRelationData,
  TOpenViewNegotiator,
  TOpenViewNegotiatorRelation,
  TOpenViewNegotiatorRelationData,
  TOpenViewOffice,
  TOpenViewOfficeRelation,
} from "types/openview.type";
import {
  mapIntegrationOfficeRelationsToOpenViewOfficeRelations,
  mapOpenViewOfficeRelationsToIntegrationOfficeRelations,
  openViewOfficeDataToIntegrationOfficeDataMapper,
} from "utils/openview.utils";
import { BaseApi } from "./base.api";

export class AccountOpenViewAPI extends BaseApi {
  async fetchAccountOpenViewContacts(
    accountId: number,
    leadId: number
  ): Promise<TOpenViewContact[]> {
    return this.get(
      `/accounts/${accountId}/leads/${leadId}/openview/related-contacts`
    );
  }

  async fetchAccountOffices(accountId: number): Promise<TIntegrationOffice[]> {
    const data = await this.get(
      `/accounts/${accountId}/openview/openview-branches`
    );
    const mappedData = openViewOfficeDataToIntegrationOfficeDataMapper(
      data as TOpenViewOffice[]
    );
    return mappedData as TIntegrationOffice[];
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    const data = await this.get(
      `/accounts/${accountId}/openview/branch-relations`
    );
    const mappedData = mapOpenViewOfficeRelationsToIntegrationOfficeRelations(
      data as TOpenViewOfficeRelation[]
    );
    return mappedData as TIntegrationOfficeRelation[];
  }

  async fetchAccountOpenViewNegotiators(
    accountId: number
  ): Promise<TOpenViewNegotiator[]> {
    return this.get(`/accounts/${accountId}/openview/openview-negotiators`);
  }

  async fetchAccountOpenViewNegotiatorRelations(
    accountId: number
  ): Promise<TOpenViewNegotiatorRelation[]> {
    return this.get(`/accounts/${accountId}/openview/negotiator-relations`);
  }

  async fetchAccountOpenViewLeadSources(
    accountId: number
  ): Promise<TOpenViewLeadSource[]> {
    return this.get(`/accounts/${accountId}/openview/openview-sources`);
  }

  async fetchAccountOpenViewLeadSourceRelations(
    accountId: number
  ): Promise<TOpenViewLeadSourceRelation[]> {
    return this.get(`/accounts/${accountId}/openview/source-relations`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelation[]
  ) {
    await this.post(`/accounts/${accountId}/openview/branch-relations`, {
      items: mapIntegrationOfficeRelationsToOpenViewOfficeRelations(
        relationsData
      ),
    });
  }

  async updateAccountOpenViewSyncedNegotiators(
    accountId: number,
    relationsData: TOpenViewNegotiatorRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/openview/negotiator-relations`, {
      items: relationsData,
    });
  }

  async updateAccountOpenViewSyncedLeadSources(
    accountId: number,
    relationsData: TOpenViewLeadSourceRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/openview/source-relations`, {
      items: relationsData,
    });
  }
}
