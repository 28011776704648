import { IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import React, { useCallback } from "react";
import { leadSubrouteToStage } from "utils/lead-stage.utils";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { useParams } from "react-router-dom";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const ResetLeadFilters: React.FC<IProps> = ({ leadsFilterStore }) => {
  let { leadSubroute } = useParams<{ leadSubroute: string }>();

  const {
    resetPendingFilter,
    upsertPendingFilter,
    setActiveFromPendingFilter,
  } = leadsFilterStore;

  const handleResetFilters = useCallback(() => {
    const stage = leadSubrouteToStage(leadSubroute);

    resetPendingFilter();
    upsertPendingFilter({
      stages: !!stage ? [stage] : [],
    });
    setActiveFromPendingFilter();
  }, [
    leadSubroute,
    resetPendingFilter,
    upsertPendingFilter,
    setActiveFromPendingFilter,
  ]);

  return (
    <IconButton
      aria-label={"reset-lead-filters"}
      title={"Reset filters"}
      variant={"ghost"}
      size={"sm"}
      icon={<FontAwesomeIcon icon={faArrowRotateLeft} />}
      onClick={handleResetFilters}
    />
  );
};
