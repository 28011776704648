import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Circle, HStack } from "@chakra-ui/react";
import { LeadFilterSimpleMenu } from "./LeadFilterMenu/LeadFilterSimpleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { typesColors } from "constants/colors";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { sortBy } from "lodash";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const FilterLeadsTypeHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const {
      pendingFilter,
      activeFilter,
      availableFilters,
      togglePendingFilterType,
    } = leadsFilterStore;

    const typesOptions = useMemo(() => {
      if (!availableFilters || !availableFilters.types) return [];

      return sortBy(availableFilters.types, type =>
        getLeadTypeLabel(type.id)
      ).map(type => ({
        value: type.id,
        label: (
          <HStack spacing={2}>
            <Circle
              size={"10px"}
              background={typesColors[type.id]?.statsColor}
            />
            <Box>{getLeadTypeLabel(type.id)}</Box>
          </HStack>
        ),
        count: type.count,
      }));
    }, [availableFilters]);

    return (
      <LeadFilterHeaderCell title={"Type"}>
        <LeadFilterSimpleMenu
          optionLabel={"Type"}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterType={LeadSimpleFilterTypes.TYPES}
          availableOptions={typesOptions}
          selectedOptions={pendingFilter.types}
          appliedOptions={activeFilter?.types}
          handleFilterItemToggle={(value: LeadTypeEnum) =>
            togglePendingFilterType(value)
          }
          leadsFilterStore={leadsFilterStore}
        />
      </LeadFilterHeaderCell>
    );
  }
);
