import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { TRegisteredCompanyData } from "types/account.type";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";

export const RegisteredCompanySelectOption: React.FC<ISelectInputOptionProps<
  TRegisteredCompanyData
>> = ({ optionProps: { option, selectedOption, ...rest } }) => {
  const isSelected = useMemo(
    () =>
      selectedOption?.value?.companyRegistrationNumber ===
      option.value.companyRegistrationNumber,
    [selectedOption, option]
  );
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
      width={"100%"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"flex-start"}
          overflow={"hidden"}
        >
          <Box fontWeight={500} noOfLines={1}>
            {option.value.companyName}
          </Box>
          <Box pl={2} color={"gray.400"}>
            ({option.value.companyRegistrationNumber})
          </Box>
        </Box>
        <Box fontSize={"xs"} noOfLines={1} width={"100%"}>
          {option.value.companyAddress}
        </Box>
      </Box>
    </SelectInputOptionBase>
  );
};
