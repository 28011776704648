import React, { useEffect, useState, useMemo } from "react";
import { AspectRatio, Box, Image } from "@chakra-ui/react";
import { Loader } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const DEFAULT_ASPECT_RATIO = 4 / 3;

interface IThumbProps {
  file?: File;
  src?: string;
}

export const Thumb: React.FC<IThumbProps> = ({ file, src }) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState<string | null>();

  const content = useMemo(() => {
    if (loading)
      return (
        <Box
          position={"relative"}
          display={"flex"}
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
        >
          <Loader />
        </Box>
      );

    if (!!file) {
      return (
        <Image
          src={thumb || undefined}
          alt={file.name}
          width={"100%"}
          height={"100%"}
          objectFit="cover"
        />
      );
    }

    if (!!src) {
      return (
        <Image
          src={src}
          alt={src}
          width={"100%"}
          height={"100%"}
          objectFit="cover"
        />
      );
    }

    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
        background={"gray.50"}
        color={"gray.400"}
      >
        <FontAwesomeIcon icon={faPlus} fontSize={"20%"} />
      </Box>
    );
  }, [file, loading, src, thumb]);

  useEffect(() => {
    if (!file) return;

    setLoading(true);
    const reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result?.toString());
    };

    reader.readAsDataURL(file);
  }, [file]);

  return <AspectRatio ratio={DEFAULT_ASPECT_RATIO}>{content}</AspectRatio>;
};
