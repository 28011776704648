import { AccountOpenViewAPI } from "api/account-openview-api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TOpenViewNegotiator,
  TOpenViewSyncNegotiatorExtendedData,
  TOpenViewNegotiatorRelation,
  TOpenViewNegotiatorRelationData,
} from "types/openview.type";
import { getUserFullName } from "utils/account-user.utils";

const accountOpenViewApi = new AccountOpenViewAPI();

export class AccountIntegrationOpenViewNegotiatorsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountOpenViewNegotiators: IObservableArray<
    TOpenViewNegotiator
  >;
  private readonly accountOpenViewNegotiatorRelations: IObservableArray<
    TOpenViewNegotiatorRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountOpenViewNegotiators = observable.array<TOpenViewNegotiator>();
    this.accountOpenViewNegotiatorRelations = observable.array<
      TOpenViewNegotiatorRelation
    >();
    this.syncStatusFilter = false;
  }

  get openViewNegotiatorRelationsMapByLeadProUserAccountId() {
    return keyBy(
      this.accountOpenViewNegotiatorRelations,
      relation => relation.userAccountId
    );
  }

  get openViewNegotiatorRelationsMapByOpenViewNegotiatorId() {
    return keyBy(
      this.accountOpenViewNegotiatorRelations,
      relation => relation.openViewNegotiatorId
    );
  }

  get availableOpenViewNegotiators() {
    const usedOpenViewNegotiatorsOpenViewNegotiatorIds = this.openViewSyncNegotiatorData.map(
      negotiator => negotiator.openViewNegotiatorId
    );
    return this.accountOpenViewNegotiators.filter(
      openViewNegotiator =>
        !usedOpenViewNegotiatorsOpenViewNegotiatorIds.includes(
          openViewNegotiator.userid
        )
    );
  }

  get availableOpenViewNegotiatorsMapByOpenViewNegotiatorEmail() {
    return keyBy(
      this.availableOpenViewNegotiators,
      negotiator => negotiator.email
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredOpenViewSyncNegotiatorData(): TOpenViewSyncNegotiatorExtendedData[] {
    return this.openViewSyncNegotiatorData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get openViewSyncNegotiatorData(): TOpenViewSyncNegotiatorExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const accountUsersStore = accountStore.accountUsersStore;
    const leadproAccountUsersMap = accountUsersStore.accountUsersMap;
    const leadproAccountUsers = accountUsersStore.accountUsersArray;
    const openViewNegotiators = this.accountOpenViewNegotiators;

    const openViewNegotiatorRelationsMapByLeadProUserAccountId = this
      .openViewNegotiatorRelationsMapByLeadProUserAccountId;
    const openViewNegotiatorRelationsMapByOpenViewNegotiatorId = this
      .openViewNegotiatorRelationsMapByOpenViewNegotiatorId;

    return leadproAccountUsers.map(accountUser => {
      const negotiatorRelation =
        openViewNegotiatorRelationsMapByLeadProUserAccountId[
          accountUser.userAccount.id
        ];
      let pairedOpenViewNegotiatorId = negotiatorRelation?.openViewNegotiatorId;
      let pairedOpenViewNegotiatorEmail =
        negotiatorRelation?.openViewNegotiatorEmail;
      let pairedOpenViewNegotiatorName =
        negotiatorRelation?.openViewNegotiatorName;
      let syncStatus = !!pairedOpenViewNegotiatorId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      // try suggesting a match
      if (!pairedOpenViewNegotiatorId) {
        const suggestedMatchIndex = openViewNegotiators.findIndex(
          openViewNegotiator => {
            return (
              openViewNegotiator.email ===
                leadproAccountUsersMap[accountUser.id].email &&
              !openViewNegotiatorRelationsMapByOpenViewNegotiatorId[
                openViewNegotiator.userid
              ]
            );
          }
        );
        if (suggestedMatchIndex > -1) {
          pairedOpenViewNegotiatorId =
            openViewNegotiators[suggestedMatchIndex].userid;
          openViewNegotiators.splice(suggestedMatchIndex, 1);
          syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
        }
      }

      return {
        userAccountId: accountUser.userAccount.id,
        user: accountUser,
        userFullName: getUserFullName(
          accountUser.firstName,
          accountUser.lastName,
          accountUser.email
        )!,
        openViewNegotiatorId: pairedOpenViewNegotiatorId,
        openViewNegotiatorEmail: pairedOpenViewNegotiatorEmail,
        openViewNegotiatorName: pairedOpenViewNegotiatorName,
        syncStatus: syncStatus,
      };
    });
  }

  get openViewNegotiatorsOptions() {
    const openViewNegotiators = this.accountOpenViewNegotiators;

    return openViewNegotiators.map(openViewNegotiator => {
      return {
        value: openViewNegotiator.userid,
        label: openViewNegotiator.name,
        data: this.openViewNegotiatorRelationsMapByOpenViewNegotiatorId[
          openViewNegotiator.userid
        ] as TOpenViewNegotiatorRelation,
      };
    });
  }

  public setAccountOpenViewNegotiators(negotiators: TOpenViewNegotiator[]) {
    this.accountOpenViewNegotiators.replace(negotiators);
  }

  public setAccountOpenViewNegotiatorRelations(
    relations: TOpenViewNegotiatorRelation[]
  ) {
    this.accountOpenViewNegotiatorRelations.replace(relations);
  }
  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }
  public async fetchOpenViewNegotiators() {
    const data = await accountOpenViewApi.fetchAccountOpenViewNegotiators(
      this.accountId
    );
    this.setAccountOpenViewNegotiators(data);
  }

  public async fetchOpenViewNegotiatorRelations() {
    const data = await accountOpenViewApi.fetchAccountOpenViewNegotiatorRelations(
      this.accountId
    );
    this.setAccountOpenViewNegotiatorRelations(data);
  }

  public async fetchAccountOpenViewNegotiatorsAndRelations() {
    await Promise.all([
      this.fetchOpenViewNegotiators(),
      this.fetchOpenViewNegotiatorRelations(),
    ]);
  }

  public async updateAccountOpenViewSyncedNegotiators(
    syncedPairs: TOpenViewNegotiatorRelationData[]
  ) {
    await accountOpenViewApi.updateAccountOpenViewSyncedNegotiators(
      this.accountId,
      syncedPairs.map(pair => {
        const negotiatorRelation = this.accountOpenViewNegotiators.filter(
          negotiator => negotiator.userid === pair.openViewNegotiatorId
        )[0];

        return {
          userAccountId: pair.userAccountId,
          openViewNegotiatorId: pair.openViewNegotiatorId,
          openViewNegotiatorName: negotiatorRelation?.name ?? "",
          openViewNegotiatorEmail: negotiatorRelation?.email ?? "",
        };
      })
    );
    await this.fetchAccountOpenViewNegotiatorsAndRelations();
  }
}
