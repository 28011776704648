import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { ToastId, useToast } from "@chakra-ui/react";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { getUserFullName } from "utils/account-user.utils";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { TLeadProAuthTokenDTO } from "types/token.type";
import { LOGIN_ROUTE } from "constants/routes";

export const AuthRoute: FC<RouteProps> = observer(({ children, ...rest }) => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId>("");
  const [currentUserStatus, setCurrentUserStatus] = useState<
    ApiRequestStatusEnum
  >(ApiRequestStatusEnum.NONE);

  const {
    authStore: { initUser, isFullyLoggedIn, authUser, authTokenPayload },
  } = useAppStore();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setCurrentUserStatus(ApiRequestStatusEnum.PENDING);
        await initUser();
        setCurrentUserStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setCurrentUserStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchUser();
  }, [initUser]);

  useEffect(() => {
    if (
      (authTokenPayload as TLeadProAuthTokenDTO)?.isImpersonating &&
      !!authUser
    ) {
      const toastOptions = {
        title: "Impersonating user",
        description: `you are logged in as ${getUserFullName(
          authUser.firstName,
          authUser.lastName,
          authUser.email
        )}`,
        status: AlertStatusEnum.WARNING,
        variant: "subtle",
        duration: null,
        isClosable: true,
        onCloseComplete: () => {
          toastIdRef.current = "";
        },
      };

      if (!!toastIdRef.current) {
        toast.update(toastIdRef.current, toastOptions);
      } else {
        toastIdRef.current = toast(toastOptions) || "";
      }
    }
  }, [authUser, toast, authTokenPayload]);

  const element = useMemo(() => {
    if (
      currentUserStatus === ApiRequestStatusEnum.NONE ||
      currentUserStatus === ApiRequestStatusEnum.PENDING
    ) {
      return <Loader />;
    }

    if (!isFullyLoggedIn) {
      return <Redirect to={LOGIN_ROUTE} />;
    }

    if (currentUserStatus === ApiRequestStatusEnum.ERROR) {
      return <Error />;
    }

    if (currentUserStatus === ApiRequestStatusEnum.SUCCESS) {
      return <>{children}</>;
    }

    return null;
  }, [children, currentUserStatus, isFullyLoggedIn]);

  return <Route {...rest}>{element}</Route>;
});
