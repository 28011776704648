import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

let stripePromise: Promise<Stripe | null> | null = null;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");
  }

  return stripePromise;
};

export const withStripe = (children: React.ReactNode) => {
  return <Elements stripe={getStripe()}>{children}</Elements>;
};
