import { Box, Button, useToast } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { ApiMessageStack } from "components";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { observer } from "mobx-react";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";

interface IProps {
  leadsStore: AccountLeadsStore;
  selectedRowIds: number[];
  setIsSubmitting: (isSubmitting: boolean) => void;
  leadStatusesStore: AccountLeadStatusesStore;
}

export const BulkUpdateDeleteLeads: FC<IProps> = observer(
  ({ leadsStore, selectedRowIds, setIsSubmitting, leadStatusesStore }) => {
    const toast = useToast();
    const { deletedStatus } = leadStatusesStore;

    const handleDeleteLeads = useCallback(async () => {
      if (!deletedStatus)
        return toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "Could not delete selected leads, please try again."
              }
            />
          ),
        });
      try {
        setIsSubmitting(true);
        await leadsStore.updateLeads(selectedRowIds, {
          statusId: deletedStatus.id,
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [leadsStore, selectedRowIds, toast, setIsSubmitting, deletedStatus]);

    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={4}
        py={2}
      >
        <Box color={"gray.500"}>Are you sure?</Box>
        <Button colorScheme={"red"} onClick={handleDeleteLeads}>
          Confirm
        </Button>
      </Box>
    );
  }
);
