import { Input } from "..";
import React, { ChangeEvent, useCallback } from "react";
import { InputGroup, InputRightAddon, InputProps } from "@chakra-ui/react";
import { ColorPickerPopup } from "./ColorPickerPopup";

interface IColorInputProps extends Omit<InputProps, "onChange"> {
  value: string;
  onChange: (newColor: string) => void;
}

export const ColorInput: React.FC<IColorInputProps> = ({
  value,
  onChange,
  isDisabled,
  ...rest
}) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <InputGroup>
      <Input
        value={value}
        onChange={handleOnChange}
        isDisabled={isDisabled}
        borderRightRadius={0}
        {...rest}
      />
      <InputRightAddon
        height={"42px"}
        p={0}
        children={
          <ColorPickerPopup
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        }
      />
    </InputGroup>
  );
};
