import { LeadQuestionnaireAnsweredEnum } from "enums/lead-questionnaire-answered.enum";
import { TAvailableLeadsFilterData } from "types/leads-filter.type";

export const questionnaireAnsweredEnumToBool = (
  questionnaireAnswered?: LeadQuestionnaireAnsweredEnum[]
) => {
  return questionnaireAnswered?.map(status => {
    if (status === LeadQuestionnaireAnsweredEnum.ANSWERED) return true;
    if (status === LeadQuestionnaireAnsweredEnum.NOT_ANSWERED) return false;
    return undefined;
  });
};

export const questionnaireAnsweredBoolToEnum = (
  filter: TAvailableLeadsFilterData<boolean>[]
) => {
  return filter.map((entry: TAvailableLeadsFilterData<boolean>) => {
    return {
      ...entry,
      id: entry.id
        ? LeadQuestionnaireAnsweredEnum.ANSWERED
        : LeadQuestionnaireAnsweredEnum.NOT_ANSWERED,
    };
  });
};
