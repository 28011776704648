import { ApiMessageStack, FormControlV2, FormControlLabel } from "components";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Heading, SlideFade, useToast } from "@chakra-ui/react";
import { TUserAccountData } from "types/user-account.type";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  workingHoursValidationSchema,
  TWorkingHoursFieldValues,
} from "utils/validation-schemas/office-working-hours.validation";
import { AccountSetupStepEnum } from "enums/account-setup-step.enum";
import { AccountLogoInput } from "routes/dashboard/components";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { HEX_COLOR_REGEX } from "utils/regexs.utils";
import { INVALID_HEX_COLOR } from "constants/validator-messages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = Pick<TUserAccountData, "primaryColor" | "website"> &
  TWorkingHoursFieldValues;

const validationSchema = Yup.object()
  .shape({
    primaryColor: Yup.string().matches(HEX_COLOR_REGEX, INVALID_HEX_COLOR),
    website: Yup.string().url(),
  })
  .concat(workingHoursValidationSchema);

export const AccountBusinessInfoStep = observer(() => {
  const toast = useToast();
  const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(false);
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();

  const initialValues = useMemo(
    () => ({
      primaryColor: selectedAccountStore?.account.primaryColor || "",
      website: selectedAccountStore?.account.website || "https://",
    }),
    [selectedAccountStore]
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ primaryColor, website }: TFieldValues) => {
    if (!!selectedAccountStore) {
      try {
        await selectedAccountStore.update({
          primaryColor: primaryColor || null,
          website: website || null,
          setupStepsCompleted: [
            ...selectedAccountStore.account.setupStepsCompleted,
            AccountSetupStepEnum.BUSINESS_INFO,
          ],
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }
  };

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  return (
    <SlideFade in={true}>
      {!!selectedAccountStore && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box textAlign="center" mb={10}>
            <Heading fontSize="3xl" fontWeight={"500"} lineHeight="none">
              Your business
            </Heading>
          </Box>
          <Box mb={10}>
            <FormControlLabel>Company logo</FormControlLabel>
            <AccountLogoInput
              accountStore={selectedAccountStore}
              onUploadStart={() => setIsUploadingLogo(true)}
              onUploadComplete={() => setIsUploadingLogo(false)}
            />
          </Box>
          <Box mb={10}>
            <FormControlV2<TFieldValues>
              name={"primaryColor"}
              control={control}
              label={"Primary color"}
              description={
                "Primary color used as an accent color throughout the valuation tool interface."
              }
              type={FormControlsTypeEnum.COLOR_INPUT}
              additionalProps={{
                placeholder: "#000000",
              }}
            />
          </Box>
          <Box mb={10}>
            <FormControlV2<TFieldValues>
              name={"website"}
              control={control}
              label={"Business website URL"}
              description={
                "Your business website address that will be used by LeadPro tools"
              }
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "https://www.your-website.com",
              }}
            />
          </Box>
          <Button
            type={"submit"}
            width={"full"}
            colorScheme={"blue"}
            size={"md"}
            isDisabled={isSubmitting || isUploadingLogo}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </form>
      )}
    </SlideFade>
  );
});
