import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { TEmailTemplate } from "types/email-template.type";
import { EmailTemplatesApi } from "api/email-templates.api";
import { WIPEmailTemplateStore } from "./WIPEmailTemplate.store";
import { keyBy } from "lodash";
import { TSelectOption } from "types/select-input.type";
import { SuggestionDataItem } from "react-mentions";
import {
  TTemplateMergeTag,
  TTemplateMergeTagGroup,
} from "types/template-merge-tag.type";
import { AppStore } from "store/App.store";
import {
  replaceAccountValuesWithUIMergeTags,
  UI_MERGE_TAGS,
} from "utils/template.utils";

const emailTemplatesApi = new EmailTemplatesApi();

export class AccountEmailTemplatesStore {
  private root: AppStore;
  public readonly accountId: number;
  private readonly emailTemplates: IObservableArray<TEmailTemplate>;
  private readonly mergeTagGroups: IObservableArray<TTemplateMergeTagGroup>;
  public wipEmailTemplateStore: WIPEmailTemplateStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.emailTemplates = observable.array<TEmailTemplate>();
    this.mergeTagGroups = observable.array<TTemplateMergeTagGroup>();
    this.wipEmailTemplateStore = new WIPEmailTemplateStore(root, accountId);
  }

  get emailTemplatesArray() {
    return this.emailTemplates.slice();
  }

  get emailTemplatesMap() {
    return keyBy(this.emailTemplatesArray, emailTemplate => emailTemplate.id);
  }

  get emailTemplatesAsOptions(): TSelectOption<number>[] {
    return this.emailTemplatesArray.map(emailTemplate => ({
      value: emailTemplate.id,
      label: emailTemplate.name,
    }));
  }

  get mergeTagGroupsArray() {
    // need to toJS it, otherwise topol template editor crashes
    return toJS(this.mergeTagGroups);
  }

  get mergeTagGroupOptionsArray(): SuggestionDataItem[] {
    const options: SuggestionDataItem[] = [];
    this.mergeTagGroups.forEach(group => {
      group.items.forEach(tag => {
        options.push({
          id: tag.value,
          display: group.name + " - " + tag.text,
        });
      });
    });

    return options;
  }

  get mergeTagsFlatArray() {
    const mergeTags: TTemplateMergeTag[] = [];
    this.mergeTagGroups.forEach(mergeTagGroup =>
      mergeTags.push(...mergeTagGroup.items)
    );

    return mergeTags;
  }

  get uiMergeTagsFlatArray() {
    return this.mergeTagsFlatArray.filter(mergeTag =>
      UI_MERGE_TAGS.includes(mergeTag.value)
    );
  }

  get uiMergeTagsMap() {
    return keyBy(this.uiMergeTagsFlatArray, mergeTag => mergeTag.value);
  }

  private setEmailTemplates(emailTemplates: TEmailTemplate[]) {
    this.emailTemplates.replace(emailTemplates);
  }

  private setMergeTagGroups(mergeTagGroups: TTemplateMergeTagGroup[]) {
    this.mergeTagGroups.replace(mergeTagGroups);
  }

  private upsertEmailTemplate(emailTemplateData: TEmailTemplate) {
    const index = this.emailTemplates.findIndex(
      emailTemplate => emailTemplate.id === emailTemplateData.id
    );
    if (index > -1) {
      this.emailTemplates[index] = {
        ...this.emailTemplates[index],
        ...emailTemplateData,
      };
    } else {
      this.emailTemplates.push(emailTemplateData);
    }
  }

  public deleteEmailTemplate(templateId: number) {
    const index = this.emailTemplates.findIndex(
      template => template.id === templateId
    );
    if (index > -1) {
      this.emailTemplates.splice(index, 1);
    }
  }

  public async loadEmailTemplates() {
    const emailTemplates = await emailTemplatesApi.fetchAll(this.accountId);
    this.setEmailTemplates(emailTemplates);
  }

  public async loadEmailTemplate(templateId: number, setAsWIP?: boolean) {
    const emailTemplate = await emailTemplatesApi.fetchOne(
      this.accountId,
      templateId
    );
    this.upsertEmailTemplate(emailTemplate);

    if (setAsWIP) {
      this.wipEmailTemplateStore.setWIPTemplate(emailTemplate);
    }
  }

  public async createEmailTemplate(
    emailTemplate: Partial<TEmailTemplate>,
    setAsWIP?: boolean
  ) {
    const createdTemplate = await emailTemplatesApi.create(
      this.accountId,
      emailTemplate
    );
    this.upsertEmailTemplate(createdTemplate);

    if (setAsWIP) {
      this.wipEmailTemplateStore.setWIPTemplate(createdTemplate);
    }
  }

  public async updateEmailTemplate(
    templateId: number,
    emailTemplate: Partial<TEmailTemplate>,
    setAsWIP?: boolean
  ) {
    const updatedTemplate = await emailTemplatesApi.update(
      this.accountId,
      templateId,
      emailTemplate
    );
    this.upsertEmailTemplate(updatedTemplate);

    if (setAsWIP) {
      this.wipEmailTemplateStore.setWIPTemplate(updatedTemplate);
    }
  }

  public async persistWipTemplateData(design: object) {
    const designWithMergeTags = replaceAccountValuesWithUIMergeTags(
      design,
      this.uiMergeTagsFlatArray
    );
    const data = {
      ...this.wipEmailTemplateStore.emailTemplateData,
      design: designWithMergeTags,
    };
    if (!!data.id) {
      await this.updateEmailTemplate(
        data.id,
        {
          name: data.name,
          subject: data.subject,
          design: data.design,
        },
        true
      );
    } else {
      await this.createEmailTemplate(data, true);
    }
  }

  public async removeEmailTemplate(templateId: number) {
    await emailTemplatesApi.delete(this.accountId, templateId);
    this.deleteEmailTemplate(templateId);
  }

  public async loadEmailTemplateMergeTags() {
    const mergeTagGroups = await emailTemplatesApi.fetchMergeTags(
      this.accountId
    );
    this.setMergeTagGroups(mergeTagGroups);
  }

  public async sendTestEmail(templateId: number, email: string) {
    await emailTemplatesApi.sendTestEmail(this.accountId, templateId, email);
  }
}
