import React, { FC, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { AccountIntegrationList } from "./components/AccountIntegrationList/AccountIntegrationList";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router-dom";
import {
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_INTEGRATION_DETAILS_ROUTE,
} from "constants/routes";
import { AccountIntegrationSettingsPage } from "./routes/integration-settings/AccountIntegrationSettingsPage";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountIntegrations: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const accountIntegrationsStore = accountStore.accountIntegrationsStore;

  // fetching pages
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await Promise.all([
          accountIntegrationsStore.fetchAvailableAccountIntegrations(),
          accountIntegrationsStore.fetchAccountIntegrations(),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [accountIntegrationsStore]);

  return (
    <Box
      position={"relative"}
      overflow={"hidden"}
      height={"100%"}
      width={"100%"}
      overflowY={"hidden"}
    >
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <Switch>
          <Route path={SETTINGS_INTEGRATIONS_ROUTE} exact>
            <AccountIntegrationList
              accountIntegrationsStore={accountIntegrationsStore}
            />
          </Route>
          <Route path={SETTINGS_INTEGRATION_DETAILS_ROUTE} exact>
            <AccountIntegrationSettingsPage accountStore={accountStore} />
          </Route>
        </Switch>
      )}
    </Box>
  );
});
