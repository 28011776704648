import { Box, Center, Spinner, SpinnerProps } from "@chakra-ui/react";
import { FC } from "react";

interface IProps {
  size?: SpinnerProps["size"];
}

export const Loader: FC<IProps> = ({ size = "xl" }) => {
  return (
    <Center
      position={"absolute"}
      top={0}
      left={0}
      height={"100%"}
      width={"100%"}
      marginTop={"0 !important"}
    >
      <Box
        width={"100%"}
        height={"100%"}
        background={"white"}
        opacity={0.5}
        position={"absolute"}
        top={0}
        left={0}
      />
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size={size}
      />
    </Center>
  );
};
