import {
  ApiMessageStack,
  FormControlV2,
  FormControlDescription,
  FormControlMeta,
  Typography,
} from "components";
import React, { MutableRefObject, useEffect, useMemo } from "react";
import {
  Box,
  Divider,
  HStack,
  Portal,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { OfficeDetailsStatic } from "./OfficeDetailsStatic";
import { OfficeNotificationsInput } from "./OfficeNotificationsInput";
import {
  getDataFromFormValues,
  getFormValuesFromData,
  TWorkingHoursFieldValues,
  workingHoursValidationSchema,
} from "utils/validation-schemas/office-working-hours.validation";
import { officeDetailsValidationSchema } from "utils/validation-schemas/office-details.validation";
import { OfficeWorkingHoursForm } from "./OfficeWorkingHoursForm";
import { OfficeLogoUploadForm } from "./OfficeLogoUploadForm";
import { FormControlError, FormFooter } from "components/form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { observer } from "mobx-react";
import { FormInputValueCounter } from "components/form/FormInputCounter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

interface IProps {
  accountStore: UserAccountStore;
  officeStore: AccountOfficeStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

type TFieldValues = TWorkingHoursFieldValues & {
  postcodes: string[];
  name: string;
  website: string | null;
  ignoreEmailRouting: boolean;
  isSales: boolean;
  isLettings: boolean;
  isMortgage: boolean;
  salesEmails: string[];
  lettingsEmails: string[];
  vendorEmails: string[];
  landlordEmails: string[];
  salesPhoneNumber: string;
  lettingsPhoneNumber: string;
  vendorPhoneNumber: string;
  landlordPhoneNumber: string;
  mortgagePhoneNumber: string;
};

export const OfficeDetails: React.FC<IProps> = observer(
  ({ accountStore, officeStore, containerRef }) => {
    const toast = useToast();
    const officeData = officeStore.office;
    const accountData = accountStore.account;

    const initialValues: TFieldValues = useMemo(
      () => ({
        // added trims because of legacy postcodes which allowed spaces around postcode
        name: officeData.name,
        website: officeData.website || "",
        ignoreEmailRouting: officeData.ignoreEmailRouting,
        postcodes:
          officeData.postcodes?.split(",").map(postcode => postcode.trim()) ||
          [],
        isSales: officeData.isSales,
        isLettings: officeData.isLettings,
        isMortgage: officeData.isMortgage,
        salesEmails: officeData.salesEmails?.split(",") || [],
        lettingsEmails: officeData.lettingsEmails?.split(",") || [],
        vendorEmails: officeData.vendorEmails?.split(",") || [],
        landlordEmails: officeData.landlordEmails?.split(",") || [],
        salesPhoneNumber: officeData.salesPhoneNumber || "",
        lettingsPhoneNumber: officeData.lettingsPhoneNumber || "",
        vendorPhoneNumber: officeData.vendorPhoneNumber || "",
        landlordPhoneNumber: officeData.landlordPhoneNumber || "",
        mortgagePhoneNumber: officeData.mortgagePhoneNumber || "",
        ...getFormValuesFromData(
          officeData.workingHours || accountData.workingHours
        ),
      }),
      [officeData, accountData]
    );

    const {
      handleSubmit,
      control,
      reset,
      watch,
      formState: { isSubmitting, isDirty, errors },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(
        officeDetailsValidationSchema.concat(workingHoursValidationSchema)
      ),
    });

    const watchPostcodes = watch("postcodes");

    const onSubmit = async ({
      postcodes,
      isSales,
      name,
      website,
      ignoreEmailRouting,
      isLettings,
      isMortgage,
      salesEmails,
      lettingsEmails,
      vendorEmails,
      landlordEmails,
      salesPhoneNumber,
      lettingsPhoneNumber,
      vendorPhoneNumber,
      landlordPhoneNumber,
      mortgagePhoneNumber,
      ...workingHours
    }: TFieldValues) => {
      try {
        const isOfficeNameUpdated = name !== officeData.name;
        await officeStore.update({
          postcodes: postcodes.join(","),
          ignoreEmailRouting,
          isSales,
          name,
          website: website || null,
          isLettings,
          isMortgage,
          salesEmails: salesEmails.join(","),
          lettingsEmails: lettingsEmails.join(","),
          vendorEmails: vendorEmails.join(","),
          landlordEmails: landlordEmails.join(","),
          salesPhoneNumber: salesPhoneNumber,
          lettingsPhoneNumber: lettingsPhoneNumber,
          vendorPhoneNumber: vendorPhoneNumber,
          landlordPhoneNumber: landlordPhoneNumber,
          mortgagePhoneNumber: mortgagePhoneNumber,
          workingHours: getDataFromFormValues(workingHours),
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={`Office updated! ${
                isOfficeNameUpdated
                  ? "Your office name is updated, but it might take up to 24 hours for the updated name to appear on all parts of your Dashboard."
                  : ""
              }`}
            />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={10} align={"stretch"} divider={<Divider />}>
            <OfficeDetailsStatic office={officeData} />
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Office Name"
                description={"This is the name for your office."}
              />
              <FormControlV2<TFieldValues>
                name={"name"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter the name of your account",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Office website"
                description={
                  "Your office website address that will be used by LeadPro tools"
                }
              />
              <FormControlV2<TFieldValues>
                name={"website"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "https://www.your-website.com",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Postcode coverage"
                description={
                  "Postcodes are used to assign valuation leads to an office."
                }
              />
              <Box>
                <FormControlV2<TFieldValues>
                  name={"postcodes"}
                  control={control}
                  type={FormControlsTypeEnum.TAG_INPUT}
                  additionalProps={{
                    placeholder: "Enter postcode and press enter or comma",
                    clearable: true,
                  }}
                />
                <FormInputValueCounter
                  name={"postcode"}
                  length={watchPostcodes.length}
                />
                <FormControlDescription>
                  Separate each postcode with a comma.
                </FormControlDescription>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Type of business"}
                description={
                  "Specify whether this office handles sales and / or lettings leads."
                }
              />
              <VStack spacing={3} align={"stretch"}>
                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                  <FormControlV2<TFieldValues>
                    name={"isSales"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                    showError={false}
                  />
                  <Box ml={2}>Sales</Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                  <FormControlV2<TFieldValues>
                    name={"isLettings"}
                    control={control}
                    showError={false}
                    type={FormControlsTypeEnum.SWITCH}
                  />
                  <Box ml={2}>Lettings</Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                  <FormControlV2<TFieldValues>
                    name={"isMortgage"}
                    control={control}
                    showError={false}
                    type={FormControlsTypeEnum.SWITCH}
                  />
                  <Box ml={2}>Mortgage</Box>
                </Box>
                {!!errors["isMortgage"] && (
                  <FormControlError error={errors["isMortgage"]} />
                )}
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Email notifications"}
                description={
                  <Typography>
                    <Box as={"p"}>
                      Turning on email notifications will send new email
                      notifications for new leads to the specified email
                      addresses.
                    </Box>
                    <Box as={"p"}>
                      If you want to use multiple email addresses to receive
                      email notifications, the email responses will only use the
                      first email address from the list.
                    </Box>
                  </Typography>
                }
              />
              <VStack spacing={6} align={"stretch"}>
                <OfficeNotificationsInput<TFieldValues>
                  emailFieldName={"salesEmails"}
                  control={control}
                  label={"Sales leads"}
                  multiple={true}
                  description={"Separate each email with a comma."}
                />
                <OfficeNotificationsInput<TFieldValues>
                  emailFieldName={"lettingsEmails"}
                  control={control}
                  label={"Lettings leads"}
                  multiple={true}
                  description={"Separate each email with a comma."}
                />

                <OfficeNotificationsInput<TFieldValues>
                  emailFieldName={"vendorEmails"}
                  control={control}
                  label={"Vendor leads"}
                  multiple={true}
                  description={"Separate each email with a comma."}
                />

                <OfficeNotificationsInput<TFieldValues>
                  emailFieldName={"landlordEmails"}
                  control={control}
                  label={"Landlord leads"}
                  multiple={true}
                  description={"Separate each email with a comma."}
                />
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Contact phone number"}
                description={
                  "Phone numbers added here will show up in email templates."
                }
              />
              <VStack spacing={6} align={"stretch"}>
                <FormControlV2<TFieldValues>
                  label={"Sales phone number"}
                  name={"salesPhoneNumber"}
                  control={control}
                  type={FormControlsTypeEnum.TEL}
                  additionalProps={{
                    placeholder: "Enter sales phone number",
                  }}
                />
                <FormControlV2<TFieldValues>
                  label={"Lettings phone number"}
                  name={"lettingsPhoneNumber"}
                  control={control}
                  type={FormControlsTypeEnum.TEL}
                  additionalProps={{
                    placeholder: "Enter lettings phone number",
                  }}
                />
                <FormControlV2<TFieldValues>
                  label={"Vendor phone number"}
                  name={"vendorPhoneNumber"}
                  control={control}
                  type={FormControlsTypeEnum.TEL}
                  additionalProps={{
                    placeholder: "Enter vendor phone number",
                  }}
                />
                <FormControlV2<TFieldValues>
                  label={"Landlord phone number"}
                  name={"landlordPhoneNumber"}
                  control={control}
                  type={FormControlsTypeEnum.TEL}
                  additionalProps={{
                    placeholder: "Enter landlord phone number",
                  }}
                />
                <FormControlV2<TFieldValues>
                  label={"Mortgage Request phone number"}
                  name={"mortgagePhoneNumber"}
                  control={control}
                  type={FormControlsTypeEnum.TEL}
                  additionalProps={{
                    placeholder: "Enter mortgage request phone number",
                  }}
                />
              </VStack>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Working hours"}
                description={
                  "If you have Autocaller enabled, calls won't be triggered outside of working hours."
                }
              />
              <OfficeWorkingHoursForm<TFieldValues>
                account={accountData}
                officeStore={officeStore}
                control={control}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Office logo"}
                description={
                  "The logo specified here will be used in any branded emails sent, and as the default logo for any Instant Online Valuation tool pages you create."
                }
              />
              <OfficeLogoUploadForm
                account={accountData}
                officeStore={officeStore}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Redirecting incoming lead responder leads"
                description={
                  " Which office each incoming lead will be directed to will be decided by using the postcode coverage of all of your offices to find the closest to the lead's postcode."
                }
              />
              <HStack spacing={2} align={"flex-start"} width={"100%"}>
                <FormControlV2<TFieldValues>
                  name={"ignoreEmailRouting"}
                  control={control}
                  type={FormControlsTypeEnum.SWITCH}
                />
                <FormControlMeta
                  label={"Redirect incoming Lead Responder leads"}
                  description={
                    "Enabling this setting will redirect all leads incoming through this office's lead responder email address to other offices in your LeadPro system."
                  }
                />
              </HStack>
            </SimpleGrid>
          </VStack>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </form>
      </Box>
    );
  }
);
