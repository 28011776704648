import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { observer } from "mobx-react";
import React, { FC, useCallback } from "react";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { TSelectValue } from "types/select-input.type";
import { TIntegrationSyncOfficeExtendedData } from "types/integration-sync-office.type";
import { AccountIntegrationOfficesStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";

interface IProps {
  integrationOfficesStore: AccountIntegrationOfficesStore;
  record: TIntegrationSyncOfficeExtendedData;
  onChange: (syncPair: TIntegrationSyncOfficeExtendedData) => void;
}

export const IntegrationOfficeSyncSelect: FC<IProps> = observer(
  ({ integrationOfficesStore, record, onChange }) => {
    const integrationOfficeOptions =
      integrationOfficesStore.integrationOfficesOptions;

    const handleChange = useCallback(
      (branchId: TSelectValue<string>) => {
        onChange({
          branchId: branchId || null,
          officeId: record.officeId,
          officeName: record.officeName,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.branchId}
        clearable={true}
        options={integrationOfficeOptions}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
