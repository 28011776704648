import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/sharp-solid-svg-icons";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

interface IProps {
  tabName: string;
  hasError: boolean;
  loadingStatus: ApiRequestStatusEnum;
}

export const PageToolPageSettingsTabHeader: FC<IProps> = ({
  tabName,
  hasError,
  loadingStatus,
}) => {
  return (
    <Box position={"relative"}>
      <Box>{tabName}</Box>
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && hasError && (
        <Box
          position={"absolute"}
          right={"-10px"}
          top={"-6px"}
          color={"red.500"}
          fontSize={"md"}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Box>
      )}
    </Box>
  );
};
