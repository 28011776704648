import { IAccountUserDataWithDetails } from "types/account-user.type";
import { AccountIntegrationReapitStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapit.store";
import React, { useCallback, useEffect, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { ReapitNegotiatorAutosuggestSelect } from "./ReapitNegotiatorAutosuggestSelect";
import { Box, Button } from "@chakra-ui/react";
import { Error, FormControlMeta, Loader } from "components";

interface IProps {
  email: string;
  existingUser?: IAccountUserDataWithDetails;
  value: string | null;
  onChange: (value: string | null) => void;
  reapitIntegrationStore: AccountIntegrationReapitStore;
}

export const ReapitNegotiatorSync: React.FC<IProps> = ({
  reapitIntegrationStore,
  value,
  existingUser,
  onChange,
  email,
}) => {
  const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
    ApiRequestStatusEnum.NONE
  );
  const reapitIntegrationNegotiatorsStore =
    reapitIntegrationStore.accountIntegrationReapitNegotiatorsStore;

  const handleFetchNegotiators = useCallback(async () => {
    try {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      await reapitIntegrationNegotiatorsStore.fetchAccountReapitNegotiatorsAndRelations();
      setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (err) {
      setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }, [reapitIntegrationNegotiatorsStore, setLoadingStatus]);

  useEffect(() => {
    handleFetchNegotiators();
  }, [handleFetchNegotiators]);

  return (
    <Box width={"100%"} position={"relative"} height={"100px"}>
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && (
        <Error
          message={"Could not load your Reapit negotiators. :("}
          action={
            <Button colorScheme={"blue"} onClick={handleFetchNegotiators}>
              Reload
            </Button>
          }
        />
      )}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <Box mb={5}>
          <FormControlMeta
            label={"Sync Reapit Negotiator"}
            description={
              "Choose a reapit negotiator you would like to link to a leadpro user"
            }
          />
          <ReapitNegotiatorAutosuggestSelect
            email={email}
            onChange={onChange}
            existingUser={existingUser}
            reapitIntegrationStore={reapitIntegrationStore}
            value={value}
          />
        </Box>
      )}
    </Box>
  );
};
