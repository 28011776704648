import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSelectValue } from "types/select-input.type";
import { TSmeEmployeeSyncData } from "types/sme.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TSmeEmployeeSyncData;
  onChange: (syncPair: TSmeEmployeeSyncData) => void;
}

export const SmeEmployeeSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const smeEmployeeOptions =
      accountStore.accountIntegrationsStore.accountIntegrationSmeStore
        .accountIntegrationSmeEmployeesStore.smeEmployeeOptions;

    const handleChange = useCallback(
      (smeEmployeeId: TSelectValue<number>) => {
        onChange({
          smeEmployeeId: smeEmployeeId || null,
          userAccountId: record.userAccountId,
          user: record.user,
          userFullName: record.userFullName,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.smeEmployeeId}
        clearable={true}
        options={smeEmployeeOptions}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
