import { Box, Button, Divider, useToast, VStack } from "@chakra-ui/react";
import {
  ActionPromptContainer,
  FormControlV2,
  ApiMessageStack,
  UserSelectOption,
} from "components";
import * as Yup from "yup";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TOfficeGroupData } from "types/account-office.type";
import { observer } from "mobx-react";
import React, { FC, useMemo, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import { assigneeOptionsFilter } from "utils/account-user.utils";
import { filterOptionsByLabel } from "utils/select.utils";
import { TSelectOption } from "types/select-input.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  name: string;
  officeIds: number[];
  users: IAccountUserDataWithDetails[];
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  users: Yup.array().of(Yup.mixed()),
  officeIds: Yup.array()
    .of(Yup.number())
    .min(1, REQUIRED_FIELD),
});

interface IProps {
  officeGroup?: TOfficeGroupData;
  accountStore: UserAccountStore;
  closePrompt: () => void;
}

export const OfficeGroupFormPrompt: FC<IProps> = observer(
  ({ officeGroup, accountStore, closePrompt }) => {
    const toast = useToast();
    const accountOfficeGroupStore = accountStore.accountOfficeGroupsStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountUsersOptions = accountUsersStore.accountUsersOptions;
    const accountOfficesOptions =
      accountOfficesStore.accountOfficesOptionsArray;

    const initialValues: TFieldValues = useMemo(() => {
      if (!!officeGroup) {
        const users = accountUsersOptions
          .filter(option => officeGroup.userIds.includes(option.value.id))
          .map(option => option.value);
        return {
          name: officeGroup.name,
          users,
          officeIds: officeGroup.officeIds,
        };
      } else {
        return {
          name: "",
          users: [],
          officeIds: [],
        };
      }
    }, [officeGroup, accountUsersOptions]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const handleUpdateOfficeGroup = useCallback(
      async (values: TFieldValues) => {
        const { name, officeIds, users } = values;
        if (!!officeGroup) {
          const userIds = users.map(user => user.id);

          await accountOfficeGroupStore.updateOfficeGroup(
            officeGroup.id,
            name,
            userIds,
            officeIds
          );
        }
      },
      [accountOfficeGroupStore, officeGroup]
    );

    const handleCreateOfficeGroup = useCallback(
      async (values: TFieldValues) => {
        const { name, users, officeIds } = values;
        const userIds = users.map(user => user.id);

        await accountOfficeGroupStore.createOfficeGroup(
          name,
          userIds,
          officeIds
        );
      },
      [accountOfficeGroupStore]
    );

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          if (!!officeGroup) {
            await handleUpdateOfficeGroup(values);
          } else {
            await handleCreateOfficeGroup(values);
          }
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={`Office group successfully ${
                  !!officeGroup ? "updated" : "created"
                }`}
              />
            ),
          });
          closePrompt();
        } catch (err) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={err.message} />,
          });
        }
      },
      [
        handleUpdateOfficeGroup,
        handleCreateOfficeGroup,
        officeGroup,
        toast,
        closePrompt,
      ]
    );

    const handleFilterOfficeOptions = useCallback(
      (options: TSelectOption<number>[], searchTerm: string) => {
        return filterOptionsByLabel<number>(options, searchTerm);
      },
      []
    );

    return (
      <ActionPromptContainer
        header={!!officeGroup ? "Edit Office Group" : "Create Office Group"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={5} align={"stretch"}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Office group name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Group name",
                    filterFn: handleFilterOfficeOptions,
                    clearable: true,
                  }}
                />

                <FormControlV2<TFieldValues>
                  name={"officeIds"}
                  control={control}
                  label={"Select offices"}
                  type={FormControlsTypeEnum.MULTI_SELECT}
                  additionalProps={{
                    clearable: true,
                    placeholder: "Offices included in the office group",
                    options: accountOfficesOptions,
                    filterFn: handleFilterOfficeOptions,
                  }}
                />
                <FormControlV2<TFieldValues>
                  name={"users"}
                  control={control}
                  label={"Select users"}
                  type={FormControlsTypeEnum.MULTI_SELECT}
                  additionalProps={{
                    clearable: true,
                    placeholder: "Users included in the office group",
                    options: accountUsersOptions,
                    filterFn: assigneeOptionsFilter,
                    optionComponent: (
                      optionProps: IGetOptionPropsData<
                        IAccountUserDataWithDetails
                      >
                    ) => <UserSelectOption optionProps={optionProps} />,
                  }}
                />
                <Divider mb={4} />
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    type={"submit"}
                    colorScheme={"blue"}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {!!officeGroup ? "Update" : "Create"}
                  </Button>
                </Box>
              </VStack>
            </form>
          </Box>
        }
      />
    );
  }
);
