import {
  TReapitNegotiator,
  TReapitNegotiatorOptionData,
  TReapitNegotiatorRelation,
  TReapitNegotiatorRelationData,
  TReapitSyncNegotiatorExtendedData,
} from "types/reapit.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountReapitApi } from "api/account-reapit.api";
import { keyBy } from "lodash";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { getUserFullName } from "utils/account-user.utils";
import { UserAccountStore } from "../../UserAccount.store";
import { AccountUsersStore } from "../../AccountUsers.store";
import { TSelectOption } from "types/select-input.type";

const accountReapitApi = new AccountReapitApi();

export class AccountIntegrationReapitNegotiatorsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountReapitNegotiators: IObservableArray<
    TReapitNegotiator
  >;
  private readonly accountReapitNegotiatorRelations: IObservableArray<
    TReapitNegotiatorRelation
  >;
  private selectedOfficeGroupId?: number;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.syncStatusFilter = false;
    this.accountReapitNegotiators = observable.array<TReapitNegotiator>();
    this.accountReapitNegotiatorRelations = observable.array<
      TReapitNegotiatorRelation
    >();
  }

  get reapitNegotiatorRelationsMapByLeadProUserAccountId() {
    return keyBy(
      this.accountReapitNegotiatorRelations,
      relation => relation.userAccountId
    );
  }

  get reapitNegotiatorRelationsMapByReapitNegotiatorId() {
    return keyBy(
      this.accountReapitNegotiatorRelations,
      relation => relation.reapitId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get availableReapitNegotiators() {
    const usedReapitNegotiatorsReapitIds = this.reapitSyncNegotiatorData.map(
      negotiator => negotiator.reapitId
    );
    return this.accountReapitNegotiators.filter(
      reapitNegotiator =>
        !usedReapitNegotiatorsReapitIds.includes(reapitNegotiator.reapitId)
    );
  }

  get availableReapitNegotiatorsMapByEmail() {
    return keyBy(
      this.availableReapitNegotiators,
      negotiator => negotiator.reapitEmail
    );
  }

  get reapitNegotiatorsOptions(): TSelectOption<
    string,
    TReapitNegotiatorOptionData
  >[] {
    const reapitNegotiators = this.accountReapitNegotiators;

    return reapitNegotiators
      .map(reapitNegotiator => ({
        value: reapitNegotiator.reapitId,
        label: reapitNegotiator.reapitName,
        data: {
          reapitData: {
            reapitEmail: reapitNegotiator.reapitEmail,
            reapitId: reapitNegotiator.reapitId,
          },
          syncData: this.reapitNegotiatorRelationsMapByReapitNegotiatorId[
            reapitNegotiator.reapitId
          ] as TReapitNegotiatorRelation,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get filteredReapitSyncNegotiatorData(): TReapitSyncNegotiatorExtendedData[] {
    return this.reapitSyncNegotiatorData.filter(
      syncItem =>
        !this.syncStatusFilter ||
        syncItem.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get reapitSyncNegotiatorData(): TReapitSyncNegotiatorExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const accountUsersStore = accountStore.accountUsersStore;
    const leadproAccountUsersMap = this.getLeadproAccountUsersMap(
      accountStore,
      accountUsersStore,
      this.selectedOfficeGroupId
    );
    const leadproAccountUsers = this.getLeadProAccountUsers(
      accountStore,
      accountUsersStore,
      this.selectedOfficeGroupId
    );
    const reapitNegotiators = this.accountReapitNegotiators.slice();

    const reapitNegotiatorRelationsMapByLeadProUserAccountId = this
      .reapitNegotiatorRelationsMapByLeadProUserAccountId;
    const reapitNegotiatorRelationsMapByReapitNegotiatorId = this
      .reapitNegotiatorRelationsMapByReapitNegotiatorId;

    return leadproAccountUsers
      .map(accountUser => {
        let pairedReapitNegotiatorId =
          reapitNegotiatorRelationsMapByLeadProUserAccountId[
            accountUser.userAccount.id
          ]?.reapitId;
        let syncStatus = !!pairedReapitNegotiatorId
          ? IntegrationEntitySyncStatusEnum.SYNCED
          : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

        // try suggesting a match
        if (!pairedReapitNegotiatorId) {
          const suggestedMatchIndex = reapitNegotiators.findIndex(
            reapitNegotiator =>
              reapitNegotiator.reapitEmail?.toLowerCase() ===
                leadproAccountUsersMap[accountUser.id].email?.toLowerCase() &&
              !reapitNegotiatorRelationsMapByReapitNegotiatorId[
                reapitNegotiator.reapitId
              ]
          );
          if (suggestedMatchIndex > -1) {
            pairedReapitNegotiatorId =
              reapitNegotiators[suggestedMatchIndex]?.reapitId || null;
            reapitNegotiators.splice(suggestedMatchIndex, 1);
            syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
          }
        }

        return {
          userAccountId: accountUser.userAccount.id,
          user: accountUser,
          userFullName: getUserFullName(
            accountUser.firstName,
            accountUser.lastName,
            accountUser.email
          )!,
          reapitId: pairedReapitNegotiatorId,
          syncStatus: syncStatus,
        };
      })
      .filter(
        syncItem =>
          !this.syncStatusFilter ||
          syncItem.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );
  }

  public setAccountReapitNegotiators(negotiators: TReapitNegotiator[]) {
    this.accountReapitNegotiators.replace(negotiators);
  }

  public setAccountReapitNegotiatorRelations(
    relations: TReapitNegotiatorRelation[]
  ) {
    this.accountReapitNegotiatorRelations.replace(relations);
  }

  public async fetchAccountReapitNegotiators(officeGroupId?: number) {
    const data = await accountReapitApi.fetchAccountReapitNegotiators(
      this.accountId,
      officeGroupId
    );
    this.setAccountReapitNegotiators(data);
  }

  public setSyncStatusFilter(syncStatusFilter: boolean) {
    this.syncStatusFilter = syncStatusFilter;
  }

  public async fetchAccountReapitNegotiatorRelations() {
    const data = await accountReapitApi.fetchAccountReapitNegotiatorRelations(
      this.accountId
    );
    this.setAccountReapitNegotiatorRelations(data);
  }

  public async fetchAccountReapitNegotiatorsAndRelations(
    officeGroupId?: number
  ) {
    await Promise.all([
      this.fetchAccountReapitNegotiators(officeGroupId),
      this.fetchAccountReapitNegotiatorRelations(),
    ]);
  }

  public async updateAccountReapitSyncedNegotiators(
    syncedPairs: TReapitNegotiatorRelationData[],
    officeGroupId?: number
  ) {
    await accountReapitApi.updateAccountReapitSyncedNegotiators(
      this.accountId,
      syncedPairs.map(pair => ({
        userAccountId: pair.userAccountId,
        reapitId: pair.reapitId,
      }))
    );
    await this.fetchAccountReapitNegotiatorsAndRelations(officeGroupId);
  }

  public setSelectedOfficeGroupId(officeGroupId?: number) {
    this.selectedOfficeGroupId = officeGroupId;
  }

  private getLeadproAccountUsersMap(
    accountStore: UserAccountStore,
    accountUsersStore: AccountUsersStore,
    selectedOfficeGroupId?: number
  ) {
    if (selectedOfficeGroupId) {
      const userIds =
        accountStore.accountOfficeGroupsStore.associatedUserIdsArray;
      return keyBy(
        accountUsersStore.accountUsersArray.filter(user =>
          userIds.includes(user.id)
        ),
        accountUser => accountUser.id
      );
    }
    return accountUsersStore.accountUsersMap;
  }

  private getLeadProAccountUsers(
    accountStore: UserAccountStore,
    accountUsersStore: AccountUsersStore,
    selectedOfficeGroupId?: number
  ) {
    if (selectedOfficeGroupId) {
      const userIds =
        accountStore.accountOfficeGroupsStore.associatedUserIdsArray;
      return accountUsersStore.accountUsersArray.filter(user =>
        userIds.includes(user.id)
      );
    }
    return accountUsersStore.accountUsersArray;
  }
}
