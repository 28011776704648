import { SingleSelectInput } from "components";
import React, { FC, useEffect } from "react";
import { TReapitNegotiator } from "types/reapit.type";
import { AccountIntegrationReapitStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapit.store";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import { observer } from "mobx-react";
import { ReapitNegotiatorIntegrationSyncSelectOption } from "components/select-input/custom-options/ReapitNegotiatorIntegrationSyncSelectOption";

interface IProps {
  value: TReapitNegotiator["reapitId"] | null;
  email: string;
  existingUser?: IAccountUserDataWithDetails;
  onChange: (value: TReapitNegotiator["reapitId"] | null) => void;
  reapitIntegrationStore: AccountIntegrationReapitStore;
}

export const ReapitNegotiatorAutosuggestSelect: FC<IProps> = observer(
  ({ onChange, existingUser, reapitIntegrationStore, email, value }) => {
    const accountReapitNegotiatorsStore =
      reapitIntegrationStore.accountIntegrationReapitNegotiatorsStore;
    const reapitNegotiatorRelationsMapByLeadProUserAccountId =
      accountReapitNegotiatorsStore.reapitNegotiatorRelationsMapByLeadProUserAccountId;
    const availableReapitNegotiatorsMapByEmail =
      accountReapitNegotiatorsStore.availableReapitNegotiatorsMapByEmail;
    const reapitNegotiatorsOptions =
      accountReapitNegotiatorsStore.reapitNegotiatorsOptions || [];

    // this effects presets the reapit negotiator id if exists
    useEffect(() => {
      if (!!existingUser) {
        const userAccountId = existingUser.userAccount.id;
        const relation =
          reapitNegotiatorRelationsMapByLeadProUserAccountId[userAccountId];
        onChange(relation?.reapitId || null);
      }
    }, [
      existingUser,
      reapitNegotiatorRelationsMapByLeadProUserAccountId,
      onChange,
    ]);

    // this effect suggests reapit negotiator
    useEffect(() => {
      const suggestedNegotiator = availableReapitNegotiatorsMapByEmail[email];

      if (!!suggestedNegotiator) {
        onChange(suggestedNegotiator.reapitId);
      }
    }, [availableReapitNegotiatorsMapByEmail, email, onChange]);

    return (
      <SingleSelectInput
        value={value}
        options={reapitNegotiatorsOptions}
        clearable
        onChange={onChange}
        optionComponent={optionProps => (
          <ReapitNegotiatorIntegrationSyncSelectOption
            optionProps={optionProps}
          />
        )}
      />
    );
  }
);
