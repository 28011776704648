import {
  ApiMessageStack,
  FormControlLabel,
  FormControlMeta,
  FormControlV2,
} from "components";
import {
  Box,
  Divider,
  HStack,
  Switch,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
  DAYS_OF_WEEK,
  TWorkingHoursFieldValues,
} from "utils/validation-schemas/office-working-hours.validation";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { TUserAccountData } from "types/user-account.type";
import { observer } from "mobx-react";
import { Control, FieldPath } from "react-hook-form";

interface IProps<T extends TWorkingHoursFieldValues> {
  account: TUserAccountData;
  officeStore?: AccountOfficeStore;
  control: Control<T>;
}

export const OfficeWorkingHoursForm = observer(function OfficeWorkingHoursForm<
  T extends TWorkingHoursFieldValues
>({ account, officeStore, control }: IProps<T>) {
  const toast = useToast();
  const officeData = officeStore?.office;

  const handleToggleCustomOfficeWorkingHours = useCallback(async () => {
    try {
      if (!officeStore || !officeData) return;

      await officeStore.update({
        workingHours: officeData.workingHours ? null : account.workingHours,
      });
    } catch (e) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={e.message} />,
      });
    }
  }, [account, officeStore, officeData, toast]);

  return (
    <Box>
      {!!officeData && (
        <HStack spacing={2} align={"flex-start"} mb={5}>
          <Switch
            name={"use-account-working-hours"}
            isChecked={!!officeData.workingHours}
            onChange={handleToggleCustomOfficeWorkingHours}
          />
          <FormControlMeta
            label={"Use custom working hours"}
            description={
              "Enable this if you want to override the default working hours."
            }
          />
        </HStack>
      )}
      <VStack spacing={2} align={"stretch"} divider={<Divider />}>
        {DAYS_OF_WEEK.map(day => {
          return (
            <HStack key={day.id} spacing={2} align={"center"}>
              <Box width={"100px"}>
                <FormControlLabel m={0}>{day.label}</FormControlLabel>
              </Box>
              <FormControlV2<T>
                name={day.id as FieldPath<T>}
                control={control}
                type={FormControlsTypeEnum.TIME_RANGE_PICKER}
                isDisabled={!!officeData && !officeData.workingHours}
              />
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
});
