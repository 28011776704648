import { AppStore } from "store/App.store";
import { AccountApi } from "api/account.api";
import { TLeadResponderLeadEmailAdditionalData } from "types/account-lead-emails.type";
import PostalMime from "postal-mime";
import { makeAutoObservable } from "mobx";
import axios from "axios";

const accountApi = new AccountApi();

export class AccountLeadResponderEmailStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private emailData: TLeadResponderLeadEmailAdditionalData;

  constructor(
    root: AppStore,
    accountId: number,
    emailData: TLeadResponderLeadEmailAdditionalData
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.emailData = emailData;
  }
  get email(): TLeadResponderLeadEmailAdditionalData {
    return this.emailData;
  }

  public async fetchDetails() {
    const emailData = await accountApi.fetchOneAccountLeadEmail(
      this.accountId,
      this.emailData.id
    );

    const parsedEmail = await this.fetchAndParsePresignedAmazonS3Url(
      emailData?.emailS3PresignedUrl
    );

    this.upsertEmailData({
      ...emailData,
      parsedEmail,
    });
  }

  private async fetchAndParsePresignedAmazonS3Url(presignedUrl?: string) {
    if (!presignedUrl) return;
    const response = await axios.get(presignedUrl, {
      responseType: "arraybuffer",
    });
    return await PostalMime.parse(response.data);
  }

  private upsertEmailData(emailDetails: TLeadResponderLeadEmailAdditionalData) {
    this.emailData = {
      ...this.emailData,
      ...emailDetails,
    };
  }
}
