import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { ISectionLayoutProps, SectionLayout } from "./SectionLayout";
import { useHistory, useLocation } from "react-router-dom";

interface IProps extends ISectionLayoutProps {
  loadingStatus?: ApiRequestStatusEnum;
  overrideMaxW?: string;
  config: {
    tabName: string;
    tabComponent: React.ReactNode;
    tabHash?: string;
  }[];
}

export const TabsGroupLayout: React.FC<IProps> = ({
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
  config,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();

  const tabHashes = useMemo(() => {
    return config.map(configElement => configElement.tabHash);
  }, [config]);

  const [currentTabIndex, setCurrentTabIndex] = useState(
    Math.max(tabHashes.indexOf(location.hash), 0)
  );

  useEffect(() => {
    const newHash = tabHashes[currentTabIndex];
    if (!!newHash && newHash !== location.hash) {
      history.replace(newHash);
    }
  }, [history, tabHashes, currentTabIndex, location.hash]);

  const onTabChange = useCallback(
    (tabIndex: number) => {
      setCurrentTabIndex(tabIndex);
    },
    [setCurrentTabIndex]
  );

  return (
    <SectionLayout {...rest}>
      <Tabs
        index={currentTabIndex}
        onChange={onTabChange}
        isLazy={true}
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        flexGrow={1}
        borderTop={0}
      >
        <TabList>
          {config.map((config, index) => (
            <Tab key={index} fontWeight={500}>
              {config.tabName}
            </Tab>
          ))}
        </TabList>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <TabPanels
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            overflow={"hidden"}
            tabIndex={undefined}
          >
            {config.map((config, index) => (
              <TabPanel
                key={index}
                flexGrow={1}
                overflowY={"auto"}
                p={0}
                pt={5}
              >
                {config.tabComponent}
              </TabPanel>
            ))}
          </TabPanels>
        )}
      </Tabs>
    </SectionLayout>
  );
};
