import { Divider } from "@chakra-ui/react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  TAccountIntegrationData,
  TMRIRadarConfig,
} from "types/account-integration.type";
import { SyncConfigurator } from "../SyncConfigurator";
import { MRIRadarIntegrationForm } from "./MRIRadarIntegrationForm";

interface IProps {
  accountStore: UserAccountStore;
}

export const MRIRadarIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.MRI_RADAR
    ] as TAccountIntegrationData<TMRIRadarConfig>;

    return (
      <>
        <MRIRadarIntegrationForm
          accountIntegrationsStore={accountIntegrationsStore}
        />
        <Divider my={6} />
        <SyncConfigurator
          accountIntegrationsStore={accountIntegrationsStore}
          accountIntegration={accountIntegration}
        />
      </>
    );
  }
);
