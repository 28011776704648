import { ShowForRoles } from "routes/dashboard/components";
import React, { FC, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { UserRoleEnum } from "enums/user-role.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AutocallerSettings } from "./routes/autocaller/AutocallerSettings";
import { InstantValuationTool } from "./routes/instant-valuation-tool/InstantValuationTool";
import { RightSideLayout } from "routes/dashboard/components/Layouts/RightSideLayout";
import {
  TOOLS_AUTOCALLER_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
  TOOLS_IVT_ROUTE,
  TOOLS_MORTGAGE_REFERRALS_ROUTE,
  TOOLS_FACEBOOK_ADS_ROUTE,
  TOOLS_FORMS_ROUTE,
} from "constants/routes";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { LeadResponder } from "./routes/lead-responder/LeadResponder";
import { MortgageReferralsSettings } from "./routes/mortgage-referrals/MortgageReferralsSettings";
import { FacebookAdsSplashScreen } from "./routes/facebook-ads/FacebookAdsSplashScreen";
import { FormTool } from "./routes/form-tool/FormTool";
import { ShowForAccounts } from "routes/dashboard/components/ShowFor/ShowForAccounts";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

interface IProps {
  accountStore: UserAccountStore;
}

export const Tools: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      try {
        await Promise.all([
          accountStore.fetchDetails(),
          accountStore.fetchPortals(),
          accountStore.accountLeadSourcesStore.fetchAccountLeadSources(),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [accountStore]);

  return (
    <RightSideLayout loadingStatus={loadingStatus}>
      <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
        <Switch>
          <Route path={TOOLS_IVT_ROUTE}>
            <InstantValuationTool accountStore={accountStore} />
          </Route>
          <Route path={TOOLS_FORMS_ROUTE}>
            <ShowForAccounts envVariable={env.REACT_APP_FORM_TOOL_ACCOUNTS}>
              <FormTool accountStore={accountStore} />
            </ShowForAccounts>
          </Route>
          <Route path={TOOLS_LEAD_RESPONDER_ROUTE}>
            <LeadResponder accountStore={accountStore} />
          </Route>
          <Route path={TOOLS_AUTOCALLER_ROUTE} exact>
            <AutocallerSettings accountStore={accountStore} />
          </Route>
          <Route path={TOOLS_MORTGAGE_REFERRALS_ROUTE} exact>
            <MortgageReferralsSettings accountStore={accountStore} />
          </Route>
          <Route path={TOOLS_FACEBOOK_ADS_ROUTE} exact>
            <FacebookAdsSplashScreen accountStore={accountStore} />
          </Route>
        </Switch>
      </ShowForRoles>
    </RightSideLayout>
  );
});
