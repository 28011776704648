import { BaseApi } from "./base.api";
import {
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "types/integration-sync-office.type";

export class AccountRightmoveApi extends BaseApi {
  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    return await this.get(`/accounts/${accountId}/rightmove/office-relations`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    data: TIntegrationOfficeRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/rightmove/office-relations`, {
      items: data,
    });
  }
}
