import { ExtendedColumn } from "components/table/table-props";
import { TPropertyTableData } from "types/property.type";
import { Row } from "react-table";
import React from "react";
import { PropertySourcesCell } from "components/table/cells/properties-table/PropertySourcesCell";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { TPortalData } from "types/portal.type";
import { PropertyAddressCell } from "components/table/cells/properties-table/PropertyAddressCell";
import { PropertyLeadsCountCell } from "components/table/cells/properties-table/PropertyLeadsCountCell";
import { PropertyHeaderCell } from "components/table/cells/properties-table/PropertyHeaderCell";

export const propertiesTableColumnDef = (
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>,
  portalsMap: Record<number, TPortalData>,
  logoUrl: string | null
): ExtendedColumn<TPropertyTableData>[] => {
  const cols: ExtendedColumn<TPropertyTableData>[] = [];

  cols.push(
    {
      Header: () => <PropertyHeaderCell>Property details</PropertyHeaderCell>,
      width: "400px",
      accessor: "address",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TPropertyTableData> }) => (
        <PropertyAddressCell address={row.original.address} />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Associated leads</PropertyHeaderCell>,
      width: "140px",
      accessor: "totalLeadsCount",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TPropertyTableData> }) => (
        <PropertyLeadsCountCell count={row.original.totalLeadsCount} />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Sources</PropertyHeaderCell>,
      accessor: "sources",
      width: "400px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TPropertyTableData> }) => (
        <PropertySourcesCell
          property={row.original}
          leadSourcesMapBySource={leadSourcesMapBySource}
          portalsMap={portalsMap}
          accountLogoUrl={logoUrl}
        />
      ),
    }
  );

  return cols;
};
