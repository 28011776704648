import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC } from "react";
import { Accordion, Box, VStack } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { QuestionnaireToolPageSettingsSelectedListSection } from "../QuestionnaireToolPageSettingsSelectedListSection/QuestionnaireToolPageSettingsSelectedListSection";
import { QuestionnaireToolPageSettingsEndingPagesSelectedListSectionContent } from "./QuestionnaireToolPageSettingsEndingPagesSelectedListSectionContent";
import { QuestionnaireToolPageAccordionSections } from "constants/questionnaire-tool-page";
import { QuestionnaireToolPageSettingsEndingPagesSelectedListSectionHeader } from "./QuestionnaireToolPageSettingsEndingPagesSelectedListSectionHeader";
import { QuestionnaireToolPageSettingsDefaultEndingPage } from "./QuestionnaireToolPageSettingsDefaultEndingPage";

interface IProps {
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsEndingPages: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore }) => {
    return (
      <Box display={"flex"} flexDirection={"row"} height={"100%"}>
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.SUCCESS && (
          <Box
            flexGrow={1}
            overflowY={"auto"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            flexDirection={"column"}
            px={10}
            py={"65px"}
          >
            <VStack spacing={10} maxW={"1200px"} width={"100%"}>
              <Box width={"100%"}>
                <Box fontWeight={700} fontSize={"lg"}>
                  Ending Pages
                </Box>
                <Box color={"leadpro.500"} maxW={"600px"}>
                  Here you can specify the ending page or pages for your
                  questionnaire. You can create alternate ending pages to
                  display depending on question responses.
                </Box>
              </Box>
              <Accordion width={"100%"} defaultIndex={0}>
                <QuestionnaireToolPageSettingsDefaultEndingPage
                  questionnaireToolPageSettingsEditorStore={
                    questionnaireToolPageSettingsEditorStore
                  }
                />
                {QuestionnaireToolPageAccordionSections.map(leadType => (
                  <QuestionnaireToolPageSettingsSelectedListSection
                    key={leadType}
                    leadType={leadType}
                    questionnaireToolPageSettingsEditorStore={
                      questionnaireToolPageSettingsEditorStore
                    }
                    Header={
                      QuestionnaireToolPageSettingsEndingPagesSelectedListSectionHeader
                    }
                    Content={
                      QuestionnaireToolPageSettingsEndingPagesSelectedListSectionContent
                    }
                  />
                ))}
              </Accordion>
            </VStack>
          </Box>
        )}
      </Box>
    );
  }
);
