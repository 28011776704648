import React, { FC, useCallback, useMemo } from "react";
import {
  ApiMessageStack,
  SingleSelectInput,
  UserSelectOption,
} from "components";
import { TAssignableToLeadUserData } from "types/user-data.type";
import { useToast } from "@chakra-ui/react";
import { assigneeOptionsFilter } from "utils/account-user.utils";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { TSelectValue } from "types/select-input.type";
import { LeadInfoRowValue } from "../../LeadInfoRow";
import { LeadControlRow } from "./LeadControlRow";
import { LeadControlRowLabel } from "./LeadControlLabel";

interface IProps {
  leadStore: AccountLeadStore;
}

export const LeadAssigneeSelect: FC<IProps> = observer(({ leadStore }) => {
  const toast = useToast();
  const assignableUsersForSingleLeadOptionsArray =
    leadStore.assignableUsersOptionsArray;

  const handleAssigneeChange = useCallback(
    async (assignedTo: TSelectValue<TAssignableToLeadUserData>) => {
      try {
        await leadStore.update({
          assignedToId: assignedTo ? assignedTo.id : null,
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [leadStore, toast]
  );

  const selectedOption = useMemo(() => {
    return assignableUsersForSingleLeadOptionsArray.find(
      option => option.value.id === leadStore.lead.assignedToId
    );
  }, [assignableUsersForSingleLeadOptionsArray, leadStore.lead.assignedToId]);

  return (
    <LeadControlRow secondary>
      <LeadControlRowLabel>Assignee</LeadControlRowLabel>
      <LeadInfoRowValue>
        <SingleSelectInput<TAssignableToLeadUserData>
          noBorder
          secondary
          value={selectedOption?.value || null}
          options={assignableUsersForSingleLeadOptionsArray}
          onChange={handleAssigneeChange}
          filterFn={assigneeOptionsFilter}
          clearable={true}
          placeholder={"Select user"}
          optionComponent={(optionProps, displayingAsInputValue) => (
            <UserSelectOption
              condensed
              displayingAsInputValue={displayingAsInputValue}
              optionProps={optionProps}
            />
          )}
        />
      </LeadInfoRowValue>
    </LeadControlRow>
  );
});
