import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultRowCell } from "components/table-v2/row-cells";
import React from "react";
import { TLeadResponderLeadEmailTableData } from "types/account-lead-emails.type";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { TAccountOfficeData } from "types/account-office.type";
import { LEAD_RESPONDER_EMAIL_STATE_COLOR_MAP } from "constants/lead-responder-email-status";
import { capitalize, Dictionary } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { LeadOfficeCell } from "components/table/cells/leads-table";
import { LeadEmailCorrespondenceCell } from "components/table-v2/row-cells/lead-responder-emails-table/LeadEmailCorrespondenceCell";
import { LeadEmailPortalCell } from "components/table-v2/row-cells/lead-responder-emails-table/LeadEmailPortalCell";
import { FilterEmailsDateRangeHeader } from "./LeadResponderEmailTableFilterHeaders/FilterEmailsDateRangeHeader";
import { FiltersLeadResponderEmailsOfficeHeader } from "./LeadResponderEmailTableFilterHeaders/FilterEmailsOfficeHeader";
import { FiltersLeadResponderEmailsPortalHeader } from "./LeadResponderEmailTableFilterHeaders/FilterEmailsPortalHeader";
import { FiltersLeadResponderEmailsStatusHeader } from "./LeadResponderEmailTableFilterHeaders/FilterEmailsStatusHeader";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import { TPortalData } from "types/portal.type";
import { TSelectOption } from "types/select-input.type";
import { LeadEmailDateCell } from "components/table-v2/row-cells/lead-responder-emails-table/LeadEmailDateCell";

const ICON_SIZE = 18;

export const leadResponderLeadEmailsTableColumnDef = (
  accountOfficesDataMap: Record<number, TAccountOfficeData>,
  emailFiltersStore: AcccountLeadResponderEmailsFiltersStore,
  accountPortalMap: Dictionary<TPortalData>,
  accountPortalOptions: TSelectOption<number, TPortalData>[],
  handleGoToEmailDetails: (email: TLeadResponderLeadEmailTableData) => void
): ITableV2Column<TLeadResponderLeadEmailTableData>[] => {
  return [
    {
      accessor: "createdAt",
      disableSortBy: false,
      Header: (
        <FilterEmailsDateRangeHeader emailFiltersStore={emailFiltersStore} />
      ),
      Cell: ({ row }) => <LeadEmailDateCell email={row} />,
    },
    {
      accessor: "leadProOfficeId",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: (
        <FiltersLeadResponderEmailsOfficeHeader
          emailFiltersStore={emailFiltersStore}
          accountOfficesDataMap={accountOfficesDataMap}
        />
      ),
      Cell: ({ row }) => {
        const office = accountOfficesDataMap[row.leadProOfficeId];
        return (
          <DefaultRowCell>
            <LeadOfficeCell office={office} />
          </DefaultRowCell>
        );
      },
    },
    {
      accessor: "to",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      width: "250px",
      Header: <DefaultHeaderCell>Correspondence</DefaultHeaderCell>,
      Cell: ({ row }) => <LeadEmailCorrespondenceCell email={row} />,
    },
    {
      accessor: "subject",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Subject</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.subject}</DefaultRowCell>,
    },
    {
      accessor: "portal",
      disableSortBy: false,
      excludeFromGlobalFilter: true,
      Header: (
        <FiltersLeadResponderEmailsPortalHeader
          emailFiltersStore={emailFiltersStore}
          portalOptions={accountPortalOptions}
        />
      ),
      Cell: ({ row }) => {
        return <LeadEmailPortalCell email={row} portalMap={accountPortalMap} />;
      },
    },
    {
      accessor: "state",
      disableSortBy: false,
      excludeFromGlobalFilter: true,
      Header: (
        <FiltersLeadResponderEmailsStatusHeader
          emailFiltersStore={emailFiltersStore}
        />
      ),
      Cell: ({ row }) => {
        return (
          <DefaultRowCell>
            <Tag
              borderRadius="md"
              colorScheme={LEAD_RESPONDER_EMAIL_STATE_COLOR_MAP[row.state]}
            >
              <TagLabel>{capitalize(row.state)}</TagLabel>
            </Tag>
          </DefaultRowCell>
        );
      },
    },
    {
      accessor: "id",
      disableSortBy: true,
      width: "30px",
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <Menu isLazy={true} placement={"bottom-end"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  fontSize={ICON_SIZE}
                />
              }
              size="xs"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={() => handleGoToEmailDetails(row)}>
                View
              </MenuItem>
            </MenuList>
          </Menu>
        </DefaultRowCell>
      ),
    },
  ];
};
