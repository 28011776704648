import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountAnalyticsLeaderboardStore } from "./AccountAnalyticsLeaderboard.store";
import { AccountAnalyticsLeadAttributionStore } from "./AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import { AccountAnalyticsFiltersStore } from "./AccountAnalyticsFilters.store";

export class AccountAnalyticsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  public accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore;
  public accountAnalyticsLeaderboardStore: AccountAnalyticsLeaderboardStore;
  public accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountAnalyticsFiltersStore = new AccountAnalyticsFiltersStore();
    this.accountAnalyticsLeaderboardStore = new AccountAnalyticsLeaderboardStore(
      root,
      accountId,
      this.accountAnalyticsFiltersStore
    );
    this.accountAnalyticsLeadAttributionStore = new AccountAnalyticsLeadAttributionStore(
      root,
      accountId,
      this.accountAnalyticsFiltersStore
    );
  }

  public resetAnalyticStores() {
    this.accountAnalyticsFiltersStore = new AccountAnalyticsFiltersStore();
    this.accountAnalyticsLeaderboardStore = new AccountAnalyticsLeaderboardStore(
      this.root,
      this.accountId,
      this.accountAnalyticsFiltersStore
    );
    this.accountAnalyticsLeadAttributionStore = new AccountAnalyticsLeadAttributionStore(
      this.root,
      this.accountId,
      this.accountAnalyticsFiltersStore
    );
  }
}
