import React, { FC, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { HStack } from "@chakra-ui/react";

interface ISelectInputControlsProps {
  onClear: () => void;
  isValueSet: boolean;
  clearable?: boolean;
}

export const SelectInputControls: FC<ISelectInputControlsProps> = ({
  onClear,
  isValueSet,
  clearable,
}) => {
  const handleClear = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      onClear();
    },
    [onClear]
  );

  const shouldRenderClearBtn = useCallback(() => clearable && isValueSet, [
    clearable,
    isValueSet,
  ]);

  return (
    <HStack spacing={2} px={2} py={1}>
      {shouldRenderClearBtn() && (
        <FontAwesomeIcon
          icon={faXmark}
          fontSize={16}
          onClick={handleClear}
          cursor={"pointer"}
        />
      )}
      <FontAwesomeIcon icon={faAngleDown} fontSize={16} cursor={"pointer"} />
    </HStack>
  );
};
