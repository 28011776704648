import { LeadTypeEnum } from "enums/lead-type.enum";
import { TSelectOption } from "types/select-input.type";
import { capitalize } from "lodash";
import { getLeadTypeLabel } from "../utils/lead-type.utils";
import { LeadTypes } from "@leadpro/forms";

export const LEAD_TYPE_OPTIONS: TSelectOption<LeadTypes>[] = [
  {
    value: LeadTypes.Let,
    label: getLeadTypeLabel(LeadTypes.Let, true),
  },
  {
    value: LeadTypes.Sale,
    label: getLeadTypeLabel(LeadTypes.Sale, true),
  },
  {
    value: LeadTypes.Landlord,
    label: getLeadTypeLabel(LeadTypes.Landlord, true),
  },
  {
    value: LeadTypes.Vendor,
    label: getLeadTypeLabel(LeadTypes.Vendor, true),
  },
  {
    value: LeadTypes.MortgageRequest,
    label: getLeadTypeLabel(LeadTypes.MortgageRequest, true),
  },
  {
    value: LeadTypes.Storage,
    label: getLeadTypeLabel(LeadTypes.Storage, true),
  },
];

export const MANUAL_ADD_LEAD_TYPE_OPTIONS: TSelectOption<LeadTypeEnum>[] = [
  {
    value: LeadTypeEnum.Landlord,
    label: `${capitalize(
      getLeadTypeLabel(LeadTypeEnum.Landlord)
    )} - Has property to let`,
  },
  {
    value: LeadTypeEnum.Let,
    label: `${capitalize(
      getLeadTypeLabel(LeadTypeEnum.Let)
    )} - Would like to rent`,
  },
  {
    value: LeadTypeEnum.Sale,
    label: `${capitalize(
      getLeadTypeLabel(LeadTypeEnum.Sale)
    )} - Would like to buy`,
  },
  {
    value: LeadTypeEnum.Vendor,
    label: `${capitalize(
      getLeadTypeLabel(LeadTypeEnum.Vendor)
    )} - Has a property to sell`,
  },
  {
    value: LeadTypeEnum.MortgageRequest,
    label: `${capitalize(
      getLeadTypeLabel(LeadTypeEnum.MortgageRequest)
    )} - Interested in mortgages`,
  },
];
