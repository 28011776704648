import { BaseApi } from "./base.api";
import {
  TAccountIntegrationData,
  TAccountIntegrationsStaticData,
  TIntegrationConfig,
} from "types/account-integration.type";

export class AccountIntegrationsApi extends BaseApi {
  async fetchAvailableAccountIntegrations(
    accountId: number
  ): Promise<TAccountIntegrationsStaticData[]> {
    return this.get(`/accounts/${accountId}/integrations/available`);
  }

  async fetchAllAccountIntegrations(
    accountId: number
  ): Promise<TAccountIntegrationData<TIntegrationConfig>[]> {
    return this.get(`/accounts/${accountId}/integrations`);
  }

  async createAccountIntegration(
    accountId: number,
    data: Partial<TAccountIntegrationData<TIntegrationConfig>>
  ): Promise<TAccountIntegrationData<TIntegrationConfig>> {
    return this.post(`/accounts/${accountId}/integrations`, data);
  }

  async updateAccountIntegration(
    accountId: number,
    accountIntegrationId: number,
    data: Partial<TAccountIntegrationData<TIntegrationConfig>>
  ): Promise<TAccountIntegrationData<TIntegrationConfig>> {
    return this.put(
      `/accounts/${accountId}/integrations/${accountIntegrationId}`,
      data
    );
  }

  async deleteAccountIntegration(
    accountId: number,
    accountIntegrationId: number
  ): Promise<TAccountIntegrationData<TIntegrationConfig>> {
    return this.del(
      `/accounts/${accountId}/integrations/${accountIntegrationId}`
    );
  }
}
