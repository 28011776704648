import { Box } from "@chakra-ui/react";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { TSelectOptionValue } from "types/select-input.type";
import { useMemo } from "react";

export function DefaultSelectInputOption<
  D extends TSelectOptionValue,
  S = unknown
>({
  optionProps: { option, selectedOption, ...rest },
}: ISelectInputOptionProps<D, S>) {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <Box noOfLines={1}>{option.label}</Box>
    </SelectInputOptionBase>
  );
}
