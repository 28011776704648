import { AppStore } from "store/App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountDevelopersWebhookStore } from "./AccountDevelopersWebhook.store";
import { TAccountDevelopersWebhookConfig } from "types/account-developers.type";
import { AccountDevelopersApi } from "api/account-developers.api";
import { keyBy } from "lodash";

const developersApi = new AccountDevelopersApi();

export class AccountDevelopersWebhooksStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private webhookStores: IObservableArray<AccountDevelopersWebhookStore>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.webhookStores = observable.array<AccountDevelopersWebhookStore>();
  }

  get webhookStoresArray() {
    return this.webhookStores.slice();
  }

  get webhookDataArray() {
    return this.webhookStoresArray.map(store => store.webhookConfig);
  }

  get externalWebhooksDataArray() {
    return this.webhookDataArray.filter(w => !w.isInternal);
  }

  get internalWebhooksDataArray() {
    return this.webhookDataArray.filter(w => w.isInternal);
  }

  get webhookStoresMap() {
    return keyBy(this.webhookStoresArray, store => store.webhookConfig.id);
  }

  get webhookDataMap() {
    return keyBy(this.webhookDataArray, webhook => webhook.id);
  }

  private setAllWebhooks(webhooks: TAccountDevelopersWebhookConfig[]) {
    this.webhookStores.replace(
      webhooks.map(
        webhook =>
          new AccountDevelopersWebhookStore(this.root, this.accountId, webhook)
      )
    );
  }

  private addWebhook(webhookData: TAccountDevelopersWebhookConfig) {
    this.webhookStores.push(
      new AccountDevelopersWebhookStore(this.root, this.accountId, webhookData)
    );
  }

  private removeWebhook(webhookId: number) {
    this.webhookStores.replace(
      this.webhookStores.filter(store => store.webhookConfig.id !== webhookId)
    );
  }

  public async fetchAllWebhooks() {
    const webhooks = await developersApi.fetchAllAccountDevelopersWebhooks(
      this.accountId
    );
    this.setAllWebhooks(webhooks);
  }

  public async createWebhook(
    webhookData: Partial<TAccountDevelopersWebhookConfig>
  ) {
    const webhook = await developersApi.createAccountDevelopersWebhook(
      this.accountId,
      webhookData
    );
    this.addWebhook(webhook);
  }

  public async deleteWebhook(webhookId: number) {
    await developersApi.deleteAccountDevelopersWebhook(
      this.accountId,
      webhookId
    );
    this.removeWebhook(webhookId);
  }

  public async fetchWebhook(webhookId: number) {
    if (!!this.webhookStoresMap[webhookId]) {
      await this.webhookStoresMap[webhookId].fetchDetails();
    } else {
      const webhookData = await developersApi.fetchAccountDevelopersWebhook(
        this.accountId,
        webhookId
      );
      this.addWebhook(webhookData);
    }
  }
}
