export enum LeadUpdatedEventChangeFieldEnum {
  STATUS = "status",
  STAGE = "stage",
  ASSIGNED_TO_ID = "assignedToId",
  OFFICE_ID = "officeId",
  NEXT_ACTION_DATE = "nextActionDate",
  STATUS_ID = "statusId",
  // DELETE AFTER THIS IS FIXED ON BE, DUPLICATE OF STATUS_ID
  LEAD_STATUS_ID = "leadStatusId",
}
