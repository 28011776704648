import { observer } from "mobx-react";
import React, { FC, useMemo } from "react";
import { accountProductsConfigMap } from "constants/account-products-config";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ProductInfo } from "routes/dashboard/routes/tools/components/ProductInfo/ProductInfo";
import { AspectRatio, Box, Button, Center, Image } from "@chakra-ui/react";
import { SplashScreen } from "routes/dashboard/routes/tools/components/SplashScreen/SplashScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { InternalLink, TypeFormWidget } from "components";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { LEADS_ROUTE } from "constants/routes";
import { getTypeformOptions } from "utils/typeform.utils";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import {
  LEAD_RESPONDER_DIAGRAM,
  LEAD_RESPONDER_ILLUSTRATION,
} from "constants/image-paths";

const env = runtimeEnv();
const productConfig =
  accountProductsConfigMap[ApplicationProductEnum.MORTGAGES];

interface IProps {
  accountStore: UserAccountStore;
  isEnabled?: boolean;
}

export const MortgageReferralsSplashScreen: FC<IProps> = observer(
  ({ accountStore, isEnabled }) => {
    const {
      authStore: { authUser },
    } = useAppStore();
    const accountData = accountStore.account;
    const typeformWidgetOptions = useMemo(
      () =>
        getTypeformOptions({
          account: accountData?.id,
          email: authUser?.email,
          firstname: authUser?.firstName,
        }),
      [accountData, authUser]
    );

    return (
      <SplashScreen
        background={"orange.50"}
        topSection={
          <>
            <ProductInfo
              productConfig={productConfig}
              header={
                isEnabled
                  ? "Thank you for activating mortgage referrals."
                  : "You’re missing 88% of your mortgage referrals."
              }
              text={
                isEnabled ? (
                  <Box as={"span"}>
                    LeadPro is now actively identifying and referring mortgage
                    leads for you. You can track mortgage leads in the{" "}
                    <InternalLink as={"span"} href={LEADS_ROUTE}>
                      Leads dashboard
                    </InternalLink>
                    .
                  </Box>
                ) : (
                  "LeadPro carried out research and found that for every 100 potential mortgage referrals: staff manually referred 12 opportunities, while LeadPro automatically picked up the other 88!"
                )
              }
              customBullet={
                <Center color={"orange.600"} flexShrink={0}>
                  <FontAwesomeIcon icon={faCheckCircle} fontSize={28} />
                </Center>
              }
              bulletPoints={[
                "Significantly increase your business's revenue, with more converted mortgage referrals.",
                "Keep your existing mortgage broker, and put an automated safety net in place so you don’t miss any opportunities.",
                "Earn passive referral commission even from buyers that go on to complete with another agent.",
              ]}
              productColor={"orange"}
              colorScheme={"orange"}
              accountBillingStore={accountStore.accountBillingStore}
              customCTA={
                isEnabled ? (
                  <></>
                ) : (
                  <TypeFormWidget
                    typeFormId={env.REACT_APP_TYPEFORM_ID_MORTGAGE_REFERRALS}
                    options={typeformWidgetOptions}
                  >
                    <Button size={"lg"} colorScheme={"orange"}>
                      Add product
                    </Button>
                  </TypeFormWidget>
                )
              }
            />
            <AspectRatio ratio={1} width={"400px"}>
              <Image src={LEAD_RESPONDER_ILLUSTRATION} />
            </AspectRatio>
          </>
        }
        bottomSection={
          <>
            <Box fontSize={"3xl"} fontWeight={700}>
              How it works
            </Box>
            <Box position={"relative"}>
              <Image src={LEAD_RESPONDER_DIAGRAM} />
            </Box>
          </>
        }
      />
    );
  }
);
