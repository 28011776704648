import { Box } from "@chakra-ui/react";
import {
  TLeadEventChangeData,
  TLeadWorkflowUpdatedEventData,
} from "types/leadpro-event.type";
import { FC, useCallback, useMemo } from "react";
import { LeadActivityEventContent } from "./LeadActivityEventContent";
import { LeadWorkflowUpdatedEventChangeFieldEnum } from "enums/lead-workflow-updated-event-change-field.enum";
import { toSpace } from "utils/to-snake-case.utils";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { LeadWorkflowStatusEnum } from "enums/lead-workflow-status.enum";

interface IProps {
  accountStore: UserAccountStore;
  event: TLeadWorkflowUpdatedEventData;
}

export const LeadWorkflowUpdatedEventContent: FC<IProps> = observer(
  ({ accountStore, event }) => {
    const { workflowsMap } = accountStore.leadResponderStore;

    const getFieldDataLabels = useCallback(
      (
        change: TLeadEventChangeData<LeadWorkflowUpdatedEventChangeFieldEnum>
      ) => {
        switch (change.name) {
          case LeadWorkflowUpdatedEventChangeFieldEnum.WORKFLOW_STATUS:
            if (change.newValue === LeadWorkflowStatusEnum.ALREADY_PROCESSED) {
              return {
                name: (
                  <Box>
                    Lead was not sent the reply because they already received
                    it.
                  </Box>
                ),
              };
            }
            return {
              name: "changed status",
              oldValue: toSpace(change.oldValue as string),
              newValue: toSpace(change.newValue as string),
            };
          case LeadWorkflowUpdatedEventChangeFieldEnum.WORKFLOW_ID:
            const workflow = workflowsMap[change.newValue as number];
            return {
              name: (
                <Box>
                  Lead was picked up by{" "}
                  <Box as={"span"} color={"purple.500"}>
                    {workflow?.name || "Unknown"}
                  </Box>{" "}
                  workflow.
                </Box>
              ),
            };
          default:
            return null;
        }
      },
      [workflowsMap]
    );

    const changeLabels = useMemo(() => {
      return event.changes.map(
        (
          change: TLeadEventChangeData<LeadWorkflowUpdatedEventChangeFieldEnum>
        ) => {
          return getFieldDataLabels(change);
        }
      );
    }, [event, getFieldDataLabels]);

    return (
      <Box fontSize={"sm"}>
        {changeLabels.map((changeLabel, index) => {
          if (!changeLabel) return null;

          return (
            <LeadActivityEventContent
              key={event.id + index}
              name={changeLabel.name}
              oldValue={changeLabel.oldValue}
              newValue={changeLabel.newValue}
            />
          );
        })}
      </Box>
    );
  }
);
