import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import { TAutocallerConfiguration } from "types/autocaller.type";
import { AccountAutocallerTemplateStore } from "./AccountAutocallerTemplate.store";
import { TAutocallerDemoCallData } from "types/autocalled-demo-call.type";
import { AccountAutocallerApi } from "api/account-autocaller.api";

const autocallerApi = new AccountAutocallerApi();

export class AccountAutocallerStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountAutocallerConfigurationData: TAutocallerConfiguration | null;
  private autocallerStatus: boolean;
  public autocallerTemplateStore: AccountAutocallerTemplateStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountAutocallerConfigurationData = null;
    this.autocallerStatus = false;
    this.autocallerTemplateStore = new AccountAutocallerTemplateStore(
      accountId
    );
  }

  get isAutocallerEnabled() {
    return this.autocallerStatus;
  }

  public async fetchAutocallerStatus() {
    this.autocallerStatus = await autocallerApi.fetchAccountAutocallerStatus(
      this.accountId
    );
  }

  get accountAutocallerConfiguration() {
    return this.accountAutocallerConfigurationData;
  }

  public setAccountAutocallerConfigurationData(
    configuration: TAutocallerConfiguration
  ) {
    this.accountAutocallerConfigurationData = configuration;
  }

  public async fetchAccountAutocallerConfiguration() {
    const configuration = await autocallerApi.fetchAccountAutocallerConfiguration(
      this.accountId
    );

    this.setAccountAutocallerConfigurationData(configuration);
  }

  public async updateAccountAutocallerConfiguration(
    configuration: Partial<TAutocallerConfiguration>
  ) {
    const updatedConfiguration = await autocallerApi.updateAccountAutocallerConfiguration(
      this.accountId,
      configuration
    );

    this.setAccountAutocallerConfigurationData(updatedConfiguration);
  }

  public async performAutocallerDemo(callData: TAutocallerDemoCallData) {
    await autocallerApi.performAutocallerDemoForAccount(
      this.accountId,
      callData
    );
  }
}
