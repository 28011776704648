import React, { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ApiMessageStack, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TUrlSyncConfig,
} from "types/account-integration.type";
import { ToggleIntegrationActiveSwitchInput } from "../ToggleIntegrationActiveSwitchInput";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  TUrlSyncIntegrationConfigFieldValues,
  urlSyncIntegrationConnectValidationSchema,
} from "utils/validation-schemas/url-sync-integration.validation";

type TFieldValues = TUrlSyncConfig & {
  active: boolean;
};

interface IUrlSyncFormProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const UrlSyncIntegrationForm: FC<IUrlSyncFormProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.URL_SYNC
    ] as TAccountIntegrationData<TUrlSyncConfig>;
    const urlSyncStore =
      accountIntegrationsStore.accountIntegrationUrlSyncStore;
    const mergeTagOptions = urlSyncStore.mergeTagGroupOptionsArray;

    const initialValues = useMemo(() => {
      return {
        urlTemplate: accountIntegration.integrationConfig.urlTemplate || "",
        active: accountIntegration.active,
      };
    }, [accountIntegration]);

    const {
      handleSubmit,
      watch,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(urlSyncIntegrationConnectValidationSchema),
    });

    const { active } = watch();

    const handleUpdateIntegrationConfig = useCallback(
      async ({ active, ...rest }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<
            TUrlSyncIntegrationConfigFieldValues
          >(accountIntegration.id, {
            type: accountIntegration.type,
            active: active,
            integrationConfig: rest,
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Url sync integration configuration updated."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(handleUpdateIntegrationConfig)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connecting with Url Sync"}
            control={control}
            value={active}
            name={"active"}
          />
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"urlTemplate"}
              control={control}
              label={"Base Url"}
              type={FormControlsTypeEnum.MERGE_TAG_INPUT}
              additionalProps={{
                singleLine: false,
                mergeTagOptions: mergeTagOptions,
              }}
            />
            <Box mt={3}>
              Use our lead templator to choose what lead data you want to
              include in your parameters.Type {"{"} to see the available merge
              tags.
            </Box>
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
