import { WebhookEventTypes } from "enums/account-developer-webhook-events.enum";
import React, { useCallback } from "react";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { Switch } from "@chakra-ui/react";
import { TDeveloperWebhookEventConfigData } from "types/account-developers.type";

interface IDeveloperConfigWebhookEventTableActionCellProps {
  row: TDeveloperWebhookEventConfigData;
  onWebhookEventChange: (id: WebhookEventTypes, value: boolean) => void;
}

export const DeveloperConfigWebhookEventTableActionCell: React.FC<IDeveloperConfigWebhookEventTableActionCellProps> = ({
  row,
  onWebhookEventChange,
}) => {
  const handleWebhookEventChange = useCallback(() => {
    onWebhookEventChange(row.id, !row.value);
  }, [row.value, onWebhookEventChange, row.id]);

  return (
    <DefaultRowCell>
      <Switch isChecked={row.value} onChange={handleWebhookEventChange} />
    </DefaultRowCell>
  );
};
