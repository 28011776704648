import { BaseApi } from "./base.api";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { TTypeformForm } from "../types/account-typeform-forms.type";

export class LeadResponderApi extends BaseApi {
  async fetchWorkflows(accountId: number): Promise<TLeadResponderWorkflow[]> {
    return this.get(`/accounts/${accountId}/workflows`);
  }

  async fetchWorkflow(
    accountId: number,
    workflowId: number
  ): Promise<TLeadResponderWorkflow> {
    return this.get(`/accounts/${accountId}/workflows/${workflowId}`);
  }

  async createWorkflow(
    accountId: number,
    workflow: Partial<TLeadResponderWorkflow>
  ): Promise<TLeadResponderWorkflow> {
    return this.post(`/accounts/${accountId}/workflows`, workflow);
  }

  async updateWorkflow(
    accountId: number,
    workflowId: number,
    workflow: Partial<TLeadResponderWorkflow>
  ): Promise<TLeadResponderWorkflow> {
    return this.patch(
      `/accounts/${accountId}/workflows/${workflowId}`,
      workflow
    );
  }

  async deleteWorkflow(accountId: number, workflowId: number): Promise<void> {
    await this.del(`/accounts/${accountId}/workflows/${workflowId}`);
  }

  async reorderWorkflows(
    accountId: number,
    workflowIds: number[]
  ): Promise<TLeadResponderWorkflow[]> {
    return this.put(`/accounts/${accountId}/workflows/reorder`, {
      ids: workflowIds,
    });
  }

  //LEAD RESPONDER TYPEFORM FORMS
  async fetchAccountTypeformForms(accountId: number): Promise<TTypeformForm[]> {
    return this.get(`/accounts/${accountId}/typeform/forms`);
  }
}
