import React, { FC } from "react";
import { AspectRatio, Box, Center, Image } from "@chakra-ui/react";
import { ProductInfo } from "routes/dashboard/routes/tools/components/ProductInfo/ProductInfo";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { accountProductsConfigMap } from "constants/account-products-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { LeadResponderHowItWorks } from "./LeadResponderHowItWorks";
import { SplashScreen } from "routes/dashboard/routes/tools/components/SplashScreen/SplashScreen";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { LEAD_RESPONDER_ILLUSTRATION } from "constants/image-paths";

const productConfig =
  accountProductsConfigMap[ApplicationProductEnum.LEAD_RESPONDER];

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderSplashScreen: FC<IProps> = observer(
  ({ accountStore }) => {
    return (
      <SplashScreen
        background={"teal.50"}
        topSection={
          <>
            <ProductInfo
              productConfig={productConfig}
              header={"Convert applicants into instructions"}
              bulletPoints={[
                "Instantly respond to every portal enquiry with a branded email and text message.",
                "Send all applicants a pre-qualification questionnaire to find out if they have a property to sell or let.",
                "Automatically refer out of area valuation leads to your branch network.",
              ]}
              finePrint={`This will increase your monthly bill by £${productConfig.pricing.flatFee}. Plan includes ${productConfig.pricing.unitsIncluded} leads. Additional leads are charged at a rate of £${productConfig.pricing.pricePerExtraUnit} per lead.`}
              productColor={"teal"}
              colorScheme={"teal"}
              accountBillingStore={accountStore.accountBillingStore}
              customBullet={
                <Center color={"teal.600"} flexShrink={0}>
                  <FontAwesomeIcon icon={faCheckCircle} fontSize={28} />
                </Center>
              }
            />
            <AspectRatio ratio={1} width={"400px"}>
              <Image src={LEAD_RESPONDER_ILLUSTRATION} />
            </AspectRatio>
          </>
        }
        bottomSection={
          <>
            <Box fontSize={"3xl"} fontWeight={700}>
              How it works
            </Box>
            <LeadResponderHowItWorks />
          </>
        }
      />
    );
  }
);
