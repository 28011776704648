import { AvatarTag } from "./AvatarTag";
import React, { FC, useMemo } from "react";

interface IReferralTagProps {
  name: string;
  logoUrl?: string;
}

export const ReferralTag: FC<IReferralTagProps> = ({ name, logoUrl }) => {
  const avatarStyling = useMemo(() => {
    if (!!logoUrl) {
      return {
        bg: "transparent",
      };
    }
  }, [logoUrl]);

  return (
    <AvatarTag
      label={name}
      avatarUrl={logoUrl}
      avatarStyling={avatarStyling}
      colorScheme={"white"}
      boxShadow={"md"}
      bg={"leadpro.100"}
    />
  );
};
