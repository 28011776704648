import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const DEFAULT_WIDTH = "400px";

interface IRelativeDrawerProps extends BoxProps {
  isOpen: boolean;
}

export const RelativeDrawer: React.FC<IRelativeDrawerProps> = ({
  children,
  width = DEFAULT_WIDTH,
  isOpen,
  ...rest
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      position={"absolute"}
      // TODO: ADD OTHER POSITIONING OPTIONS
      // TODO: ADD SOME SLIDING ANIMATION
      right={0}
      top={"1px"}
      height={"100%"}
      width={width}
      background={"#fff"}
      borderLeftWidth="1px"
      borderStyle="solid"
      borderColor="gray.100"
      boxShadow={"0 7px 14px 0 rgba(0,0,0,0.1), 0 3px 6px 0 rgba(0,0,0,.07)"}
      transform={`translateX(${isOpen ? 0 : 100}%)`}
      transition="all .25s"
      {...rest}
    >
      {children}
    </Box>
  );
};
