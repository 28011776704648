import { Skeleton, VStack } from "@chakra-ui/react";

export const DropdownFilterLoadingSkeleton = () => {
  return (
    <VStack spacing={3} p={5}>
      <Skeleton height="20px" width={"100%"} />
      <Skeleton height="20px" width={"100%"} />
      <Skeleton height="20px" width={"100%"} />
    </VStack>
  );
};
