import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";

export type TRightmoveIntegrationConfigFieldValues = {
  brandId: string;
  networkId: string;
  certificate: string;
  passphrase: string;
  testMode: boolean;
};

export const rightmoveIntegrationInitialValues: TRightmoveIntegrationConfigFieldValues = {
  brandId: "",
  networkId: "",
  certificate: "",
  passphrase: "",
  testMode: false,
};

export const rightmoveIntegrationConnectValidationSchema = Yup.object().shape({
  brandId: Yup.number()
    .typeError("Brand ID must be a number.")
    .required(REQUIRED_FIELD),
  networkId: Yup.number().required(REQUIRED_FIELD),
  certificate: Yup.string().required(REQUIRED_FIELD),
  passphrase: Yup.string().required(REQUIRED_FIELD),
  testMode: Yup.boolean(),
});
