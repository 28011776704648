import React from "react";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PageToolPages } from "../../../components/PageTool/PageToolPages";

interface IProps {
  accountStore: UserAccountStore;
  isEnabled: boolean;
}

export const InstantValuationToolPages: React.FC<IProps> = observer(
  ({ accountStore }) => {
    const accountPageToolStore =
      accountStore.accountPagesStore.accountIVTPagesStore;

    return (
      <PageToolPages
        accountPageToolStore={accountPageToolStore}
        tourId={DashboardTourEnum.IVT_TOUR}
      />
    );
  }
);
