import React, { useMemo } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { ApplicationTypes } from "enums/application-type.enum";
import { TPageData } from "types/page.type";
import {
  getIVTFullPageScript,
  getIVTPopupFullPageEmail,
  getIVTPopupScript,
  getIVTPopupScriptEmail,
} from "utils/instant-valuation-tool.utils";
import { InstantValuationToolCodeSnippetInstructions } from "./InstantValuationToolCodeSnippetInstructions";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
  page: TPageData;
}

export const InstantValuationToolCodeSnippetSettings: React.FC<IProps> = observer(
  ({ accountStore, page }) => {
    const accountData = accountStore.account;
    const application = useMemo(() => {
      return accountData.applications.find(
        app => app.applicationType === ApplicationTypes.PropertyValuation
      );
    }, [accountData]);

    const popupCode = useMemo(() => getIVTPopupScript(application, page), [
      application,
      page,
    ]);
    const popupCodeEmail = useMemo(
      () => getIVTPopupScriptEmail(application, page),
      [application, page]
    );

    const fullPageCode = useMemo(
      () => getIVTFullPageScript(application, page),
      [application, page]
    );
    const fullPageCodeEmail = useMemo(
      () => getIVTPopupFullPageEmail(application, page),
      [application, page]
    );

    return (
      <Tabs isFitted variant="enclosed" maxW={"800px"} mt={20}>
        <TabList>
          <Tab>Popup widget</Tab>
          <Tab>Full page widget</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <InstantValuationToolCodeSnippetInstructions
              code={popupCode}
              codeEmail={popupCodeEmail}
              instructions={
                "You can also use your valuation tool as a popup on your website. Copy the following snippet and paste it in to your website template, before the closing <body> tag."
              }
            />
          </TabPanel>
          <TabPanel px={0}>
            <InstantValuationToolCodeSnippetInstructions
              code={fullPageCode}
              codeEmail={fullPageCodeEmail}
              instructions={
                "You can also use your valuation tool as a separate page on your website. Copy the following snippet and paste it in to your website's new page."
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }
);
