import React, { FC, useCallback, useMemo } from "react";
import { TSelectValue } from "types/select-input.type";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import {
  ApiMessageStack,
  LeadStatusSelectOption,
  LeadStatusSelectOptionsRenderer,
  SingleSelectInput,
} from "components";
import { useToast } from "@chakra-ui/react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { LeadInfoRowValue } from "../../LeadInfoRow";
import { TLeadStatus } from "types/lead-status.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import { LeadControlRow } from "./LeadControlRow";
import { LeadControlRowLabel } from "./LeadControlLabel";

import { StrictLeadProgressionLeadStatusPrompt } from "routes/dashboard/components/prompts/StrictLeadProgressionLeadStatusPrompt/StrictLeadProgressionLeadStatusPrompt";
interface IProps {
  leadStore: AccountLeadStore;
  leadStatusesStore: AccountLeadStatusesStore;
  isStrictModeEnabled: boolean;
  disabled?: boolean;
}

export const LeadStatusSelect: FC<IProps> = observer(
  ({ leadStatusesStore, leadStore, isStrictModeEnabled, disabled }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();
    const leadStatusOptions = leadStatusesStore.accountLeadStatusOptions;

    const handleStatusChange = async (status: TSelectValue<TLeadStatus>) => {
      if (isStrictModeEnabled) {
        return handleStrictLeadProgressionLeadStatusChange(status);
      } else {
        await handleUpdateStatusChange(status);
      }
    };

    const handleUpdateStatusChange = useCallback(
      async (status: TSelectValue<TLeadStatus>) => {
        if (!!status) {
          try {
            await leadStore.update({ statusId: status.id });
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          }
        }
      },
      [toast, leadStore]
    );

    const handleStrictLeadProgressionLeadStatusChange = useCallback(
      (status: TSelectValue<TLeadStatus>) => {
        !!status &&
          setModal(
            <StrictLeadProgressionLeadStatusPrompt
              leadStatus={status}
              leadStore={leadStore}
              leadStatusesStore={leadStatusesStore}
              closePrompt={unSetModal}
            />
          );
      },
      [leadStore, leadStatusesStore, setModal, unSetModal]
    );

    const selectedOption = useMemo(() => {
      return leadStatusOptions.find(
        option => option.value.id === leadStore.lead.statusId
      );
    }, [leadStatusOptions, leadStore.lead.statusId]);

    return (
      <LeadControlRow>
        <LeadControlRowLabel>Status</LeadControlRowLabel>
        <LeadInfoRowValue>
          <SingleSelectInput<TLeadStatus>
            noBorder
            disabled={disabled}
            value={selectedOption?.value || null}
            options={leadStatusOptions}
            onChange={handleStatusChange}
            optionComponent={optionProps => (
              <LeadStatusSelectOption optionProps={optionProps} />
            )}
            optionsRenderer={optionProps => (
              <LeadStatusSelectOptionsRenderer optionProps={optionProps} />
            )}
          />
        </LeadInfoRowValue>
      </LeadControlRow>
    );
  }
);
