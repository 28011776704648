import { BaseApi } from "./base.api";
import { TUserData } from "types/user-data.type";
import { TWebPushSettings } from "types/web-push.type";
import { TLeadProSignupData } from "types/token.type";

export class AuthApi extends BaseApi {
  async signup(
    data: TLeadProSignupData,
    reCaptchaToken: string
  ): Promise<void> {
    await this.post("/signup", data, {
      headers: {
        recaptchaToken: reCaptchaToken,
      },
    });
  }

  async register(
    signupToken: string,
    password: string,
    reCaptchaToken: string
  ): Promise<void> {
    await this.post(
      "/register",
      {
        signupToken,
        password,
      },
      {
        headers: {
          recaptchaToken: reCaptchaToken,
        },
      }
    );
  }

  async epropRegister(
    signupToken: string,
    password: string,
    reCaptchaToken: string
  ): Promise<void> {
    return this.post("/eprop/register", {
      signupToken,
      password,
    });
  }

  async login(
    email: string,
    password: string,
    reCaptchaToken: string
  ): Promise<{ token: string }> {
    return this.post(
      "/login",
      {
        email,
        password,
      },
      {
        headers: {
          recaptchaToken: reCaptchaToken,
        },
      }
    );
  }

  async resetPassword(email: string, reCaptchaToken: string): Promise<void> {
    const { data } = await this.post(
      "/reset-password",
      {
        email: email,
      },
      {
        headers: {
          recaptchaToken: reCaptchaToken,
        },
      }
    );

    return data;
  }

  async setPassword(
    password: string,
    token: string,
    reCaptchaToken: string
  ): Promise<void> {
    const { data } = await this.post(
      "/set-password",
      {
        token: token,
        password: password,
      },
      {
        headers: {
          recaptchaToken: reCaptchaToken,
        },
      }
    );

    return data;
  }

  async verifyResetPasswordToken(token: string): Promise<void> {
    return this.post("/validate-reset-password-token", {
      token: token,
    });
  }

  async verifySignupToken(token: string): Promise<void> {
    return this.post("/validate-signup-token", {
      token: token,
    });
  }

  async updatePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    return this.post(`/update-password`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  }

  async me(): Promise<TUserData> {
    return this.get("/me");
  }

  async getStats(): Promise<{ noOfProcessedLeads: number }> {
    return this.get("/me/stats");
  }

  async updateWebPushSettings(
    webPushSettings: TWebPushSettings
  ): Promise<{ settings: TWebPushSettings }> {
    return this.put("/me/web-push/settings", {
      ...webPushSettings,
    });
  }

  async createWebPushSubscription(
    webPushSubscription: PushSubscription
  ): Promise<void> {
    return this.post("/me/web-push/subscriptions", {
      pushSubscriptionJSON: webPushSubscription,
    });
  }

  async updateUser(userData: Partial<TUserData>): Promise<void> {
    return this.put(`/update-user`, userData);
  }

  async disable2FA(): Promise<void> {
    return this.post("/2fa/sms/disable");
  }

  async enable2FA(code: string): Promise<void> {
    return this.post("/2fa/sms/enable", {
      code,
    });
  }

  async request2FACode(): Promise<void> {
    return this.post("/2fa/sms/send-code");
  }

  async verify2FACode(code: string): Promise<{ token: string }> {
    return this.post("/2fa/sms/verify-code", {
      code,
    });
  }

  async finalizeInvite(inviteToken: string) {
    return this.post("/finalize-invite", {
      inviteToken,
    });
  }
}
