import { LeadAttributionGroupedEnum } from "../enums/account-lead-attribution.enum";

export const leadAttributionGroupColorMap: Record<
  LeadAttributionGroupedEnum,
  string
> = {
  [LeadAttributionGroupedEnum.APPOINTMENTS]: "#383F55",
  [LeadAttributionGroupedEnum.VALUATIONS]: "#6072B0",
  [LeadAttributionGroupedEnum.INSTRUCTIONS]: "#FF7495",
  [LeadAttributionGroupedEnum.COMPLETIONS]: "#FFA56F",
  // these ones are not defined in designs
  [LeadAttributionGroupedEnum.TENANCIES]: "leadpro.400",
  [LeadAttributionGroupedEnum.EXCHANGES]: "leadpro.400",
  [LeadAttributionGroupedEnum.OFFERS]: "leadpro.400",
  [LeadAttributionGroupedEnum.OTHERS]: "leadpro.400",
};
