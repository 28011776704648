import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { SelectInputOptionBase } from "components/select-input/SelectInputOptionBase";
import { TReapitNegotiatorRelation } from "types/reapit.type";

interface IReapitNegotiatorData {
  reapitData: {
    reapitId: string;
    reapitEmail: string;
  };
  syncData: TReapitNegotiatorRelation;
}

interface IReapitNegotiatorIntegrationSyncSelectOptionsRendererProps {
  optionProps: IGetOptionPropsData<string, IReapitNegotiatorData>;
}

export const ReapitNegotiatorIntegrationSyncSelectOption: FC<IReapitNegotiatorIntegrationSyncSelectOptionsRendererProps> = ({
  optionProps: { option, selectedOption, ...rest },
}) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);

  const isSynced = useMemo(() => !!rest.onClick && !!option.data?.syncData, [
    option,
    rest,
  ]);

  return (
    <SelectInputOptionBase
      {...rest}
      option={option}
      isSelected={isSelected}
      isDisabled={isSynced}
    >
      <Box width={"100%"}>
        <Flex
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontWeight={"bold"} color={isSynced ? "gray.400" : undefined}>
            {option.label}
          </Text>
          {isSynced && <Tag colorScheme="teal">Synced</Tag>}
        </Flex>
        <Flex
          width={"100%"}
          color={isSynced ? "gray.200" : "gray.400"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          gap={1}
          mt={0.5}
        >
          <Text>{option.data?.reapitData.reapitEmail}</Text>
          <Text>{option.data?.reapitData.reapitId}</Text>
        </Flex>
      </Box>
    </SelectInputOptionBase>
  );
};
