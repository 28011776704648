import { observer } from "mobx-react";
import { Box, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";

interface IProps {
  accountAnalyticsStore: AccountAnalyticsStore;
  leadAttributionType: LeadAttributionTypesEnum;
}

export const LeadAttributionReportSegmentSummary: FC<IProps> = observer(
  ({ accountAnalyticsStore, leadAttributionType }) => {
    const {
      accountAnalyticsLeadAttributionStore,
      accountAnalyticsFiltersStore,
    } = accountAnalyticsStore;
    const reportData =
      accountAnalyticsLeadAttributionStore.leadAttributionReportDataByType[
        leadAttributionType
      ];

    const filterData =
      accountAnalyticsLeadAttributionStore
        .accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
        leadAttributionType
      ];

    return (
      <Box color={"leadpro.500"} maxWidth={"450px"} flexGrow={1}>
        <Box color={"leadpro.700"} fontWeight={"bold"}>
          Leading {reportData.type}s
        </Box>
        <VStack spacing={10} py={"120px"}>
          <Box textAlign={"center"} fontSize={"sm"}>
            <Box color={"leadpro.700"} fontSize={"28px"} fontWeight={"bold"}>
              £{reportData.totalEstValue.toLocaleString()}
            </Box>
            <Box>
              <Box>Estimated value generated from</Box>
              <Box fontWeight={"bold"}>All {reportData.type}s.</Box>
            </Box>
          </Box>
          <Box textAlign={"center"} fontSize={"sm"}>
            <Box fontSize={"24px"} fontWeight={"bold"}>
              £
              {accountAnalyticsLeadAttributionStore.totalEstValuesMapByTypeFiltered[
                leadAttributionType
              ].toLocaleString()}
            </Box>
            <Box>
              <Box>Estimated value generated from</Box>
              <Box fontWeight={"bold"}>
                {filterData.length} displayed {reportData.type}s.
              </Box>
            </Box>
          </Box>
          <Box textAlign={"center"} fontSize={"sm"}>
            <Box>Estimated values calculated from</Box>
            <Box>
              <b>{accountAnalyticsFiltersStore.dateRangeFormated.startDate}</b>{" "}
              to <b>{accountAnalyticsFiltersStore.dateRangeFormated.endDate}</b>
              .
            </Box>
          </Box>
        </VStack>
      </Box>
    );
  }
);
