import { observer } from "mobx-react";
import React, { FC, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { PropertyDetailsCard } from "./PropertyDetailsCard/PropertyDetailsCard";
import { AccountPropertiesStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperties.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PROPERTIES_ROUTE } from "constants/routes";
import { Redirect } from "react-router-dom";
import LeadsContent from "../../../leads/components/LeadsContent";
import { propertyLeadsTableColumnDef } from "./PropertyDetailsCard/propertyLeadsTableColumnDef";

interface IProps {
  accountStore: UserAccountStore;
  accountPropertiesStore: AccountPropertiesStore;
  propertyId: number;
}

export const PropertyDetailsContent: FC<IProps> = observer(
  ({ accountStore, accountPropertiesStore, propertyId }) => {
    const {
      accountLeadSourcesStore: { leadSourcesMapBySource },
      accountLeadStatusesStore: { leadStatusesMapByStatusId },
      accountOfficesStore: { accountOfficesDataMap },
      portalsMap,
      account: { logoUrl },
    } = accountStore;

    const propertyStore = accountPropertiesStore.propertyStoresMap[propertyId];

    const tableColumns = useMemo(() => {
      if (!propertyStore) return [];

      const accountUsersMap = propertyStore?.propertyLeadsStore.accountUsersMap;
      return propertyLeadsTableColumnDef(
        leadSourcesMapBySource,
        leadStatusesMapByStatusId,
        accountOfficesDataMap,
        accountUsersMap,
        logoUrl,
        portalsMap
      );
    }, [
      leadSourcesMapBySource,
      leadStatusesMapByStatusId,
      propertyStore,
      accountOfficesDataMap,
      logoUrl,
      portalsMap,
    ]);

    // TODO PROPERTIES: remove once we have endpoint to fetch property details
    if (!propertyStore) {
      return (
        <Redirect
          to={{
            pathname: PROPERTIES_ROUTE,
          }}
        />
      );
    }

    return (
      <Box display={"flex"} width={"100"} height={"100%"} flexDirection={"row"}>
        <Box flexGrow={0} flexShrink={0} width={"500px"}>
          <PropertyDetailsCard
            accountStore={accountStore}
            propertyStore={propertyStore}
          />
        </Box>
        <Box display={"flex"} flexGrow={1} overflow={"hidden"}>
          <LeadsContent
            accountStore={accountStore}
            tableColumns={tableColumns}
            showStageTabs={false}
            showNotifications={false}
            includeSpeedScores={true}
            customLeadsStore={propertyStore.propertyLeadsStore}
          />
        </Box>
      </Box>
    );
  }
);
