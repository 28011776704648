import { Box, Button } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { Error, Loader } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { ToolTour } from "routes/dashboard/components/tours/ToolTour/ToolTour";
import { observer } from "mobx-react";
import { PageToolPagesStore } from "./PageToolPages.local-store";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { PageList } from "./PageList/PageList";
import { ToolTours } from "constants/tool-tours-links";
import { IAccountPageToolStore } from "store/UserAccounts/UserAccount/AccountPages/IAccountPageToolStore";
import { useHistory } from "react-router-dom";
import { TPageData } from "types/page.type";

interface IProps {
  accountPageToolStore: IAccountPageToolStore;
  additionalMenuItemsRenderer?: (page: TPageData) => React.ReactNode;
  tourId?: ToolTours;
}

export const PageToolPages: React.FC<IProps> = observer(
  ({ accountPageToolStore, additionalMenuItemsRenderer, tourId }) => {
    const history = useHistory();
    const localStore = useLocalStore(PageToolPagesStore, {
      accountPageToolStore,
    });

    const handleOpenCreateNewPage = useCallback(() => {
      history.push(`${history.location.pathname}/new`);
    }, [history]);

    // fetching pages
    useEffect(() => {
      localStore.fetchData();
    }, [localStore, accountPageToolStore]);

    return (
      <Box
        position={"relative"}
        textAlign={"center"}
        overflow={"hidden"}
        height={"100%"}
        width={"100%"}
      >
        {localStore.loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Loader />
        )}
        {localStore.loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {localStore.loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              overflow={"hidden"}
            >
              <Box width={"100%"} textAlign={"right"} p={5}>
                <Button colorScheme={"blue"} onClick={handleOpenCreateNewPage}>
                  New Page
                </Button>
              </Box>
              <Box width={"100%"} flexGrow={1} overflow={"auto"} p={5}>
                <PageList
                  pageToolPagesStore={localStore}
                  additionalMenuItemsRenderer={additionalMenuItemsRenderer}
                />
              </Box>
            </Box>
            {!!tourId && <ToolTour tourId={tourId} />}
          </>
        )}
      </Box>
    );
  }
);
