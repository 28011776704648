import { Error, Loader } from "components";
import React, { useEffect, useState, MutableRefObject } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Box } from "@chakra-ui/react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { OfficeMembershipsForm } from "./OfficeMembershipsForm";

interface IProps {
  officeStore: AccountOfficeStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const OfficeMemberships: React.FC<IProps> = observer(
  ({ officeStore, containerRef }) => {
    const { uiStore } = useAppStore();
    const officeMembershipOptions = uiStore.officeMembershipOptionsArray;
    const selectedOfficeMemberships = officeStore.officeMembershipsArray;
    const [officeMembershipsStatus, setOfficeMembershipsStatus] = useState<
      ApiRequestStatusEnum
    >(ApiRequestStatusEnum.NONE);

    useEffect(() => {}, [uiStore, officeMembershipOptions.length]);

    useEffect(() => {
      const fetchOfficeMemberships = async () => {
        try {
          setOfficeMembershipsStatus(ApiRequestStatusEnum.PENDING);
          const promises: Promise<void>[] = [
            officeStore.fetchOfficeMemberships(),
          ];
          if (!officeMembershipOptions.length) {
            promises.push(uiStore.fetchOfficeMembershipOptions());
          }
          await Promise.all(promises);

          setOfficeMembershipsStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setOfficeMembershipsStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchOfficeMemberships();
    }, [officeStore, uiStore, officeMembershipOptions.length]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        {officeMembershipsStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            textAlign={"center"}
          >
            <Loader />
          </Box>
        )}
        {officeMembershipsStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {officeMembershipsStatus === ApiRequestStatusEnum.SUCCESS && (
          <OfficeMembershipsForm
            key={selectedOfficeMemberships.length}
            officeStore={officeStore}
            containerRef={containerRef}
          />
        )}
      </Box>
    );
  }
);
