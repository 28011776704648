import {
  RelativeDrawer,
  StandardDrawerBody,
  StandardDrawerClose,
  StandardDrawerHeader,
} from "components";
import React, { FC, useCallback } from "react";
import { LeadDetails } from "./LeadDetails";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { LeadBanner } from "./LeadBanner";
import { Flex } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadDeleteButton } from "./LeadDeleteButton";
import { LeadDrawerIntegrationsDropdown } from "./LeadDrawerIntegrationsDropdown";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";

interface IProps {
  accountStore: UserAccountStore;
  accountLeadsStore: AccountLeadsStore;
}

export const LeadDrawer: FC<IProps> = observer(
  ({ accountStore, accountLeadsStore }) => {
    const { accountLeadStatusesStore } = accountStore;
    const { selectLead, selectedLeadStore } = accountLeadsStore;

    const closeLeadDrawer = useCallback(async () => {
      await selectLead(null);
    }, [selectLead]);

    return (
      <RelativeDrawer
        isOpen={!!selectedLeadStore}
        width={"650px"}
        key={selectedLeadStore?.lead.id}
        {...{ tour: `${DashboardTourEnum.GENERAL_TOUR}-2` }}
      >
        {!!selectedLeadStore && (
          <>
            <StandardDrawerHeader>
              <Flex flexGrow={1} justifyContent={"space-between"}>
                <LeadDeleteButton
                  leadStore={selectedLeadStore}
                  leadStatusesStore={accountLeadStatusesStore}
                />
                <LeadDrawerIntegrationsDropdown accountStore={accountStore} />
              </Flex>
              <StandardDrawerClose onClose={closeLeadDrawer} />
            </StandardDrawerHeader>
            <StandardDrawerBody>
              <LeadBanner
                leadStore={selectedLeadStore}
                leadStatusesStore={accountLeadStatusesStore}
              />
              <LeadDetails
                leadStore={selectedLeadStore}
                accountStore={accountStore}
              />
            </StandardDrawerBody>
          </>
        )}
      </RelativeDrawer>
    );
  }
);
