import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalOverlay, ModalProps } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface IOptionalModalOptions {
  key?: string;
  useOverlay?: boolean;
  closeOnOverlayClick?: boolean;
  size?: ModalProps["size"];
}

interface IModalOptions extends IOptionalModalOptions {
  key: string;
  useOverlay: boolean;
  closeOnOverlayClick: boolean;
}

const DEFAULT_MODAL_KEY = "modal";
const DEFAULT_MODAL_OPTIONS: IModalOptions = {
  key: DEFAULT_MODAL_KEY,
  useOverlay: true,
  closeOnOverlayClick: true,
  size: undefined,
};
const backupModals: Record<
  string,
  { modal: React.ReactNode; options: IModalOptions }
> = {};

export const ActionPromptContext = React.createContext<
  | {
      setModal: (
        modal: React.ReactNode,
        options?: IOptionalModalOptions
      ) => void;
      unSetModal: (key?: string) => void;
    }
  | undefined
>(undefined);

export const ActionPromptProvider = (props: any) => {
  const [modals, setModals] = useState<
    Record<string, { modal: React.ReactNode; options: IModalOptions }>
  >({});

  const unSetModal = useCallback(
    (key: string = DEFAULT_MODAL_KEY) => {
      delete backupModals[key];
      setModals({ ...backupModals });
    },
    [setModals]
  );

  const setModal = useCallback(
    (modal: React.ReactNode, options?: IOptionalModalOptions) => {
      const defaultedOptions = { ...DEFAULT_MODAL_OPTIONS, ...options };
      backupModals[defaultedOptions.key] = { modal, options: defaultedOptions };
      setModals({ ...backupModals });
    },
    [setModals]
  );

  return (
    <ActionPromptContext.Provider value={{ unSetModal, setModal }} {...props}>
      {props.children}
      {Object.keys(modals)
        .reverse()
        .map(modalKey => (
          <ActionPrompt
            modal={modals[modalKey].modal}
            unSetModal={unSetModal}
            modalKey={modalKey}
            key={modalKey}
            useOverlay={modals[modalKey].options.useOverlay}
            closeOnOverlayClick={modals[modalKey].options.closeOnOverlayClick}
            size={modals[modalKey].options.size}
          />
        ))}
    </ActionPromptContext.Provider>
  );
};

interface IActionPromptProps {
  modal: React.ReactNode;
  unSetModal: (key: string) => void;
  modalKey: string;
  useOverlay: boolean;
  closeOnOverlayClick: boolean;
  size: ModalProps["size"];
}

export const ActionPrompt: React.FC<IActionPromptProps> = ({
  modal,
  unSetModal,
  modalKey,
  useOverlay,
  closeOnOverlayClick,
  size = "xl",
}) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => unSetModal(modalKey));
    return unlisten;
  }, [history, unSetModal, modalKey]);

  return (
    <Modal
      isOpen={true}
      onClose={() => unSetModal(modalKey)}
      closeOnOverlayClick={closeOnOverlayClick}
      size={size}
    >
      {useOverlay && <ModalOverlay />}
      {modal}
    </Modal>
  );
};
