import { ITableV2Column } from "components/table-v2/table-v2";
import { TAccountDevelopersWebhookConfig } from "types/account-developers.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import { AccountDevelopersWebhooksTableActionCell } from "./AccountDevelopersWebhooksTableActionCell";

export const developersConfigWebhooksTableColumnDef = (
  handleDeleteWebhook: (row: TAccountDevelopersWebhookConfig) => () => void,
  handleEditWebhook: (row: TAccountDevelopersWebhookConfig) => () => void,
  internal?: boolean
): ITableV2Column<TAccountDevelopersWebhookConfig>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "webhookUrl",
      Header: <DefaultHeaderCell>URL</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.webhookUrl}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Created at</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell py={2}>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
      width: "100px",
    },
    {
      accessor: "id",
      width: "70px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => {
        if (internal) {
          return;
        }
        return (
          <AccountDevelopersWebhooksTableActionCell
            row={row}
            handleDeleteWebhook={handleDeleteWebhook(row)}
            handleEditWebhook={handleEditWebhook(row)}
          />
        );
      },
    },
  ];
};
