import jwtDecode from "jwt-decode";
import { TAuthTokenDTO, TLeadProSignUpTokenDTO } from "types/token.type";

export const decodeAuthToken = <T extends TAuthTokenDTO>(token: string) =>
  jwtDecode<T>(token);

export function getTokenPayload<T extends TAuthTokenDTO>(
  token?: string | null
) {
  return token ? decodeAuthToken<T>(token) : null;
}

export const getLeadProSignUpTokenPayload = (token: string) => {
  return decodeAuthToken<TLeadProSignUpTokenDTO>(token);
};
