import { UserAvatar, BasicUserInfo } from "components";
import React from "react";
import { NavLink } from "react-router-dom";
import { USER_PROFILE_ROUTE } from "constants/routes";
import {
  Box,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { TNormalizedUserData } from "types/user-data.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

interface IProps {
  user: TNormalizedUserData;
}

export const UserDropdown: React.FC<IProps> = ({ user }) => {
  const { authStore } = useAppStore();
  return (
    <Menu isLazy={true}>
      <MenuButton as={Box} cursor={"pointer"}>
        <UserAvatar user={user} size={"sm"} />
      </MenuButton>
      <MenuList zIndex={999}>
        <MenuGroup>
          <Box px={2}>
            <BasicUserInfo user={user} />
          </Box>
          <MenuDivider />
          <MenuItem as={NavLink} exact={true} to={USER_PROFILE_ROUTE}>
            Profile
          </MenuItem>
          <MenuItem onClick={authStore.logout}>Logout</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
