import { FC } from "react";
import { AccountSetupStepEnum } from "enums/account-setup-step.enum";
import { AccountBasicInfoStep } from "./components/AccountBasicInfoStep";
import { AccountFinalizationStep } from "./components/AccountFinalizationStep";
import { AccountOfficesStep } from "./components/AccountOfficesStep";
import { ACCOUNT_SETUP_STEPS_ORDER } from "constants/account-setup-steps-order";
import { AccountBusinessInfoStep } from "./components/AccountBusinessInfoStep";

export type StepComponentsType = {
  [key in AccountSetupStepEnum]?: FC<any>;
};

export const AccountSetupStepToComponentMap: StepComponentsType = {
  [AccountSetupStepEnum.BASIC_INFO]: AccountBasicInfoStep,
  [AccountSetupStepEnum.BUSINESS_INFO]: AccountBusinessInfoStep,
  [AccountSetupStepEnum.OFFICES]: AccountOfficesStep,
};

export function getNextAccountSetupStep(finishedSteps: AccountSetupStepEnum[]) {
  const nextStep = ACCOUNT_SETUP_STEPS_ORDER.find(step => {
    return !finishedSteps.includes(step);
  });

  // if next step doesn't exist, should display finalization step
  return nextStep
    ? AccountSetupStepToComponentMap[nextStep]
    : AccountFinalizationStep;
}
