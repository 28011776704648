import { IntegrationEntitySyncTag } from "components";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeSyncLeadSourceData } from "types/sme.type";
import { SmeLeadSourceSyncSelect } from "./SmeSourceSyncSelect";

export const syncSmeLeadSourceTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TSmeSyncLeadSourceData) => void
): ITableV2Column<TSmeSyncLeadSourceData>[] => {
  return [
    {
      accessor: "leadSourceName",
      Header: <DefaultHeaderCell>LeadPro source</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell textTransform={"capitalize"}>
          {row.leadSourceName}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "smeSourceId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>iamproperty CRM source</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <SmeLeadSourceSyncSelect
            accountStore={accountStore}
            onChange={updateSyncPair}
            record={row}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
