import {
  TAccountBillingData,
  TAccountBillingProduct,
  TAccountBillingProductCurrentMonthActivity,
} from "types/account-billing.type";
import { StripePaymentIntentStatusEnum } from "enums/stripe-payment-intent-status.enum";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { ApplicationProductPlanEnum } from "enums/application-product-plan.enum";
import {
  accountProductsConfigMap,
  IAccountBillingSubscriptionProductConfig,
} from "constants/account-products-config";

export const HIDDEN_PRODUCTS_FROM_BILLING_SUBSCRIPTIONS: ApplicationProductEnum[] = [
  ApplicationProductEnum.INSTANT_VALUATION_TOOL,
  ApplicationProductEnum.MORTGAGES,
];
export const TREAT_AS_CUSTOM_BILLED: ApplicationProductEnum[] = [
  ApplicationProductEnum.INSTANT_VALUATION_TOOL,
];

export interface IAccountBillingIssue {
  status: StripePaymentIntentStatusEnum;
  stripePaymentIntentClientSecret: string | null;
  message: string | null;
  cta: string;
}

export const modifyUserAccountBillingData = (
  accountBilling: TAccountBillingData
) => {
  accountBilling.accountBillingProducts = accountBilling.accountBillingProducts.map(
    (accountBillingProduct: TAccountBillingProduct) => {
      return {
        ...accountBillingProduct,
        currentMonthActivity:
          accountBilling.currentlyActiveProducts.find(
            currentlyActiveProduct =>
              currentlyActiveProduct.productId ===
                accountBillingProduct.productId &&
              !accountBillingProduct.isCustom
          ) || null,
      };
    }
  );

  return accountBilling;
};

export const getProductPriceWithTaxes = (
  productConfig: IAccountBillingSubscriptionProductConfig,
  currentMonthActivity: TAccountBillingProductCurrentMonthActivity | null
) => {
  const usageFee =
    Math.max(
      (currentMonthActivity?.currentMonthUsage || 0) -
        (productConfig.pricing.unitsIncluded || 0),
      0
    ) * (productConfig.pricing.pricePerExtraUnit || 0);
  // will include metered charge afterwards;
  const sumTotal = productConfig.pricing.flatFee + usageFee;
  const sumTotalVat = sumTotal * (productConfig.pricing.vatTaxPercent / 100);
  const sumTotalWithVat = sumTotal + sumTotalVat;

  return {
    usageFee,
    sumTotal,
    sumTotalVat,
    sumTotalWithVat,
  };
};

export const isAccountProductOverBaseLimit = (
  productId: ApplicationProductEnum,
  accountBilling?: TAccountBillingData
) => {
  if (!accountBilling) return false;

  const baseLimit = accountProductsConfigMap[productId].pricing.unitsIncluded;

  const product = accountBilling.accountBillingProducts.find(
    accountBillingProduct => accountBillingProduct.productId === productId
  );

  return (
    (product?.currentMonthActivity?.currentMonthUsage || 0) >= (baseLimit || 0)
  );
};

export const getApplicationProductPlan = (
  billingProduct: TAccountBillingProduct
) => {
  if (billingProduct.isCustom) return ApplicationProductPlanEnum.CUSTOM;
  if (TREAT_AS_CUSTOM_BILLED.includes(billingProduct.productId))
    return ApplicationProductPlanEnum.CUSTOM;
  if (!!billingProduct.currentMonthActivity)
    return ApplicationProductPlanEnum.PAID;

  return ApplicationProductPlanEnum.FREE;
};

export const isApplicationProductOnSpecificPlan = (
  billingProductId: ApplicationProductEnum,
  plans: ApplicationProductPlanEnum[],
  accountBilling?: TAccountBillingData
) => {
  const product = accountBilling?.accountBillingProducts.find(
    accountBillingProduct =>
      accountBillingProduct.productId === billingProductId
  );

  if (!product) return false;

  return plans.includes(getApplicationProductPlan(product));
};

export const isApplicationProductUnlocked = (
  billingProductId: ApplicationProductEnum,
  accountBilling?: TAccountBillingData
) => {
  return isApplicationProductOnSpecificPlan(
    billingProductId,
    [ApplicationProductPlanEnum.PAID, ApplicationProductPlanEnum.CUSTOM],
    accountBilling
  );
};

export const getBillingProductCurrentMonthActivity = (
  billingProductId: ApplicationProductEnum,
  accountBilling?: TAccountBillingData
) => {
  return (
    accountBilling?.accountBillingProducts.find(
      product => product.productId === billingProductId
    )?.currentMonthActivity || null
  );
};

export const getUsageFeeDescriptionByProduct = (
  productId: ApplicationProductEnum,
  currentMonthUsage: number,
  unitsIncluded: number
) => {
  if (
    productId === ApplicationProductEnum.DASHBOARD_USERS &&
    currentMonthUsage > unitsIncluded
  ) {
    return ` ${currentMonthUsage - unitsIncluded} additional users`;
  } else {
    return ` ${currentMonthUsage} ${
      unitsIncluded > 0 ? `out of ${unitsIncluded}` : ""
    } used`;
  }
};
