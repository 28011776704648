import { UserRoleEnum } from "enums/user-role.enum";
import {
  Box,
  Button,
  Divider,
  HStack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlLabel,
  FormControlV2,
  Message,
} from "components";
import { withStripe } from "utils/hoc/withStripe.hoc";
import { PaymentMethodPrompt } from "../PaymentMethodPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { isAccountProductOverBaseLimit } from "utils/account-billing.utils";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { InviteUserWarning } from "./InviteUserWarning";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ROLE_INPUT_OPTIONS } from "constants/role-input-options";
import { TReapitNegotiator } from "types/reapit.type";
import { OfficeAndGroupSelectionOptionsRenderer } from "components/select-input/custom-option-renderers/OfficeAndGroupSelectOptionsRenderer";
import {
  addPrefixToId,
  removePrefixFromOfficeGroupAndOfficeIds,
} from "utils/office-group.utils";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { OfficeCategory } from "enums/office-type.enum";
import { filterOptionsByLabel } from "utils/select.utils";
import { TSelectOption } from "types/select-input.type";
import { ConfirmActionPrompt } from "../ConfirmActionPrompt";
import { accountProductsConfigMap } from "constants/account-products-config";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { EcosystemAuthStore } from "store/EcosystemAuth.store";
import { ShowForAuthTypes } from "../../ShowFor/ShowForAuthTypes";
import { AuthStoreTypeEnum } from "enums/auth-store-type.enum";
import { ReapitNegotiatorSync } from "./ReapitNegotiatorSync";
import { AlertStatusEnum } from "enums/alert-status.enum";

const PRICE_PER_EXTRA_USER =
  accountProductsConfigMap[ApplicationProductEnum.DASHBOARD_USERS].pricing
    .pricePerExtraUnit || 0;
const NUMBER_OF_FREE_USERS =
  accountProductsConfigMap[ApplicationProductEnum.DASHBOARD_USERS].pricing
    .unitsIncluded || 0;

type TFieldValuesOfficeAndOfficeGroupIds = TFieldValues & {
  officeIds: number[];
  officeGroupIds: number[];
};

type TFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  officeAndOfficeGroupIds: string[];
  createEcosystemUser: boolean;
  availableForLeadAssignment: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  roleId: Yup.number().required(REQUIRED_FIELD),
  officeAndOfficeGroupIds: Yup.array()
    .of(Yup.string())
    .when("roleId", {
      is: (roleId: UserRoleEnum) =>
        roleId === UserRoleEnum.LEADER || roleId === UserRoleEnum.AGENT,
      then: Yup.array()
        .of(Yup.string())
        .min(1, REQUIRED_FIELD),
    }),
  createEcosystemUser: Yup.boolean(),
});

interface IProps {
  accountStore: UserAccountStore;
  existingUserId?: number;
  closePrompt: () => void;
  onSuccess?: () => void;
}

export const UserFormPrompt: React.FC<IProps> = observer(
  ({ accountStore, existingUserId, closePrompt, onSuccess }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const [
      selectedReapitNegotiatorId,
      setSelectedReapitNegotiatorId,
    ] = useState<TReapitNegotiator["reapitId"] | null>(null);

    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountOfficeGroupsStore = accountStore.accountOfficeGroupsStore;
    const accountBillingStore = accountStore.accountBillingStore;
    const accountBilling = accountBillingStore.billing;
    const currentUserRoleId = accountStore.account.roleId;

    // Reapit integration
    const accountIntegrationStore = accountStore.accountIntegrationsStore;
    const reapitIntegrationStore =
      accountIntegrationStore.accountIntegrationReapitStore;
    const accountIntegrationReapitNegotiatorsStore =
      reapitIntegrationStore.accountIntegrationReapitNegotiatorsStore;
    const hasReapitIntegration =
      accountIntegrationStore.hasActiveReapitIntegration;

    const isMultiIntegrationEnabled =
      accountIntegrationStore.accountIntegrationReapitStore
        .isMultiIntegrationEnabled;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          const promises = [];
          promises.push(accountOfficesStore.fetchAccountOffices());
          promises.push(accountOfficeGroupsStore.fetchAllAccountOfficeGroups());
          await Promise.all(promises);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountOfficesStore,
      hasReapitIntegration,
      accountOfficeGroupsStore,
      accountIntegrationReapitNegotiatorsStore,
    ]);

    const existingUser = useMemo(() => {
      if (!!existingUserId) {
        return accountUsersStore.accountUsersMap[existingUserId];
      }
    }, [existingUserId, accountUsersStore]);

    const isOverFreeUsersLimit = useMemo(
      () =>
        isAccountProductOverBaseLimit(
          ApplicationProductEnum.DASHBOARD_USERS,
          accountBilling
        ),
      [accountBilling]
    );

    const initialValues: TFieldValues = useMemo(() => {
      return {
        email: existingUser?.email || "",
        firstName: existingUser?.firstName || "",
        lastName: existingUser?.lastName || "",
        roleId: !!existingUser
          ? existingUser.userAccount.roleId
          : UserRoleEnum.AGENT,
        officeAndOfficeGroupIds: !!existingUser
          ? [
              ...existingUser.officeIds.map(id =>
                addPrefixToId(id, OfficeCategory.OFFICE)
              ),
              ...existingUser.officeGroupIds.map(id =>
                addPrefixToId(id, OfficeCategory.GROUP)
              ),
            ] || []
          : [],
        availableForLeadAssignment:
          existingUser?.availableForLeadAssignment ?? true,
        createEcosystemUser: false,
      };
    }, [existingUser]);

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const watchEmail = watch("email");
    const watchRoleId = watch("roleId");
    const watchAvailableForLeadAssignment = watch("availableForLeadAssignment");

    const handleUpdateUser = useCallback(
      async (
        values: TFieldValuesOfficeAndOfficeGroupIds,
        user: IAccountUserDataWithDetails
      ) => {
        try {
          const {
            firstName,
            lastName,
            roleId,
            officeIds,
            officeGroupIds,
            availableForLeadAssignment,
          } = values;

          await accountUsersStore.updateAccountUser(
            user.id,
            {
              roleId,
              officeIds,
              firstName,
              lastName,
              officeGroupIds,
              availableForLeadAssignment,
            },
            {
              reapitId: selectedReapitNegotiatorId,
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"User has been updated"} />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [toast, closePrompt, accountUsersStore, selectedReapitNegotiatorId]
    );

    const inviteUser = useCallback(
      async (values: TFieldValuesOfficeAndOfficeGroupIds) => {
        const {
          email,
          firstName,
          lastName,
          roleId,
          officeIds,
          officeGroupIds,
          createEcosystemUser,
          availableForLeadAssignment,
        } = values;
        try {
          await accountUsersStore.inviteUserToAccount(
            {
              email,
              firstName,
              lastName,
              roleId,
              officeIds,
              officeGroupIds,
              availableForLeadAssignment,
              createEcosystemUser: EcosystemAuthStore.isEcosystemSupported()
                ? createEcosystemUser
                : false,
            },
            {
              reapitId: selectedReapitNegotiatorId,
            }
          );

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"User will receive email notification shortly."}
              />
            ),
          });
          onSuccess?.();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [toast, onSuccess, accountUsersStore, selectedReapitNegotiatorId]
    );

    const handleInviteUser = useCallback(
      async (values: TFieldValuesOfficeAndOfficeGroupIds) => {
        if (isOverFreeUsersLimit && !accountBilling?.stripePaymentMethodId) {
          setModal(
            withStripe(
              <PaymentMethodPrompt
                header={"Add payment method"}
                closePrompt={unSetModal}
                onSuccess={() => inviteUser(values)}
              />
            )
          );
        } else if (isOverFreeUsersLimit) {
          setModal(
            <ConfirmActionPrompt
              text={
                <Box>
                  <Box>{`Your subscription includes ${NUMBER_OF_FREE_USERS} free user(s), and additional users are £${PRICE_PER_EXTRA_USER} per user per month which will be visible on your next month's invoice.`}</Box>
                  <Box mt={4}>
                    Are you sure you would like to add this user?
                  </Box>
                </Box>
              }
              onConfirm={() => inviteUser(values)}
              closePrompt={unSetModal}
            />
          );
        } else {
          await inviteUser(values);
          closePrompt();
        }
      },
      [
        inviteUser,
        isOverFreeUsersLimit,
        accountBilling?.stripePaymentMethodId,
        setModal,
        unSetModal,
        closePrompt,
      ]
    );

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        const { officeAndOfficeGroupIds } = values;
        const {
          officeIds,
          officeGroupIds,
        } = removePrefixFromOfficeGroupAndOfficeIds(officeAndOfficeGroupIds);

        const transformedValues = {
          ...values,
          officeGroupIds,
          officeIds,
        };

        if (!!existingUser) {
          await handleUpdateUser(transformedValues, existingUser);
        } else {
          await handleInviteUser(transformedValues);
        }
      },
      [existingUser, handleInviteUser, handleUpdateUser]
    );

    const handleFilterOfficeOptions = useCallback(
      (options: TSelectOption<number>[], searchTerm: string) => {
        return filterOptionsByLabel<number>(options, searchTerm);
      },
      []
    );

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        header={!!existingUserId ? "Edit user" : "Add user to account"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={5} align={"stretch"}>
                {!existingUserId && !!accountBilling && (
                  <InviteUserWarning accountBilling={accountBilling} />
                )}
                {hasReapitIntegration && isMultiIntegrationEnabled && (
                  <Message status={AlertStatusEnum.INFO}>
                    For accounts with reapit set up at office group level,
                    please sync negotiators in the reapit settings menu.
                  </Message>
                )}
                {!existingUserId && (
                  <ShowForAuthTypes authTypes={[AuthStoreTypeEnum.ECOSYSTEM]}>
                    <HStack spacing={4}>
                      <FormControlV2<TFieldValues>
                        name={"createEcosystemUser"}
                        control={control}
                        type={FormControlsTypeEnum.SWITCH}
                      />
                      <FormControlLabel>Create Ecosystem user</FormControlLabel>
                    </HStack>
                  </ShowForAuthTypes>
                )}
                <HStack spacing={4} width={"100%"} align={"flex-start"}>
                  <Box flexGrow={1}>
                    <FormControlV2<TFieldValues>
                      name={"firstName"}
                      control={control}
                      label={"First name"}
                      type={FormControlsTypeEnum.TEXT}
                      additionalProps={{
                        placeholder: "User's first name",
                      }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <FormControlV2<TFieldValues>
                      name={"lastName"}
                      control={control}
                      label={"Last name"}
                      type={FormControlsTypeEnum.TEXT}
                      additionalProps={{
                        placeholder: "User's last name",
                      }}
                    />
                  </Box>
                </HStack>
                <FormControlV2<TFieldValues>
                  name={"email"}
                  control={control}
                  label={"Email"}
                  type={FormControlsTypeEnum.TEXT}
                  isDisabled={!!existingUser}
                  additionalProps={{
                    placeholder: "Invitation will be sent to this email",
                  }}
                />
                <HStack spacing={4}>
                  <FormControlV2<TFieldValues>
                    name={"availableForLeadAssignment"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                  />
                  <FormControlLabel>
                    {`Lead assignment ${
                      watchAvailableForLeadAssignment ? "enabled" : "disabled"
                    }`}
                  </FormControlLabel>
                </HStack>
                <FormControlV2<TFieldValues>
                  name={"roleId"}
                  control={control}
                  label={"Role"}
                  type={FormControlsTypeEnum.RADIO}
                  isDisabled={currentUserRoleId !== UserRoleEnum.ADMIN}
                  additionalProps={{
                    options: ROLE_INPUT_OPTIONS,
                  }}
                />
                {watchRoleId !== UserRoleEnum.ADMIN && (
                  <>
                    <FormControlV2<TFieldValues>
                      name={"officeAndOfficeGroupIds"}
                      control={control}
                      label={"Select offices and groups"}
                      type={FormControlsTypeEnum.MULTI_SELECT}
                      additionalProps={{
                        clearable: true,
                        placeholder:
                          "Offices and Office groups user will have access to",
                        options: [
                          ...accountOfficesStore.accountOfficeOptionsWithPrefix,
                          ...accountOfficeGroupsStore.accountOfficeGroupOptionsWithPrefix,
                        ],
                        filterFn: handleFilterOfficeOptions,
                        optionsRenderer: (
                          optionProps: IGetOptionPropsData<OfficeCategory>[]
                        ) => (
                          <OfficeAndGroupSelectionOptionsRenderer
                            optionProps={optionProps}
                          />
                        ),
                      }}
                    />
                  </>
                )}
                {hasReapitIntegration && !isMultiIntegrationEnabled && (
                  <ReapitNegotiatorSync
                    email={watchEmail}
                    onChange={setSelectedReapitNegotiatorId}
                    existingUser={existingUser}
                    value={selectedReapitNegotiatorId}
                    reapitIntegrationStore={reapitIntegrationStore}
                  />
                )}
                <Divider mb={4} />
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    type={"submit"}
                    colorScheme={"blue"}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {!!existingUser ? "Update" : "Create"}
                  </Button>
                </Box>
              </VStack>
            </form>
          </Box>
        }
      />
    );
  }
);
