import {
  List,
  AutoSizer,
  ListRowProps,
  CellMeasurerCache,
  CellMeasurer,
  InfiniteLoader,
} from "react-virtualized";
import React, { useCallback, useMemo } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { LeadActivityItem } from "./LeadActivityItem";
import { observer } from "mobx-react";
import { transformActivityToConfig } from "./lead-activity-utils";
import { Center } from "@chakra-ui/react";
import { EmptyScreen } from "components";

interface LeadActivityListProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 200,
});

export const LeadActivityList: React.FC<LeadActivityListProps> = observer(
  ({ accountStore, leadStore }) => {
    const leadActivitiesStore = leadStore.leadActivitiesStore;
    const leadActivities = leadActivitiesStore.leadActivitiesArray;

    const activityConfigs = useMemo(() => {
      return transformActivityToConfig(leadActivities, leadStore, accountStore);
    }, [leadActivities, leadStore, accountStore]);

    const rowRenderer = useCallback(
      ({ key, index, parent, style }: ListRowProps) => {
        const activityConfig = activityConfigs[index];
        return (
          <CellMeasurer cache={cache} index={index} parent={parent} key={key}>
            {({ measure }) => (
              <LeadActivityItem
                key={activityConfig.createdAt}
                style={style}
                isLast={activityConfigs.length - 1 === index}
                onLoad={measure}
                {...activityConfig}
              />
            )}
          </CellMeasurer>
        );
      },
      [activityConfigs]
    );

    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => !!activityConfigs[index]}
        loadMoreRows={leadActivitiesStore.loadLeadActivities}
        rowCount={activityConfigs.length + 1}
        threshold={1}
      >
        {({ registerChild, onRowsRendered }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={activityConfigs.length}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                registerChild={registerChild}
                onRowsRendered={onRowsRendered}
                noRowsRenderer={() => (
                  <Center height={height}>
                    <EmptyScreen label={"No activity."} />
                  </Center>
                )}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }
);
