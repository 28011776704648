import React, { FC, useMemo } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import { TPageV2SSLCertificateData } from "types/page.type";
import { SSLCertificateStatus } from "enums/ssl-certificate-status.enum";
import {
  faShieldCheck,
  faExclamationTriangle,
  faMessageExclamation,
  IconDefinition,
  faClock,
} from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BADGE_CONFIGS: {
  [key in SSLCertificateStatus]: {
    color: string;
    Icon: IconDefinition;
    tooltip: string;
  };
} = {
  [SSLCertificateStatus.NOT_INITIATED]: {
    color: "orange",
    Icon: faMessageExclamation,
    tooltip:
      "The page needs to be visited for the first time to trigger the queueing and creation of SSL certificate.",
  },
  [SSLCertificateStatus.SCHEDULED]: {
    color: "blue",
    Icon: faClock,
    tooltip: "The SSL certificate has been queued to be created.",
  },
  [SSLCertificateStatus.ACTIVE]: {
    color: "green",
    Icon: faShieldCheck,
    tooltip: "The SSL certificate has been created and is being actively used.",
  },
  [SSLCertificateStatus.EXPIRED]: {
    color: "red",
    Icon: faExclamationTriangle,
    tooltip:
      "The SSL certificate has expired, please contact support for assistance.",
  },
};
interface IProps {
  sslCertificateData: TPageV2SSLCertificateData;
}
export const PageCardSSLBadge: FC<IProps> = ({ sslCertificateData }) => {
  const theme = useTheme();
  const config = useMemo(
    () => BADGE_CONFIGS[sslCertificateData.sslCertificateStatus],
    [sslCertificateData.sslCertificateStatus]
  );

  if (!config) return null;

  return (
    <Box>
      <Tooltip label={config.tooltip} placement={"right"}>
        <Box>
          <FontAwesomeIcon
            fontSize={28}
            color={theme.colors[config.color]["600"]}
            icon={config.Icon}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
