import React from "react";
import { TReapitContactData } from "types/reapit.type";
import ReapitRelatedContactsOption from "../custom-options/ReapitRelatedContactsOption";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IReapitRelatedContactOptionsRendererProps {
  optionProps: IGetOptionPropsData<string, TReapitContactData>[];
}

export const ReapitRelatedContactsRenderer: React.FC<IReapitRelatedContactOptionsRendererProps> = ({
  optionProps,
}) => {
  return (
    <>
      {optionProps.map(optionProp => {
        return (
          <ReapitRelatedContactsOption
            optionProp={optionProp}
            hoverEffect
            key={optionProp.option.value}
          />
        );
      })}
    </>
  );
};
