import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC, useEffect } from "react";
import { Control, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  questionnaireToolPageSettingsValidationSchema,
  TQuestionnaireToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import { Box, Divider } from "@chakra-ui/react";
import { PageToolPageBasicSettings } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolPageBasicSettings";
import { PageToolPageAnalyticsSettings } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolPageAnalyticsSettings";
import { PageToolPageGDPRCheckboxSettings } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolPageGDPRCheckboxSettings";
import { QuestionnaireToolPageTriggerSettings } from "./QuestionnaireToolPageTriggerSettings";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { FormControlMeta, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { PageToolPageSettingsInputLayout } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolPageSettingsInputLayout";

type TFieldValues = TQuestionnaireToolSettingsFieldValues;
interface IProps {
  accountStore: UserAccountStore;
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsDetailsForm: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore, accountStore }) => {
    const page = questionnaireToolPageSettingsEditorStore.page || undefined;

    const { control, watch } = useForm<TFieldValues>({
      // TODO NENAD: TYPESCRIPT ISSUE, UPGRADE SHOULD FIX
      defaultValues: questionnaireToolPageSettingsEditorStore.wipPageJS as any,
      mode: "onBlur",
      resolver: yupResolver(questionnaireToolPageSettingsValidationSchema),
    });

    useEffect(() => {
      const subscription = watch(value => {
        questionnaireToolPageSettingsEditorStore.setWipPage(
          value as TFieldValues
        );
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [watch, questionnaireToolPageSettingsEditorStore]);

    return (
      <Box height={"100%"}>
        <form style={{ height: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            height={"100%"}
          >
            <Box p={"65px"} flexGrow={1} overflow={"auto"}>
              <PageToolPageBasicSettings
                page={page}
                control={control}
                watch={watch}
              />
              <PageToolPageSettingsInputLayout>
                <FormControlMeta
                  label="Enable slideshow style questions"
                  description={
                    "Enabling this will present your questions one at a time to your recipients, rather than a classic list of questions on one page."
                  }
                />
                <FormControlV2<TFieldValues>
                  name={"isSlideshowModeEnabled"}
                  control={control as Control<TFieldValues>}
                  type={FormControlsTypeEnum.SWITCH}
                />
              </PageToolPageSettingsInputLayout>
              <Divider />
              <QuestionnaireToolPageTriggerSettings
                accountStore={accountStore}
                control={control}
              />
              <Divider />
              <PageToolPageAnalyticsSettings control={control} />
              <Divider />
              <PageToolPageGDPRCheckboxSettings control={control} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  }
);
