import React, { FC, useCallback, useState } from "react";
import { AccountJourneysStore } from "store/UserAccounts/UserAccount/AccountJourneys.store";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ActionPromptContainer, ApiMessageStack } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { TAccountJourney } from "types/account-journeys.type";

interface IProps {
  closePrompt: () => void;
  journey: TAccountJourney;
  journeysStore: AccountJourneysStore;
  fetchFilters: () => Promise<void>;
}

export const DeleteJourneyPrompt: FC<IProps> = observer(
  ({ closePrompt, journey, journeysStore, fetchFilters }) => {
    const toast = useToast();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleDeleteJourney = useCallback(async () => {
      try {
        setIsRemoving(true);
        await journeysStore.deleteAccountJourney(journey.id);
        await fetchFilters();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Journey has been deleted."} />
          ),
        });
        closePrompt();
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [closePrompt, journey, journeysStore, toast, fetchFilters]);

    return (
      <ActionPromptContainer
        header={"Confirm deletion"}
        body={
          <Box>
            <Box mb={5}>
              <Box>Are you sure you want to remove this journey?</Box>
            </Box>
            <Divider mb={4} />
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button
                onClick={handleDeleteJourney}
                colorScheme={"red"}
                isDisabled={isRemoving}
                isLoading={isRemoving}
              >
                Delete journey
              </Button>
            </Box>
          </Box>
        }
      />
    );
  }
);
