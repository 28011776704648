import { TSerializedDateRange } from "../types/date.type";
import { AnalyticsDateRangeFilterEnum } from "../enums/analytics-date-range-filter.enum";
import moment from "moment";

export const getDatesForPresetOption = (type: string) => {
  let config: TSerializedDateRange = {} as TSerializedDateRange;

  if (type === AnalyticsDateRangeFilterEnum.ALL) {
    config.endDate = null;
    config.startDate = null;
  }

  if (type === AnalyticsDateRangeFilterEnum.TODAY) {
    const today = moment();
    config.startDate = today.startOf("day").toISOString();
    config.endDate = today.endOf("day").toISOString();
  }

  if (type === AnalyticsDateRangeFilterEnum.THIS_WEEK) {
    config.startDate = moment()
      .startOf("isoWeek")
      .toISOString();
    config.endDate = moment()
      .endOf("day")
      .toISOString();
  }

  if (type === AnalyticsDateRangeFilterEnum.THIS_MONTH) {
    const today = moment();
    config.startDate = today.startOf("month").toISOString();
    config.endDate = moment().toISOString();
  }

  if (type === AnalyticsDateRangeFilterEnum.THIS_YEAR) {
    const today = moment();
    config.startDate = today.startOf("year").toISOString();
    config.endDate = moment().toISOString();
  }

  return config;
};
