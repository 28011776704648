import { groupBy, keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { TLeadStatus } from "types/lead-status.type";
import { LeadStatusApi } from "api/account-lead-status.api";
import { PredefinedLeadStatus } from "enums/lead-predefined-status.enum";

const leadStatusApi = new LeadStatusApi();

export class AccountLeadStatusesStore {
  private readonly root: AppStore;
  private accountLeadStatuses: IObservableArray<TLeadStatus>;
  private readonly accountId: number;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountLeadStatuses = observable.array<TLeadStatus>();
  }

  get accountLeadStatusesArray() {
    return this.accountLeadStatuses.slice();
  }

  get deletedStatus() {
    return this.accountLeadStatusesArray.find(
      leadStatus => leadStatus.predefinedStatus === PredefinedLeadStatus.DELETED
    );
  }

  get contactedStatus() {
    return this.accountLeadStatusesArray.find(
      leadStatus =>
        leadStatus.predefinedStatus === PredefinedLeadStatus.CONTACTED
    );
  }

  get newStatus() {
    return this.accountLeadStatusesArray.find(
      leadStatus => leadStatus.predefinedStatus === PredefinedLeadStatus.NEW
    );
  }

  get leadStatusesMapByStatusId() {
    return keyBy(this.accountLeadStatusesArray, leadStatus => leadStatus.id);
  }

  get accountGroupedDefaultLeadStatusesByStage() {
    return groupBy(
      this.accountDefaultLeadStatuses,
      leadStatus => leadStatus.stageId
    );
  }

  get accountGroupedCustomLeadStatusesByStage() {
    return groupBy(
      this.accountCustomLeadStatuses,
      leadStatus => leadStatus.stageId
    );
  }

  get accountLeadStatusOptions() {
    return this.accountLeadStatusesArray.map(leadStatus => ({
      label: leadStatus.name,
      value: leadStatus,
    }));
  }

  get accountCustomLeadStatuses() {
    return this.accountLeadStatusesArray.filter(
      leadStatus => !leadStatus.isPredefined
    );
  }

  get accountDefaultLeadStatuses() {
    return this.accountLeadStatusesArray.filter(
      leadStatus => leadStatus.isPredefined
    );
  }

  private setAccountLeadStatuses(leadStatuses: TLeadStatus[]) {
    this.accountLeadStatuses.replace(leadStatuses);
  }

  private indexOfLeadStatus(id: number) {
    return this.accountLeadStatusesArray.findIndex(
      leadStatus => leadStatus.id === id
    );
  }

  private deleteStatus(id: number) {
    const leadStatusIndex = this.indexOfLeadStatus(id);
    if (leadStatusIndex !== -1) {
      this.accountLeadStatuses.splice(leadStatusIndex, 1);
    }
  }

  private async upsertStatus(leadStatus: TLeadStatus) {
    const leadStatusIndex = this.indexOfLeadStatus(leadStatus.id);
    if (leadStatusIndex !== -1) {
      this.accountLeadStatuses[leadStatusIndex] = leadStatus;
    } else {
      this.accountLeadStatuses.push(leadStatus);
    }
  }

  public async fetchAccountLeadStatuses(): Promise<void> {
    const leadStatues = await leadStatusApi.fetchAccountLeadStatuses(
      this.accountId
    );
    this.setAccountLeadStatuses(leadStatues);
  }

  public async createAccountLeadStatus(statusData: Partial<TLeadStatus>) {
    const createdStatus = await leadStatusApi.createAccountLeadStatus(
      this.accountId,
      statusData
    );
    this.upsertStatus(createdStatus);
  }

  public async updateAccountLeadStatus(
    id: number,
    name: TLeadStatus["name"]
  ): Promise<void> {
    const updatedLeadStatus = await leadStatusApi.updateAccountLeadStatus(
      this.accountId,
      id,
      { name }
    );
    this.upsertStatus(updatedLeadStatus);
  }

  public async deleteAccountLeadStatus(id: number): Promise<void> {
    await leadStatusApi.deleteAccountLeadStatus(this.accountId, id);
    this.deleteStatus(id);
  }
}
