import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";

export const IntegrationNames: {
  [key in AccountIntegrationTypeEnum]: string;
} = {
  [AccountIntegrationTypeEnum.MRI_RADAR]: "MRI Radar",
  [AccountIntegrationTypeEnum.REAPIT]: "Reapit",
  [AccountIntegrationTypeEnum.OPEN_VIEW]: "Iamproperty CRM",
  [AccountIntegrationTypeEnum.URL_SYNC]: "URL Sync",
  [AccountIntegrationTypeEnum.ALTO]: "Alto",
  [AccountIntegrationTypeEnum.SME]: "SME",
  [AccountIntegrationTypeEnum.ZAPIER]: "Zapier",
  [AccountIntegrationTypeEnum.DRIP]: "Drip",
  [AccountIntegrationTypeEnum.ACQUAINT]: "Acquaint",
  [AccountIntegrationTypeEnum.DEZREZ]: "Dezrez",
  [AccountIntegrationTypeEnum.REAPIT_INTERNET_REGISTRATION]:
    "Reapit Internet Registration",
  [AccountIntegrationTypeEnum.JUPIX]: "Jupix",
  [AccountIntegrationTypeEnum.AGENT_OS]: "Agent OS",
  [AccountIntegrationTypeEnum.MRI_QUBE]: "MRI Qube",
  [AccountIntegrationTypeEnum.ZOOPLA]: "Zoopla",
  [AccountIntegrationTypeEnum.RIGHTMOVE]: "Rightmove",
  [AccountIntegrationTypeEnum.STREET]: "Street",
  [AccountIntegrationTypeEnum.GRAINGER_SALESFORCE]: "Grainger Salesforce",
  [AccountIntegrationTypeEnum.REX]: "Rex",
};
