import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "./App.store";
import { TSelectOption } from "types/select-input.type";
import { TRegisteredCompanyData } from "types/account.type";
import { UiApi } from "api/ui.api";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { keyBy } from "lodash";

const uiApi = new UiApi();

export class UIStore {
  private readonly root: AppStore;
  public isLeftSidebarOpen: boolean;
  public showAccountCreationModal: boolean;
  public isDemoModeOn: boolean;
  public userAccountLoadingState: ApiRequestStatusEnum;
  private readonly franchiseOptions: IObservableArray<TSelectOption<number>>;
  private readonly officeMembershipOptions: IObservableArray<
    TSelectOption<number>
  >;
  private readonly websiteProviderOptions: IObservableArray<
    TSelectOption<number>
  >;
  private readonly registeredCompaniesOptions: IObservableArray<
    TSelectOption<TRegisteredCompanyData>
  >;

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.isLeftSidebarOpen = true;
    this.showAccountCreationModal = false;
    this.isDemoModeOn = false;
    this.userAccountLoadingState = ApiRequestStatusEnum.NONE;
    this.franchiseOptions = observable.array<TSelectOption<number>>();
    this.officeMembershipOptions = observable.array<TSelectOption<number>>();
    this.websiteProviderOptions = observable.array<TSelectOption<number>>();
    this.registeredCompaniesOptions = observable.array<
      TSelectOption<TRegisteredCompanyData>
    >();
  }

  get franchiseOptionsArray() {
    return this.franchiseOptions.slice();
  }

  get franchisesMap() {
    return keyBy(this.franchiseOptionsArray, option => option.value);
  }

  get officeMembershipOptionsArray() {
    return this.officeMembershipOptions.slice();
  }

  get websiteProviderOptionsArray() {
    return this.websiteProviderOptions.slice();
  }

  get registeredCompaniesOptionsArray() {
    return this.registeredCompaniesOptions.slice();
  }

  private setFranchiseOptions(options: TSelectOption<number>[]) {
    this.franchiseOptions.replace(options);
  }

  private setOfficeMembershipOptions(options: TSelectOption<number>[]) {
    this.officeMembershipOptions.replace(options);
  }

  private setWebsiteProviderOptions(options: TSelectOption<number>[]) {
    this.websiteProviderOptions.replace(options);
  }

  public setUserAccountLoadingState(state: ApiRequestStatusEnum) {
    this.userAccountLoadingState = state;
  }

  public setRegisteredCompaniesOptions(
    options: TSelectOption<TRegisteredCompanyData>[]
  ) {
    this.registeredCompaniesOptions.replace(options);
  }

  public toggleLeftSidebar() {
    this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
  }

  public setLeftSidebarIsOpen(isLeftSidebarOpen: boolean) {
    this.isLeftSidebarOpen = isLeftSidebarOpen;
  }

  public setDemoMode(isDemoModeOn: boolean) {
    this.isDemoModeOn = isDemoModeOn;
  }

  public async fetchFranchiseOptions() {
    try {
      const options = await uiApi.fetchFranchiseOptions();
      this.setFranchiseOptions(
        options.map(option => ({
          value: option.id,
          label: option.name,
        }))
      );
    } catch (error) {}
  }

  public async fetchOfficeMembershipOptions() {
    try {
      const options = await uiApi.fetchOfficeMembershipOptions();
      this.setOfficeMembershipOptions(
        options.map(option => ({
          value: option.id,
          label: option.name,
        }))
      );
    } catch (error) {}
  }

  public async fetchWebsiteProviderOptions() {
    try {
      const options = await uiApi.fetchWebsiteProviderOptions();

      this.setWebsiteProviderOptions(
        options.map(option => ({
          value: option.id,
          label: option.name,
        }))
      );
    } catch (error) {}
  }

  public async fetchRegisteredCompaniesOptions(searchTerm: string) {
    try {
      const options = await uiApi.fetchRegisteredCompaniesOptions(searchTerm);

      this.setRegisteredCompaniesOptions(
        options.map((option: TRegisteredCompanyData) => ({
          value: {
            companyName: option.companyName,
            companyAddress: option.companyAddress,
            companyRegistrationNumber: option.companyRegistrationNumber,
          },
          label: option.companyName || "",
        }))
      );
    } catch (error) {}
  }
}
