import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { useEffect } from "react";

export const EcosystemLogin = observer(() => {
  const {
    authStore: { ecosystemAuthStore },
  } = useAppStore();

  useEffect(() => {
    const login = async () => {
      await ecosystemAuthStore.login();
    };

    login();
  }, [ecosystemAuthStore]);

  return null;
});
