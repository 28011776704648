import { Box, BoxProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import React, { FC } from "react";

interface IInternalLinkProps extends BoxProps {
  href: string;
  exact?: boolean;
}

export const InternalLink: FC<IInternalLinkProps> = ({
  href,
  exact,
  children,
  ...rest
}) => {
  return (
    <NavLink exact={exact} to={href}>
      <Box
        _hover={{
          textDecoration: "underline",
        }}
        color={"blue.500"}
        {...rest}
      >
        {children}
      </Box>
    </NavLink>
  );
};
