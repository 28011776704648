import React, { useCallback, useEffect, useState } from "react";
import Tour, { ReactourProps, ReactourStepContentArgs } from "reactour";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { DashboardTourStatusEnum } from "enums/dashboard-tour-status.enum";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { TourStepBase } from "./TourStepBase";
import Styles from "./TourBase.module.scss";
import { ITourStepDefinition } from "./TourStepFooter";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ApiMessageStack } from "components";

export interface IProps
  extends Omit<ReactourProps, "isOpen" | "onRequestClose" | "steps"> {
  tourId: DashboardTourEnum;
  steps: ITourStepDefinition[];
}

export const TourBase: React.FC<IProps> = observer(
  ({ tourId, steps, ...rest }) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const {
      authStore: { authUser, finishDashboardTour },
    } = useAppStore();

    useEffect(() => {
      if (!!authUser) {
        const tourFound = !!authUser.completedDashboardTours?.find(
          tour => tour.tourId === tourId
        );

        if (!tourFound) {
          onOpen();
        }
      }
    }, [authUser, authUser?.completedDashboardTours, onOpen, tourId]);

    const handleClose = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await finishDashboardTour({
          tourId,
          status: DashboardTourStatusEnum.COMPLETED,
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    }, [finishDashboardTour, onClose, tourId, setIsSubmitting, toast]);

    return (
      <Tour
        startAt={0}
        showNumber={false}
        showCloseButton={false}
        showButtons={false}
        showNavigation={false}
        closeWithMask={false}
        disableInteraction={true}
        disableKeyboardNavigation={true}
        isOpen={isOpen}
        onRequestClose={handleClose}
        className={Styles.base}
        steps={steps.map(stepDefinition => {
          return {
            ...stepDefinition,
            content: (stepContentArgs: ReactourStepContentArgs) => (
              <TourStepBase
                stepContentArgs={stepContentArgs}
                stepDefinition={stepDefinition}
                totalSteps={steps.length}
                isStepSubmitting={isSubmitting}
              />
            ),
          };
        })}
        {...rest}
      />
    );
  }
);
