import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { FC, useCallback } from "react";
import Styles from "./MergeTagInput.module.scss";

const MERGE_TAG_REGEX = new RegExp(/{{(.*?)}}/);
const TRIGGER = "{";
const MARKUP = "__id__";

interface IMergeTagInputProps {
  value: string;
  onChange: (text: string) => void;
  mergeTagOptions: SuggestionDataItem[];
  singleLine?: boolean;
  isDisabled?: boolean;
}

export const MergeTagInput: FC<IMergeTagInputProps> = ({
  value,
  onChange,
  mergeTagOptions,
  singleLine = true,
  isDisabled,
}) => {
  const handleOnChange = useCallback(
    (event: object, newValue: string) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <MentionsInput
      value={value}
      onChange={handleOnChange}
      singleLine={singleLine}
      placeholder={`Type "{" to list available merge tags`}
      className={singleLine ? "mentionsSingleLine" : "mentionsMultiLine"}
      classNames={Styles}
      disabled={isDisabled}
    >
      <Mention
        trigger={TRIGGER}
        markup={MARKUP}
        regex={MERGE_TAG_REGEX}
        appendSpaceOnAdd={true}
        data={mergeTagOptions}
        className={
          singleLine
            ? Styles.mentionsSingleLine__mention
            : Styles.mentionsMultiLine__mention
        }
      />
    </MentionsInput>
  );
};
