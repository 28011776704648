import { LeadDateRangeFilterEnum } from "enums/lead-next-action-date-filter.enum";
import { SortingRule } from "react-table";
import { toSnakeCase } from "./to-snake-case.utils";
import {
  TLeadDateRangeFilterConfiguration,
  TLeadFilterMenuItemValue,
  TNestedFilterConfiguration,
  TNextActionDateFilterConfiguration,
  TQuestionnaireFilterConfig,
} from "types/leads-filter.type";
import { LeadNextActionDateFilterEnum } from "../enums/lead-next-action-date-filter.enum";
import moment from "moment";
import { isEmpty } from "lodash";
import { LeadResponderEmailDateRangeFilterEnum } from "../routes/dashboard/routes/tools/routes/lead-responder/components/LeadResponderLeadEmails/LeadResponderEmailTableFilterHeaders/FilterEmailsDateRangeHeader";
import { TLeadResponderEmailDateRangeFilterConfiguration } from "../types/lead-responder-email.type";

export const transformQuestionnaireQueryParam = (
  questionnaire: TQuestionnaireFilterConfig
) => {
  if (isEmpty(questionnaire)) return;

  const questionnaireArray = Object.entries(
    questionnaire
  ).map(([question, answers]) => ({ question, answers }));
  return JSON.stringify(questionnaireArray);
};

export const transformLeadSourcesQueryParam = (
  leadSourcesFilter?: TNestedFilterConfiguration
) => {
  if (!leadSourcesFilter) return;

  const sources: {
    source: TLeadFilterMenuItemValue;
    hosts: TLeadFilterMenuItemValue[];
  }[] = [];

  Object.keys(leadSourcesFilter).forEach(leadSourceKey => {
    sources.push({
      source: leadSourceKey,
      hosts: leadSourcesFilter[leadSourceKey],
    });
  });

  return !!sources.length ? JSON.stringify(sources) : undefined;
};

export const transformSortByQueryParam = (sortBy: SortingRule<string>[]) => {
  if (!sortBy.length) return;

  return JSON.stringify(
    sortBy.map(sorter => {
      return {
        id: toSnakeCase(sorter.id),
        desc: sorter.desc,
      };
    })
  );
};

export const transformNextActionDateQueryParam = (
  nextActionDate?: TNextActionDateFilterConfiguration
) => {
  if (!nextActionDate) return;
  if (nextActionDate.type === LeadNextActionDateFilterEnum.ALL) return;

  const config: Pick<
    TNextActionDateFilterConfiguration,
    "from" | "to" | "includeNotSpecified"
  > = {};

  if (nextActionDate.type === LeadNextActionDateFilterEnum.OVERDUE) {
    const yesterday = moment()
      .subtract(1, "day")
      .endOf("day")
      .toISOString();
    config.to = yesterday;
  }

  if (nextActionDate.type === LeadNextActionDateFilterEnum.TODAY) {
    const today = moment();
    config.from = today.startOf("day").toISOString();
    config.to = today.endOf("day").toISOString();
    config.includeNotSpecified = nextActionDate.includeNotSpecified;
  }

  if (nextActionDate.type === LeadNextActionDateFilterEnum.THIS_WEEK) {
    const today = moment();
    config.from = today.startOf("week").toISOString();
    config.to = today.endOf("week").toISOString();
  }

  if (nextActionDate.type === LeadNextActionDateFilterEnum.LATER) {
    const firstDayOfNextWeek = moment()
      .endOf("week")
      .add(1, "day")
      .startOf("day")
      .toISOString();
    config.from = firstDayOfNextWeek;
  }

  if (nextActionDate.type === LeadNextActionDateFilterEnum.CUSTOM) {
    config.from = nextActionDate.from;
    config.to = nextActionDate.to;
  }

  return JSON.stringify(config);
};

export const transformLeadDateRangeQueryParam = (
  dateRange?: TLeadDateRangeFilterConfiguration
) => {
  if (!dateRange) return;
  if (dateRange.type === LeadDateRangeFilterEnum.ALL) return;

  const config: Pick<TLeadDateRangeFilterConfiguration, "from" | "to"> = {};

  if (dateRange.type === LeadDateRangeFilterEnum.TODAY) {
    const today = moment();
    config.from = today.startOf("day").toISOString();
    config.to = today.endOf("day").toISOString();
  }

  if (dateRange.type === LeadDateRangeFilterEnum.THIS_WEEK) {
    const today = moment();
    config.from = today.startOf("week").toISOString();
    config.to = today.endOf("week").toISOString();
  }

  if (dateRange.type === LeadDateRangeFilterEnum.CUSTOM) {
    config.from = dateRange.from;
    config.to = dateRange.to;
  }

  return JSON.stringify(config);
};

export const transformLeadResponderEmailDateRangeQueryParam = (
  dateRange?: TLeadResponderEmailDateRangeFilterConfiguration
) => {
  if (!dateRange) return;
  if (dateRange.type === LeadResponderEmailDateRangeFilterEnum.ALL) return;

  const config: Pick<
    TLeadResponderEmailDateRangeFilterConfiguration,
    "from" | "to"
  > = {};

  if (dateRange.type === LeadResponderEmailDateRangeFilterEnum.TODAY) {
    const today = moment();
    config.from = today.startOf("day").toISOString();
    config.to = today.endOf("day").toISOString();
  }

  if (dateRange.type === LeadResponderEmailDateRangeFilterEnum.THIS_WEEK) {
    const today = moment();
    config.from = today.startOf("week").toISOString();
    config.to = today.endOf("week").toISOString();
  }

  if (dateRange.type === LeadResponderEmailDateRangeFilterEnum.CUSTOM) {
    config.from = dateRange.from;
    config.to = dateRange.to;
  }

  return JSON.stringify(config);
};

export const getResponseBodyAsObject = (
  responseBody: object | string | null
) => {
  if (typeof responseBody === "string") {
    return {
      body: responseBody,
    };
  }

  return responseBody;
};

export const getErrorMessageAsResponseObject = (
  errorMessage: string | null
) => {
  if (!!errorMessage) {
    return {
      body: errorMessage,
    };
  }
  return null;
};

export function getFormDataFromObject<T>(data: T): FormData {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (
      typeof value === "object" &&
      !(value instanceof Blob) &&
      value !== null
    ) {
      formData.set(key, JSON.stringify(value));
    } else if (value !== undefined) {
      formData.set(key, value);
    }
  });

  return formData;
}
