import { BaseApi } from "./base.api";
import { TLeadAttribution } from "../types/lead-attribution.type";

export class AccountLeadAttributionsApi extends BaseApi {
  async fetchAccountLeadAttributions(
    accountId: number,
    leadId: number
  ): Promise<TLeadAttribution[]> {
    return await this.get(
      `/accounts/${accountId}/leads/${leadId}/attributions`
    );
  }
}
