import {
  Box,
  Button,
  Divider,
  Tag,
  TagLabel,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import {
  ILeadResponderEmailBlockDefinition,
  leadResponderEmailBlockHoc,
} from "utils/hoc/leadResponderEmailBlock.hoc";
import { LEAD_RESPONDER_EMAIL_STATE_COLOR_MAP } from "constants/lead-responder-email-status";
import { capitalize } from "lodash";
import { EmailBlockItem } from "./EmailBlockItem";
import { formatDate } from "utils/date.utils";
import { DATE_TIME_FORMAT } from "constants/date";
import { AccountOfficesStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffices.store";
import { LEADS_ALL_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";

export interface IParsingAndRoutingBlockDefinition
  extends ILeadResponderEmailBlockDefinition {
  accountOfficesStore: AccountOfficesStore;
}

export const ParsingAndRoutingBlock = leadResponderEmailBlockHoc<
  IParsingAndRoutingBlockDefinition
>(({ blockDefinition }) => {
  const emailData = blockDefinition.email;
  const accountOfficesStore = blockDefinition.accountOfficesStore;
  const history = useHistory();

  const officeData = useMemo(() => {
    return accountOfficesStore.accountOfficesDataMap[emailData.leadProOfficeId];
  }, [accountOfficesStore, emailData]);

  const handleGoToLead = useCallback(() => {
    const leadId = emailData.leadProLeadId.toString();
    const route = leadId
      ? `${LEADS_ALL_ROUTE}?leadIds=${leadId}`
      : LEADS_ALL_ROUTE;
    history.push(route);
  }, [history, emailData]);

  return (
    <VStack h={"100%"} align={"left"}>
      <Box as="h2" fontSize="md" fontWeight="bold">
        Parsing and Routing
      </Box>
      <Divider />
      <Wrap spacing={10} w="100%" justify={"left"} align={"center"}>
        <WrapItem>
          <EmailBlockItem
            title="Status"
            component={
              !!emailData?.state && (
                <Tag
                  borderRadius="md"
                  colorScheme={
                    LEAD_RESPONDER_EMAIL_STATE_COLOR_MAP[emailData.state]
                  }
                >
                  <TagLabel>{capitalize(emailData.state)}</TagLabel>
                </Tag>
              )
            }
          />
        </WrapItem>
        <WrapItem>
          <EmailBlockItem title="Parser" component={emailData?.matcher} />
        </WrapItem>
        <WrapItem>
          <EmailBlockItem title="Office" component={officeData?.name} />
        </WrapItem>
        <WrapItem>
          <EmailBlockItem
            title="Date Routed"
            component={
              !!emailData?.createdAt &&
              formatDate(emailData.createdAt, DATE_TIME_FORMAT)
            }
          />
        </WrapItem>
        <WrapItem>
          <EmailBlockItem
            component={
              !!emailData?.leadProLeadId && (
                <Button onClick={handleGoToLead} colorScheme={"blue"}>
                  Open Lead
                </Button>
              )
            }
          />
        </WrapItem>
      </Wrap>
    </VStack>
  );
});
