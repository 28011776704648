import { AppStore } from "../App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TUserAccountData } from "types/user-account.type";
import { AccountApi } from "api/account.api";
import { UserAccountStore } from "./UserAccount/UserAccount.store";
import { keyBy } from "lodash";
import { getDefaultAccountIdFromAccounts } from "utils/account-storage.utils";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { setDefaultAccount } from "utils/local-storage.utils";

const accountApi = new AccountApi();

export class UserAccountsStore {
  private readonly root: AppStore;
  public selectedAccountId: number | null;
  private userAccounts: IObservableArray<UserAccountStore>;

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.selectedAccountId = null;
    this.userAccounts = observable.array<UserAccountStore>();
  }

  get userAccountsArray() {
    return this.userAccounts.slice();
  }

  get userAccountsDataArray() {
    return this.userAccountsArray.map(
      userAccountStore => userAccountStore.account
    );
  }

  get userAccountsMap() {
    return keyBy(
      this.userAccountsArray,
      userAccountStore => userAccountStore.account.id
    );
  }

  get selectedAccountStore() {
    if (!!this.selectedAccountId) {
      return this.userAccountsMap[this.selectedAccountId];
    }

    return null;
  }

  private setSelectedAccountId(accountId: number | null) {
    this.selectedAccountId = accountId;
  }

  public setDefaultAccount() {
    const accountIdToSelect = getDefaultAccountIdFromAccounts(
      this.userAccountsDataArray
    );
    this.selectAccount(accountIdToSelect);
  }

  public setUserAccounts(userAccountsData: TUserAccountData[]) {
    this.userAccounts.replace(
      userAccountsData.map(
        userAccountData => new UserAccountStore(this.root, userAccountData)
      )
    );
  }

  public async createUserAccount(accountData: Partial<TUserAccountData>) {
    const account = await accountApi.createAccount(accountData);
    await this.root.authStore.reloadAuthUserData();
    await this.selectAccount(account.id);
  }

  public async selectAccount(accountId: number | null) {
    if (!accountId) {
      this.setSelectedAccountId(null);
      this.root.uiStore.setUserAccountLoadingState(
        ApiRequestStatusEnum.SUCCESS
      );
      return;
    }

    if (accountId === this.selectedAccountId) return;

    try {
      this.root.uiStore.setUserAccountLoadingState(
        ApiRequestStatusEnum.PENDING
      );
      const userAccountStore = this.userAccountsMap[accountId];
      await userAccountStore.fetchDetails();

      // cleanup account before moving to the next one
      this.selectedAccountStore?.accountLeadsStore.selectLead(null);
      this.selectedAccountStore?.accountLeadsStore.clearLeadNotifications();

      this.setSelectedAccountId(accountId);
      this.root.initSocketIO(this.root.authStore.authToken, accountId);
      setDefaultAccount(accountId);
      this.root.uiStore.setUserAccountLoadingState(
        ApiRequestStatusEnum.SUCCESS
      );
    } catch (e) {
      this.root.uiStore.setUserAccountLoadingState(ApiRequestStatusEnum.ERROR);
    }
  }
}
