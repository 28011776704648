import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { PROPERTIES_ROUTE, PROPERTY_DETAILS_ROUTE } from "constants/routes";
import { PropertyDetails } from "./routes/PropertyDetails";
import { Properties } from "./Properties";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
}

export const PropertiesIndex: FC<IProps> = observer(({ accountStore }) => {
  return (
    <Switch>
      <Route path={PROPERTIES_ROUTE} exact>
        <Properties accountStore={accountStore} />
      </Route>
      <Route path={PROPERTY_DETAILS_ROUTE} exact>
        <PropertyDetails accountStore={accountStore} />
      </Route>
    </Switch>
  );
});
