const hasSpace = /\s/;
const hasSeparator = /(_|-|\.|:)/;
const hasCamel = /([a-z][A-Z]|[A-Z][a-z])/;

function toNoCase(text: string) {
  if (hasSpace.test(text)) return text.toLowerCase();
  if (hasSeparator.test(text)) return (unseparate(text) || text).toLowerCase();
  if (hasCamel.test(text)) return uncamelize(text).toLowerCase();
  return text.toLowerCase();
}

const separatorSplitter = /[\W_]+(.|$)/g;

function unseparate(text: string) {
  return text.replace(separatorSplitter, function(m, next) {
    return next ? " " + next : "";
  });
}

const camelSplitter = /(.)([A-Z]+)/g;

function uncamelize(text: string) {
  return text.replace(camelSplitter, function(m, previous, uppers) {
    return (
      previous +
      " " +
      uppers
        .toLowerCase()
        .split("")
        .join(" ")
    );
  });
}

export function toSpace(text: string) {
  return toNoCase(text)
    .replace(/[\W_]+(.|$)/g, function(matches, match) {
      return match ? " " + match : "";
    })
    .trim();
}

export function toSnakeCase(text: string) {
  return toSpace(text).replace(/\s/g, "_");
}
