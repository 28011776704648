import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FormControlMeta, FormControlV2 } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TPageSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { Control } from "react-hook-form";
import { PageToolPageSettingsInputLayout } from "./PageToolPageSettingsInputLayout";

type TFieldValues = TPageSettingsFieldValues;

interface IProps<T extends TFieldValues> {
  control: Control<T>;
}

export function PageToolPageGDPRCheckboxSettings<T extends TFieldValues>({
  control,
}: IProps<T>) {
  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        GDPR checkbox
      </Text>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Enable GDPR checkbox"
          description={
            "Enable a checkbox to ask the lead to opt-in to your marketing. You can specify the text and whether or not acceptance is required."
          }
        />
        <FormControlV2<TFieldValues>
          name={"gdprCheckboxEnabled"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta label="Label" description={"Checkbox label."} />
        <FormControlV2<TFieldValues>
          name={"gdprCheckboxLabel"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Sign up to our newsletter",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Description"
          description={"Checkbox description."}
        />
        <FormControlV2<TFieldValues>
          name={"gdprCheckboxDescription"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Description",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Acceptance is required"
          description={
            "Enable this if you want the lead to check this box in order to view their valuation."
          }
        />
        <FormControlV2<TFieldValues>
          name={"gdprCheckboxAcceptanceRequired"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.SWITCH}
        />
      </PageToolPageSettingsInputLayout>
    </Box>
  );
}
