import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import { FC } from "react";

interface IProps {
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
}

export const LeadAttributionReportSegmentDetailsNoDataMessage: FC<IProps> = observer(
  ({ leadAttributionType, accountAnalyticsLeadAttributionStore }) => {
    const {
      accountAnalyticsLeadAttributionFiltersStore: {
        filtersDataByType,
        itemsByTypeSortedByEstValue,
      },
    } = accountAnalyticsLeadAttributionStore;
    const selectedFilterItems = filtersDataByType[leadAttributionType];
    const totalItems = itemsByTypeSortedByEstValue[leadAttributionType];

    if (!!selectedFilterItems.length) return null;

    let message = "";
    if (!totalItems.length) {
      message = `You currently have no ${leadAttributionType} based attribution data to display for your selected date range.`;
    } else {
      message = `Please select one or more ${leadAttributionType} to view attribution.`;
    }

    return (
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box textAlign={"center"} color={"leadpro.500"} maxWidth={"400px"}>
          {message}
        </Box>
      </Box>
    );
  }
);
