import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { LeadOfficeSelectOption } from "../custom-options";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface ILeadOfficeSelectOptionsRendererProps {
  optionsProps: IGetOptionPropsData<number>[];
}

export const LeadOfficeSelectOptionsRenderer: FC<ILeadOfficeSelectOptionsRendererProps> = ({
  optionsProps,
}) => {
  return (
    <>
      {optionsProps.map((props, index) => {
        return (
          <Box
            borderBottom={index > 0 ? "2px solid" : "none"}
            borderColor={"leadpro.50"}
            key={index}
          >
            <LeadOfficeSelectOption optionProps={props} />
          </Box>
        );
      })}
    </>
  );
};
