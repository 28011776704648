import { observer } from "mobx-react";
import { useEffect } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { Redirect } from "react-router-dom";
import { HOME_ROUTE } from "constants/routes";
import { useBoolean } from "@chakra-ui/react";

export const EcosystemRedirect = observer(() => {
  const [readyToRedirect, setReadyToRedirect] = useBoolean();
  const {
    authStore: { leadProAuthStore, ecosystemAuthStore },
  } = useAppStore();

  useEffect(() => {
    const loginRedirect = async () => {
      try {
        await leadProAuthStore.removeCredentials();
        await ecosystemAuthStore.loginRedirectCallback();
        setReadyToRedirect.on();
      } catch (error) {
        await ecosystemAuthStore.signoutRedirect();
      }
    };

    loginRedirect();
  }, [leadProAuthStore, ecosystemAuthStore, setReadyToRedirect]);

  if (readyToRedirect) return <Redirect to={HOME_ROUTE} />;

  return null;
});
