import React, { FC } from "react";
import { formatNumber } from "utils/formating.utils";
import { Box, BoxProps } from "@chakra-ui/react";

const statStyle: BoxProps = {
  fontSize: "16px",
};
const cellStyle: BoxProps = {
  p: 3,
};

interface ISubStatRow {
  title: string;
  total?: number;
  last30Days?: number;
  last60Days?: number;
  colour?: string;
}

export const SubStatRow: FC<ISubStatRow> = ({
  title,
  total,
  last30Days,
  last60Days,
  colour,
}: ISubStatRow) => {
  return (
    <Box
      as={"tr"}
      borderBottomWidth={"1px"}
      borderStyle={"solid"}
      borderColor={"gray.100"}
      _hover={{ bg: "gray.50" }}
      _last={{ borderBottomWidth: 0 }}
    >
      <Box as={"td"} {...cellStyle} pl={6}>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            rounded={"full"}
            mr={3}
            width={"10px"}
            height={"10px"}
            backgroundColor={colour}
          />

          <Box {...statStyle}>{title}</Box>
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle} color={"green.500"}>
          {formatNumber({ number: total })}
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle}>
          {last30Days}
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle}>
          {last60Days}
        </Box>
      </Box>
    </Box>
  );
};
