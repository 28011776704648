import { Error, Loader } from "components";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeSelectIntegrationTable } from "../AccountIntegrationConfigurator/OfficeSelectIntegrationTable";
import { SmeIntegrationEmployeesConfigurator } from "./SmeIntegrationEmployeesConfigurator/SmeIntegrationEmployeesConfigurator";
import { SmeIntegrationLeadSourcesConfigurator } from "./SmeIntegrationSourcesConfigurator/SmeIntegrationSourcesConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const SmeIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;

    const accountIntegrationSmeStore =
      accountIntegrationsStore.accountIntegrationSmeStore;
    const accountIntegrationOfficesStore =
      accountIntegrationSmeStore.accountIntegrationSmeOfficesStore;
    const accountIntegrationEmployeesStore =
      accountIntegrationSmeStore.accountIntegrationSmeEmployeesStore;
    const accountIntegrationSmeLeadSourcesStore =
      accountIntegrationSmeStore.accountIntegrationSmeLeadSourcesStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            accountUsersStore.fetchAccountUsers(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
            accountIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations(),
            accountIntegrationEmployeesStore.fetchSmeEmployeesAndRelations(),
            accountIntegrationSmeLeadSourcesStore.fetchAccountSmeSourcesAndRelations(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountOfficesStore,
      accountUsersStore,
      accountLeadSourcesStore,
      accountIntegrationOfficesStore,
      accountIntegrationEmployeesStore,
      accountIntegrationSmeLeadSourcesStore,
    ]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <OfficeSelectIntegrationTable
              accountIntegrationOfficesStore={accountIntegrationOfficesStore}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.SME}
            />
            <SmeIntegrationEmployeesConfigurator accountStore={accountStore} />
            <SmeIntegrationLeadSourcesConfigurator
              accountStore={accountStore}
            />
          </>
        )}
      </>
    );
  }
);
