import { AppStore } from "store/App.store";
import { TAccountDevelopersWebhookConfig } from "types/account-developers.type";
import { makeAutoObservable } from "mobx";
import { TAccountWebhookRequestResponse } from "types/account-developers.type";
import { AccountDevelopersApi } from "api/account-developers.api";

const developersApi = new AccountDevelopersApi();

export class AccountDevelopersWebhookStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private webhookConfigData: TAccountDevelopersWebhookConfig;
  private webhookRequestsData: TAccountWebhookRequestResponse | null;

  constructor(
    root: AppStore,
    accountId: number,
    data: TAccountDevelopersWebhookConfig
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.webhookConfigData = data;
    this.webhookRequestsData = null;
  }

  get webhookConfig() {
    return this.webhookConfigData;
  }

  get webhookRequests() {
    return this.webhookRequestsData;
  }

  public setWebhookRequests(webhookRequests: TAccountWebhookRequestResponse) {
    this.webhookRequestsData = webhookRequests;
  }

  public resetWebhookRequests() {
    this.webhookRequestsData = null;
  }

  private setWebhookConfigData(data: TAccountDevelopersWebhookConfig) {
    this.webhookConfigData = data;
  }

  public async fetchDetails() {
    const data = await developersApi.fetchAccountDevelopersWebhook(
      this.accountId,
      this.webhookConfigData.id
    );
    this.setWebhookConfigData(data);
  }

  public async updateWebhook(data: Partial<TAccountDevelopersWebhookConfig>) {
    const webhookData = await developersApi.updateAccountDeveloperWebhook(
      this.accountId,
      this.webhookConfigData.id,
      data
    );
    this.setWebhookConfigData(webhookData);
  }

  public async fetchWebhookRequests(offset?: number, limit?: number) {
    const webhookRequests = await developersApi.fetchAccountDevelopersWebhookRequests(
      this.accountId,
      this.webhookConfigData.id,
      offset,
      limit
    );

    this.setWebhookRequests(webhookRequests);
  }

  public async regenerateDeveloperWebhookSigningSecret() {
    const webhookData = await developersApi.regenerateAccountDeveloperWebhookSigningSecret(
      this.accountId,
      this.webhookConfigData.id
    );

    this.setWebhookConfigData(webhookData);
  }
}
