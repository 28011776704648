import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as MobxProvider } from "mobx-react";
import { App } from "./App";
import { defaultTheme } from "./theme/default.theme";
import { ActionPromptProvider } from "components/action-prompt/ActionPrompt";
import appStore from "store/App.store";
import { initSentry } from "utils/sentry.utils";
import "styles/index.css";

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <MobxProvider store={appStore}>
      <ChakraProvider theme={defaultTheme}>
        <Router>
          <ActionPromptProvider>
            <App />
          </ActionPromptProvider>
        </Router>
      </ChakraProvider>
    </MobxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
