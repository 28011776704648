import { observer } from "mobx-react";
import { Box, VStack } from "@chakra-ui/react";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import React, { FC } from "react";
import { QuestionnaireToolPageSettingsSelectedQuestionsList } from "./QuestionnaireToolPageSettingsSelectedQuestionsList";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Message } from "components";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import { QuestionnaireToolPageSettingsSelectedActionsList } from "./QuestionnaireToolPageSettingsSelectedActionsList";
import { capitalize } from "lodash";
import { getLeadTypeLabel } from "utils/lead-type.utils";

interface IProps {
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsSelectedListSectionContent: FC<IProps> = observer(
  ({ leadType, questionnaireToolPageWIPFormStore }) => {
    return (
      <VStack spacing={10}>
        {questionnaireToolPageWIPFormStore.isIgnored && (
          <Message status={AlertStatusEnum.INFO}>
            <Box>
              {`This questionnaire will not be sent to ${capitalize(
                getLeadTypeLabel(leadType)
              )} leads. You must
              specify at least one question to begin sending this questionnaire
              to your sale leads.`}
            </Box>
          </Message>
        )}
        <QuestionnaireToolPageSettingsSelectedQuestionsList
          leadType={leadType}
          questionnaireToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
        />
        <QuestionnaireToolPageSettingsSelectedActionsList
          leadType={leadType}
          questionnaireToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
        />
      </VStack>
    );
  }
);
