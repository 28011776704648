import { Box, Switch, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { BlockDivider } from "../BlockDivider";

interface IProps {
  isEnabled: boolean;
  onToggle: (isChecked: boolean) => void;
  children: React.ReactNode[];
}
export const LeadResponderWorkflowEditorComplexBlock: FC<IProps> = ({
  isEnabled,
  onToggle,
  children,
}) => {
  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onToggle(event.target.checked),
    [onToggle]
  );

  return (
    <VStack
      border={"2px dashed"}
      borderColor={"gray.200"}
      rounded={"md"}
      p={4}
      spacing={4}
    >
      <Box textAlign={"right"} width={"100%"}>
        <Switch isChecked={isEnabled} onChange={handleToggle} />
      </Box>
      <Box>
        {children.map((child, index) => {
          return (
            <VStack spacing={0} key={index}>
              {index > 0 && <BlockDivider />}
              {child}
            </VStack>
          );
        })}
      </Box>
    </VStack>
  );
};
