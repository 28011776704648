import { observer } from "mobx-react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ToggleIntegrationActiveSwitchInput } from "../ToggleIntegrationActiveSwitchInput";
import React, { useCallback, useMemo } from "react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TAccountIntegrationData,
  TIntegrationConfig,
} from "types/account-integration.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";

interface TFieldValues {
  active: boolean;
}

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

const validationSchema = Yup.object().shape({
  active: Yup.boolean().required(REQUIRED_FIELD),
});

export const ReapitIRIntegrationForm: React.FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();

    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.REAPIT_INTERNET_REGISTRATION
    ] as TAccountIntegrationData<TIntegrationConfig>;

    const initialValues: TFieldValues = useMemo(
      () => ({
        active: accountIntegration.active,
      }),
      [accountIntegration]
    );

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const onSubmit = useCallback(
      async ({ active, ...rest }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration(
            accountIntegration.id,
            {
              type: accountIntegration.type,
              active: active,
              integrationConfig: rest,
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={
                  "Reapit Internet Registrations integration configuration updated."
                }
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );
    return (
      <Box width={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connecting with Reapit Internet Registrations"}
            value={active}
            name={"active"}
            control={control}
          />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
        <Divider my={5} />
      </Box>
    );
  }
);
