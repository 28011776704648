import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { SingleSelectInput } from "components";
import React, { FC, useCallback, useMemo } from "react";
import { FormActionCreateLead } from "@leadpro/forms";
import { isActionParamFieldInError } from "utils/form-tool-page.utils";
import { FormToolPageWIPFormStore } from "../../../../../FormToolPageWIPForm.store";
import { FormToolPageSettingsActionConfiguratorItem } from "../../../../../../../../../components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionConfiguratorItem";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore: FormToolPageWIPFormStore;
}

export const FormToolPageSettingsCreateLeadActionQuestionsMapper: FC<IProps> = ({
  actionIndex,
  formToolPageWIPFormStore,
}) => {
  const action = formToolPageWIPFormStore.wipFormActionsJS[
    actionIndex
  ] as FormActionCreateLead;

  const onMappablePropertyChange = useCallback(
    (property: keyof FormActionCreateLead["params"]) => (
      questionKey: string | null
    ) => {
      if (!questionKey) return;

      formToolPageWIPFormStore.updateActionParams(actionIndex, {
        [property]: { mapping: questionKey },
      });
    },
    [actionIndex, formToolPageWIPFormStore]
  );

  const errors = useMemo((): {
    [key in keyof Omit<FormActionCreateLead["params"], "leadType">]: boolean;
  } => {
    const validationResult =
      formToolPageWIPFormStore.wipFormActionsValidationResult;

    return {
      email: isActionParamFieldInError("email", actionIndex, validationResult),
      firstName: isActionParamFieldInError(
        "firstName",
        actionIndex,
        validationResult
      ),
      lastName: isActionParamFieldInError(
        "lastName",
        actionIndex,
        validationResult
      ),
      phone: isActionParamFieldInError("phone", actionIndex, validationResult),
      postcode: isActionParamFieldInError(
        "postcode",
        actionIndex,
        validationResult
      ),
      address: isActionParamFieldInError(
        "address",
        actionIndex,
        validationResult
      ),
    };
  }, [formToolPageWIPFormStore.wipFormActionsValidationResult, actionIndex]);

  return (
    <VStack width={"100%"} spacing={2} pt={8}>
      <Box color={"leadpro.500"} width={"100%"} mb={4}>
        <HStack>
          <Box fontWeight={"bold"} fontSize={"sm"} flexShrink={0}>
            Map responses to lead fields
          </Box>
          <Divider flexGrow={1} />
        </HStack>
        <Box fontSize={"xs"}>
          Chose which questions you’d like the responses to map to the fields of
          your new lead.
        </Box>
      </Box>
      <FormToolPageSettingsActionConfiguratorItem
        label={"Email"}
        showError={errors.email}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.email.mapping}
              onChange={onMappablePropertyChange("email")}
              options={formToolPageWIPFormStore.emailQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
      <FormToolPageSettingsActionConfiguratorItem
        label={"First name"}
        showError={errors.firstName}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.firstName.mapping}
              onChange={onMappablePropertyChange("firstName")}
              options={formToolPageWIPFormStore.textQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
      <FormToolPageSettingsActionConfiguratorItem
        label={"Last name"}
        showError={errors.lastName}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.lastName.mapping}
              onChange={onMappablePropertyChange("lastName")}
              options={formToolPageWIPFormStore.textQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
      <FormToolPageSettingsActionConfiguratorItem
        label={"Phone"}
        showError={errors.phone}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.phone.mapping}
              onChange={onMappablePropertyChange("phone")}
              options={formToolPageWIPFormStore.phoneQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
      <FormToolPageSettingsActionConfiguratorItem
        label={"Postcode"}
        showError={errors.postcode}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.postcode.mapping}
              onChange={onMappablePropertyChange("postcode")}
              options={formToolPageWIPFormStore.postcodeQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
      <FormToolPageSettingsActionConfiguratorItem
        label={"Address"}
        showError={errors.address}
        input={
          <Box width={"100%"} maxWidth={"500px"}>
            <SingleSelectInput<string>
              value={action.params.address.mapping}
              onChange={onMappablePropertyChange("address")}
              options={formToolPageWIPFormStore.textAreaQuestionsAsOptions}
              placeholder={"select question"}
            />
          </Box>
        }
      />
    </VStack>
  );
};
