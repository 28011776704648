import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React, { FC } from "react";

interface IProps extends BoxProps {
  background?: string;
  topChildren?: React.ReactNode;
  lineChildren?: React.ReactNode;
}
export const LeadAttributionReportSegmentDetailsItemBarTemplate: FC<IProps> = ({
  background,
  topChildren,
  lineChildren,
  ...rest
}) => {
  return (
    <Flex
      flexGrow={1}
      flexDirection={"column"}
      alignItems={"center"}
      gap={3}
      {...rest}
    >
      {topChildren}
      <Box
        height={"5px"}
        width={"100%"}
        background={background || "leadpro.400"}
        borderRadius={"md"}
        position={"relative"}
      >
        {lineChildren}
      </Box>
    </Flex>
  );
};
