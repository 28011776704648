import { ApiMessageStack, FormControlV2, FormControlMeta } from "components";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import * as Yup from "yup";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import {
  Box,
  Divider,
  Portal,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TNormalizedUserData } from "types/user-data.type";
import { isValidPhone } from "utils/custom-yup-validators.utils";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormFooter } from "components/form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
  phoneNumber: Yup.string().test(
    "is-valid-phone",
    INVALID_PHONE_NUMBER,
    isValidPhone
  ),
});

interface IProps {
  user: TNormalizedUserData;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const UserDetails: React.FC<IProps> = observer(
  ({ user, containerRef }) => {
    const {
      authStore: { updateAuthUser },
    } = useAppStore();
    const toast = useToast();

    const initialValues: TFieldValues = useMemo(
      () => ({
        email: user.email,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phoneNumber: user.phoneNumber || "",
      }),
      [user]
    );

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    const onSubmit = useCallback(
      async ({ firstName, lastName, phoneNumber, email }: TFieldValues) => {
        try {
          await updateAuthUser({
            email,
            firstName,
            lastName,
            phoneNumber,
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={"Profile updated!"} />,
          });
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [toast, updateAuthUser]
    );

    return (
      <Box
        position={"relative"}
        minHeight={"100%"}
        overflow={"hidden"}
        pr={1}
        pt={1}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={10} align={"stretch"} divider={<Divider />}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Current email"
                description={
                  "Your contact email, used for logging into LeadPro Dashboard."
                }
              />
              <FormControlV2<TFieldValues>
                name={"email"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter your email",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="First name"
                description={"Your first name."}
              />
              <FormControlV2<TFieldValues>
                name={"firstName"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter your first name",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Last name"
                description={"Your last name."}
              />
              <FormControlV2<TFieldValues>
                name={"lastName"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter your last name",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Current phone"
                description={
                  <Box>
                    <Box mb={2}>Your mobile contact phone number.</Box>
                    <Box>
                      {user.sms2FAEnabled
                        ? "In order to change your phone number, you must first disable two-factor authentication."
                        : "In case you have enabled Two-factor authentication, sms code will be sent to this phone number."}
                    </Box>
                  </Box>
                }
              />
              <FormControlV2<TFieldValues>
                name={"phoneNumber"}
                control={control}
                isDisabled={user.sms2FAEnabled}
                type={FormControlsTypeEnum.TEL}
                additionalProps={{
                  placeholder: "Enter your phone number",
                }}
              />
            </SimpleGrid>

            {isDirty && (
              <Portal containerRef={containerRef}>
                <FormFooter
                  isSubmitting={isSubmitting}
                  submitForm={handleSubmit(onSubmit)}
                  resetForm={reset}
                />
              </Portal>
            )}
          </VStack>
        </form>
      </Box>
    );
  }
);
