import { observer } from "mobx-react";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useToast,
} from "@chakra-ui/react";
import { incompleteValuationLeadsNotificationEmailsValidationSchema } from "utils/validation-schemas/notification-emails.validation";
import { ApiMessageStack, FormControlV2 } from "components";
import { TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration;

interface IProps {
  isEnabled: boolean;
  isChangePending: boolean;
  formName: string;
  officeStore: AccountOfficeStore;
  setFormActions: (
    formName: string,
    formActions: { submitForm: () => Promise<void>; resetForm: () => void }
  ) => void;
}

export const IncompleteValuationLeadsEmailNotificationsForm: FC<IProps> = observer(
  ({ isEnabled, isChangePending, formName, officeStore, setFormActions }) => {
    const toast = useToast();
    const officeEmailNotificationsConfiguration =
      officeStore.officeIncompleteValuationLeadsEmailNotificationsConfiguration;

    const initialValues: TFieldValues = useMemo(
      () => ({
        vendorEmails: officeEmailNotificationsConfiguration?.vendorEmails || [],
        landlordEmails:
          officeEmailNotificationsConfiguration?.landlordEmails || [],
      }),
      [officeEmailNotificationsConfiguration]
    );

    const {
      handleSubmit,
      control,
      reset,
      formState: { isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(
        incompleteValuationLeadsNotificationEmailsValidationSchema
      ),
    });

    const onSubmit = useCallback(
      async ({ vendorEmails, landlordEmails }: TFieldValues) => {
        try {
          await officeStore.updateIncompleteValuationLeadsEmailNotificationsConfiguration(
            {
              vendorEmails,
              landlordEmails,
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description:
              "Your office notification emails settings have been successfully updated.",
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [officeStore, toast]
    );

    useEffect(() => {
      if (!isChangePending && isDirty) {
        setFormActions(formName, {
          submitForm: handleSubmit(onSubmit),
          resetForm: reset,
        });
      }
    }, [
      isChangePending,
      formName,
      setFormActions,
      isDirty,
      reset,
      handleSubmit,
      onSubmit,
    ]);

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem key={"incomplete-vendor-leads"}>
            <AccordionButton>
              <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                Incomplete Vendor leads
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <FormControlV2<TFieldValues>
                name={"vendorEmails"}
                control={control}
                label={"Emails"}
                isDisabled={!isEnabled}
                type={FormControlsTypeEnum.TAG_INPUT}
                additionalProps={{
                  placeholder: "Enter email and press enter or comma",
                }}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem key={"incomplete-landlord-leads"}>
            <AccordionButton>
              <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                Incomplete Landlord leads
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <FormControlV2<TFieldValues>
                name={"landlordEmails"}
                control={control}
                label={"Emails"}
                isDisabled={!isEnabled}
                type={FormControlsTypeEnum.TAG_INPUT}
                additionalProps={{
                  placeholder: "Enter email and press enter or comma",
                }}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </form>
    );
  }
);
