import {
  PersistentFilterExpressionOperator,
  PersistentFilterGroupOperator,
} from "enums/persistent-filter.enum";
import { UtmAttributeEnum } from "enums/utm-attributes.enum";
import { TFilterGroup } from "types/persistent-filters.type";
import { TSelectOption } from "types/select-input.type";

export const GROUP_OPERATOR_OPTIONS: TSelectOption<
  PersistentFilterGroupOperator
>[] = [
  {
    value: PersistentFilterGroupOperator.AND,
    label: "all",
  },
  {
    value: PersistentFilterGroupOperator.OR,
    label: "any",
  },
];

export const EXPRESSION_OPERATOR_OPTIONS: TSelectOption<
  PersistentFilterExpressionOperator
>[] = [
  {
    value: PersistentFilterExpressionOperator.EQUALS,
    label: "=",
  },
  {
    value: PersistentFilterExpressionOperator.NOT_EQUAL,
    label: "!=",
  },
];

export const EXTENDED_EXPRESSION_OPERATOR_OPTIONS: TSelectOption<
  PersistentFilterExpressionOperator
>[] = [
  ...EXPRESSION_OPERATOR_OPTIONS,
  {
    value: PersistentFilterExpressionOperator.CONTAINS,
    label: "contains",
  },
];

export const NEW_DEFAULT_FILTER_GROUP: TFilterGroup = {
  operator: PersistentFilterGroupOperator.AND,
  children: [],
};

export const UTM_ATTRIBUTE_OPTIONS: TSelectOption<UtmAttributeEnum>[] = [
  {
    value: UtmAttributeEnum.CAMPAIGN,
    label: "Campaign",
  },
  {
    value: UtmAttributeEnum.CONTENT,
    label: "Content",
  },
  {
    value: UtmAttributeEnum.MEDIUM,
    label: "Medium",
  },
  {
    value: UtmAttributeEnum.SOURCE,
    label: "Source",
  },
  {
    value: UtmAttributeEnum.TERM,
    label: "Term",
  },
  { value: UtmAttributeEnum.GCLID, label: "Gclid" },
];
