import {
  ACCOUNT_SETUP_ROUTE,
  ACHIEVEMENTS_ROUTE,
  ANALYTICS_ROUTE,
  ECOSYSTEM_FINALIZE_INVITE,
  ECOSYSTEM_LOGIN_ROUTE,
  ECOSYSTEM_REDIRECT_ROUTE,
  GUIDES_ROUTE,
  HOME_ROUTE,
  IMPERSONATION_ROUTE,
  LEADS_ROUTE,
  LEAGUE_ROUTE,
  LOGIN_ROUTE,
  PROPERTIES_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  SET_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  SIGNUP_ROUTE,
  TOOLS_ROUTE,
  USER_PROFILE_ROUTE,
} from "constants/routes";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Redirect, Route, Switch } from "react-router-dom";
import { Guides } from "routes/dashboard/routes/guides/Guides";
import { Leads } from "routes/dashboard/routes/leads/Leads";
import { Settings } from "routes/dashboard/routes/settings/Settings";
import { Analytics } from "routes/dashboard/routes/analytics/Analytics";
import { UserProfile } from "routes/dashboard/routes/user-profile/UserProfile";
import {
  EcosystemLogin,
  EcosystemRedirect,
  Impersonate,
  Login,
  PasswordResetForm,
  PasswordSetForm,
  UserRegistration,
  UserSignup,
} from "routes/auth/routes";
import { AuthLayout, NonAuthRoute } from "routes/auth/components";
import {
  AuthRoute,
  DashboardLayout,
  ShowForRoles,
} from "routes/dashboard/components";
import { League } from "./routes/dashboard/routes/league/League";
import { Achievements } from "./routes/dashboard/routes/achievements/Achievements";
import { UserRoleEnum } from "./enums/user-role.enum";
import { AccountSetup } from "./routes/dashboard/routes/account-setup/AccountSetup";
import { Tools } from "./routes/dashboard/routes/tools/Tools";
import { useAppStore } from "./utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import "@fontsource/roboto/400.css";
import * as serviceWorker from "./serviceWorkerRegistration";
import { useToast } from "@chakra-ui/react";
import { AlertStatusEnum } from "./enums/alert-status.enum";
import { ShowWhenEcosystemSupported } from "components";
import { EcosystemFinalizeInvite } from "./routes/auth/routes/ecosystem/EcosystemFinalizeInvite";
import { PropertiesIndex } from "./routes/dashboard/routes/properties/PropertiesIndex";

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-KQK2NNL",
  };
  TagManager.initialize(tagManagerArgs);
}

export const App = observer(() => {
  const toast = useToast();
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();

  useEffect(() => {
    const configuration = {
      onUpdate: (registration: ServiceWorkerRegistration) => {
        if (registration && registration.waiting) {
          toast({
            title: "New version available!",
            status: AlertStatusEnum.INFO,
            position: "top-right",
            isClosable: true,
            duration: null,
            description: "Close this message to refresh the LeadPro Dashboard.",
            onCloseComplete: () => {
              if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" });
                window.location.reload();
              }
            },
          });
        }
      },
    };

    serviceWorker.register(configuration);
  }, [toast]);

  return (
    <Switch>
      {/*NON AUTH ROUTES*/}
      <NonAuthRoute path={LOGIN_ROUTE} exact>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </NonAuthRoute>
      <NonAuthRoute path={SIGNUP_ROUTE} exact>
        <AuthLayout>
          <UserSignup />
        </AuthLayout>
      </NonAuthRoute>
      <NonAuthRoute path={REGISTER_ROUTE} exact>
        <AuthLayout>
          <UserRegistration />
        </AuthLayout>
      </NonAuthRoute>
      <NonAuthRoute path={RESET_PASSWORD_ROUTE} exact>
        <AuthLayout>
          <PasswordResetForm />
        </AuthLayout>
      </NonAuthRoute>
      <NonAuthRoute path={SET_PASSWORD_ROUTE} exact>
        <AuthLayout>
          <PasswordSetForm />
        </AuthLayout>
      </NonAuthRoute>
      <Route path={IMPERSONATION_ROUTE} exact>
        <Impersonate />
      </Route>
      <Route path={ECOSYSTEM_LOGIN_ROUTE} exact>
        <ShowWhenEcosystemSupported>
          <EcosystemLogin />
        </ShowWhenEcosystemSupported>
      </Route>
      <Route path={ECOSYSTEM_REDIRECT_ROUTE} exact>
        <ShowWhenEcosystemSupported>
          <EcosystemRedirect />
        </ShowWhenEcosystemSupported>
      </Route>
      <Route path={ECOSYSTEM_FINALIZE_INVITE} exact>
        <ShowWhenEcosystemSupported>
          <EcosystemFinalizeInvite />
        </ShowWhenEcosystemSupported>
      </Route>

      {/*AUTH ROUTES*/}
      <AuthRoute path={HOME_ROUTE}>
        <Route path={HOME_ROUTE} exact>
          <DashboardLayout>
            <Redirect
              to={{
                pathname: LEADS_ROUTE,
              }}
            />
          </DashboardLayout>
        </Route>
        <Route path={ACCOUNT_SETUP_ROUTE} exact>
          <AccountSetup />
        </Route>
        <Route path={`${LEADS_ROUTE}/:leadSubroute?`} exact>
          <DashboardLayout>
            {!!selectedAccountStore && (
              <Leads accountStore={selectedAccountStore} />
            )}
          </DashboardLayout>
        </Route>
        <Route path={PROPERTIES_ROUTE}>
          <DashboardLayout>
            {!!selectedAccountStore && (
              <PropertiesIndex accountStore={selectedAccountStore} />
            )}
          </DashboardLayout>
        </Route>
        <Route path={LEAGUE_ROUTE} exact>
          <DashboardLayout>
            {!!selectedAccountStore && (
              <League accountStore={selectedAccountStore} />
            )}
          </DashboardLayout>
        </Route>
        <Route path={ANALYTICS_ROUTE} exact>
          <DashboardLayout>
            {!!selectedAccountStore && (
              <Analytics accountStore={selectedAccountStore} />
            )}
          </DashboardLayout>
        </Route>
        <Route path={ACHIEVEMENTS_ROUTE} exact>
          <DashboardLayout>
            <Achievements />
          </DashboardLayout>
        </Route>
        <Route path={USER_PROFILE_ROUTE} exact>
          <DashboardLayout>
            <UserProfile />
          </DashboardLayout>
        </Route>
        <Route path={GUIDES_ROUTE}>
          <DashboardLayout>
            <Guides />
          </DashboardLayout>
        </Route>
        <Route path={TOOLS_ROUTE}>
          <DashboardLayout>
            <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
              {!!selectedAccountStore && (
                <Tools accountStore={selectedAccountStore} />
              )}
            </ShowForRoles>
          </DashboardLayout>
        </Route>
        <Route path={SETTINGS_ROUTE}>
          <DashboardLayout>
            <ShowForRoles roles={[UserRoleEnum.ADMIN, UserRoleEnum.LEADER]}>
              {!!selectedAccountStore && (
                <Settings accountStore={selectedAccountStore} />
              )}
            </ShowForRoles>
          </DashboardLayout>
        </Route>
      </AuthRoute>
    </Switch>
  );
});
