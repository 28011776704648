import React from "react";
import { Box, HStack } from "@chakra-ui/react";

interface IProps {
  label: string;
  count: number;
  isActive?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const LeadStageTab: React.FC<IProps> = ({
  label,
  count,
  icon,
  isActive,
  onClick,
}) => {
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      px={8}
      height={"100%"}
      fontSize={"sm"}
      color={"gray.500"}
      borderBottom={"2px solid"}
      borderColor={isActive === true ? "leadpro.200" : "transparent"}
      cursor="pointer"
      onClick={onClick}
    >
      <HStack spacing={3}>
        {!!icon && icon}
        <Box>{label}</Box>
      </HStack>
      <Box>{count}</Box>
    </Box>
  );
};
