import { LeadBlockHeader } from "../LeadBlocks/LeadBlockHeader";
import React, { FC } from "react";
import { Box, Circle, Flex, HStack, VStack } from "@chakra-ui/react";
import { UnreadIcon } from "components";
import { TLeadWithDetails } from "types/lead.type";
import moment from "moment";
interface IDefaultLeadAttributionProps {
  lead: TLeadWithDetails;
}
export const LeadAttributionDefault: FC<IDefaultLeadAttributionProps> = ({
  lead,
}) => {
  return (
    <Box>
      <LeadBlockHeader>Lead Position</LeadBlockHeader>
      <HStack w="100%" gap={2} align="center" px={8} py={4}>
        <Box
          flexBasis="10%"
          flexGrow={0}
          bg="leadpro.50"
          borderRadius="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={6}
          px={4}
        >
          <Circle size="40px" bg="white">
            <UnreadIcon fontSize="15px" color="green.500" />
          </Circle>
        </Box>
        <Box
          flexBasis="90%"
          flexGrow={1}
          bg="leadpro.50"
          borderRadius="lg"
          py={6}
          px={4}
        >
          <HStack gap={6}>
            <VStack align="start" spacing={0}>
              <Box fontSize="sm">
                {moment(lead.createdAt).format("DD MMM YYYY")}
              </Box>
              <Box fontSize="xs">{moment(lead.createdAt).fromNow()}</Box>
            </VStack>
            <VStack align={"start"} spacing={0}>
              <Box>Lead Created</Box>
              <Box fontSize={"sm"}>{`This ${lead.type} lead was created`}</Box>
            </VStack>
          </HStack>
        </Box>
      </HStack>
      <Flex justify="center" align="center" w="100%" mt={15}>
        <Box textAlign="center" fontSize={"sm"} width={"60%"}>
          As your lead progresses across marketing journeys you’ll see
          engagements and business generated from this lead here
        </Box>
      </Flex>
    </Box>
  );
};
