import { Box, BoxProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface IDefaultHeaderCellProps extends BoxProps {
  children: ReactNode;
}

export const DefaultHeaderCell: FC<IDefaultHeaderCellProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box fontSize={"sm"} color={"gray.500"} fontWeight={500} py={2} {...rest}>
      {children}
    </Box>
  );
};
