import { observer } from "mobx-react";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import React, { FC } from "react";
import { faHouse } from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  propertyStore: AccountPropertyStore;
}

export const PropertyDetailsInfoSection: FC<IProps> = observer(
  ({ propertyStore }) => {
    return (
      <Box width={"100%"}>
        <HStack spacing={4} alignItems={"center"}>
          <Box color={"leadpro.500"}>
            <FontAwesomeIcon icon={faHouse} fontSize={24} />
          </Box>
          <VStack spacing={2}>
            <Box>
              <Box fontSize={"sm"}>{propertyStore.property.address}</Box>
            </Box>
            {/*<LeadTypeTag type={} />*/}
          </VStack>
        </HStack>
      </Box>
    );
  }
);
