import { FC } from "react";
import { Box, Tag, TagLabel } from "@chakra-ui/react";
import { BasicUserInfo } from "components";
import { UserRoleLabel } from "constants/user-role-label";
import { UserRoleEnum } from "enums/user-role.enum";
import { TUserBasicData } from "types/user-data.type";

interface IProps {
  user: TUserBasicData;
  roleId?: UserRoleEnum;
}

export const OfficeUsersListItem: FC<IProps> = ({ user, roleId }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <BasicUserInfo user={user} />
      {!!roleId && (
        <Tag
          size={"sm"}
          variant="subtle"
          colorScheme="gray"
          borderRadius={"full"}
        >
          <TagLabel noOfLines={1}>{UserRoleLabel[roleId]}</TagLabel>
        </Tag>
      )}
    </Box>
  );
};
