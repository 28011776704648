import { AccountIVTPagesStore } from "./AccountIVTPages.store";
import { makeAutoObservable } from "mobx";
import { PageToolTypeEnum } from "enums/page-tool-type.enum";
import { IAccountPageToolStore } from "./IAccountPageToolStore";
import { AccountFormPagesStore } from "./AccountFormPagesStore/AccountFormPages.store";
import { AccountQuestionnairePagesStore } from "./AccountQuestionnairePagesStore/AccountQuestionnairePages.store";

export class AccountPagesStore {
  public readonly accountIVTPagesStore: AccountIVTPagesStore;
  public readonly accountFormPagesStore: AccountFormPagesStore;
  public readonly accountQuestionnairePagesStore: AccountQuestionnairePagesStore;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountIVTPagesStore = new AccountIVTPagesStore(accountId);
    this.accountFormPagesStore = new AccountFormPagesStore(accountId);
    this.accountQuestionnairePagesStore = new AccountQuestionnairePagesStore(
      accountId
    );
  }

  get accountPageStoreByType(): {
    [key in PageToolTypeEnum]: IAccountPageToolStore;
  } {
    return {
      [PageToolTypeEnum.INSTANT_VALUATION_TOOL]: this.accountIVTPagesStore,
      [PageToolTypeEnum.FORM]: this.accountFormPagesStore,
      [PageToolTypeEnum.QUESTIONNAIRE]: this.accountQuestionnairePagesStore,
    };
  }
}
