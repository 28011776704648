import React, { useMemo } from "react";
import { AvatarProps } from "@chakra-ui/react";
import { LeadSourceEnum } from "enums/lead-source.enum";
import {
  getLeadSourceLabel,
  getLeadSourceLogoUrl,
} from "utils/lead-source.utils";
import { TPortalData } from "types/portal.type";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { AvatarTag } from "./AvatarTag";
import { Dictionary } from "lodash";

interface ILeadSourceTagProps {
  source: LeadSourceEnum;
  accountLogoUrl: string | null;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  portalsMap: Dictionary<TPortalData>;
}

export const LeadSourceTag: React.FC<ILeadSourceTagProps> = ({
  source,
  accountLogoUrl,
  leadSourcesMapBySource,
  portalsMap,
}) => {
  const leadSource = useMemo(() => leadSourcesMapBySource[source], [
    source,
    leadSourcesMapBySource,
  ]);
  const portal = useMemo(
    () =>
      !!leadSource?.portalId ? portalsMap[leadSource.portalId] : undefined,
    [leadSource, portalsMap]
  );

  const sourceLogoUrlFromPortal = useMemo(() => {
    return getLeadSourceLogoUrl(accountLogoUrl, leadSource, portal);
  }, [accountLogoUrl, leadSource, portal]);

  const additionalStyling = useMemo(() => {
    const styling: Partial<AvatarProps> = {};

    if (!!sourceLogoUrlFromPortal) {
      styling.bg = "transparent";
    }

    return styling;
  }, [sourceLogoUrlFromPortal]);

  const leadSourceLabel = useMemo(() => {
    return getLeadSourceLabel(source, leadSource);
  }, [source, leadSource]);

  return (
    <AvatarTag
      label={leadSourceLabel}
      avatarUrl={sourceLogoUrlFromPortal}
      avatarStyling={additionalStyling}
      colorScheme={"white"}
      bg={"leadpro.100"}
    />
  );
};
