import React, { useMemo } from "react";
import { Box, BoxProps, Center, Circle } from "@chakra-ui/react";

const color1 = "var(--chakra-colors-gray-100)";
const color2 = "var(--chakra-colors-gray-200)";
const DISABLED_BG = `repeating-linear-gradient(-55deg,${color1},${color1} 10px,${color2} 10px,${color2} 20px);`;

export interface IProps extends BoxProps {
  children?: React.ReactNode;
  placeholder: string;
  icon: React.ReactNode;
  iconColor?: string;
  isDisabled?: boolean;
}

export const LeadResponderWorkflowEditorBlock = React.forwardRef<any, IProps>(
  ({ isDisabled, children, placeholder, icon, iconColor, ...rest }, ref) => {
    const bg = useMemo(() => {
      if (isDisabled) {
        return DISABLED_BG;
      }

      return !children ? "gray.50" : "white";
    }, [isDisabled, children]);

    return (
      <Box
        pointerEvents={isDisabled ? "none" : undefined}
        ref={ref}
        width={"200px"}
        height={"80px"}
        fontSize={"sm"}
        padding={2}
        rounded={"md"}
        bg={bg}
        border={`1px ${!children ? "dashed" : "solid"}`}
        borderColor={!children ? "gray.200" : "gray.100"}
        position={"relative"}
        cursor={"pointer"}
        {...rest}
      >
        <Circle
          size={10}
          bg={!!iconColor ? `${iconColor}.500` : "black"}
          color={"white"}
          shadow={"md"}
          position={"absolute"}
          top={0}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={"lg"}
          >
            {icon}
          </Box>
        </Circle>
        <Box width={"100%"} height={"100%"}>
          <Center
            width={"100%"}
            height={"100%"}
            color={!children ? "gray.400" : undefined}
            fontWeight={"normal"}
          >
            <Box noOfLines={1}>{children || placeholder}</Box>
          </Center>
        </Box>
      </Box>
    );
  }
);
