import React from "react";
import { Card } from "components/stats/Card";
import { Box, Text, Heading } from "@chakra-ui/react";

interface ITitleStatCardProps {
  title: string;
  titleColor?: string;
  value: string;
  description: string;
}
export const TitleStatCard: React.FC<ITitleStatCardProps> = ({
  title,
  titleColor,
  value,
  description,
}) => {
  return (
    <Card>
      <Box
        p={6}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box bg={titleColor} rounded={"md"} px={2} py={1}>
          <Text
            textTransform="uppercase"
            fontSize={"xs"}
            fontWeight={500}
            color={"#fff"}
          >
            {title}
          </Text>
        </Box>

        <Heading fontSize={"xl"} fontWeight={600} mb={1} mt={1}>
          {value}
        </Heading>

        <Text fontSize="sm" fontWeight={500} color="gray.500">
          {description}
        </Text>
      </Box>
    </Card>
  );
};
