import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { leadResponderWorkflowTableColumnDef } from "./leadResponderWorkflowTableColumnDef";
import { observer } from "mobx-react";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { LeadResponderWorkflowEditorPrompt } from "routes/dashboard/components/prompts/LeadResponderWorkflowEditorPrompt/LeadResponderWorkflowEditorPrompt";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ToolTour } from "../../../../../../components/tours/ToolTour/ToolTour";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderWorkflows: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [isReordering, setIsReordering] = useState(false);
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();

    const accountEmailTemplatesStore = accountStore.accountEmailTemplatesStore;
    const accountSmsTemplatesStore = accountStore.accountSmsTemplatesStore;
    const leadResponderStore = accountStore.leadResponderStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountEmailTemplatesStore.loadEmailTemplates(),
            accountSmsTemplatesStore.loadSmsTemplates(),
            leadResponderStore.loadWorkflows(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      setLoadingStatus,
      leadResponderStore,
      accountEmailTemplatesStore,
      accountSmsTemplatesStore,
    ]);

    const handleCreateOrEditWorkflow = useCallback(
      (workflow?: TLeadResponderWorkflow) => () => {
        setModal(
          <LeadResponderWorkflowEditorPrompt
            accountStore={accountStore}
            workflow={workflow}
            closePrompt={unSetModal}
          />,
          { size: "full" }
        );
      },
      [setModal, unSetModal, accountStore]
    );

    const onRemoveWorkflow = useCallback(
      (workflowId: number) => async () => {
        try {
          await leadResponderStore.removeWorkflow(workflowId);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Workflow was removed successfully."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [leadResponderStore, toast]
    );

    const handleRemoveWorkflow = useCallback(
      (workflow: TLeadResponderWorkflow) => () => {
        setModal(
          <ConfirmActionPrompt
            text={`Please confirm that you want to remove ${workflow.name} workflow.`}
            onConfirm={onRemoveWorkflow(workflow.id)}
            closePrompt={unSetModal}
          />
        );
      },
      [onRemoveWorkflow, setModal, unSetModal]
    );

    const handleIncreaseWorkflowPriority = useCallback(
      (workflow: TLeadResponderWorkflow) => async () => {
        try {
          setIsReordering(true);
          await leadResponderStore.handleIncreaseWorkflowPriority(workflow.id);
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsReordering(false);
        }
      },
      [leadResponderStore, toast]
    );

    const handleDecreaseWorkflowPriority = useCallback(
      (workflow: TLeadResponderWorkflow) => async () => {
        try {
          setIsReordering(true);
          await leadResponderStore.handleDecreaseWorkflowPriority(workflow.id);
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsReordering(false);
        }
      },
      [leadResponderStore, toast]
    );

    const handleOnRowClick = useCallback(
      (workflow: TLeadResponderWorkflow) => {
        handleCreateOrEditWorkflow(workflow)();
      },
      [handleCreateOrEditWorkflow]
    );

    const columnsDef = useMemo(() => {
      return leadResponderWorkflowTableColumnDef(
        handleCreateOrEditWorkflow,
        handleRemoveWorkflow,
        handleIncreaseWorkflowPriority,
        handleDecreaseWorkflowPriority,
        isReordering
      );
    }, [
      handleCreateOrEditWorkflow,
      handleRemoveWorkflow,
      handleIncreaseWorkflowPriority,
      handleDecreaseWorkflowPriority,
      isReordering,
    ]);

    return (
      <>
        <VStack spacing={4} width={"100%"}>
          <Message status={AlertStatusEnum.INFO}>
            <Box>
              <Box>
                Create one or more workflows to automate emails to leads based
                on your specified filters. You can re-order workflows to define
                the priority.
              </Box>
              <Box>
                If one or more workflows contains filters that overlap, the
                workflow order determines which workflow to run when a new lead
                comes in.
              </Box>
            </Box>
          </Message>
          <TableV2<TLeadResponderWorkflow>
            dataSource={leadResponderStore.workflowsArray}
            columns={columnsDef}
            pageSize={20}
            loadingStatus={loadingStatus}
            globalFilterInputPlaceholder={"Type filter name"}
            striped={true}
            onRowClick={handleOnRowClick}
            additionalActions={
              <Box flexGrow={1}>
                <Button
                  variant={"link"}
                  colorScheme={"blue"}
                  onClick={handleCreateOrEditWorkflow()}
                >
                  + Create workflow
                </Button>
              </Box>
            }
          />
        </VStack>
        <ToolTour tourId={DashboardTourEnum.LEAD_RESPONDER_TOUR} />
      </>
    );
  }
);
