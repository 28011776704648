import { useEffect, useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import { HOME_ROUTE } from "constants/routes";
import { decodeAuthToken } from "utils/jwt.utils";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { TLeadProAuthTokenDTO } from "types/token.type";

export const Impersonate = observer(() => {
  const {
    authStore: {
      leadProAuthStore: { impersonateUser },
    },
  } = useAppStore();
  const location = useLocation();
  const token = useMemo(() => {
    const t = queryString.parse(location.search)?.t as string;
    return t === "undefined" ? "" : t;
  }, [location]);

  useEffect(() => {
    if (token) {
      const decodedToken = decodeAuthToken<TLeadProAuthTokenDTO>(token);
      if (!decodedToken.isImpersonating) return;

      impersonateUser(token);
    }
  }, [token, impersonateUser]);

  return <Redirect to={HOME_ROUTE} />;
});
