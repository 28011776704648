import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { leadResponderLeadEmailsTableColumnDef } from "./leadResponderLeadEmailsTableColumnDef";
import { Box, VStack } from "@chakra-ui/react";
import { Paginator, TableV2 } from "components";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import {
  TLeadResponderLeadEmail,
  TLeadResponderLeadEmailTableData,
} from "types/account-lead-emails.type";
import { TOOLS_LEAD_RESPONDER_LEAD_EMAILS_DETAILS_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";
import { ITableV2SortBy } from "components/table-v2/table-v2";
import { transformSortBy } from "utils/sort-by.utils";

interface ILeadResponderLeadEmailsProps {
  accountStore: UserAccountStore;
}

export const LeadResponderLeadEmails: React.FC<ILeadResponderLeadEmailsProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const history = useHistory();

    const accountOfficesDataMap =
      accountStore.accountOfficesStore.accountOfficesDataMap;

    const leadEmailsStore =
      accountStore.leadResponderStore.leadResponderEmailsStore;
    const emailFiltersStore = leadEmailsStore.emailFilterStore;
    const { activeFilter, sortByArray } = emailFiltersStore;
    const meta = leadEmailsStore.meta;
    const currentPage = leadEmailsStore.currentPage;
    const leadEmailsArray = leadEmailsStore.leadEmails;
    const accountPortalMap = accountStore.portalsMapByName;
    const accountPortalOptions = accountStore.portalsAsSelectOptions;

    const fetchAccountLeadEmails = useCallback(async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await leadEmailsStore.loadEmails(sortByArray, activeFilter);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    }, [leadEmailsStore, activeFilter, sortByArray]);

    useEffect(() => {
      const fetchData = async () => {
        await fetchAccountLeadEmails();
      };
      fetchData();
    }, [fetchAccountLeadEmails]);

    const handlePageChange = useCallback(
      async (page: number) => {
        leadEmailsStore.setPage(page);
        await fetchAccountLeadEmails();
      },
      [fetchAccountLeadEmails, leadEmailsStore]
    );

    const handleSortBy = useCallback(
      (sortBy: ITableV2SortBy<TLeadResponderLeadEmail> | null) => {
        const transformedSortBy = transformSortBy<TLeadResponderLeadEmail>(
          sortBy
        );
        emailFiltersStore.setSortBy(transformedSortBy);
      },
      [emailFiltersStore]
    );

    const handleGoToEmailDetails = useCallback(
      (leadEmail: TLeadResponderLeadEmailTableData) => {
        history.push(
          TOOLS_LEAD_RESPONDER_LEAD_EMAILS_DETAILS_ROUTE.replace(
            ":id",
            leadEmail.id.toString()
          )
        );
      },
      [history]
    );

    const columnsDef = useMemo(() => {
      return leadResponderLeadEmailsTableColumnDef(
        accountOfficesDataMap,
        emailFiltersStore,
        accountPortalMap,
        accountPortalOptions,
        handleGoToEmailDetails
      );
    }, [
      accountOfficesDataMap,
      accountPortalMap,
      accountPortalOptions,
      handleGoToEmailDetails,
      emailFiltersStore,
    ]);

    const canPreviousPage = useMemo(() => {
      return currentPage > 0;
    }, [currentPage]);

    const canNextPage = useMemo(() => {
      return currentPage < Math.ceil(meta.count / meta.limit) - 1;
    }, [currentPage, meta.limit, meta.count]);

    return (
      <VStack align={"left"} overflow={"auto"}>
        <Box fontWeight={"bold"}>Lead Inbox</Box>
        <Box fontSize={"small"}>
          Here you can review what leads have reached your Leadpro system from
          portal emails, along with their routing status.
        </Box>

        <TableV2<TLeadResponderLeadEmail>
          dataSource={leadEmailsArray}
          columns={columnsDef}
          loadingStatus={loadingStatus}
          onSortByChange={handleSortBy}
          striped={true}
        />
        <Paginator
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={handlePageChange}
          pageIndex={currentPage}
          pageSize={meta.limit}
          total={meta.count}
        />
      </VStack>
    );
  }
);
