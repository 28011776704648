import { WebhookEventTypes } from "enums/account-developer-webhook-events.enum";
import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";

export const webhookEventConfigDescriptionMap: Record<
  WebhookEventTypes,
  JSX.Element
> = {
  [WebhookEventTypes.LEAD_CREATED]: (
    <Box>
      Triggers when a new lead is created on your dashboard. The delivered
      payload will contain the standard lead fields.
    </Box>
  ),
  [WebhookEventTypes.LEAD_UPDATED]: (
    <Box>
      <Box>
        Triggers when the following fields are updated on an existing lead:
      </Box>
      <UnorderedList pl={4}>
        <ListItem>User assignment</ListItem>
        <ListItem>Office assignment</ListItem>
        <ListItem>Status change</ListItem>
        <ListItem>Next action date change</ListItem>
      </UnorderedList>
      <Box mt={2}>
        The delivered payload will contain the standard lead fields with updated
        values.
      </Box>
    </Box>
  ),
  [WebhookEventTypes.LEAD_NOTE_ADDED]: (
    <Box>
      Triggers when an agent note is added to an existing lead. This event will
      not trigger when system activity notes are added to a lead. The delivered
      payload will contain the standard lead fields with an additional notes
      field, containing a collection of notes associated with the lead.
    </Box>
  ),
  [WebhookEventTypes.LEAD_SYNCED]: (
    <Box>
      <Box>
        Triggers when a lead is sync'd to a CRM integration.Currently supported
        for the following integrations:
      </Box>
      <UnorderedList pl={4}>
        <ListItem>Reapit</ListItem>
      </UnorderedList>
    </Box>
  ),
  [WebhookEventTypes.LEAD_QUESTIONNAIRE_RESPONSE_ADDED]: (
    <Box>
      Triggers when a qualification questionnaire response is recorded against
      an existing lead. The delivered payload will contain the standard lead
      fields with an additional questionnaire response field, containing a
      collection of questions and responses.
    </Box>
  ),
  [WebhookEventTypes.LEAD_AVAILABILITY_ADDED]: (
    <Box>
      Triggers when a valuation lead is updated with appointment availability
      times from the recipient. The delivered payload will contain the standard
      lead fields with an appointment availability field containing a collection
      of proposed dates and times.
    </Box>
  ),
};
