import { ITableV2Column } from "components/table-v2/table-v2";
import { TAccountOfficeData } from "types/account-office.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { StandardIconButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { DATE_FORMAT } from "constants/date";
import { formatDate } from "utils/date.utils";

export const accountOfficesTableColumnDef = (
  handleRemoveOfficeFromAccount: (
    office: TAccountOfficeData
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
): ITableV2Column<TAccountOfficeData>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Office name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Created</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "id",
      excludeFromGlobalFilter: true,
      width: "35px",
      disableSortBy: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <StandardIconButton
            onClick={handleRemoveOfficeFromAccount(row)}
            aria-label={"remove-office"}
            icon={<FontAwesomeIcon icon={faXmark} />}
          />
        </DefaultRowCell>
      ),
    },
  ];
};
