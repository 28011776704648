import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { IntegrationEntitySyncTag } from "components";
import React from "react";
import { IntegrationOfficeSyncInput } from "components/input/IntegrationOfficeSyncInput";
import { TIntegrationSyncOfficeExtendedData } from "types/integration-sync-office.type";
import { TAccountIntegrationsStaticData } from "types/account-integration.type";

export const syncOfficeTableColumnDef = (
  updateSyncPair: (syncPair: TIntegrationSyncOfficeExtendedData) => void,
  integration: TAccountIntegrationsStaticData
): ITableV2Column<TIntegrationSyncOfficeExtendedData>[] => {
  return [
    {
      accessor: "officeName",
      Header: <DefaultHeaderCell>LeadPro office</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.officeName}</DefaultRowCell>,
    },
    {
      accessor: "branchId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: (
        <DefaultHeaderCell>{`${integration.name} office`}</DefaultHeaderCell>
      ),
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationOfficeSyncInput
            onChange={updateSyncPair}
            record={row}
            placeholder={`Enter ${integration.name} office ID`}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
