import React from "react";
import { Box, Divider } from "@chakra-ui/react";
import { AccountBillingPaymentMethod } from "./AccountBillingPaymentMethod";
import { AccountBillingPaymentIssues } from "./AccountBillingPaymentIssues";
import { withStripe } from "utils/hoc/withStripe.hoc";
import { AccountBillingAddress } from "./AccountBillingAddress";
import { AccountBillingContact } from "./AccountBillingContact";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountBillingPaymentMethodTab: React.FC<IProps> = observer(
  ({ accountStore }) => {
    const accountBillingStore = accountStore.accountBillingStore;

    return withStripe(
      <Box>
        <AccountBillingPaymentIssues
          accountBillingStore={accountBillingStore}
        />
        <AccountBillingPaymentMethod
          accountBillingStore={accountBillingStore}
        />
        <Divider />
        <AccountBillingAddress accountBillingStore={accountBillingStore} />
        <Divider />
        <AccountBillingContact accountBillingStore={accountBillingStore} />
        <Divider />
      </Box>
    );
  }
);
