import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { InstantValuationToolHowItWorksItem } from "./InstantValuationToolHowItWorksItem";
import {
  CUSTOM_SUB_DOMAIN_IVT_URL,
  LEADPRO_WEBSITE_IVT_PRODUCT_PAGE,
  LEADPRO_WEBSITE_VALUATION_URL,
} from "constants/external-paths";

export const InstantValuationToolHowItWorks = () => {
  return (
    <Box>
      <Box pb={6} fontSize={"3xl"} fontWeight={700}>
        How it works
      </Box>
      <HStack justify={"space-between"} spacing={4} align={"flex-start"}>
        <InstantValuationToolHowItWorksItem
          title="Embed"
          description="Embed IVT as a standalone page, anywhere on your website."
          demoHref={LEADPRO_WEBSITE_VALUATION_URL}
        />
        <InstantValuationToolHowItWorksItem
          title="Domain"
          description="Host IVT on a custom or lead.pro domain."
          demoHref={CUSTOM_SUB_DOMAIN_IVT_URL}
        />
        <InstantValuationToolHowItWorksItem
          title="Popup"
          description="Add IVT as a popup, on every page of your website."
          demoHref={LEADPRO_WEBSITE_IVT_PRODUCT_PAGE}
        />
      </HStack>
    </Box>
  );
};
