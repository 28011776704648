import { SingleDatePickerInput } from "components";
import React, { FC, useCallback } from "react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { useToast } from "@chakra-ui/react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { LeadInfoRowValue } from "../../LeadInfoRow";
import { LeadControlRow } from "./LeadControlRow";
import { LeadControlRowLabel } from "./LeadControlLabel";

interface IProps {
  leadStore: AccountLeadStore;
}

export const LeadNextActionDatePicker: FC<IProps> = observer(
  ({ leadStore }) => {
    const toast = useToast();
    const handleNextActionDateChange = useCallback(
      async (nextActionDate: string | null) => {
        try {
          await leadStore.update({
            nextActionDate: nextActionDate,
          });
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [leadStore, toast]
    );

    return (
      <LeadControlRow>
        <LeadControlRowLabel>Next Action</LeadControlRowLabel>
        <LeadInfoRowValue>
          <SingleDatePickerInput
            noIcon
            noBorder
            date={leadStore.lead.nextActionDate || ""}
            onChange={handleNextActionDateChange}
            clearable={true}
          />
        </LeadInfoRowValue>
      </LeadControlRow>
    );
  }
);
