import {
  TIntegrationOffice,
  TIntegrationOfficeRelation,
} from "types/integration-sync-office.type";
import { TSmeAddress, TSmeBranch, TSmeBranchRelation } from "types/sme.type";
import { normaliseAndSplitAddress } from "./integration-address.utils";

export const smeBranchDataToIntegrationOfficeDataMapper = (
  smeBranches: TSmeBranch[]
): TIntegrationOffice[] => {
  return smeBranches.map(smeBranch => ({
    id: smeBranch.id.toString(),
    name: smeBranch.name,
  }));
};

export const mapSmeBranchRelationsToIntegrationOfficeRelations = (
  smeBranchRelations: TSmeBranchRelation[]
): TIntegrationOfficeRelation[] => {
  return smeBranchRelations.map(smeBranchRelation => ({
    id: smeBranchRelation.id,
    officeId: smeBranchRelation.officeId,
    branchId: smeBranchRelation.smeId?.toString() ?? null,
  }));
};

export const mapIntegrationOfficeRelationsToSmeBranchRelations = (
  integrationOfficeRelations: TIntegrationOfficeRelation[]
): TSmeBranchRelation[] => {
  return integrationOfficeRelations.map(integrationOfficeRelation => ({
    id: integrationOfficeRelation.id,
    officeId: integrationOfficeRelation.officeId,
    smeId: Number(integrationOfficeRelation.branchId) ?? null,
  }));
};

export const getSmeSegmentedAddress = (
  address: string | null,
  postcode: string | null
): TSmeAddress => {
  const smeAddress = {
    address_line_one: "",
    address_line_two: "",
    address_line_three: "",
    address_line_four: "",
    postcode: postcode || "",
  };

  if (!address) {
    return smeAddress;
  }

  const splitAddress = normaliseAndSplitAddress(address, postcode);

  [
    smeAddress.address_line_one,
    smeAddress.address_line_two,
    smeAddress.address_line_three,
    smeAddress.address_line_four,
  ] = splitAddress.slice();

  return smeAddress;
};
