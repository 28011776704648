import { TAccountApplicationData } from "types/account-application.type";
import { TPageData } from "types/page.type";
import outdent from "outdent";

const BASE_URL = "https://assets.lead.pro/js/widgets/proval/v1/";

export function getFBTrackingPixelScript(facebookPixelId: string) {
  return outdent`
<!-- Facebook Pixel Code -->
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${facebookPixelId}');
  fbq('track', 'PageView');
</script>
<noscript>
  <img height="1" width="1" style="display:none" 
       src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1"/>
</noscript>
<!-- End Facebook Pixel Code -->`;
}

export function getGoogleAnalyticsScript({
  googleAnalyticsId,
  googleAdsConversionId,
}: TPageData) {
  return outdent`
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId ||
    googleAdsConversionId}"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());${
    !!googleAnalyticsId
      ? `
  gtag('config', '${googleAnalyticsId}', {
    'send_page_view': false,
  });`
      : ""
  }${
    !!googleAdsConversionId
      ? `
  gtag('config', '${googleAdsConversionId}');`
      : ""
  }
</script>
<!-- End Global site tag (gtag.js) - Google Analytics -->`;
}

export function getIVTFullPageScript(
  application?: TAccountApplicationData,
  page?: TPageData
) {
  let code = outdent`
<script src="${BASE_URL}page.js" defer></script>
<script>
    (function (w, d, n, a, m) {
      w[n] = w[n] || function () {
        (w[n].q = w[n].q || []).push(arguments);
      };
    })(window, document, 'LeadPro');

    LeadPro('init', {
      appId: '${application?.key}',
      pageId: '${page?.id}',
      container: 'lead-pro-widget'
    });
</script>
<div id='lead-pro-widget'></div>`;

  if (!!page?.facebookPixelId) {
    const fbCode = getFBTrackingPixelScript(page.facebookPixelId);
    code = outdent`
    ${fbCode}
    ${code}`;
  }

  if (!!page?.googleAnalyticsId || !!page?.googleAdsConversionId) {
    const googleAnalyticsCode = getGoogleAnalyticsScript(page);
    code = outdent`
    ${googleAnalyticsCode}
    ${code}`;
  }

  return code;
}

export function getIVTPopupScript(
  application?: TAccountApplicationData,
  page?: TPageData
) {
  let code = `<script src="${BASE_URL}popup.js" defer></script>
<script>
  (function (w, d, n) {
    w[n] =
      w[n] ||
      function () {
        (w[n].q = w[n].q || []).push(arguments)
      }
  })(window, document, 'LeadPro')
  LeadPro('init', {
    appId: '${application?.key}',
    pageId: '${page?.id}',
    position: 'bottom-right', // 'bottom-left', 'top-right', 'top-left'
  })
</script>`;

  if (!!page?.googleAnalyticsId || !!page?.googleAdsConversionId) {
    const googleAnalyticsCode = getGoogleAnalyticsScript(page);
    code = outdent`
    ${googleAnalyticsCode}
    ${code}`;
  }

  return code;
}

export function getIVTPopupScriptEmail(
  application?: TAccountApplicationData,
  page?: TPageData
) {
  const code = getIVTPopupScript(application, page);
  return encodeURIComponent(outdent`
    Dear Sir or Madam,
    
    I would like to add the LeadPro instant online valuation widget
    to our website.
  
    The following code needs to be pasted into our website template,
    just before the closing &lt;body&gt; tag.
    
    ${code}
    
    Once done, you will have the Instant Valuation Tool installed as a popup.
    
    If you have any questions, please email contact@lead.pro and CC
    me in.
    
    Kind regards,
    `);
}

export function getIVTPopupFullPageEmail(
  application?: TAccountApplicationData,
  page?: TPageData
) {
  const code = getIVTFullPageScript(application, page);
  return encodeURIComponent(outdent`
    Dear Sir or Madam,
    
    I would like to add the LeadPro instant online valuation widget
    to our website.
  
    The following code needs to be pasted into our website template,
    just before the closing &lt;body&gt; tag.
    
    ${code}
    
    Once done, you will have the Instant Valuation Tool installed as a fullscreen.
    
    If you have any questions, please email contact@lead.pro and CC
    me in.
    
    Kind regards,
    `);
}
