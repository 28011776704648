import { BaseApi } from "./base.api";
import { TLeadActivityResponse } from "../types/account-lead-activity.type";

export class AccountLeadActivitiesApi extends BaseApi {
  async fetchAccountLeadActivities(
    accountId: number,
    leadId: number,
    params?: { beforeDate?: string; limit?: number }
  ): Promise<TLeadActivityResponse> {
    return await this.get(`/accounts/${accountId}/leads/${leadId}/activities`, {
      params,
    });
  }
}
