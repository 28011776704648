import { StandardIconButton, TypeFormWidget } from "components";
import React, { useMemo } from "react";
import { UserDropdown } from "routes/dashboard/components";
import { Box, Button, Heading, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { PageHeaderBreadcrumbs } from "./PageHeaderBreadcrumbs";
import { PageHeaderTourButton } from "./PageHeaderTourButton";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { getTypeformOptions } from "utils/typeform.utils";
const env = runtimeEnv();

export interface IPageHeaderProps {
  title?: React.ReactNode;
  middleElement?: React.ReactNode;
}

export const PageHeader: React.FC<IPageHeaderProps> = observer(
  ({ title, middleElement }) => {
    const {
      uiStore: { isLeftSidebarOpen, toggleLeftSidebar },
      authStore: { authUser, removeDashboardTour },
      userAccountsStore: { selectedAccountStore },
    } = useAppStore();
    const accountData = selectedAccountStore?.account;

    const typeformWidgetOptions = useMemo(
      () =>
        getTypeformOptions({
          account: accountData?.id,
          email: authUser?.email,
          firstname: authUser?.firstName,
        }),
      [accountData?.id, authUser]
    );

    return (
      <Box
        height="70px"
        borderBottom={"1px solid"}
        borderColor={"leadpro.200"}
        px={6}
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        style={{ flexShrink: 0 }}
        bg={"leadpro.50"}
      >
        <HStack spacing={2} align={"center"}>
          {!isLeftSidebarOpen && (
            <StandardIconButton
              aria-label={"expand-left-side-bar"}
              color={"leadpro.400"}
              icon={
                <FontAwesomeIcon icon={faSquareChevronRight} fontSize={20} />
              }
              onClick={() => toggleLeftSidebar()}
            />
          )}
          <Heading fontSize="md" fontWeight="500">
            {!!selectedAccountStore && (
              <PageHeaderBreadcrumbs
                accountStore={selectedAccountStore}
                title={title}
              />
            )}
          </Heading>
        </HStack>
        {middleElement}
        <HStack spacing={6} align={"center"}>
          <TypeFormWidget
            options={typeformWidgetOptions}
            typeFormId={env.REACT_APP_TYPEFORM_ID_SUPPORT}
          >
            <Button
              size={"xs"}
              fontWeight={500}
              variant={"link"}
              color={"leadpro.600"}
            >
              Need help?
            </Button>
          </TypeFormWidget>
          {!!selectedAccountStore && (
            <PageHeaderTourButton
              accountStore={selectedAccountStore}
              removeDashboardTour={removeDashboardTour}
            />
          )}
          {!!authUser && <UserDropdown user={authUser} />}
        </HStack>
      </Box>
    );
  }
);
