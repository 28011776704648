import { Box, Button, Divider, HStack, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, FC } from "react";
import { SearchDelayedInput } from "components";

interface IProps {
  handleAddItem: () => void;
  addItemLabel: string;
  handleFiltering: (event: ChangeEvent<HTMLInputElement>) => void;
  renderItems: () => React.ReactNode[];
}

export const OfficeUsersList: FC<IProps> = ({
  handleAddItem,
  addItemLabel,
  handleFiltering,
  renderItems,
}) => {
  return (
    <VStack spacing={3} align={"stretch"} pr={1} divider={<Divider />}>
      <HStack justify={"space-between"} align={"center"} width={"100%"} pt={1}>
        <Button colorScheme={"teal"} onClick={handleAddItem}>
          {addItemLabel}
        </Button>
        <Box width={"300px"}>
          <SearchDelayedInput
            placeholder={"Filter by email or name"}
            handleSearch={handleFiltering}
          />
        </Box>
      </HStack>
      <VStack spacing={3} divider={<Divider />}>
        {renderItems()}
      </VStack>
    </VStack>
  );
};
