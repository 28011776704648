import { TLead } from "types/lead.type";
import { useMemo } from "react";
import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { Table } from "components/table";
import { Box } from "@chakra-ui/react";
import { LeadBlockHeader } from "../../LeadBlockHeader";
import { basicLeadsTableColumnDef } from "./basicLeadsTableColumnDef";
import {
  CONNECTED_LEAD_LIMIT,
  ConnectedLeadsFooter,
} from "./components/ConnectedLeadsFooter";

export interface ILeadConnectedLeadsBlockDefinition
  extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.ConnectedLeadsBlock;
  leads: TLead[];
}

export const LeadConnectedLeadsBlock = leadBlockHoc<
  ILeadConnectedLeadsBlockDefinition
>(({ leadBlockDefinition }) => {
  const {
    accountLeadsStore: { selectLead },
    accountLeadStatusesStore: { leadStatusesMapByStatusId },
  } = leadBlockDefinition.accountStore;

  const columns = useMemo(
    () => basicLeadsTableColumnDef(leadStatusesMapByStatusId),
    [leadStatusesMapByStatusId]
  );

  const isConnectedLeadsLimitReached = useMemo(
    () => leadBlockDefinition.leads.length >= CONNECTED_LEAD_LIMIT,
    [leadBlockDefinition]
  );

  const tableFooter = useMemo(
    () =>
      isConnectedLeadsLimitReached ? (
        <ConnectedLeadsFooter leadBlockDefinition={leadBlockDefinition} />
      ) : null,
    [isConnectedLeadsLimitReached, leadBlockDefinition]
  );

  const content = useMemo(() => {
    return (
      <Table<TLead>
        getRowId={row => `${row.id}`}
        onRowClick={lead => selectLead(lead.id)}
        total={leadBlockDefinition.leads.length}
        data={leadBlockDefinition.leads}
        defaultSort={[
          {
            id: "createdAt",
            desc: true,
          },
        ]}
        columns={columns}
        customFooter={tableFooter}
      />
    );
  }, [leadBlockDefinition, selectLead, columns, tableFooter]);

  return (
    <>
      <LeadBlockHeader>Related leads</LeadBlockHeader>
      <Box mt={4}>{content}</Box>
    </>
  );
});
