import { ITableV2Column } from "components/table-v2/table-v2";
import { TAccountDevelopersApiKeyConfig } from "types/account-developers.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import { AccountDevelopersApisTableActionCell } from "./AccountDevelopersApisTableActionCell";

export const developersConfigApiKeysTableColumnDef = (
  handleDeleteApiConfig: (row: TAccountDevelopersApiKeyConfig) => () => void,
  handleEditApiConfig: (row: TAccountDevelopersApiKeyConfig) => () => void
): ITableV2Column<TAccountDevelopersApiKeyConfig>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Created at</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell py={2}>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
      width: "100px",
    },
    {
      accessor: "id",
      width: "70px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <AccountDevelopersApisTableActionCell
          row={row}
          handleDeleteApiConfig={handleDeleteApiConfig(row)}
          handleEditApiConfig={handleEditApiConfig(row)}
        />
      ),
    },
  ];
};
