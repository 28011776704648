import React from "react";
import { FormToolPageSettingsTemplateQuestion } from "./FormToolPageSettingsTemplateQuestion";
import { FormToolPageQuestionTemplateOptions } from "constants/form-tool-page";
import { Droppable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
} from "@chakra-ui/react";
import { DraggableElement } from "components";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";

export const FormToolPageSettingsTemplateQuestionList = () => {
  return (
    <Droppable
      droppableId={FormToolPageDroppableContainerEnum.TEMPLATE_QUESTIONS}
      isDropDisabled={true}
    >
      {provided => (
        <Accordion defaultIndex={[0]} allowMultiple width={"100%"}>
          <AccordionItem key={"questions"} border={"none"}>
            <AccordionButton px={0}>
              <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                Questions
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <VStack
                spacing={4}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {FormToolPageQuestionTemplateOptions.map(
                  (questionTemplate, index) => (
                    <DraggableElement
                      id={`${FormToolPageDroppableContainerEnum.TEMPLATE_QUESTIONS}:${questionTemplate.type}`}
                      key={questionTemplate.type}
                      index={index}
                    >
                      <FormToolPageSettingsTemplateQuestion
                        questionTemplate={questionTemplate}
                      />
                    </DraggableElement>
                  )
                )}
                {provided.placeholder}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Droppable>
  );
};
