import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Portal,
  useToast,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { TAutocallerConfiguration } from "types/autocaller.type";
import { groupBy, pick } from "lodash";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { AutocallerSourceLeadTypeToggle } from "./AutocallerSourceLeadTypeToggle";
import { AutocallerPhoneForm } from "./AutocallerPhoneForm";
import { TLeadSourceTypeData } from "types/lead-source.type";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { ToolTour } from "routes/dashboard/components/tours/ToolTour/ToolTour";
import {
  TAutocallerPhonesFieldValues,
  autocallerValidationSchema,
} from "utils/validation-schemas/autocaller.validation";
import { FormControlMeta, FormFooter } from "components/form";
import { ApiMessageStack } from "components";
import { AutocallerConfiguratorDemoCall } from "./AutocallerConfiguratorDemoCall";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { autoAttendantFieldNamesByLeadType } from "utils/autocaller.utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = TAutocallerPhonesFieldValues;

interface IProps {
  autocallerConfiguration: TAutocallerConfiguration;
  onConfigToggle: (leadSourceTypeId: number, isOn: boolean) => void;
  isDisabled?: boolean;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  sourceSelectorLabel?: React.ReactNode;
  officeStore?: AccountOfficeStore;
  accountStore: UserAccountStore;
}

export const AutocallerConfigurator: React.FC<IProps> = observer(
  ({
    autocallerConfiguration,
    onConfigToggle,
    isDisabled,
    containerRef,
    sourceSelectorLabel,
    officeStore,
    accountStore,
  }) => {
    const toast = useToast();
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const leadSourceTypesDictionary =
      accountLeadSourcesStore.leadSourceTypesMap;
    const leadSourcesDictionary = accountLeadSourcesStore.leadSourcesMap;
    const portalsDictionary = accountStore.portalsMap;
    const officeAutocallerConfiguration =
      officeStore?.officeAutocallerConfiguration;

    // TODO NENAD: FIX THIS DATA QUERY MESS
    const filteredSourceTypesGroupedByLeadType = useMemo(() => {
      const leadSourceTypeIds = Object.keys(
        autocallerConfiguration.leadSourceTypes
      );
      const filteredLeadSourceTypesDictionary = pick(
        leadSourceTypesDictionary,
        leadSourceTypeIds
      );

      return groupBy(
        Object.values(filteredLeadSourceTypesDictionary),
        (lst: TLeadSourceTypeData) => lst.type
      ) as { [key: string]: TLeadSourceTypeData[] };
    }, [autocallerConfiguration, leadSourceTypesDictionary]);

    const filteredSourceTypesGroupedByLeadTypeKeys = useMemo(() => {
      return Object.keys(filteredSourceTypesGroupedByLeadType);
    }, [filteredSourceTypesGroupedByLeadType]);

    const initialValues: TFieldValues = useMemo(() => {
      return {
        salesAutoAttendant:
          officeAutocallerConfiguration?.salesAutoAttendant || "",
        lettingsAutoAttendant:
          officeAutocallerConfiguration?.lettingsAutoAttendant || "",
        landlordAutoAttendant:
          officeAutocallerConfiguration?.landlordAutoAttendant || "",
        vendorAutoAttendant:
          officeAutocallerConfiguration?.vendorAutoAttendant || "",
        mortgagesAutoAttendant:
          officeAutocallerConfiguration?.mortgagesAutoAttendant || "",
      };
    }, [officeAutocallerConfiguration]);

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(autocallerValidationSchema),
    });

    const onSubmit = useCallback(
      async ({
        salesAutoAttendant,
        lettingsAutoAttendant,
        landlordAutoAttendant,
        vendorAutoAttendant,
        mortgagesAutoAttendant,
      }: TFieldValues) => {
        if (!!officeStore) {
          try {
            await officeStore.updateOfficeAutocallerConfiguration({
              salesAutoAttendant,
              lettingsAutoAttendant,
              landlordAutoAttendant,
              vendorAutoAttendant,
              mortgagesAutoAttendant,
            });
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description:
                "Your office autocaller settings have been successfully updated.",
            });
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          }
        }
      },
      [officeStore, toast]
    );

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Accordion defaultIndex={[0]} allowMultiple>
            {!filteredSourceTypesGroupedByLeadTypeKeys.length && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"200px"}
                color={"gray.400"}
              >
                Please configure your portals.
              </Box>
            )}
            {!!filteredSourceTypesGroupedByLeadTypeKeys.length &&
              filteredSourceTypesGroupedByLeadTypeKeys.map(leadType => (
                <AccordionItem key={leadType}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" textTransform={"capitalize"}>
                      {`${getLeadTypeLabel(leadType)} leads`}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <VStack spacing={4} align={"stretch"}>
                      {!!officeStore && (
                        <Box>
                          <AutocallerPhoneForm
                            numberFieldName={
                              autoAttendantFieldNamesByLeadType[leadType]
                            }
                            control={control}
                          />
                        </Box>
                      )}
                      {!!sourceSelectorLabel && (
                        <FormControlMeta label={sourceSelectorLabel} />
                      )}
                      {filteredSourceTypesGroupedByLeadType[leadType].map(
                        (leadSourceType: TLeadSourceTypeData) => {
                          const value =
                            autocallerConfiguration.leadSourceTypes[
                              leadSourceType.id
                            ];
                          const leadSource = leadSourcesDictionary[
                            leadSourceType.leadSourceId
                          ]!;
                          const portal = leadSource.portalId
                            ? portalsDictionary[leadSource?.portalId]
                            : undefined;
                          return (
                            <Box key={leadSourceType.id} mt={4}>
                              <AutocallerSourceLeadTypeToggle
                                value={value}
                                leadSourceType={leadSourceType}
                                leadSource={leadSource}
                                portal={portal}
                                onConfigToggle={onConfigToggle}
                                isDisabled={isDisabled}
                              />
                            </Box>
                          );
                        }
                      )}
                      {!!officeStore && (
                        <VStack spacing={4} align={"flex-start"}>
                          <Divider />
                          <AutocallerConfiguratorDemoCall
                            autocallerStore={
                              accountStore.accountAutocallerStore
                            }
                            officeStore={officeStore}
                            leadType={leadType as LeadTypeEnum}
                            isDisabled={isDirty}
                          />
                        </VStack>
                      )}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </form>
        <ToolTour tourId={DashboardTourEnum.AUTOCALLER_TOUR} />
      </Box>
    );
  }
);
