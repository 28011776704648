import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { TFilterGroup, TPersistentFilter } from "types/persistent-filters.type";
import { AccountPersistentFiltersStore } from "store/UserAccounts/UserAccount/AccountPersistentFilters.store";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { NEW_DEFAULT_FILTER_GROUP } from "constants/persistent-filters";
import { persistentFilterValidationSchema } from "utils/validation-schemas/persistent-filter-group.validation";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  name: string;
  group: TFilterGroup;
};

interface IProps {
  closePrompt: () => void;
  persistentFiltersStore: AccountPersistentFiltersStore;
  persistentFilter?: TPersistentFilter;
}

export const PersistentFilterFormPrompt: FC<IProps> = observer(
  ({ closePrompt, persistentFiltersStore, persistentFilter }) => {
    const toast = useToast();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const initialValues: TFieldValues = useMemo(
      () => ({
        name: persistentFilter?.name || "",
        group: persistentFilter?.group || NEW_DEFAULT_FILTER_GROUP,
      }),
      [persistentFilter]
    );

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues as any,
      mode: "onSubmit",
      resolver: yupResolver(persistentFilterValidationSchema),
    });

    const handleUpdateFilter = useCallback(
      async (filterId: number, { name, group }: TFieldValues) => {
        await persistentFiltersStore.updatePersistentFilter(filterId, {
          name,
          group,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your filter has been updated.",
        });
      },
      [persistentFiltersStore, toast]
    );

    const handleCreateFilter = useCallback(
      async ({ name, group }: TFieldValues) => {
        await persistentFiltersStore.createPersistentFilter({
          name,
          group,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your filter has been created.",
        });
      },
      [persistentFiltersStore, toast]
    );

    const onSubmit = useCallback(
      async (formValues: TFieldValues) => {
        try {
          if (!!persistentFilter) {
            await handleUpdateFilter(persistentFilter.id, formValues);
          } else {
            await handleCreateFilter(formValues);
          }
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        closePrompt,
        handleCreateFilter,
        handleUpdateFilter,
        persistentFilter,
        toast,
      ]
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await persistentFiltersStore.loadPersistentFilterExpressionSchemas();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [persistentFiltersStore]);

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        contentStyle={{
          maxW: "2xl",
        }}
        header={`${!!persistentFilter ? "Edit" : "Create"} filter`}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Enter filter name",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"group"}
                  control={control}
                  label={"Rules"}
                  type={FormControlsTypeEnum.PERSISTENT_FILTER_CONFIGURATOR}
                  additionalProps={{
                    expressionSchemaOptions:
                      persistentFiltersStore.persistentFilterExpressionSchemaOptions,
                    expressionSchemaMap:
                      persistentFiltersStore.persistentFilterExpressionSchemaMap,
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!persistentFilter ? "Update" : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
