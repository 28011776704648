import React from "react";
import { Box, Link } from "@chakra-ui/react";

interface IEmailLink {
  email: string;
  label?: string;
  color?: string;
}

export const EmailLink: React.FC<IEmailLink> = ({ email, label, color }) => {
  return (
    <Link isExternal={true} href={`mailto:${email}`}>
      <Box
        display={"inline"}
        color={color || "gray.600"}
        textTransform={"lowercase"}
        _hover={{ textDecoration: "underline" }}
      >
        {label || email}
      </Box>
    </Link>
  );
};
