import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountRexApi } from "api/account-rex-api";
import {
  TRexLeadSource,
  TRexLeadSourceRelation,
  TRexLeadSourceRelationData,
  TRexSyncLeadSourceExtendedData,
} from "types/rex.type";
import { keyBy } from "lodash";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";

const accountRexApi = new AccountRexApi();

export class AccountIntegrationRexStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountRexLeadSources: IObservableArray<TRexLeadSource>;
  private readonly accountRexLeadSourceRelations: IObservableArray<
    TRexLeadSourceRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountRexLeadSources = observable.array<TRexLeadSource>();
    this.accountRexLeadSourceRelations = observable.array<
      TRexLeadSourceRelation
    >();
    this.syncStatusFilter = false;
  }

  get rexLeadSourceRelationsMapByLeadProLeadSourceId() {
    return keyBy(
      this.accountRexLeadSourceRelations,
      relation => relation.leadSourceId
    );
  }

  get rexLeadSourceRelationsMapByRexLeadSourceId() {
    return keyBy(
      this.accountRexLeadSourceRelations,
      relation => relation.rexId
    );
  }
  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredRexSyncLeadSourceData(): TRexSyncLeadSourceExtendedData[] {
    return this.rexSyncLeadSourceData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get rexSyncLeadSourceData(): TRexSyncLeadSourceExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproLeadSources =
      accountStore.accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    const rexLeadSourceRelationsMapByLeadProLeadSourceId = this
      .rexLeadSourceRelationsMapByLeadProLeadSourceId;

    return leadproLeadSources.map(leadSource => {
      let pairedRexLeadSourceId =
        rexLeadSourceRelationsMapByLeadProLeadSourceId[leadSource.id]?.rexId;
      let syncStatus = !!pairedRexLeadSourceId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        leadSourceId: leadSource.id,
        leadSourceName: leadSource.name,
        rexId: pairedRexLeadSourceId,
        syncStatus: syncStatus,
      };
    });
  }

  get rexLeadSourcesOptions() {
    return this.accountRexLeadSources
      .map(rexLeadSource => ({
        value: rexLeadSource.rexId,
        label: rexLeadSource.rexName,
        data: this.rexLeadSourceRelationsMapByRexLeadSourceId[
          rexLeadSource.rexId
        ],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountRexLeadSources(leadSources: TRexLeadSource[]) {
    this.accountRexLeadSources.replace(leadSources);
  }

  public setAccountRexLeadSourceRelations(relations: TRexLeadSourceRelation[]) {
    this.accountRexLeadSourceRelations.replace(relations);
  }

  public async fetchAccountRexLeadSources() {
    const data = await accountRexApi.fetchAccountRexLeadSources(this.accountId);
    this.setAccountRexLeadSources(data);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public async fetchAccountRexLeadSourceRelations() {
    const data = await accountRexApi.fetchAccountRexLeadSourceRelations(
      this.accountId
    );
    this.setAccountRexLeadSourceRelations(data);
  }

  public async fetchAccountRexLeadSourcesAndRelations() {
    await Promise.all([
      this.fetchAccountRexLeadSources(),
      this.fetchAccountRexLeadSourceRelations(),
    ]);
  }

  public async updateAccountRexSyncedLeadSources(
    syncedPairs: TRexLeadSourceRelationData[]
  ) {
    await accountRexApi.updateAccountRexSyncedLeadSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        rexId: pair.rexId,
      }))
    );
    await this.fetchAccountRexLeadSourcesAndRelations();
  }
}
