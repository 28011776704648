import React, { useCallback } from "react";
import { Card } from "components/stats/Card";
import { TPageData } from "types/page.type";
import {
  Image,
  Box,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  AspectRatio,
} from "@chakra-ui/react";
import { ExternalLink } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { DeletePagePrompt } from "routes/dashboard/components/prompts/DeletePagePrompt";
import { PageCardSSLBadge } from "./PageCardSSLBadge";
import { PageToolPagesStore } from "../../PageToolPages.local-store";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { DEFAULT_PORTAL_IMAGE } from "constants/image-paths";

const ASPECT_RATIO = 16 / 9;

interface IProps {
  page: TPageData;
  pageToolPagesStore: PageToolPagesStore;
  additionalMenuItemsRenderer?: (page: TPageData) => React.ReactNode;
}

export const PageCard: React.FC<IProps> = observer(
  ({ page, pageToolPagesStore, additionalMenuItemsRenderer }) => {
    const history = useHistory();
    const { setModal, unSetModal } = useActionPrompt();
    const handlePageSelect = useCallback(() => {
      history.push(`${history.location.pathname}/${page.id.toString()}`);
    }, [history, page]);

    const handleRemovePage = useCallback(
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        setModal(
          <DeletePagePrompt
            pageToolPagesStore={pageToolPagesStore}
            page={page}
            closePrompt={unSetModal}
          />
        );
      },
      [setModal, unSetModal, page, pageToolPagesStore]
    );

    return (
      <Card
        width={"356px"}
        _hover={{
          bg: "gray.50",
          shadow: "md",
        }}
        onClick={handlePageSelect}
        cursor={"pointer"}
        position={"relative"}
      >
        <Box position={"absolute"} top={"10px"} right={"10px"} zIndex={1}>
          <PageCardSSLBadge sslCertificateData={page.sslCertificateData} />
        </Box>
        <AspectRatio ratio={ASPECT_RATIO}>
          <Image
            src={page.photoUrl || DEFAULT_PORTAL_IMAGE}
            objectFit={"cover"}
          />
        </AspectRatio>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          padding={4}
          alignItems={"center"}
        >
          <Box flexGrow={1} textAlign={"left"}>
            <Box fontWeight={600}>{page.name}</Box>
            <Box onClick={(e: any) => e.stopPropagation()}>
              <ExternalLink label={page.host} href={`https://${page.host}`} />
            </Box>
          </Box>
          <Box onClick={(e: any) => e.stopPropagation()} zIndex={2}>
            <Menu isLazy={true} placement={"bottom-end"}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={
                  <FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />
                }
                size="xs"
                variant="ghost"
              />
              <MenuList>
                <>{additionalMenuItemsRenderer?.(page)}</>
                <MenuItem onClick={handlePageSelect}>Settings</MenuItem>
                <MenuItem onClick={handleRemovePage}>Remove</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </Card>
    );
  }
);
