import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useClipboard,
} from "@chakra-ui/react";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { TAccountDevelopersWebhookConfig } from "types/account-developers.type";

interface IProps {
  row: TAccountDevelopersWebhookConfig;
  handleEditWebhook: () => void;
  handleDeleteWebhook: () => void;
}

export const AccountDevelopersWebhooksTableActionCell: FC<IProps> = ({
  row,
  handleEditWebhook,
  handleDeleteWebhook,
}) => {
  const { onCopy } = useClipboard(row.webhookSigningSecret);
  return (
    <DefaultRowCell
      justifyContent={"flex-end"}
      onClick={event => event.stopPropagation()}
    >
      <Menu isLazy={true} placement={"bottom-end"}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />}
          size="xs"
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={onCopy}>Copy signing secret</MenuItem>
          <MenuItem onClick={handleEditWebhook}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteWebhook}>Remove</MenuItem>
        </MenuList>
      </Menu>
    </DefaultRowCell>
  );
};
