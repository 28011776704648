import { Box, Button, HStack } from "@chakra-ui/react";
import React, { FC } from "react";

interface IProps {
  hasCondition: boolean;
  handleAddConditionGroup: () => void;
  rootElement: React.ReactNode;
}
export const FormToolPageSettingsConfigConditionConfigurator: FC<IProps> = ({
  hasCondition,
  handleAddConditionGroup,
  rootElement,
}) => {
  return (
    <Box width={"100%"} py={5}>
      {!hasCondition && (
        <HStack spacing={4} width={"100%"}>
          <Box flexShrink={0} width={"100px"} color={"leadpro.500"}>
            Condition
          </Box>
          <HStack spacing={2} width={"100%"}>
            <Button variant={"link"} onClick={handleAddConditionGroup}>
              + Add condition
            </Button>
          </HStack>
        </HStack>
      )}
      {rootElement}
    </Box>
  );
};
