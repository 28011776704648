import { AppStore } from "../../../App.store";
import { AccountLeadAttributionsApi } from "api/account-lead-attributions";
import { IObservableArray, observable } from "mobx";
import { TLeadAttribution } from "types/lead-attribution.type";

const accountLeadAttributionApi = new AccountLeadAttributionsApi();

export class AccountLeadAttributionsStore {
  private readonly root: AppStore;
  private leadId: number;
  private accountId: number;
  public leadAttributions: IObservableArray<TLeadAttribution>;
  constructor(root: AppStore, accountId: number, leadId: number) {
    this.root = root;
    this.leadId = leadId;
    this.accountId = accountId;
    this.leadAttributions = observable.array();
  }
  get leadAttributionsArray() {
    return this.leadAttributions.slice();
  }
  updateLeadAttributions(data: TLeadAttribution[]) {
    this.leadAttributions.replace(data);
  }
  async fetchLeadAttributions() {
    const attributions = await accountLeadAttributionApi.fetchAccountLeadAttributions(
      this.accountId,
      this.leadId
    );
    this.updateLeadAttributions(attributions);
  }
}
