import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVial } from "@fortawesome/pro-solid-svg-icons";
import { ApiMessageStack, FormControlV2, StandardIconButton } from "components";
import React, { FC, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { AccountEmailTemplatesStore } from "store/UserAccounts/UserAccount/AccountEmailTemplates/AccountEmailTemplates.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  email: string;
};

const initialValues: TFieldValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
});

interface IProps {
  emailTemplatesStore: AccountEmailTemplatesStore;
}

export const EmailTemplateSendTestPopover: FC<IProps> = observer(
  ({ emailTemplatesStore }) => {
    const inputRef = useRef(null);
    const toast = useToast();
    const wipEmailTemplateStore = emailTemplatesStore.wipEmailTemplateStore;

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      (onClose: () => void) => async ({ email }: TFieldValues) => {
        if (!!wipEmailTemplateStore.id) {
          try {
            await emailTemplatesStore.sendTestEmail(
              wipEmailTemplateStore.id,
              email
            );
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description: (
                <ApiMessageStack messageStack={"Test email sent."} />
              ),
            });
            onClose();
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          }
        }
      },
      [wipEmailTemplateStore.id, emailTemplatesStore, toast]
    );

    return (
      <Popover closeOnBlur={false} initialFocusRef={inputRef} isLazy={true}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <StandardIconButton
                isDisabled={!wipEmailTemplateStore.isTemplateCreatedPreviously}
                aria-label={"test-email"}
                icon={<FontAwesomeIcon icon={faVial} />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader fontSize={"md"} fontWeight={500}>
                Send test email
              </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <form onSubmit={handleSubmit(onSubmit(onClose))}>
                  <FormControlV2<TFieldValues>
                    name={"email"}
                    control={control}
                    label={""}
                    type={FormControlsTypeEnum.TEXT}
                    additionalProps={{
                      placeholder: "example@email.com",
                      size: "sm",
                    }}
                  />
                  <Box width={"100%"} pt={2} textAlign={"right"}>
                    <Button
                      type={"submit"}
                      colorScheme={"blue"}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Send
                    </Button>
                  </Box>
                </form>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    );
  }
);
