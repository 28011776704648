import React from "react";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { capitalize } from "lodash";
import { TypeFormWidget } from "components";
import { PreQualificationQuestionnaireCopyLinkCell } from "components/table-v2/row-cells/PreQualificationQuestionnaireCopyLinkCell";
import { TTypeformForm } from "types/account-typeform-forms.type";
import { Button } from "@chakra-ui/react";

export const preQualificationQuestionnaireTableColumnDef = (): ITableV2Column<
  TTypeformForm
>[] => {
  return [
    {
      accessor: "leadType",
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>{capitalize(row.leadType)}</DefaultRowCell>
      ),
    },
    {
      accessor: "typeformId",
      width: "40px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell justifyContent={"flex-end"}>
          <TypeFormWidget typeFormId={row.typeformId}>
            <Button
              _hover={{
                textDecoration: "none",
                color: "teal.500",
                fonWeight: "800",
              }}
              variant={"link"}
              color={"black"}
              fontWeight={"normal"}
            >
              View
            </Button>
          </TypeFormWidget>
        </DefaultRowCell>
      ),
    },
    {
      accessor: "typeformLink",
      width: "40px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <PreQualificationQuestionnaireCopyLinkCell row={row} />
      ),
    },
  ];
};
