import {
  TAccountOfficeData,
  TOfficeMembership,
} from "types/account-office.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TOfficeAutocallerConfiguration } from "types/autocaller.type";
import {
  TOfficeEmailNotificationsConfiguration,
  TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration,
} from "types/email-notifications-configuration.type";
import { TSelectAndInputValue } from "types/select-input.type";
import { AccountOfficesApi } from "api/account-offices.api";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { combinePhoneNumberAndAutoAttendant } from "utils/autocaller.utils";
import { TOfficeMortgageReferralsConfiguration } from "types/mortgage-referrals-configuration.type";
import { TOfficeUserData } from "types/office-user.type";

const officesApi = new AccountOfficesApi();

export class AccountOfficeStore {
  private officeData: TAccountOfficeData;
  private officeAutocallerConfigurationData: TOfficeAutocallerConfiguration | null;
  private officeEmailNotificationsConfigurationData: TOfficeEmailNotificationsConfiguration | null;
  private officeMortgageReferralsConfigurationData: TOfficeMortgageReferralsConfiguration | null;
  private officeIncompleteValuationLeadsEmailNotificationsConfigurationData: TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration | null;
  private readonly officeUsers: IObservableArray<TOfficeUserData>;
  private readonly officeMemberships: IObservableArray<TOfficeMembership>;

  constructor(data: TAccountOfficeData) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.officeData = data;
    this.officeAutocallerConfigurationData = null;
    this.officeEmailNotificationsConfigurationData = null;
    this.officeMortgageReferralsConfigurationData = null;
    this.officeIncompleteValuationLeadsEmailNotificationsConfigurationData = null;
    this.officeUsers = observable.array<TOfficeUserData>();
    this.officeMemberships = observable.array<TOfficeMembership>();
  }

  get officeAutocallerConfiguration() {
    return this.officeAutocallerConfigurationData;
  }

  get officeEmailNotificationsConfiguration() {
    return this.officeEmailNotificationsConfigurationData;
  }

  get officeMortgageReferralsConfiguration() {
    return this.officeMortgageReferralsConfigurationData;
  }

  get officeIncompleteValuationLeadsEmailNotificationsConfiguration() {
    return this
      .officeIncompleteValuationLeadsEmailNotificationsConfigurationData;
  }

  get office() {
    return this.officeData;
  }

  get officeMembershipsArray() {
    return this.officeMemberships.slice();
  }

  get officeUsersArray() {
    return this.officeUsers.slice();
  }

  get areAllContactEmailsSame() {
    const officeData = this.officeData;
    return (
      officeData.salesEmails === officeData.lettingsEmails &&
      officeData.salesEmails === officeData.vendorEmails &&
      officeData.salesEmails === officeData.landlordEmails
    );
  }

  get areAllContactPhonesSame() {
    const officeData = this.officeData;
    return (
      officeData.salesPhoneNumber === officeData.lettingsPhoneNumber &&
      officeData.salesPhoneNumber === officeData.vendorPhoneNumber &&
      officeData.salesPhoneNumber === officeData.landlordPhoneNumber &&
      officeData.salesPhoneNumber === officeData.mortgagePhoneNumber
    );
  }

  get areAllContactDataSame() {
    return this.areAllContactEmailsSame && this.areAllContactPhonesSame;
  }

  get phoneNumberWithAutoAttendantByLeadType(): {
    [key in LeadTypeEnum]?: string;
  } {
    const {
      salesPhoneNumber,
      lettingsPhoneNumber,
      vendorPhoneNumber,
      landlordPhoneNumber,
      mortgagePhoneNumber,
    } = this.officeData;

    return {
      [LeadTypeEnum.Sale]: combinePhoneNumberAndAutoAttendant(
        salesPhoneNumber,
        this.officeAutocallerConfiguration?.salesAutoAttendant
      ),
      [LeadTypeEnum.Let]: combinePhoneNumberAndAutoAttendant(
        lettingsPhoneNumber,
        this.officeAutocallerConfiguration?.lettingsAutoAttendant
      ),
      [LeadTypeEnum.Vendor]: combinePhoneNumberAndAutoAttendant(
        vendorPhoneNumber,
        this.officeAutocallerConfiguration?.vendorAutoAttendant
      ),
      [LeadTypeEnum.Landlord]: combinePhoneNumberAndAutoAttendant(
        landlordPhoneNumber,
        this.officeAutocallerConfiguration?.landlordAutoAttendant
      ),
      [LeadTypeEnum.MortgageRequest]: combinePhoneNumberAndAutoAttendant(
        mortgagePhoneNumber,
        this.officeAutocallerConfiguration?.mortgagesAutoAttendant
      ),
    };
  }

  get officePostcodesArray(): string[] {
    return this.officeData.postcodes.split(",");
  }

  private upsertOfficeData(updates: TAccountOfficeData) {
    this.officeData = {
      ...this.officeData,
      ...updates,
    };
  }

  private setOfficeUsers(officeUsers: TOfficeUserData[]) {
    this.officeUsers.replace(officeUsers);
  }

  private setOfficeMemberships(officeMemberships: TOfficeMembership[]) {
    this.officeMemberships.replace(officeMemberships);
  }

  private deleteOfficeMembership(officeMembershipId: number) {
    const index = this.officeMemberships.findIndex(
      membership => membership.id === officeMembershipId
    );
    if (index > -1) {
      this.officeMemberships.splice(index, 1);
    }
  }

  private upsertOfficeMembership(officeMembershipData: TOfficeMembership) {
    const index = this.officeMemberships.findIndex(
      page => page.id === officeMembershipData.id
    );
    if (index > -1) {
      this.officeMemberships[index] = officeMembershipData;
    } else {
      this.officeMemberships.push(officeMembershipData);
    }
  }

  private setOfficeAutocallerConfigurationData(
    configuration: TOfficeAutocallerConfiguration
  ) {
    this.officeAutocallerConfigurationData = configuration;
  }

  private setOfficeEmailNotificationsConfigurationData(
    configuration: TOfficeEmailNotificationsConfiguration
  ) {
    this.officeEmailNotificationsConfigurationData = configuration;
  }

  private setOfficeMortgageReferralsConfigurationData(
    configuration: TOfficeMortgageReferralsConfiguration
  ) {
    this.officeMortgageReferralsConfigurationData = configuration;
  }

  private setOfficeIncompleteValuationLeadsEmailNotificationsConfigurationData(
    configuration: TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration
  ) {
    this.officeIncompleteValuationLeadsEmailNotificationsConfigurationData = configuration;
  }

  private setOfficeLeadCount(count: number) {
    this.officeData.leadsCount = count;
  }

  public upsertLogo(logoUrl: string | null) {
    this.officeData.logoUrl = logoUrl;
  }

  public async fetchDetails() {
    const office = await officesApi.fetchAccountOffice(
      this.officeData.accountId,
      this.officeData.id
    );
    this.upsertOfficeData(office);
  }

  public async update(officeData: Partial<TAccountOfficeData>) {
    const office = await officesApi.updateAccountOffice(
      this.officeData.accountId,
      this.officeData.id,
      officeData
    );
    this.upsertOfficeData(office);
  }

  public async fetchOfficeAutocallerConfiguration() {
    const officeAutocallerConfiguration = await officesApi.fetchAccountOfficeAutocallerConfiguration(
      this.officeData.accountId,
      this.officeData.id
    );

    this.setOfficeAutocallerConfigurationData(officeAutocallerConfiguration);
  }

  public async updateOfficeAutocallerConfiguration(
    configuration: Partial<TOfficeAutocallerConfiguration>
  ) {
    const officeAutocallerConfiguration = await officesApi.updateAccountOfficeAutocallerConfiguration(
      this.officeData.accountId,
      this.officeData.id,
      configuration
    );

    this.setOfficeAutocallerConfigurationData(officeAutocallerConfiguration);
  }

  public async fetchOfficeEmailNotificationsConfiguration() {
    const officeEmailNotificationsConfiguration = await officesApi.fetchAccountOfficeEmailNotificationsConfiguration(
      this.officeData.accountId,
      this.officeData.id
    );
    this.setOfficeEmailNotificationsConfigurationData(
      officeEmailNotificationsConfiguration
    );
  }

  public async updateOfficeEmailNotificationsConfiguration(
    configuration: Partial<TOfficeEmailNotificationsConfiguration>
  ) {
    const officeEmailNotificationsConfiguration = await officesApi.updateAccountOfficeEmailNotificationsConfiguration(
      this.officeData.accountId,
      this.officeData.id,
      configuration
    );

    this.setOfficeEmailNotificationsConfigurationData(
      officeEmailNotificationsConfiguration
    );
  }

  public async fetchIncompleteValuationLeadsEmailNotificationsConfiguration() {
    const configuration = await officesApi.fetchAccountOfficeIncompleteValuationLeadsEmailNotificationsConfiguration(
      this.officeData.accountId,
      this.officeData.id
    );
    this.setOfficeIncompleteValuationLeadsEmailNotificationsConfigurationData(
      configuration
    );
  }

  public async updateIncompleteValuationLeadsEmailNotificationsConfiguration(
    configuration: Partial<
      TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration
    >
  ) {
    const officeIncompleteValuationLeadsEmailNotificationsConfiguration = await officesApi.updateAccountOfficeIncompleteValuationLeadsEmailNotificationsConfiguration(
      this.officeData.accountId,
      this.officeData.id,
      configuration
    );

    this.setOfficeIncompleteValuationLeadsEmailNotificationsConfigurationData(
      officeIncompleteValuationLeadsEmailNotificationsConfiguration
    );
  }

  public async fetchOfficeUsers() {
    const officeUsers = await officesApi.fetchAccountOfficeUsers(
      this.officeData.accountId,
      this.officeData.id
    );

    this.setOfficeUsers(officeUsers);
  }

  public async addUsersToOffice(userIds: number[]) {
    await officesApi.addUsersToAccountOffice(
      this.officeData.accountId,
      this.officeData.id,
      userIds
    );
    await this.fetchOfficeUsers();
  }

  public async fetchOfficeMemberships() {
    const officeMemberships = await officesApi.fetchAccountOfficeMemberships(
      this.officeData.accountId,
      this.officeData.id
    );

    this.setOfficeMemberships(officeMemberships);
  }

  public async createOfficeMembership(value: TSelectAndInputValue<number>) {
    const selectValueAsNumber = value.selectValue as number;
    const inputValueAsNumber = value.inputValue
      ? Number.parseInt(value.inputValue, 10)
      : undefined;
    const officeMembership = await officesApi.createOfficeMembership(
      this.officeData.accountId,
      this.officeData.id,
      selectValueAsNumber,
      inputValueAsNumber
    );

    await this.upsertOfficeMembership(officeMembership);
  }

  public async removeOfficeMembership(officeMembershipId: number) {
    await officesApi.removeOfficeMembership(
      this.officeData.accountId,
      this.officeData.id,
      officeMembershipId
    );

    this.deleteOfficeMembership(officeMembershipId);
  }

  public async updateOfficeMembership(
    officeMembershipId: number,
    value: TSelectAndInputValue<number>
  ) {
    const inputValueAsNumber = value.inputValue
      ? Number.parseInt(value.inputValue, 10)
      : null;
    await officesApi.updateOfficeMembership(
      this.officeData.accountId,
      this.officeData.id,
      officeMembershipId,
      inputValueAsNumber
    );

    await this.fetchOfficeMemberships();
  }

  public async fetchOfficeMortgageReferralsConfiguration() {
    const officeMortgageReferralsConfiguration = await officesApi.fetchAccountOfficeMortgageReferralsConfiguration(
      this.officeData.accountId,
      this.officeData.id
    );
    this.setOfficeMortgageReferralsConfigurationData(
      officeMortgageReferralsConfiguration
    );
  }

  public async updateOfficeMortgageReferralsConfiguration(
    configuration: Partial<TOfficeMortgageReferralsConfiguration>
  ) {
    const officeMortgageReferralsConfiguration = await officesApi.updateAccountOfficeMortgageReferralsConfiguration(
      this.officeData.accountId,
      this.officeData.id,
      configuration
    );
    this.setOfficeMortgageReferralsConfigurationData(
      officeMortgageReferralsConfiguration
    );
  }

  public async fetchOfficeLeadsCount() {
    const response = await officesApi.fetchOfficeLeadsCount(
      this.officeData.accountId,
      this.officeData.id
    );
    this.setOfficeLeadCount(response.count);
  }
}
