import { Box, Button } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TableV2 } from "components";
import { TEmailTemplate } from "types/email-template.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { EmailTemplateEditorPrompt } from "routes/dashboard/components/prompts/EmailTemplatePrompt/EmailTemplateEditorPrompt";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { DeleteTemplatePrompt } from "routes/dashboard/components/prompts/DeleteTemplatePrompt";
import { aggregateEmailTemplateWorkflows } from "utils/email-template.utils";
import { emailTemplateTableColumnDef } from "./emailTemplateTableColumnDef";

interface IProps {
  accountStore: UserAccountStore;
}

export const EmailTemplates: FC<IProps> = observer(({ accountStore }) => {
  const { setModal, unSetModal } = useActionPrompt();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const emailTemplatesStore = accountStore.accountEmailTemplatesStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await emailTemplatesStore.loadEmailTemplates();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [emailTemplatesStore]);

  const handleCreateOrEditEmailTemplate = useCallback(
    (emailTemplate?: TEmailTemplate) => () => {
      setModal(
        <EmailTemplateEditorPrompt
          emailTemplateId={emailTemplate?.id}
          accountStore={accountStore}
          closePrompt={unSetModal}
        />,
        { size: "full" }
      );
    },
    [setModal, unSetModal, accountStore]
  );

  const handleRemoveEmailTemplate = useCallback(
    (emailTemplate: TEmailTemplate) => () => {
      const aggregatedWorkflows = aggregateEmailTemplateWorkflows(
        emailTemplate.workflows
      );
      setModal(
        <DeleteTemplatePrompt
          workflows={aggregatedWorkflows}
          onDelete={async () =>
            emailTemplatesStore.removeEmailTemplate(emailTemplate.id)
          }
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, emailTemplatesStore]
  );

  const handleOnRowClick = useCallback(
    (emailTemplate: TEmailTemplate) => {
      handleCreateOrEditEmailTemplate(emailTemplate)();
    },
    [handleCreateOrEditEmailTemplate]
  );

  const columnsDef = useMemo(() => {
    return emailTemplateTableColumnDef(
      handleCreateOrEditEmailTemplate,
      handleRemoveEmailTemplate
    );
  }, [handleCreateOrEditEmailTemplate, handleRemoveEmailTemplate]);

  return (
    <Box>
      <TableV2<TEmailTemplate>
        dataSource={emailTemplatesStore.emailTemplatesArray}
        columns={columnsDef}
        pageSize={20}
        loadingStatus={loadingStatus}
        globalFilterInputPlaceholder={"Type email template name"}
        striped={true}
        onRowClick={handleOnRowClick}
        additionalActions={
          <Box flexGrow={1}>
            <Button
              variant={"link"}
              colorScheme={"blue"}
              onClick={handleCreateOrEditEmailTemplate()}
            >
              + Create email template
            </Button>
          </Box>
        }
      />
    </Box>
  );
});
