import { Center } from "@chakra-ui/react";
import { FC } from "react";

interface IProps {
  count: number;
}

export const PropertyLeadsCountCell: FC<IProps> = ({ count }) => {
  return <Center height={"100%"}>{count}</Center>;
};
