import { ApiMessageStack, SingleSelectInput } from "components";
import { Box, useToast, VStack } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { filterOptionsByLabel } from "utils/select.utils";
import { LeadInfoRowValue } from "../../LeadInfoRow";
import { LeadControlRow } from "./LeadControlRow";
import { LeadControlRowLabel } from "./LeadControlLabel";
import { LeadOfficeSelectOptionsRenderer } from "components/select-input/custom-option-renderers/LeadOfficeSelectOptionsRenderer";
import { LeadOfficeSelectOptionCondensed } from "components/select-input/custom-options/LeadOfficeSelectOptions/LeadOfficeSelectOptionCondensed";

interface IProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

export const LeadOfficeSelect: FC<IProps> = observer(
  ({ accountStore, leadStore }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();
    const {
      accountOfficesStore: { accountOfficesOptionsArray },
    } = accountStore;

    const handleLeadOfficeChange = useCallback(
      async (officeId: number | null) => {
        try {
          await leadStore.update({
            officeId,
          });
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [leadStore, toast]
    );

    const confirmLeadOfficeChange = useCallback(
      async (officeId: TSelectValue<number>) => {
        setModal(
          <ConfirmActionPrompt
            text={
              <VStack spacing={4} align={"stretch"}>
                <Box>
                  {`Are you sure you want to ${
                    !officeId ? "remove" : "change"
                  } the lead office?`}
                </Box>
                <Box color={"red.500"}>
                  {`${
                    !officeId
                      ? "Once the lead has been removed from the office, only account administrators can re-assign it."
                      : "If you assign it to an office you don't have access to, you will not be able to re-assign it again."
                  }`}
                </Box>
              </VStack>
            }
            onConfirm={async () => await handleLeadOfficeChange(officeId)}
            closePrompt={unSetModal}
          />
        );
      },
      [handleLeadOfficeChange, setModal, unSetModal]
    );

    const handleFilterOfficeOptions = useCallback(
      (options: TSelectOption<number>[], searchTerm: string) => {
        return filterOptionsByLabel<number>(options, searchTerm);
      },
      []
    );

    return (
      <LeadControlRow secondary>
        <LeadControlRowLabel>Office</LeadControlRowLabel>
        <LeadInfoRowValue>
          <SingleSelectInput<number>
            noBorder
            secondary
            value={leadStore.lead.officeId}
            options={accountOfficesOptionsArray}
            onChange={confirmLeadOfficeChange}
            filterFn={handleFilterOfficeOptions}
            clearable={true}
            placeholder={"Select office"}
            optionComponent={optionProps => (
              <LeadOfficeSelectOptionCondensed optionProps={optionProps} />
            )}
            optionsRenderer={optionProps => (
              <LeadOfficeSelectOptionsRenderer optionsProps={optionProps} />
            )}
          />
        </LeadInfoRowValue>
      </LeadControlRow>
    );
  }
);
