import { observer } from "mobx-react";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import { FC, useCallback } from "react";
import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttributionFilters.store";
import { SelectInputSizes } from "components/select-input/select-input-size";
import { MultiSelectInput } from "components";
import { TSelectValue } from "types/select-input.type";
import { LeadAttributionItemSelectInputOption } from "./LeadAttributionItemSelectInputOption";

interface IProps {
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
  onClose: () => void;
}

export const LeadAttributionReportSegmentDetailsActionsItemFilterContent: FC<IProps> = observer(
  ({ leadAttributionType, accountAnalyticsLeadAttributionStore, onClose }) => {
    const {
      accountAnalyticsLeadAttributionFiltersStore: {
        setAllItemsSortedByEstValue,
        setItems,
        setTopItemsSortedByEstValue,
        filtersDataByType,
        itemsAsOptionsByTypeSortedByLabel,
      },
    } = accountAnalyticsLeadAttributionStore;

    const showAll = useCallback(() => {
      setAllItemsSortedByEstValue(leadAttributionType);
      onClose();
    }, [onClose, setAllItemsSortedByEstValue, leadAttributionType]);

    const clearAll = useCallback(() => {
      setItems(leadAttributionType, []);
      onClose();
    }, [onClose, setItems, leadAttributionType]);

    const handleSetTopItems = useCallback(() => {
      setTopItemsSortedByEstValue(leadAttributionType);
      onClose();
    }, [onClose, setTopItemsSortedByEstValue, leadAttributionType]);

    const onMultiSelectValueChange = useCallback(
      (values: TSelectValue<string | number>[]) => {
        setItems(leadAttributionType, values as []);
      },
      [setItems, leadAttributionType]
    );

    return (
      <VStack alignItems={"flex-start"} spacing={5} pb={2}>
        <Box color={"leadpro.500"} fontSize={"sm"}>
          Add or remove {leadAttributionType}s from your Lead attribution{" "}
          {leadAttributionType} reporting.
        </Box>
        <Box width={"100%"} height={"100px"}>
          <MultiSelectInput<number | string>
            height={"100%"}
            size={SelectInputSizes.SMALL}
            value={filtersDataByType[leadAttributionType]}
            onChange={onMultiSelectValueChange}
            options={itemsAsOptionsByTypeSortedByLabel[leadAttributionType]}
            placeholder={`Add ${leadAttributionType}s`}
            optionComponent={
              [
                LeadAttributionTypesEnum.CAMPAIGN,
                LeadAttributionTypesEnum.JOURNEY,
              ].includes(leadAttributionType)
                ? optionProps => {
                    return (
                      <LeadAttributionItemSelectInputOption
                        optionProps={optionProps}
                      />
                    );
                  }
                : undefined
            }
          />
        </Box>
        <HStack spacing={5}>
          <Button variant={"link"} colorScheme={"blue"} onClick={showAll}>
            Add all {leadAttributionType}s
          </Button>
          <Button variant={"link"} colorScheme={"blue"} onClick={clearAll}>
            Clear all {leadAttributionType}s
          </Button>
          <Button
            variant={"link"}
            colorScheme={"blue"}
            onClick={handleSetTopItems}
          >
            Restore top {DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW}{" "}
            {leadAttributionType}s
          </Button>
        </HStack>
      </VStack>
    );
  }
);
