import { makeAutoObservable } from "mobx";

export class AccountPropertiesFiltersStore {
  private readonly accountId: number;
  private searchQueryData: string;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.searchQueryData = "";
  }

  get searchQuery() {
    return this.searchQueryData;
  }

  public setSearchQuery(searchQuery: string) {
    this.searchQueryData = searchQuery;
  }
}
