import { Box, Checkbox, InputGroup, Switch } from "@chakra-ui/react";
import React, { ChangeEvent, useMemo } from "react";
import { FormControlMeta } from "./FormControlMeta";
import { FormControlError } from "./FormControlError";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { SelectAndInput } from "components/select-and-input/SelectAndInput";
import { TimePicker, TimeRangePicker } from "../../time-picker";
import { SingleDatePickerInput } from "../../date-picker";
import {
  ColorInput,
  RadioInput,
  HostInput,
  Input,
  MergeTagInput,
  MultiSelectInput,
  PersistentFilterGroup,
  PictureUploadInput,
  SingleSelectInput,
  TagInput,
  FormControlLabel,
} from "../../index";
import { Textarea } from "../../input/Textarea";
import { ControllerRenderProps } from "react-hook-form";
import { CertificateUploadInput } from "../../file-upload-input/CertificateUploadInput";
import { MarkdownEditor } from "../../markdown-editor/MarkdownEditor";

interface Props<T extends FieldValues> {
  type: FormControlsTypeEnum;
  name: FieldPath<T>;
  control: Control<T>;
  label?: string | React.ReactNode;
  description?: React.ReactNode;
  isOptional?: boolean;
  showError?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  additionalProps?: any;
  transformFrom?: (rawValue: any) => any;
  transformTo?: (value: any) => any;
}

interface IFormControlInputRenderer<T> {
  field: ControllerRenderProps<T>;
}

const OVERRIDE_META_ELEMENT_FOR: FormControlsTypeEnum[] = [
  FormControlsTypeEnum.CHECKBOX,
];

export function FormControlV2<T extends FieldValues>({
  type,
  name,
  control,
  label,
  description,
  isOptional,
  isDisabled,
  autoFocus,
  showError = true,
  transformFrom,
  transformTo,
  additionalProps = {},
}: Props<T>) {
  const input = useMemo(() => {
    const {
      size,
      options,
      optionComponent,
      optionsRenderer,
      clearable,
      asyncFilterFn,
      filterPlaceholder,
      inputLeftAddon,
      inputRightAddon,
      src,
      onCustomDomainToggle,
      expressionSchemaOptions,
      expressionSchemaMap,
      filterFn,
      mergeTagOptions,
      singleLine,
      placeholder,
      accept,
      textAreaRows,
    } = additionalProps;
    switch (type) {
      case FormControlsTypeEnum.MARKDOWN:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <Box height={"350px"}>
            <MarkdownEditor value={value} onChange={onChange} />
          </Box>
        );
      case FormControlsTypeEnum.CERTIFICATE_UPLOAD_INPUT:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <CertificateUploadInput
            id={name}
            name={name}
            value={value}
            isDisabled={isDisabled}
            onBlur={onBlur}
            onChange={onChange}
            accept={accept}
          />
        );
      case FormControlsTypeEnum.SWITCH:
        return ({
          field: { value, onChange, ref },
        }: IFormControlInputRenderer<T>) => (
          <Switch
            ref={ref}
            name={name}
            isChecked={value}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        );

      case FormControlsTypeEnum.CHECKBOX:
        return ({
          field: { value, onChange, ref },
        }: IFormControlInputRenderer<T>) => (
          <Checkbox
            id={name}
            ref={ref}
            name={name}
            isChecked={value}
            onChange={onChange}
            isDisabled={isDisabled}
          >
            <FormControlLabel htmlFor={name}>{label}</FormControlLabel>
          </Checkbox>
        );
      case FormControlsTypeEnum.SELECT_AND_INPUT:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <SelectAndInput
            name={name}
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={isDisabled}
            clearable={clearable}
          />
        );
      case FormControlsTypeEnum.TIME_RANGE_PICKER:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <TimeRangePicker
            name={name}
            timeRange={value}
            onChange={onChange}
            disabled={isDisabled}
          />
        );
      case FormControlsTypeEnum.TIME_PICKER:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <TimePicker
            name={name}
            time={value}
            onChange={onChange}
            disabled={isDisabled}
          />
        );
      case FormControlsTypeEnum.DATE_PICKER:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <SingleDatePickerInput
            date={value}
            onChange={onChange}
            clearable={clearable}
          />
        );
      case FormControlsTypeEnum.TAG_INPUT:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <TagInput
            value={transformFrom ? transformFrom(value) : value}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={
              transformTo ? value => onChange(transformTo(value)) : onChange
            }
            isDisabled={isDisabled}
            clearable={clearable}
          />
        );
      case FormControlsTypeEnum.SINGLE_SELECT:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <SingleSelectInput
            value={value}
            placeholder={placeholder}
            filterPlaceholder={filterPlaceholder}
            options={options}
            onChange={onChange}
            optionComponent={optionComponent}
            optionsRenderer={optionsRenderer}
            disabled={isDisabled}
            clearable={clearable}
            filterFn={filterFn}
            asyncFilterFn={asyncFilterFn}
          />
        );
      case FormControlsTypeEnum.MULTI_SELECT:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <MultiSelectInput
            value={value}
            placeholder={placeholder}
            filterPlaceholder={filterPlaceholder}
            options={options}
            onChange={onChange}
            optionComponent={optionComponent}
            optionsRenderer={optionsRenderer}
            disabled={isDisabled}
            clearable={clearable}
            filterFn={filterFn}
            asyncFilterFn={asyncFilterFn}
          />
        );
      case FormControlsTypeEnum.RADIO:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <>
            {options.map(
              (option: {
                value: string | number;
                label: string;
                description: string;
              }) => (
                <RadioInput
                  key={option.value}
                  name={name}
                  description={option.description}
                  label={option.label}
                  onChange={() => onChange(option.value)}
                  isChecked={value === option.value}
                  isDisabled={isDisabled}
                />
              )
            )}
          </>
        );
      case FormControlsTypeEnum.COLOR_INPUT:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <ColorInput
            id={name}
            name={name}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        );
      case FormControlsTypeEnum.PICTURE_UPLOAD_INPUT:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <PictureUploadInput
            id={name}
            name={name}
            value={value}
            src={src}
            isDisabled={isDisabled}
            onBlur={onBlur}
            onChange={onChange}
          />
        );
      case FormControlsTypeEnum.HOST_INPUT:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <HostInput
            id={name}
            name={name}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            autoFocus={autoFocus}
            onCustomDomainToggle={onCustomDomainToggle}
          />
        );
      case FormControlsTypeEnum.TEXTAREA:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <Textarea
            rows={textAreaRows}
            name={name}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        );
      case FormControlsTypeEnum.PERSISTENT_FILTER_CONFIGURATOR:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <PersistentFilterGroup
            configuration={value}
            expressionSchemaOptions={expressionSchemaOptions}
            expressionSchemaMap={expressionSchemaMap}
            onChange={(index, value) => onChange(value)}
            childIndex={0}
            isRoot={true}
          />
        );
      case FormControlsTypeEnum.MERGE_TAG_INPUT:
        return ({
          field: { value, onChange },
        }: IFormControlInputRenderer<T>) => (
          <MergeTagInput
            value={value}
            onChange={onChange}
            mergeTagOptions={mergeTagOptions}
            singleLine={singleLine}
            isDisabled={isDisabled}
          />
        );
      case FormControlsTypeEnum.NUMBER:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <InputGroup>
            {inputLeftAddon}
            <Input
              name={name}
              type={type}
              size={size}
              value={value}
              autoFocus={autoFocus}
              isDisabled={isDisabled}
              placeholder={placeholder}
              borderRightRadius={!!inputRightAddon ? 0 : undefined}
              onBlur={onBlur}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === "") {
                  onChange(null);
                  return;
                }

                const parseResult = parseInt(event.target.value);
                if (!isNaN(parseResult)) {
                  onChange(parseResult);
                }
              }}
            />
            {inputRightAddon}
          </InputGroup>
        );
      case FormControlsTypeEnum.PASSWORD:
      case FormControlsTypeEnum.TEXT:
      case FormControlsTypeEnum.TEL:
        return ({
          field: { value, onChange, onBlur },
        }: IFormControlInputRenderer<T>) => (
          <InputGroup>
            {inputLeftAddon}
            <Input
              name={name}
              type={type}
              size={size}
              value={value}
              autoFocus={autoFocus}
              isDisabled={isDisabled}
              placeholder={placeholder}
              borderRightRadius={!!inputRightAddon ? 0 : undefined}
              onBlur={onBlur}
              onChange={onChange}
            />
            {inputRightAddon}
          </InputGroup>
        );
      default:
        return null;
    }
  }, [
    additionalProps,
    autoFocus,
    isDisabled,
    name,
    type,
    label,
    transformTo,
    transformFrom,
  ]);

  return (
    <Box>
      {!OVERRIDE_META_ELEMENT_FOR.includes(type) && (
        <Box flexShrink={0}>
          <FormControlMeta
            label={label}
            isOptional={isOptional}
            description={description}
          />
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        render={data => (
          <Box>
            {input?.(data)}
            {!!data.fieldState.error && showError && (
              <FormControlError error={data.fieldState.error} />
            )}
          </Box>
        )}
      />
    </Box>
  );
}
