import { toSpace } from "./to-snake-case.utils";
import { LeadTypeEnum } from "../enums/lead-type.enum";
import _ from "lodash";

// TODO: ASK SAM IF IT'S OKAY, OTHERWISE WE HAVE DISCREPANCY, E.G. SALES (IN NEW DESIGN)/SALES VIEWING (LEADS TABLE)
const LeadTypeCustomLabels: { [key: string]: string } = {
  // [LeadTypeEnum.Let]: "Letting",
  // [LeadTypeEnum.Letting]: "Letting",
  // [LeadTypeEnum.Sale]: "Sales Viewing",
};

export function getLeadTypeLabel(type: string, capitalize?: boolean) {
  const label = LeadTypeCustomLabels[type] || toSpace(type);
  return capitalize ? _.capitalize(label) : label;
}

type TLeadBookingRequestLabelTypes =
  | LeadTypeEnum.Sale
  | LeadTypeEnum.Let
  | LeadTypeEnum.Landlord
  | LeadTypeEnum.Vendor;

const leadTypeBookingRequestLabels: Record<
  TLeadBookingRequestLabelTypes,
  string
> = {
  [LeadTypeEnum.Sale]: "Viewing booking request",
  [LeadTypeEnum.Let]: "Viewing booking request",
  [LeadTypeEnum.Landlord]: "Valuation booking request",
  [LeadTypeEnum.Vendor]: "Valuation booking request",
};

const DEFAULT_BOOKING_REQUEST_TITLE = "Valuation Booking Request";

export function getLeadBookingRequestLabel(type: LeadTypeEnum): string {
  return (
    leadTypeBookingRequestLabels[type as TLeadBookingRequestLabelTypes] ||
    DEFAULT_BOOKING_REQUEST_TITLE
  );
}
