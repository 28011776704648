import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

interface IPaginatorButtonButtonProps {
  children: any;
  isDisabled?: boolean;
}

export const PaginatorButton: FC<IPaginatorButtonButtonProps> = ({
  children,
  isDisabled,
}) => {
  return (
    <Box
      minWidth="95px"
      textAlign="center"
      lineHeight="text"
      borderRadius={"50px"}
      border={"1px solid"}
      borderColor={isDisabled ? "leadpro.400" : "leadpro.800"}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      fontSize="sm"
      fontWeight={500}
      display="flex"
      alignItems="center"
      justifyContent="center"
      color={isDisabled ? "leadpro.400" : "leadpro.500"}
      height="32px"
      _hover={{
        bg: isDisabled ? "white" : "blue.50",
      }}
    >
      {children}
    </Box>
  );
};
