import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import { Control } from "react-hook-form";
import { TFormToolSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { PageToolThankYouPageSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolThankYouPageSettings";

interface IProps {
  control: Control<TFormToolSettingsFieldValues>;
}

export const FormToolPageSettingsDetailsThankYouPageForm: FC<IProps> = ({
  control,
}) => {
  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        Custom "Thank You" page content
      </Text>
      <PageToolThankYouPageSettings
        control={control}
        titleField={"customThankYouPageHeader"}
        bodyField={"customThankYouPageBody"}
        ctaLabelField={"customThankYouPageCtaLabel"}
        ctaUrlField={"customThankYouPageCtaUrl"}
      />
    </Box>
  );
};
