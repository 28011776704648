import React from "react";
import { Box, Tag, TagLabel } from "@chakra-ui/react";
import { FormControlLabel, NA } from "components";
import { TAccountOfficeData } from "types/account-office.type";

interface IProps {
  office: TAccountOfficeData;
}

export const OfficeDetailsStatic: React.FC<IProps> = ({ office }) => {
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <FormControlLabel>Lead Responder email:</FormControlLabel>
        <Box color={"gray.900"}>
          {office.portalEmail ? (
            <Tag size={"sm"} mr={1} my={1}>
              <TagLabel>{office.portalEmail}</TagLabel>
            </Tag>
          ) : (
            <NA />
          )}
        </Box>
      </Box>
    </Box>
  );
};
