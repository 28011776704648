import { Box, HStack } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { TAvailableQuestionnaireFilterData } from "types/leads-filter.type";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SingleSelectInput } from "components";
import { TSelectValue } from "types/select-input.type";

interface IProps {
  question: TAvailableQuestionnaireFilterData;
  selectedAnswer?: string;
  onChange: (question: string, answers?: string[]) => void;
}
export const PropertyDetailsQuestionnaireFilterItem: FC<IProps> = ({
  question,
  selectedAnswer,
  onChange,
}) => {
  const answerOptions = useMemo(() => {
    return (
      question.answers?.map(answer => ({
        label: answer.id,
        value: answer.id,
      })) || []
    );
  }, [question]);

  const onAnswerChange = useCallback(
    (answer: TSelectValue<string>) => {
      onChange(question.id, !!answer ? [answer] : undefined);
    },
    [onChange, question.id]
  );

  return (
    <Box width={"100%"}>
      <HStack spacing={3} alignItems={"center"} mb={2}>
        <Box color={"leadpro.400"}>
          <FontAwesomeIcon icon={faCircleQuestion} fontSize={16} />
        </Box>
        <Box>{question.id}</Box>
      </HStack>
      <Box>
        <SingleSelectInput<string>
          value={selectedAnswer || ""}
          onChange={onAnswerChange}
          options={answerOptions}
          placeholder={"property"}
          clearable={true}
        />
      </Box>
    </Box>
  );
};
