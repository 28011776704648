import { AccountOpenViewAPI } from "api/account-openview-api";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TOpenViewContact, TOpenViewContactData } from "types/openview.type";
import { TSelectOption } from "types/select-input.type";
import { getUserFullName } from "utils/account-user.utils";

const accountOpenViewAPI = new AccountOpenViewAPI();

export const openViewNewContactValue = "newRecord";

export class AccountIntegrationOpenViewContactsStore {
  private readonly accountId: number;
  private openViewAccountContacts: IObservableArray<TOpenViewContact>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountId = accountId;
    this.openViewAccountContacts = observable.array<TOpenViewContact>();
  }

  get openViewRelatedContacts() {
    return this.openViewAccountContacts.slice();
  }

  get openViewRelatedContactsOptions(): TSelectOption<
    string,
    TOpenViewContactData
  >[] {
    return [
      ...this.openViewRelatedContacts
        .map(contact => {
          const { firstname, lastname, phonenumber, userId, email } = contact;
          const contactTypes = this.getContactType(contact);
          const fullName = getUserFullName(firstname, lastname);
          const phoneNumber = phonenumber
            ? this.formattedPhone(phonenumber)
            : "";
          return {
            value: userId,
            label: userId,
            data: {
              email: email,
              phoneNumber: phoneNumber,
              contactTypes,
              fullName,
            },
          };
        })
        .filter(contact => contact.data.contactTypes.length > 0),
      {
        value: openViewNewContactValue,
        label: "Create new record",
        data: {
          email: "",
          phoneNumber: "",
          fullName: "",
          contactTypes: [],
        },
      },
    ];
  }

  private getContactType(contact: TOpenViewContact) {
    const typesArray = [];
    if (contact.vendor && !contact.vendor.isArchived) {
      typesArray.push(LeadTypeEnum.Vendor);
    }
    if (contact.landlord && !contact.landlord.isArchived) {
      typesArray.push(LeadTypeEnum.Landlord);
    }
    if (contact.applicant && !contact.applicant.isArchived) {
      if (contact.applicant.isSales) {
        typesArray.push(LeadTypeEnum.Sale);
      }
      if (contact.applicant.isLettings) {
        typesArray.push(LeadTypeEnum.Let);
      }
    }

    return typesArray;
  }

  private formattedPhone = (phoneNumber: string) => {
    const removedAreaCode = phoneNumber.replace("+44", "0") || "";
    return `${removedAreaCode.slice(0, 5)} ${removedAreaCode.slice(5)}`;
  };

  private async setOpenViewAccountContacts(
    remoteRelatedContacts: TOpenViewContact[]
  ) {
    this.openViewAccountContacts.replace(remoteRelatedContacts);
  }

  public async fetchOpenViewAccountContacts(leadId: number) {
    const data = await accountOpenViewAPI.fetchAccountOpenViewContacts(
      this.accountId,
      leadId
    );

    await this.setOpenViewAccountContacts(data);
  }
}
