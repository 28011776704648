import { observer } from "mobx-react";
import {
  IPageToolPageSettingsLayoutProps,
  PageToolPageSettingsLayout,
} from "./PageToolPageSettingsLayout";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, ExternalLink } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { IPageToolPageSettingsEditorStore } from "interfaces/IPageToolPageSettingsEditorStore";

interface IProps {
  pageToolPageSettingsEditorStore: IPageToolPageSettingsEditorStore;
  getNewPageRedirectRoute: (pageId: number) => string;
  config: IPageToolPageSettingsLayoutProps["config"];
  hideCreateOrUpdateButton?: boolean;
  externalLinkComponentRenderer?: () => React.ReactNode;
}

export const PageToolPageSettings: FC<IProps> = observer(
  ({
    pageToolPageSettingsEditorStore,
    getNewPageRedirectRoute,
    config,
    hideCreateOrUpdateButton,
    externalLinkComponentRenderer,
  }) => {
    const toast = useToast();
    const history = useHistory();
    const page = pageToolPageSettingsEditorStore.page;

    const handleUpdatePage = useCallback(async () => {
      await pageToolPageSettingsEditorStore.updatePageAndPageComponents();
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: "Changes will be visible next time someone uses the page.",
      });
    }, [pageToolPageSettingsEditorStore, toast]);

    const handleCreatePage = useCallback(async () => {
      const newPageId = await pageToolPageSettingsEditorStore.createPageAndPageComponents();
      if (!!newPageId) {
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your new page is ready to generate leads.",
        });
        history.push(getNewPageRedirectRoute(newPageId));
      }
    }, [
      pageToolPageSettingsEditorStore,
      toast,
      history,
      getNewPageRedirectRoute,
    ]);

    const onSave = useCallback(async () => {
      try {
        if (!!pageToolPageSettingsEditorStore.pageId) {
          await handleUpdatePage();
        } else {
          await handleCreatePage();
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [
      handleCreatePage,
      handleUpdatePage,
      pageToolPageSettingsEditorStore.pageId,
      toast,
    ]);

    const externalLinkElement = useMemo(() => {
      if (!!page) {
        if (!!externalLinkComponentRenderer)
          return externalLinkComponentRenderer();

        return (
          <ExternalLink
            label={"Preview your page"}
            href={`https://${page.host}`}
          />
        );
      }
    }, [page, externalLinkComponentRenderer]);

    useEffect(() => {
      pageToolPageSettingsEditorStore.loadData();
    }, [
      pageToolPageSettingsEditorStore,
      pageToolPageSettingsEditorStore.pageId,
    ]);

    return (
      <PageToolPageSettingsLayout
        title={`${!!page ? "Updating" : "Creating new"} page`}
        loadingStatus={pageToolPageSettingsEditorStore.loadingStatus}
        config={config}
        commonFooter={
          <Box
            p={5}
            width={"100%"}
            borderTop={"1px solid"}
            borderTopColor={"gray.100"}
            bg={"gray.50"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={hideCreateOrUpdateButton ? "row" : "row-reverse"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {!hideCreateOrUpdateButton && (
                <Button
                  onClick={onSave}
                  colorScheme={"teal"}
                  isDisabled={
                    pageToolPageSettingsEditorStore.isSubmitting ||
                    pageToolPageSettingsEditorStore.hasWIPErrors
                  }
                  isLoading={pageToolPageSettingsEditorStore.isSubmitting}
                >
                  {!!pageToolPageSettingsEditorStore.pageId
                    ? "Update"
                    : "Create"}
                </Button>
              )}
              {externalLinkElement}
            </Box>
          </Box>
        }
      />
    );
  }
);
