import { Box, useToast } from "@chakra-ui/react";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RightSideLayout } from "routes/dashboard/components";
import LeadsContent from "./components/LeadsContent";
import {
  LEADS_UNREAD_ROUTE,
  LEAD_SUBROUTE_ALL,
  LEAD_SUBROUTE_UNREAD,
} from "constants/routes";
import { GeneralTour } from "routes/dashboard/components/tours/GeneralTour/GeneralTour";
import { TLeadsFilter } from "types/leads-filter.type";
import { isEmpty } from "lodash";
import {
  ALL_LEAD_SUBROUTES,
  leadSubrouteToStage,
} from "utils/lead-stage.utils";
import {
  createWebPushSubscriptionIfGranted,
  getWebPushNotificationPermission,
  isWebPushNotificationStackSupported,
  requestWebPushNotificationPermission,
  sendMessageToWebPushWorker,
} from "utils/web-push.utils";
import { WebPushNotificationPermissionTypeEnum } from "enums/web-push-notification-permission-type.enum";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import Visibility from "visibilityjs";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { SearchQueryInput } from "components/input/SearchQueryInput";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { leadsTableColumnDef } from "./components/LeadsTable/leadsTableColumnDef";
import { LeadsTableColumns } from "enums/leads-table-columns.enum";

enum SEARCHABLE_FIELDS {
  TYPE = "type",
  SOURCE = "source",
  OFFICE = "office",
  ASSIGNED_TO = "assignedTo",
  STATUSES = "statuses",
  LEAD_IDS = "leadIds",
}
const WEB_PUSH_NOTIFICATION_CHANNEL = "web-push-notification-channel";

interface IProps {
  accountStore: UserAccountStore;
}
export const Leads: FC<IProps> = observer(({ accountStore }) => {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  let { leadSubroute } = useParams<{ leadSubroute: string }>();
  const [includeSpeedScores, setIncludeSpeedScores] = useState(
    leadSubroute === LEAD_SUBROUTE_UNREAD
  );

  const {
    accountLeadsStore: { accountUsersMap, leadsFilterStore },
    accountLeadSourcesStore: { leadSourcesMapBySource },
    accountLeadStatusesStore: { leadStatusesMapByStatusId },
    accountOfficesStore: { accountOfficesDataMap },
    accountJourneysStore,
    portalsMap,
    account: { logoUrl },
  } = accountStore;
  const { setSearchQuery, searchQuery } = leadsFilterStore;

  useEffect(() => {
    const leadIds = new URLSearchParams(location.search)
      .getAll(SEARCHABLE_FIELDS.LEAD_IDS)
      .map(leadId => parseInt(leadId, 10));

    if (!!leadIds.length && leadSubroute === LEAD_SUBROUTE_ALL)
      return leadsFilterStore.upsertPendingFilter({ leadIds });

    leadsFilterStore.upsertPendingFilter({
      leadIds: [],
    });
  }, [leadSubroute, leadsFilterStore, location.search]);

  const filtersFromURL = useMemo(() => {
    const filtersFromURL: Partial<TLeadsFilter> = {};
    if (leadSubroute && ALL_LEAD_SUBROUTES.includes(leadSubroute)) {
      const stage = leadSubrouteToStage(leadSubroute);
      const types = new URLSearchParams(location.search).getAll(
        SEARCHABLE_FIELDS.TYPE
      );
      const sources = new URLSearchParams(location.search).getAll(
        SEARCHABLE_FIELDS.SOURCE
      );
      const offices = new URLSearchParams(location.search)
        .getAll(SEARCHABLE_FIELDS.OFFICE)
        .map(officeId => parseInt(officeId, 10));
      const assignedTo = new URLSearchParams(location.search)
        .getAll(SEARCHABLE_FIELDS.ASSIGNED_TO)
        .map(assignedToId => parseInt(assignedToId, 10));
      const statuses = new URLSearchParams(location.search)
        .getAll(SEARCHABLE_FIELDS.STATUSES)
        .map(statusId => parseInt(statusId, 10));

      filtersFromURL.stages = !!stage ? [stage] : [];

      if (!!types.length) {
        filtersFromURL.types = types;
      }
      if (!!sources.length) {
        const src: Record<string, string[]> = {};
        sources.forEach(source => (src[source] = []));
        filtersFromURL.sources = src;
      }
      if (!!offices.length) {
        filtersFromURL.offices = offices;
      }
      if (!!assignedTo.length) {
        filtersFromURL.assignedTo = assignedTo;
      }

      if (!!statuses.length) {
        filtersFromURL.statuses = statuses;
      }

      if (leadSubroute !== LEAD_SUBROUTE_ALL) {
        filtersFromURL.statuses = [];
      }
    }

    return filtersFromURL;
  }, [location, leadSubroute]);

  useEffect(() => {
    setIncludeSpeedScores(leadSubroute === LEAD_SUBROUTE_UNREAD);
  }, [leadSubroute, setIncludeSpeedScores]);

  useEffect(() => {
    if (!isEmpty(filtersFromURL)) {
      leadsFilterStore.upsertPendingFilter(filtersFromURL);
      leadsFilterStore.setActiveFromPendingFilter();
    } else {
      history.push(LEADS_UNREAD_ROUTE);
    }
  }, [history, filtersFromURL, leadsFilterStore]);

  // This effect tries to setup push notifications
  useEffect(() => {
    const initPushNotifications = async () => {
      const notificationsPermission = getWebPushNotificationPermission();

      if (
        notificationsPermission ===
        WebPushNotificationPermissionTypeEnum.DEFAULT
      ) {
        try {
          await requestWebPushNotificationPermission();
          await createWebPushSubscriptionIfGranted();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            title: "Could not register push notifications",
            description: "Please contact support for help",
          });
        }
      }
    };

    initPushNotifications();
  }, [toast]);

  // This effect prevents push notifications from being shown if browser tab with this view is currently active
  useEffect(() => {
    let currentChannel: BroadcastChannel | undefined;

    if (isWebPushNotificationStackSupported()) {
      currentChannel = new BroadcastChannel(WEB_PUSH_NOTIFICATION_CHANNEL);
      currentChannel.onmessage = event => {
        if (!Visibility.hidden()) {
          sendMessageToWebPushWorker(event.data);
        }
      };
    }

    return () => {
      leadsFilterStore?.resetActiveFilter();
      currentChannel?.close();
    };
  }, [leadsFilterStore]);

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  const tableColumns = useMemo(
    () =>
      leadsTableColumnDef(
        leadsFilterStore,
        leadSourcesMapBySource,
        leadStatusesMapByStatusId,
        accountOfficesDataMap,
        accountUsersMap,
        accountJourneysStore,
        logoUrl,
        portalsMap,
        leadSubroute
      ).filter(
        column =>
          !column.key ||
          !accountStore.accountLeadsStore.hiddenLeadsTableColumnsArray.includes(
            column.key as LeadsTableColumns
          )
      ),
    [
      leadsFilterStore,
      leadSourcesMapBySource,
      leadStatusesMapByStatusId,
      accountUsersMap,
      accountOfficesDataMap,
      logoUrl,
      portalsMap,
      leadSubroute,
      accountJourneysStore,
      accountStore.accountLeadsStore.hiddenLeadsTableColumnsArray,
    ]
  );

  return (
    <RightSideLayout
      headerProps={{
        title: "Leads",
        middleElement: (
          <SearchQueryInput
            placeholder={"Type name, email, phone or address"}
            onChange={handleSearchQuery}
            searchQuery={searchQuery}
          />
        ),
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        overflow={"hidden"}
      >
        <LeadsContent
          accountStore={accountStore}
          tableColumns={tableColumns}
          showStageTabs={true}
          showNotifications={true}
          includeSpeedScores={includeSpeedScores}
          rootProps={{
            tour: `${DashboardTourEnum.GENERAL_TOUR}-1`,
          }}
        />
        <GeneralTour accountStore={accountStore} />
      </Box>
    </RightSideLayout>
  );
});
