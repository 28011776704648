import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import {
  LeadAttributionGroupedEnum,
  LeadAttributionTypesEnum,
} from "enums/account-lead-attribution.enum";
import { TSelectOption } from "types/select-input.type";
import { AccountAnalyticsLeadAttributionStore } from "./AccountAnalyticsLeadAttribution.store";
import { getLeadAttributionItemUnknownLabel } from "utils/account-lead-attribution-analytics.utils";

export const SUPPORTED_LEAD_ATTRIBUTION_GROUPS: LeadAttributionGroupedEnum[] = [
  LeadAttributionGroupedEnum.APPOINTMENTS,
  LeadAttributionGroupedEnum.VALUATIONS,
  LeadAttributionGroupedEnum.INSTRUCTIONS,
  LeadAttributionGroupedEnum.COMPLETIONS,
];

export const DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW = 5;

export class AccountAnalyticsLeadAttributionFiltersStore {
  private accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
  private readonly leadAttributionGroupsMap: Record<
    LeadAttributionTypesEnum,
    LeadAttributionGroupedEnum[]
  >;
  private officeIds: IObservableArray<number>;
  private leadSources: IObservableArray<string>;
  private utmCampaigns: IObservableArray<string>;
  private leadJourneys: IObservableArray<string>;

  constructor(
    accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountAnalyticsLeadAttributionStore = accountAnalyticsLeadAttributionStore;
    this.leadAttributionGroupsMap = {
      [LeadAttributionTypesEnum.OFFICE]: SUPPORTED_LEAD_ATTRIBUTION_GROUPS,
      [LeadAttributionTypesEnum.SOURCE]: SUPPORTED_LEAD_ATTRIBUTION_GROUPS,
      [LeadAttributionTypesEnum.CAMPAIGN]: SUPPORTED_LEAD_ATTRIBUTION_GROUPS,
      [LeadAttributionTypesEnum.JOURNEY]: SUPPORTED_LEAD_ATTRIBUTION_GROUPS,
    };
    this.officeIds = observable.array<number>();
    this.leadSources = observable.array<string>();
    this.utmCampaigns = observable.array<string>();
    this.leadJourneys = observable.array<string>();
  }

  get leadAttributionGroupsMapByType() {
    return toJS(this.leadAttributionGroupsMap);
  }

  // Filters data
  get officeIdsArray() {
    return this.officeIds.slice();
  }

  get leadSourcesArray() {
    return this.leadSources.slice();
  }

  get utmCampaignsArray() {
    return this.utmCampaigns.slice();
  }

  get leadJourneysArray() {
    return this.leadJourneys.slice();
  }

  // Items as options sorted by label
  get officesAsOptionsSortedByLabel(): TSelectOption<number>[] {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByOfficeReportData.statsPerItem
    )
      .map(stat => ({
        value: stat.officeId,
        label:
          stat.officeName ||
          getLeadAttributionItemUnknownLabel(LeadAttributionTypesEnum.OFFICE),
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }

  get sourcesAsOptionsSortedByLabel(): TSelectOption<string>[] {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionBySourceReportData.statsPerItem
    )
      .map(stat => ({
        value: stat.source,
        label:
          stat.sourceName ||
          stat.source ||
          getLeadAttributionItemUnknownLabel(LeadAttributionTypesEnum.SOURCE),
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }

  get utmCampaignsAsOptionsSortedByLabel(): TSelectOption<string>[] {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByUTMCampaignReportData.statsPerItem
    )
      .map(stat => ({
        value: stat.utmCampaign,
        label:
          stat.utmCampaign ||
          getLeadAttributionItemUnknownLabel(LeadAttributionTypesEnum.CAMPAIGN),
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }

  get leadJourneysAsOptionsSortedByLabel(): TSelectOption<string>[] {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByLeadJourneyReportData.statsPerItem
    )
      .map(stat => ({
        value: stat.journeyName,
        label:
          stat.journeyName ||
          getLeadAttributionItemUnknownLabel(LeadAttributionTypesEnum.JOURNEY),
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }

  // Items sorted by est value
  get officesSortedByTotalEstValue() {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByOfficeReportData.statsPerItem
    ).sort((a, b) => b.totalEstValuePerItem - a.totalEstValuePerItem);
  }

  get sourcesSortedByTotalEstValue() {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionBySourceReportData.statsPerItem
    ).sort((a, b) => b.totalEstValuePerItem - a.totalEstValuePerItem);
  }

  get utmCampaignsSortedByTotalEstValue() {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByUTMCampaignReportData.statsPerItem
    ).sort((a, b) => b.totalEstValuePerItem - a.totalEstValuePerItem);
  }

  get leadJourneysSortedByTotalEstValue() {
    return Object.values(
      this.accountAnalyticsLeadAttributionStore
        .leadAttributionByLeadJourneyReportData.statsPerItem
    ).sort((a, b) => b.totalEstValuePerItem - a.totalEstValuePerItem);
  }

  // Generic gets
  get filtersDataByType(): Record<
    LeadAttributionTypesEnum,
    Array<number | string>
  > {
    return {
      [LeadAttributionTypesEnum.OFFICE]: this.officeIdsArray,
      [LeadAttributionTypesEnum.SOURCE]: this.leadSourcesArray,
      [LeadAttributionTypesEnum.CAMPAIGN]: this.utmCampaignsArray,
      [LeadAttributionTypesEnum.JOURNEY]: this.leadJourneysArray,
    };
  }

  get itemsAsOptionsByTypeSortedByLabel(): Record<
    LeadAttributionTypesEnum,
    TSelectOption<number | string>[]
  > {
    return {
      [LeadAttributionTypesEnum.OFFICE]: this.officesAsOptionsSortedByLabel,
      [LeadAttributionTypesEnum.SOURCE]: this.sourcesAsOptionsSortedByLabel,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .utmCampaignsAsOptionsSortedByLabel,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .leadJourneysAsOptionsSortedByLabel,
    };
  }

  get itemsByTypeSortedByEstValue() {
    return {
      [LeadAttributionTypesEnum.OFFICE]: this.officesSortedByTotalEstValue,
      [LeadAttributionTypesEnum.SOURCE]: this.sourcesSortedByTotalEstValue,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .utmCampaignsSortedByTotalEstValue,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .leadJourneysSortedByTotalEstValue,
    };
  }

  get haveMoreItemsToShow(): Record<LeadAttributionTypesEnum, boolean> {
    return {
      [LeadAttributionTypesEnum.OFFICE]:
        this.officeIds.length === this.officesSortedByTotalEstValue.length,
      [LeadAttributionTypesEnum.SOURCE]:
        this.leadSources.length === this.sourcesSortedByTotalEstValue.length,
      [LeadAttributionTypesEnum.CAMPAIGN]:
        this.utmCampaigns.length ===
        this.utmCampaignsSortedByTotalEstValue.length,
      [LeadAttributionTypesEnum.JOURNEY]:
        this.leadJourneys.length ===
        this.leadJourneysSortedByTotalEstValue.length,
    };
  }

  // Set items
  private setOfficeIds(officeIds: number[]) {
    this.officeIds.replace(officeIds);
  }

  private setLeadSources(leadSources: string[]) {
    this.leadSources.replace(leadSources);
  }

  private setUtmCampaigns(leadSources: string[]) {
    this.utmCampaigns.replace(leadSources);
  }

  private setLeadJourneys(leadSources: string[]) {
    this.leadJourneys.replace(leadSources);
  }

  // Set top items
  private setTopOfficesSortedByEstValue() {
    this.setOfficeIds(
      this.officesSortedByTotalEstValue
        .slice(0, DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW)
        .map(item => item.officeId)
    );
  }

  private setTopLeadSourcesSortedByEstValue() {
    this.setLeadSources(
      this.sourcesSortedByTotalEstValue
        .slice(0, DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW)
        .map(item => item.source)
    );
  }

  private setTopUTMCampaignsSortedByEstValue() {
    this.setUtmCampaigns(
      this.utmCampaignsSortedByTotalEstValue
        .slice(0, DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW)
        .map(item => item.utmCampaign)
    );
  }

  private setTopLeadJourneysSortedByEstValue() {
    this.setLeadJourneys(
      this.leadJourneysSortedByTotalEstValue
        .slice(0, DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW)
        .map(item => item.journeyName)
    );
  }

  // Set all items
  private setAllOfficeItemsSortedByEstValue() {
    this.setOfficeIds(
      this.itemsByTypeSortedByEstValue[LeadAttributionTypesEnum.OFFICE].map(
        item => item.officeId
      )
    );
  }

  private setAllSourceItemsSortedByEstValue() {
    this.setLeadSources(
      this.itemsByTypeSortedByEstValue[LeadAttributionTypesEnum.SOURCE].map(
        item => item.source
      )
    );
  }

  private setAllUTMCampaignItemsSortedByEstValue() {
    this.setUtmCampaigns(
      this.itemsByTypeSortedByEstValue[LeadAttributionTypesEnum.CAMPAIGN].map(
        item => item.utmCampaign
      )
    );
  }

  private setAllLeadJourneysItemsSortedByEstValue() {
    this.setLeadJourneys(
      this.itemsByTypeSortedByEstValue[LeadAttributionTypesEnum.JOURNEY].map(
        item => item.journeyName
      )
    );
  }

  // Set next item batches
  private setNextBatchOfOfficeItemsSortedByEstValue() {
    this.setOfficeIds(
      this.officesSortedByTotalEstValue
        .slice(0, this.officeIds.length + DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW)
        .map(item => item.officeId)
    );
  }

  private setNextBatchOfSourceItemsSortedByEstValue() {
    this.setLeadSources(
      this.sourcesSortedByTotalEstValue
        .slice(
          0,
          this.leadSources.length + DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW
        )
        .map(item => item.source)
    );
  }

  private setNextBatchOfUTMCampaignItemsSortedByEstValue() {
    this.setUtmCampaigns(
      this.utmCampaignsSortedByTotalEstValue
        .slice(
          0,
          this.utmCampaigns.length + DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW
        )
        .map(item => item.utmCampaign)
    );
  }

  private setNextBatchOfLeadJourneysItemsSortedByEstValue() {
    this.setLeadJourneys(
      this.leadJourneysSortedByTotalEstValue
        .slice(
          0,
          this.leadJourneys.length + DEFAULT_NUMBER_OF_STAT_ITEMS_TO_SHOW
        )
        .map(item => item.journeyName)
    );
  }

  // Generic sets
  public setAllItemsSortedByEstValue(type: LeadAttributionTypesEnum) {
    const functionsMap: Record<LeadAttributionTypesEnum, () => void> = {
      [LeadAttributionTypesEnum.OFFICE]: this.setAllOfficeItemsSortedByEstValue,
      [LeadAttributionTypesEnum.SOURCE]: this.setAllSourceItemsSortedByEstValue,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .setAllUTMCampaignItemsSortedByEstValue,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .setAllLeadJourneysItemsSortedByEstValue,
    };

    functionsMap[type]();
  }

  public setTopItemsSortedByEstValue(type: LeadAttributionTypesEnum) {
    const functionsMap: Record<LeadAttributionTypesEnum, () => void> = {
      [LeadAttributionTypesEnum.OFFICE]: this.setTopOfficesSortedByEstValue,
      [LeadAttributionTypesEnum.SOURCE]: this.setTopLeadSourcesSortedByEstValue,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .setTopUTMCampaignsSortedByEstValue,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .setTopLeadJourneysSortedByEstValue,
    };

    functionsMap[type]();
  }

  public setNextBatchOfItemsSortedByEstValue(type: LeadAttributionTypesEnum) {
    const functionsMap: Record<LeadAttributionTypesEnum, () => void> = {
      [LeadAttributionTypesEnum.OFFICE]: this
        .setNextBatchOfOfficeItemsSortedByEstValue,
      [LeadAttributionTypesEnum.SOURCE]: this
        .setNextBatchOfSourceItemsSortedByEstValue,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .setNextBatchOfUTMCampaignItemsSortedByEstValue,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .setNextBatchOfLeadJourneysItemsSortedByEstValue,
    };

    functionsMap[type]();
  }

  public setItems(type: LeadAttributionTypesEnum, items: []) {
    const functionsMap: Record<
      LeadAttributionTypesEnum,
      (items: []) => void
    > = {
      [LeadAttributionTypesEnum.OFFICE]: this.setOfficeIds,
      [LeadAttributionTypesEnum.SOURCE]: this.setLeadSources,
      [LeadAttributionTypesEnum.CAMPAIGN]: this.setUtmCampaigns,
      [LeadAttributionTypesEnum.JOURNEY]: this.setLeadJourneys,
    };

    functionsMap[type](items);
  }

  public toggleLeadAttributionGroup(
    type: LeadAttributionTypesEnum,
    group: LeadAttributionGroupedEnum
  ) {
    const newGroups = this.leadAttributionGroupsMap[type].filter(
      existingGroup => existingGroup !== group
    );

    if (newGroups.length < this.leadAttributionGroupsMap[type].length) {
      this.leadAttributionGroupsMap[type] = newGroups;
    } else {
      this.leadAttributionGroupsMap[type].push(group);
    }
  }
}
