import { BaseApi } from "./base.api";
import {
  TAccountBillingData,
  TStripeCustomerInfo,
} from "types/account-billing.type";
import { ApplicationProductEnum } from "../enums/application-product.enum";

export class AccountBillingApi extends BaseApi {
  // BILLING
  async setAccountPaymentMethod(
    accountId: number,
    paymentMethodId: string
  ): Promise<TAccountBillingData> {
    const { accountBilling } = await this.put(
      `/accounts/${accountId}/subscription/set-payment-method`,
      {
        paymentMethodId,
      }
    );

    return accountBilling;
  }

  async updateAccountBillingCustomerInfo(
    accountId: number,
    info: Partial<TStripeCustomerInfo>
  ): Promise<TAccountBillingData> {
    return this.put(`/accounts/${accountId}/subscription/billing-info`, info);
  }

  async addAccountProduct(
    accountId: number,
    productId: ApplicationProductEnum
  ): Promise<TAccountBillingData> {
    return this.put(`/accounts/${accountId}/subscription/add-product`, {
      productId,
    });
  }

  async removeAccountProduct(
    accountId: number,
    productId: ApplicationProductEnum
  ): Promise<TAccountBillingData> {
    return this.put(`/accounts/${accountId}/subscription/remove-product`, {
      productId,
    });
  }
}
