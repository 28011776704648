import {
  TAccountApiRequestResponse,
  TAccountWebhookRequestResponse,
} from "types/account-developers.type";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Accordion, Box, Center, Flex } from "@chakra-ui/react";
import {
  Error,
  FormControlMeta,
  Loader,
  Message,
  Paginator,
  StandardIconButton,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";

type RequestsType = TAccountApiRequestResponse | TAccountWebhookRequestResponse;
interface IDevelopersConfigRequestsProps<D extends RequestsType> {
  label: string;
  description: string;
  requests: D | null;
  loadRequests: (offset?: number, limit?: number) => Promise<void>;
  resetRequests: () => void;
  renderRequest: (request: any) => React.ReactNode;
}

export function DevelopersConfigRequests<D extends RequestsType>({
  label,
  description,
  requests,
  loadRequests,
  resetRequests,
  renderRequest,
}: IDevelopersConfigRequestsProps<D>) {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);

  const listData = useMemo(() => {
    if (!!requests) {
      return {
        data: requests.data,
        total: requests.meta.count,
        pageSize: requests.meta.limit,
        pageIndex: requests.meta.offset / requests.meta.limit,
        canPreviousPage: requests.meta.offset > 0,
        canNextPage:
          requests.meta.offset + requests.meta.limit < requests.meta.count,
      };
    }
    return null;
  }, [requests]);

  const initialFetch = useCallback(async () => {
    try {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      await loadRequests(0, 10);
      setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (e) {
      setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }, [loadRequests]);

  useEffect(() => {
    initialFetch();

    return () => {
      resetRequests();
    };
  }, [initialFetch, resetRequests]);

  const handleGotoPage = useCallback(
    async (pageNumber: number) => {
      try {
        if (!!requests) {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await loadRequests(
            pageNumber * requests.meta.limit,
            requests.meta.limit
          );
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        }
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    },
    [requests, loadRequests]
  );

  const showWhenPendingOrSuccess = useMemo(() => {
    if (!listData) return null;

    if (!!listData.data.length) {
      return (
        <>
          <Accordion width={"100%"} allowToggle={true}>
            {listData.data.map(renderRequest)}
          </Accordion>
          <Paginator
            border={"none"}
            total={listData.total}
            pageSize={listData.pageSize}
            pageIndex={listData.pageIndex}
            canPreviousPage={listData.canPreviousPage}
            canNextPage={listData.canNextPage}
            gotoPage={handleGotoPage}
          />
        </>
      );
    } else if (!listData.data.length) {
      return (
        <Message>
          <Center width={"100%"}>No data.</Center>
        </Message>
      );
    }
  }, [listData, handleGotoPage, renderRequest]);

  return (
    <Box width={"100%"} position={"relative"} minHeight={"400px"}>
      <Flex mb={2} justifyContent={"space-between"} alignItems={"center"}>
        <FormControlMeta label={label} description={description} />
        <StandardIconButton
          onClick={initialFetch}
          isDisabled={loadingStatus === ApiRequestStatusEnum.PENDING}
          aria-label={"reload-requests"}
          icon={<FontAwesomeIcon icon={faArrowRotateLeft} fontSize={24} />}
        />
      </Flex>
      {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {(loadingStatus === ApiRequestStatusEnum.PENDING ||
        loadingStatus === ApiRequestStatusEnum.SUCCESS) &&
        showWhenPendingOrSuccess}
    </Box>
  );
}
