import React, { FC, useEffect, useState } from "react";
import { LeadNoteInput } from "./LeadActivityNote";
import { Box, Divider, Flex } from "@chakra-ui/react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { LeadActivityList } from "./LeadActivityList";

interface IProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

export const LeadActivity: FC<IProps> = observer(
  ({ leadStore, accountStore }) => {
    const leadData = leadStore.lead;
    const leadActivitiesStore = leadStore.leadActivitiesStore;
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await leadActivitiesStore.loadLeadActivities();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (err) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();

      return () => leadActivitiesStore.reset();
    }, [leadActivitiesStore, setLoadingStatus]);

    return (
      <Flex
        flexDirection={"column"}
        height={"100%"}
        width={"100%"}
        bg={"leadpro.900"}
        py={6}
      >
        {!!leadData && <LeadNoteInput leadStore={leadStore} />}
        <Box width={"100%"} px={4} marginTop={4}>
          <Divider />
        </Box>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <Box
              width={"100%"}
              flexBasis={"100%"}
              height={"100%"}
              px={4}
              position={"relative"}
              marginTop={4}
            >
              <LeadActivityList
                accountStore={accountStore}
                leadStore={leadStore}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              position={"relative"}
              width={"100%"}
            >
              {leadActivitiesStore.isFetchingInProgress && (
                <Loader size={"sm"} />
              )}
            </Box>
          </>
        )}
      </Flex>
    );
  }
);
