import { FC, useCallback, useState } from "react";
import {
  ApiMessageStack,
  FormControlV2,
  FormControlMeta,
  PanelBody,
  PanelFooter,
  Panel,
  PanelHeader,
  PanelTitle,
} from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { Box, Button, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";

type TFieldValues = {
  archiveBefore: string | null;
};

const initialValues: TFieldValues = {
  archiveBefore: null,
};

const validationSchema = Yup.object().shape({
  archiveBefore: Yup.date()
    .nullable()
    .required(REQUIRED_FIELD),
});

interface IProps {
  accountLeadsStore: AccountLeadsStore;
}

export const ArchiveLeads: FC<IProps> = observer(({ accountLeadsStore }) => {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setModal, unSetModal } = useActionPrompt();

  const { handleSubmit, control, reset } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const handleArchiveLeads = useCallback(
    async ({ archiveBefore }: TFieldValues) => {
      if (!archiveBefore) return;
      try {
        setIsSubmitting(true);
        await accountLeadsStore.archiveLeads(archiveBefore);
        reset();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Leads archived successfully!"} />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [accountLeadsStore, toast, reset]
  );

  const onSubmit = useCallback(
    async (values: TFieldValues) => {
      if (!values.archiveBefore) return;

      const formatedArchiveDate = formatDate(values.archiveBefore, DATE_FORMAT);
      setModal(
        <ConfirmActionPrompt
          text={`Please confirm that you want to archive all the leads before ${formatedArchiveDate} for this account. This action cannot be reverted.`}
          closePrompt={unSetModal}
          onConfirm={() => handleArchiveLeads(values)}
        />
      );
    },
    [setModal, handleArchiveLeads, unSetModal]
  );

  return (
    <Panel overflow={"visible"}>
      <PanelHeader>
        <PanelTitle>Archive Leads</PanelTitle>
      </PanelHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PanelBody>
          <FormControlMeta label={"Leads created before"} />
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box>
              <FormControlV2<TFieldValues>
                name={"archiveBefore"}
                control={control}
                type={FormControlsTypeEnum.DATE_PICKER}
                additionalProps={{ clearable: true }}
              />
            </Box>
          </Box>
        </PanelBody>
        <PanelFooter>
          <Box ml="auto">
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Archive
            </Button>
          </Box>
        </PanelFooter>
      </form>
    </Panel>
  );
});
