import React, { FC } from "react";
import { Image } from "@chakra-ui/react";

interface IProps {
  image: string;
  threshold: number;
  completed: boolean;
}

export const AchievementBadge: FC<IProps> = ({
  image,
  threshold,
  completed,
}) => (
  <Image
    boxSize={"70px"}
    src={image}
    alt={`${threshold} badge`}
    style={{
      filter: completed ? "none" : "grayscale(100%) opacity(0.20)",
    }}
  />
);
