import { ApiMessageStack, FormControlV2 } from "components";
import { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { Box, Heading, SlideFade, Button, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { TRegisteredCompanyData } from "types/account.type";
import { RegisteredCompanySelectOption } from "components/select-input/custom-options/RegisteredCompanySelectOption";
import { AccountSetupStepEnum } from "enums/account-setup-step.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  registeredCompany: TRegisteredCompanyData | null;
  name: string;
};

const validationSchema = Yup.object().shape({
  registeredCompany: Yup.object()
    .shape({
      companyName: Yup.string().required(REQUIRED_FIELD),
      companyRegistrationNumber: Yup.string().required(REQUIRED_FIELD),
      companyAddress: Yup.string().required(REQUIRED_FIELD),
    })
    .required(REQUIRED_FIELD)
    .nullable(false)
    .typeError(REQUIRED_FIELD),
  name: Yup.string().required(REQUIRED_FIELD),
});

export const AccountBasicInfoStep = observer(() => {
  const toast = useToast();
  const {
    uiStore: {
      registeredCompaniesOptionsArray,
      fetchRegisteredCompaniesOptions,
    },
    userAccountsStore: { selectedAccountStore, createUserAccount },
  } = useAppStore();

  const initialValues: TFieldValues = useMemo(
    () => ({
      registeredCompany: selectedAccountStore?.account.companyRegistrationNumber
        ? {
            companyName: selectedAccountStore?.account.companyName,
            companyRegistrationNumber:
              selectedAccountStore?.account.companyRegistrationNumber,
            companyAddress: selectedAccountStore?.account.companyAddress,
          }
        : null,
      name: selectedAccountStore ? selectedAccountStore?.account.name : "",
    }),
    [selectedAccountStore]
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onCompanySearch = useCallback(
    async (searchTerm: string) => {
      await fetchRegisteredCompaniesOptions(searchTerm);
    },
    [fetchRegisteredCompaniesOptions]
  );

  const onSubmit = useCallback(
    async ({ registeredCompany, name }: TFieldValues) => {
      try {
        if (!selectedAccountStore) {
          await createUserAccount({
            companyName: registeredCompany?.companyName,
            companyRegistrationNumber:
              registeredCompany?.companyRegistrationNumber,
            companyAddress: registeredCompany?.companyAddress,
            name,
            setupStepsCompleted: [AccountSetupStepEnum.BASIC_INFO],
          });
        } else {
          await selectedAccountStore.update({
            companyName: registeredCompany?.companyName,
            companyRegistrationNumber:
              registeredCompany?.companyRegistrationNumber,
            companyAddress: registeredCompany?.companyAddress,
            name,
            setupStepsCompleted: [AccountSetupStepEnum.BASIC_INFO],
          });
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [createUserAccount, selectedAccountStore, toast]
  );

  return (
    <SlideFade in={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box textAlign="center" mb={10}>
          <Heading fontSize="3xl" fontWeight={"500"} lineHeight="none">
            Your LeadPro Account
          </Heading>
        </Box>
        <Box mb={10}>
          <FormControlV2<TFieldValues>
            label={"What is your registered company name?"}
            name={"registeredCompany"}
            control={control}
            type={FormControlsTypeEnum.SINGLE_SELECT}
            description={
              "This is the legal entity that you trade under e.g. LTD or LLP"
            }
            additionalProps={{
              options: registeredCompaniesOptionsArray,
              asyncFilterFn: onCompanySearch,
              placeholder: "Select company",
              filterPlaceholder: "Lookup company name from Companies House",
              optionComponent: (
                optionProps: IGetOptionPropsData<TRegisteredCompanyData>
              ) => <RegisteredCompanySelectOption optionProps={optionProps} />,
            }}
          />
        </Box>
        <Box mb={10}>
          <FormControlV2<TFieldValues>
            label={"What do you want to call your account?"}
            description={"This is the name you trade under, e.g. your brand"}
            name={"name"}
            control={control}
            type={FormControlsTypeEnum.TEXT}
            additionalProps={{
              placeholder: "Account name",
            }}
          />
        </Box>
        <Button
          type={"submit"}
          width={"full"}
          colorScheme={"blue"}
          size={"md"}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </form>
    </SlideFade>
  );
});
