import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { ExternalLink } from "components";
import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { TAdditionalDataEnquiry, TLeadWithDetails } from "types/lead.type";
import { LeadReferralTableCell } from "components/table/cells/LeadReferralTableCell";
import { TPortalData } from "types/portal.type";
import { Dictionary } from "lodash";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { LeadSourceTag } from "components/tags/LeadSourceTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthEurope } from "@fortawesome/pro-solid-svg-icons";
import { LeadBlockHeader } from "../LeadBlockHeader";
import {
  LeadInfoRow,
  LeadInfoRowLabel,
  LeadInfoRowValue,
} from "../LeadInfoRow";
import { LeadBlockBody } from "../LeadBlockBody";

export interface ILeadSourceDetailsBlockDefinition
  extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.SourceBlock;
  source: LeadSourceEnum;
  leadReferredByAccount?: TLeadWithDetails["leadReferredByAccount"];
  leadReferredByOffice?: TLeadWithDetails["leadReferredByOffice"];
  portalsMap: Dictionary<TPortalData>;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  accountLogo: string | null;
  enquiry?: TAdditionalDataEnquiry;
}

export const LeadSourceDetailsBlock = leadBlockHoc<
  ILeadSourceDetailsBlockDefinition
>(({ leadBlockDefinition }) => {
  return (
    <>
      <LeadBlockHeader>Source details</LeadBlockHeader>
      <LeadBlockBody>
        <LeadInfoRow>
          <LeadInfoRowLabel>Source</LeadInfoRowLabel>
          <LeadInfoRowValue>
            <Box display={"flex"} alignItems={"center"} height={"100%"}>
              <LeadSourceTag
                source={leadBlockDefinition.source}
                leadSourcesMapBySource={
                  leadBlockDefinition.leadSourcesMapBySource
                }
                accountLogoUrl={leadBlockDefinition.accountLogo}
                portalsMap={leadBlockDefinition.portalsMap}
              />
            </Box>
          </LeadInfoRowValue>
        </LeadInfoRow>
        {!!leadBlockDefinition.enquiry?.host && (
          <LeadInfoRow>
            <LeadInfoRowLabel>Website</LeadInfoRowLabel>
            <LeadInfoRowValue>
              <ExternalLink
                href={"https://" + leadBlockDefinition.enquiry.host}
                label={
                  <Tag colorScheme={"blue"}>
                    <TagLeftIcon
                      boxSize="18px"
                      as={() => <FontAwesomeIcon icon={faEarthEurope} />}
                    />
                    <TagLabel ml={1}>
                      {leadBlockDefinition.enquiry.host}
                    </TagLabel>
                  </Tag>
                }
              />
            </LeadInfoRowValue>
          </LeadInfoRow>
        )}
        <LeadInfoRow>
          <LeadInfoRowLabel>Referred By</LeadInfoRowLabel>
          <LeadInfoRowValue>
            <LeadReferralTableCell
              leadReferredByAccount={leadBlockDefinition.leadReferredByAccount}
              leadReferredByOffice={leadBlockDefinition.leadReferredByOffice}
            />
          </LeadInfoRowValue>
        </LeadInfoRow>

        <LeadInfoRow>
          <LeadInfoRowLabel>ETA</LeadInfoRowLabel>
          <LeadInfoRowValue>
            {leadBlockDefinition.enquiry?.eta}
          </LeadInfoRowValue>
        </LeadInfoRow>
      </LeadBlockBody>
    </>
  );
});
