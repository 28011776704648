import { Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack } from "components";
import { DEFAULT_CSV_OPTIONS } from "constants/csv-exporter";
import { ExportToCsv, Options } from "export-to-csv";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/pro-solid-svg-icons";

export interface ICSVDownloadButtonProps<T> {
  data: T[];
  options?: Partial<Options>;
}

export const CSVDownloadButton = <T extends {}>({
  data,
  options,
}: ICSVDownloadButtonProps<T>) => {
  const toast = useToast();

  const handleDownload = useCallback(async () => {
    try {
      const csvExporter = new ExportToCsv({
        ...DEFAULT_CSV_OPTIONS,
        ...options,
      });

      csvExporter.generateCsv(data);
    } catch (error) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: (
          <ApiMessageStack
            messageStack={"Data could not be processed, please try again"}
          />
        ),
      });
    }
  }, [toast, options, data]);

  return (
    <>
      <Button
        aria-label={"Download CSV file"}
        leftIcon={<FontAwesomeIcon icon={faCloudDownload} fontSize="18px" />}
        onClick={handleDownload}
        colorScheme="teal"
      >
        Download
      </Button>
    </>
  );
};
