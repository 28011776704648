export enum PersistentFilterGroupOperator {
  AND = "and",
  OR = "or",
}

export enum PersistentFilterExpressionOperator {
  EQUALS = "equals",
  NOT_EQUAL = "not_equal",
  CONTAINS = "contains",
}

export enum PersistentFilterExpressionSchemaType {
  ENUM = "enum",
  META = "meta",
  UTM = "utm",
}
