import React, { useCallback, useMemo } from "react";
import { Card } from "components/stats/Card";
import {
  Box,
  Button,
  Divider,
  HStack,
  Tag,
  TagLabel,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IAccountBillingSubscriptionProductConfig } from "constants/account-products-config";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { withStripe } from "utils/hoc/withStripe.hoc";
import { PaymentMethodPrompt } from "routes/dashboard/components/prompts/PaymentMethodPrompt";
import { formatMoney } from "utils/formating.utils";
import {
  getBillingProductCurrentMonthActivity,
  getProductPriceWithTaxes,
  isApplicationProductOnSpecificPlan,
} from "utils/account-billing.utils";
import { observer } from "mobx-react";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";
import { AccountBillingSubscriptionProductUsageGauge } from "./AccountBillingSubscriptionProductUsageGauge";
import { ApplicationProductPlanEnum } from "enums/application-product-plan.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  config: IAccountBillingSubscriptionProductConfig;
  accountBillingStore: AccountBillingStore;
}

export const AccountBillingSubscriptionProduct: React.FC<IProps> = observer(
  ({ config, accountBillingStore }) => {
    const accountBilling = accountBillingStore.billing;
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();
    const isCustomBilled = useMemo(
      () =>
        isApplicationProductOnSpecificPlan(
          config.productId,
          [ApplicationProductPlanEnum.CUSTOM],
          accountBilling
        ),
      [config, accountBilling]
    );
    const billingProductCurrentMonthActivity = useMemo(
      () =>
        getBillingProductCurrentMonthActivity(config.productId, accountBilling),
      [accountBilling, config]
    );

    const handleBillingActionOnConfirm = useCallback(
      (product: ApplicationProductEnum) => async () => {
        try {
          if (!billingProductCurrentMonthActivity) {
            await accountBillingStore.addProduct(product);
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description: (
                <ApiMessageStack
                  messageStack={"Successfully added subscription."}
                />
              ),
            });
          } else {
            await accountBillingStore.removeProduct(product);
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description: (
                <ApiMessageStack
                  messageStack={"Successfully removed subscription."}
                />
              ),
            });
          }
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
            isClosable: false,
          });
        }
      },
      [accountBillingStore, toast, billingProductCurrentMonthActivity]
    );

    const openProductActivationConfirmation = useCallback(
      (product: ApplicationProductEnum) => {
        setModal(
          <ConfirmActionPrompt
            text={`Are you sure you want to ${
              !billingProductCurrentMonthActivity ? "activate" : "remove"
            } ${config.title}?`}
            colorScheme={!billingProductCurrentMonthActivity ? "teal" : "red"}
            onConfirm={handleBillingActionOnConfirm(product)}
            closePrompt={unSetModal}
          />
        );
      },
      [
        setModal,
        billingProductCurrentMonthActivity,
        config.title,
        unSetModal,
        handleBillingActionOnConfirm,
      ]
    );

    const { sumTotal, sumTotalVat, usageFee } = useMemo(() => {
      return getProductPriceWithTaxes(
        config,
        billingProductCurrentMonthActivity
      );
    }, [billingProductCurrentMonthActivity, config]);

    const handleToggleAddon = useCallback(
      (product: ApplicationProductEnum) => () => {
        if (!accountBilling?.stripePaymentMethodId) {
          setModal(
            withStripe(
              <PaymentMethodPrompt
                header={"Add payment method"}
                closePrompt={unSetModal}
                onSuccess={() => openProductActivationConfirmation(product)}
              />
            )
          );
        } else {
          openProductActivationConfirmation(product);
        }
      },
      [accountBilling, setModal, unSetModal, openProductActivationConfirmation]
    );

    return (
      <Card p={6}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Box
              p={2}
              background={"gray.200"}
              rounded={"md"}
              width={"40px"}
              height={"40px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {<FontAwesomeIcon icon={config.icon} />}
            </Box>
            <Box px={4} fontSize={"xl"} fontWeight={600}>
              {config.title}
            </Box>
          </Box>
          <HStack spacing={2}>
            {config.core && (
              <Tag colorScheme={"blue"}>
                <TagLabel>CORE</TagLabel>
              </Tag>
            )}
            {isCustomBilled && (
              <Tag colorScheme={"blue"}>
                <TagLabel>CUSTOM BILLING</TagLabel>
              </Tag>
            )}
            {!isCustomBilled && !config.core && (
              <Button
                variant={"outline"}
                onClick={handleToggleAddon(config.productId)}
                colorScheme={
                  !!billingProductCurrentMonthActivity ? "red" : "blue"
                }
              >
                {`${
                  !!billingProductCurrentMonthActivity ? "Remove" : "Activate"
                } add-on`}
              </Button>
            )}
          </HStack>
        </Box>
        <Box pt={1}>{config.description}</Box>

        {!isCustomBilled && (
          <>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              p={4}
              pl={0}
            >
              <Box pr={2} fontSize={"xs"} color={"gray.500"} fontWeight={700}>
                CHARGES
              </Box>
              <Divider />
            </Box>
            <VStack
              spacing={4}
              align={"stretch"}
              fontSize={"sm"}
              fontWeight={500}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>Subscription fee</Box>
                <Box>{formatMoney(config.pricing.flatFee, 2, 2)}</Box>
              </Box>
              <AccountBillingSubscriptionProductUsageGauge
                config={config}
                billingProductCurrentMonthActivity={
                  billingProductCurrentMonthActivity
                }
                usageFee={usageFee}
              />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>{`VAT (${config.pricing.vatTaxPercent}%)`}</Box>
                <Box>{formatMoney(sumTotalVat, 2, 2)}</Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>Subtotal</Box>
                <Box>{formatMoney(sumTotal + sumTotalVat, 2, 2)}</Box>
              </Box>
            </VStack>
          </>
        )}
      </Card>
    );
  }
);
