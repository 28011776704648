import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { AuthStatusEnum } from "enums/auth-status.enum";
import { LoginForm } from "./LoginForm";
import { TwoFactorAuthLogin } from "./TwoFactorAuthLogin";

export const Login = observer(() => {
  const {
    authStore: { authStatus },
  } = useAppStore();

  if (authStatus === AuthStatusEnum.LOGGED_OUT) {
    return <LoginForm />;
  }

  if (authStatus === AuthStatusEnum.WAITING_2FA) {
    return <TwoFactorAuthLogin />;
  }

  return null;
});
