import { LeadSourceTag, ReferralTag } from "components";
import { VStack } from "@chakra-ui/react";
import React, { FC } from "react";
import { TLead } from "types/lead.type";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { Dictionary } from "lodash";
import { TPortalData } from "types/portal.type";
import { LeadHostTag } from "../../../tags/LeadHostTag";

interface ILeadSourceCellProps {
  lead: TLead;
  accountLogoUrl: string | null;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  portalsMap: Dictionary<TPortalData>;
}

export const LeadSourceCell: FC<ILeadSourceCellProps> = ({
  lead,
  leadSourcesMapBySource,
  portalsMap,
  accountLogoUrl,
}) => {
  const isOther = lead.host === "other";
  return (
    <VStack
      height={"100%"}
      justifyContent={"space-evenly"}
      alignItems={"flex-start"}
      spacing={0}
    >
      {!!lead.leadReferredByAccount ? (
        <ReferralTag
          name={lead.leadReferredByAccount.name}
          logoUrl={lead.leadReferredByAccount.logoUrl}
        />
      ) : (
        <>
          <LeadSourceTag
            source={lead.source}
            leadSourcesMapBySource={leadSourcesMapBySource}
            portalsMap={portalsMap}
            accountLogoUrl={accountLogoUrl}
          />

          {!isOther && <LeadHostTag host={lead.host} />}
        </>
      )}
    </VStack>
  );
};
