import React, { FC, useCallback } from "react";
import { observer } from "mobx-react";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  LeadResponderWorkflowEditorBlock,
  LeadResponderWorkflowEditorComplexBlock,
} from "../common-blocks";
import {
  LeadResponderWorkflowEditorDelayBlock,
  LeadResponderWorkflowEditorTemplateBlock,
} from "../simple-blocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@chakra-ui/react";
import { WorkflowEditorTemplateBlockTypeEnum } from "enums/workflow-editor-template-block-type.enum";

interface IProps {
  accountStore: UserAccountStore;
  updateWipWorkflow: (workflow: Partial<TLeadResponderWorkflow>) => void;
  wipWorkflow: Partial<TLeadResponderWorkflow>;
  remainderEnabled: keyof Pick<
    TLeadResponderWorkflow,
    "followUpReminderEnabled" | "smsReminderEnabled"
  >;
  delayField: keyof Pick<TLeadResponderWorkflow, "followUpDelay" | "smsDelay">;
  templateField: keyof Pick<
    TLeadResponderWorkflow,
    "followUpEmailTemplateId" | "smsTemplateId"
  >;
  templateType: WorkflowEditorTemplateBlockTypeEnum;
}

export const LeadResponderWorkflowEditorRemainderBlock: FC<IProps> = observer(
  ({
    accountStore,
    updateWipWorkflow,
    wipWorkflow,
    remainderEnabled,
    delayField,
    templateField,
    templateType,
  }) => {
    const handleToggleRemainder = useCallback(
      (enabled: boolean) => {
        updateWipWorkflow({
          ...wipWorkflow,
          [remainderEnabled]: enabled,
        });
      },
      [updateWipWorkflow, wipWorkflow, remainderEnabled]
    );

    const handleApplyDelay = useCallback(
      (delay: number) => {
        updateWipWorkflow({
          ...wipWorkflow,
          [delayField]: delay,
        });
      },
      [updateWipWorkflow, wipWorkflow, delayField]
    );

    const handleApplyTemplate = useCallback(
      (templateId: number | null) => {
        updateWipWorkflow({
          ...wipWorkflow,
          [templateField]: templateId,
        });
      },
      [updateWipWorkflow, wipWorkflow, templateField]
    );

    return (
      <LeadResponderWorkflowEditorComplexBlock
        isEnabled={wipWorkflow[remainderEnabled] || false}
        onToggle={handleToggleRemainder}
      >
        <LeadResponderWorkflowEditorDelayBlock
          onApply={handleApplyDelay}
          delay={wipWorkflow[delayField] || null}
          isDisabled={!wipWorkflow[remainderEnabled]}
        />
        <LeadResponderWorkflowEditorBlock
          children={<Box>Questionnaire not filled</Box>}
          placeholder={"Questionnaire not filled"}
          icon={<FontAwesomeIcon icon={faCircleQuestion} fontSize={20} />}
          isDisabled={!wipWorkflow[remainderEnabled]}
        />
        <LeadResponderWorkflowEditorTemplateBlock
          onApply={handleApplyTemplate}
          accountStore={accountStore}
          templateId={wipWorkflow[templateField] || null}
          templateType={templateType}
          isDisabled={!wipWorkflow[remainderEnabled]}
        />
      </LeadResponderWorkflowEditorComplexBlock>
    );
  }
);
