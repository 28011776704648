import { AccountSmeApi } from "api/account-sme.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TSmeEmployee,
  TSmeEmployeeData,
  TSmeEmployeeRelation,
  TSmeEmployeeSyncData,
} from "types/sme.type";
import { getUserFullName } from "utils/account-user.utils";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeEmployeesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountSmeEmployees: IObservableArray<TSmeEmployee>;
  private accountSmeEmployeeRelations: IObservableArray<TSmeEmployeeRelation>;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountSmeEmployees = observable.array<TSmeEmployee>();
    this.accountSmeEmployeeRelations = observable.array<TSmeEmployeeRelation>();
    this.syncStatusFilter = false;
  }

  get smeEmployeeRelationsMapByLeadProUserAccountId() {
    return keyBy(
      this.accountSmeEmployeeRelations,
      relation => relation.userAccountId
    );
  }

  get smeEmployeeRelationsMapBySmeEmployeeId() {
    return keyBy(
      this.accountSmeEmployeeRelations,
      relation => relation.smeEmployeeId
    );
  }

  get availableSmeEmployees() {
    const usedSmeEmployeeIds = this.smeSyncEmployeeData.map(
      employee => employee.smeEmployeeId
    );
    return this.accountSmeEmployees.filter(
      smeEmployee => !usedSmeEmployeeIds.includes(smeEmployee.id)
    );
  }

  get availableSmeEmployeesMapBySmeEmployeeEmail() {
    return keyBy(this.availableSmeEmployees, employee => employee.email);
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredSmeEmployeeData(): TSmeEmployeeData[] {
    return this.smeSyncEmployeeData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get smeSyncEmployeeData(): TSmeEmployeeData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const accountUsersStore = accountStore.accountUsersStore;
    const leadproAccountUsersMap = accountUsersStore.accountUsersMap;
    const leadproAccountUsers = accountUsersStore.accountUsersArray;
    const smeEmployees = this.accountSmeEmployees;

    const smeEmployeeRelationsMapByLeadProUserAccountId = this
      .smeEmployeeRelationsMapByLeadProUserAccountId;
    const smeEmployeeRelationsMapBySmeEmployeeId = this
      .smeEmployeeRelationsMapBySmeEmployeeId;

    return leadproAccountUsers.map(accountUser => {
      const employeeRelation =
        smeEmployeeRelationsMapByLeadProUserAccountId[
          accountUser.userAccount.id
        ];
      let pairedSmeEmployeeId = employeeRelation?.smeEmployeeId;
      let pairedSmeEmployeeEmail = employeeRelation?.smeEmployeeEmail;
      let pairedSmeEmployeeName = employeeRelation?.smeEmployeeName;
      let syncStatus = !!pairedSmeEmployeeId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      // try suggesting a match
      if (!pairedSmeEmployeeId) {
        const suggestedMatchIndex = smeEmployees.findIndex(smeEmployee => {
          return (
            smeEmployee.email ===
              leadproAccountUsersMap[accountUser.id].email &&
            !smeEmployeeRelationsMapBySmeEmployeeId[smeEmployee.id]
          );
        });
        if (suggestedMatchIndex > -1) {
          pairedSmeEmployeeId = smeEmployees[suggestedMatchIndex].id;
          smeEmployees.splice(suggestedMatchIndex, 1);
          syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
        }
      }

      return {
        userAccountId: accountUser.userAccount.id,
        user: accountUser,
        userFullName: getUserFullName(
          accountUser.firstName,
          accountUser.lastName,
          accountUser.email
        )!,
        smeEmployeeId: pairedSmeEmployeeId,
        smeEmployeeEmail: pairedSmeEmployeeEmail,
        smeEmployeeName: pairedSmeEmployeeName,
        syncStatus: syncStatus,
      };
    });
  }

  get smeEmployeeOptions() {
    const smeEmployees = this.accountSmeEmployees;

    return smeEmployees.map(smeEmployee => {
      return {
        value: smeEmployee.id,
        label: this.getEmployeeFullName(smeEmployee),
        data: this.smeEmployeeRelationsMapBySmeEmployeeId[smeEmployee.id],
      };
    });
  }

  public setAccountSmeEmployees(employees: TSmeEmployee[]) {
    this.accountSmeEmployees.replace(employees);
  }

  public setAccountSmeEmployeeRelations(relations: TSmeEmployeeRelation[]) {
    this.accountSmeEmployeeRelations.replace(relations);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public async fetchSmeEmployees() {
    const data = await accountSmeApi.fetchAccountEmployees(this.accountId);
    this.setAccountSmeEmployees(data);
  }

  public async fetchSmeEmployeeRelations() {
    const data = await accountSmeApi.fetchAccountEmployeeRelations(
      this.accountId
    );
    this.setAccountSmeEmployeeRelations(data);
  }

  public async fetchSmeEmployeesAndRelations() {
    await Promise.all([
      this.fetchSmeEmployees(),
      this.fetchSmeEmployeeRelations(),
    ]);
  }

  public async updateAccountSmeEmployeeRelations(
    syncedPairs: TSmeEmployeeSyncData[]
  ) {
    await accountSmeApi.updateSyncedAccountEmployees(
      this.accountId,
      syncedPairs.map(pair => {
        const employeeRelation = this.accountSmeEmployees.filter(
          employee => employee.id === pair.smeEmployeeId
        )[0];

        return {
          userAccountId: pair.userAccountId,
          smeEmployeeId: pair.smeEmployeeId,
          smeEmployeeName: this.getEmployeeFullName(employeeRelation),
          smeEmployeeEmail: employeeRelation?.email ?? "",
        };
      })
    );
    await this.fetchSmeEmployeesAndRelations();
  }

  private getEmployeeFullName(employee: TSmeEmployee): string {
    return (
      getUserFullName(
        employee?.first_name,
        employee?.last_name,
        employee?.email
      ) ?? ""
    );
  }
}
