import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/pro-regular-svg-icons";
import {
  CustomizeTableColumnsMenuItem,
  ICustomizeTableColumnsMenuItemProps,
} from "./CustomizeTableColumnsMenuItem";
import { LeadsTableColumns } from "enums/leads-table-columns.enum";

const MENU_OPTIONS: ICustomizeTableColumnsMenuItemProps["option"][] = [
  {
    value: LeadsTableColumns.LEAD_SOURCE,
    label: "Source",
  },
  {
    value: LeadsTableColumns.JOURNEY,
    label: "Journey",
  },
  {
    value: LeadsTableColumns.CAMPAIGN,
    label: "Campaign",
  },
  {
    value: LeadsTableColumns.LEAD_TYPE,
    label: "Type",
  },
  {
    value: LeadsTableColumns.ASSIGNED_TO,
    label: "Assignee",
  },
  {
    value: LeadsTableColumns.NEXT_ACTION_DATE,
    label: "Next Action",
  },
];

interface IProps {
  accountStore: UserAccountStore;
}

export const CustomizeTableColumnsMenu: FC<IProps> = observer(
  ({ accountStore }) => {
    return (
      <Menu closeOnSelect={false} autoSelect={false} isLazy={true}>
        <MenuButton
          as={Button}
          variant={"ghost"}
          size={"sm"}
          onClick={() => {}}
          color={"leadpro.600"}
          fontSize={"xs"}
          leftIcon={
            <FontAwesomeIcon icon={faCircleHalfStroke} fontSize="14px" />
          }
        >
          Columns
        </MenuButton>
        <MenuList
          minWidth={"350px"}
          borderRadius={"none"}
          overflowY="auto"
          px={0}
          py={3}
          fontSize={"sm"}
        >
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            pl={5}
            pr={3}
            py={3}
          >
            <Box fontWeight={800} color={"leadpro.700"}>
              Hide selected columns
            </Box>
          </HStack>
          <Box maxHeight={"500px"} overflowY={"auto"}>
            {MENU_OPTIONS.map(option => (
              <CustomizeTableColumnsMenuItem
                accountLeadsStore={accountStore.accountLeadsStore}
                key={option.value}
                option={option}
              />
            ))}
          </Box>
        </MenuList>
      </Menu>
    );
  }
);
