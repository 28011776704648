import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ActionPromptContainer, FormControlV2 } from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD, BAD_NAME } from "constants/validator-messages";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { TPageData } from "types/page.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { PageToolPagesStore } from "../../routes/tools/components/PageTool/PageToolPages.local-store";
import { observer } from "mobx-react";

type TFieldValues = {
  pageName: string;
};

const initialValues: TFieldValues = {
  pageName: "",
};

interface IProps {
  page: TPageData;
  pageToolPagesStore: PageToolPagesStore;
  closePrompt: () => void;
}
export const DeletePagePrompt: React.FC<IProps> = observer(
  ({ page, pageToolPagesStore, closePrompt }) => {
    const toast = useToast();

    const validationSchema = useMemo(() => {
      return Yup.object().shape({
        pageName: Yup.string()
          .matches(new RegExp("^" + page.name + "$"), {
            message: BAD_NAME,
          })
          .required(REQUIRED_FIELD),
      });
    }, [page.name]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(async () => {
      try {
        await pageToolPagesStore.removePage(page.id);

        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Your page has been deleted."} />
          ),
        });

        closePrompt();
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [closePrompt, pageToolPagesStore, page.id, toast]);

    return (
      <ActionPromptContainer
        header={"Confirm deletion"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"pageName"}
                  control={control}
                  type={FormControlsTypeEnum.TEXT}
                  label={"Type the name of the page below to confirm deletion."}
                  autoFocus={true}
                  additionalProps={{
                    placeholder: "Page name",
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"red"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Delete page
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
