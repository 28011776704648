import React, { useCallback, useState } from "react";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import { CenteredMessage } from "components/message/CenteredMessage";
import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import { Box, HStack } from "@chakra-ui/react";
import { LeadResponderEmailFilterSimpleMenuItem } from "./LeadResponderEmailFilterSimpleMenuItem";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import {
  TLeadResponderEmailFilterMenuItemData,
  TLeadResponderEmailFilterMenuItemValue,
} from "types/lead-responder-email.type";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

interface IProps {
  title?: string;
  optionLabel?: string;
  icon: React.ReactNode;
  availableOptions: TLeadResponderEmailFilterMenuItemData[];
  selectedOptions: TLeadResponderEmailFilterMenuItemValue[];
  appliedOptions?: TLeadResponderEmailFilterMenuItemValue[];
  handleFilterItemToggle: (value: any) => void;
  handleOnOpen?: () => Promise<void>;
  filterStore: AcccountLeadResponderEmailsFiltersStore;
}

export const LeadResponderEmailFilterSimpleMenu: React.FC<IProps> = observer(
  ({
    title,
    optionLabel,
    icon,
    availableOptions,
    selectedOptions,
    appliedOptions,
    handleFilterItemToggle,
    handleOnOpen,
    filterStore,
  }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const handleClose = useCallback(() => {
      if (!isEqual(selectedOptions, appliedOptions)) {
        filterStore.setActiveFromPendingFilter();
      }
    }, [filterStore, selectedOptions, appliedOptions]);

    const onMenuOpen = useCallback(async () => {
      if (!handleOnOpen) {
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        return;
      }
      try {
        await handleOnOpen();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    }, [handleOnOpen, setLoadingStatus]);

    const onMenuItemClick = useCallback(
      (value: TLeadResponderEmailFilterMenuItemValue) => () => {
        handleFilterItemToggle(value);
      },
      [handleFilterItemToggle]
    );

    return (
      <DropdownFilterMenuShell
        title={title}
        icon={icon}
        badgeCount={selectedOptions.length}
        onOpen={onMenuOpen}
        onClose={handleClose}
        loadingStatus={loadingStatus}
      >
        <>
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            pl={5}
            pr={3}
            py={3}
          >
            <Box fontWeight={800} color={"leadpro.700"}>
              {optionLabel}
            </Box>
          </HStack>
          {!availableOptions.length && (
            <CenteredMessage
              height={"100px"}
              message={<DropdownNoFilterMessage />}
            />
          )}
          {!!availableOptions.length && (
            <Box maxHeight={"500px"} overflowY={"auto"}>
              {availableOptions.map((availableOption, index) => (
                <LeadResponderEmailFilterSimpleMenuItem
                  key={index}
                  availableOption={availableOption}
                  onMenuItemClick={onMenuItemClick}
                  selectedOptions={selectedOptions}
                />
              ))}
            </Box>
          )}
        </>
      </DropdownFilterMenuShell>
    );
  }
);
