import { BaseApi } from "./base.api";
import { TExportRequest } from "../types/export-request.type";

export class AccountExportApi extends BaseApi {
  async fetchAutocallerExportRequests(
    accountId: number
  ): Promise<TExportRequest[]> {
    return this.get(`/accounts/${accountId}/export/iovox-stats-csv`);
  }

  async createAutocallerExportRequest(
    accountId: number,
    period: string
  ): Promise<TExportRequest> {
    return this.post(`/accounts/${accountId}/export/iovox-stats-csv`, {
      period,
    });
  }

  async fetchLeadsExportRequests(accountId: number): Promise<TExportRequest[]> {
    return this.get(`/accounts/${accountId}/export/leads-csv`);
  }

  async createLeadsExportRequest(
    accountId: number,
    period: string
  ): Promise<TExportRequest> {
    return this.post(`/accounts/${accountId}/export/leads-csv`, {
      period,
    });
  }
}
