import { TLeadAttribution } from "types/lead-attribution.type";
import { LeadAttributionStatusEnum } from "enums/account-lead-attribution.enum";
import { NoBusinessIcon, UnreadIcon } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCircleQuestion,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { TLeadPerson, TLeadWithDetails } from "types/lead.type";
import { LeadAttributionPropertyContent } from "./LeadAttributionProperty/LeadAttributionPropertyContent";
import { ReactNode } from "react";
import { formatMoney } from "utils/formating.utils";
import { formatDate } from "utils/date.utils";
import { DATE_TIME_DAY_DECORATOR_FORMAT } from "constants/date";
import {
  faGlasses,
  faSignHanging,
  faWallet,
} from "@fortawesome/sharp-regular-svg-icons";
import { faHouseChimney } from "@fortawesome/sharp-light-svg-icons";
import { getUserFullName } from "utils/account-user.utils";

export const propertyAttributionKeys: (keyof TLeadAttribution)[] = [
  "instructedDate",
  "negotiator",
  "offer",
  "address",
];

const STATUS_ICON_SIZE = 12;
const PROPERTY_ICON_SIZE = 30;

export type TLeadAttributionPropertyOption = {
  label: string;
  value: string | number;
};
export type TLeadAttributionConfig = {
  id: number;
  icon: ReactNode;
  content: ReactNode;
  title: string;
  instructedDate: string;
  createdAt: string;
  description: string;
};
export const transformAttributionToConfig = (
  leadAttributions: TLeadAttribution[],
  lead: TLeadWithDetails
) => {
  const person = lead.person;
  return leadAttributions.map(item => {
    return {
      id: item.id,
      icon: getIconForAttributionEventType(item.eventType),
      description: getDescriptionForAttribution(item, person),
      title: attributionEventTitleMap[item.eventType],
      content: getContentForLeadAttributionType(item),
      instructedDate: !item.instructedDateUnknown ? item.instructedDate : "N/A",
      createdAt: item.createdAt,
    };
  });
};

const getContentForLeadAttributionType = (attribution: TLeadAttribution) => {
  switch (attribution.eventType) {
    case LeadAttributionStatusEnum.VALUATION_BOOKED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon
              icon={faCalendarCheck}
              fontSize={PROPERTY_ICON_SIZE}
            />
          }
        />
      );
    case LeadAttributionStatusEnum.OFFER_MADE:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon icon={faWallet} fontSize={PROPERTY_ICON_SIZE} />
          }
        />
      );
    case LeadAttributionStatusEnum.OFFER_RECEIVED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon icon={faWallet} fontSize={PROPERTY_ICON_SIZE} />
          }
        />
      );
    case LeadAttributionStatusEnum.VIEWING_BOOKED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon icon={faGlasses} fontSize={PROPERTY_ICON_SIZE} />
          }
        />
      );
    case LeadAttributionStatusEnum.SELLING_INSTRUCTED_TO_MARKET:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon
              icon={faSignHanging}
              fontSize={PROPERTY_ICON_SIZE}
            />
          }
        />
      );

    case LeadAttributionStatusEnum.PROPERTY_EXCHANGED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon
              icon={faHouseChimney}
              fontSize={PROPERTY_ICON_SIZE}
            />
          }
        />
      );
    case LeadAttributionStatusEnum.PROPERTY_COMPLETED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon
              icon={faHouseChimney}
              fontSize={PROPERTY_ICON_SIZE}
            />
          }
        />
      );
    case LeadAttributionStatusEnum.OFFER_ACCEPTED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={
            <FontAwesomeIcon icon={faWallet} fontSize={PROPERTY_ICON_SIZE} />
          }
        />
      );
    case LeadAttributionStatusEnum.TENANCY_ACCEPTED:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={<FontAwesomeIcon icon={faUser} fontSize={PROPERTY_ICON_SIZE} />}
        />
      );
    case LeadAttributionStatusEnum.TENANCY_REQUESTED_BY_CONTACT:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={<FontAwesomeIcon icon={faUser} fontSize={PROPERTY_ICON_SIZE} />}
        />
      );
    case LeadAttributionStatusEnum.TENANCY_REQUESTED_TO_CONTACT:
      return (
        <LeadAttributionPropertyContent
          attribution={attribution}
          icon={<FontAwesomeIcon icon={faUser} fontSize={PROPERTY_ICON_SIZE} />}
        />
      );

    default:
      return null;
  }
};

const getDescriptionForAttribution = (
  item: TLeadAttribution,
  person: TLeadPerson
) => {
  const { fullName, firstName, lastName, title } = person;

  let name = fullName || getUserFullName(firstName, lastName) || "";
  if (title) {
    name = `${person.title} ${name}`;
  }
  switch (item.eventType) {
    case LeadAttributionStatusEnum.LEAD_CREATED:
      return `${name}'s lead was created`;
    case LeadAttributionStatusEnum.LEAD_SYNCED:
      return `${name}'s lead has been synced to CRM`;
    case LeadAttributionStatusEnum.OFFER_RECEIVED:
      return `${name} has received an offer`;
    case LeadAttributionStatusEnum.OFFER_ACCEPTED:
      return `${name}'s offer has been accepted`;
    case LeadAttributionStatusEnum.OFFER_MADE:
      return `${name} has made an offer`;
    case LeadAttributionStatusEnum.PROPERTY_COMPLETED:
      return `${name} has purchased a new home`;
    case LeadAttributionStatusEnum.PROPERTY_EXCHANGED:
      return `${name}'s property has been exchanged `;
    case LeadAttributionStatusEnum.VALUATION_BOOKED:
      return `${name} has booked a valuation`;
    case LeadAttributionStatusEnum.VIEWING_BOOKED:
      return `${name} has booked a viewing`;
    case LeadAttributionStatusEnum.LETTING_INSTRUCTED_TO_MARKET:
      return `${name}'s property has been instructed to market`;
    case LeadAttributionStatusEnum.TENANCY_REQUESTED_BY_CONTACT:
      return `${name} has submitted a request for tenancy`;
    case LeadAttributionStatusEnum.TENANCY_REQUESTED_TO_CONTACT:
      return `${name} has received a tenancy request`;
    case LeadAttributionStatusEnum.SELLING_INSTRUCTED_TO_MARKET:
      return `${name}'s property has been instructed to market`;
    case LeadAttributionStatusEnum.TENANCY_ACCEPTED:
      return `${name} has accepted a tenancy request`;
    case LeadAttributionStatusEnum.UNDEFINED_STATUS:
      return "An unknown event has been recorded from CRM data";
    default:
      return "An unknown event has been recorded from CRM data";
  }
};
const getIconForAttributionEventType = (
  attributionEvent: LeadAttributionStatusEnum
) => {
  switch (attributionEvent) {
    case LeadAttributionStatusEnum.LEAD_CREATED:
    case LeadAttributionStatusEnum.LEAD_SYNCED:
      return <UnreadIcon fontSize={STATUS_ICON_SIZE} color={"green.500"} />;
    case LeadAttributionStatusEnum.OFFER_RECEIVED:
    case LeadAttributionStatusEnum.OFFER_ACCEPTED:
    case LeadAttributionStatusEnum.OFFER_MADE:
    case LeadAttributionStatusEnum.PROPERTY_COMPLETED:
    case LeadAttributionStatusEnum.PROPERTY_EXCHANGED:
    case LeadAttributionStatusEnum.VALUATION_BOOKED:
    case LeadAttributionStatusEnum.VIEWING_BOOKED:
    case LeadAttributionStatusEnum.LETTING_INSTRUCTED_TO_MARKET:
    case LeadAttributionStatusEnum.TENANCY_REQUESTED_BY_CONTACT:
    case LeadAttributionStatusEnum.SELLING_INSTRUCTED_TO_MARKET:
    case LeadAttributionStatusEnum.TENANCY_ACCEPTED:
      return (
        <NoBusinessIcon color={"leadpro.400"} fontSize={STATUS_ICON_SIZE} />
      );

    case LeadAttributionStatusEnum.UNDEFINED_STATUS:
    default:
      return (
        <FontAwesomeIcon
          icon={faCircleQuestion}
          fontSize={STATUS_ICON_SIZE}
          color={"gray.500"}
        />
      );
  }
};

const attributionEventTitleMap: Record<LeadAttributionStatusEnum, string> = {
  [LeadAttributionStatusEnum.VIEWING_BOOKED]: "Viewing Booked",
  [LeadAttributionStatusEnum.VALUATION_BOOKED]: "Valuation Booked",
  [LeadAttributionStatusEnum.SELLING_INSTRUCTED_TO_MARKET]:
    "Property Instructed To Market",
  [LeadAttributionStatusEnum.LETTING_INSTRUCTED_TO_MARKET]:
    "Property Instructed To Market",
  [LeadAttributionStatusEnum.OFFER_MADE]: "Offer Made",
  [LeadAttributionStatusEnum.OFFER_RECEIVED]: "Offer Received",
  [LeadAttributionStatusEnum.OFFER_ACCEPTED]: "Offer Accepted",
  [LeadAttributionStatusEnum.TENANCY_REQUESTED_BY_CONTACT]:
    "Tenancy Request Made",
  [LeadAttributionStatusEnum.TENANCY_REQUESTED_TO_CONTACT]:
    "Tenancy Request Received",
  [LeadAttributionStatusEnum.TENANCY_ACCEPTED]: "Tenancy Accepted",
  [LeadAttributionStatusEnum.PROPERTY_EXCHANGED]: "Property Exchanged",
  [LeadAttributionStatusEnum.PROPERTY_COMPLETED]: "Property Completed",
  [LeadAttributionStatusEnum.LEAD_CREATED]: "Lead Created",
  [LeadAttributionStatusEnum.LEAD_SYNCED]: "Lead Sync'd",
  [LeadAttributionStatusEnum.UNDEFINED_STATUS]: "Unknown Status",
};

const transformOptionFunctions: Partial<Record<
  keyof TLeadAttribution,
  (value: any) => string
>> = {
  instructedDate: date => formatDate(date, DATE_TIME_DAY_DECORATOR_FORMAT),
  offer: formatMoney,
};

const optionLabelMap: Partial<Record<keyof TLeadAttribution, string>> = {
  instructedDate: "time",
};

export const getPropertyOptionsFromAttribution = (
  attribution: TLeadAttribution
): TLeadAttributionPropertyOption[] => {
  const entries = Object.entries(attribution) as [
    keyof TLeadAttribution,
    string | number
  ][];

  const result = entries.reduce(
    (result: TLeadAttributionPropertyOption[], [key, value]) => {
      if (propertyAttributionKeys.includes(key) && value) {
        const transform = transformOptionFunctions[key];
        result.push({
          label: optionLabelMap[key] || key,
          value: transform ? transform(value) : value,
        });
      }
      return result;
    },
    []
  );
  return result.sort((a, b) => a.label.localeCompare(b.label));
};
