import React from "react";
import { Box, Button, VStack, Image } from "@chakra-ui/react";
import { Card } from "components/stats/Card";
import { NavLink } from "react-router-dom";

interface IProps {
  title: string;
  pitch: string;
  icon: string;
  to: string;
}

export const EmptyLeadProductPitch: React.FC<IProps> = ({
  title,
  pitch,
  icon,
  to,
}) => {
  return (
    <Card p={6} width={"350px"}>
      <VStack spacing={2} align={"left"}>
        <Image src={icon} width={"48px"} />
        <Box fontSize={"lg"} fontWeight={500}>
          {title}
        </Box>
        <Box color={"gray.400"}>{pitch}</Box>
        <NavLink exact={true} to={to}>
          <Button size={"md"} width={"full"} colorScheme={"teal"}>
            Get started
          </Button>
        </NavLink>
      </VStack>
    </Card>
  );
};
