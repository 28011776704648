import { TSelectOption } from "types/select-input.type";
import {
  Expression,
  ExpressionOperators,
  ExpressionOperatorTypes,
  FormActionSendEmailRoutingTypes,
  FormActionType,
  FormFieldType,
  FormSchema,
  Group,
  GroupOperators,
  LeadTypes,
  NodeTypes,
} from "@leadpro/forms";
import { LEAD_TYPE_OPTIONS } from "./lead-type-options";
import {
  TFormToolPageActionTemplate,
  TFormToolPageQuestionTemplate,
} from "types/form-tool-page.type";

export const FormToolPageSendEmailActionRoutingTypeOptions: TSelectOption<
  FormActionSendEmailRoutingTypes
>[] = [
  {
    label: "Office",
    value: FormActionSendEmailRoutingTypes.OFFICE,
  },
  {
    label: "Direct",
    value: FormActionSendEmailRoutingTypes.DIRECT,
  },
];

export const FormToolPageSendEmailActionOfficeLeadTypeOptions = LEAD_TYPE_OPTIONS.filter(
  option =>
    [
      LeadTypes.Let,
      LeadTypes.Sale,
      LeadTypes.Landlord,
      LeadTypes.Vendor,
    ].includes(option.value)
);

export const FormToolPageQuestionTypeLabels: {
  [key in FormFieldType]: string;
} = {
  [FormFieldType.TEXT]: "Text input question",
  [FormFieldType.TEXT_AREA]: "Multi-line text input question",
  [FormFieldType.EMAIL]: "Email input question",
  [FormFieldType.POSTCODE]: "Postcode input question",
  [FormFieldType.RADIO]: "Choice question",
  [FormFieldType.PHONE]: "Phone input question",
};

export const FormToolPageQuestionTemplateOptions: TFormToolPageQuestionTemplate[] = [
  {
    type: FormFieldType.TEXT,
    label: FormToolPageQuestionTypeLabels[FormFieldType.TEXT],
  },
  {
    type: FormFieldType.TEXT_AREA,
    label: FormToolPageQuestionTypeLabels[FormFieldType.TEXT_AREA],
  },
  {
    type: FormFieldType.RADIO,
    label: FormToolPageQuestionTypeLabels[FormFieldType.RADIO],
  },
  {
    type: FormFieldType.EMAIL,
    label: FormToolPageQuestionTypeLabels[FormFieldType.EMAIL],
  },
  {
    type: FormFieldType.PHONE,
    label: FormToolPageQuestionTypeLabels[FormFieldType.PHONE],
  },
  {
    type: FormFieldType.POSTCODE,
    label: FormToolPageQuestionTypeLabels[FormFieldType.POSTCODE],
  },
];

export const DefaultFormToolPageQuestionTemplates: {
  [key in FormFieldType]: FormSchema["fields"]["key"];
} = {
  [FormFieldType.TEXT]: {
    type: FormFieldType.TEXT,
    label: "Text input question title",
    placeholder: "Type your answer here",
  },
  [FormFieldType.TEXT_AREA]: {
    type: FormFieldType.TEXT_AREA,
    label: "Multi-line text input question title",
    placeholder: "Type your answer here",
  },
  [FormFieldType.EMAIL]: {
    type: FormFieldType.EMAIL,
    label: "Email input question title",
    placeholder: "Type your answer here",
  },
  [FormFieldType.POSTCODE]: {
    type: FormFieldType.POSTCODE,
    label: "Postcode input question title",
    placeholder: "Type your answer here",
    countryCode: "GB",
  },
  [FormFieldType.PHONE]: {
    type: FormFieldType.PHONE,
    label: "Phone input question title",
    placeholder: "Type your answer here",
    countryCode: "GB",
  },
  [FormFieldType.RADIO]: {
    type: FormFieldType.RADIO,
    label: "Choice input question title",
    placeholder: "Please select a response",
    options: [
      {
        value: "option 1",
        label: "option 1",
      },
      {
        value: "option 2",
        label: "option 2",
      },
    ],
  },
};

export const FormToolPageActionConditionGroupOperatorOptions: TSelectOption<
  GroupOperators
>[] = [
  {
    value: GroupOperators.AND,
    label: "all",
  },
  {
    value: GroupOperators.OR,
    label: "any",
  },
];

export const FormToolPageActionConditionExpressionOperatorOptions: TSelectOption<
  ExpressionOperators
>[] = [
  {
    value: ExpressionOperators.DoesNotMatchRegex,
    label: "does not match regex",
  },
  {
    value: ExpressionOperators.MatchesRegex,
    label: "matches regex",
  },
  {
    value: ExpressionOperators.Equals,
    label: "equals",
  },
  {
    value: ExpressionOperators.NotEquals,
    label: "does not equals",
  },
  {
    value: ExpressionOperators.In,
    label: "in",
  },
  {
    value: ExpressionOperators.NotIn,
    label: "not in",
  },
  {
    value: ExpressionOperators.IsEmpty,
    label: "is empty",
  },
  {
    value: ExpressionOperators.IsNotEmpty,
    label: "is not empty",
  },
];

export const NEW_DEFAULT_CONDITION_GROUP: Group = {
  type: NodeTypes.Group,
  operator: GroupOperators.AND,
  children: [],
};

export const NEW_DEFAULT_CONDITION_EXPRESSION: Expression = {
  type: NodeTypes.Expression,
  field: "",
  operator: ExpressionOperators.IsEmpty,
  value: undefined,
};

export const DEFAULT_VALUE_MAP_PER_OPERATOR_TYPE: Record<
  ExpressionOperatorTypes,
  unknown
> = {
  [ExpressionOperatorTypes.SingleValue]: "",
  [ExpressionOperatorTypes.MultiValue]: [],
  [ExpressionOperatorTypes.Regex]: "",
  [ExpressionOperatorTypes.NoValue]: undefined,
};

export const FormToolPageActionTypeLabels: {
  [key in FormActionType]: string;
} = {
  [FormActionType.CREATE_LEAD]: "Create lead",
  [FormActionType.SEND_EMAIL]: "Send email",
};

export const FormToolPageActionTemplateOptions: TFormToolPageActionTemplate<
  FormActionType
>[] = [
  {
    type: FormActionType.CREATE_LEAD,
    label: FormToolPageActionTypeLabels[FormActionType.CREATE_LEAD],
  },
  {
    type: FormActionType.SEND_EMAIL,
    label: FormToolPageActionTypeLabels[FormActionType.SEND_EMAIL],
  },
];

export const DEFAULT_FORM_FIELD_VALUES_BY_INPUT_TYPE: {
  [key in FormFieldType]: unknown;
} = {
  [FormFieldType.TEXT]: "",
  [FormFieldType.EMAIL]: "",
  [FormFieldType.PHONE]: "",
  [FormFieldType.POSTCODE]: "",
  [FormFieldType.TEXT_AREA]: "",
  [FormFieldType.RADIO]: "",
};
