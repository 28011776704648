import { Box, Divider } from "@chakra-ui/react";
import { DefaultSelectInputOption } from "components";
import {
  OfficeCategoryLabel,
  OFFICE_CATEGORY_ORDER,
} from "constants/office-select-options";
import { OfficeCategory } from "enums/office-type.enum";
import { groupBy } from "lodash";
import { FC, useMemo } from "react";
import { getOfficeCategoryFromPrefixId } from "utils/office-group.utils";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IOfficeAndGroupSelectionOptionsRenderer {
  optionProps: IGetOptionPropsData<string>[];
}

export const OfficeAndGroupSelectionOptionsRenderer: FC<IOfficeAndGroupSelectionOptionsRenderer> = ({
  optionProps,
}) => {
  const grouped = useMemo(() => {
    return groupBy(optionProps, optionProp => {
      return getOfficeCategoryFromPrefixId(optionProp.option.value);
    });
  }, [optionProps]);

  return (
    <>
      {OFFICE_CATEGORY_ORDER.map((officeCategory: OfficeCategory) => {
        return (
          <Box key={officeCategory}>
            <Box
              key={officeCategory}
              px={2}
              py={1}
              color={"gray.500"}
              fontSize={"xs"}
              textTransform={"uppercase"}
            >
              {!!grouped[officeCategory] && OfficeCategoryLabel[officeCategory]}
            </Box>
            {!!grouped[officeCategory] &&
              grouped[officeCategory].map((optionProps, key) => {
                return (
                  <DefaultSelectInputOption
                    key={key}
                    optionProps={optionProps}
                  />
                );
              })}
            <Divider />
          </Box>
        );
      })}
    </>
  );
};
