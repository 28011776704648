import { ApiMessageStack, FormControlV2, FormControlMeta } from "components";
import {
  HStack,
  Portal,
  useToast,
  SimpleGrid,
  Divider,
  VStack,
} from "@chakra-ui/react";
import React, { MutableRefObject, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FormFooter } from "components/form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  websiteProviderId: number | null;
  useCustomWebsiteProvider: boolean;
  portalIds: number[];
  useCustomPortals: boolean;
};

const validationSchema = Yup.object().shape({
  franchiseId: Yup.number().nullable(true),
  websiteProviderId: Yup.number().nullable(true),
  useCustomWebsiteProvider: Yup.boolean(),
  portalIds: Yup.array().of(Yup.number()),
  useCustomPortals: Yup.boolean(),
});

interface IProps {
  officeStore: AccountOfficeStore;
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const OfficeIntegrations: React.FC<IProps> = observer(
  ({ officeStore, accountStore, containerRef }) => {
    const toast = useToast();
    const { uiStore } = useAppStore();
    const accountData = accountStore.account;
    const officeData = officeStore.office;
    const websiteProviderOptions = uiStore.websiteProviderOptionsArray;
    const portalOptions = accountStore.portalsAsSelectOptions;

    const initialValues: TFieldValues = useMemo(
      () => ({
        websiteProviderId:
          officeData.websiteProviderId || accountData.websiteProviderId,
        useCustomWebsiteProvider: officeData.websiteProviderId !== null,
        portalIds: officeData.useOfficePortals
          ? officeData.portalIds
          : accountData.portalIds,
        useCustomPortals: officeData.useOfficePortals,
      }),
      [officeData, accountData]
    );

    const {
      handleSubmit,
      control,
      reset,
      watch,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = async ({
      websiteProviderId,
      useCustomWebsiteProvider,
      portalIds,
      useCustomPortals,
    }: TFieldValues) => {
      try {
        await officeStore.update({
          websiteProviderId: useCustomWebsiteProvider
            ? websiteProviderId
            : null,
          portalIds: portalIds,
          useOfficePortals: useCustomPortals,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description:
            "Your office integration settings have been successfully updated.",
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    const watchUseCustomPortals = watch("useCustomPortals");
    const watchUseCustomWebsiteProvider = watch("useCustomWebsiteProvider");

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    useEffect(() => {
      if (!websiteProviderOptions.length) {
        uiStore.fetchWebsiteProviderOptions();
      }
    }, [websiteProviderOptions.length, uiStore]);

    useEffect(() => {
      if (!portalOptions.length) {
        accountStore.fetchPortals();
      }
    }, [portalOptions.length, uiStore, accountStore]);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={10} align={"stretch"} divider={<Divider />}>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta label="Website provider" />
            <VStack spacing={6} align={"stretch"}>
              <HStack spacing={2} alignItems={"flex-start"} width={"100%"}>
                <FormControlV2<TFieldValues>
                  name={"useCustomWebsiteProvider"}
                  control={control}
                  type={FormControlsTypeEnum.SWITCH}
                />
                <FormControlMeta
                  label={"Use a different website provider for this office"}
                  description={
                    "This will override the default website provider."
                  }
                />
              </HStack>
              {!!watchUseCustomWebsiteProvider && (
                <FormControlV2<TFieldValues>
                  name={"websiteProviderId"}
                  control={control}
                  type={FormControlsTypeEnum.SINGLE_SELECT}
                  isDisabled={!watchUseCustomWebsiteProvider}
                  additionalProps={{
                    options: websiteProviderOptions,
                    placeholder: "Select website provider",
                    clearable: true,
                  }}
                />
              )}
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta label="Portals" />
            <VStack spacing={6} align={"stretch"}>
              <HStack spacing={2} alignItems={"flex-start"} width={"100%"}>
                <FormControlV2<TFieldValues>
                  name={"useCustomPortals"}
                  control={control}
                  type={FormControlsTypeEnum.SWITCH}
                />
                <FormControlMeta
                  label={"Use custom portals"}
                  description={"This will override the default portals."}
                />
              </HStack>
              {!!watchUseCustomPortals && (
                <FormControlV2<TFieldValues>
                  name={"portalIds"}
                  control={control}
                  type={FormControlsTypeEnum.MULTI_SELECT}
                  isDisabled={!watchUseCustomPortals}
                  additionalProps={{
                    options: portalOptions,
                    placeholder: "Office portals",
                    clearable: true,
                  }}
                />
              )}
            </VStack>
          </SimpleGrid>
        </VStack>
        {isDirty && (
          <Portal containerRef={containerRef}>
            <FormFooter
              isSubmitting={isSubmitting}
              submitForm={handleSubmit(onSubmit)}
              resetForm={reset}
            />
          </Portal>
        )}
      </form>
    );
  }
);
