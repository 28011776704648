import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, HStack } from "@chakra-ui/react";
import { LeadResponderEmailFilterSimpleMenu } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadResponderEmailSimpleFilterTypes } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenuItem";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import { TSelectOption } from "types/select-input.type";
import { TPortalData } from "types/portal.type";

interface IProps {
  emailFiltersStore: AcccountLeadResponderEmailsFiltersStore;
  portalOptions: TSelectOption<number, TPortalData>[];
}

export const FiltersLeadResponderEmailsPortalHeader: FC<IProps> = observer(
  ({ emailFiltersStore, portalOptions }) => {
    const { togglePendingFilterByType, pendingFilter } = emailFiltersStore;

    const selectedOptions = useMemo(() => {
      return portalOptions.reduce<number[]>((acc, option) => {
        if (option.data && pendingFilter.portals.includes(option.data.name)) {
          acc.push(option.data.id);
        }
        return acc;
      }, []);
    }, [pendingFilter, portalOptions]);

    return (
      <HStack spacing={2}>
        <Box
          fontSize={"sm"}
          color={"gray.500"}
          fontWeight={500}
          py={2}
          w={"100%"}
        >
          Portal
        </Box>
        <LeadResponderEmailFilterSimpleMenu
          optionLabel={"Portal"}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          availableOptions={portalOptions}
          selectedOptions={selectedOptions}
          handleFilterItemToggle={(value: number) =>
            togglePendingFilterByType(
              value,
              LeadResponderEmailSimpleFilterTypes.PORTAL
            )
          }
          filterStore={emailFiltersStore}
        />
      </HStack>
    );
  }
);
