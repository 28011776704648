import { Box } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PropertiesTable } from "./PropertiesTable/PropertiesTable";

interface IProps {
  accountStore: UserAccountStore;
}

export const PropertiesContent: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        const promises = [];
        promises.push(
          accountStore.fetchPortals(),
          accountLeadSourcesStore.fetchAccountLeadSources()
        );

        await Promise.all(promises);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [accountLeadSourcesStore, accountStore]);

  return (
    <Box flexDirection="column" display="flex" flex={1} overflow="hidden">
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          overflow={"hidden"}
          height={"100%"}
          position={"relative"}
        >
          <Box display={"flex"} flexGrow={1} overflow={"hidden"}>
            <PropertiesTable accountStore={accountStore} />
          </Box>
        </Box>
      )}
    </Box>
  );
});
