import React, { ReactNode } from "react";
import { Box, ChakraStyledOptions } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
  styles?: ChakraStyledOptions;
}

export const LeadBlockBody: React.FC<IProps> = props => {
  const { children, styles } = props;

  return (
    <Box p={5} bg={"white"} {...styles}>
      {children}
    </Box>
  );
};
