import React from "react";
import { TOpenViewContactData } from "types/openview.type";
import OpenViewRelatedContactsOption from "../custom-options/OpenViewRelatedContactsOption";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IOpenViewRelatedContactOptionsRendererProps {
  optionProps: IGetOptionPropsData<string, TOpenViewContactData>[];
}

export const OpenViewRelatedContactsRenderer: React.FC<IOpenViewRelatedContactOptionsRendererProps> = ({
  optionProps,
}) => {
  return (
    <>
      {optionProps.map(optionProp => {
        return (
          <OpenViewRelatedContactsOption
            optionProp={optionProp}
            hoverEffect
            key={optionProp.option.value}
          />
        );
      })}
    </>
  );
};
