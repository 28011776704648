import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { StandardIconButton } from "components";
import React, { FC, useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

interface IProps {
  onDelete: () => Promise<void>;
  isDisabled: boolean;
}

export const EmailTemplateConfirmDeletePopover: FC<IProps> = ({
  onDelete,
  isDisabled,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef(null);

  const handleDelete = useCallback(async () => {
    if (!isDisabled) {
      setIsSubmitting(true);
      await onDelete();
    }
  }, [onDelete, isDisabled]);

  return (
    <Popover closeOnBlur={false} initialFocusRef={inputRef} isLazy={true}>
      <PopoverTrigger>
        <StandardIconButton
          disabled={isDisabled}
          aria-label={"delete-template"}
          icon={<FontAwesomeIcon icon={faTrashCan} />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader fontSize={"md"} fontWeight={500}>
          Confirm deletion
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <Box fontSize={"sm"} fontWeight={"normal"}>
            Are you sure you want to remove this email template?
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup
            size="sm"
            spacing={2}
            justifyContent={"flex-end"}
            width={"100%"}
          >
            <Button
              colorScheme={"red"}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              onClick={handleDelete}
            >
              Delete template
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
