import { Box, Button, HStack, Tag } from "@chakra-ui/react";
import { ILeadConnectedLeadsBlockDefinition } from "../LeadConnectedLeadsBlock";
import { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react";

export const CONNECTED_LEAD_LIMIT = 20;

interface IConnectedLeadsFooterProps {
  leadBlockDefinition: ILeadConnectedLeadsBlockDefinition;
}

export const ConnectedLeadsFooter: FC<IConnectedLeadsFooterProps> = observer(
  ({ leadBlockDefinition }) => {
    const {
      accountStore: {
        accountLeadsStore: {
          leadsFilterStore: { setSearchQuery, searchQuery },
          selectLead,
        },
      },
      leadStore,
    } = leadBlockDefinition;

    const isNewSearchQuery = useMemo(() => {
      return searchQuery !== leadStore.lead.person?.email;
    }, [searchQuery, leadStore]);

    const handleSearchLeadsByPersonEmail = useCallback(
      () =>
        isNewSearchQuery
          ? setSearchQuery(leadStore.lead.person?.email)
          : selectLead(null),
      [setSearchQuery, leadStore, selectLead, isNewSearchQuery]
    );

    return (
      <Box display={"flex"} alignItems={"center"} px={4} py={4} pl={2}>
        <HStack>
          <Tag fontSize={"sm"} color="gray.800" colorScheme={"orange"}>
            Limited to {CONNECTED_LEAD_LIMIT} most recent leads.
          </Tag>
          <Button
            onClick={handleSearchLeadsByPersonEmail}
            variant={"link"}
            colorScheme={"blue"}
          >
            Load all leads from this person
          </Button>
        </HStack>
      </Box>
    );
  }
);
