import { observer } from "mobx-react";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
} from "@chakra-ui/react";
import { QuestionnaireToolPageWIPFormStore } from "../stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
  Header: FC<{
    isExpanded: boolean;
    leadType: QuestionnaireLeadTypes;
    questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
    questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
  }>;
  Content: FC<{
    isExpanded: boolean;
    leadType: QuestionnaireLeadTypes;
    questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
    questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
  }>;
}
export const QuestionnaireToolPageSettingsSelectedListSection: FC<IProps> = observer(
  ({ leadType, questionnaireToolPageSettingsEditorStore, Header, Content }) => {
    const questionnaireToolPageWIPFormStore =
      questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap[
        leadType
      ];

    return (
      <AccordionItem
        marginBottom={5}
        overflow={"hidden"}
        border={"1px solid #E4E7F2"}
        borderRadius={"md"}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton p={4} _expanded={{ bg: "gray.50" }}>
              <Flex
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Header
                  isExpanded={isExpanded}
                  leadType={leadType}
                  questionnaireToolPageSettingsEditorStore={
                    questionnaireToolPageSettingsEditorStore
                  }
                  questionnaireToolPageWIPFormStore={
                    questionnaireToolPageWIPFormStore
                  }
                />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel p={4}>
              <Content
                isExpanded={isExpanded}
                leadType={leadType}
                questionnaireToolPageSettingsEditorStore={
                  questionnaireToolPageSettingsEditorStore
                }
                questionnaireToolPageWIPFormStore={
                  questionnaireToolPageWIPFormStore
                }
              />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
