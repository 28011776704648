import React from "react";
import { AspectRatio, Box } from "@chakra-ui/react";

const DEFAULT_ASPECT_RATIO = 4 / 3;
const VIDEO_SRC =
  "https://videos.ctfassets.net/zd8ea9012lh1/4UW8xxB1PMjN4P1dLD7oMj/8c470356bcc01ced4ef438cecd2af2d2/Untitled.mp4";

export const InstantValuationToolIntroVideo = () => {
  return (
    <AspectRatio ratio={DEFAULT_ASPECT_RATIO} bg="white" width={"500px"}>
      <Box
        as="video"
        src={VIDEO_SRC}
        autoPlay
        muted
        loop
        rounded="lg"
        shadow="lg"
      />
    </AspectRatio>
  );
};
