import { TUserAccountData } from "types/user-account.type";
import { getDefaultAccount } from "./local-storage.utils";

export const getDefaultAccountIdFromAccounts = (
  accounts: TUserAccountData[]
) => {
  const defaultAccount = getDefaultAccount();
  if (defaultAccount && accounts.find(a => a.id === defaultAccount)) {
    return defaultAccount;
  }

  return accounts[0]?.id;
};
