import { FormSchema, validateFormSchema } from "@leadpro/forms";
import { ResolverResult } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";

export function validateFormFieldsFromSchemaExcludingLogic<Values>(
  schema: FormSchema,
  values: Values
): ResolverResult<Values> {
  const { errors } = validateFormSchema({
    ...schema,
    logic: undefined,
  });
  if (!errors)
    return {
      values,
      errors: {},
    };

  const result: FieldErrors<Values> = {};
  errors.forEach(error => {
    const fieldKey = error.path[error.path.length - 1] as keyof Values;
    result[fieldKey] = {
      message: error.message.replace(error.path.join("."), fieldKey.toString()),
      ref: { name: fieldKey.toString() },
    } as any;
  });

  return {
    values: {},
    errors: result,
  };
}
