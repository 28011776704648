import { IChartistData, IChartistSeriesData } from "chartist";

export enum ChartType {
  Bar,
  StackedBar,
  Line,
  Doughnut,
}

export type ChartSubSeries = {
  [x: string]: {
    name?: string;
    value?: number;
  }[];
}[];

export interface IChartProps {
  type: ChartType;
  labels: Array<string> | Array<number> | Array<Date>;
  series:
    | Array<IChartistSeriesData>
    | Array<Array<IChartistSeriesData>>
    | Array<Array<IChartistData>>
    | Array<number>
    | Array<Array<number>>;
  subSeries?: ChartSubSeries;
  colors?: string[];
  seriesNames?: string[];
  className?: string;
  showSecondarySources?: boolean;
}
