import { Box, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { leadResponderEmailBlockHoc } from "utils/hoc/leadResponderEmailBlock.hoc";
import DOMPurify from "dompurify";
import { NA } from "components";

export const ContentBlock = leadResponderEmailBlockHoc(
  ({ blockDefinition }) => {
    const emailData = blockDefinition.email;

    const purifiedHtml = useMemo(() => {
      const htmlContent = emailData.parsedEmail?.html;
      return htmlContent && DOMPurify.sanitize(htmlContent);
    }, [emailData?.parsedEmail]);

    return (
      <>
        <Box fontWeight={"bold"}>Content</Box>
        {!purifiedHtml && <NA />}
        {!!purifiedHtml && (
          <VStack bg={"blue.50"} h={"100%"} borderRadius={"xl"} p={4}>
            <Box
              backgroundColor={"white"}
              height={"100%"}
              dangerouslySetInnerHTML={{
                __html: purifiedHtml,
              }}
            />
          </VStack>
        )}
      </>
    );
  }
);
