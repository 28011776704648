import React, { FC, ReactNode, useCallback } from "react";
import { Error } from "../error/Error";
import { EcosystemAuthStore } from "store/EcosystemAuth.store";
import { LEADS_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
  hideError?: boolean;
}

export const ShowWhenEcosystemSupported: FC<IProps> = ({
  children,
  hideError,
}) => {
  const history = useHistory();

  const goToLogin = useCallback(() => {
    history.push(LEADS_ROUTE);
  }, [history]);

  if (!EcosystemAuthStore.isEcosystemSupported()) {
    if (hideError) return null;

    return (
      <Error
        message={"Ecosystem authentication currently not supported."}
        action={
          <Button colorScheme={"blue"} onClick={goToLogin}>
            Log in with another method
          </Button>
        }
      />
    );
  }

  return <>{children}</>;
};
