import { TAccountDevelopersApiKeyConfig } from "types/account-developers.type";
import { FC } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useClipboard,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { DefaultRowCell } from "components/table-v2/row-cells";

interface IProps {
  row: TAccountDevelopersApiKeyConfig;
  handleEditApiConfig: () => void;
  handleDeleteApiConfig: () => void;
}

export const AccountDevelopersApisTableActionCell: FC<IProps> = ({
  row,
  handleEditApiConfig,
  handleDeleteApiConfig,
}) => {
  const { onCopy } = useClipboard(row.apiKey);
  return (
    <DefaultRowCell
      justifyContent={"flex-end"}
      onClick={event => event.stopPropagation()}
    >
      <Menu isLazy={true} placement={"bottom-end"}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />}
          size="xs"
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={onCopy}>Copy API key</MenuItem>
          <MenuItem onClick={handleEditApiConfig}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteApiConfig}>Remove</MenuItem>
        </MenuList>
      </Menu>
    </DefaultRowCell>
  );
};
