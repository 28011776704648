import { Button, HStack, Progress } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { useWizard } from "utils/react-hooks/useWizard.hook";

interface IWizardFooterProps {
  onNext?: () => void;
  isProcessing?: boolean;
  nextLabel?: string;
  backLabel?: string;
  disableNext?: boolean;
  disableBack?: boolean;
}

export const WizardFooter: FC<IWizardFooterProps> = ({
  onNext,
  isProcessing,
  nextLabel = "Next",
  backLabel = "Back",
  disableNext,
  disableBack,
}) => {
  const {
    currentStep,
    goToPreviousStep,
    goToNextStep,
    totalNumberOfSteps,
    showStepProgress,
  } = useWizard();

  const handleNext = useCallback(() => {
    if (!onNext) {
      goToNextStep();
    } else {
      onNext();
    }
  }, [goToNextStep, onNext]);

  return (
    <HStack spacing={10}>
      <Button
        onClick={goToPreviousStep}
        variant={"outline"}
        disabled={currentStep <= 0 || disableBack || isProcessing}
        isLoading={isProcessing}
      >
        {backLabel}
      </Button>
      <Progress
        visibility={showStepProgress ? "visible" : "hidden"}
        flexGrow={1}
        rounded={"md"}
        value={((currentStep + 1) / totalNumberOfSteps) * 100}
        size={"xs"}
      />
      <Button
        onClick={handleNext}
        colorScheme={"blue"}
        disabled={disableNext || isProcessing}
        isLoading={isProcessing}
      >
        {nextLabel}
      </Button>
    </HStack>
  );
};
