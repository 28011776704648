import { Box, HStack, useToast } from "@chakra-ui/react";
import { ProgressBar } from "@uppy/react";
import {
  ApiMessageStack,
  FormControlDescription,
  OfficeAvatar,
  PictureUploadInput,
} from "components";
import React, { useCallback, useEffect, useMemo } from "react";
import Uppy, { UploadResult } from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { BaseApi } from "api/base.api";
import "@uppy/core/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import "styles/uppy-custom.css";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { observer } from "mobx-react";
import { TUserAccountData } from "types/user-account.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

interface IProps {
  account: TUserAccountData;
  officeStore: AccountOfficeStore;
  onUpdate: () => void;
}

export const OfficeLogoInput: React.FC<IProps> = observer(
  ({ account, officeStore, onUpdate }) => {
    const {
      authStore: { authToken },
    } = useAppStore();
    const toast = useToast();
    const officeData = officeStore.office;

    const uppy = useMemo(() => {
      return Uppy({
        meta: { type: "avatar" },
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ["image/png", "image/jpg", "image/jpeg"],
        },
        autoProceed: true,
      }).use(XHRUpload, {
        endpoint: `${BaseApi.getBaseUrl()}/accounts/${
          officeData.accountId
        }/offices/${officeData.id}/logo`,
        method: "POST",
        formData: true,
        fieldName: "file",
        metaFields: [],
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }, [officeData, authToken]);

    useEffect(() => {
      onUpdate();
    }, [officeStore, onUpdate]);

    const handleUploadComplete = useCallback(
      (result: UploadResult<{}, { logoUrl: string; message: string }>) => {
        if (result.successful.length) {
          const url = result?.successful?.[0]?.response?.body?.logoUrl;
          officeStore.upsertLogo(url || null);

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={"Image uploaded!"} />,
          });
        } else if (result.failed.length) {
          const error = result.failed?.[0]?.response?.body?.message;
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error} />,
          });

          uppy.cancelAll();
        }
      },
      [officeStore, toast, uppy]
    );

    useEffect(() => {
      uppy.on("complete", handleUploadComplete);

      return function cleanup() {
        uppy.off("complete", handleUploadComplete);
      };
    }, [uppy, handleUploadComplete]);

    const onChange = useCallback(
      (file?: File) => {
        try {
          if (!!file) {
            uppy.addFile({ data: file, type: file.type });
          }
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [uppy, toast]
    );

    return (
      <HStack spacing={4} align={"center"}>
        <Box>
          <PictureUploadInput
            name={"office-picture"}
            onBlur={() => {}}
            onChange={onChange}
          >
            <OfficeAvatar
              account={account}
              office={officeData}
              heightInPx={150}
              bg={"gray.100"}
            />
          </PictureUploadInput>
          <Box mt={1} height={"1rem"}>
            <ProgressBar uppy={uppy} hideAfterFinish={true} />
          </Box>
        </Box>
        <FormControlDescription>
          Please size your logo as you'd like it to appear in your branded email
          reply that is sent to applicants. Images up to 1MB.
        </FormControlDescription>
      </HStack>
    );
  }
);
