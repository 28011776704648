import { AccountBlacklistedEmailsApi } from "api/account-blacklisted-emails.api";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TAccountBlacklistedEmail } from "types/account-blacklisted-email.type";

const accountBlacklistedEmailsApi = new AccountBlacklistedEmailsApi();

export class BlacklistedEmailLocalStore {
  private readonly accountId: number;
  private blacklistedEmails: IObservableArray<TAccountBlacklistedEmail>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.blacklistedEmails = observable.array<TAccountBlacklistedEmail>();
  }

  get blackListedEmailsArray() {
    return this.blacklistedEmails.slice();
  }

  get blacklistedEmailAddressesArray() {
    return this.blacklistedEmails.map(e => e.email).slice();
  }

  private updateBlacklistedEmails(updatedEmails: TAccountBlacklistedEmail[]) {
    this.blacklistedEmails.replace(updatedEmails);
  }

  public async fetchAllBlacklistedEmails() {
    const emails = await accountBlacklistedEmailsApi.fetchAllBlacklistedEmails(
      this.accountId
    );

    this.updateBlacklistedEmails(emails);
  }

  public async addBlacklistedEmail(emailAddress: string) {
    const blacklistedEmail = await accountBlacklistedEmailsApi.addBlacklistedEmail(
      this.accountId,
      emailAddress.toLowerCase()
    );

    this.updateBlacklistedEmails([...this.blacklistedEmails, blacklistedEmail]);
  }

  public async removeBlacklistedEmail(emailId: number) {
    await accountBlacklistedEmailsApi.removeBlacklistedEmail(
      this.accountId,
      emailId
    );

    const updatedEmails = this.blacklistedEmails.filter(
      email => email.id !== emailId
    );

    this.updateBlacklistedEmails(updatedEmails);
  }
}
