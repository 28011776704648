import { BoxProps } from "@chakra-ui/react";
import {
  IGetOptionPropsData,
  SelectInputOptionsController,
} from "../SelectInputOptionsController";
import React, { useCallback, useMemo } from "react";
import { SingleSelectInputValue } from "./SingleSelectInputValue";
import { SelectInputSizes } from "../select-input-size";
import {
  TSelectOption,
  TSelectOptionValue,
  TSelectValue,
} from "types/select-input.type";
import { DefaultSelectInputOption } from "../custom-options";
import { SelectInputControls } from "../SelectInputControls";
import { SelectInputBase } from "../SelectInputBase";
import useSelect from "utils/react-hooks/useSelect.hook";

interface SingleSelectInputProps<D extends TSelectOptionValue, S = unknown>
  extends Omit<BoxProps, "onChange"> {
  value: TSelectValue<D>;
  options: TSelectOption<D, S>[];
  onChange: (value: TSelectValue<D>) => void;
  onClose?: () => void;
  onClear?: () => void;
  placeholder?: string;
  filterPlaceholder?: string;
  size?: SelectInputSizes;
  disabled?: boolean;
  clearable?: boolean;
  optionComponent?: (
    optionProps: IGetOptionPropsData<D, S>,
    displayingAsInputValue?: boolean
  ) => React.ReactNode;
  selectedComponent?: (
    optionProps: IGetOptionPropsData<D, S>
  ) => React.ReactNode;
  optionsRenderer?: (
    optionProps: IGetOptionPropsData<D, S>[]
  ) => React.ReactNode;
  asyncFilterFn?: (searchTerm: string) => void;
  filterFn?: (
    options: TSelectOption<D, S>[],
    searchTerm: string
  ) => TSelectOption<D, S>[];
  optionsFooter?: React.ReactNode;
  handleOptionsFetch?: () => Promise<void>;
  noBorder?: boolean;
  secondary?: boolean;
}

export function SingleSelectInput<D extends TSelectOptionValue, S = unknown>({
  value,
  options,
  onChange,
  onClose,
  onClear,
  placeholder,
  size = SelectInputSizes.BASE,
  disabled = false,
  clearable,
  optionComponent = optionProps => (
    <DefaultSelectInputOption optionProps={optionProps} />
  ),
  optionsRenderer,
  asyncFilterFn,
  filterFn,
  filterPlaceholder,
  optionsFooter,
  handleOptionsFetch,
  noBorder,
  secondary,
  ...rest
}: SingleSelectInputProps<D, S>) {
  const {
    visibleOptions,
    selectedOption,
    getInputProps,
    getOptionProps,
    setSearch,
  } = useSelect({
    options,
    value,
    onChange,
    filterFn,
  });
  const inputProps = useMemo(() => getInputProps(), [getInputProps]);
  const handleOnClear = useCallback(() => {
    onChange(null);
    onClear?.();
  }, [onChange, onClear]);
  const handleOnClose = useCallback(() => {
    setSearch("");
    onClose?.();
  }, [setSearch, onClose]);

  return (
    <SelectInputBase
      onClose={handleOnClose}
      disabled={disabled}
      size={size}
      noBorder={noBorder}
      secondary={secondary}
      inputValueElement={
        <SingleSelectInputValue<D, S>
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          getOptionProps={getOptionProps}
          optionComponent={optionComponent}
          selectedOption={selectedOption}
        />
      }
      selectControlsElement={
        <SelectInputControls
          onClear={handleOnClear}
          isValueSet={selectedOption?.value}
          clearable={clearable}
        />
      }
      popoverContentRenderer={close => (
        <SelectInputOptionsController<D, S>
          visibleOptions={visibleOptions}
          getOptionProps={getOptionProps}
          optionComponent={optionComponent}
          optionsRenderer={optionsRenderer}
          handleFiltering={
            asyncFilterFn || (!!filterFn ? setSearch : undefined)
          }
          filterPlaceholder={filterPlaceholder}
          optionsFooter={optionsFooter}
          handleOptionsFetch={handleOptionsFetch}
          selectedOption={selectedOption}
          onClick={close}
        />
      )}
      {...inputProps}
      {...rest}
    />
  );
}
