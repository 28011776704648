import { observer } from "mobx-react";
import { Box, Divider, VStack } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, MutableRefObject } from "react";
import { BlacklistedEmails } from "./BlacklistedEmails/BlacklistedEmails";
import { OfficeDistanceCutoff } from "./OfficeDistanceCutoff/OfficeDistanceCutoff";

interface IProps {
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AccountLeadsConfig: FC<IProps> = observer(
  ({ accountStore, containerRef }) => {
    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        <VStack spacing={5} align={"stretch"} divider={<Divider />}>
          <OfficeDistanceCutoff accountStore={accountStore} />
          <BlacklistedEmails accountStore={accountStore} />
        </VStack>
      </Box>
    );
  }
);
