import React, { useCallback } from "react";
import { Box, Button, Center, HStack, VStack } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { IAccountBillingSubscriptionProductConfig } from "constants/account-products-config";
import { withStripe } from "utils/hoc/withStripe.hoc";
import { FormControlDescription } from "components/form";
import { PaymentMethodPrompt } from "routes/dashboard/components/prompts/PaymentMethodPrompt";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { observer } from "mobx-react";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";

interface IProps {
  productConfig: IAccountBillingSubscriptionProductConfig;
  header: string;
  bulletPoints?: (string | React.ReactNode)[];
  customBullet?: React.ReactNode;
  text?: React.ReactNode;
  finePrint?: string;
  colorScheme: string;
  productColor: string;
  accountBillingStore: AccountBillingStore;
  customCTA?: React.ReactNode;
}

export const ProductInfo: React.FC<IProps> = observer(
  ({
    productConfig,
    header,
    bulletPoints,
    customBullet,
    text,
    finePrint,
    colorScheme,
    productColor,
    accountBillingStore,
    customCTA,
  }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const accountBilling = accountBillingStore.billing;

    const openProductActivationConfirmation = useCallback(() => {
      if (!!customCTA) return;

      setModal(
        <ConfirmActionPrompt
          text={`Are you sure you want to activate ${productConfig.title}?`}
          colorScheme={"teal"}
          onConfirm={async () => {
            // TODO NENAD: ADD TOAST AS CONFIRMATION/ERROR HANDLING
            await accountBillingStore.addProduct(productConfig.productId);
          }}
          closePrompt={unSetModal}
        />
      );
    }, [setModal, productConfig, unSetModal, accountBillingStore, customCTA]);

    const handleToggleAddon = useCallback(() => {
      if (!!customCTA) return;

      if (!accountBilling?.stripePaymentMethodId) {
        setModal(
          withStripe(
            <PaymentMethodPrompt
              header={"Add payment method"}
              closePrompt={unSetModal}
              onSuccess={() => openProductActivationConfirmation()}
            />
          )
        );
      } else {
        openProductActivationConfirmation();
      }
    }, [
      accountBilling,
      setModal,
      unSetModal,
      openProductActivationConfirmation,
      customCTA,
    ]);

    return (
      <Box width={"500px"} mb={6}>
        <Box color={`${productColor}.600`} fontWeight={"600"} mb={3}>
          {productConfig.title}
        </Box>
        <Box fontSize={"42px"} lineHeight={"1.1"} fontWeight={"700"} mb={4}>
          {header}
        </Box>
        <VStack spacing={3}>
          {!!text && <Box fontSize={"xl"}>{text}</Box>}
          {!!bulletPoints &&
            bulletPoints.map((point, index) => (
              <HStack
                key={index}
                spacing={4}
                align={"flex-start"}
                width={"100%"}
              >
                {customBullet || (
                  <Center
                    border={"1px solid"}
                    borderColor={`${colorScheme}.600`}
                    rounded={"full"}
                    color={`${colorScheme}.600`}
                    width={"32px"}
                    height={"32px"}
                    flexShrink={0}
                  >
                    {index + 1}
                  </Center>
                )}
                <Box>{point}</Box>
              </HStack>
            ))}
          <VStack spacing={2} align={"flex-start"} width={"100%"}>
            {customCTA || (
              <Button
                size={"lg"}
                colorScheme={colorScheme}
                onClick={handleToggleAddon}
              >
                Add product
              </Button>
            )}
            {!!finePrint && (
              <Box>
                <FormControlDescription>
                  <Box as={"span"} lineHeight={1.5}>
                    {finePrint}
                  </Box>
                </FormControlDescription>
              </Box>
            )}
          </VStack>
        </VStack>
      </Box>
    );
  }
);
