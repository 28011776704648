export enum LeadSimpleFilterTypes {
  OFFICES = "offices",
  ASSIGNED_TO = "assignedTo",
  STAGES = "stages",
  STATUSES = "statuses",
  SOURCES = "sources",
  TYPES = "types",
  PROPERTIES = "properties",
  OFFICE_GROUPS = "officeGroups",
  QUESTIONNAIRE_ANSWERED = "questionnaireAnswered",
  QUESTIONNAIRE = "questionnaire",
  UTM_SOURCES = "utmSources",
  UTM_MEDIUMS = "utmMediums",
  UTM_CAMPAIGNS = "utmCampaigns",
}
