import { Error, Loader } from "components";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Box } from "@chakra-ui/react";
import { OfficeUsersList } from "./OfficeUsersList";
import { getUserFullName } from "utils/account-user.utils";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { AddUserToOfficePrompt } from "./AddUserToOfficePrompt/AddUserToOfficePrompt";
import { OfficeUsersListItem } from "./OfficeUsersListItem";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";

interface IProps {
  accountStore: UserAccountStore;
  officeStore: AccountOfficeStore;
}

export const OfficeUsers: React.FC<IProps> = observer(
  ({ accountStore, officeStore }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );
    const [searchQuery, setSearchQuery] = useState<string>();
    const officeUsers = officeStore.officeUsersArray;

    const filteredOfficeUsers = useMemo(() => {
      if (!searchQuery) return officeUsers;

      return officeUsers.filter(
        user =>
          getUserFullName(user.firstName, user.lastName)?.includes(
            searchQuery
          ) || user.email.toLowerCase().includes(searchQuery)
      );
    }, [searchQuery, officeUsers]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await officeStore.fetchOfficeUsers();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [officeStore]);

    const openAddUserModal = useCallback(() => {
      setModal(
        <AddUserToOfficePrompt
          accountStore={accountStore}
          officeStore={officeStore}
          closePrompt={unSetModal}
        />
      );
    }, [setModal, unSetModal, accountStore, officeStore]);

    const filterUsers = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
      },
      [setSearchQuery]
    );

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            textAlign={"center"}
          >
            <Loader />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <OfficeUsersList
            handleAddItem={openAddUserModal}
            addItemLabel={"Add user"}
            handleFiltering={filterUsers}
            renderItems={() =>
              filteredOfficeUsers.map(user => (
                <OfficeUsersListItem key={user.id} user={user} />
              ))
            }
          />
        )}
      </Box>
    );
  }
);
