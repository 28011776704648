import React, { FC, useCallback, useMemo } from "react";
import ReactCalendar from "react-calendar";
import "./Calendar.scss";
import moment from "moment";
import { Center } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronsLeft,
  faChevronRight,
  faChevronsRight,
} from "@fortawesome/pro-solid-svg-icons";

interface ISingleDatePickerControllerProps {
  date: string | null;
  onChange: (date: string | null) => void;
  maxDate?: string;
  minDate?: string;
}

export const SingleDatePickerController: FC<ISingleDatePickerControllerProps> = ({
  date,
  onChange,
  maxDate,
  minDate,
}) => {
  const dateInstance = useMemo(() => (!!date ? new Date(date) : null), [date]);
  const handleOnChange = useCallback(
    (newDate: Date | null) => {
      const returnDate = !!newDate
        ? moment(newDate)
            .startOf("day")
            .toISOString()
        : null;
      onChange(returnDate);
    },
    [onChange]
  );

  return (
    <ReactCalendar
      value={dateInstance}
      onChange={handleOnChange}
      locale={"en-GB"}
      showNeighboringMonth={false}
      maxDate={!!maxDate ? new Date(maxDate) : undefined}
      minDate={!!minDate ? new Date(minDate) : undefined}
      prevLabel={
        <Center>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Center>
      }
      prev2Label={
        <Center>
          <FontAwesomeIcon icon={faChevronsLeft} />
        </Center>
      }
      nextLabel={
        <Center>
          <FontAwesomeIcon icon={faChevronRight} />
        </Center>
      }
      next2Label={
        <Center>
          <FontAwesomeIcon icon={faChevronsRight} />
        </Center>
      }
    />
  );
};
