import { Box, Progress } from "@chakra-ui/react";
import { formatMoney } from "utils/formating.utils";
import React, { FC, useMemo } from "react";
import { IAccountBillingSubscriptionProductConfig } from "constants/account-products-config";
import { TAccountBillingProductCurrentMonthActivity } from "types/account-billing.type";
import { getUsageFeeDescriptionByProduct } from "utils/account-billing.utils";

interface IProps {
  config: IAccountBillingSubscriptionProductConfig;
  billingProductCurrentMonthActivity: TAccountBillingProductCurrentMonthActivity | null;
  usageFee: number;
}

export const AccountBillingSubscriptionProductUsageGauge: FC<IProps> = ({
  config,
  billingProductCurrentMonthActivity,
  usageFee,
}) => {
  const currentMonthUsage = useMemo(
    () => billingProductCurrentMonthActivity?.currentMonthUsage || 0,
    [billingProductCurrentMonthActivity]
  );
  const unitsIncluded = useMemo(() => config.pricing.unitsIncluded, [config]);

  const progress = useMemo(() => {
    if (!unitsIncluded) {
      return !currentMonthUsage ? 0 : 100;
    }
    return (currentMonthUsage / unitsIncluded) * 100;
  }, [unitsIncluded, currentMonthUsage]);

  const usageFeeDescription = useMemo(() => {
    return getUsageFeeDescriptionByProduct(
      config.productId,
      currentMonthUsage,
      unitsIncluded || 0
    );
  }, [config.productId, currentMonthUsage, unitsIncluded]);

  if (unitsIncluded === undefined) return null;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>
        <Box pb={2}>
          <Box as={"span"}>Usage fee</Box>
          <Box as={"span"} color={"gray.400"} fontStyle={"italic"}>
            {usageFeeDescription}
          </Box>
        </Box>
        <Progress
          width={"300px"}
          height={"6px"}
          colorScheme={"teal"}
          rounded={"md"}
          value={progress}
        />
      </Box>
      <Box>{formatMoney(usageFee, 2, 2)}</Box>
    </Box>
  );
};
