import { AccountSmeApi } from "api/account-sme.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TSmeSource,
  TSmeSourceRelation,
  TSmeSyncLeadSourceData,
} from "types/sme.type";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeLeadSourceStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountSmeSources: IObservableArray<TSmeSource>;
  private readonly accountSmeSourceRelations: IObservableArray<
    TSmeSourceRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.syncStatusFilter = false;
    this.accountSmeSources = observable.array<TSmeSource>();
    this.accountSmeSourceRelations = observable.array<TSmeSourceRelation>();
  }

  get smeSourceRelationsMapByLeadProLeadSourceId() {
    return keyBy(
      this.accountSmeSourceRelations,
      relation => relation.leadSourceId
    );
  }

  get smeSourceRelationsMapBySmeSourceId() {
    return keyBy(
      this.accountSmeSourceRelations,
      relation => relation.smeSourceId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  get filteredSmeSyncSourceData(): TSmeSyncLeadSourceData[] {
    return this.smeSyncLeadSourceData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get smeSyncLeadSourceData(): TSmeSyncLeadSourceData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproLeadSources =
      accountStore.accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    const smeSourceRelationsMapByLeadProLeadSourceId = this
      .smeSourceRelationsMapByLeadProLeadSourceId;

    return leadproLeadSources.map(leadSource => {
      let pairedSmeSource =
        smeSourceRelationsMapByLeadProLeadSourceId[leadSource.id]?.smeSourceId;
      let syncStatus = !!pairedSmeSource
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        leadSourceId: leadSource.id,
        leadSourceName: leadSource.name,
        smeSourceId: pairedSmeSource,
        syncStatus: syncStatus,
      };
    });
  }

  get smeSourceOptions() {
    return this.accountSmeSources
      .map(smeSource => ({
        value: smeSource.id,
        label: smeSource.name,
        data: this.smeSourceRelationsMapBySmeSourceId[smeSource.id],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountSmeSources(sources: TSmeSource[]) {
    this.accountSmeSources.replace(sources);
  }

  public setAccountSmeSourceRelations(relations: TSmeSourceRelation[]) {
    this.accountSmeSourceRelations.replace(relations);
  }

  public async fetchAccountSmeSources() {
    const data = await accountSmeApi.fetchAccountSources(this.accountId);
    this.setAccountSmeSources(data);
  }

  public async fetchAccountSmeSourceRelations() {
    const data = await accountSmeApi.fetchAccountSourceRelations(
      this.accountId
    );
    this.setAccountSmeSourceRelations(data);
  }

  public async fetchAccountSmeSourcesAndRelations() {
    await Promise.all([
      this.fetchAccountSmeSources(),
      this.fetchAccountSmeSourceRelations(),
    ]);
  }

  public async updateAccountSmeSyncedSources(
    syncedPairs: TSmeSyncLeadSourceData[]
  ) {
    await accountSmeApi.updateSyncedAccountSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        smeSourceId: pair.smeSourceId,
      }))
    );
    await this.fetchAccountSmeSourcesAndRelations();
  }
}
