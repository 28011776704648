import * as Yup from "yup";
import { REQUIRED_FIELD } from "../../constants/validator-messages";

export type TStreetIntegrationConfigFieldValues = {
  token: string;
  testMode: boolean;
};

export const streetIntegrationInitialValues: TStreetIntegrationConfigFieldValues = {
  token: "",
  testMode: true,
};

export const streetIntegrationConnectValidationSchema = Yup.object().shape({
  token: Yup.string().required(REQUIRED_FIELD),
  testMode: Yup.boolean(),
});
