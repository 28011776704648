import { observer } from "mobx-react";
import React, { FC, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { ReapitIRIntegrationOfficesConfigurator } from "./ReapitIRIntegrationOfficesConfigurator/ReapitIRIntegrationOfficesConfigurator";
import { ReapitIRIntegrationForm } from "./ReapitIRIntegrationsForm";

interface IProps {
  accountStore: UserAccountStore;
}

export const ReapitIRIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsReapitIRStore =
      accountIntegrationsStore.accountIntegrationsReapitIRStore;

    useMemo(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            accountIntegrationsReapitIRStore.fetchAccountReapitIRConfiguration(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountOfficesStore, accountIntegrationsReapitIRStore]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <ReapitIRIntegrationForm
              accountIntegrationsStore={accountIntegrationsStore}
            />
            <ReapitIRIntegrationOfficesConfigurator
              accountStore={accountStore}
            />
          </>
        )}
      </>
    );
  }
);
