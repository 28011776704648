import { Box, HStack, MenuItem, Switch } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { LeadsTableColumns } from "enums/leads-table-columns.enum";
import { observer } from "mobx-react";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";

export interface ICustomizeTableColumnsMenuItemProps {
  accountLeadsStore: AccountLeadsStore;
  option: {
    value: LeadsTableColumns;
    label: string;
  };
}

export const CustomizeTableColumnsMenuItem: FC<ICustomizeTableColumnsMenuItemProps> = observer(
  ({ accountLeadsStore, option }) => {
    const isSelected = accountLeadsStore.hiddenLeadsTableColumnsArray.includes(
      option.value
    );

    const handleClick = useCallback(() => {
      accountLeadsStore.toggleHiddenLeadsTableColumns(option.value);
    }, [accountLeadsStore, option.value]);

    return (
      <MenuItem px={5} key={option.value} onClick={handleClick}>
        <HStack spacing={2} align={"center"}>
          <Switch size={"sm"} onChange={handleClick} isChecked={isSelected} />
          <Box>{option.label}</Box>
        </HStack>
      </MenuItem>
    );
  }
);
