import { Box, BoxProps, HStack, MenuItem, Switch } from "@chakra-ui/react";
import { TLeadFilterMenuItemValue } from "types/leads-filter.type";
import { FC } from "react";
import { TLeadResponderEmailFilterMenuItemData } from "types/lead-responder-email.type";

export enum LeadResponderEmailSimpleFilterTypes {
  OFFICES = "offices",
  DATE_RECEIVED = "dateReceivedRange",
  PORTAL = "portals",
  STATUS = "statuses",
}

interface IProps {
  availableOption: TLeadResponderEmailFilterMenuItemData;
  onMenuItemClick: (
    value: TLeadFilterMenuItemValue,
    category?: LeadResponderEmailSimpleFilterTypes
  ) => () => void;
  category?: LeadResponderEmailSimpleFilterTypes;
  selectedOptions: TLeadFilterMenuItemValue[];
  textTransform?: BoxProps["textTransform"];
}

export const LeadResponderEmailFilterSimpleMenuItem: FC<IProps> = ({
  selectedOptions,
  onMenuItemClick,
  availableOption,
  category,
  textTransform = "capitalize",
}) => {
  return (
    <MenuItem
      px={5}
      key={availableOption.value}
      onClick={onMenuItemClick(availableOption.value, category)}
    >
      <HStack justify={"space-between"} width={"100%"}>
        <HStack spacing={2} align={"center"}>
          <Switch
            size={"sm"}
            onChange={onMenuItemClick(availableOption.value, category)}
            isChecked={selectedOptions.includes(availableOption.value)}
          />
          <Box textTransform={textTransform}>{availableOption.label}</Box>
        </HStack>
      </HStack>
    </MenuItem>
  );
};
