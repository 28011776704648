import React, { useEffect, useMemo, useState } from "react";
import {
  PanelsGroupLayout,
  RightSideLayout,
} from "routes/dashboard/components";
import { achievementsConfig } from "./achievements-config";
import { AchievementsProgress } from "./AchievementsProgress";
import { isAchieved } from "./is-achieved";
import { AchievementsItem } from "./AchievementsItem";
import { Box } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";

export const Achievements = observer(() => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const {
    authStore: { authUser, loadUserAchievements },
  } = useAppStore();

  const noOfProcessedLeads = useMemo(() => {
    return authUser?.noOfProcessedLeads || 0;
  }, [authUser?.noOfProcessedLeads]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await loadUserAchievements();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [loadUserAchievements]);

  return (
    <RightSideLayout loadingStatus={loadingStatus}>
      <PanelsGroupLayout maxW={"4xl"}>
        <AchievementsProgress noOfProcessedLeads={noOfProcessedLeads} />
        {!isAchieved(achievementsConfig[0].threshold, noOfProcessedLeads) && (
          <Box textAlign="center" py={100} fontWeight={500}>
            To win achievement badges, please start managing leads in your
            LeadPro inbox!
          </Box>
        )}
        {achievementsConfig.map((config, index) =>
          isAchieved(config.threshold, noOfProcessedLeads) ? (
            <AchievementsItem key={index} {...config} />
          ) : null
        )}
      </PanelsGroupLayout>
    </RightSideLayout>
  );
});
