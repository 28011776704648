import { observer } from "mobx-react";
import React, { FC, useCallback, useMemo } from "react";
import { FormActionSendEmail, LeadTypes } from "@leadpro/forms";
import {
  generateDefaultSendEmailFormActionParamsByRoutingType,
  isActionParamFieldInError,
} from "utils/form-tool-page.utils";
import {
  FormActionSendEmailDirectRoutingParams,
  FormActionSendEmailOfficeRoutingParams,
  FormActionSendEmailRoutingTypes,
} from "@leadpro/forms";
import { Box } from "@chakra-ui/react";
import { SingleSelectInput, TagInput } from "components";
import {
  FormToolPageSendEmailActionOfficeLeadTypeOptions,
  FormToolPageSendEmailActionRoutingTypeOptions,
} from "constants/form-tool-page";
import { FormToolPageWIPFormStore } from "../../../../FormToolPageWIPForm.store";
import { FormToolPageSettingsActionConfiguratorItem } from "../../../../../../../../components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionConfiguratorItem";

type KeysOfUnion<T> = T extends T ? keyof T : never;

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore: FormToolPageWIPFormStore;
}

export const FormToolPageSettingsSendEmailParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore }) => {
    const action = formToolPageWIPFormStore.wipFormActionsJS[
      actionIndex
    ] as FormActionSendEmail;

    const onRoutingTypeChange = useCallback(
      (routingType: FormActionSendEmailRoutingTypes | null) => {
        if (!routingType) return;
        formToolPageWIPFormStore.setActionParams(
          actionIndex,
          generateDefaultSendEmailFormActionParamsByRoutingType(routingType)
        );
      },
      [actionIndex, formToolPageWIPFormStore]
    );

    const onValuedPropertyChange = useCallback(
      (property: KeysOfUnion<FormActionSendEmail["params"]>) => (
        value: any | null
      ) => {
        if (!value) return;

        formToolPageWIPFormStore.updateActionParams(actionIndex, {
          [property]: { value },
        });
      },
      [actionIndex, formToolPageWIPFormStore]
    );

    const onMappablePropertyChange = useCallback(
      (property: KeysOfUnion<FormActionSendEmail["params"]>) => (
        questionKey: string | null
      ) => {
        if (!questionKey) return;

        formToolPageWIPFormStore.updateActionParams(actionIndex, {
          [property]: { mapping: questionKey },
        });
      },
      [actionIndex, formToolPageWIPFormStore]
    );

    const errors = useMemo((): {
      [key in KeysOfUnion<FormActionSendEmail["params"]>]: boolean;
    } => {
      const validationResult =
        formToolPageWIPFormStore.wipFormActionsValidationResult;

      return {
        routingType: isActionParamFieldInError(
          "routingType",
          actionIndex,
          validationResult
        ),
        postcode: isActionParamFieldInError(
          "postcode",
          actionIndex,
          validationResult
        ),
        officeEmailType: isActionParamFieldInError(
          "officeEmailType",
          actionIndex,
          validationResult
        ),
        emails: isActionParamFieldInError(
          "emails",
          actionIndex,
          validationResult
        ),
      };
    }, [formToolPageWIPFormStore.wipFormActionsValidationResult, actionIndex]);

    const subTypeElements = useMemo(() => {
      if (
        action.params.routingType.value ===
        FormActionSendEmailRoutingTypes.OFFICE
      ) {
        const params = action.params as FormActionSendEmailOfficeRoutingParams;
        return (
          <>
            <FormToolPageSettingsActionConfiguratorItem
              label={"Office email type"}
              showError={errors.officeEmailType}
              input={
                <Box width={"200px"}>
                  <SingleSelectInput<LeadTypes>
                    value={params.officeEmailType.value}
                    onChange={onValuedPropertyChange("officeEmailType")}
                    options={FormToolPageSendEmailActionOfficeLeadTypeOptions}
                    placeholder={"select lead type"}
                  />
                </Box>
              }
            />
            <FormToolPageSettingsActionConfiguratorItem
              label={"Postcode"}
              showError={errors.postcode}
              input={
                <Box width={"100%"} maxWidth={"500px"}>
                  <SingleSelectInput<string>
                    value={params.postcode.mapping}
                    onChange={onMappablePropertyChange("postcode")}
                    options={
                      formToolPageWIPFormStore.postcodeQuestionsAsOptions
                    }
                    placeholder={"select question"}
                  />
                </Box>
              }
            />
          </>
        );
      }

      if (
        action.params.routingType.value ===
        FormActionSendEmailRoutingTypes.DIRECT
      ) {
        const params = action.params as FormActionSendEmailDirectRoutingParams;
        return (
          <>
            <FormToolPageSettingsActionConfiguratorItem
              label={"Emails"}
              showError={errors.emails}
              input={
                <Box width={"100%"} maxWidth={"500px"}>
                  <TagInput
                    value={params.emails.value}
                    placeholder={"Enter email and press enter or comma"}
                    onChange={onValuedPropertyChange("emails")}
                  />
                </Box>
              }
            />
          </>
        );
      }
    }, [
      action.params,
      errors,
      onValuedPropertyChange,
      onMappablePropertyChange,
      formToolPageWIPFormStore.postcodeQuestionsAsOptions,
    ]);

    return (
      <>
        <FormToolPageSettingsActionConfiguratorItem
          label={"Routing type"}
          showError={errors.routingType}
          input={
            <Box width={"200px"}>
              <SingleSelectInput<FormActionSendEmailRoutingTypes>
                value={action.params.routingType.value}
                onChange={onRoutingTypeChange}
                options={FormToolPageSendEmailActionRoutingTypeOptions}
                placeholder={"select routing type"}
              />
            </Box>
          }
        />
        {subTypeElements}
      </>
    );
  }
);
