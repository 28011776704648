import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  HStack,
  Circle,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { StandardIconButton } from "components";
import { stageColors } from "constants/colors";
import { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { LeadStageTag } from "components";
import { observer } from "mobx-react";
import { NoDataTableView } from "components/table-v2/table-content/NoDataTableView";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { Dictionary } from "lodash";
import { TLeadStatus } from "types/lead-status.type";

interface IStatusesConfiguratorProps {
  statusesStageMap: Dictionary<TLeadStatus[]>;
  onEdit?: (statusId: number) => void;
  onDelete?: (statusId: number) => void;
}

export const StatusesConfigurator: React.FC<IStatusesConfiguratorProps> = observer(
  ({ onEdit, onDelete, statusesStageMap }) => {
    const handleEdit = useCallback(
      (statusId: number) => () => {
        if (!!onEdit) {
          onEdit(statusId);
        }
      },
      [onEdit]
    );

    const handleDelete = useCallback(
      (statusId: number) => () => {
        if (!!onDelete) {
          onDelete(statusId);
        }
      },
      [onDelete]
    );

    const statusStageMapKeys = useMemo<LeadStageEnum[]>(() => {
      return Object.keys(statusesStageMap).map(key => parseInt(key));
    }, [statusesStageMap]);

    return (
      <Box>
        {!!statusStageMapKeys.length ? (
          <Accordion defaultIndex={[0]} allowMultiple>
            {statusStageMapKeys.map((stage, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <LeadStageTag stage={stage} size={"lg"} />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Box>
                    {statusesStageMap[stage].map(status => {
                      return (
                        <Flex
                          key={status.id}
                          my={2}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <HStack spacing={2}>
                              <Circle size={"8px"} bg={stageColors[stage]} />
                              <Box>{status.name}</Box>
                            </HStack>
                          </Box>
                          {!!onEdit && !!onDelete && (
                            <SimpleGrid columns={2}>
                              <StandardIconButton
                                onClick={handleEdit(status.id)}
                                aria-label={"edit-status"}
                                icon={<FontAwesomeIcon icon={faPen} />}
                              />
                              <StandardIconButton
                                onClick={handleDelete(status.id)}
                                aria-label={"remove-status"}
                                icon={<FontAwesomeIcon icon={faXmark} />}
                              />
                            </SimpleGrid>
                          )}
                        </Flex>
                      );
                    })}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <NoDataTableView />
        )}
      </Box>
    );
  }
);
