import { TEmailTemplateWorkflowData } from "types/email-template.type";
import { TLeadResponderWorkflowCoreData } from "types/lead-responder-workflow.type";

export const aggregateEmailTemplateWorkflows = (
  workflows: TEmailTemplateWorkflowData
) => {
  return (Object.keys(
    workflows
  ) as (keyof TEmailTemplateWorkflowData)[]).reduce(
    (accumulator: TLeadResponderWorkflowCoreData[], workflowKey) => {
      return [...accumulator, ...workflows[workflowKey]];
    },
    []
  );
};
