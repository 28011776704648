import { Button, HStack, Textarea, VStack } from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { UserAvatar } from "components/avatar";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

interface IProps {
  leadStore: AccountLeadStore;
}
export const LeadNoteInput: FC<IProps> = observer(({ leadStore }) => {
  const [currentNote, updateNote] = useState("");
  const [isSendingNote, setIsSendingNote] = useState(false);
  const {
    authStore: { authUser },
  } = useAppStore();

  const handleSendNote = useCallback(async () => {
    if (!isSendingNote && currentNote) {
      setIsSendingNote(true);
      try {
        await leadStore.addLeadContactNote(currentNote);
        updateNote("");
      } finally {
        setIsSendingNote(false);
      }
    }
  }, [isSendingNote, currentNote, leadStore]);

  return (
    <HStack width={"100%"} px={4} spacing={2} align={"flex-start"}>
      {!!authUser && <UserAvatar user={authUser} size={"sm"} />}
      <VStack spacing={2} align={"flex-end"} flexGrow={1}>
        <Textarea
          value={currentNote}
          onChange={event => updateNote(event.target.value)}
          placeholder={"Add a note"}
          autoFocus={true}
          resize={"none"}
          disabled={isSendingNote}
          bg={"white"}
          size="sm"
        />
        <Button
          colorScheme={"blue"}
          onClick={handleSendNote}
          isDisabled={isSendingNote}
          isLoading={isSendingNote}
        >
          Add Note
        </Button>
      </VStack>
    </HStack>
  );
});
