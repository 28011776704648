import {
  Box,
  BoxProps,
  Divider,
  InputGroup,
  InputLeftElement,
  useTheme,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { DelayedInput, Loader } from "..";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TSelectOption, TSelectOptionValue } from "types/select-input.type";
import { DefaultSelectInputOption } from "./custom-options/DefaultSelectInputOption";

export interface IGetOptionPropsData<D extends TSelectOptionValue, S = unknown>
  extends BoxProps {
  key: number;
  option: TSelectOption<D, S>;
  selectedOption?: TSelectOption<D, S>;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface ISelectInputOptionsControllerProps<
  D extends TSelectOptionValue,
  S = unknown
> {
  visibleOptions: TSelectOption<D, S>[];
  getOptionProps: (props: object) => IGetOptionPropsData<D, S>;
  selectedOption?: TSelectOption<D, S>;
  optionComponent?: (optionProps: IGetOptionPropsData<D, S>) => React.ReactNode;
  optionsRenderer?: (
    optionProps: IGetOptionPropsData<D, S>[]
  ) => React.ReactNode;
  handleFiltering?: (searchTerm: string) => void;
  filterPlaceholder?: string;
  optionsFooter?: React.ReactNode;
  handleOptionsFetch?: () => Promise<void>;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function SelectInputOptionsController<
  D extends TSelectOptionValue,
  S = unknown
>({
  visibleOptions,
  getOptionProps,
  optionComponent = optionProps => (
    <DefaultSelectInputOption optionProps={optionProps} />
  ),
  selectedOption,
  optionsRenderer,
  handleFiltering,
  filterPlaceholder = "Search",
  optionsFooter,
  handleOptionsFetch,
  onClick,
}: ISelectInputOptionsControllerProps<D, S>) {
  const theme = useTheme();
  const [optionsLoadingStatus, setOptionsLoadingStatus] = useState(
    ApiRequestStatusEnum.NONE
  );

  useEffect(() => {
    const fetchOptions = async () => {
      if (!!handleOptionsFetch) {
        try {
          setOptionsLoadingStatus(ApiRequestStatusEnum.PENDING);
          await handleOptionsFetch();
          setOptionsLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setOptionsLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      }
    };

    fetchOptions();
  }, [handleOptionsFetch]);

  const onSearchInputChange = useCallback(
    async (e: any) => {
      if (handleFiltering) {
        try {
          setOptionsLoadingStatus(ApiRequestStatusEnum.PENDING);
          await handleFiltering(e.target.value);
          setOptionsLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setOptionsLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      }
    },
    [handleFiltering]
  );

  const optionsContent = useMemo(() => {
    if (optionsLoadingStatus === ApiRequestStatusEnum.PENDING) {
      return (
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100px"}
          textAlign={"center"}
        >
          <Loader />
        </Box>
      );
    } else if (!visibleOptions.length) {
      return (
        <Box
          display="flex"
          alignItems="center"
          px={4}
          py={3}
          color={"gray.400"}
        >
          No options...
        </Box>
      );
    } else {
      if (!!optionsRenderer) {
        return optionsRenderer(
          visibleOptions.map((option: TSelectOption<D, S>, index: number) =>
            getOptionProps({ index, option, selectedOption, onClick })
          )
        );
      }
      return visibleOptions.map(
        (option: TSelectOption<D, S>, index: number) => {
          return (
            <Box
              key={index}
              borderTop={index > 0 ? "2px solid" : "none"}
              borderColor={"leadpro.50"}
            >
              {optionComponent({
                ...getOptionProps({ index, option, onClick }),
                selectedOption,
              })}
            </Box>
          );
        }
      );
    }
  }, [
    visibleOptions,
    getOptionProps,
    optionComponent,
    optionsLoadingStatus,
    optionsRenderer,
    selectedOption,
    onClick,
  ]);

  return (
    <>
      {handleFiltering && (
        <InputGroup>
          <InputLeftElement
            height={"100%"}
            pointerEvents="none"
            children={
              <Box>
                <FontAwesomeIcon
                  icon={faSearch}
                  fontSize={18}
                  color={theme.colors.gray[400]}
                />
              </Box>
            }
          />
          <DelayedInput
            onChange={onSearchInputChange}
            inputValue={""}
            size={"sm"}
            placeholder={filterPlaceholder}
            borderRadius={"none"}
            borderTop={"none"}
            borderLeft={"none"}
            borderRight={"none"}
            paddingLeft={"40px"}
          />
        </InputGroup>
      )}
      <Box overflowY="auto" maxHeight="320px">
        {optionsContent}
      </Box>
      {!!optionsFooter && (
        <>
          <Divider />
          {optionsFooter}
        </>
      )}
    </>
  );
}
