import { observer } from "mobx-react";
import React, { FC, useCallback, useMemo } from "react";
import {
  ChildLeadOfficeRoutingType,
  ChildLeadType,
  QuestionnaireActionCreateChildLead,
} from "@leadpro/forms";
import { isActionParamFieldInError } from "utils/form-tool-page.utils";
import { generateDefaultCreateChildLeadQuestionnaireActionParams } from "utils/questionnaire-tool-page.utils";
import { Box } from "@chakra-ui/react";
import { SingleSelectInput } from "components";

import {
  QuestionnaireToolPageCreateChildLeadActionChildLeadTypeOptions,
  QuestionnaireToolPageCreateChildLeadActionRoutingTypeOptions,
} from "constants/questionnaire-tool-page";
import { TSelectValue } from "types/select-input.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { QuestionnaireToolPageWIPFormStore } from "../../../../stores/QuestionnaireToolPageWIPForm.store";
import { FormToolPageSettingsActionConfiguratorItem } from "../../../../../../../../../../components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionConfiguratorItem";

interface IProps {
  actionIndex: number;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsCreateChildLeadParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, questionnaireToolPageWIPFormStore }) => {
    const { userAccountsStore } = useAppStore();
    const selectedAccountStore = userAccountsStore.selectedAccountStore;

    const action = questionnaireToolPageWIPFormStore.wipFormActionsJS[
      actionIndex
    ] as QuestionnaireActionCreateChildLead;

    const onRoutingTypeChange = useCallback(
      (routingType: ChildLeadOfficeRoutingType | null) => {
        if (!routingType) return;
        questionnaireToolPageWIPFormStore.setActionParams(
          actionIndex,
          generateDefaultCreateChildLeadQuestionnaireActionParams(
            routingType,
            action.params.leadType.value
          )
        );
      },
      [actionIndex, action, questionnaireToolPageWIPFormStore]
    );

    const onValuedPropertyChange = useCallback(
      (property: keyof QuestionnaireActionCreateChildLead["params"]) => (
        value: string | null
      ) => {
        if (!value) return;

        questionnaireToolPageWIPFormStore.updateActionParams(actionIndex, {
          [property]: { value },
        });
      },
      [actionIndex, questionnaireToolPageWIPFormStore]
    );

    const onOfficeChange = useCallback(
      (value: TSelectValue<number>) => {
        if (!value) return;

        questionnaireToolPageWIPFormStore.updateActionParams(actionIndex, {
          officeId: { value },
        });
      },
      [actionIndex, questionnaireToolPageWIPFormStore]
    );

    const errors = useMemo((): {
      [key in keyof QuestionnaireActionCreateChildLead["params"]]: boolean;
    } => {
      const validationResult =
        questionnaireToolPageWIPFormStore.wipFormActionsValidationResult;

      return {
        leadType: isActionParamFieldInError(
          "leadType",
          actionIndex,
          validationResult
        ),
        routingType: isActionParamFieldInError(
          "routingType",
          actionIndex,
          validationResult
        ),
        officeId: isActionParamFieldInError(
          "officeId",
          actionIndex,
          validationResult
        ),
      };
    }, [
      questionnaireToolPageWIPFormStore.wipFormActionsValidationResult,
      actionIndex,
    ]);

    const subTypeElements = useMemo(() => {
      if (
        action.params.routingType.value ===
        ChildLeadOfficeRoutingType.SpecificOffice
      ) {
        return (
          <FormToolPageSettingsActionConfiguratorItem
            label={"Office"}
            showError={errors.officeId || false}
            input={
              <Box width={"200px"}>
                <SingleSelectInput<number>
                  value={action.params.officeId?.value || null}
                  onChange={onOfficeChange}
                  options={
                    selectedAccountStore?.accountOfficesStore
                      .accountOfficesOptionsArray || []
                  }
                  placeholder={"select office"}
                />
              </Box>
            }
          />
        );
      }

      return null;
    }, [
      action.params.routingType.value,
      action.params.officeId?.value,
      errors.officeId,
      onOfficeChange,
      selectedAccountStore?.accountOfficesStore.accountOfficesOptionsArray,
    ]);

    return (
      <>
        <FormToolPageSettingsActionConfiguratorItem
          label={"Create child lead"}
          showError={errors.leadType}
          input={
            <Box width={"200px"}>
              <SingleSelectInput<ChildLeadType>
                value={action.params.leadType.value}
                onChange={onValuedPropertyChange("leadType")}
                options={
                  QuestionnaireToolPageCreateChildLeadActionChildLeadTypeOptions
                }
                placeholder={"select lead type of child lead"}
              />
            </Box>
          }
        />
        <FormToolPageSettingsActionConfiguratorItem
          label={"Routing type"}
          showError={errors.routingType}
          input={
            <Box width={"200px"}>
              <SingleSelectInput<ChildLeadOfficeRoutingType>
                value={action.params.routingType.value}
                onChange={onRoutingTypeChange}
                options={
                  QuestionnaireToolPageCreateChildLeadActionRoutingTypeOptions
                }
                placeholder={"select routing type"}
              />
            </Box>
          }
        />
        {subTypeElements}
      </>
    );
  }
);
