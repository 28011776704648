import {
  Box,
  Button,
  ChakraStyledOptions,
  Image,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ApiMessageStack, Tooltip } from "components";
import {
  IntegrationLogoIcons,
  IntegrationLogoIconURLs,
} from "constants/integrationLogoIcons";

interface IProps {
  leadStore: AccountLeadStore;
  hasActiveMRIRadarIntegration: boolean;
  styles?: ChakraStyledOptions;
}

export const LeadSyncMRIRadarButton: FC<IProps> = observer(
  ({ leadStore, hasActiveMRIRadarIntegration, styles }) => {
    const toast = useToast();
    const [isSyncing, setIsSyncing] = useState(false);
    const isLeadSynced = leadStore.isLeadSyncedToMRIRadar;

    const validationErrorMessages = useMemo(() => {
      const errorMessages: string[] = [];

      if (isSyncing) errorMessages.push("Lead is being synced...");
      if (isLeadSynced) errorMessages.push("Lead already synced.");

      return errorMessages;
    }, [isSyncing, isLeadSynced]);

    const buttonData = useMemo(() => {
      return {
        buttonProps: {
          isDisabled: !!validationErrorMessages.length,
          isLoading: isSyncing,
          children: isLeadSynced ? "Synced to Radar" : "Sync to Radar",
          leftIcon: isLeadSynced ? (
            <FontAwesomeIcon icon={faCircleCheck} fontSize={24} />
          ) : (
            <Image
              alt={"mri-radar-icon"}
              width={"24px"}
              src={IntegrationLogoIconURLs[IntegrationLogoIcons.MRI]}
            />
          ),
        },
      };
    }, [isLeadSynced, isSyncing, validationErrorMessages.length]);

    const handleSyncLead = useCallback(async () => {
      if (hasActiveMRIRadarIntegration && !validationErrorMessages.length) {
        try {
          setIsSyncing(true);
          await leadStore.syncLeadWithMRIRadar();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        } finally {
          setIsSyncing(false);
        }
      }
    }, [
      leadStore,
      hasActiveMRIRadarIntegration,
      validationErrorMessages,
      toast,
    ]);

    return (
      <Tooltip
        isDisabled={!validationErrorMessages.length}
        aria-label={`lead-mri-radar-sync-tooltip`}
        label={
          <Box>
            {validationErrorMessages.map(message => (
              <Box key={message}>{message}</Box>
            ))}
          </Box>
        }
      >
        <Box>
          <Button
            onClick={handleSyncLead}
            variant={"ghost"}
            {...buttonData.buttonProps}
            {...styles}
          />
        </Box>
      </Tooltip>
    );
  }
);
