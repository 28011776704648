import { observer } from "mobx-react";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Box, Flex, HStack, Portal, useToast } from "@chakra-ui/react";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormFooter, FormInputCharacterCounter } from "components/form";
import { AutocallerTemplateTestPopover } from "./AutocallerTemplateTestPopover";
import { AccountAutocallerStore } from "store/UserAccounts/UserAccount/AccountAutocaller/AccountAutocaller.store";

type TFieldValues = {
  template: string;
  isCustom: boolean;
};

const TEMPLATE_MAX_CHAR_LENGTH = 1536;

const validationSchema = Yup.object().shape({
  template: Yup.string().max(TEMPLATE_MAX_CHAR_LENGTH),
  isCustom: Yup.boolean(),
});

interface IProps {
  isEnabled: boolean;
  accountAutocallerStore: AccountAutocallerStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AutocallerTemplateEditor: FC<IProps> = observer(
  ({ accountAutocallerStore, isEnabled, containerRef }) => {
    const toast = useToast();
    const autocallerTemplateStore =
      accountAutocallerStore.autocallerTemplateStore;
    const {
      templateContent,
      isDefaultTemplateUsed,
    } = accountAutocallerStore.autocallerTemplateStore;

    const initialValues: TFieldValues = useMemo(
      () => ({
        template: templateContent,
        isCustom: !isDefaultTemplateUsed,
      }),
      [templateContent, isDefaultTemplateUsed]
    );

    const {
      handleSubmit,
      control,
      formState: { isSubmitting, isDirty },
      reset,
      watch,
    } = useForm<TFieldValues>({
      mode: "onSubmit",
      defaultValues: initialValues,
      resolver: yupResolver(validationSchema),
    });

    const watchTemplate = watch("template");
    const watchIsCustom = watch("isCustom");

    const onSubmit = useCallback(
      async ({ template, isCustom }: TFieldValues) => {
        if (isEnabled) {
          try {
            await autocallerTemplateStore.updateTemplate(
              isCustom && !!template ? template : null
            );
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description: (
                <ApiMessageStack
                  messageStack={"Autocaller template updated."}
                />
              ),
            });
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          }
        }
      },
      [isEnabled, autocallerTemplateStore, toast]
    );

    const isMessageTemplateInputDisabled = useMemo(
      () => !isEnabled || !watchIsCustom,
      [isEnabled, watchIsCustom]
    );

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box px={1}>
        <form>
          <HStack mb={5} alignItems={"center"} spacing={4}>
            <FormControlLabel>Use custom message template</FormControlLabel>
            <FormControlV2<TFieldValues>
              name={"isCustom"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </HStack>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"template"}
              control={control}
              label={"Message template"}
              type={FormControlsTypeEnum.MERGE_TAG_INPUT}
              isDisabled={isMessageTemplateInputDisabled}
              additionalProps={{
                singleLine: false,
                mergeTagOptions:
                  autocallerTemplateStore.mergeTagGroupOptionsArray,
              }}
            />
            <FormInputCharacterCounter
              currentLength={watchTemplate.length}
              maxLength={TEMPLATE_MAX_CHAR_LENGTH}
            />
          </Box>

          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </form>
        <Flex justifyContent={"flex-end"} mt={10}>
          <AutocallerTemplateTestPopover
            isDisabled={isDirty || !isEnabled}
            accountAutocallerStore={accountAutocallerStore}
          />
        </Flex>
      </Box>
    );
  }
);
