import React, { useCallback } from "react";
import sortByKeys from "utils/sort-keys.utils";
import { StatRow } from "./StatRow";
import { LeadByOption } from "enums/lead-by-option.enum";
import { toSnakeCase } from "utils/to-snake-case.utils";
import {
  TColorsMap,
  TLeadsInRangeCount,
  TLeadsStats,
} from "types/account-analytics.type";
import { Box, BoxProps } from "@chakra-ui/react";
import { SubStatRow } from "./SubStatRow";
import { orderSourceHosts } from "utils/lead-source.utils";

const tableHeaderStyle: BoxProps = {
  color: "gray.600",
  fontSize: "14px",
  borderColor: "gray.300",
  py: 3,
  lineHeight: 1,
  textAlign: "left",
  px: 3,
  fontWeight: 500,
};

interface IStatTable<T extends string> {
  statType: LeadByOption;
  totalLeads: TLeadsStats<T>;
  leadsInRangeCount: TLeadsInRangeCount<TLeadsStats<T>>;
  colorDefinitions: TColorsMap<T>;
  labelFunction: (label: string) => string;
  showSecondarySources?: boolean;
}

export function StatTable<T extends string>(props: IStatTable<T>) {
  const { previous30Days, previous60Days } = props.leadsInRangeCount;
  const { totalLeads, showSecondarySources } = props;

  const getOrderedHostKeys = useCallback(
    (key: T) => {
      const totalKeys = [
        ...Object.keys(totalLeads[key]?.hosts || []),
        ...Object.keys(previous30Days[key]?.hosts || []),
        ...Object.keys(previous60Days[key]?.hosts || []),
      ];
      const hostKeys = totalKeys.filter((key, index) => {
        return totalKeys.indexOf(key) === index;
      });

      const otherIndex = hostKeys.indexOf("other");

      const orderHostKeys = (keys: string[]) =>
        keys.sort((a, b) => {
          return (
            (totalLeads[key]?.hosts[b] || 0) - (totalLeads[key]?.hosts[a] || 0)
          );
        });

      return orderSourceHosts<string>(hostKeys, otherIndex, orderHostKeys);
    },
    [totalLeads, previous30Days, previous60Days]
  );

  return (
    <Box as={"table"} width={"100%"}>
      <Box as={"thead"} bg={"gray.100"} rounded={"full"}>
        <Box as={"tr"}>
          <Box as={"th"} {...tableHeaderStyle}>
            Type
          </Box>
          <Box as={"th"} {...tableHeaderStyle}>
            Dates selected
          </Box>
          <Box as={"th"} {...tableHeaderStyle}>
            Last 30 days
          </Box>
          <Box as={"th"} {...tableHeaderStyle}>
            Last 60 days
          </Box>
        </Box>
      </Box>
      <tbody>
        {(Object.keys(sortByKeys(totalLeads)) as T[]).map(key => {
          return (
            <>
              <StatRow
                key={key}
                statKey={key}
                statType={props.statType}
                colour={props.colorDefinitions[key]}
                title={props.labelFunction(toSnakeCase(key))}
                total={totalLeads[key].total}
                last30Days={previous30Days[key]?.total || 0}
                last60Days={previous60Days[key]?.total || 0}
              />
              {showSecondarySources &&
                getOrderedHostKeys(key).map(hostKey => {
                  return (
                    <SubStatRow
                      key={hostKey}
                      colour={props.colorDefinitions[key]}
                      title={hostKey}
                      total={totalLeads[key]?.hosts[hostKey] || 0}
                      last30Days={previous30Days[key]?.hosts[hostKey] || 0}
                      last60Days={previous60Days[key]?.hosts[hostKey] || 0}
                    />
                  );
                })}
            </>
          );
        })}
      </tbody>
    </Box>
  );
}
