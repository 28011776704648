import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { TAccountIVTConfigData } from "types/account.type";

export type TAccountIVTConfigFieldValues = {
  isDistanceCheckEnabled: TAccountIVTConfigData["isDistanceCheckEnabled"];
  distanceCheckRadius: TAccountIVTConfigData["distanceCheckRadius"] | "";
};

export const accountIVTConfigValidationSchema = Yup.object().shape({
  isDistanceCheckEnabled: Yup.boolean().required(REQUIRED_FIELD),
  distanceCheckRadius: Yup.number()
    .integer()
    .transform(value => (!value ? null : value)) // turn empty string to `null`
    .nullable()
    .positive()
    .min(1)
    .required("Please provide a number greater than zero."),
});
