import { Error, Loader } from "components";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import React, { FC, useState, useEffect } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeSelectIntegrationTable } from "../AccountIntegrationConfigurator/OfficeSelectIntegrationTable";
import { OpenViewIntegrationsLeadSourcesConfigurator } from "./OpenViewIntegrationSourcesConfigurator/OpenViewIntegrationsLeadSourcesConfigurator";
import { OpenViewIntegrationUsersConfigurator } from "./OpenViewIntegrationUsersConfigurator/OpenViewIntegrationUsersConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const OpenViewIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsOpenViewStore =
      accountIntegrationsStore.accountIntegrationOpenViewStore;
    const accountIntegrationOfficesStore =
      accountIntegrationsOpenViewStore.accountIntegrationOpenViewOfficesStore;
    const accountIntegrationLeadSourcesStore =
      accountIntegrationsOpenViewStore.accountIntegrationOpenViewLeadSourcesStore;
    const accountIntegrationsOpenViewNegotiatorsStore =
      accountIntegrationsOpenViewStore.accountIntegrationOpenViewNegotiatorsStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            accountUsersStore.fetchAccountUsers(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
            accountIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations(),
            accountIntegrationsOpenViewNegotiatorsStore.fetchAccountOpenViewNegotiatorsAndRelations(),
            accountIntegrationLeadSourcesStore.fetchAccountOpenViewLeadSourcesAndRelations(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountOfficesStore,
      accountUsersStore,
      accountIntegrationsOpenViewNegotiatorsStore,
      accountLeadSourcesStore,
      accountIntegrationLeadSourcesStore,
      accountIntegrationOfficesStore,
    ]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <OfficeSelectIntegrationTable
              accountIntegrationOfficesStore={accountIntegrationOfficesStore}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.OPEN_VIEW}
            />
            <OpenViewIntegrationUsersConfigurator accountStore={accountStore} />
            <OpenViewIntegrationsLeadSourcesConfigurator
              accountStore={accountStore}
            />
          </>
        )}
      </>
    );
  }
);
