import React, { FC, useMemo } from "react";
import { aggregateEmailTemplateWorkflows } from "utils/email-template.utils";
import { DefaultRowCell } from "./DefaultRowCell";
import { TEmailTemplate } from "types/email-template.type";
import { Tooltip } from "components/tooltip/Tooltip";
import { Box } from "@chakra-ui/react";
import { TemplateUsedInWorkflowNotice } from "components/notice/TemplateUsedInWorkflowNotice";

interface IEmailTemplateWorkflowUsageRowCellProps {
  row: TEmailTemplate;
}

export const EmailTemplateWorkflowUsageRowCell: FC<IEmailTemplateWorkflowUsageRowCellProps> = ({
  row,
}) => {
  const aggregatedWorkflows = useMemo(
    () => aggregateEmailTemplateWorkflows(row.workflows),
    [row.workflows]
  );

  const tooltipLabel = useMemo(() => {
    return !!aggregatedWorkflows.length ? (
      <TemplateUsedInWorkflowNotice
        description={"This email template is used in workflow(s):"}
        workflows={aggregatedWorkflows}
      />
    ) : (
      <Box>This template is not currently used in any workflows</Box>
    );
  }, [aggregatedWorkflows]);

  return (
    <DefaultRowCell>
      <Tooltip label={tooltipLabel}>
        {aggregatedWorkflows.length + " place(s)"}
      </Tooltip>
    </DefaultRowCell>
  );
};
