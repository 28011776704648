import {
  Box,
  Button,
  Divider,
  HStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { ApiMessageStack, Input, StandardIconButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faRedo,
  faUndo,
  faMobile,
  faDisplay,
} from "@fortawesome/pro-regular-svg-icons";
import { EmailTemplateSendTestPopover } from "./EmailTemplateSendTestPopover";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR_BORDERLESS } from "styles/input-default-pseudobox-behaviour";
import { AccountEmailTemplatesStore } from "store/UserAccounts/UserAccount/AccountEmailTemplates/AccountEmailTemplates.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { EmailTemplateConfirmDeletePopover } from "./EmailTemplateConfirmDeletePopover";
import { observer } from "mobx-react";

const { TopolPlugin } = window as any;

interface IProps {
  emailTemplatesStore: AccountEmailTemplatesStore;
  isSubmittingTemplate: boolean;
  setIsSubmittingTemplate: (isSubmittingTemplate: boolean) => void;
  closePrompt: () => void;
}

export const EmailTemplateEditorPromptHeader: FC<IProps> = observer(
  ({
    emailTemplatesStore,
    isSubmittingTemplate,
    setIsSubmittingTemplate,
    closePrompt,
  }) => {
    const toast = useToast();
    const [previewMode, setPreviewMode] = useBoolean();
    const [mobileScreenSize, setMobileScreenSize] = useBoolean();

    const wipEmailTemplateStore = emailTemplatesStore.wipEmailTemplateStore;
    const isWipTemplateCreatedPreviously =
      wipEmailTemplateStore.isTemplateCreatedPreviously;
    const isWipTemplateDeletable = wipEmailTemplateStore.isWipTemplateDeletable;

    const handleDelete = useCallback(async () => {
      if (!!wipEmailTemplateStore.id && isWipTemplateDeletable) {
        try {
          await emailTemplatesStore.removeEmailTemplate(
            wipEmailTemplateStore.id
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "Template has been deleted.",
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      }
    }, [
      wipEmailTemplateStore,
      emailTemplatesStore,
      isWipTemplateDeletable,
      closePrompt,
      toast,
    ]);

    const handleTemplateNameChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) =>
        wipEmailTemplateStore.setWIPTemplateName(event.target.value),
      [wipEmailTemplateStore]
    );

    const handleCopy = useCallback(() => {
      if (isWipTemplateCreatedPreviously) {
        wipEmailTemplateStore.copyTemplate();
      }
    }, [isWipTemplateCreatedPreviously, wipEmailTemplateStore]);

    const handlePreviewAndTest = useCallback(() => {
      if (!!TopolPlugin) {
        TopolPlugin.togglePreview();
        setPreviewMode.toggle();
      }
    }, [setPreviewMode]);

    const handleScreenSizeToggle = useCallback(() => {
      if (!!TopolPlugin) {
        TopolPlugin.togglePreviewSize();
        setMobileScreenSize.toggle();
      }
    }, [setMobileScreenSize]);

    const handleSave = useCallback(() => {
      if (!isSubmittingTemplate && !!TopolPlugin) {
        setIsSubmittingTemplate(true);
        TopolPlugin.save();
      }
    }, [isSubmittingTemplate, setIsSubmittingTemplate]);

    const handleUndo = useCallback(() => {
      TopolPlugin?.undo();
    }, []);

    const handleRedo = useCallback(() => {
      TopolPlugin?.redo();
    }, []);

    return (
      <HStack spacing={4} pr={4}>
        <HStack spacing={0} flexGrow={1}>
          <Box flexGrow={1}>
            <Input
              value={wipEmailTemplateStore.name}
              onChange={handleTemplateNameChange}
              placeholder={"Name"}
              {...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR_BORDERLESS}
            />
          </Box>
          {!previewMode && (
            <>
              <StandardIconButton
                onClick={handleUndo}
                aria-label={"undo"}
                icon={<FontAwesomeIcon icon={faUndo} />}
              />
              <StandardIconButton
                onClick={handleRedo}
                aria-label={"redo"}
                icon={<FontAwesomeIcon icon={faRedo} />}
              />
              <StandardIconButton
                onClick={handleCopy}
                isDisabled={!isWipTemplateCreatedPreviously}
                aria-label={"copy-template"}
                icon={<FontAwesomeIcon icon={faCopy} />}
              />
              <EmailTemplateConfirmDeletePopover
                onDelete={handleDelete}
                isDisabled={!isWipTemplateDeletable}
              />
            </>
          )}
          {previewMode && (
            <>
              <EmailTemplateSendTestPopover
                emailTemplatesStore={emailTemplatesStore}
              />
              <StandardIconButton
                onClick={handleScreenSizeToggle}
                aria-label={"screen-size-toggle"}
                icon={
                  mobileScreenSize ? (
                    <FontAwesomeIcon icon={faMobile} />
                  ) : (
                    <FontAwesomeIcon icon={faDisplay} />
                  )
                }
              />
            </>
          )}
        </HStack>
        <Divider orientation={"vertical"} height={30} borderRightWidth={1} />
        <HStack spacing={4}>
          <Button variant={"outline"} onClick={handlePreviewAndTest}>
            {previewMode ? "Email builder" : "Preview and test"}
          </Button>
          <Button
            colorScheme={"teal"}
            onClick={handleSave}
            isDisabled={isSubmittingTemplate}
            isLoading={isSubmittingTemplate}
          >
            {!!wipEmailTemplateStore.id ? "Update" : "Create"}
          </Button>
        </HStack>
        <Divider orientation={"vertical"} height={30} borderRightWidth={1} />
      </HStack>
    );
  }
);
