import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import { TAccountDevelopersApiKeyConfig } from "types/account-developers.type";
import { AccountDevelopersApi } from "api/account-developers.api";
import { TAccountApiRequestResponse } from "types/account-developers.type";

const developersApi = new AccountDevelopersApi();

export class AccountDevelopersApiKeyConfigStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private apiKeyConfigData: TAccountDevelopersApiKeyConfig;
  private apiRequestsData: TAccountApiRequestResponse | null;

  constructor(
    root: AppStore,
    accountId: number,
    data: TAccountDevelopersApiKeyConfig
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.apiKeyConfigData = data;
    this.apiRequestsData = null;
  }

  get apiKeyConfig() {
    return this.apiKeyConfigData;
  }

  get apiRequests() {
    return this.apiRequestsData;
  }

  private setApiRequestsData(apiRequests: TAccountApiRequestResponse) {
    this.apiRequestsData = apiRequests;
  }

  public resetApiRequests() {
    this.apiRequestsData = null;
  }

  private setApiKeyConfigData(
    accountDevelopersApiKeyConfig: TAccountDevelopersApiKeyConfig
  ) {
    this.apiKeyConfigData = accountDevelopersApiKeyConfig;
  }

  public async fetchDetails() {
    const accountDevelopersApiKeyConfig = await developersApi.fetchAccountDevelopersApiKeyConfig(
      this.accountId,
      this.apiKeyConfigData.id
    );
    this.setApiKeyConfigData(accountDevelopersApiKeyConfig);
  }

  public async updateApiKeyConfig(
    data: Partial<TAccountDevelopersApiKeyConfig>
  ) {
    const apiConfigData = await developersApi.updateAccountDevelopersApiKeyConfig(
      this.accountId,
      this.apiKeyConfigData.id,
      data
    );
    this.setApiKeyConfigData(apiConfigData);
  }

  public async fetchApiKeyRequests(offset?: number, limit?: number) {
    const apiRequests = await developersApi.fetchAccountDevelopersApiKeyRequests(
      this.accountId,
      this.apiKeyConfigData.id,
      offset,
      limit
    );

    this.setApiRequestsData(apiRequests);
  }

  public async regenerateDevelopersApiKey() {
    const apiConfig = await developersApi.regenerateAccountDevelopersApiKey(
      this.accountId,
      this.apiKeyConfigData.id
    );

    this.setApiKeyConfigData(apiConfig);
  }
}
