import { LeadSourceEnum } from "enums/lead-source.enum";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";

export interface IOptionColor {
  colorScheme?: string;
  statsColor: string;
}

export const stageColors: {
  [key in LeadStageEnum]: string;
} = {
  [LeadStageEnum.UNREAD]: "#00AAE7",
  [LeadStageEnum.IN_PROGRESS]: "#9A71DD",
  [LeadStageEnum.BUSINESS]: "#00BF63",
  [LeadStageEnum.NO_BUSINESS]: "#BEC5DB",
  [LeadStageEnum.DUPLICATE]: "#F29967",
};

export const sourceColors: {
  [key in LeadSourceEnum]: IOptionColor;
} = {
  [LeadSourceEnum.zoopla]: {
    statsColor: "#5b1760",
  },
  [LeadSourceEnum.zoopla_valuation]: {
    statsColor: "#5b1760",
  },
  [LeadSourceEnum.boomin]: {
    statsColor: "#5b1760",
  },
  [LeadSourceEnum.rightmove]: {
    statsColor: "#04dfb6",
  },
  [LeadSourceEnum.rightmove_valuation]: {
    statsColor: "#04dfb6",
  },
  [LeadSourceEnum.yourmove]: {
    statsColor: "#5F933D",
  },
  [LeadSourceEnum.yourmove_valuation]: {
    statsColor: "#5F933D",
  },
  [LeadSourceEnum.reedsrains]: {
    statsColor: "#081D5E",
  },
  [LeadSourceEnum.reedsrains_valuation]: {
    statsColor: "#081D5E",
  },
  [LeadSourceEnum.ivt]: {
    statsColor: "#356BE9",
  },
  [LeadSourceEnum.onthemarket]: {
    statsColor: "#003f5b",
  },

  [LeadSourceEnum.gumtree]: {
    statsColor: "#69560d",
  },
  [LeadSourceEnum.s1]: {
    statsColor: "#69560d",
  },
  [LeadSourceEnum.lettingweb]: {
    statsColor: "#69560d",
  },
  [LeadSourceEnum.propertypal]: {
    statsColor: "#69560d",
  },
  [LeadSourceEnum.propertynews]: {
    statsColor: "#69560d",
  },

  [LeadSourceEnum.signup]: {
    statsColor: "#EC7140",
  },
  [LeadSourceEnum.homepage]: {
    statsColor: "#ED64A6",
  },
  [LeadSourceEnum.manually_added]: {
    statsColor: "#ED64A6",
  },
};

export const typesColors: {
  [key in LeadTypeEnum]: IOptionColor;
} = {
  [LeadTypeEnum.Let]: {
    colorScheme: "purple",
    statsColor: "#960DF2",
  },
  [LeadTypeEnum.Landlord]: {
    colorScheme: "purple",
    statsColor: "#960DF2",
  },
  [LeadTypeEnum.Tenant]: {
    colorScheme: "purple",
    statsColor: "#960DF2",
  },
  [LeadTypeEnum.Letting]: {
    colorScheme: "purple",
    statsColor: "#960DF2",
  },

  [LeadTypeEnum.Sale]: {
    colorScheme: "teal",
    statsColor: "#1B94A1",
  },
  [LeadTypeEnum.Vendor]: {
    colorScheme: "teal",
    statsColor: "#1B94A1",
  },
  [LeadTypeEnum.Selling]: {
    colorScheme: "teal",
    statsColor: "#1B94A1",
  },

  [LeadTypeEnum.Evaluation]: {
    colorScheme: "blue",
    statsColor: "#356BE9",
  },
  [LeadTypeEnum.MortgageRequest]: {
    colorScheme: "blue",
    statsColor: "#356BE9",
  },
  [LeadTypeEnum.Meeting]: {
    colorScheme: "blue",
    statsColor: "#356BE9",
  },

  [LeadTypeEnum.JustCurious]: {
    colorScheme: "yellow",
    statsColor: "#D69E2E",
  },
  [LeadTypeEnum.Utility]: {
    colorScheme: "yellow",
    statsColor: "#D69E2E",
  },
  [LeadTypeEnum.SellingBuying]: {
    colorScheme: "yellow",
    statsColor: "#D69E2E",
  },

  [LeadTypeEnum.Null]: {
    colorScheme: "orange",
    statsColor: "#DD6B20",
  },
  [LeadTypeEnum.Internet]: {
    colorScheme: "orange",
    statsColor: "#DD6B20",
  },
  [LeadTypeEnum.Empty]: {
    colorScheme: "orange",
    statsColor: "#DD6B20",
  },
  [LeadTypeEnum.MarketAppraisal]: {
    colorScheme: "red",
    statsColor: "#EF4444",
  },
};

export const STAT_COLORS = [
  "#0066ff",
  "#960df2",
  "#1b94a1",
  "#f05b4f",
  "#453d3f",
  "#59922b",
  "#0544d3",
  "#6b0392",
  "#d17905",
  "#dda458",
  "#eacf7d",
  "#86797d",
  "#b2c326",
  "#6188e2",
  "#a748ca",
];
