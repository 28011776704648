import { Flex, Tag, Text } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { SelectInputOptionBase } from "components/select-input/SelectInputOptionBase";

interface IIntegrationSyncSelectOptionsRendererProps {
  optionProps: IGetOptionPropsData<string | number>;
}

export const IntegrationSyncSelectOption: FC<IIntegrationSyncSelectOptionsRendererProps> = ({
  optionProps: { option, selectedOption, ...rest },
}) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);

  const isSynced = useMemo(() => !!rest.onClick && !!option.data, [
    option,
    rest,
  ]);

  return (
    <SelectInputOptionBase
      {...rest}
      option={option}
      isSelected={isSelected}
      isDisabled={isSynced}
    >
      <Flex
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text color={isSynced ? "gray.400" : undefined}>{option.label}</Text>
        {isSynced && <Tag colorScheme="teal">Synced</Tag>}
      </Flex>
    </SelectInputOptionBase>
  );
};
