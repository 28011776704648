import { Options } from "export-to-csv";

export const DEFAULT_CSV_TITLE = "no-date-range-provided-lead-pro.csv";

export const TITLE_PREFIX_OFFICE_LEADERBOARD = "Office leaderboard";

export const TITLE_PREFIX_LEADS_BY_STAGE = "Leads by office by stage";

export const ACCOUNT_OFFICE_ACTIVITY_TITLE =
  "Offices lead source portal connections";

export const DEFAULT_CSV_OPTIONS: Options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  title: DEFAULT_CSV_TITLE,
};

export const OFFICE_LEADS_BY_STAGE_CSV_HEADERS = [
  "Office",
  "Type",
  "Unread",
  "In Progress",
  "Business",
  "No Business",
];

export const OFFICE_LEADERBOARD_CSV_HEADERS = [
  "Office",
  "Total Leads",
  "Response Time",
  "Business Won",
  "Overall Score",
];
