import { MarkerType } from "reactflow";
import { QuestionnaireBranchingEdgeTypes } from "../enums/questionnaire-branching.enum";
import { TSelectOption } from "../types/select-input.type";
import {
  FormLogicJump,
  FormLogicJumpAlways,
  FormLogicJumpOperator,
} from "@leadpro/forms";

export const COMMON_EDGE_SETTINGS = {
  type: QuestionnaireBranchingEdgeTypes.JUMP_EDGE,
  animated: true,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: "#3B68AA", // leadpro.600
  },
  style: {
    strokeWidth: 2,
    stroke: "#3B68AA", // leadpro.600
  },
};

export const OPERATOR_LABEL: Record<FormLogicJumpOperator, string> = {
  [FormLogicJumpOperator.IS]: "With",
  [FormLogicJumpOperator.ALWAYS]: "Always",
};

export const OPERATOR_OPTIONS: TSelectOption<FormLogicJumpOperator>[] = [
  {
    label: OPERATOR_LABEL[FormLogicJumpOperator.IS],
    value: FormLogicJumpOperator.IS,
  },
  {
    label: OPERATOR_LABEL[FormLogicJumpOperator.ALWAYS],
    value: FormLogicJumpOperator.ALWAYS,
  },
];

export const DEFAULT_JUMP_DATA: Record<
  FormLogicJumpOperator,
  (from: string, to: string) => FormLogicJump
> = {
  [FormLogicJumpOperator.ALWAYS]: (from: string, to: string) =>
    ({
      to: to,
      operator: FormLogicJumpOperator.ALWAYS,
    } as FormLogicJumpAlways),
  [FormLogicJumpOperator.IS]: (from: string, to: string) => {
    return {
      field: from,
      to: to,
      operator: FormLogicJumpOperator.IS,
      value: "",
    };
  },
};

export const QUESTIONNAIRE_BRANCHING_END_NODE = "$end";
