import { AccountDevelopersApi } from "api/account-developers.api";
import { AppStore } from "store/App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountDevelopersApiKeyConfigStore } from "./AccountDevelopersApiKeyConfig.store";
import { keyBy } from "lodash";
import { TAccountDevelopersApiKeyConfig } from "types/account-developers.type";

const developersApi = new AccountDevelopersApi();

export class AccountDevelopersApiKeysStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private apiKeyConfigStores: IObservableArray<
    AccountDevelopersApiKeyConfigStore
  >;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.apiKeyConfigStores = observable.array<
      AccountDevelopersApiKeyConfigStore
    >();
  }

  get apiKeyConfigStoresArray() {
    return this.apiKeyConfigStores.slice();
  }

  get apiKeyConfigStoresMap() {
    return keyBy(this.apiKeyConfigStoresArray, store => store.apiKeyConfig.id);
  }

  get apiKeyConfigDataArray() {
    return this.apiKeyConfigStoresArray.map(store => store.apiKeyConfig);
  }

  get apiKeyConfigDataMap() {
    return keyBy(this.apiKeyConfigDataArray, apiConfig => apiConfig.id);
  }

  private setAllApiKeyConfigs(apiKeyConfigs: TAccountDevelopersApiKeyConfig[]) {
    this.apiKeyConfigStores.replace(
      apiKeyConfigs.map(
        apiKeyConfig =>
          new AccountDevelopersApiKeyConfigStore(
            this.root,
            this.accountId,
            apiKeyConfig
          )
      )
    );
  }

  private addApiKeyConfig(apiKeyConfig: TAccountDevelopersApiKeyConfig) {
    this.apiKeyConfigStores.push(
      new AccountDevelopersApiKeyConfigStore(
        this.root,
        this.accountId,
        apiKeyConfig
      )
    );
  }

  private removeApiKeyConfig(apiKeyConfigId: number) {
    this.apiKeyConfigStores.replace(
      this.apiKeyConfigStores.filter(
        store => store.apiKeyConfig.id !== apiKeyConfigId
      )
    );
  }

  public async fetchAllApiKeyConfigs() {
    const apiKeyConfigs = await developersApi.fetchAllAccountDevelopersApiKeyConfigs(
      this.accountId
    );
    this.setAllApiKeyConfigs(apiKeyConfigs);
  }

  public async createApiKeyConfig(
    apiKeyConfigData: Partial<TAccountDevelopersApiKeyConfig>
  ) {
    const apiKeyConfig = await developersApi.createAccountDevelopersApiKeyConfig(
      this.accountId,
      apiKeyConfigData
    );
    this.addApiKeyConfig(apiKeyConfig);
  }

  public async deleteApiKeyConfig(apiKeyConfigId: number) {
    await developersApi.deleteAccountDevelopersApiKeyConfig(
      this.accountId,
      apiKeyConfigId
    );
    this.removeApiKeyConfig(apiKeyConfigId);
  }

  public async fetchApiKeyConfig(apiKeyConfigId: number) {
    if (!!this.apiKeyConfigStoresMap[apiKeyConfigId]) {
      await this.apiKeyConfigStoresMap[apiKeyConfigId].fetchDetails();
    } else {
      const apiKeyConfigData = await developersApi.fetchAccountDevelopersApiKeyConfig(
        this.accountId,
        apiKeyConfigId
      );
      this.addApiKeyConfig(apiKeyConfigData);
    }
  }
}
