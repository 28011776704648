import {
  IAccountOfficePortalActivity,
  IPortalLinkStatus,
  TOfficeActivitySupportedLeadTypes,
} from "types/account-portal-link-status-per-office.type";
import { TPortalSelectOptionData } from "types/portal.type";
import outdent from "outdent";
import { TAccountOfficeData } from "types/account-office.type";
import { TUserAccountData } from "types/user-account.type";

export const transformOfficeActivityTypeEntriesIntoArray = (
  officeActivityTypes: {
    [key in TOfficeActivitySupportedLeadTypes]?: IPortalLinkStatus[];
  }
) => {
  const newOfficeActivityTypes: {
    [key in TOfficeActivitySupportedLeadTypes]?: IPortalLinkStatus[];
  } = {};
  (Object.keys(
    officeActivityTypes
  ) as TOfficeActivitySupportedLeadTypes[]).forEach(
    (type: TOfficeActivitySupportedLeadTypes) => {
      newOfficeActivityTypes[type] = Object.values(
        officeActivityTypes[type] || {}
      );
    }
  );

  return newOfficeActivityTypes;
};

export const getProblematicOfficeActivityLeadTypes = (
  officeActivity: IAccountOfficePortalActivity
) => {
  const problematicTypes: TOfficeActivitySupportedLeadTypes[] = [];

  (Object.keys(
    officeActivity.types
  ) as TOfficeActivitySupportedLeadTypes[]).forEach(
    (type: TOfficeActivitySupportedLeadTypes) => {
      const hasProblematicType = !!officeActivity.types[type]!.find(
        entry => entry.lastReceived === null
      );

      if (hasProblematicType) {
        problematicTypes.push(type);
      }
    }
  );

  return problematicTypes;
};

export const generateEmailWithOfficePortalLinkIssues = (
  officesWithIssues: Pick<TAccountOfficeData, "name" | "portalEmail">[],
  account?: TUserAccountData,
  portalOptions?: TPortalSelectOptionData[]
) => {
  const emailData = {
    to: "",
    subject: "",
    body: "",
    all: "",
  };

  if (!account || !portalOptions) return emailData;

  const selectedPortalsEmails = portalOptions.flatMap(portal => {
    return account.portalIds.includes(portal.data.id) ? portal.data.email : [];
  });

  const body = outdent`
    Dear Sir or Madam,
    
    Please can you update our lead email address for: sales viewings, let viewings, all types of sales valuation and lettings valuation leads, including local valuation alerts and Rightmove discover leads to:
    
    Brand: ${account.name}
    
    ${officesWithIssues.map((office, index) => {
      return `\n${index + 1}. ${office.name} to ${office.portalEmail}`;
    })}

    
    Kind regards,
    `;

  emailData.to = selectedPortalsEmails.join("; ");
  emailData.subject = "Update Lead Email Address";
  emailData.body = encodeURIComponent(body);
  emailData.all = outdent`
      To: ${emailData.to}\n
      
      Subject: ${emailData.subject}\n
      
      ${body}
    `;

  return emailData;
};
