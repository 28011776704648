import { NoDataTableView } from "./NoDataTableView";
import { RowStub } from "../RowStub";
import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { ITableV2Column } from "../table-v2";

interface ITableV2ContentProps<T> {
  dataSource: T[];
  columns: Array<ITableV2Column<T>>;
  gridTemplateColumns: string;
  striped?: boolean;
  onRowClick?: (row: T) => void;
}

export function TableV2Content<T>({
  dataSource,
  columns,
  gridTemplateColumns,
  striped,
  onRowClick,
}: ITableV2ContentProps<T>) {
  // EMPTY VIEW
  if (!dataSource.length) {
    return <NoDataTableView />;
  }

  // ROWS
  return (
    <>
      {dataSource.map((row: T, index) => {
        const additionalProps: BoxProps = {};
        if (striped) {
          additionalProps.bg = index % 2 === 0 ? "leadpro.50" : undefined;
        }

        if (!!onRowClick) {
          additionalProps.onClick = () => onRowClick(row);
          additionalProps.cursor = "pointer";
          additionalProps._hover = {
            bg: "blue.50",
          };
        }

        return (
          <RowStub
            key={index}
            columnsCount={columns.length}
            gridTemplateColumns={gridTemplateColumns}
            {...additionalProps}
          >
            {columns.map((column, index) => (
              <Box key={index} overflow={"hidden"}>
                {column.Cell({
                  row,
                })}
              </Box>
            ))}
          </RowStub>
        );
      })}
    </>
  );
}
