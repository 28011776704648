import { FC } from "react";
import { Box } from "@chakra-ui/react";
import { TContactNote } from "types/lead.type";

interface IProps {
  note: TContactNote;
}

export const LeadActivityNoteContent: FC<IProps> = ({ note }) => {
  return (
    <Box fontSize={"sm"}>
      <Box color={"gray.500"}>added a note</Box>
      <Box
        padding={3}
        border={"1px solid"}
        borderRadius={"md"}
        borderColor={"gray.100"}
        bg={"white"}
        boxShadow={"sm"}
        overflow={"hidden"}
        wordBreak={"break-word"}
      >
        {note.content}
      </Box>
    </Box>
  );
};
