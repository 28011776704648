import { FormLogicJump, FormLogicJumpIs } from "@leadpro/forms";
import { FormFieldType } from "@leadpro/forms";
import { Box } from "@chakra-ui/react";
import { Input, SingleSelectInput } from "components";
import React, { ChangeEvent, FC } from "react";
import { FormFieldRadio } from "@leadpro/forms/dist/types/fields/form-field-radio";
import { TSelectValue } from "types/select-input.type";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";

interface IProps {
  fromNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  toNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  formLogicJump: FormLogicJumpIs;
  onChange: (formLogicJump: FormLogicJump | null) => void;
}

export const LogicElementJumpConfiguratorIs: FC<IProps> = ({
  fromNode,
  toNode,
  formLogicJump,
  onChange,
}) => {
  const fromField = fromNode.data.field;
  const { value } = formLogicJump;

  const fromQuestionType = fromField.type;
  switch (fromQuestionType) {
    case FormFieldType.TEXT:
    case FormFieldType.TEXT_AREA:
    case FormFieldType.POSTCODE:
    case FormFieldType.EMAIL:
    case FormFieldType.PHONE:
      return (
        <Box>
          <Input
            value={value}
            placeholder={"some text here"}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange({ ...formLogicJump, value: event.target.value })
            }
          />
        </Box>
      );
    case FormFieldType.RADIO:
      const options = (fromField as FormFieldRadio).options;
      return (
        <Box>
          <SingleSelectInput<string>
            value={value}
            onChange={(value: TSelectValue<string>) => {
              if (!!value) onChange({ ...formLogicJump, value });
            }}
            options={options}
            placeholder={"Select answer"}
          />
        </Box>
      );
    default:
      return null;
  }
};
