import React, { FC } from "react";
import { Avatar, Box, HStack, VStack } from "@chakra-ui/react";
import { NA, Tooltip } from "components";
import { TUserBasicData } from "types/user-data.type";
import { getUserFullName } from "utils/account-user.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/pro-regular-svg-icons";

const ICON_SIZE = 16;

interface ILeadAssigneeCellProps {
  user: TUserBasicData | null;
}

export const LeadAssigneeCell: FC<ILeadAssigneeCellProps> = ({ user }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      {!user && <NA verb={"assigned"} />}
      {!!user && (
        <Tooltip
          label={
            <VStack alignItems={"flex-start"} spacing={1} fontSize={"sm"}>
              <HStack spacing={1}>
                <FontAwesomeIcon icon={faUser} fontSize={ICON_SIZE} />
                <Box textTransform={"capitalize"}>
                  {getUserFullName(user.firstName, user.lastName) || <NA />}
                </Box>
              </HStack>
              <HStack>
                <FontAwesomeIcon icon={faEnvelope} fontSize={ICON_SIZE} />
                <Box>{user.email}</Box>
              </HStack>
            </VStack>
          }
        >
          <HStack maxWidth={"100%"} spacing={2}>
            <Avatar
              name={getUserFullName(user.firstName, user.lastName, user.email)}
              size={"xs"}
            />
            <Box noOfLines={1}>
              {getUserFullName(user.firstName, user.lastName, user.email)}
            </Box>
          </HStack>
        </Tooltip>
      )}
    </Box>
  );
};
