import React, { useMemo } from "react";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  getOfficeLeaderbordStats,
  IOfficeLeaderboardStats,
} from "utils/office-leaderboard.utils";
import { orderBy } from "lodash";
import { OfficesLeagueList } from "./OfficesLeagueList";
import { useTheme, Box } from "@chakra-ui/react";
import { TitleStatCard } from "components/stats/TitleStatCard";
import { TOfficeLeaderboardDataPoint } from "types/account-analytics.type";

interface IProps {
  data: TOfficeLeaderboardDataPoint[];
  onItemClick: (officeStats: IOfficeLeaderboardStats) => void;
}

export const OfficesLeagueBoard: React.FC<IProps> = ({ data, onItemClick }) => {
  const theme = useTheme();

  const allTypes = useMemo(() => {
    const types = new Set<LeadTypeEnum>();
    data.forEach(dataPoint => {
      Object.keys(dataPoint.stats).forEach(key => {
        types.add(key as LeadTypeEnum);
      });
    });

    return types;
  }, [data]);

  const {
    officeDataPoints,
    bestMaxAverageResponseTimeDataPoint,
    bestConversionRateDataPoint,
  } = useMemo(() => {
    const {
      dataPoints,
      bestMaxAverageResponseTimeDataPoint,
      bestConversionRateDataPoint,
    } = getOfficeLeaderbordStats(data, Array.from(allTypes));

    return {
      officeDataPoints: orderBy(
        dataPoints,
        dataPoint => dataPoint.overallScore,
        "desc"
      ),
      bestMaxAverageResponseTimeDataPoint,
      bestConversionRateDataPoint,
    };
  }, [allTypes, data]);

  const statCards = useMemo(() => {
    return (
      <>
        {bestConversionRateDataPoint && (
          <Box flexBasis={"50%"} mr={2}>
            <TitleStatCard
              title={"Best closer"}
              titleColor={theme.colors.purple[500]}
              value={bestConversionRateDataPoint.office.name}
              description={`${bestConversionRateDataPoint.conversionRate}% conversion rate`}
            />
          </Box>
        )}
        {bestMaxAverageResponseTimeDataPoint && (
          <Box flexBasis={"50%"} ml={2}>
            <TitleStatCard
              title={"Most responsive"}
              titleColor={theme.colors.green[500]}
              value={bestMaxAverageResponseTimeDataPoint.office.name}
              description={`Response speed score of ${bestMaxAverageResponseTimeDataPoint.averageResponseTime}`}
            />
          </Box>
        )}
      </>
    );
  }, [
    bestConversionRateDataPoint,
    bestMaxAverageResponseTimeDataPoint,
    theme.colors.green,
    theme.colors.purple,
  ]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      height={"100%"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        mb={5}
      >
        {statCards}
      </Box>
      <Box flexGrow={1} overflowY={"auto"}>
        <OfficesLeagueList
          officesStats={officeDataPoints}
          onItemClick={onItemClick}
        />
      </Box>
    </Box>
  );
};
