import React, { useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  useTheme,
  Text,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { FormControlV2, Loader } from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR } from "styles/input-default-pseudobox-behaviour";
import { PaymentMethod } from "@stripe/stripe-js";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  ActionPromptContainer,
  ApiMessageStack,
  Bullet,
  ExternalLink,
} from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { LEADPRO_WEBSITE_LEGAL_URL } from "constants/external-paths";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  billingName: string;
};

const initialValues: TFieldValues = {
  billingName: "",
};

interface IProps {
  header: string;
  closePrompt: () => void;
  onSuccess?: () => void;
}

const validationSchema = Yup.object().shape({
  billingName: Yup.string().required(REQUIRED_FIELD),
});

export const PaymentMethodPrompt: React.FC<IProps> = observer(
  ({ header, closePrompt, onSuccess }) => {
    const theme = useTheme();
    const toast = useToast();
    const stripe = useStripe();
    const elements = useElements();
    const {
      userAccountsStore: { selectedAccountStore },
    } = useAppStore();
    const accountBillingStore = selectedAccountStore?.accountBillingStore;

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const cardElementOptions = useMemo(() => {
      return {
        style: {
          base: {
            fontFamily: theme.fonts.body,
            fontSize: theme.fontSizes.xl,
            fontWeight: theme.fontWeights.normal,
            color: theme.colors.gray[600],
            "::placeholder": {
              color: theme.colors.gray[300],
            },
          },
          invalid: {
            color: theme.colors.red[600],
          },
        },
      };
    }, [theme]);

    const handleAddPaymentMethodSuccess = async (
      paymentMethod: PaymentMethod
    ) => {
      if (!accountBillingStore) return;

      await accountBillingStore.updatePaymentMethod(paymentMethod.id);

      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: "You have successfully set a payment method.",
      });

      closePrompt();
      onSuccess?.();
    };

    const onSubmit = async (values: TFieldValues) => {
      const { billingName } = values;
      if (!!stripe && !!elements) {
        try {
          const cardElement = elements.getElement(CardElement);
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement!,
            billing_details: {
              name: billingName,
            },
          });

          if (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          } else if (paymentMethod) {
            await handleAddPaymentMethodSuccess(paymentMethod);
          }
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      }
    };

    return (
      <ActionPromptContainer
        header={header}
        body={
          <Box pt={5}>
            {!!stripe && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack mb={3}>
                  <Bullet>No fixed-term contract – cancel anytime</Bullet>
                </Stack>
                <Text color="gray.500" fontSize="sm">
                  All prices exclude VAT. Full Terms &amp; Conditions available{" "}
                  <ExternalLink
                    href={LEADPRO_WEBSITE_LEGAL_URL}
                    textDecoration="underline"
                    label="here"
                  />
                  .
                </Text>
                <Divider my={4} />
                <Box mb={5}>
                  <FormControlV2<TFieldValues>
                    name={"billingName"}
                    control={control}
                    label={"Billing name"}
                    type={FormControlsTypeEnum.TEXT}
                    additionalProps={{
                      placeholder: "First and last name",
                    }}
                  />
                </Box>
                <Box mb={5}>
                  <Box
                    borderRadius={"md"}
                    p={2}
                    border={"1px solid"}
                    borderColor={"gray.300"}
                    {...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR}
                  >
                    <CardElement options={cardElementOptions} />
                  </Box>
                </Box>
                <Divider mb={4} />
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    type={"submit"}
                    colorScheme={"blue"}
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            )}
            {!stripe && (
              <Box position={"relative"} width={"100%"} height={"200px"}>
                <Loader />
              </Box>
            )}
          </Box>
        }
      />
    );
  }
);
