import React from "react";
import { Box, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { PageCard } from "./PageCard/PageCard";
import { PageToolPagesStore } from "../PageToolPages.local-store";
import { observer } from "mobx-react";
import { TPageData } from "types/page.type";

interface IProps {
  pageToolPagesStore: PageToolPagesStore;
  additionalMenuItemsRenderer?: (page: TPageData) => React.ReactNode;
}

export const PageList: React.FC<IProps> = observer(
  ({ pageToolPagesStore, additionalMenuItemsRenderer }) => {
    const pages = pageToolPagesStore.pagesArray;

    if (!pages.length) {
      return (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text color={"gray.500"}>No pages.</Text>
        </Box>
      );
    }

    return (
      <Wrap spacing={4}>
        {pages.map(page => (
          <WrapItem key={page.id}>
            <PageCard
              pageToolPagesStore={pageToolPagesStore}
              page={page}
              additionalMenuItemsRenderer={additionalMenuItemsRenderer}
            />
          </WrapItem>
        ))}
      </Wrap>
    );
  }
);
