import {
  TLeadEventChangeData,
  TLeadUpdatedEventData,
} from "types/leadpro-event.type";
import { FC, useCallback, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { LeadUpdatedEventChangeFieldEnum } from "enums/lead-updated-event-change-field.enum";
import { getUserFullName } from "utils/account-user.utils";
import { stageColors } from "constants/colors";
import { DATE_FORMAT } from "constants/date";
import moment from "moment";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { LeadActivityEventContent } from "./LeadActivityEventContent";

interface IProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
  event: TLeadUpdatedEventData;
}

export const LeadUpdatedEventContent: FC<IProps> = observer(
  ({ accountStore, leadStore, event }) => {
    const officesStore = accountStore.accountOfficesStore;
    const accountOfficesDataMap = officesStore.accountOfficesDataMap;
    const assignableUsersForSingleLeadMap = leadStore.assignableUsersMap;
    const leadStatusesMapByStatusId =
      accountStore.accountLeadStatusesStore.leadStatusesMapByStatusId;

    const getFieldDataLabels = useCallback(
      (change: TLeadEventChangeData<LeadUpdatedEventChangeFieldEnum>) => {
        switch (change.name) {
          case LeadUpdatedEventChangeFieldEnum.STAGE:
            // ignore stage for now
            return null;
          case LeadUpdatedEventChangeFieldEnum.STATUS_ID:
          case LeadUpdatedEventChangeFieldEnum.LEAD_STATUS_ID:
            const oldStatus = !!change.oldValue
              ? leadStatusesMapByStatusId[change.oldValue as number]
              : null;
            const newStatus = !!change.newValue
              ? leadStatusesMapByStatusId[change.newValue as number]
              : null;
            return {
              name: "changed status",
              oldValue: (
                <Box
                  color={
                    !!oldStatus
                      ? `${stageColors[oldStatus.stageId]}.500`
                      : undefined
                  }
                >
                  {oldStatus?.name || "None"}
                </Box>
              ),
              newValue: (
                <Box
                  color={
                    !!newStatus
                      ? `${stageColors[newStatus.stageId]}.500`
                      : undefined
                  }
                >
                  {newStatus?.name || "None"}
                </Box>
              ),
            };
          case LeadUpdatedEventChangeFieldEnum.ASSIGNED_TO_ID:
            const oldUser = !!change.oldValue
              ? assignableUsersForSingleLeadMap[change.oldValue as number]
              : null;
            const newUser = !!change.newValue
              ? assignableUsersForSingleLeadMap[change.newValue as number]
              : null;

            return {
              name: "changed assignee",
              oldValue: oldUser
                ? getUserFullName(
                    oldUser.firstName,
                    oldUser.lastName,
                    oldUser.email
                  )
                : !!change.oldValue
                ? change.oldValue
                : "None",
              newValue: newUser
                ? getUserFullName(
                    newUser.firstName,
                    newUser.lastName,
                    newUser.email
                  )
                : !!change.newValue
                ? change.newValue
                : "None",
            };
          case LeadUpdatedEventChangeFieldEnum.OFFICE_ID:
            const oldOffice = !!change.oldValue
              ? accountOfficesDataMap[change.oldValue as number]
              : null;
            const newOffice = !!change.newValue
              ? accountOfficesDataMap[change.newValue as number]
              : null;

            return {
              name: "changed office",
              oldValue: oldOffice?.name || "None",
              newValue: newOffice?.name || "None",
            };
          case LeadUpdatedEventChangeFieldEnum.NEXT_ACTION_DATE:
            return {
              name: "changed next action date",
              oldValue: !!change.oldValue
                ? moment(change.oldValue).format(DATE_FORMAT)
                : "None",
              newValue: !!change.newValue
                ? moment(change.newValue).format(DATE_FORMAT)
                : "None",
            };
          default:
            return null;
        }
      },

      [
        accountOfficesDataMap,
        assignableUsersForSingleLeadMap,
        leadStatusesMapByStatusId,
      ]
    );

    const changeLabels = useMemo(() => {
      return event.changes.map(
        (
          change: TLeadEventChangeData<LeadUpdatedEventChangeFieldEnum>,
          index
        ) => {
          return getFieldDataLabels(change);
        }
      );
    }, [event, getFieldDataLabels]);

    return (
      <Box fontSize={"sm"}>
        {changeLabels.map((changeLabel, index) => {
          if (!changeLabel) return null;

          return (
            <LeadActivityEventContent
              key={event.id + index}
              name={changeLabel.name}
              oldValue={changeLabel.oldValue}
              newValue={changeLabel.newValue}
            />
          );
        })}
      </Box>
    );
  }
);
