import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { TAutocallerTemplate } from "types/autocaller.type";
import {
  TTemplateMergeTag,
  TTemplateMergeTagGroup,
} from "types/template-merge-tag.type";
import { SuggestionDataItem } from "react-mentions";
import { AccountAutocallerApi } from "api/account-autocaller.api";

const autocallerApi = new AccountAutocallerApi();

export class AccountAutocallerTemplateStore {
  private readonly accountId: number;
  private readonly mergeTagGroups: IObservableArray<TTemplateMergeTagGroup>;
  private autocallerTemplate: TAutocallerTemplate | null;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.mergeTagGroups = observable.array<TTemplateMergeTagGroup>();
    this.autocallerTemplate = null;
  }

  get mergeTagGroupsArray() {
    return toJS(this.mergeTagGroups);
  }

  get mergeTagsFlatArray() {
    const mergeTags: TTemplateMergeTag[] = [];
    this.mergeTagGroups.forEach(mergeTagGroup =>
      mergeTags.push(...mergeTagGroup.items)
    );

    return mergeTags;
  }

  get templateContent() {
    return !this.autocallerTemplate
      ? ""
      : this.autocallerTemplate.template ||
          this.autocallerTemplate.defaultTemplate;
  }

  get isDefaultTemplateUsed() {
    return !this.autocallerTemplate?.template;
  }

  get mergeTagGroupOptionsArray(): SuggestionDataItem[] {
    const options: SuggestionDataItem[] = [];
    this.mergeTagGroups.forEach(group => {
      group.items.forEach(tag => {
        options.push({
          id: tag.value,
          display: group.name + " - " + tag.text,
        });
      });
    });

    return options;
  }

  private setMergeTagGroups(mergeTagGroups: TTemplateMergeTagGroup[]) {
    this.mergeTagGroups.replace(mergeTagGroups);
  }

  private setAutocallerTemplate(autocallerTemplate: TAutocallerTemplate) {
    this.autocallerTemplate = autocallerTemplate;
  }

  public async loadAutocallerTemplate() {
    const autocallerTemplate = await autocallerApi.fetchAccountAutocallerTemplate(
      this.accountId
    );
    this.setAutocallerTemplate(autocallerTemplate);
  }

  public async loadAutocallerTemplateMergeTags() {
    const mergeTagGroups = await autocallerApi.fetchAccountAutocallerTemplateMergeTags(
      this.accountId
    );
    this.setMergeTagGroups(mergeTagGroups);
  }

  public async updateTemplate(template: string | null) {
    const autocallerTemplate = await autocallerApi.updateAutocallerTemplate(
      this.accountId,
      template
    );
    this.setAutocallerTemplate(autocallerTemplate);
  }
}
