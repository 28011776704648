import { Box, HStack } from "@chakra-ui/react";
import React from "react";

interface IProps {
  background: string;
  topSection: React.ReactNode;
  bottomSection: React.ReactNode;
}

export const SplashScreen: React.FC<IProps> = ({
  background,
  topSection,
  bottomSection,
}) => {
  return (
    <Box width={"100%"} height={"100%"} overflow={"auto"}>
      <Box position={"relative"}>
        <Box
          position={"absolute"}
          top={0}
          width={"100%"}
          bottom={"100px"}
          background={background}
          zIndex={"-1"}
          _after={
            {
              position: "absolute",
              bottom: "-100px",
              height: "100px",
              width: "100%",
              background,
              content: `""`,
              clipPath: "polygon(0 0, 100% 0, 0% 96%, 0 100%)",
            } as any
          }
        />
        <HStack
          px={6}
          py={12}
          pb={0}
          maxW={"6xl"}
          margin={"auto"}
          justify={"space-between"}
          wrap={"wrap"}
          align={"flex-start"}
        >
          {topSection}
        </HStack>
      </Box>
      <Box px={6} py={12} maxW={"6xl"} margin={"auto"}>
        {bottomSection}
      </Box>
    </Box>
  );
};
