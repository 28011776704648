import * as Yup from "yup";
import { UK_POSTCODE_REGEX_WITH_ELEMENTS } from "utils/regexs.utils";
import {
  getInvalidUKPostcodeMessage,
  INVALID_PHONE_NUMBER,
  OFFICE_POSTCODE_LIMIT,
  REQUIRED_FIELD,
  SELECT_AT_LEAST_ONE,
} from "constants/validator-messages";
import { isValidPhone } from "utils/custom-yup-validators.utils";

export const officeDetailsValidationSchema = Yup.object()
  .shape({
    website: Yup.string().url(),
    postcodes: Yup.array()
      .of(
        Yup.string().matches(
          UK_POSTCODE_REGEX_WITH_ELEMENTS,
          getInvalidUKPostcodeMessage
        )
      )
      .min(1, REQUIRED_FIELD)
      .max(150, OFFICE_POSTCODE_LIMIT),
    ignoreEmailRouting: Yup.boolean(),
    isSales: Yup.boolean(),
    isLettings: Yup.boolean(),
    isMortgage: Yup.boolean(),
    salesEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    lettingsEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    vendorEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    landlordEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    salesPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    lettingsPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    vendorPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    landlordPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    mortgagePhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
  })
  .test("atLeastOneChecked", "", obj => {
    if (obj.isSales || obj.isLettings || obj.isMortgage) {
      return true;
    }

    return new Yup.ValidationError(SELECT_AT_LEAST_ONE, null, "isMortgage");
  });
