import { createIcon } from "@chakra-ui/react";
import { withIconProps } from "utils/hoc/chakra-svg-icon.hoc";

export const InProgressCreateIcon = createIcon({
  displayName: "InProgressIcon",
  viewBox: "0 0 10 11",
  path: (
    <path
      id="Polygon 2"
      d="M3.72848 1.61347C4.01299 0.89721 4.96666 0.758454 5.44348 1.36394L9.72421 6.79979C9.89974 7.02269 9.97233 7.30967 9.92389 7.58922L9.67144 9.04605C9.59654 9.47827 9.2487 9.81122 8.81361 9.86714L1.68341 10.7836C0.927656 10.8808 0.34527 10.1308 0.626558 9.42262L3.72848 1.61347Z"
    />
  ),
});

export const InProgressIcon = withIconProps(InProgressCreateIcon);
