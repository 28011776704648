import React, { useCallback, useState } from "react";
import { HStack, Switch, useToast, VStack } from "@chakra-ui/react";
import { OfficeLogoInput } from "routes/dashboard/components";
import { FormControlMeta } from "components/form";
import { ApiMessageStack } from "components";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { TUserAccountData } from "types/user-account.type";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { observer } from "mobx-react";

interface IProps {
  account: TUserAccountData;
  officeStore: AccountOfficeStore;
}

export const OfficeLogoUploadForm: React.FC<IProps> = observer(
  ({ account, officeStore }) => {
    const toast = useToast();
    const officeData = officeStore.office;
    const [useCustomLogo, setUseCustomLogo] = useState<boolean>(
      !account.logoUrl || !!officeData.logoUrl
    );

    const onUpdate = () => {
      setUseCustomLogo(!account.logoUrl || !!officeData.logoUrl);
    };

    const handleToggleCustomOfficeLogo = useCallback(async () => {
      try {
        await officeStore.update({
          logoUrl: officeData.logoUrl ? null : account.logoUrl,
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [account, officeStore, officeData, toast]);

    return (
      <VStack spacing={5}>
        <HStack spacing={2} align={"flex-start"} width={"100%"}>
          <Switch
            name={"use-office-logo"}
            isChecked={useCustomLogo}
            onChange={handleToggleCustomOfficeLogo}
          />
          <FormControlMeta
            label={"Use custom logo"}
            description={
              "Enable this if you want to override the default account logo."
            }
          />
        </HStack>
        <OfficeLogoInput
          account={account}
          officeStore={officeStore}
          onUpdate={onUpdate}
        />
      </VStack>
    );
  }
);
