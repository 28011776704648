import { GUIDES_ROUTE } from "./routes";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import {
  AUTOCALLER_SETUP_WEBINAR_URL,
  IVT_SETUP_WEBINAR_URL,
  LEAD_RESPONDER_SETUP_WEBINAR_URL,
} from "./external-paths";

export type ToolTours =
  | DashboardTourEnum.AUTOCALLER_TOUR
  | DashboardTourEnum.IVT_TOUR
  | DashboardTourEnum.LEAD_RESPONDER_TOUR;

export const TOOL_TOURS_LINKS: {
  [key in ToolTours]: {
    guideLink: string;
    webinarLink: string;
  };
} = {
  [DashboardTourEnum.AUTOCALLER_TOUR]: {
    guideLink: `${GUIDES_ROUTE}/setting-up-autocaller`,
    webinarLink: AUTOCALLER_SETUP_WEBINAR_URL,
  },
  [DashboardTourEnum.IVT_TOUR]: {
    guideLink: `${GUIDES_ROUTE}/turning-on-your-instant-valuation-tool`,
    webinarLink: IVT_SETUP_WEBINAR_URL,
  },
  [DashboardTourEnum.LEAD_RESPONDER_TOUR]: {
    guideLink: `${GUIDES_ROUTE}/setting-up-lead-responder`,
    webinarLink: LEAD_RESPONDER_SETUP_WEBINAR_URL,
  },
};
