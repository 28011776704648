import React, { useCallback, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { isEqual } from "lodash";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { observer } from "mobx-react";
import { CenteredMessage } from "components/message/CenteredMessage";
import {
  TLeadFilterMenuItemData,
  TLeadFilterMenuItemValue,
} from "types/leads-filter.type";
import { LeadFilterSimpleMenuItem } from "./LeadFilterSimpleMenuItem";
import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import { Box, HStack } from "@chakra-ui/react";
import { ResetLeadFilters } from "./ResetLeadFilters";

interface IProps {
  title?: string;
  optionLabel?: string;
  icon: React.ReactNode;
  filterType: LeadSimpleFilterTypes;
  availableOptions: TLeadFilterMenuItemData[];
  selectedOptions: TLeadFilterMenuItemValue[];
  appliedOptions?: TLeadFilterMenuItemValue[];
  handleFilterItemToggle: (value: any) => void;
  leadsFilterStore: AccountLeadsFiltersStore;
  showResetFilters?: boolean;
}

export const LeadFilterSimpleMenu: React.FC<IProps> = observer(
  ({
    title,
    optionLabel,
    icon,
    filterType,
    availableOptions,
    selectedOptions,
    appliedOptions,
    handleFilterItemToggle,
    leadsFilterStore,
    showResetFilters = true,
  }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const handleClose = useCallback(() => {
      if (!isEqual(selectedOptions, appliedOptions)) {
        leadsFilterStore.setActiveFromPendingFilter();
      }
    }, [leadsFilterStore, selectedOptions, appliedOptions]);

    const onMenuItemClick = useCallback(
      (value: TLeadFilterMenuItemValue) => () => {
        handleFilterItemToggle(value);
      },
      [handleFilterItemToggle]
    );

    const onMenuOpen = useCallback(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await leadsFilterStore.loadAvailableFilters(filterType);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [leadsFilterStore, filterType]);

    return (
      <DropdownFilterMenuShell
        title={title}
        icon={icon}
        badgeCount={selectedOptions.length}
        onOpen={onMenuOpen}
        onClose={handleClose}
        loadingStatus={loadingStatus}
      >
        <>
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            pl={5}
            pr={3}
            py={3}
          >
            <Box fontWeight={800} color={"leadpro.700"}>
              {optionLabel}
            </Box>
            {showResetFilters && (
              <ResetLeadFilters leadsFilterStore={leadsFilterStore} />
            )}
          </HStack>
          {!availableOptions.length && (
            <CenteredMessage
              height={"100px"}
              message={<DropdownNoFilterMessage />}
            />
          )}
          {!!availableOptions.length && (
            <Box maxHeight={"500px"} overflowY={"auto"}>
              {availableOptions.map((availableOption, index) => (
                <LeadFilterSimpleMenuItem
                  key={index}
                  availableOption={availableOption}
                  onMenuItemClick={onMenuItemClick}
                  selectedOptions={selectedOptions}
                />
              ))}
            </Box>
          )}
        </>
      </DropdownFilterMenuShell>
    );
  }
);
