import { DefaultRowCell } from "../DefaultRowCell";
import { AvatarTag } from "../../../tags";
import React, { useMemo } from "react";
import { TLeadResponderLeadEmailTableData } from "types/account-lead-emails.type";
import { TPortalData } from "types/portal.type";
import { Dictionary } from "lodash";

interface ILeadEmailPortalCellProps {
  email: TLeadResponderLeadEmailTableData;
  portalMap: Dictionary<TPortalData>;
}

export const LeadEmailPortalCell: React.FC<ILeadEmailPortalCellProps> = ({
  email,
  portalMap,
}) => {
  const portal = useMemo(() => {
    if (!email.portal) return;
    return portalMap[email.portal.toLowerCase()];
  }, [portalMap, email.portal]);

  const portalLogoUrl = useMemo(() => {
    if (!portal?.logoUrl) return;
    return portal.logoUrl;
  }, [portal]);

  const portalLabel = useMemo(() => {
    if (!email.portal) return "N/A";
    return email.portal;
  }, [email.portal]);

  return (
    <DefaultRowCell>
      <AvatarTag
        label={portalLabel}
        avatarUrl={portalLogoUrl}
        avatarStyling={{ bg: "transparent" }}
        colorScheme={"white"}
        bg={"leadpro.100"}
      />
    </DefaultRowCell>
  );
};
