import { TPortalData } from "types/portal.type";
import { toSpace } from "./to-snake-case.utils";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { DEFAULT_PORTAL_IMAGE } from "../constants/image-paths";

export function getLeadSourceLabel(
  source: string,
  leadSource?: TNormalizedLeadSourceData
) {
  return leadSource?.name || toSpace(source);
}

export const getLeadSourceLogoUrl = (
  accountLogoUrl: string | null,
  leadSource?: TNormalizedLeadSourceData,
  portal?: TPortalData
) => {
  const fallbackLogoUrl = accountLogoUrl || DEFAULT_PORTAL_IMAGE;
  if (!leadSource || !leadSource.portalId) return fallbackLogoUrl;

  const foundSourceLogoUrlFromPortal = portal?.logoUrl;
  return foundSourceLogoUrlFromPortal || fallbackLogoUrl;
};

export const orderSourceHosts = <T>(
  data: T[],
  otherIndex: number,
  orderFunc: (data: T[]) => T[]
) => {
  let otherValue;

  if (otherIndex > -1) {
    if (data.length < 2) {
      return [];
    }

    otherValue = data.splice(otherIndex, 1)[0];
  }

  const orderedData = orderFunc(data);

  if (otherValue) {
    orderedData.push(otherValue);
  }

  return orderedData;
};
