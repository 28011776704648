import {
  ApiMessageStack,
  FormControlMeta,
  FormControlV2,
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
} from "components";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  HStack,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ExportRequestList } from "./ExportRequestList";
import { AccountExportRequestsStore } from "store/UserAccounts/UserAccount/AccountExportStore/AccountExportRequestsStore.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { ExportSelectPeriodOption } from "components/select-input/custom-options/ExportSelectPeriodOption";
import { isDateCorrectFormat } from "utils/date.utils";
import { DATE_YEAR, DATE_YEAR_MONTH } from "constants/date";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const REFRESH_INTERVAL = 20000;
const DEFAULT_TITLE = "Export";

type TFieldValues = {
  period: string;
};

const initialValues: TFieldValues = {
  period: "",
};

const validationSchema = Yup.object().shape({
  period: Yup.string().required(REQUIRED_FIELD),
});

interface IProps {
  exportRequestsStore: AccountExportRequestsStore;
  title?: string;
}

export const Export: FC<IProps> = observer(
  ({ exportRequestsStore, title = DEFAULT_TITLE }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const refreshInterval = useRef<
      ReturnType<typeof setInterval> | undefined
    >();
    const toast = useToast();

    const exportRequests = exportRequestsStore.exportRequestsArray || [];
    const areTherePendingExportRequests =
      exportRequestsStore.areTherePendingExportRequests;
    const exportPeriodOptions = exportRequestsStore.exportPeriodOptions;

    const {
      handleSubmit,
      control,
      watch,
      reset,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const period = watch("period");

    const submitButtonLabel = useMemo(() => {
      if (isDateCorrectFormat(period, DATE_YEAR))
        return "Export the selected year";
      if (isDateCorrectFormat(period, DATE_YEAR_MONTH))
        return "Export the selected month";
      return "Export";
    }, [period]);

    const onSubmit = useCallback(
      async ({ period }: TFieldValues) => {
        try {
          if (!!period) {
            await exportRequestsStore.createExportRequest(period);
            reset();
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description:
                "We are preparing your export. Download will become automatically available once it is ready.",
            });
          }
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [exportRequestsStore, toast, reset]
    );

    const fetchExportRequests = useCallback(async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await exportRequestsStore.fetchExportRequests();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    }, [exportRequestsStore]);

    useEffect(() => {
      fetchExportRequests();
    }, [fetchExportRequests]);

    useEffect(() => {
      if (refreshInterval.current) clearInterval(refreshInterval.current);

      if (areTherePendingExportRequests) {
        refreshInterval.current = setInterval(
          fetchExportRequests,
          REFRESH_INTERVAL
        );
      }
    }, [areTherePendingExportRequests, fetchExportRequests]);

    return (
      <Panel overflow={"visible"}>
        <PanelHeader>
          <PanelTitle>{title}</PanelTitle>
        </PanelHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PanelBody>
            <VStack spacing={2} align={"flex-end"} mb={10}>
              <SimpleGrid columns={2} spacing={4}>
                <FormControlMeta
                  label={"Export to a CSV file"}
                  description={
                    "Select a period to export it’s data. When an export is requested it can take a few minutes to prepare. Once the export is ready to be downloaded it will appear here."
                  }
                />
                <FormControlV2<TFieldValues>
                  name={"period"}
                  control={control}
                  type={FormControlsTypeEnum.SINGLE_SELECT}
                  additionalProps={{
                    options: exportPeriodOptions,
                    placeholder: "Select date",
                    optionComponent: (
                      optionProps: IGetOptionPropsData<string>
                    ) => <ExportSelectPeriodOption optionProps={optionProps} />,
                  }}
                />
              </SimpleGrid>
              <Button
                type={"submit"}
                colorScheme={"blue"}
                isDisabled={isSubmitting || !period}
                isLoading={isSubmitting}
              >
                {submitButtonLabel}
              </Button>
              {!period && (
                <HStack>
                  <Box opacity={0.6}>
                    <FontAwesomeIcon icon={faInfoCircle} fontSize={16} />
                  </Box>
                  <Text fontSize={"xs"}>Select a export option</Text>
                </HStack>
              )}
            </VStack>
            <ExportRequestList
              exportRequests={exportRequests}
              loadingStatus={loadingStatus}
            />
          </PanelBody>
        </form>
      </Panel>
    );
  }
);
