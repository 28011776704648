import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

interface IContentfulTableProps {
  data: any;
}

const ContentfulTable: React.FC<IContentfulTableProps> = ({ data }) => {
  return (
    <SimpleGrid
      columns={data[0].length}
      rounded={"md"}
      border={"1px solid"}
      borderColor={"gray.100"}
      my={5}
    >
      {data[0].map((tableColumn: [], i: number) => (
        <Box key={i} bg={"gray.50"} p={3} fontWeight="bold">
          {tableColumn}
        </Box>
      ))}
      {data
        .slice(1)
        .flat()
        .map((tableRow: [], index: number) => {
          return (
            <Box
              key={index}
              borderBottom={"1px solid"}
              borderColor={"gray.100"}
              p={3}
            >
              {tableRow}
            </Box>
          );
        })}
    </SimpleGrid>
  );
};

export default ContentfulTable;
