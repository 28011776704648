import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { TReapitContactData } from "types/reapit.type";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IReapitRelatedContactOptionProps {
  optionProp: IGetOptionPropsData<string, TReapitContactData>;
  hoverEffect?: boolean;
}

export const ReapitRelatedContactsOption: FC<IReapitRelatedContactOptionProps> = ({
  optionProp,
  hoverEffect,
}) => {
  const { value, data } = optionProp.option;
  const formattedPhone = () => {
    const removedAreaCode = data?.phone?.replace("+44", "0") || "";
    return `${removedAreaCode.slice(0, 5)} ${removedAreaCode.slice(5)}`;
  };
  return (
    <Box
      key={optionProp.option.value}
      onClick={optionProp.onClick}
      py={1}
      px={2}
      fontSize={"14px"}
      minHeight={"56px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      cursor={"pointer"}
      _hover={hoverEffect ? { bg: "blue.50" } : { bg: "transparent" }}
    >
      {value === "newRecord" ? (
        <Box display={"flex"} gap={2}>
          <Box color={"black"}>Create a new contact record</Box>
        </Box>
      ) : (
        <>
          <Box display={"flex"} gap={2}>
            <Box color={"black"}>{data?.name}</Box>
            <Box color={"leadpro.500"}>{data?.email},</Box>
            <Box color={"leadpro.500"}>{formattedPhone()}</Box>
          </Box>
          <Box display={"inline"} color={"leadpro.400"}>
            {value}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReapitRelatedContactsOption;
