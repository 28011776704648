import { observer } from "mobx-react";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { AccountDevelopersApiKeyConfigStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersApiKeys/AccountDevelopersApiKeyConfig.store";
import {
  Box,
  Divider,
  Portal,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlMeta, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FormFooter } from "components/form";
import { developersConfigApiKeyValidation } from "utils/validation-schemas/developers-config-api-key.validation";
import { DevelopersConfigKeyItem } from "routes/dashboard/routes/settings/components/DevelopersConfigKeyItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  name: string;
};

interface IProps {
  accountDevelopersApiKeyConfigStore: AccountDevelopersApiKeyConfigStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const DevelopersConfigApiKeyDetails: FC<IProps> = observer(
  ({ accountDevelopersApiKeyConfigStore, containerRef }) => {
    const toast = useToast();
    const apiKeyConfig = accountDevelopersApiKeyConfigStore.apiKeyConfig;

    const initialValues: TFieldValues = useMemo(
      () => ({
        name: apiKeyConfig.name,
      }),
      [apiKeyConfig]
    );

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(developersConfigApiKeyValidation),
    });

    const onRegenerateKey = useCallback(() => {
      accountDevelopersApiKeyConfigStore.regenerateDevelopersApiKey();
    }, [accountDevelopersApiKeyConfigStore]);

    const onSubmit = async (data: TFieldValues) => {
      try {
        await accountDevelopersApiKeyConfigStore.updateApiKeyConfig(data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"API key configuration updated!"} />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    };

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={10} align={"stretch"} divider={<Divider />} mt={1}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"API key name"}
                description={
                  "A human-readable name to make it easier to distinguish between multiple API keys."
                }
              />
              <FormControlV2<TFieldValues>
                name={"name"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Enter API key name",
                }}
              />
            </SimpleGrid>
            <DevelopersConfigKeyItem
              label={"API key"}
              name={"api-key"}
              value={apiKeyConfig.apiKey}
              onRegenerateKey={onRegenerateKey}
            />
          </VStack>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </form>
      </Box>
    );
  }
);
