import { AccountReapitApi } from "api/account-reapit.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  TReapitLeadSource,
  TReapitLeadSourceRelation,
  TReapitLeadSourceRelationData,
  TReapitSyncLeadSourceExtendedData,
} from "types/reapit.type";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";

const accountReapitApi = new AccountReapitApi();

export class AccountIntegrationReapitLeadSourcesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountReapitLeadSources: IObservableArray<
    TReapitLeadSource
  >;
  private readonly accountReapitLeadSourceRelations: IObservableArray<
    TReapitLeadSourceRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountReapitLeadSources = observable.array<TReapitLeadSource>();
    this.syncStatusFilter = false;
    this.accountReapitLeadSourceRelations = observable.array<
      TReapitLeadSourceRelation
    >();
  }

  get reapitLeadSourceRelationsMapByLeadProLeadSourceId() {
    return keyBy(
      this.accountReapitLeadSourceRelations,
      relation => relation.leadSourceId
    );
  }

  get reapitLeadSourceRelationsMapByReapitLeadSourceId() {
    return keyBy(
      this.accountReapitLeadSourceRelations,
      relation => relation.reapitId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredReapitSyncLeadSourceData(): TReapitSyncLeadSourceExtendedData[] {
    return this.reapitSyncLeadSourceData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get reapitSyncLeadSourceData(): TReapitSyncLeadSourceExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproLeadSources =
      accountStore.accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    const reapitLeadSourceRelationsMapByLeadProLeadSourceId = this
      .reapitLeadSourceRelationsMapByLeadProLeadSourceId;

    return leadproLeadSources.map(leadSource => {
      let pairedReapitLeadSourceId =
        reapitLeadSourceRelationsMapByLeadProLeadSourceId[leadSource.id]
          ?.reapitId;
      let syncStatus = !!pairedReapitLeadSourceId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        leadSourceId: leadSource.id,
        leadSourceName: leadSource.name,
        reapitId: pairedReapitLeadSourceId,
        syncStatus: syncStatus,
      };
    });
  }

  get reapitLeadSourcesOptions() {
    return this.accountReapitLeadSources
      .map(reapitLeadSource => ({
        value: reapitLeadSource.reapitId,
        label: reapitLeadSource.reapitName,
        data: this.reapitLeadSourceRelationsMapByReapitLeadSourceId[
          reapitLeadSource.reapitId
        ],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountReapitLeadSources(leadSources: TReapitLeadSource[]) {
    this.accountReapitLeadSources.replace(leadSources);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public setAccountReapitLeadSourceRelations(
    relations: TReapitLeadSourceRelation[]
  ) {
    this.accountReapitLeadSourceRelations.replace(relations);
  }

  public async fetchAccountReapitLeadSources(officeGroupId?: number) {
    const data = await accountReapitApi.fetchAccountReapitLeadSources(
      this.accountId,
      officeGroupId
    );
    this.setAccountReapitLeadSources(data);
  }

  public async fetchAccountReapitLeadSourceRelations(officeGroupId?: number) {
    const data = await accountReapitApi.fetchAccountReapitLeadSourceRelations(
      this.accountId,
      officeGroupId
    );
    this.setAccountReapitLeadSourceRelations(data);
  }

  public async fetchAccountReapitLeadSourcesAndRelations(
    officeGroupId?: number
  ) {
    await Promise.all([
      this.fetchAccountReapitLeadSources(officeGroupId),
      this.fetchAccountReapitLeadSourceRelations(officeGroupId),
    ]);
  }

  public async updateAccountReapitSyncedLeadSources(
    syncedPairs: TReapitLeadSourceRelationData[],
    officeGroupId?: number
  ) {
    await accountReapitApi.updateAccountReapitSyncedLeadSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        reapitId: pair.reapitId,
        officeGroupId: officeGroupId ?? null,
      }))
    );
    await this.fetchAccountReapitLeadSourcesAndRelations(officeGroupId);
  }
}
