import React from "react";
import { Box } from "@chakra-ui/react";

interface IApiMessageStackProps {
  messageStack?: string | string[];
}

export const ApiMessageStack: React.FC<IApiMessageStackProps> = ({
  messageStack = "Error has occurred. Please try again.",
}) => {
  if (Array.isArray(messageStack)) {
    return (
      <>
        {messageStack.map((message, index) => (
          <Box key={index}>{message}</Box>
        ))}
      </>
    );
  }

  return <Box>{messageStack}</Box>;
};
