import React, { FC } from "react";
import { ProductInfo } from "../../../../components/ProductInfo/ProductInfo";
import { accountProductsConfigMap } from "constants/account-products-config";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { SplashTestimonial } from "routes/dashboard/routes/tools/components/SplashScreen/SplashTestimonial";
import { InstantValuationToolIntroVideo } from "./InstantValuationToolIntroVideo";
import { SplashScreen } from "../../../../components/SplashScreen/SplashScreen";
import { InstantValuationToolHowItWorks } from "./InstantValuationToolHowItWorks";
import { Divider, VStack } from "@chakra-ui/react";
import { InstantValuationToolPricing } from "./InstantValuationToolPricing";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PERRY_TESTIMONIAL } from "constants/image-paths";

const productConfig =
  accountProductsConfigMap[ApplicationProductEnum.INSTANT_VALUATION_TOOL];

interface IProps {
  accountStore: UserAccountStore;
}

export const InstantValuationToolSplashScreen: FC<IProps> = observer(
  ({ accountStore }) => {
    return (
      <SplashScreen
        background={"blue.50"}
        topSection={
          <>
            <ProductInfo
              productConfig={productConfig}
              header={"Book more valuations"}
              text={
                "Turn unknown website visitors and social media users into vendor and landlord leads."
              }
              finePrint={`This will add £${productConfig.pricing.flatFee} per month to your monthly bill. Includes ${productConfig.pricing.unitsIncluded} leads per month. Thereafter, leads outside the monthly allowance are charged at £${productConfig.pricing.pricePerExtraUnit} per lead.`}
              productColor={"blue"}
              colorScheme={"blue"}
              accountBillingStore={accountStore.accountBillingStore}
            />
            <InstantValuationToolIntroVideo />
          </>
        }
        bottomSection={
          <VStack spacing={12}>
            <InstantValuationToolHowItWorks />
            <Divider />
            <InstantValuationToolPricing />
            <Divider />
            <SplashTestimonial
              quote={
                "The Lead Pro instant online valuation tool is consistently getting me high quality new client leads."
              }
              author={"Perry Power, Power Bespoke"}
              authorImageUrl={PERRY_TESTIMONIAL}
              colorScheme={"green"}
            />
          </VStack>
        }
      />
    );
  }
);
