import { Box } from "@chakra-ui/react";
import { FC } from "react";

interface INAProps {
  verb?: string;
}

const NA: FC<INAProps> = ({ verb = "provided" }) => {
  return <Box color="gray.400">Not {verb}</Box>;
};

export default NA;
