import React, { useCallback, useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { PaymentMethodPrompt } from "routes/dashboard/components/prompts/PaymentMethodPrompt";
import { withStripe } from "utils/hoc/withStripe.hoc";
import NA from "components/na/NA";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";
import { observer } from "mobx-react";

interface IProps {
  accountBillingStore: AccountBillingStore;
}

export const AccountBillingPaymentMethod: React.FC<IProps> = observer(
  ({ accountBillingStore }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const accountBilling = accountBillingStore.billing;

    const actionTitle = useMemo(() => {
      return `${
        !!accountBilling?.stripePaymentMethodLast4 ? "Change" : "Add"
      } payment method`;
    }, [accountBilling]);

    const handleOpenCreditCardFormPrompt = useCallback(() => {
      setModal(
        withStripe(
          <PaymentMethodPrompt header={actionTitle} closePrompt={unSetModal} />
        )
      );
    }, [actionTitle, setModal, unSetModal]);

    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        py={5}
      >
        <Box color={"blue.500"} width={"200px"} pr={4} fontWeight={500}>
          Payment method
        </Box>

        <Box flexGrow={1}>
          {!!accountBilling?.stripePaymentMethodId ? (
            <Box>
              {`Card ending in ${accountBilling.stripePaymentMethodLast4}`}
            </Box>
          ) : (
            <NA />
          )}
        </Box>

        <Box>
          <Button
            onClick={handleOpenCreditCardFormPrompt}
            variant="link"
            size={"md"}
            colorScheme={"blue"}
          >
            Edit
          </Button>
        </Box>
      </Box>
    );
  }
);
