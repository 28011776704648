import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { keyBy } from "lodash";
import {
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "types/integration-sync-office.type";
import { AppStore } from "../../../App.store";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";

export type TIntegrationOffice = {
  id: string;
  name: string;
};

export interface IAccountIntegrationOfficeAPI {
  fetchAccountOffices?(
    accountId: number,
    officeGroupId?: number
  ): Promise<TIntegrationOffice[]>;
  fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]>;
  updateSyncedAccountOffices(
    accountId: number,
    data: TIntegrationOfficeRelationData[]
  ): Promise<void>;
}

export class AccountIntegrationOfficesStore {
  root: AppStore;
  accountId: number;
  accountIntegrationOffices: IObservableArray<TIntegrationOffice>;
  accountIntegrationOfficeRelations: IObservableArray<
    TIntegrationOfficeRelation
  >;
  private readonly api: IAccountIntegrationOfficeAPI;
  private syncStatusFilter: boolean;
  private selectedOfficeGroupId?: number;

  constructor(
    root: AppStore,
    accountId: number,
    api: IAccountIntegrationOfficeAPI
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.api = api;
    this.accountIntegrationOffices = observable.array<TIntegrationOffice>();
    this.syncStatusFilter = false;
    this.accountIntegrationOfficeRelations = observable.array<
      TIntegrationOfficeRelation
    >();
  }

  get officeRelationsMapByLeadProOfficeId() {
    return keyBy(
      this.accountIntegrationOfficeRelations,
      relation => relation.officeId
    );
  }

  get officeRelationsMapByIntegrationOfficeId() {
    return keyBy(
      this.accountIntegrationOfficeRelations,
      relation => relation.branchId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get integrationOfficesOptions() {
    return this.accountIntegrationOffices
      .map(integrationOffice => ({
        value: integrationOffice.id,
        label: integrationOffice.name,
        data: this.officeRelationsMapByIntegrationOfficeId[
          integrationOffice.id
        ] as TIntegrationOfficeRelation,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  get filteredAccountSyncOfficeData() {
    return this.accountSyncOfficeDataWithSuggestions.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get accountSyncOfficeData() {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproOffices =
      accountStore.accountOfficesStore.accountOfficesDataArray;

    return leadproOffices.map(office => {
      const pairedOfficeId = this.officeRelationsMapByLeadProOfficeId[office.id]
        ?.branchId;
      const syncStatus = !!pairedOfficeId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;
      return {
        officeId: office.id,
        officeName: office.name,
        branchId: pairedOfficeId || null,
        syncStatus: syncStatus,
      };
    });
  }

  get accountSyncOfficeDataWithSuggestions() {
    const leadproOffices = this.leadproOffices;

    const streetOfficesArray = this.accountIntegrationOffices.slice();

    const officeRelationsMapByLeadProOfficeID = this
      .officeRelationsMapByLeadProOfficeId;
    const officeRelationsMapByStreetOfficeID = this
      .officeRelationsMapByIntegrationOfficeId;

    return leadproOffices.map(office => {
      let pairedIntegrationOfficeId =
        officeRelationsMapByLeadProOfficeID[office.id]?.branchId;
      let syncStatus = !!pairedIntegrationOfficeId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      // try suggesting a match
      if (!pairedIntegrationOfficeId) {
        const suggestedMatchIndex = streetOfficesArray.findIndex(
          integrationOffice =>
            integrationOffice.name === office.name &&
            !officeRelationsMapByStreetOfficeID[integrationOffice.id]
        );
        if (suggestedMatchIndex > -1) {
          pairedIntegrationOfficeId =
            streetOfficesArray[suggestedMatchIndex]?.id || null;
          streetOfficesArray.splice(suggestedMatchIndex, 1);
          syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
        }
      }

      return {
        officeId: office.id,
        officeName: office.name,
        branchId: pairedIntegrationOfficeId,
        syncStatus: syncStatus,
      };
    });
  }

  get availableIntegrationOffices() {
    const usedIntegrationOffices = this.accountSyncOfficeDataWithSuggestions;
    return this.accountIntegrationOffices.filter(
      integrationOffice =>
        !usedIntegrationOffices.find(
          usedIntegrationOffice =>
            usedIntegrationOffice.branchId === integrationOffice.id
        )
    );
  }

  get availableIntegrationOfficesMapByName() {
    return keyBy(this.availableIntegrationOffices, office => office.name);
  }

  private setAccountIntegrationOffices(data: TIntegrationOffice[]) {
    this.accountIntegrationOffices.replace(data);
  }

  get leadproOffices() {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    if (this.selectedOfficeGroupId) {
      const officeIds = accountStore.accountOfficeGroupsStore.getOfficeIdsForOfficeGroupIds(
        [this.selectedOfficeGroupId]
      );
      if (officeIds.length > 0) {
        return accountStore.accountOfficesStore.accountOfficesDataArray.filter(
          office => officeIds.includes(office.id)
        );
      }
      return [];
    } else {
      return accountStore.accountOfficesStore.accountOfficesDataArray;
    }
  }

  public setAccountOfficeRelations(relations: TIntegrationOfficeRelation[]) {
    this.accountIntegrationOfficeRelations.replace(relations);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public async fetchAccountOfficeRelations() {
    const data = await this.api.fetchAccountOfficeRelations(this.accountId);
    this.setAccountOfficeRelations(data);
  }

  public async updateSyncedAccountOffices(
    syncedPairs: TIntegrationOfficeRelationData[]
  ) {
    await this.api.updateSyncedAccountOffices(
      this.accountId,
      syncedPairs.map(pair => ({
        officeId: pair.officeId,
        branchId: pair.branchId,
      }))
    );
    await this.fetchAccountOfficeRelations();
  }

  public async fetchAccountIntegrationOffices(officeGroupId?: number) {
    if (!!this.api.fetchAccountOffices) {
      const data = await this.api.fetchAccountOffices(
        this.accountId,
        officeGroupId
      );
      this.setAccountIntegrationOffices(data);
    }
  }

  public async fetchAccountIntegrationOfficesAndRelations(
    officeGroupId?: number
  ) {
    await Promise.all([
      this.fetchAccountIntegrationOffices(officeGroupId),
      this.fetchAccountOfficeRelations(),
    ]);
  }

  public setSelectedOfficeGroupId(officeGroupId?: number) {
    this.selectedOfficeGroupId = officeGroupId;
  }
}
