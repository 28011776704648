import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { sortBy } from "lodash";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";
import { LeadFilterSimpleCombinedMenu } from "./LeadFilterMenu/LeadFilterCombinedMenu";
import { faBuilding, faBuildings } from "@fortawesome/pro-regular-svg-icons";

const availableOptionTitles: Partial<Record<LeadSimpleFilterTypes, string>> = {
  [LeadSimpleFilterTypes.OFFICE_GROUPS]: "Office Groups",
  [LeadSimpleFilterTypes.OFFICES]: "Offices",
};

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const FiltersLeadsOfficeHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const { pendingFilter, activeFilter, availableFilters } = leadsFilterStore;

    const officesAndOfficeGroupsCombinedOptions = useMemo(() => {
      if (
        !availableFilters ||
        !availableFilters.offices ||
        !availableFilters.officeGroups
      )
        return {};

      return {
        [LeadSimpleFilterTypes.OFFICE_GROUPS]: sortBy(
          availableFilters.officeGroups,
          officeGroup => officeGroup.name?.toLowerCase()
        ).map(officeGroup => ({
          value: officeGroup.id,
          label: (
            <HStack spacing={2}>
              <FontAwesomeIcon icon={faBuildings} />
              <Box>{officeGroup.name}</Box>
            </HStack>
          ),
          count: officeGroup.count,
        })),
        [LeadSimpleFilterTypes.OFFICES]: sortBy(
          availableFilters.offices,
          office => office.name?.toLowerCase()
        ).map(office => ({
          value: office.id,
          label: (
            <HStack spacing={2}>
              <FontAwesomeIcon icon={faBuilding} />
              <Box>{office.name}</Box>
            </HStack>
          ),
          count: office.count,
        })),
      };
    }, [availableFilters]);

    return (
      <LeadFilterHeaderCell title={"Office"}>
        <LeadFilterSimpleCombinedMenu
          leadsFilterStore={leadsFilterStore}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterTypes={[LeadSimpleFilterTypes.OFFICES]}
          availableOptions={officesAndOfficeGroupsCombinedOptions}
          availableOptionLabels={availableOptionTitles}
          selectedOptions={{
            [LeadSimpleFilterTypes.OFFICES]:
              pendingFilter[LeadSimpleFilterTypes.OFFICES],
            [LeadSimpleFilterTypes.OFFICE_GROUPS]:
              pendingFilter[LeadSimpleFilterTypes.OFFICE_GROUPS],
          }}
          appliedOptions={
            !!activeFilter
              ? {
                  [LeadSimpleFilterTypes.OFFICES]:
                    activeFilter[LeadSimpleFilterTypes.OFFICES],
                  [LeadSimpleFilterTypes.OFFICE_GROUPS]:
                    activeFilter[LeadSimpleFilterTypes.OFFICE_GROUPS],
                }
              : {
                  [LeadSimpleFilterTypes.OFFICES]: [],
                  [LeadSimpleFilterTypes.OFFICE_GROUPS]: [],
                }
          }
        />
      </LeadFilterHeaderCell>
    );
  }
);
