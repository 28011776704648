import { observer } from "mobx-react";
import { LeadFilterHeaderCell } from "../LeadFilterHeaderCell";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import React, { FC, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { AccountJourneysStore } from "store/UserAccounts/UserAccount/AccountJourneys.store";
import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Box, HStack } from "@chakra-ui/react";
import { CenteredMessage } from "components/message/CenteredMessage";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { ResetLeadFilters } from "../LeadFilterMenu/ResetLeadFilters";
import { LeadFilterSimpleMenuItem } from "../LeadFilterMenu/LeadFilterSimpleMenuItem";
import { TLeadFilterMenuItemValue } from "types/leads-filter.type";
import { isEqual } from "lodash";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
  accountJourneysStore: AccountJourneysStore;
}

export const FilterLeadsJourneyHeader: FC<IProps> = observer(
  ({ leadsFilterStore, accountJourneysStore }) => {
    const {
      pendingFilter,
      activeFilter,
      togglePendingFilterJourney,
      setActiveFromPendingFilter,
    } = leadsFilterStore;
    const {
      accountJourneysArray,
      fetchAllAccountJourneys,
    } = accountJourneysStore;

    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const journeyOptions = accountJourneysArray.map(journey => ({
      value: journey.leadFilterId,
      label: journey.name,
      count: 0,
    }));

    const onMenuOpen = useCallback(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await fetchAllAccountJourneys();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [fetchAllAccountJourneys]);

    const onMenuClose = useCallback(() => {
      if (!isEqual(pendingFilter.filterIds, activeFilter?.filterIds))
        setActiveFromPendingFilter();
    }, [
      pendingFilter.filterIds,
      activeFilter?.filterIds,
      setActiveFromPendingFilter,
    ]);

    const onMenuItemClick = useCallback(
      (value: TLeadFilterMenuItemValue) => () => {
        togglePendingFilterJourney(Number(value));
      },
      [togglePendingFilterJourney]
    );

    return (
      <LeadFilterHeaderCell title={"Journey"}>
        <DropdownFilterMenuShell
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          badgeCount={pendingFilter.filterIds.length}
          onOpen={onMenuOpen}
          onClose={onMenuClose}
          loadingStatus={loadingStatus}
        >
          <>
            <HStack
              width={"100%"}
              justifyContent={"space-between"}
              gap={2}
              pl={5}
              pr={3}
              py={3}
            >
              <Box fontWeight={800} color={"leadpro.700"}>
                Journey
              </Box>
              <ResetLeadFilters leadsFilterStore={leadsFilterStore} />
            </HStack>
            {!journeyOptions.length && (
              <CenteredMessage
                height={"100px"}
                message={<DropdownNoFilterMessage />}
              />
            )}
            {!!journeyOptions.length && (
              <Box maxHeight={"500px"}>
                {journeyOptions
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((availableOption, index) => (
                    <LeadFilterSimpleMenuItem
                      key={index}
                      availableOption={availableOption}
                      onMenuItemClick={onMenuItemClick}
                      selectedOptions={pendingFilter.filterIds}
                      disableCount
                    />
                  ))}
              </Box>
            )}
          </>
        </DropdownFilterMenuShell>
      </LeadFilterHeaderCell>
    );
  }
);
