import { Box } from "@chakra-ui/react";
import React from "react";
import { IPageHeaderProps, PageHeader } from "../PageHeader/PageHeader";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";

interface IProps {
  headerProps?: IPageHeaderProps;
  loadingStatus?: ApiRequestStatusEnum;
  children: React.ReactNode;
}

export const RightSideLayout: React.FC<IProps> = ({
  headerProps,
  children,
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      overflow={"hidden"}
    >
      <PageHeader {...headerProps} />
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexGrow={1}
        overflow={"hidden"}
      >
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && children}
      </Box>
    </Box>
  );
};
