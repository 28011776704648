import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { TAccountBillingProduct } from "types/account-billing.type";
import { accountProductsConfigMap } from "constants/account-products-config";
import { formatMoney } from "utils/formating.utils";
import { getProductPriceWithTaxes } from "utils/account-billing.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "components";

interface IProps {
  stripePaymentMethodLast4?: string | null;
  paidProducts: TAccountBillingProduct[];
}
export const AccountBillingPeriodTotalCharge: React.FC<IProps> = ({
  stripePaymentMethodLast4,
  paidProducts,
}) => {
  const totalMonthlyCharge = useMemo(() => {
    return paidProducts.reduce<number>(
      (total, currentValue, currentIndex, arr) => {
        return (
          total +
          getProductPriceWithTaxes(
            accountProductsConfigMap[currentValue.productId],
            currentValue.currentMonthActivity
          ).sumTotalWithVat
        );
      },
      0
    );
  }, [paidProducts]);

  return (
    <Box px={6} py={5}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        fontSize={"lg"}
        fontWeight={700}
      >
        <Box>Total monthly charge</Box>
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Tooltip
            aria-label={`keypress-tooltip`}
            label={
              "This amount is your per calendar month bill, your first month's bill will be calculated prorata."
            }
          >
            <Box color={"gray.500"} mr={2}>
              <FontAwesomeIcon icon={faInfoCircle} fontSize={20} />
            </Box>
          </Tooltip>
          <Box>{formatMoney(totalMonthlyCharge, 2, 2)}</Box>
        </Box>
      </Box>
      {!!stripePaymentMethodLast4 && (
        <Box fontSize={"sm"} fontWeight={500} color={"gray.500"}>
          {`Billed to card ending in ${stripePaymentMethodLast4} on the 1st of every month.`}
        </Box>
      )}
    </Box>
  );
};
