import { VStack } from "@chakra-ui/layout";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PanelsGroupLayout } from "routes/dashboard/components";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { CustomStatusesPanel } from "./components/CustomStatusesPanel";
import { DefaultStatusesPanel } from "./components/DefaultStatusesPanel";

interface ICustomStatusesProps {
  accountStore: UserAccountStore;
}

export const CustomStatuses: React.FC<ICustomStatusesProps> = observer(
  ({ accountStore }) => {
    const { accountLeadStatusesStore } = accountStore;
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountLeadStatusesStore.fetchAccountLeadStatuses();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };
      fetchData();
    }, [accountLeadStatusesStore]);

    return (
      <VStack spacing={10} align={"stretch"}>
        <PanelsGroupLayout>
          <CustomStatusesPanel
            leadStatusesStore={accountLeadStatusesStore}
            loadingStatus={loadingStatus}
          />
          <DefaultStatusesPanel
            leadStatusesStore={accountLeadStatusesStore}
            loadingStatus={loadingStatus}
          />
        </PanelsGroupLayout>
      </VStack>
    );
  }
);
