import React from "react";
import { ProgressBar } from "components";
import { Box } from "@chakra-ui/react";

interface IProps {
  value: number | string;
  percent: number;
  color: string;
}
export const ScoreCell: React.FC<IProps> = ({ value, percent, color }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Box width={"50px"}>{value}</Box>
      <Box width={"50px"} ml={2}>
        <ProgressBar
          value={percent}
          height={"4px"}
          rounded="md"
          color={color}
        />
      </Box>
    </Box>
  );
};
