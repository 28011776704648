import { Box, Flex } from "@chakra-ui/react";
import React from "react";

export const DropdownNoFilterMessage = () => {
  return (
    <Flex alignItems={"center"}>
      <Box fontSize={"sm"}>No filters available.</Box>
    </Flex>
  );
};
