import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const Card = (props: BoxProps) => {
  const { children, ...rest } = props;
  return (
    <Box
      width="100%"
      rounded={"md"}
      bg="white"
      border={"1px solid"}
      borderColor={"gray.200"}
      shadow={"sm"}
      {...rest}
    >
      {props.children}
    </Box>
  );
};
