import React, { FC, useCallback, useState } from "react";
import { Box, Button, HStack, useToast } from "@chakra-ui/react";
import { ApiMessageStack, SingleSelectInput } from "components";
import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "./stores/QuestionnaireToolPageSettingsEditor.store";
import { TriggerablePopoverBlock } from "routes/dashboard/components/prompts/LeadResponderWorkflowEditorPrompt/LeadResponderWorkflowEditor/common-blocks";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";

interface IProps {
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsPreviewLinkSelector: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore }) => {
    const [previewLink, setPreviewLink] = useState<string | null>(null);
    const toast = useToast();

    const {
      hasWIPErrors,
      updatePageAndPageComponents,
      previewLinkOptions,
    } = questionnaireToolPageSettingsEditorStore;

    const handleApply = useCallback(
      (onClose: () => void) => async () => {
        try {
          if (!hasWIPErrors) {
            onClose();
            await updatePageAndPageComponents();
            window.open(`https://${previewLink}`, "_blank")?.focus();
          }
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [updatePageAndPageComponents, hasWIPErrors, previewLink, toast]
    );

    return (
      <HStack>
        <Box>Preview</Box>
        <Box width={"200px"}>
          <SingleSelectInput<string>
            value={previewLink}
            clearable={false}
            options={previewLinkOptions}
            onChange={setPreviewLink}
            placeholder={"Select preview type"}
          />
        </Box>
        {!!previewLink && (
          <TriggerablePopoverBlock
            triggerElement={
              <Button size={"md"} variant={"link"} colorScheme={"blue"}>
                questionnaire
              </Button>
            }
            headerElement={"Saving the changes"}
            bodyElement={
              <Box>
                {hasWIPErrors
                  ? "Please fix the errors in order to save the changes and open the preview page."
                  : "Your changes will be saved in order to preview the page."}
              </Box>
            }
            handleApply={handleApply}
            applyLabel={"Save & preview"}
            applyBtnIsDisabled={hasWIPErrors}
            popoverProps={{
              closeOnBlur: true,
              closeOnEsc: true,
            }}
          />
        )}
      </HStack>
    );
  }
);
