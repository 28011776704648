import { TAccountWebhookRequestData } from "types/account-developers.type";
import { FC, useMemo } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { RequestStatusCodeTag } from "components/tags";
import moment from "moment";
import { DATE_TIME_DETAIL_FORMAT } from "constants/date";
import { FormControlLabel, JSONViewer } from "components";
import {
  getErrorMessageAsResponseObject,
  getResponseBodyAsObject,
} from "utils/api.utils";

interface IProps {
  request: TAccountWebhookRequestData;
}

export const DevelopersConfigWebhookRequestItem: FC<IProps> = ({ request }) => {
  const responseBody = useMemo(() => {
    if (!!request.errorMessage)
      return getErrorMessageAsResponseObject(request.errorMessage);
    return getResponseBodyAsObject(request.responseBody);
  }, [request.responseBody, request.errorMessage]);

  return (
    <AccordionItem id={request.id.toString()}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <HStack spacing={2}>
            <RequestStatusCodeTag code={request.responseStatusCode} />
            <Tag>{`ID ${request.id}`}</Tag>
            {!!request.parentId && (
              <Tag>
                <VStack spacing={0} align={"flex-start"} width={"100%"}>
                  <HStack width={"100%"}>
                    <Box>Parent ID</Box>
                    <Box>{request.parentId}</Box>
                  </HStack>
                  <HStack width={"100%"}>
                    <Box>Attempt</Box>
                    <Box>{request.attemptNumber}</Box>
                  </HStack>
                </VStack>
              </Tag>
            )}
            <Box flexGrow={1} noOfLines={1}>
              {request.webhookUrl}
            </Box>
            <Box>
              {moment(request.createdAt).format(DATE_TIME_DETAIL_FORMAT)}
            </Box>
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <VStack spacing={2}>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Request body</FormControlLabel>
            <JSONViewer json={request.requestBody} />
          </Box>
          {!!responseBody && (
            <Box width={"100%"}>
              <FormControlLabel mb={2}>Response body</FormControlLabel>
              <JSONViewer json={responseBody} />
            </Box>
          )}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};
