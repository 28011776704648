import { BaseApi } from "./base.api";
import { TRegisteredCompanyData } from "types/account.type";

type Option = { id: number; name: string };

export class UiApi extends BaseApi {
  async fetchOfficeMembershipOptions(): Promise<Option[]> {
    return this.get(`/memberships/`);
  }

  async fetchFranchiseOptions(): Promise<Option[]> {
    return this.get(`/franchises/`);
  }

  async fetchWebsiteProviderOptions(): Promise<Option[]> {
    return this.get(`/website-providers/`);
  }

  async fetchRegisteredCompaniesOptions(
    searchTerm: string
  ): Promise<TRegisteredCompanyData[]> {
    return this.get(`/registered-companies/`, {
      params: {
        q: searchTerm,
        itemsPerPage: 100,
      },
    });
  }
}
