import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

export const ASSET_BASE_URL = env.REACT_APP_IMAGE_ASSETS_BASE_URL;

const TESTIMONIAL_PATH = "testimonials";
const ICON_PATH = "icons";
const ILLUSTRATIONS_PATH = "illustrations";
const DIAGRAMS_PATH = "diagrams";
const BANNERS_PATH = "banners";

//TESTIMONIALS
export const BEN_TESTIMONIAL = `${ASSET_BASE_URL}${TESTIMONIAL_PATH}/ben.jpeg`;
export const PAUL_RAI_TESTIMONIAL = `${ASSET_BASE_URL}${TESTIMONIAL_PATH}/paul-rai-testimonial.png`;
export const PERRY_TESTIMONIAL = `${ASSET_BASE_URL}${TESTIMONIAL_PATH}/perry.jpeg`;

//ICONS
export const DEFAULT_PORTAL_IMAGE = `${ASSET_BASE_URL}${ICON_PATH}/default-portal.png`;
export const DEMO_BRAND_ICON = `${ASSET_BASE_URL}${ICON_PATH}/demo-brand.png`;
export const IVT_TOOL_ICON = `${ASSET_BASE_URL}${ICON_PATH}/instant-valuation-tool-icon.png`;
export const LEAD_RESPONDER_ICON = `${ASSET_BASE_URL}${ICON_PATH}/lead-responder-icon.png`;

//ILLUSTRATIONS
export const FACEBOOK_ADS_ILLUSTRATION = `${ASSET_BASE_URL}${ILLUSTRATIONS_PATH}/facebook-ads-illustration.png`;
export const LEAD_RESPONDER_ILLUSTRATION = `${ASSET_BASE_URL}${ILLUSTRATIONS_PATH}/lead-responder-illustration.png`;

//DIAGRAMS
export const LEAD_RESPONDER_DIAGRAM = `${ASSET_BASE_URL}${DIAGRAMS_PATH}/lead-responder-diagram.png`;

//BANNERS
export const LEAD_PRO_BANNER = `${ASSET_BASE_URL}${BANNERS_PATH}/leadpro-banner.png`;
