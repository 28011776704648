import { Box, Center, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/pro-solid-svg-icons";

export const NoDataTableView = () => {
  return (
    <Center
      width={"100%"}
      height={"100%"}
      minHeight={"200px"}
      color={"gray.400"}
    >
      <VStack spacing={4}>
        <FontAwesomeIcon icon={faInbox} fontSize={30} />
        <Box fontSize={"sm"}>No Data.</Box>
      </VStack>
    </Center>
  );
};
