import { observer } from "mobx-react";
import { Box, Flex } from "@chakra-ui/react";
import {
  TItemStatsSummary,
  TLeadAttributionByItem,
  TLeadAttributionByJourneyStatsDto,
  TLeadAttributionByOfficeStatsDto,
  TLeadAttributionBySourceStatsDto,
  TLeadAttributionByUtmCampaignStatsDto,
} from "types/account-lead-attribution-analyticis.type";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import React, { FC, useMemo } from "react";
import { LeadAttributionSourceAvatar } from "./LeadAttributionSourceAvatar";
import { LeadAttributionReportSegmentDetailsItemBarTemplate } from "./LeadAttributionReportSegmentDetailsItemBarTemplate";
import { getLeadAttributionItemUnknownLabel } from "utils/account-lead-attribution-analytics.utils";

interface IProps {
  item: TItemStatsSummary<TLeadAttributionByItem>;
  leadAttributionType: LeadAttributionTypesEnum;
}

export const LeadAttributionReportSegmentDetailsItemBarItemDetails: FC<IProps> = observer(
  ({ item, leadAttributionType }) => {
    const detailsElement = useMemo(() => {
      const unknownLabel = getLeadAttributionItemUnknownLabel(
        leadAttributionType
      );
      switch (leadAttributionType) {
        case LeadAttributionTypesEnum.OFFICE:
          const itemAsOffice = item as TItemStatsSummary<
            TLeadAttributionByOfficeStatsDto
          >;
          return (
            <Box>
              <Box>{itemAsOffice.officeName || unknownLabel}</Box>
              <Box color={"leadpro.400"} fontSize={"xs"}>
                Office
              </Box>
            </Box>
          );
        case LeadAttributionTypesEnum.SOURCE:
          const itemAsSource = item as TItemStatsSummary<
            TLeadAttributionBySourceStatsDto
          >;

          return (
            <Flex gap={2} alignItems={"center"}>
              <LeadAttributionSourceAvatar src={itemAsSource.logoUrl} />
              <Box>
                <Box>
                  {itemAsSource.sourceName ||
                    itemAsSource.source ||
                    unknownLabel}
                </Box>
                <Box color={"leadpro.400"} fontSize={"xs"}>
                  Source
                </Box>
              </Box>
            </Flex>
          );
        case LeadAttributionTypesEnum.CAMPAIGN:
          const itemAsCampaign = item as TItemStatsSummary<
            TLeadAttributionByUtmCampaignStatsDto
          >;
          return (
            <Box width={"100%"}>
              <Box width={"100%"} isTruncated={true}>
                {itemAsCampaign.utmCampaign || unknownLabel}
              </Box>
              <Box color={"leadpro.400"} fontSize={"xs"}>
                Campaign
              </Box>
            </Box>
          );
        case LeadAttributionTypesEnum.JOURNEY:
          const itemAsJourney = item as TItemStatsSummary<
            TLeadAttributionByJourneyStatsDto
          >;
          return (
            <Box width={"100%"}>
              <Box width={"100%"} isTruncated={true}>
                {itemAsJourney.journeyName || unknownLabel}
              </Box>
              <Box color={"leadpro.400"} fontSize={"xs"}>
                Journey
              </Box>
            </Box>
          );
        default:
          return null;
      }
    }, [item, leadAttributionType]);

    return (
      <LeadAttributionReportSegmentDetailsItemBarTemplate
        width={"220px"}
        maxWidth={"220px"}
        alignItems={"flex-start"}
        topChildren={detailsElement}
      />
    );
  }
);
