import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import {
  TAccountBillingData,
  TAccountBillingInvoice,
  TStripeCustomerInfo,
} from "types/account-billing.type";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { AccountBillingApi } from "api/account-billing.api";
import {
  IAccountBillingIssue,
  isApplicationProductOnSpecificPlan,
  modifyUserAccountBillingData,
} from "utils/account-billing.utils";
import { StripePaymentIntentStatusEnum } from "enums/stripe-payment-intent-status.enum";
import { maxBy } from "lodash";
import { ApplicationProductPlanEnum } from "enums/application-product-plan.enum";

const billingApi = new AccountBillingApi();

export class AccountBillingStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private billingData: TAccountBillingData | undefined;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.billingData = undefined;
  }

  get billing() {
    return this.billingData;
  }

  get accountBillingInvoicesSortedDescending() {
    const accountBilling = this.billingData;
    if (!accountBilling) return [];

    return accountBilling.accountBillingInvoices
      .slice()
      .sort(
        (current: TAccountBillingInvoice, previous: TAccountBillingInvoice) => {
          return (
            new Date(previous.period).getTime() -
            new Date(current.period).getTime()
          );
        }
      );
  }

  get accountBillingIssues() {
    const accountBilling = this.billingData;
    const issues: IAccountBillingIssue[] = [];

    if (accountBilling) {
      accountBilling.accountBillingInvoices.forEach(invoice => {
        if (
          invoice?.stripePaymentStatus ===
          StripePaymentIntentStatusEnum.REQUIRES_ACTION
        ) {
          issues.push({
            status: StripePaymentIntentStatusEnum.REQUIRES_ACTION,
            stripePaymentIntentClientSecret:
              invoice.stripePaymentIntentClientSecret,
            message:
              "Additional action is required in order to make a charge on your selected payment method.",
            cta: "Confirm payment",
          });
        }

        if (
          invoice?.stripePaymentStatus ===
          StripePaymentIntentStatusEnum.REQUIRES_PAYMENT_METHOD
        ) {
          issues.push({
            status: StripePaymentIntentStatusEnum.REQUIRES_ACTION,
            stripePaymentIntentClientSecret:
              invoice.stripePaymentIntentClientSecret,
            message: invoice.stripePaymentIntentLastPaymentErrorMessage,
            cta: "Retry",
          });
        }
      });
    }

    return issues;
  }

  get applicationProductsOnPaidPlan() {
    const accountBilling = this.billingData;

    if (!accountBilling) return [];

    return accountBilling.accountBillingProducts.filter(billingProduct =>
      isApplicationProductOnSpecificPlan(
        billingProduct.productId,
        [ApplicationProductPlanEnum.PAID],
        accountBilling
      )
    );
  }

  get isAnyApplicationProductOnPaidPlan() {
    return !!this.applicationProductsOnPaidPlan.length;
  }

  get latestInvoice() {
    const accountBilling = this.billingData;
    if (!accountBilling || !accountBilling.accountBillingInvoices.length)
      return null;
    return maxBy(accountBilling.accountBillingInvoices, invoice => invoice.id);
  }

  public setBillingData(accountBilling: TAccountBillingData) {
    this.billingData = modifyUserAccountBillingData(accountBilling);
  }

  public async updatePaymentMethod(paymentMethodId: string) {
    const accountBilling = await billingApi.setAccountPaymentMethod(
      this.accountId,
      paymentMethodId
    );

    this.setBillingData(accountBilling);
  }

  public async updateBillingCustomerInfo(info: Partial<TStripeCustomerInfo>) {
    const accountBilling = await billingApi.updateAccountBillingCustomerInfo(
      this.accountId,
      info
    );

    this.setBillingData(accountBilling);
  }

  public async addProduct(productId: ApplicationProductEnum) {
    const accountBilling = await billingApi.addAccountProduct(
      this.accountId,
      productId
    );

    this.setBillingData(accountBilling);
  }

  public async removeProduct(productId: ApplicationProductEnum) {
    const accountBilling = await billingApi.removeAccountProduct(
      this.accountId,
      productId
    );

    this.setBillingData(accountBilling);
  }
}
