import { BoxProps, Center } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface IDefaultRowCellProps extends BoxProps {
  children: ReactNode;
}

export const DefaultRowCell: FC<IDefaultRowCellProps> = ({
  children,
  ...rest
}) => {
  return (
    <Center
      height={"100%"}
      justifyContent={"flex-start"}
      fontSize={"sm"}
      py={1}
      {...rest}
    >
      {children}
    </Center>
  );
};
