import { Box } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

interface IProps {
  children: [ReactNode, ReactNode];
}
export const PageToolPageSettingsInputLayout: FC<IProps> = ({ children }) => {
  return (
    <Box
      mb={8}
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      gap={"45px"}
    >
      <Box flexBasis={"400px"} mr={2}>
        {children[0]}
      </Box>
      <Box flexBasis={"800px"}>{children[1]}</Box>
    </Box>
  );
};
