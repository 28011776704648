import { Avatar, Box, HStack } from "@chakra-ui/react";
import React from "react";

interface IProps {
  quote: string;
  author: string;
  authorImageUrl: string;
  colorScheme: string;
}

export const SplashTestimonial: React.FC<IProps> = ({
  quote,
  author,
  authorImageUrl,
  colorScheme,
}) => {
  return (
    <Box>
      <Box pb={6} fontSize={"3xl"} fontWeight={700}>
        What our customers said
      </Box>
      <HStack spacing={12}>
        <Avatar size={"2xl"} src={authorImageUrl} />
        <Box fontWeight={500}>
          <Box fontSize={"2xl"}>{`"${quote}"`}</Box>
          <Box mt={3} color={`${colorScheme}.600`}>
            {author}
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};
