import React from "react";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";

interface ILeadHostTagProps {
  host?: string;
}

export const LeadHostTag: React.FC<ILeadHostTagProps> = ({ host }) => {
  if (!host) return null;

  return (
    <Box mt={2} width={"100%"}>
      <Link
        href={"https://" + host}
        isExternal
        color={"leadpro.800"}
        _hover={{ textDecoration: "none" }}
      >
        <Box>
          <Flex align="center">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            <Text isTruncated ml={2}>
              {host}
            </Text>
          </Flex>
        </Box>
      </Link>
    </Box>
  );
};
