import { observer } from "mobx-react";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import React, { FC, useMemo } from "react";
import { sortBy } from "lodash";
import { Box, HStack } from "@chakra-ui/react";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterSimpleMenu } from "./LeadFilterMenu/LeadFilterSimpleMenu";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const FilterLeadsUTMCampaignHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const {
      pendingFilter,
      activeFilter,
      availableFilters,
      togglePendingFilterUTMCampaign,
    } = leadsFilterStore;

    const utmCampaignOptions = useMemo(() => {
      if (
        !availableFilters ||
        !availableFilters[LeadSimpleFilterTypes.UTM_CAMPAIGNS]
      )
        return [];

      return sortBy(
        availableFilters[LeadSimpleFilterTypes.UTM_CAMPAIGNS],
        utmCampaign => utmCampaign.id
      ).map(utmCampaign => ({
        value: utmCampaign.id,
        label: (
          <HStack spacing={2}>
            <FontAwesomeIcon icon={faMegaphone} />
            <Box textTransform={"none"}>{utmCampaign.id}</Box>
          </HStack>
        ),
        count: utmCampaign.count,
      }));
    }, [availableFilters]);

    return (
      <LeadFilterHeaderCell title={"Campaign"}>
        <LeadFilterSimpleMenu
          optionLabel={"Campaign"}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterType={LeadSimpleFilterTypes.UTM_CAMPAIGNS}
          availableOptions={utmCampaignOptions}
          selectedOptions={pendingFilter[LeadSimpleFilterTypes.UTM_CAMPAIGNS]}
          appliedOptions={
            !!activeFilter
              ? activeFilter[LeadSimpleFilterTypes.UTM_CAMPAIGNS]
              : []
          }
          handleFilterItemToggle={(value: string) =>
            togglePendingFilterUTMCampaign(value)
          }
          leadsFilterStore={leadsFilterStore}
        />
      </LeadFilterHeaderCell>
    );
  }
);
