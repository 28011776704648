import React from "react";
import { Box, HStack, Image } from "@chakra-ui/react";
import { TPortalData } from "types/portal.type";
import { IGetOptionPropsData } from "../SelectInputOptionsController";
import { SelectInputOptionBase } from "../SelectInputOptionBase";
import { TSelectOptionValue } from "types/select-input.type";

interface IPortalSyncSelectOptionsRendererProps<
  D extends TSelectOptionValue,
  S = unknown
> {
  optionProps: IGetOptionPropsData<D, S>;
  fallbackLogo?: string;
}

export const PortalSelectOption: React.FC<IPortalSyncSelectOptionsRendererProps<
  number,
  TPortalData
>> = ({ optionProps: { option, selectedOption, ...rest }, fallbackLogo }) => {
  const isDisabled = false;
  const isSelected = false;

  return (
    <SelectInputOptionBase
      {...rest}
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <HStack alignItems={"center"} spacing={2}>
        <Image
          rounded={"md"}
          boxSize={"24px"}
          objectFit={"contain"}
          src={option.data?.logoUrl || fallbackLogo}
          bg={"transparent"}
        />
        <Box>{option.label}</Box>
      </HStack>
    </SelectInputOptionBase>
  );
};
