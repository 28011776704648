import React from "react";
import { IOfficeLeaderboardStats } from "utils/office-leaderboard.utils";
import { OfficesLeagueListItem } from "./OfficesLeagueListItem";
import { Box } from "@chakra-ui/react";

interface IProps {
  officesStats: IOfficeLeaderboardStats[];
  onItemClick: (officeStats: IOfficeLeaderboardStats) => void;
}
export const OfficesLeagueList: React.FC<IProps> = ({
  officesStats,
  onItemClick,
}) => {
  return (
    <Box>
      {officesStats.map((officeStats, index) => (
        <Box key={index} mb={4}>
          <OfficesLeagueListItem
            officeStats={officeStats}
            index={index + 1}
            onClick={onItemClick}
          />
        </Box>
      ))}
    </Box>
  );
};
