import moment from "moment";
import { DATE_SHORT_FORMAT, DATE_YEAR, DATE_YEAR_MONTH } from "constants/date";

export function formatDate(date: string, format: string) {
  return moment(date).format(format);
}

export const formatDateForChart = (date: string) => {
  const dateMoment = moment(date);
  const today = moment().startOf("day");
  const diff = dateMoment.diff(today, "days", true);
  if (diff === 0) {
    return "Today";
  }
  return dateMoment.format(DATE_SHORT_FORMAT);
};
export const getMonthsAndYearsBetweenDates = (from: string, to?: string) => {
  const dateStart = moment(from).startOf("month");
  const dateEnd = moment(to).endOf("month");
  const timeValues = [];
  let currentYear = "";

  while (dateStart.isBefore(dateEnd)) {
    const formattedDate = dateStart.format(DATE_YEAR_MONTH);
    const year = dateStart.format(DATE_YEAR);

    if (year !== currentYear) {
      timeValues.push(year);
      currentYear = year;
    }

    timeValues.push(formattedDate);
    dateStart.add(1, "month");
  }

  return timeValues;
};

export const isDateCorrectFormat = (
  date: string,
  formatToCheck: string
): boolean => {
  return moment(date, formatToCheck, true).isValid();
};
