import React from "react";
import { Box } from "@chakra-ui/react";
import ReactPlayer from "react-player";

interface IEmbedProps {
  node: any;
}

const Embed: React.FC<IEmbedProps> = ({ node }) => {
  const entry = node.data.target;
  const videoURL = entry.fields.file.url;

  return (
    <Box my={5}>
      <ReactPlayer
        url={videoURL}
        width="100%"
        controls={true}
        style={{ outline: "none" }}
      />
    </Box>
  );
};

export default Embed;
