import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

interface IProps {
  children: React.ReactNode;
}

export const PropertyHeaderCell: FC<IProps> = ({ children }) => {
  return (
    <Box height={"54px"} display={"flex"} alignItems={"center"}>
      {children}
    </Box>
  );
};
