import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { TLeadResponderLeadEmailTableData } from "types/account-lead-emails.type";

interface ILeadEmailCorrespondenceCellProps {
  email: TLeadResponderLeadEmailTableData;
}

export const LeadEmailCorrespondenceCell: React.FC<ILeadEmailCorrespondenceCellProps> = ({
  email,
}) => {
  return (
    <VStack
      align={"stretch"}
      p={1}
      fontSize={"sm"}
      whiteSpace={"normal"}
      style={{ wordWrap: "break-word" }}
    >
      <Box>
        <Box as={"span"} fontWeight={"bold"}>
          FROM:
        </Box>
        {email.from}
      </Box>
      <Box>
        <Box as={"span"} fontWeight={"bold"}>
          TO:
        </Box>
        {email.to}
      </Box>
    </VStack>
  );
};
