import { Redirect, Route, Switch } from "react-router-dom";
import {
  TOOLS_LEAD_RESONDER_LEAD_EMAILS_ROUTE,
  TOOLS_LEAD_RESPONDER_LEAD_EMAILS_DETAILS_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
} from "constants/routes";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { LeadEmailDetails } from "./LeadEmailDetails/LeadEmailDetails";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderLeadEmailsDetail: FC<IProps> = ({ accountStore }) => {
  return (
    <Switch>
      <Route path={TOOLS_LEAD_RESONDER_LEAD_EMAILS_ROUTE} exact>
        <Redirect to={`${TOOLS_LEAD_RESPONDER_ROUTE}#lead-inbox`} />
      </Route>
      <Route path={TOOLS_LEAD_RESPONDER_LEAD_EMAILS_DETAILS_ROUTE}>
        <LeadEmailDetails accountStore={accountStore} />
      </Route>
    </Switch>
  );
};
