import { Box, Link, Text } from "@chakra-ui/react";
import { FormControlV2 } from "components";
import { OpenViewRelatedContactsRenderer } from "components/select-input/custom-option-renderers/OpenViewRelatedContactsRenderer";
import OpenViewRelatedContactsOption from "components/select-input/custom-options/OpenViewRelatedContactsOption";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FC, useMemo } from "react";
import { Control } from "react-hook-form";
import {
  TLeadOpenViewFieldValues as TFieldValues,
  TOpenViewContactData,
} from "types/openview.type";
import { TSelectOption } from "types/select-input.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { openViewNewContactValue } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOpenView/AccountIntegrationOpenViewContacts.store";

export interface IProps {
  control: Control<TFieldValues, any>;
  openViewRelatedContactsOptions: TSelectOption<string, TOpenViewContactData>[];
  currentUserId: string;
  type: LeadTypeEnum;
}

const env = runtimeEnv();

const recordTypeToLinkMap: Record<string, string> = {
  [LeadTypeEnum.Let]: "Lettings/Applicant",
  [LeadTypeEnum.Landlord]: "Lettings/Landlord",
  [LeadTypeEnum.Vendor]: "Sales/Vendor",
  [LeadTypeEnum.Sale]: "Sales/Applicant",
};

export const ExistingRecordsForm: FC<IProps> = ({
  control,
  openViewRelatedContactsOptions,
  currentUserId,
  type,
}) => {
  const getOpenViewRelatedContactDescription = useMemo(() => {
    const optionsLength = openViewRelatedContactsOptions.length;
    if (optionsLength < 2) {
      return (
        <span>
          <strong>No existing records</strong> have been identified. Sync'ing
          this lead will create a new iamproperty CRM contact record.
        </span>
      );
    }
    if (optionsLength === 2) {
      return (
        <span>
          <strong>1 other record</strong> with similar or identical contact
          details has been identified in your iamproperty CRM system. You can
          choose to update an existing record or create a new one.
        </span>
      );
    }
    return (
      <span>
        <strong>{optionsLength - 1} other records</strong> with similar or
        identical contact details have been identified in your iamproperty CRM
        system. You can choose to update an existing record or create a new one.
      </span>
    );
  }, [openViewRelatedContactsOptions]);

  const currentUserLink = useMemo(() => {
    const currentUser = openViewRelatedContactsOptions.find(
      user => user.value === currentUserId
    );

    const currentUserRecordTypes = currentUser?.data?.contactTypes;
    if (currentUserRecordTypes) {
      const recordType =
        currentUserRecordTypes.find(record => record === type) ??
        currentUserRecordTypes[0];
      return `${env.REACT_APP_OPENVIEW_BASE_URL}/${recordTypeToLinkMap[recordType]}/Record/${currentUserId}`;
    }

    return "";
  }, [currentUserId, openViewRelatedContactsOptions, type]);

  return (
    <>
      <Box
        width={"full"}
        py={2}
        px={5}
        mt={2}
        mb={2}
        background={"leadpro.50"}
        fontSize={"sm"}
      >
        Existing iamproperty CRM Records
      </Box>
      <Box mb={4} px={4}>
        <Box mb={4}>
          <FormControlV2<TFieldValues>
            name={"userId"}
            control={control}
            type={FormControlsTypeEnum.SINGLE_SELECT}
            description={
              <Box mb={4}>{getOpenViewRelatedContactDescription}</Box>
            }
            additionalProps={{
              options: openViewRelatedContactsOptions,
              optionComponent: (
                optionProp: IGetOptionPropsData<string, TOpenViewContactData>
              ) => <OpenViewRelatedContactsOption optionProp={optionProp} />,
              optionsRenderer: (
                optionProps: IGetOptionPropsData<string, TOpenViewContactData>[]
              ) => (
                <OpenViewRelatedContactsRenderer optionProps={optionProps} />
              ),
            }}
          />
        </Box>
        {currentUserId !== openViewNewContactValue && (
          <Box mb={5}>
            <Text fontSize={"sm"}>
              Open this selected record in{" "}
              <Link color={"blue.500"} href={currentUserLink} isExternal>
                iamproperty CRM{" "}
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};
