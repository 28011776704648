import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { makeAutoObservable } from "mobx";
import { IAccountPageToolStore } from "store/UserAccounts/UserAccount/AccountPages/IAccountPageToolStore";

export type TPageToolPagesLocalStoreParams = {
  accountPageToolStore: IAccountPageToolStore;
};

export class PageToolPagesStore {
  private accountPageToolStore: IAccountPageToolStore;
  public loadingStatus: ApiRequestStatusEnum;

  constructor({ accountPageToolStore }: TPageToolPagesLocalStoreParams) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountPageToolStore = accountPageToolStore;
    this.loadingStatus = ApiRequestStatusEnum.NONE;
  }

  get pagesArray() {
    return this.accountPageToolStore.accountPagesArray;
  }

  public async fetchData() {
    this.setLoadingStatus(ApiRequestStatusEnum.PENDING);
    try {
      await this.accountPageToolStore.loadPages();
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (e) {
      this.setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }

  public async removePage(pageId: number) {
    await this.accountPageToolStore.removePage(pageId);
  }

  private setLoadingStatus(loadingStatus: ApiRequestStatusEnum) {
    this.loadingStatus = loadingStatus;
  }
}
