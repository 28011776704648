import { Box } from "@chakra-ui/react";
import { formatDate } from "utils/date.utils";
import { DATE_TIME_FORMAT } from "constants/date";
import React, { FC } from "react";
import { TLead } from "types/lead.type";

interface ILeadDateCreatedCellProps {
  lead: TLead;
}

export const LeadDateCreatedCell: FC<ILeadDateCreatedCellProps> = ({
  lead,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      <Box>{formatDate(lead.createdAt, DATE_TIME_FORMAT)}</Box>
    </Box>
  );
};
