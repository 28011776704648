import { VStack, StackProps, Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { NA } from "components";

interface EmailBlockItemProps extends StackProps {
  title?: string;
  component?: ReactNode;
}

export const EmailBlockItem: React.FC<EmailBlockItemProps> = ({
  title,
  component,
  ...rest
}) => {
  return (
    <VStack {...rest} align="left" alignItems="flex-start">
      {!!title && (
        <Box as="h2" fontSize="sm" fontWeight="bold">
          {title}
        </Box>
      )}
      <Box fontSize={"sm"}>{component || <NA />}</Box>
    </VStack>
  );
};
