import React, { useCallback } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

interface IPickerOption {
  value: string;
  label: string;
}

interface DatePickerOptionsProps {
  options: IPickerOption[];
  onChange: (value: string) => void;
  value?: string;
}

export const DateRangePickerOptions: React.FC<DatePickerOptionsProps> = ({
  options,
  value,
  onChange,
}) => {
  const handleOptionSelect = useCallback(
    (value: string) => () => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <>
      {options.map(option => {
        return (
          <Box
            key={option.value}
            width={"100%"}
            _hover={{
              bg: "leadpro.50",
            }}
          >
            <HStack alignItems={"center"} ml={3}>
              <Box visibility={value === option.value ? "visible" : "hidden"}>
                <FontAwesomeIcon icon={faCheck} />
              </Box>
              <Box
                flex={1}
                justifyContent="center"
                alignItems="center"
                fontSize="sm"
                fontWeight="500"
                color="leadpro.700"
                cursor="pointer"
                p={1}
                textAlign="start"
                onClick={handleOptionSelect(option.value)}
              >
                {option.label}
              </Box>
            </HStack>
          </Box>
        );
      })}
    </>
  );
};
