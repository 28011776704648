import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { FormControlV2, FormControlMeta } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ExternalLink } from "components";
import { TPageSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import { Control } from "react-hook-form";
import { PageToolPageSettingsInputLayout } from "./PageToolPageSettingsInputLayout";

type TFieldValues = TPageSettingsFieldValues;

interface IProps<T extends TFieldValues> {
  control: Control<T>;
}

export function PageToolPageAnalyticsSettings<T extends TFieldValues>({
  control,
}: IProps<T>) {
  return (
    <Box>
      <Text my={6} fontSize={"lg"}>
        Analytics
      </Text>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Facebook tracking pixel ID"
          description={
            "Add a Facebook tracking ID to track conversions generated by Facebook adverts."
          }
        />
        <FormControlV2<TFieldValues>
          name={"facebookPixelId"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Facebook tracking pixel ID",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Google Analytics ID"
          description={
            <Box as={"span"}>
              Enabling Google Analytics will allow you to track visitors to your
              page.
              <ExternalLink
                label={" Here's how to find your tracking ID."}
                href={
                  "https://support.google.com/analytics/answer/7372977?hl=en"
                }
              />
            </Box>
          }
        />
        <FormControlV2<TFieldValues>
          name={"googleAnalyticsId"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "UA-XXXXX-Y or G-XXXXXXXX",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Google Ads Conversion ID"
          description={
            <Box as={"span"}>
              Track conversions generated by Google Ads.
              <ExternalLink
                label={" Here's how to find your conversion ID."}
                href={
                  "https://faq.businesstech.fr/en/faq/166-where-can-i-find-my-conversion-id-and-label"
                }
              />
            </Box>
          }
        />
        <FormControlV2<TFieldValues>
          name={"googleAdsConversionId"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "AW-XXXXX",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Google Ads Conversion Label ID"
          description={
            <Box as={"span"}>
              Track conversions generated by Google Ads.
              <ExternalLink
                label={" Here's how to find your conversion label ID."}
                href={
                  "https://faq.businesstech.fr/en/faq/166-where-can-i-find-my-conversion-id-and-label"
                }
              />
            </Box>
          }
        />
        <FormControlV2<TFieldValues>
          name={"googleAdsConversionLabelId"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Google Ads Conversion Label ID",
          }}
        />
      </PageToolPageSettingsInputLayout>
    </Box>
  );
}
