import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { keyBy } from "lodash";
import { LeadResponderApi } from "api/lead-responder.api";
import { AccountLeadResponderEmailsStore } from "./AccountLeadResponderEmails.store";
import { AppStore } from "../../../App.store";

const leadResponderApi = new LeadResponderApi();

export class AccountLeadResponderStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly workflows: IObservableArray<TLeadResponderWorkflow>;
  public readonly leadResponderEmailsStore: AccountLeadResponderEmailsStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.workflows = observable.array<TLeadResponderWorkflow>();
    this.leadResponderEmailsStore = new AccountLeadResponderEmailsStore(
      root,
      accountId
    );
  }

  get workflowsArray() {
    return this.workflows.slice();
  }

  get workflowsMap() {
    return keyBy(this.workflows, workflow => workflow.id);
  }

  private setWorkflows(workflows: TLeadResponderWorkflow[]) {
    this.workflows.replace(workflows);
  }

  private upsertWorkflow(workflowData: TLeadResponderWorkflow) {
    const index = this.workflows.findIndex(
      workflow => workflow.id === workflowData.id
    );
    if (index > -1) {
      this.workflows[index] = workflowData;
    } else {
      this.workflows.push(workflowData);
    }
  }

  private deleteWorkflow(workflowId: number) {
    const index = this.workflows.findIndex(
      workflow => workflow.id === workflowId
    );
    if (index > -1) {
      this.workflows.splice(index, 1);
    }
  }

  public async loadWorkflows() {
    const workflows = await leadResponderApi.fetchWorkflows(this.accountId);
    this.setWorkflows(workflows);
  }

  public async loadWorkflow(workflowId: number) {
    const workflow = await leadResponderApi.fetchWorkflow(
      this.accountId,
      workflowId
    );
    this.upsertWorkflow(workflow);
  }

  private async createWorkflow(workflow: Partial<TLeadResponderWorkflow>) {
    const newWorkflow = await leadResponderApi.createWorkflow(
      this.accountId,
      workflow
    );
    this.upsertWorkflow(newWorkflow);
  }

  private async updateWorkflow(
    workflowId: number,
    workflow: Partial<TLeadResponderWorkflow>
  ) {
    const updatedWorkflow = await leadResponderApi.updateWorkflow(
      this.accountId,
      workflowId,
      {
        name: workflow.name,
        active: workflow.active,
        leadFilterId: workflow.leadFilterId,
        replyToEmailTemplateId: workflow.replyToEmailTemplateId,
        smsReminderEnabled: workflow.smsReminderEnabled,
        smsDelay: workflow.smsDelay,
        smsTemplateId: workflow.smsTemplateId,
        followUpReminderEnabled: workflow.followUpReminderEnabled,
        followUpDelay: workflow.followUpDelay,
        followUpEmailTemplateId: workflow.followUpEmailTemplateId,
      }
    );
    this.upsertWorkflow(updatedWorkflow);
  }

  public async persistWorkflow(workflow: Partial<TLeadResponderWorkflow>) {
    if (!!workflow.id) {
      await this.updateWorkflow(workflow.id, workflow);
    } else {
      await this.createWorkflow(workflow);
    }
  }

  public async removeWorkflow(workflowId: number) {
    await leadResponderApi.deleteWorkflow(this.accountId, workflowId);
    await this.loadWorkflows();
  }

  private async reorderWorkflows(ids: number[]) {
    await leadResponderApi.reorderWorkflows(this.accountId, ids);
    await this.loadWorkflows();
  }

  public async handleIncreaseWorkflowPriority(id: number) {
    const workflowIds = this.workflowsArray.map(workflow => workflow.id);

    if (!!workflowIds.length) {
      const currentIndex = workflowIds.findIndex(
        workflowId => workflowId === id
      );

      if (currentIndex > 0) {
        workflowIds[currentIndex] = workflowIds[currentIndex - 1];
        workflowIds[currentIndex - 1] = id;
        await this.reorderWorkflows(workflowIds);
      }
    }
  }

  public async handleDecreaseWorkflowPriority(id: number) {
    const workflowIds = this.workflowsArray.map(workflow => workflow.id);

    if (!!workflowIds.length) {
      const currentIndex = workflowIds.findIndex(
        workflowId => workflowId === id
      );

      if (currentIndex < workflowIds.length - 1) {
        workflowIds[currentIndex] = workflowIds[currentIndex + 1];
        workflowIds[currentIndex + 1] = id;
        await this.reorderWorkflows(workflowIds);
      }
    }
  }
}
