import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface IProps extends BoxProps {
  htmlFor?: string;
}

export const FormControlLabel: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <Box
      as="label"
      fontSize="sm"
      display="block"
      lineHeight="none"
      fontWeight={500}
      color="leadpro.700"
      whiteSpace={"nowrap"}
      {...rest}
    >
      {children}
    </Box>
  );
};
