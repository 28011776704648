import { SETTINGS_BILLING_ROUTE } from "constants/routes";
import { NavigationPrompt } from "components/navigation-prompt/NavigationPrompt";
import React, { FC } from "react";

interface IPaidFeatureBannerProps {
  ctaNavigateTo?: string;
  text?: string;
  ctaText?: string;
}

export const PaidFeatureBanner: FC<IPaidFeatureBannerProps> = ({
  ctaNavigateTo,
}) => {
  return (
    <NavigationPrompt
      text={"This is a paid feature."}
      ctaNavigateTo={ctaNavigateTo || SETTINGS_BILLING_ROUTE}
      ctaText={"Upgrade your plan"}
      asButton={true}
    />
  );
};
