import { observer } from "mobx-react";
import { TPropertySource } from "types/property.type";
import React, { FC, useCallback, useMemo } from "react";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { Dictionary } from "lodash";
import { TPortalData } from "types/portal.type";
import {
  getLeadSourceLabel,
  getLeadSourceLogoUrl,
} from "utils/lead-source.utils";
import { Avatar, AvatarProps, Box, Checkbox, HStack } from "@chakra-ui/react";
import Styles from "components/avatar/Avatar.module.scss";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";

interface IProps {
  propertyStore: AccountPropertyStore;
  propertySource: TPropertySource;
  accountLogoUrl: string | null;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  portalsMap: Dictionary<TPortalData>;
}

export const PropertyDetailsLeadSourcesFilterItem: FC<IProps> = observer(
  ({
    propertyStore,
    propertySource,
    accountLogoUrl,
    leadSourcesMapBySource,
    portalsMap,
  }) => {
    const leadsFilterStore = propertyStore.propertyLeadsStore.leadsFilterStore;
    const leadSource = useMemo(
      () => leadSourcesMapBySource[propertySource.source],
      [propertySource.source, leadSourcesMapBySource]
    );
    const portal = useMemo(
      () =>
        !!leadSource?.portalId ? portalsMap[leadSource.portalId] : undefined,
      [leadSource, portalsMap]
    );

    const sourceLogoUrlFromPortal = useMemo(() => {
      return getLeadSourceLogoUrl(accountLogoUrl, leadSource, portal);
    }, [accountLogoUrl, leadSource, portal]);

    const avatarStyling = useMemo(() => {
      const styling: Partial<AvatarProps> = {};

      if (!!sourceLogoUrlFromPortal) {
        styling.bg = "transparent";
      }

      return styling;
    }, [sourceLogoUrlFromPortal]);

    const leadSourceLabel = useMemo(() => {
      return getLeadSourceLabel(propertySource.source, leadSource);
    }, [propertySource.source, leadSource]);

    const onClick = useCallback(() => {
      leadsFilterStore.togglePendingFilterSourceAndHost(
        propertySource.source,
        ""
      );
      leadsFilterStore.setActiveFromPendingFilter();
    }, [leadsFilterStore, propertySource.source]);

    const isChecked =
      !!propertyStore.propertyLeadsStore.leadsFilterStore.activeFilter?.[
        LeadSimpleFilterTypes.SOURCES
      ][propertySource.source] || false;

    return (
      <Checkbox isChecked={isChecked} onChange={onClick}>
        <HStack spacing={2}>
          <Avatar
            src={sourceLogoUrlFromPortal}
            name={leadSourceLabel}
            size={"2xs"}
            rounded={"sm"}
            className={Styles.mdRoundedAvatar}
            {...avatarStyling}
          />
          <Box as={"span"} fontSize={"xs"}>
            {leadSourceLabel}{" "}
            <Box as={"span"} color={"leadpro.500"}>{`(${
              propertySource.count
            } lead${propertySource.count !== 1 ? "s" : ""})`}</Box>
          </Box>
        </HStack>
      </Checkbox>
    );
  }
);
