import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, useToast } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ApiMessageStack } from "components";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";

interface IProps {
  leadStore: AccountLeadStore;
  leadStatusesStore: AccountLeadStatusesStore;
}

export const LeadDeleteButton: FC<IProps> = observer(
  ({ leadStore, leadStatusesStore }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const toast = useToast();
    const isLeadDeleted = leadStore.isLeadDeleted;

    const { deletedStatus } = leadStatusesStore;

    const handleDeleteStatus = useCallback(async () => {
      try {
        setIsDeleting(true);
        await leadStore.update({
          statusId: deletedStatus?.id,
        });
        setIsDeleting(false);
      } catch (error) {
        setIsDeleting(false);
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [leadStore, deletedStatus, toast]);

    return (
      <Button
        width={"100px"}
        leftIcon={<FontAwesomeIcon icon={faTrashCan} fontSize="18px" />}
        colorScheme={"red"}
        disabled={isLeadDeleted}
        fontWeight={"light"}
        variant={isLeadDeleted ? "solid" : "outline"}
        borderRadius={"full"}
        isLoading={isDeleting}
        onClick={handleDeleteStatus}
      >
        {isLeadDeleted ? "Deleted" : "Delete"}
      </Button>
    );
  }
);
