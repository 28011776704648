import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlLabel } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TGraingerSalesforceConfig,
} from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  graingerSalesforceIntegrationConnectValidationSchema,
  TGraingerSalesforceIntegrationConfigFieldValues,
} from "utils/validation-schemas/grainger-salesforce-integration.validation";
import { ToggleIntegrationActiveSwitchInput } from "./ToggleIntegrationActiveSwitchInput";

type TFieldValues = TGraingerSalesforceIntegrationConfigFieldValues & {
  active: boolean;
};

const validationSchema = graingerSalesforceIntegrationConnectValidationSchema.concat(
  Yup.object().shape({
    active: Yup.boolean().required(REQUIRED_FIELD),
  })
);

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const GraingerSalesforceIntegrationForm: React.FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.GRAINGER_SALESFORCE
    ] as TAccountIntegrationData<TGraingerSalesforceConfig>;

    const initialValues: TFieldValues = useMemo(
      () => ({
        clientId: accountIntegration.integrationConfig.clientId,
        userName: accountIntegration.integrationConfig.userName,
        certificatePrivateKey: "",
        testMode: accountIntegration.integrationConfig.testMode,
        active: accountIntegration.active,
      }),
      [accountIntegration]
    );

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const onSubmit = useCallback(
      async ({ active, ...rest }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<
            TGraingerSalesforceIntegrationConfigFieldValues
          >(accountIntegration.id, {
            type: accountIntegration.type,
            active: active,
            integrationConfig: rest,
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"SalesForce integration configuration updated."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connecting with Sales Force"}
            value={active}
            name={"active"}
            control={control}
          />
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Client ID"}
              type={FormControlsTypeEnum.TEXT}
              name={"clientId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Username"}
              type={FormControlsTypeEnum.TEXT}
              name={"userName"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Certificate"}
              description={"upload your .key certificate file"}
              type={FormControlsTypeEnum.CERTIFICATE_UPLOAD_INPUT}
              name={"certificatePrivateKey"}
              control={control}
              additionalProps={{
                accept: ".key",
              }}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel>Test mode</FormControlLabel>
            <FormControlV2<TFieldValues>
              name={"testMode"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
