import React, { useEffect, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { ACCOUNT_SETUP_ROUTE } from "constants/routes";
import { Redirect } from "react-router-dom";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Loader } from "components";
import { LeftSideBar } from "../LeftSideBar";

const SIDEBAR_WIDTH = "260px";

interface IProps {
  children?: React.ReactNode;
}

export const DashboardLayout = observer(({ children }: IProps) => {
  const {
    uiStore: { isLeftSidebarOpen, userAccountLoadingState },
    userAccountsStore: {
      userAccountsArray,
      selectedAccountStore,
      setDefaultAccount,
    },
  } = useAppStore();

  useEffect(() => {
    setDefaultAccount();
  }, [setDefaultAccount]);

  // TODO NENAD: RIGHT NOW, OLD ACCOUNTS WILL BE SPARED FROM ACCOUNT SETUP.
  //  BUT, THEY WILL ALSO MISS OF ANY FUTURE STEPS THAT WE MIGHT ADD AND
  //  WANT TO FORCE ON ALL ACCOUNTS. TO REMEDY THAT, OLD ACCOUNTS NEED TO HAVE
  //  setupStepsCompleted SET AS IF THEY DID ALL CURRENTLY EXISTING STEPS.
  const shouldOpenAccountSetup = useMemo(() => {
    return (
      !userAccountsArray.length || selectedAccountStore?.shouldOpenAccountSetup
    );
  }, [userAccountsArray, selectedAccountStore]);

  if (
    !selectedAccountStore &&
    userAccountLoadingState !== ApiRequestStatusEnum.SUCCESS
  ) {
    return <Loader />;
  }

  if (shouldOpenAccountSetup) {
    return (
      <Redirect
        to={{
          pathname: ACCOUNT_SETUP_ROUTE,
        }}
      />
    );
  }

  return (
    <Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"row"}>
      <Box
        height="100vh"
        width={SIDEBAR_WIDTH}
        transition="all .25s"
        style={{ marginLeft: isLeftSidebarOpen ? "0" : `-${SIDEBAR_WIDTH}` }}
      >
        <LeftSideBar />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"0%"}
        overflow={"hidden"}
      >
        {children}
      </Box>
      {userAccountLoadingState === ApiRequestStatusEnum.PENDING && <Loader />}
    </Box>
  );
});
