import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { ReCaptchaV2 } from "components";
import { LEAD_PRO_BANNER } from "constants/image-paths";

interface AuthLayoutProps {
  children: React.ReactNode;
}
export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100vh"
      bg="gray.50"
    >
      <Image src={LEAD_PRO_BANNER} width="180px" alt="" mx="auto" mb={6} />
      <Box maxW="380px">
        <Box width="100%" p={10}>
          <ReCaptchaV2>{children}</ReCaptchaV2>
        </Box>
        <Text fontSize="xs" color="gray.500" mt={4} textAlign="center">
          Property Technology Ltd (SC528448) is registered in the United
          Kingdom. Property Technology Ltd, 70 White Lion Street, London, N1
          9PP.
        </Text>
      </Box>
    </Box>
  );
};
