export enum LeadNextActionDateFilterEnum {
  ALL = "all",
  OVERDUE = "overdue",
  TODAY = "today",
  THIS_WEEK = "this_week",
  LATER = "later",
  CUSTOM = "custom",
}

export enum LeadDateRangeFilterEnum {
  ALL = "all",
  TODAY = "today",
  THIS_WEEK = "this_week",
  CUSTOM = "custom",
}
