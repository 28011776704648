import { AppStore } from "store/App.store";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TReapitIROfficeRelation,
  TReapitIROfficeRelationData,
  TReapitIRSyncOfficeExtendedData,
} from "types/reapit-internet-registration.type";
import { keyBy } from "lodash";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { AccountReapitInternetRegistrationApi } from "api/account-reapit-internet-registration.api";

const accountReapitIRApi = new AccountReapitInternetRegistrationApi();

export class AccountIntegrationReapitIRStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountReapitIROfficeRelations: IObservableArray<
    TReapitIROfficeRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountReapitIROfficeRelations = observable.array<
      TReapitIROfficeRelation
    >([]);
    this.syncStatusFilter = false;
  }

  get reapitIROfficeRelationsMapByLeadProOfficeId() {
    return keyBy(
      this.accountReapitIROfficeRelations,
      relation => relation.officeId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get filteredReapitIRSyncOfficeData(): TReapitIRSyncOfficeExtendedData[] {
    return this.reapitIRSyncOfficeData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get reapitIRSyncOfficeData(): TReapitIRSyncOfficeExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproOffices =
      accountStore.accountOfficesStore.accountOfficesDataArray;
    const reapitIROfficeRelationsMapByLeadProOfficeID = this
      .reapitIROfficeRelationsMapByLeadProOfficeId;

    return leadproOffices.map(office => {
      const lettingsEmail =
        reapitIROfficeRelationsMapByLeadProOfficeID[office.id]?.lettingsEmail;
      const salesEmail =
        reapitIROfficeRelationsMapByLeadProOfficeID[office.id]?.salesEmail;

      let syncStatus =
        !!lettingsEmail || !!salesEmail
          ? IntegrationEntitySyncStatusEnum.SYNCED
          : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        officeId: office.id,
        officeName: office.name,
        lettingsEmail: lettingsEmail || null,
        salesEmail: salesEmail || null,
        syncStatus: syncStatus,
      };
    });
  }

  public setAccountReapitIROfficeRelations(
    relations: TReapitIROfficeRelation[]
  ) {
    this.accountReapitIROfficeRelations.replace(relations);
  }

  async fetchAccountReapitIRConfiguration() {
    const data = await accountReapitIRApi.fetchAccountReapitIROfficeRelations(
      this.accountId
    );
    this.setAccountReapitIROfficeRelations(data);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  async updateAccountReapitIRSyncedOffices(
    syncedPairs: TReapitIROfficeRelationData[]
  ) {
    await accountReapitIRApi.updateAccountReapitIRSyncedOffices(
      this.accountId,
      syncedPairs.map(pair => ({
        officeId: pair.officeId,
        salesEmail: pair.salesEmail,
        lettingsEmail: pair.lettingsEmail,
      }))
    );
    await this.fetchAccountReapitIRConfiguration();
  }
}
