import React, { ChangeEvent, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { Thumb } from "./Thumb";

interface IPictureUploadInputProps {
  id?: string;
  name: string;
  value?: File;
  src?: string;
  onChange: (file?: File) => void;
  onBlur: () => void;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

export const PictureUploadInput: React.FC<IPictureUploadInputProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  isDisabled,
  src,
  children,
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files?.[0];
      onChange(file);
    },
    [onChange]
  );

  return (
    <Box
      position={"relative"}
      mt={5}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        as={"input"}
        id={id || name}
        name={name}
        type="file"
        onChange={handleChange}
        onBlur={onBlur}
        disabled={isDisabled}
        border={0}
        height={"1px"}
        overflow={"hidden"}
        padding={0}
        position={"absolute"}
        whiteSpace={"nowrap"}
        width={"1px"}
        sx={{
          clip: "rect(0, 0, 0, 0)",
        }}
      />
      <Box
        as={"label"}
        htmlFor={id || name}
        width={"100%"}
        cursor={"pointer"}
        border={"2px dashed"}
        borderColor={"gray.200"}
        rounded={"md"}
        overflow={"hidden"}
      >
        {children || <Thumb file={value} src={src} />}
      </Box>
    </Box>
  );
};
