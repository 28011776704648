import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC } from "react";
import { VStack } from "@chakra-ui/react";
import {
  AnalyticsFiltersRow,
  AnalyticsFilterTypesEnum,
} from "./AnalyticsFiltersRow/AnalyticsFiltersRow";

interface IProps {
  accountStore: UserAccountStore;
  showFilters: AnalyticsFilterTypesEnum[];
  children: React.ReactNode;
}

export const FilterableAnalyticsView: FC<IProps> = observer(
  ({ accountStore, showFilters, children }) => {
    return (
      <VStack spacing={6} alignItems={"stretch"}>
        <AnalyticsFiltersRow
          accountStore={accountStore}
          showFilters={showFilters}
        />
        {children}
      </VStack>
    );
  }
);
