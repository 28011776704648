import { AccountReapitApi } from "api/account-reapit.api";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { TReapitContact } from "types/reapit.type";

const accountReapitApi = new AccountReapitApi();

export class AccountIntegrationReapitContactsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountContacts: IObservableArray<TReapitContact>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountContacts = observable.array<TReapitContact>();
  }

  get reapitRelatedContacts() {
    return this.accountContacts.slice();
  }

  get reapitRelatedContactsOptions() {
    return [
      ...this.accountContacts
        .slice()
        .sort(a => (a.matchedWith === "email" ? -1 : 1))
        .map(contact => ({
          value: contact.id,
          label: contact.id,
          data: {
            email: contact.email,
            phone:
              contact.mobilePhone || contact.workPhone || contact.homePhone,
            matchedWith: contact.matchedWith,
            name: `${contact.forename} ${contact.surname}`,
          },
        })),
      {
        value: "newRecord",
        label: "Create new record",
        data: {
          email: "",
          phone: "",
          matchedWith: "phone",
          name: "",
        },
      },
    ];
  }

  private async setReapitAccountContacts(
    remoteRelatedContacts: TReapitContact[]
  ) {
    this.accountContacts.replace(remoteRelatedContacts);
  }

  public async fetchReapitAccountContacts(
    leadId: number,
    officeGroupId?: number
  ) {
    const data = await accountReapitApi.fetchAccountReapitContacts(
      this.accountId,
      leadId,
      officeGroupId
    );

    await this.setReapitAccountContacts(data);
  }
}
