import { observer } from "mobx-react";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import { FC } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  propertyStore: AccountPropertyStore;
}

export const PropertyDetailsDisclaimer: FC<IProps> = observer(
  ({ propertyStore }) => {
    const leadsCount = propertyStore.propertyLeadsStore.meta.count;
    return (
      <Box textAlign={"center"}>
        <Box>
          Displaying{" "}
          <Box as={"span"} fontWeight={"bold"}>
            {leadsCount}
          </Box>{" "}
          filtered {`lead${leadsCount === 1 ? "" : "s"}`}
        </Box>
        <Box fontSize={"sm"} color={"leadpro.500"}>
          Based on the selections you have made <br /> on the responses above.
        </Box>
      </Box>
    );
  }
);
