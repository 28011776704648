import React, { useCallback } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { DeleteOfficePrompt } from "routes/dashboard/components/prompts/OfficePrompt/DeleteOfficePrompt";
import { OfficeFormPrompt } from "routes/dashboard/components/prompts/OfficePrompt/OfficeFormPrompt";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
  officeStore: AccountOfficeStore;
}

export const OfficeSetupItem: React.FC<IProps> = observer(
  ({ accountStore, officeStore }) => {
    const { setModal, unSetModal } = useActionPrompt();

    const officesStore = accountStore.accountOfficesStore;

    const handleRemoveOfficeFromAccount = useCallback(() => {
      setModal(
        <DeleteOfficePrompt
          officesStore={officesStore}
          office={officeStore.office}
          closePrompt={unSetModal}
        />
      );
    }, [officesStore, officeStore, setModal, unSetModal]);

    const handleOpenOfficeEditPrompt = useCallback(() => {
      setModal(
        <OfficeFormPrompt
          accountStore={accountStore}
          officeStore={officeStore}
          closePrompt={unSetModal}
        />,
        { closeOnOverlayClick: false }
      );
    }, [officeStore, accountStore, setModal, unSetModal]);

    return (
      <Box
        width={"100%"}
        padding={3}
        bg={"white"}
        rounded={"md"}
        mb={1}
        border={"1px solid"}
        borderColor={"gray.200"}
        display={"fled"}
        justifyContent={"space-between"}
        alignItems={"center"}
        fontSize={"sm"}
        fontWeight={500}
      >
        <Box>{officeStore.office.name}</Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            onClick={handleOpenOfficeEditPrompt}
            type={"button"}
            variant="link"
          >
            Edit
          </Button>
          <Button
            onClick={handleRemoveOfficeFromAccount}
            type={"button"}
            variant="link"
            colorScheme={"red"}
            ml={3}
          >
            Delete
          </Button>
        </Box>
      </Box>
    );
  }
);
