import React, { FC } from "react";
import { HStack, useTheme } from "@chakra-ui/react";
import { getScoreColor } from "utils/office-leaderboard.utils";
import { Box } from "@chakra-ui/react";
import { Tooltip } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
): { x: number; y: number } => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY - radius * Math.sin(angleInRadians),
  };
};

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
): string {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = Math.abs(endAngle - startAngle) <= 180 ? "0" : "1";

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
}

const SIZE = 150;
const ARC_RADIUS = 60;
const START_ANGLE = 220;
const END_ANGLE = -40;
const STROKE_WIDTH = 20;

export const GaugeStat: FC<{
  value: number;
  label: string;
  tooltip?: React.ReactNode;
}> = ({ value, label, tooltip }) => {
  const x = SIZE / 2;
  const y = SIZE / 2;
  const background = describeArc(x, y, ARC_RADIUS, START_ANGLE, END_ANGLE);
  const progress = describeArc(
    x,
    y,
    ARC_RADIUS,
    START_ANGLE,
    START_ANGLE - ((START_ANGLE - END_ANGLE) * value) / 100
  );

  const theme = useTheme();
  const color = getScoreColor(value);

  return (
    <Box display="flex" flexDirection={"column"}>
      <Box pos="relative">
        <svg width={150} height={150} viewBox="0 0 150 150">
          <path
            id="arc1"
            fill="none"
            stroke={theme.colors.gray[100]}
            strokeWidth={STROKE_WIDTH}
            d={background}
            strokeLinecap="round"
          />
          <path
            id="arc1"
            fill="none"
            stroke={color}
            strokeWidth={STROKE_WIDTH}
            d={progress}
            strokeLinecap="round"
          />
        </svg>
        <Box
          pos="absolute"
          top={0}
          left={0}
          height="100%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={color}
          fontSize={35}
          fontWeight={700}
        >
          {value}
        </Box>
        <HStack justifyContent={"center"}>
          <Box textAlign="center" fontSize={12} fontWeight={600}>
            {label}
          </Box>
          {!!tooltip && (
            <Tooltip aria-label={`${label}-tooltip`} label={tooltip}>
              <Box opacity={0.6}>
                <FontAwesomeIcon icon={faInfoCircle} fontSize={14} />
              </Box>
            </Tooltip>
          )}
        </HStack>
      </Box>
    </Box>
  );
};
