import { GraphCard } from "components/stats/GraphCard";
import { Legend } from "components/chart/Legend";
import { Chart, ChartType } from "components/chart";
import { formatDateForChart } from "utils/date.utils";
import { StatTable } from "components/stats/StatTable";
import React, { useCallback, useMemo, useState } from "react";
import { LeadByOption } from "enums/lead-by-option.enum";
import { toSnakeCase } from "utils/to-snake-case.utils";
import {
  TColorsMap,
  TLeadsInRangeCount,
  TLeadsStats,
  TLeadStatsGroupedByDate,
  TVisibilityMap,
} from "types/account-analytics.type";
import {
  getLegendForLeadsByOption,
  getSeriesForLeadsByOptionByDay,
  getStatLabelFunction,
} from "utils/account-stats.utils";
import { Box, Switch } from "@chakra-ui/react";
import { formatLeadsByXByDayForCSVDownload } from "utils/csv-exporter.utils";
import { CSVDownloadButton } from "components";

interface IProps<T extends string> {
  statType: LeadByOption;
  leadsByOption: TLeadsStats<T>;
  leadsInRangeCount: TLeadsInRangeCount<TLeadsStats<T>>;
  leadsByOptionByDay: TLeadStatsGroupedByDate<TLeadsStats<T>>[];
  colorDefinitionFunction: (keys: T[]) => TColorsMap<T>;
}

export function CardForLeadsByOption<T extends string>({
  statType,
  leadsByOption,
  leadsByOptionByDay,
  leadsInRangeCount,
  colorDefinitionFunction,
}: IProps<T>) {
  const options = useMemo(() => Object.keys(leadsByOption) as T[], [
    leadsByOption,
  ]);

  const [showSecondarySources, setShowSecondarySources] = useState<boolean>(
    false
  );

  const [visibleGraphSeries, setVisibleGraphSeries] = useState<
    TVisibilityMap<T>
  >(
    options.reduce((accumulator, option: T) => {
      accumulator[option] = true;
      return accumulator;
    }, {} as TVisibilityMap<T>)
  );

  const visibleOptions = useMemo(
    () => options.filter(key => visibleGraphSeries[key]),
    [options, visibleGraphSeries]
  );

  const colorDefinitions = useMemo(() => colorDefinitionFunction(options), [
    options,
    colorDefinitionFunction,
  ]);

  const labelFunction = useMemo(() => getStatLabelFunction(statType), [
    statType,
  ]);

  const legendItems = useMemo(() => {
    return getLegendForLeadsByOption(
      statType,
      options,
      colorDefinitions,
      visibleGraphSeries,
      labelFunction
    );
  }, [statType, options, colorDefinitions, visibleGraphSeries, labelFunction]);

  const chartSeries = useMemo(() => {
    return getSeriesForLeadsByOptionByDay(visibleOptions, leadsByOptionByDay);
  }, [visibleOptions, leadsByOptionByDay]);

  const subSeries = useMemo(() => {
    return leadsByOptionByDay.map(day => {
      let subSeriesEntry = {};
      visibleOptions.forEach(option => {
        let data: { name: string; value: number }[] = [];

        if (day.leads[option]) {
          const hostKeys = Object.keys(day.leads[option].hosts);
          data = hostKeys.map(host => ({
            name: host,
            value: day.leads[option].hosts[host],
          }));
        }

        subSeriesEntry = { ...subSeriesEntry, [option]: data };
      });
      return subSeriesEntry;
    });
  }, [visibleOptions, leadsByOptionByDay]);

  const hasSecondarySources = useMemo(() => {
    let secondarySourcesExist = false;
    leadsByOptionByDay.forEach(day => {
      options.forEach(option => {
        if (day.leads[option]) {
          const hostKeys = Object.keys(day.leads[option].hosts);
          if (hostKeys.length > 0) {
            secondarySourcesExist = true;
          }
        }
      });
    });
    return secondarySourcesExist;
  }, [options, leadsByOptionByDay]);

  const toggleGraphSeriesVisibility = useCallback(
    (series: T) => {
      setVisibleGraphSeries({
        ...visibleGraphSeries,
        [series]: !visibleGraphSeries[series],
      });
    },
    [visibleGraphSeries, setVisibleGraphSeries]
  );

  const handleLegendItemClick = useCallback(
    (key: T) => {
      toggleGraphSeriesVisibility(key);
    },
    [toggleGraphSeriesVisibility]
  );

  const formatStatsTableData = useMemo(() => {
    return formatLeadsByXByDayForCSVDownload<T>({
      leadsByOption,
      leadsInRangeCount,
    });
  }, [leadsInRangeCount, leadsByOption]);

  return (
    <GraphCard
      title={`Leads by ${statType} by day`}
      action={
        <Box display={"flex"} alignItems={"center"}>
          {hasSecondarySources && (
            <Box mr={3} display={"flex"} alignItems={"center"} gap={"0.5rem"}>
              <Switch
                isChecked={showSecondarySources}
                onChange={event =>
                  setShowSecondarySources(event.target.checked)
                }
              />
              <span>Show Secondary Sources</span>
            </Box>
          )}
          <CSVDownloadButton<any>
            data={formatStatsTableData}
            options={{
              filename: `Leads by ${statType} by day`,
            }}
          />
        </Box>
      }
    >
      <Legend<T> items={legendItems} onItemClick={handleLegendItemClick} />
      <Box mb={5}>
        <Chart
          type={ChartType.Line}
          series={chartSeries}
          labels={leadsByOptionByDay.map(i => formatDateForChart(i.date))}
          colors={visibleOptions.map(key => colorDefinitions[key])}
          seriesNames={visibleOptions.map(option =>
            labelFunction(toSnakeCase(option))
          )}
          subSeries={subSeries}
          showSecondarySources={showSecondarySources}
        />
      </Box>
      <StatTable
        statType={statType}
        totalLeads={leadsByOption}
        leadsInRangeCount={leadsInRangeCount}
        colorDefinitions={colorDefinitions}
        labelFunction={labelFunction}
        showSecondarySources={showSecondarySources}
      />
    </GraphCard>
  );
}
