import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { Box, HStack, useTheme } from "@chakra-ui/react";
import { Input, Tooltip } from "components";
import { TReapitIRSyncOfficeExtendedData } from "types/reapit-internet-registration.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import * as Yup from "yup";

interface IProps {
  record: TReapitIRSyncOfficeExtendedData;
  onChange: (syncPair: TReapitIRSyncOfficeExtendedData) => void;
  field: keyof TReapitIRSyncOfficeExtendedData;
  onValidation: (key: string, message: string | null) => void;
}

const validationSchema = Yup.string().email();

export const ReapitIREmailSyncInput: FC<IProps> = observer(
  ({ record, onChange, field, onValidation }) => {
    const theme = useTheme();
    const [validationError, setValidationError] = useState<string | null>(null);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...record,
          [field]: event.target.value || null,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record, field]
    );

    const handleBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        try {
          validationSchema.validateSync(event.target.value);
          setValidationError(null);
          onValidation(`${field}-${record.officeId}`, null);
        } catch (error) {
          setValidationError(error.message);
          onValidation(`${field}-${record.officeId}`, error.message);
        }
      },
      [onValidation, field, record.officeId]
    );

    return (
      <HStack px={1} spacing={1}>
        <Input
          value={record[field] || ""}
          placeholder={`Enter email`}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Tooltip label={validationError || ""}>
          <Box>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              fontSize={"28px"}
              color={theme.colors.red[500]}
              visibility={!!validationError ? "visible" : "hidden"}
            />
          </Box>
        </Tooltip>
      </HStack>
    );
  }
);
