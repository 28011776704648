import { observer } from "mobx-react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
  LeadStatusSelectOption,
  LeadStatusSelectOptionsRenderer,
} from "components";
import React, { useCallback, useMemo } from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { useForm } from "react-hook-form";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import * as Yup from "yup";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { TLeadStatus } from "types/lead-status.type";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";

interface IStrictLeadProgressionsLeadStatusPromptProps {
  leadStore: AccountLeadStore;
  leadStatusesStore: AccountLeadStatusesStore;
  leadStatus: TLeadStatus;
  closePrompt: (key?: string | undefined) => void;
}

export type TStrictLeadProgressionFormFieldValues = {
  leadStatus: TLeadStatus;
  note: string;
};

const validatonSchema = Yup.object().shape({
  leadStatus: yup
    .object()
    .shape({
      id: yup.number(),
      stageId: yup.string(),
      name: yup.string(),
      isPredefined: yup.bool(),
      predefinedStatus: yup.string().nullable(),
    })
    .required(REQUIRED_FIELD),
  note: Yup.string(),
});

export const StrictLeadProgressionLeadStatusPrompt: React.FC<IStrictLeadProgressionsLeadStatusPromptProps> = observer(
  ({ leadStore, leadStatusesStore, leadStatus, closePrompt }) => {
    const toast = useToast();
    const initialValues: TStrictLeadProgressionFormFieldValues = useMemo(() => {
      return {
        leadStatus: leadStatus,
        note: "",
      };
    }, [leadStatus]);

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TStrictLeadProgressionFormFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validatonSchema),
    });

    const onSubmit = useCallback(
      async (values: TStrictLeadProgressionFormFieldValues) => {
        const { note, leadStatus } = values;
        try {
          await Promise.all([
            leadStore.update({ statusId: leadStatus.id }),
            leadStore.addLeadContactNote(note, {
              append: false,
            }),
          ]);
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [leadStore, toast, closePrompt]
    );

    const note = watch("note");

    const isSubmitDisabled = useMemo(() => {
      return note.trim().length < 1;
    }, [note]);

    return (
      <ActionPromptContainer
        header={"Lead status update"}
        body={
          <Box pt={5}>
            <Box>
              Please provide an update regarding this lead’s status change.
              These updates can be reviewed by either yourself or other users to
              learn additional context about the lead’s progress.
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={5}>
                <FormControlV2<TStrictLeadProgressionFormFieldValues>
                  name={"leadStatus"}
                  control={control}
                  type={FormControlsTypeEnum.SINGLE_SELECT}
                  additionalProps={{
                    placeholder: "Select Lead Status",
                    options: leadStatusesStore.accountLeadStatusOptions,
                    optionComponent: (
                      optionProps: IGetOptionPropsData<TLeadStatus>
                    ) => <LeadStatusSelectOption optionProps={optionProps} />,
                    optionsRenderer: (
                      optionProps: IGetOptionPropsData<TLeadStatus>[]
                    ) => (
                      <LeadStatusSelectOptionsRenderer
                        optionProps={optionProps}
                      />
                    ),
                  }}
                />
              </Box>
              <Box mt={5}>
                <FormControlV2<TStrictLeadProgressionFormFieldValues>
                  name={"note"}
                  control={control}
                  type={FormControlsTypeEnum.TEXTAREA}
                  additionalProps={{
                    textAreaRows: 10,
                    placeholder:
                      "Provide an update regarding this lead’s status change...",
                  }}
                />
                <Box
                  mt={4}
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    type={"submit"}
                    colorScheme={"blue"}
                    isDisabled={isSubmitting || isSubmitDisabled}
                    isLoading={isSubmitting}
                  >
                    {"Update lead"}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
