import { TStripeCustomerInfoAddress } from "types/account-billing.type";
import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ApiMessageStack, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ActionPromptContainer } from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = TStripeCustomerInfoAddress;

const validationSchema = Yup.object().shape({
  line1: Yup.string().required(REQUIRED_FIELD),
  line2: Yup.string(),
  postalCode: Yup.string().required(REQUIRED_FIELD),
  city: Yup.string().required(REQUIRED_FIELD),
  country: Yup.string().required(REQUIRED_FIELD),
});

interface IProps {
  accountBillingStore: AccountBillingStore;
  closePrompt: () => void;
}

export const AccountBillingCustomerAddressPrompt: React.FC<IProps> = observer(
  ({ accountBillingStore, closePrompt }) => {
    const toast = useToast();
    const stripeCustomerAddress =
      accountBillingStore.billing?.stripeCustomerAddress;

    const initialValues: TFieldValues = useMemo(
      () => ({
        line1: stripeCustomerAddress?.line1 || "",
        line2: stripeCustomerAddress?.line2 || "",
        postalCode: stripeCustomerAddress?.postalCode || "",
        city: stripeCustomerAddress?.city || "",
        country: stripeCustomerAddress?.country || "",
      }),
      [stripeCustomerAddress]
    );

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          await accountBillingStore.updateBillingCustomerInfo({
            address: {
              ...values,
              line2: values.line2 || null,
            },
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Your billing address has been updated."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountBillingStore, closePrompt, toast]
    );

    return (
      <ActionPromptContainer
        header={"Update billing address"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"line1"}
                  control={control}
                  label={"Line 1"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"line2"}
                  control={control}
                  label={"Line 2"}
                  isOptional={true}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"postalCode"}
                  control={control}
                  label={"Postcode"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"city"}
                  control={control}
                  label={"City"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"country"}
                  control={control}
                  label={"Country"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
