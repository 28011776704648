import {
  SETTINGS_OFFICE_DETAILS_ROUTE,
  SETTINGS_OFFICES_ROUTE,
} from "constants/routes";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { OfficeSettings } from "./routes/office-settings/OfficeSettings";
import { Box } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { OfficesTabIndex } from "./components/OfficesTabIndex";

interface IProps {
  accountStore: UserAccountStore;
}
export const Offices: FC<IProps> = observer(({ accountStore }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  return (
    <Box
      position={"relative"}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      overflowY={"hidden"}
      ref={ref}
    >
      <Switch>
        <Route path={SETTINGS_OFFICES_ROUTE} exact>
          <OfficesTabIndex accountStore={accountStore} />
        </Route>
        <Route path={SETTINGS_OFFICE_DETAILS_ROUTE} exact>
          <OfficeSettings containerRef={ref} accountStore={accountStore} />
        </Route>
      </Switch>
    </Box>
  );
});
