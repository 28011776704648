import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useCallback } from "react";
import { TAltoNegotiatorRelationExtendedData } from "types/alto.type";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { TSelectValue } from "types/select-input.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TAltoNegotiatorRelationExtendedData;
  onChange: (syncPair: TAltoNegotiatorRelationExtendedData) => void;
}

export const AltoNegotiatorSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const options =
      accountStore.accountIntegrationsStore.accountIntegrationAltoStore
        .accountIntegrationAltoNegotiatorsStore.altoNegotiatorsOptionsWithData;

    const handleChange = useCallback(
      (altoId: TSelectValue<string>) => {
        onChange({
          altoId: altoId || null,
          userAccountId: record.userAccountId,
          userName: record.userName,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.altoId}
        clearable={true}
        options={options}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
