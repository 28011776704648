import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TAccountIntegrationData,
  TRexConfig,
} from "types/account-integration.type";
import { Box, Center } from "@chakra-ui/react";
import { Loader } from "components";
import { RexIntegrationLeadSourcesConfigurator } from "./RexIntegrationSourcesConfigurator/RexIntegrationLeadSourcesConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const RexIntegrationResources: FC<IProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;

    const accountIntegrationRexLeadSourcesStore =
      accountIntegrationsStore.accountIntegrationRexStore;
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const rexIntegrationConfig = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.REX
    ] as TAccountIntegrationData<TRexConfig>;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountIntegrationRexLeadSourcesStore.fetchAccountRexLeadSourcesAndRelations(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountLeadSourcesStore,
      accountIntegrationRexLeadSourcesStore,
      rexIntegrationConfig.integrationConfig,
    ]);

    return (
      <Box position={"relative"} minHeight={"200px"}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && (
          <Center position={"absolute"} top={0} bottom={0} left={0} right={0}>
            <Box>
              <Box>
                {`Rex customer id is used to load your Rex resources. Check the customer id`}
                {rexIntegrationConfig.active
                  ? "."
                  : " and enable this integration."}
              </Box>
            </Box>
          </Center>
        )}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <RexIntegrationLeadSourcesConfigurator
              accountStore={accountStore}
            />
          </>
        )}
      </Box>
    );
  }
);
