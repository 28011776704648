import { observer } from "mobx-react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { DripIntegrationForm } from "./DripIntegrationForm";

interface IProps {
  accountStore: UserAccountStore;
}

export const DripIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;

    return (
      <DripIntegrationForm
        accountIntegrationsStore={accountIntegrationsStore}
      />
    );
  }
);
