import { Box, ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import { TLeadResponderWorkflowCoreData } from "types/lead-responder-workflow.type";
import { FC } from "react";

interface ITemplateUserInWorkflowMessage {
  workflows: TLeadResponderWorkflowCoreData[];
  description: string;
}

export const TemplateUsedInWorkflowNotice: FC<ITemplateUserInWorkflowMessage> = ({
  workflows,
  description,
}) => {
  return (
    <VStack spacing={4} align={"stretch"}>
      <Box>{description}</Box>
      <UnorderedList spacing={4} pl={10}>
        {workflows.map(workflow => (
          <ListItem key={workflow.id}>{workflow.name}</ListItem>
        ))}
      </UnorderedList>
    </VStack>
  );
};
