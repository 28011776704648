import { BaseApi } from "./base.api";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import { keysToCamel } from "../utils/keys-to-camel.utils";
import { TUserInviteOrUpdateData } from "types/user-invitation.type";
import { TUserBasicData } from "types/user-data.type";

export class AccountUsersApi extends BaseApi {
  async fetchAccountUsersBasicData(
    accountId: number
  ): Promise<TUserBasicData[]> {
    const { data } = await this.get(`/accounts/${accountId}/users/basic`);
    return data;
  }

  async fetchAccountUsers(
    accountId: number
  ): Promise<IAccountUserDataWithDetails[]> {
    const { data } = await this.get(`/accounts/${accountId}/users`, {
      // TODO: REMOVE THIS AND INTRODUCE PROPER PAGINATION
      params: {
        limit: 100000,
        offset: 0,
      },
    });

    return keysToCamel(data);
  }

  async fetchAccountUser(
    accountId: number,
    userId: number
  ): Promise<IAccountUserDataWithDetails> {
    return this.get(`/accounts/${accountId}/users/${userId}`);
  }

  async updateAccountUser(
    accountId: number,
    userId: number,
    data: Partial<TUserInviteOrUpdateData>
  ): Promise<IAccountUserDataWithDetails> {
    return this.put(`/accounts/${accountId}/users/${userId}`, data);
  }

  async inviteUserToAccount(
    accountId: number,
    data: TUserInviteOrUpdateData
  ): Promise<IAccountUserDataWithDetails> {
    return this.post(`/accounts/${accountId}/users`, data);
  }

  async resendInvite(
    userId: number,
    accountId: number
  ): Promise<IAccountUserDataWithDetails> {
    return this.post(`/accounts/${accountId}/users/${userId}/resend-invite`);
  }

  async removeUserFromAccount(
    accountId: number,
    userId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/users/${userId}/`);
  }
}
