import { Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

interface ITagInputControlsProps {
  values: string[];
  clearable?: boolean;
  onChange: (value: string[]) => void;
}

export const TagInputControls: React.FC<ITagInputControlsProps> = ({
  onChange,
  values,
  clearable,
}) => {
  const handleClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  return (
    <Box position={"absolute"} height={"100%"} right={2} pb={2}>
      {!!clearable && !!values.length && (
        <Box
          display={"flex"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FontAwesomeIcon
            icon={faXmark}
            fontSize={18}
            onClick={handleClear}
            cursor={"pointer"}
          />
        </Box>
      )}
    </Box>
  );
};
