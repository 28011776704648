import * as Yup from "yup";
import { REQUIRED_FIELD } from "../../constants/validator-messages";

export type TOpenViewIntegrationConfigFieldValues = {
  openViewClientId: string;
  openViewClientSecret: string;
  isLeadAutoAssignable: boolean;
};

export const openViewIntegrationInitialValues: TOpenViewIntegrationConfigFieldValues = {
  openViewClientId: "",
  openViewClientSecret: "",
  isLeadAutoAssignable: true,
};

export const openViewIntegrationConnectValidationSchema = Yup.object().shape({
  openViewClientId: Yup.string().required(REQUIRED_FIELD),
  openViewClientSecret: Yup.string().required(REQUIRED_FIELD),
  isLeadAutoAssignable: Yup.boolean().required(REQUIRED_FIELD),
});
