import React, { FC } from "react";
import { AccountDetails } from "./components/AccountDetails/AccountDetails";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { Box } from "@chakra-ui/react";
import { AccountEmailNotifications } from "./components/AccountEmailNotifications/AccountEmailNotifications";
import { AccountLeadResponder } from "./components/AccountLeadResponder/AccountLeadResponder";
import { AccountAdvanced } from "./components/AccountAdvanced/AccountAdvanced";
import { CustomStatuses } from "./components/CustomStatuses/CustomStatuses";
import { AccountLeadsConfig } from "./components/AccountLeadsConfig/AccountLeadsConfig";
import { AccountJourneys } from "./components/AccountJourneys/AccountJourneys";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountSettings: FC<IProps> = observer(({ accountStore }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const accountData = accountStore.account;

  return (
    <Box
      position={"relative"}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      overflowY={"hidden"}
      ref={ref}
    >
      <TabsGroupLayout
        title={accountData.name}
        config={[
          {
            tabHash: "#details",
            tabName: "Details",
            tabComponent: (
              <AccountDetails accountStore={accountStore} containerRef={ref} />
            ),
          },
          {
            tabHash: "#email-notifications",
            tabName: "Email Notifications",
            tabComponent: (
              <AccountEmailNotifications
                accountStore={accountStore}
                containerRef={ref}
              />
            ),
          },
          {
            tabHash: "#leads-config",
            tabName: "Leads",
            tabComponent: (
              <AccountLeadsConfig
                accountStore={accountStore}
                containerRef={ref}
              />
            ),
          },
          {
            tabHash: "#lead-responder",
            tabName: "Lead Responder",
            tabComponent: <AccountLeadResponder accountStore={accountStore} />,
          },
          {
            tabHash: "#journeys",
            tabName: "Filters and Journeys",
            tabComponent: <AccountJourneys accountStore={accountStore} />,
          },
          {
            tabHash: "#custom-statuses",
            tabName: "Custom Statuses",
            tabComponent: <CustomStatuses accountStore={accountStore} />,
          },
          {
            tabHash: "#advanced",
            tabName: "Advanced",
            tabComponent: <AccountAdvanced accountStore={accountStore} />,
          },
        ]}
      />
    </Box>
  );
});
