import React from "react";
import { Observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";

export enum LeadBlockTypes {
  BasicBlock = "BASIC_BLOCK",
  SettingsBlock = "SETTINGS_BLOCK",
  PortalBlock = "PORTAL_BLOCK",
  ValuationBlock = "VALUATION_BLOCK",
  QuestionnaireBlock = "QUESTIONNAIRE_BLOCK",
  ConnectedLeadsBlock = "CONNECTED_LEADS_BLOCK",
  BookingRequest = "BOOKING_REQUEST",
  MetaBlock = "METAL_BLOCK",
  UTMBlock = "UTM",
  SourceBlock = "SOURCE_BLOCK",
  BYMBlock = "BYM_BLOCK",
}

export interface ILeadBlockDefinition {
  blockType: LeadBlockTypes;
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

export interface ILeadBlockComponentProps<T extends ILeadBlockDefinition> {
  leadBlockDefinition: T;
}

export const leadBlockHoc = <T extends ILeadBlockDefinition>(
  Component: React.JSXElementConstructor<ILeadBlockComponentProps<T>>
) => {
  return (props: ILeadBlockComponentProps<ILeadBlockDefinition>) => (
    <Observer>
      {() => <Component {...(props as ILeadBlockComponentProps<T>)} />}
    </Observer>
  );
};
