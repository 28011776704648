import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TTemplateMergeTag,
  TTemplateMergeTagGroup,
} from "types/template-merge-tag.type";
import { SuggestionDataItem } from "react-mentions";
import { AccountUrlSyncApi } from "../../../../api/account-url-sync.api";
import { AppStore } from "../../../App.store";

const accountUrlSyncApi = new AccountUrlSyncApi();

export class AccountIntegrationUrlSyncStore {
  private readonly root: AppStore;
  public readonly accountId: number;
  private readonly mergeTagGroups: IObservableArray<TTemplateMergeTagGroup>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.mergeTagGroups = observable.array<TTemplateMergeTagGroup>();
  }

  get mergeTagGroupsArray() {
    return this.mergeTagGroups.slice();
  }

  get mergeTagsFlatArray() {
    const mergeTags: TTemplateMergeTag[] = [];
    this.mergeTagGroupsArray.forEach(mergeTagGroup =>
      mergeTags.push(...mergeTagGroup.items)
    );

    return mergeTags;
  }

  get mergeTagGroupOptionsArray(): SuggestionDataItem[] {
    const options: SuggestionDataItem[] = [];
    this.mergeTagGroupsArray.forEach(group => {
      group.items.forEach(tag => {
        options.push({
          id: tag.value,
          display: group.name + " - " + tag.text,
        });
      });
    });

    return options;
  }

  private setMergeTagGroups(mergeTagGroups: TTemplateMergeTagGroup[]) {
    this.mergeTagGroups.replace(mergeTagGroups);
  }

  public async loadUrlSyncMergeTags() {
    const mergeTagGroups = await accountUrlSyncApi.fetchMergeTags(
      this.accountId
    );
    this.setMergeTagGroups(mergeTagGroups);
  }
}
