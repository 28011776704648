import { Box, BoxProps } from "@chakra-ui/react";
import React, { FC } from "react";

export const PanelFooter: FC<BoxProps> = ({ children }) => {
  return (
    <Box
      py={3}
      px={6}
      bg="gray.50"
      borderTop="1px solid"
      borderColor="gray.200"
      display="flex"
    >
      {children}
    </Box>
  );
};
