import React, { useCallback, useState } from "react";
import { Box, useClipboard, HStack, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faArrowRotateRight } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormControlLabel, StandardIconButton } from "components";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { times } from "lodash";

interface IProps {
  label: string;
  name: string;
  value: string;
  onRegenerateKey: () => void;
}

export const DevelopersConfigKeyItem: React.FC<IProps> = ({
  label,
  name,
  value,
  onRegenerateKey,
}) => {
  const { setModal, unSetModal } = useActionPrompt();
  const [isHidden, setIsHidden] = useState(true);
  const { onCopy, hasCopied } = useClipboard(value);

  const toggleIsHidden = useCallback(() => {
    setIsHidden(!isHidden);
  }, [isHidden, setIsHidden]);

  const handleRegenerateKey = useCallback(() => {
    // TODO NENAD: USE TOASTS
    setModal(
      <ConfirmActionPrompt
        text={
          "Generating new key will invalidate the current one. Are you sure?"
        }
        onConfirm={onRegenerateKey}
        closePrompt={unSetModal}
      />
    );
  }, [onRegenerateKey, setModal, unSetModal]);

  return (
    <Box width={"100%"} px={1}>
      <FormControlLabel>{label}</FormControlLabel>
      <HStack
        spacing={4}
        width={"100%"}
        align={"center"}
        justify={"space-between"}
      >
        {isHidden ? (
          <HStack spacing={1}>
            {times(30, key => (
              <FontAwesomeIcon icon={faCircle} key={key} fontSize="8px" />
            ))}
          </HStack>
        ) : (
          <Box fontSize={"sm"} fontWeight={600} noOfLines={1}>
            {value}
          </Box>
        )}
        <HStack>
          <Button variant={"outline"} size={"sm"} onClick={toggleIsHidden}>
            {isHidden ? "Reveal" : "Hide"}
          </Button>
          <StandardIconButton
            onClick={onCopy}
            aria-label={`copy-key-${name}`}
            colorScheme={hasCopied ? "green" : undefined}
            icon={<FontAwesomeIcon icon={faCopy} />}
          />
          <StandardIconButton
            onClick={handleRegenerateKey}
            aria-label={`generate-key-${name}`}
            colorScheme={"red"}
            icon={<FontAwesomeIcon icon={faArrowRotateRight} />}
          />
        </HStack>
      </HStack>
    </Box>
  );
};
