import React, { useEffect } from "react";
import { HOME_ROUTE } from "constants/routes";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";

export const NonAuthRoute: React.FC<RouteProps> = observer(
  ({ children, ...rest }) => {
    const {
      authStore: { initStore, isFullyLoggedIn },
    } = useAppStore();

    useEffect(() => {
      initStore();
    }, [initStore]);

    if (isFullyLoggedIn) {
      return <Redirect to={HOME_ROUTE} />;
    }

    return <Route {...rest}>{children}</Route>;
  }
);
