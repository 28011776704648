import { FC, PropsWithChildren } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Box } from "@chakra-ui/react";

export const DraggableElement: FC<PropsWithChildren<{
  id: string;
  index: number;
}>> = ({ id, index, children }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <Box
          width={"100%"}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </Box>
      )}
    </Draggable>
  );
};
