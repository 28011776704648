import React, { useCallback, useMemo, useRef } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCalendarRange } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useTheme,
} from "@chakra-ui/react";
import { DATE_CALENDAR_FORMAT } from "constants/date";
import { StandardIconButton } from "../standard-icon-button/StandardIconButton";
import { SingleDatePickerController } from "./SingleDatePickerController";

interface ISingleDatePickerInputProps {
  date: string;
  onChange: (date: string | null) => void;
  clearable?: boolean;
  disabled?: boolean;
  maxDate?: string;
  minDate?: string;
  noBorder?: boolean;
  noIcon?: boolean;
}

export const SingleDatePickerInput: React.FC<ISingleDatePickerInputProps> = ({
  date,
  onChange,
  clearable,
  disabled,
  maxDate,
  minDate,
  noBorder,
  noIcon,
}) => {
  const inputRef = useRef(null);
  const theme = useTheme();

  const dateLabel = useMemo(() => {
    if (!date) {
      return (
        <Box color={"gray.400"} fontSize={"md"}>
          Date
        </Box>
      );
    }

    return (
      <Box color={"gray.900"} fontSize={"md"}>
        {moment(date).format(DATE_CALENDAR_FORMAT)}
      </Box>
    );
  }, [date]);

  const handleOnChange = useCallback(
    (onClose: () => void) => (newDate: string | null) => {
      onChange(newDate);
      onClose();
    },
    [onChange]
  );

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(null);
    },
    [onChange]
  );

  return (
    <Popover
      closeOnBlur={true}
      initialFocusRef={inputRef}
      isLazy={true}
      arrowSize={20}
      gutter={15}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              minWidth={clearable ? "150px" : "120px"}
              height={"42px"}
              px={2}
              py={1}
              border={noBorder ? "none" : "1px solid"}
              borderColor={"gray.200"}
              rounded={noBorder ? "none" : "md"}
              pointerEvents={disabled ? "none" : undefined}
              opacity={disabled ? 0.6 : undefined}
              cursor={disabled ? "not-allowed" : "pointer"}
            >
              {!noIcon && (
                <FontAwesomeIcon
                  icon={faCalendarRange}
                  fontSize={20}
                  color={theme.colors.gray[400]}
                />
              )}
              {dateLabel}
              {!!clearable && (
                <StandardIconButton
                  onClick={handleClear}
                  aria-label={"delete-template"}
                  icon={<FontAwesomeIcon icon={faXmark} fontSize={18} />}
                />
              )}
            </HStack>
          </PopoverTrigger>
          <PopoverContent width={"unset"}>
            <PopoverArrow />
            <PopoverBody padding={1}>
              <SingleDatePickerController
                date={date}
                onChange={handleOnChange(onClose)}
                maxDate={maxDate}
                minDate={minDate}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
