import { createIcon } from "@chakra-ui/react";
import { withIconProps } from "utils/hoc/chakra-svg-icon.hoc";

const NoBusinessCreateIcon = createIcon({
  displayName: "NoBusinessIcon",
  viewBox: "0 0 9 7",
  path: (
    <path
      id="Ellipse 3"
      d="M9 4.27778C9 6.64033 6.98528 7 4.5 7C2.01472 7 0 6.64033 0 4.27778C0 1.91523 2.01472 0 4.5 0C6.98528 0 9 1.91523 9 4.27778Z"
    />
  ),
});

export const NoBusinessIcon = withIconProps(NoBusinessCreateIcon);
