import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useMemo } from "react";
import { Box, HStack, MenuItemOption, MenuOptionGroup } from "@chakra-ui/react";
import { DateRangePickerController } from "components/date-picker/DateRangePickerController";
import { observer } from "mobx-react";
import { DropdownFilterMenuShell } from "components";
import { TSerializedDateRange } from "types/date.type";
import { END_OF_TODAY } from "constants/date";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import { isEqual } from "lodash";
import { LeadResponderEmailSimpleFilterTypes } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenuItem";
import { TLeadResponderEmailDateRangeFilterConfiguration } from "types/lead-responder-email.type";

export enum LeadResponderEmailDateRangeFilterEnum {
  ALL = "all",
  TODAY = "today",
  THIS_WEEK = "this_week",
  CUSTOM = "custom",
}

export const LeadResponderEmailDateRangeFiltersOptions: {
  value: LeadResponderEmailDateRangeFilterEnum;
  label: string;
}[] = [
  {
    value: LeadResponderEmailDateRangeFilterEnum.ALL,
    label: "All",
  },
  {
    value: LeadResponderEmailDateRangeFilterEnum.TODAY,
    label: "Today",
  },
  {
    value: LeadResponderEmailDateRangeFilterEnum.THIS_WEEK,
    label: "This week",
  },
  {
    value: LeadResponderEmailDateRangeFilterEnum.CUSTOM,
    label: "Custom",
  },
];

interface IProps {
  emailFiltersStore: AcccountLeadResponderEmailsFiltersStore;
}

export const FilterEmailsDateRangeHeader: FC<IProps> = observer(
  ({ emailFiltersStore }) => {
    const {
      pendingFilter: { dateReceivedRange: pendingDateRange },
      activeFilter,
      togglePendingFilterByType,
    } = emailFiltersStore;

    const isOptionSelected = useMemo(() => {
      if (pendingDateRange.type !== LeadResponderEmailDateRangeFilterEnum.ALL)
        return 1;

      return 0;
    }, [pendingDateRange]);

    const handleOptionSelect = useCallback(
      (option: string | string[]) => {
        const value = option as LeadResponderEmailDateRangeFilterEnum;
        togglePendingFilterByType<
          TLeadResponderEmailDateRangeFilterConfiguration
        >({ type: value }, LeadResponderEmailSimpleFilterTypes.DATE_RECEIVED);
      },
      [togglePendingFilterByType]
    );

    const handleDatesChange = useCallback(
      (dates: TSerializedDateRange) => {
        emailFiltersStore.togglePendingFilterByType<
          TLeadResponderEmailDateRangeFilterConfiguration
        >(
          {
            type: LeadResponderEmailDateRangeFilterEnum.CUSTOM,
            from: dates.startDate || undefined,
            to: dates.endDate || undefined,
          },
          LeadResponderEmailSimpleFilterTypes.DATE_RECEIVED
        );
      },
      [emailFiltersStore]
    );

    const onClose = useCallback(() => {
      if (!isEqual(pendingDateRange, activeFilter?.dateReceivedRange)) {
        emailFiltersStore.setActiveFromPendingFilter();
      }
    }, [emailFiltersStore, pendingDateRange, activeFilter?.dateReceivedRange]);

    return (
      <>
        <HStack spacing={2}>
          <Box
            fontSize={"sm"}
            color={"gray.500"}
            fontWeight={500}
            py={2}
            w={"100%"}
          >
            Date Received
          </Box>
        </HStack>
        <DropdownFilterMenuShell
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          badgeCount={isOptionSelected}
          onClose={onClose}
        >
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            pl={5}
            pr={3}
            py={3}
          >
            <Box fontWeight={800} color={"leadpro.700"}>
              Emails In Date Range
            </Box>
          </HStack>
          <HStack spacing={0} pr={2}>
            <MenuOptionGroup
              type="radio"
              value={pendingDateRange.type}
              onChange={handleOptionSelect}
            >
              {LeadResponderEmailDateRangeFiltersOptions.map(option => (
                <MenuItemOption key={option.value} value={option.value}>
                  {option.label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            <Box borderLeft={"1px solid"} borderColor={"gray.200"}>
              <DateRangePickerController
                startDate={pendingDateRange.from || null}
                endDate={pendingDateRange.to || null}
                onChange={handleDatesChange}
                maxDate={END_OF_TODAY}
              />
            </Box>
          </HStack>
        </DropdownFilterMenuShell>
      </>
    );
  }
);
