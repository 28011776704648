import { Box } from "@chakra-ui/react";
import React from "react";
import { FormControlLabel } from "./FormControlLabel";
import { FormControlDescription } from "./FormControlDescription";
import { FormControlOptionalMark } from "./FormControlOptionalMark";

interface IFormControlMetaProps {
  label?: string | React.ReactNode;
  isOptional?: boolean;
  description?: React.ReactNode;
}

export const FormControlMeta: React.FC<IFormControlMetaProps> = ({
  label,
  isOptional,
  description,
}) => {
  if (!!label || isOptional || !!description) {
    return (
      <Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={!!label || isOptional ? 2 : 0}
        >
          {label && <FormControlLabel width={"100%"}>{label}</FormControlLabel>}
          {isOptional && <FormControlOptionalMark />}
        </Box>
        {description && (
          <FormControlDescription>{description}</FormControlDescription>
        )}
      </Box>
    );
  }

  return null;
};
