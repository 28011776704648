import { observer } from "mobx-react";
import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import React, { FC } from "react";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadAttributionCalculationValuesSettingsContent } from "./LeadAttributionCalculationValuesSettingsContent";

interface IProps {
  accountAnalyticsStore: AccountAnalyticsStore;
}

export const LeadAttributionCalculationValuesSettings: FC<IProps> = observer(
  ({ accountAnalyticsStore }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    return (
      <Popover
        closeOnBlur={true}
        isLazy={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={"bottom-end"}
      >
        <PopoverTrigger>
          <HStack spacing={2} color={"blue.500"} cursor={"pointer"}>
            <FontAwesomeIcon icon={faCog} fontSize={20} />
            <Box>Change your calculation values</Box>
          </HStack>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width={"645px"}>
            <PopoverArrow />
            <PopoverHeader fontSize={"md"} fontWeight={700} color={"blue.500"}>
              Lead attribution calculation figures
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody pt={5}>
              <LeadAttributionCalculationValuesSettingsContent
                accountAnalyticsStore={accountAnalyticsStore}
                onClose={onClose}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
