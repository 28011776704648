import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import {
  TLeadResponderLeadEmailAdditionalData,
  TLeadResponderLeadEmailsMeta,
  TLeadResponderLeadEmailsResponse,
} from "types/account-lead-emails.type";
import { AccountApi } from "api/account.api";
import { AppStore } from "../../../App.store";
import { AccountLeadResponderEmailStore } from "./AccountLeadResponderEmail.store";
import { keyBy } from "lodash";
import { AcccountLeadResponderEmailsFiltersStore } from "./AccountLeadResponderEmailsFilters.store";
import { TLeadResponderEmailsFilter } from "types/lead-responder-email.type";
import { SortingRule } from "react-table";

const FETCH_LIMIT = 10;
let latestLoadingAttempt: number | null;

const DEFAULT_META = {
  limit: FETCH_LIMIT,
  offset: 0,
  count: 0,
};

const accountApi = new AccountApi();

export class AccountLeadResponderEmailsStore {
  private readonly accountId: number;
  private root: AppStore;
  public emailFilterStore: AcccountLeadResponderEmailsFiltersStore;
  private emailMeta: TLeadResponderLeadEmailsMeta;
  private emailStores: IObservableArray<AccountLeadResponderEmailStore>;
  private page: number;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.emailFilterStore = new AcccountLeadResponderEmailsFiltersStore(
      this.accountId,
      this.root
    );
    this.emailStores = observable.array<AccountLeadResponderEmailStore>();
    this.page = 0;
    this.emailMeta = DEFAULT_META;
  }

  get leadEmails() {
    return this.emailStores.map(emailStore => toJS(emailStore.email));
  }

  get currentPage() {
    return this.page;
  }

  get meta() {
    return this.emailMeta;
  }

  get emailStoresMap() {
    return keyBy(this.emailStores, emailStore => emailStore.email.id);
  }

  private setEmails(emailResponse: TLeadResponderLeadEmailsResponse) {
    const { data, meta } = emailResponse;
    this.emailStores.replace(
      data.map(
        email =>
          new AccountLeadResponderEmailStore(this.root, this.accountId, email)
      )
    );
    this.emailMeta = meta;
  }

  public setPage(page: number) {
    this.page = page;
  }

  public resetMeta() {
    this.emailMeta = DEFAULT_META;
    this.setPage(0);
  }

  public async loadEmails(
    sortBy: SortingRule<string>[],
    filter?: TLeadResponderEmailsFilter
  ) {
    const now = Date.now();
    latestLoadingAttempt = now;

    const data = await accountApi.fetchAccountLeadEmails(
      this.accountId,
      sortBy,
      filter,
      this.page * this.emailMeta.limit,
      this.emailMeta.limit
    );

    if (now === latestLoadingAttempt) {
      this.setEmails(data);
    }
  }

  public async getEmailWithDetails(emailId: number) {
    if (!!this.emailStoresMap[emailId]) {
      await this.emailStoresMap[emailId].fetchDetails();
    } else {
      const emailData = await accountApi.fetchOneAccountLeadEmail(
        this.accountId,
        emailId
      );
      this.addEmail(emailData);
      await this.emailStoresMap[emailId]?.fetchDetails();
    }
  }

  private addEmail(emailData: TLeadResponderLeadEmailAdditionalData) {
    this.emailStores.push(
      new AccountLeadResponderEmailStore(this.root, this.accountId, emailData)
    );
  }
}
