import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TOpenViewSyncLeadSourceExtendedData } from "types/openview.type";
import { TSelectValue } from "types/select-input.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TOpenViewSyncLeadSourceExtendedData;
  onChange: (syncPair: TOpenViewSyncLeadSourceExtendedData) => void;
}

export const OpenViewLeadSourceSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const options =
      accountStore.accountIntegrationsStore.accountIntegrationOpenViewStore
        .accountIntegrationOpenViewLeadSourcesStore.openViewLeadSourceOptions;

    const handleChange = useCallback(
      (openViewSource: TSelectValue<string>) => {
        onChange({
          openViewSource: openViewSource || null,
          leadSourceId: record.leadSourceId,
          leadSourceName: record.leadSourceName,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.openViewSource}
        clearable={true}
        options={options}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
