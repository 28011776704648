import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountIntegrationReapitLeadSourcesStore } from "./AccountIntegrationReapitLeadSources.store";
import { AccountIntegrationReapitNegotiatorsStore } from "./AccountIntegrationReapitNegotiators.store";
import { AccountIntegrationOfficesStore } from "../AccountIntegrationOfficesStore.store";
import { AccountReapitApi } from "api/account-reapit.api";
import { AccountIntegrationReapitAreaStore } from "./AccountIntegrationReapitAreas.store";
import { AccountIntegrationReapitContactsStore } from "./AccountIntegrationReapitContacts.store";
import { TAccountReapitIntegrationConfigSyncData } from "../../../../../types/reapit.type";

const accountReapitApi = new AccountReapitApi();

export class AccountIntegrationReapitStore {
  public accountIntegrationReapitOfficesStore: AccountIntegrationOfficesStore;
  public accountIntegrationReapitLeadSourcesStore: AccountIntegrationReapitLeadSourcesStore;
  public accountIntegrationReapitNegotiatorsStore: AccountIntegrationReapitNegotiatorsStore;
  public accountIntegrationReapitAreasStore: AccountIntegrationReapitAreaStore;
  public accountIntegrationReapitContactsStore: AccountIntegrationReapitContactsStore;
  public accountId: number;
  private configSyncData: TAccountReapitIntegrationConfigSyncData;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountIntegrationReapitOfficesStore = new AccountIntegrationOfficesStore(
      root,
      accountId,
      accountReapitApi
    );
    this.accountIntegrationReapitLeadSourcesStore = new AccountIntegrationReapitLeadSourcesStore(
      root,
      accountId
    );
    this.accountIntegrationReapitNegotiatorsStore = new AccountIntegrationReapitNegotiatorsStore(
      root,
      accountId
    );
    this.accountIntegrationReapitAreasStore = new AccountIntegrationReapitAreaStore(
      root,
      accountId
    );
    this.accountIntegrationReapitContactsStore = new AccountIntegrationReapitContactsStore(
      root,
      accountId
    );

    this.accountId = accountId;

    this.configSyncData = {
      multiReapitIntegration: false,
      enableApplicantRequirements: false,
      areApplicantRequirementsFieldsMandatory: false,
    };
  }

  get isMultiIntegrationEnabled() {
    return this.configSyncData.multiReapitIntegration;
  }

  get isApplicantRequirementsEnabled() {
    return this.configSyncData.enableApplicantRequirements;
  }
  get areApplicantRequirementsFieldsMandatory() {
    return this.configSyncData.areApplicantRequirementsFieldsMandatory;
  }

  public async fetchReapitIntegrationConfigSyncData() {
    this.configSyncData = await accountReapitApi.getReapitConfig(
      this.accountId
    );
  }
}
