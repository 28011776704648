import { AccountAltoApi } from "api/account-alto.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  TAltoLeadSource,
  TAltoLeadSourceRelation,
  TAltoLeadSourceRelationData,
  TAltoSyncLeadSourceExtendedData,
} from "types/alto.type";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";

const accountAltoApi = new AccountAltoApi();

export class AccountIntegrationAltoLeadSourcesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private syncStatusFilter: boolean;
  private readonly accountAltoLeadSources: IObservableArray<TAltoLeadSource>;
  private readonly accountAltoLeadSourceRelations: IObservableArray<
    TAltoLeadSourceRelation
  >;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountAltoLeadSources = observable.array<TAltoLeadSource>();
    this.accountAltoLeadSourceRelations = observable.array<
      TAltoLeadSourceRelation
    >();
    this.syncStatusFilter = false;
  }

  get altoLeadSourceRelationsMapByLeadProLeadSourceId() {
    return keyBy(
      this.accountAltoLeadSourceRelations,
      relation => relation.leadSourceId
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get altoLeadSourceRelationsMapByAltoLeadSourceId() {
    return keyBy(
      this.accountAltoLeadSourceRelations,
      relation => relation.altoId
    );
  }

  get filteredAltoSyncLeadSourceData(): TAltoSyncLeadSourceExtendedData[] {
    return this.altoSyncLeadSourceData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get altoSyncLeadSourceData(): TAltoSyncLeadSourceExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproLeadSources =
      accountStore.accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    const altoLeadSourceRelationsMapByLeadProLeadSourceId = this
      .altoLeadSourceRelationsMapByLeadProLeadSourceId;

    return leadproLeadSources.map(leadSource => {
      let pairedAltoLeadSourceId =
        altoLeadSourceRelationsMapByLeadProLeadSourceId[leadSource.id]?.altoId;
      let syncStatus = !!pairedAltoLeadSourceId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        leadSourceId: leadSource.id,
        leadSourceName: leadSource.name,
        altoId: pairedAltoLeadSourceId,
        syncStatus: syncStatus,
      };
    });
  }

  get altoLeadSourcesOptions() {
    return this.accountAltoLeadSources
      .map(altoLeadSource => ({
        value: altoLeadSource.altoId,
        label: altoLeadSource.altoName,
        data: this.altoLeadSourceRelationsMapByAltoLeadSourceId[
          altoLeadSource.altoId
        ],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountAltoLeadSources(leadSources: TAltoLeadSource[]) {
    this.accountAltoLeadSources.replace(leadSources);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public setAccountAltoLeadSourceRelations(
    relations: TAltoLeadSourceRelation[]
  ) {
    this.accountAltoLeadSourceRelations.replace(relations);
  }

  public async fetchAccountAltoLeadSources() {
    const data = await accountAltoApi.fetchAccountAltoLeadSources(
      this.accountId
    );
    this.setAccountAltoLeadSources(data);
  }

  public async fetchAccountAltoLeadSourceRelations() {
    const data = await accountAltoApi.fetchAccountAltoLeadSourceRelations(
      this.accountId
    );
    this.setAccountAltoLeadSourceRelations(data);
  }

  public async fetchAccountAltoLeadSourcesAndRelations() {
    await Promise.all([
      this.fetchAccountAltoLeadSources(),
      this.fetchAccountAltoLeadSourceRelations(),
    ]);
  }

  public async updateAccountAltoSyncedLeadSources(
    syncedPairs: TAltoLeadSourceRelationData[]
  ) {
    await accountAltoApi.updateAccountAltoSyncedLeadSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        altoId: pair.altoId,
      }))
    );
    await this.fetchAccountAltoLeadSourcesAndRelations();
  }
}
