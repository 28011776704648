import React from "react";
import { ReferralTag, NA } from "components";
import { Wrap, WrapItem } from "@chakra-ui/react";
import { TLeadWithDetails } from "types/lead.type";

interface ILeadReferralTableCellProps {
  leadReferredByAccount?: TLeadWithDetails["leadReferredByAccount"];
  leadReferredByOffice?: TLeadWithDetails["leadReferredByOffice"];
}

export const LeadReferralTableCell: React.FC<ILeadReferralTableCellProps> = ({
  leadReferredByAccount,
  leadReferredByOffice,
}) => {
  if (!leadReferredByAccount && !leadReferredByOffice) return <NA />;

  return (
    <Wrap>
      {!!leadReferredByAccount && (
        <WrapItem alignItems={"center"}>
          <ReferralTag
            name={leadReferredByAccount.name}
            logoUrl={leadReferredByAccount.logoUrl}
          />
        </WrapItem>
      )}
      {!!leadReferredByOffice && (
        <WrapItem alignItems={"center"}>
          <ReferralTag name={leadReferredByOffice.name} />
        </WrapItem>
      )}
    </Wrap>
  );
};
