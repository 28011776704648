import { Box } from "@chakra-ui/react";
import { PaidFeatureBanner } from "components";
import React from "react";

interface IProps {
  render: (isEnabled: boolean) => React.ReactElement;
  customBanner?: React.ReactNode;
  ctaNavigateTo?: string;
}

export const PremiumFeatureBlockedOverlay: React.FC<IProps> = ({
  render,
  customBanner,
  ctaNavigateTo,
}) => {
  return (
    <Box position={"relative"} minHeight={"100%"}>
      <Box pointerEvents={"none"} filter={"blur(3px)"}>
        {render(false)}
      </Box>
      <Box position={"absolute"} top={0} left={0} bottom={0} right={0}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100%"}
        >
          <Box width={"100%"} py={4} bg={"gray.200"} textAlign={"center"}>
            {customBanner || (
              <PaidFeatureBanner ctaNavigateTo={ctaNavigateTo} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
