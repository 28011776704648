import {
  TItemStatCalculation,
  TItemStatsSummary,
  TLeadAttributionByItem,
  TLeadAttributionCalculationMatrix,
  TStatAttributeCalculation,
} from "../types/account-lead-attribution-analyticis.type";
import {
  LeadAttributionGroupedEnum,
  LeadAttributionTypesEnum,
} from "../enums/account-lead-attribution.enum";

export function getLeadAttributionStatsForItem<
  T extends TLeadAttributionByItem
>(
  byItem: T,
  leadAttributionCalculationMatrix: TLeadAttributionCalculationMatrix
): TItemStatsSummary<T> {
  let totalEstValuePerItem = 0;
  const itemStats: TItemStatCalculation[] = byItem.stats.map(stat => {
    let totalCountByStat = 0;
    const statAttributeCalculations: TStatAttributeCalculation[] = [];
    const calculationInstructionsForStat =
      leadAttributionCalculationMatrix[stat.name];
    stat.attributes.forEach(attribute => {
      totalCountByStat += attribute.count;
      const calculationInstructionsForAttribute =
        calculationInstructionsForStat?.[attribute.name];
      if (
        !!calculationInstructionsForStat &&
        !!calculationInstructionsForAttribute
      ) {
        const monetaryValue =
          attribute.count * calculationInstructionsForAttribute.multiplier;
        statAttributeCalculations.push({
          label: calculationInstructionsForAttribute.label,
          count: attribute.count,
          monetaryValue,
        });
        if (stat.name === LeadAttributionGroupedEnum.COMPLETIONS) {
          totalEstValuePerItem += monetaryValue;
        }
      }
    });

    return {
      title: stat.name,
      totalCountByStat,
      statAttributeCalculations,
    };
  });

  return {
    ...byItem,
    totalEstValuePerItem,
    preparedStatsPerItem: itemStats,
  };
}

export function getLeadAttributionItemUnknownLabel(
  leadAttributionType: LeadAttributionTypesEnum
) {
  return `Unknown ${leadAttributionType}`;
}
