import { addPrefixToId } from "utils/office-group.utils";
import { AccountOfficesApi } from "api/account-offices.api";
import { TOfficeGroupData } from "types/account-office.type";
import { keyBy, sortBy, uniq } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { OfficeCategory } from "enums/office-type.enum";
import { TSelectOption } from "types/select-input.type";

const accountOfficesApi = new AccountOfficesApi();

export class AccountOfficeGroupsStore {
  private root: AppStore;
  public accountId: number;
  public accountOfficeGroups: IObservableArray<TOfficeGroupData>;
  public associatedUserIds: IObservableArray<number>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountOfficeGroups = observable.array<TOfficeGroupData>();
    this.associatedUserIds = observable.array<number>();
  }

  get accountOfficeGroupsArray() {
    return this.accountOfficeGroups.slice();
  }

  get accountOfficeGroupOptionsWithPrefix() {
    return sortBy(this.accountOfficeGroupsArray, officeGroup =>
      officeGroup.name.toLowerCase()
    ).map(group => {
      return {
        value: addPrefixToId(group.id, OfficeCategory.GROUP),
        label: group.name,
      };
    });
  }

  get accountOfficeGroupsOptions(): TSelectOption<number>[] {
    return sortBy(this.accountOfficeGroupsArray, officeGroup =>
      officeGroup.name.toLocaleLowerCase()
    ).map(group => {
      return {
        value: group.id,
        label: group.name,
      };
    });
  }

  get officeGroupMapById() {
    return keyBy(this.accountOfficeGroupsArray, officeGroup => officeGroup.id);
  }

  get associatedUserIdsArray() {
    return this.associatedUserIds.slice();
  }

  private setAccountOfficeGroups(officeGroups: TOfficeGroupData[]) {
    this.accountOfficeGroups.replace(officeGroups);
  }

  private findIndexOfOfficeGroup(accountOfficeGroup: TOfficeGroupData) {
    return this.accountOfficeGroups.findIndex(
      officeGroup => officeGroup.id === accountOfficeGroup.id
    );
  }

  private deleteOneAccountOfficeGroup(accountOfficeGroupId: number) {
    const officeGroupToDelete = this.officeGroupMapById[accountOfficeGroupId];
    const indexOfGroup = this.findIndexOfOfficeGroup(officeGroupToDelete);
    if (indexOfGroup > -1) {
      this.accountOfficeGroups.splice(indexOfGroup, 1);
    }
  }

  private upsertAccountOfficeGroup(accountOfficeGroup: TOfficeGroupData) {
    const index = this.findIndexOfOfficeGroup(accountOfficeGroup);

    if (index > -1) {
      this.accountOfficeGroups[index] = accountOfficeGroup;
    } else {
      this.accountOfficeGroups.push(accountOfficeGroup);
    }
  }

  public getOfficeIdsForOfficeGroupIds(officeGroupIds: number[]) {
    const officeIds: number[] = [];

    officeGroupIds.forEach(officeGroupId => {
      const officeGroup = this.officeGroupMapById[officeGroupId];

      if (!!officeGroup) {
        officeIds.push(...officeGroup.officeIds);
      }
    });

    return uniq(officeIds);
  }

  public getUserIdsForOfficeGroupIds(officeGroupIds: number[]) {
    const userIds: number[] = [];

    officeGroupIds.forEach(officeGroupId => {
      const officeGroup = this.officeGroupMapById[officeGroupId];

      if (!!officeGroup) {
        userIds.push(...officeGroup.userIds);
      }
    });

    return uniq(userIds);
  }

  async fetchAllAssociatedUserIdsForOfficeGroupId(officeGroupId: number) {
    const associatedUsers = await accountOfficesApi.fetchOfficeGroupAssociatedUsers(
      this.accountId,
      officeGroupId
    );

    this.associatedUserIds.replace(associatedUsers?.map(user => user.id));
  }

  async fetchAllAccountOfficeGroups(): Promise<void> {
    const data = await accountOfficesApi.fetchAccountOfficeGroups(
      this.accountId
    );
    this.setAccountOfficeGroups(data);
  }

  async createOfficeGroup(
    name: string,
    userIds: number[],
    officeIds: number[]
  ): Promise<void> {
    const data = await accountOfficesApi.createAccountOfficeGroup(
      this.accountId,
      name,
      userIds,
      officeIds
    );

    this.upsertAccountOfficeGroup(data);
  }

  async updateOfficeGroup(
    officeGroupId: number,
    name: string,
    userIds: number[],
    officeIds: number[]
  ): Promise<void> {
    const data = await accountOfficesApi.updateAccountOfficeGroup(
      this.accountId,
      officeGroupId,
      name,
      userIds,
      officeIds
    );

    this.upsertAccountOfficeGroup(data);
  }

  async removeOfficeGroup(officeGroupId: number): Promise<void> {
    await accountOfficesApi.removeAccountOfficeGroup(
      this.accountId,
      officeGroupId
    );

    this.deleteOneAccountOfficeGroup(officeGroupId);
  }
}
