import { Box } from "@chakra-ui/react";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { useMemo } from "react";
import { TPersistentFilterPropertyValueSelectOption } from "types/persistent-filters.type";

export const PersistentFilterPropertyValueSelectOption = ({
  optionProps: { option, selectedOption, ...rest },
}: ISelectInputOptionProps<
  string,
  TPersistentFilterPropertyValueSelectOption
>) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);

  const isDisabled = useMemo(() => option?.data?.selectable === false, [
    option,
  ]);

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <Box>{option.label}</Box>
    </SelectInputOptionBase>
  );
};
