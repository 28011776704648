import React, { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { NEW_DEFAULT_CONDITION_GROUP } from "constants/form-tool-page";
import { Expression, Group } from "@leadpro/forms";
import { FormToolPageSettingsConfigConditionGroup } from "../../../../../../../../../components/FormTool/FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionGroup";
import { FormToolPageSettingsConfigConditionExpression } from "../../../../../../../../../components/FormTool/FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionExpression";
import { FormToolPageSettingsConfigConditionConfigurator } from "../../../../../../../../../components/FormTool/FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionConfigurator";
import { QuestionnaireToolPageWIPFormStore } from "../../../stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  customEndingPageIndex: number;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsCustomEndingPageConditionConfigurator: FC<IProps> = observer(
  ({ customEndingPageIndex, questionnaireToolPageWIPFormStore }) => {
    const customEndingPage =
      questionnaireToolPageWIPFormStore.wipFormCustomEndingPagesJSWithTempId[
        customEndingPageIndex
      ];

    const handleAddConditionGroup = useCallback(() => {
      questionnaireToolPageWIPFormStore.updateWipFormCustomEndingPageConfigCondition(
        customEndingPageIndex,
        NEW_DEFAULT_CONDITION_GROUP
      );
    }, [questionnaireToolPageWIPFormStore, customEndingPageIndex]);

    const onConditionsChange = useCallback(
      (childIndex: number, groupOrExpression?: Group | Expression) => {
        questionnaireToolPageWIPFormStore.updateWipFormCustomEndingPageConfigCondition(
          customEndingPageIndex,
          groupOrExpression
        );
      },
      [questionnaireToolPageWIPFormStore, customEndingPageIndex]
    );

    const rootElement = useMemo(() => {
      if (
        !!customEndingPage.condition &&
        (customEndingPage.condition as Group).children
      ) {
        return (
          <FormToolPageSettingsConfigConditionGroup
            isRoot={true}
            childIndex={0}
            onChange={onConditionsChange}
            group={customEndingPage.condition as Group}
            formToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
            validationResult={
              questionnaireToolPageWIPFormStore.wipFormCustomEndingPagesValidationResult
            }
            validationPath={[customEndingPageIndex, "condition"]}
          />
        );
      }

      if (!!customEndingPage.condition) {
        return (
          <FormToolPageSettingsConfigConditionExpression
            childIndex={0}
            onChange={onConditionsChange}
            expression={customEndingPage.condition as Expression}
            formToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
            validationResult={
              questionnaireToolPageWIPFormStore.wipFormCustomEndingPagesValidationResult
            }
            validationPath={[customEndingPageIndex, "condition"]}
          />
        );
      }
    }, [
      customEndingPage.condition,
      customEndingPageIndex,
      questionnaireToolPageWIPFormStore,
      onConditionsChange,
    ]);

    return (
      <FormToolPageSettingsConfigConditionConfigurator
        hasCondition={!!customEndingPage.condition}
        handleAddConditionGroup={handleAddConditionGroup}
        rootElement={rootElement}
      />
    );
  }
);
