import axios, { AxiosRequestConfig } from "axios";
import queryString from "query-string";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

export class BaseApi {
  private static sharedAxiosInstance = BaseApi.createSharedInstance();
  private static unauthorizedRequestHandler: () => void;

  private static createSharedInstance() {
    const instance = axios.create();
    instance.defaults.baseURL = BaseApi.getBaseUrl();
    instance.defaults.paramsSerializer = params => {
      return queryString.stringify(params, { arrayFormat: "none" });
    };

    instance.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (
          error.response?.status === 401 &&
          !error.response?.config?.url?.includes("/login")
        ) {
          return BaseApi.unauthorizedRequestHandler?.();
        } else {
          return Promise.reject(error.response?.data || error);
        }
      }
    );

    return instance;
  }

  public static setDefaultAuthorizationHeader(token: string | null) {
    if (token) {
      this.sharedAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    } else {
      delete this.sharedAxiosInstance.defaults.headers.common["Authorization"];
    }
  }

  public static getBaseUrl(): string {
    const baseUrl = env.REACT_APP_NEW_API_BASE_URL;
    if (!baseUrl) {
      throw new Error("REACT_APP_API_NEW_BASE_URL is not specified");
    }
    return baseUrl;
  }

  public static onUnauthorizedRequest = (handler: () => void) => {
    BaseApi.unauthorizedRequestHandler = handler;
  };

  get axiosInstance() {
    return BaseApi.sharedAxiosInstance;
  }

  protected async get<T>(path: string, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.get<T>(path, config);

    return response?.data;
  }

  protected async del<T>(path: string, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.delete<T>(path, config);

    return response?.data;
  }

  protected async post<T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    const response = await this.axiosInstance.post<T>(path, data, config);

    return response?.data;
  }

  protected async patch<T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    const response = await this.axiosInstance.patch<T>(path, data, config);

    return response?.data;
  }

  protected async put<T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    const response = await this.axiosInstance.put<T>(path, data, config);

    return response?.data;
  }
}
