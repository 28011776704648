import React from "react";
import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/react";

interface ExtendedTooltipProps extends TooltipProps {
  label: React.ReactNode;
}

export const Tooltip: React.FC<ExtendedTooltipProps> = props => {
  return (
    <ChakraTooltip
      paddingX={3}
      paddingY={2}
      borderRadius={"md"}
      closeOnMouseDown={true}
      closeOnClick={true}
      {...props}
    />
  );
};
