import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { DefaultHeaderCell } from "components/table-v2/header-cells/DefaultHeaderCell";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DATE_FORMAT } from "constants/date";
import { TOfficeGroupData } from "types/account-office.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "utils/date.utils";

export const officeGroupsTableColumnDef = (
  handleDeleteOfficeGroup: (row: TOfficeGroupData) => () => void,
  handleEditOfficeGroup: (row: TOfficeGroupData) => () => void
): ITableV2Column<TOfficeGroupData>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Created at</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell py={2}>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
      width: "100px",
    },
    {
      accessor: "officeIds",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Offices</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>{`${row.officeIds.length} offices`}</DefaultRowCell>
      ),
      width: "100px",
    },
    {
      accessor: "userIds",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Users</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>{`${row.userIds.length} users`}</DefaultRowCell>
      ),
      width: "100px",
    },
    {
      accessor: "id",
      width: "70px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: null,
      Cell: ({ row }) => (
        <DefaultRowCell
          justifyContent={"flex-end"}
          onClick={event => event.stopPropagation()}
        >
          <Menu isLazy={true} placement={"bottom-end"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={
                <FontAwesomeIcon icon={faEllipsisVertical} fontSize="16px" />
              }
              size="xs"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={handleEditOfficeGroup(row)}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteOfficeGroup(row)}>Remove</MenuItem>
            </MenuList>
          </Menu>
        </DefaultRowCell>
      ),
    },
  ];
};
