import { GuidesStore } from "./Guides.store";
import { UIStore } from "./UI.store";
import { AuthStore } from "./Auth.store";
import { UserAccountsStore } from "./UserAccounts/UserAccounts.store";
import { io, Socket } from "socket.io-client";
import { SocketEventEnum } from "enums/socket-event.enum";
import { TLeadWithDetails } from "types/lead.type";
import { TAccountBillingData } from "types/account-billing.type";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { makeAutoObservable } from "mobx";

const env = runtimeEnv();

export class AppStore {
  private ioClient: Socket | null;
  public authStore: AuthStore;
  public userAccountsStore: UserAccountsStore;
  public guidesStore: GuidesStore;
  public uiStore: UIStore;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.ioClient = null;
    this.authStore = new AuthStore(this);
    this.userAccountsStore = new UserAccountsStore(this);
    this.guidesStore = new GuidesStore(this);
    this.uiStore = new UIStore(this);
  }

  public destroySocketIO() {
    this.ioClient?.disconnect();
    this.ioClient = null;
  }

  public initSocketIO(token: string | null, accountId: number) {
    if (!!this.ioClient) {
      this.ioClient.disconnect();
    }

    this.ioClient = io(`${env.REACT_APP_NEW_API_BASE_URL}/accounts`, {
      query: {
        token,
        accountId,
      },
      transports: ["websocket", "polling"], // use WebSocket first, if available
      withCredentials: true,
    });

    this.ioClient.on(SocketEventEnum.LEAD_CREATED, (lead: TLeadWithDetails) => {
      this.userAccountsStore.selectedAccountStore?.accountLeadsStore.addLeadNotification(
        lead
      );
    });

    this.ioClient.on(
      SocketEventEnum.ACCOUNT_BILLING_UPDATE,
      (accountBilling: TAccountBillingData) => {
        this.userAccountsStore.userAccountsMap[accountId]?.deepUpsert({
          accountBilling,
        });
      }
    );
  }
}

export default new AppStore();
