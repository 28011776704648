import React from "react";
import { Text } from "@chakra-ui/react";

interface IStandardDrawerTitleProps {
  children: React.ReactNode;
}

export const StandardDrawerTitle: React.FC<IStandardDrawerTitleProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Text fontSize={"20px"} fontWeight={600}>
      {children}
    </Text>
  );
};
