import moment from "moment";

export const DATE_SHORT_FORMAT = "D MMM";
export const DATE_FORMAT = "D MMM YYYY";
export const DATE_STRIPE_SUBSCRIPTION = "Do of MMMM";

export const DATE_TIME_FORMAT = "D MMM YYYY HH:mm";
export const DATE_TIME_DETAIL_FORMAT = "D MMM 'YY @ HH:mm:ss";
export const DATE_TIME_DAY_DECORATOR_FORMAT = "Do MMMM YYYY, HH:mm";
export const DATE_TIME_DAY_OF_THE_WEEK_FORMAT = "ddd D MMM YY @ HH:mm";
export const DATE_TIME_SLASH_FORMAT = "DD/MM/YYYY HH.mm";

export const DATE_YEAR_MONTH = "YYYY-MM";
export const DATE_YEAR_MONTH_DAY = "YYYY-MM-DD";
export const DATE_MONTH_YEAR = "MMMM YYYY";
export const DATE_CALENDAR_FORMAT = "DD-MM-YY";
export const DATE_CALENDAR_FORMAT_WITH_YEAR = "DD/MM/YYYY";
export const DATE_YEAR = "YYYY";

export const TODAY = moment()
  .subtract(1, "day")
  .endOf("day")
  .toISOString();

export const END_OF_TODAY = moment()
  .endOf("day")
  .toISOString();
