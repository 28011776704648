import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import React, { FC, useMemo } from "react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { RightmoveIntegrationForm } from "routes/dashboard/routes/settings/routes/account-integrations/routes/integration-settings/components/RightmoveIntegrationConfigurator/RightmoveIntegrationForm";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  TAccountIntegrationData,
  TRightmoveConfig,
} from "types/account-integration.type";
import { formatDate } from "utils/date.utils";
import { DATE_CALENDAR_FORMAT } from "constants/date";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}
export const RightmoveIntegrationAPIConfigurator: FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const rightmoveIntegrationData = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.RIGHTMOVE
    ] as TAccountIntegrationData<TRightmoveConfig>;

    const banner = useMemo(() => {
      if (!!rightmoveIntegrationData) {
        const expiryDate = formatDate(
          rightmoveIntegrationData.integrationConfig?.certificateExpiryDate,
          DATE_CALENDAR_FORMAT
        );
        return (
          <Message my={5} status={AlertStatusEnum.WARNING}>
            <Box>Your current certificate expires on {expiryDate}.</Box>
          </Message>
        );
      } else {
        return null;
      }
    }, [rightmoveIntegrationData]);

    return (
      <>
        {banner}
        <RightmoveIntegrationForm
          accountIntegrationsStore={accountIntegrationsStore}
        />
      </>
    );
  }
);
