import {
  LeadAddressCell,
  LeadDateCreatedCell,
  LeadStatusCell,
  LeadTypeCell,
} from "components/table/cells/leads-table";
import { LeadByOption } from "enums/lead-by-option.enum";
import { TLead } from "types/lead.type";
import { Row } from "react-table";
import { ExtendedColumn } from "components/table/table-props";
import { TLeadStatusBasic } from "types/lead-status.type";
import React from "react";

export const basicLeadsTableColumnDef = (
  leadStatusesMapByStatusId: Record<number, TLeadStatusBasic>
): ExtendedColumn<TLead>[] => {
  return [
    {
      Header: "Date",
      accessor: "createdAt",
      minWidth: 150,
      Cell: ({ row }) => <LeadDateCreatedCell lead={row.original} />,
    },
    {
      Header: "Status",
      accessor: "statusId",
      minWidth: 150,
      Cell: ({ row }) => (
        <LeadStatusCell
          status={leadStatusesMapByStatusId[row.original.statusId]}
        />
      ),
    },
    {
      Header: "Type",
      accessor: LeadByOption.TYPE,
      minWidth: 200,
      Cell: ({ row }) => <LeadTypeCell lead={row.original} />,
    },
    {
      Header: "Subject",
      accessor: (row: TLead) => row.advertAddress || row.address,
      minWidth: 250,
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadAddressCell lead={row.original} />
      ),
    },
  ];
};
