import React, { FC, useCallback, useEffect, useMemo } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Accordion, Box, Center, HStack } from "@chakra-ui/react";
import {
  Error,
  Loader,
  Message,
  Paginator,
  StandardIconButton,
} from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import { AccountFormPagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountFormPagesStore/AccountFormPages.store";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { FormToolPageResponsesStore } from "./FormToolPageResponses.store";
import { FormToolPageResponseItem } from "./FormToolPageResponseItem";
import { observer } from "mobx-react";
import { AlertStatusEnum } from "../../../../../../../../enums/alert-status.enum";

const PAGE_SIZE = 10;

interface IProps {
  accountFormPagesStore: AccountFormPagesStore;
  pageId: number | null;
}

export const FormToolPageResponses: FC<IProps> = observer(
  ({ accountFormPagesStore, pageId }) => {
    const formToolPageResponsesStore = useLocalStore(
      FormToolPageResponsesStore,
      {
        accountFormPagesStore,
        pageId,
      }
    );

    const showWhenPendingOrSuccess = useMemo(() => {
      const listData = formToolPageResponsesStore.listData;
      if (!listData) return null;

      if (!!listData.data.length) {
        return (
          <>
            <Accordion width={"100%"} allowToggle={true}>
              {listData.data.map(response => (
                <FormToolPageResponseItem
                  key={response.id}
                  response={response}
                />
              ))}
            </Accordion>
            <Paginator
              border={"none"}
              total={listData.total}
              pageSize={listData.pageSize}
              pageIndex={listData.pageIndex}
              canPreviousPage={listData.canPreviousPage}
              canNextPage={listData.canNextPage}
              gotoPage={formToolPageResponsesStore.handleGoToPage}
            />
          </>
        );
      } else if (!listData.data.length) {
        return (
          <Message status={AlertStatusEnum.INFO}>
            <Center width={"100%"}>No data.</Center>
          </Message>
        );
      }
    }, [
      formToolPageResponsesStore.listData,
      formToolPageResponsesStore.handleGoToPage,
    ]);

    const handleInitialFetch = useCallback(() => {
      formToolPageResponsesStore.loadData(0, PAGE_SIZE);
    }, [formToolPageResponsesStore]);

    useEffect(() => {
      formToolPageResponsesStore.loadData(0, PAGE_SIZE);
    }, [formToolPageResponsesStore, pageId]);

    return (
      <Box
        width={"100%"}
        position={"relative"}
        minHeight={"400px"}
        p={"65px"}
        overflowY={"auto"}
        height={"100%"}
      >
        <HStack
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={"45px"}
          width={"100%"}
        >
          <Box>
            <Box fontSize={"20px"} fontWeight={"bold"}>
              Responses
            </Box>
            <Box color={"leadpro.500"} fontSize={"sm"}>
              These are the responses captured from your lead form.
            </Box>
          </Box>
          <StandardIconButton
            onClick={handleInitialFetch}
            isDisabled={
              formToolPageResponsesStore.loadingStatus ===
              ApiRequestStatusEnum.PENDING
            }
            aria-label={"reload-requests"}
            icon={<FontAwesomeIcon icon={faArrowRotateLeft} fontSize={24} />}
          />
        </HStack>
        {formToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {formToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {(formToolPageResponsesStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING ||
          formToolPageResponsesStore.loadingStatus ===
            ApiRequestStatusEnum.SUCCESS) &&
          showWhenPendingOrSuccess}
      </Box>
    );
  }
);
