import React from "react";
import { ReCaptchaV2Context } from "components";

export const useRecaptchaV2 = () => {
  const context = React.useContext(ReCaptchaV2Context);
  if (context === undefined) {
    throw new Error("useRecaptchaV2 must be used within a ReCaptchaV2");
  }

  return context;
};
