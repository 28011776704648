import { observer } from "mobx-react";
import React, { FC, useCallback } from "react";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE } from "constants/routes";
import { QuestionnaireToolPageSettingsEditorStore } from "./stores/QuestionnaireToolPageSettingsEditor.store";
import { PageToolPageSettings } from "routes/dashboard/routes/tools/components/PageTool/PageToolPageSettings/PageToolPageSettings";
import { PageToolPageSettingsTabHeader } from "routes/dashboard/routes/tools/components/PageTool/PageToolPageSettings/PageToolPageSettingsTabHeader";
import { QuestionnaireToolPageSettingsDetailsForm } from "./QuestionnaireToolPageSettingsDetailsForm/QuestionnaireToolPageSettingsDetailsForm";
import { QuestionnaireToolPageSettingsQuestionnairesBuilder } from "./QuestionnaireToolPageSettingsQuestionnairesBuilder/QuestionnaireToolPageSettingsQuestionnairesBuilder";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { QuestionnaireToolPageSettingsPreviewLinkSelector } from "./QuestionnaireToolPageSettingsPreviewLinkSelector";
import { QuestionnaireToolPageSettingsQuestionnairesBranching } from "./QuestionnaireToolPageSettingsQuestionnairesBranching/QuestionnaireToolPageSettingsQuestionnairesBranching";
import { QuestionnaireToolPageSettingsEndingPages } from "./QuestionnaireToolPageSettingsEndingPages/QuestionnaireToolPageSettingsEndingPages";

interface IProps {
  accountStore: UserAccountStore;
  pageId: number | null;
}

export const QuestionnaireToolPageSettings: FC<IProps> = observer(
  ({ pageId, accountStore }) => {
    const accountQuestionnairePagesStore =
      accountStore.accountPagesStore.accountQuestionnairePagesStore;

    const questionnaireToolPageSettingsEditorStore = useLocalStore(
      QuestionnaireToolPageSettingsEditorStore,
      {
        accountQuestionnairePagesStore,
        pageId,
      }
    );

    const getNewPageRedirectRoute = useCallback(
      (newPageId: number) =>
        `${TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE}/${newPageId}`,
      []
    );

    const externalLinkComponentRenderer = useCallback(() => {
      return (
        <QuestionnaireToolPageSettingsPreviewLinkSelector
          questionnaireToolPageSettingsEditorStore={
            questionnaireToolPageSettingsEditorStore
          }
        />
      );
    }, [questionnaireToolPageSettingsEditorStore]);

    return (
      <PageToolPageSettings
        pageToolPageSettingsEditorStore={
          questionnaireToolPageSettingsEditorStore
        }
        getNewPageRedirectRoute={getNewPageRedirectRoute}
        hideCreateOrUpdateButton={
          questionnaireToolPageSettingsEditorStore.isPageDefault
        }
        config={[
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={
                  questionnaireToolPageSettingsEditorStore.loadingStatus
                }
                tabName={"Details"}
                hasError={
                  !questionnaireToolPageSettingsEditorStore.isWIPPageValid
                }
              />
            ),
            tabHash: "#details",
            tabComponent: (
              <QuestionnaireToolPageSettingsDetailsForm
                accountStore={accountStore}
                questionnaireToolPageSettingsEditorStore={
                  questionnaireToolPageSettingsEditorStore
                }
              />
            ),
          },
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={
                  questionnaireToolPageSettingsEditorStore.loadingStatus
                }
                tabName={"Questions"}
                hasError={
                  !questionnaireToolPageSettingsEditorStore.areAllWIPQuestionnaireFormSchemasWithoutLogicAndWithActionsValid
                }
              />
            ),
            tabHash: "#builder",
            tabComponent: (
              <QuestionnaireToolPageSettingsQuestionnairesBuilder
                questionnaireToolPageSettingsEditorStore={
                  questionnaireToolPageSettingsEditorStore
                }
              />
            ),
          },
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={
                  questionnaireToolPageSettingsEditorStore.loadingStatus
                }
                tabName={"Branching"}
                hasError={
                  !questionnaireToolPageSettingsEditorStore.areAllWIPQuestionnairesLogicsValid
                }
              />
            ),
            tabHash: "#branching",
            tabComponent: (
              <QuestionnaireToolPageSettingsQuestionnairesBranching
                questionnaireToolPageSettingsEditorStore={
                  questionnaireToolPageSettingsEditorStore
                }
              />
            ),
          },
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={
                  questionnaireToolPageSettingsEditorStore.loadingStatus
                }
                tabName={"Ending Pages"}
                hasError={
                  !questionnaireToolPageSettingsEditorStore.areAllWIPPageCustomEndingPagesValid
                }
              />
            ),
            tabHash: "#ending-pages",
            tabComponent: (
              <QuestionnaireToolPageSettingsEndingPages
                questionnaireToolPageSettingsEditorStore={
                  questionnaireToolPageSettingsEditorStore
                }
              />
            ),
          },
        ]}
        externalLinkComponentRenderer={externalLinkComponentRenderer}
      />
    );
  }
);
