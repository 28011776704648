import React, { useMemo } from "react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { ActionPromptContainer, EmailLink } from "components";
import { ConnectDripForm } from "./ConnectDripForm";
import { Box } from "@chakra-ui/react";
import { ConnectZapierForm } from "./ConnectZapierForm";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { observer } from "mobx-react";
import { ConnectReapitForm } from "./ConnectReapitForm";
import { ConnectMRIRadarForm } from "./ConnectMRIRadarForm";
import { ConnectIntegrationConfirmation } from "./ConnectIntegrationConfirmation";
import { ConnectGraingerSalesforceForm } from "./ConnectGraingerSalesforceForm";
import { ConnectRightmoveForm } from "./ConnectRightmoveForm";
import { ConnectStreetForm } from "./ConnectStreetForm";
import { ConnectOpenViewForm } from "./ConnectOpenViewForm";
import { ConnectRexForm } from "./ConnectRexForm";
import { ConnectUrlSyncForm } from "./ConnectUrlSyncForm";
import { ConnectSmeForm } from "./ConnectSmeForm";

interface IProps {
  header: string;
  type: AccountIntegrationTypeEnum;
  closePrompt: () => void;
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const ConnectIntegrationAccountPrompt: React.FC<IProps> = observer(
  ({ header, type, closePrompt, accountIntegrationsStore }) => {
    const formComponent = useMemo(() => {
      switch (type) {
        case AccountIntegrationTypeEnum.DRIP:
          return (
            <ConnectDripForm
              closePrompt={closePrompt}
              accountIntegrationsStore={accountIntegrationsStore}
            />
          );
        case AccountIntegrationTypeEnum.REAPIT:
          return <ConnectReapitForm closePrompt={closePrompt} />;
        case AccountIntegrationTypeEnum.REAPIT_INTERNET_REGISTRATION:
          return (
            <ConnectIntegrationConfirmation
              closePrompt={closePrompt}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={
                AccountIntegrationTypeEnum.REAPIT_INTERNET_REGISTRATION
              }
            />
          );
        case AccountIntegrationTypeEnum.ZAPIER:
          return <ConnectZapierForm closePrompt={closePrompt} />;
        case AccountIntegrationTypeEnum.MRI_RADAR:
          return (
            <ConnectMRIRadarForm
              closePrompt={closePrompt}
              accountIntegrationsStore={accountIntegrationsStore}
            />
          );
        case AccountIntegrationTypeEnum.ZOOPLA:
          return (
            <ConnectIntegrationConfirmation
              closePrompt={closePrompt}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.ZOOPLA}
            />
          );
        case AccountIntegrationTypeEnum.RIGHTMOVE:
          return (
            <ConnectRightmoveForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );
        case AccountIntegrationTypeEnum.GRAINGER_SALESFORCE:
          return (
            <ConnectGraingerSalesforceForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );
        case AccountIntegrationTypeEnum.STREET:
          return (
            <ConnectStreetForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );

        case AccountIntegrationTypeEnum.OPEN_VIEW:
          return (
            <ConnectOpenViewForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );

        case AccountIntegrationTypeEnum.REX:
          return (
            <ConnectRexForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );
        case AccountIntegrationTypeEnum.URL_SYNC:
          return (
            <ConnectUrlSyncForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );

        case AccountIntegrationTypeEnum.SME:
          return (
            <ConnectSmeForm
              accountIntegrationsStore={accountIntegrationsStore}
              closePrompt={closePrompt}
            />
          );

        default:
          return (
            <Box py={5} color={"gray.400"}>
              Please email <EmailLink email={"contact@lead.pro"} /> to activate
              this integration.
            </Box>
          );
      }
    }, [type, closePrompt, accountIntegrationsStore]);

    return <ActionPromptContainer header={header} body={formComponent} />;
  }
);
