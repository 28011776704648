import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useCallback } from "react";
import { TAltoIntentionRelationExtendedData } from "types/alto.type";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { TSelectValue } from "types/select-input.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TAltoIntentionRelationExtendedData;
  onChange: (syncPair: TAltoIntentionRelationExtendedData) => void;
}

export const AltoIntentionSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const options =
      accountStore.accountIntegrationsStore.accountIntegrationAltoStore
        .accountIntegrationAltoIntentionsStore.altoIntentionsOptions;

    const handleChange = useCallback(
      (intentionId: TSelectValue<string>) => {
        onChange({
          intentionId: intentionId || null,
          type: record.type,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.intentionId}
        clearable={true}
        options={options}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
