import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlV2 } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ActionPromptContainer, ApiMessageStack } from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
});

interface IProps {
  accountBillingStore: AccountBillingStore;
  closePrompt: () => void;
}

export const AccountBillingCustomerContactPrompt: React.FC<IProps> = observer(
  ({ accountBillingStore, closePrompt }) => {
    const toast = useToast();

    const initialValues: TFieldValues = useMemo(
      () => ({
        email: accountBillingStore.billing?.stripeCustomerEmail || "",
      }),
      [accountBillingStore.billing]
    );

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          await accountBillingStore.updateBillingCustomerInfo(values);

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Billing contact information updated!"}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountBillingStore, closePrompt, toast]
    );

    return (
      <ActionPromptContainer
        header={"Update billing contact"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"email"}
                  control={control}
                  label={"Contact email"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
