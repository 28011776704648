import React from "react";
import { Center, Box } from "@chakra-ui/react";
import { AccountBillingInvoiceItem } from "./AccountBillingInvoiceItem";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
}
export const AccountBillingInvoicesTab: React.FC<IProps> = observer(
  ({ accountStore }) => {
    const accountBillingInvoices =
      accountStore.accountBillingStore.accountBillingInvoicesSortedDescending;

    if (!accountBillingInvoices.length) {
      return (
        <Center width={"100%"} height={"200px"}>
          No invoices.
        </Center>
      );
    }

    return (
      <Box>
        {accountBillingInvoices.map(invoice => (
          <AccountBillingInvoiceItem key={invoice.id} invoice={invoice} />
        ))}
      </Box>
    );
  }
);
