import React, { FC } from "react";
import { Box, Checkbox, HStack } from "@chakra-ui/react";

const DEFAULT_TITLE = "Only show 'unmapped' items";

interface SyncStatusFilterProps {
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
}

export const SyncStatusFilter: FC<SyncStatusFilterProps> = ({
  isChecked,
  onChange,
  title,
}) => {
  return (
    <HStack gap={1} marginRight={3}>
      <Box fontSize={"xs"}>{title || DEFAULT_TITLE}</Box>
      <Checkbox size={"lg"} isChecked={isChecked} onChange={onChange} />
    </HStack>
  );
};
