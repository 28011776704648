import React, { useMemo } from "react";
import { LeadStatusTag } from "components";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { TLeadStatus } from "types/lead-status.type";

export const LeadStatusSelectOption: React.FC<ISelectInputOptionProps<
  TLeadStatus
>> = ({ optionProps: { option, selectedOption, ...rest } }) => {
  const isSelected = useMemo(() => selectedOption?.value === option.value, [
    selectedOption,
    option,
  ]);
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <LeadStatusTag status={option.value} />
    </SelectInputOptionBase>
  );
};
