import React, { useCallback, useMemo } from "react";
import { BoxProps } from "@chakra-ui/react";
import {
  TSelectValue,
  TSelectOption,
  TSelectOptionValue,
} from "types/select-input.type";
import {
  IGetOptionPropsData,
  SelectInputOptionsController,
} from "../SelectInputOptionsController";
import { DefaultSelectInputOption } from "../custom-options/DefaultSelectInputOption";
import { SelectInputSizes } from "../select-input-size";
import { MultiSelectInputValue } from "./MultiSelectInputValue";
import { SelectInputControls } from "../SelectInputControls";
import { SelectInputBase } from "../SelectInputBase";
import useSelect from "utils/react-hooks/useSelect.hook";

interface IMultiSelectInputProps<D extends TSelectOptionValue, S = unknown>
  extends Omit<BoxProps, "onChange"> {
  value: TSelectValue<D>[];
  options: TSelectOption<D, S>[];
  onChange: (value: TSelectValue<D>[]) => void;
  onClose?: () => void;
  onClear?: () => void;
  placeholder?: string;
  filterPlaceholder?: string;
  size?: SelectInputSizes;
  disabled?: boolean;
  clearable?: boolean;
  optionComponent?: (optionProps: IGetOptionPropsData<D, S>) => React.ReactNode;
  optionsRenderer?: (
    optionProps: IGetOptionPropsData<D, S>[]
  ) => React.ReactNode;
  asyncFilterFn?: (searchTerm: string) => void;
  filterFn?: (
    options: TSelectOption<D, S>[],
    searchTerm: string
  ) => TSelectOption<D, S>[];
  optionsFooter?: React.ReactNode;
  handleOptionsFetch?: () => Promise<void>;
}

export function MultiSelectInput<D extends TSelectOptionValue, S = unknown>({
  value,
  options,
  onChange,
  onClose,
  onClear,
  placeholder,
  size = SelectInputSizes.BASE,
  disabled,
  clearable,
  optionComponent = optionProps => (
    <DefaultSelectInputOption optionProps={optionProps} />
  ),
  optionsRenderer,
  asyncFilterFn,
  filterFn,
  filterPlaceholder,
  optionsFooter,
  handleOptionsFetch,
  ...rest
}: IMultiSelectInputProps<D, S>) {
  const {
    visibleOptions,
    selectedOption,
    getInputProps,
    getOptionProps,
    setSearch,
  } = useSelect({
    multi: true,
    options,
    value,
    onChange,
    filterFn,
  });
  const inputProps = useMemo(() => getInputProps(), [getInputProps]);
  const handleOnClear = useCallback(() => {
    onChange([]);
    onClear?.();
  }, [onChange, onClear]);
  const handleOnClose = useCallback(() => {
    setSearch("");
    onClose?.();
  }, [setSearch, onClose]);

  return (
    <SelectInputBase
      onClose={handleOnClose}
      disabled={disabled}
      size={size}
      inputValueElement={
        <MultiSelectInputValue<D, S>
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          getOptionProps={getOptionProps}
          optionComponent={optionComponent}
          selectedOption={selectedOption}
        />
      }
      selectControlsElement={
        <SelectInputControls
          onClear={handleOnClear}
          isValueSet={selectedOption?.length}
          clearable={clearable}
        />
      }
      popoverContentRenderer={() => (
        <SelectInputOptionsController<D, S>
          visibleOptions={visibleOptions}
          getOptionProps={getOptionProps}
          optionComponent={optionComponent}
          optionsRenderer={optionsRenderer}
          handleFiltering={
            asyncFilterFn || (!!filterFn ? setSearch : undefined)
          }
          filterPlaceholder={filterPlaceholder}
          optionsFooter={optionsFooter}
          handleOptionsFetch={handleOptionsFetch}
          selectedOption={selectedOption}
        />
      )}
      {...inputProps}
      {...rest}
    />
  );
}
