import { Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Box } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { accountProductsConfigMap } from "constants/account-products-config";
import { isAccountProductOverBaseLimit } from "utils/account-billing.utils";
import { TAccountBillingData } from "types/account-billing.type";

const PRICE_PER_EXTRA_USER =
  accountProductsConfigMap[ApplicationProductEnum.DASHBOARD_USERS].pricing
    .pricePerExtraUnit || 0;
const NUMBER_OF_FREE_USERS =
  accountProductsConfigMap[ApplicationProductEnum.DASHBOARD_USERS].pricing
    .unitsIncluded || 0;

interface IProps {
  accountBilling: TAccountBillingData;
}

export const InviteUserWarning: FC<IProps> = ({ accountBilling }) => {
  const isOverFreeUsersLimit = useMemo(
    () =>
      isAccountProductOverBaseLimit(
        ApplicationProductEnum.DASHBOARD_USERS,
        accountBilling
      ),
    [accountBilling]
  );

  if (isOverFreeUsersLimit) {
    return (
      <Message status={AlertStatusEnum.WARNING}>
        <Box>
          <Box>
            {`${NUMBER_OF_FREE_USERS} ${
              NUMBER_OF_FREE_USERS > 1 ? "users are " : "user is "
            }`}
            <Box as={"strong"}>free</Box>
            {". Each additional user is "}
            <Box as={"strong"}>{`£${PRICE_PER_EXTRA_USER} per month.`}</Box>
          </Box>
        </Box>
      </Message>
    );
  }

  return null;
};
