import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { TAccountOfficeData } from "types/account-office.type";
import { NA } from "components";

interface ILeadOfficeCellProps {
  office: TAccountOfficeData | null;
}

export const LeadOfficeCell: FC<ILeadOfficeCellProps> = ({ office }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      {!!office ? <Box>{office.name}</Box> : <NA verb={"assigned"} />}
    </Box>
  );
};
