import {
  ActionPromptContainer,
  ApiMessageStack,
  EmailTemplateEditorV3,
  FormControlLabel,
  MergeTagInput,
} from "components";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, HStack, useToast, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { EmailTemplateEditorPromptHeader } from "./EmailTemplateEditorPromptHeader";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  emailTemplateId?: number;
  accountStore: UserAccountStore;
  closePrompt: () => void;
}

export const EmailTemplateEditorPrompt: FC<IProps> = observer(
  ({ emailTemplateId, accountStore, closePrompt }) => {
    const toast = useToast();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [isSubmittingTemplate, setIsSubmittingTemplate] = useState(false);

    const emailTemplatesStore = accountStore.accountEmailTemplatesStore;
    const wipEmailTemplateStore = emailTemplatesStore.wipEmailTemplateStore;
    const isWipTemplateCreatedPreviously =
      wipEmailTemplateStore.isTemplateCreatedPreviously;

    useEffect(() => {
      const setupData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          wipEmailTemplateStore.setWIPTemplate();

          const promises = [];
          promises.push(emailTemplatesStore.loadEmailTemplateMergeTags());
          if (!!emailTemplateId) {
            promises.push(
              emailTemplatesStore.loadEmailTemplate(emailTemplateId, true)
            );
          }
          await Promise.all(promises);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
          closePrompt();
        }
      };

      setupData();
    }, [
      emailTemplateId,
      wipEmailTemplateStore,
      emailTemplatesStore,
      closePrompt,
      toast,
    ]);

    const handleSave = useCallback(
      async (design: object) => {
        try {
          await emailTemplatesStore.persistWipTemplateData(design);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={`Email template has been ${
                  isWipTemplateCreatedPreviously ? "updated" : "created"
                }.`}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        } finally {
          setIsSubmittingTemplate(false);
        }
      },
      [emailTemplatesStore, isWipTemplateCreatedPreviously, toast]
    );

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        contentStyle={{
          height: "100%",
          width: "100%",
          margin: 0,
          rounded: "none",
        }}
        bodyStyle={{
          padding: 0,
        }}
        header={
          <EmailTemplateEditorPromptHeader
            emailTemplatesStore={emailTemplatesStore}
            isSubmittingTemplate={isSubmittingTemplate}
            setIsSubmittingTemplate={setIsSubmittingTemplate}
            closePrompt={closePrompt}
          />
        }
        body={
          <VStack width={"100%"} height={"100%"} spacing={0}>
            <HStack
              spacing={10}
              width={"100%"}
              px={7}
              borderBottom={"1px solid"}
              borderColor={"gray.200"}
            >
              <FormControlLabel>Subject line</FormControlLabel>
              <Box flexGrow={1} py={2}>
                <MergeTagInput
                  value={wipEmailTemplateStore.subject}
                  onChange={wipEmailTemplateStore.setWIPTemplateSubject}
                  mergeTagOptions={
                    emailTemplatesStore.mergeTagGroupOptionsArray
                  }
                />
              </Box>
            </HStack>
            <EmailTemplateEditorV3
              account={accountStore.account}
              mergeTagGroups={emailTemplatesStore.mergeTagGroupsArray}
              uiMergeTags={emailTemplatesStore.uiMergeTagsMap}
              templateDesign={wipEmailTemplateStore.designWithPreviewData}
              onSave={handleSave}
            />
          </VStack>
        }
      />
    );
  }
);
