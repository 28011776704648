import { Box, BoxProps } from "@chakra-ui/react";
import { TSelectOption, TSelectOptionValue } from "types/select-input.type";
import { IGetOptionPropsData } from "./SelectInputOptionsController";

export interface ISelectInputOptionProps<
  D extends TSelectOptionValue,
  S = unknown
> {
  optionProps: IGetOptionPropsData<D, S>;
}

interface ISelectInputOptionBaseProp<D extends TSelectOptionValue, S = unknown>
  extends BoxProps {
  option: TSelectOption<D, S>;
  isSelected?: boolean;
  isDisabled?: boolean;
}

export function SelectInputOptionBase<
  D extends TSelectOptionValue,
  S = unknown
>({
  children,
  option,
  isSelected,
  isDisabled,
  ...rest
}: ISelectInputOptionBaseProp<D, S>) {
  return (
    <Box
      display="flex"
      alignItems="center"
      py={1}
      px={2}
      bg={isSelected ? "blue.100" : ""}
      pointerEvents={isDisabled ? "none" : undefined}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      opacity={isDisabled ? 0.5 : 1}
      _hover={
        !isSelected && !!rest.onClick
          ? {
              bg: "blue.50",
            }
          : undefined
      }
      noOfLines={1}
      {...rest}
    >
      <Box height={"100%"} display={"flex"} alignItems={"center"}>
        {children}
      </Box>
    </Box>
  );
}
