import React from "react";
import { Box } from "@chakra-ui/react";

interface IProgressBarProps {
  height: string;
  color: string;
  value: number;
  rounded: string;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({
  height,
  color,
  value,
  rounded,
}) => {
  return (
    <Box
      height={height}
      width={"100%"}
      overflow={"hidden"}
      position={"relative"}
      rounded={rounded}
    >
      <Box
        height={"100%"}
        width={"100%"}
        rounded={rounded}
        opacity={0.2}
        background={color}
        position={"absolute"}
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
      <Box
        height={"100%"}
        width={`${value}%`}
        rounded={rounded}
        background={color}
        position={"absolute"}
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
    </Box>
  );
};
