import { ApiMessageStack, FormControlMeta, FormControlV2 } from "components";
import {
  PASSWORDS_MISMATCH,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import React, { FC, MutableRefObject, useCallback } from "react";
import * as Yup from "yup";
import {
  Box,
  Divider,
  useToast,
  Portal,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormFooter } from "components/form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(REQUIRED_FIELD),
  newPassword: Yup.string().required(REQUIRED_FIELD),
  confirmNewPassword: Yup.string()
    .required(REQUIRED_FIELD)
    .when("newPassword", {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref("newPassword")], PASSWORDS_MISMATCH),
    }),
});

const initialValues: TFieldValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

interface IProps {
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const ChangePassword: FC<IProps> = observer(({ containerRef }) => {
  const {
    authStore: {
      leadProAuthStore: { updatePassword },
    },
  } = useAppStore();
  const toast = useToast();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({ currentPassword, newPassword }: TFieldValues) => {
      try {
        await updatePassword(currentPassword, newPassword);
        reset();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Your password has been updated."} />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [toast, updatePassword, reset]
  );

  return (
    <Box
      position={"relative"}
      minHeight={"100%"}
      overflow={"hidden"}
      pr={1}
      pt={1}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={10} align={"stretch"} divider={<Divider />}>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta label="Current password" />
            <FormControlV2<TFieldValues>
              name={"currentPassword"}
              control={control}
              type={FormControlsTypeEnum.PASSWORD}
              additionalProps={{
                placeholder: "Enter current password",
              }}
            />
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta label="New password" />
            <FormControlV2<TFieldValues>
              name={"newPassword"}
              control={control}
              type={FormControlsTypeEnum.PASSWORD}
              additionalProps={{
                placeholder: "Enter new password",
              }}
            />
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta label="Confirm new password" />
            <FormControlV2<TFieldValues>
              name={"confirmNewPassword"}
              control={control}
              type={FormControlsTypeEnum.PASSWORD}
              additionalProps={{
                placeholder: "Enter new password again",
              }}
            />
          </SimpleGrid>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
              />
            </Portal>
          )}
        </VStack>
      </form>
    </Box>
  );
});
