import React, { FC } from "react";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { AutocallerBasicSettings } from "./components/AutocallerBasicSettings/AutocallerBasicSettings";
import { AutocallerSplashScreen } from "./components/AutocallerSplashScreen/AutocallerSplashScreen";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { TabsGroupLayout } from "../../../../components/Layouts/TabsGroupLayout";
import { AutocallerAdvancedSettings } from "./components/AutocallerAdvancedSettings/AutocallerAdvancedSettings";
import { Box } from "@chakra-ui/react";

interface IProps {
  accountStore: UserAccountStore;
}

export const AutocallerSettings: FC<IProps> = observer(({ accountStore }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  return (
    <PremiumFeatureBlock
      productId={ApplicationProductEnum.AUTOCALLER}
      render={isEnabled => (
        <Box
          position={"relative"}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          overflowY={"hidden"}
          ref={ref}
        >
          <TabsGroupLayout
            title={"Autocaller"}
            config={[
              {
                tabHash: "#details",
                tabName: "Details",
                tabComponent: (
                  <AutocallerBasicSettings
                    isEnabled={isEnabled}
                    accountStore={accountStore}
                  />
                ),
              },
              {
                tabHash: "#advanced",
                tabName: "Advanced",
                tabComponent: (
                  <AutocallerAdvancedSettings
                    isEnabled={isEnabled}
                    accountStore={accountStore}
                    containerRef={ref}
                  />
                ),
              },
            ]}
          />
        </Box>
      )}
      alternativeRender={() => (
        <AutocallerSplashScreen accountStore={accountStore} />
      )}
    />
  );
});
