import React, { FC } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { FormControlLabel, JSONViewer } from "components";
import { TFormToolFormResponseData } from "types/form-tool-page.type";
import { formatDate } from "utils/date.utils";
import { DATE_TIME_DETAIL_FORMAT } from "constants/date";

interface IProps {
  response: TFormToolFormResponseData;
}

export const FormToolPageResponseItem: FC<IProps> = ({ response }) => {
  return (
    <AccordionItem id={response.id.toString()}>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <HStack spacing={5}>
            <Tag>{formatDate(response.createdAt, DATE_TIME_DETAIL_FORMAT)}</Tag>
            <Box>Click to view details</Box>
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <VStack spacing={2}>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Response data</FormControlLabel>
            <JSONViewer json={response.data} />
          </Box>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Response meta</FormControlLabel>
            <JSONViewer json={response.meta} />
          </Box>
          <Box width={"100%"}>
            <FormControlLabel mb={2}>Triggered actions</FormControlLabel>
            <JSONViewer json={response.triggeredActions} />
          </Box>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
};
