import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { Box, Button, Link, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { TIntegrationSyncOfficeExtendedData } from "types/integration-sync-office.type";
import { AccountIntegrationOfficesStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import { syncSelectIntegrationOfficeTableColumnDef } from "./syncSelectIntegrationOfficeTableColumnDef";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { IntegrationConfiguratorLayout } from "../IntegrationConfiguratorLayout";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { SyncStatusFilter } from "../SyncStatusFilter";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountIntegrationOfficesStore: AccountIntegrationOfficesStore;
  accountIntegrationsStore: AccountIntegrationsStore;
  integrationType: AccountIntegrationTypeEnum;
  selectedOfficeGroupId?: number;
}

export const OfficeSelectIntegrationTable: FC<IProps> = observer(
  ({
    accountIntegrationsStore,
    accountIntegrationOfficesStore,
    integrationType,
    selectedOfficeGroupId,
  }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TIntegrationSyncOfficeExtendedData>
    >({});

    const integrationData =
      accountIntegrationsStore.availableAccountIntegrationsMapByType[
        integrationType
      ];

    const {
      accountSyncOfficeDataWithSuggestions,
      filteredAccountSyncOfficeData,
      setSelectedOfficeGroupId,
      setSyncStatusFilter,
      getSyncStatusFilter: syncStatusFilter,
    } = accountIntegrationOfficesStore;

    const syncOfficeData = accountSyncOfficeDataWithSuggestions;

    const updateSyncPair = useCallback(
      (syncPair: TIntegrationSyncOfficeExtendedData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.officeId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    useEffect(() => {
      setSelectedOfficeGroupId(selectedOfficeGroupId);
    }, [selectedOfficeGroupId, setSelectedOfficeGroupId]);

    useEffect(() => {
      const suggestedArray = syncOfficeData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy<TIntegrationSyncOfficeExtendedData>(
        suggestedArray,
        syncData => syncData.officeId
      );
      setNewRelations({
        ...suggestedMap,
      });
    }, [syncOfficeData, setNewRelations]);

    const columnsDef = useMemo(() => {
      return syncSelectIntegrationOfficeTableColumnDef(
        accountIntegrationOfficesStore,
        updateSyncPair,
        integrationData
      );
    }, [accountIntegrationOfficesStore, updateSyncPair, integrationData]);

    const dataSource = useMemo(() => {
      return filteredAccountSyncOfficeData.map(syncData => {
        if (!!newRelations[syncData.officeId])
          return newRelations[syncData.officeId];

        return syncData;
      });
    }, [filteredAccountSyncOfficeData, newRelations]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await accountIntegrationOfficesStore.updateSyncedAccountOffices(
          Object.values(newRelations)
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={`${integrationData.name} integration office configuration updated.`}
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [accountIntegrationOfficesStore, newRelations, toast, integrationData]);

    const banner = useMemo(() => {
      const notSyncedArray = syncOfficeData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your offices are not configured.`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [syncOfficeData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>Office mappings</Box>
        {integrationType === AccountIntegrationTypeEnum.SME &&
        !accountIntegrationOfficesStore.integrationOfficesOptions.length ? (
          <Message status={AlertStatusEnum.INFO}>
            <Box>
              Your SME Professional system appears to have no branches. Your
              leads will be sync'd without any associated Leadpro offices. In
              order to prepare office mapping, you'll need to change your SME
              system into a multi-branch system.{" "}
              <Link
                href={
                  "https://smeprofessional.supporthero.io/article/show/137703-branch-functionality-multi-branch-systems"
                }
                textDecoration={"underline"}
                fontWeight={"bold"}
                target={"_blank"}
              >
                Click here
              </Link>{" "}
              to learn more.
            </Box>
          </Message>
        ) : (
          <>
            {banner}
            <TableV2<TIntegrationSyncOfficeExtendedData>
              globalFilterInputPlaceholder={"Search offices by name"}
              additionalActions={
                <SyncStatusFilter
                  isChecked={syncStatusFilter}
                  onChange={handleSyncFilterChange}
                />
              }
              dataSource={dataSource}
              columns={columnsDef}
              pageSize={DEFAULT_PAGE_SIZE}
            />
            <Box width={"100%"} textAlign={"right"}>
              <Button
                colorScheme={"blue"}
                onClick={submitSyncPairs}
                isLoading={isSubmitting}
              >
                Update
              </Button>
            </Box>
          </>
        )}
      </IntegrationConfiguratorLayout>
    );
  }
);
