import React, { ChangeEvent, KeyboardEvent, useCallback } from "react";
import { Input } from "components";
import { Box } from "@chakra-ui/react";
import moment from "moment";

interface ITimePickerProps {
  name: string;
  time: string;
  onChange: (time: string) => void;
  disabled?: boolean;
}
export const TimePicker: React.FC<ITimePickerProps> = ({
  name,
  time,
  onChange,
  disabled,
}) => {
  const momentDate = time ? moment(time, "HH:mm") : null;
  const changeHour = useCallback(
    (value: number) => {
      if (value > -2 && value < 25) {
        const newTime = momentDate || moment().set("minute", 0);
        onChange(newTime.set("hour", value).format("HH:mm"));
      }
    },
    [momentDate, onChange]
  );

  const changeMinute = useCallback(
    (value: number) => {
      if (value > -2 && value < 61) {
        const newTime = momentDate || moment().set("hour", 0);
        onChange(newTime.set("minute", value).format("HH:mm"));
      }
    },
    [momentDate, onChange]
  );

  const handleHours = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      changeHour(Number.parseInt(value));
    },
    [changeHour]
  );

  const handleMinutes = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      changeMinute(Number.parseInt(value));
    },
    [changeMinute]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const isHourInput = event.currentTarget.name?.includes("-hours");
      const changeFunction = isHourInput ? changeHour : changeMinute;

      const key = event.key?.toLowerCase();
      if (key === "arrowdown" || key === "arrowup") {
        event.preventDefault();
        event.stopPropagation();

        const timeDelta = momentDate
          ? parseInt(momentDate.format(isHourInput ? "HH" : "mm")) +
            (key === "arrowdown" ? -1 : 1)
          : 0;
        changeFunction(timeDelta);
      }
    },
    [changeHour, changeMinute, momentDate]
  );

  return (
    <Box
      width={"90px"}
      display={"flex"}
      flexDirection={"row"}
      flexWrap={"nowrap"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Input
        width={"40px"}
        size={"sm"}
        textAlign={"center"}
        name={`${name}-hours`}
        value={momentDate?.format("HH") || ""}
        onChange={handleHours}
        onKeyDown={handleKeyDown}
        placeholder={"--"}
        autoComplete={"off"}
        isDisabled={disabled}
      />
      <Box mx={1}>:</Box>
      <Input
        width={"40px"}
        size={"sm"}
        textAlign={"center"}
        name={`${name}-minutes`}
        value={momentDate?.format("mm") || ""}
        onChange={handleMinutes}
        onKeyDown={handleKeyDown}
        placeholder={"--"}
        autoComplete={"off"}
        isDisabled={disabled}
      />
    </Box>
  );
};
