import React from "react";
import { ActionPromptContext } from "components/action-prompt/ActionPrompt";

export const useActionPrompt = () => {
  const context = React.useContext(ActionPromptContext);
  if (context === undefined) {
    throw new Error(
      "useActionPrompt must be used within a ActionPromptProvider"
    );
  }

  return context;
};
