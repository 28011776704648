import { FC } from "react";
import { BoxProps, SimpleGrid } from "@chakra-ui/react";

interface IRowStubProps extends BoxProps {
  columnsCount: number;
}

export const RowStub: FC<IRowStubProps> = ({
  children,
  columnsCount,
  ...rest
}) => {
  return (
    <SimpleGrid
      columns={columnsCount}
      width={"100%"}
      spacing={2}
      cursor={"default"}
      {...rest}
    >
      {children}
    </SimpleGrid>
  );
};
