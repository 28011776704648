import React, { FC, useMemo } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { PaginatorButton } from "./PaginatorButton";

interface IPaginatorProps extends BoxProps {
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  gotoPage: (pageNumber: number) => void;
  pageIndex: number;
  pageSize: number;
  total: number;
}

export const Paginator: FC<IPaginatorProps> = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageIndex,
  pageSize,
  total,
  ...rest
}) => {
  const previousPage = () => {
    if (canPreviousPage) {
      gotoPage(pageIndex - 1);
    }
  };

  const nextPage = () => {
    if (canNextPage) {
      gotoPage(pageIndex + 1);
    }
  };

  const start = useMemo(() => {
    return total > 0 ? pageIndex * pageSize + 1 : 0;
  }, [total, pageIndex, pageSize]);

  const end = useMemo(() => {
    return total > 0 ? Math.min(total, (pageIndex + 1) * pageSize) : 0;
  }, [total, pageIndex, pageSize]);

  return (
    <Box
      bg="white"
      width="100%"
      py={4}
      display="flex"
      alignItems="center"
      {...rest}
    >
      <Box fontSize="md" color="leadpro.500" ml="auto">
        Showing {start} to{" "}
        <Box as={"span"} fontWeight={500}>
          {end}
        </Box>{" "}
        of{" "}
        <Box as={"span"} fontWeight={500}>
          {total}
        </Box>{" "}
        results
      </Box>
      {total > pageSize && (
        <Box
          ml={4}
          rounded="md"
          overflow="hidden"
          display="flex"
          bg="white"
          gap={2}
        >
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <PaginatorButton isDisabled={!canPreviousPage}>
              Prev
            </PaginatorButton>
          </button>{" "}
          <Box onClick={() => nextPage()}>
            <PaginatorButton isDisabled={!canNextPage}>Next</PaginatorButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
