import { TLead } from "types/lead.type";
import { ExtendedColumn } from "components/table/table-props";
import { Row } from "react-table";
import {
  LeadAddressCell,
  LeadFromCell,
  LeadNextActionDateCell,
  LeadDateCell,
  LeadStatusCell,
  LeadTypeCell,
  LeadOfficeCell,
  LeadAssigneeCell,
} from "components/table/cells/leads-table";
import { LeadByOption } from "enums/lead-by-option.enum";
import React from "react";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { TPortalData } from "types/portal.type";
import { TUserBasicData } from "types/user-data.type";
import { TLeadStatusBasic } from "types/lead-status.type";
import { TAccountOfficeData } from "types/account-office.type";
import { LeadSourceCell } from "components/table/cells/leads-table/LeadSourceCell";
import { PropertyHeaderCell } from "components/table/cells/properties-table/PropertyHeaderCell";

export const propertyLeadsTableColumnDef = (
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>,
  leadStatusesMapByStatusId: Record<number, TLeadStatusBasic>,
  accountOfficesDataMap: Record<number, TAccountOfficeData>,
  accountUsersMap: Record<number, TUserBasicData>,
  logoUrl: string | null,
  portalsMap: Record<number, TPortalData>
): ExtendedColumn<TLead>[] => {
  const cols: ExtendedColumn<TLead>[] = [];

  cols.push({
    Header: () => <PropertyHeaderCell>Date</PropertyHeaderCell>,
    accessor: "createdAt",
    width: "150px",
    Cell: ({ row }: { row: Row<TLead> }) => (
      <LeadDateCell lead={row.original} />
    ),
  });

  cols.push(
    {
      Header: () => <PropertyHeaderCell>Status</PropertyHeaderCell>,
      accessor: "statusId",
      disableSortBy: true,
      width: "150px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadStatusCell
          status={leadStatusesMapByStatusId[row.original.statusId]}
        />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>From</PropertyHeaderCell>,
      accessor: "person",
      width: "250px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadFromCell lead={row.original} />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Source</PropertyHeaderCell>,
      accessor: LeadByOption.SOURCE,
      disableSortBy: true,
      width: "220px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadSourceCell
          lead={row.original}
          leadSourcesMapBySource={leadSourcesMapBySource}
          portalsMap={portalsMap}
          accountLogoUrl={logoUrl}
        />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Type</PropertyHeaderCell>,
      accessor: LeadByOption.TYPE,
      width: "200px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadTypeCell lead={row.original} />
      ),
    },
    {
      Header: "Subject",
      accessor: (row: TLead) => row.advertAddress || row.address,
      width: "250px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadAddressCell lead={row.original} />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Assignee</PropertyHeaderCell>,
      accessor: "assignedToId",
      width: "200px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadAssigneeCell
          user={
            row.original.assignedToId
              ? accountUsersMap[row.original.assignedToId]
              : null
          }
        />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Office</PropertyHeaderCell>,
      accessor: "officeId",
      width: "200px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadOfficeCell
          office={
            row.original.officeId
              ? accountOfficesDataMap[row.original.officeId]
              : null
          }
        />
      ),
    },
    {
      Header: () => <PropertyHeaderCell>Next action</PropertyHeaderCell>,
      accessor: "nextActionDate",
      width: "150px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadNextActionDateCell lead={row.original} />
      ),
    }
  );

  return cols;
};
