import { Box, Checkbox, Flex, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  TAccountIntegrationData,
  TReapitConfig,
} from "types/account-integration.type";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  isMultiIntegrationEnabled: boolean;
}

export const ReapitApplicantRequirementsConfigurator: FC<IProps> = observer(
  ({ accountIntegrationsStore, isMultiIntegrationEnabled }) => {
    const toast = useToast();

    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.REAPIT
    ] as TAccountIntegrationData<TReapitConfig>;

    const enableApplicantRequirements =
      accountIntegration.integrationConfig.enableApplicantRequirements;
    const areApplicantRequirementsFieldsMandatory =
      accountIntegration.integrationConfig
        .areApplicantRequirementsFieldsMandatory;

    const handleApplicantRequirementsChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const isEnabled = event.currentTarget.checked;
        const { id, type, active, isManuallyTriggered } = accountIntegration;
        try {
          await accountIntegrationsStore.updateAccountIntegration(id, {
            type,
            active,
            isManuallyTriggered,
            integrationConfig: {
              enableApplicantRequirements: isEnabled,
              areApplicantRequirementsFieldsMandatory: isEnabled,
            },
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={
                  "Reapit integration configuration successfully updated."
                }
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountIntegration, accountIntegrationsStore, toast]
    );

    const handleMandatoryInputsChange = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const isEnabled = event.currentTarget.checked;
        const { id, type, active, isManuallyTriggered } = accountIntegration;
        try {
          await accountIntegrationsStore.updateAccountIntegration(id, {
            type,
            active,
            isManuallyTriggered,
            integrationConfig: {
              enableApplicantRequirements: true,
              areApplicantRequirementsFieldsMandatory: isEnabled,
            },
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={
                  "Reapit integration configuration successfully updated."
                }
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountIntegration, accountIntegrationsStore, toast]
    );

    return (
      <Box mb={6}>
        <Box fontSize={"xl"} color={"gray.700"}>
          Applicant Requirements
        </Box>
        <VStack spacing={8} mt={4} mx={16}>
          <Flex
            gap={8}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            width={"100%"}
          >
            <Box>
              <Box mb={4} color={"gray.700"}>
                {`Enable applicant requirements (${
                  enableApplicantRequirements ? "Enabled" : "Disabled"
                })`}
              </Box>
              <Box fontSize={"sm"} maxWidth={"580px"} color={"leadpro.500"}>
                Applicant requirements will allow you to specify 'applicant
                areas' and 'applicant price range' to Reapit when syncing your
                applicant leads (let / sale). <strong>Note that</strong> Reapit
                has an API usage charge, fetching applicant requirements from
                your reapit account will be additional charged usage.{" "}
                {isMultiIntegrationEnabled &&
                  "This control effects the whole account, not just the selected office group."}
              </Box>
            </Box>
            <Checkbox
              size={"lg"}
              isChecked={enableApplicantRequirements}
              onChange={event => handleApplicantRequirementsChange(event)}
            />
          </Flex>

          {enableApplicantRequirements && (
            <Flex
              gap={8}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              width={"100%"}
            >
              <Box>
                <Box mb={4} color={"gray.700"}>
                  {`Mandatory applicant requirement inputs (${
                    areApplicantRequirementsFieldsMandatory
                      ? "Enabled"
                      : "Disabled"
                  })`}
                </Box>
                <Box fontSize={"sm"} maxWidth={"580px"} color={"leadpro.500"}>
                  Depending on how you have set up your Reapit CRM, you may find
                  it useful to make the input values for applicant requirements
                  mandatory fields for your agents to fulfil before sync’ing to
                  Reapit. Use this switch to enable or disable mandatory
                  applicant requirement inputs.
                </Box>
              </Box>
              <Checkbox
                size={"lg"}
                isChecked={areApplicantRequirementsFieldsMandatory}
                onChange={event => handleMandatoryInputsChange(event)}
              />
            </Flex>
          )}
        </VStack>
      </Box>
    );
  }
);
