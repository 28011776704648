import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { PersistentFilterGroupOperator } from "enums/persistent-filter.enum";
import { SingleSelectInput } from "../select-input";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { SelectInputSizes } from "../select-input/select-input-size";
import {
  TFilterNode,
  TFilterExpression,
  TFilterGroup,
  TPersistentFiltersExpressionSchema,
} from "types/persistent-filters.type";
import {
  NEW_DEFAULT_FILTER_EXPRESSION,
  PersistentFilterExpression,
} from "./PersistentFilterExpression";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { StandardIconButton } from "../standard-icon-button/StandardIconButton";
import {
  GROUP_OPERATOR_OPTIONS,
  NEW_DEFAULT_FILTER_GROUP,
} from "constants/persistent-filters";

interface IPersistentFilterGroupProps {
  configuration: TFilterGroup;
  expressionSchemaOptions: TSelectOption<string>[];
  expressionSchemaMap: Record<string, TPersistentFiltersExpressionSchema>;
  onChange: (childIndex: number, newConfiguration?: TFilterGroup) => void;
  childIndex: number;
  isRoot: boolean;
}

export const PersistentFilterGroup: FC<IPersistentFilterGroupProps> = ({
  configuration,
  expressionSchemaOptions,
  expressionSchemaMap,
  onChange,
  childIndex,
  isRoot,
}) => {
  const onGroupOperatorChange = useCallback(
    (value: TSelectValue<PersistentFilterGroupOperator>) => {
      if (!!value) {
        onChange(childIndex, {
          operator: value,
          children: configuration.children,
        });
      }
    },
    [onChange, configuration, childIndex]
  );

  const onSubgroupChange = useCallback(
    (newConfigChildIndex: number, newConfiguration?: TFilterNode) => {
      const children = [...configuration.children];

      if (!!newConfiguration) {
        children[newConfigChildIndex] = newConfiguration;
      } else {
        children.splice(newConfigChildIndex, 1);
      }

      onChange(childIndex, {
        operator: configuration.operator,
        children,
      });
    },
    [configuration, onChange, childIndex]
  );

  const handleRemoveFilterGroup = useCallback(() => {
    onChange(childIndex);
  }, [onChange, childIndex]);

  const handleAddConditionGroup = useCallback(() => {
    onChange(childIndex, {
      operator: configuration.operator,
      children: [...configuration.children, { ...NEW_DEFAULT_FILTER_GROUP }],
    });
  }, [configuration, onChange, childIndex]);

  const handleAddCondition = useCallback(() => {
    onChange(childIndex, {
      operator: configuration.operator,
      children: [
        ...configuration.children,
        { ...NEW_DEFAULT_FILTER_EXPRESSION },
      ],
    });
  }, [configuration, onChange, childIndex]);

  return (
    <VStack
      width={"100%"}
      spacing={2}
      border={"1px solid"}
      borderColor={"gray.100"}
      px={4}
      py={2}
      alignItems={"flex-start"}
      position={"relative"}
    >
      <HStack spacing={2} width={"100%"}>
        <Box flexGrow={0}>If</Box>
        <Box flexGrow={0}>
          <SingleSelectInput<PersistentFilterGroupOperator>
            size={SelectInputSizes.SMALL}
            value={configuration.operator}
            options={GROUP_OPERATOR_OPTIONS}
            onChange={onGroupOperatorChange}
          />
        </Box>
        <Box flexGrow={1}>of the following conditions apply:</Box>
      </HStack>
      {configuration.children.map((groupChild, index) => {
        if (!!(groupChild as TFilterGroup).children) {
          return (
            <PersistentFilterGroup
              key={`${childIndex || 0}-${index}`}
              configuration={groupChild as TFilterGroup}
              expressionSchemaOptions={expressionSchemaOptions}
              expressionSchemaMap={expressionSchemaMap}
              onChange={onSubgroupChange}
              childIndex={index}
              isRoot={false}
            />
          );
        } else {
          return (
            <PersistentFilterExpression
              key={`${childIndex || 0}-${index}`}
              configuration={groupChild as TFilterExpression}
              expressionSchemaOptions={expressionSchemaOptions}
              expressionSchemaMap={expressionSchemaMap}
              onChange={onSubgroupChange}
              childIndex={index}
            />
          );
        }
      })}
      <HStack spacing={4} py={2}>
        <Button variant={"link"} onClick={handleAddConditionGroup}>
          + Add condition group
        </Button>
        <Button variant={"link"} onClick={handleAddCondition}>
          + Add condition
        </Button>
      </HStack>
      <Box position={"absolute"} top={0} right={2}>
        {!isRoot && (
          <StandardIconButton
            onClick={handleRemoveFilterGroup}
            aria-label={"remove-filter-group"}
            icon={<FontAwesomeIcon icon={faXmark} />}
          />
        )}
      </Box>
    </VStack>
  );
};
