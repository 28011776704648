import { AccountAltoApi } from "api/account-alto.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  TAltoIntention,
  TAltoIntentionRelationData,
  TAltoIntentionRelationExtendedData,
} from "types/alto.type";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { ALTO_SUPPORTED_LEAD_TYPES } from "constants/alto-supported-lead-types";

const accountAltoApi = new AccountAltoApi();

export class AccountIntegrationAltoIntentionsStore {
  private readonly accountId: number;
  private readonly accountAltoIntentions: IObservableArray<TAltoIntention>;
  private readonly accountAltoIntentionsRelations: IObservableArray<
    TAltoIntentionRelationData
  >;
  private syncStatusFilter: boolean;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountId = accountId;
    this.accountAltoIntentions = observable.array<TAltoIntention>();
    this.accountAltoIntentionsRelations = observable.array<
      TAltoIntentionRelationData
    >();
    this.syncStatusFilter = false;
  }

  get altoIntentionsRelationsMapByLeadType() {
    return keyBy(
      this.accountAltoIntentionsRelations,
      relation => relation.type
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  get altoIntentionsRelationsMapByIntentionId() {
    return keyBy(
      this.accountAltoIntentionsRelations,
      relation => relation.intentionId
    );
  }

  get filteredAltoSyncIntentionsData(): TAltoIntentionRelationExtendedData[] {
    return this.altoSyncIntentionsData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get altoSyncIntentionsData(): TAltoIntentionRelationExtendedData[] {
    const altoIntentionsRelationsMapByLeadType = this
      .altoIntentionsRelationsMapByLeadType;

    return ALTO_SUPPORTED_LEAD_TYPES.map(type => {
      let pairedAltoIntentionsId =
        altoIntentionsRelationsMapByLeadType[type]?.intentionId;
      let syncStatus = !!pairedAltoIntentionsId
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        intentionId: pairedAltoIntentionsId,
        type: type,
        syncStatus: syncStatus,
      };
    });
  }

  get altoIntentionsOptions() {
    return this.accountAltoIntentions
      .map(altoIntention => ({
        value: altoIntention.intentionId,
        label: altoIntention.name,
        data: this.altoIntentionsRelationsMapByIntentionId[
          altoIntention.intentionId
        ],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountAltoIntentions(intentions: TAltoIntention[]) {
    this.accountAltoIntentions.replace(intentions);
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  public setAccountAltoIntentionsRelations(
    relations: TAltoIntentionRelationData[]
  ) {
    this.accountAltoIntentionsRelations.replace(relations);
  }

  public async fetchAccountAltoIntentions() {
    const data = await accountAltoApi.fetchAccountAltoIntentions(
      this.accountId
    );
    this.setAccountAltoIntentions(data);
  }

  public async fetchAccountAltoIntentionsRelations() {
    const data = await accountAltoApi.fetchAccountAltoIntentionRelations(
      this.accountId
    );
    this.setAccountAltoIntentionsRelations(data);
  }

  public async fetchAccountAltoIntentionsAndRelations() {
    await Promise.all([
      this.fetchAccountAltoIntentions(),
      this.fetchAccountAltoIntentionsRelations(),
    ]);
  }

  public async updateAccountAltoSyncedIntentions(
    syncedPairs: TAltoIntentionRelationData[]
  ) {
    await accountAltoApi.updateAccountAltoSyncedIntentions(
      this.accountId,
      syncedPairs.map(pair => ({
        type: pair.type,
        intentionId: pair.intentionId,
      }))
    );
    await this.fetchAccountAltoIntentionsAndRelations();
  }
}
