import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import { isValidPhone } from "utils/custom-yup-validators.utils";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountAutocallerStore } from "store/UserAccounts/UserAccount/AccountAutocaller/AccountAutocaller.store";
import { AutocallerDemoLeadData } from "constants/autocaller-demo-lead-data";
import { LeadTypeEnum } from "enums/lead-type.enum";

type TFieldValues = {
  phoneNumber: string;
};

const initialValues: TFieldValues = {
  phoneNumber: "",
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
    .required(REQUIRED_FIELD),
});

interface IProps {
  accountAutocallerStore: AccountAutocallerStore;
  isDisabled: boolean;
}

export const AutocallerTemplateTestPopover: FC<IProps> = observer(
  ({ accountAutocallerStore, isDisabled }) => {
    const inputRef = useRef(null);
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      (onClose: () => void) => async ({ phoneNumber }: TFieldValues) => {
        if (!isDisabled) {
          try {
            await accountAutocallerStore.performAutocallerDemo({
              leadType: LeadTypeEnum.Vendor,
              agentLine: phoneNumber,
              customerLine: AutocallerDemoLeadData.phone,
              customerName: `${AutocallerDemoLeadData.firstName} ${AutocallerDemoLeadData.lastName}`,
              propertyAddress: AutocallerDemoLeadData.address,
            });
            toast({
              ...DEFAULT_SUCCESS_TOAST_OPTIONS,
              description: (
                <ApiMessageStack
                  messageStack={
                    "The phone number you provided should receive a call shortly."
                  }
                />
              ),
            });
            onClose();
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          }
        }
      },
      [accountAutocallerStore, isDisabled, toast]
    );

    return (
      <Popover
        initialFocusRef={inputRef}
        isLazy={true}
        placement={"bottom-end"}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                leftIcon={<FontAwesomeIcon icon={faPhone} />}
                variant={"outline"}
                colorScheme={"blue"}
                isDisabled={isDisabled}
              >
                Simulate call
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader fontSize={"md"} fontWeight={500}>
                Provide details
              </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <form onSubmit={handleSubmit(onSubmit(onClose))}>
                  <FormControlV2<TFieldValues>
                    name={"phoneNumber"}
                    control={control}
                    isDisabled={isDisabled}
                    label={"Receiving phone number"}
                    description={
                      "Provide a phone number you want to receive a demo call with the automated message template above."
                    }
                    type={FormControlsTypeEnum.TEXT}
                    additionalProps={{
                      placeholder: "ex. +447700184816",
                      size: "sm",
                    }}
                  />
                  <Box width={"100%"} pt={2} textAlign={"right"}>
                    <Button
                      type={"submit"}
                      colorScheme={"teal"}
                      isDisabled={isSubmitting || isDisabled}
                      isLoading={isSubmitting}
                    >
                      Receive a call
                    </Button>
                  </Box>
                </form>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    );
  }
);
