import { Box } from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

interface IHelpPromptButtonProps {
  title: string;
  icon: IconDefinition;
  color: string;
  onClick: () => void;
}

export const PromptOptionButton: React.FC<IHelpPromptButtonProps> = ({
  title,
  icon,
  color,
  onClick,
}) => {
  return (
    <Box
      border="1px solid"
      borderColor="gray.100"
      shadow="none"
      backgroundColor="white"
      cursor="pointer"
      rounded="md"
      display="flex"
      alignItems="center"
      p={2}
      lineHeight="none"
      transition="all .25s"
      _hover={{
        shadow: "sm",
      }}
      onClick={onClick}
    >
      <Box
        w={12}
        h={12}
        bg={`${color}.50`}
        rounded="md"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={`${color}.500`}
        fontSize="2xl"
      >
        {icon && <FontAwesomeIcon icon={icon} />}
      </Box>
      <Box flex={1} p={4}>
        <Box lineHeight="none" fontWeight="semibold" fontSize="md">
          {title}
        </Box>
      </Box>
      <Box
        fontSize="xl"
        color="gray.400"
        w={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Box>
    </Box>
  );
};
