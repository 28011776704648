import { observer } from "mobx-react";
import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { FormToolPageWIPFormStore } from "../../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  questionKey: string;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsSelectedQuestionConfiguratorMenuDelete: FC<IProps> = observer(
  ({ questionKey, formToolPageWIPFormStore }) => {
    const onClick = useCallback(() => {
      formToolPageWIPFormStore.removeQuestion(questionKey);
    }, [formToolPageWIPFormStore, questionKey]);

    return (
      <Popover closeOnBlur={false} isLazy={true}>
        <PopoverTrigger>
          <Box color={"red.500"}>Delete question</Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader fontSize={"md"} fontWeight={500}>
            Confirm deletion
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box fontSize={"sm"} fontWeight={"normal"}>
              Are you sure you want to delete this question?
            </Box>
          </PopoverBody>
          <PopoverFooter border={"none"}>
            <ButtonGroup
              size="sm"
              spacing={2}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button colorScheme={"red"} onClick={onClick}>
                Delete
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    );
  }
);
