import React from "react";
import { Box, Divider, HStack, Tag } from "@chakra-ui/react";
import { TAccountBillingInvoice } from "types/account-billing.type";
import { ExternalLink } from "components";

interface IProps {
  invoice: TAccountBillingInvoice;
}

export const AccountBillingInvoiceItem: React.FC<IProps> = ({ invoice }) => {
  return (
    <Box width={"100%"}>
      <HStack spacing={2} py={5} width={"100%"}>
        <Box flexGrow={1}>
          {!!invoice.stripeInvoicePdf ? (
            <ExternalLink
              label={invoice.period}
              href={invoice.stripeInvoicePdf}
            />
          ) : (
            <Box>{invoice.period}</Box>
          )}
        </Box>
        <Box>
          <Tag>{invoice.stripeInvoiceStatus}</Tag>
        </Box>
      </HStack>
      <Divider />
    </Box>
  );
};
