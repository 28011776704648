import { AccountOpenViewAPI } from "api/account-openview-api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TOpenViewLeadSource,
  TOpenViewLeadSourceRelation,
  TOpenViewLeadSourceRelationData,
  TOpenViewSyncLeadSourceExtendedData,
} from "types/openview.type";

const accountOpenViewAPI = new AccountOpenViewAPI();

export class AccountIntegrationOpenViewLeadSourcesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountOpenViewLeadSources: IObservableArray<
    TOpenViewLeadSource
  >;
  private readonly accountOpenViewLeadSourceRelations: IObservableArray<
    TOpenViewLeadSourceRelation
  >;
  private syncStatusFilter: boolean;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.syncStatusFilter = false;
    this.accountOpenViewLeadSources = observable.array<TOpenViewLeadSource>();
    this.accountOpenViewLeadSourceRelations = observable.array<
      TOpenViewLeadSourceRelation
    >();
  }

  get openViewLeadSourceRelationsMapByLeadProLeadSourceId() {
    return keyBy(
      this.accountOpenViewLeadSourceRelations,
      relation => relation.leadSourceId
    );
  }

  get openViewLeadSourceRelationsMapByOpenViewLeadSourceId() {
    return keyBy(
      this.accountOpenViewLeadSourceRelations,
      relation => relation.openViewSource
    );
  }

  get getSyncStatusFilter() {
    return this.syncStatusFilter;
  }

  public setSyncStatusFilter(value: boolean) {
    this.syncStatusFilter = value;
  }

  get filteredOpenViewSyncLeadSourceData(): TOpenViewSyncLeadSourceExtendedData[] {
    return this.openViewSyncLeadSourceData.filter(
      syncData =>
        !this.syncStatusFilter ||
        syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
    );
  }

  get openViewSyncLeadSourceData(): TOpenViewSyncLeadSourceExtendedData[] {
    const accountStore = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const leadproLeadSources =
      accountStore.accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    const openViewLeadSourceRelationsMapByLeadProLeadSourceId = this
      .openViewLeadSourceRelationsMapByLeadProLeadSourceId;

    return leadproLeadSources.map(leadSource => {
      let pairedOpenViewLeadSource =
        openViewLeadSourceRelationsMapByLeadProLeadSourceId[leadSource.id]
          ?.openViewSource;
      let syncStatus = !!pairedOpenViewLeadSource
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

      return {
        leadSourceId: leadSource.id,
        leadSourceName: leadSource.name,
        openViewSource: pairedOpenViewLeadSource,
        syncStatus: syncStatus,
      };
    });
  }

  get openViewLeadSourceOptions() {
    return this.accountOpenViewLeadSources
      .map(openViewLeadSource => ({
        value: openViewLeadSource,
        label: openViewLeadSource,
        data: this.openViewLeadSourceRelationsMapByOpenViewLeadSourceId[
          openViewLeadSource
        ],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountOpenViewLeadSources(relations: TOpenViewLeadSource[]) {
    this.accountOpenViewLeadSources.replace(relations);
  }

  public setAcountOpenViewLeadSourceRelations(
    relations: TOpenViewLeadSourceRelation[]
  ) {
    this.accountOpenViewLeadSourceRelations.replace(relations);
  }

  public async fetchAccountOpenViewLeadSources() {
    const data = await accountOpenViewAPI.fetchAccountOpenViewLeadSources(
      this.accountId
    );
    this.setAccountOpenViewLeadSources(data);
  }

  public async fetchAccountOpenViewLeadSourceRelations() {
    const data = await accountOpenViewAPI.fetchAccountOpenViewLeadSourceRelations(
      this.accountId
    );
    this.setAcountOpenViewLeadSourceRelations(data);
  }

  public async fetchAccountOpenViewLeadSourcesAndRelations() {
    await Promise.all([
      this.fetchAccountOpenViewLeadSources(),
      this.fetchAccountOpenViewLeadSourceRelations(),
    ]);
  }

  public async updateAccountOpenViewSyncedLeadSources(
    syncedPairs: TOpenViewLeadSourceRelationData[]
  ) {
    await accountOpenViewAPI.updateAccountOpenViewSyncedLeadSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        openViewSource: pair.openViewSource,
      }))
    );
    await this.fetchAccountOpenViewLeadSourcesAndRelations();
  }
}
