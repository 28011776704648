import {
  Box,
  Button,
  ChakraStyledOptions,
  Image,
  useToast,
} from "@chakra-ui/react";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiMessageStack, Tooltip } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_WARNING_TOAST_OPTIONS,
} from "constants/default-toast-options";
import {
  IntegrationLogoIcons,
  IntegrationLogoIconURLs,
} from "constants/integrationLogoIcons";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo, useState } from "react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { TLeadSmeSyncData } from "types/sme.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { LeadSmeSyncFormPrompt } from "./LeadSmeSyncFormPrompt";

interface IProps {
  leadStore: AccountLeadStore;
  hasActiveSmeIntegration: boolean;
  styles?: ChakraStyledOptions;
}

export const LeadSyncSmeButton: FC<IProps> = observer(
  ({ leadStore, hasActiveSmeIntegration, styles }) => {
    const toast = useToast();
    const [isSyncing, setIsSyncing] = useState(false);
    const { setModal, unSetModal } = useActionPrompt();
    const isLeadSynced = leadStore.isLeadSyncedToSme;
    const isSmeSyncAllowedForLeadType = leadStore.isSmeSyncAllowedForLeadType;
    const isLeadAssignedToUser = leadStore.isLeadAssignedToUser;
    const isLeadAssignedToOffice = leadStore.isLeadAssignedToOffice;

    const validationErrorMessages = useMemo(() => {
      const errorMessages: string[] = [];

      if (isSyncing) errorMessages.push("Lead is being synced...");
      if (isLeadSynced) errorMessages.push("Lead already synced.");
      if (!isSmeSyncAllowedForLeadType)
        errorMessages.push("Lead type not supported.");
      if (!isLeadAssignedToUser) errorMessages.push("Please assign an agent.");
      if (!isLeadAssignedToOffice)
        errorMessages.push("Please assign an office.");

      return errorMessages;
    }, [
      isSyncing,
      isLeadSynced,
      isSmeSyncAllowedForLeadType,
      isLeadAssignedToUser,
      isLeadAssignedToOffice,
    ]);

    const buttonData = useMemo(() => {
      return {
        buttonProps: {
          isDisabled: !!validationErrorMessages.length,
          isLoading: isSyncing,
          children: isLeadSynced
            ? "Synced to SME Professional"
            : "Sync to SME Professional",
          leftIcon: isLeadSynced ? (
            <FontAwesomeIcon icon={faCircleCheck} fontSize={24} />
          ) : (
            <Image
              alt={"SME Professional Logo"}
              width={"24px"}
              src={IntegrationLogoIconURLs[IntegrationLogoIcons.SME]}
            />
          ),
        },
      };
    }, [isLeadSynced, isSyncing, validationErrorMessages.length]);

    const handleSyncLead = useCallback(
      async (syncData: TLeadSmeSyncData) => {
        if (hasActiveSmeIntegration && !validationErrorMessages.length) {
          try {
            setIsSyncing(true);
            await leadStore.syncLeadWithSme(syncData);
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          } finally {
            setIsSyncing(false);
          }
        }
      },
      [leadStore, hasActiveSmeIntegration, validationErrorMessages, toast]
    );

    const handleOpenSyncModal = useCallback(async () => {
      await leadStore.fetchDetails();
      const leadSyncCheck = leadStore.isLeadSyncedToAlto;
      if (leadSyncCheck) {
        toast({
          ...DEFAULT_WARNING_TOAST_OPTIONS,
          description:
            "This lead has already been sync'd to alto by another user.",
        });
      } else {
        setModal(
          <LeadSmeSyncFormPrompt
            leadStore={leadStore}
            handleSyncLead={handleSyncLead}
            closePrompt={unSetModal}
          />
        );
      }
    }, [leadStore, handleSyncLead, setModal, unSetModal, toast]);

    return (
      <Tooltip
        isDisabled={!validationErrorMessages.length}
        aria-label="lead-sme-sync-tooltip"
        label={
          <Box>
            {validationErrorMessages.map(message => (
              <Box key={message}>{message}</Box>
            ))}
          </Box>
        }
      >
        <Box>
          <Button
            onClick={handleOpenSyncModal}
            variant="ghost"
            {...buttonData.buttonProps}
            {...styles}
          />
        </Box>
      </Tooltip>
    );
  }
);
