import { Box, Button } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { NA } from "components";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { AccountBillingCustomerAddressPrompt } from "routes/dashboard/components/prompts/AccountBillingCustomerAddressPrompt";
import { observer } from "mobx-react";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";

interface IProps {
  accountBillingStore: AccountBillingStore;
}

export const AccountBillingAddress: React.FC<IProps> = observer(
  ({ accountBillingStore }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const stripeCustomerAddress =
      accountBillingStore.billing?.stripeCustomerAddress;
    const isDisabled = !accountBillingStore.billing?.stripePaymentMethodLast4;
    const handleOpenBillingAddressFormPrompt = useCallback(() => {
      setModal(
        <AccountBillingCustomerAddressPrompt
          accountBillingStore={accountBillingStore}
          closePrompt={unSetModal}
        />
      );
    }, [accountBillingStore, setModal, unSetModal]);

    return (
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        py={5}
      >
        <Box color={"blue.500"} width={"200px"} pr={4} fontWeight={500}>
          Billing address
        </Box>

        <Box flexGrow={1}>
          {!stripeCustomerAddress && <NA />}
          {!!stripeCustomerAddress?.line1 && (
            <Box>{stripeCustomerAddress?.line1}</Box>
          )}
          {!!stripeCustomerAddress?.line2 && (
            <Box>{stripeCustomerAddress?.line2}</Box>
          )}
          {!!stripeCustomerAddress?.city && (
            <Box>{stripeCustomerAddress?.city}</Box>
          )}
          {!!stripeCustomerAddress?.postalCode && (
            <Box>{stripeCustomerAddress?.postalCode}</Box>
          )}
          {!!stripeCustomerAddress?.country && (
            <Box>{stripeCustomerAddress?.country}</Box>
          )}
        </Box>

        <Box>
          <Button
            onClick={handleOpenBillingAddressFormPrompt}
            variant="link"
            size={"md"}
            colorScheme={"blue"}
            isDisabled={isDisabled}
          >
            Edit
          </Button>
        </Box>
      </Box>
    );
  }
);
