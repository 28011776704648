export const HOME_ROUTE = "/";
export const ANALYTICS_ROUTE = "/analytics";
export const LEAGUE_ROUTE = "/league";
export const ACHIEVEMENTS_ROUTE = "/achievements";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const ACCOUNT_SETUP_ROUTE = "/account-setup";
export const SET_PASSWORD_ROUTE = "/password";
export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const REGISTER_ROUTE = "/register";
export const USER_PROFILE_ROUTE = "/user-profile";
export const IMPERSONATION_ROUTE = "/impersonate";
export const ECOSYSTEM_LOGIN_ROUTE = "/ecosystem-login";
export const ECOSYSTEM_REDIRECT_ROUTE = "/ecosystem-redirect";
export const ECOSYSTEM_FINALIZE_INVITE = "/ecosystem-finalize-invite";

export const GUIDES_ROUTE = "/guides";
export const GUIDES_ARTICLE_ROUTE = `${GUIDES_ROUTE}/:slug`;

export const SETTINGS_ROUTE = "/settings";
export const SETTINGS_ACCOUNT_ROUTE = `${SETTINGS_ROUTE}/account`;
export const SETTINGS_BILLING_ROUTE = `${SETTINGS_ROUTE}/billing`;
export const SETTINGS_OFFICES_ROUTE = `${SETTINGS_ROUTE}/offices`;
export const SETTINGS_OFFICE_DETAILS_ROUTE = `${SETTINGS_OFFICES_ROUTE}/:id`;
export const SETTINGS_USERS_ROUTE = `${SETTINGS_ROUTE}/users`;
export const SETTINGS_INTEGRATIONS_ROUTE = `${SETTINGS_ROUTE}/integrations`;
export const SETTINGS_INTEGRATION_DETAILS_ROUTE = `${SETTINGS_INTEGRATIONS_ROUTE}/:type`;
export const SETTINGS_DEVELOPERS_ROUTE = `${SETTINGS_ROUTE}/developers`;
export const SETTINGS_DEVELOPERS_API_KEYS_ROUTE = `${SETTINGS_ROUTE}/developers/api-keys`;
export const SETTINGS_DEVELOPERS_API_KEYS_DETAILS_ROUTE = `${SETTINGS_DEVELOPERS_API_KEYS_ROUTE}/:id`;
export const SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE = `${SETTINGS_ROUTE}/developers/webhooks`;
export const SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE = `${SETTINGS_ROUTE}/developers/internal-webhooks`;
export const SETTINGS_DEVELOPERS_WEBHOOK_DETAILS_ROUTE = `${SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE}/:id`;
export const SETTINGS_DEVELOPERS_INTERNAL_WEBHOOK_DETAILS_ROUTE = `${SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE}/:id`;

export const TOOLS_ROUTE = "/tools";
export const TOOLS_IVT_ROUTE = `${TOOLS_ROUTE}/instant-valuation-tool`;
export const TOOLS_IVT_PAGE_DETAILS_ROUTE = `${TOOLS_IVT_ROUTE}/:id`;
export const TOOLS_FORMS_ROUTE = `${TOOLS_ROUTE}/forms`;
export const TOOLS_FORMS_PAGE_DETAILS_ROUTE = `${TOOLS_FORMS_ROUTE}/:id`;
export const TOOLS_FORMS_PAGE_DETAILS_RESPONSES_ROUTE = `${TOOLS_FORMS_PAGE_DETAILS_ROUTE}/responses`;
export const TOOLS_LEAD_RESPONDER_ROUTE = `${TOOLS_ROUTE}/lead-responder`;
export const TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE = `${TOOLS_LEAD_RESPONDER_ROUTE}/questionnaire-pages`;
export const TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE = `${TOOLS_LEAD_RESPONDER_ROUTE}/questionnaire-pages/:id`;
export const TOOLS_LEAD_RESONDER_LEAD_EMAILS_ROUTE = `${TOOLS_LEAD_RESPONDER_ROUTE}/lead-emails`;
export const TOOLS_LEAD_RESPONDER_LEAD_EMAILS_DETAILS_ROUTE = `${TOOLS_LEAD_RESPONDER_ROUTE}/lead-emails/:id`;
export const TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_RESPONSES_ROUTE = `${TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE}/responses`;
export const TOOLS_AUTOCALLER_ROUTE = `${TOOLS_ROUTE}/autocaller`;
export const TOOLS_MORTGAGE_REFERRALS_ROUTE = `${TOOLS_ROUTE}/mortgages`;
export const TOOLS_FACEBOOK_ADS_ROUTE = `${TOOLS_ROUTE}/facebook-ads`;

export const LEAD_SUBROUTE_UNREAD = "unread";
export const LEAD_SUBROUTE_IN_PROGRESS = "in-progress";
export const LEAD_SUBROUTE_BUSINESS = "business";
export const LEAD_SUBROUTE_NO_BUSINESS = "no-business";
export const LEAD_SUBROUTE_ALL = "all";

export const LEADS_ROUTE = "/leads";
export const LEADS_UNREAD_ROUTE = `${LEADS_ROUTE}/${LEAD_SUBROUTE_UNREAD}`;
export const LEADS_IN_PROGRESS_ROUTE = `${LEADS_ROUTE}/${LEAD_SUBROUTE_IN_PROGRESS}`;
export const LEADS_BUSINESS_ROUTE = `${LEADS_ROUTE}/${LEAD_SUBROUTE_BUSINESS}`;
export const LEADS_NO_BUSINESS_ROUTE = `${LEADS_ROUTE}/${LEAD_SUBROUTE_NO_BUSINESS}`;
export const LEADS_ALL_ROUTE = `${LEADS_ROUTE}/${LEAD_SUBROUTE_ALL}`;

export const PROPERTIES_ROUTE = "/properties";
export const PROPERTY_DETAILS_ROUTE = `${PROPERTIES_ROUTE}/:id`;
