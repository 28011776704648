import { observer } from "mobx-react";
import Styles from "components/avatar/Avatar.module.scss";
import { Avatar } from "@chakra-ui/react";
import React, { FC } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

interface IProps {
  src?: string;
}
export const LeadAttributionSourceAvatar: FC<IProps> = observer(({ src }) => {
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();

  return (
    <Avatar
      className={Styles["squareAvatar"]}
      size={"sm"}
      src={src || selectedAccountStore?.account.logoUrl || ""}
    />
  );
});
