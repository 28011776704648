import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import {
  IAccountOfficePortalActivity,
  TOfficeActivitySupportedLeadTypes,
} from "types/account-portal-link-status-per-office.type";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import React from "react";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { PortalLinkStatusCell } from "components/table/cells/PortalLinkStatusCell";
import { TPortalData } from "types/portal.type";
import { TNormalizedLeadSourceData } from "types/lead-source.type";

const SUPPORTED_LEAD_TYPES = [
  LeadTypeEnum.Vendor,
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
];

const generateLeadTypeColumn = (
  leadType: TOfficeActivitySupportedLeadTypes,
  portalsMap: Record<number, TPortalData>,
  leadSourcesMap: Record<number, TNormalizedLeadSourceData>
): ITableV2Column<IAccountOfficePortalActivity> => {
  return {
    accessor: "id",
    excludeFromGlobalFilter: true,
    disableSortBy: true,
    width: "100px",
    Header: (
      <DefaultHeaderCell mx={"auto"}>
        {getLeadTypeLabel(leadType)}
      </DefaultHeaderCell>
    ),
    Cell: ({ row }: { row: IAccountOfficePortalActivity }) => {
      return (
        <DefaultRowCell>
          <PortalLinkStatusCell
            officeActivity={row}
            displayForType={leadType}
            portals={portalsMap}
            leadSources={leadSourcesMap}
          />
        </DefaultRowCell>
      );
    },
  };
};

export const officePortalActivityTableColumnDef = (
  portalsMap: Record<number, TPortalData>,
  leadSourcesMap: Record<number, TNormalizedLeadSourceData>
): ITableV2Column<IAccountOfficePortalActivity>[] => {
  return [
    {
      accessor: "name",
      Header: <DefaultHeaderCell>Office name</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.name}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      excludeFromGlobalFilter: true,
      width: "100px",
      Header: <DefaultHeaderCell>Created</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    ...SUPPORTED_LEAD_TYPES.map(supportedType =>
      generateLeadTypeColumn(
        supportedType as TOfficeActivitySupportedLeadTypes,
        portalsMap,
        leadSourcesMap
      )
    ),
  ];
};
