import { Box, HStack, useTheme, useToken, VStack } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";

interface IProps {
  value: AccountEmailNotificationPreferenceEnum;
  selectedValue: AccountEmailNotificationPreferenceEnum;
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
  onClick: (value: AccountEmailNotificationPreferenceEnum) => void;
}
export const AccountEmailNotificationsItem: FC<IProps> = ({
  value,
  selectedValue,
  icon,
  title,
  description,
  onClick,
}) => {
  const [leadpro800] = useToken("colors", ["leadpro.800"]);
  const theme = useTheme();
  const isSelected = useMemo(() => value === selectedValue, [
    value,
    selectedValue,
  ]);

  const style = useMemo(() => {
    return {
      base: {
        spacing: 2,
        padding: 5,
        rounded: "md",
        align: "flex-start",
        color: "leadpro.500",
        cursor: "pointer",
        bg: isSelected ? "white" : transparentize(leadpro800, 0.03)(theme),
        border: "1px solid",
        borderColor: isSelected ? "leadpro.800" : "leadpro.400",
        outline: isSelected ? "solid 0.5px" : "none",
        outlineColor: isSelected ? "leadpro.800" : "none",
      },
      icon: {
        bg: isSelected ? "leadpro.800" : "transparent",
        color: isSelected ? "white" : "leadpro.500",
        rounded: "full",
        border: "1px solid",
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 48.5,
        minHeight: 48.5,
      },
      title: {
        color: isSelected ? "leadpro.800" : "leadpro.500",
      },
    };
  }, [isSelected, leadpro800, theme]);

  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  return (
    <VStack {...style.base} onClick={handleClick}>
      <HStack gap={7}>
        <Box {...style.icon}>{icon}</Box>
        <Box>
          <Box {...style.title}>{title}</Box>
          <Box fontSize={"xs"} fontWeight={"normal"}>
            {description}
          </Box>
        </Box>
      </HStack>
    </VStack>
  );
};
