import React, { FC } from "react";
import { Box, Kbd } from "@chakra-ui/react";
import { AutocallerDemoCard } from "./AutocallerDemoCard";
import { ProductInfo } from "routes/dashboard/routes/tools/components/ProductInfo/ProductInfo";
import { accountProductsConfigMap } from "constants/account-products-config";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { SplashTestimonial } from "../../../../components/SplashScreen/SplashTestimonial";
import { SplashScreen } from "../../../../components/SplashScreen/SplashScreen";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { BEN_TESTIMONIAL } from "constants/image-paths";

const productConfig =
  accountProductsConfigMap[ApplicationProductEnum.AUTOCALLER];

interface IProps {
  accountStore: UserAccountStore;
}

export const AutocallerSplashScreen: FC<IProps> = observer(
  ({ accountStore }) => {
    return (
      <SplashScreen
        background={"purple.50"}
        topSection={
          <>
            <ProductInfo
              productConfig={productConfig}
              header={
                "Speak to high value portal enquiries before your competitors."
              }
              bulletPoints={[
                "Vendor requests a valuation through a portal (e.g. Rightmove)",
                "Autocaller rings your office, and plays a message with the lead details.",
                <Box>
                  Press <Kbd>1</Kbd> on your keypad to accept the call
                  connection, or <Kbd>2</Kbd> to reject. If you accept,
                  Autocaller will attempt to connect you to the lead.
                </Box>,
              ]}
              finePrint={`This will increase your monthly bill by £${productConfig.pricing.flatFee}. Plan includes ${productConfig.pricing.unitsIncluded} minutes. Additional minutes are charged at a rate of £${productConfig.pricing.pricePerExtraUnit}.`}
              productColor={"purple"}
              colorScheme={"purple"}
              accountBillingStore={accountStore.accountBillingStore}
            />
            <AutocallerDemoCard
              autocallerStore={accountStore.accountAutocallerStore}
            />
          </>
        }
        bottomSection={
          <SplashTestimonial
            quote={
              "Autocaller makes sure we call valuation leads before any other agent, since using it we are booking in 5-7 more valuation appointments every month."
            }
            author={"Benjamin Churchill, Harry Harper Estate Agents"}
            authorImageUrl={BEN_TESTIMONIAL}
            colorScheme={"green"}
          />
        }
      />
    );
  }
);
