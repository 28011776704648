import { observer } from "mobx-react";
import { Box, Portal, useToast, VStack } from "@chakra-ui/react";
import { WebPushSettings } from "./WebPushSettings/WebPushSettings";
import React, { FC, MutableRefObject, useCallback, useState } from "react";
import { TNormalizedUserData } from "types/user-data.type";
import { UserAssigneeAvailabilitySettings } from "./UserAssigneeAvailabilitySettings";
import { FormFooter } from "../../../../../../components/form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "../../../../../../constants/default-toast-options";

type TSetting = {
  isDirty: boolean;
  reset: () => void;
  submit: () => Promise<void>;
};

interface IProps {
  user: TNormalizedUserData;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const UserSettings: FC<IProps> = observer(({ user, containerRef }) => {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settings, setSettings] = useState<TSetting[]>([]);

  const isDirty = settings.some(setting => setting.isDirty);

  const reset = useCallback(() => {
    settings.forEach(setting => {
      if (setting.isDirty) setting.reset();
    });
  }, [settings]);

  const submit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      const promises: Promise<void>[] = [];
      settings.forEach(setting => {
        if (setting.isDirty) promises.push(setting.submit());
      });

      await Promise.all(promises);
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        title: "Your user settings have been updated!",
      });
    } catch (err) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: "Please contact support",
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [settings, toast]);

  const setFormSettings = useCallback((newSetting: TSetting, index: number) => {
    setSettings((oldSettings: TSetting[]) => {
      const newSettings = [...oldSettings];
      newSettings[index] = newSetting;

      return newSettings;
    });
  }, []);

  return (
    <Box>
      <VStack
        spacing={20}
        position={"relative"}
        minHeight={"100%"}
        overflow={"hidden"}
        pr={1}
        pt={1}
        pb={20}
      >
        <UserAssigneeAvailabilitySettings
          index={0}
          user={user}
          setFormSettings={setFormSettings}
        />
        <WebPushSettings
          index={1}
          user={user}
          setFormSettings={setFormSettings}
        />
      </VStack>
      {isDirty && (
        <Portal containerRef={containerRef}>
          <FormFooter
            isSubmitting={isSubmitting}
            submitForm={submit}
            resetForm={reset}
          />
        </Portal>
      )}
    </Box>
  );
});
