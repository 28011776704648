import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

interface IProps {
  title: string;
  demoHref: string;
  description: string;
}
export const InstantValuationToolHowItWorksItem: React.FC<IProps> = ({
  title,
  demoHref,
  description,
}) => {
  return (
    <Box
      as="a"
      display="block"
      href={demoHref}
      target="_blank"
      rounded="lg"
      border="1px solid"
      borderColor="gray.100"
      width="100%"
      cursor="pointer"
      textAlign="left"
      _hover={{
        bg: "gray.50",
      }}
    >
      <Box p={7}>
        <Heading fontSize="2xl" mb={3} lineHeight="none">
          {title}
        </Heading>
        <Box fontSize="md" mb={4}>
          {description}
        </Box>
        <Box
          lineHeight="none"
          display="flex"
          fontSize="sm"
          alignItems="center"
          fontWeight={700}
          color="blue.600"
          textTransform="uppercase"
          letterSpacing="wide"
        >
          See demo
          <Box ml={1}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
