import { IntegrationEntitySyncTag } from "components";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { RexLeadSourceSyncSelect } from "./RexLeadSourceSyncSelect";
import { TRexSyncLeadSourceExtendedData } from "types/rex.type";

export const syncRexLeadSourceTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TRexSyncLeadSourceExtendedData) => void
): ITableV2Column<TRexSyncLeadSourceExtendedData>[] => {
  return [
    {
      accessor: "leadSourceName",
      Header: <DefaultHeaderCell>LeadPro source</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell textTransform={"capitalize"}>
          {row.leadSourceName}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "rexId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Rex source</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <RexLeadSourceSyncSelect
            accountStore={accountStore}
            onChange={updateSyncPair}
            record={row}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
