export const INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR = {
  bg: "white",
  _hover: {
    borderColor: "gray.200",
  },
  _invalid: {
    boxShadow: "0 0 0 4px rgba(241, 103, 103, 0.2)",
    borderColor: "#cc7171",
  },
  _focus: {
    outline: "none",
    boxShadow: "0 0 0 3px rgba(00,00,00,0.1)",
    borderColor: "transparent",
  },
};

export const INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR_BORDERLESS = {
  bg: "white",
  border: "none",
  _hover: {
    borderColor: "none",
  },
  _focus: {
    outline: "none",
    boxShadow: "none",
    borderColor: "none",
  },
};
