import { Box } from "@chakra-ui/react";
import { SearchQueryInput } from "components";
import { observer } from "mobx-react";
import { ChangeEvent, FC, useCallback } from "react";
import { RightSideLayout } from "routes/dashboard/components";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { PropertiesContent } from "./components/PropertiesContent";

interface IProps {
  accountStore: UserAccountStore;
}

export const Properties: FC<IProps> = observer(({ accountStore }) => {
  const propertiesFilterStore =
    accountStore.accountPropertiesStore.propertiesFilterStore;
  const { setSearchQuery, searchQuery } = propertiesFilterStore;

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  return (
    <RightSideLayout
      headerProps={{
        title: "Properties",
        middleElement: (
          <SearchQueryInput
            placeholder={"Search by address or property reference"}
            onChange={handleSearchQuery}
            searchQuery={searchQuery}
          />
        ),
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        overflow={"hidden"}
      >
        <PropertiesContent accountStore={accountStore} />
      </Box>
    </RightSideLayout>
  );
});
