import * as Yup from "yup";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
  getInvalidUKPostcodeMessage,
  SELECT_AT_LEAST_ONE,
  OFFICE_POSTCODE_LIMIT,
} from "constants/validator-messages";
import { isValidPhone } from "../custom-yup-validators.utils";
import { UK_POSTCODE_REGEX_WITH_ELEMENTS } from "../regexs.utils";

export type TOfficeFormFieldValues = {
  name: string;
  website: string | null;
  postcodes: string[];
  isSales: boolean;
  isLettings: boolean;
  isMortgage: boolean;
  isAdvancedContactData: boolean;
  salesEmails: string[];
  lettingsEmails: string[];
  vendorEmails: string[];
  landlordEmails: string[];
  salesPhoneNumber: string;
  lettingsPhoneNumber: string;
  vendorPhoneNumber: string;
  landlordPhoneNumber: string;
  mortgagePhoneNumber: string;
  officeGroupIds: number[];
};

export const officeBasicValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required(REQUIRED_FIELD),
    website: Yup.string().url(),
    postcodes: Yup.array()
      .of(
        Yup.string().matches(
          UK_POSTCODE_REGEX_WITH_ELEMENTS,
          getInvalidUKPostcodeMessage
        )
      )
      .min(1, REQUIRED_FIELD)
      .max(150, OFFICE_POSTCODE_LIMIT),
    isSales: Yup.boolean(),
    isLettings: Yup.boolean(),
    isMortgage: Yup.boolean(),
    salesEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    lettingsEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    vendorEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    landlordEmails: Yup.array()
      .of(Yup.string().email())
      .min(1, REQUIRED_FIELD),
    salesPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    lettingsPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    vendorPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    landlordPhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
    mortgagePhoneNumber: Yup.string()
      .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
      .required(REQUIRED_FIELD),
  })
  .test("atLeastOneChecked", "", obj => {
    if (obj.isSales || obj.isLettings || obj.isMortgage) {
      return true;
    }

    return new Yup.ValidationError(SELECT_AT_LEAST_ONE, null, "isMortgage");
  });
