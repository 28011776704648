import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import { AccountDevelopersWebhooksStore } from "./AccountDevelopersWebhooks/AccountDevelopersWebhooks.store";
import { AccountDevelopersApiKeysStore } from "./AccountDevelopersApiKeys/AccountDevelopersApiKeys.store";

export class AccountDevelopersStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  public readonly accountDevelopersApiKeysStore: AccountDevelopersApiKeysStore;
  public readonly accountDevelopersWebhooksStore: AccountDevelopersWebhooksStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;

    this.accountDevelopersApiKeysStore = new AccountDevelopersApiKeysStore(
      root,
      accountId
    );
    this.accountDevelopersWebhooksStore = new AccountDevelopersWebhooksStore(
      root,
      accountId
    );
  }
}
