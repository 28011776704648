import { AccountSmeApi } from "api/account-sme.api";
import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountIntegrationOfficesStore } from "../AccountIntegrationOfficesStore.store";
import { AccountIntegrationSmeEmployeesStore } from "./AccountIntegrationSmeEmployees.store";
import { AccountIntegrationSmeLeadSourceStore } from "./AccountIntegrationSmeLeadSource.store";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeStore {
  public accountIntegrationSmeOfficesStore: AccountIntegrationOfficesStore;
  public accountIntegrationSmeLeadSourcesStore: AccountIntegrationSmeLeadSourceStore;
  public accountIntegrationSmeEmployeesStore: AccountIntegrationSmeEmployeesStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountIntegrationSmeOfficesStore = new AccountIntegrationOfficesStore(
      root,
      accountId,
      accountSmeApi
    );
    this.accountIntegrationSmeLeadSourcesStore = new AccountIntegrationSmeLeadSourceStore(
      root,
      accountId
    );
    this.accountIntegrationSmeEmployeesStore = new AccountIntegrationSmeEmployeesStore(
      root,
      accountId
    );
  }
}
