import { getLeadProSignUpTokenPayload } from "utils/jwt.utils";
import { TLeadProSignupData } from "types/token.type";
import { AppStore } from "./App.store";
import { makeAutoObservable } from "mobx";
import { AuthApi } from "api/auth.api";
import { IAuthStore } from "interfaces/IAuthStore";
import {
  clearLeadProAuthTokenFromLocalStorage,
  getLeadProAuthTokenFromLocalStorage,
  setLeadProAuthTokenFromLocalStorage,
} from "utils/local-storage.utils";

const authApi = new AuthApi();

export class LeadProAuthStore implements IAuthStore {
  private readonly root: AppStore;

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
  }

  private setAuthHeaderAndStatusFromTokenInStorage() {
    this.root.authStore.setAuth(getLeadProAuthTokenFromLocalStorage());
  }

  // init store is for setting up token if it exists
  public async initStore() {
    this.setAuthHeaderAndStatusFromTokenInStorage();
  }

  public async removeCredentials() {
    clearLeadProAuthTokenFromLocalStorage();
  }

  public impersonateUser(token: string) {
    setLeadProAuthTokenFromLocalStorage(token);
    this.root.authStore.setAuth(token);
  }

  public async verifySignupToken(token: string) {
    return await authApi.verifySignupToken(token);
  }

  public async verifyPasswordResetToken(token: string) {
    return await authApi.verifyResetPasswordToken(token);
  }

  public async setPassword(
    password: string,
    token: string,
    reCaptchaToken: string
  ) {
    return await authApi.setPassword(password, token, reCaptchaToken);
  }

  public async resetPassword(email: string, reCaptchaToken: string) {
    return await authApi.resetPassword(email, reCaptchaToken);
  }

  public async register(
    signupToken: string,
    password: string,
    reCaptchaToken: string
  ) {
    const tokenPayload = getLeadProSignUpTokenPayload(signupToken);

    if (!!tokenPayload.additionalData) {
      await authApi.epropRegister(signupToken, password, reCaptchaToken);
    } else {
      await authApi.register(signupToken, password, reCaptchaToken);
    }
  }

  public async signup(signupData: TLeadProSignupData, reCaptchaToken: string) {
    await authApi.signup(signupData, reCaptchaToken);
  }

  public async login(email: string, password: string, reCaptchaToken: string) {
    const { token } = await authApi.login(email, password, reCaptchaToken);

    setLeadProAuthTokenFromLocalStorage(token);
    this.setAuthHeaderAndStatusFromTokenInStorage();
  }

  public async confirmLoginWith2FA(code: string) {
    const { token } = await this.verify2FACode(code);

    setLeadProAuthTokenFromLocalStorage(token);
    this.setAuthHeaderAndStatusFromTokenInStorage();
  }

  public async updatePassword(currentPassword: string, newPassword: string) {
    await authApi.updatePassword(currentPassword, newPassword);
  }

  public async disable2FA() {
    await authApi.disable2FA();
    await this.root.authStore.reloadAuthUserData();
  }

  public async enable2FA(code: string) {
    await authApi.enable2FA(code);
    await this.root.authStore.reloadAuthUserData();
  }

  public async request2FACode() {
    await authApi.request2FACode();
  }

  public async verify2FACode(code: string) {
    return await authApi.verify2FACode(code);
  }
}
