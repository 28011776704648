import React, { FC } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import { TFormToolPageQuestionTemplate } from "types/form-tool-page.type";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  questionTemplate: TFormToolPageQuestionTemplate;
}
export const FormToolPageSettingsTemplateQuestion: FC<IProps> = ({
  questionTemplate,
}) => {
  const theme = useTheme();

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      rounded={"sm"}
      p={4}
      border={"1px solid"}
      borderColor={"leadpro.200"}
      bg={"leadpro.50"}
      shadow={"sm"}
    >
      <Box
        display={"flex"}
        gap={"16px"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Box fontSize={"sm"} color={"leadpro.400"}>
          New
        </Box>
        <Box fontSize={"md"} color={"leadpro.500"}>
          {questionTemplate.label}
        </Box>
      </Box>
      <FontAwesomeIcon
        icon={faInfoCircle}
        fontSize={16}
        color={theme.colors.leadpro[400]}
      />
    </Box>
  );
};
