import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeGroups } from "./OfficeGroups/OfficeGroups";
import { OfficesList } from "./Offices/OfficesList";

interface IProps {
  accountStore: UserAccountStore;
}

export const OfficesTabIndex: React.FC<IProps> = ({ accountStore }) => {
  return (
    <TabsGroupLayout
      title={"Offices"}
      config={[
        {
          tabName: "Offices",
          tabHash: "#list",
          tabComponent: <OfficesList accountStore={accountStore} />,
        },
        {
          tabName: "Office Groups",
          tabHash: "#groups",
          tabComponent: <OfficeGroups accountStore={accountStore} />,
        },
      ]}
    />
  );
};
