import React from "react";
import {
  Box,
  BoxProps,
  Center,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  TSelectOption,
  TSelectOptionValue,
  TSelectValue,
} from "types/select-input.type";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

interface IMultiSelectInputValueProps<
  D extends TSelectOptionValue,
  S = unknown
> extends Omit<BoxProps, "onChange"> {
  value: TSelectValue<D>[];
  onChange: (value: TSelectValue<D>[]) => void;
  placeholder?: string;
  optionComponent: (optionProps: IGetOptionPropsData<D, S>) => React.ReactNode;
  selectedOption: TSelectOption<D, S>[];
  getOptionProps: (props: object) => IGetOptionPropsData<D, S>;
}

export function MultiSelectInputValue<
  D extends TSelectOptionValue,
  S = unknown
>({
  value,
  onChange,
  placeholder,
  optionComponent,
  selectedOption,
}: IMultiSelectInputValueProps<D, S>) {
  return (
    <Box display={"flex"} flexDirection={"row"} flexBasis={"100%"} px={2}>
      {!selectedOption?.length && (
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          color={"gray.400"}
        >
          {placeholder}
        </Box>
      )}
      {!!selectedOption?.length && (
        <Center py={2}>
          <Wrap overflowX={"hidden"} overflowY={"auto"} height={"100%"}>
            {selectedOption.map((option: TSelectOption<D, S>, index) => (
              <WrapItem key={index}>
                <Tag size={"sm"} variant={"subtle"}>
                  <TagLabel>
                    {optionComponent({
                      option,
                      key: index,
                      onClick: undefined,
                      onMouseEnter: undefined,
                      py: 1,
                      px: 0,
                    })}
                  </TagLabel>
                  <TagCloseButton
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      onChange(value.filter(d => d !== option.value));
                    }}
                  />
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Center>
      )}
    </Box>
  );
}
