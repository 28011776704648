import { Box, Divider, SimpleGrid, VStack } from "@chakra-ui/react";
import { FormControlV2, FormControlMeta } from "components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  getWebPushNotificationPermission,
  isWebPushNotificationStackSupported,
} from "utils/web-push.utils";
import { TNormalizedUserData } from "types/user-data.type";
import { WebPushNotificationPermissionTypeEnum } from "enums/web-push-notification-permission-type.enum";
import { TWebPushSettings } from "types/web-push.type";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { WebPushSettingsHeaderElement } from "./WebPushSettingsHeaderElement";
import { useForm } from "react-hook-form";

type TFieldValues = TWebPushSettings;

interface IProps {
  index: number;
  user: TNormalizedUserData;
  setFormSettings: (
    data: {
      isDirty: boolean;
      reset: () => void;
      submit: () => Promise<void>;
    },
    index: number
  ) => void;
}

export const WebPushSettings: React.FC<IProps> = observer(
  ({ index, user, setFormSettings }) => {
    const isSupported = useRef(isWebPushNotificationStackSupported());
    const [notificationsPermission, setNotificationsPermission] = useState(
      getWebPushNotificationPermission()
    );

    const {
      authStore: { updateWebPushSettings },
    } = useAppStore();

    const initialValues: TFieldValues = useMemo(
      () => ({
        sale: user.webPushSettings[LeadTypeEnum.Sale],
        let: user.webPushSettings[LeadTypeEnum.Let],
        vendor: user.webPushSettings[LeadTypeEnum.Vendor],
        landlord: user.webPushSettings[LeadTypeEnum.Landlord],
        mortgage_request: user.webPushSettings[LeadTypeEnum.MortgageRequest],
        market_appraisal: user.webPushSettings[LeadTypeEnum.MarketAppraisal],
      }),
      [user.webPushSettings]
    );

    const isAllowedToEditForm = useMemo(() => {
      return (
        isSupported.current &&
        notificationsPermission ===
          WebPushNotificationPermissionTypeEnum.GRANTED
      );
    }, [isSupported, notificationsPermission]);

    const {
      handleSubmit,
      control,
      reset,
      formState: { isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
    });

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    useEffect(() => {
      setFormSettings(
        {
          isDirty,
          reset,
          submit: handleSubmit(updateWebPushSettings),
        },
        index
      );
    }, [
      setFormSettings,
      isDirty,
      reset,
      handleSubmit,
      updateWebPushSettings,
      index,
    ]);

    return (
      <VStack spacing={10} width={"100%"} alignItems={"stretch"}>
        <Box>
          <Box color={"leadpro.700"} fontSize={"lg"} fontWeight={"bold"}>
            Push notifications
          </Box>
          <Box color={"leadpro.500"} fontSize={"md"} mt={4}>
            Use push notifications to get notifications directly to your desktop
            when new leads are generated. You can select which lead types you
            will get notified for.
          </Box>
        </Box>
        <Box>
          <Box pb={10}>
            <WebPushSettingsHeaderElement
              isSupported={isSupported.current}
              notificationsPermission={notificationsPermission}
              setNotificationsPermission={setNotificationsPermission}
            />
          </Box>
          <VStack spacing={10} align={"stretch"} divider={<Divider />}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Sales leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.Sale}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Landlord leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.Landlord}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Vendor leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.Vendor}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Let leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.Let}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Mortgage leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.MortgageRequest}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta label="Market Appraisal leads" />
              <FormControlV2<TFieldValues>
                name={LeadTypeEnum.MarketAppraisal}
                control={control}
                type={FormControlsTypeEnum.SWITCH}
                isDisabled={!isAllowedToEditForm}
              />
            </SimpleGrid>
          </VStack>
        </Box>
      </VStack>
    );
  }
);
