import {
  ILeadBlockDefinition,
  leadBlockHoc,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import { LeadInfoBlockRow } from "../LeadInfoRow/LeadInfoBlockRow";

export interface ILeadUTMBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.UTMBlock;
  utm: Record<string, string | null>;
}

export const LeadUTMBlock = leadBlockHoc<ILeadUTMBlockDefinition>(
  ({ leadBlockDefinition: { utm } }) => {
    return (
      <>
        <LeadBlockHeader>UTM</LeadBlockHeader>
        <LeadBlockBody>
          {Object.keys(utm).map(key => (
            <LeadInfoBlockRow key={key} metaKey={key} value={utm[key] || ""} />
          ))}
        </LeadBlockBody>
      </>
    );
  }
);
