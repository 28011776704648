import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { PageToolPageGDPRCheckboxSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageGDPRCheckboxSettings";
import { PageToolPageBasicSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageBasicSettings";
import { ApiMessageStack, ExternalLink } from "components";
import { InstantValuationToolCodeSnippetSettings } from "./InstantValuationToolCodeSnippet/InstantValuationToolCodeSnippetSettings";
import { InstantValuationToolCTASettings } from "./InstantValuationToolCTASettings";
import { PageCtaEnum } from "enums/page-cta.enum";
import { PageToolPageAnalyticsSettings } from "../../../../../components/PageTool/PageToolPageSettings/PageToolPageAnalyticsSettings";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import {
  instantValuationToolSettingsValidationSchema,
  TInstantValuationToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TCreateOrUpdatePageData } from "types/page.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InstantValuationToolBasicSettings } from "./InstantValuationToolBasicSettings";
import { useHistory } from "react-router-dom";
import { TOOLS_IVT_ROUTE } from "constants/routes";

type TFieldValues = TInstantValuationToolSettingsFieldValues;

interface IProps {
  pageId: number | null;
  accountStore: UserAccountStore;
}

export const InstantValuationToolPageSettingsDetailsForm: React.FC<IProps> = observer(
  ({ accountStore, pageId }) => {
    const toast = useToast();
    const history = useHistory();
    const accountPagesStore =
      accountStore.accountPagesStore.accountIVTPagesStore;

    const page = useMemo(() => {
      if (!pageId) return;
      return accountPagesStore.accountPagesMap[pageId];
    }, [accountPagesStore, pageId]);

    const initialValues: TFieldValues = useMemo(
      () => ({
        name: page?.name || "",
        host: page?.host || "",
        color: page?.color || "",
        facebookPixelId: page?.facebookPixelId || "",
        googleAnalyticsId: page?.googleAnalyticsId || "",
        googleAdsConversionId: page?.googleAdsConversionId || "",
        googleAdsConversionLabelId: page?.googleAdsConversionLabelId || "",
        privacyPolicyLink: page?.privacyPolicyLink || "",
        gdprCheckboxEnabled: page?.gdprCheckboxEnabled || false,
        gdprCheckboxLabel: page?.gdprCheckboxLabel || "",
        gdprCheckboxDescription: page?.gdprCheckboxDescription || "",
        gdprCheckboxAcceptanceRequired:
          page?.gdprCheckboxAcceptanceRequired || false,
        photoFile: null,
        customLogoFile: null,

        presetValuationType: page?.pageIvtConfig.presetValuationType || null,
        ctaType: page?.pageIvtConfig.ctaType || PageCtaEnum.BOOKING_CALENDAR,
        customCtaLabel: page?.pageIvtConfig.customCtaLabel || "",
        customCtaUrl: page?.pageIvtConfig.customCtaUrl || "",
        etaEnabled: page?.pageIvtConfig.etaEnabled || false,
        showBathroomsAndReceptions:
          page?.pageIvtConfig.showBathroomsAndReceptions || false,
        incompleteValuationLeadEmailsEnabled:
          page?.pageIvtConfig.incompleteValuationLeadEmailsEnabled || false,
        reportDownloadEnabled:
          page?.pageIvtConfig.reportDownloadEnabled || false,
      }),
      [page]
    );

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(instantValuationToolSettingsValidationSchema),
    });

    const handleUpdatePage = useCallback(
      async (pageId: number, data: Partial<TCreateOrUpdatePageData>) => {
        await accountPagesStore.updatePage(pageId, data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description:
            "Changes will be visible next time someone uses the valuation tool.",
        });
      },
      [accountPagesStore, toast]
    );

    const handleCreatePage = useCallback(
      async (data: Partial<TCreateOrUpdatePageData>) => {
        const newPageId = await accountPagesStore.createPage(data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your new page is ready to generate leads.",
        });
        history.push(`${TOOLS_IVT_ROUTE}/${newPageId}`);
      },
      [accountPagesStore, history, toast]
    );

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        const data: Partial<TCreateOrUpdatePageData> = {
          name: values.name,
          color: values.color || null,
          facebookPixelId: values.facebookPixelId || null,
          googleAnalyticsId: values.googleAnalyticsId || null,
          googleAdsConversionId: values.googleAdsConversionId || null,
          googleAdsConversionLabelId: values.googleAdsConversionLabelId || null,
          host: values.host,
          privacyPolicyLink: values.privacyPolicyLink || null,
          gdprCheckboxEnabled: values.gdprCheckboxEnabled,
          gdprCheckboxLabel: values.gdprCheckboxLabel || null,
          gdprCheckboxDescription: values.gdprCheckboxDescription || null,
          gdprCheckboxAcceptanceRequired: values.gdprCheckboxAcceptanceRequired,
          photoFile: values.photoFile || undefined,
          customLogoFile: values.customLogoFile || undefined,
          pageIvtConfig: {
            presetValuationType: values.presetValuationType || null,
            ctaType: values.ctaType,
            customCtaLabel: values.customCtaLabel || null,
            customCtaUrl: values.customCtaUrl || null,
            etaEnabled: values.etaEnabled,
            incompleteValuationLeadEmailsEnabled:
              values.incompleteValuationLeadEmailsEnabled,
            reportDownloadEnabled: values.reportDownloadEnabled,
            showBathroomsAndReceptions: values.showBathroomsAndReceptions,
          },
        };

        try {
          if (!!page) {
            await handleUpdatePage(page.id, data);
          } else {
            await handleCreatePage(data);
          }
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [handleCreatePage, handleUpdatePage, page, toast]
    );

    return (
      <Box height={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            height={"100%"}
          >
            <Box p={"65px"} flexGrow={1} overflow={"auto"}>
              <PageToolPageBasicSettings
                page={page}
                control={control}
                watch={watch}
              />
              <Divider />
              <InstantValuationToolBasicSettings
                page={page}
                control={control}
              />
              <Divider />
              <PageToolPageAnalyticsSettings control={control} />
              <Divider />
              <InstantValuationToolCTASettings control={control} />
              <Divider />
              <PageToolPageGDPRCheckboxSettings control={control} />
              {!!page && (
                <>
                  <Divider />
                  <InstantValuationToolCodeSnippetSettings
                    page={page}
                    accountStore={accountStore}
                  />
                </>
              )}
            </Box>
            <Box
              p={5}
              width={"100%"}
              borderTop={"1px solid"}
              borderTopColor={"gray.100"}
              bg={"gray.50"}
            >
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row-reverse"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"teal"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!pageId ? "Update" : "Create"}
                </Button>
                {!!page && !isDirty && (
                  <ExternalLink
                    label={"Preview your page"}
                    href={`https://${page.host}`}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    );
  }
);
