import React, { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ApiMessageStack, FormControlV2 } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TRexConfig,
} from "types/account-integration.type";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { ToggleIntegrationActiveSwitchInput } from "../ToggleIntegrationActiveSwitchInput";
import {
  rexIntegrationConnectValidationSchema,
  TRexIntegrationConfigFieldValues,
} from "utils/validation-schemas/rex-integration-validation";

type TFieldValues = TRexIntegrationConfigFieldValues & {
  active: boolean;
};

const validationSchema = rexIntegrationConnectValidationSchema.concat(
  Yup.object().shape({
    active: Yup.boolean().required(REQUIRED_FIELD),
  })
);

interface IRexFormProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const RexIntegrationForm: FC<IRexFormProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.REX
    ] as TAccountIntegrationData<TRexConfig>;

    const initialValues = useMemo(() => {
      return {
        rexCustomerId: accountIntegration.integrationConfig.rexCustomerId,
        active: accountIntegration.active,
      };
    }, [accountIntegration]);

    const {
      handleSubmit,
      watch,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const handleUpdateIntegrationConfig = useCallback(
      async ({ active, ...rest }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<
            TRexIntegrationConfigFieldValues
          >(accountIntegration.id, {
            type: accountIntegration.type,
            active: active,
            integrationConfig: rest,
          });
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Rex integration configuration updated."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(handleUpdateIntegrationConfig)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connecting with Rex"}
            control={control}
            value={active}
            name={"active"}
          />
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Rex Customer Id"}
              type={FormControlsTypeEnum.TEXT}
              name={"rexCustomerId"}
              control={control}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
