import { Box } from "@chakra-ui/react";
import React from "react";

interface IProps {
  maxW?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const PanelsGroupLayout: React.FC<IProps> = ({
  maxW = "3xl",
  children,
}) => {
  return (
    <Box
      flex={"1 1 0%"}
      py={12}
      overflowY={"auto"}
      position={"relative"}
      height={"100%"}
    >
      <Box maxW={maxW} mx="auto" width="100%">
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <Box key={index} mb={6}>
                {child}
              </Box>
            );
          })
        ) : (
          <Box mb={6}>{children}</Box>
        )}
      </Box>
    </Box>
  );
};
