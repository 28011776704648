import { Box } from "@chakra-ui/react";
import { FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FC } from "react";
import { Control } from "react-hook-form";
import { TLeadOpenViewFieldValues as TFieldValues } from "types/openview.type";

export interface IProps {
  control: Control<TFieldValues, any>;
  isApplicant: boolean;
}

export const LeadAddressForm: FC<IProps> = ({ control, isApplicant }) => (
  <>
    <Box
      width={"full"}
      py={2}
      px={5}
      mt={2}
      mb={4}
      background={"leadpro.50"}
      fontSize={"sm"}
    >
      Lead address
    </Box>
    <Box mb={4} px={4}>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"houseNo"}
          control={control}
          label={"House number/name"}
          isOptional={isApplicant}
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"address1"}
          control={control}
          label={"Address line 1"}
          isOptional={isApplicant}
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"address2"}
          control={control}
          label={"Address line 2"}
          isOptional
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"town"}
          control={control}
          label={"Town"}
          isOptional={isApplicant}
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"county"}
          control={control}
          label={"County"}
          isOptional={isApplicant}
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"postcode"}
          control={control}
          label={"Postcode"}
          isOptional={isApplicant}
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
      <Box mb={5}>
        <FormControlV2<TFieldValues>
          name={"fullAddress"}
          control={control}
          label={"Full Address"}
          isOptional
          type={FormControlsTypeEnum.TEXT}
        />
      </Box>
    </Box>
  </>
);
