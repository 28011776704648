import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useTheme } from "@chakra-ui/react";

interface CodeSnippetProps {
  code: string;
}

export const CodeSnippet: React.FC<CodeSnippetProps> = ({ code }) => {
  const theme = useTheme();

  return (
    <SyntaxHighlighter
      language="javascript"
      customStyle={{
        padding: "20px",
        fontSize: theme.fontSizes.xs,
        whiteSpace: "break-spaces",
        backgroundColor: theme.colors.gray[100],
      }}
    >
      {code}
    </SyntaxHighlighter>
  );
};
