import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC, useEffect } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  questionnaireToolPageSettingsValidationSchema,
  TQuestionnaireToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import { PageToolThankYouPageSettings } from "../../../../../../../components/PageTool/PageToolPageSettings/PageToolThankYouPageSettings";

type TFieldValues = TQuestionnaireToolSettingsFieldValues;

interface IProps {
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsDefaultEndingPage: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore }) => {
    const { control, watch } = useForm<TFieldValues>({
      // TODO NENAD: TYPESCRIPT ISSUE, UPGRADE SHOULD FIX
      defaultValues: questionnaireToolPageSettingsEditorStore.wipPageJS as any,
      mode: "onBlur",
      resolver: yupResolver(questionnaireToolPageSettingsValidationSchema),
    });

    useEffect(() => {
      const subscription = watch(value => {
        questionnaireToolPageSettingsEditorStore.setWipPage(
          value as TFieldValues
        );
      });

      return () => {
        subscription.unsubscribe();
      };
    }, [watch, questionnaireToolPageSettingsEditorStore]);

    return (
      <AccordionItem
        marginBottom={5}
        overflow={"hidden"}
        border={"1px solid #E4E7F2"}
        borderRadius={"md"}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton p={4} _expanded={{ bg: "gray.50" }}>
              <Flex
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>Default ending page</Box>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel px={4} py={2}>
              <Box width={"100%"}>
                <Text fontSize={"lg"} mb={2}>
                  Ending page details
                </Text>
                <Text
                  fontSize={"sm"}
                  mb={8}
                  color={"leadpro.500"}
                  maxWidth={"700px"}
                >
                  This is your default ending page and will appear for all lead
                  type question sets. If you specify alternate endings in the
                  lead type modules below, these will override this default
                  ending depending on if the crtieria is met.
                </Text>
                <PageToolThankYouPageSettings
                  control={control}
                  titleField={"customThankYouPageHeader"}
                  bodyField={"customThankYouPageBody"}
                  ctaLabelField={"customThankYouPageCtaLabel"}
                  ctaUrlField={"customThankYouPageCtaUrl"}
                />
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
