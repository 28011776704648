import React, { ChangeEvent, useCallback, useMemo } from "react";
import { Avatar, Box, Switch, Text } from "@chakra-ui/react";
import {
  TLeadSourceTypeData,
  TNormalizedLeadSourceData,
} from "types/lead-source.type";
import { TPortalData } from "types/portal.type";
import Styles from "components/avatar/Avatar.module.scss";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { DEFAULT_PORTAL_IMAGE } from "constants/image-paths";

interface IProps {
  value: boolean;
  leadSourceType: TLeadSourceTypeData;
  leadSource: TNormalizedLeadSourceData;
  portal?: TPortalData;
  onConfigToggle: (leadSourceTypeId: number, isOn: boolean) => void;
  isDisabled?: boolean;
}
export const AutocallerSourceLeadTypeToggle: React.FC<IProps> = ({
  value,
  leadSourceType,
  leadSource,
  portal,
  onConfigToggle,
  isDisabled,
}) => {
  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onConfigToggle(leadSourceType.id, event.target.checked);
    },
    [leadSourceType.id, onConfigToggle]
  );

  const label = useMemo(() => {
    if (
      leadSourceType.type === LeadTypeEnum.Landlord ||
      leadSourceType.type === LeadTypeEnum.Vendor
    ) {
      switch (leadSource.source) {
        case LeadSourceEnum.rightmove:
          return `Rightmove Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        case LeadSourceEnum.rightmove_valuation:
          return "Rightmove Local Valuation Alert";
        case LeadSourceEnum.zoopla:
          return `Zoopla Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        case LeadSourceEnum.zoopla_valuation:
          return "Zoopla Appraisal Booster";
        case LeadSourceEnum.reedsrains:
          return `Reeds Rains Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        case LeadSourceEnum.reedsrains_valuation:
          return "Reeds Rains Valuation";
        case LeadSourceEnum.yourmove:
          return `Your Move Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        case LeadSourceEnum.yourmove_valuation:
          return "Your Move Valuation";
        case LeadSourceEnum.onthemarket:
          return `On The Market Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        // case LeadSourceEnum.onthemarket_valuation:
        //   return "On The Market Valuation";
        case LeadSourceEnum.gumtree:
          return `Gumtree Sales Viewing & ${getLeadTypeLabel(
            leadSourceType.type
          )} Lead`;
        case LeadSourceEnum.ivt:
          return "Instant Valuation Tool";
      }
    }

    return portal?.name || leadSource.name;
  }, [leadSource, portal, leadSourceType.type]);

  return (
    <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
      <Switch
        isChecked={value}
        onChange={handleToggle}
        isDisabled={isDisabled}
      />
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        ml={4}
      >
        <Avatar
          name={label}
          src={portal?.logoUrl || DEFAULT_PORTAL_IMAGE}
          size={"sm"}
          rounded={"sm"}
          className={Styles.squareAvatar}
          mr={4}
        />
        <Text textTransform={"capitalize"}>{label}</Text>
      </Box>
    </Box>
  );
};
