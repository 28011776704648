export enum LeadResponderEmailState {
  NONE = "none",
  RECEIVED = "received",
  IGNORED = "ignored",
  UNPROCESSABLE = "unprocessable",
  PROCESSED = "processed",
  FAILED = "failed",
}

export const LEAD_RESPONDER_EMAIL_STATE_COLOR_MAP: Record<
  LeadResponderEmailState,
  string
> = {
  [LeadResponderEmailState.NONE]: "blue",
  [LeadResponderEmailState.PROCESSED]: "green",
  [LeadResponderEmailState.FAILED]: "red",
  [LeadResponderEmailState.IGNORED]: "orange",
  [LeadResponderEmailState.UNPROCESSABLE]: "red",
  [LeadResponderEmailState.RECEIVED]: "blue",
};
