import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GraphCard } from "components/stats/GraphCard";
import { PivotStatTable } from "components/table/PivotStatTable";
import { ScoreCell } from "./cells/ScoreCell";
import { Legend } from "components/chart/Legend";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { typesColors } from "constants/colors";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  getScoreColor,
  getOfficeLeaderbordStats,
  IOfficeLeaderboardStats,
  IDownloadableLeaderboardStats,
} from "utils/office-leaderboard.utils";
import { orderBy } from "lodash";
import { ScoreHeader } from "./cells/ScoreHeader";
import { ResponseTimeTooltip } from "./tooltips/ResponseTimeTooltip";
import { OverallScoreTooltip } from "./tooltips/OverallScoreTooltip";
import { ConversionRateTooltip } from "./tooltips/ConversionRateTooltip";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/sharp-solid-svg-icons";
import { useTheme, Box } from "@chakra-ui/react";
import { TitleStatCard } from "components/stats/TitleStatCard";
import { TOfficeLeaderboardDataPoint } from "types/account-analytics.type";
import { TSerializedDateRange } from "types/date.type";
import {
  OFFICE_LEADERBOARD_CSV_HEADERS,
  TITLE_PREFIX_OFFICE_LEADERBOARD,
} from "constants/csv-exporter";
import { CSVDownloadButton } from "components";
import {
  formatCSVDateRangeTitle,
  formatLeaderboardForCSVDownload,
} from "utils/csv-exporter.utils";

interface IProps {
  data: TOfficeLeaderboardDataPoint[];
  dateRange: TSerializedDateRange;
}

export const OfficesLeaderboard: React.FC<IProps> = ({ data, dateRange }) => {
  const theme = useTheme();
  const [visibleTypes, setVisibleTypes] = useState<LeadTypeEnum[]>([]);

  const allTypes = useMemo(() => {
    const types = new Set<LeadTypeEnum>();
    data.forEach(dataPoint => {
      Object.keys(dataPoint.stats).forEach(key => {
        types.add(key as LeadTypeEnum);
      });
    });

    return types;
  }, [data]);

  useEffect(() => {
    setVisibleTypes(Array.from(allTypes));
  }, [allTypes]);

  const handleToggleType = useCallback(
    (type: LeadTypeEnum) => {
      if (visibleTypes.includes(type)) {
        setVisibleTypes(visibleTypes.filter(element => element !== type));
      } else {
        setVisibleTypes([...visibleTypes, type]);
      }
    },
    [visibleTypes]
  );

  const {
    tableData,
    bestMaxAverageResponseTimeDataPoint,
    bestConversionRateDataPoint,
    bestOverallDataPoint,
  } = useMemo(() => {
    const {
      dataPoints,
      bestMaxAverageResponseTimeDataPoint,
      bestConversionRateDataPoint,
      bestOverallDataPoint,
    } = getOfficeLeaderbordStats(data, visibleTypes);

    return {
      tableData: orderBy(
        dataPoints,
        dataPoint => dataPoint.overallScore,
        "desc"
      ),
      bestMaxAverageResponseTimeDataPoint,
      bestConversionRateDataPoint,
      bestOverallDataPoint,
    };
  }, [data, visibleTypes]);

  const dataForCSVDownload = useMemo(() => {
    return formatLeaderboardForCSVDownload(tableData);
  }, [tableData]);

  const csvFileName = useMemo(() => {
    return formatCSVDateRangeTitle(dateRange, TITLE_PREFIX_OFFICE_LEADERBOARD);
  }, [dateRange]);

  const legend = useMemo(() => {
    return (
      <Legend<LeadTypeEnum>
        onItemClick={handleToggleType}
        items={Array.from(allTypes).map((type: any) => {
          return {
            key: type,
            label: getLeadTypeLabel(type),
            color: typesColors[type as LeadTypeEnum]?.statsColor || "#718096",
            toggled: visibleTypes.includes(type),
          };
        })}
      />
    );
  }, [allTypes, handleToggleType, visibleTypes]);

  const statCards = useMemo(() => {
    return (
      <>
        {bestConversionRateDataPoint && (
          <Box flexBasis={"50%"} mr={2}>
            <TitleStatCard
              title={"Best closer"}
              titleColor={theme.colors.purple[500]}
              value={bestConversionRateDataPoint.office.name}
              description={`${bestConversionRateDataPoint.conversionRate}% conversion rate`}
            />
          </Box>
        )}
        {bestMaxAverageResponseTimeDataPoint && (
          <Box flexBasis={"50%"} ml={2}>
            <TitleStatCard
              title={"Most responsive"}
              titleColor={theme.colors.green[500]}
              value={bestMaxAverageResponseTimeDataPoint.office.name}
              description={`Response speed score of ${bestMaxAverageResponseTimeDataPoint.averageResponseTime}`}
            />
          </Box>
        )}
      </>
    );
  }, [
    bestConversionRateDataPoint,
    bestMaxAverageResponseTimeDataPoint,
    theme.colors.green,
    theme.colors.purple,
  ]);

  const table = useMemo(() => {
    return (
      <PivotStatTable<IOfficeLeaderboardStats>
        data={tableData}
        defaultSort={[
          {
            id: "rating",
            desc: false,
          },
        ]}
        columns={[
          {
            id: "star",
            Header: "",
            Cell: ({ row }: any) => {
              return row.original.office.id ===
                bestOverallDataPoint?.office.id ? (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faStar}
                    color={theme.colors.yellow[500]}
                    fontSize={18}
                  />
                </Box>
              ) : null;
            },
            width: 30,
          },
          {
            id: "rating",
            Header: (
              <Box display={"flex"} height={"100%"} alignItems={"center"}>
                <div>#</div>
              </Box>
            ),
            accessor: (row, rowIndex) => rowIndex,
            Cell: ({ row }: { row: Row<IOfficeLeaderboardStats> }) => {
              return <>{row.index + 1}</>;
            },
            width: 40,
          },
          {
            id: "officeName",
            Header: (
              <Box display={"flex"} height={"100%"} alignItems={"center"}>
                <div>Office</div>
              </Box>
            ),
            accessor: row => row.office.name,
            Cell: ({ row }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <div>{row.original.office.name}</div>
                </Box>
              );
            },
          },
          {
            Header: (
              <ScoreHeader
                title={"Response time"}
                tooltip={<ResponseTimeTooltip />}
              />
            ),
            accessor: "averageResponseTime",
            Cell: ({ row }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <ScoreCell
                    value={row.original.averageResponseTime}
                    percent={row.original.averageResponseTime}
                    color={getScoreColor(row.original.averageResponseTime)}
                  />
                </Box>
              );
            },
          },
          {
            Header: (
              <ScoreHeader
                title={"Business won"}
                tooltip={<ConversionRateTooltip />}
              />
            ),
            accessor: "conversionRate",
            Cell: ({ row }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <ScoreCell
                    value={row.original.conversionRate + "%"}
                    percent={row.original.conversionRate}
                    color={getScoreColor(row.original.conversionRate)}
                  />
                </Box>
              );
            },
          },
          {
            Header: (
              <ScoreHeader
                title={"Overall score"}
                tooltip={<OverallScoreTooltip />}
              />
            ),
            accessor: "overallScore",
            Cell: ({ row }: any) => {
              return (
                <Box display={"flex"} height={"100%"} alignItems={"center"}>
                  <ScoreCell
                    value={row.original.overallScore}
                    percent={row.original.overallScore}
                    color={getScoreColor(row.original.overallScore)}
                  />
                </Box>
              );
            },
          },
        ]}
      />
    );
  }, [bestOverallDataPoint, tableData, theme.colors.yellow]);

  return (
    <GraphCard
      title={`Offices leaderboard`}
      action={
        <CSVDownloadButton<IDownloadableLeaderboardStats>
          data={dataForCSVDownload}
          options={{
            filename: csvFileName,
            useKeysAsHeaders: false,
            headers: OFFICE_LEADERBOARD_CSV_HEADERS,
          }}
        />
      }
    >
      <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
        {legend}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        mb={5}
      >
        {statCards}
      </Box>
      {table}
    </GraphCard>
  );
};
