import React, { useEffect, useMemo, useRef } from "react";
import "@typeform/embed/build/css/popup.css";
import { Box } from "@chakra-ui/react";
import { PopupOptions, Popup, createPopup } from "@typeform/embed";

const DEFAULT_TYPEFORM_OPTIONS: PopupOptions = {
  hideFooter: true,
  hideHeaders: true,
  size: 100,
};

interface ITypeFormWidgetProps {
  typeFormId: string;
  options?: PopupOptions;
  children: React.ReactNode;
}

export const TypeFormWidget: React.FC<ITypeFormWidgetProps> = ({
  typeFormId,
  options,
  children,
}) => {
  const popUp = useRef<Popup>();

  const updatedOptions = useMemo(() => {
    return {
      ...DEFAULT_TYPEFORM_OPTIONS,
      ...options,
    };
  }, [options]);

  useEffect(() => {
    popUp.current = createPopup(typeFormId, updatedOptions);
  }, [typeFormId, updatedOptions]);

  const openTypeForm = () => {
    popUp.current?.open();
  };

  return <Box onClick={openTypeForm}>{children}</Box>;
};
