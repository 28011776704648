import React, { FC, useCallback, useState } from "react";

export const WizardContext = React.createContext<
  | {
      currentStep: number;
      goToNextStep: () => void;
      goToPreviousStep: () => void;
      totalNumberOfSteps: number;
      showStepProgress?: boolean;
    }
  | undefined
>(undefined);

interface IWizardProps {
  children: React.ReactNode[];
  showStepProgress?: boolean;
}

export const Wizard: FC<IWizardProps> = ({ children, showStepProgress }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const goToNextStep = useCallback(() => {
    if (currentStep >= children.length - 1) return;
    setCurrentStep(currentStep + 1);
  }, [currentStep, children.length, setCurrentStep]);

  const goToPreviousStep = useCallback(() => {
    if (currentStep <= 0) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  return (
    <WizardContext.Provider
      value={{
        currentStep,
        goToNextStep,
        goToPreviousStep,
        totalNumberOfSteps: children.length,
        showStepProgress,
      }}
    >
      {children[currentStep]}
    </WizardContext.Provider>
  );
};
