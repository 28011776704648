import { makeAutoObservable } from "mobx";
import { AccountDevelopersWebhookStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhook.store";
import {
  TAccountDeveloperWebhookEventConfig,
  TDeveloperWebhookEventConfigData,
} from "types/account-developers.type";
import { WebhookEventTypes } from "enums/account-developer-webhook-events.enum";
import { isEqual } from "lodash";
import { webhookEventConfigTableColumnDef } from "./developersWebhookEventConfigTableColumnDef";

export type TFieldValues = {
  name: string;
  webhookUrl: string;
};

export class DeveloperConfigWebhookDetailsLocalStore {
  private accountWebhookStore: AccountDevelopersWebhookStore;
  public currentWebhookConfiguration: TAccountDeveloperWebhookEventConfig;

  constructor(accountWebhookStore: AccountDevelopersWebhookStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountWebhookStore = accountWebhookStore;
    this.currentWebhookConfiguration = this.accountWebhookStore.webhookConfig.eventConfiguration;
  }

  get isInternal() {
    return this.webhookData.isInternal;
  }

  get webhookData() {
    return this.accountWebhookStore.webhookConfig;
  }

  get initialFormValues(): TFieldValues {
    return {
      name: this.webhookData.name,
      webhookUrl: this.webhookData.webhookUrl,
    };
  }

  get eventConfiguration() {
    return this.webhookData.eventConfiguration;
  }

  public get columnDef() {
    return webhookEventConfigTableColumnDef(this.handleToggleWebhookEvent);
  }

  get eventConfigurationTableData(): TDeveloperWebhookEventConfigData[] {
    return Object.entries(this.currentWebhookConfiguration)
      .sort(
        ([a], [b]) =>
          this.eventConfigurationOrder.indexOf(a) -
          this.eventConfigurationOrder.indexOf(b)
      )
      .map(([key, value]) => ({
        id: key as WebhookEventTypes,
        value,
      }));
  }

  get isEventConfigDirty() {
    return !isEqual(this.currentWebhookConfiguration, this.eventConfiguration);
  }

  get eventConfigurationOrder(): string[] {
    return [
      WebhookEventTypes.LEAD_CREATED,
      WebhookEventTypes.LEAD_UPDATED,
      WebhookEventTypes.LEAD_SYNCED,
      WebhookEventTypes.LEAD_NOTE_ADDED,
      WebhookEventTypes.LEAD_QUESTIONNAIRE_RESPONSE_ADDED,
      WebhookEventTypes.LEAD_AVAILABILITY_ADDED,
    ];
  }

  private handleToggleWebhookEvent(id: WebhookEventTypes, value: boolean) {
    this.currentWebhookConfiguration = {
      ...this.currentWebhookConfiguration,
      [id]: value,
    };
  }

  public resetEventConfig() {
    this.currentWebhookConfiguration = this.eventConfiguration;
  }

  public async onRegenerateKey() {
    await this.accountWebhookStore.regenerateDeveloperWebhookSigningSecret();
  }

  public async handleUpdateWebhook(data: TFieldValues) {
    return this.accountWebhookStore.updateWebhook({
      ...data,
      ...(!this.isInternal && {
        eventConfiguration: this.currentWebhookConfiguration,
      }),
    });
  }
}
