import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import { OfficesLeaderboard } from "./OfficesLeaderboard/OfficesLeaderboard";
import { OfficeLeadsStatsTable } from "./OfficeLeadsStatsTable";
import { CardForLeadsByOption } from "./CardForLeadsByOption";
import { LeadByOption } from "enums/lead-by-option.enum";
import { GraphCard } from "components/stats/GraphCard";
import { Chart, ChartType } from "components/chart";
import { formatDateForChart } from "utils/date.utils";
import { StatCard } from "components/stats/StatCard";
import React, { FC } from "react";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  mapLeadSourceColorsToKeys,
  mapLeadTypeColorsToKeys,
} from "utils/account-stats.utils";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { CSVDownloadButton } from "components";
import { TLeadStatsGroupedByDate } from "types/account-analytics.type";

interface IProps {
  analyticsStore: AccountAnalyticsStore;
}

export const LeaderboardsContent: FC<IProps> = observer(
  ({ analyticsStore }) => {
    const {
      accountAnalyticsFiltersStore: { dateRange },
      accountAnalyticsLeaderboardStore: {
        statsDataFilteredByOffices,
        leaderboardDataFilteredByOfficesArray,
        leadsByOfficeDataFilteredByOfficesArray,
        totalLeadContacts,
      },
    } = analyticsStore;

    const {
      totalLeads,
      leadsByDay,
      leadsByType,
      leadsBySource,
      leadsByTypeByDay,
      leadsBySourceByDay,
      leadsBySourceCount,
      leadsByTypeCount,
    } = statsDataFilteredByOffices!;

    return (
      <>
        <Box mb={6}>
          <OfficesLeaderboard
            data={leaderboardDataFilteredByOfficesArray}
            dateRange={dateRange}
          />
        </Box>
        <Box mb={6}>
          <OfficeLeadsStatsTable
            data={leadsByOfficeDataFilteredByOfficesArray}
            dateRange={dateRange}
          />
        </Box>
        <Box mb={6}>
          <CardForLeadsByOption<LeadTypeEnum>
            statType={LeadByOption.TYPE}
            leadsInRangeCount={leadsByTypeCount}
            colorDefinitionFunction={mapLeadTypeColorsToKeys}
            leadsByOption={leadsByType}
            leadsByOptionByDay={leadsByTypeByDay}
          />
        </Box>
        <Box mb={6}>
          <CardForLeadsByOption<LeadSourceEnum>
            statType={LeadByOption.SOURCE}
            leadsInRangeCount={leadsBySourceCount}
            colorDefinitionFunction={mapLeadSourceColorsToKeys}
            leadsByOption={leadsBySource}
            leadsByOptionByDay={leadsBySourceByDay}
          />
        </Box>
        <Box mb={6}>
          <GraphCard
            title="Leads by day"
            action={
              <CSVDownloadButton<TLeadStatsGroupedByDate<number>>
                data={leadsByDay}
                options={{
                  filename: `Leads by day`,
                }}
              />
            }
          >
            <Chart
              type={ChartType.Line}
              series={[leadsByDay.map(i => i.leads)]}
              labels={leadsByDay.map(i => formatDateForChart(i.date))}
              colors={["#0066ff"]}
              seriesNames={["Total"]}
            />
          </GraphCard>
        </Box>
        <Box display={"flex"} mb={4} mx={-2}>
          <Box px={2} width={"50%"}>
            <StatCard title="Total leads" stat={totalLeads} />
          </Box>
          <Box px={2} width={"50%"}>
            <StatCard title="Total contacts" stat={totalLeadContacts} />
          </Box>
        </Box>
      </>
    );
  }
);
