import { observer } from "mobx-react";
import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { AccountAnalyticsLeadAttributionStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttribution.store";
import { SUPPORTED_LEAD_ATTRIBUTION_GROUPS } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalyticsLeadAttributionStore/AccountAnalyticsLeadAttributionFilters.store";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { leadAttributionGroupColorMap } from "constants/account-lead-attribution";

interface IProps {
  leadAttributionType: LeadAttributionTypesEnum;
  accountAnalyticsLeadAttributionStore: AccountAnalyticsLeadAttributionStore;
}

export const LeadAttributionReportSegmentDetailsFilter: FC<IProps> = observer(
  ({ leadAttributionType, accountAnalyticsLeadAttributionStore }) => {
    const {
      leadAttributionGroupsMapByType,
      toggleLeadAttributionGroup,
    } = accountAnalyticsLeadAttributionStore.accountAnalyticsLeadAttributionFiltersStore;

    const activeGroups = leadAttributionGroupsMapByType[leadAttributionType];

    return (
      <Flex gap={2} flexWrap={"wrap"}>
        {SUPPORTED_LEAD_ATTRIBUTION_GROUPS.map(group => (
          <Box
            key={group}
            opacity={activeGroups.includes(group) ? 1 : 0.5}
            color={leadAttributionGroupColorMap[group]}
            borderColor={leadAttributionGroupColorMap[group]}
            rounded={"full"}
            border={"1px solid"}
            px={4}
            py={1}
            cursor={"pointer"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            onClick={() =>
              toggleLeadAttributionGroup(leadAttributionType, group)
            }
          >
            <Box
              width={"10px"}
              height={"10px"}
              borderRadius={"full"}
              background={leadAttributionGroupColorMap[group]}
            ></Box>
            {group}
          </Box>
        ))}
      </Flex>
    );
  }
);
