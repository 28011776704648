import * as Yup from "yup";
import { TestContext } from "yup/lib/util/createValidation";
import { count, messageLength } from "sms-counter-npm";
import { TTemplateMergeTag } from "types/template-merge-tag.type";

export const QUESTIONNAIRE_URL_MERGE_TAG = "{{questionnaire.url}}";
export const ACCOUNT_PRIMARY_COLOR_MERGE_TAG = "{{account.primary_color}}";
export const ACCOUNT_PRIMARY_CONTRAST_COLOR_MERGE_TAG =
  "{{account.primary_contrast_color}}";
export const ACCOUNT_LOGO_URL_MERGE_TAG = "{{account.logo_url}}";

export const UI_MERGE_TAGS = [
  ACCOUNT_PRIMARY_COLOR_MERGE_TAG,
  ACCOUNT_PRIMARY_CONTRAST_COLOR_MERGE_TAG,
  ACCOUNT_LOGO_URL_MERGE_TAG,
];

export const getEmailTemplateEditorPremadeBlocks = (
  uiMergeTagsMap: Record<string, TTemplateMergeTag>
) => {
  const primaryColor =
    uiMergeTagsMap[ACCOUNT_PRIMARY_COLOR_MERGE_TAG]?.previewValue;
  const primaryContrastColor =
    uiMergeTagsMap[ACCOUNT_PRIMARY_CONTRAST_COLOR_MERGE_TAG]?.previewValue;
  const logoUrl = uiMergeTagsMap[ACCOUNT_LOGO_URL_MERGE_TAG]?.previewValue;

  return {
    headers: [
      {
        name: "Account banner",
        definition: [
          {
            tagName: "mj-section",
            children: [
              {
                tagName: "mj-column",
                children: [
                  {
                    tagName: "mj-image",
                    attributes: {
                      alt: "",
                      src: logoUrl,
                      href: "",
                      padding: "0px 0px 0px 0px",
                      containerWidth: 600,
                      width: 200,
                    },
                  },
                ],
                attributes: {
                  width: "100%",
                  "vertical-align": "top",
                },
              },
            ],
            attributes: {
              padding: "9px 0px 9px 0px",
              "full-width": false,
            },
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            backgroundColor: "",
            backgroundImage: "",
          },
        ],
      },
    ],
    content: [
      {
        name: "Valuation section",
        definition: [
          {
            tagName: "mj-section",
            children: [
              {
                tagName: "mj-column",
                children: [
                  {
                    content: `
                      <table style="width: 100%;text-align: center;">
                        <tr style="font-family: Lato, Tahoma, sans-serif; font-size: 14px;">
                          <th style="width: 33%;">Min. Value</th>
                          <th style="width: 33%;">Average Value</th>
                          <th style="width: 33%;">Max Value</th>
                        </tr>
                        <tr style="font-family: Lato, Tahoma, sans-serif; font-size: 18px;">
                          <td>
                            {{#isHelper lead.type "vendor"}}
                              {{currencyHelper lead.minimum_sale_estimation}}
                            {{/isHelper}}
                            {{#isHelper lead.type "landlord"}}
                              {{currencyHelper lead.minimum_rent_estimation}}
                            {{/isHelper}}
                            </td>
                          <td>
                            {{#isHelper lead.type "vendor"}}
                              {{currencyHelper lead.average_sale_estimation}}
                            {{/isHelper}}
                            {{#isHelper lead.type "landlord"}}
                                {{currencyHelper lead.average_rent_estimation}}
                            {{/isHelper}}
                          </td>
                          <td>
                            {{#isHelper lead.type "vendor"}}
                              {{currencyHelper lead.maximum_sale_estimation}}
                            {{/isHelper}}
                            {{#isHelper lead.type "landlord"}}
                              {{currencyHelper lead.maximum_rent_estimation}}
                            {{/isHelper}}
                          </td>
                        </tr>
                      </table>
                      `,
                    tagName: "mj-raw",
                    attributes: {
                      containerWidth: 600,
                    },
                  },
                ],
                attributes: {
                  width: "100%",
                  "vertical-align": "top",
                },
              },
            ],
            attributes: {
              padding: "9px 0px 9px 0px",
              "full-width": false,
            },
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            backgroundColor: "",
            backgroundImage: "",
          },
        ],
      },
      {
        name: "IVT Valuation Report button",
        definition: [
          {
            tagName: "mj-section",
            children: [
              {
                tagName: "mj-column",
                children: [
                  {
                    content: `
                     {{#if (isDefinedHelper ivt.report_url)}}
                       <button style="
                          padding: 18px 26px;
                          color: ${primaryContrastColor};
                          background-color: ${primaryColor};
                          font-size: 13px;
                          font-family: Ubuntu, Helvetica, Arial, sans-serif;
                          border-radius: 2px;
                          text-align: center;
                          width: 100%;
                          border: none;
                          cursor: pointer;
                  ">
                    <a href="{{ivt.report_url}}" style="
                      text-decoration: none;
                      color: inherit;
                    ">Download Valuation Report</a>
                  </button>
                        {{/if}}
                    `,
                    tagName: "mj-raw",
                    attributes: {
                      containerWidth: 600,
                    },
                  },
                ],
                attributes: {
                  width: "95%",
                  "vertical-align": "top",
                },
              },
            ],
            attributes: {
              "full-width": false,
            },
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            backgroundColor: "",
            backgroundImage: "",
          },
        ],
      },
      {
        name: "Questionnaire button",
        definition: [
          {
            tagName: "mj-section",
            children: [
              {
                tagName: "mj-column",
                children: [
                  {
                    content: "Complete Your Enquiry",
                    tagName: "mj-button",
                    attributes: {
                      href: QUESTIONNAIRE_URL_MERGE_TAG,
                      align: "center",
                      color: primaryContrastColor,
                      padding: "10px 15px 10px 15px",
                      "font-size": "13px",
                      "font-family":
                        "Ubuntu, Helvetica, Arial, sans-serif, Helvetica, Arial, sans-serif",
                      "border-radius": "2px",
                      "inner-padding": "18px 26px 18px 26px",
                      containerWidth: 600,
                      "background-color": primaryColor,
                      width: "100%",
                    },
                  },
                ],
                attributes: {
                  width: "100%",
                  "vertical-align": "top",
                },
              },
            ],
            attributes: {
              padding: "9px 0px 9px 0px",
              "full-width": false,
            },
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            backgroundColor: "",
            backgroundImage: "",
          },
        ],
      },
    ],
    footers: [
      {
        name: "Office signature",
        definition: [
          {
            tagName: "mj-section",
            children: [
              {
                tagName: "mj-column",
                children: [
                  {
                    content:
                      '<p><span style="font-family: Lato, Tahoma, sans-serif; font-size: 34px;"><strong>{{account.name}} - {{office.name}}</strong></span></p>' +
                      '<p><strong><span style="font-family: Lato, Tahoma, sans-serif; font-size: 34px;">T: </span></strong> <span style="font-family: Lato, Tahoma, sans-serif; font-size: 14px;"> {{#isHelper lead.type "sale"}}{{office.sales_phone_number}}{{/isHelper}} {{#isHelper lead.type "let"}}{{office.lettings_phone_number}}{{/isHelper}} {{#isHelper lead.type "vendor"}}{{office.vendor_phone_number}}{{/isHelper}} {{#isHelper lead.type "landlord"}}{{office.landlord_phone_number}}{{/isHelper}} </span></p>' +
                      '<p><strong><span style="font-family: Lato, Tahoma, sans-serif; font-size: 34px;">E: </span></strong> <span style="font-family: Lato, Tahoma, sans-serif; font-size: 14px;"> {{#isHelper lead.type "sale"}}{{office.sales_email}}{{/isHelper}} {{#isHelper lead.type "let"}}{{office.lettings_email}}{{/isHelper}} {{#isHelper lead.type "vendor"}}{{office.vendor_email}}{{/isHelper}} {{#isHelper lead.type "landlord"}}{{office.landlord_email}}{{/isHelper}} </span></p>' +
                      '<p><strong><span style="font-family: Lato, Tahoma, sans-serif; font-size: 34px;">W: </span></strong> <span style="font-family: Lato, Tahoma, sans-serif; font-size: 14px;"> {{account.website}} </span></p>',
                    tagName: "mj-text",
                    attributes: {
                      align: "left",
                      padding: "0px 15px 0px 15px",
                      "line-height": 1.2,
                      containerWidth: 600,
                    },
                  },
                ],
                attributes: {
                  width: "100%",
                  "vertical-align": "top",
                },
              },
            ],
            attributes: {
              padding: "9px 0px 9px 0px",
              "full-width": false,
            },
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            backgroundColor: "",
            backgroundImage: "",
          },
        ],
      },
    ],
  };
};

export const isValidSmsTemplateContent = (mergeTags: TTemplateMergeTag[]) => (
  value?: string,
  context?: TestContext
) => {
  if (!value) return true;

  let dummyContent = value;
  mergeTags.forEach(mergeTag => {
    if (!!mergeTag.mergeTagMaxLength) {
      dummyContent = dummyContent.replaceAll(
        mergeTag.value,
        "x".repeat(mergeTag.mergeTagMaxLength)
      );
    }
  });

  const smsCountData = count(dummyContent);

  if (smsCountData.messages === 1) {
    return true;
  }

  return new Yup.ValidationError(
    `Content must be at most ${
      messageLength[smsCountData.encoding]
    } characters.`,
    value,
    context?.path
  );
};

export const replaceUIMergeTagsWithAccountValues = (
  design: object,
  mergeTags: TTemplateMergeTag[]
) => {
  let stringifiedDesign = JSON.stringify(design);
  mergeTags.forEach(mergeTag => {
    if (mergeTag.previewValue) {
      stringifiedDesign = stringifiedDesign.replaceAll(
        mergeTag.value,
        mergeTag.previewValue
      );
    }
  });

  return JSON.parse(stringifiedDesign);
};

export const replaceAccountValuesWithUIMergeTags = (
  design: object,
  mergeTags: TTemplateMergeTag[]
) => {
  let stringifiedDesign = JSON.stringify(design);
  mergeTags.forEach(mergeTag => {
    if (mergeTag.previewValue) {
      stringifiedDesign = stringifiedDesign.replaceAll(
        mergeTag.previewValue,
        mergeTag.value
      );
    }
  });

  return JSON.parse(stringifiedDesign);
};
