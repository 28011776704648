import React, { FC, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { officePortalActivityTableColumnDef } from "./officePortalActivityTableColumnDef";
import { Box } from "@chakra-ui/react";
import { AccountOfficesPortalLinkStatusWarning } from "./AccountOfficesPortalLinkStatusWarning";
import { CSVDownloadButton, TableV2 } from "components";
import { IAccountOfficePortalActivity } from "types/account-portal-link-status-per-office.type";
import {
  flattenAccountOfficePortalActivity,
  TFlatOfficeRow,
} from "utils/csv-exporter.utils";
import { ACCOUNT_OFFICE_ACTIVITY_TITLE } from "constants/csv-exporter";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountOfficesPortalLinkStatus: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    const accountOfficesPortalActivity =
      accountStore.accountOfficesStore.accountOfficesPortalActivityArray;
    const problematicOfficeActivity =
      accountStore.accountOfficesStore.problematicOfficeActivity;
    const portalsMap = accountStore.portalsMap;
    const leadSourcesMap = accountStore.accountLeadSourcesStore.leadSourcesMap;

    const columnsDef = useMemo(() => {
      return officePortalActivityTableColumnDef(portalsMap, leadSourcesMap);
    }, [portalsMap, leadSourcesMap]);

    useEffect(() => {
      const fetchData = async () => {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        try {
          await accountStore.accountOfficesStore.fetchAccountOfficesPortalActivity();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountStore]);

    const flatAccountOfficesPortalActivity = useMemo(() => {
      return flattenAccountOfficePortalActivity(
        accountOfficesPortalActivity,
        leadSourcesMap
      );
    }, [accountOfficesPortalActivity, leadSourcesMap]);

    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          {!!problematicOfficeActivity.length && (
            <AccountOfficesPortalLinkStatusWarning
              accountStore={accountStore}
              problematicOfficeActivity={problematicOfficeActivity}
            />
          )}
        </Box>
        <Box>
          <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
            <CSVDownloadButton<TFlatOfficeRow>
              data={flatAccountOfficesPortalActivity}
              options={{
                filename: ACCOUNT_OFFICE_ACTIVITY_TITLE,
              }}
            />
          </Box>
          <TableV2<IAccountOfficePortalActivity>
            loadingStatus={loadingStatus}
            globalFilterInputPlaceholder={"Search offices by name"}
            dataSource={accountOfficesPortalActivity}
            columns={columnsDef}
            pageSize={DEFAULT_PAGE_SIZE}
            striped={true}
          />
        </Box>
      </Box>
    );
  }
);
