import { AccountIntegrationsStore } from "./AccountIntegrations/AccountIntegrations.store";
import { AccountOfficesStore } from "./AccountOffices/AccountOffices.store";
import { AccountPagesStore } from "./AccountPages/AccountPages.store";
import { AccountEmailNotificationsStore } from "./AccountEmailNotifications.store";
import { AccountUsersStore } from "./AccountUsers.store";
import { TUserAccountData } from "types/user-account.type";
import { TAccountIVTConfigData, TRawAccountData } from "types/account.type";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountApi } from "api/account.api";
import { AccountLeadsStore } from "./AccountLeads/AccountLeads.store";
import { AccountPropertiesStore } from "./AccountProperties/AccountProperties.store";
import { AppStore } from "store/App.store";
import { TPortalData } from "types/portal.type";
import { keyBy } from "lodash";
import { AccountAnalyticsStore } from "./AccountAnalytics/AccountAnalytics.store";
import { UserRoleEnum } from "enums/user-role.enum";
import { ACCOUNT_SETUP_STEPS_ORDER } from "constants/account-setup-steps-order";
import { AccountDevelopersStore } from "./AccountDevelopers/AccountDevelopers.store";
import { AccountBillingStore } from "./AccountBilling.store";
import { AccountPersistentFiltersStore } from "./AccountPersistentFilters.store";
import { AccountEmailTemplatesStore } from "./AccountEmailTemplates/AccountEmailTemplates.store";
import { AccountOfficeGroupsStore } from "./AccountOffices/AccountOfficeGroups.store";
import { AccountLeadResponderStore } from "./AccountLeadResponder/AccountLeadResponder.store";
import { AccountSmsTemplatesStore } from "./AccountSmsTemplates.store";
import { AccountLeadSourcesStore } from "./AccountLeadSources.store";
import { AccountLeadStatusesStore } from "./AccountLeadStatuses.store";
import { AccountAutocallerStore } from "./AccountAutocaller/AccountAutocaller.store";
import { AccountTypeformFormStore } from "./AccountTypeformForm.store";
import { AccountExportsStore } from "./AccountExportStore/AccountExportsStore.store";
import { AccountLeadProgressionStore } from "./AccountLeadProgression/AccountLeadProgression.store";
import { AccountJourneysStore } from "./AccountJourneys.store";

const accountApi = new AccountApi();

export class UserAccountStore {
  private readonly root: AppStore;
  private readonly portals: IObservableArray<TPortalData>;
  private accountData: TUserAccountData;
  public accountOfficesStore: AccountOfficesStore;
  public accountUsersStore: AccountUsersStore;
  public accountLeadSourcesStore: AccountLeadSourcesStore;
  public accountPagesStore: AccountPagesStore;
  public accountIntegrationsStore: AccountIntegrationsStore;
  public accountSettingsStore: AccountEmailNotificationsStore;
  public accountAutocallerStore: AccountAutocallerStore;
  public accountLeadsStore: AccountLeadsStore;
  public accountPropertiesStore: AccountPropertiesStore;
  public accountAnalyticsStore: AccountAnalyticsStore;
  public accountDevelopersStore: AccountDevelopersStore;
  public accountBillingStore: AccountBillingStore;
  public accountPersistentFiltersStore: AccountPersistentFiltersStore;
  public accountEmailTemplatesStore: AccountEmailTemplatesStore;
  public accountSmsTemplatesStore: AccountSmsTemplatesStore;
  public accountOfficeGroupsStore: AccountOfficeGroupsStore;
  public accountLeadStatusesStore: AccountLeadStatusesStore;
  public leadResponderStore: AccountLeadResponderStore;
  public accountExportsStore: AccountExportsStore;
  public accountTypeFormFormStore: AccountTypeformFormStore;
  public accountLeadProgressionStore: AccountLeadProgressionStore;
  public accountJourneysStore: AccountJourneysStore;

  constructor(root: AppStore, data: TUserAccountData) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.portals = observable.array<TPortalData>();
    this.accountOfficesStore = new AccountOfficesStore(root, data.id);
    this.accountUsersStore = new AccountUsersStore(root, data.id);
    this.accountLeadSourcesStore = new AccountLeadSourcesStore(root, data.id);
    this.accountPagesStore = new AccountPagesStore(data.id);
    this.accountIntegrationsStore = new AccountIntegrationsStore(root, data.id);
    this.accountSettingsStore = new AccountEmailNotificationsStore(
      root,
      data.id
    );
    this.accountAutocallerStore = new AccountAutocallerStore(root, data.id);
    this.accountLeadsStore = new AccountLeadsStore(root, data.id, false, false);
    this.accountPropertiesStore = new AccountPropertiesStore(root, data.id);
    this.accountAnalyticsStore = new AccountAnalyticsStore(root, data.id);
    this.accountDevelopersStore = new AccountDevelopersStore(root, data.id);
    this.accountBillingStore = new AccountBillingStore(root, data.id);
    this.accountPersistentFiltersStore = new AccountPersistentFiltersStore(
      data.id
    );
    this.accountLeadStatusesStore = new AccountLeadStatusesStore(root, data.id);
    this.accountOfficeGroupsStore = new AccountOfficeGroupsStore(root, data.id);
    this.accountEmailTemplatesStore = new AccountEmailTemplatesStore(
      root,
      data.id
    );
    this.accountExportsStore = new AccountExportsStore(root, data.id);
    this.accountSmsTemplatesStore = new AccountSmsTemplatesStore(data.id);
    this.leadResponderStore = new AccountLeadResponderStore(root, data.id);
    this.accountTypeFormFormStore = new AccountTypeformFormStore(root, data.id);
    this.accountLeadProgressionStore = new AccountLeadProgressionStore(
      root,
      data.id
    );
    this.accountJourneysStore = new AccountJourneysStore(data.id);
    this.accountData = data;
  }

  get account() {
    return this.accountData;
  }

  get isCurrentUserAgent() {
    return this.accountData.roleId === UserRoleEnum.AGENT;
  }

  get accountContactEmails() {
    const emailsString = this.accountData.contactEmails;
    if (!emailsString) {
      return [];
    }
    return emailsString.split(",").map(s => s.trim());
  }

  get portalsAsSelectOptions() {
    return this.portals.map(portal => ({
      value: portal.id,
      label: portal.name,
      data: portal,
    }));
  }

  get portalsArray() {
    return this.portals.slice();
  }

  get portalsMap() {
    return keyBy(this.portalsArray, portal => portal.id);
  }

  get portalsMapByName() {
    return keyBy(this.portalsArray, portal => portal.name.toLowerCase());
  }

  get shouldOpenAccountSetup() {
    return (
      this.accountData.roleId === UserRoleEnum.ADMIN &&
      this.accountData.setupStepsCompleted?.length <
        ACCOUNT_SETUP_STEPS_ORDER.length
    );
  }

  private setPortals(portals: TPortalData[]) {
    this.portals.replace(portals);
  }

  private upsert(updates: Partial<TUserAccountData>) {
    this.accountData = {
      ...this.accountData,
      ...updates,
    };
  }

  public upsertLogo(logoUrl: string | null) {
    this.accountData.logoUrl = logoUrl;
  }

  public deepUpsert(updates: Partial<TRawAccountData>) {
    const {
      integrations,
      accountBilling,
      accountIntegrations,
      accountPortals,
      ...rest
    } = updates;
    this.upsert(rest);

    if (!!integrations) {
      this.accountIntegrationsStore.setAccountIntegrations(integrations);
    }

    if (!!accountBilling) {
      this.accountBillingStore.setBillingData(accountBilling);
    }
  }

  public async update(accountData: Partial<TUserAccountData>) {
    const updatedAccount = await accountApi.updateAccount(
      this.accountData.id,
      accountData
    );

    this.deepUpsert(updatedAccount);
  }

  public async fetchDetails() {
    const data = await accountApi.fetchUserAccount(this.accountData.id);
    this.deepUpsert(data);
  }

  public async fetchAccountIVTConfig() {
    const data = await accountApi.fetchAccountIVTConfig(this.accountData.id);
    this.deepUpsert({ accountIVTConfig: data });
  }

  public async updateAccountIVTConfig(config: TAccountIVTConfigData) {
    const data = await accountApi.updateAccountIVTConfig(
      this.accountData.id,
      config
    );
    this.deepUpsert({ accountIVTConfig: data });
  }

  public async fetchPortals() {
    const portals = await accountApi.fetchPortals(this.accountData.id);
    this.setPortals(portals);
  }
}
