import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  rightmoveIntegrationConnectValidationSchema,
  rightmoveIntegrationInitialValues,
  TRightmoveIntegrationConfigFieldValues,
} from "utils/validation-schemas/rightmove-integration.validation";

type TFieldValues = TRightmoveIntegrationConfigFieldValues;

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectRightmoveForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: rightmoveIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(rightmoveIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<TFieldValues>(
            {
              type: AccountIntegrationTypeEnum.RIGHTMOVE,
              active: true,
              integrationConfig,
            }
          );

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Rightmove successfully connected."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Brand ID"}
              type={FormControlsTypeEnum.TEXT}
              name={"brandId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Network Id"}
              type={FormControlsTypeEnum.TEXT}
              name={"networkId"}
              control={control}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Certificate"}
              type={FormControlsTypeEnum.CERTIFICATE_UPLOAD_INPUT}
              name={"certificate"}
              control={control}
              additionalProps={{
                accept: ".pem",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              label={"Passphrase"}
              type={FormControlsTypeEnum.TEXT}
              name={"passphrase"}
              control={control}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel>Test mode</FormControlLabel>
            <FormControlV2<TFieldValues>
              name={"testMode"}
              control={control}
              type={FormControlsTypeEnum.SWITCH}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
