import React, { useMemo } from "react";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Box, Button, useClipboard } from "@chakra-ui/react";
import { Message } from "components";
import { generateEmailWithOfficePortalLinkIssues } from "utils/office-activity.utils";
import { IAccountOfficePortalActivity } from "types/account-portal-link-status-per-office.type";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { ButtonLink } from "components";

interface IProps {
  accountStore: UserAccountStore;
  problematicOfficeActivity: IAccountOfficePortalActivity[];
}

export const AccountOfficesPortalLinkStatusWarning: React.FC<IProps> = observer(
  ({ accountStore, problematicOfficeActivity }) => {
    const accountData = accountStore.account;
    const portalOptions = accountStore.portalsAsSelectOptions;

    const issuesEmail = useMemo(() => {
      return generateEmailWithOfficePortalLinkIssues(
        problematicOfficeActivity,
        accountData,
        portalOptions
      );
    }, [portalOptions, problematicOfficeActivity, accountData]);

    const { onCopy, hasCopied } = useClipboard(
      decodeURIComponent(issuesEmail.all)
    );

    return (
      <Message mb={3} status={AlertStatusEnum.WARNING}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box textAlign="justify" fontSize={"sm"}>
            {`We’ve identified ${
              problematicOfficeActivity.length
            } offices that have not received leads from one or more portals.${
              !!issuesEmail.to
                ? " To resolve this, we advise you re-link portals for the affected offices."
                : ""
            }`}
          </Box>
          {!!issuesEmail.to && (
            <Box
              flexShrink={0}
              ml={4}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
            >
              <ButtonLink
                href={`mailto:?to=${issuesEmail.to}&subject=${issuesEmail.subject}&body=${issuesEmail.body}`}
                colorScheme={"orange"}
                label={"Send Email"}
              />
              <Box mt={1}>
                <Button onClick={onCopy} colorScheme={"orange"}>
                  {hasCopied ? "Copied" : "Copy email"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Message>
    );
  }
);
