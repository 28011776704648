import {
  TLeadOpenViewFieldValues,
  TLeadOpenViewSyncData,
} from "types/openview.type";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { getOpenViewSegmentedAddress } from "utils/openview.utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ActionPromptContainer, Error, LeadTypeTag, Loader } from "components";
import { Box, Button, Divider } from "@chakra-ui/react";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { OpenviewValidationSchema } from "utils/validation-schemas/openview-sync.validation";
import {
  AccountIntegrationOpenViewContactsStore,
  openViewNewContactValue,
} from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOpenView/AccountIntegrationOpenViewContacts.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { getUserFullName } from "utils/account-user.utils";
import { LeadAddressForm } from "./LeadAddressForm";
import { ExistingRecordsForm } from "./ExistingRecordsForm";

type TFieldValues = TLeadOpenViewFieldValues;

interface IProps {
  leadStore: AccountLeadStore;
  handleSyncLead: (syncData: TLeadOpenViewSyncData) => Promise<void>;
  closePrompt: () => void;
  contactsStore: AccountIntegrationOpenViewContactsStore;
}

export const LeadOpenViewSyncFormPrompt: FC<IProps> = observer(
  ({ leadStore, handleSyncLead, closePrompt, contactsStore }) => {
    const { address, postcode, type, id: leadId, person } = leadStore.lead;
    const {
      openViewRelatedContactsOptions,
      fetchOpenViewAccountContacts,
    } = contactsStore;

    const brokenDownAddress = getOpenViewSegmentedAddress(address, postcode);
    const [loadingState, setLoadingState] = useState(ApiRequestStatusEnum.NONE);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingState(ApiRequestStatusEnum.PENDING);
          await fetchOpenViewAccountContacts(leadId);
          setLoadingState(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingState(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [fetchOpenViewAccountContacts, setLoadingState, leadId]);

    const initialValues: TFieldValues = useMemo(() => {
      return {
        houseNo: brokenDownAddress.houseNo || "",
        address1: brokenDownAddress.address1 || "",
        address2: brokenDownAddress.address2 || "",
        town: brokenDownAddress.town || "",
        county: brokenDownAddress.county || "",
        postcode: brokenDownAddress.postcode || "",
        countryName: brokenDownAddress.countryName || "",
        fullAddress: brokenDownAddress.fullAddress || "",
        shortAddress: brokenDownAddress.shortAddress || "",
        userId: openViewRelatedContactsOptions[0]?.value,
      };
    }, [brokenDownAddress, openViewRelatedContactsOptions]);

    const isApplicant = [LeadTypeEnum.Sale, LeadTypeEnum.Let].includes(type);

    const validationSchema = useMemo(() => {
      return OpenviewValidationSchema(isApplicant);
    }, [isApplicant]);

    const personFullName = useMemo(() => {
      return (
        person?.fullName ||
        getUserFullName(person?.firstName, person?.lastName) ||
        ""
      );
    }, [person]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
      setValue,
      watch,
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const currentUserId = watch("userId");

    useEffect(() => {
      setValue("userId", openViewRelatedContactsOptions[0]?.value);
    }, [openViewRelatedContactsOptions, setValue]);

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        const { userId, ...rest } = values;
        await handleSyncLead({
          address: rest,
          userId: userId === openViewNewContactValue ? null : userId,
        });
        closePrompt();
      },
      [handleSyncLead, closePrompt]
    );

    return (
      <ActionPromptContainer
        header={<Box color={"leadpro.600"}>Sync lead to iamproperty CRM</Box>}
        bodyStyle={{ padding: "0" }}
        body={
          <Box minHeight={"100px"} position={"relative"}>
            {loadingState === ApiRequestStatusEnum.PENDING && <Loader />}
            {loadingState === ApiRequestStatusEnum.ERROR && <Error />}
            {loadingState === ApiRequestStatusEnum.SUCCESS && (
              <Box>
                <Box
                  px={8}
                  py={5}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    {personFullName}{" "}
                    <Box display={"inline"} color={"leadpro.500"}>
                      ({person.email})
                    </Box>
                  </Box>
                  <LeadTypeTag type={type} />
                </Box>
                <hr />
                <Box px={8} pb={8}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <LeadAddressForm
                      control={control}
                      isApplicant={isApplicant}
                    />

                    <ExistingRecordsForm
                      control={control}
                      openViewRelatedContactsOptions={
                        openViewRelatedContactsOptions
                      }
                      currentUserId={currentUserId}
                      type={type}
                    />

                    <Divider mb={4} />
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        type={"submit"}
                        colorScheme={"blue"}
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Sync to iamproperty CRM
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            )}
          </Box>
        }
      />
    );
  }
);
