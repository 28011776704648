import { getNumberType } from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js/max";

export const doesUrlContainProtocol = (value?: string) => {
  if (!value) return true;
  const regex = new RegExp("^(http|https)://", "i");
  return regex.test(value);
};

export const isValidPhone = (value?: string) => {
  if (!value) return true;
  return isValidPhoneNumber(value, "GB");
};

export const isValidMobilePhone = (value?: string) => {
  if (!value) return true;
  const isValid = isValidPhoneNumber(value, "GB");
  const numberType = getNumberType(value, "GB");
  return isValid && numberType === "MOBILE";
};
