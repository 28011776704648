import { observer } from "mobx-react";
import { Box, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { TItemStatCalculation } from "types/account-lead-attribution-analyticis.type";
import React, { FC } from "react";

interface IProps {
  statCalculation: TItemStatCalculation;
}

export const LeadAttributionReportSegmentDetailsItemBarSegmentTooltipContent: FC<IProps> = observer(
  ({ statCalculation }) => {
    return (
      <Box minWidth={"215px"} pb={2}>
        <Box
          width={"100%"}
          textAlign={"center"}
          fontSize={"sm"}
          fontWeight={"bold"}
        >
          {statCalculation.title}
        </Box>
        <TableContainer>
          <Table variant="simple">
            <Tbody fontSize={"xs"}>
              <Tr p={0}>
                <Td p={0} textAlign={"left"}>
                  Total
                </Td>
                <Td px={3} py={0} textAlign={"center"}>
                  {statCalculation.totalCountByStat}
                </Td>
                <Td p={0} fontWeight={"bold"} textAlign={"right"}>
                  £
                  {statCalculation.statAttributeCalculations
                    .reduce(
                      (accumulator, calculation) =>
                        accumulator + calculation.monetaryValue,
                      0
                    )
                    .toLocaleString()}
                </Td>
              </Tr>
              {statCalculation.statAttributeCalculations.map(
                (attributeCalculation, index) => {
                  return (
                    <Tr p={0} key={attributeCalculation.label}>
                      <Td p={0} textAlign={"left"}>
                        {attributeCalculation.label}
                      </Td>
                      <Td px={3} py={0} textAlign={"center"}>
                        {attributeCalculation.count}
                      </Td>
                      <Td p={0} fontWeight={"bold"} textAlign={"right"}>
                        £{attributeCalculation.monetaryValue.toLocaleString()}
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
);
