import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AccountJourneysStore } from "store/UserAccounts/UserAccount/AccountJourneys.store";
import { TAccountJourney } from "types/account-journeys.type";
import { TSelectOption } from "types/select-input.type";
import { number, object, string } from "yup";

type TFieldValues = {
  name: string;
  filterId?: number;
};

const journeyValidationSchema = object().shape({
  name: string().required(REQUIRED_FIELD),
  filterId: number().required(REQUIRED_FIELD),
});

interface IProps {
  closePrompt: () => void;
  accountJourneysStore: AccountJourneysStore;
  journey?: TAccountJourney;
  filtersOptions: TSelectOption<number>[];
  availableFiltersOptions: TSelectOption<number>[];
  fetchFilters: () => Promise<void>;
}

export const JourneyFormPrompt: FC<IProps> = observer(
  ({
    closePrompt,
    accountJourneysStore,
    journey,
    filtersOptions,
    availableFiltersOptions,
    fetchFilters,
  }) => {
    const toast = useToast();

    const initialValues: TFieldValues = useMemo(
      () => ({
        name: journey?.name || "",
        filterId: journey?.leadFilterId,
      }),
      [journey]
    );

    const options = useMemo(() => {
      if (journey) {
        const currentOption = filtersOptions.find(
          option => option.value === journey.leadFilterId
        );
        return [...availableFiltersOptions, currentOption];
      } else {
        return availableFiltersOptions;
      }
    }, [journey, availableFiltersOptions, filtersOptions]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(journeyValidationSchema),
    });

    const handleUpdateJourney = useCallback(
      async (journeyId: number, { name, filterId }: TFieldValues) => {
        await accountJourneysStore.updateAccountJourney(journeyId, {
          name,
          leadFilterId: filterId,
          active: true,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your journey has been updated",
        });
      },
      [accountJourneysStore, toast]
    );

    const handleCreateJourney = useCallback(
      async ({ name, filterId }: TFieldValues) => {
        await accountJourneysStore.createAccountJourney({
          name,
          leadFilterId: filterId,
          active: true,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: "Your journey has been created",
        });
      },
      [accountJourneysStore, toast]
    );

    const onSubmit = useCallback(
      async (formValues: TFieldValues) => {
        try {
          if (!!journey) {
            await handleUpdateJourney(journey.id, formValues);
          } else {
            await handleCreateJourney(formValues);
          }
          await fetchFilters();
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        closePrompt,
        handleCreateJourney,
        handleUpdateJourney,
        journey,
        toast,
        fetchFilters,
      ]
    );

    return (
      <ActionPromptContainer
        contentStyle={{ maxW: "2xl" }}
        header={`${!!journey ? "Edit" : "Create"} journey`}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Enter journey name",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"filterId"}
                  control={control}
                  label={"Filter"}
                  type={FormControlsTypeEnum.SINGLE_SELECT}
                  additionalProps={{
                    options: options,
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!journey ? "Update" : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
