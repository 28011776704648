import { Box, Button, Divider } from "@chakra-ui/react";
import { ActionPromptContainer, Error, LeadTypeTag, Loader } from "components";
import { observer } from "mobx-react";
import React, {
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TLeadReapitSyncData } from "types/reapit.type";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { getReapitSegmentedAddress } from "utils/reapit.utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountIntegrationReapitAreaStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapitAreas.store";

import { AccountIntegrationReapitContactsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapitContacts.store";
import { getUserFullName } from "utils/account-user.utils";
import { RentFrequencyEnum } from "enums/rent-frequency.enum";
import { range } from "lodash";
import { reapitSyncValidationSchema } from "utils/validation-schemas/reapit-sync.validation";
import { TReapitSyncFormFieldValues as TFieldValues } from "./reapit-sync-form-field.type";
import { LeadAddressForm } from "./components/LeadAddressForm";
import { ApplicantRequirementsForm } from "./components/ApplicantRequirementsForm";
import { ExistingRecordsForm } from "./components/ExistingRecordsForm";

interface IProps {
  leadStore: AccountLeadStore;
  handleSyncLead: (
    syncData: TLeadReapitSyncData,
    officeGroupId?: number
  ) => Promise<void>;
  closePrompt: () => void;
  officeGroupId?: number;
  areasStore: AccountIntegrationReapitAreaStore;
  contactsStore: AccountIntegrationReapitContactsStore;
  isApplicantRequirementsEnabled: boolean;
  areApplicantRequirementsFieldsMandatory: boolean;
}

export const LeadReapitSyncFormPrompt: FC<IProps> = observer(
  ({
    leadStore,
    handleSyncLead,
    closePrompt,
    areasStore,
    contactsStore,
    officeGroupId,
    isApplicantRequirementsEnabled,
    areApplicantRequirementsFieldsMandatory,
  }) => {
    const { address, postcode, type, id: leadId, person } = leadStore.lead;
    const brokenDownAddress = getReapitSegmentedAddress(address, postcode);
    const { fetchReapitAccountAreas, reapitAccountAreasOptions } = areasStore;
    const {
      fetchReapitAccountContacts,
      reapitRelatedContactsOptions,
    } = contactsStore;
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const priceArray: number[] = useMemo(() => {
      let prices = [];
      if (type === LeadTypeEnum.Sale) {
        prices.push(...range(50000, 300000, 10000));
        prices.push(...range(300000, 1050000, 50000));
        prices.push(...range(2000000, 6000000, 1000000));
      }
      if (type === LeadTypeEnum.Let) {
        prices = range(100, 5100, 100);
      }
      return prices;
    }, [type]);

    const isApplicant = [LeadTypeEnum.Let, LeadTypeEnum.Sale].includes(type);

    const showApplicantRequirements =
      isApplicant && isApplicantRequirementsEnabled;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await fetchReapitAccountContacts(leadId, officeGroupId);
          if (showApplicantRequirements) {
            await fetchReapitAccountAreas(leadId);
          }
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      fetchReapitAccountAreas,
      fetchReapitAccountContacts,
      leadId,
      officeGroupId,
      type,
      isApplicantRequirementsEnabled,
      showApplicantRequirements,
    ]);

    const initialValues: TFieldValues = useMemo(() => {
      return {
        buildingName: brokenDownAddress.buildingName || "",
        buildingNumber: brokenDownAddress.buildingNumber || "",
        line1: brokenDownAddress.line1 || "",
        line2: brokenDownAddress.line2 || "",
        line3: brokenDownAddress.line3 || "",
        line4: brokenDownAddress.line4 || "",
        postcode: brokenDownAddress.postcode || "",
        locationOptions: [],
        minPrice: null,
        maxPrice: null,
        contactId: reapitRelatedContactsOptions[0]?.value,
      };
    }, [brokenDownAddress, reapitRelatedContactsOptions]);

    const validationSchema = useMemo(() => {
      return reapitSyncValidationSchema(
        isApplicant,
        showApplicantRequirements,
        areApplicantRequirementsFieldsMandatory,
        reapitAccountAreasOptions
      );
    }, [
      reapitAccountAreasOptions,
      showApplicantRequirements,
      isApplicant,
      areApplicantRequirementsFieldsMandatory,
    ]);

    const personFullName = useMemo(() => {
      return (
        person?.fullName ||
        getUserFullName(person?.firstName, person?.lastName) ||
        ""
      );
    }, [person]);

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
      setValue,
      watch,
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
      setValue("contactId", reapitRelatedContactsOptions[0]?.value);
    }, [reapitRelatedContactsOptions, setValue]);

    const onSubmit = useCallback(
      async ({
        locationOptions,
        contactId,
        minPrice: formMinPrice,
        maxPrice: formMaxPrice,
        ...rest
      }: TFieldValues) => {
        const formMinPriceAsNumber = formMinPrice ?? 0;
        const formMaxPriceAsNumber = formMaxPrice ?? 0;

        const syncData: TLeadReapitSyncData = {
          locationOptions,
          contactId: contactId === "newRecord" ? null : contactId,
          address: rest,
        };

        const shouldSendApplicantRequirementsData =
          areApplicantRequirementsFieldsMandatory ||
          formMinPriceAsNumber ||
          formMaxPriceAsNumber;
        if (
          isApplicantRequirementsEnabled &&
          shouldSendApplicantRequirementsData
        ) {
          if (type === LeadTypeEnum.Sale) {
            syncData.buying = {
              priceFrom: formMinPriceAsNumber,
              priceTo: formMaxPriceAsNumber,
            };
          } else if (type === LeadTypeEnum.Let) {
            syncData.renting = {
              rentFrom: formMinPriceAsNumber,
              rentTo: formMaxPriceAsNumber,
              rentFrequency: RentFrequencyEnum.MONTHLY,
            };
          }
        }

        await handleSyncLead(syncData, officeGroupId);
        closePrompt();
      },
      [
        handleSyncLead,
        closePrompt,
        officeGroupId,
        type,
        isApplicantRequirementsEnabled,
        areApplicantRequirementsFieldsMandatory,
      ]
    );

    return (
      <ActionPromptContainer
        header={<Box color={"leadpro.600"}>Sync lead to Reapit</Box>}
        bodyStyle={{ padding: "0" }}
        body={
          <Box minHeight={"100px"} position={"relative"}>
            {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
            {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
            {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
              <Box>
                <Box
                  px={8}
                  py={5}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    {personFullName}{" "}
                    <Box display={"inline"} color={"leadpro.500"}>
                      ({person.email})
                    </Box>
                  </Box>
                  <LeadTypeTag type={type} />
                </Box>
                <hr />
                <Box px={8} pb={8}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={handleKeydown}
                  >
                    <LeadAddressForm
                      control={control}
                      isApplicant={isApplicant}
                    />
                    {showApplicantRequirements && (
                      <ApplicantRequirementsForm
                        control={control}
                        priceArray={priceArray}
                        reapitAccountAreasOptions={reapitAccountAreasOptions}
                        areApplicantRequirementsFieldsMandatory={
                          areApplicantRequirementsFieldsMandatory
                        }
                        type={type}
                        watch={watch}
                      />
                    )}
                    <ExistingRecordsForm
                      control={control}
                      reapitRelatedContactsOptions={
                        reapitRelatedContactsOptions
                      }
                    />

                    <Divider mb={4} />
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        type={"submit"}
                        colorScheme={"blue"}
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Sync to Reapit
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            )}
          </Box>
        }
      />
    );
  }
);
