import { Box, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/sharp-solid-svg-icons";
import React, { FC } from "react";

interface IProps {
  label: string;
  input: React.ReactNode;
  showError: boolean;
}
export const FormToolPageSettingsActionConfiguratorItem: FC<IProps> = ({
  label,
  input,
  showError,
}) => {
  return (
    <HStack spacing={4} width={"100%"}>
      <Box flexShrink={0} width={"100px"} color={"leadpro.500"}>
        {label}
      </Box>
      <HStack spacing={2} width={"100%"}>
        {input}
        {showError && (
          <Box color={"red.500"} fontSize={"lg"}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Box>
        )}
      </HStack>
    </HStack>
  );
};
