import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { isEmpty } from "lodash";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import { LeadInfoBlockRow } from "../LeadInfoRow/LeadInfoBlockRow";

export interface LeadMetaBlockDefinition extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.MetaBlock;
  meta: Record<string, string>;
}

export const LeadMetaBlock = leadBlockHoc<LeadMetaBlockDefinition>(
  ({ leadBlockDefinition: { meta } }) => {
    if (isEmpty(meta)) return null;

    return (
      <>
        <LeadBlockHeader>Additional information</LeadBlockHeader>
        <LeadBlockBody>
          {Object.keys(meta).map(key => (
            <LeadInfoBlockRow key={key} metaKey={key} value={meta[key]} />
          ))}
        </LeadBlockBody>
      </>
    );
  }
);
