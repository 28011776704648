export const LEADPRO_WEBSITE_BASE_URL = "https://www.lead.pro";

export const LEADPRO_WEBSITE_LEGAL_URL = `${LEADPRO_WEBSITE_BASE_URL}/legal`;
export const LEADPRO_WEBSITE_WEBINARS_URL = `${LEADPRO_WEBSITE_BASE_URL}/webinars`;
export const LEADPRO_WEBSITE_VALUATION_URL = `${LEADPRO_WEBSITE_BASE_URL}/valuation`;

export const LEADPRO_WEBSITE_IVT_PRODUCT_PAGE = `${LEADPRO_WEBSITE_BASE_URL}/instant-valuation-tool`;

export const AUTOCALLER_SETUP_WEBINAR_URL = `${LEADPRO_WEBSITE_WEBINARS_URL}/autocaller-set-up-webinar`;

export const IVT_SETUP_WEBINAR_URL = `${LEADPRO_WEBSITE_WEBINARS_URL}/instant-valuation-tool-set-up-webinar`;

export const LEAD_RESPONDER_SETUP_WEBINAR_URL = `${LEADPRO_WEBSITE_WEBINARS_URL}/lead-responder-set-up-webinar`;

export const LEADPRO_WEBSITE_TERMS_AND_CONDITIONS = `${LEADPRO_WEBSITE_LEGAL_URL}/service-agreement`;

export const CUSTOM_SUB_DOMAIN_IVT_URL = "https://valuation.lead.pro";
