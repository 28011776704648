import React from "react";
import { Observer } from "mobx-react";
import { TLeadResponderLeadEmailAdditionalData } from "types/account-lead-emails.type";
import { AccountLeadResponderEmailStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmail.store";

export enum LeadResponderEmailBlockTypes {
  ParsingAndRoutingBlock = "PARSING_ROUTING_BLOCK",
  EmailDetailsBlock = "EMAIL_DETAILS_BLOCK",
  ContentBlock = "CONTENT_BLOCK",
}

export interface ILeadResponderEmailBlockDefinition {
  blockType: LeadResponderEmailBlockTypes;
  accountLeadResponderEmailStore: AccountLeadResponderEmailStore;
  email: TLeadResponderLeadEmailAdditionalData;
}

export interface ILeadResponderEmailBlockComponentProps<
  T extends ILeadResponderEmailBlockDefinition
> {
  blockDefinition: T;
}

export const leadResponderEmailBlockHoc = <
  T extends ILeadResponderEmailBlockDefinition
>(
  Component: React.JSXElementConstructor<
    ILeadResponderEmailBlockComponentProps<T>
  >
) => {
  return (
    props: ILeadResponderEmailBlockComponentProps<
      ILeadResponderEmailBlockDefinition
    >
  ) => (
    <Observer>
      {() => (
        <Component {...(props as ILeadResponderEmailBlockComponentProps<T>)} />
      )}
    </Observer>
  );
};
