import { AccountLeadResponderEmailStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmail.store";
import { AccountOfficesStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffices.store";
import React, { useMemo } from "react";
import { observer } from "mobx-react";
import {
  components,
  leadResponderEmailBlockDefinitionFactory,
} from "./leadResponderEmailBlockDefinitionFactory";

interface ILeadEmailBlocksProps {
  leadResponderEmailStore: AccountLeadResponderEmailStore;
  accountOfficesStore: AccountOfficesStore;
}

export const LeadEmailBlocks: React.FC<ILeadEmailBlocksProps> = observer(
  ({ leadResponderEmailStore, accountOfficesStore }) => {
    const definitions = useMemo(() => {
      return leadResponderEmailBlockDefinitionFactory(
        leadResponderEmailStore,
        accountOfficesStore
      );
    }, [leadResponderEmailStore, accountOfficesStore]);
    return (
      <>
        {definitions.map(definition => {
          const Component = components[definition.blockType];
          return (
            <Component
              key={definition.blockType}
              blockDefinition={definition}
            />
          );
        })}
      </>
    );
  }
);
