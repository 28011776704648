import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import {
  faXmark,
  faCheck,
  faHourglassClock,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { DefaultRowCell } from "./DefaultRowCell";
import { TAccountUsersTableData } from "store/UserAccounts/UserAccount/AccountUsers.store";
import { FC, useMemo } from "react";
import { UserInvitationStatusEnum } from "enums/user-invitation-status.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TStatusData = {
  color: string;
  Icon: IconDefinition;
  label: string;
};

interface IProps {
  userInvitationStatus: TAccountUsersTableData["userInvitationStatus"];
}
export const UserStatusRowCell: FC<IProps> = ({ userInvitationStatus }) => {
  const statusData: TStatusData = useMemo(() => {
    switch (userInvitationStatus) {
      case UserInvitationStatusEnum.ACTIVE:
        return {
          color: "green",
          Icon: faCheck,
          label: "active",
        };
      case UserInvitationStatusEnum.EXPIRED:
        return {
          color: "red",
          Icon: faXmark,
          label: "expired",
        };
      case UserInvitationStatusEnum.PENDING:
        return {
          color: "yellow",
          Icon: faHourglassClock,
          label: "pending",
        };
    }
  }, [userInvitationStatus]);

  return (
    <DefaultRowCell>
      <Tag colorScheme={statusData.color}>
        <TagLeftIcon
          boxSize="12px"
          as={() => <FontAwesomeIcon icon={statusData.Icon} />}
        />
        <TagLabel ml={2}>{statusData.label}</TagLabel>
      </Tag>
    </DefaultRowCell>
  );
};
