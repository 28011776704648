import { createIcon } from "@chakra-ui/react";
import { withIconProps } from "utils/hoc/chakra-svg-icon.hoc";

export const BusinessIconCreateIcon = createIcon({
  displayName: "BusinessIcon",
  viewBox: "0 0 12 10",
  path: (
    <path
      id="Polygon 3"
      d="M11.2303 4.49732C12.0614 4.83012 12.07 6.00344 11.2439 6.34841L3.83235 9.44359C3.18785 9.71274 2.47297 9.25506 2.44765 8.55708L2.34014 5.59387C2.33564 5.46987 2.3081 5.34778 2.25893 5.23386L0.960684 2.22587C0.607082 1.40659 1.4222 0.569539 2.25058 0.901272L11.2303 4.49732Z"
    />
  ),
});

export const BusinessIcon = withIconProps(BusinessIconCreateIcon);
