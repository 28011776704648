import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { AccountAvatar } from "components";
import { Box, HStack } from "@chakra-ui/react";
import { TUserAccountData } from "types/user-account.type";
import { FC, useMemo } from "react";

export const AccountSelectOption: FC<ISelectInputOptionProps<
  TUserAccountData
>> = ({ optionProps: { option, selectedOption, ...rest } }) => {
  const isSelected = useMemo(
    () => selectedOption?.value?.id === option.value.id,
    [selectedOption, option]
  );
  const isDisabled = false;

  return (
    <SelectInputOptionBase
      option={option}
      isSelected={isSelected}
      isDisabled={isDisabled}
      {...rest}
    >
      <HStack spacing={2} maxWidth={"100%"}>
        <AccountAvatar account={option.value} heightInPx={20} />
        <Box noOfLines={1} fontSize={"sm"}>
          {option.value.name}
        </Box>
      </HStack>
    </SelectInputOptionBase>
  );
};
