import { BaseApi } from "./base.api";
import {
  TAccountOfficeData,
  TOfficeGroupData,
  TOfficeMembership,
} from "types/account-office.type";
import { keysToCamel } from "utils/keys-to-camel.utils";
import { TOfficeAutocallerConfiguration } from "types/autocaller.type";
import {
  TOfficeEmailNotificationsConfiguration,
  TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration,
} from "types/email-notifications-configuration.type";
import { IAccountOfficePortalActivity } from "types/account-portal-link-status-per-office.type";
import {
  getProblematicOfficeActivityLeadTypes,
  transformOfficeActivityTypeEntriesIntoArray,
} from "utils/office-activity.utils";
import { TOfficeMortgageReferralsConfiguration } from "types/mortgage-referrals-configuration.type";
import { TOfficeUserData } from "types/office-user.type";
import { TUserData } from "types/user-data.type";

export class AccountOfficesApi extends BaseApi {
  async createAccountOffice(
    accountId: number,
    officeData: Partial<TAccountOfficeData>
  ): Promise<TAccountOfficeData> {
    const { data } = await this.post(
      `/accounts/${accountId}/offices/`,
      officeData
    );

    return keysToCamel(data);
  }

  async fetchAccountOffices(accountId: number): Promise<TAccountOfficeData[]> {
    return this.get(`/accounts/${accountId}/offices/`);
  }

  async fetchAccountOffice(
    accountId: number,
    officeId: number
  ): Promise<TAccountOfficeData> {
    return this.get(`/accounts/${accountId}/offices/${officeId}`);
  }

  async updateAccountOffice(
    accountId: number,
    officeId: number,
    officeData: Partial<TAccountOfficeData>
  ): Promise<TAccountOfficeData> {
    return this.put(`/accounts/${accountId}/offices/${officeId}/`, officeData);
  }

  async removeOfficeFromAccount(
    accountId: number,
    officeId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/offices/${officeId}/`);
  }

  // OFFICE USERS
  async fetchAccountOfficeUsers(
    accountId: number,
    officeId: number
  ): Promise<TOfficeUserData[]> {
    const { data } = await this.get(
      `/accounts/${accountId}/offices/${officeId}/users`
    );

    return data;
  }

  async addUsersToAccountOffice(
    accountId: number,
    officeId: number,
    userIds: number[]
  ): Promise<void> {
    return this.post(`/accounts/${accountId}/offices/${officeId}/users`, {
      userIds,
    });
  }

  // OFFICE MEMBERSHIP
  async createOfficeMembership(
    accountId: number,
    officeId: number,
    membershipId: number,
    externalId?: number
  ): Promise<TOfficeMembership> {
    return this.post(
      `/accounts/${accountId}/offices/${officeId}/memberships/`,
      {
        membershipId,
        externalId,
      }
    );
  }

  async updateOfficeMembership(
    accountId: number,
    officeId: number,
    officeMembershipId: number,
    externalId: number | null
  ): Promise<void> {
    return this.put(
      `/accounts/${accountId}/offices/${officeId}/memberships/${officeMembershipId}`,
      {
        externalId,
      }
    );
  }

  async removeOfficeMembership(
    accountId: number,
    officeId: number,
    officeMembershipId: number
  ): Promise<void> {
    return this.del(
      `/accounts/${accountId}/offices/${officeId}/memberships/${officeMembershipId}`
    );
  }

  async fetchAccountOfficeMemberships(
    accountId: number,
    officeId: number
  ): Promise<TOfficeMembership[]> {
    return this.get(`/accounts/${accountId}/offices/${officeId}/memberships/`);
  }

  // OFFICE AUTOCALLER
  async fetchAccountOfficeAutocallerConfiguration(
    accountId: number,
    officeId: number
  ): Promise<TOfficeAutocallerConfiguration> {
    return this.get(`/accounts/${accountId}/offices/${officeId}/autocaller/`);
  }

  async updateAccountOfficeAutocallerConfiguration(
    accountId: number,
    officeId: number,
    configuration: Partial<TOfficeAutocallerConfiguration>
  ): Promise<TOfficeAutocallerConfiguration> {
    return this.put(
      `/accounts/${accountId}/offices/${officeId}/autocaller`,
      configuration
    );
  }

  // OFFICE EMAIL NOTIFICATIONS
  async fetchAccountOfficeEmailNotificationsConfiguration(
    accountId: number,
    officeId: number
  ): Promise<TOfficeEmailNotificationsConfiguration> {
    return this.get(
      `/accounts/${accountId}/offices/${officeId}/email-notifications`
    );
  }

  async updateAccountOfficeEmailNotificationsConfiguration(
    accountId: number,
    officeId: number,
    configuration: Partial<TOfficeEmailNotificationsConfiguration>
  ): Promise<TOfficeEmailNotificationsConfiguration> {
    return this.put(
      `/accounts/${accountId}/offices/${officeId}/email-notifications`,
      configuration
    );
  }

  async fetchAccountOfficeIncompleteValuationLeadsEmailNotificationsConfiguration(
    accountId: number,
    officeId: number
  ): Promise<TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration> {
    return this.get(
      `/accounts/${accountId}/offices/${officeId}/incomplete-valuation-leads-email-notifications`
    );
  }

  async updateAccountOfficeIncompleteValuationLeadsEmailNotificationsConfiguration(
    accountId: number,
    officeId: number,
    configuration: Partial<
      TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration
    >
  ): Promise<TOfficeIncompleteValuationLeadsEmailNotificationsConfiguration> {
    return this.put(
      `/accounts/${accountId}/offices/${officeId}/incomplete-valuation-leads-email-notifications`,
      configuration
    );
  }

  async fetchAccountOfficesPortalActivity(
    accountId: number
  ): Promise<IAccountOfficePortalActivity[]> {
    const { data } = await this.get(`/accounts/${accountId}/offices-activity`);

    data.forEach((officeActivity: IAccountOfficePortalActivity) => {
      officeActivity.types = transformOfficeActivityTypeEntriesIntoArray(
        officeActivity.types
      );
      officeActivity.problematicTypes = getProblematicOfficeActivityLeadTypes(
        officeActivity
      );
    });
    return data;
  }

  // OFFICE GROUPS
  async fetchAccountOfficeGroups(
    accountId: number
  ): Promise<TOfficeGroupData[]> {
    return this.get(`/accounts/${accountId}/office-groups`);
  }

  async createAccountOfficeGroup(
    accountId: number,
    name: string,
    userIds: number[],
    officeIds: number[]
  ): Promise<TOfficeGroupData> {
    return this.post(`/accounts/${accountId}/office-groups`, {
      name,
      officeIds,
      userIds,
    });
  }

  async updateAccountOfficeGroup(
    accountId: number,
    officeGroupId: number,
    name: string,
    userIds: number[],
    officeIds: number[]
  ): Promise<TOfficeGroupData> {
    return this.put(`/accounts/${accountId}/office-groups/${officeGroupId}`, {
      name,
      userIds,
      officeIds,
    });
  }

  async removeAccountOfficeGroup(
    accountId: number,
    officeGroupId: number
  ): Promise<void> {
    await this.del(`/accounts/${accountId}/office-groups/${officeGroupId}`);
  }

  async fetchOfficeGroupAssociatedUsers(
    accountId: number,
    officeGroupId: number
  ): Promise<TUserData[]> {
    return await this.get(
      `/accounts/${accountId}/office-groups/associated-users/${officeGroupId}`
    );
  }

  //OFFICE MORTGAGE REFERRALS
  async fetchAccountOfficeMortgageReferralsConfiguration(
    accountId: number,
    officeId: number
  ): Promise<TOfficeMortgageReferralsConfiguration> {
    return this.get(`/accounts/${accountId}/offices/${officeId}/mortgages`);
  }

  async updateAccountOfficeMortgageReferralsConfiguration(
    accountId: number,
    officeId: number,
    configuration: Partial<TOfficeMortgageReferralsConfiguration>
  ): Promise<TOfficeMortgageReferralsConfiguration> {
    return this.put(
      `/accounts/${accountId}/offices/${officeId}/mortgages`,
      configuration
    );
  }

  // LEADS COUNT
  async fetchOfficeLeadsCount(
    accountId: number,
    officeId: number
  ): Promise<{ count: number }> {
    return this.get(`/accounts/${accountId}/offices/${officeId}/leadsCount`);
  }
}
