import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  rexIntegrationConnectValidationSchema,
  rexIntegrationInitialValues,
  TRexIntegrationConfigFieldValues,
} from "utils/validation-schemas/rex-integration-validation";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectRexForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TRexIntegrationConfigFieldValues>({
      defaultValues: rexIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(rexIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TRexIntegrationConfigFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TRexIntegrationConfigFieldValues
          >({
            type: AccountIntegrationTypeEnum.REX,
            active: true,
            integrationConfig,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"Rex successfully connected."} />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TRexIntegrationConfigFieldValues>
              label={"Rex Customer Id"}
              type={FormControlsTypeEnum.TEXT}
              name={"rexCustomerId"}
              control={control}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
