import { Input as ChakraInput } from "@chakra-ui/react";
import React from "react";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR } from "styles/input-default-pseudobox-behaviour";

const sizes = {
  sm: {
    height: "35px",
    fontSize: "sm",
    padding: 2,
  },
  md: {
    height: "35px",
    fontSize: "sm",
    padding: 3,
  },
  lg: {
    height: "35px",
    fontSize: "sm",
    padding: 3,
  },
  base: {
    height: "42px",
    fontSize: "base",
    padding: 3,
  },
};

export type InputProps = React.ComponentPropsWithoutRef<typeof ChakraInput> & {
  size?: "sm" | "md" | "lg" | "base";
};

export const Input = React.forwardRef<any, InputProps>((props, ref) => {
  const {
    id,
    name,
    size,
    onChange,
    defaultValue,
    onBlur,
    placeholder,
    value,
    type,
    isDisabled,
    ...rest
  } = props;

  return (
    <ChakraInput
      id={id}
      name={name}
      type={type}
      ref={ref}
      onChange={onChange}
      defaultValue={defaultValue}
      onBlur={onBlur}
      borderRadius="md"
      height={sizes[size || "base"].height}
      fontSize={sizes[size || "base"].fontSize}
      px={sizes[size || "base"].padding}
      placeholder={placeholder}
      value={value}
      border="1px solid"
      borderColor="gray.300"
      isDisabled={isDisabled}
      {...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR}
      {...rest}
    />
  );
});
