import { Box, Button, Divider, useToast, VStack } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { useCallback } from "react";
import * as Yup from "yup";
import {
  REQUIRED_FIELD,
  URL_FIELD,
  URL_FIELD_MISSING_PROTOCOL,
} from "constants/validator-messages";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, Bullet, Message, FormControlV2 } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TMRIRadarConfig } from "types/account-integration.type";
import { doesUrlContainProtocol } from "utils/custom-yup-validators.utils";

type TFieldValues = {
  mriRadarUrl: string;
};

const initialValues: TFieldValues = {
  mriRadarUrl: "",
};

const validationSchema = Yup.object().shape({
  mriRadarUrl: Yup.string()
    .test("is-valid-url", URL_FIELD_MISSING_PROTOCOL, doesUrlContainProtocol)
    .url(URL_FIELD)
    .required(REQUIRED_FIELD),
});

interface IProps {
  closePrompt: () => void;
  accountIntegrationsStore: AccountIntegrationsStore;
}
export const ConnectMRIRadarForm: React.FC<IProps> = observer(
  ({ closePrompt, accountIntegrationsStore }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async ({ mriRadarUrl }: TFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TMRIRadarConfig
          >({
            type: AccountIntegrationTypeEnum.MRI_RADAR,
            active: true,
            integrationConfig: { mriRadarUrl },
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"MRI Radar successfully connected."}
              />
            ),
          });
          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <Message status={AlertStatusEnum.WARNING}>
              <VStack spacing={4}>
                <Bullet alignItems={"flex-start"}>
                  If you'd like every lead received to instantly be sent to
                  Radar, please contact your Radar account manager and ask for
                  your 'Lead Import URL'. You then need to navigate to the
                  webhooks page, and add this URL into the webhook URL box.{" "}
                </Bullet>
                <Bullet alignItems={"flex-start"}>
                  If you'd like to select which leads are sent to Radar, and add
                  a button to your lead dashboard to select which leads you want
                  to sync, then you need to use the connect button below.
                </Bullet>
              </VStack>
            </Message>
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"mriRadarUrl"}
              control={control}
              label={"MRI Radar Webhook URL"}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "MRI Radar Webhook URL",
              }}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
