import { Avatar } from "@chakra-ui/react";
import React from "react";
import { AvatarSize } from "./avatar-size";

type AvatarUserData = {
  firstName?: string;
  lastName?: string;
  email: string;
};
interface IUserAvatarProps {
  user: AvatarUserData;
  size?: AvatarSize;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({ user, size }) => {
  const name = React.useMemo(() => {
    let val = "";

    if (user.firstName) {
      val += user.firstName;
    }

    if (user.lastName) {
      if (user.firstName) {
        val += " ";
      }
      val += user.lastName;
    }

    return val || user.email!;
  }, [user]);
  return <Avatar name={name} src={""} size={size} />;
};
