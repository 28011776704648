import React, { FC } from "react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { RexIntegrationForm } from "./RexIntegrationForm";
import { RexIntegrationResources } from "./RexIntegrationResources";

interface IProps {
  accountStore: UserAccountStore;
}

export const RexIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;

    return (
      <>
        <RexIntegrationForm
          accountIntegrationsStore={accountIntegrationsStore}
        />
        <RexIntegrationResources accountStore={accountStore} />
      </>
    );
  }
);
