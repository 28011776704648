import { makeAutoObservable } from "mobx";
import { AccountApi } from "api/account.api";
import { TEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import { AppStore } from "store/App.store";

const accountAPI = new AccountApi();

export class AccountEmailNotificationsStore {
  private readonly root: AppStore;
  private readonly accountId: number;

  private accountEmailNotificationsConfigurationData: TEmailNotificationsConfiguration | null;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountEmailNotificationsConfigurationData = null;
  }

  get accountEmailNotificationsConfiguration() {
    return this.accountEmailNotificationsConfigurationData;
  }

  public setAccountEmailNotificationsConfigurationData(
    configuration: TEmailNotificationsConfiguration
  ) {
    this.accountEmailNotificationsConfigurationData = configuration;
  }

  public async fetchAccountEmailNotificationsConfiguration() {
    const configuration = await accountAPI.fetchAccountEmailNotificationsConfiguration(
      this.accountId
    );

    this.setAccountEmailNotificationsConfigurationData(configuration);
  }
}
