import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TPersistentFilter,
  TPersistentFiltersExpressionSchema,
} from "types/persistent-filters.type";
import { PersistentFiltersApi } from "api/persistent-filters.api";
import { TSelectOption } from "types/select-input.type";
import { keyBy } from "lodash";

const persistentFiltersApi = new PersistentFiltersApi();

export class AccountPersistentFiltersStore {
  private readonly accountId: number;
  private readonly persistentFilterExpressionSchemas: IObservableArray<
    TPersistentFiltersExpressionSchema
  >;
  private readonly persistentFilters: IObservableArray<TPersistentFilter>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.persistentFilterExpressionSchemas = observable.array<
      TPersistentFiltersExpressionSchema
    >();
    this.persistentFilters = observable.array<TPersistentFilter>();
  }

  get persistentFilterExpressionSchemasArray() {
    return this.persistentFilterExpressionSchemas.slice();
  }

  get persistentFilterExpressionSchemaOptions(): TSelectOption<string>[] {
    return this.persistentFilterExpressionSchemasArray.map(schema => ({
      value: schema.name,
      label: schema.label,
    }));
  }

  get persistentFilterExpressionSchemaMap(): Record<
    string,
    TPersistentFiltersExpressionSchema
  > {
    return keyBy(
      this.persistentFilterExpressionSchemasArray,
      schema => schema.name
    );
  }

  get persistentFiltersArray() {
    return this.persistentFilters.slice();
  }

  get persistentFiltersMap() {
    return keyBy(
      this.persistentFiltersArray,
      persistentFilter => persistentFilter.id
    );
  }

  get persistentFiltersAsOptions(): TSelectOption<number>[] {
    return this.persistentFiltersArray.map(persistentFilter => ({
      value: persistentFilter.id,
      label: persistentFilter.name,
    }));
  }

  get availablePersistentFiltersAsOptions(): TSelectOption<number>[] {
    return this.persistentFiltersArray
      .filter(persistentFilter => persistentFilter.journeys.length === 0)
      .map(persistentFilter => ({
        value: persistentFilter.id,
        label: persistentFilter.name,
      }));
  }

  private upsertPersistentFilter(filterData: TPersistentFilter) {
    const index = this.persistentFilters.findIndex(
      filter => filter.id === filterData.id
    );
    if (index > -1) {
      this.persistentFilters[index] = {
        ...this.persistentFilters[index],
        ...filterData,
      };
    } else {
      this.persistentFilters.push(filterData);
    }
  }

  private setPersistentFilterExpressionSchemas(
    persistentFilterExpressionSchemas: TPersistentFiltersExpressionSchema[]
  ) {
    this.persistentFilterExpressionSchemas.replace(
      persistentFilterExpressionSchemas
    );
  }

  private setPersistentFilters(persistentFilters: TPersistentFilter[]) {
    this.persistentFilters.replace(persistentFilters);
  }

  private deletePersistentFilter(filterId: number) {
    const index = this.persistentFilters.findIndex(
      filter => filter.id === filterId
    );
    if (index > -1) {
      this.persistentFilters.splice(index, 1);
    }
  }

  public async loadPersistentFilterExpressionSchemas() {
    const schemas = await persistentFiltersApi.getPersistentFiltersSchema(
      this.accountId
    );
    this.setPersistentFilterExpressionSchemas(schemas);
  }

  public async loadPersistentFilters() {
    const filters = await persistentFiltersApi.getPersistentFilters(
      this.accountId
    );
    this.setPersistentFilters(filters);
  }

  public async createPersistentFilter(
    persistentFilter: Partial<TPersistentFilter>
  ) {
    const newFilter = await persistentFiltersApi.createPersistentFilter(
      this.accountId,
      persistentFilter
    );
    this.upsertPersistentFilter(newFilter);
  }

  public async updatePersistentFilter(
    filterId: number,
    persistentFilter: Partial<TPersistentFilter>
  ) {
    const updatedFilter = await persistentFiltersApi.updatePersistentFilter(
      this.accountId,
      filterId,
      persistentFilter
    );
    this.upsertPersistentFilter(updatedFilter);
  }

  public async removePersistentFilter(filterId: number) {
    await persistentFiltersApi.deletePersistentFilter(this.accountId, filterId);
    this.deletePersistentFilter(filterId);
  }
}
