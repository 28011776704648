import React, { useCallback, useMemo, useRef } from "react";
import { TSerializedDateRange } from "types/date.type";
import { DateRangePickerOptions } from "components";
import moment from "moment";
import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCalendarRange } from "@fortawesome/pro-regular-svg-icons";
import { StandardIconButton } from "../standard-icon-button/StandardIconButton";
import { DateRangePickerController } from "./DateRangePickerController";
import { DATE_CALENDAR_FORMAT } from "constants/date";

interface IDateRangePickerInputProps extends TSerializedDateRange {
  onDatesChange: (arg: TSerializedDateRange) => void;
  clearable?: boolean;
  disabled?: boolean;
  maxDate?: string;
  minDate?: string;
  presetOptions?: { value: string; label: string }[];
  selectedPresetOption?: string;
  onSelectPreset?: (value: string) => void;
}

export const DateRangePickerInput: React.FC<IDateRangePickerInputProps> = ({
  startDate,
  endDate,
  onDatesChange,
  clearable,
  disabled,
  maxDate,
  minDate,
  presetOptions,
  onSelectPreset,
  selectedPresetOption,
}) => {
  const inputRef = useRef(null);
  const theme = useTheme();

  const dateLabel = useMemo(() => {
    return (
      <HStack
        justifyContent={"space-evenly"}
        width={"100%"}
        spacing={2}
        fontSize={"md"}
      >
        <Box>
          {!!startDate ? (
            <Box color={"gray.900"}>
              {moment(startDate).format(DATE_CALENDAR_FORMAT)}
            </Box>
          ) : (
            <Box color={"gray.400"}>Start</Box>
          )}
        </Box>
        <Box color={"gray.400"}>-</Box>
        <Box>
          {!!endDate ? (
            <Box color={"gray.900"}>
              {moment(endDate).format(DATE_CALENDAR_FORMAT)}
            </Box>
          ) : (
            <Box color={"gray.400"}>End</Box>
          )}
        </Box>
      </HStack>
    );
  }, [startDate, endDate]);

  const handleOnChange = useCallback(
    (onClose: () => void) => (dates: TSerializedDateRange) => {
      onDatesChange(dates);
      onClose();
    },
    [onDatesChange]
  );

  const handleSelectPreset = useCallback(
    (value: string) => {
      !!onSelectPreset && onSelectPreset(value);
    },
    [onSelectPreset]
  );

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onDatesChange({
        startDate: null,
        endDate: null,
      });
    },
    [onDatesChange]
  );

  return (
    <Popover
      closeOnBlur={true}
      initialFocusRef={inputRef}
      isLazy={true}
      arrowSize={20}
      gutter={15}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={clearable ? "230px" : "200px"}
              height={"42px"}
              px={2}
              py={1}
              border={"1px solid"}
              borderColor={"gray.200"}
              rounded={"md"}
              pointerEvents={disabled ? "none" : undefined}
              opacity={disabled ? 0.6 : undefined}
              cursor={disabled ? "not-allowed" : "pointer"}
            >
              <FontAwesomeIcon
                icon={faCalendarRange}
                fontSize={20}
                color={theme.colors.gray[400]}
              />
              {dateLabel}
              {!!clearable && (
                <StandardIconButton
                  onClick={handleClear}
                  aria-label={"delete-template"}
                  icon={<FontAwesomeIcon icon={faXmark} fontSize={18} />}
                />
              )}
            </HStack>
          </PopoverTrigger>
          <PopoverContent width={"unset"}>
            <PopoverArrow />
            <PopoverBody padding={0}>
              <HStack spacing={0}>
                {!!presetOptions && presetOptions.length > 0 && (
                  <VStack minW={"130px"} spacing={0}>
                    <DateRangePickerOptions
                      options={presetOptions}
                      onChange={handleSelectPreset}
                      value={selectedPresetOption}
                    />
                  </VStack>
                )}
                <Box
                  p={4}
                  borderLeft={!!presetOptions ? "1px solid" : 0}
                  borderColor={!!presetOptions ? "gray.200" : undefined}
                >
                  <DateRangePickerController
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleOnChange(onClose)}
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                </Box>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
