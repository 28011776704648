import React, { useMemo } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Message } from "components";
import moment from "moment";
import { DATE_STRIPE_SUBSCRIPTION } from "constants/date";
import { AccountBillingSubscriptionProduct } from "./AccountBillingSubscriptionProduct";
import { AccountBillingPeriodTotalCharge } from "./AccountBillingPeriodTotalCharge";
import { accountProductsConfigMap } from "constants/account-products-config";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { HIDDEN_PRODUCTS_FROM_BILLING_SUBSCRIPTIONS } from "utils/account-billing.utils";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountBillingSubscriptionTab: React.FC<IProps> = observer(
  ({ accountStore }) => {
    const accountBillingStore = accountStore.accountBillingStore;
    const paidProducts = accountBillingStore.applicationProductsOnPaidPlan;
    const stripePaymentMethodLast4 =
      accountBillingStore.billing?.stripePaymentMethodLast4;

    const accountProductsConfig = useMemo(() => {
      return Object.values(accountProductsConfigMap).filter(
        product =>
          !HIDDEN_PRODUCTS_FROM_BILLING_SUBSCRIPTIONS.includes(
            product.productId
          )
      );
    }, []);

    return (
      <Box>
        {!!paidProducts.length && (
          <Message status={AlertStatusEnum.INFO}>
            {`Your next payment will be taken on the ${moment()
              .add(1, "months")
              .startOf("month")
              .format(DATE_STRIPE_SUBSCRIPTION)}.`}
          </Message>
        )}
        <VStack spacing={4} mt={6}>
          {accountProductsConfig.map(config => (
            <AccountBillingSubscriptionProduct
              key={config.productId}
              config={config}
              accountBillingStore={accountBillingStore}
            />
          ))}
        </VStack>
        {!!paidProducts.length && (
          <AccountBillingPeriodTotalCharge
            paidProducts={paidProducts}
            stripePaymentMethodLast4={stripePaymentMethodLast4}
          />
        )}
      </Box>
    );
  }
);
