import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import {
  TItemStatsSummary,
  TLeadAttributionByItem,
} from "types/account-lead-attribution-analyticis.type";
import { FC } from "react";
import { LeadAttributionReportSegmentDetailsItemBarTemplate } from "./LeadAttributionReportSegmentDetailsItemBarTemplate";

interface IProps {
  item: TItemStatsSummary<TLeadAttributionByItem>;
}

export const LeadAttributionReportSegmentDetailsItemBarEstimatedValue: FC<IProps> = observer(
  ({ item }) => {
    return (
      <LeadAttributionReportSegmentDetailsItemBarTemplate
        width={"135px"}
        maxWidth={"135px"}
        alignItems={"flex-end"}
        topChildren={
          <Box textAlign={"right"}>
            <Box>£{item.totalEstValuePerItem.toLocaleString()}</Box>
            <Box color={"leadpro.400"} fontSize={"xs"}>
              Estimated value
            </Box>
          </Box>
        }
      />
    );
  }
);
