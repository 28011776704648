import ReactDOM from "react-dom";
import { ChartTooltip, IChartTooltipProps } from "./ChartTooltip";
import { defaultTheme } from "../../../theme/default.theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ChartSubSeries } from "../interfaces";

interface Rect {
  left: number;
  width: number;
  top: number;
  height: number;
}

export function betterTooltip(
  colors?: string[],
  seriesNames?: string[],
  subSeries?: ChartSubSeries,
  showSecondarySources?: boolean
) {
  if (!colors || !seriesNames) {
    console.warn("Tooltip requires colors and series names.");
    return () => {};
  }
  return (chart: any) => {
    const labels: string[] = chart.data.labels;
    const series: string[][] = chart.data.series;
    if (!series.length) return;
    const values: string[][] = series[0].map((col, i) =>
      series.map(row => row[i])
    );

    const chartContainer: HTMLDivElement = chart.container;

    const renderTooltip = (props: IChartTooltipProps) => {
      ReactDOM.render(
        <ChakraProvider theme={defaultTheme}>
          <ChartTooltip {...props} />
        </ChakraProvider>,
        verticalLine
      );
    };

    let svgElement: SVGElement | undefined;
    let innerRect: Rect | undefined;
    let xTickPositions: number[] = [];

    let prevClosestTickIndex = -1;

    const verticalLine = createVerticalLine();
    chartContainer.appendChild(verticalLine);

    chartContainer.addEventListener("mousemove", e => {
      if (innerRect) {
        const chartContainerRect = chartContainer.getBoundingClientRect();

        const x = e.clientX - chartContainerRect.left; //x position within the element.
        // const y = e.clientY - chartContainerRect.top; //x position within the element.

        const closestTickIndex = findClosestTick(x, xTickPositions);
        if (
          closestTickIndex !== undefined &&
          closestTickIndex !== prevClosestTickIndex
        ) {
          verticalLine.style.transform = `translate3d(${xTickPositions[closestTickIndex]}px, 0, 0)`;

          renderTooltip({
            xLabel: labels[closestTickIndex],
            values: values[closestTickIndex],
            seriesNames,
            subSeries: subSeries && subSeries[closestTickIndex],
            showSecondarySources,
            colors,
            left: 2 * closestTickIndex > labels.length,
          });

          prevClosestTickIndex = closestTickIndex;
        }
      }
    });

    chartContainer.addEventListener("mouseout", () => {
      verticalLine.style.opacity = "0";
    });

    chartContainer.addEventListener("mouseover", () => {
      verticalLine.style.opacity = "1";
    });

    chart.on("created", (e: any) => {
      svgElement = chart.svg.getNode();

      if (!svgElement) return;
      const chartContainerRect = chartContainer.getBoundingClientRect();
      const svgRect = svgElement.getBoundingClientRect();
      const chartPositionInContainer: Rect = {
        left: svgRect.left - chartContainerRect.left,
        top: svgRect.top - chartContainerRect.top,
        width: svgRect.width,
        height: svgRect.height,
      };

      const { chartRect, axisX } = e;

      innerRect = {
        left: chartPositionInContainer.left + chartRect.x1,
        top: chartPositionInContainer.top + chartRect.y2,
        width: axisX.stepLength * (axisX.ticks.length - 1),
        height: chartRect.height(),
      };

      xTickPositions = axisX.ticks.map(
        (_: any, i: number) => i * axisX.stepLength + innerRect!.left
      );
      // xTickPositions.forEach((tick: number) => chartContainer.appendChild(createVerticalLine(true, tick, "blue")));

      // chartContainer.appendChild(createBoundingRect(chartPositionInContainer));
      // chartContainer.appendChild(createBoundingRect(innerRect, "red"));

      verticalLine.style.top = `${innerRect.top}px`;
      verticalLine.style.height = `${innerRect.height}px`;
    });
  };
}

const createVerticalLine = (visible = false, left = 0, color = "#9E9E9E") => {
  const div = document.createElement("div");
  div.style.top = "0";
  div.style.height = "100%";
  div.style.left = `${left}px`;
  div.style.position = "absolute";
  div.style.width = "1px";
  div.style.transition = "all 0.1s ease";
  div.style.background = `${color}`;
  div.style.opacity = visible ? "1" : "0";

  return div;
};

// const createBoundingRect = (rect: Rect, color = "black") => {
//   const div = document.createElement("div");
//   div.style.top = `${rect.top}px`;
//   div.style.left = `${rect.left}px`;
//   div.style.width = `${rect.width}px`;
//   div.style.height = `${rect.height}px`;
//   div.style.position = "absolute";
//   div.style.border = `1px solid ${color}`;
//   div.style.background = "transparent";
//
//   return div;
// };

const findClosestTick = (position: number, ticks: number[]) => {
  if (ticks.length === 0) {
    return;
  }

  if (position <= ticks[0]) {
    return 0;
  }

  if (position >= ticks[ticks.length - 1]) {
    return ticks.length - 1;
  }

  let i = 0;
  let j = ticks.length - 1;
  while (j - i > 1) {
    const lowerDist = position - ticks[i];
    const upperDist = ticks[j] - position;
    const halfDistance = Math.floor((j - i) / 2);
    if (lowerDist > upperDist) {
      i = i + halfDistance;
    } else {
      j = j - halfDistance;
    }
  }
  const lowerDist = position - ticks[i];
  const upperDist = ticks[j] - position;
  i = lowerDist < upperDist ? i : j;

  return i;
};
