import { Box } from "@chakra-ui/react";
import { FormControlV2, FormControlLabel } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { FC, useEffect } from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { TOfficeFormFieldValues } from "utils/validation-schemas/office-basic.validation";

interface IProps {
  watch: UseFormWatch<TOfficeFormFieldValues>;
  control: Control<TOfficeFormFieldValues>;
  setValue: UseFormSetValue<TOfficeFormFieldValues>;
}

export const OfficeFormContactDataInput: FC<IProps> = ({
  watch,
  control,
  setValue,
}) => {
  const watchAll = watch();

  useEffect(() => {
    if (!watchAll.isAdvancedContactData) {
      setValue("lettingsEmails", watchAll.salesEmails);
      setValue("vendorEmails", watchAll.salesEmails);
      setValue("landlordEmails", watchAll.salesEmails);
    }
  }, [watchAll.isAdvancedContactData, watchAll.salesEmails, setValue]);

  useEffect(() => {
    if (!watchAll.isAdvancedContactData) {
      setValue("lettingsPhoneNumber", watchAll.salesPhoneNumber);
      setValue("vendorPhoneNumber", watchAll.salesPhoneNumber);
      setValue("landlordPhoneNumber", watchAll.salesPhoneNumber);
      setValue("mortgagePhoneNumber", watchAll.salesPhoneNumber);
    }
  }, [watchAll.isAdvancedContactData, watchAll.salesPhoneNumber, setValue]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        mb={5}
      >
        <FormControlV2<TOfficeFormFieldValues>
          name={"isAdvancedContactData"}
          control={control}
          type={FormControlsTypeEnum.SWITCH}
        />
        <FormControlLabel mb={0} ml={4} mr={4}>
          Use advanced contact details
        </FormControlLabel>
      </Box>
      {!watchAll.isAdvancedContactData && (
        <>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Office emails"}
              description={
                "Which email address should leads be sent to. Only the first email from the list will be used in the email replies sent to leads."
              }
              name={"salesEmails"}
              control={control}
              type={FormControlsTypeEnum.TAG_INPUT}
              additionalProps={{
                placeholder: "Enter email and press enter or comma",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Office phone number"}
              description={"Which phone number should leads call."}
              name={"salesPhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter phone number",
              }}
            />
          </Box>
        </>
      )}
      {!!watchAll.isAdvancedContactData && (
        <>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Sales emails"}
              description={
                "Which email address should 'Sales Viewing' leads be sent to. Only the first email from the list will be used in the email replies sent to leads."
              }
              name={"salesEmails"}
              control={control}
              type={FormControlsTypeEnum.TAG_INPUT}
              additionalProps={{
                placeholder: "Enter sales email and press enter or comma",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Lettings emails"}
              description={
                "Which email address should 'Let Viewing' leads be sent to. Only the first email from the list will be used in the email replies sent to leads."
              }
              name={"lettingsEmails"}
              control={control}
              type={FormControlsTypeEnum.TAG_INPUT}
              additionalProps={{
                placeholder: "Enter letting email and press enter or comma",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Vendor emails"}
              description={
                "Which email address should 'Vendor Valuation' leads be sent to. Only the first email from the list will be used in the email replies sent to leads."
              }
              name={"vendorEmails"}
              control={control}
              type={FormControlsTypeEnum.TAG_INPUT}
              additionalProps={{
                placeholder: "Enter vendor email and press enter or comma",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Landlord emails"}
              description={
                "Which email address should 'Landlord Valuation' leads be sent to. Only the first email from the list will be used in the email replies sent to leads."
              }
              name={"landlordEmails"}
              control={control}
              type={FormControlsTypeEnum.TAG_INPUT}
              additionalProps={{
                placeholder: "Enter landlord email and press enter or comma",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Sales phone number"}
              description={
                "Which phone number should 'Sales Viewing' leads call."
              }
              name={"salesPhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter sales phone number",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Lettings phone number"}
              description={
                "Which phone number should 'Lettings Viewing' leads call."
              }
              name={"lettingsPhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter lettings phone number",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Vendor phone number"}
              description={
                "Which phone number should 'Sales Valuation' leads call."
              }
              name={"vendorPhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter vendor phone number",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Landlord phone number"}
              description={
                "Which phone number should 'Lettings Valuation' leads call."
              }
              name={"landlordPhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter landlord phone number",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TOfficeFormFieldValues>
              label={"Mortgage Request phone number"}
              description={
                "Which phone number should 'Mortgage Request' leads call."
              }
              name={"mortgagePhoneNumber"}
              control={control}
              type={FormControlsTypeEnum.TEL}
              additionalProps={{
                placeholder: "Enter mortgage request phone number",
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};
