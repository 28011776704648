import { Box, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { StandardIconButton, Tooltip } from "components";
import React, { FC, useRef } from "react";

const ICON_BUTTON_STYLE_PROPS = {
  colorScheme: "whiteAlpha",
  _hover: {
    color: "whiteAlpha.600",
    bgColor: "whiteAlpha.400",
  },
};

interface IProps {
  ariaLabel: string;
  icon: React.ReactElement;
  children: React.ReactElement;
}

export const BulkUpdateMenu: FC<IProps> = ({ ariaLabel, icon, children }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Box position={"relative"}>
      <Menu isLazy={true} placement={"top"} gutter={24}>
        <Tooltip
          label={ariaLabel}
          aria-label={ariaLabel}
          portalProps={{
            appendToParentPortal: true,
            containerRef: buttonRef,
          }}
        >
          <MenuButton
            as={StandardIconButton}
            {...ICON_BUTTON_STYLE_PROPS}
            aria-label={ariaLabel}
            icon={icon}
            ref={buttonRef}
          />
        </Tooltip>
        <MenuList p={0}>
          <Box shadow={"lg"}>{children}</Box>
        </MenuList>
      </Menu>
    </Box>
  );
};
