import {
  TIntegrationOffice,
  TIntegrationOfficeRelation,
} from "types/integration-sync-office.type";
import {
  TSmeBranch,
  TSmeBranchRelation,
  TSmeEmployee,
  TSmeEmployeeRelation,
  TSmeEmployeeRelationData,
  TSmeSource,
  TSmeSourceRelation,
} from "types/sme.type";
import {
  mapIntegrationOfficeRelationsToSmeBranchRelations,
  mapSmeBranchRelationsToIntegrationOfficeRelations,
  smeBranchDataToIntegrationOfficeDataMapper,
} from "utils/sme.utils";
import { BaseApi } from "./base.api";

export class AccountSmeApi extends BaseApi {
  async fetchAccountOffices(accountId: number): Promise<TIntegrationOffice[]> {
    const data = await this.get<TSmeBranch[]>(
      `/accounts/${accountId}/sme/sme-branches`
    );
    const mappedData = smeBranchDataToIntegrationOfficeDataMapper(data);
    return mappedData;
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    const data = await this.get<TSmeBranchRelation[]>(
      `/accounts/${accountId}/sme/branch-relations`
    );
    const mappedData = mapSmeBranchRelationsToIntegrationOfficeRelations(data);
    return mappedData;
  }

  async fetchAccountEmployees(accountId: number): Promise<TSmeEmployee[]> {
    return this.get(`accounts/${accountId}/sme/sme-employees`);
  }

  async fetchAccountEmployeeRelations(
    accountId: number
  ): Promise<TSmeEmployeeRelation[]> {
    return this.get(`accounts/${accountId}/sme/employee-relations`);
  }

  async fetchAccountSources(accountId: number): Promise<TSmeSource[]> {
    return this.get(`accounts/${accountId}/sme/sme-sources`);
  }

  async fetchAccountSourceRelations(
    accountId: number
  ): Promise<TSmeSourceRelation[]> {
    return this.get(`/accounts/${accountId}/sme/source-relations`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelation[]
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/branch-relations`, {
      items: mapIntegrationOfficeRelationsToSmeBranchRelations(relationsData),
    });
  }

  async updateSyncedAccountEmployees(
    accountId: number,
    relationsData: TSmeEmployeeRelationData[]
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/employee-relations`, {
      items: relationsData,
    });
  }

  async updateSyncedAccountSources(
    accountId: number,
    relationsData: TSmeSourceRelation[]
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/source-relations`, {
      items: relationsData,
    });
  }
}
