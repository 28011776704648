import React, { useCallback, useMemo } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import {
  TAccountIntegrationData,
  TDripConfig,
} from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ToggleIntegrationActiveSwitchInput } from "../ToggleIntegrationActiveSwitchInput";

const validationSchema = Yup.object().shape({
  dripApiKey: Yup.string().required(REQUIRED_FIELD),
  dripAccountId: Yup.string().required(REQUIRED_FIELD),
  active: Yup.boolean().required(REQUIRED_FIELD),
});

type TFieldValues = {
  active: boolean;
  dripApiKey: string;
  dripAccountId: string;
};

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
}

export const DripIntegrationForm: React.FC<IProps> = observer(
  ({ accountIntegrationsStore }) => {
    const toast = useToast();
    const accountIntegration = accountIntegrationsStore
      .accountIntegrationsMapByType[
      AccountIntegrationTypeEnum.DRIP
    ] as TAccountIntegrationData<TDripConfig>;

    const initialValues: TFieldValues = useMemo(
      () => ({
        active: accountIntegration.active,
        dripApiKey: accountIntegration.integrationConfig.dripApiKey,
        dripAccountId: accountIntegration.integrationConfig.dripAccountId,
      }),
      [accountIntegration]
    );

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const { active } = watch();

    const onSubmit = useCallback(
      async ({ dripApiKey, dripAccountId, active }: TFieldValues) => {
        try {
          await accountIntegrationsStore.updateAccountIntegration<TDripConfig>(
            accountIntegration.id,
            {
              type: accountIntegration.type,
              active: active,
              integrationConfig: {
                dripApiKey,
                dripAccountId,
              },
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "Drip integration configuration updated.",
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [
        accountIntegration.id,
        accountIntegration.type,
        accountIntegrationsStore,
        toast,
      ]
    );

    return (
      <Box w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ToggleIntegrationActiveSwitchInput<TFieldValues>
            label={"Connect with Drip"}
            name={"active"}
            value={active}
            control={control}
          />
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"dripApiKey"}
              control={control}
              label={"Drip API Token"}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "Drip API Token",
              }}
            />
          </Box>
          <Box mb={5}>
            <FormControlV2<TFieldValues>
              name={"dripAccountId"}
              label={"Drip Account ID"}
              control={control}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "Drip Account ID",
              }}
            />
          </Box>
          <Box
            mb={5}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          ></Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
