import React, { ReactNode } from "react";
import { Box, Divider, Flex } from "@chakra-ui/react";

interface IProps {
  children: ReactNode;
}

export const LeadBlockHeader: React.FC<IProps> = props => {
  const { children } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      pl={5}
      pt={5}
      lineHeight="none"
      fontSize={"sm"}
    >
      <Flex as={"h2"} fontWeight={500} w={"100%"} alignItems={"center"} gap={4}>
        <Box whiteSpace={"nowrap"}>{children}</Box>
        <Divider />
      </Flex>
    </Box>
  );
};
