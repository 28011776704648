import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { sortBy } from "lodash";
import { getLeadSourceLabel } from "utils/lead-source.utils";
import { Box, Circle, HStack } from "@chakra-ui/react";
import { sourceColors } from "constants/colors";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";
import { LeadsFilterNestedMenu } from "./LeadFilterMenu/LeadsFilterNestedMenu";
import { TLeadFilterNestedMenuItemData } from "types/leads-filter.type";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
}

const FILTER_TITLE = "Source";
export const FilterLeadsSourceHeader: FC<IProps> = observer(
  ({ leadsFilterStore, leadSourcesMapBySource }) => {
    const {
      activeFilter,
      availableFilters,
      pendingFilter,
      togglePendingFilterSourceAndHost,
    } = leadsFilterStore;

    const sourcesOptions: TLeadFilterNestedMenuItemData[] = useMemo(() => {
      if (!availableFilters || !availableFilters.sources) return [];

      return sortBy(availableFilters.sources, source =>
        getLeadSourceLabel(source.id, leadSourcesMapBySource[source.id])
      ).map(source => ({
        value: source.id,
        label: (
          <HStack spacing={2}>
            <Circle
              size={"10px"}
              background={sourceColors[source.id]?.statsColor}
            />
            <Box>
              {getLeadSourceLabel(source.id, leadSourcesMapBySource[source.id])}
            </Box>
          </HStack>
        ),
        count: source.count,
        nestedOptions: source.hosts
          .filter(host => host.id !== "other")
          .map(host => ({
            value: host.id,
            label: <Box>{host.id}</Box>,
            count: host.count,
          })),
      }));
    }, [availableFilters, leadSourcesMapBySource]);

    return (
      <LeadFilterHeaderCell title={FILTER_TITLE}>
        <LeadsFilterNestedMenu
          optionLabel={`${FILTER_TITLE}s`}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterType={LeadSimpleFilterTypes.SOURCES}
          handleFilterItemToggle={togglePendingFilterSourceAndHost}
          availableOptions={sourcesOptions}
          selectedOptions={pendingFilter.sources}
          appliedOptions={activeFilter?.sources}
          leadsFilterStore={leadsFilterStore}
        />
      </LeadFilterHeaderCell>
    );
  }
);
