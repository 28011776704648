import { VStack } from "@chakra-ui/react";
import React, { FC } from "react";

interface IProps {
  children: React.ReactNode;
  disabled?: boolean;
}

export const IntegrationConfiguratorLayout: FC<IProps> = ({
  children,
  disabled,
}) => {
  return (
    <VStack
      width={"100%"}
      alignItems={"flex-start"}
      spacing={4}
      mt={10}
      pointerEvents={disabled ? "none" : "all"}
      opacity={disabled ? "0.8" : "1"}
    >
      {children}
    </VStack>
  );
};
