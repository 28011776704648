export const PHONE_AUTO_ATTENDANT_REGEX = /^[\d,]*$/;

// source: https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation/8027444
export const HEX_COLOR_REGEX = new RegExp(/^#([0-9A-F]{3}){1,2}$/i);

// source: https://stackoverflow.com/questions/55470171/yup-domain-name-validation-for-punycode-and-cyrillic
export const DOMAIN_NAME_REGEX = new RegExp(
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
);

// source: https://ideal-postcodes.co.uk/guides/postcode-validation
export const UK_POSTCODE_REGEX = new RegExp(
  /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
);
export const UK_POSTCODE_REGEX_WITH_ELEMENTS = /^([A-Z]{1,2})([0-9][0-9A-Z]?(([0-9][A-Z]{2})|(\s+[0-9]([A-Z]{2})?))?)?$/i;

export const VALID_SMS_ORIGIN_REGEX = /^[a-z0-9 .]+$/i;
