import { observer } from "mobx-react";
import { Box, Button, Divider, Flex, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, FormControlLabel, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import * as Yup from "yup";
import { NUMBERS_ONLY, REQUIRED_FIELD } from "constants/validator-messages";
import { TAccountLeadAttributionCalculationConfig } from "types/account-lead-attribution-analyticis.type";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";

type TFieldValues = TAccountLeadAttributionCalculationConfig;

const validationSchema = Yup.object({
  averageValueForSaleCompletion: Yup.number()
    .min(1)
    .required(REQUIRED_FIELD)
    .typeError(NUMBERS_ONLY),
  averageValueForLettingInstruction: Yup.number()
    .min(1)
    .required(REQUIRED_FIELD)
    .typeError(NUMBERS_ONLY),
});

interface IProps {
  accountAnalyticsStore: AccountAnalyticsStore;
  onClose: () => void;
}

export const LeadAttributionCalculationValuesSettingsContent: FC<IProps> = observer(
  ({ accountAnalyticsStore, onClose }) => {
    const toast = useToast();
    const accountAnalyticsLeadAttributionStore =
      accountAnalyticsStore.accountAnalyticsLeadAttributionStore;
    const leadAttributionAnalyticsCalculationConfig =
      accountAnalyticsLeadAttributionStore.leadAttributionAnalyticsCalculationConfig;

    const initialValues = useMemo(
      () => ({
        averageValueForSaleCompletion:
          leadAttributionAnalyticsCalculationConfig?.averageValueForSaleCompletion ||
          1000,
        averageValueForLettingInstruction:
          leadAttributionAnalyticsCalculationConfig?.averageValueForLettingInstruction ||
          500,
      }),
      [leadAttributionAnalyticsCalculationConfig]
    );

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          await accountAnalyticsLeadAttributionStore.updateLeadAttributionAnalyticsCalculationConfig(
            values
          );
          onClose();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [accountAnalyticsLeadAttributionStore, onClose, toast]
    );

    return (
      <Box color={"leadpro.500"}>
        <Box fontSize={"sm"} mb={10}>
          Your estimated attribution values are calculated from your specified
          fixed averages below. You can update these averages to influence the
          calculated attribution values presented on your report.
        </Box>
        <Box mb={10}>
          <VStack spacing={5} width={"100%"} alignItems={"stretch"}>
            <Flex alignItems={"flex-start"} gap={5}>
              <FormControlLabel
                height={"35px"}
                width={"250px"}
                alignContent={"center"}
              >
                Average value of a <b>sale completion</b>
              </FormControlLabel>
              <Flex alignItems={"flex-start"} gap={2}>
                <Box height={"35px"} alignContent={"center"}>
                  £
                </Box>
                <FormControlV2<TFieldValues>
                  name={"averageValueForSaleCompletion"}
                  control={control}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    size: "sm",
                  }}
                />
              </Flex>
            </Flex>
            <Flex alignItems={"flex-start"} gap={5}>
              <FormControlLabel
                height={"35px"}
                width={"250px"}
                alignContent={"center"}
              >
                Average value of a <b>letting instruction</b>
              </FormControlLabel>
              <Flex alignItems={"flex-start"} gap={2}>
                <Box height={"35px"} alignContent={"center"}>
                  £
                </Box>
                <FormControlV2<TFieldValues>
                  name={"averageValueForLettingInstruction"}
                  control={control}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    size: "sm",
                  }}
                />
              </Flex>
            </Flex>
          </VStack>
        </Box>
        <Divider />
        <Box width={"100%"} textAlign={"right"} pt={3} pb={2}>
          <Button
            width={"105px"}
            colorScheme={"blue"}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Box>
    );
  }
);
