import { useMemo } from "react";
import {
  leadBlockHoc,
  ILeadBlockDefinition,
  LeadBlockTypes,
} from "utils/hoc/leadBlock.hoc";
import { chain, groupBy } from "lodash";
import moment from "moment";
import { Box, Divider, Tag } from "@chakra-ui/react";
import { DATE_YEAR_MONTH, DATE_YEAR_MONTH_DAY } from "constants/date";
import { LeadBlockHeader } from "../LeadBlockHeader";
import { LeadBlockBody } from "../LeadBlockBody";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { getLeadBookingRequestLabel } from "utils/lead-type.utils";

export interface LeadBookingRequestBlockDefinition
  extends ILeadBlockDefinition {
  blockType: LeadBlockTypes.BookingRequest;
  type: LeadTypeEnum;
  hours: string[];
}

export const LeadBookingRequestBlock = leadBlockHoc<
  LeadBookingRequestBlockDefinition
>(props => {
  const {
    leadBlockDefinition: { hours, type },
  } = props;

  const label = useMemo(() => {
    return getLeadBookingRequestLabel(type);
  }, [type]);

  const groupedMomentHours = useMemo(() => {
    const sortedHours = [...hours].sort();
    const momentHours = sortedHours.map(h => moment(h));
    return chain(momentHours)
      .groupBy(mh => mh.format(DATE_YEAR_MONTH))
      .mapValues(values => {
        return groupBy(values, mh => mh.format(DATE_YEAR_MONTH_DAY));
      })
      .value();
  }, [hours]);

  return (
    <>
      <LeadBlockHeader>{label}</LeadBlockHeader>
      <LeadBlockBody>
        {Object.entries(groupedMomentHours).map(([month, days]) => {
          return (
            <Box key={month}>
              <Box
                textTransform={"uppercase"}
                fontWeight="medium"
                letterSpacing="wide"
                color="gray.600"
                mb={2}
              >
                {moment(month).format("MMMM")}
              </Box>
              {Object.entries(days).map(([day, hours]) => {
                return (
                  <Box key={day}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box
                        whiteSpace="nowrap"
                        mr={2}
                        fontWeight="medium"
                        color="gray.600"
                      >
                        {moment(day).format("ddd Do")}
                      </Box>
                      <Divider />
                    </Box>
                    <Box>
                      {hours.map(h => (
                        <Tag
                          color="gray.800"
                          key={h?.toString()}
                          variant="outline"
                          mr={2}
                          mb={2}
                        >
                          {h.format("HH:mm")}
                        </Tag>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </LeadBlockBody>
    </>
  );
});
