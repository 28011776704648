import { Box, Checkbox } from "@chakra-ui/react";
import { ChangeEvent, useCallback } from "react";

export const RowSelectionCheckboxCell = ({
  indeterminate,
  checked,
  onChange,
}: any) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <Box
      px={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      height={"100%"}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        isChecked={checked}
        isIndeterminate={indeterminate}
        onChange={handleChange}
      />
    </Box>
  );
};
