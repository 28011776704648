import { observer } from "mobx-react";
import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import {
  FormToolPageActionConditionGroupOperatorOptions,
  NEW_DEFAULT_CONDITION_EXPRESSION,
  NEW_DEFAULT_CONDITION_GROUP,
} from "constants/form-tool-page";
import {
  Expression,
  Group,
  GroupOperators,
  Node,
  validateFormEndingPages,
} from "@leadpro/forms";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";
import { TSelectValue } from "types/select-input.type";
import { SingleSelectInput, StandardIconButton, Tooltip } from "components";
import { SelectInputSizes } from "../../../../../../../components/select-input/select-input-size";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/sharp-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { getErrorForConditionGroup } from "utils/form-tool-page.utils";
import { FormToolPageSettingsConfigConditionExpression } from "./FormToolPageSettingsConfigConditionExpression";

export interface IFormToolPageSettingsConfigConditionGroupProps {
  isRoot: boolean;
  childIndex: number;
  group: Group;
  onChange: (childIndex: number, newConfiguration?: Group) => void;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
  validationResult: ReturnType<typeof validateFormEndingPages>;
  validationPath: (string | number)[];
}

export const FormToolPageSettingsConfigConditionGroup: FC<IFormToolPageSettingsConfigConditionGroupProps> = observer(
  ({
    isRoot,
    childIndex,
    group,
    onChange,
    formToolPageWIPFormStore,
    validationResult,
    validationPath,
  }) => {
    const errorMessage = useMemo(() => {
      return getErrorForConditionGroup(
        [...validationPath, "children"],
        validationResult
      );
    }, [validationResult, validationPath]);

    const handleRemoveGroup = useCallback(() => {
      onChange(childIndex);
    }, [onChange, childIndex]);

    const onChildChange = useCallback(
      (newConfigChildIndex: number, newConfiguration?: Node) => {
        const children = [...group.children];

        if (!!newConfiguration) {
          children[newConfigChildIndex] = newConfiguration;
        } else {
          children.splice(newConfigChildIndex, 1);
        }

        onChange(childIndex, {
          ...group,
          children,
        });
      },
      [childIndex, group, onChange]
    );

    const onGroupOperatorChange = useCallback(
      (value: TSelectValue<GroupOperators>) => {
        if (!!value) {
          onChange(childIndex, {
            ...group,
            operator: value,
          });
        }
      },
      [onChange, childIndex, group]
    );

    const handleAddConditionGroup = useCallback(() => {
      onChange(childIndex, {
        ...group,
        children: [...group.children, { ...NEW_DEFAULT_CONDITION_GROUP }],
      });
    }, [onChange, childIndex, group]);

    const handleAddConditionExpression = useCallback(() => {
      onChange(childIndex, {
        ...group,
        children: [...group.children, { ...NEW_DEFAULT_CONDITION_EXPRESSION }],
      });
    }, [onChange, childIndex, group]);

    return (
      <VStack spacing={2} width={"100%"}>
        <HStack spacing={2} width={"100%"}>
          {!isRoot && <Box width={4} height={"2px"} background={"gray.100"} />}
          <Box flexGrow={0}>If</Box>
          <Box flexGrow={0}>
            <SingleSelectInput<GroupOperators>
              size={SelectInputSizes.SMALL}
              value={group.operator}
              options={FormToolPageActionConditionGroupOperatorOptions}
              onChange={onGroupOperatorChange}
            />
          </Box>
          <Box flexGrow={1}>of the following conditions apply:</Box>
          <HStack spacing={1} alignItems={"center"} pr={!isRoot ? 6 : 0}>
            {errorMessage && (
              <Tooltip
                aria-label={`expression-group-${childIndex}-tooltip`}
                label={errorMessage}
              >
                <Box color={"red.500"} fontSize={"lg"}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Box>
              </Tooltip>
            )}
            <StandardIconButton
              onClick={handleRemoveGroup}
              aria-label={"remove-condition-group"}
              icon={<FontAwesomeIcon icon={faXmark} />}
            />
          </HStack>
        </HStack>
        <VStack
          width={"100%"}
          spacing={2}
          px={!isRoot ? 6 : 0}
          alignItems={"flex-start"}
        >
          {!group.children.length && <Box pl={6}>No conditions added.</Box>}
          {group.children.map((groupChild, index) => {
            if (!!(groupChild as Group).children) {
              return (
                <FormToolPageSettingsConfigConditionGroup
                  key={`${childIndex || 0}-${index}`}
                  group={groupChild as Group}
                  onChange={onChildChange}
                  childIndex={index}
                  isRoot={false}
                  formToolPageWIPFormStore={formToolPageWIPFormStore}
                  validationResult={validationResult}
                  validationPath={[...validationPath, "children", index]}
                />
              );
            } else {
              return (
                <FormToolPageSettingsConfigConditionExpression
                  key={`${childIndex || 0}-${index}`}
                  expression={groupChild as Expression}
                  onChange={onChildChange}
                  childIndex={index}
                  formToolPageWIPFormStore={formToolPageWIPFormStore}
                  validationResult={validationResult}
                  validationPath={[...validationPath, "children", index]}
                />
              );
            }
          })}
          <HStack spacing={4} py={2} pl={6}>
            <Button variant={"link"} onClick={handleAddConditionGroup}>
              + Add condition group
            </Button>
            <Button variant={"link"} onClick={handleAddConditionExpression}>
              + Add condition
            </Button>
          </HStack>
        </VStack>
      </VStack>
    );
  }
);
