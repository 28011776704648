import React from "react";
import { List, ListItem, Box, Text } from "@chakra-ui/react";

export const ResponseTimeTooltip = () => {
  return (
    <Box>
      <Text mb={2} textAlign={"justify"}>
        You gain points for how quickly you respond to leads. Your score is an
        average so that high and low volume offices are fairly compared.
      </Text>
      <Box mb={2} textAlign={"justify"}>
        <Text>Response Time</Text>
        <List styleType="disc" ml={10}>
          <ListItem>0-15 minutes = 100 points</ListItem>
          <ListItem>15-60 minutes = 80 points</ListItem>
          <ListItem>1-6 hours = 60 points</ListItem>
          <ListItem>6-24 hours = 40 points</ListItem>
          <ListItem>over 24 hours = 20 points</ListItem>
        </List>
      </Box>
      <Text mb={2} textAlign={"justify"}>
        Your score calculated by your Response time score for each lead, added
        and divided by the number of leads.
      </Text>
    </Box>
  );
};
