import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  IconDefinition,
  faExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faInfo } from "@fortawesome/pro-regular-svg-icons";

export const IntegrationEntitySyncStatusLabel: {
  [key in IntegrationEntitySyncStatusEnum]: string;
} = {
  [IntegrationEntitySyncStatusEnum.SYNCED]: "Mapped",
  [IntegrationEntitySyncStatusEnum.NOT_SYNCED]: "Unmapped",
  [IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC]: "Suggested",
};

export const IntegrationEntitySyncStatusTagColor: {
  [key in IntegrationEntitySyncStatusEnum]: string;
} = {
  [IntegrationEntitySyncStatusEnum.SYNCED]: "green",
  [IntegrationEntitySyncStatusEnum.NOT_SYNCED]: "red",
  [IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC]: "orange",
};

export const IntegrationEntitySyncStatusTagIcon: {
  [key in IntegrationEntitySyncStatusEnum]: IconDefinition;
} = {
  [IntegrationEntitySyncStatusEnum.SYNCED]: faCheck,
  [IntegrationEntitySyncStatusEnum.NOT_SYNCED]: faExclamation,
  [IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC]: faInfo,
};
