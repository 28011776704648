import React, { useCallback } from "react";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ManualAddLeadPrompt } from "routes/dashboard/components/prompts/ManualAddLeadPrompt/ManualAddLeadPrompt";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";

interface IProps {
  accountStore: UserAccountStore;
}

export const ManualAddLeadButton: React.FC<IProps> = observer(
  ({ accountStore }) => {
    const { setModal, unSetModal } = useActionPrompt();

    const handleAddLead = useCallback(() => {
      setModal(
        <ManualAddLeadPrompt
          accountStore={accountStore}
          closePrompt={unSetModal}
        />
      );
    }, [setModal, unSetModal, accountStore]);

    return (
      <Button
        variant={"ghost"}
        size={"sm"}
        onClick={handleAddLead}
        colorScheme={"teal"}
        fontSize={"xs"}
        leftIcon={<FontAwesomeIcon icon={faCirclePlus} fontSize="14px" />}
      >
        Add Lead
      </Button>
    );
  }
);
