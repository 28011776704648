import React, { useMemo } from "react";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { observer } from "mobx-react";
import { Redirect, useParams } from "react-router-dom";
import { SETTINGS_INTEGRATIONS_ROUTE } from "constants/routes";
import { AccountIntegrationSettingsPageContent } from "./components/AccountIntegrationSettingsPageContent";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountIntegrationSettingsPage: React.FC<IProps> = observer(
  ({ accountStore }) => {
    let { type } = useParams<{ type: AccountIntegrationTypeEnum }>();
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const staticIntegrationData =
      accountIntegrationsStore.availableAccountIntegrationsMapByType[type];

    const accountIntegration = useMemo(() => {
      return accountIntegrationsStore.accountIntegrationsMapByType[
        staticIntegrationData?.type
      ];
    }, [accountIntegrationsStore, staticIntegrationData]);

    if (!staticIntegrationData || !accountIntegration) {
      return (
        <Redirect
          to={{
            pathname: SETTINGS_INTEGRATIONS_ROUTE,
          }}
        />
      );
    }

    return (
      <AccountIntegrationSettingsPageContent
        staticData={staticIntegrationData}
        accountStore={accountStore}
      />
    );
  }
);
