import { BaseApi } from "./base.api";
import {
  TReapitIROfficeRelation,
  TReapitIROfficeRelationData,
} from "types/reapit-internet-registration.type";

export class AccountReapitInternetRegistrationApi extends BaseApi {
  async fetchAccountReapitIROfficeRelations(
    accountId: number
  ): Promise<TReapitIROfficeRelation[]> {
    return this.get(
      `/accounts/${accountId}/reapit-internet-registration/office-relations`
    );
  }

  async updateAccountReapitIRSyncedOffices(
    accountId: number,
    relations: TReapitIROfficeRelationData[]
  ) {
    await this.post(
      `/accounts/${accountId}/reapit-internet-registration/office-relations`,
      {
        items: relations,
      }
    );
  }
}
