import { Box, Center, VStack } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import {
  LeadResponderWorkflowEditorFilterBlock,
  LeadResponderWorkflowEditorTemplateBlock,
} from "./simple-blocks";
import { LeadResponderWorkflowEditorRemainderBlock } from "./complex-blocks";
import { BlockDivider } from "./BlockDivider";
import { WorkflowEditorTemplateBlockTypeEnum } from "enums/workflow-editor-template-block-type.enum";

interface IProps {
  wipWorkflow: Partial<TLeadResponderWorkflow>;
  accountStore: UserAccountStore;
  updateWipWorkflow: (workflow: Partial<TLeadResponderWorkflow>) => void;
}

export const LeadResponderWorkflowEditor: FC<IProps> = observer(
  ({ wipWorkflow, accountStore, updateWipWorkflow }) => {
    const accountPersistentFiltersStore =
      accountStore.accountPersistentFiltersStore;

    const handleApplyReplyToEmailTemplate = useCallback(
      (templateId: number | null) => {
        updateWipWorkflow({
          ...wipWorkflow,
          replyToEmailTemplateId: templateId,
        });
      },
      [updateWipWorkflow, wipWorkflow]
    );

    return (
      <Box width={"100%"} height={"100%"} bg={"gray.50"}>
        <Center
          width={"100%"}
          height={"100%"}
          overflow={"auto"}
          alignItems={"flex-start"}
          py={20}
        >
          <VStack spacing={0}>
            <LeadResponderWorkflowEditorFilterBlock
              wipWorkflow={wipWorkflow}
              accountPersistentFiltersStore={accountPersistentFiltersStore}
              updateWipWorkflow={updateWipWorkflow}
            />
            <BlockDivider />
            <LeadResponderWorkflowEditorTemplateBlock
              onApply={handleApplyReplyToEmailTemplate}
              accountStore={accountStore}
              templateId={wipWorkflow.replyToEmailTemplateId || null}
              templateType={WorkflowEditorTemplateBlockTypeEnum.EMAIL}
            />
            <BlockDivider />
            <LeadResponderWorkflowEditorRemainderBlock
              accountStore={accountStore}
              updateWipWorkflow={updateWipWorkflow}
              wipWorkflow={wipWorkflow}
              remainderEnabled={"smsReminderEnabled"}
              delayField={"smsDelay"}
              templateField={"smsTemplateId"}
              templateType={WorkflowEditorTemplateBlockTypeEnum.SMS}
            />
            <BlockDivider />
            <LeadResponderWorkflowEditorRemainderBlock
              accountStore={accountStore}
              updateWipWorkflow={updateWipWorkflow}
              wipWorkflow={wipWorkflow}
              remainderEnabled={"followUpReminderEnabled"}
              delayField={"followUpDelay"}
              templateField={"followUpEmailTemplateId"}
              templateType={WorkflowEditorTemplateBlockTypeEnum.EMAIL}
            />
          </VStack>
        </Center>
      </Box>
    );
  }
);
