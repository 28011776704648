import { FC, useCallback } from "react";
import { ApiMessageStack, Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { Box, Button, useToast } from "@chakra-ui/react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { observer } from "mobx-react";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";

interface IProps {
  leadStore: AccountLeadStore;
  leadStatusesStore: AccountLeadStatusesStore;
}

export const LeadBanner: FC<IProps> = observer(
  ({ leadStore, leadStatusesStore }) => {
    const toast = useToast();
    const { newStatus, deletedStatus } = leadStatusesStore;

    const handleUndoDelete = useCallback(async () => {
      try {
        await leadStore.update({
          statusId: newStatus?.id,
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    }, [leadStore, newStatus, toast]);

    if (leadStore.lead.statusId === deletedStatus?.id) {
      return (
        <Message flexShrink={0} status={AlertStatusEnum.ERROR} rounded={"none"}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>This lead is deleted.</Box>
            <Button colorScheme={"red"} size={"xs"} onClick={handleUndoDelete}>
              Undo
            </Button>
          </Box>
        </Message>
      );
    }

    return null;
  }
);
