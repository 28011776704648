import { Route, Switch } from "react-router-dom";
import { GUIDES_ARTICLE_ROUTE, GUIDES_ROUTE } from "constants/routes";
import { ArticlePage } from "./ArticlePage";
import {
  PanelsGroupLayout,
  RightSideLayout,
} from "routes/dashboard/components";
import { Articles } from "./Articles";

export const Guides = () => {
  return (
    <RightSideLayout>
      <PanelsGroupLayout maxW={"6xl"}>
        <Switch>
          <Route path={GUIDES_ROUTE} exact>
            <Articles />
          </Route>
          <Route path={GUIDES_ARTICLE_ROUTE} exact>
            <ArticlePage />
          </Route>
        </Switch>
      </PanelsGroupLayout>
    </RightSideLayout>
  );
};
