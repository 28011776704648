import { BaseApi } from "./base.api";
import { TExportRequest } from "../types/export-request.type";
import {
  TAutocallerConfiguration,
  TAutocallerTemplate,
} from "../types/autocaller.type";
import { TAutocallerDemoCallData } from "../types/autocalled-demo-call.type";
import { TTemplateMergeTagGroup } from "../types/template-merge-tag.type";

export class AccountAutocallerApi extends BaseApi {
  async fetchAccountAutocallerStatus(accountId: number): Promise<boolean> {
    return this.get(`/accounts/${accountId}/autocaller/status`);
  }

  async fetchAccountAutocallerConfiguration(
    accountId: number
  ): Promise<TAutocallerConfiguration> {
    return this.get(`/accounts/${accountId}/autocaller`);
  }

  async updateAccountAutocallerConfiguration(
    accountId: number,
    configuration: Partial<TAutocallerConfiguration>
  ): Promise<TAutocallerConfiguration> {
    return this.put(`/accounts/${accountId}/autocaller`, configuration);
  }

  async performAutocallerDemoForAccount(
    accountId: number,
    callData: TAutocallerDemoCallData
  ): Promise<void> {
    return this.post(`/accounts/${accountId}/autocaller/demo`, callData);
  }

  async fetchAccountAutocallerTemplate(
    accountId: number
  ): Promise<TAutocallerTemplate> {
    return this.get(`/accounts/${accountId}/autocaller/autocaller-config`);
  }

  async fetchAccountAutocallerTemplateMergeTags(
    accountId: number
  ): Promise<TTemplateMergeTagGroup[]> {
    return this.get(`/accounts/${accountId}/autocaller/merge-tags`);
  }

  async updateAutocallerTemplate(
    accountId: number,
    template: string | null
  ): Promise<TAutocallerTemplate> {
    return this.put(`/accounts/${accountId}/autocaller/autocaller-config`, {
      template,
    });
  }
  async fetchExportRequests(accountId: number): Promise<TExportRequest[]> {
    return this.get(`/accounts/${accountId}/export/iovox-stats-csv`);
  }

  async createExportRequest(
    accountId: number,
    month: string
  ): Promise<TExportRequest> {
    return this.post(`/accounts/${accountId}/export/iovox-stats-csv`, {
      month,
    });
  }
}
