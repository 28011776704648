import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { LeadFilterSimpleMenu } from "./LeadFilterMenu/LeadFilterSimpleMenu";
import { toSpace } from "utils/to-snake-case.utils";
import { LeadQuestionnaireAnsweredEnum } from "enums/lead-questionnaire-answered.enum";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const FilterLeadsQuestionnaireHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const {
      activeFilter,
      availableFilters,
      pendingFilter,
      togglePendingQuestionnaireStatus,
    } = leadsFilterStore;

    const questionnaireStatusOptions = useMemo(() => {
      if (!availableFilters || !availableFilters.questionnaireAnswered)
        return [];

      return availableFilters.questionnaireAnswered.map(status => ({
        value: status.id,
        label: <Box textTransform={"capitalize"}>{toSpace(status.id)}</Box>,
        count: status.count,
      }));
    }, [availableFilters]);

    return (
      <LeadFilterHeaderCell title={"Lead"}>
        <LeadFilterSimpleMenu
          optionLabel={"Questionnaire answers"}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterType={LeadSimpleFilterTypes.QUESTIONNAIRE_ANSWERED}
          availableOptions={questionnaireStatusOptions}
          selectedOptions={pendingFilter.questionnaireAnswered}
          appliedOptions={activeFilter?.questionnaireAnswered}
          handleFilterItemToggle={(value: LeadQuestionnaireAnsweredEnum) =>
            togglePendingQuestionnaireStatus(value)
          }
          leadsFilterStore={leadsFilterStore}
        />
      </LeadFilterHeaderCell>
    );
  }
);
