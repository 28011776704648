import { Input, InputProps } from "./Input";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { debounce } from "lodash";

const INPUT_DELAY = 300;

interface IDelayedInput extends InputProps {
  inputValue?: string;
  delay?: number;
}

export const DelayedInput: React.FC<IDelayedInput> = ({
  onChange,
  inputValue,
  delay = INPUT_DELAY,
  ...rest
}) => {
  const [value, setValue] = React.useState(inputValue);
  const delayedChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  }, delay);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.persist();
      delayedChange(e);
      setValue(e.target.value);
    },
    [delayedChange]
  );
  return <Input {...rest} value={value} onChange={onInputChange} />;
};
