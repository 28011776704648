import {
  IChartistAutoScaleAxis,
  IChartistFixedScaleAxis,
  IChartistStepAxis,
} from "chartist";
import { IChartProps } from "./interfaces";

type IChartistAxis =
  | IChartistStepAxis
  | IChartistFixedScaleAxis
  | IChartistAutoScaleAxis;

export const createXAxisOptions = (
  props: IChartProps
): { axisX: IChartistAxis } => {
  const totalLabels = props.labels.length;
  const maxLabels = Math.min(totalLabels, 8);
  const distance = (totalLabels - 1) / (maxLabels - 1);

  let current = 0;
  const positions = [0];
  while (current < totalLabels) {
    current += distance;
    positions.push(Math.round(current));
  }

  return {
    axisX: {
      labelInterpolationFnc: function(value: any, index: number) {
        return positions.includes(index) ? value : null;
      },
    },
  };
};

export const overrideXLabelPositions = (data: any) => {
  if (data.type === "label" && data.axis.units.pos === "x") {
    // data.element._node.setAttribute()
    const dateWidthText = 75;
    data.element._node.setAttribute(
      "style",
      `width: ${dateWidthText}px; height: 20px;`
    );
    data.element._node.setAttribute("width", `${dateWidthText}`);
    data.element._node.setAttribute("x", `${data.x - dateWidthText / 2}`);
    data.element._node.children[0].setAttribute(
      "style",
      `width: ${dateWidthText}px; height: 20px;`
    );
  }
};
