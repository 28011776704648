import { TourBase } from "../TourBase/TourBase";
import React, { useMemo } from "react";
import { ITourStepDefinition } from "../TourBase/TourStepFooter";
import { Box, Stack } from "@chakra-ui/react";
import { PromptOptionButton } from "components";
import { faPlay, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { TOOL_TOURS_LINKS, ToolTours } from "constants/tool-tours-links";
import { useHistory } from "react-router-dom";

interface IProps {
  tourId: ToolTours;
}

export const ToolTour: React.FC<IProps> = ({ tourId }) => {
  const history = useHistory();

  const steps = useMemo(() => {
    const steps: ITourStepDefinition[] = [
      {
        content: (
          <Box width={"500px"}>
            <Box fontSize={"xl"} fontWeight={600} mb={2}>
              Help &amp; support
            </Box>
            <Stack>
              <PromptOptionButton
                title="Book a free webinar"
                icon={faUsers}
                color="teal"
                onClick={() =>
                  window.open(TOOL_TOURS_LINKS[tourId].webinarLink)
                }
              />
              <PromptOptionButton
                title="Watch the setup guide"
                icon={faPlay}
                color="teal"
                onClick={() => history.push(TOOL_TOURS_LINKS[tourId].guideLink)}
              />
            </Stack>
          </Box>
        ),
      },
    ];

    return steps;
  }, [history, tourId]);

  return <TourBase tourId={tourId} steps={steps} showCloseButton={true} />;
};
