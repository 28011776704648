import { Box, BoxProps, Center } from "@chakra-ui/react";
import { FC } from "react";

interface ICenteredMessageProps {
  message: React.ReactNode;
  height?: BoxProps["height"];
  color?: BoxProps["color"];
}

export const CenteredMessage: FC<ICenteredMessageProps> = ({
  message,
  height = "100%",
  color = "gray.400",
}) => {
  return (
    <Center width={"100%"} height={height}>
      <Box color={color}>{message}</Box>
    </Center>
  );
};
