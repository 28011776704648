import React from "react";
import { FormControlMeta, FormControlV2 } from "components";
import { Control, Path } from "react-hook-form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { PageToolPageSettingsInputLayout } from "./PageToolPageSettingsInputLayout";

interface IProps<T> {
  control: Control<T>;
  titleField: Path<T>;
  bodyField: Path<T>;
  ctaLabelField: Path<T>;
  ctaUrlField: Path<T>;
}

export function PageToolThankYouPageSettings<T>({
  control,
  titleField,
  bodyField,
  ctaLabelField,
  ctaUrlField,
}: IProps<T>) {
  return (
    <>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label={"Thank you title"}
          description={"Provide a title for your thank you message."}
        />
        <FormControlV2<T>
          name={titleField}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Thank you for your enquiry.",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label={"Thank you message"}
          description={"Provide a message body for your thank you message."}
        />
        <FormControlV2<T>
          name={bodyField}
          control={control}
          type={FormControlsTypeEnum.MARKDOWN}
          additionalProps={{
            placeholder:
              "Thank you for your enquiry. One of the team will be in contact soon.",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label={"Action button label"}
          description={"Optionally provide a label for your action button."}
        />
        <FormControlV2<T>
          name={ctaLabelField}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Visit our page.",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label={"Action button link"}
          description={
            "Optionally include a button on your thank you page to link users elsewhere."
          }
        />
        <FormControlV2<T>
          name={ctaUrlField}
          control={control}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "https://www.example.com",
          }}
        />
      </PageToolPageSettingsInputLayout>
    </>
  );
}
