import React, { useCallback, useMemo } from "react";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import { ReactourStep, ReactourStepContentArgs } from "reactour";

export interface ITourStepDefinition extends ReactourStep {
  actionBeforeNextStep?: () => Promise<void>;
  actionBeforePreviousStep?: () => Promise<void>;
  content: React.ReactNode;
}

interface IProps {
  stepContentArgs: ReactourStepContentArgs;
  stepDefinition: ITourStepDefinition;
  totalSteps: number;
  isStepSubmitting: boolean;
}

export const TourStepFooter: React.FC<IProps> = ({
  stepContentArgs,
  stepDefinition,
  totalSteps,
  isStepSubmitting,
}) => {
  const { step, goTo, close } = stepContentArgs;

  const { actionBeforeNextStep, actionBeforePreviousStep } = stepDefinition;

  const leftButton = useMemo(() => {
    if (step === 1) {
      return (
        <Button isLoading={isStepSubmitting} variant={"link"} onClick={close}>
          Skip the tour
        </Button>
      );
    }

    if (step === totalSteps) {
      return null;
    }

    return (
      <Button isLoading={isStepSubmitting} variant={"link"} onClick={close}>
        End tour
      </Button>
    );
  }, [step, totalSteps, close, isStepSubmitting]);

  const goToNext = useCallback(async () => {
    if (!!actionBeforeNextStep) {
      await actionBeforeNextStep();
    }
    goTo(step);
  }, [goTo, step, actionBeforeNextStep]);

  const goToPrevious = useCallback(async () => {
    if (!!actionBeforePreviousStep) {
      await actionBeforePreviousStep();
    }
    goTo(step - 2);
  }, [goTo, step, actionBeforePreviousStep]);

  const rightButtons = useMemo(() => {
    if (step === 1) {
      return (
        <Button onClick={goToNext} colorScheme={"blue"}>
          Start tour
        </Button>
      );
    }

    if (step === totalSteps) {
      return (
        <Button
          isLoading={isStepSubmitting}
          onClick={close}
          colorScheme={"blue"}
        >
          End tour
        </Button>
      );
    }

    return (
      <ButtonGroup variant={"outline"} size={"sm"} isAttached={true}>
        <Button
          onClick={goToPrevious}
          mr="-px"
          fontSize={"xs"}
          fontWeight={500}
        >
          PREV
        </Button>
        <Button onClick={goToNext} fontSize={"xs"} fontWeight={500}>
          NEXT
        </Button>
      </ButtonGroup>
    );
  }, [step, totalSteps, goToPrevious, goToNext, close, isStepSubmitting]);

  return (
    <Box
      px={6}
      py={3}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>{leftButton}</Box>
      <Box>{rightButtons}</Box>
    </Box>
  );
};
