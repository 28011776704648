import { Box } from "@chakra-ui/react";
import React from "react";
import { accountProductsConfigMap } from "constants/account-products-config";
import { ApplicationProductEnum } from "enums/application-product.enum";

const productConfig =
  accountProductsConfigMap[ApplicationProductEnum.INSTANT_VALUATION_TOOL];

export const InstantValuationToolPricing = () => {
  return (
    <Box width={"100%"}>
      <Box pb={6} fontSize={"3xl"} fontWeight={700}>
        Pricing
      </Box>
      <Box
        p={6}
        border="1px solid"
        rounded="md"
        borderColor="gray.100"
        width={"100%"}
      >
        <Box fontSize="4xl" display="flex" lineHeight="none" mb={2}>
          {`£${productConfig.pricing.flatFee} `}
          <Box fontSize="md" mt="auto" ml={1} mb="3px" color="gray.500">
            / month
          </Box>
        </Box>
        <Box fontSize="sm" color="gray.500">
          {`Includes ${
            productConfig.pricing.unitsIncluded
          } leads per month. Thereafter, leads outside the monthly
            allowance are charged at ${productConfig.pricing
              .pricePerExtraUnit! * 100}p per lead.`}
        </Box>
      </Box>
    </Box>
  );
};
