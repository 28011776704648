import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { StreetIntegrationForm } from "./StreetIntegrationForm";
import { StreetIntegrationOfficesConfigurator } from "./StreetIntegrationOfficesConfigurator/StreetIntegrationOfficeConfigurator";

interface IStreetIntegrationConfiguratorProps {
  accountStore: UserAccountStore;
}

export const StreetIntegrationConfigurator: FC<IStreetIntegrationConfiguratorProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;

    return (
      <>
        <StreetIntegrationForm
          accountIntegrationsStore={accountIntegrationsStore}
        />
        <StreetIntegrationOfficesConfigurator accountStore={accountStore} />
      </>
    );
  }
);
