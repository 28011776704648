import { Box, BoxProps } from "@chakra-ui/react";
import React, { FC } from "react";

export const PanelHeader: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      px={6}
      py={4}
      borderBottom="1px solid"
      borderColor="gray.100"
      {...rest}
    >
      {children}
    </Box>
  );
};
