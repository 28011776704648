import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
  Loader,
  Error,
} from "components";
import * as Yup from "yup";
import { REQUIRED_FIELD, BAD_NAME } from "constants/validator-messages";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountOfficesStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffices.store";
import { observer } from "mobx-react";
import { TAccountOfficeData } from "types/account-office.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  officeName: string;
};

const initialValues: TFieldValues = {
  officeName: "",
};

interface IProps {
  officesStore: AccountOfficesStore;
  office: TAccountOfficeData;
  closePrompt: () => void;
}
export const DeleteOfficePrompt: React.FC<IProps> = observer(
  ({ officesStore, office, closePrompt }) => {
    const toast = useToast();

    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    const officeStore = useMemo(
      () => officesStore.accountOfficeStoresMap[office.id],
      [office.id, officesStore.accountOfficeStoresMap]
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await officeStore.fetchOfficeLeadsCount();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };
      fetchData();
    }, [officeStore]);

    const validationSchema = useMemo(() => {
      return Yup.object().shape({
        officeName: Yup.string()
          .matches(new RegExp("^" + office.name + "$"), {
            message: BAD_NAME,
          })
          .required(REQUIRED_FIELD),
      });
    }, [office]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(async () => {
      try {
        await officesStore.deleteOffice(office.id);

        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Office has been deleted"} />
          ),
        });
        closePrompt();
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [closePrompt, office, toast, officesStore]);

    const leadsCount = useMemo(() => officeStore?.office?.leadsCount, [
      officeStore?.office?.leadsCount,
    ]);

    const leadsText = useMemo(() => {
      if (!leadsCount)
        return "There are currently no leads assigned to this office.";
      const countText = leadsCount === 1 ? "lead" : "leads";
      return (
        <>
          Deleting this office will unassign the{" "}
          <b>
            {leadsCount} {countText}
          </b>{" "}
          currently assigned to this office if you continue.
        </>
      );
    }, [leadsCount]);

    return (
      <ActionPromptContainer
        header={"Confirm deletion"}
        body={
          <>
            {loadingStatus === ApiRequestStatusEnum.PENDING && (
              <Box pt={10} pb={10} position={"relative"}>
                <Loader />
              </Box>
            )}
            {loadingStatus === ApiRequestStatusEnum.ERROR && (
              <Box pt={12} pb={12} position={"relative"}>
                <Error message="We're unable to detect all lead data in this office, please contact our support team to proceed with deleting this office" />
              </Box>
            )}
            {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
              <Box pt={5}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={5}>
                    <FormControlV2<TFieldValues>
                      name={"officeName"}
                      control={control}
                      type={FormControlsTypeEnum.TEXT}
                      label={
                        <Text lineHeight={1.5} whiteSpace={"normal"}>
                          {leadsText} Please type the name of the office below
                          to confirm deletion
                        </Text>
                      }
                      autoFocus={true}
                      additionalProps={{
                        placeholder: "Office name",
                      }}
                    />
                  </Box>
                  <Divider mb={4} />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      type={"submit"}
                      colorScheme={"red"}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Delete office
                    </Button>
                  </Box>
                </form>
              </Box>
            )}
          </>
        }
      />
    );
  }
);
