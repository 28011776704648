import React, { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Expression, Group } from "@leadpro/forms";
import { NEW_DEFAULT_CONDITION_GROUP } from "constants/form-tool-page";
import { FormToolPageWIPFormStore } from "../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";
import { FormToolPageSettingsConfigConditionConfigurator } from "../FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionConfigurator";
import { FormToolPageSettingsConfigConditionGroup } from "../FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionGroup";
import { FormToolPageSettingsConfigConditionExpression } from "../FormToolPageSettingsConfigConditionConfigurator/FormToolPageSettingsConfigConditionExpression";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsActionConditionConfigurator: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore }) => {
    const action = formToolPageWIPFormStore.wipFormActionsJS[actionIndex];
    const wipFormActionsValidationResult =
      formToolPageWIPFormStore.wipFormActionsValidationResult;

    const handleAddConditionGroup = useCallback(() => {
      formToolPageWIPFormStore.setActionCondition(actionIndex, {
        ...NEW_DEFAULT_CONDITION_GROUP,
      });
    }, [formToolPageWIPFormStore, actionIndex]);

    const onConditionsChange = useCallback(
      (childIndex: number, groupOrExpression?: Group | Expression) => {
        formToolPageWIPFormStore.setActionCondition(
          actionIndex,
          groupOrExpression
        );
      },
      [formToolPageWIPFormStore, actionIndex]
    );

    const rootElement = useMemo(() => {
      if (!!action.condition && (action.condition as Group).children) {
        return (
          <FormToolPageSettingsConfigConditionGroup
            isRoot={true}
            childIndex={0}
            onChange={onConditionsChange}
            group={action.condition as Group}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
            validationResult={wipFormActionsValidationResult}
            validationPath={[actionIndex, "condition"]}
          />
        );
      }

      if (!!action.condition) {
        return (
          <FormToolPageSettingsConfigConditionExpression
            childIndex={0}
            onChange={onConditionsChange}
            expression={action.condition as Expression}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
            validationResult={wipFormActionsValidationResult}
            validationPath={[actionIndex, "condition"]}
          />
        );
      }
    }, [
      action.condition,
      actionIndex,
      formToolPageWIPFormStore,
      onConditionsChange,
      wipFormActionsValidationResult,
    ]);

    return (
      <FormToolPageSettingsConfigConditionConfigurator
        hasCondition={!!action.condition}
        handleAddConditionGroup={handleAddConditionGroup}
        rootElement={rootElement}
      />
    );
  }
);
