import { Box } from "@chakra-ui/react";
import { FC } from "react";

interface IProps {
  address: string;
}

export const PropertyAddressCell: FC<IProps> = ({ address }) => {
  return (
    <Box
      height={"92px"}
      width={"100%"}
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      {address}
    </Box>
  );
};
