import React from "react";
import { NavLink } from "react-router-dom";
import { formatNumber } from "utils/formating.utils";
import { LEADS_ALL_ROUTE } from "constants/routes";
import { LeadByOption } from "enums/lead-by-option.enum";
import { toSnakeCase } from "utils/to-snake-case.utils";
import { Box, BoxProps } from "@chakra-ui/react";

const statStyle: BoxProps = {
  fontSize: "20px",
  textTransform: "capitalize",
  cursor: "pointer",
  _hover: {
    textDecoration: "underline",
  },
};
const cellStyle: BoxProps = {
  p: 3,
};

interface IStatRow {
  statKey: string;
  title: string;
  statType: LeadByOption;
  total?: number;
  last30Days?: number;
  last60Days?: number;
  colour?: string;
}

export const StatRow = (props: IStatRow) => {
  return (
    <Box
      as={"tr"}
      borderBottomWidth={"1px"}
      borderStyle={"solid"}
      borderColor={"gray.100"}
      _hover={{ bg: "gray.50" }}
      _last={{ borderBottomWidth: 0 }}
    >
      <Box as={"td"} {...cellStyle}>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            rounded={"full"}
            mr={3}
            width={"10px"}
            height={"10px"}
            backgroundColor={props.colour}
          />
          <NavLink
            exact={true}
            to={`${LEADS_ALL_ROUTE}?${props.statType}=${toSnakeCase(
              props.statKey
            )}`}
          >
            <Box {...statStyle}>{props.title}</Box>
          </NavLink>
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle} color={"green.500"}>
          {formatNumber({ number: props.total })}
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle}>
          {props.last30Days}
        </Box>
      </Box>
      <Box as={"td"} {...cellStyle}>
        <Box as={"h2"} {...statStyle}>
          {props.last60Days}
        </Box>
      </Box>
    </Box>
  );
};
