import React from "react";
import { Box } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { PremiumFeatureBlockedOverlay } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlockedOverlay";

interface LegacyLeadResponderBlockProps {
  accountStore: UserAccountStore;
  render: () => React.ReactElement;
}

export const LegacyLeadResponderBlock: React.FC<LegacyLeadResponderBlockProps> = observer(
  ({ accountStore, render }) => {
    const useOldLeadResponder = accountStore.account.useOldLeadResponder;
    const typeFormFormsLength =
      accountStore.accountTypeFormFormStore.typeformForms.length;

    if (useOldLeadResponder && !typeFormFormsLength) {
      return (
        <PremiumFeatureBlockedOverlay
          render={render}
          customBanner={
            <Box>
              For more info on your qualification settings, please reach out to
              support.
            </Box>
          }
        />
      );
    } else {
      return render();
    }
  }
);
