import React, { useMemo } from "react";
import { TUserAccountData } from "types/user-account.type";
import { Avatar, AvatarProps } from "@chakra-ui/react";
import Styles from "./Avatar.module.scss";

const DEFAULT_ASPECT_RATIO = 4 / 3;

interface IAccountAvatarProps extends AvatarProps {
  account: TUserAccountData;
  aspectRatio?: number;
  heightInPx: number;
}

export const AccountAvatar: React.FC<IAccountAvatarProps> = ({
  account,
  aspectRatio = DEFAULT_ASPECT_RATIO,
  heightInPx,
  ...rest
}) => {
  const { name, logo, bg, height, width } = useMemo(() => {
    const name = account.name?.charAt(0) || "?";
    const logo = account.logoUrl || "";
    const bg = !!logo ? "transparent" : "blue.500";
    const height = heightInPx + "px";
    const width = heightInPx * aspectRatio + "px";

    return {
      name,
      logo,
      bg,
      height,
      width,
    };
  }, [account.name, account.logoUrl, heightInPx, aspectRatio]);

  return (
    <Avatar
      className={Styles["squareAvatar"]}
      fontWeight={"500"}
      rounded={"sm"}
      size={"md"}
      name={name}
      src={logo}
      height={height}
      width={width}
      bg={bg}
      {...rest}
    />
  );
};
