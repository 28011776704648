import { ApiMessageStack, SingleDatePickerController } from "components";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { observer } from "mobx-react";

interface IProps {
  leadsStore: AccountLeadsStore;
  selectedRowIds: number[];
  setIsSubmitting: (isSubmitting: boolean) => void;
}
export const BulkUpdateNextActionDateMenu: FC<IProps> = observer(
  ({ leadsStore, selectedRowIds, setIsSubmitting }) => {
    const toast = useToast();
    const [date, setDate] = useState<string | null>(null);
    const dateRef = useRef<string | null>(null);

    const handleUpdateNextActionDate = useCallback(
      (selectedDate: string | null) => {
        setDate(selectedDate);
        dateRef.current = selectedDate;
      },
      []
    );

    useEffect(() => {
      const cleanup = async () => {
        if (!!dateRef.current) {
          try {
            setIsSubmitting(true);
            await leadsStore.updateLeads(selectedRowIds, {
              nextActionDate: dateRef.current,
            });
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          } finally {
            setIsSubmitting(false);
          }
        }
      };
      return () => {
        cleanup();
      };
    }, [leadsStore, selectedRowIds, toast, setIsSubmitting]);

    return (
      <SingleDatePickerController
        onChange={handleUpdateNextActionDate}
        date={date}
      />
    );
  }
);
