import { Box, VStack } from "@chakra-ui/react";
import { DATE_CALENDAR_FORMAT_WITH_YEAR } from "constants/date";
import moment from "moment";
import React, { FC, useMemo } from "react";
import { TLead } from "types/lead.type";
import { formatDate } from "utils/date.utils";

interface ILeadDateCellProps {
  lead: TLead;
}

export const LeadDateCell: FC<ILeadDateCellProps> = ({ lead }) => {
  const normalizedScore = useMemo(() => {
    return (lead.potentialSpeedScore || 0) * 20;
  }, [lead.potentialSpeedScore]);

  const displayDate = useMemo(() => {
    const leadCreatedAtMoment = moment(lead.createdAt);
    return leadCreatedAtMoment.fromNow();
  }, [lead.createdAt]);

  const calanderDate = useMemo(() => {
    return formatDate(lead.createdAt, DATE_CALENDAR_FORMAT_WITH_YEAR);
  }, [lead.createdAt]);

  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"} py={2}>
      <VStack spacing={0} alignItems={"left"}>
        <Box fontSize={"md"} fontWeight={"bold"}>
          {displayDate}
        </Box>
        <Box fontSize={"sm"} color={"leadpro.400"}>
          {calanderDate}
        </Box>
        {!!normalizedScore && (
          <Box color={"leadpro.800"}>
            Earn <strong>{normalizedScore} points</strong>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
