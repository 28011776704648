import { Box } from "@chakra-ui/react";
import { ActionPromptContainer, Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import React, { FC } from "react";
import { TOfficeGroupData } from "types/account-office.type";

interface ILeadReapitSyncOfficeGroupProps {
  selectOfficeGroup: (id: number) => void;
  officeGroups: TOfficeGroupData[];
}

const LeadReapitSyncOfficeGroup: FC<ILeadReapitSyncOfficeGroupProps> = ({
  selectOfficeGroup,
  officeGroups,
}) => {
  return (
    <ActionPromptContainer
      header={<Box color={"leadpro.600"}>Select Office Group</Box>}
      body={
        <Box minHeight={"100px"} position={"relative"}>
          <Box>
            <Message status={AlertStatusEnum.INFO} mb={5}>
              <Box>
                {
                  "Syncing this lead to Reapit requires that you to select the office group that you would like sync to."
                }
              </Box>
            </Message>
            <Box mb={5}>
              {officeGroups.map(officeGroup => (
                <Box
                  cursor={"pointer"}
                  mb={2}
                  key={officeGroup.id}
                  onClick={() => selectOfficeGroup(officeGroup.id)}
                >
                  <Box
                    width={"100%"}
                    py={2}
                    px={4}
                    border={"2px solid"}
                    borderColor={"leadpro.200"}
                    transition={"background-color 150ms"}
                    _hover={{
                      bg: "leadpro.50",
                    }}
                  >
                    <Box>{officeGroup.name}</Box>
                    <Box></Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default LeadReapitSyncOfficeGroup;
