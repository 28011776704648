import React, { CSSProperties, useCallback } from "react";
import { toSpace } from "utils/to-snake-case.utils";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnDownRight } from "@fortawesome/pro-solid-svg-icons";
import { orderSourceHosts } from "utils/lead-source.utils";

type SubSeriesItemType = { name?: string; value?: number };

export interface IChartTooltipProps {
  colors: string[];
  xLabel: string;
  values: string[];
  seriesNames: string[];
  left: boolean;
  subSeries?: Record<string, { name?: string; value?: number }[]>;
  showSecondarySources?: boolean;
}

export const ChartTooltip: React.FC<IChartTooltipProps> = props => {
  const { xLabel, left } = props;

  const {
    colors,
    values,
    seriesNames,
    subSeries,
    showSecondarySources,
  } = props;
  const style: CSSProperties = {
    width: "max-content",
    minWidth: "200px",
    bottom: "20px",
    left: 0,
    transition: "all 0.3s ease",
  };

  if (left) {
    style.transform = "translate3d(-240px, 0, 0)";
  } else {
    style.transform = "translate3d(20px, 0, 0)";
  }

  const getOrderedSubSeries = useCallback(
    (seriesName: string) => {
      if (!subSeries) {
        return [];
      }

      const subSeriesRaw = subSeries[seriesName.replaceAll(" ", "_")];
      const otherIndex = subSeriesRaw.findIndex(
        subSeriesItem => subSeriesItem.name === "other"
      );

      const orderSubSeries = (series: SubSeriesItemType[]) =>
        series.sort((a, b) => {
          return (b.value || 0) - (a.value || 0);
        });

      return orderSourceHosts<SubSeriesItemType>(
        subSeriesRaw,
        otherIndex,
        orderSubSeries
      );
    },
    [subSeries]
  );

  return (
    <Box
      position={"absolute"}
      py={3}
      px={3}
      background={"white"}
      shadow={"lg"}
      rounded={"md"}
      right={0}
      zIndex={10}
      style={style}
    >
      <Box p={1} textAlign={"center"} width={"100%"}>
        {xLabel}
      </Box>
      {values.map((value, i) => (
        <>
          <Box
            key={i}
            py={1}
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box
              mr={3}
              width={"10px"}
              height={"10px"}
              rounded={"full"}
              backgroundColor={colors[i]}
            />
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              flexGrow={1}
            >
              <Box mr={3} width={"60px"} fontSize={"sm"}>
                {value}
              </Box>
              <Box textTransform={"capitalize"} fontSize={"sm"}>
                {toSpace(seriesNames[i] || "")}
              </Box>
            </Box>
          </Box>
          {showSecondarySources &&
            subSeries &&
            getOrderedSubSeries(seriesNames[i]).map(sub => {
              return (
                <Box
                  key={sub.name}
                  py={1}
                  display={"flex"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Box mr={3}>
                    <FontAwesomeIcon icon={faArrowTurnDownRight} size={"xs"} />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    flexGrow={1}
                  >
                    <Box mr={3} width={"60px"} fontSize={"sm"}>
                      {sub.value}
                    </Box>
                    <Box fontSize={"sm"}>{sub.name || ""}</Box>
                  </Box>
                </Box>
              );
            })}
        </>
      ))}
    </Box>
  );
};
