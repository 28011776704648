import { BaseApi } from "./base.api";
import {
  TPageToolQuestionnaire,
  TQuestionnaireToolQuestionnaireResponsesResponse,
} from "types/questionnaire-tool-page.type";

export class AccountQuestionnairesApi extends BaseApi {
  async fetchOne(
    accountId: number,
    questionnaireId: number
  ): Promise<TPageToolQuestionnaire> {
    return this.get(`/accounts/${accountId}/questionnaires/${questionnaireId}`);
  }

  async create(
    accountId: number,
    data: Partial<TPageToolQuestionnaire>
  ): Promise<TPageToolQuestionnaire> {
    return this.post(`/accounts/${accountId}/questionnaires`, data);
  }

  async update(
    accountId: number,
    questionnaireId: number,
    data: Partial<TPageToolQuestionnaire>
  ): Promise<TPageToolQuestionnaire> {
    return this.put(
      `/accounts/${accountId}/questionnaires/${questionnaireId}`,
      data
    );
  }

  async delete(accountId: number, questionnaireId: number) {
    await this.del(`/accounts/${accountId}/questionnaires/${questionnaireId}`);
  }

  async fetchQuestionnaireResponses(
    accountId: number,
    questionnaireId: number,
    offset: number,
    limit: number
  ): Promise<TQuestionnaireToolQuestionnaireResponsesResponse> {
    return this.get(
      `/accounts/${accountId}/questionnaires/${questionnaireId}/responses`,
      {
        params: {
          offset,
          limit,
        },
      }
    );
  }
}
