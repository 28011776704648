import { observer } from "mobx-react";
import React, { FC, useCallback, useState } from "react";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import {
  ApiMessageStack,
  IntegrationEntitySyncTag,
  SingleSelectInput,
} from "components";
import { Box, Button, Grid, GridItem, useToast } from "@chakra-ui/react";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
  isManuallyTriggered: boolean;
}

export const AltoIntegrationDefaultNegotiatorConfigurator: FC<IProps> = observer(
  ({ accountStore, isManuallyTriggered }) => {
    const toast = useToast();

    const integrationsStore = accountStore.accountIntegrationsStore;
    const altoIntegrationStore = integrationsStore.accountIntegrationAltoStore;
    const altoNegotiatorsStore =
      altoIntegrationStore.accountIntegrationAltoNegotiatorsStore;
    const defaultNegotiator = altoNegotiatorsStore.defaultNegotiatorId;
    const setDefaultNegotiator =
      altoNegotiatorsStore.setAccountDefaultAltoNegotiatorId;
    const altoNegotiators =
      altoIntegrationStore.accountIntegrationAltoNegotiatorsStore
        .altoNegotiatorsOptions;

    const [syncStatus, setSyncStatus] = useState(
      defaultNegotiator
        ? IntegrationEntitySyncStatusEnum.SYNCED
        : IntegrationEntitySyncStatusEnum.NOT_SYNCED
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitNewDefaultNegotiatorId = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await altoNegotiatorsStore.updateAccountDefaultAltoNegotiatorId();
        await integrationsStore.fetchAccountIntegrations();
        if (defaultNegotiator) {
          setSyncStatus(IntegrationEntitySyncStatusEnum.SYNCED);
        }
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={"Alto integration default negotiator updated."}
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [toast, altoNegotiatorsStore, integrationsStore, defaultNegotiator]);

    return (
      <IntegrationConfiguratorLayout disabled={isManuallyTriggered}>
        <Box fontSize={"xl"}>Default negotiator mapping</Box>
        <Box fontSize={"md"} color={"leadpro.400"}>
          Using the automatic Alto integration requires a 'default negotiator'
          to be mapped which will be associated with all sync'd leads.
        </Box>
        <Grid
          width={"full"}
          templateColumns={"repeat(3, 1fr)"}
          gridGap={2}
          alignItems={"center"}
        >
          <GridItem colSpan={2}>
            <SingleSelectInput
              options={altoNegotiators}
              value={defaultNegotiator}
              clearable
              onChange={value => {
                setSyncStatus(IntegrationEntitySyncStatusEnum.NOT_SYNCED);
                setDefaultNegotiator(value);
              }}
            />
          </GridItem>
          <GridItem>
            <IntegrationEntitySyncTag syncStatus={syncStatus} />
          </GridItem>
        </Grid>
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitNewDefaultNegotiatorId}
            isLoading={isSubmitting}
            disabled={isManuallyTriggered}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
