import { observer } from "mobx-react";
import React, { FC } from "react";
import { FormActionType } from "@leadpro/forms";
import { FormToolPageSettingsCreateLeadActionParamsConfigurator } from "./FormToolPageSettingsCreateLeadActionParamsConfigurator/FormToolPageSettingsCreateLeadActionParamsConfigurator";
import { FormToolPageSettingsSendEmailParamsConfigurator } from "./FormToolPageSettingsSendEmailParamsConfigurator";
import { FormToolPageWIPFormStore } from "../../../../FormToolPageWIPForm.store";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore: FormToolPageWIPFormStore;
}

export const FormToolPageSettingsActionParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore }) => {
    const action = formToolPageWIPFormStore.wipFormActionsJS[actionIndex];

    switch (action.type) {
      case FormActionType.CREATE_LEAD:
        return (
          <FormToolPageSettingsCreateLeadActionParamsConfigurator
            actionIndex={actionIndex}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
          />
        );
      case FormActionType.SEND_EMAIL:
        return (
          <FormToolPageSettingsSendEmailParamsConfigurator
            actionIndex={actionIndex}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
          />
        );
      default:
        return null;
    }
  }
);
