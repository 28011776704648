import { observer } from "mobx-react";
import { Droppable } from "react-beautiful-dnd";
import React, { FC, useMemo } from "react";
import { DraggableElement } from "components";
import { Box, VStack } from "@chakra-ui/react";
import { FormToolPageSettingsEditorStore } from "../../FormToolPageSettingsEditor.store";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { FormToolPageSettingsEmptyList } from "../../../../../../components/FormTool/FormToolPageSettingsEmptyList";
import { FormToolPageSettingsSelectedQuestionConfigurator } from "../../../../../../components/FormTool/FormToolPageSettingsSelectedQuestionConfigurator/FormToolPageSettingsSelectedQuestionConfigurator";

interface IProps {
  formToolPageSettingsEditorStore: FormToolPageSettingsEditorStore;
}
export const FormToolPageSettingsSelectedQuestionsList: FC<IProps> = observer(
  ({ formToolPageSettingsEditorStore }) => {
    const formToolPageWIPFormStore =
      formToolPageSettingsEditorStore.wipFormStore;
    const questionsOrder = formToolPageWIPFormStore.wipFormSchemaJS.order;

    const draggableElements = useMemo(() => {
      return questionsOrder.map((questionKey, index) => (
        <DraggableElement
          id={`${FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS}:${questionKey}`}
          key={questionKey}
          index={index}
        >
          <FormToolPageSettingsSelectedQuestionConfigurator
            index={index}
            questionKey={questionKey}
            formToolPageWIPFormStore={formToolPageWIPFormStore}
          />
        </DraggableElement>
      ));
    }, [formToolPageWIPFormStore, questionsOrder]);

    return (
      <Box width={"100%"}>
        <Box pb={10}>
          <Box pb={"10px"} fontSize={"lg"} fontWeight={"bold"}>
            Questions
          </Box>
          <Box fontSize={"sm"} color={"leadpro.500"}>
            Here you can create and re-order your selection of lead form
            questions. In order to capture a lead, some questions are<br></br>{" "}
            marked as essential, and cannot be removed.{" "}
            <b>
              Begin by dragging and dropping a question type from the left
              panel.
            </b>
          </Box>
        </Box>
        <Box>
          <Droppable
            droppableId={FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS}
          >
            {provided => (
              <VStack
                spacing={2}
                paddingBottom={!!draggableElements.length ? "250px" : "150px"}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!draggableElements.length && (
                  <FormToolPageSettingsEmptyList>
                    Drag the questions from the menu and drop them here.
                  </FormToolPageSettingsEmptyList>
                )}
                {draggableElements}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </Box>
      </Box>
    );
  }
);
