import React, { FC } from "react";
import { DevelopersConfigRequests } from "../../../DevelopersConfigRequests";
import {
  TAccountApiRequestData,
  TAccountApiRequestResponse,
} from "types/account-developers.type";
import { DevelopersConfigApiRequestItem } from "./DevelopersConfigApiRequestItem";
import { observer } from "mobx-react";
import { AccountDevelopersApiKeyConfigStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersApiKeys/AccountDevelopersApiKeyConfig.store";

interface IProps {
  accountDevelopersApiKeyConfigStore: AccountDevelopersApiKeyConfigStore;
}

export const DevelopersConfigApiRequests: FC<IProps> = observer(
  ({ accountDevelopersApiKeyConfigStore }) => {
    return (
      <DevelopersConfigRequests<TAccountApiRequestResponse>
        label={"Requests"}
        description={"Here you can see your previous API requests."}
        requests={accountDevelopersApiKeyConfigStore.apiRequests}
        loadRequests={accountDevelopersApiKeyConfigStore.fetchApiKeyRequests}
        resetRequests={accountDevelopersApiKeyConfigStore.resetApiRequests}
        renderRequest={(request: TAccountApiRequestData) => (
          <DevelopersConfigApiRequestItem key={request.id} request={request} />
        )}
      />
    );
  }
);
