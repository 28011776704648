import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import { ActionPromptContainer, ApiMessageStack } from "components";
import { FC } from "react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { TAccountBlacklistedEmail } from "types/account-blacklisted-email.type";

interface IProps {
  row: TAccountBlacklistedEmail;
  closePrompt: () => void;
  removeEmail: (emailId: number) => Promise<void>;
}

export const RemoveEmailActionPrompt: FC<IProps> = ({
  row,
  closePrompt,
  removeEmail,
}) => {
  const toast = useToast();

  const onClick = async () => {
    try {
      await removeEmail(row.id);
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: (
          <ApiMessageStack messageStack={"Blacklisted email created."} />
        ),
      });
      closePrompt();
    } catch (e) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={e.message} />,
      });
    }
  };

  return (
    <ActionPromptContainer
      header={"Remove blacklisted email "}
      body={
        <Box pt={2}>
          <Text>
            Are you sure you want to remove {row.email} from your blacklist?
          </Text>
          <Divider mt={4} mb={2} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            gap={4}
          >
            <Button onClick={() => closePrompt()}>No</Button>
            <Button colorScheme={"blue"} onClick={onClick}>
              Yes
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
