// TODO: DO WE NEED ALL THESE

export enum LeadTypeEnum {
  Let = "let",
  Landlord = "landlord",
  Vendor = "vendor",
  Sale = "sale",
  MortgageRequest = "mortgage_request",
  Utility = "utility",
  Evaluation = "evaluation",
  MarketAppraisal = "market_appraisal",

  Tenant = "tenant", // does not exist in db
  Null = "Null", // does not exist in db
  Empty = "", // does not exist in db
  SellingBuying = "Selling & Buying", // last one on 2020/02
  Selling = "selling", // last one on 2020/06
  JustCurious = "just-curious", // last one on 2020/06
  Letting = "letting", // last one on 2020/06
  Meeting = "meeting", // last one on 2020/08
  Internet = "internet", // last one on 2020/09
}
