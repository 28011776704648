import React from "react";
import { Box, Link } from "@chakra-ui/react";

interface IPhoneLink {
  phone: string;
  label?: string;
  color?: string;
}

export const PhoneLink: React.FC<IPhoneLink> = ({ phone, label, color }) => {
  return (
    <Link isExternal={true} href={`tel:${phone}`}>
      <Box
        display={"inline"}
        color={color || "gray.600"}
        textTransform={"lowercase"}
        _hover={{ textDecoration: "underline" }}
      >
        {label || phone}
      </Box>
    </Link>
  );
};
