import React from "react";
import { IconButton, IconButtonProps } from "@chakra-ui/react";

export const StandardIconButton = React.forwardRef<any, IconButtonProps>(
  (props, ref) => {
    const { title, colorScheme = "gray", ...rest } = props;

    return (
      <IconButton
        ref={ref}
        fontSize={"18px"}
        width={8}
        height={8}
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        title={title}
        rounded="md"
        color="gray.500"
        colorScheme={colorScheme}
        background={"none"}
        _hover={{
          color: `${colorScheme}.600`,
          bgColor: `${colorScheme}.100`,
        }}
        _focus={{
          outline: "none",
          boxShadow: "none",
        }}
        {...rest}
      />
    );
  }
);
