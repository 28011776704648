import React from "react";
import { Card } from "./Card";
import { formatNumber } from "utils/formating.utils";
import { Box } from "@chakra-ui/react";

interface IStatCard {
  title: string;
  stat: number;
}

export const StatCard = (props: IStatCard) => {
  return (
    <Card>
      <Box p={6}>
        <Box
          as={"h2"}
          mb={4}
          color={"gray.600"}
          fontWeight={500}
          lineHeight={1}
          fontSize={"16px"}
          letterSpacing={"0.025em"}
        >
          {props.title}
        </Box>
        <Box
          fontWeight={500}
          fontSize={"32px"}
          color={"#0066ff"}
          lineHeight={1}
        >
          {formatNumber({ number: props.stat })}
        </Box>
      </Box>
    </Card>
  );
};
