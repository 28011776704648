import React, { ChangeEvent, useCallback } from "react";
import { Box, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons";
import { extractTextFromFileBlob } from "../../utils/file-blob.utils";

interface IProps {
  id?: string;
  name: string;
  value?: File;
  customButtonLabel?: string;
  onChange: (parsedCertificate: string) => void;
  onBlur: () => void;
  isDisabled?: boolean;
  accept?: string;
}

export const CertificateUploadInput: React.FC<IProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  isDisabled,
  customButtonLabel,
  accept,
}) => {
  const [rawFile, setRawFile] = React.useState<File>();

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!!file) {
        setRawFile(file);
        const parsedCertificate = await extractTextFromFileBlob(file);
        onChange(parsedCertificate);
      }
    },
    [onChange]
  );

  return (
    <Box position={"relative"} mt={5} display={"flex"} flexDirection={"column"}>
      <Box
        as={"input"}
        id={id || name}
        name={name}
        type="file"
        onChange={handleChange}
        onBlur={onBlur}
        disabled={isDisabled}
        accept={accept}
        border={0}
        height={"1px"}
        overflow={"hidden"}
        padding={0}
        position={"absolute"}
        whiteSpace={"nowrap"}
        width={"1px"}
        sx={{
          clip: "rect(0, 0, 0, 0)",
        }}
      />
      <Button
        as={"label"}
        rightIcon={<FontAwesomeIcon icon={faCloudArrowUp} fontSize={22} />}
        htmlFor={id || name}
        colorScheme={"teal"}
        overflow={"hidden"}
      >
        {customButtonLabel || "Upload file"}
      </Button>
      {!!rawFile && <Box mt={2}>{rawFile.name}</Box>}
    </Box>
  );
};
