import { groupBy } from "lodash";
import { LeadStageEnum } from "../../../enums/lead-stage.enum";
import { Box, Divider } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { IGetOptionPropsData } from "../SelectInputOptionsController";
import { LeadStageLabel } from "constants/lead-stage-label";
import { LeadStatusSelectOption } from "../custom-options";
import { TLeadStatus } from "types/lead-status.type";
import { LEAD_STAGES_ORDER } from "constants/lead-stage-options";

interface ILeadStatusSelectOptionsRendererProps {
  optionProps: IGetOptionPropsData<TLeadStatus>[];
}

export const LeadStatusSelectOptionsRenderer: FC<ILeadStatusSelectOptionsRendererProps> = ({
  optionProps,
}) => {
  const grouped = useMemo(() => {
    return groupBy(optionProps, optionProp => optionProp.option.value.stageId);
  }, [optionProps]);

  return (
    <>
      {LEAD_STAGES_ORDER.map((groupKey: LeadStageEnum) => {
        return (
          <Box key={groupKey}>
            <Box
              px={2}
              py={1}
              fontSize={"xs"}
              color={"gray.500"}
              textTransform={"uppercase"}
            >
              {LeadStageLabel[groupKey]}
            </Box>
            {grouped[groupKey].map((props, key) => {
              return <LeadStatusSelectOption key={key} optionProps={props} />;
            })}
            <Divider />
          </Box>
        );
      })}
    </>
  );
};
