import { Button } from "@chakra-ui/react";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DATE_FORMAT } from "constants/date";
import { TAccountBlacklistedEmail } from "types/account-blacklisted-email.type";
import { formatDate } from "utils/date.utils";

export const blacklistedEmailsColumnDef = (
  handleRemove: (row: TAccountBlacklistedEmail) => void
): ITableV2Column<TAccountBlacklistedEmail>[] => {
  return [
    {
      accessor: "email",
      Header: <DefaultHeaderCell>Blacklisted email address</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.email}</DefaultRowCell>,
    },
    {
      accessor: "createdAt",
      Header: <DefaultHeaderCell>Date added</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          {formatDate(row.createdAt, DATE_FORMAT)}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "id",
      width: "100px",
      disableSortBy: true,
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Action</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <Button onClick={() => handleRemove(row)} colorScheme={"red"}>
            Remove
          </Button>
        </DefaultRowCell>
      ),
    },
  ];
};
