import {
  Box,
  Button,
  ChakraStyledOptions,
  Image,
  useToast,
} from "@chakra-ui/react";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiMessageStack, Tooltip } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_WARNING_TOAST_OPTIONS,
} from "constants/default-toast-options";
import {
  IntegrationLogoIcons,
  IntegrationLogoIconURLs,
} from "constants/integrationLogoIcons";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo, useState } from "react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TLeadOpenViewSyncData } from "types/openview.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { LeadOpenViewSyncFormPrompt } from "./LeadOpenViewSyncFormPrompt/LeadOpenViewSyncFormPrompt";

interface IProps {
  leadStore: AccountLeadStore;
  hasActiveOpenViewIntegration: boolean;
  accountStore: UserAccountStore;
  styles?: ChakraStyledOptions;
}

export const LeadSyncOpenViewButton: FC<IProps> = observer(
  ({ leadStore, hasActiveOpenViewIntegration, styles, accountStore }) => {
    const toast = useToast();
    const [isSyncing, setIsSyncing] = useState(false);
    const { setModal, unSetModal } = useActionPrompt();
    const {
      accountIntegrationOpenViewContactsStore,
    } = accountStore.accountIntegrationsStore.accountIntegrationOpenViewStore;
    const isLeadOfficeSyncedWithOpenView =
      leadStore.isLeadOfficeSyncedWithOpenView;
    const isLeadSynced = leadStore.isLeadSyncedToOpenView;
    const isOpenViewSyncAllowedForLeadType =
      leadStore.isOpenViewSyncAllowedForLeadType;
    const isLeadAssignedToUser = leadStore.isLeadAssignedToUser;
    const isLeadAssignedToOffice = leadStore.isLeadAssignedToOffice;
    const validationErrorMessages = useMemo(() => {
      const errorMessages: string[] = [];

      if (isSyncing) errorMessages.push("Lead is being synced...");
      if (isLeadSynced) errorMessages.push("Lead already synced.");
      if (!isOpenViewSyncAllowedForLeadType)
        errorMessages.push("Lead type not supported.");
      if (!isLeadAssignedToUser) errorMessages.push("Please assign an agent.");
      if (!isLeadAssignedToOffice)
        errorMessages.push("Please assign an office.");
      if (!isLeadOfficeSyncedWithOpenView)
        errorMessages.push(
          "Assigned office is not synced with iamproperty CRM"
        );

      return errorMessages;
    }, [
      isSyncing,
      isLeadSynced,
      isOpenViewSyncAllowedForLeadType,
      isLeadAssignedToUser,
      isLeadAssignedToOffice,
      isLeadOfficeSyncedWithOpenView,
    ]);

    const buttonData = useMemo(() => {
      return {
        buttonProps: {
          isDisabled: !!validationErrorMessages.length,
          isLoading: isSyncing,
          children: isLeadSynced
            ? "Synced to iamproperty CRM"
            : "Sync to iamproperty CRM",
          leftIcon: isLeadSynced ? (
            <FontAwesomeIcon icon={faCircleCheck} fontSize={24} />
          ) : (
            <Image
              alt={"iamproperty CRM Logo"}
              width={"24px"}
              src={IntegrationLogoIconURLs[IntegrationLogoIcons.OPEN_VIEW]}
            />
          ),
        },
      };
    }, [isLeadSynced, isSyncing, validationErrorMessages.length]);

    const handleSyncLead = useCallback(
      async (syncData: TLeadOpenViewSyncData) => {
        if (hasActiveOpenViewIntegration && !validationErrorMessages.length) {
          try {
            setIsSyncing(true);
            await leadStore.syncLeadWithOpenView(syncData);
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          } finally {
            setIsSyncing(false);
          }
        }
      },
      [leadStore, hasActiveOpenViewIntegration, validationErrorMessages, toast]
    );

    const handleOpenSyncModal = useCallback(async () => {
      await leadStore.fetchDetails();
      const leadSyncCheck = leadStore.isLeadSyncedToOpenView;
      if (leadSyncCheck) {
        toast({
          ...DEFAULT_WARNING_TOAST_OPTIONS,
          description:
            "This lead has already been sync'd to iamproperty by another user.",
        });
      } else {
        setModal(
          <LeadOpenViewSyncFormPrompt
            leadStore={leadStore}
            handleSyncLead={handleSyncLead}
            closePrompt={unSetModal}
            contactsStore={accountIntegrationOpenViewContactsStore}
          />
        );
      }
    }, [
      leadStore,
      handleSyncLead,
      setModal,
      unSetModal,
      accountIntegrationOpenViewContactsStore,
      toast,
    ]);

    return (
      <Tooltip
        isDisabled={!validationErrorMessages.length}
        aria-label="lead-openview-sync-tooltip"
        label={
          <Box>
            {validationErrorMessages.map(message => (
              <Box key={message}>{message}</Box>
            ))}
          </Box>
        }
      >
        <Box>
          <Button
            onClick={handleOpenSyncModal}
            variant="ghost"
            {...buttonData.buttonProps}
            {...styles}
          />
        </Box>
      </Tooltip>
    );
  }
);
