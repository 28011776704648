import React, { useMemo } from "react";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { isDateCorrectFormat } from "utils/date.utils";
import { Box } from "@chakra-ui/react";
import { DATE_YEAR } from "constants/date";

export const ExportSelectPeriodOption: React.FC<ISelectInputOptionProps<
  string
>> = ({ optionProps }) => {
  const { option, ...rest } = optionProps;

  const isYear = useMemo(() => {
    return isDateCorrectFormat(option.value, DATE_YEAR);
  }, [option]);

  const labelStyles = useMemo(() => {
    return {
      fontWeight: isYear ? "bold" : undefined,
      marginLeft: isYear ? undefined : "3",
    };
  }, [isYear]);

  return (
    <SelectInputOptionBase option={option} {...rest}>
      <Box {...labelStyles}>{option.label}</Box>
    </SelectInputOptionBase>
  );
};
