import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import { DevelopersConfigApiRequests } from "./DevelopersConfigApiRequests/DevelopersConfigApiRequests";
import { SETTINGS_DEVELOPERS_API_KEYS_ROUTE } from "constants/routes";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountDevelopersApiKeysStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersApiKeys/AccountDevelopersApiKeys.store";
import { Redirect, useParams } from "react-router-dom";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { DevelopersConfigApiKeyDetails } from "./DevelopersConfigApiKeyDetails";

interface IProps {
  accountDevelopersApiKeysStore: AccountDevelopersApiKeysStore;
}

export const DevelopersConfigApiKey: React.FC<IProps> = observer(
  ({ accountDevelopersApiKeysStore }) => {
    let { id } = useParams<{ id: string }>();
    const apiKeyConfigId = useMemo(() => parseInt(id!), [id]);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const apiKeyConfigStore =
      accountDevelopersApiKeysStore.apiKeyConfigStoresMap[apiKeyConfigId];

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountDevelopersApiKeysStore.fetchApiKeyConfig(apiKeyConfigId);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountDevelopersApiKeysStore, apiKeyConfigId]);

    const content = useMemo(() => {
      const title =
        apiKeyConfigStore?.apiKeyConfig.name ||
        "Loading API key configuration...";
      return (
        <Box
          position={"relative"}
          height={"100%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          overflowY={"hidden"}
          ref={ref}
        >
          <TabsGroupLayout
            title={title}
            loadingStatus={loadingStatus}
            config={[
              {
                tabName: "Details",
                tabHash: "#details",
                tabComponent: !!apiKeyConfigStore && (
                  <DevelopersConfigApiKeyDetails
                    accountDevelopersApiKeyConfigStore={apiKeyConfigStore}
                    containerRef={ref}
                  />
                ),
              },
              {
                tabName: "Logs",
                tabHash: "#logs",
                tabComponent: !!apiKeyConfigStore && (
                  <DevelopersConfigApiRequests
                    accountDevelopersApiKeyConfigStore={apiKeyConfigStore}
                  />
                ),
              },
            ]}
          />
        </Box>
      );
    }, [apiKeyConfigStore, loadingStatus]);

    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return (
        <Redirect
          to={{
            pathname: SETTINGS_DEVELOPERS_API_KEYS_ROUTE,
          }}
        />
      );
    }

    return content;
  }
);
