import { observer } from "mobx-react";
import {
  Box,
  Divider,
  Portal,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { FC, MutableRefObject, useCallback, useMemo } from "react";
import { AccountDevelopersWebhookStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhook.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import {
  ApiMessageStack,
  FormControlMeta,
  FormControlV2,
  TableV2,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FormFooter } from "components/form";
import { DevelopersConfigKeyItem } from "routes/dashboard/routes/settings/components/DevelopersConfigKeyItem";
import { developersConfigWebhookValidation } from "utils/validation-schemas/developers-config-webhook.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TDeveloperWebhookEventConfigData } from "types/account-developers.type";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import {
  DeveloperConfigWebhookDetailsLocalStore,
  TFieldValues,
} from "./DeveloperConfigWebhookDetails.local.store";

interface IProps {
  accountDevelopersWebhookStore: AccountDevelopersWebhookStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const DevelopersConfigWebhookDetails: FC<IProps> = observer(
  ({ accountDevelopersWebhookStore, containerRef }) => {
    const toast = useToast();

    const localStore = useLocalStore(
      DeveloperConfigWebhookDetailsLocalStore,
      accountDevelopersWebhookStore
    );

    const {
      eventConfigurationTableData,
      initialFormValues,
      isInternal,
      onRegenerateKey,
      columnDef,
      webhookData,
      isEventConfigDirty,
      handleUpdateWebhook,
      resetEventConfig,
    } = localStore;

    const {
      handleSubmit,
      control,
      reset,

      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialFormValues,
      mode: "onSubmit",
      resolver: yupResolver(developersConfigWebhookValidation),
    });

    const onSubmit = async (data: TFieldValues) => {
      try {
        await handleUpdateWebhook(data);
        reset(data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={"Webhook updated!"} />,
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
        handleReset();
      }
    };

    const handleReset = useCallback(() => {
      reset();
      resetEventConfig();
    }, [reset, resetEventConfig]);

    const dirty = useMemo(() => {
      return isDirty || isEventConfigDirty;
    }, [isDirty, isEventConfigDirty]);

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={10} align={"stretch"} divider={<Divider />} mt={1}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Webhook name"}
                description={
                  "A human-readable name to make it easier to distinguish between multiple webhooks."
                }
              />
              <FormControlV2<TFieldValues>
                name={"name"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                isDisabled={isInternal}
                additionalProps={{
                  placeholder: "Enter webhook name",
                }}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Webhook URL"}
                description={
                  "URL of the endpoint you created to receive the data from LeadPro."
                }
              />
              <FormControlV2<TFieldValues>
                name={"webhookUrl"}
                control={control}
                type={FormControlsTypeEnum.TEXT}
                isDisabled={isInternal}
                additionalProps={{
                  placeholder: "http://",
                }}
              />
            </SimpleGrid>
            {!isInternal && (
              <DevelopersConfigKeyItem
                label={"Webhook signing secret"}
                name={"webhook-signing-secret"}
                value={webhookData.webhookSigningSecret}
                onRegenerateKey={onRegenerateKey}
              />
            )}

            {!isInternal && (
              <Box>
                <TableV2<TDeveloperWebhookEventConfigData>
                  dataSource={eventConfigurationTableData}
                  columns={columnDef}
                  striped={true}
                />
              </Box>
            )}
          </VStack>
          {dirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                isSubmitting={isSubmitting}
                submitForm={handleSubmit(onSubmit)}
                resetForm={handleReset}
              />
            </Portal>
          )}
        </form>
      </Box>
    );
  }
);
