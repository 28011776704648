import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const NavigationSection: React.FC<BoxProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box width="100%" pt={2} pb={10} px={3} {...rest}>
      {children}
    </Box>
  );
};
