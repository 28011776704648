import { observer } from "mobx-react";
import { Box, Divider, VStack } from "@chakra-ui/react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountOfficesPortalLinkStatus } from "./components/AccountOfficesPortalLinkStatus/AccountOfficesPortalLinkStatus";
import { Templates } from "./components/Templates/Templates";
import { PreQualificationQuestionnaires } from "./components/PreQualificationQuestionnaires/PreQualificationQuestionnaires";
import { LegacyLeadResponderBlock } from "routes/dashboard/routes/settings/components/LegacyLeadResponderBlock";

interface IProps {
  accountStore: UserAccountStore;
}
export const AccountLeadResponder: FC<IProps> = observer(({ accountStore }) => {
  return (
    <VStack spacing={6} align={"stretch"}>
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"xl"} fontWeight={"bold"}>
          Lead Sources
        </Box>
        <AccountOfficesPortalLinkStatus accountStore={accountStore} />
      </VStack>
      <Divider />
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"xl"} fontWeight={"bold"}>
          Pre-Qualification Questionnaires
        </Box>
        <LegacyLeadResponderBlock
          accountStore={accountStore}
          render={() => (
            <PreQualificationQuestionnaires accountStore={accountStore} />
          )}
        />
      </VStack>
      <Divider />
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"xl"} fontWeight={"bold"}>
          Templates
        </Box>
        <Templates accountStore={accountStore} />
      </VStack>
    </VStack>
  );
});
