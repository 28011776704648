import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TCreateOrUpdatePageData, TPageData } from "types/page.type";
import { AccountPagesApi } from "api/account-pages.api";
import { keyBy } from "lodash";
import { PageToolTypeEnum } from "enums/page-tool-type.enum";
import { IAccountPageToolStore } from "../IAccountPageToolStore";
import { AccountFormsApi } from "api/account-forms.api";
import { AccountFormPageStore } from "./AccountFormPage.store";
import { AccountFormPageFormStore } from "./AccountFormPageForm.store";
import { TPageToolForm } from "types/form-tool-page.type";

const pagesApi = new AccountPagesApi();
const formsApi = new AccountFormsApi();

export class AccountFormPagesStore implements IAccountPageToolStore {
  private readonly accountId: number;
  private readonly accountFormPageStores: IObservableArray<
    AccountFormPageStore
  >;
  private readonly accountFormPageFormStores: IObservableArray<
    AccountFormPageFormStore
  >;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.accountFormPageStores = observable.array<AccountFormPageStore>();
    this.accountFormPageFormStores = observable.array<
      AccountFormPageFormStore
    >();
  }

  // PAGE GETTERS

  get accountPagesArray() {
    return this.accountFormPageStores.map(store => store.page);
  }

  get accountPagesMap() {
    return keyBy(this.accountPagesArray, page => page.id);
  }

  get accountFormPageStoresMap() {
    return keyBy(this.accountFormPageStores, pageStore => pageStore.page.id);
  }

  // FORM GETTERS
  get accountFormsArray() {
    return this.accountFormPageFormStores.map(store => store.form);
  }

  get accountFormsMap() {
    return keyBy(this.accountFormsArray, form => form.id);
  }

  get accountFormPageFormStoresMap() {
    return keyBy(
      this.accountFormPageFormStores,
      formStore => formStore.form.id
    );
  }

  // PAGES SETTERS
  public setPages(pages: TPageData[]) {
    this.accountFormPageStores.replace(
      pages.map(page => new AccountFormPageStore(this.accountId, page))
    );
  }

  public deletePage(pageId: number) {
    const index = this.accountFormPageStores.findIndex(
      page => page.page.id === pageId
    );
    if (index > -1) {
      this.accountFormPageStores.splice(index, 1);
    }
  }

  public upsertPage(pageData: TPageData) {
    const index = this.accountFormPageStores.findIndex(
      pageStore => pageStore.page.id === pageData.id
    );
    if (index > -1) {
      this.accountFormPageStores[index].upsertPage(pageData);
    } else {
      this.accountFormPageStores.push(
        new AccountFormPageStore(this.accountId, pageData)
      );
    }
  }

  // FORM SETTERS
  public setForms(forms: TPageToolForm[]) {
    this.accountFormPageFormStores.replace(
      forms.map(form => new AccountFormPageFormStore(this.accountId, form))
    );
  }

  public deleteForm(formId: number) {
    const index = this.accountFormPageFormStores.findIndex(
      formStore => formStore.form.id === formId
    );
    if (index > -1) {
      this.accountFormPageFormStores.splice(index, 1);
    }
  }

  public upsertForm(formData: TPageToolForm) {
    const index = this.accountFormPageFormStores.findIndex(
      formStore => formStore.form.id === formData.id
    );
    if (index > -1) {
      this.accountFormPageFormStores[index].upsertForm(formData);
    } else {
      this.accountFormPageFormStores.push(
        new AccountFormPageFormStore(this.accountId, formData)
      );
    }
  }

  // PAGES ASYNC
  public async loadPages() {
    const pages = await pagesApi.fetchAll(
      this.accountId,
      PageToolTypeEnum.FORM
    );

    this.setPages(pages);
  }

  public async loadPage(id: number) {
    const page = await pagesApi.fetchOne(
      id,
      this.accountId,
      PageToolTypeEnum.FORM
    );

    this.upsertPage(page);
  }

  public async createPage(data: Partial<TCreateOrUpdatePageData>) {
    const newPage = await pagesApi.create(this.accountId, data);
    this.upsertPage(newPage);

    return newPage.id;
  }

  public async removePage(pageId: number) {
    await pagesApi.delete(this.accountId, pageId);
    this.deletePage(pageId);
  }

  public async checkIfHostIsAvailable(host: string) {
    await pagesApi.checkIfHostIsAvailable(host);
  }

  // FORMS ASYNC
  public async loadForm(formId: number) {
    const formData = await formsApi.fetchOne(this.accountId, formId);
    this.upsertForm(formData);
  }

  public async createForm(formData: Partial<TPageToolForm>) {
    const newForm = await formsApi.create(this.accountId, formData);
    this.upsertForm(newForm);

    return newForm.id;
  }

  public async removeForm(formId: number) {
    await formsApi.delete(this.accountId, formId);
    this.deleteForm(formId);
  }
}
