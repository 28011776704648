import { Box, HStack } from "@chakra-ui/react";
import { LeadStageEnum } from "enums/lead-stage.enum";
import {
  ISelectInputOptionProps,
  SelectInputOptionBase,
} from "../SelectInputOptionBase";
import { stageIcons } from "constants/icons";
import { useMemo } from "react";
import { stageColors } from "../../../constants/colors";

export const LeadStageSelectOption: React.FC<ISelectInputOptionProps<
  LeadStageEnum
>> = ({ optionProps }) => {
  const { option, ...rest } = optionProps;

  const StageIcon = useMemo(() => stageIcons[option.value], [option.value]);

  return (
    <SelectInputOptionBase option={option} {...rest}>
      <HStack spacing={2}>
        <StageIcon boxSize={3} color={stageColors[option.value]} />
        <Box>{option.label}</Box>
      </HStack>
    </SelectInputOptionBase>
  );
};
