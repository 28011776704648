import { Box, Portal, SimpleGrid, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, FormControlMeta } from "components";
import { SingleOfficeMembershipInput } from "./SingleOfficeMembershipInput";
import {
  getAllMembershipsValidationSchema,
  getInitialMembershipFormData,
  getMembershipFieldName,
  TOfficeMembershipFieldValues,
} from "utils/validation-schemas/office-membership.validation";
import { FormFooter } from "components/form";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";

interface IProps {
  officeStore: AccountOfficeStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const OfficeMembershipsForm: FC<IProps> = observer(
  ({ officeStore, containerRef }) => {
    const { uiStore } = useAppStore();
    const officeMembershipOptions = uiStore.officeMembershipOptionsArray;
    const selectedOfficeMemberships = officeStore.officeMembershipsArray;

    const toast = useToast();

    const remainingMembershipsOptions = useMemo(() => {
      const takenMemberships = selectedOfficeMemberships.map(
        membership => membership.membership.id
      );
      return officeMembershipOptions.filter(option => {
        return !takenMemberships.includes(option.value as number);
      });
    }, [officeMembershipOptions, selectedOfficeMemberships]);

    const initialValues: TOfficeMembershipFieldValues = useMemo(
      () => getInitialMembershipFormData(selectedOfficeMemberships),
      [selectedOfficeMemberships]
    );

    const validationSchema = useMemo(() => {
      return getAllMembershipsValidationSchema(
        selectedOfficeMemberships.map(officeMembership => officeMembership.id)
      );
    }, [selectedOfficeMemberships]);

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TOfficeMembershipFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    const onSubmit = useCallback(
      async (values: TOfficeMembershipFieldValues) => {
        const pendingUpdates = [];
        try {
          selectedOfficeMemberships.forEach(officeMembership => {
            pendingUpdates.push(
              officeStore.updateOfficeMembership(
                officeMembership.id,
                values[getMembershipFieldName(officeMembership.id)]
              )
            );
          });

          if (!!values[getMembershipFieldName()].selectValue) {
            pendingUpdates.push(
              officeStore.createOfficeMembership(
                values[getMembershipFieldName()]
              )
            );
          }

          await Promise.all(pendingUpdates);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: "You have successfully updated memberships.",
          });
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [officeStore, selectedOfficeMemberships, toast]
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={2} spacing={4} pt={1}>
          <FormControlMeta
            label="Memberships"
            description={
              "If you are part of any membership organisations that provides you with a special deal, please add the details here so we can bill the correct entity."
            }
          />
          <VStack spacing={4} align={"stretch"}>
            {selectedOfficeMemberships.map((membership, index) => {
              return (
                <Box key={index}>
                  <SingleOfficeMembershipInput
                    officeStore={officeStore}
                    membershipId={membership.id}
                    name={getMembershipFieldName(membership.id)}
                    control={control}
                    options={officeMembershipOptions}
                  />
                </Box>
              );
            })}
            <Box key={selectedOfficeMemberships?.length || 0}>
              <SingleOfficeMembershipInput
                officeStore={officeStore}
                name={getMembershipFieldName()}
                control={control}
                options={remainingMembershipsOptions}
              />
            </Box>
          </VStack>
        </SimpleGrid>
        {isDirty && (
          <Portal containerRef={containerRef}>
            <FormFooter
              isSubmitting={isSubmitting}
              submitForm={handleSubmit(onSubmit)}
              resetForm={reset}
            />
          </Portal>
        )}
      </form>
    );
  }
);
