import { Box, Button } from "@chakra-ui/react";
import { observer } from "mobx-react";
import {
  LeadResponderWorkflowEditorBlock,
  TriggerablePopoverBlock,
} from "../common-blocks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobile } from "@fortawesome/pro-regular-svg-icons";
import { SingleSelectInput } from "components";
import React, { FC, useCallback, useMemo, useState } from "react";
import { TEmailTemplate } from "types/email-template.type";
import { EmailTemplateEditorPrompt } from "routes/dashboard/components/prompts/EmailTemplatePrompt/EmailTemplateEditorPrompt";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { filterOptionsByLabel } from "utils/select.utils";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { WorkflowEditorTemplateBlockTypeEnum } from "enums/workflow-editor-template-block-type.enum";
import { TSmsTemplate } from "types/sms-template.type";
import { SmsTemplateEditorPrompt } from "routes/dashboard/components/prompts/SmsTemplatePrompt/SmsTemplateEditorPrompt";

const EDIT_OR_CREATE_TEMPLATE_MODAL = "edit-or-create-template-modal";

interface IProps {
  templateId: number | null;
  onApply: (templateId: number | null) => void;
  accountStore: UserAccountStore;
  templateType: WorkflowEditorTemplateBlockTypeEnum;
  isDisabled?: boolean;
}

export const LeadResponderWorkflowEditorTemplateBlock: FC<IProps> = observer(
  ({ templateId, onApply, accountStore, templateType, isDisabled }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const [selectedTemplateId, setSelectedTemplateId] = useState(
      templateId || null
    );
    const accountEmailTemplatesStore = accountStore.accountEmailTemplatesStore;
    const accountSmsTemplatesStore = accountStore.accountSmsTemplatesStore;

    const templatesMap = useMemo(() => {
      switch (templateType) {
        case WorkflowEditorTemplateBlockTypeEnum.EMAIL:
          return accountEmailTemplatesStore.emailTemplatesMap;
        case WorkflowEditorTemplateBlockTypeEnum.SMS:
          return accountSmsTemplatesStore.smsTemplatesMap;
        default:
          throw new Error("Template type not supported!");
      }
    }, [
      templateType,
      accountEmailTemplatesStore.emailTemplatesMap,
      accountSmsTemplatesStore.smsTemplatesMap,
    ]);

    const templateOptions = useMemo(() => {
      switch (templateType) {
        case WorkflowEditorTemplateBlockTypeEnum.EMAIL:
          return accountEmailTemplatesStore.emailTemplatesAsOptions;
        case WorkflowEditorTemplateBlockTypeEnum.SMS:
          return accountSmsTemplatesStore.smsTemplatesAsOptions;
        default:
          throw new Error("Template type not supported!");
      }
    }, [
      templateType,
      accountEmailTemplatesStore.emailTemplatesAsOptions,
      accountSmsTemplatesStore.smsTemplatesAsOptions,
    ]);

    const blockIcon = useMemo(() => {
      switch (templateType) {
        case WorkflowEditorTemplateBlockTypeEnum.EMAIL:
          return <FontAwesomeIcon icon={faEnvelope} />;
        case WorkflowEditorTemplateBlockTypeEnum.SMS:
          return <FontAwesomeIcon icon={faMobile} />;
        default:
          throw new Error("Template type not supported!");
      }
    }, [templateType]);

    const selectedTemplate = useMemo(() => {
      if (!!selectedTemplateId) {
        return templatesMap[selectedTemplateId];
      }
    }, [templatesMap, selectedTemplateId]);

    const wipWorkflowTemplate = useMemo(() => {
      if (!!templateId) {
        return templatesMap[templateId];
      }
    }, [templatesMap, templateId]);

    const onOpen = useCallback(() => {
      setSelectedTemplateId(templateId || null);
    }, [setSelectedTemplateId, templateId]);

    const onChange = useCallback(
      (templateId: TSelectValue<number>) => {
        setSelectedTemplateId(templateId);
      },
      [setSelectedTemplateId]
    );

    const handleApply = useCallback(
      (onClose: () => void) => () => {
        onApply(selectedTemplateId);
        onClose();
      },
      [selectedTemplateId, onApply]
    );

    const handleCreateOrEditEmailTemplate = useCallback(
      (emailTemplate?: TEmailTemplate) => () => {
        setModal(
          <EmailTemplateEditorPrompt
            emailTemplateId={emailTemplate?.id}
            accountStore={accountStore}
            closePrompt={() => unSetModal(EDIT_OR_CREATE_TEMPLATE_MODAL)}
          />,
          {
            key: EDIT_OR_CREATE_TEMPLATE_MODAL,
            size: "full",
          }
        );
      },
      [setModal, unSetModal, accountStore]
    );

    const handleCreateOrEditSMSTemplate = useCallback(
      (smsTemplate?: TSmsTemplate) => () => {
        setModal(
          <SmsTemplateEditorPrompt
            smsTemplate={smsTemplate}
            smsTemplatesStore={accountSmsTemplatesStore}
            closePrompt={() => unSetModal(EDIT_OR_CREATE_TEMPLATE_MODAL)}
          />,
          {
            key: EDIT_OR_CREATE_TEMPLATE_MODAL,
          }
        );
      },
      [setModal, unSetModal, accountSmsTemplatesStore]
    );

    const handleCreateOrEditTemplate = useCallback(
      (template?: TEmailTemplate | TSmsTemplate) => {
        switch (templateType) {
          case WorkflowEditorTemplateBlockTypeEnum.EMAIL:
            return handleCreateOrEditEmailTemplate(template as TEmailTemplate);
          case WorkflowEditorTemplateBlockTypeEnum.SMS:
            return handleCreateOrEditSMSTemplate(template as TSmsTemplate);
          default:
            throw new Error("Template type not supported!");
        }
      },
      [
        templateType,
        handleCreateOrEditEmailTemplate,
        handleCreateOrEditSMSTemplate,
      ]
    );

    const handleFilterTemplateOptions = useCallback(
      (options: TSelectOption<number>[], searchTerm: string) => {
        return filterOptionsByLabel<number>(options, searchTerm);
      },
      []
    );

    return (
      <TriggerablePopoverBlock
        onOpen={onOpen}
        triggerElement={
          <LeadResponderWorkflowEditorBlock
            placeholder={"Select template"}
            iconColor={"teal"}
            icon={blockIcon}
            isDisabled={isDisabled}
          >
            {wipWorkflowTemplate?.name}
          </LeadResponderWorkflowEditorBlock>
        }
        headerElement={`Select ${templateType} template`}
        bodyElement={
          <>
            <SingleSelectInput<number>
              value={selectedTemplateId}
              options={templateOptions}
              onChange={onChange}
              filterFn={handleFilterTemplateOptions}
              clearable={true}
              placeholder={`Select ${templateType} template`}
            />
            <Box display={"flex"} justifyContent={"space-between"} pt={2}>
              <Button variant={"link"} onClick={handleCreateOrEditTemplate()}>
                + Create template
              </Button>
              {!!selectedTemplateId && (
                <Button
                  variant={"link"}
                  onClick={handleCreateOrEditTemplate(selectedTemplate)}
                >
                  + Edit template
                </Button>
              )}
            </Box>
          </>
        }
        handleApply={handleApply}
      />
    );
  }
);
