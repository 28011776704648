import { Box, Portal, SimpleGrid, useToast } from "@chakra-ui/react";
import { ApiMessageStack, FormControlV2, FormControlMeta } from "components";
import { FormFooter } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import React, { MutableRefObject, useEffect, useMemo } from "react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  mortgageBrokerEmails: string[];
};

const validationSchema = Yup.object().shape({
  mortgageBrokerEmails: Yup.array().of(Yup.string().email()),
});

interface IProps {
  isEnabled: boolean;
  officeStore: AccountOfficeStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const OfficeMortgageReferralsSettings: React.FC<IProps> = observer(
  ({ containerRef, officeStore, isEnabled }) => {
    const mortgageBrokerConfiguration =
      officeStore.officeMortgageReferralsConfiguration;
    const toast = useToast();

    const initialValues: TFieldValues = useMemo(
      () => ({
        mortgageBrokerEmails: mortgageBrokerConfiguration?.emails || [],
      }),
      [mortgageBrokerConfiguration]
    );

    const {
      handleSubmit,
      control,
      reset,
      formState: { isSubmitting, isDirty },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (values: TFieldValues) => {
      try {
        await officeStore.updateOfficeMortgageReferralsConfiguration({
          emails: values.mortgageBrokerEmails,
        });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description:
            "Your mortgage broker referral email notifications have been successfully updated.",
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        await officeStore.fetchOfficeMortgageReferralsConfiguration();
      };
      fetchData();
    }, [officeStore]);

    useEffect(() => {
      reset(initialValues);
    }, [reset, initialValues]);

    return (
      <Box
        position={"relative"}
        minHeight={"100%"}
        overflow={"hidden"}
        pr={1}
        pt={1}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={2} spacing={4}>
            <FormControlMeta
              label="Mortgage broker emails"
              description={
                "Please enter the email addresses of the mortgage brokers you would like to refer mortgage leads to."
              }
            />
            <Box>
              <FormControlV2<TFieldValues>
                name={"mortgageBrokerEmails"}
                control={control}
                type={FormControlsTypeEnum.TAG_INPUT}
                isDisabled={!isEnabled}
                additionalProps={{
                  placeholder: "Enter email and press enter or comma",
                }}
              />
            </Box>
          </SimpleGrid>
          {isDirty && (
            <Portal containerRef={containerRef}>
              <FormFooter
                submitForm={handleSubmit(onSubmit)}
                resetForm={reset}
                isSubmitting={isSubmitting}
              />
            </Portal>
          )}
        </form>
      </Box>
    );
  }
);
