import React, { FC, useCallback } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { FormToolPageSettingsTemplateQuestionList } from "../../../../../components/FormTool/FormToolPageSettingsTemplateQuestions/FormToolPageSettingsTemplateQuestionList";
import { FormToolPageSettingsSelectedQuestionsList } from "./FormToolPageSettingsSelectedQuestions/FormToolPageSettingsSelectedQuestionsList";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { FormActionType, FormFieldType } from "@leadpro/forms";
import { FormToolPageSettingsEditorStore } from "../FormToolPageSettingsEditor.store";
import { FormToolPageSettingsSelectedActionsList } from "./FormToolPageSettingsSelectedActions/FormToolPageSettingsSelectedActionsList";
import { FormToolPageSettingsTemplateActionList } from "../../../../../components/FormTool/FormToolPageSettingsTemplateActions/FormToolPageSettingsTemplateActionList";
import { FormToolPageActionTemplateOptions } from "constants/form-tool-page";

interface IProps {
  formToolPageSettingsEditorStore: FormToolPageSettingsEditorStore;
}

export const FormToolPageSettingsFormBuilder: FC<IProps> = observer(
  ({ formToolPageSettingsEditorStore }) => {
    const handleAddQuestion = useCallback(
      (result: DropResult) => {
        const position = result.destination?.index;
        const questionType = result.draggableId.split(":")[1] as FormFieldType;
        if (!!questionType && position !== undefined) {
          formToolPageSettingsEditorStore.wipFormStore.addQuestion(
            questionType,
            position
          );
        }
      },
      [formToolPageSettingsEditorStore]
    );

    const handleQuestionReorder = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;
        formToolPageSettingsEditorStore.wipFormStore.swapQuestionsOrder(
          result.source.index,
          result.destination?.index
        );
      },
      [formToolPageSettingsEditorStore]
    );

    const handleDropIntoSelectedQuestions = useCallback(
      (result: DropResult) => {
        const sourceDroppableId = result.source.droppableId;
        if (
          sourceDroppableId ===
          FormToolPageDroppableContainerEnum.TEMPLATE_QUESTIONS
        ) {
          handleAddQuestion(result);
        } else if (
          sourceDroppableId ===
          FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS
        ) {
          handleQuestionReorder(result);
        }
      },
      [handleAddQuestion, handleQuestionReorder]
    );

    const handleAddAction = useCallback(
      (result: DropResult) => {
        const actionType = result.draggableId.split(":")[1] as FormActionType;
        formToolPageSettingsEditorStore.wipFormStore.addAction(actionType);
      },
      [formToolPageSettingsEditorStore]
    );

    const handleDropIntoSelectedActions = useCallback(
      (result: DropResult) => {
        const sourceDroppableId = result.source.droppableId;
        if (
          sourceDroppableId ===
          FormToolPageDroppableContainerEnum.TEMPLATE_ACTIONS
        ) {
          handleAddAction(result);
        }
      },
      [handleAddAction]
    );

    const onDragEnd = useCallback(
      (result: DropResult) => {
        const destinationDroppableId = result.destination?.droppableId;
        if (
          destinationDroppableId ===
          FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS
        ) {
          handleDropIntoSelectedQuestions(result);
        }
        if (
          destinationDroppableId ===
          FormToolPageDroppableContainerEnum.SELECTED_ACTIONS
        ) {
          handleDropIntoSelectedActions(result);
        }
      },
      [handleDropIntoSelectedQuestions, handleDropIntoSelectedActions]
    );

    return (
      <Box display={"flex"} flexDirection={"row"} height={"100%"}>
        {formToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {formToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {formToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.SUCCESS && (
          <DragDropContext onDragEnd={onDragEnd}>
            <VStack
              spacing={5}
              width={"325px"}
              flexShrink={0}
              p={"65px 18px"}
              borderRight={"1px solid"}
              borderRightColor={"leadpro.200"}
              overflowY={"auto"}
            >
              <FormToolPageSettingsTemplateQuestionList />
              <FormToolPageSettingsTemplateActionList<FormActionType>
                templateOptions={FormToolPageActionTemplateOptions}
              />
            </VStack>
            <Box
              flexGrow={1}
              overflowY={"auto"}
              display={"flex"}
              justifyContent={"center"}
              px={10}
              py={"65px"}
            >
              <VStack spacing={0} maxW={"980px"} flexGrow={1}>
                <FormToolPageSettingsSelectedQuestionsList
                  formToolPageSettingsEditorStore={
                    formToolPageSettingsEditorStore
                  }
                />
                <FormToolPageSettingsSelectedActionsList
                  formToolPageSettingsEditorStore={
                    formToolPageSettingsEditorStore
                  }
                />
              </VStack>
            </Box>
          </DragDropContext>
        )}
      </Box>
    );
  }
);
