import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useMemo } from "react";
import { Box, HStack, MenuItemOption, MenuOptionGroup } from "@chakra-ui/react";
import { LeadDateRangeFilterEnum } from "enums/lead-next-action-date-filter.enum";
import { DateRangePickerController } from "components/date-picker/DateRangePickerController";
import { isEqual } from "lodash";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { observer } from "mobx-react";
import { DropdownFilterMenuShell } from "components";
import { TSerializedDateRange } from "types/date.type";
import { LeadDateRangeFiltersOptions } from "constants/lead-filters-options";
import { END_OF_TODAY } from "constants/date";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { ResetLeadFilters } from "./LeadFilterMenu/ResetLeadFilters";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

export const FilterLeadsDateRangeHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const {
      pendingFilter: { dateRange: pendingLeadDateRange },
      activeFilter,
    } = leadsFilterStore;

    const isOptionSelected = useMemo(() => {
      if (pendingLeadDateRange.type !== LeadDateRangeFilterEnum.ALL) return 1;

      return 0;
    }, [pendingLeadDateRange]);

    const handleOptionSelect = useCallback(
      (value: string | string[]) => {
        leadsFilterStore.setPendingFilterLeadDateRange({
          type: value as LeadDateRangeFilterEnum,
        });
      },
      [leadsFilterStore]
    );

    const handleDatesChange = useCallback(
      (dates: TSerializedDateRange) => {
        leadsFilterStore.setPendingFilterLeadDateRange({
          type: LeadDateRangeFilterEnum.CUSTOM,
          from: dates.startDate || undefined,
          to: dates.endDate || undefined,
        });
      },
      [leadsFilterStore]
    );

    const onClose = useCallback(() => {
      if (!isEqual(pendingLeadDateRange, activeFilter?.dateRange)) {
        leadsFilterStore.setActiveFromPendingFilter();
      }
    }, [leadsFilterStore, pendingLeadDateRange, activeFilter?.dateRange]);

    return (
      <LeadFilterHeaderCell title={"Date"}>
        <DropdownFilterMenuShell
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          badgeCount={isOptionSelected}
          onClose={onClose}
        >
          <HStack
            width={"100%"}
            justifyContent={"space-between"}
            gap={2}
            pl={5}
            pr={3}
            py={3}
          >
            <Box fontWeight={800} color={"leadpro.700"}>
              Leads In Date Range
            </Box>
            <ResetLeadFilters leadsFilterStore={leadsFilterStore} />
          </HStack>
          <HStack spacing={0} pr={2}>
            <MenuOptionGroup
              type="radio"
              value={pendingLeadDateRange.type}
              onChange={handleOptionSelect}
            >
              {LeadDateRangeFiltersOptions.map(option => (
                <MenuItemOption key={option.value} value={option.value}>
                  {option.label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            <Box borderLeft={"1px solid"} borderColor={"gray.200"}>
              <DateRangePickerController
                startDate={pendingLeadDateRange.from || null}
                endDate={pendingLeadDateRange.to || null}
                onChange={handleDatesChange}
                maxDate={END_OF_TODAY}
              />
            </Box>
          </HStack>
        </DropdownFilterMenuShell>
      </LeadFilterHeaderCell>
    );
  }
);
