import { VStack } from "@chakra-ui/react";
import React from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AdvancedSettings } from "./components/AdvancedSettings";
import { ArchiveLeads } from "./components/ArchiveLeads";
import { Export } from "./components/Export/Export";

interface Props {
  accountStore: UserAccountStore;
}

export const AccountAdvanced: React.FC<Props> = ({ accountStore }) => {
  return (
    <VStack spacing={10} align={"stretch"}>
      <ArchiveLeads accountLeadsStore={accountStore.accountLeadsStore} />
      <Export
        title={"Autocaller Export"}
        exportRequestsStore={
          accountStore.accountExportsStore.autocallerExportRequestsStore
        }
      />
      <Export
        title={"Leads Export"}
        exportRequestsStore={
          accountStore.accountExportsStore.leadsExportRequestsStore
        }
      />
      <AdvancedSettings accountStore={accountStore} />
    </VStack>
  );
};
