import { observer } from "mobx-react";
import React, { FC, useCallback } from "react";
import { IntegrationConfiguratorLayout } from "../IntegrationConfiguratorLayout";
import { DevelopersConfigKeyItem } from "routes/dashboard/routes/settings/components/DevelopersConfigKeyItem";
import { Box } from "@chakra-ui/react";
import { AccountIntegrationZooplaStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationZoopla.store";

interface IProps {
  accountIntegrationZooplaStore: AccountIntegrationZooplaStore;
}
export const ZooplaIntegrationAPIConfigurator: FC<IProps> = observer(
  ({ accountIntegrationZooplaStore }) => {
    const zooplaApiKey = accountIntegrationZooplaStore.zooplaApiKey;

    const onRegenerateKey = useCallback(async () => {
      await accountIntegrationZooplaStore.regenerateZooplaApiKey();
    }, [accountIntegrationZooplaStore]);

    return (
      <IntegrationConfiguratorLayout>
        <Box mr={8} fontSize={"xl"}>
          Zoopla Api Configuration
        </Box>
        <DevelopersConfigKeyItem
          label={"API key"}
          name={"api-key"}
          value={zooplaApiKey || ""}
          onRegenerateKey={onRegenerateKey}
        />
      </IntegrationConfiguratorLayout>
    );
  }
);
