import { observer } from "mobx-react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { AccountAnalyticsStore } from "store/UserAccounts/UserAccount/AccountAnalytics/AccountAnalytics.store";
import { LeadAttributionTypesEnum } from "enums/account-lead-attribution.enum";
import { LeadAttributionReportSegmentDetailsItemBar } from "./LeadAttributionReportSegmentDetailsItemBar/LeadAttributionReportSegmentDetailsItemBar";
import { LeadAttributionReportSegmentDetailsFilter } from "./LeadAttributionReportSegmentDetailsFilter";
import { LeadAttributionReportSegmentDetailsActions } from "./LeadAttributionReportSegmentDetailsActions/LeadAttributionReportSegmentDetailsActions";
import { LeadAttributionReportSegmentDetailsNoDataMessage } from "./LeadAttributionReportSegmentDetailsNoDataMessage";

interface IProps {
  accountAnalyticsStore: AccountAnalyticsStore;
  leadAttributionType: LeadAttributionTypesEnum;
}

export const LeadAttributionReportSegmentDetails: FC<IProps> = observer(
  ({ accountAnalyticsStore, leadAttributionType }) => {
    const { accountAnalyticsLeadAttributionStore } = accountAnalyticsStore;
    const leadAttributionReportData =
      accountAnalyticsLeadAttributionStore.leadAttributionReportDataByType[
        leadAttributionType
      ];
    const filtersDataByType =
      accountAnalyticsLeadAttributionStore
        .accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
        leadAttributionType
      ];

    return (
      <Flex
        gap={10}
        flexGrow={1}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <LeadAttributionReportSegmentDetailsFilter
          leadAttributionType={leadAttributionType}
          accountAnalyticsLeadAttributionStore={
            accountAnalyticsLeadAttributionStore
          }
        />
        <LeadAttributionReportSegmentDetailsNoDataMessage
          leadAttributionType={leadAttributionType}
          accountAnalyticsLeadAttributionStore={
            accountAnalyticsLeadAttributionStore
          }
        />
        {!!filtersDataByType.length && (
          <VStack spacing={5} alignItems={"stretch"}>
            {filtersDataByType.map(itemKey => (
              <LeadAttributionReportSegmentDetailsItemBar
                key={itemKey}
                item={leadAttributionReportData.statsPerItem[itemKey]}
                leadAttributionType={leadAttributionType}
                accountAnalyticsLeadAttributionStore={
                  accountAnalyticsLeadAttributionStore
                }
              />
            ))}
          </VStack>
        )}
        <Box flexGrow={1} display="flex" alignItems={"flex-end"}>
          <LeadAttributionReportSegmentDetailsActions
            leadAttributionType={leadAttributionType}
            accountAnalyticsLeadAttributionStore={
              accountAnalyticsLeadAttributionStore
            }
          />
        </Box>
      </Flex>
    );
  }
);
