import { observer } from "mobx-react";
import { Divider, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, FormControlV2, WizardFooter } from "components";
import React, { FC, useCallback, useMemo } from "react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { INVALID_MOBILE_PHONE_NUMBER } from "constants/validator-messages";
import { isValidMobilePhone } from "utils/custom-yup-validators.utils";
import { TNormalizedUserData } from "types/user-data.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { useWizard } from "utils/react-hooks/useWizard.hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  phoneNumber: string;
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .test(
      "is-valid-mobile-phone",
      INVALID_MOBILE_PHONE_NUMBER,
      isValidMobilePhone
    )
    .required("Phone number is required to enable 2FA."),
});

interface IProps {
  user: TNormalizedUserData;
}

export const TwoFactorAuthSetupPhoneStep: FC<IProps> = observer(({ user }) => {
  const { goToNextStep } = useWizard();
  const toast = useToast();
  const {
    authStore: { updateAuthUser },
  } = useAppStore();

  const initialValues: TFieldValues = useMemo(
    () => ({
      phoneNumber: user.phoneNumber || "",
    }),
    [user]
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (values: TFieldValues) => {
      try {
        await updateAuthUser({
          phoneNumber: values.phoneNumber,
        });
        goToNextStep();
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    },
    [toast, updateAuthUser, goToNextStep]
  );

  return (
    <VStack
      spacing={4}
      align={"stretch"}
      width={"100%"}
      position={"relative"}
      divider={<Divider />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControlV2<TFieldValues>
          name={"phoneNumber"}
          control={control}
          label={"Mobile phone number"}
          description={
            "Please make sure this is you mobile phone number. This number will be used for receiving verification codes via SMS whenever you try to log into LeadPro Dashboard."
          }
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "ex. +447700184816",
          }}
        />
      </form>
      <WizardFooter
        onNext={handleSubmit(onSubmit)}
        isProcessing={isSubmitting}
      />
    </VStack>
  );
});
