import React, { FC } from "react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { Box, Divider, VStack } from "@chakra-ui/react";
import { PersistentFilters } from "./PersistentFilters/PersistentFilters";
import { Journeys } from "./Journeys/Journeys";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountJourneys: FC<IProps> = observer(({ accountStore }) => {
  return (
    <VStack spacing={6} align={"stretch"}>
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"xl"} fontWeight={"bold"}>
          Filters
        </Box>
        <PersistentFilters
          persistentFiltersStore={accountStore.accountPersistentFiltersStore}
        />
      </VStack>
      <Divider />
      <VStack spacing={3} align={"stretch"}>
        <Box fontSize={"xl"} fontWeight={"bold"}>
          Journeys
        </Box>
        <Box fontSize={"sm"} color={"leadpro.500"} maxWidth={"650px"}>
          Journeys are tags associated with your leads. You can associate a
          journey with a filter you've made, and every new lead that lands in
          your dashboard that satisfies that filter will be tagged with the
          appropriate journey. You can order your journeys to determine which
          filters will be matched against your leads before others.
        </Box>
        <Journeys
          accountJourneysStore={accountStore.accountJourneysStore}
          accountPersistentFiltersStore={
            accountStore.accountPersistentFiltersStore
          }
        />
      </VStack>
    </VStack>
  );
});
