import { SingleSelectInput } from "components";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, useCallback } from "react";
import {
  TReapitNegotiatorOptionData,
  TReapitSyncNegotiatorExtendedData,
} from "types/reapit.type";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { ReapitNegotiatorIntegrationSyncSelectOption } from "components/select-input/custom-options/ReapitNegotiatorIntegrationSyncSelectOption";

interface IProps {
  accountStore: UserAccountStore;
  record: TReapitSyncNegotiatorExtendedData;
  onChange: (syncPair: TReapitSyncNegotiatorExtendedData) => void;
}

export const ReapitNegotiatorSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const reapitNegotiatorOptions =
      accountStore.accountIntegrationsStore.accountIntegrationReapitStore
        .accountIntegrationReapitNegotiatorsStore.reapitNegotiatorsOptions;

    const handleChange = useCallback(
      (reapitId: TSelectValue<string>) => {
        onChange({
          reapitId: reapitId || null,
          userAccountId: record.userAccountId,
          user: record.user,
          userFullName: record.userFullName,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    const optionsFilter = useCallback(
      (
        options: TSelectOption<string, TReapitNegotiatorOptionData>[],
        searchTerm: string
      ) => {
        return options.filter(option =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
      },
      []
    );

    return (
      <SingleSelectInput
        value={record.reapitId}
        clearable={true}
        options={reapitNegotiatorOptions}
        onChange={handleChange}
        filterFn={optionsFilter}
        optionComponent={optionProps => (
          <ReapitNegotiatorIntegrationSyncSelectOption
            optionProps={optionProps}
          />
        )}
      />
    );
  }
);
