import { Message } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  ListItem,
  OrderedList,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { WebPushNotificationPermissionTypeEnum } from "enums/web-push-notification-permission-type.enum";
import React, { FC, useCallback, useState } from "react";
import {
  createWebPushSubscription,
  getWebPushNotificationPermission,
  requestWebPushNotificationPermission,
} from "utils/web-push.utils";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";

interface IProps {
  isSupported: boolean;
  notificationsPermission?: NotificationPermission;
  setNotificationsPermission: (permission?: NotificationPermission) => void;
}

export const WebPushSettingsHeaderElement: FC<IProps> = ({
  isSupported,
  notificationsPermission,
  setNotificationsPermission,
}) => {
  const toast = useToast();

  const [
    isRequestWebPushNotificationPermissionInProgress,
    setIsRequestWebPushNotificationPermissionInProgress,
  ] = useState(false);

  const handleAskForWebPushNotificationPermission = useCallback(async () => {
    if (isRequestWebPushNotificationPermissionInProgress) return;

    try {
      setIsRequestWebPushNotificationPermissionInProgress(true);
      await requestWebPushNotificationPermission();

      const permission = getWebPushNotificationPermission();
      setNotificationsPermission(permission);

      if (permission === WebPushNotificationPermissionTypeEnum.GRANTED) {
        await createWebPushSubscription();
      }
    } catch (e) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        title: "Could not register push notifications",
        description: "Please contact support for help",
      });
    } finally {
      setIsRequestWebPushNotificationPermissionInProgress(false);
    }
  }, [
    setNotificationsPermission,
    isRequestWebPushNotificationPermissionInProgress,
    setIsRequestWebPushNotificationPermissionInProgress,
    toast,
  ]);

  if (!isSupported) {
    return (
      <Message status={AlertStatusEnum.WARNING}>
        <VStack width={"100%"} alignItems={"flex-start"}>
          <HStack width={"100%"}>
            <AlertIcon />
            <AlertTitle>Browser not supported.</AlertTitle>
          </HStack>
          <AlertDescription>
            In order to use push notifications, please use latest versions of
            Google Chrome, Mozilla Firefox or Safari.
          </AlertDescription>
        </VStack>
      </Message>
    );
  }

  if (
    notificationsPermission === WebPushNotificationPermissionTypeEnum.DENIED
  ) {
    return (
      <Message status={AlertStatusEnum.ERROR}>
        <VStack width={"100%"}>
          <HStack width={"100%"}>
            <AlertIcon />
            <AlertTitle>Notifications blocked!</AlertTitle>
            <AlertDescription>
              In order to enable notifications, please do the following:
            </AlertDescription>
          </HStack>
          <VStack spacing={2}>
            <AlertDescription>
              <Box textAlign={"justify"}>
                <OrderedList ml={4}>
                  <ListItem>Navigate to browser settings</ListItem>
                  <ListItem>Navigate to security settings</ListItem>
                  <ListItem>Reset permissions for LeadPro Dashboard</ListItem>
                </OrderedList>
              </Box>
            </AlertDescription>
          </VStack>
        </VStack>
      </Message>
    );
  }

  if (
    notificationsPermission === WebPushNotificationPermissionTypeEnum.GRANTED
  ) {
    return (
      <Message status={AlertStatusEnum.SUCCESS}>
        <VStack width={"100%"} alignItems={"flex-start"}>
          <HStack width={"100%"}>
            <AlertIcon />
            <AlertTitle>Notifications allowed.</AlertTitle>
          </HStack>
          <AlertDescription>
            Your will receive system notifications about new leads even while
            not actively using the LeadPro dashboard.
          </AlertDescription>
        </VStack>
      </Message>
    );
  }

  if (
    notificationsPermission === WebPushNotificationPermissionTypeEnum.DEFAULT
  ) {
    return (
      <Message status={AlertStatusEnum.INFO}>
        <VStack width={"100%"} alignItems={"flex-start"}>
          <HStack width={"100%"}>
            <AlertIcon />
            <AlertTitle>Please allow notifications.</AlertTitle>
          </HStack>
          <AlertDescription width={"100%"}>
            <HStack spacing={2} width={"100%"}>
              <Box flexGrow={1}>
                In order to receive notifications, you need to allow them in
                your browser.
              </Box>
              <Button
                onClick={handleAskForWebPushNotificationPermission}
                colorScheme={"blue"}
              >
                Allow notifications
              </Button>
            </HStack>
          </AlertDescription>
        </VStack>
      </Message>
    );
  }

  return null;
};
