import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { TLeadStatusBasic } from "types/lead-status.type";
import { stageIcons } from "constants/icons";
import { stageColors } from "constants/colors";

interface ILeadStatusTagProps {
  status: TLeadStatusBasic;
}

export const LeadStatusTag: React.FC<ILeadStatusTagProps> = ({ status }) => {
  if (!status) return null;

  const IconComponent = stageIcons[status.stageId];

  return (
    <HStack spacing={2}>
      <IconComponent boxSize={3} color={stageColors[status.stageId]} />
      <Box color={"gray.900"}>{status.name}</Box>
    </HStack>
  );
};
