import { FC } from "react";
import {
  EditorProvider,
  Editor,
  Toolbar,
  Separator,
  BtnUndo,
  BtnRedo,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnStrikeThrough,
  BtnClearFormatting,
  HtmlButton,
  BtnStyles,
  ContentEditableEvent,
} from "react-simple-wysiwyg";

const style = { height: "100%", overflow: "auto" };

interface IProps {
  value: string;
  onChange: (event: ContentEditableEvent) => void;
}

export const MarkdownEditor: FC<IProps> = ({ value, onChange }) => {
  return (
    <EditorProvider>
      <Editor value={value} onChange={onChange} containerProps={{ style }}>
        <Toolbar>
          <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough />
          <Separator />
          <BtnClearFormatting />
          <HtmlButton />
          <Separator />
          <BtnStyles />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};
