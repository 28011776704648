import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { Button } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import {
  LEADS_ROUTE,
  TOOLS_AUTOCALLER_ROUTE,
  TOOLS_IVT_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
} from "constants/routes";
import { isApplicationProductUnlocked } from "utils/account-billing.utils";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  accountStore: UserAccountStore;
  removeDashboardTour: (tourId: DashboardTourEnum) => void;
}

export const PageHeaderTourButton: FC<IProps> = observer(
  ({ accountStore, removeDashboardTour }) => {
    const location = useLocation();
    const accountBilling = accountStore.accountBillingStore.billing;

    const tourId = useMemo(() => {
      if (location.pathname.includes(LEADS_ROUTE)) {
        return DashboardTourEnum.GENERAL_TOUR;
      }
      if (
        location.pathname.includes(TOOLS_IVT_ROUTE) &&
        isApplicationProductUnlocked(
          ApplicationProductEnum.INSTANT_VALUATION_TOOL,
          accountBilling
        )
      ) {
        return DashboardTourEnum.IVT_TOUR;
      }

      if (
        location.pathname.includes(TOOLS_LEAD_RESPONDER_ROUTE) &&
        isApplicationProductUnlocked(
          ApplicationProductEnum.LEAD_RESPONDER,
          accountBilling
        )
      ) {
        return DashboardTourEnum.LEAD_RESPONDER_TOUR;
      }

      if (
        location.pathname.includes(TOOLS_AUTOCALLER_ROUTE) &&
        isApplicationProductUnlocked(
          ApplicationProductEnum.AUTOCALLER,
          accountBilling
        )
      ) {
        return DashboardTourEnum.AUTOCALLER_TOUR;
      }

      return undefined;
    }, [location, accountBilling]);

    const handleHelpClick = useCallback(() => {
      if (!!tourId) {
        removeDashboardTour(tourId);
      }
    }, [removeDashboardTour, tourId]);

    if (!tourId) return null;

    return (
      <Button
        size={"xs"}
        fontWeight={500}
        variant={"link"}
        color={"leadpro.600"}
        onClick={handleHelpClick}
        {...{ tour: `${DashboardTourEnum.GENERAL_TOUR}-4` }}
      >
        Tour this page
      </Button>
    );
  }
);
