import { observer } from "mobx-react";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ApiMessageStack,
  ExternalLink,
  FormControlDescription,
  FormControlV2,
  ShowWhenEcosystemSupported,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { NavLink } from "react-router-dom";
import { RESET_PASSWORD_ROUTE, SIGNUP_ROUTE } from "constants/routes";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { useCallback } from "react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { useRecaptchaV2 } from "utils/react-hooks/useRecaptchaV2.hook";
import { LEADPRO_WEBSITE_TERMS_AND_CONDITIONS } from "constants/external-paths";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  email: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(REQUIRED_FIELD),
  password: Yup.string().required(REQUIRED_FIELD),
});

const initialValues: TFieldValues = {
  email: "",
  password: "",
};

export const LoginForm = observer(() => {
  const { reCaptchaV2 } = useRecaptchaV2();
  const toast = useToast();
  const {
    authStore: { leadProAuthStore, ecosystemAuthStore },
  } = useAppStore();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({ email, password }: TFieldValues) => {
      try {
        if (!!reCaptchaV2) {
          const reCaptchaToken = (await reCaptchaV2.executeAsync()) || "";
          reCaptchaV2.reset();
          await leadProAuthStore.login(email, password, reCaptchaToken);
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [leadProAuthStore, toast, reCaptchaV2]
  );

  return (
    <Box>
      <Heading fontSize="3xl" textAlign="center" lineHeight="none" mb={6}>
        Welcome back
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={2} align={"stretch"}>
          <FormControlV2<TFieldValues>
            name={"email"}
            control={control}
            isDisabled={isSubmitting}
            type={FormControlsTypeEnum.TEXT}
            additionalProps={{
              placeholder: "Email",
            }}
          />
          <FormControlV2<TFieldValues>
            name={"password"}
            control={control}
            isDisabled={isSubmitting}
            type={FormControlsTypeEnum.PASSWORD}
            additionalProps={{
              placeholder: "Password",
            }}
          />
        </VStack>
        <NavLink to={RESET_PASSWORD_ROUTE}>
          <Box
            color="gray.600"
            mb={4}
            mt={2}
            fontSize="xs"
            _hover={{ textDecoration: "underline" }}
          >
            Forgot your password?
          </Box>
        </NavLink>
        <VStack spacing={2} align={"stretch"}>
          <Button
            type={"submit"}
            isFullWidth={true}
            colorScheme={"blue"}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Login
          </Button>
          <Box>
            <NavLink
              to={SIGNUP_ROUTE}
              style={{
                display: "block",
              }}
            >
              <Button isFullWidth={true} variant="outline" colorScheme={"blue"}>
                Create an account
              </Button>
            </NavLink>
          </Box>
          <ShowWhenEcosystemSupported hideError={true}>
            <Box>
              <HStack spacing={2} mb={2}>
                <Divider />
                <FormControlDescription>OR</FormControlDescription>
                <Divider />
              </HStack>
              <Button
                isFullWidth={true}
                onClick={ecosystemAuthStore.login}
                colorScheme={"teal"}
              >
                Login with NURTUR
              </Button>
            </Box>
          </ShowWhenEcosystemSupported>
        </VStack>
      </form>
      <Box textAlign="center" pt={6} fontSize="sm" color="gray.600">
        By signing in and using the application, you are agreeing to abide by
        our{" "}
        <ExternalLink
          label={"terms and conditions"}
          href={LEADPRO_WEBSITE_TERMS_AND_CONDITIONS}
        />{" "}
        of use.
      </Box>
    </Box>
  );
});
