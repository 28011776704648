import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlMeta,
  FormControlV2,
} from "components";
import { MANUAL_ADD_LEAD_TYPE_OPTIONS } from "constants/lead-type-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { FC, useCallback } from "react";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import {
  manualAddLeadInitialValues,
  manualAddLeadValidationSchema,
} from "utils/validation-schemas/manual-add-lead.validation";
import {
  shouldGeneralEnquiryShow,
  shouldPropertyDetailsShow,
} from "utils/manual-add-lead.utils";
import { ConfirmActionPrompt } from "../ConfirmActionPrompt";
import { OfficeAutosuggestSelect } from "./OfficeAutosuggestSelect";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TManualAddLeadFieldValues } from "types/manual-add-lead.type";

export const CONFIRM_ADD_LEAD_MODAL_KEY = "CONFIRM_ADD_LEAD_MODAL_KEY";

interface IProps {
  accountStore: UserAccountStore;
  closePrompt: () => void;
}

export const ManualAddLeadPrompt: FC<IProps> = observer(
  ({ accountStore, closePrompt }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();

    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountLeadsStore = accountStore.accountLeadsStore;

    const {
      handleSubmit,
      control,
      watch,
      formState: { isSubmitting },
    } = useForm<TManualAddLeadFieldValues>({
      defaultValues: manualAddLeadInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(manualAddLeadValidationSchema),
    });

    const watchType = watch("type");
    const watchGeneralEnquiry = watch("generalEnquiry");
    const watchPostcode = watch("postcode");

    const onConfirmSubmitAddLead = useCallback(
      async ({ generalEnquiry, ...rest }: TManualAddLeadFieldValues) => {
        try {
          await accountLeadsStore.manuallyAddLead(rest, generalEnquiry);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"Lead has been created."} />
            ),
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountLeadsStore, closePrompt, toast]
    );

    const onSubmit = useCallback(
      async (values: TManualAddLeadFieldValues) => {
        if (!values.officeId) {
          setModal(
            <ConfirmActionPrompt
              header={
                "Are you sure you don't want to assign this lead to an office?"
              }
              text={
                "If you do not assign this lead to an office and the postcode falls outside your office coverage, this lead could get referred to another office in your referral network."
              }
              onConfirm={() => onConfirmSubmitAddLead(values)}
              onDenyBtnLabel={"Go back"}
              closePrompt={() => unSetModal(CONFIRM_ADD_LEAD_MODAL_KEY)}
            />,
            {
              key: CONFIRM_ADD_LEAD_MODAL_KEY,
            }
          );
        } else {
          await onConfirmSubmitAddLead(values);
        }
      },
      [setModal, unSetModal, onConfirmSubmitAddLead]
    );

    return (
      <ActionPromptContainer
        header={"Add a new lead"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControlV2<TManualAddLeadFieldValues>
                name={"type"}
                control={control}
                type={FormControlsTypeEnum.SINGLE_SELECT}
                label={"Type "}
                additionalProps={{
                  options: MANUAL_ADD_LEAD_TYPE_OPTIONS,
                }}
              />
              {shouldGeneralEnquiryShow(watchType) && (
                <HStack spacing={2} alignItems={"center"} width={"100%"} mt={3}>
                  <FormControlV2<TManualAddLeadFieldValues>
                    name={"generalEnquiry"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                  />
                  <FormControlMeta
                    label={"General Enquiry"}
                    description={`Hides Property Details section.`}
                  />
                </HStack>
              )}
              <Divider my={4} />
              <Heading my={3} fontSize={"md"}>
                Person Details
              </Heading>
              <SimpleGrid columns={2} spacing={4} mt={3}>
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"firstName"}
                  control={control}
                  label={"First Name"}
                  type={FormControlsTypeEnum.TEXT}
                />
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"lastName"}
                  control={control}
                  label={"Last Name"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={4} mt={3}>
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"email"}
                  control={control}
                  label={"Email"}
                  type={FormControlsTypeEnum.TEXT}
                />
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"phone"}
                  control={control}
                  label={"Phone"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={4} mt={3}>
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"address"}
                  control={control}
                  label={"Address"}
                  type={FormControlsTypeEnum.TEXT}
                />
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"postcode"}
                  control={control}
                  label={"Postcode"}
                  type={FormControlsTypeEnum.TEXT}
                />
              </SimpleGrid>
              <Divider my={4} />
              {shouldPropertyDetailsShow(watchType, watchGeneralEnquiry) && (
                <>
                  <Heading my={3} fontSize={"md"}>
                    Property Details
                  </Heading>
                  <SimpleGrid columns={2} spacing={4} mt={3}>
                    <FormControlV2<TManualAddLeadFieldValues>
                      name={"advertAddress"}
                      control={control}
                      label={"Advert Address"}
                      type={FormControlsTypeEnum.TEXT}
                    />
                    <FormControlV2<TManualAddLeadFieldValues>
                      name={"advertPostcode"}
                      control={control}
                      label={"Advert Postcode"}
                      type={FormControlsTypeEnum.TEXT}
                    />
                  </SimpleGrid>
                  <SimpleGrid columns={2} spacing={4} mt={3}>
                    <FormControlV2<TManualAddLeadFieldValues>
                      name={"advertUrl"}
                      control={control}
                      label={"Advert Url"}
                      type={FormControlsTypeEnum.TEXT}
                    />
                    <FormControlV2<TManualAddLeadFieldValues>
                      name={"propertyReference"}
                      control={control}
                      label={"Property Reference"}
                      type={FormControlsTypeEnum.TEXT}
                    />
                  </SimpleGrid>
                  <Divider my={4} />
                </>
              )}
              <Box mt={3}>
                <FormControlV2<TManualAddLeadFieldValues>
                  name={"message"}
                  control={control}
                  label={"Message"}
                  type={FormControlsTypeEnum.TEXTAREA}
                />
              </Box>
              <Divider my={4} />
              <Box mt={3}>
                <FormControlMeta
                  label={"Office"}
                  description={"Assign new lead to this office."}
                />
                <Controller<TManualAddLeadFieldValues, "officeId">
                  name={"officeId"}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <OfficeAutosuggestSelect
                      value={value}
                      onChange={onChange}
                      error={error}
                      postcode={watchPostcode}
                      accountOfficesStore={accountOfficesStore}
                    />
                  )}
                />
              </Box>
              <Divider my={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Add Lead
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
