import { AppStore } from "../../../../App.store";
import {
  IObservableArray,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { AccountLeadAttributionAnalyticsApi } from "api/account-lead-attribution-analytics.api";
import {
  TAccountLeadAttributionCalculationConfig,
  TItemStatsSummary,
  TLeadAttributionByItem,
  TLeadAttributionByJourneyStatsDto,
  TLeadAttributionByOfficeStatsDto,
  TLeadAttributionBySourceStatsDto,
  TLeadAttributionByUtmCampaignStatsDto,
  TLeadAttributionCalculationMatrix,
  TLeadAttributionTypeSummary,
} from "types/account-lead-attribution-analyticis.type";
import { TSerializedDateRange } from "types/date.type";
import {
  LeadAttributionGroupedEnum,
  LeadAttributionStatusEnum,
  LeadAttributionTypesEnum,
} from "enums/account-lead-attribution.enum";
import { getLeadAttributionStatsForItem } from "utils/account-lead-attribution-analytics.utils";
import { AccountAnalyticsLeadAttributionFiltersStore } from "./AccountAnalyticsLeadAttributionFilters.store";
import { AccountAnalyticsFiltersStore } from "../AccountAnalyticsFilters.store";
import { keyBy } from "lodash";

const leadAttributionAnalyticsApi = new AccountLeadAttributionAnalyticsApi();

export class AccountAnalyticsLeadAttributionStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore;
  public readonly accountAnalyticsLeadAttributionFiltersStore: AccountAnalyticsLeadAttributionFiltersStore;
  public leadAttributionAnalyticsCalculationConfig: TAccountLeadAttributionCalculationConfig | null;
  private leadAttributionStatsByOffice: IObservableArray<
    TLeadAttributionByOfficeStatsDto
  >;
  private leadAttributionStatsBySource: IObservableArray<
    TLeadAttributionBySourceStatsDto
  >;
  private leadAttributionStatsByUTMCampaign: IObservableArray<
    TLeadAttributionByUtmCampaignStatsDto
  >;
  private leadAttributionStatsByJourney: IObservableArray<
    TLeadAttributionByJourneyStatsDto
  >;

  constructor(
    root: AppStore,
    accountId: number,
    accountAnalyticsFiltersStore: AccountAnalyticsFiltersStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountAnalyticsFiltersStore = accountAnalyticsFiltersStore;
    this.accountAnalyticsLeadAttributionFiltersStore = new AccountAnalyticsLeadAttributionFiltersStore(
      this
    );
    this.leadAttributionAnalyticsCalculationConfig = null;
    this.leadAttributionStatsByOffice = observable.array<
      TLeadAttributionByOfficeStatsDto
    >();
    this.leadAttributionStatsBySource = observable.array<
      TLeadAttributionBySourceStatsDto
    >();
    this.leadAttributionStatsByUTMCampaign = observable.array<
      TLeadAttributionByUtmCampaignStatsDto
    >();
    this.leadAttributionStatsByJourney = observable.array<
      TLeadAttributionByJourneyStatsDto
    >();
  }

  get leadAttributionCalculationMatrix(): TLeadAttributionCalculationMatrix {
    return {
      [LeadAttributionGroupedEnum.VALUATIONS]: {
        [LeadAttributionStatusEnum.VALUATION_BOOKED]: {
          label: "Valuations",
          multiplier:
            this.leadAttributionAnalyticsCalculationConfig
              ?.averageValueForSaleCompletion || 0,
        },
      },
      [LeadAttributionGroupedEnum.INSTRUCTIONS]: {
        [LeadAttributionStatusEnum.SELLING_INSTRUCTED_TO_MARKET]: {
          label: "Sale instructed",
          multiplier:
            this.leadAttributionAnalyticsCalculationConfig
              ?.averageValueForSaleCompletion || 0,
        },
        [LeadAttributionStatusEnum.LETTING_INSTRUCTED_TO_MARKET]: {
          label: "Let instructed",
          multiplier:
            this.leadAttributionAnalyticsCalculationConfig
              ?.averageValueForLettingInstruction || 0,
        },
      },
      [LeadAttributionGroupedEnum.COMPLETIONS]: {
        [LeadAttributionStatusEnum.PROPERTY_COMPLETED]: {
          label: "Sale completions",
          multiplier:
            this.leadAttributionAnalyticsCalculationConfig
              ?.averageValueForSaleCompletion || 0,
        },
        [LeadAttributionStatusEnum.TENANCY_ACCEPTED]: {
          label: "Let completions",
          multiplier:
            this.leadAttributionAnalyticsCalculationConfig
              ?.averageValueForLettingInstruction || 0,
        },
      },
    };
  }

  get leadAttributionReportDataByType(): Record<
    LeadAttributionTypesEnum,
    TLeadAttributionTypeSummary<TLeadAttributionByItem>
  > {
    return {
      [LeadAttributionTypesEnum.OFFICE]: this.leadAttributionByOfficeReportData,
      [LeadAttributionTypesEnum.SOURCE]: this.leadAttributionBySourceReportData,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .leadAttributionByUTMCampaignReportData,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .leadAttributionByLeadJourneyReportData,
    };
  }

  get totalEstValuesMapByTypeFiltered() {
    const office = this.accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
      LeadAttributionTypesEnum.OFFICE
    ].reduce<number>(
      (accumulator, itemId) =>
        accumulator +
        this.leadAttributionReportDataByType[LeadAttributionTypesEnum.OFFICE]
          .statsPerItem[itemId].totalEstValuePerItem,
      0
    );

    const source = this.accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
      LeadAttributionTypesEnum.SOURCE
    ].reduce<number>(
      (accumulator, itemId) =>
        accumulator +
        this.leadAttributionReportDataByType[LeadAttributionTypesEnum.SOURCE]
          .statsPerItem[itemId].totalEstValuePerItem,
      0
    );

    const campaign = this.accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
      LeadAttributionTypesEnum.CAMPAIGN
    ].reduce<number>(
      (accumulator, itemId) =>
        accumulator +
        this.leadAttributionReportDataByType[LeadAttributionTypesEnum.CAMPAIGN]
          .statsPerItem[itemId].totalEstValuePerItem,
      0
    );

    const journey = this.accountAnalyticsLeadAttributionFiltersStore.filtersDataByType[
      LeadAttributionTypesEnum.JOURNEY
    ].reduce<number>(
      (accumulator, itemId) =>
        accumulator +
        this.leadAttributionReportDataByType[LeadAttributionTypesEnum.JOURNEY]
          .statsPerItem[itemId].totalEstValuePerItem,
      0
    );

    return {
      [LeadAttributionTypesEnum.OFFICE]: office,
      [LeadAttributionTypesEnum.SOURCE]: source,
      [LeadAttributionTypesEnum.CAMPAIGN]: campaign,
      [LeadAttributionTypesEnum.JOURNEY]: journey,
    };
  }

  get leadAttributionByOfficeReportData(): TLeadAttributionTypeSummary<
    TLeadAttributionByOfficeStatsDto
  > {
    const statsPerItem: Record<
      number,
      TItemStatsSummary<TLeadAttributionByOfficeStatsDto>
    > = keyBy(
      this.leadAttributionStatsByOffice.map(byItem =>
        getLeadAttributionStatsForItem<TLeadAttributionByOfficeStatsDto>(
          byItem,
          this.leadAttributionCalculationMatrix
        )
      ),
      officeStat => officeStat.officeId
    );

    return {
      type: LeadAttributionTypesEnum.OFFICE,
      totalEstValue: Object.values(statsPerItem).reduce(
        (accumulator, item) => accumulator + item.totalEstValuePerItem,
        0
      ),
      from: this.accountAnalyticsFiltersStore.dateRange.startDate,
      to: this.accountAnalyticsFiltersStore.dateRange.endDate,
      statsPerItem,
    };
  }

  get leadAttributionBySourceReportData(): TLeadAttributionTypeSummary<
    TLeadAttributionBySourceStatsDto
  > {
    const statsPerItem: Record<
      string,
      TItemStatsSummary<TLeadAttributionBySourceStatsDto>
    > = keyBy(
      this.leadAttributionStatsBySource.map(byItem =>
        getLeadAttributionStatsForItem<TLeadAttributionBySourceStatsDto>(
          byItem,
          this.leadAttributionCalculationMatrix
        )
      ),
      sourceStat => sourceStat.source
    );

    return {
      type: LeadAttributionTypesEnum.SOURCE,
      totalEstValue: Object.values(statsPerItem).reduce(
        (accumulator, item) => accumulator + item.totalEstValuePerItem,
        0
      ),
      from: this.accountAnalyticsFiltersStore.dateRange.startDate,
      to: this.accountAnalyticsFiltersStore.dateRange.endDate,
      statsPerItem,
    };
  }

  get leadAttributionByUTMCampaignReportData(): TLeadAttributionTypeSummary<
    TLeadAttributionByUtmCampaignStatsDto
  > {
    const statsPerItem: Record<
      string,
      TItemStatsSummary<TLeadAttributionByUtmCampaignStatsDto>
    > = keyBy(
      this.leadAttributionStatsByUTMCampaign.map(byItem =>
        getLeadAttributionStatsForItem<TLeadAttributionByUtmCampaignStatsDto>(
          byItem,
          this.leadAttributionCalculationMatrix
        )
      ),
      utmCampaignStat => utmCampaignStat.utmCampaign
    );

    return {
      type: LeadAttributionTypesEnum.CAMPAIGN,
      totalEstValue: Object.values(statsPerItem).reduce(
        (accumulator, item) => accumulator + item.totalEstValuePerItem,
        0
      ),
      from: this.accountAnalyticsFiltersStore.dateRange.startDate,
      to: this.accountAnalyticsFiltersStore.dateRange.endDate,
      statsPerItem,
    };
  }

  get leadAttributionByLeadJourneyReportData(): TLeadAttributionTypeSummary<
    TLeadAttributionByJourneyStatsDto
  > {
    const statsPerItem: Record<
      string,
      TItemStatsSummary<TLeadAttributionByJourneyStatsDto>
    > = keyBy(
      this.leadAttributionStatsByJourney.map(byItem =>
        getLeadAttributionStatsForItem<TLeadAttributionByJourneyStatsDto>(
          byItem,
          this.leadAttributionCalculationMatrix
        )
      ),
      journeyStat => journeyStat.journeyName
    );

    return {
      type: LeadAttributionTypesEnum.JOURNEY,
      totalEstValue: Object.values(statsPerItem).reduce(
        (accumulator, item) => accumulator + item.totalEstValuePerItem,
        0
      ),
      from: this.accountAnalyticsFiltersStore.dateRange.startDate,
      to: this.accountAnalyticsFiltersStore.dateRange.endDate,
      statsPerItem,
    };
  }

  private setLeadAttributionAnalyticsCalculationConfig(
    config: TAccountLeadAttributionCalculationConfig
  ) {
    this.leadAttributionAnalyticsCalculationConfig = config;
  }

  private setLeadAttributionStatsByOffice(
    data: TLeadAttributionByOfficeStatsDto[]
  ) {
    this.leadAttributionStatsByOffice.replace(data);
  }

  private setLeadAttributionStatsBySource(
    data: TLeadAttributionBySourceStatsDto[]
  ) {
    this.leadAttributionStatsBySource.replace(data);
  }

  private setLeadAttributionStatsByUTMCampaign(
    data: TLeadAttributionByUtmCampaignStatsDto[]
  ) {
    this.leadAttributionStatsByUTMCampaign.replace(data);
  }

  private setLeadAttributionStatsByJourney(
    data: TLeadAttributionByJourneyStatsDto[]
  ) {
    this.leadAttributionStatsByJourney.replace(data);
  }

  private async fetchAccountLeadAttributionStatsByOffice(
    dateRange: TSerializedDateRange
  ) {
    const stats = await leadAttributionAnalyticsApi.fetchAccountLeadAttributionStatsByOffice(
      this.accountId,
      dateRange
    );
    runInAction(() => {
      this.setLeadAttributionStatsByOffice(stats);
      this.accountAnalyticsLeadAttributionFiltersStore.setTopItemsSortedByEstValue(
        LeadAttributionTypesEnum.OFFICE
      );
    });
  }

  private async fetchAccountLeadAttributionStatsBySource(
    dateRange: TSerializedDateRange
  ) {
    const stats = await leadAttributionAnalyticsApi.fetchAccountLeadAttributionStatsBySource(
      this.accountId,
      dateRange
    );
    runInAction(() => {
      this.setLeadAttributionStatsBySource(stats);
      this.accountAnalyticsLeadAttributionFiltersStore.setTopItemsSortedByEstValue(
        LeadAttributionTypesEnum.SOURCE
      );
    });
  }

  private async fetchAccountLeadAttributionStatsByUTMCampaign(
    dateRange: TSerializedDateRange
  ) {
    const stats = await leadAttributionAnalyticsApi.fetchAccountLeadAttributionStatsByUTMCampaign(
      this.accountId,
      dateRange
    );
    runInAction(() => {
      this.setLeadAttributionStatsByUTMCampaign(stats);
      this.accountAnalyticsLeadAttributionFiltersStore.setTopItemsSortedByEstValue(
        LeadAttributionTypesEnum.CAMPAIGN
      );
    });
  }

  private async fetchAccountLeadAttributionStatsByJourney(
    dateRange: TSerializedDateRange
  ) {
    const stats = await leadAttributionAnalyticsApi.fetchAccountLeadAttributionStatsByJourney(
      this.accountId,
      dateRange
    );
    runInAction(() => {
      this.setLeadAttributionStatsByJourney(stats);
      this.accountAnalyticsLeadAttributionFiltersStore.setTopItemsSortedByEstValue(
        LeadAttributionTypesEnum.JOURNEY
      );
    });
  }

  public fetchAccountLeadAttributionStatsByType(
    type: LeadAttributionTypesEnum,
    dateRange: TSerializedDateRange
  ) {
    const functionsMap: Record<
      LeadAttributionTypesEnum,
      (dateRange: TSerializedDateRange) => Promise<void>
    > = {
      [LeadAttributionTypesEnum.OFFICE]: this
        .fetchAccountLeadAttributionStatsByOffice,
      [LeadAttributionTypesEnum.SOURCE]: this
        .fetchAccountLeadAttributionStatsBySource,
      [LeadAttributionTypesEnum.CAMPAIGN]: this
        .fetchAccountLeadAttributionStatsByUTMCampaign,
      [LeadAttributionTypesEnum.JOURNEY]: this
        .fetchAccountLeadAttributionStatsByJourney,
    };

    return functionsMap[type](dateRange);
  }

  public async fetchLeadAttributionAnalyticsCalculationConfig() {
    const config = await leadAttributionAnalyticsApi.fetchAccountLeadAttributionCalculationConfig(
      this.accountId
    );
    this.setLeadAttributionAnalyticsCalculationConfig(config);
  }

  public async updateLeadAttributionAnalyticsCalculationConfig(
    config: TAccountLeadAttributionCalculationConfig
  ) {
    const updatedConfig = await leadAttributionAnalyticsApi.updateAccountLeadAttributionCalculationConfig(
      this.accountId,
      config
    );
    this.setLeadAttributionAnalyticsCalculationConfig(updatedConfig);
  }
}
