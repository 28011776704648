export enum PredefinedLeadStatus {
  NEW = "New",
  CONTACTED = "Contacted",
  KEEP_IN_TOUCH = "Keep in touch",
  LOST = "Lost",
  NOT_CONTACTABLE = "Not contactable",
  WON = "Won",
  VALUATION_BOOKED = "Valuation booked",
  INSTRUCTED = "Instructed",
  VIEWING_BOOKED = "Viewing booked",
  REGISTERED = "Registered",
  DUPLICATE = "Duplicate",
  SPAM = "Spam",
  DELETED = "Deleted",
  OUT_OF_AREA = "Out of area",
}
