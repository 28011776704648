import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IntegrationConfiguratorLayout } from "../IntegrationConfiguratorLayout";
import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { keyBy } from "lodash";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { TIntegrationSyncOfficeExtendedData } from "types/integration-sync-office.type";
import { syncOfficeTableColumnDef } from "./syncOfficeTableColumnDef";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AccountIntegrationOfficesStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { SyncStatusFilter } from "../SyncStatusFilter";

interface IProps {
  integrationOfficesStore: AccountIntegrationOfficesStore;
  accountIntegrationsStore: AccountIntegrationsStore;
  integrationType: AccountIntegrationTypeEnum;
}

const DEFAULT_PAGE_SIZE = 10;

export const OfficeInputIntegrationTable: React.FC<IProps> = observer(
  ({ accountIntegrationsStore, integrationOfficesStore, integrationType }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TIntegrationSyncOfficeExtendedData>
    >({});
    const integrationData =
      accountIntegrationsStore.availableAccountIntegrationsMapByType[
        integrationType
      ];

    const {
      filteredAccountSyncOfficeData,
      accountSyncOfficeData,
      getSyncStatusFilter,
      setSyncStatusFilter,
    } = integrationOfficesStore;

    useEffect(() => {
      const relations = keyBy<TIntegrationSyncOfficeExtendedData>(
        accountSyncOfficeData,
        syncData => syncData.officeId
      );

      setNewRelations({
        ...relations,
      });
    }, [accountSyncOfficeData, setNewRelations]);

    const updateSyncPair = useCallback(
      (syncPair: TIntegrationSyncOfficeExtendedData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.officeId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await integrationOfficesStore.updateSyncedAccountOffices(
          Object.values(newRelations)
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={`${integrationData.name} integration office configuration updated.`}
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [integrationOfficesStore, newRelations, toast, integrationData]);

    const columnsDef = useMemo(() => {
      return syncOfficeTableColumnDef(updateSyncPair, integrationData);
    }, [updateSyncPair, integrationData]);

    const dataSource = useMemo(() => {
      return filteredAccountSyncOfficeData.map(syncData => {
        if (!!newRelations[syncData.officeId])
          return newRelations[syncData.officeId];

        return syncData;
      });
    }, [filteredAccountSyncOfficeData, newRelations]);

    const banner = useMemo(() => {
      const notSyncedArray = accountSyncOfficeData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your offices are not configured.`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [accountSyncOfficeData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>Office mappings</Box>
        {banner}
        <TableV2<TIntegrationSyncOfficeExtendedData>
          globalFilterInputPlaceholder={"search"}
          dataSource={dataSource}
          additionalActions={
            <SyncStatusFilter
              isChecked={getSyncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          columns={columnsDef}
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
