import { Box, Divider, HStack, VStack } from "@chakra-ui/react";
import { Loader, Paginator, SearchDelayedInput } from "components";
import { sortBy } from "lodash";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { HeaderStub } from "./HeaderStub";
import { RowStub } from "./RowStub";
import {
  ITableV2Props,
  ITableV2SortBy,
  TableV2SortByDirection,
} from "./table-v2";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TableV2Content } from "./table-content/TableV2Content";

const DEFAULT_PAGINATION_DATA = {
  canPreviousPage: false,
  canNextPage: true,
  pageIndex: 0,
};

export function TableV2<T>({
  loadingStatus = ApiRequestStatusEnum.SUCCESS,
  globalFilterInputPlaceholder,
  dataSource,
  columns,
  pageSize,
  striped,
  onRowClick,
  onSortByChange,
  additionalActions,
}: ITableV2Props<T>) {
  const [globalSearchQuery, setGlobalSearchQuery] = useState("");
  const [sortByData, setSortByData] = useState<ITableV2SortBy<T> | null>(null);
  const [paginationData, setPaginationData] = useState(DEFAULT_PAGINATION_DATA);

  // TODO NENAD: add effect search changes that will trigger external handler

  const globalFilterColumns = useMemo(() => {
    return columns.filter(column => column.excludeFromGlobalFilter !== true);
  }, [columns]);

  const handleSortByChange = useCallback(
    (onSortByData: ITableV2SortBy<T> | null) => {
      setSortByData(onSortByData);
      !!onSortByChange && onSortByChange(onSortByData);
    },
    [onSortByChange]
  );

  // TODO NENAD: skip all if external global filtering is on
  const filteredRows = useMemo(() => {
    return dataSource.filter(row => {
      let foundMatch = false;
      for (let i = 0; i < globalFilterColumns.length; i++) {
        if (
          JSON.stringify(row[globalFilterColumns[i].accessor])
            ?.toLowerCase()
            .includes(globalSearchQuery)
        ) {
          foundMatch = true;
          break;
        }
      }

      return foundMatch;
    });
  }, [dataSource, globalSearchQuery, globalFilterColumns]);

  const filteredSortedRows = useMemo(() => {
    if (!sortByData || !!onSortByChange) return filteredRows;

    const sorted = sortBy(filteredRows, row => row[sortByData.field]);
    if (sortByData.direction === TableV2SortByDirection.ASC) return sorted;

    return sorted.reverse();
  }, [filteredRows, sortByData, onSortByChange]);

  const filteredSortedPaginatedRows = useMemo(() => {
    if (!pageSize) return filteredSortedRows;

    const startIndex = paginationData.pageIndex * pageSize;
    const stopIndex = startIndex + pageSize;
    return filteredSortedRows.slice(startIndex, stopIndex);
  }, [pageSize, filteredSortedRows, paginationData]);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGlobalSearchQuery(event.target.value.toLowerCase());

      if (!!pageSize) {
        setPaginationData(DEFAULT_PAGINATION_DATA);
      }
    },
    [pageSize]
  );

  const handleChangePage = useCallback(
    (pageIndex: number) => {
      if (!!pageSize) {
        setPaginationData({
          pageIndex,
          canPreviousPage: pageIndex !== 0,
          canNextPage:
            filteredSortedRows.length > pageIndex * pageSize + pageSize,
        });
      }
    },
    [pageSize, filteredSortedRows]
  );

  const gridTemplateColumns = useMemo(() => {
    let gridTemplateColumnsValue = ``;
    columns.forEach(column => {
      gridTemplateColumnsValue += `${
        !!column.width ? ` ${column.width}` : " 1fr"
      }`;
    });

    return gridTemplateColumnsValue;
  }, [columns]);

  return (
    <VStack spacing={4} align={"stretch"} width={"100%"} position={"relative"}>
      {/* GLOBAL FILTER AND ADDITIONAL ACTIONS */}
      <HStack justify={"flex-end"} align={"center"} width={"100%"}>
        {additionalActions}
        {!!globalFilterInputPlaceholder && (
          <Box width={"300px"}>
            <SearchDelayedInput
              placeholder={globalFilterInputPlaceholder}
              handleSearch={handleSearch}
            />
          </Box>
        )}
      </HStack>
      {/* TABLE */}
      <VStack spacing={0} divider={<Divider />}>
        {/* HEADERS */}
        <RowStub
          columnsCount={columns.length}
          gridTemplateColumns={gridTemplateColumns}
        >
          {columns.map((column, index) => (
            <HeaderStub
              key={index}
              column={column}
              sortByData={sortByData}
              setSortByData={handleSortByChange}
            />
          ))}
        </RowStub>
        {/* CONTENT */}
        <TableV2Content<T>
          onRowClick={onRowClick}
          columns={columns}
          gridTemplateColumns={gridTemplateColumns}
          dataSource={filteredSortedPaginatedRows}
          striped={striped}
        />
      </VStack>
      {/* PAGINATOR */}
      {!!pageSize && (
        <Paginator
          canPreviousPage={paginationData.canPreviousPage}
          canNextPage={paginationData.canNextPage}
          pageIndex={paginationData.pageIndex}
          total={filteredSortedRows.length}
          pageSize={pageSize}
          gotoPage={handleChangePage}
        />
      )}
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
    </VStack>
  );
}
