import { observer } from "mobx-react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { MortgageReferralsSplashScreen } from "./components/MortgageReferralsSplashScreen/MortgageReferralsSplashScreen";

interface IProps {
  accountStore: UserAccountStore;
}

export const MortgageReferralsSettings: FC<IProps> = observer(
  ({ accountStore }) => {
    return (
      <PremiumFeatureBlock
        productId={ApplicationProductEnum.MORTGAGES}
        render={isEnabled => (
          <MortgageReferralsSplashScreen
            accountStore={accountStore}
            isEnabled={true}
          />
        )}
        alternativeRender={() => (
          <MortgageReferralsSplashScreen accountStore={accountStore} />
        )}
      />
    );
  }
);
