import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AutocallerTemplateEditor } from "./AutocallerTemplateEditor/AutocallerTemplateEditor";
import { Error, Loader } from "components";

interface IProps {
  isEnabled: boolean;
  accountStore: UserAccountStore;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const AutocallerAdvancedSettings: FC<IProps> = observer(
  ({ accountStore, isEnabled, containerRef }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const { autocallerTemplateStore } = accountStore.accountAutocallerStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            autocallerTemplateStore.loadAutocallerTemplate(),
            autocallerTemplateStore.loadAutocallerTemplateMergeTags(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [autocallerTemplateStore]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <AutocallerTemplateEditor
            accountAutocallerStore={accountStore.accountAutocallerStore}
            isEnabled={isEnabled}
            containerRef={containerRef}
          />
        )}
      </>
    );
  }
);
