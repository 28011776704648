import { observer } from "mobx-react";
import { Route, Switch, useParams } from "react-router-dom";
import React, { FC, useMemo } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_RESPONSES_ROUTE,
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE,
} from "constants/routes";
import { QuestionnaireToolPageSettings } from "./QuestionnaireToolPageSettings/QuestionnaireToolPageSettings";
import { QuestionnaireToolPageResponses } from "../QuestionnaireToolPageResponses/QuestionnaireToolPageResponses";

interface IProps {
  accountStore: UserAccountStore;
}

export const QuestionnaireToolPageDetails: FC<IProps> = observer(
  ({ accountStore }) => {
    const { id } = useParams<{ id: string }>();
    const pageId = useMemo(() => parseInt(id!), [id]);

    return (
      <Switch>
        <Route
          path={TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE}
          exact
        >
          <QuestionnaireToolPageSettings
            accountStore={accountStore}
            pageId={pageId}
          />
        </Route>
        <Route
          path={TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_RESPONSES_ROUTE}
          exact
        >
          <QuestionnaireToolPageResponses
            pageId={pageId}
            accountQuestionnairePagesStore={
              accountStore.accountPagesStore.accountQuestionnairePagesStore
            }
          />
        </Route>
      </Switch>
    );
  }
);
