import {
  useExpanded,
  useTable,
  useFlexLayout,
  TableInstance,
  UseTableRowProps,
  Column,
  Row,
  useSortBy,
} from "react-table";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { NoDataTableView } from "components/table-v2/table-content/NoDataTableView";

const ICON_SIZE = 12;

interface ExtendedTableInstance<D extends object> extends TableInstance<D> {
  row: UseTableRowProps<D>;
}

interface ExpandedRow<D extends Object> extends Row<D> {
  toggleRowExpanded?: () => void;
}

interface ITableSortProperty {
  id: string;
  desc?: boolean;
}

interface IPivotStatTableProps<D extends Object> {
  data: D[] | null;
  columns: Array<Column<D>>;
  getSubRows?: (row: D) => any;
  defaultSort?: ITableSortProperty[];
}

export function PivotStatTable<D extends Object>({
  data,
  columns,
  getSubRows = () => {},
  defaultSort,
}: IPivotStatTableProps<D>) {
  const extendedUseTable = useTable as (
    ...args: any[]
  ) => ExtendedTableInstance<D>;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = extendedUseTable(
    {
      columns,
      data,
      getSubRows,
      initialState: {
        sortBy: defaultSort ? defaultSort : [],
      },
    },
    useFlexLayout,
    useSortBy,
    useExpanded
  );

  return (
    <Box {...getTableProps()} width={"100%"} fontSize={"sm"}>
      <Box bg={"gray.50"} border={"1px solid"} borderColor={"gray.100"}>
        {headerGroups.map(headerGroup => (
          <Box {...headerGroup.getHeaderGroupProps()} paddingRight={"14px"}>
            {headerGroup.headers.map((column: any) => (
              <Box
                {...column.getHeaderProps(column.getSortByToggleProps())}
                fontSize={"sm"}
                color={"gray.600"}
                fontWeight={500}
                p={3}
                textAlign={"left"}
                lineHeight={1}
                textTransform={"uppercase"}
                title={undefined}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"nowrap"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  {column.render("Header")}
                  {!column.disableSortBy && (
                    <Box px={1}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            fontSize={ICON_SIZE}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            fontSize={ICON_SIZE}
                          />
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box
        {...getTableBodyProps()}
        overflowX={"hidden"}
        overflowY={"scroll"}
        maxHeight={"400px"}
      >
        {!rows.length && <NoDataTableView />}
        {rows.map((row: ExpandedRow<D>, index) => {
          prepareRow(row);
          return (
            <Box
              {...row.getRowProps()}
              onClick={() => row.toggleRowExpanded?.()}
              borderBottom={"1px solid"}
              borderColor={"gray.100"}
              cursor="pointer"
              bg={index % 2 === 0 ? "" : "gray.50"}
              _hover={{ bg: "gray.100" }}
              _last={{ borderBottom: "none" }}
            >
              {row.cells.map(cell => {
                return (
                  <Box {...cell.getCellProps()} p={3}>
                    {cell.render("Cell")}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
