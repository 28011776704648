import { Box } from "@chakra-ui/react";
import {
  FormControlDescription,
  Loader,
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  Error,
} from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import React from "react";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import { StatusesConfigurator } from "./StatusesConfigurator";

interface IDefaultStatusesPanelProps {
  leadStatusesStore: AccountLeadStatusesStore;
  loadingStatus: ApiRequestStatusEnum;
}

export const DefaultStatusesPanel: React.FC<IDefaultStatusesPanelProps> = observer(
  ({ leadStatusesStore, loadingStatus }) => {
    const groupedDefaultLeadStatusesByStage =
      leadStatusesStore.accountGroupedDefaultLeadStatusesByStage;

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>Default Statuses</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <Box mb={5}>
            <FormControlDescription>
              These are your account default statuses. These can't be edited.
            </FormControlDescription>
          </Box>
          <Box position={"relative"} minHeight={"200px"}>
            {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
            {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
            {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
              <StatusesConfigurator
                statusesStageMap={groupedDefaultLeadStatusesByStage}
              />
            )}
          </Box>
        </PanelBody>
      </Panel>
    );
  }
);
