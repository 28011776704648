import React, { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { observer } from "mobx-react";
import { RightmoveIntegrationAPIConfigurator } from "./RightmoveIntegrationAPIConfigurator";
import { OfficeInputIntegrationTable } from "../AccountIntegrationConfigurator/OfficeInputIntegrationTable";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";

interface IRightmoveIntegrationConfiguratorProps {
  accountStore: UserAccountStore;
}

export const RightmoveIntegrationConfigurator: FC<IRightmoveIntegrationConfiguratorProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const rightmoveIntegrationStore =
      accountIntegrationsStore.accountIntegrationRightmoveStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountOfficesStore.fetchAccountOffices(),
            rightmoveIntegrationStore.fetchAccountOfficeRelations(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [rightmoveIntegrationStore, accountOfficesStore]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <RightmoveIntegrationAPIConfigurator
              accountIntegrationsStore={accountIntegrationsStore}
            />
            <OfficeInputIntegrationTable
              integrationOfficesStore={rightmoveIntegrationStore}
              accountIntegrationsStore={accountIntegrationsStore}
              integrationType={AccountIntegrationTypeEnum.RIGHTMOVE}
            />
          </>
        )}
      </>
    );
  }
);
