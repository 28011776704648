import { Box, Button } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TSmsTemplate } from "types/sms-template.type";
import { TableV2 } from "components";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { smsTemplateTableColumnDef } from "./smsTemplateTableColumnDef";
import { SmsTemplateEditorPrompt } from "routes/dashboard/components/prompts/SmsTemplatePrompt/SmsTemplateEditorPrompt";
import { TestSmsTemplatePrompt } from "routes/dashboard/components/prompts/SmsTemplatePrompt/TestSmsTemplatePrompt";
import { DeleteTemplatePrompt } from "routes/dashboard/components/prompts/DeleteTemplatePrompt";

interface IProps {
  accountStore: UserAccountStore;
}

export const SmsTemplates: FC<IProps> = observer(({ accountStore }) => {
  const { setModal, unSetModal } = useActionPrompt();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const smsTemplatesStore = accountStore.accountSmsTemplatesStore;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await smsTemplatesStore.loadSmsTemplates();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [smsTemplatesStore]);

  const handleCreateOrEditSmsTemplate = useCallback(
    (smsTemplate?: TSmsTemplate) => () => {
      setModal(
        <SmsTemplateEditorPrompt
          smsTemplate={smsTemplate}
          smsTemplatesStore={smsTemplatesStore}
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, smsTemplatesStore]
  );

  const handleSendTestSMS = useCallback(
    (smsTemplate: TSmsTemplate) => () => {
      setModal(
        <TestSmsTemplatePrompt
          smsTemplate={smsTemplate}
          smsTemplatesStore={smsTemplatesStore}
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, smsTemplatesStore]
  );

  const handleRemoveSmsTemplate = useCallback(
    (smsTemplate: TSmsTemplate) => () => {
      setModal(
        <DeleteTemplatePrompt
          workflows={smsTemplate.workflows}
          onDelete={async () =>
            smsTemplatesStore.removeSmsTemplate(smsTemplate.id)
          }
          closePrompt={unSetModal}
        />
      );
    },
    [setModal, unSetModal, smsTemplatesStore]
  );

  const handleOnRowClick = useCallback(
    (smsTemplate: TSmsTemplate) => {
      handleCreateOrEditSmsTemplate(smsTemplate)();
    },
    [handleCreateOrEditSmsTemplate]
  );

  const columnsDef = useMemo(() => {
    return smsTemplateTableColumnDef(
      handleCreateOrEditSmsTemplate,
      handleSendTestSMS,
      handleRemoveSmsTemplate
    );
  }, [
    handleCreateOrEditSmsTemplate,
    handleSendTestSMS,
    handleRemoveSmsTemplate,
  ]);

  return (
    <Box>
      <TableV2<TSmsTemplate>
        dataSource={smsTemplatesStore.smsTemplatesArray}
        columns={columnsDef}
        pageSize={20}
        loadingStatus={loadingStatus}
        globalFilterInputPlaceholder={"Type sms template name"}
        striped={true}
        onRowClick={handleOnRowClick}
        additionalActions={
          <Box flexGrow={1}>
            <Button
              variant={"link"}
              colorScheme={"blue"}
              onClick={handleCreateOrEditSmsTemplate()}
            >
              + Create SMS template
            </Button>
          </Box>
        }
      />
    </Box>
  );
});
