import { Box, HStack, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IntegrationEntitySyncStatusLabel,
  IntegrationEntitySyncStatusTagColor,
  IntegrationEntitySyncStatusTagIcon,
} from "constants/reapit-sync";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import React, { FC, useMemo } from "react";

interface IIntegrationEntitySyncTagProps {
  syncStatus: IntegrationEntitySyncStatusEnum;
}

export const IntegrationEntitySyncTag: FC<IIntegrationEntitySyncTagProps> = ({
  syncStatus,
}) => {
  const settings = useMemo(() => {
    return {
      color: IntegrationEntitySyncStatusTagColor[syncStatus],
      label: IntegrationEntitySyncStatusLabel[syncStatus],
      icon: IntegrationEntitySyncStatusTagIcon[syncStatus],
    };
  }, [syncStatus]);

  return (
    <Tag
      variant={"subtle"}
      textTransform="capitalize"
      border={"1px solid"}
      borderColor={settings.color}
      colorScheme={settings.color}
      p={0}
      minWidth={"114px"}
    >
      <TagLabel
        m={1}
        px={4}
        lineHeight={1.5}
        bg={"white"}
        color={"leadpro.500"}
        width={"100%"}
        height={"100%"}
        textAlign={"center"}
      >
        <HStack>
          <TagLeftIcon
            fontSize={"lg"}
            as={() => (
              <FontAwesomeIcon color={settings.color} icon={settings.icon} />
            )}
          />
          <Box>{settings.label}</Box>
        </HStack>
      </TagLabel>
    </Tag>
  );
};
