import { makeAutoObservable } from "mobx";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { User as OIDCUser, UserManager } from "oidc-client-ts";
import { ECOSYSTEM_REDIRECT_ROUTE, LOGIN_ROUTE } from "constants/routes";
import { AppStore } from "./App.store";
import { IAuthStore } from "interfaces/IAuthStore";
import { AuthApi } from "../api/auth.api";

const env = runtimeEnv();
const OIDC_AUTHORITY = env.REACT_APP_OIDC_AUTHORITY;
const OIDC_CLIENT_ID = env.REACT_APP_OIDC_CLIENT_ID;
const OIDC_SCOPE = env.REACT_APP_OIDC_SCOPE;
const authApi = new AuthApi();

export class EcosystemAuthStore implements IAuthStore {
  private static oidcUserManager: UserManager | null = null;
  private readonly root: AppStore;
  oidcUser: OIDCUser | null = null;

  static isEcosystemSupported() {
    return !!OIDC_AUTHORITY && !!OIDC_CLIENT_ID && !!OIDC_SCOPE;
  }

  static getOIDCUserManagerInstance() {
    if (!EcosystemAuthStore.isEcosystemSupported())
      throw new Error("Ecosystem authentication currently not supported.");

    if (!!EcosystemAuthStore.oidcUserManager)
      return EcosystemAuthStore.oidcUserManager;

    EcosystemAuthStore.oidcUserManager = new UserManager({
      authority: OIDC_AUTHORITY,
      client_id: OIDC_CLIENT_ID,
      response_type: "code",
      scope: OIDC_SCOPE,
      redirect_uri: `${window.location.origin}${ECOSYSTEM_REDIRECT_ROUTE}`,
      post_logout_redirect_uri: `${window.location.origin}${LOGIN_ROUTE}`,
      loadUserInfo: true,
    });

    return EcosystemAuthStore.oidcUserManager;
  }

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
  }

  // init store is for setting up token if it exists
  public async initStore() {
    this.oidcUser = await EcosystemAuthStore.getOIDCUserManagerInstance().getUser();
    this.root.authStore.setAuth(this.oidcUser?.access_token || null);
  }

  public async login() {
    return EcosystemAuthStore.getOIDCUserManagerInstance().signinRedirect();
  }

  public async removeCredentials() {
    await EcosystemAuthStore.getOIDCUserManagerInstance().removeUser();
  }

  public async loginRedirectCallback() {
    await EcosystemAuthStore.getOIDCUserManagerInstance().signinRedirectCallback();
  }

  public async finalizeInvite(inviteToken: string) {
    await authApi.finalizeInvite(inviteToken);
  }

  public async signoutRedirect() {
    await EcosystemAuthStore.getOIDCUserManagerInstance().signoutRedirect();
  }
}
