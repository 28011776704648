import { BaseApi } from "./base.api";
import { TLeadStatus } from "types/lead-status.type";

export class LeadStatusApi extends BaseApi {
  async createAccountLeadStatus(
    accountId: number,
    data: Partial<TLeadStatus>
  ): Promise<TLeadStatus> {
    return this.post(`/accounts/${accountId}/lead-statuses`, data);
  }

  async updateAccountLeadStatus(
    accountId: number,
    statusId: number,
    data: Partial<TLeadStatus>
  ): Promise<TLeadStatus> {
    return this.put(`/accounts/${accountId}/lead-statuses/${statusId}`, data);
  }

  async fetchAccountLeadStatuses(accountId: number): Promise<TLeadStatus[]> {
    return this.get(`/accounts/${accountId}/lead-statuses`);
  }

  async deleteAccountLeadStatus(
    accountId: number,
    statusId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/lead-statuses/${statusId}`);
  }
}
