import React from "react";
import { Text } from "@chakra-ui/react";

export const OverallScoreTooltip = () => {
  return (
    <Text mb={2} textAlign={"justify"}>
      Your overall score is a combination of your Response Time points and your
      Business Won percentage. They are combined to give you an overall total
      score, which offices are then ranked by.
    </Text>
  );
};
