import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import { Box } from "@chakra-ui/react";
import { FormLogicJump, FormLogicJumpIs } from "@leadpro/forms";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import React, { useCallback, useMemo } from "react";
import { EditLogicElementJumpsPrompt } from "./EditLogicElementJumpsPrompt";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/sharp-solid-svg-icons";
import { getErrorForJumpEdge } from "utils/form-tool-page.utils";
import { Tooltip } from "components";

export default function JumpEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  label,
  data,
  source,
  target,
}: EdgeProps<{
  logicIndex: number;
  jumpIndexes: number[];
  jumps: FormLogicJump[];
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}>) {
  const { getNode } = useReactFlow();
  const { setModal, unSetModal } = useActionPrompt();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const fromNode = getNode(source) as Node<
    TQuestionNodeData,
    QuestionnaireBranchingNodeTypes
  >;
  const toNode = getNode(target) as Node<
    TQuestionNodeData,
    QuestionnaireBranchingNodeTypes
  >;
  const jumps = data?.jumps;
  const questionnaireToolPageWIPFormStore =
    data?.questionnaireToolPageWIPFormStore;
  const validationResult =
    questionnaireToolPageWIPFormStore?.wipFormSchemaValidationResult;

  const error = useMemo(
    () =>
      getErrorForJumpEdge(
        validationResult,
        data?.logicIndex,
        data?.jumpIndexes
      ),
    [validationResult, data?.logicIndex, data?.jumpIndexes]
  );

  const openEditModal = useCallback(() => {
    if (!questionnaireToolPageWIPFormStore) return;
    if (!fromNode || !toNode) return;

    setModal(
      <EditLogicElementJumpsPrompt
        fromNode={fromNode}
        toNode={toNode}
        closePrompt={unSetModal}
        questionnaireToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
      />
    );
  }, [
    fromNode,
    toNode,
    setModal,
    unSetModal,
    questionnaireToolPageWIPFormStore,
  ]);

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <Box
          pointerEvents={"all"}
          position={"absolute"}
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
          p={4}
          fontSize={12}
          border={"1px solid"}
          borderColor={!!error ? "red.200" : "leadpro.200"}
          borderRadius={"full"}
          background={"white"}
        >
          {!!jumps && (
            <Box onClick={openEditModal}>
              {jumps?.map((jump, index) => {
                const jumpValue = (jump as FormLogicJumpIs)?.value;

                return (
                  <Box key={`${jumpValue}-${index}`}>
                    <Box
                      as={"span"}
                      color={"leadpro.400"}
                      textTransform={"lowercase"}
                    >
                      {jump.operator}
                    </Box>{" "}
                    {jumpValue !== undefined && (
                      <Box as={"span"} fontWeight={"bold"}>
                        "{jumpValue}"
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
          {!jumps && (
            <Box as={"span"} color={"leadpro.400"} textTransform={"lowercase"}>
              {label}
            </Box>
          )}
          {!!error && (
            <Tooltip
              color={"red.800"}
              bg={"red.100"}
              border={"transparent"}
              label={error}
            >
              <Box
                color={"red.500"}
                fontSize={"md"}
                position={"absolute"}
                top={"-5px"}
                right={"-5px"}
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Box>
            </Tooltip>
          )}
        </Box>
      </EdgeLabelRenderer>
    </>
  );
}
