import { AppStore } from "store/App.store";
import { makeAutoObservable } from "mobx";
import { TLeadProgressionConfiguration } from "types/account-lead-progression.type";
import { AccountLeadProgressionApi } from "api/account-lead-progression.api";

const leadProgressionApi = new AccountLeadProgressionApi();

export class AccountLeadProgressionStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountLeadProgressionConfigurationData: TLeadProgressionConfiguration | null;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountLeadProgressionConfigurationData = null;
  }

  get accountLeadProgressionConfiguration() {
    return this.accountLeadProgressionConfigurationData;
  }

  public setAccountLeadProgressionConfigurationData(
    configuration: TLeadProgressionConfiguration
  ) {
    this.accountLeadProgressionConfigurationData = configuration;
  }

  public async fetchAccountLeadProgressionConfiguration() {
    const configuration = await leadProgressionApi.fetchAccountLeadProgressionConfiguration(
      this.accountId
    );

    this.setAccountLeadProgressionConfigurationData(configuration);
  }
}
