import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import React, { FC, useCallback, useMemo, useState } from "react";
import { AccountPersistentFiltersStore } from "store/UserAccounts/UserAccount/AccountPersistentFilters.store";
import { observer } from "mobx-react";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { Box, Button } from "@chakra-ui/react";
import { SingleSelectInput } from "components";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { TPersistentFilter } from "types/persistent-filters.type";
import { PersistentFilterFormPrompt } from "routes/dashboard/components/prompts/PersistentFilterPrompt/PersistentFilterFormPrompt";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { filterOptionsByLabel } from "utils/select.utils";
import {
  TriggerablePopoverBlock,
  LeadResponderWorkflowEditorBlock,
} from "../common-blocks";

const EDIT_OR_CREATE_PERSISTENT_FILTER_MODAL =
  "edit-or-create-persistent-filter-modal";

interface IProps {
  wipWorkflow: Partial<TLeadResponderWorkflow>;
  accountPersistentFiltersStore: AccountPersistentFiltersStore;
  updateWipWorkflow: (workflow: Partial<TLeadResponderWorkflow>) => void;
}

export const LeadResponderWorkflowEditorFilterBlock: FC<IProps> = observer(
  ({ wipWorkflow, accountPersistentFiltersStore, updateWipWorkflow }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const [
      selectedPersistentFilterId,
      setSelectedPersistentFilterId,
    ] = useState(wipWorkflow.leadFilterId || null);

    const wipWorkflowPersistentFilter = useMemo(() => {
      if (!!wipWorkflow.leadFilterId) {
        return accountPersistentFiltersStore.persistentFiltersMap[
          wipWorkflow.leadFilterId
        ];
      }
    }, [
      accountPersistentFiltersStore.persistentFiltersMap,
      wipWorkflow.leadFilterId,
    ]);

    const selectedPersistentFilter = useMemo(() => {
      if (!!selectedPersistentFilterId) {
        return accountPersistentFiltersStore.persistentFiltersMap[
          selectedPersistentFilterId
        ];
      }
    }, [
      accountPersistentFiltersStore.persistentFiltersMap,
      selectedPersistentFilterId,
    ]);

    const onOpen = useCallback(() => {
      setSelectedPersistentFilterId(wipWorkflow.leadFilterId || null);
    }, [setSelectedPersistentFilterId, wipWorkflow]);

    const onChange = useCallback(
      (persistentFilterId: TSelectValue<number>) => {
        setSelectedPersistentFilterId(persistentFilterId);
      },
      [setSelectedPersistentFilterId]
    );

    const handleApply = useCallback(
      (onClose: () => void) => () => {
        if (!!selectedPersistentFilterId) {
          updateWipWorkflow({
            ...wipWorkflow,
            leadFilterId: selectedPersistentFilterId,
          });
        }
        onClose();
      },
      [selectedPersistentFilterId, updateWipWorkflow, wipWorkflow]
    );

    const handleCreateOrEditPersistentFilter = useCallback(
      (persistentFilter?: TPersistentFilter) => () => {
        setModal(
          <PersistentFilterFormPrompt
            closePrompt={() =>
              unSetModal(EDIT_OR_CREATE_PERSISTENT_FILTER_MODAL)
            }
            persistentFilter={persistentFilter}
            persistentFiltersStore={accountPersistentFiltersStore}
          />,
          {
            key: EDIT_OR_CREATE_PERSISTENT_FILTER_MODAL,
          }
        );
      },
      [accountPersistentFiltersStore, setModal, unSetModal]
    );

    const handleFilteringPersistantFilterOptions = useCallback(
      (options: TSelectOption<number>[], searchTerm: string) => {
        return filterOptionsByLabel<number>(options, searchTerm);
      },
      []
    );

    return (
      <TriggerablePopoverBlock
        onOpen={onOpen}
        triggerElement={
          <LeadResponderWorkflowEditorBlock
            placeholder={"Select filter"}
            iconColor={"gray"}
            icon={<FontAwesomeIcon icon={faFilter} />}
          >
            {wipWorkflowPersistentFilter?.name}
          </LeadResponderWorkflowEditorBlock>
        }
        headerElement={"Select filter"}
        bodyElement={
          <>
            <SingleSelectInput<number>
              value={selectedPersistentFilterId}
              options={accountPersistentFiltersStore.persistentFiltersAsOptions}
              onChange={onChange}
              filterFn={handleFilteringPersistantFilterOptions}
              clearable={false}
              placeholder={"Select filter"}
            />
            <Box display={"flex"} justifyContent={"space-between"} pt={2}>
              <Button
                variant={"link"}
                onClick={handleCreateOrEditPersistentFilter()}
              >
                + Create filter
              </Button>
              {!!selectedPersistentFilterId && (
                <Button
                  variant={"link"}
                  onClick={handleCreateOrEditPersistentFilter(
                    selectedPersistentFilter
                  )}
                >
                  + Edit filter
                </Button>
              )}
            </Box>
          </>
        }
        handleApply={handleApply}
      />
    );
  }
);
