import { AutocallerDemoLeadData } from "constants/autocaller-demo-lead-data";
import { FC, useCallback, useMemo, useState } from "react";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { AccountAutocallerStore } from "store/UserAccounts/UserAccount/AccountAutocaller/AccountAutocaller.store";

interface IProps {
  autocallerStore: AccountAutocallerStore;
  officeStore: AccountOfficeStore;
  leadType: LeadTypeEnum;
  isDisabled: boolean;
}

export const AutocallerConfiguratorDemoCall: FC<IProps> = observer(
  ({ autocallerStore, officeStore, leadType, isDisabled }) => {
    const toast = useToast();
    const [isMakingDemoCall, setIsMakingDemoCall] = useState(false);
    const phoneNumber = useMemo(
      () => officeStore.phoneNumberWithAutoAttendantByLeadType[leadType],
      [officeStore.phoneNumberWithAutoAttendantByLeadType, leadType]
    );
    const handleDemoCall = useCallback(async () => {
      if (isDisabled) return;

      try {
        setIsMakingDemoCall(true);

        if (phoneNumber) {
          await autocallerStore.performAutocallerDemo({
            leadType: leadType,
            agentLine: phoneNumber,
            customerLine: AutocallerDemoLeadData.phone,
            customerName: `${AutocallerDemoLeadData.firstName} ${AutocallerDemoLeadData.lastName}`,
            propertyAddress: AutocallerDemoLeadData.address,
          });
        }

        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description:
            "The phone number you provided should receive a call shortly.",
        });
        setTimeout(() => {
          setIsMakingDemoCall(false);
        }, 3000);
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    }, [phoneNumber, leadType, isDisabled, toast, autocallerStore]);

    return (
      <Button
        leftIcon={<FontAwesomeIcon icon={faPhone} />}
        colorScheme={"teal"}
        onClick={handleDemoCall}
        isDisabled={isDisabled || !phoneNumber || isMakingDemoCall}
        isLoading={isMakingDemoCall}
      >
        Simulate call
      </Button>
    );
  }
);
