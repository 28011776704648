import {
  TAltoOffice,
  TAltoOfficeRelation,
  TLeadAltoAddress,
} from "types/alto.type";
import { TIntegrationOfficeRelationData } from "../types/integration-sync-office.type";
import { TIntegrationOffice } from "../store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import {
  normaliseAndSplitAddress,
  shiftPropertyName,
  shiftPropertyNumber,
} from "./integration-address.utils";

export const altoOfficeDataToIntegrationOfficeDataMapper = (
  altoOffices: TAltoOffice[]
): TIntegrationOffice[] => {
  return altoOffices.map(altoOffice => {
    return {
      id: altoOffice.altoId,
      name: altoOffice.altoName,
    };
  });
};

export const mapIntegrationOfficeRelationsToAltoOfficeRelations = (
  relations: TIntegrationOfficeRelationData[]
) => {
  return relations.map(relation => {
    return {
      altoId: relation.branchId,
      officeId: relation.officeId,
    };
  });
};
export const mapAltoOfficeRelationsToIntegrationOfficeRelations = (
  altoOfficeRelations: TAltoOfficeRelation[]
) => {
  return altoOfficeRelations.map(altoOfficeRelation => {
    return {
      branchId: altoOfficeRelation.altoId,
      officeId: altoOfficeRelation.officeId,
    };
  });
};

export const getAltoAddressFields = (
  address: string,
  postcode: string
): TLeadAltoAddress => {
  const altoAddress = {
    subDwelling: "",
    nameNumber: "",
    street: "",
    locality: "",
    town: "",
    county: "",
    postcode: postcode || "",
  };

  if (!address) {
    return altoAddress;
  }

  const splitAddress = normaliseAndSplitAddress(address, postcode);
  const name = shiftPropertyName(splitAddress);
  const number = shiftPropertyNumber(splitAddress);

  altoAddress.nameNumber = name ? `${name} ${number}` : number;

  const [street, locality, town, county] = splitAddress.slice();

  return { ...altoAddress, street, locality, town, county };
};
