import { AccountEmailNotificationPreferenceEnum } from "enums/account-email-notification-preference.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faComet, faClock } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@chakra-ui/react";
import React from "react";

export const accountEmailNotificationTilesConfig = [
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_OFF,
    icon: <FontAwesomeIcon icon={faXmark} fontSize={26} />,
    title: "Emails Off",
    description: (
      <Box>
        <Box>
          Leads are only visible on the LeadPro dashboard. You won't receive any
          email notifications.
        </Box>
        <Box></Box>
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_ON,
    icon: <FontAwesomeIcon icon={faClock} fontSize={26} />,
    title: "Emails On (Lead creation or qualification)",
    description: (
      <Box>
        Email notifications will be sent to offices 15 minutes after lead
        creation, or immediately after the questionnaire is completed.
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.PRE_QUALIFIED_LEADS_ONLY,
    icon: <FontAwesomeIcon icon={faComet} fontSize={26} />,
    title: "Email on (Lead qualification)",
    description: (
      <Box>
        Only leads that complete questionnaires will generate office
        notification leads.
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.UNQUALIFIED_LEADS_ONLY,
    icon: <FontAwesomeIcon icon={faCheck} fontSize={26} />,
    title: "Email on (Lead creation)",
    description: (
      <Box>
        Email notifications are sent to offices as soon as the lead is created.
      </Box>
    ),
  },
];

export const accountLeadAutoAssignEmailNotificationTilesConfig = [
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_OFF,
    icon: <FontAwesomeIcon icon={faXmark} fontSize={26} />,
    title: "Emails Off",
    description: (
      <Box>
        <Box>
          Your agents will not receive any email notifications upon agent
          assignment
        </Box>
      </Box>
    ),
  },
  {
    value: AccountEmailNotificationPreferenceEnum.EMAILS_ON,
    icon: <FontAwesomeIcon icon={faCheck} fontSize={26} />,
    title: "Emails On (Lead assignment)",
    description: (
      <Box>
        Email notifications are sent to agents as soon as they’re assigned to
        the lead by auto-assign.
      </Box>
    ),
  },
];
