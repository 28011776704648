import { FormControlLabel, Input } from "..";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InputGroup, InputRightAddon, Switch, Box } from "@chakra-ui/react";
import { InputProps } from "../input/Input";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const IVT_DOMAIN = "." + env.REACT_APP_IVT_DOMAIN;

export const HostInput: React.FC<InputProps> = ({
  isDisabled,
  onChange,
  value,
  onCustomDomainToggle,
  ...rest
}) => {
  const [isCustomDomain, setIsCustomDomain] = useState(
    !!value ? !value.includes(IVT_DOMAIN) : false
  );

  useEffect(() => {
    onCustomDomainToggle(isCustomDomain);
  });

  const computedValue = useMemo(() => {
    if (!value) return "";
    if (isCustomDomain) return value;
    return value.split(IVT_DOMAIN)[0];
  }, [value, isCustomDomain]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const hostString = event.target.value;
      if (isCustomDomain) {
        onChange(hostString);
      } else {
        onChange(hostString + IVT_DOMAIN);
      }
    },
    [isCustomDomain, onChange]
  );

  const handleCustomDomainToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCustomDomain(event.target.checked);
      onCustomDomainToggle?.(event.target.checked);
      onChange("");
    },
    [onChange, onCustomDomainToggle]
  );

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        mb={2}
      >
        <FormControlLabel mr={2}>Use custom domain</FormControlLabel>
        <Switch
          isChecked={isCustomDomain}
          onChange={handleCustomDomainToggle}
          isDisabled={isDisabled}
        />
      </Box>
      <InputGroup>
        <Input
          value={computedValue}
          onChange={handleOnChange}
          isDisabled={isDisabled}
          borderRightRadius={0}
          {...rest}
        />
        {!isCustomDomain && (
          <InputRightAddon height={"42px"} children={IVT_DOMAIN} />
        )}
      </InputGroup>
    </Box>
  );
};
