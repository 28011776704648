import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";
import { ADDRESS_LINES_MAX_LENGTH } from "utils/reapit.utils";
import { TSelectOption } from "types/select-input.type";

export const reapitSyncValidationSchema = (
  isApplicant: boolean,
  showApplicantRequirements: boolean,
  areApplicantRequirementsFieldsMandatory: boolean,
  reapitAccountAreasOptions: TSelectOption<string>[]
) => {
  return Yup.object().shape({
    buildingName: Yup.string(),
    buildingNumber: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),
    line1: isApplicant
      ? Yup.string().max(ADDRESS_LINES_MAX_LENGTH[0])
      : Yup.string()
          .required(REQUIRED_FIELD)
          .max(ADDRESS_LINES_MAX_LENGTH[0]),
    line2: Yup.string().max(ADDRESS_LINES_MAX_LENGTH[1]),
    line3: Yup.string().max(ADDRESS_LINES_MAX_LENGTH[2]),
    line4: Yup.string().max(ADDRESS_LINES_MAX_LENGTH[3]),
    postcode: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),

    locationOptions: Yup.array().when("buildingName", {
      is: () =>
        showApplicantRequirements &&
        areApplicantRequirementsFieldsMandatory &&
        reapitAccountAreasOptions.length > 0,
      then: Yup.array().min(1, REQUIRED_FIELD),
    }),
    minPrice: Yup.number().when("buildingName", {
      is: () =>
        showApplicantRequirements && areApplicantRequirementsFieldsMandatory,
      then: Yup.number()
        .required(REQUIRED_FIELD)
        .typeError("A value is required"),
      otherwise: Yup.number().nullable(),
    }),
    maxPrice: Yup.number().when("buildingName", {
      is: () =>
        showApplicantRequirements && areApplicantRequirementsFieldsMandatory,
      then: Yup.number()
        .required(REQUIRED_FIELD)
        .typeError("A value is required"),
      otherwise: Yup.number().nullable(),
    }),
    contactId: Yup.string(),
  });
};
