import { Box } from "@chakra-ui/react";
import React, { MouseEvent, useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface IProps {
  children?: any;
  path: string;
  exact?: boolean;
  isDisabled?: boolean;
}

export const NavLinkItem: React.FC<IProps> = ({
  children,
  path,
  exact,
  isDisabled,
}) => {
  const location = useLocation();

  const isActive = useMemo(() => {
    let isActive;
    if (exact) {
      isActive = location.pathname === path;
    } else {
      isActive = location.pathname.includes(path);
    }

    return isActive;
  }, [location.pathname, exact, path]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (isDisabled) event.preventDefault();
    },
    [isDisabled]
  );

  return (
    <NavLink
      exact={exact}
      to={path}
      onClick={handleClick}
      style={{
        width: "100%",
        cursor: isDisabled ? "not-allowed" : undefined,
      }}
    >
      <Box
        pointerEvents={isDisabled ? "none" : undefined}
        opacity={isDisabled ? 0.4 : 1}
        width="100%"
        display="flex"
        alignItems="center"
        mb={1}
        px={2}
        fontSize="14px"
        height="32px"
        lineHeight="none"
        rounded="md"
        bg={isActive ? "leadpro.200" : "transparent"}
        py={3}
        _hover={{ bg: "leadpro.100" }}
      >
        {children}
      </Box>
    </NavLink>
  );
};
