import { TLead } from "types/lead.type";
import { ExtendedColumn } from "components/table/table-props";
import { Row } from "react-table";
import {
  LeadAddressCell,
  LeadFromCell,
  LeadNextActionDateCell,
  LeadDateCell,
  LeadStatusCell,
  LeadTypeCell,
  LeadOfficeCell,
  LeadAssigneeCell,
  LeadJourneyCell,
  LeadCampaignCell,
} from "components/table/cells/leads-table";
import { LeadByOption } from "enums/lead-by-option.enum";
import React from "react";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { TPortalData } from "types/portal.type";
import { TUserBasicData } from "types/user-data.type";
import { TLeadStatusBasic } from "types/lead-status.type";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { TAccountOfficeData } from "types/account-office.type";
import {
  FilterLeadsTypeHeader,
  FilterLeadsStatusHeader,
  FilterLeadsQuestionnaireHeader,
  FilterLeadsNextActionDateHeader,
  FilterLeadsDateRangeHeader,
  FiltersLeadsOfficeHeader,
  FilterLeadsSourceHeader,
  FilterLeadsAssigneeHeader,
  FilterLeadsJourneyHeader,
  FilterLeadsUTMCampaignHeader,
} from "./LeadsTableFilterHeaders";
import { LeadSourceCell } from "components/table/cells/leads-table/LeadSourceCell";
import { LeadsTableColumns } from "enums/leads-table-columns.enum";
import { AccountJourneysStore } from "store/UserAccounts/UserAccount/AccountJourneys.store";

export const leadsTableColumnDef = (
  leadsFilterStore: AccountLeadsFiltersStore,
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>,
  leadStatusesMapByStatusId: Record<number, TLeadStatusBasic>,
  accountOfficesDataMap: Record<number, TAccountOfficeData>,
  accountUsersMap: Record<number, TUserBasicData>,
  accountJourneysStore: AccountJourneysStore,
  logoUrl: string | null,
  portalsMap: Record<number, TPortalData>,
  leadSubRoute: string
): ExtendedColumn<TLead>[] => {
  const cols: ExtendedColumn<TLead>[] = [];

  cols.push({
    key: LeadsTableColumns.DATE,
    Header: () => (
      <FilterLeadsDateRangeHeader leadsFilterStore={leadsFilterStore} />
    ),
    accessor: "createdAt",
    width: "150px",
    Cell: ({ row }: { row: Row<TLead> }) => (
      <LeadDateCell lead={row.original} />
    ),
  });

  cols.push(
    {
      key: LeadsTableColumns.LEAD_STATUS,
      Header: () => (
        <FilterLeadsStatusHeader
          subRoute={leadSubRoute}
          leadsFilterStore={leadsFilterStore}
          leadStatusesMapByStatusId={leadStatusesMapByStatusId}
        />
      ),
      accessor: "statusId",
      disableSortBy: true,
      width: "150px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadStatusCell
          status={leadStatusesMapByStatusId[row.original.statusId]}
        />
      ),
    },
    {
      key: LeadsTableColumns.PERSON,
      Header: () => (
        <FilterLeadsQuestionnaireHeader leadsFilterStore={leadsFilterStore} />
      ),
      accessor: "person",
      width: "250px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadFromCell lead={row.original} />
      ),
    },
    {
      key: LeadsTableColumns.LEAD_SOURCE,
      Header: () => (
        <FilterLeadsSourceHeader
          leadsFilterStore={leadsFilterStore}
          leadSourcesMapBySource={leadSourcesMapBySource}
        />
      ),
      accessor: LeadByOption.SOURCE,
      disableSortBy: true,
      width: "220px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadSourceCell
          lead={row.original}
          leadSourcesMapBySource={leadSourcesMapBySource}
          portalsMap={portalsMap}
          accountLogoUrl={logoUrl}
        />
      ),
    },
    {
      id: LeadsTableColumns.JOURNEY,
      key: LeadsTableColumns.JOURNEY,
      Header: () => (
        <FilterLeadsJourneyHeader
          accountJourneysStore={accountJourneysStore}
          leadsFilterStore={leadsFilterStore}
        />
      ),
      disableSortBy: true,
      width: "220px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadJourneyCell lead={row.original} />
      ),
    },
    {
      id: LeadsTableColumns.CAMPAIGN,
      key: LeadsTableColumns.CAMPAIGN,
      Header: (
        <FilterLeadsUTMCampaignHeader leadsFilterStore={leadsFilterStore} />
      ),
      disableSortBy: true,
      width: "220px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadCampaignCell lead={row.original} />
      ),
    },
    {
      key: LeadsTableColumns.LEAD_TYPE,
      Header: () => (
        <FilterLeadsTypeHeader leadsFilterStore={leadsFilterStore} />
      ),
      accessor: LeadByOption.TYPE,
      width: "200px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadTypeCell lead={row.original} />
      ),
    },
    {
      key: LeadsTableColumns.SUBJECT,
      Header: "Subject",
      accessor: (row: TLead) => row.advertAddress || row.address,
      width: "250px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadAddressCell lead={row.original} />
      ),
    },
    {
      key: LeadsTableColumns.ASSIGNED_TO,
      Header: () => (
        <FilterLeadsAssigneeHeader leadsFilterStore={leadsFilterStore} />
      ),
      accessor: "assignedToId",
      width: "200px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadAssigneeCell
          user={
            row.original.assignedToId
              ? accountUsersMap[row.original.assignedToId]
              : null
          }
        />
      ),
    },
    {
      key: LeadsTableColumns.OFFICE,
      Header: () => (
        <FiltersLeadsOfficeHeader leadsFilterStore={leadsFilterStore} />
      ),
      accessor: "officeId",
      width: "200px",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadOfficeCell
          office={
            row.original.officeId
              ? accountOfficesDataMap[row.original.officeId]
              : null
          }
        />
      ),
    },
    {
      key: LeadsTableColumns.NEXT_ACTION_DATE,
      Header: () => (
        <FilterLeadsNextActionDateHeader leadsFilterStore={leadsFilterStore} />
      ),
      accessor: "nextActionDate",
      width: "150px",
      Cell: ({ row }: { row: Row<TLead> }) => (
        <LeadNextActionDateCell lead={row.original} />
      ),
    }
  );

  return cols;
};
