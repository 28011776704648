import React from "react";
import { Box } from "@chakra-ui/react";
import Styles from "./Typography.module.scss";

interface ITypographyProps {
  children: React.ReactNode;
}

export const Typography: React.FC<ITypographyProps> = ({ children }) => {
  return <Box className={Styles.root}>{children}</Box>;
};
