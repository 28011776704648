import { ActionPromptContainer, Wizard } from "components";
import { observer } from "mobx-react";
import { FC } from "react";
import { TNormalizedUserData } from "types/user-data.type";
import { TwoFactorAuthSetupPhoneStep } from "./TwoFactorAuthSetupPhoneStep";
import { TwoFactorAuthSetupVerificationStep } from "./TwoFactorAuthSetupVerificationStep";

interface IProps {
  user: TNormalizedUserData;
  closePrompt: () => void;
}

export const TwoFactorAuthSetupPrompt: FC<IProps> = observer(
  ({ user, closePrompt }) => {
    return (
      <ActionPromptContainer
        contentStyle={{
          maxW: "2xl",
        }}
        header={`Setup Two-factor authentication`}
        body={
          <Wizard>
            <TwoFactorAuthSetupPhoneStep user={user} />
            <TwoFactorAuthSetupVerificationStep closePrompt={closePrompt} />
          </Wizard>
        }
      />
    );
  }
);
