import { observer } from "mobx-react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { Box, Button, Divider, HStack, Switch } from "@chakra-ui/react";
import { FormControlLabel, Input } from "components";
import { INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR_BORDERLESS } from "styles/input-default-pseudobox-behaviour";
import { TLeadResponderWorkflow } from "types/lead-responder-workflow.type";
import { getWorkflowActivityLabel } from "utils/lead-responder-workflow.utils";

interface IProps {
  wipWorkflow: Partial<TLeadResponderWorkflow>;
  updateWipWorkflow: (workflow: Partial<TLeadResponderWorkflow>) => void;
  isSubmittingWorkflow: boolean;
  handleSave: () => void;
}

export const LeadResponderWorkflowEditorPromptHeader: FC<IProps> = observer(
  ({ wipWorkflow, updateWipWorkflow, isSubmittingWorkflow, handleSave }) => {
    const handleWorkflowNameChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        updateWipWorkflow({ ...wipWorkflow, name: event.target.value });
      },
      [updateWipWorkflow, wipWorkflow]
    );

    const handleWorkflowActiveChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        updateWipWorkflow({ ...wipWorkflow, active: event.target.checked });
      },
      [updateWipWorkflow, wipWorkflow]
    );

    return (
      <HStack spacing={4} pr={4}>
        <HStack spacing={0} flexGrow={1}>
          <Box flexGrow={1}>
            <Input
              value={wipWorkflow.name}
              onChange={handleWorkflowNameChange}
              placeholder={"Name"}
              {...INPUT_DEFAULT_PSEUDOBOX_BEHAVIOUR_BORDERLESS}
            />
          </Box>
          <HStack spacing={2}>
            <FormControlLabel>{`Workflow is ${getWorkflowActivityLabel(
              wipWorkflow.active
            )}`}</FormControlLabel>
            <Switch
              name="lead-responder-workflow-active"
              isChecked={wipWorkflow.active}
              onChange={handleWorkflowActiveChange}
            />
          </HStack>
        </HStack>
        <Divider orientation={"vertical"} height={30} borderRightWidth={1} />
        <Button
          colorScheme={"teal"}
          onClick={handleSave}
          isDisabled={isSubmittingWorkflow}
          isLoading={isSubmittingWorkflow}
        >
          Save and close
        </Button>
        <Divider orientation={"vertical"} height={30} borderRightWidth={1} />
      </HStack>
    );
  }
);
