import { TOfficeEmailNotificationsConfiguration } from "types/email-notifications-configuration.type";
import * as Yup from "yup";
import { LeadTypeEnum } from "enums/lead-type.enum";

export type NotificationEmails = keyof Pick<
  TOfficeEmailNotificationsConfiguration,
  "salesEmails" | "lettingsEmails" | "vendorEmails" | "landlordEmails"
>;

export const notificationEmailsValidationSchema = Yup.object().shape({
  salesEmails: Yup.array().of(Yup.string().email()),
  lettingsEmails: Yup.array().of(Yup.string().email()),
  vendorEmails: Yup.array().of(Yup.string().email()),
  landlordEmails: Yup.array().of(Yup.string().email()),
});

export const incompleteValuationLeadsNotificationEmailsValidationSchema = Yup.object().shape(
  {
    landlordEmails: Yup.array().of(Yup.string().email()),
    vendorEmails: Yup.array().of(Yup.string().email()),
  }
);

export const leadTypeNotificationEmails: Record<string, NotificationEmails> = {
  [LeadTypeEnum.Sale]: "salesEmails",
  [LeadTypeEnum.Let]: "lettingsEmails",
  [LeadTypeEnum.Vendor]: "vendorEmails",
  [LeadTypeEnum.Landlord]: "landlordEmails",
};
