import { AnalyticsDateRangeFilterEnum } from "../enums/analytics-date-range-filter.enum";

export const AnalyticsDateRangeFilterOptions: {
  value: AnalyticsDateRangeFilterEnum;
  label: string;
}[] = [
  {
    value: AnalyticsDateRangeFilterEnum.ALL,
    label: "All",
  },
  {
    value: AnalyticsDateRangeFilterEnum.TODAY,
    label: "Today",
  },
  {
    value: AnalyticsDateRangeFilterEnum.THIS_WEEK,
    label: "This week",
  },
  {
    value: AnalyticsDateRangeFilterEnum.THIS_MONTH,
    label: "This month",
  },
  {
    value: AnalyticsDateRangeFilterEnum.THIS_YEAR,
    label: "This year",
  },
  { value: AnalyticsDateRangeFilterEnum.CUSTOM, label: "Custom" },
];
