import { observer } from "mobx-react";
import { QuestionnaireToolPageWIPFormStore } from "../../../../stores/QuestionnaireToolPageWIPForm.store";
import { FC } from "react";
import { QuestionnaireActionType } from "@leadpro/forms";
import { QuestionnaireToolPageSettingsUpdatePostcodeParamsConfigurator } from "./QuestionnaireToolPageSettingsUpdatePostcodeParamsConfigurator";
import { QuestionnaireToolPageSettingsCreateChildLeadParamsConfigurator } from "./QuestionnaireToolPageSettingsCreateChildLeadParamsConfigurator";

interface IProps {
  actionIndex: number;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsActionParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, questionnaireToolPageWIPFormStore }) => {
    const action =
      questionnaireToolPageWIPFormStore.wipFormActionsJS[actionIndex];

    switch (action.type) {
      case QuestionnaireActionType.CREATE_CHILD_LEAD:
        return (
          <QuestionnaireToolPageSettingsCreateChildLeadParamsConfigurator
            actionIndex={actionIndex}
            questionnaireToolPageWIPFormStore={
              questionnaireToolPageWIPFormStore
            }
          />
        );
      case QuestionnaireActionType.UPDATE_POSTCODE:
        return (
          <QuestionnaireToolPageSettingsUpdatePostcodeParamsConfigurator
            actionIndex={actionIndex}
            questionnaireToolPageWIPFormStore={
              questionnaireToolPageWIPFormStore
            }
          />
        );
      default:
        return null;
    }
  }
);
