import ChartistTooltip from "chartist-plugin-tooltips-updated";

const tooltipFnc = (meta: any, value: any) => {
  return `<span class="chartist-tooltip-value">${value}</br>${meta}</span>`;
};

export const seriesWithMeta = (series: any[], labels: any[]) => {
  return (series as any[][]).map(seriesData => {
    return seriesData.map((value, i) => ({ value, meta: labels[i] }));
  });
};

export const tooltipPlugin = ChartistTooltip({
  tooltipFnc,
});
