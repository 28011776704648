import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";

interface IProps {
  envVariable?: string;
  children: React.ReactNode;
}

export const ShowForAccounts: FC<IProps> = observer(
  ({ envVariable, children }) => {
    const {
      userAccountsStore: { selectedAccountStore },
    } = useAppStore();
    const accountId = selectedAccountStore?.account.id;

    const accountIds = useMemo(() => {
      try {
        return envVariable?.split(" ").map((id: string) => parseInt(id));
      } catch (error) {}
    }, [envVariable]);

    const shouldShowChildren = useMemo(() => {
      // missing env, show for everyone
      if (!envVariable) return true;

      // account not loaded or bad env, better to hide
      if (!accountId || !accountIds) return false;

      return accountIds.includes(accountId);
    }, [accountIds, envVariable, accountId]);

    if (shouldShowChildren) return <>{children}</>;

    return null;
  }
);
