import { ITableV2Column } from "components/table-v2/table-v2";
import { TReapitIRSyncOfficeExtendedData } from "types/reapit-internet-registration.type";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { IntegrationEntitySyncTag } from "components";
import { ReapitIREmailSyncInput } from "./ReapitIREmailSyncInput";

export const syncReapitIROfficeTableColumnDef = (
  updateSyncPair: (syncPair: TReapitIRSyncOfficeExtendedData) => void,
  onValidation: (key: string, message: string | null) => void
): ITableV2Column<TReapitIRSyncOfficeExtendedData>[] => {
  return [
    {
      accessor: "officeName",
      Header: <DefaultHeaderCell>LeadPro office</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.officeName}</DefaultRowCell>,
    },
    {
      accessor: "lettingsEmail",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Lettings plain-text email</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <ReapitIREmailSyncInput
            onChange={updateSyncPair}
            record={row}
            field={"lettingsEmail"}
            onValidation={onValidation}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "salesEmail",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Sales plain-text email</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <ReapitIREmailSyncInput
            onChange={updateSyncPair}
            record={row}
            field={"salesEmail"}
            onValidation={onValidation}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      width: "140px",
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
