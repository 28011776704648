import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TReapitSyncNegotiatorExtendedData } from "types/reapit.type";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import { syncReapitNegotiatorTableColumnDef } from "./syncReapitNegotiatorTableColumnDef";
import { observer } from "mobx-react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { SyncStatusFilter } from "../../SyncStatusFilter";
const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
  selectedOfficeGroupId?: number;
}

export const ReapitIntegrationUsersConfigurator: FC<IProps> = observer(
  ({ accountStore, selectedOfficeGroupId }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TReapitSyncNegotiatorExtendedData>
    >({});

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsReapitStore =
      accountIntegrationsStore.accountIntegrationReapitStore;
    const accountIntegrationsReapitNegotiatorsStore =
      accountIntegrationsReapitStore.accountIntegrationReapitNegotiatorsStore;
    const {
      filteredReapitSyncNegotiatorData,
      reapitSyncNegotiatorData,
      setSelectedOfficeGroupId,
    } = accountIntegrationsReapitNegotiatorsStore;
    const setSyncStatusFilter =
      accountIntegrationsReapitNegotiatorsStore.setSyncStatusFilter;
    const syncStatusFilter =
      accountIntegrationsReapitNegotiatorsStore.getSyncStatusFilter;

    const updateSyncPair = useCallback(
      (syncPair: TReapitSyncNegotiatorExtendedData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.userAccountId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    useEffect(() => {
      setSelectedOfficeGroupId(selectedOfficeGroupId);
    }, [setSelectedOfficeGroupId, selectedOfficeGroupId]);

    useEffect(() => {
      const suggestedArray = reapitSyncNegotiatorData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy<TReapitSyncNegotiatorExtendedData>(
        suggestedArray,
        syncData => syncData.userAccountId
      );
      setNewRelations({
        ...suggestedMap,
      });
    }, [reapitSyncNegotiatorData]);

    const columnsDef = useMemo(() => {
      return syncReapitNegotiatorTableColumnDef(accountStore, updateSyncPair);
    }, [accountStore, updateSyncPair]);

    const dataSource = useMemo(() => {
      return filteredReapitSyncNegotiatorData.map(syncData => {
        if (!!newRelations[syncData.userAccountId])
          return newRelations[syncData.userAccountId];

        return syncData;
      });
    }, [filteredReapitSyncNegotiatorData, newRelations]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await accountIntegrationsReapitNegotiatorsStore.updateAccountReapitSyncedNegotiators(
          Object.values(newRelations),
          selectedOfficeGroupId
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "Reapit integration negotiators configuration updated."
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [
      accountIntegrationsReapitNegotiatorsStore,
      newRelations,
      toast,
      selectedOfficeGroupId,
    ]);

    const banner = useMemo(() => {
      const notSyncedArray = reapitSyncNegotiatorData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your negotiators are not syncing to LeadPro`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [reapitSyncNegotiatorData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>User mappings</Box>
        {banner}
        <TableV2<TReapitSyncNegotiatorExtendedData>
          globalFilterInputPlaceholder={"Search users by name"}
          additionalActions={
            <SyncStatusFilter
              isChecked={syncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          dataSource={dataSource}
          columns={columnsDef}
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
