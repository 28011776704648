import { isEmpty } from "lodash";
import { TLeadUTM } from "types/lead.type";

const SOCIALS = ["facebook", "instagram", "linkedin", "x"];
export enum LeadJourneyEnum {
  PPC = "PPC",
  GOOGLE_LOCAL = "GOOGLE_LOCAL",
  GOOGLE_SEARCH = "GOOGLE_SEARCH",
  BING_SEARCH = "BING_SEARCH",
  PAID_SOCIAL = "PAID_SOCIAL",
  OFF_MARKET_2020 = "OFF_MARKET_2020",
  OFF_MARKET_COMMUNICATION = "OFF_MARKET_COMMUNICATION",
  WEBSITE = "WEBSITE",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  SOCIAL = "SOCIAL",
  EMAIL = "EMAIL",
  PRINT = "PRINT",
}

export const LEAD_JOURNEY_LABELS: Record<LeadJourneyEnum, string> = {
  [LeadJourneyEnum.PPC]: "PPC",
  [LeadJourneyEnum.GOOGLE_LOCAL]: "Google Local",
  [LeadJourneyEnum.GOOGLE_SEARCH]: "Google search",
  [LeadJourneyEnum.BING_SEARCH]: "Bing search",
  [LeadJourneyEnum.PAID_SOCIAL]: "Paid Social",
  [LeadJourneyEnum.OFF_MARKET_2020]: "Off Market 2020",
  [LeadJourneyEnum.OFF_MARKET_COMMUNICATION]: "Off Market Communication",
  [LeadJourneyEnum.WEBSITE]: "Website",
  [LeadJourneyEnum.SOCIAL_MEDIA]: "Social Media",
  [LeadJourneyEnum.SOCIAL]: "Social",
  [LeadJourneyEnum.EMAIL]: "Email",
  [LeadJourneyEnum.PRINT]: "Print",
};

export const LEAD_JOURNEY_TO_UTM_CONFIG_MAP: Record<
  LeadJourneyEnum,
  { source: string[]; medium: string[]; campaign: string[] }
> = {
  [LeadJourneyEnum.PPC]: { source: ["google"], medium: ["cpc"], campaign: [] },
  [LeadJourneyEnum.GOOGLE_LOCAL]: {
    source: ["google"],
    medium: ["local"],
    campaign: [],
  },
  [LeadJourneyEnum.GOOGLE_SEARCH]: {
    source: ["google"],
    medium: ["organic"],
    campaign: [],
  },
  [LeadJourneyEnum.BING_SEARCH]: {
    source: ["bing"],
    medium: ["organic"],
    campaign: [],
  },
  [LeadJourneyEnum.PAID_SOCIAL]: {
    source: SOCIALS,
    medium: ["paid"],
    campaign: [],
  },
  [LeadJourneyEnum.OFF_MARKET_2020]: {
    source: ["bym"],
    medium: [],
    campaign: ["recently_sold_near_you"],
  },
  [LeadJourneyEnum.OFF_MARKET_COMMUNICATION]: {
    source: ["bym"],
    medium: [],
    campaign: ["otm_other_agent"],
  },
  [LeadJourneyEnum.WEBSITE]: { source: ["website"], medium: [], campaign: [] },
  [LeadJourneyEnum.SOCIAL_MEDIA]: {
    source: ["social_media"],
    medium: [],
    campaign: [],
  },
  [LeadJourneyEnum.SOCIAL]: { source: SOCIALS, medium: [], campaign: [] },
  [LeadJourneyEnum.EMAIL]: {
    source: ["email"],
    medium: ["newsletter, email"],
    campaign: [],
  },
  [LeadJourneyEnum.PRINT]: {
    source: [],
    medium: ["print"],
    campaign: [],
  },
};

export const getLeadJourneyFromUTMConfig = (
  utm: TLeadUTM
): LeadJourneyEnum | null => {
  if (!utm || isEmpty(utm)) return null;

  const source = utm.source?.toLowerCase() || "";
  const medium = utm.medium?.toLowerCase() || "";
  const campaign = utm.campaign?.toLowerCase() || "";

  if (source === "google" && medium === "cpc") return LeadJourneyEnum.PPC;
  if (source === "google" && medium === "local")
    return LeadJourneyEnum.GOOGLE_LOCAL;
  if (source === "google" && medium === "organic")
    return LeadJourneyEnum.GOOGLE_SEARCH;
  if (source === "bing" && medium === "organic")
    return LeadJourneyEnum.BING_SEARCH;
  if (SOCIALS.includes(source || "") && medium === "paid")
    return LeadJourneyEnum.PAID_SOCIAL;

  if (source === "bym" && campaign === "recently_sold_near_you")
    return LeadJourneyEnum.OFF_MARKET_2020;
  if (source === "bym" && campaign === "otm_other_agent")
    return LeadJourneyEnum.OFF_MARKET_COMMUNICATION;

  if (source === "website") return LeadJourneyEnum.WEBSITE;
  if (source === "social_media") return LeadJourneyEnum.SOCIAL_MEDIA;
  if (SOCIALS.includes(source || "")) return LeadJourneyEnum.SOCIAL;

  if (medium.includes("email")) return LeadJourneyEnum.EMAIL;

  if (medium.includes("print")) return LeadJourneyEnum.PRINT;

  return null;
};
