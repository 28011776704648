import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { IntegrationEntitySyncTag } from "components";
import { ITableV2Column } from "components/table-v2/table-v2";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { TAltoNegotiatorRelationExtendedData } from "types/alto.type";
import { AltoNegotiatorSyncSelect } from "./AltoNegotiatorSyncSelect";

export const syncAltoNegotiatorTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TAltoNegotiatorRelationExtendedData) => void
): ITableV2Column<TAltoNegotiatorRelationExtendedData>[] => {
  return [
    {
      accessor: "userName",
      Header: <DefaultHeaderCell>LeadPro user</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell textTransform={"capitalize"}>
          {row.userName}
        </DefaultRowCell>
      ),
    },
    {
      accessor: "altoId",
      excludeFromGlobalFilter: true,
      disableSortBy: true,
      Header: <DefaultHeaderCell>Alto negotiator</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <AltoNegotiatorSyncSelect
            accountStore={accountStore}
            onChange={updateSyncPair}
            record={row}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
