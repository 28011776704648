import { Box, Button, Divider, useToast, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
  Message,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { FC, useCallback } from "react";
import { TSmsTemplate } from "types/sms-template.type";
import { AccountSmsTemplatesStore } from "store/UserAccounts/UserAccount/AccountSmsTemplates.store";
import * as Yup from "yup";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED_FIELD,
} from "constants/validator-messages";
import { isValidPhone } from "utils/custom-yup-validators.utils";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  phoneNumber: string;
};

const initialValues: TFieldValues = {
  phoneNumber: "",
};

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .test("is-valid-phone", INVALID_PHONE_NUMBER, isValidPhone)
    .required(REQUIRED_FIELD),
});

interface IProps {
  closePrompt: () => void;
  smsTemplate: TSmsTemplate;
  smsTemplatesStore: AccountSmsTemplatesStore;
}

export const TestSmsTemplatePrompt: FC<IProps> = observer(
  ({ closePrompt, smsTemplate, smsTemplatesStore }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async ({ phoneNumber }: TFieldValues) => {
        try {
          await smsTemplatesStore.sendTestSms(smsTemplate.id, phoneNumber);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description:
              "The phone number you provided should receive an SMS shortly.",
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [closePrompt, smsTemplate, smsTemplatesStore, toast]
    );

    return (
      <ActionPromptContainer
        header={"Send test SMS"}
        body={
          <VStack pt={5} spacing={5} align={"stretch"}>
            <Message status={AlertStatusEnum.INFO}>
              <VStack spacing={2} align={"stretch"}>
                <Box>The following SMS text will be sent:</Box>
                <Box fontStyle={"italic"} fontWeight={"bold"}>
                  {smsTemplate.content}
                </Box>
              </VStack>
            </Message>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"phoneNumber"}
                  control={control}
                  label={"Phone number"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Receiving phone number",
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Send
                </Button>
              </Box>
            </form>
          </VStack>
        }
      />
    );
  }
);
