import React from "react";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FormControlV2 } from "components";
import { Control, Path } from "react-hook-form";
import { EnabledOrDisabledTag } from "components/tags/EnabledOrDisabledTag";

type IToggleIntegrationActiveProps<T> = {
  value: boolean;
  name: Path<T>;
  control: Control<T>;
  label?: string;
};

export const ToggleIntegrationActiveSwitchInput = <T,>({
  control,
  name,
  value,
  label,
}: IToggleIntegrationActiveProps<T>) => {
  return (
    <Flex w={"100%"} mb={5}>
      {!!label && (
        <Box mr={8} fontSize={"xl"}>
          {label}
        </Box>
      )}
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <HStack>
          <FormControlV2<T>
            name={name}
            control={control}
            type={FormControlsTypeEnum.SWITCH}
          />
          <EnabledOrDisabledTag isEnabled={value} />
        </HStack>
      </Box>
    </Flex>
  );
};
