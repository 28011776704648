import { AccountOpenViewAPI } from "api/account-openview-api";
import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountIntegrationOfficesStore } from "../AccountIntegrationOfficesStore.store";
import { AccountIntegrationOpenViewContactsStore } from "./AccountIntegrationOpenViewContacts.store";
import { AccountIntegrationOpenViewLeadSourcesStore } from "./AccountIntegrationOpenViewLeadSource.store";
import { AccountIntegrationOpenViewNegotiatorsStore } from "./AccountIntegrationOpenViewNegotiators.store";

const accountOpenViewAPI = new AccountOpenViewAPI();

export class AccountIntegrationOpenViewStore {
  public accountIntegrationOpenViewOfficesStore: AccountIntegrationOfficesStore;
  public accountIntegrationOpenViewLeadSourcesStore: AccountIntegrationOpenViewLeadSourcesStore;
  public accountIntegrationOpenViewNegotiatorsStore: AccountIntegrationOpenViewNegotiatorsStore;
  public accountIntegrationOpenViewContactsStore: AccountIntegrationOpenViewContactsStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountIntegrationOpenViewOfficesStore = new AccountIntegrationOfficesStore(
      root,
      accountId,
      accountOpenViewAPI
    );
    this.accountIntegrationOpenViewLeadSourcesStore = new AccountIntegrationOpenViewLeadSourcesStore(
      root,
      accountId
    );
    this.accountIntegrationOpenViewNegotiatorsStore = new AccountIntegrationOpenViewNegotiatorsStore(
      root,
      accountId
    );
    this.accountIntegrationOpenViewContactsStore = new AccountIntegrationOpenViewContactsStore(
      accountId
    );
  }
}
