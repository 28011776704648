import React, { useCallback, useMemo, MouseEvent } from "react";
import { Card } from "components/stats/Card";
import {
  Box,
  Button,
  Image,
  Text,
  HStack,
  VStack,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { TAccountIntegrationsStaticData } from "types/account-integration.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { ConnectIntegrationAccountPrompt } from "routes/dashboard/components/prompts/ConnectIntegrationAccountPrompt/ConnectIntegrationAccountPrompt";
import { SETTINGS_INTEGRATIONS_ROUTE } from "constants/routes";
import { ExternalLink, InternalLink, ButtonLink } from "components";
import { EnabledOrDisabledTag } from "components/tags/EnabledOrDisabledTag";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  staticData: TAccountIntegrationsStaticData;
}

export const AccountIntegrationItem: React.FC<IProps> = observer(
  ({ accountIntegrationsStore, staticData }) => {
    const history = useHistory();
    const { setModal, unSetModal } = useActionPrompt();
    const accountIntegration =
      accountIntegrationsStore.accountIntegrationsMapByType[staticData.type];

    const disconnectIntegration = useCallback(async () => {
      if (!!accountIntegration) {
        await accountIntegrationsStore.deleteAccountIntegration(
          accountIntegration.id
        );
      }
    }, [accountIntegrationsStore, accountIntegration]);

    const handleOnConnect = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setModal(
          <ConnectIntegrationAccountPrompt
            header={`Connect your ${staticData.name} account`}
            type={staticData.type}
            accountIntegrationsStore={accountIntegrationsStore}
            closePrompt={unSetModal}
          />
        );
      },
      [setModal, unSetModal, staticData, accountIntegrationsStore]
    );

    const handleOnDisconnect = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // TODO NENAD: USE TOASTS
        setModal(
          <ConfirmActionPrompt
            text={`If you proceed with disconnecting the ${staticData.name} integration, its current settings may be lost. If you would like to reactivate this integration in the future, you may need to reconfigure its settings. Please confirm you still want to disconnect this integration.`}
            onConfirm={disconnectIntegration}
            closePrompt={unSetModal}
          />
        );
      },
      [setModal, staticData.name, disconnectIntegration, unSetModal]
    );

    const handleShowDetails = useCallback(() => {
      if (accountIntegration) {
        history.push(`${SETTINGS_INTEGRATIONS_ROUTE}/${staticData.type}`);
      }
    }, [accountIntegration, staticData, history]);

    const accountImageComponent = useMemo(() => {
      if (staticData.affiliateLink)
        return (
          <LinkBox w={"100px"}>
            <LinkOverlay href={staticData.affiliateLink} isExternal>
              <Image
                src={staticData.logoUrl}
                height={"62px"}
                width={"62px"}
                rounded={"md"}
                objectFit={"contain"}
              />
            </LinkOverlay>
          </LinkBox>
        );

      return (
        <Image
          src={staticData.logoUrl}
          height={"62px"}
          width={"62px"}
          rounded={"md"}
          objectFit={"contain"}
        />
      );
    }, [staticData]);

    return (
      <Card>
        <Box
          display="flex"
          w="full"
          p={4}
          onClick={handleShowDetails}
          cursor="pointer"
        >
          {accountImageComponent}
          <Box pl={4} display={"flex"} alignItems={"center"} flexGrow={1}>
            <Box pr={16}>
              <HStack spacing={2} align={"center"}>
                <Text fontSize="md">{staticData.name}</Text>
                {!!accountIntegration && (
                  <EnabledOrDisabledTag isEnabled={accountIntegration.active} />
                )}
              </HStack>
              <VStack
                spacing={3}
                align={"flex-start"}
                fontSize="sm"
                color="gray.500"
              >
                <Box fontWeight="normal">{staticData.description}</Box>
                {!!staticData.guideCTA && !!staticData.guideLink && (
                  <InternalLink href={staticData.guideLink}>
                    {staticData.guideCTA}
                  </InternalLink>
                )}
                {!!staticData.affiliateLink && staticData.affiliateCTA && (
                  <ExternalLink
                    label={staticData.affiliateCTA}
                    href={staticData.affiliateLink}
                  />
                )}
              </VStack>
            </Box>
            <Box ml="auto" width={100} textAlign="right">
              {!accountIntegration ? (
                !!staticData.voteUrl ? (
                  <ButtonLink href={staticData.voteUrl} label={"Vote"} />
                ) : (
                  <Button size="sm" onClick={handleOnConnect}>
                    Connect
                  </Button>
                )
              ) : (
                <Button size="sm" onClick={handleOnDisconnect}>
                  Disconnect
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }
);
