import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import {
  TOOLS_FORMS_PAGE_DETAILS_ROUTE,
  TOOLS_FORMS_ROUTE,
} from "constants/routes";
import { FormToolPages } from "./components/FormToolPages";
import { FormToolPageDetails } from "./components/FormToolPageDetails/FormToolPageDetails";

interface IProps {
  accountStore: UserAccountStore;
}

export const FormTool: FC<IProps> = observer(({ accountStore }) => {
  return (
    <Switch>
      <Route path={TOOLS_FORMS_ROUTE} exact>
        <FormToolPages accountStore={accountStore} />
      </Route>
      <Route path={TOOLS_FORMS_PAGE_DETAILS_ROUTE}>
        <FormToolPageDetails accountStore={accountStore} />
      </Route>
    </Switch>
  );
});
