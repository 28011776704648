import { observer } from "mobx-react";
import React, { FC, useCallback } from "react";
import { FormToolPageSettingsDetailsForm } from "./FormToolPageSettingsDetailsForm/FormToolPageSettingsDetailsForm";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { FormToolPageSettingsEditorStore } from "./FormToolPageSettingsEditor.store";
import { PageToolPageSettingsTabHeader } from "../../../../components/PageTool/PageToolPageSettings/PageToolPageSettingsTabHeader";
import { TOOLS_FORMS_ROUTE } from "constants/routes";
import { AccountFormPagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountFormPagesStore/AccountFormPages.store";
import { FormToolPageSettingsFormBuilder } from "./FormToolPageSettingsFormBuilder/FormToolPageSettingsFormBuilder";
import { PageToolPageSettings } from "routes/dashboard/routes/tools/components/PageTool/PageToolPageSettings/PageToolPageSettings";

interface IProps {
  accountFormPagesStore: AccountFormPagesStore;
  pageId: number | null;
}

export const FormToolPageSettings: FC<IProps> = observer(
  ({ accountFormPagesStore, pageId }) => {
    const formToolPageSettingsEditorStore = useLocalStore(
      FormToolPageSettingsEditorStore,
      {
        accountFormPagesStore,
        pageId,
      }
    );

    const getNewPageRedirectRoute = useCallback(
      (newPageId: number) => `${TOOLS_FORMS_ROUTE}/${newPageId}`,
      []
    );

    return (
      <PageToolPageSettings
        pageToolPageSettingsEditorStore={formToolPageSettingsEditorStore}
        getNewPageRedirectRoute={getNewPageRedirectRoute}
        config={[
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={formToolPageSettingsEditorStore.loadingStatus}
                tabName={"Form details"}
                hasError={!formToolPageSettingsEditorStore.isWIPPageValid}
              />
            ),
            tabHash: "#details",
            tabComponent: (
              <FormToolPageSettingsDetailsForm
                formToolPageSettingsEditorStore={
                  formToolPageSettingsEditorStore
                }
              />
            ),
          },
          {
            tabHeader: (
              <PageToolPageSettingsTabHeader
                loadingStatus={formToolPageSettingsEditorStore.loadingStatus}
                tabName={"Form Builder"}
                hasError={
                  !formToolPageSettingsEditorStore.wipFormStore
                    .isWIPFormSchemaValid ||
                  !formToolPageSettingsEditorStore.wipFormStore
                    .isWIPFormActionsValid
                }
              />
            ),
            tabHash: "#builder",
            tabComponent: (
              <FormToolPageSettingsFormBuilder
                formToolPageSettingsEditorStore={
                  formToolPageSettingsEditorStore
                }
              />
            ),
          },
        ]}
      />
    );
  }
);
