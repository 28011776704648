import { StandardIconButton } from "components";
import { NoltWidget } from "./NoltWidget";
import { AccountSelect } from "./AccountSelect";
import { Box, Image, HStack } from "@chakra-ui/react";
import { Navigation } from "./Navigation/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { TypeFormWidget } from "components/typeform-widget/TypeFormWidget";
import { useMemo } from "react";
import { getTypeformOptions } from "utils/typeform.utils";
import { NavButton } from "components/nav-button/NavButton";
import { LEAD_PRO_BANNER } from "constants/image-paths";
const env = runtimeEnv();

export const LeftSideBar = observer(() => {
  const {
    uiStore,
    authStore: { authUser },
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();
  const accountData = selectedAccountStore?.account;

  const typeformWidgetOptions = useMemo(
    () =>
      getTypeformOptions({
        account: accountData?.id,
        email: authUser?.email,
        firstname: authUser?.firstName,
      }),
    [accountData?.id, authUser]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width={"100%"}
      bg="leadpro.50"
      pb={3}
      borderRight="1px solid"
      borderColor="leadpro.200"
    >
      <HStack
        width={"100%"}
        justify={"space-between"}
        align={"center"}
        mb={3}
        pl={3}
        pr={3}
        height={"70px"}
      >
        <Image
          width={130}
          objectFit="cover"
          alt={"leadpro-banner"}
          src={LEAD_PRO_BANNER}
        />
        <StandardIconButton
          aria-label={"collapse-left-side-bar"}
          color={"leadpro.400"}
          icon={<FontAwesomeIcon icon={faSquareChevronLeft} fontSize={20} />}
          onClick={() => uiStore.toggleLeftSidebar()}
        />
      </HStack>
      <Box px={3} mb={3}>
        <AccountSelect />
      </Box>
      {!!selectedAccountStore && (
        <Navigation accountStore={selectedAccountStore} />
      )}
      <Box mt="auto" width="100%">
        <TypeFormWidget
          typeFormId={env.REACT_APP_TYPEFORM_ID_SUPPORT}
          options={typeformWidgetOptions}
        >
          <NavButton>Support</NavButton>
        </TypeFormWidget>
        <NoltWidget />
      </Box>
    </Box>
  );
});
