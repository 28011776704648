import {
  Button,
  HStack,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiMessageStack, FormControlMeta, FormControlV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { getMetersFromMiles, getMilesFromMeters } from "utils/distance.utils";
import {
  accountIVTConfigValidationSchema,
  TAccountIVTConfigFieldValues,
} from "utils/validation-schemas/account-ivt-config.validation";

interface IProps {
  accountStore: UserAccountStore;
}

export const OfficeDistanceCutoff: FC<IProps> = observer(({ accountStore }) => {
  const accountData = accountStore.account;
  const toast = useToast();

  const initialValues: TAccountIVTConfigFieldValues = useMemo(
    () => ({
      isDistanceCheckEnabled:
        accountData.accountIVTConfig?.isDistanceCheckEnabled || false,
      distanceCheckRadius:
        Math.round(
          getMilesFromMeters(
            accountData.accountIVTConfig?.distanceCheckRadius || 0
          )
        ) || "",
    }),
    [accountData]
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<TAccountIVTConfigFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(accountIVTConfigValidationSchema),
  });

  const onSubmit = async ({
    isDistanceCheckEnabled,
    distanceCheckRadius,
  }: TAccountIVTConfigFieldValues) => {
    try {
      const distanceInMeters = Math.round(
        getMetersFromMiles(distanceCheckRadius || 0)
      );
      await accountStore.updateAccountIVTConfig({
        isDistanceCheckEnabled,
        distanceCheckRadius: distanceInMeters,
      });
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: (
          <ApiMessageStack messageStack={"Account IVT settings updated!"} />
        ),
      });
    } catch (e) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={e.message} />,
      });
    }
  };

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={8} align={"stretch"}>
        <Text fontSize={"2xl"} fontWeight={"bold"} mb={-2}>
          Office distance cutoff
        </Text>
        <SimpleGrid columns={2} spacing={4}>
          <FormControlMeta
            label="Enable office distance cutoff"
            description={
              "Enabling office distance cutoff will prevent valuation leads outside a specified miles radius of any of your office locations from being recorded, instead recommending the user to visit the closest office branch website."
            }
          />
          <FormControlV2<TAccountIVTConfigFieldValues>
            name={"isDistanceCheckEnabled"}
            control={control}
            type={FormControlsTypeEnum.SWITCH}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={4}>
          <FormControlMeta
            label="Office distance cutoff radius"
            description={
              "Specify a miles radius that the office distance cutoff should consider."
            }
          />
          <FormControlV2<TAccountIVTConfigFieldValues>
            name={"distanceCheckRadius"}
            control={control}
            type={FormControlsTypeEnum.TEXT}
            additionalProps={{
              placeholder: "radius in miles",
            }}
          />
        </SimpleGrid>
        <HStack maxW="5xl" width={"100%"} spacing={4} justify={"flex-end"}>
          <Button
            variant={"outline"}
            isDisabled={isSubmitting || !isDirty}
            onClick={() => reset()}
          >
            Cancel
          </Button>
          <Button
            colorScheme={"teal"}
            isDisabled={isSubmitting || !isDirty}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </HStack>
      </VStack>
    </form>
  );
});
