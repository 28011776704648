import { AppStore } from "../../../App.store";
import { makeAutoObservable } from "mobx";
import { AccountExportRequestsStore } from "./AccountExportRequestsStore.store";
import { AccountExportApi } from "api/account-export.api";

const exportApi = new AccountExportApi();

export class AccountExportsStore {
  private readonly root: AppStore;
  public accountId: number;
  public autocallerExportRequestsStore: AccountExportRequestsStore;
  public leadsExportRequestsStore: AccountExportRequestsStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;

    this.autocallerExportRequestsStore = new AccountExportRequestsStore(
      root,
      accountId,
      {
        fetchExportRequests: exportApi.fetchAutocallerExportRequests.bind(
          exportApi
        ),
        createExportRequest: exportApi.createAutocallerExportRequest.bind(
          exportApi
        ),
      }
    );
    this.leadsExportRequestsStore = new AccountExportRequestsStore(
      root,
      accountId,
      {
        fetchExportRequests: exportApi.fetchLeadsExportRequests.bind(exportApi),
        createExportRequest: exportApi.createLeadsExportRequest.bind(exportApi),
      }
    );
  }
}
