import { Box } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { TableV2 } from "components";
import { preQualificationQuestionnaireTableColumnDef } from "./PreQualificationQuestionnaireTableColumnDef";
import { TTypeformForm } from "types/account-typeform-forms.type";

interface IProps {
  accountStore: UserAccountStore;
}

export const PreQualificationQuestionnaires: FC<IProps> = observer(
  ({ accountStore }) => {
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );

    const typeformStore = accountStore.accountTypeFormFormStore;
    const accountTypeformForms = typeformStore.typeformForms;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await typeformStore.fetchAccountTypeformForms();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [typeformStore, setLoadingStatus]);

    return (
      <Box>
        <TableV2<TTypeformForm>
          dataSource={accountTypeformForms}
          columns={preQualificationQuestionnaireTableColumnDef()}
          pageSize={5}
          loadingStatus={loadingStatus}
          striped={true}
          globalFilterInputPlaceholder={"Search by name"}
        />
      </Box>
    );
  }
);
