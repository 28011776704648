import {
  BoxProps,
  InputGroup,
  InputLeftElement,
  useTheme,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { DelayedInput } from "./DelayedInput";
import React, { ChangeEvent, FC } from "react";

interface ISearchDelayedInputProps {
  value?: string;
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  width?: string;
  inputStyle?: BoxProps;
  delay?: number;
}

export const SearchDelayedInput: FC<ISearchDelayedInputProps> = ({
  value,
  handleSearch,
  placeholder,
  width,
  inputStyle,
  delay,
}) => {
  const theme = useTheme();

  return (
    <InputGroup width={width}>
      <InputLeftElement
        height={"100%"}
        pointerEvents="none"
        children={
          <FontAwesomeIcon
            fontSize={16}
            icon={faMagnifyingGlass}
            color={theme.colors.gray[400]}
          />
        }
      />
      <DelayedInput
        delay={delay}
        placeholder={placeholder}
        inputValue={value}
        onChange={handleSearch}
        size={"sm"}
        rounded={"md"}
        bg={"gray.50"}
        borderColor={"gray.100"}
        pl={10}
        {...inputStyle}
      />
    </InputGroup>
  );
};
