import { observer } from "mobx-react";
import React, { FC, useCallback, useState } from "react";
import { Box, Button, Divider, useToast, VStack } from "@chakra-ui/react";
import { ApiMessageStack, FormControlV2, WizardFooter } from "components";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { twoFactorAuthCodeValidationSchema } from "utils/validation-schemas/two-factor-auth-code.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  verificationCode: string;
};

const initialValues: TFieldValues = {
  verificationCode: "",
};

interface IProps {
  closePrompt: () => void;
}

export const TwoFactorAuthSetupVerificationStep: FC<IProps> = observer(
  ({ closePrompt }) => {
    const [isRequestingCode, setIsRequestingCode] = useState(false);
    const toast = useToast();
    const {
      authStore: {
        leadProAuthStore: { enable2FA, request2FACode },
      },
    } = useAppStore();

    const handRequest2FACode = useCallback(async () => {
      try {
        setIsRequestingCode(true);
        await request2FACode();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description:
            "The phone number you provided should receive an SMS shortly.",
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsRequestingCode(false);
      }
    }, [setIsRequestingCode, request2FACode, toast]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(twoFactorAuthCodeValidationSchema),
    });

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        try {
          await enable2FA(values.verificationCode);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={
                  "Next time you log in, you will be asked to complete two-factor authentication."
                }
              />
            ),
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [toast, enable2FA, closePrompt]
    );

    return (
      <VStack
        spacing={4}
        align={"stretch"}
        width={"100%"}
        position={"relative"}
        divider={<Divider />}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={2} align={"stretch"}>
            <FormControlV2<TFieldValues>
              name={"verificationCode"}
              control={control}
              label={"Phone number verification code"}
              description={`Please click "Request code" button, then enter the code you receive via SMS on mobile phone number you entered in previous step.`}
              type={FormControlsTypeEnum.TEXT}
              additionalProps={{
                placeholder: "ex. 075054",
              }}
            />
            <Box width={"100%"} textAlign={"right"}>
              <Button
                onClick={handRequest2FACode}
                variant={"outline"}
                colorScheme={"blue"}
                isLoading={isRequestingCode}
                isDisabled={isRequestingCode}
              >
                Request code
              </Button>
            </Box>
          </VStack>
        </form>
        <WizardFooter
          onNext={handleSubmit(onSubmit)}
          nextLabel={"Verify"}
          isProcessing={isSubmitting}
        />
      </VStack>
    );
  }
);
