import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { groupBy, sortBy } from "lodash";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { LeadTypeTag } from "components/tags";
import { LeadStageLabel } from "constants/lead-stage-label";
import {
  TLeadsByOffice,
  TLeadsByOfficeStats,
} from "types/account-analytics.type";

const HEADER_SMALL_FONT_SIZE = "12px";
const ROW_Y_PADDING = 3;
const FONT_WEIGHT = 600;
const BORDER_STYLE = "1px solid";
const BORDER_COLOR = "gray.200";

interface IProps {
  officeLeadsData: TLeadsByOffice;
}

type TableData = {
  type: LeadTypeEnum;
  [LeadStageEnum.UNREAD]?: TLeadsByOfficeStats[];
  [LeadStageEnum.IN_PROGRESS]?: TLeadsByOfficeStats[];
  [LeadStageEnum.BUSINESS]?: TLeadsByOfficeStats[];
  [LeadStageEnum.NO_BUSINESS]?: TLeadsByOfficeStats[];
  [LeadStageEnum.DUPLICATE]?: TLeadsByOfficeStats[];
};

export const OfficeLeagueStatsTable: React.FC<IProps> = ({
  officeLeadsData,
}) => {
  const tableData: TableData[] = useMemo(() => {
    const statsGroupedByType = officeLeadsData
      ? groupBy(officeLeadsData.stats, stat => stat.type)
      : {};
    const allTypes = Object.keys(statsGroupedByType) as LeadTypeEnum[];
    const tableData = Object.values(statsGroupedByType).map(
      (statsGroupedByTypeArray, index) => {
        return {
          type: allTypes[index],
          ...groupBy(statsGroupedByTypeArray, element => element.stage),
        };
      }
    );
    return sortBy(tableData, data => data.type);
  }, [officeLeadsData]);

  const readCountByStage = (dataPoint: TableData, stage: LeadStageEnum) => {
    return dataPoint[stage]?.[0].count || 0;
  };

  const addAllStageCounts = (dataPoint: TableData) => {
    return (
      readCountByStage(dataPoint, LeadStageEnum.UNREAD) +
      readCountByStage(dataPoint, LeadStageEnum.IN_PROGRESS) +
      readCountByStage(dataPoint, LeadStageEnum.BUSINESS) +
      readCountByStage(dataPoint, LeadStageEnum.NO_BUSINESS)
    );
  };

  return (
    <Box as={"table"} textAlign={"center"} width={"100%"}>
      <Box as={"thead"} borderTop={BORDER_STYLE} borderColor={BORDER_COLOR}>
        <Box
          as={"tr"}
          borderBottom={BORDER_STYLE}
          borderColor={BORDER_COLOR}
          fontWeight={FONT_WEIGHT}
        >
          <Box as={"th"} textAlign={"left"} py={ROW_Y_PADDING}>
            Leads
          </Box>
          <Box as={"th"} fontSize={HEADER_SMALL_FONT_SIZE}>
            All
          </Box>
          <Box as={"th"} fontSize={HEADER_SMALL_FONT_SIZE}>
            {LeadStageLabel[LeadStageEnum.UNREAD]}
          </Box>
          <Box as={"th"} fontSize={HEADER_SMALL_FONT_SIZE}>
            {LeadStageLabel[LeadStageEnum.IN_PROGRESS]}
          </Box>
          <Box as={"th"} fontSize={HEADER_SMALL_FONT_SIZE}>
            {LeadStageLabel[LeadStageEnum.BUSINESS]}
          </Box>
          <Box as={"th"} fontSize={HEADER_SMALL_FONT_SIZE}>
            {LeadStageLabel[LeadStageEnum.NO_BUSINESS]}
          </Box>
        </Box>
      </Box>
      <Box as={"tbody"}>
        {tableData.map((dataPoint, index) => (
          <Box
            key={index}
            as={"tr"}
            borderBottom={BORDER_STYLE}
            borderColor={BORDER_COLOR}
          >
            <Box as={"td"} textAlign={"left"} py={ROW_Y_PADDING}>
              <LeadTypeTag type={dataPoint.type} />
            </Box>
            <Box as={"td"} fontWeight={FONT_WEIGHT}>
              {addAllStageCounts(dataPoint)}
            </Box>
            <Box as={"td"} fontWeight={FONT_WEIGHT}>
              {readCountByStage(dataPoint, LeadStageEnum.UNREAD)}
            </Box>
            <Box as={"td"} fontWeight={FONT_WEIGHT}>
              {readCountByStage(dataPoint, LeadStageEnum.IN_PROGRESS)}
            </Box>
            <Box as={"td"} fontWeight={FONT_WEIGHT}>
              {readCountByStage(dataPoint, LeadStageEnum.BUSINESS)}
            </Box>
            <Box as={"td"} fontWeight={FONT_WEIGHT}>
              {readCountByStage(dataPoint, LeadStageEnum.NO_BUSINESS)}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
