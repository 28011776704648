import { Box, Flex } from "@chakra-ui/react";
import { FormControlV2 } from "components";
import { ReapitAreasOptionsRenderer } from "components/select-input/custom-option-renderers/ReapitAreasOptionsRenderer";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { FC, useMemo } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import { TSelectOption } from "types/select-input.type";
import { createPriceOptionsArray } from "utils/reapit.utils";
import { TReapitSyncFormFieldValues as TFieldValues } from "../reapit-sync-form-field.type";

interface IProps {
  control: Control<TFieldValues, any>;
  reapitAccountAreasOptions: TSelectOption<string>[];
  areApplicantRequirementsFieldsMandatory: boolean;
  type: LeadTypeEnum;
  priceArray: number[];
  watch: UseFormWatch<TFieldValues>;
}

export const ApplicantRequirementsForm: FC<IProps> = ({
  control,
  reapitAccountAreasOptions,
  areApplicantRequirementsFieldsMandatory,
  type,
  priceArray,
  watch,
}) => {
  const filterAccountAreas = (
    options: TSelectOption<string>[],
    query: string
  ) => {
    return options.filter(option =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  };

  const [minPrice, maxPrice] = watch(["minPrice", "maxPrice"]);

  const minPriceOptions = useMemo(() => {
    if (!maxPrice) {
      return createPriceOptionsArray(type, priceArray);
    }
    const maxPriceIndex = priceArray.indexOf(maxPrice);
    return createPriceOptionsArray(type, priceArray.slice(0, maxPriceIndex));
  }, [priceArray, maxPrice, type]);

  const maxPriceOptions = useMemo(() => {
    if (!minPrice) {
      return createPriceOptionsArray(type, priceArray);
    }
    const minPriceIndex = priceArray.indexOf(minPrice);
    return createPriceOptionsArray(type, priceArray.slice(minPriceIndex + 1));
  }, [priceArray, minPrice, type]);

  return (
    <>
      <Box
        width={"full"}
        py={2}
        px={5}
        mt={2}
        mb={4}
        background={"leadpro.50"}
        fontSize={"sm"}
      >
        Applicant Requirements
      </Box>
      <Box mb={5} px={4}>
        <FormControlV2<TFieldValues>
          name={"locationOptions"}
          control={control}
          label={"Applicant Area"}
          type={FormControlsTypeEnum.MULTI_SELECT}
          isOptional={
            !areApplicantRequirementsFieldsMandatory ||
            reapitAccountAreasOptions.length === 0
          }
          additionalProps={{
            placeholder:
              reapitAccountAreasOptions.length === 0
                ? "No areas found for this lead's office"
                : "Select one or more areas",
            clearable: true,
            options: reapitAccountAreasOptions,
            filterFn: filterAccountAreas,
            optionsRenderer: (optionProps: IGetOptionPropsData<string>[]) => (
              <ReapitAreasOptionsRenderer optionProps={optionProps} />
            ),
          }}
        />
      </Box>
      <Flex px={4} width={"full"} justifyContent={"space-between"} gap={8}>
        <Box flexGrow={1} maxWidth={"215px"}>
          <FormControlV2<TFieldValues>
            name={"minPrice"}
            type={FormControlsTypeEnum.SINGLE_SELECT}
            label={"Min Price"}
            control={control}
            additionalProps={{
              options: minPriceOptions,
            }}
          />
        </Box>
        <Box flexGrow={1} maxWidth={"215px"}>
          <FormControlV2<TFieldValues>
            name={"maxPrice"}
            type={FormControlsTypeEnum.SINGLE_SELECT}
            label={"Max Price"}
            control={control}
            additionalProps={{
              options: maxPriceOptions,
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
