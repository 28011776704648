import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC, useRef } from "react";

interface IProps {
  onOpen?: () => void;
  triggerElement: React.ReactNode;
  headerElement: React.ReactNode;
  bodyElement: React.ReactNode;
  handleApply: (onClose: () => void) => () => void;
  applyLabel?: string;
  applyBtnIsDisabled?: boolean;
  popoverProps?: PopoverProps;
}

export const TriggerablePopoverBlock: FC<IProps> = ({
  onOpen,
  triggerElement,
  headerElement,
  bodyElement,
  handleApply,
  applyLabel = "Apply",
  applyBtnIsDisabled,
  popoverProps,
}) => {
  const inputRef = useRef(null);

  return (
    <Popover
      closeOnBlur={false}
      initialFocusRef={inputRef}
      isLazy={true}
      placement={"top"}
      onOpen={onOpen}
      {...popoverProps}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>{triggerElement}</PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader fontSize={"md"} fontWeight={500}>
              {headerElement}
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>{bodyElement}</PopoverBody>
            <PopoverFooter>
              <ButtonGroup
                size="sm"
                spacing={2}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button
                  colorScheme={"blue"}
                  onClick={handleApply(onClose)}
                  isDisabled={applyBtnIsDisabled}
                >
                  {applyLabel}
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
