import { LeadInfoRowLabel } from "./LeadInfoRowLabel";
import { LeadInfoSmartRowValue } from "./LeadInfoSmartRowValue";
import { LeadInfoRow } from "./LeadInfoRow";
import { FC } from "react";

interface IProps {
  value?: string;
  metaKey: string;
}

export const LeadInfoBlockRow: FC<IProps> = ({ value, metaKey }) => {
  return (
    <LeadInfoRow key={metaKey}>
      <LeadInfoRowLabel>{metaKey}</LeadInfoRowLabel>
      <LeadInfoSmartRowValue metaKey={metaKey} value={value} />
    </LeadInfoRow>
  );
};
