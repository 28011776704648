import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import { ApiMessageStack, SelectInputOptionsController } from "components";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { TUserData } from "types/user-data.type";
import { observer } from "mobx-react";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { UserSelectOption } from "components";
import useSelect from "utils/react-hooks/useSelect.hook";

interface IProps {
  leadsStore: AccountLeadsStore;
  selectedRowIds: number[];
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const BulkUpdateAssignedTo: FC<IProps> = observer(
  ({ leadsStore, selectedRowIds, setIsSubmitting }) => {
    const toast = useToast();
    const [assignedTo, setAssignedTo] = useState<TUserData | null>(null);
    const assignedToRef = useRef<TUserData | null>(null);

    const handleChange = useCallback((newAssignedTo: TUserData) => {
      setAssignedTo(newAssignedTo);
      assignedToRef.current = newAssignedTo;
    }, []);

    useEffect(() => {
      leadsStore.fetchAssignableUsersForMultipleLeads(selectedRowIds);

      const cleanup = async () => {
        if (!!assignedToRef.current) {
          try {
            setIsSubmitting(true);
            await leadsStore.updateLeads(selectedRowIds, {
              assignedToId: assignedToRef.current?.id || undefined,
            });
          } catch (error) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={error.message} />,
            });
          } finally {
            setIsSubmitting(false);
          }
        }
      };

      return () => {
        cleanup();
      };
    }, [leadsStore, selectedRowIds, toast, setIsSubmitting]);

    const { visibleOptions, getOptionProps, selectedOption } = useSelect({
      multi: false,
      options: leadsStore.assignableUsersForMultipleLeadsOptions,
      value: assignedTo,
      onChange: handleChange,
    });

    return (
      <Box height={"320px"} cursor={"pointer"}>
        <SelectInputOptionsController<TUserData>
          selectedOption={selectedOption}
          visibleOptions={visibleOptions}
          getOptionProps={getOptionProps}
          optionComponent={optionProps => (
            <UserSelectOption optionProps={optionProps} />
          )}
        />
      </Box>
    );
  }
);
