import React from "react";
import { WizardContext } from "components";

export const useWizard = () => {
  const context = React.useContext(WizardContext);
  if (context === undefined) {
    throw new Error("useWizard must be used within a Wizard");
  }

  return context;
};
