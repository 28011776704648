import { TProperty, TPropertyDetails } from "types/property.type";
import { makeAutoObservable, toJS } from "mobx";
import { PropertiesApi } from "api/properties.api";
import { AppStore } from "store/App.store";
import { AccountLeadsStore } from "../AccountLeads/AccountLeads.store";

const propertiesApi = new PropertiesApi();

export class AccountPropertyStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private propertyData: TProperty;
  public propertyLeadsStore: AccountLeadsStore;

  constructor(root: AppStore, accountId: number, propertyData: TProperty) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.propertyData = propertyData;
    this.propertyLeadsStore = new AccountLeadsStore(
      this.root,
      this.accountId,
      true,
      true
    );
    this.propertyLeadsStore.leadsFilterStore.togglePendingFilterProperty(
      propertyData.id
    );
    this.propertyLeadsStore.leadsFilterStore.setActiveFromPendingFilter();
  }
  get property(): TProperty {
    return toJS(this.propertyData);
  }

  public async fetchDetails() {
    const propertyData = await propertiesApi.getPropertyWithDetails(
      this.accountId,
      this.propertyData.id
    );

    this.upsertPropertyData(propertyData);
  }

  private upsertPropertyData(propertyDetails: TPropertyDetails) {
    this.propertyData = {
      ...this.propertyData,
      ...propertyDetails,
    };
  }
}
