import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "../../App.store";
import { LeadResponderApi } from "../../../api/lead-responder.api";
import { TTypeformForm } from "types/account-typeform-forms.type";

const leadResponderApi = new LeadResponderApi();

export class AccountTypeformFormStore {
  private root: AppStore;
  private accountId: number;
  private accountTypeformForms: IObservableArray<TTypeformForm>;
  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountTypeformForms = observable.array<TTypeformForm>();
  }

  get typeformForms() {
    return this.accountTypeformForms.slice();
  }

  setTypeformForms(data: TTypeformForm[]) {
    this.accountTypeformForms.replace(data);
  }

  async fetchAccountTypeformForms() {
    const data = await leadResponderApi.fetchAccountTypeformForms(
      this.accountId
    );
    this.setTypeformForms(data);
  }
}
