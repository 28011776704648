import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { InternalLink } from "components";
import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import {
  SETTINGS_DEVELOPERS_API_KEYS_ROUTE,
  SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE,
  SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE,
  SETTINGS_OFFICES_ROUTE,
} from "constants/routes";

const urlPieceToLabel = (piece?: string) => piece?.replace(/-|_/g, " ");

interface IProps {
  accountStore: UserAccountStore;
  title?: React.ReactNode;
}

export const PageHeaderBreadcrumbs: FC<IProps> = observer(
  ({ accountStore, title }) => {
    const {
      accountOfficesStore: { accountOfficesDataMap },
      accountDevelopersStore: {
        accountDevelopersWebhooksStore: { webhookDataMap },
        accountDevelopersApiKeysStore: { apiKeyConfigDataMap },
      },
    } = accountStore;
    const location = useLocation();

    const pieces = useMemo(() => {
      return location.pathname.split("/").filter(piece => !!piece);
    }, [location]);

    const pieceURLs = useMemo(() => {
      return pieces.map(
        (piece, index) => `/${pieces.slice(0, index + 1).join("/")}`
      );
    }, [pieces]);

    const pieceLabels = useMemo(() => {
      if (pieceURLs.includes(SETTINGS_OFFICES_ROUTE)) {
        return [
          urlPieceToLabel(pieces[0]),
          urlPieceToLabel(pieces[1]),
          accountOfficesDataMap[Number.parseInt(pieces[2])]?.name ||
            urlPieceToLabel(pieces[2]),
        ];
      }

      if (pieceURLs.includes(SETTINGS_DEVELOPERS_WEBHOOKS_ROUTE)) {
        return [
          urlPieceToLabel(pieces[0]),
          urlPieceToLabel(pieces[1]),
          urlPieceToLabel(pieces[2]),
          webhookDataMap[Number.parseInt(pieces[3])]?.name ||
            urlPieceToLabel(pieces[3]),
        ];
      }

      if (pieceURLs.includes(SETTINGS_DEVELOPERS_INTERNAL_WEBHOOKS_ROUTE)) {
        return [
          urlPieceToLabel(pieces[0]),
          urlPieceToLabel(pieces[1]),
          urlPieceToLabel(pieces[2]),
          webhookDataMap[Number.parseInt(pieces[3])]?.name ||
            urlPieceToLabel(pieces[3]),
        ];
      }

      if (pieceURLs.includes(SETTINGS_DEVELOPERS_API_KEYS_ROUTE)) {
        return [
          urlPieceToLabel(pieces[0]),
          urlPieceToLabel(pieces[1]),
          urlPieceToLabel(pieces[2]),
          apiKeyConfigDataMap[Number.parseInt(pieces[3])]?.name ||
            urlPieceToLabel(pieces[3]),
        ];
      }

      return pieces.map(piece => urlPieceToLabel(piece));
    }, [
      pieceURLs,
      pieces,
      accountOfficesDataMap,
      webhookDataMap,
      apiKeyConfigDataMap,
    ]);

    if (!!title) return <>{title}</>;

    return (
      <Breadcrumb
        fontWeight={"medium"}
        color={"gray.600"}
        separator={<FontAwesomeIcon icon={faChevronRight} />}
      >
        {pieces.map((piece, index) => {
          return (
            <BreadcrumbItem key={piece}>
              <InternalLink
                exact={true}
                href={pieceURLs[index]}
                color={"gray.900"}
                textTransform={"capitalize"}
              >
                {pieceLabels[index]}
              </InternalLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  }
);
