import { AccountJournysApi } from "api/account-journeys.api";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TAccountJourney,
  TAccountJourneyCreateData,
} from "types/account-journeys.type";

const accountJourneysApi = new AccountJournysApi();

export class AccountJourneysStore {
  private readonly accountId: number;
  private accountJourneys: IObservableArray<TAccountJourney>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.accountJourneys = observable.array<TAccountJourney>();
  }

  get accountJourneysArray() {
    return this.accountJourneys.slice();
  }

  private setAccountJourneys(accountJourneys: TAccountJourney[]) {
    this.accountJourneys.replace(accountJourneys);
  }

  private upsertAccountJourneys(journeyData: TAccountJourney) {
    const index = this.accountJourneys.findIndex(
      journey => journey.id === journeyData.id
    );

    if (index > -1) {
      this.accountJourneys[index] = {
        ...this.accountJourneys[index],
        ...journeyData,
      };
    } else {
      this.accountJourneys.push(journeyData);
    }
  }

  async fetchAllAccountJourneys() {
    const response = await accountJourneysApi.fetchAllAccountJourneys(
      this.accountId
    );
    this.setAccountJourneys(response);
  }

  async fetchAccountJourney(journeyId: number) {
    const response = await accountJourneysApi.fetchAccountJourney(
      this.accountId,
      journeyId
    );
    this.upsertAccountJourneys(response);
  }

  async createAccountJourney(journeyCreateData: TAccountJourneyCreateData) {
    const response = await accountJourneysApi.createAccountJourney(
      this.accountId,
      journeyCreateData
    );
    this.upsertAccountJourneys(response);
  }

  async updateAccountJourney(
    journeyId: number,
    journeyUpdateData: TAccountJourneyCreateData
  ) {
    const response = await accountJourneysApi.updateAccountJourney(
      this.accountId,
      journeyId,
      journeyUpdateData
    );

    this.upsertAccountJourneys(response);
  }

  async updateAccountJourneysOrder(ids: number[]) {
    await accountJourneysApi.updateAccountJourneyOrder(this.accountId, ids);
    await this.fetchAllAccountJourneys();
  }

  async deleteAccountJourney(journeyId: number) {
    await accountJourneysApi.deleteAccountJourney(this.accountId, journeyId);
    await this.fetchAllAccountJourneys();
  }
}
