import { Box } from "@chakra-ui/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "components";

interface IProps {
  title: React.ReactNode | string;
  tooltip: any | string;
}

export const ScoreHeader: React.FC<IProps> = ({ title, tooltip }) => {
  return (
    <Box
      display={"flex"}
      height={"100%"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Box mr={2}>{title}</Box>
      <Tooltip aria-label={`${title}-tooltip`} label={tooltip}>
        <Box opacity={0.6}>
          <FontAwesomeIcon icon={faInfoCircle} fontSize={14} />
        </Box>
      </Tooltip>
    </Box>
  );
};
