import { BaseApi } from "./base.api";
import {
  TIntegrationOffice,
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "types/integration-sync-office.type";

export class AccountStreetApi extends BaseApi {
  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    return await this.get(`/accounts/${accountId}/street/branch-relations`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/street/branch-relations`, {
      items: relationsData,
    });
  }

  async fetchAccountOffices(accountId: number): Promise<TIntegrationOffice[]> {
    return await this.get(`/accounts/${accountId}/street/street-branches`);
  }
}
