import { Box, VStack } from "@chakra-ui/react";
import { DATE_TIME_SLASH_FORMAT } from "constants/date";
import moment from "moment";
import React, { FC, useMemo } from "react";
import { formatDate } from "utils/date.utils";
import { TLeadResponderLeadEmailTableData } from "types/account-lead-emails.type";

interface ILeadEmailDateCellProps {
  email: TLeadResponderLeadEmailTableData;
}

export const LeadEmailDateCell: FC<ILeadEmailDateCellProps> = ({ email }) => {
  const displayDate = useMemo(() => {
    const leadCreatedAtMoment = moment(email.createdAt);
    return leadCreatedAtMoment.fromNow();
  }, [email.createdAt]);

  const calanderDate = useMemo(() => {
    return formatDate(email.createdAt, DATE_TIME_SLASH_FORMAT);
  }, [email.createdAt]);

  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"} p={2}>
      <VStack spacing={0} alignItems={"center"}>
        <Box fontSize={"sm"}>{calanderDate}</Box>
        <Box fontSize={"xs"} color={"leadpro.400"}>
          {displayDate}
        </Box>
      </VStack>
    </Box>
  );
};
