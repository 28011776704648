import React, { FC, useCallback, useMemo } from "react";
import { TSerializedDateRange } from "types/date.type";
import moment from "moment";
import ReactCalendar from "react-calendar";
import { Center } from "@chakra-ui/react";
import "./Calendar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-solid-svg-icons";

interface IDateRangePickerControllerProps extends TSerializedDateRange {
  onChange: (arg: TSerializedDateRange) => void;
  maxDate?: string;
  minDate?: string;
}

export const DateRangePickerController: FC<IDateRangePickerControllerProps> = ({
  startDate,
  endDate,
  onChange,
  maxDate,
  minDate,
}) => {
  const startDateInstance = useMemo(
    () => (!!startDate ? new Date(startDate) : null),
    [startDate]
  );
  const endDateInstance = useMemo(
    () => (!!endDate ? new Date(endDate) : null),
    [endDate]
  );

  const handleOnChange = useCallback(
    ([startDate, endDate]: Date[]) => {
      onChange({
        startDate: !!startDate
          ? moment(startDate)
              .startOf("day")
              .toISOString()
          : null,
        endDate: endDate
          ? moment(endDate)
              .endOf("day")
              .toISOString()
          : null,
      });
    },
    [onChange]
  );

  return (
    <ReactCalendar
      value={[startDateInstance, endDateInstance]}
      onChange={handleOnChange}
      locale={"en-GB"}
      showNeighboringMonth={false}
      selectRange={true}
      maxDate={!!maxDate ? new Date(maxDate) : undefined}
      minDate={!!minDate ? new Date(minDate) : undefined}
      prevLabel={
        <Center>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Center>
      }
      prev2Label={
        <Center>
          <FontAwesomeIcon icon={faChevronsLeft} />
        </Center>
      }
      nextLabel={
        <Center>
          <FontAwesomeIcon icon={faChevronRight} />
        </Center>
      }
      next2Label={
        <Center>
          <FontAwesomeIcon icon={faChevronsRight} />
        </Center>
      }
    />
  );
};
