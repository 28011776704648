import React, { ReactNode } from "react";
import { Box, Button, VStack, Center } from "@chakra-ui/react";

interface ErrorProps {
  message?: string;
  action?: ReactNode;
}

export const Error: React.FC<ErrorProps> = ({ message, action }) => {
  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <Center position={"absolute"} top={0} bottom={0} left={0} right={0}>
      <VStack spacing={4}>
        <Box>
          {message ?? "Something went wrong :(. Please reload to try again."}
        </Box>
        {action || (
          <Button colorScheme={"blue"} onClick={handleReloadClick}>
            Reload
          </Button>
        )}
      </VStack>
    </Center>
  );
};
