import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { Dictionary } from "lodash";
import { TPortalData } from "types/portal.type";
import { TProperty } from "types/property.type";
import React, { FC } from "react";
import { HStack } from "@chakra-ui/react";
import { LeadSourceTag } from "../../../tags";

interface IProps {
  property: TProperty;
  accountLogoUrl: string | null;
  leadSourcesMapBySource: Record<string, TNormalizedLeadSourceData>;
  portalsMap: Dictionary<TPortalData>;
}

export const PropertySourcesCell: FC<IProps> = ({
  property,
  accountLogoUrl,
  leadSourcesMapBySource,
  portalsMap,
}) => {
  return (
    <HStack
      py={4}
      height={"92px"}
      width={"100%"}
      display={"flex"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      {property.sources.map(propertySource => (
        <LeadSourceTag
          key={propertySource.source}
          source={propertySource.source}
          leadSourcesMapBySource={leadSourcesMapBySource}
          portalsMap={portalsMap}
          accountLogoUrl={accountLogoUrl}
        />
      ))}
    </HStack>
  );
};
