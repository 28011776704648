import { Box, Text, SimpleGrid, VStack } from "@chakra-ui/react";
import { FormControlMeta, FormControlV2 } from "components/form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import React, { useCallback, useState, useMemo } from "react";
import { TPageData } from "types/page.type";
import { Message } from "components";
import { Control, UseFormWatch } from "react-hook-form";
import { TPageSettingsFieldValues } from "utils/validation-schemas/page-settings.validation";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { PageToolPageSettingsInputLayout } from "./PageToolPageSettingsInputLayout";

type TFieldValues = TPageSettingsFieldValues;

const env = runtimeEnv();

interface IProps<T extends TFieldValues> {
  page?: TPageData;
  control: Control<T>;
  watch: UseFormWatch<T>;
}
export function PageToolPageBasicSettings<T extends TFieldValues>({
  page,
  control,
  watch,
}: IProps<T>) {
  const [isCustomDomain, setIsCustomDomain] = useState(false);
  const onCustomDomainToggle = useCallback(
    (value: boolean) => {
      setIsCustomDomain(value);
    },
    [setIsCustomDomain]
  );

  const watchHost: TFieldValues["host"] = watch("host" as any);
  const [prefix, ...suffix] = useMemo(() => {
    if (!!watchHost) {
      return watchHost.split(".");
    }

    return [];
  }, [watchHost]);

  return (
    <>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Name"
          description={
            "This is your own internal reference for the page you're creating. You can call it anything you like."
          }
        />
        <FormControlV2<TFieldValues>
          name={"name"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Name",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Photo"
          description={
            "This is the photo used as a background image on the results page. We recommend uploading an image that’s at least 1200px wide."
          }
        />
        <FormControlV2<TFieldValues>
          name={"photoFile"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.PICTURE_UPLOAD_INPUT}
          additionalProps={{
            src: page?.photoUrl || null,
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Custom logo"
          description={
            "By default, page will show your account logo. If you wish to display a different logo, set it here."
          }
        />
        <FormControlV2<TFieldValues>
          name={"customLogoFile"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.PICTURE_UPLOAD_INPUT}
          additionalProps={{
            src: page?.customLogoUrl || null,
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Primary color"
          description={
            "Primary color used as an accent color throughout the valuation tool interface."
          }
        />
        <FormControlV2<TFieldValues>
          name={"color"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.COLOR_INPUT}
          additionalProps={{
            placeholder: "#000000",
          }}
        />
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="URL"
          description={
            "This is the URL where your valuation tool can be accessed. We can host it for you, or you can specify a custom domain."
          }
        />
        <Box>
          <FormControlV2<TFieldValues>
            name={"host"}
            control={control as Control<T | TFieldValues>}
            type={FormControlsTypeEnum.HOST_INPUT}
            additionalProps={{
              placeholder: "URL",
              onCustomDomainToggle,
            }}
          />
          {isCustomDomain && (
            <Box mt={2}>
              <Message>
                <VStack spacing={4} align={"flex-start"}>
                  <Box fontWeight={500} fontSize={"base"}>
                    Set the following record on your DNS provider to continue
                  </Box>
                  {!!prefix && suffix?.length > 1 && (
                    <Text fontSize={"sm"}>
                      Please note that some domain control panels will
                      automatically add the <b>{suffix.join(".")}</b> suffix to
                      the name, in which case the name should simply be set to{" "}
                      <b>{prefix}</b>.
                    </Text>
                  )}
                  <SimpleGrid
                    columns={3}
                    spacing={0}
                    textAlign={"center"}
                    fontSize={"sm"}
                    width={"100%"}
                  >
                    <Box fontWeight={500}>Type</Box>
                    <Box fontWeight={500}>Name</Box>
                    <Box fontWeight={500}>Value</Box>
                    <Box>CNAME</Box>
                    <Box>{watchHost}</Box>
                    <Box>{`pages.${env.REACT_APP_IVT_DOMAIN}`}</Box>
                  </SimpleGrid>
                  <Text fontSize={"sm"}>
                    Depending on your provider, it might take some time for the
                    changes to apply. Find out more here (link to guides
                    section).
                  </Text>
                </VStack>
              </Message>
            </Box>
          )}
        </Box>
      </PageToolPageSettingsInputLayout>
      <PageToolPageSettingsInputLayout>
        <FormControlMeta
          label="Privacy policy link"
          description={
            "Include a link to your Privacy policy. This will appear at the bottom of the form."
          }
        />
        <FormControlV2<TFieldValues>
          name={"privacyPolicyLink"}
          control={control as Control<T | TFieldValues>}
          type={FormControlsTypeEnum.TEXT}
          additionalProps={{
            placeholder: "Privacy policy link",
          }}
        />
      </PageToolPageSettingsInputLayout>
    </>
  );
}
