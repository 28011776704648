import { useMemo } from "react";

export const useLocalStore = <T, A>(
  StoreConstructor: new (storeParams: A) => T,
  storeParams: A
) => {
  return useMemo(
    () => new StoreConstructor(storeParams),
    // eslint-disable-next-line
    [StoreConstructor, ...Object.values(storeParams)]
  );
};
