import React from "react";
import { FormControlDescription } from "./FormControl/FormControlDescription";
import { Text } from "@chakra-ui/react";

interface Props {
  length: number;
  name: string;
}

export const FormInputValueCounter: React.FC<Props> = ({ length, name }) => {
  return (
    <FormControlDescription>
      Currently {""}
      <Text as={"b"}>{`${length}`}</Text>{" "}
      {`${length !== 1 ? `${name}s` : name} in the list`}
    </FormControlDescription>
  );
};
