import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { IAccountUserDataWithDetails } from "types/account-user.type";
import { keyBy } from "lodash";
import { TUserInviteOrUpdateData } from "types/user-invitation.type";
import { UserRoleEnum } from "enums/user-role.enum";
import { AppStore } from "store/App.store";
import { TAccountSubentityIntegrationsConfig } from "types/account-integration.type";
import { AccountUsersApi } from "api/account-users.api";
import { getUserFullName } from "utils/account-user.utils";
import { TUserBasicData } from "types/user-data.type";
import { UserInvitationStatusEnum } from "enums/user-invitation-status.enum";
import moment from "moment";
import { EcosystemAuthStore } from "store/EcosystemAuth.store";

const usersApi = new AccountUsersApi();

export type TAccountUsersTableData = {
  id: IAccountUserDataWithDetails["id"];
  email: IAccountUserDataWithDetails["email"];
  roleId: IAccountUserDataWithDetails["userAccount"]["roleId"];
  sms2FAEnabled: IAccountUserDataWithDetails["sms2FAEnabled"];
  isEcosystemUser: IAccountUserDataWithDetails["isEcosystemUser"];
  userInvitationStatus: UserInvitationStatusEnum;
  fullName?: string;
  user: TUserBasicData;
};

export class AccountUsersStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly accountUsers: IObservableArray<IAccountUserDataWithDetails>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.accountUsers = observable.array<IAccountUserDataWithDetails>();
  }

  get accountUsersArray() {
    return this.accountUsers.slice();
  }

  get accountUsersOptions() {
    return this.accountUsersArray.map(accountUser => {
      const { firstName, lastName, email } = accountUser;
      return {
        value: accountUser,
        label: getUserFullName(firstName, lastName, email),
      };
    });
  }

  get accountUsersTableData(): TAccountUsersTableData[] {
    return this.accountUsersArray.map(accountUser => ({
      id: accountUser.id,
      email: accountUser.email,
      roleId: accountUser.userAccount.roleId,
      sms2FAEnabled: accountUser.sms2FAEnabled,
      fullName: getUserFullName(accountUser.firstName, accountUser.lastName),
      isEcosystemUser: accountUser.isEcosystemUser,
      userInvitationStatus: !accountUser.ecosystemInviteExpiration
        ? UserInvitationStatusEnum.ACTIVE
        : moment(accountUser.ecosystemInviteExpiration).isBefore()
        ? UserInvitationStatusEnum.EXPIRED
        : UserInvitationStatusEnum.PENDING,
      user: accountUser,
    }));
  }

  get accountUsersMap() {
    return keyBy(this.accountUsersArray, accountUser => accountUser.id);
  }

  public setAccountUsers(accountUsers: IAccountUserDataWithDetails[]) {
    this.accountUsers.replace(accountUsers);
  }

  public deleteAccountUser(userId: number) {
    const index = this.accountUsers.findIndex(
      accountUser => accountUser.id === userId
    );
    if (index > -1) {
      this.accountUsers.splice(index, 1);
    }
  }

  public upsertAccountUser(accountUserData: IAccountUserDataWithDetails) {
    const index = this.accountUsers.findIndex(
      accountUser => accountUser.id === accountUserData.id
    );
    if (index > -1) {
      this.accountUsers[index] = accountUserData;
    } else {
      this.accountUsers.push(accountUserData);
    }
  }

  public async fetchAccountUsers() {
    const users = await usersApi.fetchAccountUsers(this.accountId);

    this.setAccountUsers(users);
  }

  public async fetchAccountUser(userId: number) {
    const user = await usersApi.fetchAccountUser(this.accountId, userId);

    this.upsertAccountUser(user);
  }

  public async updateAccountUser(
    userId: number,
    data: Partial<TUserInviteOrUpdateData>,
    integrations: TAccountSubentityIntegrationsConfig = {}
  ) {
    if (data.roleId === UserRoleEnum.ADMIN) {
      data.officeIds = [];
    }

    const updatedUser = await usersApi.updateAccountUser(
      this.accountId,
      userId,
      data
    );
    this.upsertAccountUser(updatedUser);

    // reapit integration
    await this.trySyncAccountUserWithReapit(userId, integrations.reapitId);
  }

  public async inviteUserToAccount(
    data: TUserInviteOrUpdateData,
    integrations: TAccountSubentityIntegrationsConfig = {}
  ) {
    const newUser = await usersApi.inviteUserToAccount(this.accountId, data);
    this.upsertAccountUser(newUser);
    // TODO: FETCH ONLY BILLING ONCE WE HAVE ENDPOINT FOR THAT
    const userAccountsStore = this.root.userAccountsStore;
    await userAccountsStore.userAccountsMap[this.accountId]?.fetchDetails();
    // reapit integration
    await this.trySyncAccountUserWithReapit(newUser.id, integrations.reapitId);
  }

  public async resendUserInvite(userId: number) {
    if (!EcosystemAuthStore.isEcosystemSupported()) return;
    const user = await usersApi.resendInvite(userId, this.accountId);
    this.upsertAccountUser(user);
  }

  public async removeUserFromAccount(userId: number) {
    await usersApi.removeUserFromAccount(this.accountId, userId);
    this.deleteAccountUser(userId);
  }

  public async trySyncAccountUserWithReapit(
    userId: number,
    reapitId: TAccountSubentityIntegrationsConfig["reapitId"]
  ) {
    const userAccountsStore = this.root.userAccountsStore;
    const userAccountStore = userAccountsStore.userAccountsMap[this.accountId];
    const hasReapitIntegration =
      userAccountStore.accountIntegrationsStore.hasActiveReapitIntegration;
    if (hasReapitIntegration && reapitId !== undefined) {
      const reapitIntegrationStore =
        userAccountStore.accountIntegrationsStore.accountIntegrationReapitStore;
      const accountUser = this.accountUsersMap[userId];
      await reapitIntegrationStore.accountIntegrationReapitNegotiatorsStore.updateAccountReapitSyncedNegotiators(
        [
          {
            userAccountId: accountUser.userAccount.id,
            reapitId: reapitId,
          },
        ]
      );
    }
  }
}
