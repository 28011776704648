import React, { FC, useCallback, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const REACT_APP_RECAPTCHA_SITEKEY = env.REACT_APP_RECAPTCHA_SITEKEY;

export const ReCaptchaV2Context = React.createContext<
  | {
      reCaptchaV2: ReCAPTCHA | null;
    }
  | undefined
>(undefined);

interface IReCaptchaV2Props {
  children: React.ReactNode;
}

export const ReCaptchaV2: FC<IReCaptchaV2Props> = ({ children }) => {
  const [reCaptchaV2Ref, setReCaptchaV2Ref] = useState<ReCAPTCHA | null>(null);

  const updateRef = useCallback(
    (refElement: ReCAPTCHA) => {
      setReCaptchaV2Ref(refElement);
    },
    [setReCaptchaV2Ref]
  );

  return (
    <>
      <ReCAPTCHA
        sitekey={REACT_APP_RECAPTCHA_SITEKEY}
        size={"invisible"}
        ref={updateRef}
      />
      <ReCaptchaV2Context.Provider value={{ reCaptchaV2: reCaptchaV2Ref }}>
        {children}
      </ReCaptchaV2Context.Provider>
    </>
  );
};
