import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";

export const SmeSyncValidationSchema = (isApplicant: boolean) => {
  return Yup.object().shape({
    address_line_one: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),
    address_line_two: Yup.string(),
    address_line_three: Yup.string(),
    address_line_four: Yup.string(),
    postcode: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),
  });
};
