import { PopupOptions } from "@typeform/embed";

type THiddenOptionsRaw = Record<string, string | number | undefined>;
type THiddenOptions = Record<string, string>;

export const getTypeformOptions = (
  hidden?: THiddenOptionsRaw,
  options?: Omit<PopupOptions, "hidden">
) => {
  if (!hidden) return options;

  const hiddenAsOptions = Object.keys(hidden).reduce(
    (acc: THiddenOptions, key: string) => {
      acc[key] = hidden[key]?.toString() || `no ${key}`;
      return acc;
    },
    {}
  );

  return {
    ...options,
    hidden: hiddenAsOptions,
  };
};
