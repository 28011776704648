import { TAccountBlacklistedEmail } from "types/account-blacklisted-email.type";
import { BaseApi } from "./base.api";

export class AccountBlacklistedEmailsApi extends BaseApi {
  async fetchAllBlacklistedEmails(
    accountId: number
  ): Promise<TAccountBlacklistedEmail[]> {
    return this.get(`accounts/${accountId}/blacklisted-emails`);
  }

  async addBlacklistedEmail(
    accountId: number,
    email: string
  ): Promise<TAccountBlacklistedEmail> {
    return this.post(`accounts/${accountId}/blacklisted-emails`, {
      email,
    });
  }

  async removeBlacklistedEmail(
    accountId: number,
    emailId: number
  ): Promise<void> {
    return this.del(`accounts/${accountId}/blacklisted-emails/${emailId}`);
  }
}
