import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface LegendItem<T extends string> {
  key: T;
  label: string;
  color: string;
  className?: string;
  toggled: boolean;
}

interface LabelProps<T extends string> {
  items: LegendItem<T>[];
  onItemClick: (key: T) => void;
}

export function Legend<T extends string>(props: LabelProps<T>) {
  const { items, onItemClick } = props;
  return (
    <Box
      mb={2}
      _after={{
        content: '""',
        display: "table",
        clear: "both",
      }}
    >
      {items.map(({ key, label, className, color, toggled }) => {
        const baseStyle: React.CSSProperties = {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.1,
        };
        const circleStyle: React.CSSProperties = {
          opacity: 0.2,
        };
        const textStyle: React.CSSProperties = {};
        if (toggled) {
          baseStyle.backgroundColor = color;

          circleStyle.backgroundColor = color;
          circleStyle.opacity = 1;

          textStyle.color = color;
        }

        return (
          <Box
            key={key}
            onClick={() => onItemClick(key)}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            textTransform={"capitalize"}
            cursor={"pointer"}
            p={2}
            mb={2}
            mr={2}
            position={"relative"}
            lineHeight={1}
            float={"left"}
          >
            <Box rounded="md" style={baseStyle} bg={"gray.600"} />
            <Box
              width={"10px"}
              height={"10px"}
              style={circleStyle}
              bg={"gray.600"}
              mr={2}
              rounded={"full"}
            />
            <Text
              style={textStyle}
              fontWeight={500}
              fontSize={"sm"}
              color={"gray.600"}
              whiteSpace={"nowrap"}
            >
              {label}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}
