import { TourBase } from "../TourBase/TourBase";
import React, { FC, useCallback, useMemo } from "react";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { Box } from "@chakra-ui/react";
import { UserRoleEnum } from "enums/user-role.enum";
import { ITourStepDefinition } from "../TourBase/TourStepFooter";
import { dummyLeadDetailsData } from "constants/dummyLeadData";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

const STEP_SWITCH_DELAY = 300;

interface IProps {
  accountStore: UserAccountStore;
}
export const GeneralTour: FC<IProps> = observer(({ accountStore }) => {
  const {
    uiStore: { setDemoMode, setLeftSidebarIsOpen },
  } = useAppStore();
  const {
    accountLeadsStore: { selectLead },
  } = accountStore;

  const onAfterOpen = useCallback(() => {
    setDemoMode(true);
  }, [setDemoMode]);

  const onBeforeClose = useCallback(() => {
    setDemoMode(false);
  }, [setDemoMode]);

  const steps = useMemo(() => {
    const steps: ITourStepDefinition[] = [
      {
        content: (
          <Box width={"500px"}>
            <Box fontSize={"xl"} fontWeight={600} mb={2}>
              Welcome to LeadPro
            </Box>
            <Box>
              LeadPro generates valuation leads for your business, and allows
              you to manage those leads in one place. Let's show you how it
              works.
            </Box>
          </Box>
        ),
      },
      {
        selector: `[tour="${DashboardTourEnum.GENERAL_TOUR}-1"]`,
        content: (
          <Box width={"400px"}>
            Once you’ve connected lead sources, and started generating leads,
            they will show up here. You can also setup email notifications.
          </Box>
        ),
        actionBeforeNextStep: async () => {
          await selectLead(dummyLeadDetailsData.id);
          return new Promise<void>(resolve => {
            setTimeout(resolve, STEP_SWITCH_DELAY);
          });
        },
      },
      {
        selector: `[tour="${DashboardTourEnum.GENERAL_TOUR}-2"]`,
        content: (
          <Box width={"400px"}>
            Click on a lead to assign it to a team member, update it's status,
            and view additional details such as source & estimated valuation.
          </Box>
        ),
        actionBeforeNextStep: async () => {
          await selectLead(null);
          if (accountStore.account.roleId === UserRoleEnum.ADMIN) {
            setLeftSidebarIsOpen(true);
            return new Promise<void>(resolve => {
              setTimeout(resolve, STEP_SWITCH_DELAY);
            });
          }
        },
        actionBeforePreviousStep: async () => {
          await selectLead(null);
        },
      },
    ];

    if (accountStore.account.roleId === UserRoleEnum.ADMIN) {
      steps.push({
        selector: `[tour="${DashboardTourEnum.GENERAL_TOUR}-3"]`,
        content: (
          <Box width={"400px"}>
            To start generating valuation leads, set up your Instant Valuation
            Tool and/or Lead Responder.
          </Box>
        ),
        actionBeforePreviousStep: async () => {
          await selectLead(dummyLeadDetailsData.id);
          return new Promise<void>(resolve => {
            setTimeout(resolve, STEP_SWITCH_DELAY);
          });
        },
      });
    }

    steps.push({
      selector: `[tour="${DashboardTourEnum.GENERAL_TOUR}-4"]`,
      content: (
        <Box width={"400px"}>
          If you wish to revisit this tour, simply click here. Similar tours are
          available on the individual tool pages.
        </Box>
      ),
      actionBeforePreviousStep: async () => {
        if (accountStore.account.roleId === UserRoleEnum.ADMIN) {
          setLeftSidebarIsOpen(true);
        } else {
          await selectLead(dummyLeadDetailsData.id);
        }
        return new Promise<void>(resolve => {
          setTimeout(resolve, STEP_SWITCH_DELAY);
        });
      },
    });

    return steps;
  }, [accountStore, selectLead, setLeftSidebarIsOpen]);

  return (
    <TourBase
      tourId={DashboardTourEnum.GENERAL_TOUR}
      steps={steps}
      onAfterOpen={onAfterOpen}
      onBeforeClose={onBeforeClose}
    />
  );
});
