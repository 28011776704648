import React, { FC, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { UrlSyncIntegrationForm } from "./UrlSyncIntegrationForm";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";

interface IUrlSyncIntegrationConfiguratorProps {
  accountStore: UserAccountStore;
}

export const UrlSyncIntegrationConfigurator: FC<IUrlSyncIntegrationConfiguratorProps> = observer(
  ({ accountStore }) => {
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const urlSyncStore =
      accountStore.accountIntegrationsStore.accountIntegrationUrlSyncStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await urlSyncStore.loadUrlSyncMergeTags();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [urlSyncStore]);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <UrlSyncIntegrationForm
            accountIntegrationsStore={accountIntegrationsStore}
          />
        )}
      </>
    );
  }
);
