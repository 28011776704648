import { observer } from "mobx-react";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import React, { FC, useMemo } from "react";
import { FormToolPageSettingsEmptyList } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsEmptyList";
import { Box, VStack } from "@chakra-ui/react";
import { Droppable } from "react-beautiful-dnd";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { FormToolPageSettingsSelectedActionConfigurator } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsSelectedActionConfigurator";
import { QuestionnaireToolPageSettingsActionParamsConfigurator } from "./QuestionnaireToolPageSettingsSelectedActionConfigurator/QuestionnaireToolPageSettingsActionParamsConfigurator/QuestionnaireToolPageSettingsActionParamsConfigurator";

interface IProps {
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsSelectedActionsList: FC<IProps> = observer(
  ({ leadType, questionnaireToolPageWIPFormStore }) => {
    const formActions = questionnaireToolPageWIPFormStore.wipFormActionsJS;

    const elements = useMemo(() => {
      return formActions.map((action, index) => (
        <FormToolPageSettingsSelectedActionConfigurator
          key={`${action.type}-${index}`}
          actionIndex={index}
          formToolPageWIPFormStore={questionnaireToolPageWIPFormStore}
          additionalConfigurators={
            <QuestionnaireToolPageSettingsActionParamsConfigurator
              actionIndex={index}
              questionnaireToolPageWIPFormStore={
                questionnaireToolPageWIPFormStore
              }
            />
          }
        />
      ));
    }, [formActions, questionnaireToolPageWIPFormStore]);

    return (
      <Box width={"100%"}>
        <Box pb={10}>
          <Box pb={"10px"} fontSize={"lg"} fontWeight={"bold"}>
            Actions
          </Box>
          <Box fontSize={"sm"} color={"leadpro.500"}>
            Here you can specify how your questionnaire responses are processed.{" "}
            <b>
              Begin by dragging and dropping an action type from the left panel.
            </b>
          </Box>
        </Box>
        <Box>
          <Droppable
            droppableId={`${FormToolPageDroppableContainerEnum.SELECTED_ACTIONS}:${leadType}`}
          >
            {provided => (
              <VStack
                spacing={2}
                paddingBottom={!!elements.length ? "250px" : "150px"}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!elements.length && (
                  <FormToolPageSettingsEmptyList>
                    Drag the actions from the menu and drop them here.
                  </FormToolPageSettingsEmptyList>
                )}
                {elements}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </Box>
      </Box>
    );
  }
);
