import { BaseApi } from "./base.api";
import {
  TFormToolFormResponsesResponse,
  TPageToolForm,
} from "../types/form-tool-page.type";

export class AccountFormsApi extends BaseApi {
  async fetchOne(accountId: number, formId: number): Promise<TPageToolForm> {
    return this.get(`/accounts/${accountId}/forms/${formId}`);
  }

  async create(
    accountId: number,
    data: Partial<TPageToolForm>
  ): Promise<TPageToolForm> {
    return this.post(`/accounts/${accountId}/forms`, data);
  }

  async update(
    accountId: number,
    formId: number,
    data: Partial<TPageToolForm>
  ): Promise<TPageToolForm> {
    return this.put(`/accounts/${accountId}/forms/${formId}`, data);
  }

  async delete(accountId: number, formId: number) {
    await this.del(`/accounts/${accountId}/forms/${formId}`);
  }

  async fetchFormResponses(
    accountId: number,
    formId: number,
    offset: number,
    limit: number
  ): Promise<TFormToolFormResponsesResponse> {
    return this.get(`/accounts/${accountId}/forms/${formId}/responses`, {
      params: {
        offset,
        limit,
      },
    });
  }
}
