import {
  TAccountJourney,
  TAccountJourneyCreateData,
} from "types/account-journeys.type";
import { BaseApi } from "./base.api";

export class AccountJournysApi extends BaseApi {
  async fetchAllAccountJourneys(accountId: number): Promise<TAccountJourney[]> {
    return this.get(`/accounts/${accountId}/journeys`);
  }

  async fetchAccountJourney(
    accountId: number,
    journeyId: number
  ): Promise<TAccountJourney> {
    return this.get(`/accounts/${accountId}/journeys/${journeyId}`);
  }

  async createAccountJourney(
    accountId: number,
    journeyData: TAccountJourneyCreateData
  ): Promise<TAccountJourney> {
    return this.post(`/accounts/${accountId}/journeys`, journeyData);
  }

  async updateAccountJourney(
    accountId: number,
    journeyId: number,
    journeyData: TAccountJourneyCreateData
  ): Promise<TAccountJourney> {
    return this.patch(
      `/accounts/${accountId}/journeys/${journeyId}`,
      journeyData
    );
  }

  async updateAccountJourneyOrder(
    accountId: number,
    ids: number[]
  ): Promise<void> {
    return this.put(`accounts/${accountId}/journeys/reorder`, {
      ids,
    });
  }

  async deleteAccountJourney(
    accountId: number,
    journeyId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/journeys/${journeyId}`);
  }
}
