import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  children: React.ReactNode;
}

export const NavLinkIcon: React.FC<IProps> = ({ children }) => {
  return (
    <Box
      width="30px"
      height="30px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      rounded="full"
      mr={2}
    >
      {children}
    </Box>
  );
};
