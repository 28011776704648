import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountLeadActivitiesApi } from "api/account-lead-activities";
import { AppStore } from "../../../App.store";
import { UserRoleEnum } from "enums/user-role.enum";
import {
  isContactNoteActivity,
  isLeadProEventActivity,
} from "routes/dashboard/routes/leads/components/LeadDrawer/LeadActivity/lead-activity-utils";
import { LeadEventTypeEnum } from "enums/lead-event-type.enum";
import {
  TLeadActivity,
  TLeadActivityMeta,
} from "types/account-lead-activity.type";
import { TContactNote } from "types/lead.type";
import moment from "moment/moment";

const leadActivityApi = new AccountLeadActivitiesApi();

const FETCH_LIMIT = 10;

const getDefaultMeta = (): TLeadActivityMeta => {
  return {
    beforeDate: moment()
      .endOf("day")
      .toISOString(),
    limit: 0,
    count: 0,
  };
};

export class AccountLeadActivitiesStore {
  private readonly accountId: number;
  private readonly leadId: number;
  private readonly root: AppStore;
  private leadActivity: IObservableArray<TLeadActivity>;
  private meta: TLeadActivityMeta;
  public isFetchingInProgress: boolean;

  constructor(root: AppStore, accountId: number, leadId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountId = accountId;
    this.leadId = leadId;
    this.leadActivity = observable.array<TLeadActivity>();
    this.root = root;
    this.meta = getDefaultMeta();
    this.isFetchingInProgress = false;
  }

  get leadActivitiesArray() {
    const userAccount = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ];
    const userAccountRoleId = userAccount?.account?.roleId;

    if (userAccountRoleId !== UserRoleEnum.ADMIN) {
      return this.leadActivity.filter(
        activity =>
          (isLeadProEventActivity(activity) &&
            activity.eventType !==
              LeadEventTypeEnum.LEAD_WORKFLOW_STATUS_UPDATED) ||
          isContactNoteActivity(activity)
      );
    }

    return this.leadActivity.slice();
  }

  private shouldLoadNextBatch(): boolean {
    const { beforeDate, count, limit } = this.meta;
    return !(beforeDate === null || count < limit);
  }

  public reset() {
    this.leadActivity.clear();
    this.meta = getDefaultMeta();
    this.isFetchingInProgress = false;
  }

  public appendNewNoteToActivities(newNote: TContactNote) {
    this.leadActivity.unshift(newNote);
  }

  public async loadLeadActivities() {
    if (!this.shouldLoadNextBatch()) return;
    if (this.isFetchingInProgress) return;

    try {
      this.isFetchingInProgress = true;
      const { beforeDate } = this.meta;
      const { data, meta } = await leadActivityApi.fetchAccountLeadActivities(
        this.accountId,
        this.leadId,
        {
          beforeDate: beforeDate,
          limit: FETCH_LIMIT,
        }
      );

      this.appendLeadActivity(data);
      this.setMeta(meta);
      this.isFetchingInProgress = false;
    } catch (error) {
      this.isFetchingInProgress = false;
      throw error;
    }
  }

  private setMeta(meta: TLeadActivityMeta) {
    this.meta = meta;
  }

  private appendLeadActivity(leadActivity: TLeadActivity[]) {
    this.leadActivity.push(...leadActivity);
  }
}
