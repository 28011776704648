import React from "react";
import { Box, Center, HStack, Kbd, VStack } from "@chakra-ui/react";
import { FormControlV2, Tooltip } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { TAutocallerPhonesFieldValues } from "utils/validation-schemas/autocaller.validation";
import { Control, FieldPath } from "react-hook-form";

type TFieldValues = TAutocallerPhonesFieldValues;

interface IProps {
  numberFieldName: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}

export const AutocallerPhoneForm: React.FC<IProps> = ({
  numberFieldName,
  control,
}) => {
  return (
    <VStack spacing={4} align={"stretch"}>
      <FormControlV2<TFieldValues>
        label={
          <HStack spacing={2}>
            <Center
              width={"24px"}
              height={"24px"}
              border={"1px solid"}
              borderColor={"gray.500"}
              color={"gray.500"}
              rounded={"full"}
            >
              1
            </Center>
            <Box display="flex" flexDirection="row" alignItems="center">
              Keypress (optional)
              <Tooltip
                aria-label={`keypress-tooltip`}
                label={
                  <span>
                    If your phone line has an auto attendant (e.g. press 1 for
                    sales 2 for lettings) please enter the number here. If you
                    have multiple key presses please separate them with a{" "}
                    <Kbd>,</Kbd>. Each <Kbd>,</Kbd> creates a 2 second delay.
                    You can use multiple <Kbd>,</Kbd>s
                  </span>
                }
              >
                <Box opacity={0.6} ml={1}>
                  <FontAwesomeIcon icon={faCircleInfo} fontSize={14} />
                </Box>
              </Tooltip>
            </Box>
          </HStack>
        }
        type={FormControlsTypeEnum.TEXT}
        name={numberFieldName}
        control={control}
        additionalProps={{
          placeholder: "Enter key sequence",
        }}
      />
    </VStack>
  );
};
