import { makeAutoObservable, toJS } from "mobx";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import {
  questionnaireToolPageSettingsValidationSchema,
  TQuestionnaireToolSettingsFieldValues,
} from "utils/validation-schemas/page-settings.validation";
import {
  TCreateOrUpdatePageData,
  TPageQuestionnaireConfig,
} from "types/page.type";
import { IPageToolPageSettingsEditorStore } from "interfaces/IPageToolPageSettingsEditorStore";
import { AccountQuestionnairePagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountQuestionnairePagesStore/AccountQuestionnairePages.store";
import { QuestionnaireToolPageWIPFormStore } from "./QuestionnaireToolPageWIPForm.store";
import { AccountQuestionnairePageQuestionnaireStore } from "store/UserAccounts/UserAccount/AccountPages/AccountQuestionnairePagesStore/AccountQuestionnairePageQuestionnaire.store";
import { generateQuestionnaireToolDefaultPageData } from "utils/questionnaire-tool-page.utils";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { TSelectOption } from "types/select-input.type";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { capitalize, values } from "lodash";
import { validateFormEndingPages } from "@leadpro/forms";

interface ILeadResponderQuestionnairePageSettingsEditorStore {
  pageId: number | null;
  accountQuestionnairePagesStore: AccountQuestionnairePagesStore;
}

export class QuestionnaireToolPageSettingsEditorStore
  implements IPageToolPageSettingsEditorStore {
  public loadingStatus: ApiRequestStatusEnum;
  public isSubmitting: boolean;
  public readonly pageId: number | null;
  private readonly accountQuestionnairePagesStore: AccountQuestionnairePagesStore;
  private wipPage: TQuestionnaireToolSettingsFieldValues;
  public wipQuestionnaireStoresMap: Record<
    QuestionnaireLeadTypes,
    QuestionnaireToolPageWIPFormStore
  >;

  constructor({
    pageId,
    accountQuestionnairePagesStore,
  }: ILeadResponderQuestionnairePageSettingsEditorStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loadingStatus = ApiRequestStatusEnum.NONE;
    this.isSubmitting = false;
    this.pageId = pageId;
    this.accountQuestionnairePagesStore = accountQuestionnairePagesStore;
    this.wipPage = generateQuestionnaireToolDefaultPageData();
    this.wipQuestionnaireStoresMap = {
      [LeadTypeEnum.Landlord]: new QuestionnaireToolPageWIPFormStore({}),
      [LeadTypeEnum.Let]: new QuestionnaireToolPageWIPFormStore({}),
      [LeadTypeEnum.MortgageRequest]: new QuestionnaireToolPageWIPFormStore({}),
      [LeadTypeEnum.Sale]: new QuestionnaireToolPageWIPFormStore({}),
      [LeadTypeEnum.Vendor]: new QuestionnaireToolPageWIPFormStore({}),
    };
  }

  get page() {
    return !!this.pageId
      ? this.accountQuestionnairePagesStore.accountPagesMap[this.pageId]
      : null;
  }

  get pageStore() {
    return !!this.page
      ? this.accountQuestionnairePagesStore.accountQuestionnairePageStoresMap[
          this.page.id
        ]
      : null;
  }

  get wipQuestionnaireStoresArray() {
    return Object.values(this.wipQuestionnaireStoresMap);
  }

  get pageQuestionnaireStoresMap(): Record<
    QuestionnaireLeadTypes,
    AccountQuestionnairePageQuestionnaireStore | null
  > {
    return {
      [LeadTypeEnum.Landlord]: this.page?.pageQuestionnaireConfig
        .landlordQuestionnaireId
        ? this.accountQuestionnairePagesStore
            .accountQuestionnairePageQuestionnaireStoresMap[
            this.page?.pageQuestionnaireConfig.landlordQuestionnaireId
          ]
        : null,
      [LeadTypeEnum.Let]: this.page?.pageQuestionnaireConfig.letQuestionnaireId
        ? this.accountQuestionnairePagesStore
            .accountQuestionnairePageQuestionnaireStoresMap[
            this.page?.pageQuestionnaireConfig.letQuestionnaireId
          ]
        : null,
      [LeadTypeEnum.MortgageRequest]: this.page?.pageQuestionnaireConfig
        .mortgageRequestQuestionnaireId
        ? this.accountQuestionnairePagesStore
            .accountQuestionnairePageQuestionnaireStoresMap[
            this.page?.pageQuestionnaireConfig.mortgageRequestQuestionnaireId
          ]
        : null,
      [LeadTypeEnum.Sale]: this.page?.pageQuestionnaireConfig
        .saleQuestionnaireId
        ? this.accountQuestionnairePagesStore
            .accountQuestionnairePageQuestionnaireStoresMap[
            this.page?.pageQuestionnaireConfig.saleQuestionnaireId
          ]
        : null,
      [LeadTypeEnum.Vendor]: this.page?.pageQuestionnaireConfig
        .vendorQuestionnaireId
        ? this.accountQuestionnairePagesStore
            .accountQuestionnairePageQuestionnaireStoresMap[
            this.page?.pageQuestionnaireConfig.vendorQuestionnaireId
          ]
        : null,
    };
  }

  get wipPageJS() {
    return toJS(this.wipPage);
  }

  get isWIPPageValid() {
    return questionnaireToolPageSettingsValidationSchema.isValidSync(
      this.wipPageJS
    );
  }

  get wipPageCustomEndingPagesValidationResult(): Record<
    QuestionnaireLeadTypes,
    ReturnType<typeof validateFormEndingPages>
  > {
    return {
      [LeadTypeEnum.Landlord]: this.wipQuestionnaireStoresMap[
        LeadTypeEnum.Landlord
      ].wipFormCustomEndingPagesValidationResult,
      [LeadTypeEnum.Let]: this.wipQuestionnaireStoresMap[LeadTypeEnum.Let]
        .wipFormCustomEndingPagesValidationResult,
      [LeadTypeEnum.MortgageRequest]: this.wipQuestionnaireStoresMap[
        LeadTypeEnum.MortgageRequest
      ].wipFormCustomEndingPagesValidationResult,
      [LeadTypeEnum.Sale]: this.wipQuestionnaireStoresMap[LeadTypeEnum.Sale]
        .wipFormCustomEndingPagesValidationResult,
      [LeadTypeEnum.Vendor]: this.wipQuestionnaireStoresMap[LeadTypeEnum.Vendor]
        .wipFormCustomEndingPagesValidationResult,
    };
  }

  get areAllWIPPageCustomEndingPagesValid() {
    const validationResults = this.wipPageCustomEndingPagesValidationResult;
    return values(validationResults).every(
      validationResult => validationResult.isValid
    );
  }

  get isPageDefault() {
    return this.page?.pageQuestionnaireConfig.default || false;
  }

  get hasWIPErrors() {
    return (
      !this.isWIPPageValid ||
      !this.areAllWIPQuestionnairesFormSchemaAndActionsValid ||
      !this.areAllWIPPageCustomEndingPagesValid
    );
  }

  get areAllQuestionnairesIgnored() {
    return this.wipQuestionnaireStoresArray.every(store => store.isIgnored);
  }

  get areAllWIPQuestionnairesFormSchemaAndActionsValid() {
    if (this.areAllQuestionnairesIgnored) return false;

    return this.wipQuestionnaireStoresArray.every(
      store =>
        store.isIgnored || store.isWIPQuestionnaireFormSchemaAndActionsValid
    );
  }
  get areAllWIPQuestionnaireFormSchemasWithoutLogicAndWithActionsValid() {
    if (this.areAllQuestionnairesIgnored) return false;

    return this.wipQuestionnaireStoresArray.every(
      store =>
        store.isIgnored ||
        store.isWIPQuestionnaireFormSchemaWithoutLogicAndWithActionsValid
    );
  }

  get areAllWIPQuestionnairesLogicsValid() {
    if (this.areAllQuestionnairesIgnored) return false;

    return this.wipQuestionnaireStoresArray.every(
      store => store.isIgnored || store.isWIPQuestionnaireFormSchemaLogicValid
    );
  }

  get wipPageDataForCreateOrUpdate(): Partial<TCreateOrUpdatePageData> {
    return {
      name: this.wipPageJS.name,
      color: this.wipPageJS.color || null,
      facebookPixelId: this.wipPageJS.facebookPixelId || null,
      googleAnalyticsId: this.wipPageJS.googleAnalyticsId || null,
      googleAdsConversionId: this.wipPageJS.googleAdsConversionId || null,
      googleAdsConversionLabelId:
        this.wipPageJS.googleAdsConversionLabelId || null,
      host: this.wipPageJS.host,
      privacyPolicyLink: this.wipPageJS.privacyPolicyLink || null,
      gdprCheckboxEnabled: this.wipPageJS.gdprCheckboxEnabled,
      gdprCheckboxLabel: this.wipPageJS.gdprCheckboxLabel || null,
      gdprCheckboxDescription: this.wipPageJS.gdprCheckboxDescription || null,
      gdprCheckboxAcceptanceRequired: this.wipPageJS
        .gdprCheckboxAcceptanceRequired,
      photoFile: this.wipPageJS.photoFile || undefined,
      customLogoFile: this.wipPageJS.customLogoFile || undefined,
      pageQuestionnaireConfig: {
        active: this.wipPageJS.active,
        leadFilterId: this.wipPageJS.leadFilterId,
        isSlideshowModeEnabled: this.wipPageJS.isSlideshowModeEnabled,
        landlordQuestionnaireId: this.wipPageJS.landlordQuestionnaireId || null,
        letQuestionnaireId: this.wipPageJS.letQuestionnaireId || null,
        mortgageRequestQuestionnaireId:
          this.wipPageJS.mortgageRequestQuestionnaireId || null,
        saleQuestionnaireId: this.wipPageJS.saleQuestionnaireId || null,
        vendorQuestionnaireId: this.wipPageJS.vendorQuestionnaireId || null,
        customThankYouPageHeader:
          this.wipPageJS.customThankYouPageHeader || null,
        customThankYouPageBody: this.wipPageJS.customThankYouPageBody || null,
        customThankYouPageCtaLabel:
          this.wipPageJS.customThankYouPageCtaLabel || null,
        customThankYouPageCtaUrl:
          this.wipPageJS.customThankYouPageCtaUrl || null,
      },
    };
  }

  get previewLinkOptions(): TSelectOption<string>[] {
    if (!this.page || !this.page?.pageQuestionnaireConfig) return [];

    const previewTypes: QuestionnaireLeadTypes[] = [
      LeadTypeEnum.Landlord,
      LeadTypeEnum.Let,
      LeadTypeEnum.MortgageRequest,
      LeadTypeEnum.Sale,
      LeadTypeEnum.Vendor,
    ];
    return previewTypes.map(previewType => ({
      label: capitalize(getLeadTypeLabel(previewType)),
      value: `${this.page?.host}?questionnairePreviewType=${previewType}`,
    }));
  }

  public setWipPage(pageData: TQuestionnaireToolSettingsFieldValues) {
    this.wipPage = pageData;
  }

  public async loadData() {
    if (!this.pageId) {
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      return;
    }

    try {
      this.setLoadingStatus(ApiRequestStatusEnum.PENDING);
      await this.accountQuestionnairePagesStore.loadPage(this.pageId);
      await this.loadPageQuestionnaires();

      if (!!this.page) {
        this.setWipPage(generateQuestionnaireToolDefaultPageData(this.page));
        this.setWIPQuestionnairesFromExisting();
      }
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (error) {
      this.setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }

  public async updatePageAndPageComponents() {
    if (this.hasWIPErrors) return;

    try {
      this.setIsSubmitting(true);
      const partialPageQuestionnaireConfig = await this.updateOrCreateAllQuestionnaires();

      await this.updatePage({
        pageQuestionnaireConfig: {
          ...this.wipPageDataForCreateOrUpdate.pageQuestionnaireConfig,
          ...partialPageQuestionnaireConfig,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      this.setIsSubmitting(false);
    }
  }

  public async createPageAndPageComponents(): Promise<number | undefined> {
    if (this.hasWIPErrors) return;

    try {
      this.setIsSubmitting(true);
      await this.checkIfHostIsAvailable();

      const questionnaireIds = await this.createAllCreatableQuestionnaires();
      return await this.createPage(questionnaireIds);
    } catch (error) {
      throw error;
    } finally {
      this.setIsSubmitting(false);
    }
  }

  private setLoadingStatus(loadingStatus: ApiRequestStatusEnum) {
    this.loadingStatus = loadingStatus;
  }

  private setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  private async checkIfHostIsAvailable() {
    await this.accountQuestionnairePagesStore.checkIfHostIsAvailable(
      this.wipPageJS.host
    );
  }

  private async createPage(
    questionnaireIds: { [key in QuestionnaireLeadTypes]?: number }
  ): Promise<number> {
    const partialPageQuestionnaireConfig: Partial<TPageQuestionnaireConfig> = {
      landlordQuestionnaireId: questionnaireIds[LeadTypeEnum.Landlord],
      letQuestionnaireId: questionnaireIds[LeadTypeEnum.Let],
      mortgageRequestQuestionnaireId:
        questionnaireIds[LeadTypeEnum.MortgageRequest],
      saleQuestionnaireId: questionnaireIds[LeadTypeEnum.Sale],
      vendorQuestionnaireId: questionnaireIds[LeadTypeEnum.Vendor],
    };
    const page = {
      ...this.wipPageDataForCreateOrUpdate,
      pageQuestionnaireConfig: {
        ...this.wipPageDataForCreateOrUpdate.pageQuestionnaireConfig,
        ...(partialPageQuestionnaireConfig as TPageQuestionnaireConfig),
      },
    };

    return await this.accountQuestionnairePagesStore.createPage(page);
  }

  private async updatePage(override: Partial<TCreateOrUpdatePageData>) {
    if (!this.pageStore) return;

    await this.pageStore.updatePage({
      ...this.wipPageDataForCreateOrUpdate,
      ...override,
    });
  }

  private async updateOrCreateAllQuestionnaires(): Promise<
    Partial<TPageQuestionnaireConfig>
  > {
    await this.updateAllUpdatableQuestionnaires();
    const freshlyRemovedQuestionnaires = await this.removeAllRemovableQuestionnaires();
    const newlyCreatedQuestionnaireIds = await this.createAllCreatableQuestionnaires();

    return {
      landlordQuestionnaireId: freshlyRemovedQuestionnaires[
        LeadTypeEnum.Landlord
      ]
        ? null
        : newlyCreatedQuestionnaireIds[LeadTypeEnum.Landlord],
      letQuestionnaireId: freshlyRemovedQuestionnaires[LeadTypeEnum.Let]
        ? null
        : newlyCreatedQuestionnaireIds[LeadTypeEnum.Let],
      mortgageRequestQuestionnaireId: freshlyRemovedQuestionnaires[
        LeadTypeEnum.MortgageRequest
      ]
        ? null
        : newlyCreatedQuestionnaireIds[LeadTypeEnum.MortgageRequest],
      saleQuestionnaireId: freshlyRemovedQuestionnaires[LeadTypeEnum.Sale]
        ? null
        : newlyCreatedQuestionnaireIds[LeadTypeEnum.Sale],
      vendorQuestionnaireId: freshlyRemovedQuestionnaires[LeadTypeEnum.Vendor]
        ? null
        : newlyCreatedQuestionnaireIds[LeadTypeEnum.Vendor],
    };
  }

  private async removeAllRemovableQuestionnaires(): Promise<
    { [key in QuestionnaireLeadTypes]?: boolean }
  > {
    const questionnaireIdsMap: {
      [key in QuestionnaireLeadTypes]?: boolean;
    } = {};

    Object.keys(this.wipQuestionnaireStoresMap).map(async (key, index) => {
      const typeKey = key as QuestionnaireLeadTypes;

      const questionnaireStore = this.pageQuestionnaireStoresMap[typeKey];
      if (questionnaireStore?.questionnaire) {
        if (
          !this.wipQuestionnaireStoresMap[typeKey].wipFormSchemaJS.order.length
        ) {
          // TODO NENAD QUESTIONNAIRE: ACTUALLY CALL DELETE API ENDPOINT IF WE HAVE IT?
          questionnaireIdsMap[typeKey] = true;
        }
      }
    });

    return questionnaireIdsMap;
  }

  private async createQuestionnaire(
    type: QuestionnaireLeadTypes
  ): Promise<number | undefined> {
    const wipQuestionnaireStore = this.wipQuestionnaireStoresMap[type];
    if (wipQuestionnaireStore.isIgnored) return;
    return await this.accountQuestionnairePagesStore.createQuestionnaire({
      schema: wipQuestionnaireStore.wipFormSchemaJS,
      actions: wipQuestionnaireStore.wipFormActionsJS,
      customEndingPages:
        wipQuestionnaireStore.wipFormCustomEndingPagesJSWithoutTempId,
    });
  }

  private async createAllCreatableQuestionnaires(): Promise<
    { [key in QuestionnaireLeadTypes]?: number }
  > {
    const questionnaireIdsMap: {
      [key in QuestionnaireLeadTypes]?: number;
    } = {};
    await Promise.all(
      Object.keys(this.wipQuestionnaireStoresMap).map(async (key, index) => {
        const typeKey = key as QuestionnaireLeadTypes;
        const questionnaireStore = this.pageQuestionnaireStoresMap[typeKey];
        if (!questionnaireStore) {
          questionnaireIdsMap[typeKey] = await this.createQuestionnaire(
            typeKey
          );
        }
      })
    );

    return questionnaireIdsMap;
  }

  private async updateQuestionnaireOrSkipIfEmpty(type: QuestionnaireLeadTypes) {
    const questionnaireStore = this.pageQuestionnaireStoresMap[type];
    if (questionnaireStore?.questionnaire) {
      if (!this.wipQuestionnaireStoresMap[type].wipFormSchemaJS.order.length)
        return;
      await questionnaireStore.updateQuestionnaire({
        schema: this.wipQuestionnaireStoresMap[type].wipFormSchemaJS,
        actions: this.wipQuestionnaireStoresMap[type].wipFormActionsJS,
        customEndingPages: this.wipQuestionnaireStoresMap[type]
          .wipFormCustomEndingPagesJSWithoutTempId,
      });
    }
  }

  private async updateAllUpdatableQuestionnaires() {
    return Promise.all(
      Object.keys(this.pageQuestionnaireStoresMap).map(typeKey =>
        this.updateQuestionnaireOrSkipIfEmpty(typeKey as QuestionnaireLeadTypes)
      )
    );
  }

  private setWIPQuestionnairesFromExisting() {
    let existingQuestionnaire = this.pageQuestionnaireStoresMap[
      LeadTypeEnum.Landlord
    ]?.questionnaire;
    this.wipQuestionnaireStoresMap[
      LeadTypeEnum.Landlord
    ] = new QuestionnaireToolPageWIPFormStore({
      form: existingQuestionnaire?.schema,
      actions: existingQuestionnaire?.actions,
      customEndingPages: existingQuestionnaire?.customEndingPages,
    });

    existingQuestionnaire = this.pageQuestionnaireStoresMap[LeadTypeEnum.Let]
      ?.questionnaire;
    this.wipQuestionnaireStoresMap[
      LeadTypeEnum.Let
    ] = new QuestionnaireToolPageWIPFormStore({
      form: existingQuestionnaire?.schema,
      actions: existingQuestionnaire?.actions,
      customEndingPages: existingQuestionnaire?.customEndingPages,
    });

    existingQuestionnaire = this.pageQuestionnaireStoresMap[
      LeadTypeEnum.MortgageRequest
    ]?.questionnaire;
    this.wipQuestionnaireStoresMap[
      LeadTypeEnum.MortgageRequest
    ] = new QuestionnaireToolPageWIPFormStore({
      form: existingQuestionnaire?.schema,
      actions: existingQuestionnaire?.actions,
      customEndingPages: existingQuestionnaire?.customEndingPages,
    });

    existingQuestionnaire = this.pageQuestionnaireStoresMap[LeadTypeEnum.Sale]
      ?.questionnaire;
    this.wipQuestionnaireStoresMap[
      LeadTypeEnum.Sale
    ] = new QuestionnaireToolPageWIPFormStore({
      form: existingQuestionnaire?.schema,
      actions: existingQuestionnaire?.actions,
      customEndingPages: existingQuestionnaire?.customEndingPages,
    });

    existingQuestionnaire = this.pageQuestionnaireStoresMap[LeadTypeEnum.Vendor]
      ?.questionnaire;
    this.wipQuestionnaireStoresMap[
      LeadTypeEnum.Vendor
    ] = new QuestionnaireToolPageWIPFormStore({
      form: existingQuestionnaire?.schema,
      actions: existingQuestionnaire?.actions,
      customEndingPages: existingQuestionnaire?.customEndingPages,
    });
  }

  private async loadPageQuestionnaires() {
    if (!!this.page) {
      return Promise.all([
        !!this.page.pageQuestionnaireConfig.landlordQuestionnaireId &&
          this.accountQuestionnairePagesStore.loadQuestionnaire(
            this.page.pageQuestionnaireConfig.landlordQuestionnaireId
          ),
        !!this.page.pageQuestionnaireConfig.letQuestionnaireId &&
          this.accountQuestionnairePagesStore.loadQuestionnaire(
            this.page.pageQuestionnaireConfig.letQuestionnaireId
          ),
        !!this.page.pageQuestionnaireConfig.mortgageRequestQuestionnaireId &&
          this.accountQuestionnairePagesStore.loadQuestionnaire(
            this.page.pageQuestionnaireConfig.mortgageRequestQuestionnaireId
          ),
        !!this.page.pageQuestionnaireConfig.saleQuestionnaireId &&
          this.accountQuestionnairePagesStore.loadQuestionnaire(
            this.page.pageQuestionnaireConfig.saleQuestionnaireId
          ),
        !!this.page.pageQuestionnaireConfig.vendorQuestionnaireId &&
          this.accountQuestionnairePagesStore.loadQuestionnaire(
            this.page.pageQuestionnaireConfig.vendorQuestionnaireId
          ),
      ]);
    }
  }
}
