import React, { useMemo } from "react";
import { Box, Center, HStack } from "@chakra-ui/react";
import { EmptyLeadProductPitch } from "./EmptyLeadProductPitch";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { TOOLS_IVT_ROUTE, TOOLS_LEAD_RESPONDER_ROUTE } from "constants/routes";
import { UserRoleEnum } from "enums/user-role.enum";
import { isApplicationProductUnlocked } from "utils/account-billing.utils";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { EmptyScreen } from "components";
import { IVT_TOOL_ICON, LEAD_RESPONDER_ICON } from "constants/image-paths";

export const EmptyLeadsTable = observer(() => {
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();
  const accountBilling = selectedAccountStore?.accountBillingStore.billing;
  const roleId = selectedAccountStore?.account.roleId;

  const shouldShowProductsPitch = useMemo(() => {
    return (
      !isApplicationProductUnlocked(
        ApplicationProductEnum.INSTANT_VALUATION_TOOL,
        accountBilling
      ) &&
      !isApplicationProductUnlocked(
        ApplicationProductEnum.LEAD_RESPONDER,
        accountBilling
      ) &&
      roleId === UserRoleEnum.ADMIN
    );
  }, [roleId, accountBilling]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      minHeight={"100%"}
      bg={"gray.50"}
    >
      <Center width={"100%"} padding={5}>
        {shouldShowProductsPitch ? (
          <Box>
            <Box fontSize={"2xl"} fontWeight={700} mb={5} maxW={"xl"}>
              To start generating leads, you need to add one or more lead
              sources.
            </Box>
            <HStack spacing={6}>
              <EmptyLeadProductPitch
                title={"Instant Valuation Tool"}
                pitch={"Start booking more valuations today."}
                icon={IVT_TOOL_ICON}
                to={TOOLS_IVT_ROUTE}
              />
              <EmptyLeadProductPitch
                title={"Lead Responder"}
                pitch={"Convert applicants into instructions."}
                icon={LEAD_RESPONDER_ICON}
                to={TOOLS_LEAD_RESPONDER_ROUTE}
              />
            </HStack>
          </Box>
        ) : (
          <EmptyScreen label={"No leads."} />
        )}
      </Center>
    </Box>
  );
});
