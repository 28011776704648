import { IAccountPageToolStore } from "../IAccountPageToolStore";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AccountQuestionnairePageStore } from "./AccountQuestionnairePage.store";
import { AccountQuestionnairePageQuestionnaireStore } from "./AccountQuestionnairePageQuestionnaire.store";
import { AccountPagesApi } from "api/account-pages.api";
import { keyBy } from "lodash";
import { TCreateOrUpdatePageData, TPageData } from "types/page.type";
import {
  TPageToolQuestionnaire,
  TQuestionnairePageTableData,
} from "types/questionnaire-tool-page.type";
import { PageToolTypeEnum } from "enums/page-tool-type.enum";
import { AccountQuestionnairesApi } from "api/account-questionnaires.api";

const pagesApi = new AccountPagesApi();
const questionnairesApi = new AccountQuestionnairesApi();

export class AccountQuestionnairePagesStore implements IAccountPageToolStore {
  private readonly accountId: number;
  private readonly accountQuestionnairePageStores: IObservableArray<
    AccountQuestionnairePageStore
  >;
  private readonly accountQuestionnairePageQuestionnaireStores: IObservableArray<
    AccountQuestionnairePageQuestionnaireStore
  >;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.accountQuestionnairePageStores = observable.array<
      AccountQuestionnairePageStore
    >();
    this.accountQuestionnairePageQuestionnaireStores = observable.array<
      AccountQuestionnairePageQuestionnaireStore
    >();
  }

  // PAGE GETTERS

  get accountPagesArray() {
    return this.accountQuestionnairePageStores.map(store => store.page);
  }

  get accountPagesMap() {
    return keyBy(this.accountPagesArray, page => page.id);
  }

  get accountQuestionnairePageStoresMap() {
    return keyBy(
      this.accountQuestionnairePageStores,
      pageStore => pageStore.page.id
    );
  }

  get accountPagesTableDataSortedByPriorityDesc(): TQuestionnairePageTableData[] {
    return this.accountPagesArray
      .map(page => ({
        id: page.id,
        name: page.name,
        active: page.pageQuestionnaireConfig.active,
        default: page.pageQuestionnaireConfig.default,
        leadFilterId: page.pageQuestionnaireConfig.leadFilterId,
        priority: page.pageQuestionnaireConfig.priority,
        pageQuestionnaireConfigId: page.pageQuestionnaireConfigId,
        createdAt: page.createdAt,
        updatedAt: page.updatedAt,
      }))
      .sort((a, b) => a.priority - b.priority);
  }

  // QUESTIONNAIRE GETTERS
  get accountQuestionnairesArray() {
    return this.accountQuestionnairePageQuestionnaireStores.map(
      store => store.questionnaire
    );
  }

  get accountQuestionnairesMap() {
    return keyBy(
      this.accountQuestionnairesArray,
      questionnaire => questionnaire.id
    );
  }

  get accountQuestionnairePageQuestionnaireStoresMap() {
    return keyBy(
      this.accountQuestionnairePageQuestionnaireStores,
      questionnaireStore => questionnaireStore.questionnaire.id
    );
  }

  // PAGES SETTERS
  public setPages(pages: TPageData[]) {
    this.accountQuestionnairePageStores.replace(
      pages.map(page => new AccountQuestionnairePageStore(this.accountId, page))
    );
  }

  public deletePage(pageId: number) {
    const index = this.accountQuestionnairePageStores.findIndex(
      page => page.page.id === pageId
    );
    if (index > -1) {
      this.accountQuestionnairePageStores.splice(index, 1);
    }
  }

  public upsertPage(pageData: TPageData) {
    const index = this.accountQuestionnairePageStores.findIndex(
      pageStore => pageStore.page.id === pageData.id
    );
    if (index > -1) {
      this.accountQuestionnairePageStores[index].upsertPage(pageData);
    } else {
      this.accountQuestionnairePageStores.push(
        new AccountQuestionnairePageStore(this.accountId, pageData)
      );
    }
  }

  // QUESTIONNAIRE SETTERS
  public setQuestionnaires(questionnaires: TPageToolQuestionnaire[]) {
    this.accountQuestionnairePageQuestionnaireStores.replace(
      questionnaires.map(
        questionnaire =>
          new AccountQuestionnairePageQuestionnaireStore(
            this.accountId,
            questionnaire
          )
      )
    );
  }

  public deleteQuestionnaire(questionnaireId: number) {
    const index = this.accountQuestionnairePageQuestionnaireStores.findIndex(
      questionnaireStore =>
        questionnaireStore.questionnaire.id === questionnaireId
    );
    if (index > -1) {
      this.accountQuestionnairePageQuestionnaireStores.splice(index, 1);
    }
  }

  public upsertQuestionnaire(questionnaireData: TPageToolQuestionnaire) {
    const index = this.accountQuestionnairePageQuestionnaireStores.findIndex(
      questionnaireStore =>
        questionnaireStore.questionnaire.id === questionnaireData.id
    );
    if (index > -1) {
      this.accountQuestionnairePageQuestionnaireStores[
        index
      ].upsertQuestionnaire(questionnaireData);
    } else {
      this.accountQuestionnairePageQuestionnaireStores.push(
        new AccountQuestionnairePageQuestionnaireStore(
          this.accountId,
          questionnaireData
        )
      );
    }
  }

  // PAGES ASYNC
  public async loadPages() {
    const pages = await pagesApi.fetchAll(
      this.accountId,
      PageToolTypeEnum.QUESTIONNAIRE
    );

    this.setPages(pages);
  }

  public async loadPage(id: number) {
    const page = await pagesApi.fetchOne(
      id,
      this.accountId,
      PageToolTypeEnum.QUESTIONNAIRE
    );

    this.upsertPage(page);
  }

  public async createPage(data: Partial<TCreateOrUpdatePageData>) {
    const newPage = await pagesApi.create(this.accountId, data);
    this.upsertPage(newPage);

    return newPage.id;
  }

  public async removePage(pageId: number) {
    await pagesApi.delete(this.accountId, pageId);
    this.deletePage(pageId);
  }

  public async checkIfHostIsAvailable(host: string) {
    await pagesApi.checkIfHostIsAvailable(host);
  }

  private async reorderPages(ids: number[]) {
    await pagesApi.reorderQuestionnairePages(this.accountId, ids);
    await this.loadPages();
  }

  public async handleDecreaseQuestionnairePriority(
    questionnairePageConfigId: number
  ) {
    let accountPagesTableDataSortedByPriorityAsc = [
      ...this.accountPagesTableDataSortedByPriorityDesc,
    ];
    if (!!accountPagesTableDataSortedByPriorityAsc.length) {
      const currentIndex = accountPagesTableDataSortedByPriorityAsc.findIndex(
        pageConfig =>
          pageConfig.pageQuestionnaireConfigId === questionnairePageConfigId
      );

      if (currentIndex < accountPagesTableDataSortedByPriorityAsc.length - 2) {
        const temp = accountPagesTableDataSortedByPriorityAsc[currentIndex];
        accountPagesTableDataSortedByPriorityAsc[currentIndex] =
          accountPagesTableDataSortedByPriorityAsc[currentIndex + 1];
        accountPagesTableDataSortedByPriorityAsc[currentIndex + 1] = temp;
        await this.reorderPages(
          accountPagesTableDataSortedByPriorityAsc.map(
            pageData => pageData.pageQuestionnaireConfigId
          )
        );
      }
    }
  }

  public async handleIncreaseQuestionnairePagePriority(
    questionnairePageConfigId: number
  ) {
    let accountPagesTableDataSortedByPriorityAsc = [
      ...this.accountPagesTableDataSortedByPriorityDesc,
    ];
    if (!!accountPagesTableDataSortedByPriorityAsc.length) {
      const currentIndex = accountPagesTableDataSortedByPriorityAsc.findIndex(
        pageConfig =>
          pageConfig.pageQuestionnaireConfigId === questionnairePageConfigId
      );

      if (currentIndex > 0) {
        const temp = accountPagesTableDataSortedByPriorityAsc[currentIndex];
        accountPagesTableDataSortedByPriorityAsc[currentIndex] =
          accountPagesTableDataSortedByPriorityAsc[currentIndex - 1];
        accountPagesTableDataSortedByPriorityAsc[currentIndex - 1] = temp;
        await this.reorderPages(
          accountPagesTableDataSortedByPriorityAsc.map(
            pageData => pageData.pageQuestionnaireConfigId
          )
        );
      }
    }
  }

  // QUESTIONNAIRE ASYNC
  public async loadQuestionnaire(questionnaireId: number) {
    const questionnaireData = await questionnairesApi.fetchOne(
      this.accountId,
      questionnaireId
    );
    this.upsertQuestionnaire(questionnaireData);
  }

  public async createQuestionnaire(
    questionnaireData: Partial<TPageToolQuestionnaire>
  ) {
    const newQuestionnaire = await questionnairesApi.create(
      this.accountId,
      questionnaireData
    );
    this.upsertQuestionnaire(newQuestionnaire);

    return newQuestionnaire.id;
  }

  public async removeQuestionnaire(questionnaireId: number) {
    await questionnairesApi.delete(this.accountId, questionnaireId);
    this.deleteQuestionnaire(questionnaireId);
  }

  public async duplicatePage(pageId: number) {
    const newPage = await pagesApi.duplicateQuestionnairePage(
      this.accountId,
      pageId
    );
    this.upsertPage(newPage);
  }
}
