import { Error, FormControlMeta, Loader } from "components";
import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Center,
  HStack,
  Radio,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AutocallerConfigurator } from "routes/dashboard/routes/tools/routes/autocaller/components/AutocallerBasicSettings/AutocallerConfigurator/AutocallerConfigurator";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";

interface IProps {
  officeStore: AccountOfficeStore;
  accountStore: UserAccountStore;
  isEnabled: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const OfficeAutocallerSettings: FC<IProps> = observer(
  ({ officeStore, accountStore, isEnabled, containerRef }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const officeAutocallerConfiguration =
      officeStore.officeAutocallerConfiguration;
    const accountAutocallerConfiguration =
      accountStore.accountAutocallerStore.accountAutocallerConfiguration;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            await officeStore.fetchOfficeAutocallerConfiguration(),
            await accountStore.accountAutocallerStore.fetchAccountAutocallerConfiguration(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [officeStore, accountStore]);

    const handleToggleAutocallerConfig = useCallback(
      async (leadSourceTypeId: number, isOn: boolean) => {
        if (isEnabled) {
          await officeStore.updateOfficeAutocallerConfiguration({
            leadSourceTypes: {
              [leadSourceTypeId]: isOn,
            },
          });
        }
      },
      [officeStore, isEnabled]
    );

    const handleToggleCustomAutocallerSettings = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        if (isEnabled) {
          await officeStore.updateOfficeAutocallerConfiguration({
            useOfficeLeadSourceTypes: !!event.target.value,
          });
        }
      },
      [officeStore, isEnabled]
    );

    return (
      <Box position={"relative"} minHeight={"100%"} overflow={"hidden"} pr={1}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && (
          <Box
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            textAlign={"center"}
          >
            <Loader />
          </Box>
        )}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS &&
          !!officeAutocallerConfiguration &&
          !!accountAutocallerConfiguration && (
            <SimpleGrid columns={2} spacing={4} pt={1}>
              <FormControlMeta
                label="Lead types & sources"
                description={
                  "Configure the lead types and sources you want Autocaller to work for. For each type, you also need to specify the phone number, and optionally, the key combination needed."
                }
              />
              <VStack spacing={8} align={"stretch"}>
                <VStack spacing={2} align={"stretch"}>
                  <FormControlMeta
                    label={"Select portals & sources configuration"}
                  />
                  <VStack spacing={4}>
                    <Radio
                      alignItems={"flex-start"}
                      onChange={handleToggleCustomAutocallerSettings}
                      isChecked={
                        !officeAutocallerConfiguration?.useOfficeLeadSourceTypes
                      }
                      isDisabled={!isEnabled}
                      value={""}
                    >
                      <FormControlMeta
                        label={"Account-level"}
                        description={
                          "Enables Autocaller, and allows you to specify a phone number and keypress combination for every portal configured at the account level."
                        }
                      />
                    </Radio>
                    <Radio
                      alignItems={"flex-start"}
                      onChange={handleToggleCustomAutocallerSettings}
                      isChecked={
                        !!officeAutocallerConfiguration?.useOfficeLeadSourceTypes
                      }
                      isDisabled={!isEnabled}
                      value={"true"}
                    >
                      <FormControlMeta
                        label={"Office-level"}
                        description={
                          "Enables Autocaller, and allows you to specify a phone number and keypress combination for every portal configured under the Integrations tab of this office."
                        }
                      />
                    </Radio>
                  </VStack>
                </VStack>
                <VStack spacing={2} align={"stretch"}>
                  <FormControlMeta
                    label={"Configure phone numbers and keypresses"}
                  />
                  <AutocallerConfigurator
                    isDisabled={
                      !officeAutocallerConfiguration.useOfficeLeadSourceTypes ||
                      !isEnabled
                    }
                    autocallerConfiguration={
                      officeAutocallerConfiguration.useOfficeLeadSourceTypes
                        ? officeAutocallerConfiguration
                        : accountAutocallerConfiguration
                    }
                    onConfigToggle={handleToggleAutocallerConfig}
                    officeStore={officeStore}
                    accountStore={accountStore}
                    containerRef={containerRef}
                    sourceSelectorLabel={
                      <HStack spacing={2}>
                        <Center
                          width={"24px"}
                          height={"24px"}
                          border={"1px solid"}
                          borderColor={"gray.500"}
                          color={"gray.500"}
                          rounded={"full"}
                        >
                          2
                        </Center>
                        <Box>Select sources</Box>
                      </HStack>
                    }
                  />
                </VStack>
              </VStack>
            </SimpleGrid>
          )}
      </Box>
    );
  }
);
