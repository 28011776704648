import {
  Box,
  Center,
  Circle,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { HEX_COLOR_REGEX } from "utils/regexs.utils";
import React, { FC, useCallback } from "react";
import { HexColorPicker } from "react-colorful";
import { debounce } from "lodash";

const FALLBACK_COLOR = "#ffffff";

interface IColorPickerPopupProps {
  onChange: (newColor: string) => void;
  value: string;
  isDisabled?: boolean;
}

export const ColorPickerPopup: FC<IColorPickerPopupProps> = ({
  onChange,
  value,
  isDisabled,
}) => {
  const delayedOnChange = debounce((newValue: string) => {
    const color = !HEX_COLOR_REGEX.test(newValue) ? FALLBACK_COLOR : newValue;
    onChange(color);
  }, 200);

  const onPopoverOpen = useCallback(() => {
    if (!value || !HEX_COLOR_REGEX.test(value)) {
      onChange(FALLBACK_COLOR);
    }
  }, [value, onChange]);

  return (
    <Popover
      closeOnBlur={true}
      closeOnEsc={true}
      returnFocusOnClose={false}
      isLazy={true}
      computePositionOnMount={false}
      placement="top-end"
      lazyBehavior={"unmount"}
      openDelay={0}
      onOpen={onPopoverOpen}
    >
      <PopoverTrigger>
        <Center
          width={"50px"}
          height={"100%"}
          cursor={"pointer"}
          pointerEvents={isDisabled ? "none" : undefined}
        >
          {value && HEX_COLOR_REGEX.test(value.toString()) ? (
            <Circle
              size={"20px"}
              border={"1px solid"}
              borderColor={"gray.200"}
              bg={value}
            />
          ) : (
            <Box color={"red.500"}>!</Box>
          )}
        </Center>
      </PopoverTrigger>
      <PopoverContent width={"fit-content"} border={0}>
        <HexColorPicker color={value} onChange={delayedOnChange} />
      </PopoverContent>
    </Popover>
  );
};
