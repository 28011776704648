import { observer } from "mobx-react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import React, { FC } from "react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { AccountDevelopersWebhooksStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersWebhooks/AccountDevelopersWebhooks.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { developersConfigWebhookValidation } from "utils/validation-schemas/developers-config-webhook.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  name: string;
  webhookUrl: string;
};

const initialValues: TFieldValues = {
  name: "",
  webhookUrl: "",
};

interface IProps {
  accountDevelopersWebhooksStore: AccountDevelopersWebhooksStore;
  closePrompt: () => void;
}

export const DevelopersConfigWebhookFormPrompt: FC<IProps> = observer(
  ({ accountDevelopersWebhooksStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(developersConfigWebhookValidation),
    });

    const onSubmit = async (data: TFieldValues) => {
      try {
        await accountDevelopersWebhooksStore.createWebhook(data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={"Webhook created."} />,
        });
        closePrompt();
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    return (
      <ActionPromptContainer
        header={"Create webhook"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Enter webhook name",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"webhookUrl"}
                  control={control}
                  label={"Webhook URL"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "http://",
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Create
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
