import { BaseApi } from "./base.api";
import {
  TGetPropertiesResponse,
  TPropertyDetails,
} from "../types/property.type";

export class PropertiesApi extends BaseApi {
  async getProperties(
    accountId: number,
    searchQuery: string,
    pageSize: number,
    page: number
  ): Promise<TGetPropertiesResponse> {
    return await this.get<TGetPropertiesResponse>(
      `/accounts/${accountId}/properties`,
      {
        params: {
          searchQuery: searchQuery || undefined,
          limit: pageSize,
          offset: pageSize * page,
        },
      }
    );
  }

  async getPropertyWithDetails(
    accountId: number,
    propertyId: number
  ): Promise<TPropertyDetails> {
    return await this.get<TPropertyDetails>(
      `/accounts/${accountId}/properties/${propertyId}`
    );
  }
}
