import { Box, HStack } from "@chakra-ui/react";
import React, { FC, ReactNode, useMemo } from "react";
import moment from "moment/moment";

interface ILeadAttributionItemProps {
  content: ReactNode;
  icon: ReactNode;
  description: string;
  createdAt: string;
  title: string;
}
export const LeadAttributionItem: FC<ILeadAttributionItemProps> = ({
  icon,
  content,
  description,
  title,
  createdAt,
}) => {
  const fromNowDate = useMemo(() => {
    const leadCreatedAtMoment = moment(createdAt);
    return leadCreatedAtMoment.fromNow();
  }, [createdAt]);

  const formattedCreatedAt = useMemo(() => {
    return moment(createdAt).format("DD MMM YYYY");
  }, [createdAt]);
  return (
    <Box
      p={4}
      mt={2}
      border={"1px"}
      borderRadius={"lg"}
      borderColor={"leadpro.200"}
    >
      <HStack gap={25}>
        {icon}
        <Box>
          <Box fontSize={"sm"}>{formattedCreatedAt}</Box>
          <Box fontSize={"xs"}>{fromNowDate}</Box>
        </Box>

        <Box>
          <Box fontSize={"md"}>{title}</Box>
          <Box fontSize={"sm"}>{description}</Box>
        </Box>
      </HStack>
      <Box>{content}</Box>
    </Box>
  );
};
