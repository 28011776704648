import {
  ApiMessageStack,
  FormControlV2,
  FormControlMeta,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  PanelTitle,
  FormControlLabel,
} from "components";
import React, { FC, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import * as Yup from "yup";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TUserAccountData } from "types/user-account.type";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { REQUIRED_FIELD } from "constants/validator-messages";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

type TFieldValues = {
  outOfAreaChildLeadsRoutingEnabled: TUserAccountData["outOfAreaChildLeadsRoutingEnabled"];
  franchiseId: TUserAccountData["franchiseId"];
  websiteProviderId: TUserAccountData["websiteProviderId"];
};

const validationSchema = Yup.object().shape({
  outOfAreaChildLeadsRoutingEnabled: Yup.boolean().required(REQUIRED_FIELD),
  franchiseId: Yup.number().nullable(true),
  websiteProviderId: Yup.number().nullable(true),
});

interface IProps {
  accountStore: UserAccountStore;
}

export const AdvancedSettings: FC<IProps> = observer(({ accountStore }) => {
  const toast = useToast();
  const { uiStore } = useAppStore();
  const accountData = accountStore.account;
  const franchisesMap = uiStore.franchisesMap;
  const websiteProviderOptions = uiStore.websiteProviderOptionsArray;

  const initialValues: TFieldValues = useMemo(
    () => ({
      outOfAreaChildLeadsRoutingEnabled:
        accountData.outOfAreaChildLeadsRoutingEnabled,
      franchiseId: accountData.franchiseId,
      websiteProviderId: accountData.websiteProviderId,
    }),
    [accountData]
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async (values: TFieldValues) => {
      try {
        await accountStore.update({ ...values });
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={`Account updated!`} />,
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [accountStore, toast]
  );

  return (
    <Panel overflow={"visible"}>
      <PanelHeader>
        <PanelTitle>Advanced Settings</PanelTitle>
      </PanelHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PanelBody>
          <VStack spacing={10} align={"stretch"} divider={<Divider />}>
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label={"Enable Out Of Area Lead Routing"}
                description={
                  "If we identify additional business opportunities from the leads in your account (e.g. applicants having a property to sell or let) that are outside the original office's coverage area, we route them to the nearest office on the account. If this feature is disabled, we won't route any leads, but those can still be sent to other offices manually."
                }
              />
              <Flex alignItems={"center"} justifyContent={"flex-end"}>
                <FormControlV2<TFieldValues>
                  name={"outOfAreaChildLeadsRoutingEnabled"}
                  control={control}
                  type={FormControlsTypeEnum.SWITCH}
                />
              </Flex>
            </SimpleGrid>
            {accountData.franchiseId && (
              <SimpleGrid columns={2} spacing={4}>
                <FormControlMeta
                  label="Franchise"
                  description={
                    "Presented is your associated franchise, this will be considered during calculating your system usage for billing. If this appears incorrect, contact your account manager and request this to be changed to the correct franchise."
                  }
                />
                <Flex alignItems={"center"} justifyContent={"flex-end"}>
                  <FormControlLabel>
                    {franchisesMap[accountData.franchiseId]?.label ||
                      "No Franchise"}
                  </FormControlLabel>
                </Flex>
              </SimpleGrid>
            )}
            <SimpleGrid columns={2} spacing={4}>
              <FormControlMeta
                label="Website provider"
                description={
                  "We work with various website providers, if one of these agencies provides your website, please select one."
                }
              />
              <FormControlV2<TFieldValues>
                name={"websiteProviderId"}
                control={control}
                type={FormControlsTypeEnum.SINGLE_SELECT}
                additionalProps={{
                  options: websiteProviderOptions,
                  placeholder: "Account website provider",
                  clearable: true,
                }}
              />
            </SimpleGrid>
          </VStack>
        </PanelBody>
        <PanelFooter>
          <Box ml="auto">
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Update Advanced Settings
            </Button>
          </Box>
        </PanelFooter>
      </form>
    </Panel>
  );
});
