import React from "react";
import { Box } from "@chakra-ui/react";

interface IStandardDrawerHeaderProps {
  children: React.ReactNode;
}

export const StandardDrawerHeader: React.FC<IStandardDrawerHeaderProps> = ({
  children,
}) => {
  return (
    <Box
      display={"flex"}
      bg={"leadpro.50"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      p={2}
      pl={5}
    >
      {children}
    </Box>
  );
};
