import React, { FC, useCallback, useEffect, useRef } from "react";
import { Box, useToast, ToastId, UseToastOptions } from "@chakra-ui/react";
import { LEADS_ROUTE, LEAD_SUBROUTE_UNREAD } from "constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useSound from "use-sound";
import notificationSfx from "sounds/notification.mp3";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { observer } from "mobx-react";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";

interface IProps {
  accountLeadsStore: AccountLeadsStore;
}
export const NewLeadsNotification: FC<IProps> = observer(
  ({ accountLeadsStore }) => {
    let { leadSubroute } = useParams<{ leadSubroute: string }>();
    const history = useHistory();
    const [play] = useSound(notificationSfx, { volume: 0.1 });
    const toast = useToast();
    const toastIdRef = useRef<ToastId>("");

    const {
      clearLeadNotifications,
      leadNotificationsCount,
    } = accountLeadsStore;

    const onAlertClick = useCallback(() => {
      history.push(LEADS_ROUTE);
    }, [history]);

    useEffect(() => {
      if (leadSubroute === LEAD_SUBROUTE_UNREAD) {
        clearLeadNotifications();
      }
    }, [clearLeadNotifications, leadSubroute]);

    useEffect(() => {
      try {
        if (!!leadNotificationsCount) {
          const notificationOptions: UseToastOptions = {
            title: `New lead${leadNotificationsCount > 1 ? "s" : ""}!`,
            position: "top-right",
            status: AlertStatusEnum.INFO,
            duration: null,
            isClosable: true,
            description: (
              <Box onClick={onAlertClick} cursor={"pointer"}>
                {`You have ${leadNotificationsCount} new lead${
                  leadNotificationsCount > 1 ? "s" : ""
                }! `}
                <Box as={"span"} textDecoration={"underline"}>
                  {`Click here to see ${
                    leadNotificationsCount > 1 ? "them" : "it"
                  }.`}
                </Box>
              </Box>
            ),
            onCloseComplete: () => {
              toastIdRef.current = "";
            },
          };

          play();
          if (!!toastIdRef.current) {
            toast.update(toastIdRef.current, notificationOptions);
          } else {
            toastIdRef.current = toast(notificationOptions) || "";
          }
        } else {
          if (!!toastIdRef.current) {
            toast.close(toastIdRef.current);
          }
        }
      } catch (e) {}
    }, [toast, onAlertClick, play, leadNotificationsCount]);

    useEffect(() => {
      return function cleanup() {
        clearLeadNotifications();
        toast.close(toastIdRef.current);
      };
    }, [clearLeadNotifications, toast]);
    return null;
  }
);
