import React from "react";
import { useClipboard } from "@chakra-ui/react";
import { StandardIconButton, Tooltip } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { TTypeformForm } from "types/account-typeform-forms.type";
import { DefaultRowCell } from "./DefaultRowCell";

export const PreQualificationQuestionnaireCopyLinkCell = ({
  row,
}: {
  row: TTypeformForm;
}) => {
  const { onCopy, hasCopied } = useClipboard(row.typeformLink);
  return (
    <DefaultRowCell
      justifyContent={"flex-end"}
      onClick={event => event.stopPropagation()}
    >
      <Tooltip label="Copy" placement={"right"}>
        <StandardIconButton
          onClick={onCopy}
          aria-label={"copy-code"}
          colorScheme={hasCopied ? "green" : "blue"}
          icon={<FontAwesomeIcon icon={faCopy} />}
          title={"Copy"}
          height={6}
          width={6}
        />
      </Tooltip>
    </DefaultRowCell>
  );
};
