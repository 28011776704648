import {
  ApiMessageStack,
  EmailLink,
  FormControlV2,
  NavigationPrompt,
} from "components";
import { LOGIN_ROUTE, RESET_PASSWORD_ROUTE } from "constants/routes";
import { REQUIRED_FIELD } from "constants/validator-messages";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Box, Heading, Button, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { DEFAULT_ERROR_TOAST_OPTIONS } from "constants/default-toast-options";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useRecaptchaV2 } from "utils/react-hooks/useRecaptchaV2.hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  password: string;
};

const initialValues: TFieldValues = {
  password: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(REQUIRED_FIELD),
});

export const PasswordSetForm = observer(() => {
  const [status, setStatus] = useState<ApiRequestStatusEnum | null>(null);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const toast = useToast();
  const { reCaptchaV2 } = useRecaptchaV2();
  const {
    authStore: {
      leadProAuthStore: { setPassword, verifyPasswordResetToken },
    },
  } = useAppStore();
  const location = useLocation();
  const token = useMemo(() => {
    const t = queryString.parse(location.search)?.t as string;
    return t === "undefined" ? "" : t;
  }, [location]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    async ({ password }: TFieldValues) => {
      try {
        if (!!reCaptchaV2) {
          const reCaptchaToken = (await reCaptchaV2.executeAsync()) || "";
          reCaptchaV2.reset();

          await setPassword(password, token, reCaptchaToken);
          setStatus(ApiRequestStatusEnum.SUCCESS);
        }
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    },
    [setPassword, token, toast, reCaptchaV2]
  );

  const renderMissingToken = useCallback(() => {
    return (
      <Box>
        <Box mb={4}>
          No user with your email is currently registered on the LeadPro
          dashboard.
        </Box>
        <Box>
          Please contact your office manager to be added, or email{" "}
          <EmailLink email={"contact@lead.pro"} /> to request dashboard access
          to your account.
        </Box>
      </Box>
    );
  }, []);

  const renderInvalidToken = useCallback(() => {
    return (
      <NavigationPrompt
        text={"This request has expired."}
        ctaText={"Please try again."}
        ctaNavigateTo={RESET_PASSWORD_ROUTE}
      />
    );
  }, []);

  const renderValidToken = useCallback(() => {
    if (status !== ApiRequestStatusEnum.SUCCESS) {
      return (
        <>
          <Box mb={3}>
            <FormControlV2<TFieldValues>
              name={"password"}
              control={control}
              type={FormControlsTypeEnum.PASSWORD}
              additionalProps={{
                placeholder: "New password",
              }}
            />
          </Box>
          <Button
            type={"submit"}
            width={"full"}
            colorScheme={"blue"}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Set password
          </Button>
        </>
      );
    }

    return (
      <NavigationPrompt
        text={"You have reset your password"}
        ctaText={"Please login here."}
        ctaNavigateTo={LOGIN_ROUTE}
      />
    );
  }, [control, status, isSubmitting]);

  useEffect(() => {
    const checkToken = async () => {
      try {
        await verifyPasswordResetToken(token);
        setTokenIsValid(true);
      } catch (e) {
        setTokenIsValid(false);
      }
    };

    if (token) {
      checkToken();
    }
  }, [verifyPasswordResetToken, location, token]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="2xl" textAlign="center" lineHeight="none" mb={6}>
        Set your new password
      </Heading>
      {!!token
        ? tokenIsValid
          ? renderValidToken()
          : renderInvalidToken()
        : renderMissingToken()}
    </form>
  );
});
