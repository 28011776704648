import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { StandardIconButton } from "components";

interface IProps {
  handleRemove: () => void;
  noun: string;
}

export const FormToolPageSettingsConfiguratorDeletePopover: FC<IProps> = observer(
  ({ handleRemove, noun }) => {
    return (
      <Popover closeOnBlur={false} isLazy={true}>
        <PopoverTrigger>
          <StandardIconButton
            aria-label={"remove-filter-group"}
            icon={<FontAwesomeIcon icon={faClose} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader fontSize={"md"} fontWeight={500}>
            Confirm deletion
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box fontSize={"sm"} fontWeight={"normal"}>
              {`Are you sure you want to delete this ${noun}?`}
            </Box>
          </PopoverBody>
          <PopoverFooter border={"none"}>
            <ButtonGroup
              size="sm"
              spacing={2}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button colorScheme={"red"} onClick={handleRemove}>
                Delete
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    );
  }
);
