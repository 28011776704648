import { observer } from "mobx-react";
import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import * as Yup from "yup";
import { TAccountIntegrationData } from "types/account-integration.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { useForm } from "react-hook-form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { yupResolver } from "@hookform/resolvers/yup";
import { omit } from "lodash";

interface IntegrationEcosystemConfiguratorProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  integrationType: AccountIntegrationTypeEnum;
}

type TFieldValues = {
  instanceId: string | null;
};

const validationSchema = Yup.object().shape({
  instanceId: Yup.string().nullable(),
});

const excludeKeys: string[] = [
  "createdAt",
  "updatedAt",
  "deletedAt",
  "id",
  "multiReapitIntegration",
  "reapitCustomerId",
];

export const getMutableIntegrationConfigData = <T extends {}>(
  accountIntegration: TAccountIntegrationData<T>
) => {
  return omit(accountIntegration.integrationConfig, excludeKeys);
};

export const IntegrationEcosystemConfigurator = observer(
  ({
    accountIntegrationsStore,
    integrationType,
  }: IntegrationEcosystemConfiguratorProps) => {
    const toast = useToast();
    const accountIntegration =
      accountIntegrationsStore.accountIntegrationsMapByType[integrationType];

    const initialValues = useMemo(() => {
      return {
        instanceId: accountIntegration.instanceId,
      };
    }, [accountIntegration]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const handleUpdateIntegrationConfig = useCallback(
      async ({ instanceId }: TFieldValues) => {
        const integrationConfig = getMutableIntegrationConfigData(
          accountIntegration
        );
        try {
          await accountIntegrationsStore.updateAccountIntegration(
            accountIntegration.id,
            {
              type: accountIntegration.type,
              active: accountIntegration.active,
              instanceId: instanceId || null,
              integrationConfig: integrationConfig,
            }
          );
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"integration configuration updated."}
              />
            ),
          });
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [accountIntegration, accountIntegrationsStore, toast]
    );
    return (
      <VStack align={"left"} spacing={3}>
        <Box fontSize={"xl"} fontWeight={"bold"} color={"leadpro.700"}>
          Lead attribution
        </Box>
        <Box fontSize={"sm"} color={"leadpro.700"} fontWeight={"bold"}>
          Nurtur Data Warehouse instance
        </Box>
        <Box w={"100%"}>
          <form onSubmit={handleSubmit(handleUpdateIntegrationConfig)}>
            <Box mb={5}>
              <FormControlV2<TFieldValues>
                label={
                  "This is the identifier used to connect and acquire your CRM data to establish lead attribution from"
                }
                type={FormControlsTypeEnum.TEXT}
                name={"instanceId"}
                control={control}
              />
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button
                type={"submit"}
                colorScheme={"blue"}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </VStack>
    );
  }
);
