import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiMessageStack, FormControlV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { SETTINGS_INTEGRATIONS_ROUTE } from "constants/routes";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AccountIntegrationsStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrations.store";
import {
  smeIntegrationConnectValidationSchema,
  smeIntegrationInitialValues,
  TSmeIntegrationConfigFieldValues,
} from "utils/validation-schemas/sme-integration-validation";

interface IProps {
  accountIntegrationsStore: AccountIntegrationsStore;
  closePrompt: () => void;
}

export const ConnectSmeForm: FC<IProps> = observer(
  ({ accountIntegrationsStore, closePrompt }) => {
    const toast = useToast();
    const history = useHistory();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TSmeIntegrationConfigFieldValues>({
      defaultValues: smeIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(smeIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TSmeIntegrationConfigFieldValues) => {
        try {
          await accountIntegrationsStore.createAccountIntegration<
            TSmeIntegrationConfigFieldValues
          >({
            type: AccountIntegrationTypeEnum.SME,
            active: true,
            integrationConfig,
          });

          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"SME Professional successfully connected."}
              />
            ),
          });
          closePrompt();
          history.push(
            `${SETTINGS_INTEGRATIONS_ROUTE}/${AccountIntegrationTypeEnum.SME}`
          );
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
          });
        }
      },
      [accountIntegrationsStore, closePrompt, toast, history]
    );

    return (
      <Box pt={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={5}>
            <FormControlV2<TSmeIntegrationConfigFieldValues>
              label={"SME Key"}
              type={FormControlsTypeEnum.TEXT}
              name={"smeApiKey"}
              control={control}
            />
          </Box>
          <Divider mb={4} />
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
          >
            <Button
              type={"submit"}
              colorScheme={"blue"}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Connect
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
