import { ShowForRoles } from "routes/dashboard/components";
import {
  SETTINGS_ACCOUNT_ROUTE,
  SETTINGS_BILLING_ROUTE,
  SETTINGS_DEVELOPERS_ROUTE,
  SETTINGS_INTEGRATIONS_ROUTE,
  SETTINGS_OFFICES_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_USERS_ROUTE,
} from "constants/routes";
import React, { FC, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Users } from "./routes/users";
import { UserRoleEnum } from "enums/user-role.enum";
import { AccountSettings } from "./routes/account-settings/AccountSettings";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountIntegrations } from "./routes/account-integrations/AccountIntegrations";
import { RightSideLayout } from "routes/dashboard/components/Layouts/RightSideLayout";
import { AccountBilling } from "./routes/account-billing/AccountBilling";
import { DevelopersConfig } from "./routes/developers-config/DevelopersConfig";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { Offices } from "./routes/offices/Offices";

interface IProps {
  accountStore: UserAccountStore;
}
export const Settings: FC<IProps> = observer(({ accountStore }) => {
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const { uiStore } = useAppStore();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      try {
        await Promise.all([
          accountStore.accountIntegrationsStore.accountIntegrationReapitStore.fetchReapitIntegrationConfigSyncData(),
          accountStore.fetchDetails(),
          accountStore.fetchAccountIVTConfig(),
          accountStore.fetchPortals(),
          accountStore.accountLeadSourcesStore.fetchAccountLeadSources(),
          uiStore.fetchFranchiseOptions(),
          uiStore.fetchWebsiteProviderOptions(),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [uiStore, accountStore]);

  return (
    <RightSideLayout loadingStatus={loadingStatus}>
      <Switch>
        <Route path={SETTINGS_ROUTE} exact>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <Redirect to={SETTINGS_ACCOUNT_ROUTE} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_ACCOUNT_ROUTE} exact>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <AccountSettings accountStore={accountStore} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_BILLING_ROUTE} exact>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <AccountBilling accountStore={accountStore} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_OFFICES_ROUTE}>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <Offices accountStore={accountStore} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_USERS_ROUTE} exact>
          <ShowForRoles roles={[UserRoleEnum.ADMIN, UserRoleEnum.LEADER]}>
            <Users accountStore={accountStore} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_INTEGRATIONS_ROUTE}>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <AccountIntegrations accountStore={accountStore} />
          </ShowForRoles>
        </Route>
        <Route path={SETTINGS_DEVELOPERS_ROUTE}>
          <ShowForRoles roles={[UserRoleEnum.ADMIN]}>
            <DevelopersConfig accountStore={accountStore} />
          </ShowForRoles>
        </Route>
      </Switch>
    </RightSideLayout>
  );
});
