import React from "react";
import { ArticleListItem } from "./ArticleListItem";
import { GUIDES_ROUTE } from "constants/routes";
import { useHistory } from "react-router-dom";
import { TContentfulArticleData } from "types/contentful-article.type";
import { observer } from "mobx-react";
import { Box, Center } from "@chakra-ui/react";
import { EmptyScreen, Loader } from "components";

interface IProps {
  isLoading: boolean;
  articleList: TContentfulArticleData[];
}

export const ArticleList: React.FC<IProps> = observer(
  ({ isLoading, articleList }) => {
    const history = useHistory();

    const handleArticleClick = (articleSlug: string) => {
      history.push(`${GUIDES_ROUTE}/${articleSlug}`);
    };

    if (isLoading)
      return (
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"500px"}
          textAlign={"center"}
        >
          <Loader />
        </Box>
      );

    if (articleList.length) {
      return (
        <>
          {articleList.map((article: TContentfulArticleData, index: number) => {
            return (
              <ArticleListItem
                key={article.fields.slug}
                article={article}
                index={index + 1}
                onClick={handleArticleClick}
              />
            );
          })}
        </>
      );
    }

    return (
      <Center height={"500px"}>
        <EmptyScreen label={"No articles."} />
      </Center>
    );
  }
);
