import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  getScoreColor,
  IOfficeLeaderboardStats,
} from "utils/office-leaderboard.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/pro-solid-svg-icons";

interface IProps {
  officeStats: IOfficeLeaderboardStats;
  index: number;
  onClick: (officeStats: IOfficeLeaderboardStats) => void;
}

export const OfficesLeagueListItem: React.FC<IProps> = ({
  officeStats,
  index,
  onClick,
}) => {
  return (
    <Box
      onClick={() => onClick(officeStats)}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={5}
      shadow={"md"}
      border={"1px solid"}
      borderColor={"gray.200"}
      rounded={"md"}
      cursor={"pointer"}
      _hover={{
        shadow: "sm",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box width={"40px"}>
          {index === 1 ? (
            <Text color={"yellow.400"}>
              <FontAwesomeIcon icon={faCrown} fontSize={20} />
            </Text>
          ) : (
            <Text fontWeight={600} color={"gray.400"}>
              {index}.
            </Text>
          )}
        </Box>
        <Text fontSize={"20px"} fontWeight={600}>
          {officeStats.office.name}
        </Text>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
          alignItems={"center"}
          pl={5}
          pr={5}
          borderRight={"1px solid"}
          borderColor={"gray.200"}
          mr={5}
        >
          <Text fontSize={"12px"} fontWeight={600} color={"gray.400"}>
            LEADS
          </Text>
          <Text fontSize={"20px"} fontWeight={600}>
            {officeStats.totalLeadsSum}
          </Text>
        </Box>
        <Box
          bg={getScoreColor(officeStats.overallScore)}
          rounded={"full"}
          width={"36px"}
          height={"36px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text color={"#fff"} fontWeight={600}>
            {officeStats.overallScore}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
