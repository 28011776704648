import {
  TLeadProEventData,
  TLeadUpdatedEventData,
  TLeadWorkflowUpdatedEventData,
} from "types/leadpro-event.type";
import { LeadEventTypeEnum } from "enums/lead-event-type.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faFilePlus,
  faCircleQuestion,
  faNoteSticky,
  faSitemap,
} from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@chakra-ui/react";
import { NA } from "components";
import { TUserData } from "types/user-data.type";
import { getUserFullName } from "utils/account-user.utils";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import {
  LeadUpdatedEventContent,
  LeadWorkflowUpdatedEventContent,
} from "routes/dashboard/routes/leads/components/LeadDrawer/LeadActivity/LeadActivityEvent";
import { LeadCreatedEventContent } from "./LeadActivityEvent/LeadCreatedEventContent";
import { TContactNote } from "types/lead.type";
import { LeadActivityNoteContent } from "./LeadActivityNote";
import { ReactNode } from "react";
import { TLeadActivity } from "types/account-lead-activity.type";

const ICON_SIZE = 20;

type TLeadActivityConfig = {
  icon: ReactNode;
  iconColor: string;
  content: ReactNode;
  author: ReactNode;
  createdAt: string;
};

export const getLeadActivityEventIconConfig = (event: TLeadProEventData) => {
  switch (event.eventType) {
    case LeadEventTypeEnum.LEAD_CREATED:
      return {
        icon: <FontAwesomeIcon icon={faFilePlus} fontSize={ICON_SIZE} />,
        iconColor: "green.500",
      };
    case LeadEventTypeEnum.LEAD_UPDATED:
      return {
        icon: <FontAwesomeIcon icon={faPen} fontSize={ICON_SIZE} />,
        iconColor: "green.500",
      };
    case LeadEventTypeEnum.LEAD_WORKFLOW_STATUS_UPDATED:
      return {
        icon: <FontAwesomeIcon icon={faSitemap} fontSize={ICON_SIZE} />,
        iconColor: "purple.500",
      };
    default:
      return {
        icon: <FontAwesomeIcon icon={faCircleQuestion} fontSize={ICON_SIZE} />,
        iconColor: "gray.500",
      };
  }
};

export const getLeadActivityEventAuthor = (event: TLeadProEventData) => {
  switch (event.eventType) {
    case LeadEventTypeEnum.LEAD_CREATED:
    case LeadEventTypeEnum.LEAD_UPDATED:
      const createdBy = event.user;
      return getLeadActivityAuthor(createdBy, "SYSTEM");
    case LeadEventTypeEnum.LEAD_WORKFLOW_STATUS_UPDATED:
      return <Box color="gray.400">WORKFLOW</Box>;
    default:
      return <NA />;
  }
};

export const getLeadActivityAuthor = (
  createdBy: TUserData | null,
  fallback: string
) => {
  if (!!createdBy) {
    return getUserFullName(
      createdBy.firstName,
      createdBy.lastName,
      createdBy.email
    );
  } else {
    return <Box color="gray.400">{fallback}</Box>;
  }
};

export const getLeadActivityEventContent = (
  event: TLeadProEventData,
  leadStore: AccountLeadStore,
  accountStore: UserAccountStore
) => {
  switch (event.eventType) {
    case LeadEventTypeEnum.LEAD_CREATED:
      return <LeadCreatedEventContent />;
    case LeadEventTypeEnum.LEAD_UPDATED:
      return (
        <LeadUpdatedEventContent
          event={event as TLeadUpdatedEventData}
          leadStore={leadStore}
          accountStore={accountStore}
        />
      );
    case LeadEventTypeEnum.LEAD_WORKFLOW_STATUS_UPDATED:
      return (
        <LeadWorkflowUpdatedEventContent
          accountStore={accountStore}
          event={event as TLeadWorkflowUpdatedEventData}
        />
      );
  }
};

export const getLeadActivityNoteIconConfig = () => {
  return {
    icon: <FontAwesomeIcon icon={faNoteSticky} fontSize={ICON_SIZE} />,
    iconColor: "blue.500",
  };
};

export const transformActivityToConfig = (
  leadActivity: TLeadActivity[],
  leadStore: AccountLeadStore,
  accountStore: UserAccountStore
): TLeadActivityConfig[] => {
  return leadActivity.reduce((configs: TLeadActivityConfig[], activity) => {
    if (isContactNoteActivity(activity)) {
      const note = activity as TContactNote;
      configs.push({
        ...getLeadActivityNoteIconConfig(),
        author: getLeadActivityAuthor(note.createdBy, "SYSTEM"),
        createdAt: note.createdAt,
        content: <LeadActivityNoteContent note={note} />,
      });
    } else if (isLeadProEventActivity(activity)) {
      const event = activity as TLeadProEventData;
      configs.push({
        ...getLeadActivityEventIconConfig(event),
        author: getLeadActivityEventAuthor(event),
        createdAt: event.createdAt,
        content: getLeadActivityEventContent(event, leadStore, accountStore),
      });
    }
    return configs;
  }, []);
};

export const isContactNoteActivity = (
  activity: TLeadActivity
): activity is TContactNote => {
  return "content" in activity;
};

export const isLeadProEventActivity = (
  activity: TLeadActivity
): activity is TLeadProEventData => {
  return "eventType" in activity;
};
