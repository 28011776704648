import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "360px",
  md: "720px",
  lg: "1000px",
  xl: "1400px",
});

const Button = {
  baseStyle: {
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
  },
  variants: {
    ghost: {
      bg: "transparent",
      color: "leadpro.500",
      _hover: {
        bg: "leadpro.50",
      },
      _active: {
        bg: "leadpro.200",
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
};
const Tabs = {
  parts: ["tab"],
  baseStyle: {
    tab: {
      _focus: {
        outline: "none",
        boxShadow: "none",
      },
    },
  },
};

const Menu = {
  parts: ["item", "list"],
  baseStyle: {
    item: {
      _hover: {
        background: "blue.50",
        outline: "none",
        boxShadow: "none",
      },
      _focus: { outline: "none", boxShadow: "none", background: "blue.100" },
    },
    list: {
      border: "1px solid",
      borderColor: "leadpro.200",
    },
  },
};

const Switch = {
  parts: ["thumb", "track"],
  baseStyle: {
    track: {
      bg: "leadpro.200",
      _checked: {
        bg: "leadpro.75",
      },
    },
    thumb: {
      bg: "leadpro.400",
      _checked: {
        bg: "leadpro.800",
      },
    },
  },
};

const Checkbox = {
  baseStyle: {
    control: {
      borderColor: "gray.300",
    },
  },
  defaultProps: {
    colorScheme: "teal",
  },
};

const Radio = {
  defaultProps: {
    colorScheme: "teal",
  },
};

const Accordion = {
  baseStyle: {
    button: {
      _focus: { outline: "none", boxShadow: "none" },
    },
  },
};

const Tag = {
  baseStyle: {
    container: {
      flexShrink: 0,
    },
  },
};

const Popover = {
  baseStyle: {
    content: {
      boxShadow: "lg",
      _focus: { outline: "none", boxShadow: "lg" },
    },
  },
  parts: ["content"],
};

export const defaultTheme = extendTheme({
  breakpoints: breakpoints,
  styles: {
    global: {
      body: {
        fontWeight: 500,
        color: "leadpro.700",
      },
    },
  },
  fonts: {
    body: "Roboto, sans-serif",
    heading: "Roboto, sans-serif",
    mono: "Menlo, monospace",
  },
  colors: {
    red: {
      50: "#FEF2F2",
      100: "#FEE2E2",
      200: "#FECACA",
      300: "#FCA5A5",
      400: "#F87171",
      500: "#EF4444",
      600: "#DC2626",
      700: "#B91C1C",
      800: "#991B1B",
      900: "#7F1D1D",
    },
    purple: {
      50: "#FAF5FF",
      100: "#F3E8FF",
      200: "#E9D5FF",
      300: "#D8B4FE",
      400: "#C084FC",
      500: "#A855F7",
      600: "#9333EA",
      700: "#7E22CE",
      800: "#6B21A8",
      900: "#581C87",
    },
    teal: {
      50: "#F0FDFA",
      100: "#CCFBF1",
      200: "#99F6E4",
      300: "#5EEAD4",
      400: "#2DD4BF",
      500: "#14B8A6",
      600: "#0D9488",
      700: "#0F766E",
      800: "#115E59",
      900: "#134E4A",
    },
    emerald: {
      50: "#ECFDF5",
      100: "#D1FAE5",
      200: "#A7F3D0",
      300: "#6EE7B7",
      400: "#34D399",
      500: "#10B981",
      600: "#059669",
      700: "#047857",
      800: "#065F46",
      900: "#064E3B",
    },
    blue: {
      50: "#EFF6FF",
      100: "#DBEAFE",
      200: "#BFDBFE",
      300: "#93C5FD",
      400: "#60A5FA",
      500: "#3B82F6",
      600: "#2563EB",
      700: "#1D4ED8",
      800: "#1E40AF",
      900: "#1E3A8A",
    },
    gray: {
      50: "#F7F7F7",
      100: "#E1E1E1",
      200: "#CFCFCF",
      300: "#B1B1B1",
      400: "#9E9E9E",
      500: "#7E7E7E",
      600: "#626262",
      700: "#515151",
      800: "#3B3B3B",
      900: "#222222",
    },
    orange: {
      50: "#FFFAF0",
      100: "#FEEBC8",
      200: "#FBD38D",
      300: "#F6AD55",
      400: "#ED8936",
      500: "#DD6B20",
      600: "#C05621",
      700: "#9C4221",
      800: "#7B341E",
      900: "#652B19",
    },
    yellow: {
      50: "#FFFFF0",
      100: "#FEFCBF",
      200: "#FAF089",
      300: "#F6E05E",
      400: "#ECC94B",
      500: "#D69E2E",
      600: "#B7791F",
      700: "#975A16",
      800: "#744210",
      900: "#5F370E",
    },
    cyan: {
      50: "#EDFDFD",
      100: "#C4F1F9",
      200: "#9DECF9",
      300: "#76E4F7",
      400: "#0BC5EA",
      500: "#00B5D8",
      600: "#00A3C4",
      700: "#0987A0",
      800: "#086F83",
      900: "#065666",
    },
    pink: {
      50: "#FFF5F7",
      100: "#FED7E2",
      200: "#FBB6CE",
      300: "#F687B3",
      400: "#ED64A6",
      500: "#D53F8C",
      600: "#B83280",
      700: "#97266D",
      800: "#702459",
      900: "#521B41",
    },
    leadpro: {
      50: "#FAFBFF",
      75: "#CEF2FF",
      100: "#F2F4FA",
      200: "#E4E7F2",
      300: "#BEC5DB",
      400: "#8D96B2",
      500: "#566081",
      600: "#3B68AA",
      700: "#383F55",
      800: "#00AAE7",
      900: "#FDFDFF",
    },
    pageHeader: "#FFFFFF",
  },
  components: {
    Button,
    Tabs,
    Menu,
    Switch,
    Checkbox,
    Radio,
    Accordion,
    Tag,
    Popover,
  },
});
