import React, { FC } from "react";
import { Box, Tag, TagLabel } from "@chakra-ui/react";
import { TLead } from "types/lead.type";

interface IProps {
  lead: TLead;
}

export const LeadCampaignCell: FC<IProps> = ({ lead }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      {!!lead.utm?.campaign && (
        <Tag
          bg={"leadpro.100"}
          height={"40px"}
          minWidth={"100px"}
          justifyContent={"center"}
        >
          <TagLabel>{lead.utm.campaign}</TagLabel>
        </Tag>
      )}
    </Box>
  );
};
