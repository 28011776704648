import React, { useCallback, useState } from "react";
import { Box, Button, Divider, HStack } from "@chakra-ui/react";
import { ActionPromptContainer } from "components";

interface IProps {
  header?: string;
  text: React.ReactNode;
  onConfirm: () => void;
  onConfirmBtnLabel?: string;
  onDenyBtnLabel?: string;
  closePrompt: () => void;
  colorScheme?: string;
}

export const ConfirmActionPrompt: React.FC<IProps> = ({
  header,
  text,
  onConfirm,
  onDenyBtnLabel,
  onConfirmBtnLabel,
  closePrompt,
  colorScheme = "red",
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
      setIsSubmitting(false);
      closePrompt();
    } catch (e) {
      setIsSubmitting(false);
    }
  }, [closePrompt, onConfirm]);

  return (
    <ActionPromptContainer
      header={header || "Confirm your action"}
      body={
        <Box>
          <Box mb={5}>
            <Box>{text}</Box>
          </Box>
          <Divider mb={4} />
          <HStack width={"100%"} spacing={4} justify={"flex-end"}>
            {!!onDenyBtnLabel && (
              <Button
                variant={"outline"}
                onClick={closePrompt}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {onDenyBtnLabel}
              </Button>
            )}
            <Button
              type={"submit"}
              colorScheme={colorScheme}
              onClick={handleConfirm}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {onConfirmBtnLabel || "Confirm"}
            </Button>
          </HStack>
        </Box>
      }
    />
  );
};
