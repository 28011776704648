import React from "react";
import { Alert } from "../index";
import { IAlertProps } from "../alert/Alert";

const statuses = {
  neutral: { color: "gray" },
  info: { color: "blue" },
  warning: { color: "orange" },
  success: { color: "green" },
  error: { color: "red" },
};

type MessageStatuses = typeof statuses;

interface IMessageProps {
  type?: keyof MessageStatuses;
  children: React.ReactNode;
}

interface IMessageProps extends IAlertProps {}

export const Message: React.FC<IMessageProps> = ({ children, ...rest }) => {
  return (
    <Alert fontSize={"sm"} p={3} {...rest}>
      {children}
    </Alert>
  );
};
