import { Button, Center, HStack } from "@chakra-ui/react";
import { FC } from "react";

interface IFormFooterProps {
  isSubmitting: boolean;
  resetForm: () => void;
  submitForm: () => void;
}

export const FormFooter: FC<IFormFooterProps> = ({
  isSubmitting,
  resetForm,
  submitForm,
}) => {
  return (
    <Center
      px={4}
      py={2}
      width={"100%"}
      background={"leadpro.50"}
      borderTop={"1px solid"}
      borderTopColor={"leadpro.200"}
    >
      <HStack maxW="5xl" width={"100%"} spacing={4} justify={"flex-end"}>
        <Button
          size={"md"}
          variant={"outline"}
          isDisabled={isSubmitting}
          onClick={() => resetForm()}
        >
          Cancel
        </Button>
        <Button
          onClick={submitForm}
          size={"md"}
          colorScheme={"leadpro"}
          bgColor={"leadpro.800"}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </HStack>
    </Center>
  );
};
