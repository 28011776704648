import { LeadNextActionDateFilterEnum } from "enums/lead-next-action-date-filter.enum";
import { LeadDateRangeFilterEnum } from "enums/lead-next-action-date-filter.enum";
import {
  LEAD_JOURNEY_LABELS,
  LeadJourneyEnum,
} from "../utils/utm-journey.utils";

export const LeadNextActionDateFiltersOptions: {
  value: LeadNextActionDateFilterEnum;
  label: string;
}[] = [
  {
    value: LeadNextActionDateFilterEnum.ALL,
    label: "All",
  },
  {
    value: LeadNextActionDateFilterEnum.OVERDUE,
    label: "Overdue",
  },
  {
    value: LeadNextActionDateFilterEnum.TODAY,
    label: "Today",
  },
  {
    value: LeadNextActionDateFilterEnum.THIS_WEEK,
    label: "This week",
  },
  {
    value: LeadNextActionDateFilterEnum.LATER,
    label: "Later",
  },
  {
    value: LeadNextActionDateFilterEnum.CUSTOM,
    label: "Custom",
  },
];

export const LeadDateRangeFiltersOptions: {
  value: LeadDateRangeFilterEnum;
  label: string;
}[] = [
  {
    value: LeadDateRangeFilterEnum.ALL,
    label: "All",
  },
  {
    value: LeadDateRangeFilterEnum.TODAY,
    label: "Today",
  },
  {
    value: LeadDateRangeFilterEnum.THIS_WEEK,
    label: "This week",
  },
  {
    value: LeadDateRangeFilterEnum.CUSTOM,
    label: "Custom",
  },
];

export const LeadJourneyPredefinedOptions: {
  value: LeadJourneyEnum;
  label: string;
}[] = [
  {
    value: LeadJourneyEnum.PPC,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.PPC],
  },
  {
    value: LeadJourneyEnum.GOOGLE_LOCAL,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.GOOGLE_LOCAL],
  },
  {
    value: LeadJourneyEnum.GOOGLE_SEARCH,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.GOOGLE_SEARCH],
  },
  {
    value: LeadJourneyEnum.BING_SEARCH,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.BING_SEARCH],
  },
  {
    value: LeadJourneyEnum.PAID_SOCIAL,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.PAID_SOCIAL],
  },
  {
    value: LeadJourneyEnum.OFF_MARKET_2020,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.OFF_MARKET_2020],
  },
  {
    value: LeadJourneyEnum.OFF_MARKET_COMMUNICATION,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.OFF_MARKET_COMMUNICATION],
  },
  {
    value: LeadJourneyEnum.WEBSITE,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.WEBSITE],
  },
  {
    value: LeadJourneyEnum.SOCIAL_MEDIA,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.SOCIAL_MEDIA],
  },
  {
    value: LeadJourneyEnum.SOCIAL,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.SOCIAL],
  },
  {
    value: LeadJourneyEnum.EMAIL,
    label: LEAD_JOURNEY_LABELS[LeadJourneyEnum.EMAIL],
  },
];
