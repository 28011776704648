export const normaliseAndSplitAddress = (
  address: string,
  postcode?: string | null
): string[] => {
  let normalisedAddress = postcode ? address.replace(postcode, "") : address;

  const numberMatches = normalisedAddress.match(/(\d+\w*)-*(\d*\w*) /g);

  if (numberMatches) {
    numberMatches.forEach(
      match =>
        (normalisedAddress = normalisedAddress.replace(
          match,
          `${match.trim()},`
        ))
    );
  }

  return normalisedAddress.split(",").map(s => s.trim());
};

export const shiftPropertyName = (splitAddress: string[]): string => {
  const hasName = !!splitAddress[0][0]?.match(/[^\d]/);
  if (!hasName) {
    return "";
  }

  const propertyName = splitAddress[0];
  splitAddress.shift();
  return propertyName;
};

export const shiftPropertyNumber = (splitAddress: string[]): string => {
  const hasNumber = !!splitAddress[0]?.match(/(\d+\w*)-*(\d*\w*)/);
  if (!hasNumber) {
    return "";
  }

  const propertyNumber = splitAddress[0];
  splitAddress.shift();
  return propertyNumber;
};
