import { UserAvatar } from "../avatar";
import { Box, ChakraStyledOptions, HStack } from "@chakra-ui/react";
import { getUserFullName } from "utils/account-user.utils";
import { NA } from "../index";
import { TUserBasicData } from "types/user-data.type";
import { FC, useMemo } from "react";

interface IBasicUserInfoProps {
  user: TUserBasicData;
  hideEmail?: boolean;
  condensed?: boolean;
}

export const BasicUserInfo: FC<IBasicUserInfoProps> = ({
  user,
  hideEmail,
  condensed,
}) => {
  const userFullName = useMemo(
    () => getUserFullName(user.firstName, user.lastName) || <NA />,
    [user]
  );

  const userCondensedStyles: ChakraStyledOptions = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
  };

  return (
    <HStack spacing={2} align={"center"} width={"100%"} color={"gray.900"}>
      <UserAvatar size={condensed ? "xs" : "sm"} user={user} />
      <Box fontSize={"sm"} lineHeight={1}>
        {condensed ? (
          <Box {...userCondensedStyles}>{userFullName}</Box>
        ) : (
          <Box>{userFullName}</Box>
        )}
        {!hideEmail && !condensed && <Box color={"gray.400"}>{user.email}</Box>}
      </Box>
    </HStack>
  );
};
