import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { TContentfulArticleData } from "types/contentful-article.type";

interface IProps {
  article: TContentfulArticleData;
  index: number;
  onClick: (articleSlug: string) => void;
}

export const ArticleListItem: React.FC<IProps> = ({
  article,
  index,
  onClick,
}) => {
  return (
    <Box
      onClick={() => onClick(article.fields.slug)}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={5}
      shadow={"md"}
      border={"1px solid"}
      borderColor={"gray.200"}
      rounded={"md"}
      cursor={"pointer"}
      mb={4}
      _hover={{
        shadow: "sm",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box width={"40px"}>
          <Text fontWeight={600} color={"gray.400"}>
            {index}.
          </Text>
        </Box>
        <Text fontSize={"20px"} fontWeight={600}>
          {article.fields.title}
        </Text>
      </Box>
    </Box>
  );
};
