import {
  TRexLeadSource,
  TRexLeadSourceRelation,
  TRexLeadSourceRelationData,
} from "types/rex.type";
import { BaseApi } from "./base.api";

export class AccountRexApi extends BaseApi {
  async fetchAccountRexLeadSources(
    accountId: number
  ): Promise<TRexLeadSource[]> {
    return this.get(`/accounts/${accountId}/rex/rex-sources`);
  }

  async fetchAccountRexLeadSourceRelations(
    accountId: number
  ): Promise<TRexLeadSourceRelation[]> {
    return this.get(`/accounts/${accountId}/rex/source-relations`);
  }

  async updateAccountRexSyncedLeadSources(
    accountId: number,
    relationsData: TRexLeadSourceRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/rex/source-relations`, {
      items: relationsData,
    });
  }
}
