import { TLeadProgressionConfiguration } from "types/account-lead-progression.type";
import { BaseApi } from "./base.api";

export class AccountLeadProgressionApi extends BaseApi {
  async fetchAccountLeadProgressionConfiguration(
    accountId: number
  ): Promise<TLeadProgressionConfiguration> {
    return this.get(`/accounts/${accountId}/lead-progression-config`);
  }
}
