import { LeadTypeTag } from "components";
import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { TLead } from "types/lead.type";

interface ILeadTypeCellProps {
  lead: TLead;
}

export const LeadTypeCell: FC<ILeadTypeCellProps> = ({ lead }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      <LeadTypeTag type={lead.type} source={lead.source} />
    </Box>
  );
};
