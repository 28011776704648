import {
  SETTINGS_OFFICES_ROUTE,
  TOOLS_MORTGAGE_REFERRALS_ROUTE,
} from "constants/routes";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Redirect, useParams } from "react-router-dom";
import { OfficeUsers } from "./components/OfficeUsers/OfficeUsers";
import { OfficeDetails } from "./components/OfficeDetails/OfficeDetails";
import { OfficeMemberships } from "./components/OfficeMemberships/OfficeMemberships";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { OfficeIntegrations } from "./components/OfficeIntegrations";
import { OfficeAutocallerSettings } from "./components/OfficeAutocallerSettings";
import { ApplicationProductEnum } from "enums/application-product.enum";
import { PremiumFeatureBlock } from "routes/dashboard/components/PremiumFeatureBlock/PremiumFeatureBlock";
import { OfficeInstantValuationToolSettings } from "./components/OfficeInstantValuationToolSettings/OfficeInstantValuationToolSettings";
import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { OfficeMortgageReferralsSettings } from "./components/OfficeMortgageReferralsSettings";

interface IProps {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  accountStore: UserAccountStore;
}

export const OfficeSettings: FC<IProps> = observer(
  ({ containerRef, accountStore }) => {
    let { id } = useParams<{ id: string }>();
    const officeId = useMemo(() => parseInt(id!), [id]);
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const accountOfficesStore = accountStore.accountOfficesStore;
    const officeStore = accountOfficesStore.accountOfficeStoresMap[officeId];

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountOfficesStore.fetchAccountOffice(officeId);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountOfficesStore, officeId]);

    const content = useMemo(() => {
      const title = officeStore?.office.name || "Loading office...";
      return (
        <TabsGroupLayout
          title={title}
          loadingStatus={loadingStatus}
          config={[
            {
              tabName: "Details",
              tabHash: "#details",
              tabComponent: !!officeStore && (
                <OfficeDetails
                  officeStore={officeStore}
                  accountStore={accountStore}
                  containerRef={containerRef}
                />
              ),
            },
            {
              tabName: "Integrations",
              tabHash: "#integrations",
              tabComponent: !!officeStore && (
                <OfficeIntegrations
                  accountStore={accountStore}
                  officeStore={officeStore}
                  containerRef={containerRef}
                />
              ),
            },
            {
              tabName: "Memberships",
              tabHash: "#memberships",
              tabComponent: !!officeStore && (
                <OfficeMemberships
                  officeStore={officeStore}
                  containerRef={containerRef}
                />
              ),
            },
            {
              tabName: "Users",
              tabHash: "#users",
              tabComponent: !!officeStore && (
                <OfficeUsers
                  accountStore={accountStore}
                  officeStore={officeStore}
                />
              ),
            },
            {
              tabName: "Autocaller",
              tabHash: "#autocaller",
              tabComponent: !!officeStore && (
                <PremiumFeatureBlock
                  productId={ApplicationProductEnum.AUTOCALLER}
                  render={isEnabled => (
                    <OfficeAutocallerSettings
                      accountStore={accountStore}
                      officeStore={officeStore}
                      isEnabled={isEnabled}
                      containerRef={containerRef}
                    />
                  )}
                />
              ),
            },
            {
              tabName: "Instant Valuation Tool",
              tabHash: "#ivt",
              tabComponent: !!officeStore && (
                <PremiumFeatureBlock
                  productId={ApplicationProductEnum.INSTANT_VALUATION_TOOL}
                  render={isEnabled => (
                    <OfficeInstantValuationToolSettings
                      accountStore={accountStore}
                      officeStore={officeStore}
                      isEnabled={isEnabled}
                      containerRef={containerRef}
                    />
                  )}
                />
              ),
            },
            {
              tabName: "Mortgages",
              tabHash: "#mortgages",
              tabComponent: !!officeStore && (
                <PremiumFeatureBlock
                  productId={ApplicationProductEnum.MORTGAGES}
                  ctaNavigateTo={TOOLS_MORTGAGE_REFERRALS_ROUTE}
                  render={isEnabled => (
                    <OfficeMortgageReferralsSettings
                      isEnabled={isEnabled}
                      officeStore={officeStore}
                      containerRef={containerRef}
                    />
                  )}
                />
              ),
            },
          ]}
        />
      );
    }, [officeStore, loadingStatus, containerRef, accountStore]);

    if (loadingStatus === ApiRequestStatusEnum.ERROR) {
      return (
        <Redirect
          to={{
            pathname: SETTINGS_OFFICES_ROUTE,
          }}
        />
      );
    }

    return content;
  }
);
