import { Link, TextDecorationProps } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

interface IExternalLink {
  href: string;
  label?: React.ReactNode;
  textDecoration?: TextDecorationProps["textDecoration"];
  isDisabled?: boolean;
}

export const ExternalLink: React.FC<IExternalLink> = ({
  href,
  label,
  textDecoration,
  isDisabled,
}) => {
  return (
    <Link
      href={href}
      textDecoration={textDecoration}
      isExternal
      color={isDisabled ? "leadpro.400" : "blue.500"}
      pointerEvents={isDisabled ? "none" : undefined}
    >
      {label || href}
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        style={{
          marginLeft: "4px",
          marginBottom: "-1px",
          display: "inline",
        }}
      />
    </Link>
  );
};
