import { Box, Button, useToast } from "@chakra-ui/react";
import {
  FormControlDescription,
  Loader,
  Panel,
  PanelBody,
  Error,
  PanelHeader,
  PanelTitle,
  PanelFooter,
  ApiMessageStack,
} from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import { CustomStatusFormPrompt } from "routes/dashboard/components/prompts/CustomStatusPrompt/CustomStatusFormPrompt";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { StatusesConfigurator } from "./StatusesConfigurator";

interface ICustomStatusesPanelProps {
  leadStatusesStore: AccountLeadStatusesStore;
  loadingStatus: ApiRequestStatusEnum;
}

export const CustomStatusesPanel: React.FC<ICustomStatusesPanelProps> = observer(
  ({ leadStatusesStore, loadingStatus }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();

    const groupedCustomLeadStatusesByStageId =
      leadStatusesStore.accountGroupedCustomLeadStatusesByStage;

    const handleDelete = useCallback(
      (statusId: number) => async () => {
        try {
          await leadStatusesStore.deleteAccountLeadStatus(statusId);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"Status has been deleted"} />
            ),
          });
          unSetModal();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [toast, leadStatusesStore, unSetModal]
    );

    const handleCreateOrUpdateCustomStatus = useCallback(
      (statusId?: number) => {
        setModal(
          <CustomStatusFormPrompt
            leadStatusesStore={leadStatusesStore}
            selectedStatusId={statusId}
            closePrompt={unSetModal}
          />
        );
      },
      [setModal, leadStatusesStore, unSetModal]
    );

    const handleDeleteCustomStatus = useCallback(
      (statusId: number) => {
        setModal(
          <ConfirmActionPrompt
            header="Delete Custom Status"
            text="Are you sure you want to delete this status?"
            onConfirm={handleDelete(statusId)}
            closePrompt={unSetModal}
          />
        );
      },
      [unSetModal, setModal, handleDelete]
    );

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>Custom Statuses</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <Box mb={5}>
            <FormControlDescription>
              Manage your account lead custom statuses.
            </FormControlDescription>
          </Box>
          <Box position={"relative"} minHeight={"200px"}>
            {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
            {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
            {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
              <StatusesConfigurator
                statusesStageMap={groupedCustomLeadStatusesByStageId}
                onEdit={handleCreateOrUpdateCustomStatus}
                onDelete={handleDeleteCustomStatus}
              />
            )}
          </Box>
        </PanelBody>
        <PanelFooter>
          <Box ml="auto">
            <Button
              onClick={() => handleCreateOrUpdateCustomStatus()}
              colorScheme={"blue"}
            >
              Create Custom Status
            </Button>
          </Box>
        </PanelFooter>
      </Panel>
    );
  }
);
