import { Box, Button, Text, VStack } from "@chakra-ui/react";
import { Error, Loader, TableV2 } from "components";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TAccountBlacklistedEmail } from "types/account-blacklisted-email.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { useLocalStore } from "utils/react-hooks/useLocalStore";
import { CreateEmailActionPrompt } from "./ActionPrompts/CreateEmailActionPrompt";
import { RemoveEmailActionPrompt } from "./ActionPrompts/RemoveEmailActionPrompt";
import { BlacklistedEmailLocalStore } from "./BlacklistedEmails.local.store";
import { blacklistedEmailsColumnDef } from "./blacklistedEmailsColumnDef";

interface IProps {
  accountStore: UserAccountStore;
}

export const BlacklistedEmails: FC<IProps> = observer(({ accountStore }) => {
  const accountId = accountStore.account.id;
  const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
    ApiRequestStatusEnum.NONE
  );
  const blacklistedEmailsStore = useLocalStore(
    BlacklistedEmailLocalStore,
    accountId
  );
  const {
    blackListedEmailsArray,
    blacklistedEmailAddressesArray,
    fetchAllBlacklistedEmails,
    addBlacklistedEmail,
    removeBlacklistedEmail,
  } = blacklistedEmailsStore;

  const { setModal, unSetModal } = useActionPrompt();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        await fetchAllBlacklistedEmails();
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [setLoadingStatus, fetchAllBlacklistedEmails]);

  const handleEmailCreation = useCallback(() => {
    setModal(
      <CreateEmailActionPrompt
        closePrompt={unSetModal}
        createEmail={addBlacklistedEmail}
        existingEmails={blacklistedEmailAddressesArray}
      />
    );
  }, [
    addBlacklistedEmail,
    unSetModal,
    setModal,
    blacklistedEmailAddressesArray,
  ]);

  const handleEmailRemoval = useCallback(
    (row: TAccountBlacklistedEmail) => {
      setModal(
        <RemoveEmailActionPrompt
          closePrompt={unSetModal}
          row={row}
          removeEmail={removeBlacklistedEmail}
        />
      );
    },
    [setModal, unSetModal, removeBlacklistedEmail]
  );

  return (
    <VStack spacing={8} align={"stretch"}>
      <Text fontSize={"2xl"} fontWeight={"bold"} mb={-4}>
        Blacklisted email addresses
      </Text>
      <Text fontSize={"sm"} color={"leadpro.500"}>
        If any leads come into your Leadpro system with an email address
        specified in the below list, the lead will automatically be marked as
        spam and not appear in your 'new leads' tab on your dashboard. You'll
        still be able to access and see these leads from the 'all leads' tab.
      </Text>
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <TableV2<TAccountBlacklistedEmail>
          striped
          dataSource={blackListedEmailsArray}
          columns={blacklistedEmailsColumnDef(handleEmailRemoval)}
          additionalActions={
            <Box flexGrow={1}>
              <Button
                variant={"link"}
                colorScheme={"blue"}
                onClick={handleEmailCreation}
              >
                + Add blacklisted email
              </Button>
            </Box>
          }
        />
      )}
    </VStack>
  );
});
