import {
  TCompletedDashboardTour,
  TNormalizedUserData,
  TUserData,
} from "types/user-data.type";
import { AuthApi } from "api/auth.api";
import { AppStore } from "./App.store";
import { makeAutoObservable } from "mobx";
import { BaseApi } from "api/base.api";
import { TWebPushSettings } from "types/web-push.type";
import {
  createWebPushSubscriptionIfGranted,
  removeWebPushSubscription,
} from "utils/web-push.utils";
import { DashboardTourEnum } from "enums/dashboard-tour.enum";
import { normalize, schema } from "normalizr";
import { TUserAccountData } from "types/user-account.type";
import { LeadProAuthStore } from "./LeadProAuth.store";
import { AuthStatusEnum } from "enums/auth-status.enum";
import { AuthStoreTypeEnum } from "enums/auth-store-type.enum";
import { EcosystemAuthStore } from "./EcosystemAuth.store";
import { IAuthStore } from "interfaces/IAuthStore";
import { getTokenPayload } from "utils/jwt.utils";
import { TEcosystemAuthTokenDTO, TLeadProAuthTokenDTO } from "types/token.type";
import { addAuthInfoToSentry } from "utils/sentry.utils";
import {
  clearDefaultAccountFromLocalStorage,
  getLeadProAuthTokenFromLocalStorage,
} from "utils/local-storage.utils";

enum MeDataNormalizedKeys {
  USER_ACCOUNTS = "userAccounts",
  AUTH_USER = "authUser",
  INTEGRATIONS = "integrations",
}

interface INormalizedData {
  [MeDataNormalizedKeys.AUTH_USER]: { [key: number]: TNormalizedUserData };
  [MeDataNormalizedKeys.USER_ACCOUNTS]: { [key: number]: TUserAccountData };
}

const authApi = new AuthApi();

const integrationEntity = new schema.Entity(MeDataNormalizedKeys.INTEGRATIONS);
const accountEntity = new schema.Entity(MeDataNormalizedKeys.USER_ACCOUNTS, {
  integrations: [integrationEntity],
});
const userEntity = new schema.Entity(MeDataNormalizedKeys.AUTH_USER, {
  accounts: [accountEntity],
});

export class AuthStore {
  private readonly root: AppStore;
  public authToken: string | null = null;
  public selectedAuthStoreType: AuthStoreTypeEnum | null = null;
  private authUserData: TNormalizedUserData | null = null;
  public authStores: Record<AuthStoreTypeEnum, IAuthStore>;

  constructor(root: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    BaseApi.onUnauthorizedRequest(this.logout);

    this.root = root;
    this.authStores = {
      [AuthStoreTypeEnum.LEAD_PRO]: new LeadProAuthStore(root),
      [AuthStoreTypeEnum.ECOSYSTEM]: new EcosystemAuthStore(root),
    };
  }

  get authStatus(): AuthStatusEnum {
    const tokenData = getTokenPayload<
      TEcosystemAuthTokenDTO | TLeadProAuthTokenDTO
    >(this.authToken);

    if (!tokenData) return AuthStatusEnum.LOGGED_OUT;

    if ((tokenData as TLeadProAuthTokenDTO).require2FA)
      return AuthStatusEnum.WAITING_2FA;

    return AuthStatusEnum.LOGGED_IN;
  }

  get selectedAuthStore() {
    if (!!this.selectedAuthStoreType) {
      return this.authStores[this.selectedAuthStoreType];
    }

    throw new Error("Auth store not initialized!");
  }

  get leadProAuthStore(): LeadProAuthStore {
    return this.authStores[AuthStoreTypeEnum.LEAD_PRO] as LeadProAuthStore;
  }

  get ecosystemAuthStore(): EcosystemAuthStore {
    return this.authStores[AuthStoreTypeEnum.ECOSYSTEM] as EcosystemAuthStore;
  }

  get isFullyLoggedIn() {
    return this.authStatus === AuthStatusEnum.LOGGED_IN;
  }

  get authUser() {
    return this.authUserData;
  }

  get authTokenPayload() {
    return getTokenPayload<TLeadProAuthTokenDTO | TEcosystemAuthTokenDTO>(
      this.authToken
    );
  }

  private setAuthUserData(authUser: TNormalizedUserData) {
    this.authUserData = authUser;
  }

  private processAuthUserData(user: TUserData) {
    const userAccountsStore = this.root.userAccountsStore;
    const normalizedUser = normalize<any, INormalizedData, any>(
      user,
      userEntity
    ).entities;

    if (normalizedUser[MeDataNormalizedKeys.USER_ACCOUNTS]) {
      const accountsArray = Object.values(
        normalizedUser[MeDataNormalizedKeys.USER_ACCOUNTS]
      );
      userAccountsStore.setUserAccounts(accountsArray);
    }

    this.setAuthUserData(
      Object.values(normalizedUser[MeDataNormalizedKeys.AUTH_USER])[0]
    );
  }

  private async loadAuthUser() {
    const user = await authApi.me();
    this.processAuthUserData(user);
  }

  public async initStore() {
    if (
      !!getLeadProAuthTokenFromLocalStorage() ||
      !EcosystemAuthStore.isEcosystemSupported()
    ) {
      this.selectedAuthStoreType = AuthStoreTypeEnum.LEAD_PRO;
    } else {
      this.selectedAuthStoreType = AuthStoreTypeEnum.ECOSYSTEM;
    }
    await this.selectedAuthStore.initStore();
  }

  public async initUser() {
    await this.initStore();
    await this.root.authStore.finalizeLogin();
  }

  public setAuth(token: string | null = null) {
    this.authToken = token;
    BaseApi.setDefaultAuthorizationHeader(token);

    if (!!token) {
      addAuthInfoToSentry(token);
    }
  }

  public async logout() {
    await this.selectedAuthStore.removeCredentials();
    this.root.authStore.setAuth(null);
    this.root.destroySocketIO();
    removeWebPushSubscription();
    clearDefaultAccountFromLocalStorage();
  }

  public async updateWebPushSettings(webPushSettings: TWebPushSettings) {
    const { settings } = await authApi.updateWebPushSettings(webPushSettings);
    if (!!this.authUserData) {
      this.authUserData.webPushSettings = settings;
    }
  }

  public async removeDashboardTour(tourId: DashboardTourEnum) {
    if (!!this.authUserData) {
      const allCompletedTours = this.authUserData.completedDashboardTours
        ? this.authUserData.completedDashboardTours
        : [];
      const remainingCompletedTours = allCompletedTours.filter(
        tour => tour.tourId !== tourId
      );
      await authApi.updateUser({
        completedDashboardTours: remainingCompletedTours,
      });
      this.authUserData.completedDashboardTours = remainingCompletedTours;
    }
  }

  public async finishDashboardTour(tour: TCompletedDashboardTour) {
    if (!!this.authUserData) {
      const allCompletedTours = this.authUserData.completedDashboardTours
        ? this.authUserData.completedDashboardTours
        : [];
      const newAllCompletedTours = [...allCompletedTours, tour];
      await authApi.updateUser({
        completedDashboardTours: newAllCompletedTours,
      });
      this.authUserData.completedDashboardTours = newAllCompletedTours;
    }
  }

  public async reloadAuthUserData() {
    const user = await authApi.me();
    this.processAuthUserData(user);
  }

  public async finalizeLogin() {
    await this.loadAuthUser();
    createWebPushSubscriptionIfGranted();
  }

  public async loadUserAchievements() {
    if (!!this.authUserData) {
      const { noOfProcessedLeads } = await authApi.getStats();
      this.authUserData.noOfProcessedLeads = noOfProcessedLeads;
    }
  }

  public async updateAuthUser(userData: Partial<TUserData>) {
    if (!!this.authUserData) {
      await authApi.updateUser(userData);
      await this.reloadAuthUserData();
    }
  }
}
