import { typesColors } from "constants/colors";
import React, { useMemo } from "react";
import { Box, HStack, Tag, TagLabel } from "@chakra-ui/react";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { LeadSourceEnum } from "enums/lead-source.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/pro-duotone-svg-icons";

interface ILeadTypeTagProps {
  type: LeadTypeEnum;
  source?: LeadSourceEnum;
}

export const LeadTypeTag: React.FC<ILeadTypeTagProps> = ({ type, source }) => {
  const sourceBanner = useMemo(() => {
    if (source === LeadSourceEnum.rightmove_valuation) {
      return (
        <Box
          display="flex"
          alignItems="center"
          mr={2}
          overflow="hidden"
          justifyContent="center"
        >
          <Box color="red.500">
            <FontAwesomeIcon icon={faFire} fontSize="21px" />
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  }, [source]);

  return (
    <HStack>
      <Tag
        variant={"subtle"}
        textTransform="capitalize"
        border={"1px solid"}
        borderColor={typesColors[type]?.statsColor}
        colorScheme={typesColors[type]?.colorScheme}
        p={0}
        minWidth={"114px"}
      >
        <TagLabel
          m={1}
          px={5}
          lineHeight={1.5}
          bg={"white"}
          color={"leadpro.500"}
          width={"100%"}
          height={"100%"}
          textAlign={"center"}
        >
          {getLeadTypeLabel(type)}
        </TagLabel>
      </Tag>
      {sourceBanner}
    </HStack>
  );
};
