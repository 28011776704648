import * as Yup from "yup";
import { REQUIRED_FIELD } from "../../constants/validator-messages";

export type TUrlSyncIntegrationConfigFieldValues = {
  urlTemplate: string;
};

export const urlSyncIntegrationConnectValidationSchema = Yup.object().shape({
  urlTemplate: Yup.string().required(REQUIRED_FIELD),
});
