import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ChakraStyledOptions,
  Image,
  useToast,
} from "@chakra-ui/react";
import { ApiMessageStack, Tooltip } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_WARNING_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TLeadReapitSyncData } from "types/reapit.type";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { LeadReapitSyncFormPrompt } from "./LeadReapitSyncFormPrompt/LeadReapitSyncFormPrompt";
import {
  IntegrationLogoIcons,
  IntegrationLogoIconURLs,
} from "constants/integrationLogoIcons";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import LeadReapitSyncOfficeGroup from "./LeadReapitSyncOfficeGroup";

interface IProps {
  leadStore: AccountLeadStore;
  hasActiveReapitIntegration: boolean;
  accountStore: UserAccountStore;
  styles?: ChakraStyledOptions;
}

export const LeadSyncReapitButton: FC<IProps> = observer(
  ({ leadStore, hasActiveReapitIntegration, accountStore, styles }) => {
    const toast = useToast();
    const [isSyncing, setIsSyncing] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const { setModal, unSetModal } = useActionPrompt();
    const isLeadOfficeSyncedWithReapit = leadStore.isLeadOfficeSyncedWithReapit;
    const isLeadSynced = leadStore.isLeadSyncedToReapit;
    const isReapitSyncAllowedForLeadType =
      leadStore.isReapitSyncAllowedForLeadType;
    const isLeadAssignedToUser = leadStore.isLeadAssignedToUser;
    const isLeadAssignedToOffice = leadStore.isLeadAssignedToOffice;

    const leadOffice = leadStore.lead.officeId;
    const {
      isApplicantRequirementsEnabled,
      areApplicantRequirementsFieldsMandatory,
      isMultiIntegrationEnabled,
      fetchReapitIntegrationConfigSyncData,
    } = accountStore.accountIntegrationsStore.accountIntegrationReapitStore;

    const {
      accountOfficeGroupsArray,
      fetchAllAccountOfficeGroups,
    } = accountStore.accountOfficeGroupsStore;

    const {
      accountIntegrationReapitAreasStore,
      accountIntegrationReapitContactsStore,
    } = accountStore.accountIntegrationsStore.accountIntegrationReapitStore;

    const leadCompatibleOfficeGroups = useMemo(() => {
      return accountOfficeGroupsArray.filter(officeGroup => {
        if (leadOffice) {
          return officeGroup.officeIds.includes(leadOffice);
        }
        return false;
      });
    }, [leadOffice, accountOfficeGroupsArray]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            fetchAllAccountOfficeGroups(),
            fetchReapitIntegrationConfigSyncData(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      };

      fetchData();
    }, [
      fetchReapitIntegrationConfigSyncData,
      fetchAllAccountOfficeGroups,
      toast,
    ]);

    const validationErrorMessages = useMemo(() => {
      const errorMessages: string[] = [];

      if (isSyncing) errorMessages.push("Lead is being synced...");
      if (isLeadSynced) errorMessages.push("Lead already synced.");
      if (!isReapitSyncAllowedForLeadType)
        errorMessages.push("Lead type not supported.");
      if (!isLeadAssignedToUser) errorMessages.push("Please assign an agent.");
      if (!isLeadAssignedToOffice)
        errorMessages.push("Please assign an office.");
      if (!isLeadOfficeSyncedWithReapit)
        errorMessages.push("Assigned office is not synced with Reapit");
      if (isMultiIntegrationEnabled) {
        if (leadCompatibleOfficeGroups.length < 1) {
          errorMessages.push("Office does not match any office group");
        }
      }

      return errorMessages;
    }, [
      isSyncing,
      isLeadSynced,
      isReapitSyncAllowedForLeadType,
      isLeadAssignedToUser,
      isLeadAssignedToOffice,
      isLeadOfficeSyncedWithReapit,
      leadCompatibleOfficeGroups,
      isMultiIntegrationEnabled,
    ]);

    const buttonData = useMemo(() => {
      return {
        buttonProps: {
          isDisabled: !!validationErrorMessages.length,
          isLoading: isSyncing,
          children: isLeadSynced ? "Synced to Reapit" : "Sync to Reapit",
          leftIcon: isLeadSynced ? (
            <FontAwesomeIcon icon={faCircleCheck} fontSize={24} />
          ) : (
            <Image
              alt={"reapit-icon"}
              width={"24px"}
              src={IntegrationLogoIconURLs[IntegrationLogoIcons.REAPIT]}
            />
          ),
        },
      };
    }, [isLeadSynced, isSyncing, validationErrorMessages.length]);

    const handleSyncLead = useCallback(
      async (syncData: TLeadReapitSyncData, officeGroupId?: number) => {
        if (hasActiveReapitIntegration && !validationErrorMessages.length) {
          try {
            setIsSyncing(true);
            if (isMultiIntegrationEnabled && officeGroupId) {
              await leadStore.syncLeadWithReapit({
                ...syncData,
                officeGroupId: officeGroupId,
              });
            } else {
              await leadStore.syncLeadWithReapit(syncData);
            }
          } catch (e) {
            toast({
              ...DEFAULT_ERROR_TOAST_OPTIONS,
              description: <ApiMessageStack messageStack={e.message} />,
            });
          } finally {
            setIsSyncing(false);
          }
        }
      },
      [
        leadStore,
        hasActiveReapitIntegration,
        validationErrorMessages,
        toast,
        isMultiIntegrationEnabled,
        setIsSyncing,
      ]
    );

    const setFormModal = useCallback(
      async (officeGroupId?: number) => {
        await leadStore.fetchDetails();
        const leadSyncCheck = leadStore.isLeadSyncedToReapit;
        if (leadSyncCheck) {
          toast({
            ...DEFAULT_WARNING_TOAST_OPTIONS,
            description:
              "This lead has already been sync'd to reapit by another user.",
          });
        } else {
          setModal(
            <LeadReapitSyncFormPrompt
              leadStore={leadStore}
              handleSyncLead={handleSyncLead}
              closePrompt={unSetModal}
              areasStore={accountIntegrationReapitAreasStore}
              contactsStore={accountIntegrationReapitContactsStore}
              officeGroupId={
                isMultiIntegrationEnabled ? officeGroupId : undefined
              }
              isApplicantRequirementsEnabled={isApplicantRequirementsEnabled}
              areApplicantRequirementsFieldsMandatory={
                areApplicantRequirementsFieldsMandatory
              }
            />
          );
        }
      },
      [
        leadStore,
        handleSyncLead,
        setModal,
        unSetModal,
        accountIntegrationReapitAreasStore,
        accountIntegrationReapitContactsStore,
        isMultiIntegrationEnabled,
        isApplicantRequirementsEnabled,
        areApplicantRequirementsFieldsMandatory,
        toast,
      ]
    );

    const setOfficeGroupModal = useCallback(() => {
      setModal(
        <LeadReapitSyncOfficeGroup
          officeGroups={leadCompatibleOfficeGroups}
          selectOfficeGroup={(id: number) => {
            setFormModal(id);
          }}
        />
      );
    }, [leadCompatibleOfficeGroups, setModal, setFormModal]);

    const handleOpenSyncModal = useCallback(() => {
      if (isMultiIntegrationEnabled) {
        if (leadCompatibleOfficeGroups.length > 1) setOfficeGroupModal();
        else if (leadCompatibleOfficeGroups.length === 1) {
          setFormModal(leadCompatibleOfficeGroups[0].id);
        }
      } else {
        setFormModal();
      }
    }, [
      isMultiIntegrationEnabled,
      leadCompatibleOfficeGroups,
      setFormModal,
      setOfficeGroupModal,
    ]);

    return (
      <Tooltip
        isDisabled={
          !validationErrorMessages.length ||
          loadingStatus !== ApiRequestStatusEnum.SUCCESS
        }
        aria-label={`lead-reapit-sync-tooltip`}
        label={
          <Box>
            {validationErrorMessages.map(message => (
              <Box key={message}>{message}</Box>
            ))}
          </Box>
        }
      >
        <Box>
          <Button
            onClick={handleOpenSyncModal}
            variant={"ghost"}
            {...buttonData.buttonProps}
            {...styles}
          />
        </Box>
      </Tooltip>
    );
  }
);
