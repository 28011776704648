import { TSerializedDateRange } from "types/date.type";
import { LeadStageEnum } from "enums/lead-stage.enum";
import {
  TLeadsByOffice,
  TLeadsByOfficeStats,
  TLeadsInRangeCount,
  TLeadsStats,
} from "types/account-analytics.type";
import { DEFAULT_CSV_TITLE } from "constants/csv-exporter";
import { formatDate } from "./date.utils";
import { DATE_CALENDAR_FORMAT, DATE_FORMAT } from "constants/date";
import { Dictionary, groupBy } from "lodash";
import { IOfficeLeaderboardStats } from "./office-leaderboard.utils";
import {
  IAccountOfficePortalActivity,
  TOfficeActivitySupportedLeadTypes,
} from "types/account-portal-link-status-per-office.type";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  OFFICE_NOT_SUPPORTED,
  PORTAL_NOT_SUPPORTED,
} from "constants/account-activity-data";
import { orderSourceHosts } from "./lead-source.utils";

export interface IFlatOfficeLeadsByStage {
  [x: string]: string | number;
  OFFICE_NAME: string;
  TYPE: string;
}

export const formatCSVDateRangeTitle = (
  dateRange: TSerializedDateRange,
  prefix: string
) => {
  if (!dateRange) return DEFAULT_CSV_TITLE;
  return `${prefix}${formatDate(
    dateRange.startDate || "",
    DATE_CALENDAR_FORMAT
  )}-to-${formatDate(dateRange.endDate || "", DATE_CALENDAR_FORMAT)}`;
};

export const getCountByStage = (
  groupedStats: TLeadsByOfficeStats[],
  stageToFilter: LeadStageEnum
) => {
  let totalCount = 0;

  const foundStage = groupedStats.find(stats => stats.stage === stageToFilter);

  if (!!foundStage) totalCount = foundStage.count;

  return totalCount;
};

export const flattenDataForLeadsByStageCSV = (data: TLeadsByOffice[]) => {
  let flatArray: Array<IFlatOfficeLeadsByStage> = [];
  data.forEach(office => {
    const groupedStatsByType = groupBy(office.stats, stat => stat.type);

    Object.keys(groupedStatsByType).forEach(type => {
      const mappedType = {
        OFFICE_NAME: office.officeName,
        TYPE: type,
        [LeadStageEnum[LeadStageEnum.UNREAD]]: getCountByStage(
          groupedStatsByType[type],
          LeadStageEnum.UNREAD
        ),
        [LeadStageEnum[LeadStageEnum.IN_PROGRESS]]: getCountByStage(
          groupedStatsByType[type],
          LeadStageEnum.IN_PROGRESS
        ),
        [LeadStageEnum[LeadStageEnum.BUSINESS]]: getCountByStage(
          groupedStatsByType[type],
          LeadStageEnum.BUSINESS
        ),
        [LeadStageEnum[LeadStageEnum.NO_BUSINESS]]: getCountByStage(
          groupedStatsByType[type],
          LeadStageEnum.NO_BUSINESS
        ),
      };
      flatArray.push(mappedType);
    });
  });
  return flatArray;
};

export const formatLeaderboardForCSVDownload = (
  data: IOfficeLeaderboardStats[]
) => {
  return data.map(data => {
    return {
      ...data,
      office: data.office.name,
    };
  });
};

export type TFlatOfficeRow = {
  SOURCE: TNormalizedLeadSourceData["name"];
  TYPE: LeadTypeEnum;
  OFFICE_NAME: string;
  LAST_RECEIVED: string | null;
};

const supportedLeadTypes: TOfficeActivitySupportedLeadTypes[] = [
  LeadTypeEnum.Vendor,
  LeadTypeEnum.Landlord,
  LeadTypeEnum.Sale,
  LeadTypeEnum.Let,
];

export const flattenAccountOfficePortalActivity = (
  data: IAccountOfficePortalActivity[],
  leadSourcesMap: Dictionary<TNormalizedLeadSourceData>
) => {
  let flatArray: TFlatOfficeRow[] = [];

  data.forEach(officeActivity => {
    supportedLeadTypes.forEach(leadType => {
      const { name } = officeActivity;
      const typeData = officeActivity.types[leadType];

      if (!typeData) {
        const row = {
          OFFICE_NAME: name,
          TYPE: leadType,
          SOURCE: OFFICE_NOT_SUPPORTED,
          LAST_RECEIVED: OFFICE_NOT_SUPPORTED,
        };
        flatArray.push(row);
        return;
      }

      if (!typeData.length) {
        const row = {
          OFFICE_NAME: name,
          TYPE: leadType,
          SOURCE: PORTAL_NOT_SUPPORTED,
          LAST_RECEIVED: PORTAL_NOT_SUPPORTED,
        };
        flatArray.push(row);
      }

      typeData.forEach(source => {
        const row = {
          OFFICE_NAME: name,
          TYPE: leadType,
          SOURCE: leadSourcesMap[source.sourceId].name,
          LAST_RECEIVED: source.lastReceived
            ? formatDate(source.lastReceived, DATE_FORMAT)
            : "Not received in last 30 days",
        };
        flatArray.push(row);
      });
    });
  });
  return flatArray;
};

type TLeadsByXByDayData<T extends string> = {
  leadsByOption: TLeadsStats<T>;
  leadsInRangeCount: TLeadsInRangeCount<TLeadsStats<T>>;
};

type TKeysOfOptions<T extends string> = keyof TLeadsStats<T>;

export const formatLeadsByXByDayForCSVDownload = <T extends string>(
  data: TLeadsByXByDayData<T>
) => {
  const {
    leadsByOption,
    leadsInRangeCount: { previous30Days, previous60Days },
  } = data;

  const leadOptionKeys = Object.keys(leadsByOption) as TKeysOfOptions<T>[];

  let formattedLeads: {
    TYPE: string;
    DATES_SELECTED: number;
    LAST_30_DAYS: number;
    LAST_60_DAYS: number;
  }[] = [];

  leadOptionKeys.forEach(typeKey => {
    formattedLeads.push({
      TYPE: typeKey,
      DATES_SELECTED: leadsByOption[typeKey] ? leadsByOption[typeKey].total : 0,
      LAST_30_DAYS: previous30Days[typeKey] ? previous30Days[typeKey].total : 0,
      LAST_60_DAYS: previous60Days[typeKey] ? previous60Days[typeKey].total : 0,
    });

    if (leadsByOption[typeKey]) {
      let orderedHostKeys: string[] = [];

      const totalKeys = [
        ...Object.keys(leadsByOption[typeKey]?.hosts || []),
        ...Object.keys(previous30Days[typeKey]?.hosts || []),
        ...Object.keys(previous60Days[typeKey]?.hosts || []),
      ] as TKeysOfOptions<string>[];

      const hostKeys = totalKeys.filter((key, index) => {
        return totalKeys.indexOf(key) === index;
      });

      const otherIndex = hostKeys.indexOf("other");

      const orderHostKeys = (keys: string[]) =>
        keys.sort((a, b) => {
          return (
            leadsByOption[typeKey].hosts[b] - leadsByOption[typeKey].hosts[a]
          );
        });

      orderedHostKeys = orderSourceHosts<string>(
        hostKeys,
        otherIndex,
        orderHostKeys
      );

      orderedHostKeys.forEach(hostKey => {
        formattedLeads.push({
          TYPE: `(${typeKey}) ${hostKey}`,
          DATES_SELECTED: leadsByOption[typeKey]
            ? leadsByOption[typeKey].hosts[hostKey] || 0
            : 0,
          LAST_30_DAYS: previous30Days[typeKey]
            ? previous30Days[typeKey].hosts[hostKey] || 0
            : 0,
          LAST_60_DAYS: previous60Days[typeKey]
            ? previous60Days[typeKey].hosts[hostKey] || 0
            : 0,
        });
      });
    }
  });

  return formattedLeads;
};
