import { Center } from "@chakra-ui/react";
import React, { FC } from "react";

interface IProps {
  children: React.ReactNode;
}

export const FormToolPageSettingsEmptyList: FC<IProps> = ({ children }) => {
  return (
    <Center
      height={"300px"}
      width={"100%"}
      border={"1px solid"}
      borderColor={"leadpro.200"}
      bg={"leadpro.50"}
      color={"leadpro.500"}
      p={5}
    >
      {children}
    </Center>
  );
};
