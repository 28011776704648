import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, Circle, HStack } from "@chakra-ui/react";
import { LeadFilterSimpleMenu } from "./LeadFilterMenu/LeadFilterSimpleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { LEAD_STAGES_ORDER } from "constants/lead-stage-options";
import { stageColors } from "constants/colors";
import { TLeadStatusBasic } from "types/lead-status.type";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";
import { LEAD_SUBROUTE_ALL } from "constants/routes";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
  leadStatusesMapByStatusId: Record<number, TLeadStatusBasic>;
  subRoute: string;
}

const FILTER_TITLE = "Status";
export const FilterLeadsStatusHeader: FC<IProps> = observer(
  ({ leadsFilterStore, leadStatusesMapByStatusId, subRoute }) => {
    const { togglePendingFilterStatus } = leadsFilterStore;

    const { pendingFilter, activeFilter, availableFilters } = leadsFilterStore;

    const statusesOptions = useMemo(() => {
      if (!availableFilters || !availableFilters.statuses) return [];

      const items = availableFilters.statuses
        .slice()
        .sort((previous, next) => {
          return (
            LEAD_STAGES_ORDER.indexOf(
              leadStatusesMapByStatusId[previous.id]?.stageId
            ) -
            LEAD_STAGES_ORDER.indexOf(
              leadStatusesMapByStatusId[next.id]?.stageId
            )
          );
        })
        .map(status => {
          return {
            value: status.id,
            label: (
              <HStack spacing={2}>
                <Circle
                  size={"10px"}
                  background={
                    stageColors[leadStatusesMapByStatusId[status.id]?.stageId]
                  }
                />
                <Box>{status.name}</Box>
              </HStack>
            ),
            count: status.count,
          };
        });

      return items;
    }, [availableFilters, leadStatusesMapByStatusId]);

    const showFilter = useMemo(() => {
      return subRoute === LEAD_SUBROUTE_ALL;
    }, [subRoute]);

    return (
      <LeadFilterHeaderCell title={FILTER_TITLE}>
        {showFilter && (
          <LeadFilterSimpleMenu
            optionLabel={FILTER_TITLE}
            leadsFilterStore={leadsFilterStore}
            icon={
              <FontAwesomeIcon
                icon={faFilter}
                fontSize={LEAD_FILTER_ICON_SIZE}
              />
            }
            filterType={LeadSimpleFilterTypes.STATUSES}
            handleFilterItemToggle={(value: number) => {
              togglePendingFilterStatus(value);
            }}
            availableOptions={statusesOptions}
            selectedOptions={pendingFilter.statuses}
            appliedOptions={activeFilter?.statuses}
          />
        )}
      </LeadFilterHeaderCell>
    );
  }
);
