import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Sentry from "@sentry/react";
import { StackFrame } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { TAuthTokenDTO } from "types/token.type";
import { getTokenPayload } from "./jwt.utils";
const env = runtimeEnv();

const FILTER_OUT_FILES: Array<StackFrame["filename"]> = [
  "https://komito.net/komito.js",
];

export const initSentry = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
      release: env.REACT_APP_SENTRY_RELEASE,
      integrations: [new Integrations.BrowserTracing()],
      environment: env.SENTRY_ENV,
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.05,
      beforeSend: (event, hint) => {
        return shouldEventBeSent(event, hint) ? event : null;
      },
    });
  }
};

const configureUserForSentry = (userTokenData: TAuthTokenDTO): Sentry.User => {
  return {
    ...userTokenData,
    id: userTokenData.sub.toString(),
  };
};

export const setSentryUser = (user: Sentry.User): void => {
  Sentry.setUser(user);
};

export const setSentryContext = <T extends Object>(
  contextId: string,
  context: T
): void => {
  Sentry.setContext(contextId, context);
};

export const addAuthInfoToSentry = (token: string): void => {
  const decodedUser = getTokenPayload(token);

  if (decodedUser) {
    setSentryContext<{ jwt: string | null }>("origin-jwt", {
      jwt: token,
    });
    setSentryUser(configureUserForSentry(decodedUser));
  }
};

const shouldEventBeSent = (
  event: Sentry.Event,
  hint: Sentry.EventHint
): boolean => {
  // OIDC client error
  if (
    typeof hint.originalException === "string" &&
    hint.originalException.includes("login_required")
  )
    return false;
  // Recaptcha error
  if (
    typeof hint.originalException === "string" &&
    hint.originalException.includes(
      "Non-Error promise rejection captured with value: Timeout"
    )
  )
    return false;

  return !FILTER_OUT_FILES.find(
    filePath => !!findEventStackTraceFrameByFilePath(event, filePath)
  );
};

const findEventStackTraceFrameByFilePath = (
  event: Sentry.Event,
  filePath: StackFrame["filename"]
) => {
  if (!!event.exception?.values && !!filePath) {
    const exceptionStackTraceFrames =
      event.exception.values[0]?.stacktrace?.frames || [];

    return exceptionStackTraceFrames.find(frame =>
      frame.filename?.includes(filePath)
    );
  }
};
