import {
  TPageToolQuestionnaire,
  TQuestionnaireToolQuestionnaireResponsesResponse,
} from "types/questionnaire-tool-page.type";
import { makeAutoObservable } from "mobx";
import { AccountQuestionnairesApi } from "api/account-questionnaires.api";

const questionnairesApi = new AccountQuestionnairesApi();

export class AccountQuestionnairePageQuestionnaireStore {
  private readonly accountId: number;
  private questionnaireData: TPageToolQuestionnaire;
  private paginatedResponsesData: TQuestionnaireToolQuestionnaireResponsesResponse | null;

  constructor(accountId: number, questionnaireData: TPageToolQuestionnaire) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.questionnaireData = questionnaireData;
    this.paginatedResponsesData = null;
  }

  get questionnaire() {
    return this.questionnaireData;
  }

  get paginatedResponses() {
    return this.paginatedResponsesData;
  }

  public upsertQuestionnaire(questionnaireData: TPageToolQuestionnaire) {
    this.questionnaireData = questionnaireData;
  }

  public async updateQuestionnaire(data: Partial<TPageToolQuestionnaire>) {
    const updatedQuestionnaire = await questionnairesApi.update(
      this.accountId,
      this.questionnaire.id,
      data
    );
    this.upsertQuestionnaire(updatedQuestionnaire);
  }

  public async fetchQuestionnaireResponses(offset: number, limit: number) {
    const responses = await questionnairesApi.fetchQuestionnaireResponses(
      this.accountId,
      this.questionnaireData.id,
      offset,
      limit
    );
    this.setPaginatedResponses(responses);
  }

  private setPaginatedResponses(
    responses: TQuestionnaireToolQuestionnaireResponsesResponse
  ) {
    this.paginatedResponsesData = responses;
  }
}
