import { PageToolPages } from "../../../components/PageTool/PageToolPages";
import React, { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { MenuItem } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { TPageData } from "types/page.type";
import { TOOLS_FORMS_PAGE_DETAILS_RESPONSES_ROUTE } from "constants/routes";

interface IProps {
  accountStore: UserAccountStore;
}

export const FormToolPages: FC<IProps> = ({ accountStore }) => {
  const history = useHistory();

  const accountPageToolStore =
    accountStore.accountPagesStore.accountFormPagesStore;

  const handleGoToResponses = useCallback(
    (page: TPageData) => () => {
      history.push(
        TOOLS_FORMS_PAGE_DETAILS_RESPONSES_ROUTE.replace(
          ":id",
          page.id.toString()
        )
      );
    },
    [history]
  );

  return (
    <PageToolPages
      accountPageToolStore={accountPageToolStore}
      additionalMenuItemsRenderer={page => {
        return (
          <>
            <MenuItem onClick={handleGoToResponses(page)}>Responses</MenuItem>
          </>
        );
      }}
    />
  );
};
