import * as Yup from "yup";
import { REQUIRED_FIELD } from "../../constants/validator-messages";

export type TRexIntegrationConfigFieldValues = {
  rexCustomerId: string;
};

export const rexIntegrationInitialValues: TRexIntegrationConfigFieldValues = {
  rexCustomerId: "",
};

export const rexIntegrationConnectValidationSchema = Yup.object().shape({
  rexCustomerId: Yup.string().required(REQUIRED_FIELD),
});
