import { Box, BoxProps, HStack, MenuItem, Switch } from "@chakra-ui/react";
import {
  TLeadFilterMenuItemData,
  TLeadFilterMenuItemValue,
} from "types/leads-filter.type";
import { FC } from "react";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";

interface IProps {
  availableOption: TLeadFilterMenuItemData;
  onMenuItemClick: (
    value: TLeadFilterMenuItemValue,
    category?: LeadSimpleFilterTypes
  ) => () => void;
  category?: LeadSimpleFilterTypes;
  selectedOptions: TLeadFilterMenuItemValue[];
  textTransform?: BoxProps["textTransform"];
  disableCount?: boolean;
}

export const LeadFilterSimpleMenuItem: FC<IProps> = ({
  selectedOptions,
  onMenuItemClick,
  availableOption,
  category,
  textTransform = "capitalize",
  disableCount,
}) => {
  return (
    <MenuItem
      px={5}
      key={availableOption.value}
      onClick={onMenuItemClick(availableOption.value, category)}
    >
      <HStack justify={"space-between"} width={"100%"}>
        <HStack spacing={2} align={"center"}>
          <Switch
            size={"sm"}
            onChange={onMenuItemClick(availableOption.value, category)}
            isChecked={selectedOptions.includes(availableOption.value)}
          />
          <Box textTransform={textTransform}>{availableOption.label}</Box>
        </HStack>
        {!disableCount && (
          <Box color={"gray.400"} pl={2}>
            {availableOption.count}
          </Box>
        )}
      </HStack>
    </MenuItem>
  );
};
