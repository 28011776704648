import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { AccountFormPagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountFormPagesStore/AccountFormPages.store";
import { makeAutoObservable } from "mobx";

interface IFormToolPageRequestsStore {
  pageId: number | null;
  accountFormPagesStore: AccountFormPagesStore;
}

export class FormToolPageResponsesStore {
  public loadingStatus: ApiRequestStatusEnum;
  private readonly pageId: number | null;
  private readonly accountFormPagesStore: AccountFormPagesStore;

  constructor({ pageId, accountFormPagesStore }: IFormToolPageRequestsStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loadingStatus = ApiRequestStatusEnum.NONE;
    this.pageId = pageId;
    this.accountFormPagesStore = accountFormPagesStore;
  }

  get pageStore() {
    if (!this.pageId) return null;

    return (
      this.accountFormPagesStore.accountFormPageStoresMap[this.pageId] || null
    );
  }

  get formId() {
    if (!this.pageStore) return null;

    return this.pageStore.page.pageFormConfig.formId || null;
  }

  get formStore() {
    if (!this.formId) return null;

    return this.accountFormPagesStore.accountFormPageFormStoresMap[this.formId];
  }

  get listData() {
    const responses = this.formStore?.paginatedResponses;
    if (!responses) return null;

    return {
      data: responses.data,
      total: responses.meta.count,
      pageSize: responses.meta.limit,
      pageIndex: responses.meta.offset / responses.meta.limit,
      canPreviousPage: responses.meta.offset > 0,
      canNextPage:
        responses.meta.offset + responses.meta.limit < responses.meta.count,
    };
  }

  public async handleGoToPage(pageNumber: number) {
    const responses = this.formStore?.paginatedResponses;
    if (!responses) return;

    await this.loadData(
      pageNumber * responses.meta.limit,
      responses.meta.limit
    );
  }

  public async loadData(offset: number, limit: number) {
    if (!this.pageId) {
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      return;
    }

    try {
      this.setLoadingStatus(ApiRequestStatusEnum.PENDING);
      if (!this.pageStore) {
        await this.accountFormPagesStore.loadPage(this.pageId);
      }

      if (!!this.formId && !this.formStore) {
        await this.accountFormPagesStore.loadForm(this.formId);
      }

      await this.formStore?.fetchFormResponses(offset, limit);
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (error) {
      this.setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }

  private setLoadingStatus(loadingStatus: ApiRequestStatusEnum) {
    this.loadingStatus = loadingStatus;
  }
}
