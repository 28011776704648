import { observer } from "mobx-react";
import React, { FC, useMemo } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  HStack,
  Image,
  VStack,
} from "@chakra-ui/react";
import { SplashScreen } from "routes/dashboard/routes/tools/components/SplashScreen/SplashScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { TypeFormWidget } from "components";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { getTypeformOptions } from "utils/typeform.utils";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { SplashTestimonial } from "routes/dashboard/routes/tools/components/SplashScreen/SplashTestimonial";
import {
  FACEBOOK_ADS_ILLUSTRATION,
  PAUL_RAI_TESTIMONIAL,
} from "constants/image-paths";

const env = runtimeEnv();

const bulletPoints = [
  "Targeted vendor and landlord lead generation",
  "Increase brand awareness and stay top of mind with your target market",
  "Dedicated marketing expert",
];

const colorScheme = "cyan";

interface IProps {
  accountStore: UserAccountStore;
}

export const FacebookAdsSplashScreen: FC<IProps> = observer(
  ({ accountStore }) => {
    const {
      authStore: { authUser },
    } = useAppStore();
    const accountData = accountStore.account;

    const typeformWidgetOptions = useMemo(() => {
      return getTypeformOptions(
        {
          account: accountData?.id,
          email: authUser?.email,
          firstname: authUser?.firstName,
          phone: authUser?.phoneNumber,
        },
        {
          hideFooter: true,
        }
      );
    }, [accountData, authUser]);

    return (
      <SplashScreen
        background={`${colorScheme}.50`}
        topSection={
          <>
            <Box width={"500px"} mb={6}>
              <Box color={`${colorScheme}.600`} fontWeight={"600"} mb={3}>
                Facebook Ads
              </Box>
              <Box
                fontSize={"42px"}
                lineHeight={"1.1"}
                fontWeight={"700"}
                mb={4}
              >
                Generate Qualified Valuation Leads With Facebook Advertising
              </Box>
              <VStack spacing={3}>
                <Box fontSize={"xl"}>
                  Use lead pro tools as they should be used. We created a system
                  combining our tools with facebook that will generate leads for
                  you and your team.
                </Box>

                {bulletPoints.map((point, index) => (
                  <HStack
                    key={index}
                    spacing={4}
                    align={"flex-start"}
                    width={"100%"}
                  >
                    <Center color={"te.600"} flexShrink={0}>
                      <FontAwesomeIcon icon={faCheckCircle} fontSize={28} />
                    </Center>
                    <Box>{point}</Box>
                  </HStack>
                ))}
                <VStack spacing={2} align={"flex-start"} width={"100%"}>
                  <TypeFormWidget
                    typeFormId={env.REACT_APP_TYPEFORM_ID_FACEBOOK}
                    options={typeformWidgetOptions}
                  >
                    <Button
                      size={"lg"}
                      colorScheme={colorScheme}
                      color={"white"}
                    >
                      Find out more
                    </Button>
                  </TypeFormWidget>
                </VStack>
              </VStack>
            </Box>
            <AspectRatio ratio={1} width={"400px"}>
              <Image src={FACEBOOK_ADS_ILLUSTRATION} />
            </AspectRatio>
          </>
        }
        bottomSection={
          <SplashTestimonial
            quote={
              "My experience with LeadPro has been remarkable. In the past I have had such mixed results with social media. Thanks to LeadPro combined with Facebook, it has become my primary source of new business."
            }
            author={
              "Paul Rai, Owner, EweMove Estate Agent Gillingham & Hempstead"
            }
            authorImageUrl={PAUL_RAI_TESTIMONIAL}
            colorScheme={colorScheme}
          />
        }
      />
    );
  }
);
