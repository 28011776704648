import { LeadTypeEnum } from "enums/lead-type.enum";
import { TManualAddLeadFieldValues } from "types/manual-add-lead.type";

export const isTypeSaleOrLet = (type: LeadTypeEnum) => {
  return [LeadTypeEnum.Sale, LeadTypeEnum.Let].includes(type);
};

export const isTypeSaleOrLetOrMortgage = (type: LeadTypeEnum) => {
  return [
    LeadTypeEnum.Sale,
    LeadTypeEnum.Let,
    LeadTypeEnum.MortgageRequest,
  ].includes(type);
};

export const shouldGeneralEnquiryShow = (
  type: TManualAddLeadFieldValues["type"]
) => {
  return isTypeSaleOrLetOrMortgage(type);
};

export const shouldPropertyDetailsShow = (
  type: TManualAddLeadFieldValues["type"],
  generalEnquiry: TManualAddLeadFieldValues["generalEnquiry"]
) => {
  return isTypeSaleOrLetOrMortgage(type) && !generalEnquiry;
};
