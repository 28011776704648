import { observer } from "mobx-react";
import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { useHistory, useLocation } from "react-router-dom";

export interface IPageToolPageSettingsLayoutProps {
  title: React.ReactNode;
  loadingStatus?: ApiRequestStatusEnum;
  commonFooter?: React.ReactNode;
  config: {
    tabHeader: React.ReactNode;
    tabComponent: React.ReactNode;
    tabHash?: string;
    disabled?: boolean;
  }[];
}
export const PageToolPageSettingsLayout: FC<IPageToolPageSettingsLayoutProps> = observer(
  ({
    title,
    loadingStatus = ApiRequestStatusEnum.SUCCESS,
    config,
    commonFooter,
  }) => {
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();

    const tabHashes = useMemo(() => {
      return config.map(configElement => configElement.tabHash);
    }, [config]);

    const [currentTabIndex, setCurrentTabIndex] = useState(
      Math.max(tabHashes.indexOf(location.hash), 0)
    );

    useEffect(() => {
      const newHash = tabHashes[currentTabIndex];
      if (!!newHash && newHash !== location.hash) {
        history.replace(newHash);
      }
    }, [history, tabHashes, currentTabIndex, location.hash]);

    const onTabChange = useCallback(
      (tabIndex: number) => {
        setCurrentTabIndex(tabIndex);
      },
      [setCurrentTabIndex]
    );

    return (
      <Box
        position={"relative"}
        height={"100%"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
      >
        <Tabs
          index={currentTabIndex}
          onChange={onTabChange}
          isLazy={true}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          flexGrow={1}
          variant={"unstyled"}
          overflow={"hidden"}
        >
          <HStack
            spacing={20}
            height={"84px"}
            paddingX={16}
            borderBottom={"1px solid"}
            borderBottomColor={"leadpro.200"}
            flexShrink={0}
          >
            <Box fontWeight={700} fontSize={"lg"} minWidth={"200px"}>
              {title}
            </Box>
            <TabList flexGrow={1}>
              {config.map((config, index) => (
                <Tab
                  key={index}
                  height={"100%"}
                  fontWeight={500}
                  transition={"text-shadow .3s"}
                  isDisabled={config.disabled}
                  _selected={{
                    color: "leadpro.800",
                    textShadow: `0 0 .69px ${theme.colors.leadpro[800]}, 0 0 .69px ${theme.colors.leadpro[800]}, 0 0 .69px ${theme.colors.leadpro[800]}`,
                  }}
                  _disabled={{
                    color: "leadpro.400",
                    cursor: "not-allowed",
                  }}
                >
                  {config.tabHeader}
                </Tab>
              ))}
            </TabList>
          </HStack>
          {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
          {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
          {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
            <TabPanels
              display={"flex"}
              flexDirection={"column"}
              flexGrow={1}
              overflow={"hidden"}
              tabIndex={undefined}
            >
              {config.map((config, index) => (
                <TabPanel key={index} flexGrow={1} overflowY={"auto"} p={0}>
                  {config.tabComponent}
                </TabPanel>
              ))}
            </TabPanels>
          )}
        </Tabs>
        {commonFooter}
      </Box>
    );
  }
);
