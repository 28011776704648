import { IObservableArray, makeAutoObservable, observable } from "mobx";
import {
  TAccountIntegrationData,
  TAccountIntegrationsStaticData,
  TIntegrationConfig,
} from "types/account-integration.type";
import { keyBy, sortBy } from "lodash";
import { AccountIntegrationTypeEnum } from "enums/account-integration-type.enum";
import { AccountIntegrationReapitStore } from "./AccountIntegrationReapit/AccountIntegrationReapit.store";
import { AppStore } from "store/App.store";
import { AccountIntegrationsApi } from "api/account-integrations.api";
import { AccountIntegrationZooplaStore } from "./AccountIntegrationZoopla.store";
import { AccountIntegrationReapitIRStore } from "./AccountIntegrationReapitIR.store";
import { AccountIntegrationOfficesStore } from "./AccountIntegrationOfficesStore.store";
import { AccountRightmoveApi } from "api/account-rightmove.api";
import { AccountStreetApi } from "api/account-street.api";
import { AccountIntegrationOpenViewStore } from "./AccountIntegrationOpenView/AccountIntegrationOpenView.store";
import { AccountIntegrationRexStore } from "./AccountIntegrationRex.store";
import { AccountIntegrationUrlSyncStore } from "./AccountIntegrationUrlSync.store";
import { AccountIntegrationAltoStore } from "./AccountIntegrationAlto/AccountIntegrationAlto.store";
import { AccountIntegrationSmeStore } from "./AccountIntegrationSme/AccountIntegrationSme.store";

const integrationsApi = new AccountIntegrationsApi();
const accountRightMoveApi = new AccountRightmoveApi();
const streetApi = new AccountStreetApi();

export class AccountIntegrationsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private readonly availableAccountIntegrations: IObservableArray<
    TAccountIntegrationsStaticData
  >;
  private readonly accountIntegrations: IObservableArray<
    TAccountIntegrationData<TIntegrationConfig>
  >;
  public readonly accountIntegrationReapitStore: AccountIntegrationReapitStore;
  public readonly accountIntegrationsReapitIRStore: AccountIntegrationReapitIRStore;
  public readonly accountIntegrationZooplaStore: AccountIntegrationZooplaStore;
  public readonly accountIntegrationRightmoveStore: AccountIntegrationOfficesStore;
  public readonly accountIntegrationStreetStore: AccountIntegrationOfficesStore;
  public readonly accountIntegrationOpenViewStore: AccountIntegrationOpenViewStore;
  public readonly accountIntegrationRexStore: AccountIntegrationRexStore;
  public readonly accountIntegrationUrlSyncStore: AccountIntegrationUrlSyncStore;
  public readonly accountIntegrationAltoStore: AccountIntegrationAltoStore;
  public readonly accountIntegrationSmeStore: AccountIntegrationSmeStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.availableAccountIntegrations = observable.array<
      TAccountIntegrationsStaticData
    >();
    this.accountIntegrations = observable.array<
      TAccountIntegrationData<TIntegrationConfig>
    >();
    this.accountIntegrationRexStore = new AccountIntegrationRexStore(
      root,
      this.accountId
    );
    this.accountIntegrationReapitStore = new AccountIntegrationReapitStore(
      root,
      this.accountId
    );
    this.accountIntegrationsReapitIRStore = new AccountIntegrationReapitIRStore(
      root,
      this.accountId
    );
    this.accountIntegrationZooplaStore = new AccountIntegrationZooplaStore(
      root,
      this.accountId
    );
    this.accountIntegrationRightmoveStore = new AccountIntegrationOfficesStore(
      root,
      this.accountId,
      accountRightMoveApi
    );
    this.accountIntegrationStreetStore = new AccountIntegrationOfficesStore(
      root,
      this.accountId,
      streetApi
    );
    this.accountIntegrationOpenViewStore = new AccountIntegrationOpenViewStore(
      root,
      this.accountId
    );
    this.accountIntegrationAltoStore = new AccountIntegrationAltoStore(
      root,
      this.accountId
    );
    this.accountIntegrationUrlSyncStore = new AccountIntegrationUrlSyncStore(
      root,
      this.accountId
    );
    this.accountIntegrationSmeStore = new AccountIntegrationSmeStore(
      root,
      this.accountId
    );
  }

  get availableAccountIntegrationsArray() {
    return this.availableAccountIntegrations.slice();
  }

  get availableAccountIntegrationsMapByType() {
    return keyBy(
      this.availableAccountIntegrationsArray,
      accountIntegration => accountIntegration.type
    );
  }

  get availableIntegrationsSortedArray() {
    const sortedByName = sortBy(
      this.availableAccountIntegrationsArray,
      integration => integration.name
    );
    const active: TAccountIntegrationsStaticData[] = [];
    const inactive: TAccountIntegrationsStaticData[] = [];
    const notImplemented: TAccountIntegrationsStaticData[] = [];

    sortedByName.forEach(integration => {
      if (!!this.accountIntegrationsMapByType[integration.type]) {
        active.push(integration);
      } else if (!!integration.voteUrl) {
        notImplemented.push(integration);
      } else {
        inactive.push(integration);
      }
    });
    return [...active, ...inactive, ...notImplemented];
  }

  get accountIntegrationsMapByType() {
    return keyBy(
      this.accountIntegrations,
      accountIntegration => accountIntegration.type
    );
  }

  get hasActiveReapitIntegration() {
    return (
      !!this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.REAPIT] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.REAPIT]
        .active
    );
  }

  get hasActiveManualAltoIntegration() {
    return (
      !!this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.ALTO] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.ALTO]
        .active &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.ALTO]
        .isManuallyTriggered
    );
  }

  get hasActiveUrlSyncIntegration() {
    return (
      !!this.accountIntegrationsMapByType[
        AccountIntegrationTypeEnum.URL_SYNC
      ] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.URL_SYNC]
        .active
    );
  }

  get hasActiveManualMRIRadarIntegration() {
    return (
      !!this.accountIntegrationsMapByType[
        AccountIntegrationTypeEnum.MRI_RADAR
      ] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.MRI_RADAR]
        .active &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.MRI_RADAR]
        .isManuallyTriggered
    );
  }

  get hasActiveOpenViewIntegration() {
    return (
      !!this.accountIntegrationsMapByType[
        AccountIntegrationTypeEnum.OPEN_VIEW
      ] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.OPEN_VIEW]
        .active
    );
  }

  get hasActiveRightmoveIntegration() {
    return (
      !!this.accountIntegrationsMapByType[
        AccountIntegrationTypeEnum.RIGHTMOVE
      ] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.RIGHTMOVE]
        .active
    );
  }

  get hasActiveSmeIntegration() {
    return (
      !!this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.SME] &&
      this.accountIntegrationsMapByType[AccountIntegrationTypeEnum.SME].active
    );
  }

  get hasEcosystemIntegration() {
    return this.accountIntegrations.some(
      integration => integration.instanceId && integration.active
    );
  }

  public setAccountIntegrations(
    accountIntegrations: TAccountIntegrationData<TIntegrationConfig>[]
  ) {
    this.accountIntegrations.replace(accountIntegrations);
  }

  public setAvailableAccountIntegrations(
    accountIntegrations: TAccountIntegrationsStaticData[]
  ) {
    this.availableAccountIntegrations.replace(accountIntegrations);
  }

  public removeAccountIntegration(integrationId: number) {
    const index = this.accountIntegrations.findIndex(
      accountIntegration => accountIntegration.id === integrationId
    );
    if (index > -1) {
      this.accountIntegrations.splice(index, 1);
    }
  }

  public upsertAccountIntegration(
    accountIntegrationData: TAccountIntegrationData<TIntegrationConfig>
  ) {
    const index = this.accountIntegrations.findIndex(
      accountIntegration => accountIntegration.id === accountIntegrationData.id
    );
    if (index > -1) {
      this.accountIntegrations[index] = accountIntegrationData;
    } else {
      this.accountIntegrations.push(accountIntegrationData);
    }
  }

  public async fetchAvailableAccountIntegrations() {
    const integrations = await integrationsApi.fetchAvailableAccountIntegrations(
      this.accountId
    );

    this.setAvailableAccountIntegrations(integrations);
  }

  public async fetchAccountIntegrations() {
    const integrations = await integrationsApi.fetchAllAccountIntegrations(
      this.accountId
    );

    this.setAccountIntegrations(integrations);
  }

  public async createAccountIntegration<T extends TIntegrationConfig>(
    data: Partial<TAccountIntegrationData<T>>
  ) {
    const accountIntegration = await integrationsApi.createAccountIntegration(
      this.accountId,
      data
    );

    this.upsertAccountIntegration(accountIntegration);
  }

  public async updateAccountIntegration<T extends TIntegrationConfig>(
    accountIntegrationId: number,
    data: Partial<TAccountIntegrationData<T>>
  ) {
    const accountIntegration = await integrationsApi.updateAccountIntegration(
      this.accountId,
      accountIntegrationId,
      data
    );

    this.upsertAccountIntegration(accountIntegration);
  }

  public async deleteAccountIntegration(accountIntegrationId: number) {
    await integrationsApi.deleteAccountIntegration(
      this.accountId,
      accountIntegrationId
    );

    this.removeAccountIntegration(accountIntegrationId);
  }
}
