import { VStack } from "@chakra-ui/react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountPropertyStore } from "store/UserAccounts/UserAccount/AccountProperties/AccountProperty.store";
import React, { FC, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { Error, Loader } from "components";
import { PropertyDetailsQuestionnaireFilterItem } from "./PropertyDetailsQuestionnaireFilterItem";

interface IProps {
  accountStore: UserAccountStore;
  propertyStore: AccountPropertyStore;
}

export const PropertyDetailsQuestionnaireFilter: FC<IProps> = observer(
  ({ accountStore, propertyStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;

    const onQuestionFilterChange = useCallback(
      (question: string, answers?: string[]) => {
        propertyStore.propertyLeadsStore.leadsFilterStore.setPendingFilterQuestionAnswer(
          question,
          answers
        );
        propertyStore.propertyLeadsStore.leadsFilterStore.setActiveFromPendingFilter();
      },
      [propertyStore.propertyLeadsStore.leadsFilterStore]
    );

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await propertyStore.propertyLeadsStore.leadsFilterStore.loadAvailableFilters(
            LeadSimpleFilterTypes.QUESTIONNAIRE,
            [propertyStore.property.id]
          );
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountLeadSourcesStore,
      accountStore,
      propertyStore.property.id,
      propertyStore.propertyLeadsStore.leadsFilterStore,
    ]);

    return (
      <VStack spacing={8} flexGrow={1} width={"100%"}>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS &&
          propertyStore.propertyLeadsStore.leadsFilterStore.availableFilters?.[
            LeadSimpleFilterTypes.QUESTIONNAIRE
          ]?.map(question => (
            <PropertyDetailsQuestionnaireFilterItem
              key={question.id}
              question={question}
              selectedAnswer={
                propertyStore.propertyLeadsStore.leadsFilterStore.pendingFilter[
                  LeadSimpleFilterTypes.QUESTIONNAIRE
                ][question.id]?.[0]
              }
              onChange={onQuestionFilterChange}
            />
          ))}
      </VStack>
    );
  }
);
