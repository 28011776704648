import { Redirect, Route, Switch } from "react-router-dom";
import {
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE,
  TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE,
  TOOLS_LEAD_RESPONDER_ROUTE,
} from "constants/routes";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { QuestionnaireToolPageDetails } from "./QuestionnaireToolPageDetails/QuestionnaireToolPageDetails";

interface IProps {
  accountStore: UserAccountStore;
}

export const LeadResponderQuestionnairePages: FC<IProps> = ({
  accountStore,
}) => {
  return (
    <Switch>
      <Route path={TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGES_ROUTE} exact>
        <Redirect to={`${TOOLS_LEAD_RESPONDER_ROUTE}#questionnaires`} />
      </Route>
      <Route path={TOOLS_LEAD_RESPONDER_QUESTIONNAIRE_PAGE_DETAILS_ROUTE}>
        <QuestionnaireToolPageDetails accountStore={accountStore} />
      </Route>
    </Switch>
  );
};
