import { Box, Button } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { NA } from "components";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { observer } from "mobx-react";
import { AccountBillingStore } from "store/UserAccounts/UserAccount/AccountBilling.store";
import { AccountBillingCustomerContactPrompt } from "routes/dashboard/components/prompts/AccountBillingCustomerContactPrompt";

interface IProps {
  accountBillingStore: AccountBillingStore;
}

export const AccountBillingContact: React.FC<IProps> = observer(
  ({ accountBillingStore }) => {
    const { setModal, unSetModal } = useActionPrompt();
    const accountBilling = accountBillingStore.billing;
    const stripeCustomerEmail = accountBilling?.stripeCustomerEmail;
    const isDisabled = !accountBilling?.stripePaymentMethodLast4;

    const handleOpenBillingContactFormPrompt = useCallback(() => {
      setModal(
        <AccountBillingCustomerContactPrompt
          accountBillingStore={accountBillingStore}
          closePrompt={unSetModal}
        />
      );
    }, [accountBillingStore, setModal, unSetModal]);

    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        py={5}
      >
        <Box color={"blue.500"} width={"200px"} pr={4} fontWeight={500}>
          Billing contact
        </Box>

        <Box flexGrow={1}>
          {!!stripeCustomerEmail ? <Box>{stripeCustomerEmail}</Box> : <NA />}
        </Box>

        <Box>
          <Button
            onClick={handleOpenBillingContactFormPrompt}
            variant="link"
            size={"md"}
            colorScheme={"blue"}
            isDisabled={isDisabled}
          >
            Edit
          </Button>
        </Box>
      </Box>
    );
  }
);
