import { observer } from "mobx-react";
import { AccountDevelopersApiKeysStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersApiKeys/AccountDevelopersApiKeys.store";
import React, { FC } from "react";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { developersConfigApiKeyValidation } from "utils/validation-schemas/developers-config-api-key.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type TFieldValues = {
  name: string;
};

const initialValues: TFieldValues = {
  name: "",
};

interface IProps {
  accountDevelopersApiKeysStore: AccountDevelopersApiKeysStore;
  closePrompt: () => void;
}

export const DevelopersConfigApiKeyFormPrompt: FC<IProps> = observer(
  ({ accountDevelopersApiKeysStore, closePrompt }) => {
    const toast = useToast();

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(developersConfigApiKeyValidation),
    });

    const onSubmit = async (data: TFieldValues) => {
      try {
        await accountDevelopersApiKeysStore.createApiKeyConfig(data);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={"API key created."} />,
        });
        closePrompt();
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    return (
      <ActionPromptContainer
        header={"Create API key"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TFieldValues>
                  name={"name"}
                  control={control}
                  label={"Name"}
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "Enter API key name",
                  }}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Create
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
