import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { Box, HStack } from "@chakra-ui/react";
import { LeadFilterSimpleMenu } from "./LeadFilterMenu/LeadFilterSimpleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { sortBy } from "lodash";
import { getUserFullName } from "utils/account-user.utils";
import { UserAvatar } from "components";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { LeadFilterHeaderCell } from "./LeadFilterHeaderCell";

interface IProps {
  leadsFilterStore: AccountLeadsFiltersStore;
}

const FILTER_TITLE = "Assignee";

export const FilterLeadsAssigneeHeader: FC<IProps> = observer(
  ({ leadsFilterStore }) => {
    const {
      authStore: { authUser },
    } = useAppStore();
    const {
      pendingFilter,
      activeFilter,
      availableFilters,
      togglePendingFilterAssignedTo,
    } = leadsFilterStore;

    const assignedToOptions = useMemo(() => {
      if (!availableFilters || !availableFilters.assignedTo || !authUser)
        return [];

      let assignedToOptions = [];
      const me = availableFilters.assignedTo.find(
        user => user.id === authUser.id
      ) || {
        id: authUser.id,
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        email: authUser.email,
        count: 0,
      };

      assignedToOptions.push(me);
      assignedToOptions = assignedToOptions.concat(
        sortBy(
          availableFilters.assignedTo.filter(user => user.id !== authUser.id),
          user => getUserFullName(user.firstName, user.lastName) || user.email
        )
      );

      return assignedToOptions.map(assignedTo => ({
        value: assignedTo.id,
        label: (
          <HStack spacing={2}>
            <UserAvatar user={assignedTo} size={"xs"} />
            {assignedTo.id === authUser.id ? (
              <Box>Me</Box>
            ) : (
              <Box textTransform={"none"}>
                {getUserFullName(assignedTo.firstName, assignedTo.lastName) ||
                  assignedTo.email}
              </Box>
            )}
          </HStack>
        ),
        count: assignedTo.count,
      }));
    }, [authUser, availableFilters]);

    return (
      <LeadFilterHeaderCell title={FILTER_TITLE}>
        <LeadFilterSimpleMenu
          optionLabel={FILTER_TITLE}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          filterType={LeadSimpleFilterTypes.ASSIGNED_TO}
          availableOptions={assignedToOptions}
          selectedOptions={pendingFilter.assignedTo}
          appliedOptions={activeFilter?.assignedTo}
          handleFilterItemToggle={(value: number) =>
            togglePendingFilterAssignedTo(value)
          }
          leadsFilterStore={leadsFilterStore}
        />
      </LeadFilterHeaderCell>
    );
  }
);
