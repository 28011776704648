import { Box, Divider, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import { leadResponderEmailBlockHoc } from "utils/hoc/leadResponderEmailBlock.hoc";
import { formatDate } from "utils/date.utils";
import { DATE_TIME_FORMAT } from "constants/date";
import { EmailBlockItem } from "./EmailBlockItem";

export const EmailDetailsBlock = leadResponderEmailBlockHoc(
  ({ blockDefinition }) => {
    const emailData = blockDefinition.email;
    return (
      <VStack align={"left"}>
        <Box as="h2" fontSize="md" fontWeight="bold">
          Email
        </Box>
        <Divider />
        <Wrap spacing={10} w="100%" justify={"left"}>
          <WrapItem>
            <EmailBlockItem title="To" component={emailData?.to} />
          </WrapItem>
          <WrapItem>
            <EmailBlockItem title="CC" component={emailData?.parsedEmail?.cc} />
          </WrapItem>
          <WrapItem>
            <EmailBlockItem title="From" component={emailData?.from} />
          </WrapItem>
          <WrapItem>
            <EmailBlockItem
              title="Date Received"
              component={
                !!emailData?.parsedEmail?.date &&
                formatDate(emailData?.parsedEmail?.date, DATE_TIME_FORMAT)
              }
            />
          </WrapItem>
          <WrapItem>
            <EmailBlockItem
              title="Subject Line"
              component={emailData?.subject}
            />
          </WrapItem>
        </Wrap>
      </VStack>
    );
  }
);
