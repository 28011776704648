import { ChangeEvent, FC, useCallback } from "react";
import { SearchDelayedInput } from "components";

interface IProps {
  searchQuery?: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export const SearchQueryInput: FC<IProps> = ({
  searchQuery,
  onChange,
  placeholder,
}) => {
  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <SearchDelayedInput
      delay={1000}
      width={"450px"}
      placeholder={placeholder}
      value={searchQuery}
      handleSearch={onSearchInputChange}
      inputStyle={{
        bg: "white",
        _placeholder: {
          color: "leadpro.400",
        },
      }}
    />
  );
};
