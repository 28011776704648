import { observer } from "mobx-react";
import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FormControlV2 } from "components";
import { validateFormFieldsFromSchemaExcludingLogic } from "utils/validation-schemas/form-tool-schema.validation";
import {
  FormFieldRadio,
  FormFieldText,
  FormFieldType,
  FormSchema,
} from "@leadpro/forms";
import { FormToolPageWIPFormStore } from "../../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";

type TFieldValues = FormSchema["fields"]["key"];

interface IProps {
  questionKey: string;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsSelectedQuestionConfiguratorMenuSettings: FC<IProps> = observer(
  ({ questionKey, formToolPageWIPFormStore }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const question =
      formToolPageWIPFormStore.wipFormSchemaJS.fields[questionKey];

    const initialValues = useMemo(() => {
      const values: Partial<TFieldValues> = {
        label: question.label || "",
        placeholder: question.placeholder || "",
        required: question.required || false,
      };

      switch (question.type) {
        case FormFieldType.RADIO:
          (values as FormFieldRadio).options = [...question.options];
          (values as FormFieldRadio).multiple = question.multiple;
          (values as FormFieldRadio).otherOption = question.otherOption;
          // TODO FORMS: SHOULD THIS BE HARDCODED?
          (values as FormFieldRadio).otherLabel = "Other";
          (values as FormFieldRadio).otherMinLength = 0;
          (values as FormFieldRadio).otherMaxLength = 255;
          break;
        case FormFieldType.TEXT:
        case FormFieldType.TEXT_AREA:
          (values as FormFieldText).minLength = question.minLength;
          (values as FormFieldText).maxLength = question.maxLength;
          break;
      }

      return values;
    }, [question]);

    const { handleSubmit, control } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: values =>
        validateFormFieldsFromSchemaExcludingLogic<TFieldValues>(
          {
            ...formToolPageWIPFormStore.wipFormSchemaJS,
            ...{
              fields: {
                ...formToolPageWIPFormStore.wipFormSchemaJS.fields,
                [questionKey]: { ...question, ...values },
              },
            },
          },
          values
        ),
    });

    const onSubmit = useCallback(
      (values: TFieldValues) => {
        formToolPageWIPFormStore.updateQuestion(questionKey, values);
        onClose();
      },
      [questionKey, formToolPageWIPFormStore, onClose]
    );

    return (
      <Popover
        closeOnBlur={false}
        isLazy={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Box color={"blue.500"}>Settings</Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader fontSize={"md"} fontWeight={500}>
              Modify properties
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody pt={5}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={5} width={"100%"} alignItems={"stretch"}>
                  <FormControlV2<TFieldValues>
                    name={"label"}
                    label={"Question label"}
                    control={control}
                    type={FormControlsTypeEnum.TEXT}
                    additionalProps={{
                      size: "sm",
                    }}
                  />
                  <FormControlV2<TFieldValues>
                    name={"placeholder"}
                    label={"Question input placeholder"}
                    control={control}
                    type={FormControlsTypeEnum.TEXT}
                    additionalProps={{
                      size: "sm",
                    }}
                  />
                  {(question.type === FormFieldType.TEXT ||
                    question.type === FormFieldType.TEXT_AREA) && (
                    <FormControlV2<TFieldValues>
                      name={"minLength"}
                      label={"Minimum answer length"}
                      control={control}
                      type={FormControlsTypeEnum.NUMBER}
                      additionalProps={{
                        size: "sm",
                      }}
                    />
                  )}
                  {(question.type === FormFieldType.TEXT ||
                    question.type === FormFieldType.TEXT_AREA) && (
                    <FormControlV2<TFieldValues>
                      name={"maxLength"}
                      label={"Maximum answer length"}
                      control={control}
                      type={FormControlsTypeEnum.NUMBER}
                      additionalProps={{
                        size: "sm",
                      }}
                    />
                  )}
                  {question.type === FormFieldType.RADIO && (
                    <>
                      <FormControlV2<TFieldValues>
                        label={"Options"}
                        name={"options"}
                        control={control}
                        type={FormControlsTypeEnum.TAG_INPUT}
                        transformFrom={(options: FormFieldRadio["options"]) =>
                          options.map(option => option.label)
                        }
                        transformTo={(values: string[]) =>
                          values.map(value => ({ value, label: value }))
                        }
                      />
                      <FormControlV2<TFieldValues>
                        name={"multiple"}
                        label={"Multi-select"}
                        control={control}
                        type={FormControlsTypeEnum.CHECKBOX}
                      />
                      <FormControlV2<TFieldValues>
                        name={"otherOption"}
                        label={`Add "Other" option`}
                        control={control}
                        type={FormControlsTypeEnum.CHECKBOX}
                      />
                    </>
                  )}
                  <FormControlV2<TFieldValues>
                    name={"required"}
                    label={"Required question"}
                    control={control}
                    type={FormControlsTypeEnum.CHECKBOX}
                  />
                </VStack>
              </form>
            </PopoverBody>
            <PopoverFooter border={"none"}>
              <ButtonGroup
                size="sm"
                spacing={2}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button colorScheme={"red"} onClick={handleSubmit(onSubmit)}>
                  Apply
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  }
);
