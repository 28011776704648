import { useClipboard } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import * as Yup from "yup";
import { ExternalLink, StandardIconButton } from "components";
import { LeadInfoRowValue } from "./LeadInfoRowValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

const URL_MAX_SIZE = 55;
const isUrl = (value: string) => {
  try {
    Yup.string()
      .url()
      .validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
};

interface IProps {
  value?: string;
  metaKey: string;
}

export const LeadInfoSmartRowValue: FC<IProps> = ({ value, metaKey }) => {
  const { onCopy, hasCopied } = useClipboard(value || "");

  const subComponent = useMemo(() => {
    if (!value) return null;

    if (isUrl(value)) {
      return (
        <ExternalLink
          label={
            value.length > URL_MAX_SIZE
              ? value.substring(0, URL_MAX_SIZE) + "..."
              : value
          }
          href={value}
        />
      );
    }

    return value;
  }, [value]);

  return (
    <>
      <LeadInfoRowValue>{subComponent}</LeadInfoRowValue>
      {!!value && (
        <StandardIconButton
          onClick={onCopy}
          aria-label={`copy-${metaKey}`}
          fontSize={"16px"}
          colorScheme={hasCopied ? "green" : undefined}
          icon={<FontAwesomeIcon icon={faCopy} />}
        />
      )}
    </>
  );
};
