import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlError,
  FormControlMeta,
  FormControlV2,
} from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, HStack, useToast } from "@chakra-ui/react";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import {
  officeBasicValidationSchema,
  TOfficeFormFieldValues,
} from "utils/validation-schemas/office-basic.validation";
import { observer } from "mobx-react";
import { AccountOfficeStore } from "store/UserAccounts/UserAccount/AccountOffices/AccountOffice.store";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { TReapitOffice } from "types/reapit.type";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { OfficeFormContactDataInput } from "./OfficeFormContactDataInput";
import { TAccountOfficeData } from "types/account-office.type";
import { FormInputValueCounter } from "components/form/FormInputCounter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReapitOfficeSync } from "./ReapitOfficeSync";

type PartialOfficeData = Pick<
  TAccountOfficeData,
  | "name"
  | "website"
  | "postcodes"
  | "isSales"
  | "isLettings"
  | "isMortgage"
  | "salesEmails"
  | "lettingsEmails"
  | "vendorEmails"
  | "landlordEmails"
  | "salesPhoneNumber"
  | "lettingsPhoneNumber"
  | "vendorPhoneNumber"
  | "landlordPhoneNumber"
  | "mortgagePhoneNumber"
  | "officeGroupIds"
>;

interface IProps {
  officeStore?: AccountOfficeStore;
  accountStore: UserAccountStore;
  closePrompt: () => void;
}

export const OfficeFormPrompt: React.FC<IProps> = observer(
  ({ officeStore, accountStore, closePrompt }) => {
    const toast = useToast();
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [selectedReapitOfficeId, setSelectedReapitOfficeId] = useState<
      TReapitOffice["reapitId"] | null
    >(null);

    const officesStore = accountStore.accountOfficesStore;
    const officeGroupsStore = accountStore.accountOfficeGroupsStore;
    const accountIntegrationStore = accountStore.accountIntegrationsStore;
    const reapitIntegrationStore =
      accountIntegrationStore.accountIntegrationReapitStore;
    const reapitIntegrationOfficesStore =
      reapitIntegrationStore.accountIntegrationReapitOfficesStore;
    const hasReapitIntegration =
      accountIntegrationStore.hasActiveReapitIntegration;

    const initialValues: TOfficeFormFieldValues = useMemo(() => {
      return {
        isAdvancedContactData: officeStore
          ? officeStore.areAllContactDataSame
          : false,
        name: officeStore?.office.name || "",
        website: officeStore?.office.website || "",
        postcodes: officeStore?.office.postcodes?.split(",") || [],
        isSales: officeStore ? officeStore?.office.isSales : true,
        isLettings: officeStore ? officeStore?.office.isLettings : true,
        isMortgage: officeStore ? officeStore?.office.isMortgage : true,
        salesEmails: officeStore?.office.salesEmails?.split(",") || [],
        lettingsEmails: officeStore?.office.lettingsEmails?.split(",") || [],
        vendorEmails: officeStore?.office.vendorEmails?.split(",") || [],
        landlordEmails: officeStore?.office.landlordEmails?.split(",") || [],
        salesPhoneNumber: officeStore?.office.salesPhoneNumber || "",
        lettingsPhoneNumber: officeStore?.office.lettingsPhoneNumber || "",
        vendorPhoneNumber: officeStore?.office.vendorPhoneNumber || "",
        landlordPhoneNumber: officeStore?.office.landlordPhoneNumber || "",
        mortgagePhoneNumber: officeStore?.office.mortgagePhoneNumber || "",
        officeGroupIds: officeStore?.office.officeGroupIds || [],
      };
    }, [officeStore]);

    const {
      handleSubmit,
      control,
      watch,
      setValue,
      formState: { isSubmitting, errors },
    } = useForm<TOfficeFormFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(officeBasicValidationSchema),
    });

    const watchName = watch("name");
    const watchPostcodes = watch("postcodes");

    useEffect(() => {
      const fetchData = async () => {
        try {
          let promises = [];
          promises.push(officeGroupsStore.fetchAllAccountOfficeGroups());
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all(promises);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (err) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };
      fetchData();
    }, [
      hasReapitIntegration,
      reapitIntegrationOfficesStore,
      officeGroupsStore,
    ]);

    const handleOfficeUpdate = useCallback(
      async (officeStore: AccountOfficeStore, values: PartialOfficeData) => {
        const {
          postcodes,
          isSales,
          isLettings,
          isMortgage,
          salesEmails,
          lettingsEmails,
          vendorEmails,
          landlordEmails,
          salesPhoneNumber,
          lettingsPhoneNumber,
          vendorPhoneNumber,
          landlordPhoneNumber,
          mortgagePhoneNumber,
          officeGroupIds,
          website,
        } = values;
        await officeStore.update({
          postcodes,
          isSales,
          isLettings,
          isMortgage,
          salesEmails,
          lettingsEmails,
          vendorEmails,
          landlordEmails,
          salesPhoneNumber,
          lettingsPhoneNumber,
          vendorPhoneNumber,
          landlordPhoneNumber,
          mortgagePhoneNumber,
          officeGroupIds,
          website: website || null,
        });

        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Office successfully updated"} />
          ),
        });
      },
      [toast]
    );

    const handleOfficeCreate = useCallback(
      async (officeData: PartialOfficeData) => {
        const {
          name,
          website,
          postcodes,
          isSales,
          isLettings,
          isMortgage,
          salesEmails,
          lettingsEmails,
          vendorEmails,
          landlordEmails,
          salesPhoneNumber,
          lettingsPhoneNumber,
          vendorPhoneNumber,
          landlordPhoneNumber,
          mortgagePhoneNumber,
          officeGroupIds,
        } = officeData;
        await officesStore.createAccountOffice(
          {
            name,
            website: website || null,
            accountId: officesStore.accountId,
            postcodes,
            isSales,
            isLettings,
            isMortgage,
            salesEmails,
            lettingsEmails,
            vendorEmails,
            landlordEmails,
            salesPhoneNumber,
            lettingsPhoneNumber,
            vendorPhoneNumber,
            landlordPhoneNumber,
            mortgagePhoneNumber,
            officeGroupIds: officeGroupIds || [],
          },
          {
            reapitId: selectedReapitOfficeId,
          }
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Office successfully created"} />
          ),
        });
      },
      [officesStore, selectedReapitOfficeId, toast]
    );

    const onSubmit = useCallback(
      async (values: TOfficeFormFieldValues) => {
        const { isAdvancedContactData, ...rest } = values;

        const officeData = {
          ...rest,
          postcodes: rest.postcodes.join(","),
          salesEmails: rest.salesEmails.join(","),
          lettingsEmails: rest.lettingsEmails.join(","),
          vendorEmails: rest.vendorEmails.join(","),
          landlordEmails: rest.landlordEmails.join(","),
        };
        try {
          if (!!officeStore) {
            await handleOfficeUpdate(officeStore, officeData);
          } else {
            await handleOfficeCreate(officeData);
          }

          closePrompt();
        } catch (e) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={e.message} />,
          });
        }
      },
      [closePrompt, handleOfficeCreate, handleOfficeUpdate, officeStore, toast]
    );

    return (
      <ActionPromptContainer
        loadingStatus={loadingStatus}
        header={!!officeStore ? "Edit office" : "Create office"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TOfficeFormFieldValues>
                  label={"Office name"}
                  description={
                    "Please use the name of the postal town / area of your office."
                  }
                  name={"name"}
                  control={control}
                  type={FormControlsTypeEnum.TEXT}
                  isDisabled={!!officeStore}
                  additionalProps={{
                    placeholder: "Enter office name",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TOfficeFormFieldValues>
                  name={"website"}
                  control={control}
                  label={"Office website"}
                  description={
                    "Your office website address that will be used by LeadPro tools"
                  }
                  type={FormControlsTypeEnum.TEXT}
                  additionalProps={{
                    placeholder: "https://www.your-website.com",
                  }}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TOfficeFormFieldValues>
                  label={"Postcode patch coverage"}
                  description={
                    "Please add all the postcodes that this offices covers. Make sure to use a comma between each postcode or they will not be recorded properly. You can enter a full or partial postcode. E.G. N1 or N1 8RA"
                  }
                  name={"postcodes"}
                  control={control}
                  type={FormControlsTypeEnum.TAG_INPUT}
                  additionalProps={{
                    placeholder: "Enter postcode and press enter or comma",
                  }}
                />
                <FormInputValueCounter
                  name={"postcode"}
                  length={watchPostcodes.length}
                />
              </Box>
              <Box mb={5}>
                <FormControlMeta
                  label={"Type"}
                  description={
                    "Please select if this office manages sales and or lettings business."
                  }
                />
                <HStack spacing={2} ml={10} mt={4}>
                  <FormControlV2<TOfficeFormFieldValues>
                    name={"isSales"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                    showError={false}
                  />
                  <Box>Sales</Box>
                </HStack>
                <HStack spacing={2} ml={10} mt={4}>
                  <FormControlV2<TOfficeFormFieldValues>
                    name={"isLettings"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                    showError={false}
                  />
                  <Box>Lettings</Box>
                </HStack>
                <HStack spacing={2} ml={10} mt={4}>
                  <FormControlV2<TOfficeFormFieldValues>
                    name={"isMortgage"}
                    control={control}
                    type={FormControlsTypeEnum.SWITCH}
                    showError={false}
                  />
                  <Box>Mortgage</Box>
                </HStack>
                {!!errors["isMortgage"] && (
                  <FormControlError error={errors["isMortgage"]} />
                )}
              </Box>
              <Divider mb={5} />
              <OfficeFormContactDataInput
                watch={watch}
                control={control}
                setValue={setValue}
              />
              <Divider mb={5} />
              <Box mb={5}>
                <FormControlV2<TOfficeFormFieldValues>
                  label={"Office Groups"}
                  description={
                    "Which office groups should your office be a part of?"
                  }
                  name={"officeGroupIds"}
                  control={control}
                  type={FormControlsTypeEnum.MULTI_SELECT}
                  additionalProps={{
                    clearable: true,
                    placeholder: "Select Office Groups",
                    options: officeGroupsStore.accountOfficeGroupsOptions,
                  }}
                />
              </Box>
              {hasReapitIntegration && (
                <ReapitOfficeSync
                  officeName={watchName}
                  onChange={setSelectedReapitOfficeId}
                  existingOfficeId={officeStore?.office.id}
                  reapitIntegrationStore={reapitIntegrationStore}
                  value={selectedReapitOfficeId}
                />
              )}
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!officeStore ? "Update" : "Create"}
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
