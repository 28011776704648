import { useCallback } from "react";
import { SingleSelectInput } from "components";
import { Box, HStack, useTheme } from "@chakra-ui/react";
import { TUserAccountData } from "types/user-account.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { ACCOUNT_SETUP_ROUTE } from "constants/routes";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { AccountSelectOption } from "components/select-input/custom-options";
import { filterOptionsByLabel } from "utils/select.utils";

export const AccountSelect = observer(() => {
  const history = useHistory();
  const theme = useTheme();
  const { userAccountsStore } = useAppStore();
  const userAccountsDataArray = userAccountsStore.userAccountsDataArray;
  const selectedAccountStore = userAccountsStore.selectedAccountStore;
  const selectedAccountData = selectedAccountStore?.account;

  const handleAccountChange = useCallback(
    (account: TSelectValue<TUserAccountData>) => {
      if (account) {
        userAccountsStore.selectAccount(account.id);
      }
    },
    [userAccountsStore]
  );

  const handleFilterAccountOptions = useCallback(
    (options: TSelectOption<TUserAccountData>[], searchTerm: string) => {
      return filterOptionsByLabel<TUserAccountData>(options, searchTerm);
    },
    []
  );

  const handleAddAccount = useCallback(() => {
    userAccountsStore.selectAccount(null);
    history.push(ACCOUNT_SETUP_ROUTE);
  }, [userAccountsStore, history]);

  return (
    <SingleSelectInput<TUserAccountData>
      value={selectedAccountData || null}
      options={userAccountsDataArray.map(account => ({
        label: account.name,
        value: account,
      }))}
      onChange={handleAccountChange}
      filterFn={handleFilterAccountOptions}
      optionComponent={optionProps => (
        <AccountSelectOption optionProps={optionProps} />
      )}
      optionsFooter={
        <HStack
          bg="gray.50"
          p={3}
          spacing={2}
          onClick={handleAddAccount}
          cursor={"pointer"}
        >
          <FontAwesomeIcon
            icon={faSquarePlus}
            fontSize={"28px"}
            color={theme.colors.gray[500]}
          />
          <Box>Create account</Box>
        </HStack>
      }
    />
  );
});
