import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();
const contentfulService = require("contentful");

const ContentfulClient = contentfulService.createClient({
  space: env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: env.REACT_APP_CONTENTFUL_TOKEN,
});

export { ContentfulClient };
