import { observer } from "mobx-react";
import { Droppable } from "react-beautiful-dnd";
import React, { FC, useMemo } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { FormToolPageSettingsEditorStore } from "../../FormToolPageSettingsEditor.store";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { FormToolPageSettingsEmptyList } from "../../../../../../components/FormTool/FormToolPageSettingsEmptyList";
import { FormToolPageSettingsSelectedActionConfigurator } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsSelectedActionConfigurator";
import { FormToolPageSettingsActionParamsConfigurator } from "./FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionParamsConfigurator/FormToolPageSettingsActionParamsConfigurator";

interface IProps {
  formToolPageSettingsEditorStore: FormToolPageSettingsEditorStore;
}
export const FormToolPageSettingsSelectedActionsList: FC<IProps> = observer(
  ({ formToolPageSettingsEditorStore }) => {
    const formToolPageWIPFormStore =
      formToolPageSettingsEditorStore.wipFormStore;
    const formActions = formToolPageWIPFormStore.wipFormActionsJS;

    const elements = useMemo(() => {
      return formActions.map((action, index) => (
        <FormToolPageSettingsSelectedActionConfigurator
          key={`${action.type}-${index}`}
          actionIndex={index}
          formToolPageWIPFormStore={formToolPageWIPFormStore}
          additionalConfigurators={
            <FormToolPageSettingsActionParamsConfigurator
              actionIndex={index}
              formToolPageWIPFormStore={formToolPageWIPFormStore}
            />
          }
        />
      ));
    }, [formToolPageWIPFormStore, formActions]);

    return (
      <Box width={"100%"}>
        <Box pb={10}>
          <Box pb={"10px"} fontSize={"lg"} fontWeight={"bold"}>
            Actions
          </Box>
          <Box fontSize={"sm"} color={"leadpro.500"}>
            Here you can specify how your form responses are processed.{" "}
            <b>
              Begin by dragging and dropping an action type from the left panel.
            </b>
          </Box>
        </Box>
        <Box>
          <Droppable
            droppableId={FormToolPageDroppableContainerEnum.SELECTED_ACTIONS}
          >
            {provided => (
              <VStack
                spacing={2}
                paddingBottom={!!elements.length ? "250px" : "150px"}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!elements.length && (
                  <FormToolPageSettingsEmptyList>
                    Drag the actions from the menu and drop them here.
                  </FormToolPageSettingsEmptyList>
                )}
                {elements}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </Box>
      </Box>
    );
  }
);
