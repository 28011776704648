import React from "react";
import { AspectRatio, Box, Button, Image } from "@chakra-ui/react";
import { Typography } from "components";
import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { DEMO_BRAND_ICON } from "constants/image-paths";

export const WEBFORM_LINK =
  "https://propertytechnology.typeform.com/to/E1lo7m#id=xxxxx";

export const LeadResponderEmailExample = observer(() => {
  const {
    userAccountsStore: { selectedAccountStore },
  } = useAppStore();
  const accountData = selectedAccountStore?.account;

  return (
    <Box pb={4}>
      <Box fontSize="sm" color="gray.600" mb={6}>
        This is an example of the personalised HTML email response that your
        branch can immediately send to all enquirers. It can also be sent by
        text message. The replies are fully branded with your brand and logo.
      </Box>

      <Box
        p={10}
        border="1px solid"
        rounded="md"
        borderColor="gray.100"
        bg="gray.50"
      >
        <Box
          bg="white"
          p={10}
          rounded="md"
          shadow="sm"
          border="1px solid"
          borderColor="gray.100"
        >
          <AspectRatio ratio={1 / 1} width={"150px"} mb={5}>
            <Image src={accountData?.logoUrl || DEMO_BRAND_ICON} />
          </AspectRatio>
          <Typography>
            <p>Dear Seb Powell, </p>
            <p>Thank you for enquiring about Example Property Address</p>
            <p>
              We'd like to learn a little more about you. To progress your
              enquiry please follow this link:
            </p>
            <p>
              <Button
                width={"100%"}
                colorScheme={"teal"}
                size={"lg"}
                as={"a"}
                href={WEBFORM_LINK}
                target={"_blank"}
                rel={"noopener noreferrer"}
                style={{
                  textDecoration: "none",
                }}
              >
                Click Here To Complete Your Enquiry
              </Button>
            </p>
            <p>
              We'll review your enquiry and get back to you as soon as possible
              to book a viewing.
            </p>
            <p>Kind regards,</p>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});
