import { BaseApi } from "./base.api";
import { TEmailTemplate } from "types/email-template.type";
import { TTemplateMergeTagGroup } from "types/template-merge-tag.type";

export class EmailTemplatesApi extends BaseApi {
  async fetchAll(accountId: number): Promise<TEmailTemplate[]> {
    return this.get(`/accounts/${accountId}/email-templates`);
  }

  async fetchOne(
    accountId: number,
    templateId: number
  ): Promise<TEmailTemplate> {
    return this.get(`/accounts/${accountId}/email-templates/${templateId}`);
  }

  async fetchMergeTags(accountId: number): Promise<TTemplateMergeTagGroup[]> {
    return this.get(`/accounts/${accountId}/email-templates/merge-tags`);
  }

  async create(
    accountId: number,
    data: Partial<TEmailTemplate>
  ): Promise<TEmailTemplate> {
    return this.post<TEmailTemplate>(
      `/accounts/${accountId}/email-templates`,
      data
    );
  }

  async update(
    accountId: number,
    templateId: number,
    data: Partial<TEmailTemplate>
  ): Promise<TEmailTemplate> {
    return this.patch<TEmailTemplate>(
      `/accounts/${accountId}/email-templates/${templateId}`,
      data
    );
  }

  async delete(accountId: number, templateId: number) {
    await this.del(`/accounts/${accountId}/email-templates/${templateId}`);
  }

  async sendTestEmail(accountId: number, templateId: number, email: string) {
    await this.post(
      `/accounts/${accountId}/email-templates/${templateId}/test`,
      {
        emailAddress: email,
      }
    );
  }
}
