import { Box, Button, HStack, Tag } from "@chakra-ui/react";
import React, { useMemo } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { DATE_MONTH_YEAR, DATE_YEAR } from "constants/date";
import { EXPORT_STATUS_COLORS } from "constants/export-request-status-colors";
import { TExportRequest } from "types/export-request.type";
import { observer } from "mobx-react";
import { isDateCorrectFormat } from "utils/date.utils";

interface IProps {
  request: TExportRequest;
}

export const ExportRequestItem: React.FC<IProps> = observer(({ request }) => {
  const label = useMemo(() => {
    if (isDateCorrectFormat(request.period, DATE_YEAR)) {
      return `Full Year ${moment(request.period).format(DATE_YEAR)}`;
    }

    return moment(request.period).format(DATE_MONTH_YEAR);
  }, [request]);

  return (
    <HStack justifyContent={"space-between"}>
      <Box>
        <Box>{label}</Box>
        <Tag colorScheme={EXPORT_STATUS_COLORS[request.status]}>
          {request.status.toUpperCase()}
        </Tag>
      </Box>
      <Button
        as={"a"}
        href={request.url || ""}
        isDisabled={!request.url}
        colorScheme={"teal"}
        leftIcon={<FontAwesomeIcon icon={faCloudArrowDown} fontSize={18} />}
      >
        Download
      </Button>
    </HStack>
  );
});
