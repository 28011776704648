import { Box, Heading, SlideFade, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { HOME_ROUTE } from "constants/routes";

export const AccountFinalizationStep = () => {
  const history = useHistory();
  return (
    <SlideFade in={true}>
      <Box textAlign="center" mb={10}>
        <Heading fontSize="3xl" fontWeight={"500"} lineHeight="none">
          All done!
        </Heading>
        <Text fontSize="md" fontWeight={500} color="gray.600" mt={6}>
          Your account is ready to go.
        </Text>
      </Box>
      <Box mb={10} width={"100%"} textAlign="center">
        <Button
          colorScheme={"blue"}
          size={"md"}
          onClick={() => {
            history.push(HOME_ROUTE);
          }}
        >
          Go to your dashboard
        </Button>
      </Box>
    </SlideFade>
  );
};
