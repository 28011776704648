import { useEffect, useRef } from "react";
import Chartist from "chartist";
import "./Chart.scss";
import { seriesWithMeta, tooltipPlugin } from "./tooltip-utils";
import { IChartProps, ChartType } from "./interfaces";
import { createXAxisOptions, overrideXLabelPositions } from "./x-axis-utils";
import { overrideColors } from "./override-colors";
import { betterTooltip } from "./betterTooltip";
import { Box } from "@chakra-ui/react";

const commonOptions = {
  plugins: [tooltipPlugin],
};

const createBar = (element: HTMLElement, props: IChartProps) => {
  const { labels, series } = props;
  return new Chartist.Bar(
    element,
    {
      labels,
      series: seriesWithMeta(series, labels),
    },
    {
      ...commonOptions,
      ...createXAxisOptions(props),
    }
  ).on("draw", function(data: any) {
    overrideXLabelPositions(data);
  });
};

const createStackedBar = (element: HTMLElement, props: IChartProps) => {
  const { labels, series } = props;
  return new Chartist.Bar(
    element,
    {
      labels,
      series: seriesWithMeta(series, labels),
    },
    {
      stackBars: true,
      ...commonOptions,
      ...createXAxisOptions(props),
    }
  ).on("draw", function(data: any) {
    overrideXLabelPositions(data);
  });
};

const createLine = (element: HTMLElement, props: IChartProps) => {
  const {
    labels,
    series,
    colors,
    seriesNames,
    subSeries,
    showSecondarySources,
  } = props;

  return new Chartist.Line(
    element,
    {
      labels,
      series,
    },
    {
      // fullWidth: true,
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      plugins: [
        betterTooltip(colors, seriesNames, subSeries, showSecondarySources),
      ],
      ...createXAxisOptions(props),
    }
  ).on("draw", function(data: any) {
    overrideXLabelPositions(data);
    overrideColors(data, colors);
  });
};

const createDoughnut = (element: HTMLElement, props: IChartProps) => {
  const { labels, series } = props;
  return new Chartist.Pie(
    element,
    {
      labels,
      series,
    },
    commonOptions
  );
};

const chartFactoryMap = {
  [ChartType.Bar]: createBar,
  [ChartType.StackedBar]: createStackedBar,
  [ChartType.Line]: createLine,
  [ChartType.Doughnut]: createDoughnut,
};

let i = 0; // force recreation of container div to prevent memory leaks
export const Chart = (props: IChartProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { type, className } = props;

  useEffect(() => {
    const current = rootRef.current;
    if (current) {
      const chart = chartFactoryMap[type](current, props);

      return () => chart.detach();
    }

    return;
  });

  return (
    <Box
      key={i++}
      className={className}
      height={"400px"}
      position={"relative"}
      ref={rootRef}
    />
  );
};
