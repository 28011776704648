import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { LeadAttributionList } from "./LeadAttributionList";
import { LeadAttributionOrigin } from "./LeadAttributionOrigin";
import {
  TLeadAttributionConfig,
  transformAttributionToConfig,
} from "./lead-attribution.utils";
import { LeadAttributionDefault } from "./LeadAttributionDefault";

interface ILeadAttributionProps {
  accountStore: UserAccountStore;
  leadStore: AccountLeadStore;
}

export const LeadAttribution: FC<ILeadAttributionProps> = observer(
  ({ accountStore, leadStore }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const leadAttributionStore = leadStore.leadAttributionsStore;
    const leadAttributions = leadAttributionStore.leadAttributionsArray;
    const lead = leadStore.lead;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await leadAttributionStore.fetchLeadAttributions();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };
      fetchData();
    }, [leadAttributionStore]);

    const leadAttributionConfig: TLeadAttributionConfig[] = useMemo(() => {
      return transformAttributionToConfig(leadAttributions, lead);
    }, [lead, leadAttributions]);

    const content = useMemo(() => {
      if (leadAttributionConfig.length === 0) {
        return <LeadAttributionDefault lead={lead} />;
      } else {
        return (
          <>
            <LeadAttributionOrigin
              accountStore={accountStore}
              leadStore={leadStore}
            />
            <LeadAttributionList
              leadAttributionConfig={leadAttributionConfig}
            />
          </>
        );
      }
    }, [leadStore, leadAttributionConfig, accountStore, lead]);
    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && content}
      </>
    );
  }
);
