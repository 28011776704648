import { BaseApi } from "./base.api";
import {
  TAccountDevelopersApiKeyConfig,
  TAccountDevelopersWebhookConfig,
} from "types/account-developers.type";
import {
  TAccountApiRequestResponse,
  TAccountWebhookRequestResponse,
} from "types/account-developers.type";

export class AccountDevelopersApi extends BaseApi {
  // API
  async fetchAllAccountDevelopersApiKeyConfigs(
    accountId: number
  ): Promise<TAccountDevelopersApiKeyConfig[]> {
    return this.get(`/accounts/${accountId}/api-configs`);
  }

  async fetchAccountDevelopersApiKeyConfig(
    accountId: number,
    apiKeyConfigId: number
  ): Promise<TAccountDevelopersApiKeyConfig> {
    return this.get(`/accounts/${accountId}/api-configs/${apiKeyConfigId}`);
  }

  async fetchAccountDevelopersApiKeyRequests(
    accountId: number,
    apiKeyConfigId: number,
    offset?: number,
    limit?: number
  ): Promise<TAccountApiRequestResponse> {
    return this.get(
      `/accounts/${accountId}/api-configs/${apiKeyConfigId}/api-requests`,
      {
        params: {
          offset,
          limit,
        },
      }
    );
  }

  async createAccountDevelopersApiKeyConfig(
    accountId: number,
    apiKeyConfigId: Partial<TAccountDevelopersApiKeyConfig>
  ): Promise<TAccountDevelopersApiKeyConfig> {
    return this.post(`/accounts/${accountId}/api-configs`, apiKeyConfigId);
  }

  async updateAccountDevelopersApiKeyConfig(
    accountId: number,
    apiKeyConfigId: number,
    apiKeyConfig: Partial<TAccountDevelopersApiKeyConfig>
  ): Promise<TAccountDevelopersApiKeyConfig> {
    return this.put(
      `/accounts/${accountId}/api-configs/${apiKeyConfigId}`,
      apiKeyConfig
    );
  }

  async deleteAccountDevelopersApiKeyConfig(
    accountId: number,
    apiKeyConfigId: number
  ): Promise<TAccountDevelopersApiKeyConfig[]> {
    return this.del(`/accounts/${accountId}/api-configs/${apiKeyConfigId}`);
  }

  async regenerateAccountDevelopersApiKey(
    accountId: number,
    apiKeyConfigId: number
  ): Promise<TAccountDevelopersApiKeyConfig> {
    return this.post(
      `/accounts/${accountId}/api-configs/${apiKeyConfigId}/regenerate-api-key`
    );
  }

  // WEBHOOKS
  async fetchAllAccountDevelopersWebhooks(
    accountId: number
  ): Promise<TAccountDevelopersWebhookConfig[]> {
    return this.get(`/accounts/${accountId}/api-webhooks`);
  }

  async fetchAccountDevelopersWebhook(
    accountId: number,
    webhookId: number
  ): Promise<TAccountDevelopersWebhookConfig> {
    return this.get(`/accounts/${accountId}/api-webhooks/${webhookId}`);
  }

  async fetchAccountDevelopersWebhookRequests(
    accountId: number,
    webhookId: number,
    offset?: number,
    limit?: number
  ): Promise<TAccountWebhookRequestResponse> {
    return this.get(
      `/accounts/${accountId}/api-webhooks/${webhookId}/api-webhook-requests`,
      {
        params: {
          offset,
          limit,
        },
      }
    );
  }

  async createAccountDevelopersWebhook(
    accountId: number,
    webhookData: Partial<TAccountDevelopersWebhookConfig>
  ): Promise<TAccountDevelopersWebhookConfig> {
    return this.post(`/accounts/${accountId}/api-webhooks`, webhookData);
  }

  async updateAccountDeveloperWebhook(
    accountId: number,
    webhookId: number,
    webhookData: Partial<TAccountDevelopersWebhookConfig>
  ): Promise<TAccountDevelopersWebhookConfig> {
    return this.put(
      `/accounts/${accountId}/api-webhooks/${webhookId}`,
      webhookData
    );
  }

  async deleteAccountDevelopersWebhook(
    accountId: number,
    webhookId: number
  ): Promise<TAccountDevelopersWebhookConfig[]> {
    return this.del(`/accounts/${accountId}/api-webhooks/${webhookId}`);
  }

  async regenerateAccountDeveloperWebhookSigningSecret(
    accountId: number,
    webhookId: number
  ): Promise<TAccountDevelopersWebhookConfig> {
    return this.post(
      `/accounts/${accountId}/api-webhooks/${webhookId}/regenerate-api-webhook-signing-secret`
    );
  }
}
