import { observer } from "mobx-react";
import { QuestionnaireToolPageSettingsEditorStore } from "../stores/QuestionnaireToolPageSettingsEditor.store";
import React, { FC, useCallback } from "react";
import { Accordion, Box, VStack } from "@chakra-ui/react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Error, Loader } from "components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { FormToolPageSettingsTemplateQuestionList } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsTemplateQuestions/FormToolPageSettingsTemplateQuestionList";
import { FormToolPageSettingsTemplateActionList } from "routes/dashboard/routes/tools/components/FormTool/FormToolPageSettingsTemplateActions/FormToolPageSettingsTemplateActionList";
import {
  QuestionnaireToolPageAccordionSections,
  QuestionnaireToolPageActionTemplateOptions,
} from "constants/questionnaire-tool-page";
import { QuestionnaireActionType, FormFieldType } from "@leadpro/forms";
import { QuestionnaireToolPageSettingsSelectedListSection } from "../QuestionnaireToolPageSettingsSelectedListSection/QuestionnaireToolPageSettingsSelectedListSection";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { FormToolPageDroppableContainerEnum } from "enums/form-tool-page-droppable-container.enum";
import { QuestionnaireToolPageSettingsSelectedListSectionHeader } from "../QuestionnaireToolPageSettingsSelectedListSection/QuestionnaireToolPageSettingsSelectedListSectionHeader";
import { QuestionnaireToolPageSettingsSelectedListSectionContent } from "./QuestionnaireToolPageSettingsSelectedListSectionContent/QuestionnaireToolPageSettingsSelectedListSectionContent";

interface IProps {
  questionnaireToolPageSettingsEditorStore: QuestionnaireToolPageSettingsEditorStore;
}

export const QuestionnaireToolPageSettingsQuestionnairesBuilder: FC<IProps> = observer(
  ({ questionnaireToolPageSettingsEditorStore }) => {
    const handleAddQuestion = useCallback(
      (result: DropResult) => {
        const position = result.destination?.index;
        const questionType = result.draggableId.split(":")[1] as FormFieldType;
        const destinationDroppableId = result.destination?.droppableId;
        const leadType = destinationDroppableId?.split(
          ":"
        )[1] as QuestionnaireLeadTypes;

        if (!!questionType && !!leadType && position !== undefined) {
          const store =
            questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap[
              leadType
            ];
          store.addQuestion(questionType, position);
        }
      },
      [questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap]
    );

    const handleQuestionReorder = useCallback(
      (result: DropResult) => {
        const destinationDroppableId = result.destination?.droppableId;
        const leadType = destinationDroppableId?.split(
          ":"
        )[1] as QuestionnaireLeadTypes;
        const store =
          questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap[
            leadType
          ];

        if (!result.destination || !store) return;

        store.swapQuestionsOrder(
          result.source.index,
          result.destination?.index
        );
      },
      [questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap]
    );

    const handleDropIntoSelectedQuestions = useCallback(
      (result: DropResult) => {
        const sourceDroppableId = result.source.droppableId;
        if (
          sourceDroppableId.includes(
            FormToolPageDroppableContainerEnum.TEMPLATE_QUESTIONS
          )
        ) {
          handleAddQuestion(result);
        } else if (
          sourceDroppableId.includes(
            FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS
          )
        ) {
          handleQuestionReorder(result);
        }
      },
      [handleAddQuestion, handleQuestionReorder]
    );

    const handleAddAction = useCallback(
      (result: DropResult) => {
        const actionType = result.draggableId.split(
          ":"
        )[1] as QuestionnaireActionType;
        const destinationDroppableId = result.destination?.droppableId;
        const leadType = destinationDroppableId?.split(
          ":"
        )[1] as QuestionnaireLeadTypes;

        if (!!actionType && !!leadType) {
          const store =
            questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap[
              leadType
            ];
          store.addAction(actionType);
        }
      },
      [questionnaireToolPageSettingsEditorStore.wipQuestionnaireStoresMap]
    );

    const handleDropIntoSelectedActions = useCallback(
      (result: DropResult) => {
        const sourceDroppableId = result.source.droppableId;
        if (
          sourceDroppableId ===
          FormToolPageDroppableContainerEnum.TEMPLATE_ACTIONS
        ) {
          handleAddAction(result);
        }
      },
      [handleAddAction]
    );

    const onDragEnd = useCallback(
      (result: DropResult) => {
        const destinationDroppableId = result.destination?.droppableId;
        if (
          destinationDroppableId?.includes(
            FormToolPageDroppableContainerEnum.SELECTED_QUESTIONS
          )
        ) {
          handleDropIntoSelectedQuestions(result);
        }
        if (
          destinationDroppableId?.includes(
            FormToolPageDroppableContainerEnum.SELECTED_ACTIONS
          )
        ) {
          handleDropIntoSelectedActions(result);
        }
      },
      [handleDropIntoSelectedQuestions, handleDropIntoSelectedActions]
    );

    return (
      <Box display={"flex"} flexDirection={"row"} height={"100%"}>
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.PENDING && <Loader />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.ERROR && <Error />}
        {questionnaireToolPageSettingsEditorStore.loadingStatus ===
          ApiRequestStatusEnum.SUCCESS && (
          <DragDropContext onDragEnd={onDragEnd}>
            <VStack
              spacing={5}
              width={"325px"}
              flexShrink={0}
              p={"65px 18px"}
              borderRight={"1px solid"}
              borderRightColor={"leadpro.200"}
              overflowY={"auto"}
            >
              <FormToolPageSettingsTemplateQuestionList />
              <FormToolPageSettingsTemplateActionList<QuestionnaireActionType>
                templateOptions={QuestionnaireToolPageActionTemplateOptions}
              />
            </VStack>
            <Box
              flexGrow={1}
              overflowY={"auto"}
              display={"flex"}
              justifyContent={"center"}
              px={10}
              py={"65px"}
            >
              <Accordion defaultIndex={[0]} width={"100%"} maxW={"1200px"}>
                {QuestionnaireToolPageAccordionSections.map(leadType => (
                  <QuestionnaireToolPageSettingsSelectedListSection
                    key={leadType}
                    leadType={leadType}
                    questionnaireToolPageSettingsEditorStore={
                      questionnaireToolPageSettingsEditorStore
                    }
                    Header={
                      QuestionnaireToolPageSettingsSelectedListSectionHeader
                    }
                    Content={
                      QuestionnaireToolPageSettingsSelectedListSectionContent
                    }
                  />
                ))}
              </Accordion>
            </Box>
          </DragDropContext>
        )}
      </Box>
    );
  }
);
