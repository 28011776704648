import { Box } from "@chakra-ui/react";
import { formatDate } from "utils/date.utils";
import { DATE_FORMAT } from "constants/date";
import { NA } from "components";
import React, { FC } from "react";
import { TLead } from "types/lead.type";

interface ILeadNextActionDateProps {
  lead: TLead;
}

export const LeadNextActionDateCell: FC<ILeadNextActionDateProps> = ({
  lead,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      {!!lead.nextActionDate ? (
        <Box>{formatDate(lead.nextActionDate, DATE_FORMAT)}</Box>
      ) : (
        <NA />
      )}
    </Box>
  );
};
