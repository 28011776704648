import {
  faHouse,
  faMessage,
  faPhone,
  faDiploma,
  IconDefinition,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import { ApplicationProductEnum } from "enums/application-product.enum";

export interface IAccountBillingSubscriptionProductConfig {
  icon: IconDefinition;
  title: string;
  description: string;
  productId: ApplicationProductEnum;
  core?: boolean;
  pricing: {
    flatFee: number;
    unitsIncluded?: number;
    pricePerExtraUnit?: number;
    vatTaxPercent: number;
  };
}

export const accountProductsConfigMap: {
  [key in ApplicationProductEnum]: IAccountBillingSubscriptionProductConfig;
} = {
  [ApplicationProductEnum.DASHBOARD_USERS]: {
    icon: faUserGroup,
    title: "Users",
    description:
      "Your plan includes 1 free user. Additional users cost £16 per user per month.",
    productId: ApplicationProductEnum.DASHBOARD_USERS,
    core: true,
    pricing: {
      flatFee: 0,
      unitsIncluded: 1,
      pricePerExtraUnit: 16,
      vatTaxPercent: 20,
    },
  },
  [ApplicationProductEnum.AUTOCALLER]: {
    icon: faPhone,
    title: "Autocaller",
    description:
      "High value email leads are converted from an email to a call, causing the office phone to ring, connecting the agent directly to the lead, ahead of any competitors. 150 minutes are included, additional minutes are £0.04 per minute.",
    productId: ApplicationProductEnum.AUTOCALLER,
    pricing: {
      flatFee: 82.5,
      unitsIncluded: 150,
      pricePerExtraUnit: 0.04,
      vatTaxPercent: 20,
    },
  },
  [ApplicationProductEnum.INSTANT_VALUATION_TOOL]: {
    icon: faHouse,
    title: "Instant Valuation Tool",
    description: "",
    productId: ApplicationProductEnum.INSTANT_VALUATION_TOOL,
    pricing: {
      flatFee: 0,
      unitsIncluded: 0,
      pricePerExtraUnit: 0,
      vatTaxPercent: 20,
    },
  },
  [ApplicationProductEnum.LEAD_RESPONDER]: {
    icon: faMessage,
    title: "Lead Responder",
    description:
      "Leads from the portals & your agent’s website receive instant branded email and text message replies, with a custom questionnaire to identify vendor, mortgage and landlord leads. 300 leads are included additional leads are £0.04 per lead.",
    productId: ApplicationProductEnum.LEAD_RESPONDER,
    pricing: {
      flatFee: 39,
      unitsIncluded: 300,
      pricePerExtraUnit: 0.04,
      vatTaxPercent: 20,
    },
  },
  [ApplicationProductEnum.REAPIT_INTEGRATION]: {
    icon: faMessage,
    title: "Reapit Integration",
    description:
      "When you Sync you account to Reapit or use the Reapit Foundations API to send leads to Reapit, there is a charge from Reapit to send the data. This is £0.0235 per API call which LeadPro passes directly onto you, and is added to your monthly invoice. ",
    productId: ApplicationProductEnum.REAPIT_INTEGRATION,
    pricing: {
      flatFee: 0,
      unitsIncluded: 0,
      pricePerExtraUnit: 0.02,
      vatTaxPercent: 20,
    },
  },
  [ApplicationProductEnum.MORTGAGES]: {
    icon: faDiploma,
    title: "Mortgages",
    description: "",
    productId: ApplicationProductEnum.MORTGAGES,
    pricing: {
      flatFee: 0,
      unitsIncluded: 0,
      pricePerExtraUnit: 0,
      vatTaxPercent: 0,
    },
  },
};
