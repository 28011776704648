import * as Yup from "yup";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { FC } from "react";
import { useForm } from "react-hook-form";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";

type TFieldValues = {
  email: string;
};

const initialValues: TFieldValues = {
  email: "",
};

interface IProps {
  closePrompt: () => void;
  createEmail: (email: string) => Promise<void>;
  existingEmails: string[];
}

export const CreateEmailActionPrompt: FC<IProps> = ({
  closePrompt,
  createEmail,
  existingEmails,
}) => {
  const toast = useToast();

  const createBlacklistedEmailValidation = Yup.object().shape({
    email: Yup.string()
      .email("A valid email is required.")
      .required("Email field is required.")
      .notOneOf(existingEmails, "Email is already blacklisted."),
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(createBlacklistedEmailValidation),
  });

  const onSubmit = async (data: TFieldValues) => {
    try {
      await createEmail(data.email);
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description: (
          <ApiMessageStack messageStack={"Blacklisted email created."} />
        ),
      });
      closePrompt();
    } catch (e) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={e.message} />,
      });
    }
  };

  return (
    <ActionPromptContainer
      header={"Add blacklisted email "}
      body={
        <Box pt={5}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={5}>
              <FormControlV2<TFieldValues>
                name={"email"}
                control={control}
                label={"Email address"}
                type={FormControlsTypeEnum.TEXT}
                additionalProps={{
                  placeholder: "Email address",
                }}
              />
            </Box>
            <Divider mb={4} />
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <Button
                type={"submit"}
                colorScheme={"blue"}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Create
              </Button>
            </Box>
          </form>
        </Box>
      }
    />
  );
};
