import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { IGetOptionPropsData } from "../SelectInputOptionsController";

import ReapitAreasOption from "../custom-options/ReapitAreasOption";

interface IReapitAreasOptionsRendererProps {
  optionProps: IGetOptionPropsData<string>[];
}

export const ReapitAreasOptionsRenderer: FC<IReapitAreasOptionsRendererProps> = ({
  optionProps,
}) => {
  if (!optionProps || optionProps.length === 0) {
    return (
      <Box px={2} py={1} color={"gray.400"}>
        No Options ...
      </Box>
    );
  }

  return (
    <>
      {optionProps.map((option, i) => {
        return (
          <Box key={i}>
            <ReapitAreasOption optionProp={option} />
          </Box>
        );
      })}
    </>
  );
};
