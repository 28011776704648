import { AppStore } from "store/App.store";
import {
  IObservableArray,
  makeAutoObservable,
  observable,
  ObservableMap,
} from "mobx";
import {
  TGetLeadsResponse,
  TLeadsListMeta,
  TLeadWithDetails,
} from "types/lead.type";
import { LeadsApi } from "api/leads.api";
import { keyBy, sortBy } from "lodash";
import { dummyLeadsList } from "constants/dummyLeadData";
import { TLeadsFilter } from "types/leads-filter.type";
import { SortingRule } from "react-table";
import { AccountLeadsFiltersStore } from "./AccountLeadsFilters.store";
import { AccountLeadStore } from "./AccountLead.store";
import {
  TAssignableToLeadUserData,
  TUserBasicData,
} from "types/user-data.type";
import { getUserFullName } from "utils/account-user.utils";
import { AccountUsersApi } from "api/account-users.api";
import { TManualAddLeadData } from "types/manual-add-lead.type";
import { isTypeSaleOrLetOrMortgage } from "utils/manual-add-lead.utils";
import { LeadsTableColumns } from "enums/leads-table-columns.enum";
import {
  getAccountLeadTableHiddenColumnsFromLocalStorage,
  setAccountLeadTableHiddenColumnsFromLocalStorage,
} from "utils/local-storage.utils";
import { TSelectOption } from "../../../../types/select-input.type";

const leadsApi = new LeadsApi();
const usersApi = new AccountUsersApi();
let latestLeadsLoadingAttempt: number | null;

export class AccountLeadsStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private leads: IObservableArray<AccountLeadStore>;
  private leadsMeta: TLeadsListMeta | null;
  private leadNotifications: ObservableMap<number, TLeadWithDetails>;
  private readonly assignableUsersForMultipleLeads: IObservableArray<
    TAssignableToLeadUserData
  >;
  public leadsFilterStore: AccountLeadsFiltersStore;
  public selectedLeadId: number | null;
  private readonly accountUsers: IObservableArray<TUserBasicData>;
  private hiddenLeadsTableColumns: IObservableArray<LeadsTableColumns>;

  constructor(
    root: AppStore,
    accountId: number,
    skipPreloadingFiltersFromStorage: boolean,
    skipPreloadingHiddenColumnsFromStorage: boolean
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.root = root;
    this.accountId = accountId;
    this.leads = observable.array<AccountLeadStore>();
    this.leadsMeta = null;
    this.leadNotifications = observable.map<number, TLeadWithDetails>();
    this.assignableUsersForMultipleLeads = observable.array<
      TAssignableToLeadUserData
    >();
    this.leadsFilterStore = new AccountLeadsFiltersStore(
      accountId,
      skipPreloadingFiltersFromStorage
    );
    this.selectedLeadId = null;
    this.accountUsers = observable.array<TUserBasicData>();
    this.hiddenLeadsTableColumns = observable.array<LeadsTableColumns>(
      skipPreloadingHiddenColumnsFromStorage
        ? []
        : getAccountLeadTableHiddenColumnsFromLocalStorage(accountId)
    );
  }

  get assignableUsersForMultipleLeadsArray() {
    return this.assignableUsersForMultipleLeads.slice();
  }

  get assignableUsersForMultipleLeadsOptions(): TSelectOption<
    TAssignableToLeadUserData
  >[] {
    return sortBy(this.assignableUsersForMultipleLeadsArray, user =>
      getUserFullName(user.firstName, user.lastName)
    ).map(user => ({
      value: user,
      label: user.email,
      isDisabled: user.shouldDisable,
      isHidden: user.shouldHide,
    }));
  }

  get leadNotificationsCount() {
    return this.leadNotifications.size;
  }

  get meta() {
    let currentPage = 0;
    if (!!this.leadsMeta?.offset && this.leadsMeta?.limit) {
      currentPage = this.leadsMeta?.offset / this.leadsMeta?.limit;
    }

    return {
      currentPage,
      currentSelectionTotalLeads: this.leadsMeta?.count || 0,
      unreadTotalLeads: this.leadsMeta?.unreadTotalLeads || 0,
      inProgressTotalLeads: this.leadsMeta?.inProgressTotalLeads || 0,
      businessTotalLeads: this.leadsMeta?.businessTotalLeads || 0,
      noBusinessTotalLeads: this.leadsMeta?.noBusinessTotalLeads || 0,
      overallTotalLeads: this.leadsMeta?.overallTotalLeads || 0,
      offset: this.leadsMeta?.offset || 0,
      limit: this.leadsMeta?.limit || 0,
      count: this.leadsMeta?.count || 0,
    };
  }

  get leadsArray() {
    return this.leads.slice();
  }

  get leadsDataArray() {
    return this.leadsArray.map(leadStore => leadStore.lead);
  }

  get leadsMap() {
    return keyBy(this.leadsArray, lead => lead.lead.id);
  }

  get leadsDataMap() {
    return keyBy(this.leadsDataArray, lead => lead.id);
  }

  get selectedLeadStore() {
    if (!this.selectedLeadId) return null;

    return this.leadsMap[this.selectedLeadId];
  }

  get accountUsersArray() {
    return this.accountUsers.slice();
  }

  get accountUsersMap() {
    return keyBy(this.accountUsersArray, user => user.id);
  }

  get hiddenLeadsTableColumnsArray() {
    return this.hiddenLeadsTableColumns.slice();
  }

  private setAssignableUsersForMultipleLeads(
    assignableUsers: TAssignableToLeadUserData[]
  ) {
    this.assignableUsersForMultipleLeads.replace(assignableUsers);
  }

  private setLeads(leadsResponse: TGetLeadsResponse) {
    const { data, meta } = leadsResponse;
    this.leads.replace(
      (data as TLeadWithDetails[]).map(
        data => new AccountLeadStore(this.root, this.accountId, data)
      )
    );
    this.leadsMeta = meta;
  }

  private upsertLeads(leads: TLeadWithDetails[]) {
    leads.forEach(lead => {
      this.leadsMap[lead.id]?.upsertLeadData(lead);
    });
  }

  private addLeadStore(leadStore: AccountLeadStore) {
    this.leads.push(leadStore);
  }

  private setAccountUsers(accountUsers: TUserBasicData[]) {
    this.accountUsers.replace(accountUsers);
  }

  public resetMetaOffset() {
    if (!!this.leadsMeta) {
      this.leadsMeta.offset = 0;
    }
  }

  public addLeadNotification(lead: TLeadWithDetails) {
    if (this.leadNotifications.has(lead.id)) return;

    this.leadNotifications.set(lead.id, lead);
  }

  public clearLeadNotifications() {
    this.leadNotifications.clear();
  }

  public toggleHiddenLeadsTableColumns(columnKey: LeadsTableColumns) {
    if (this.hiddenLeadsTableColumns.includes(columnKey)) {
      const newArray = this.hiddenLeadsTableColumns.filter(
        key => key !== columnKey
      );
      this.hiddenLeadsTableColumns.replace(newArray);
    } else {
      this.hiddenLeadsTableColumns.push(columnKey);
    }

    setAccountLeadTableHiddenColumnsFromLocalStorage(
      this.accountId,
      this.hiddenLeadsTableColumns.slice()
    );
  }

  public async selectLead(leadId: number | null) {
    this.selectedLeadId = leadId;

    if (!!leadId) {
      await this.fetchAccountLead(leadId);
    }
  }

  public async updateLeads(leadIds: number[], data: Partial<TLeadWithDetails>) {
    const updatedLeads = await leadsApi.updateLeads(
      this.accountId,
      leadIds,
      data
    );
    this.upsertLeads(updatedLeads);
  }

  public async loadLeads(
    filter: TLeadsFilter,
    sortBy: SortingRule<string>[],
    searchQuery: string,
    includeSpeedScores: boolean,
    pageSize: number,
    page?: number
  ) {
    const now = Date.now();
    latestLeadsLoadingAttempt = now;

    await this.selectLead(null);

    const isDemoModeOn = this.root.uiStore.isDemoModeOn;
    if (isDemoModeOn) {
      this.setLeads(dummyLeadsList);
    } else {
      if (page === undefined) {
        this.resetMetaOffset();
      }
      const data = await leadsApi.getLeads(
        this.accountId,
        filter,
        sortBy,
        searchQuery,
        includeSpeedScores,
        pageSize,
        page !== undefined ? page : this.meta.currentPage
      );
      if (now === latestLeadsLoadingAttempt) {
        this.setLeads(data);
      }
    }
  }

  public async fetchAssignableUsersForMultipleLeads(leadIds: number[]) {
    const assignableUsersForMultipleLeads = await leadsApi.fetchAssignableUsersForMultipleLeads(
      this.accountId,
      leadIds
    );

    this.setAssignableUsersForMultipleLeads(assignableUsersForMultipleLeads);
  }

  public async fetchAccountLead(leadId: number) {
    if (!!this.leadsMap[leadId]) {
      await this.leadsMap[leadId].fetchDetails();
    } else {
      const newLeadStore = new AccountLeadStore(this.root, this.accountId, {
        id: leadId,
      } as TLeadWithDetails);
      await newLeadStore.fetchDetails();
      if (!!newLeadStore.lead) {
        this.addLeadStore(newLeadStore);
      }
    }
  }

  public async fetchAccountUsersBasicData() {
    const users = await usersApi.fetchAccountUsersBasicData(this.accountId);

    this.setAccountUsers(users);
  }

  public async manuallyAddLead(
    data: TManualAddLeadData,
    generalEnquiry: boolean
  ) {
    const shouldOmitPropertyDetails =
      !isTypeSaleOrLetOrMortgage(data.type) || generalEnquiry;
    await leadsApi.manuallyAddLead(this.accountId, {
      ...data,
      address: data.address || null,
      postcode: data.postcode || null,
      advertAddress: shouldOmitPropertyDetails
        ? null
        : data.advertAddress || null,
      advertPostcode: shouldOmitPropertyDetails
        ? null
        : data.advertPostcode || null,
      advertUrl: shouldOmitPropertyDetails ? null : data.advertUrl || null,
      propertyReference: shouldOmitPropertyDetails
        ? null
        : data.propertyReference || null,
    });
  }

  public async archiveLeads(archiveBefore: string) {
    await leadsApi.archiveLeads(this.accountId, archiveBefore);
  }
}
