import React, { FC } from "react";
import { Text } from "@chakra-ui/react";
import { FormControlDescription } from "./FormControl/FormControlDescription";

interface IProps {
  currentLength: number;
  maxLength: number;
}
export const FormInputCharacterCounter: FC<IProps> = ({
  currentLength,
  maxLength,
}) => {
  return (
    <FormControlDescription>
      <Text
        as={"b"}
        color={currentLength > maxLength ? "red.400" : "unset"}
      >{`${currentLength}`}</Text>
      /<Text as={"b"}>{`${maxLength}`}</Text> characters used
    </FormControlDescription>
  );
};
