import { observer } from "mobx-react";
import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { FormToolPageSettingsSelectedQuestionConfiguratorMenuDelete } from "./FormToolPageSettingsSelectedQuestionConfiguratorMenuDelete";
import { FormToolPageSettingsSelectedQuestionConfiguratorMenuSettings } from "./FormToolPageSettingsSelectedQuestionConfiguratorMenuSettings";
import { FormToolPageWIPFormStore } from "../../../../routes/form-tool/components/FormToolPageSettings/FormToolPageWIPForm.store";
import { QuestionnaireToolPageWIPFormStore } from "../../../../routes/lead-responder/routes/lead-responder-questionnaire-pages/QuestionnaireToolPageDetails/QuestionnaireToolPageSettings/stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  questionKey: string;
  formToolPageWIPFormStore:
    | FormToolPageWIPFormStore
    | QuestionnaireToolPageWIPFormStore;
}

export const FormToolPageSettingsSelectedQuestionConfiguratorMenu: FC<IProps> = observer(
  ({ questionKey, formToolPageWIPFormStore }) => {
    return (
      <Box
        width={"240px"}
        flexShrink={0}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"12px"}
      >
        <FormToolPageSettingsSelectedQuestionConfiguratorMenuSettings
          questionKey={questionKey}
          formToolPageWIPFormStore={formToolPageWIPFormStore}
        />
        <FormToolPageSettingsSelectedQuestionConfiguratorMenuDelete
          questionKey={questionKey}
          formToolPageWIPFormStore={formToolPageWIPFormStore}
        />
      </Box>
    );
  }
);
