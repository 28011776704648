import { AccountIntegrationReapitStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationReapit/AccountIntegrationReapit.store";
import React, { useCallback, useEffect, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { Box, Button } from "@chakra-ui/react";
import { Error, FormControlMeta, Loader } from "components";
import { ReapitOfficeAutosuggestSelect } from "./ReapitOfficeAutosuggestSelect";

interface IProps {
  officeName: string;
  existingOfficeId?: number;
  value: string | null;
  onChange: (value: string | null) => void;
  reapitIntegrationStore: AccountIntegrationReapitStore;
}

export const ReapitOfficeSync: React.FC<IProps> = ({
  reapitIntegrationStore,
  onChange,
  existingOfficeId,
  value,
  officeName,
}) => {
  const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
    ApiRequestStatusEnum.NONE
  );
  const reapitIntegrationOfficesStore =
    reapitIntegrationStore.accountIntegrationReapitOfficesStore;

  const handleFetchReapitOffices = useCallback(async () => {
    try {
      setLoadingStatus(ApiRequestStatusEnum.PENDING);
      await reapitIntegrationOfficesStore.fetchAccountIntegrationOfficesAndRelations();
      setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (err) {
      setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }, [reapitIntegrationOfficesStore, setLoadingStatus]);

  useEffect(() => {
    handleFetchReapitOffices();
  }, [handleFetchReapitOffices]);

  return (
    <Box width={"100%"} position={"relative"} height={"100px"}>
      {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
      {loadingStatus === ApiRequestStatusEnum.ERROR && (
        <Error
          message={"Could not load your Reapit offices. :("}
          action={
            <Button colorScheme={"blue"} onClick={handleFetchReapitOffices}>
              Reload
            </Button>
          }
        />
      )}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <Box mb={5}>
          <FormControlMeta
            label={"Sync Reapit Office"}
            description={
              "Choose a reapit office you would like to link to your new office"
            }
          />
          <ReapitOfficeAutosuggestSelect
            officeName={officeName}
            onChange={onChange}
            existingOfficeId={existingOfficeId}
            reapitIntegrationStore={reapitIntegrationStore}
            value={value}
          />
        </Box>
      )}
    </Box>
  );
};
