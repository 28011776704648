import { makeAutoObservable } from "mobx";
import {
  TFormToolFormResponsesResponse,
  TPageToolForm,
} from "types/form-tool-page.type";
import { AccountFormsApi } from "api/account-forms.api";

const formsApi = new AccountFormsApi();

export class AccountFormPageFormStore {
  private readonly accountId: number;
  private formData: TPageToolForm;
  private paginatedResponsesData: TFormToolFormResponsesResponse | null;

  constructor(accountId: number, formData: TPageToolForm) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.formData = formData;
    this.paginatedResponsesData = null;
  }

  get form() {
    return this.formData;
  }

  get paginatedResponses() {
    return this.paginatedResponsesData;
  }

  public upsertForm(formData: TPageToolForm) {
    this.formData = formData;
  }

  public async updateForm(data: Partial<TPageToolForm>) {
    const updatedForm = await formsApi.update(
      this.accountId,
      this.form.id,
      data
    );
    this.upsertForm(updatedForm);
  }

  public async fetchFormResponses(offset: number, limit: number) {
    const responses = await formsApi.fetchFormResponses(
      this.accountId,
      this.formData.id,
      offset,
      limit
    );
    this.setPaginatedResponses(responses);
  }

  private setPaginatedResponses(responses: TFormToolFormResponsesResponse) {
    this.paginatedResponsesData = responses;
  }
}
