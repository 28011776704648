import { Box, Link, useClipboard, VStack } from "@chakra-ui/react";
import { CodeSnippet, Message, StandardIconButton } from "components";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

interface IProps {
  code: string;
  codeEmail: string;
  instructions: string;
}

export const InstantValuationToolCodeSnippetInstructions: React.FC<IProps> = ({
  code,
  codeEmail,
  instructions,
}) => {
  const { onCopy: onCopyCode, hasCopied: hasCopiedCode } = useClipboard(
    decodeURIComponent(code)
  );

  return (
    <Box>
      <Box mb={8}>
        <Message status={AlertStatusEnum.INFO}>{instructions}</Message>
      </Box>
      <Box position={"relative"}>
        <CodeSnippet code={code} />
        <Box position={"absolute"} top={"10px"} right={"10px"}>
          <VStack spacing={2}>
            <Link isExternal={true} href={`mailto:?&body=${codeEmail}`}>
              <StandardIconButton
                aria-label={"copy-code"}
                colorScheme={"blue"}
                icon={<FontAwesomeIcon icon={faEnvelope} />}
              />
            </Link>
            <StandardIconButton
              onClick={onCopyCode}
              aria-label={"copy-code"}
              colorScheme={hasCopiedCode ? "green" : "blue"}
              icon={<FontAwesomeIcon icon={faCopy} />}
            />
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
