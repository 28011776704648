import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { makeAutoObservable } from "mobx";
import { AccountQuestionnairePagesStore } from "store/UserAccounts/UserAccount/AccountPages/AccountQuestionnairePagesStore/AccountQuestionnairePages.store";
import { TSelectOption } from "types/select-input.type";

import { LeadTypeEnum } from "enums/lead-type.enum";

interface IQuestionnaireToolPageRequestsStore {
  pageId: number | null;
  accountQuestionnairePagesStore: AccountQuestionnairePagesStore;
}

export class QuestionnaireToolPageResponsesStore {
  public loadingStatus: ApiRequestStatusEnum;
  public selectedQuestionnaireId: number | null;
  private readonly pageId: number | null;
  private readonly accountQuestionnairePagesStore: AccountQuestionnairePagesStore;

  constructor({
    pageId,
    accountQuestionnairePagesStore,
  }: IQuestionnaireToolPageRequestsStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loadingStatus = ApiRequestStatusEnum.NONE;
    this.pageId = pageId;
    this.selectedQuestionnaireId = null;
    this.accountQuestionnairePagesStore = accountQuestionnairePagesStore;
  }

  get pageStore() {
    if (!this.pageId) return null;

    return (
      this.accountQuestionnairePagesStore.accountQuestionnairePageStoresMap[
        this.pageId
      ] || null
    );
  }

  get selectedQuestionnaireStore() {
    if (!this.selectedQuestionnaireId) return null;

    return this.accountQuestionnairePagesStore
      .accountQuestionnairePageQuestionnaireStoresMap[
      this.selectedQuestionnaireId
    ];
  }

  get listData() {
    const responses = this.selectedQuestionnaireStore?.paginatedResponses;
    if (!responses) return null;

    return {
      data: responses.data,
      total: responses.meta.count,
      pageSize: responses.meta.limit,
      pageIndex: responses.meta.offset / responses.meta.limit,
      canPreviousPage: responses.meta.offset > 0,
      canNextPage:
        responses.meta.offset + responses.meta.limit < responses.meta.count,
    };
  }

  get questionnaireOptions(): TSelectOption<number>[] {
    const options = [];
    if (this.pageStore) {
      const pageQuestionnaireConfig = this.pageStore.page
        .pageQuestionnaireConfig;
      if (pageQuestionnaireConfig.saleQuestionnaireId) {
        options.push({
          label: LeadTypeEnum.Sale,
          value: pageQuestionnaireConfig.saleQuestionnaireId,
        });
      }
      if (pageQuestionnaireConfig.landlordQuestionnaireId) {
        options.push({
          label: LeadTypeEnum.Landlord,
          value: pageQuestionnaireConfig.landlordQuestionnaireId,
        });
      }
      if (pageQuestionnaireConfig.letQuestionnaireId) {
        options.push({
          label: LeadTypeEnum.Let,
          value: pageQuestionnaireConfig.letQuestionnaireId,
        });
      }
      if (pageQuestionnaireConfig.vendorQuestionnaireId) {
        options.push({
          label: LeadTypeEnum.Vendor,
          value: pageQuestionnaireConfig.vendorQuestionnaireId,
        });
      }
      if (pageQuestionnaireConfig.mortgageRequestQuestionnaireId) {
        options.push({
          label: LeadTypeEnum.MortgageRequest,
          value: pageQuestionnaireConfig.mortgageRequestQuestionnaireId,
        });
      }
    }

    return options;
  }

  public setSelectedQuestionnaireId(id: number | null) {
    this.selectedQuestionnaireId = id;
  }

  public async handleGoToPage(pageNumber: number) {
    const responses = this.selectedQuestionnaireStore?.paginatedResponses;
    if (!responses) return;

    await this.loadData(
      pageNumber * responses.meta.limit,
      responses.meta.limit
    );
  }

  public async loadData(offset: number, limit: number) {
    if (!this.pageId) {
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      return;
    }

    try {
      this.setLoadingStatus(ApiRequestStatusEnum.PENDING);
      if (!this.pageStore) {
        await this.accountQuestionnairePagesStore.loadPage(this.pageId);
      }

      if (!!this.selectedQuestionnaireId && !this.selectedQuestionnaireStore) {
        await this.accountQuestionnairePagesStore.loadQuestionnaire(
          this.selectedQuestionnaireId
        );
      }

      await this.selectedQuestionnaireStore?.fetchQuestionnaireResponses(
        offset,
        limit
      );
      this.setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
    } catch (error) {
      this.setLoadingStatus(ApiRequestStatusEnum.ERROR);
    }
  }

  private setLoadingStatus(loadingStatus: ApiRequestStatusEnum) {
    this.loadingStatus = loadingStatus;
  }
}
