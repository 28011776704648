import {
  TLeadReapitAddress,
  TReapitOffice,
  TReapitOfficeRelation,
} from "types/reapit.type";
import { TIntegrationOfficeRelationData } from "../types/integration-sync-office.type";
import { TIntegrationOffice } from "../store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationOfficesStore.store";
import { LeadTypeEnum } from "enums/lead-type.enum";
import {
  normaliseAndSplitAddress,
  shiftPropertyName,
  shiftPropertyNumber,
} from "./integration-address.utils";

export const ADDRESS_LINES_MAX_LENGTH: [number, number, number, number] = [
  35,
  30,
  30,
  30,
];

export const getReapitSegmentedAddress = (
  address: string | null,
  postcode: string | null
): TLeadReapitAddress => {
  const reapitAddress = {
    buildingName: "",
    buildingNumber: "",
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    postcode: postcode || "",
  };
  if (!address) {
    return reapitAddress;
  }

  const splitAddress = normaliseAndSplitAddress(address, postcode);
  reapitAddress.buildingName = shiftPropertyName(splitAddress);
  reapitAddress.buildingNumber = shiftPropertyNumber(splitAddress);

  const [line1, line2, line3, ...rest] = splitAddress.slice();

  const line4 = rest.join(", ");

  return { ...reapitAddress, line1, line2, line3, line4 };
};

export const reapitOfficeDataToIntegrationOfficeDataMapper = (
  reapitOffices: TReapitOffice[]
): TIntegrationOffice[] => {
  return reapitOffices.map(reapitOffice => {
    return {
      id: reapitOffice.reapitId,
      name: reapitOffice.reapitName,
    };
  });
};

export const mapIntegrationOfficeRelationsToReapitOfficeRelations = (
  relations: TIntegrationOfficeRelationData[]
) => {
  return relations.map(relation => {
    return {
      reapitId: relation.branchId,
      officeId: relation.officeId,
    };
  });
};
export const mapReapitOfficeRelationsToIntegrationOfficeRelations = (
  reapitOfficeRelations: TReapitOfficeRelation[]
) => {
  return reapitOfficeRelations.map(reapitOfficeRelation => {
    return {
      branchId: reapitOfficeRelation.reapitId,
      officeId: reapitOfficeRelation.officeId,
    };
  });
};

export const createPriceOptionsArray = (
  type: LeadTypeEnum,
  array: number[]
) => {
  if (type === LeadTypeEnum.Let) {
    return array.map(item => ({
      label: `£${item.toLocaleString()} PCM`,
      value: item,
    }));
  } else {
    return array.map(item => ({
      label: `£${item.toLocaleString()}`,
      value: item,
    }));
  }
};
