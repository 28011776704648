import { observer } from "mobx-react";
import { Route, Switch, useParams } from "react-router-dom";
import {
  TOOLS_FORMS_PAGE_DETAILS_RESPONSES_ROUTE,
  TOOLS_FORMS_PAGE_DETAILS_ROUTE,
} from "constants/routes";
import { FormToolPageSettings } from "../FormToolPageSettings/FormToolPageSettings";
import React, { FC, useMemo } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { FormToolPageResponses } from "../FormToolPageResponses/FormToolPageResponses";

interface IProps {
  accountStore: UserAccountStore;
}

export const FormToolPageDetails: FC<IProps> = observer(({ accountStore }) => {
  const { id } = useParams<{ id: string }>();
  const pageId = useMemo(() => parseInt(id!), [id]);
  const accountFormPagesStore =
    accountStore.accountPagesStore.accountFormPagesStore;

  return (
    <Switch>
      <Route path={TOOLS_FORMS_PAGE_DETAILS_ROUTE} exact>
        <FormToolPageSettings
          accountFormPagesStore={accountFormPagesStore}
          pageId={pageId}
        />
      </Route>
      <Route path={TOOLS_FORMS_PAGE_DETAILS_RESPONSES_ROUTE} exact>
        <FormToolPageResponses
          accountFormPagesStore={accountFormPagesStore}
          pageId={pageId}
        />
      </Route>
    </Switch>
  );
});
