import { observer } from "mobx-react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import React, { FC } from "react";
import { AuthStoreTypeEnum } from "enums/auth-store-type.enum";

interface IProps {
  authTypes: AuthStoreTypeEnum[];
  children: React.ReactNode;
  showInstead?: React.ReactNode;
}

export const ShowForAuthTypes: FC<IProps> = observer(
  ({ authTypes, children, showInstead = null }) => {
    const {
      authStore: { selectedAuthStoreType },
    } = useAppStore();

    if (!!selectedAuthStoreType && authTypes.includes(selectedAuthStoreType)) {
      return <>{children}</>;
    }

    return <>{showInstead}</>;
  }
);
