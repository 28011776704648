import { TabsGroupLayout } from "routes/dashboard/components/Layouts/TabsGroupLayout";
import { AccountBillingSubscriptionTab } from "./components/AccountBillingSubscriptionTab/AccountBillingSubscriptionTab";
import { AccountBillingPaymentMethodTab } from "./components/AccountBillingPaymentMethodTab/AccountBillingPaymentMethodTab";
import { AccountBillingInvoicesTab } from "./components/AccountBillingInvoicesTab/AccountBillingInvoicesTab";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { observer } from "mobx-react";
import { FC } from "react";

interface IProps {
  accountStore: UserAccountStore;
}

export const AccountBilling: FC<IProps> = observer(({ accountStore }) => {
  return (
    <TabsGroupLayout
      title={"Billing"}
      config={[
        {
          tabHash: "#subscription",
          tabName: "Subscription",
          tabComponent: (
            <AccountBillingSubscriptionTab accountStore={accountStore} />
          ),
        },
        {
          tabHash: "#payment-method",
          tabName: "Payment method",
          tabComponent: (
            <AccountBillingPaymentMethodTab accountStore={accountStore} />
          ),
        },
        {
          tabHash: "#invoices",
          tabName: "Invoices",
          tabComponent: (
            <AccountBillingInvoicesTab accountStore={accountStore} />
          ),
        },
      ]}
    />
  );
});
