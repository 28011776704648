import { BaseApi } from "./base.api";
import { TSmsTemplate } from "types/sms-template.type";
import { TTemplateMergeTagGroup } from "types/template-merge-tag.type";

export class SmsTemplatesApi extends BaseApi {
  async fetchAll(accountId: number): Promise<TSmsTemplate[]> {
    return this.get(`/accounts/${accountId}/sms-templates`);
  }

  async fetchOne(accountId: number, templateId: number): Promise<TSmsTemplate> {
    return this.get(`/accounts/${accountId}/sms-templates/${templateId}`);
  }

  async fetchMergeTags(accountId: number): Promise<TTemplateMergeTagGroup[]> {
    return this.get(`/accounts/${accountId}/sms-templates/merge-tags`);
  }

  async create(
    accountId: number,
    data: Partial<TSmsTemplate>
  ): Promise<TSmsTemplate> {
    return this.post<TSmsTemplate>(
      `/accounts/${accountId}/sms-templates`,
      data
    );
  }

  async update(
    accountId: number,
    templateId: number,
    data: Partial<TSmsTemplate>
  ): Promise<TSmsTemplate> {
    return this.patch<TSmsTemplate>(
      `/accounts/${accountId}/sms-templates/${templateId}`,
      data
    );
  }

  async delete(accountId: number, templateId: number) {
    await this.del(`/accounts/${accountId}/sms-templates/${templateId}`);
  }

  async sendTestSms(
    accountId: number,
    templateId: number,
    phoneNumber: string
  ) {
    await this.post(`/accounts/${accountId}/sms-templates/${templateId}/test`, {
      phone: phoneNumber,
    });
  }
}
