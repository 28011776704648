import * as Yup from "yup";
import {
  FILE_TOO_LARGE,
  GOOGLE_ADS_CONVERSION_LABEL_ID_REQUIRED,
  INVALID_DOMAIN_NAME,
  INVALID_HEX_COLOR,
  REQUIRED_FIELD,
  URL_FIELD,
  URL_FIELD_MISSING_PROTOCOL,
} from "../../constants/validator-messages";
import { DOMAIN_NAME_REGEX, HEX_COLOR_REGEX } from "../regexs.utils";
import { doesUrlContainProtocol } from "../custom-yup-validators.utils";
import { PageCtaEnum } from "enums/page-cta.enum";

export type TPageSettingsFieldValues = {
  name: string;
  host: string;
  color: string;
  facebookPixelId: string | null;
  googleAnalyticsId: string | null;
  googleAdsConversionId: string | null;
  googleAdsConversionLabelId: string | null;
  privacyPolicyLink: string;
  gdprCheckboxEnabled: boolean;
  gdprCheckboxLabel: string | null;
  gdprCheckboxDescription: string | null;
  gdprCheckboxAcceptanceRequired: boolean;
  photoFile: File | null;
  customLogoFile: File | null;
};

export type TInstantValuationToolSettingsFieldValues = TPageSettingsFieldValues & {
  presetValuationType: string | null;
  ctaType: PageCtaEnum;
  customCtaLabel: string | null;
  customCtaUrl: string | null;
  etaEnabled: boolean;
  incompleteValuationLeadEmailsEnabled: boolean;
  reportDownloadEnabled: boolean;
  showBathroomsAndReceptions: boolean;
};

export type TFormToolSettingsFieldValues = TPageSettingsFieldValues & {
  customThankYouPageHeader: string | null;
  customThankYouPageBody: string | null;
  customThankYouPageCtaLabel: string | null;
  customThankYouPageCtaUrl: string | null;
};

export type TQuestionnaireToolSettingsFieldValues = TFormToolSettingsFieldValues & {
  active: boolean;
  isSlideshowModeEnabled: boolean;
  leadFilterId: number | null;
  saleQuestionnaireId: number | null;
  letQuestionnaireId: number | null;
  vendorQuestionnaireId: number | null;
  landlordQuestionnaireId: number | null;
  mortgageRequestQuestionnaireId: number | null;
};

const pageSettingsSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  host: Yup.string()
    .matches(DOMAIN_NAME_REGEX, INVALID_DOMAIN_NAME)
    .required(REQUIRED_FIELD),
  color: Yup.string().matches(HEX_COLOR_REGEX, INVALID_HEX_COLOR),
  facebookPixelId: Yup.string(),
  googleAnalyticsId: Yup.string(),
  googleAdsConversionId: Yup.string(),
  googleAdsConversionLabelId: Yup.string().when("googleAdsConversionId", {
    is: (googleAdsConversionId: string) => !!googleAdsConversionId,
    then: Yup.string()
      .nullable()
      .required(GOOGLE_ADS_CONVERSION_LABEL_ID_REQUIRED),
    otherwise: Yup.string().nullable(),
  }),
  privacyPolicyLink: Yup.string()
    .test("is-valid-url", URL_FIELD_MISSING_PROTOCOL, doesUrlContainProtocol)
    .url(URL_FIELD),
  gdprCheckboxEnabled: Yup.boolean(),
  gdprCheckboxLabel: Yup.string().when("gdprCheckboxEnabled", {
    is: (gdprCheckboxEnabled: boolean) => !!gdprCheckboxEnabled,
    then: Yup.string().required(REQUIRED_FIELD),
  }),
  gdprCheckboxDescription: Yup.string(),
  gdprCheckboxAcceptanceRequired: Yup.boolean(),
  photoFile: Yup.mixed().test("fileSize", FILE_TOO_LARGE, value => {
    if (!value) return true; // attachment is optional
    return value.size <= 20 * 1024 * 1024; // 20mb
  }),
  customLogoFile: Yup.mixed().test("fileSize", FILE_TOO_LARGE, value => {
    if (!value) return true; // attachment is optional
    return value.size <= 5 * 1024 * 1024; // 5mb
  }),
});

export const formToolPageSettingsValidationSchema = pageSettingsSchema.concat(
  Yup.object().shape({
    customThankYouPageHeader: Yup.string(),
    customThankYouPageBody: Yup.string(),
    customThankYouPageCtaLabel: Yup.string().max(255),
    customThankYouPageCtaUrl: Yup.string()
      .max(255)
      .url(),
  })
);

export const questionnaireToolPageSettingsValidationSchema = formToolPageSettingsValidationSchema.concat(
  Yup.object().shape({
    active: Yup.boolean(),
    isSlideshowModeEnabled: Yup.boolean(),
    leadFilterId: Yup.number().nullable(true),
  })
);

export const instantValuationToolSettingsValidationSchema = pageSettingsSchema.concat(
  Yup.object().shape({
    presetValuationType: Yup.string().nullable(),
    ctaType: Yup.string(),
    customCtaLabel: Yup.string().when("ctaType", {
      is: (ctaType: PageCtaEnum) => ctaType === PageCtaEnum.CUSTOM,
      then: Yup.string()
        .nullable()
        .required(REQUIRED_FIELD),
      otherwise: Yup.string().nullable(),
    }),
    customCtaUrl: Yup.string().when("ctaType", {
      is: (ctaType: PageCtaEnum) => ctaType === PageCtaEnum.CUSTOM,
      then: Yup.string()
        .test(
          "is-valid-url",
          URL_FIELD_MISSING_PROTOCOL,
          doesUrlContainProtocol
        )
        .url(URL_FIELD)
        .nullable()
        .required(REQUIRED_FIELD),
      otherwise: Yup.string().nullable(),
    }),
    etaEnabled: Yup.boolean(),
    incompleteValuationLeadEmailsEnabled: Yup.boolean(),
    reportDownloadEnabled: Yup.boolean(),
  })
);
