import * as Yup from "yup";
import { REQUIRED_FIELD } from "constants/validator-messages";

export type TGraingerSalesforceIntegrationConfigFieldValues = {
  clientId: string;
  userName: string;
  certificatePrivateKey: string;
  testMode: boolean;
};

export const graingerSalesforceIntegrationInitialValues: TGraingerSalesforceIntegrationConfigFieldValues = {
  clientId: "",
  userName: "",
  certificatePrivateKey: "",
  testMode: false,
};

export const graingerSalesforceIntegrationConnectValidationSchema = Yup.object().shape(
  {
    clientId: Yup.string().required(REQUIRED_FIELD),
    userName: Yup.string().required(REQUIRED_FIELD),
    certificatePrivateKey: Yup.string().required(REQUIRED_FIELD),
    testMode: Yup.boolean(),
  }
);
