import { observer } from "mobx-react";
import { Box, Button, Heading, useToast, VStack } from "@chakra-ui/react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import React, { useCallback, useState } from "react";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { twoFactorAuthCodeValidationSchema } from "utils/validation-schemas/two-factor-auth-code.validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TLeadProAuthTokenDTO } from "types/token.type";

type TFieldValues = {
  verificationCode: string;
};

const initialValues: TFieldValues = {
  verificationCode: "",
};

export const TwoFactorAuthLogin = observer(() => {
  const toast = useToast();
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const {
    authStore: {
      leadProAuthStore: { request2FACode, confirmLoginWith2FA },
      authTokenPayload,
    },
  } = useAppStore();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: yupResolver(twoFactorAuthCodeValidationSchema),
  });

  const handRequest2FACode = useCallback(async () => {
    try {
      setIsRequestingCode(true);
      await request2FACode();
      setIsCodeSent(true);
      toast({
        ...DEFAULT_SUCCESS_TOAST_OPTIONS,
        description:
          "The phone number you provided should receive an SMS shortly.",
      });
    } catch (error) {
      toast({
        ...DEFAULT_ERROR_TOAST_OPTIONS,
        description: <ApiMessageStack messageStack={error.message} />,
      });
    } finally {
      setIsRequestingCode(false);
    }
  }, [setIsRequestingCode, request2FACode, toast]);

  const onSubmit = useCallback(
    async (values: TFieldValues) => {
      try {
        await confirmLoginWith2FA(values.verificationCode);
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      }
    },
    [toast, confirmLoginWith2FA]
  );

  return (
    <Box>
      <Heading fontSize="3xl" textAlign="center" lineHeight="none" mb={6}>
        Security verification
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={2} align={"stretch"}>
          <FormControlV2<TFieldValues>
            name={"verificationCode"}
            control={control}
            label={"Phone number verification code"}
            description={
              isCodeSent
                ? `Enter the 6-digit code sent to phone number ending with ${
                    (authTokenPayload as TLeadProAuthTokenDTO)?.sms2FANumber
                  }.`
                : "Please request the authentication code."
            }
            type={FormControlsTypeEnum.TEXT}
          />
          <Button
            onClick={handRequest2FACode}
            isFullWidth={true}
            variant={"outline"}
            colorScheme={"blue"}
            isLoading={isRequestingCode}
            isDisabled={isRequestingCode}
          >
            Request code
          </Button>
          <Button
            type={"submit"}
            isFullWidth={true}
            colorScheme={"blue"}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Verify
          </Button>
        </VStack>
      </form>
    </Box>
  );
});
