import { VStack } from "@chakra-ui/layout";
import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import {
  ActionPromptContainer,
  ApiMessageStack,
  FormControlV2,
} from "components";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { LEAD_STAGE_OPTIONS } from "constants/lead-stage-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { LeadStageEnum } from "enums/lead-stage.enum";
import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AccountLeadStatusesStore } from "store/UserAccounts/UserAccount/AccountLeadStatuses.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { customStatusValidationSchema } from "utils/validation-schemas/account-custom-status.validation";
import { LeadStageSelectOption } from "components/select-input/custom-options/LeadStageSelectOption";

type TFieldValues = {
  name: string;
  stageId?: LeadStageEnum;
};

interface ICustomStatusFormPromptProps {
  leadStatusesStore: AccountLeadStatusesStore;
  selectedStatusId?: number;
  closePrompt: () => void;
}

export const CustomStatusFormPrompt: React.FC<ICustomStatusFormPromptProps> = observer(
  ({ leadStatusesStore, selectedStatusId, closePrompt }) => {
    const toast = useToast();

    const selectedStatus = useMemo(() => {
      if (!!selectedStatusId) {
        return leadStatusesStore.leadStatusesMapByStatusId[selectedStatusId];
      }
    }, [leadStatusesStore, selectedStatusId]);

    const initialValues: TFieldValues = useMemo(() => {
      return {
        name: selectedStatus?.name || "",
        stageId: LeadStageEnum.UNREAD,
      };
    }, [selectedStatus]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(customStatusValidationSchema),
    });

    const handleCreateStatus = useCallback(
      async values => {
        try {
          await leadStatusesStore.createAccountLeadStatus(values);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Custom status has been created."}
              />
            ),
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [toast, closePrompt, leadStatusesStore]
    );

    const handleUpdateStatus = useCallback(
      async (id: number, values: TFieldValues) => {
        try {
          const { name } = values;
          await leadStatusesStore.updateAccountLeadStatus(id, name);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack
                messageStack={"Custom status has been updated."}
              />
            ),
          });
          closePrompt();
        } catch (error) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={error.message} />,
          });
        }
      },
      [leadStatusesStore, closePrompt, toast]
    );

    const submitForm = useCallback(
      async (values: TFieldValues) => {
        if (!!selectedStatusId) {
          await handleUpdateStatus(selectedStatusId, values);
        } else {
          await handleCreateStatus(values);
        }
      },
      [selectedStatusId, handleUpdateStatus, handleCreateStatus]
    );

    return (
      <ActionPromptContainer
        header={
          !!selectedStatusId ? `Edit custom status` : `Create custom status`
        }
        body={
          <form onSubmit={handleSubmit(submitForm)}>
            <VStack spacing={5} width={"100%"} align={"stretch"}>
              <FormControlV2
                name={"name"}
                type={FormControlsTypeEnum.TEXT}
                control={control}
                label={"Custom status name"}
              />
              {!selectedStatusId && (
                <FormControlV2
                  name={"stageId"}
                  type={FormControlsTypeEnum.SINGLE_SELECT}
                  control={control}
                  label={"Stage"}
                  description={"Please assign a stage to this custom status."}
                  additionalProps={{
                    options: LEAD_STAGE_OPTIONS,
                    optionComponent: (
                      optionProps: IGetOptionPropsData<LeadStageEnum>
                    ) => <LeadStageSelectOption optionProps={optionProps} />,
                  }}
                />
              )}

              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {!!selectedStatusId ? `Edit` : `Create`}
                </Button>
              </Box>
            </VStack>
          </form>
        }
      />
    );
  }
);
