import React from "react";
import { Box, HStack } from "@chakra-ui/react";

interface IProps {
  children: React.ReactNode;
  title?: string;
}

export const LeadFilterHeaderCell: React.FC<IProps> = ({ children, title }) => {
  return (
    <HStack spacing={2}>
      {!!title && <Box>{title}</Box>}
      {children}
    </HStack>
  );
};
