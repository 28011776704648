import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountIntegrationAltoLeadSourcesStore } from "./AccountIntegrationAltoLeadSources.store";
import { AccountIntegrationOfficesStore } from "../AccountIntegrationOfficesStore.store";
import { AccountAltoApi } from "api/account-alto.api";
import { AccountIntegrationAltoIntentionsStore } from "./AccountIntegrationAltoIntentions.store";
import { AccountIntegrationAltoNegotiatorsStore } from "./AccountIntegrationAltoNegotiators.store";
const accountAltoApi = new AccountAltoApi();

export class AccountIntegrationAltoStore {
  public accountIntegrationAltoOfficesStore: AccountIntegrationOfficesStore;
  public accountIntegrationAltoLeadSourcesStore: AccountIntegrationAltoLeadSourcesStore;
  public accountIntegrationAltoIntentionsStore: AccountIntegrationAltoIntentionsStore;
  public accountIntegrationAltoNegotiatorsStore: AccountIntegrationAltoNegotiatorsStore;
  public accountId: number;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountIntegrationAltoOfficesStore = new AccountIntegrationOfficesStore(
      root,
      accountId,
      accountAltoApi
    );
    this.accountIntegrationAltoLeadSourcesStore = new AccountIntegrationAltoLeadSourcesStore(
      root,
      accountId
    );
    this.accountIntegrationAltoIntentionsStore = new AccountIntegrationAltoIntentionsStore(
      accountId
    );
    this.accountIntegrationAltoNegotiatorsStore = new AccountIntegrationAltoNegotiatorsStore(
      root,
      accountId
    );
    this.accountId = accountId;
  }
}
