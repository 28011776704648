import { BaseApi } from "./base.api";
import {
  TPersistentFilter,
  TPersistentFiltersExpressionSchema,
} from "types/persistent-filters.type";

export class PersistentFiltersApi extends BaseApi {
  async getPersistentFilters(accountId: number): Promise<TPersistentFilter[]> {
    return this.get(`/accounts/${accountId}/lead-filters`);
  }

  async getPersistentFiltersSchema(
    accountId: number
  ): Promise<TPersistentFiltersExpressionSchema[]> {
    return this.get(`/accounts/${accountId}/lead-filters/schema`);
  }

  async createPersistentFilter(
    accountId: number,
    persistentFilter: Partial<TPersistentFilter>
  ): Promise<TPersistentFilter> {
    return this.post(`/accounts/${accountId}/lead-filters`, persistentFilter);
  }

  async updatePersistentFilter(
    accountId: number,
    filterId: number,
    persistentFilter: Partial<TPersistentFilter>
  ): Promise<TPersistentFilter> {
    return this.put(
      `/accounts/${accountId}/lead-filters/${filterId}`,
      persistentFilter
    );
  }

  async deletePersistentFilter(
    accountId: number,
    filterId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/lead-filters/${filterId}`);
  }
}
