import React, { useCallback, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import { isEqual } from "lodash";
import { AccountLeadsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeadsFilters.store";
import { observer } from "mobx-react";
import { CenteredMessage } from "components/message/CenteredMessage";
import {
  TLeadFilterMenuItemData,
  TLeadFilterMenuItemValue,
  TLeadFilterNestedMenuItemData,
  TNestedFilterConfiguration,
} from "types/leads-filter.type";
import { LeadFilterSimpleMenuItem } from "./LeadFilterSimpleMenuItem";
import { DropdownFilterMenuShell, DropdownNoFilterMessage } from "components";
import { Box, HStack } from "@chakra-ui/react";
import { ResetLeadFilters } from "./ResetLeadFilters";

interface IProps {
  title?: string;
  optionLabel?: string;
  leadsFilterStore: AccountLeadsFiltersStore;
  icon: React.ReactNode;
  handleFilterItemToggle: (parentValue: any, childValue?: any) => void;
  filterType: LeadSimpleFilterTypes;
  availableOptions: TLeadFilterNestedMenuItemData[];
  selectedOptions: TNestedFilterConfiguration;
  appliedOptions?: TNestedFilterConfiguration;
  showResetFilters?: boolean;
}

export const LeadsFilterNestedMenu: React.FC<IProps> = observer(
  ({
    title,
    optionLabel,
    icon,
    handleFilterItemToggle,
    filterType,
    availableOptions,
    selectedOptions,
    appliedOptions,
    leadsFilterStore,
    showResetFilters = true,
  }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );

    const onMenuOpen = useCallback(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await leadsFilterStore.loadAvailableFilters(filterType);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [leadsFilterStore, filterType]);

    const handleClose = useCallback(() => {
      if (!isEqual(selectedOptions, appliedOptions)) {
        leadsFilterStore.setActiveFromPendingFilter();
      }
    }, [leadsFilterStore, selectedOptions, appliedOptions]);

    const onMenuItemClick = useCallback(
      (parentOption?: TLeadFilterMenuItemValue) => (
        option: TLeadFilterMenuItemValue
      ) => () => {
        if (!!parentOption) {
          handleFilterItemToggle(parentOption, option);
        } else {
          handleFilterItemToggle(option);
        }
      },
      [handleFilterItemToggle]
    );

    const isPrimaryOptionSelected = useCallback(
      optionValue => !!selectedOptions[optionValue],
      [selectedOptions]
    );

    return (
      <DropdownFilterMenuShell
        title={title}
        icon={icon}
        badgeCount={Object.keys(selectedOptions).length}
        onOpen={onMenuOpen}
        onClose={handleClose}
        loadingStatus={loadingStatus}
      >
        <HStack
          width={"100%"}
          justifyContent={"space-between"}
          gap={2}
          pl={5}
          pr={3}
          py={3}
        >
          <Box fontWeight={800} color={"leadpro.700"}>
            {optionLabel}
          </Box>
          {showResetFilters && (
            <ResetLeadFilters leadsFilterStore={leadsFilterStore} />
          )}
        </HStack>
        {!availableOptions.length && (
          <CenteredMessage
            height={"100px"}
            message={<DropdownNoFilterMessage />}
          />
        )}
        {!!availableOptions.length && (
          <Box maxHeight={"500px"} overflowY={"auto"}>
            {availableOptions.map((availableOption, index) => {
              return (
                <Box key={availableOption.value}>
                  <LeadFilterSimpleMenuItem
                    key={index}
                    availableOption={availableOption}
                    onMenuItemClick={onMenuItemClick()}
                    selectedOptions={Object.keys(selectedOptions)}
                  />
                  {isPrimaryOptionSelected(availableOption.value) &&
                    availableOption.nestedOptions.map(
                      (secondaryOption: TLeadFilterMenuItemData) => {
                        return (
                          <Box ml={3} key={secondaryOption.value}>
                            <LeadFilterSimpleMenuItem
                              key={secondaryOption.value}
                              availableOption={secondaryOption}
                              onMenuItemClick={onMenuItemClick(
                                availableOption.value
                              )}
                              selectedOptions={
                                selectedOptions[availableOption.value]
                              }
                              textTransform={"lowercase"}
                            />
                          </Box>
                        );
                      }
                    )}
                </Box>
              );
            })}
          </Box>
        )}
      </DropdownFilterMenuShell>
    );
  }
);
