import { BaseApi } from "./base.api";
import {
  TAccountIntegrationData,
  TIntegrationConfig,
} from "types/account-integration.type";
import {
  TIntegrationOfficeRelation,
  TIntegrationOfficeRelationData,
} from "types/integration-sync-office.type";

export class AccountZooplaApi extends BaseApi {
  async regenerateZooplaApiKey(
    accountId: number
  ): Promise<TAccountIntegrationData<TIntegrationConfig>> {
    return this.post<TAccountIntegrationData<TIntegrationConfig>>(
      `/accounts/${accountId}/zoopla/regenerate-api-key`
    );
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TIntegrationOfficeRelation[]> {
    return this.get(`/accounts/${accountId}/zoopla/office-relations`);
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TIntegrationOfficeRelationData[]
  ) {
    await this.post(`/accounts/${accountId}/zoopla/office-relations`, {
      items: relationsData,
    });
  }
}
