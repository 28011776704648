export enum SelectInputSizes {
  SMALL = "sm",
  BASE = "base",
  LARGE = "lg",
}

export type SelectInputSizeConfig = {
  height: string;
  fontSize: string;
};

export const SELECT_INPUT_SIZE_CONFIG: Record<
  SelectInputSizes,
  SelectInputSizeConfig
> = {
  sm: {
    height: "35px",
    fontSize: "sm",
  },
  base: {
    height: "42px",
    fontSize: "base",
  },
  lg: {
    height: "50px",
    fontSize: "base",
  },
};
