import { BaseApi } from "./base.api";
import { TPageData, TCreateOrUpdatePageData } from "types/page.type";
import { getFormDataFromObject } from "../utils/api.utils";
import { PageToolTypeEnum } from "../enums/page-tool-type.enum";

export class AccountPagesApi extends BaseApi {
  async fetchAll(
    accountId: number,
    type: PageToolTypeEnum
  ): Promise<TPageData[]> {
    return this.get(`/accounts/${accountId}/pages`, {
      params: {
        type,
      },
    });
  }

  async fetchOne(
    id: number,
    accountId: number,
    type: PageToolTypeEnum
  ): Promise<TPageData> {
    return this.get(`/accounts/${accountId}/pages/${id}`, {
      params: {
        type,
      },
    });
  }

  async create(
    accountId: number,
    data: Partial<TCreateOrUpdatePageData>
  ): Promise<TPageData> {
    const formData = getFormDataFromObject<Partial<TCreateOrUpdatePageData>>(
      data
    );
    return this.post<TPageData>(`/accounts/${accountId}/pages`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  async update(
    accountId: number,
    pageId: number,
    data: Partial<TCreateOrUpdatePageData>
  ): Promise<TPageData> {
    const formData = getFormDataFromObject<Partial<TCreateOrUpdatePageData>>(
      data
    );
    return this.put<TPageData>(
      `/accounts/${accountId}/pages/${pageId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  }

  async delete(accountId: number, pageId: number) {
    await this.del(`/accounts/${accountId}/pages/${pageId}`);
  }

  async checkIfHostIsAvailable(host: string) {
    await this.post(`/pages/is-host-available`, {
      host,
    });
  }

  async reorderQuestionnairePages(
    accountId: number,
    pageIds: number[]
  ): Promise<void> {
    return this.put(`/accounts/${accountId}/pages/questionnaires/reorder`, {
      ids: pageIds,
    });
  }

  async duplicateQuestionnairePage(
    accountId: number,
    pageId: number
  ): Promise<TPageData> {
    return this.post(`/accounts/${accountId}/pages/${pageId}/duplicate`);
  }
}
