import React from "react";
import { Alert as ChakraAlert } from "@chakra-ui/react";
import { AlertProps } from "@chakra-ui/react";
import { AlertStatusEnum } from "enums/alert-status.enum";

const statuses = {
  [AlertStatusEnum.INFO]: { color: "blue" },
  [AlertStatusEnum.WARNING]: { color: "orange" },
  [AlertStatusEnum.SUCCESS]: { color: "green" },
  [AlertStatusEnum.ERROR]: { color: "red" },
};

export interface IAlertProps extends AlertProps {
  status?: AlertStatusEnum;
}

export const Alert: React.FC<IAlertProps> = ({ status, children, ...rest }) => {
  const color = status ? statuses[status].color : "gray";

  return (
    <ChakraAlert
      status={status}
      cursor={"default"}
      bg={`${color}.100`}
      color={`${color}.800`}
      border="1px solid"
      borderColor={`${color}.200`}
      {...rest}
    >
      {children}
    </ChakraAlert>
  );
};
