import { observer } from "mobx-react";
import { AccountDevelopersApiKeysStore } from "store/UserAccounts/UserAccount/AccountDevelopers/AccountDevelopersApiKeys/AccountDevelopersApiKeys.store";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { Box, Button, useToast, VStack } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { ApiMessageStack, InternalLink, Message, TableV2 } from "components";
import { TAccountDevelopersApiKeyConfig } from "types/account-developers.type";
import { ConfirmActionPrompt } from "routes/dashboard/components/prompts/ConfirmActionPrompt";
import {
  GUIDES_ROUTE,
  SETTINGS_DEVELOPERS_API_KEYS_ROUTE,
} from "constants/routes";
import { developersConfigApiKeysTableColumnDef } from "./developersConfigApiKeysTableColumnDef";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { DevelopersConfigApiKeyFormPrompt } from "routes/dashboard/components/prompts/DevelopersConfigPrompt/DevelopersConfigApiKeyFormPrompt";

interface IProps {
  accountDevelopersApiKeysStore: AccountDevelopersApiKeysStore;
}
export const DevelopersConfigApiKeys: FC<IProps> = observer(
  ({ accountDevelopersApiKeysStore }) => {
    const [loadingStatus, setLoadingStatus] = useState<ApiRequestStatusEnum>(
      ApiRequestStatusEnum.NONE
    );
    const { setModal, unSetModal } = useActionPrompt();
    const toast = useToast();
    const history = useHistory();

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await accountDevelopersApiKeysStore.fetchAllApiKeyConfigs();
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (error) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [accountDevelopersApiKeysStore, toast]);

    const onDeleteApiKeyConfig = useCallback(
      (apiConfigId: number) => async () => {
        try {
          await accountDevelopersApiKeysStore.deleteApiKeyConfig(apiConfigId);
          toast({
            ...DEFAULT_SUCCESS_TOAST_OPTIONS,
            description: (
              <ApiMessageStack messageStack={"API key successfully removed."} />
            ),
          });
        } catch (err) {
          toast({
            ...DEFAULT_ERROR_TOAST_OPTIONS,
            description: <ApiMessageStack messageStack={err.message} />,
          });
        }
      },
      [accountDevelopersApiKeysStore, toast]
    );

    const handleCreateApiKeyConfigFormPrompt = useCallback(() => {
      setModal(
        <DevelopersConfigApiKeyFormPrompt
          accountDevelopersApiKeysStore={accountDevelopersApiKeysStore}
          closePrompt={unSetModal}
        />
      );
    }, [accountDevelopersApiKeysStore, setModal, unSetModal]);

    const handleDeleteApiKeyConfigPrompt = useCallback(
      (apiConfigData: TAccountDevelopersApiKeyConfig) => () => {
        setModal(
          <ConfirmActionPrompt
            text={`Please confirm that you want to remove ${apiConfigData.name} API key.`}
            onConfirm={onDeleteApiKeyConfig(apiConfigData.id)}
            closePrompt={unSetModal}
          />
        );
      },
      [onDeleteApiKeyConfig, setModal, unSetModal]
    );

    const handleEditApiKeyConfig = useCallback(
      (apiConfigData: TAccountDevelopersApiKeyConfig) => () => {
        history.push(
          `${SETTINGS_DEVELOPERS_API_KEYS_ROUTE}/${apiConfigData.id}`
        );
      },
      [history]
    );

    const handleOnRowClick = useCallback(
      (apiConfigData: TAccountDevelopersApiKeyConfig) =>
        handleEditApiKeyConfig(apiConfigData)(),
      [handleEditApiKeyConfig]
    );

    const columnDef = useMemo(() => {
      return developersConfigApiKeysTableColumnDef(
        handleDeleteApiKeyConfigPrompt,
        handleEditApiKeyConfig
      );
    }, [handleDeleteApiKeyConfigPrompt, handleEditApiKeyConfig]);

    return (
      <VStack spacing={10}>
        <Message status={AlertStatusEnum.INFO}>
          <VStack spacing={2} align={"flex-start"}>
            <Box>
              You can retrieve or create leads via our designated endpoint.
            </Box>
            <InternalLink href={`${GUIDES_ROUTE}/leadpro-api-v103`} pl={1}>
              Read the guide.
            </InternalLink>
          </VStack>
        </Message>
        <TableV2<TAccountDevelopersApiKeyConfig>
          dataSource={accountDevelopersApiKeysStore.apiKeyConfigDataArray}
          globalFilterInputPlaceholder="Search API key"
          columns={columnDef}
          pageSize={20}
          loadingStatus={loadingStatus}
          striped={true}
          onRowClick={handleOnRowClick}
          additionalActions={
            <Box flexGrow={1}>
              <Button
                variant={"link"}
                colorScheme={"blue"}
                onClick={handleCreateApiKeyConfigFormPrompt}
              >
                + Create API key
              </Button>
            </Box>
          }
        />
      </VStack>
    );
  }
);
