import * as Yup from "yup";
import {
  FIRST_TIME_MISSING,
  GREATER_TIME_FIELD,
} from "constants/validator-messages";
import moment from "moment";
import { Days, TOfficeWorkingHours } from "types/account-office.type";

export const DAYS_OF_WEEK: Array<{ id: Days; label: string }> = [
  {
    id: "2",
    label: "Monday",
  },
  {
    id: "3",
    label: "Tuesday",
  },
  {
    id: "4",
    label: "Wednesday",
  },
  {
    id: "5",
    label: "Thursday",
  },
  {
    id: "6",
    label: "Friday",
  },
  {
    id: "7",
    label: "Saturday",
  },
  {
    id: "1",
    label: "Sunday",
  },
];

export type TWorkingHoursFieldValues = {
  [key in Days]?: { from: string; to: string };
};

export const getFormValuesFromData = (workingHours?: TOfficeWorkingHours) => {
  const initialValues: TWorkingHoursFieldValues = {};
  const weekDays = workingHours?.weekDays;

  DAYS_OF_WEEK.forEach(day => {
    initialValues[day.id] = {
      from: weekDays?.[day.id]?.periods?.[0].open || "",
      to: weekDays?.[day.id]?.periods?.[0].closed || "",
    };
  });

  return initialValues;
};

export const getDataFromFormValues = (formData: TWorkingHoursFieldValues) => {
  const workingHours: TOfficeWorkingHours = {
    weekDays: {},
  };

  DAYS_OF_WEEK.forEach(day => {
    if (formData[day.id]?.from && formData[day.id]?.to) {
      workingHours.weekDays[day.id] = {
        periods: [
          { open: formData[day.id]?.from!, closed: formData[day.id]?.to! },
        ],
      };
    }
  });

  return workingHours;
};

const yupDayValidationObject = Yup.object({
  from: Yup.string().when("to", {
    is: (to: string) => !!to,
    then: Yup.string().required(FIRST_TIME_MISSING),
  }),
  to: Yup.string().test("is-greater", GREATER_TIME_FIELD, function(value) {
    const { from } = this.parent;
    if (!from) return true;
    return moment(value, "HH:mm").isAfter(moment(from, "HH:mm"));
  }),
});

export const workingHoursValidationSchema = Yup.object().shape({
  "2": yupDayValidationObject,
  "3": yupDayValidationObject,
  "4": yupDayValidationObject,
  "5": yupDayValidationObject,
  "6": yupDayValidationObject,
  "7": yupDayValidationObject,
  "1": yupDayValidationObject,
});
