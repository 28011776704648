import { observer } from "mobx-react";
import React, { FC, useCallback, useMemo } from "react";
import { SingleSelectInput } from "components";
import { Box } from "@chakra-ui/react";
import { LEAD_TYPE_OPTIONS } from "constants/lead-type-options";
import { isActionParamFieldInError } from "utils/form-tool-page.utils";
import { FormToolPageSettingsCreateLeadActionQuestionsMapper } from "./FormToolPageSettingsCreateLeadActionQuestionsMapper";
import { FormActionCreateLead, LeadTypes } from "@leadpro/forms";
import { FormToolPageWIPFormStore } from "../../../../../FormToolPageWIPForm.store";
import { FormToolPageSettingsActionConfiguratorItem } from "../../../../../../../../../components/FormTool/FormToolPageSettingsSelectedActionConfigurator/FormToolPageSettingsActionConfiguratorItem";

interface IProps {
  actionIndex: number;
  formToolPageWIPFormStore: FormToolPageWIPFormStore;
}

export const FormToolPageSettingsCreateLeadActionParamsConfigurator: FC<IProps> = observer(
  ({ actionIndex, formToolPageWIPFormStore }) => {
    const action = formToolPageWIPFormStore.wipFormActionsJS[
      actionIndex
    ] as FormActionCreateLead;

    const onValuedPropertyChange = useCallback(
      (property: keyof FormActionCreateLead["params"]) => (
        value: string | null
      ) => {
        if (!value) return;

        formToolPageWIPFormStore.updateActionParams(actionIndex, {
          [property]: { value },
        });
      },
      [actionIndex, formToolPageWIPFormStore]
    );

    const errors = useMemo((): {
      [key in keyof Pick<FormActionCreateLead["params"], "leadType">]: boolean;
    } => {
      const validationResult =
        formToolPageWIPFormStore.wipFormActionsValidationResult;

      return {
        leadType: isActionParamFieldInError(
          "leadType",
          actionIndex,
          validationResult
        ),
      };
    }, [formToolPageWIPFormStore.wipFormActionsValidationResult, actionIndex]);

    return (
      <>
        <FormToolPageSettingsActionConfiguratorItem
          label={"Lead type"}
          showError={errors.leadType}
          input={
            <Box width={"200px"}>
              <SingleSelectInput<LeadTypes>
                value={action.params.leadType.value}
                onChange={onValuedPropertyChange("leadType")}
                options={LEAD_TYPE_OPTIONS}
                placeholder={"select lead type"}
              />
            </Box>
          }
        />
        <FormToolPageSettingsCreateLeadActionQuestionsMapper
          actionIndex={actionIndex}
          formToolPageWIPFormStore={formToolPageWIPFormStore}
        />
      </>
    );
  }
);
