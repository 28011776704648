import Script from "react-load-script";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useCallback } from "react";
import { useAppStore } from "utils/react-hooks/useAppStore.hook";
import { observer } from "mobx-react";
import { NavButton } from "components/nav-button/NavButton";
const env = runtimeEnv();

const SDK_URL = env.REACT_APP_NOLT_SDK_URL;
const BOARD_URL = env.REACT_APP_NOLT_BOARD_URL;
const NOLT_BUTTON_ELEMENT_ID = "nolt-button";

export const NoltWidget = observer(() => {
  const {
    authStore: { authToken },
  } = useAppStore();
  const handleScriptCreate = useCallback(() => {
    console.log("script created");
  }, []);

  const handleScriptError = useCallback(() => {
    console.log("script error");
  }, []);

  const handleScriptLoad = useCallback(() => {
    try {
      const { nolt } = window as any;
      const noltBtnElement = document.getElementById(NOLT_BUTTON_ELEMENT_ID);
      if (!!noltBtnElement) {
        nolt?.("init", {
          selector: "#" + NOLT_BUTTON_ELEMENT_ID,
          url: BOARD_URL,
        });
        // Single Sign-On
        nolt?.("identify", { jwt: authToken });
      }
    } catch (e) {}
  }, [authToken]);

  return (
    <>
      <NavButton id={NOLT_BUTTON_ELEMENT_ID}>Suggestions</NavButton>

      <Script
        attributes={{
          defer: true,
        }}
        url={SDK_URL}
        onCreate={handleScriptCreate}
        onError={handleScriptError}
        onLoad={handleScriptLoad}
      />
    </>
  );
});
