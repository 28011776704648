import {
  Expression,
  FormActions,
  FormActionType,
  FormFieldType,
  FormSchema,
  Group,
  validateFormActions,
  validateFormSchema,
} from "@leadpro/forms";
import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import {
  generateFormActionStub,
  generateFormToolPageDefaultFormSchema,
} from "utils/form-tool-page.utils";
import { TSelectOption } from "types/select-input.type";
import {
  DefaultFormToolPageQuestionTemplates,
  FormToolPageActionTypeLabels,
} from "constants/form-tool-page";
import { remove } from "lodash";

export class FormToolPageWIPFormStore {
  private wipFormSchema: FormSchema;
  private wipFormActions: IObservableArray<FormActions[number]>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.wipFormSchema = generateFormToolPageDefaultFormSchema();
    this.wipFormActions = observable.array();
  }

  get wipFormSchemaJS() {
    return toJS(this.wipFormSchema);
  }

  get wipFormActionsJS() {
    return toJS(this.wipFormActions);
  }

  get wipFormSchemaValidationResult() {
    return validateFormSchema(this.wipFormSchemaJS);
  }

  get wipFormActionsValidationResult() {
    return validateFormActions(this.wipFormSchemaJS, this.wipFormActionsJS);
  }

  get isWIPFormSchemaValid() {
    return this.wipFormSchemaValidationResult.isValid;
  }

  get isWIPFormActionsValid() {
    return this.wipFormActionsValidationResult.isValid;
  }

  // OPTIONS
  get allQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];

    this.wipFormSchema.order.forEach((qestionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[qestionKey];

      options.push({
        label: `Question #${questionIndex + 1}: ${question.label}`,
        value: qestionKey,
      });
    });

    return options;
  }

  get textQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((questionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[questionKey];

      if (question?.required && question?.type === FormFieldType.TEXT) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: questionKey,
        });
      }
    });

    return options;
  }

  get textAreaQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((questionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[questionKey];

      if (question?.required && question?.type === FormFieldType.TEXT_AREA) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: questionKey,
        });
      }
    });

    return options;
  }

  get emailQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((qestionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[qestionKey];

      if (question?.required && question?.type === FormFieldType.EMAIL) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: qestionKey,
        });
      }
    });

    return options;
  }

  get phoneQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((qestionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[qestionKey];

      if (question?.required && question?.type === FormFieldType.PHONE) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: qestionKey,
        });
      }
    });

    return options;
  }

  get postcodeQuestionsAsOptions() {
    const options: TSelectOption<string>[] = [];
    this.wipFormSchema.order.forEach((qestionKey, questionIndex) => {
      const question = this.wipFormSchema.fields[qestionKey];

      if (question?.required && question?.type === FormFieldType.POSTCODE) {
        options.push({
          label: `Question #${questionIndex + 1}: ${question.label}`,
          value: qestionKey,
        });
      }
    });

    return options;
  }

  // QUESTIONS
  public addQuestion(type: FormFieldType, position: number) {
    const questionKey = `q_${new Date().getTime()}`;
    this.wipFormSchema.fields[questionKey] = {
      ...DefaultFormToolPageQuestionTemplates[type],
    };
    this.wipFormSchema.order.splice(position, 0, questionKey);
  }

  public swapQuestionsOrder(index1: number, index2: number) {
    this.wipFormSchema.order[index1] = this.wipFormSchema.order.splice(
      index2,
      1,
      this.wipFormSchema.order[index1]
    )[0];
  }

  public removeQuestion(questionKey: string) {
    const newSchema = this.wipFormSchemaJS;
    delete newSchema.fields[questionKey];
    remove(newSchema.order, value => value === questionKey);

    this.setWIPFormSchema(newSchema);
  }

  public updateQuestion(
    questionKey: string,
    values: FormSchema["fields"]["key"]
  ) {
    this.wipFormSchema.fields[questionKey] = {
      ...this.wipFormSchema.fields[questionKey],
      ...values,
    };
  }

  // ACTIONS
  public addAction(actionType?: FormActionType) {
    this.wipFormActions.push(generateFormActionStub(actionType));
  }

  public setActionCondition(
    actionIndex: number,
    groupOrExpression?: Group | Expression
  ) {
    const action = this.wipFormActions[actionIndex];
    if (!action) return;

    action.condition = groupOrExpression;
  }

  public setActionParams<T extends FormActions[number]>(
    actionIndex: number,
    actionParams: T["params"]
  ) {
    const action = this.wipFormActions[actionIndex] as T;
    if (!action) return;

    action.params = actionParams;
  }

  public updateActionParams<T extends FormActions[number]>(
    actionIndex: number,
    actionParams: Partial<T["params"]>
  ) {
    const action = this.wipFormActions[actionIndex] as T;
    if (!action) return;

    action.params = {
      ...action.params,
      ...actionParams,
    };
  }

  public removeAction(actionIndex: number) {
    if (!this.wipFormActions[actionIndex]) return;
    this.wipFormActions.splice(actionIndex, 1);
  }

  public setWIPFormSchema(schema: FormSchema | null) {
    this.wipFormSchema = schema || generateFormToolPageDefaultFormSchema();
  }

  public setWipFormActions(actions: FormActions) {
    this.wipFormActions.replace(actions);
  }

  public getActionLabelForAction(actionIndex: number) {
    const action = this.wipFormActionsJS[actionIndex];

    return FormToolPageActionTypeLabels[action.type];
  }
}
