import React, { useMemo } from "react";
import { Box, Avatar } from "@chakra-ui/react";
import { TPortalData } from "types/portal.type";
import moment from "moment";
import Styles from "components/avatar/Avatar.module.scss";
import { IPortalLinkStatus } from "types/account-portal-link-status-per-office.type";
import { TNormalizedLeadSourceData } from "types/lead-source.type";
import { DEFAULT_PORTAL_IMAGE } from "constants/image-paths";

interface IPortalLinkStatusTooltipProps {
  typeActivities: IPortalLinkStatus[];
  portals: Record<number, TPortalData>;
  leadSources: Record<number, TNormalizedLeadSourceData>;
}
export const PortalLinkStatusTooltip: React.FC<IPortalLinkStatusTooltipProps> = ({
  typeActivities,
  portals,
  leadSources,
}) => {
  const statuses = useMemo(() => {
    return typeActivities.map(activity => {
      const leadSource = leadSources[activity.sourceId];
      const portal = portals[leadSource?.portalId || -1];

      return {
        activity,
        label: leadSource?.name,
        logoUrl: portal?.logoUrl || DEFAULT_PORTAL_IMAGE,
      };
    });
  }, [typeActivities, portals, leadSources]);

  return (
    <Box>
      {statuses.map(status => (
        <Box
          key={status.activity.sourceId}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"nowrap"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          width={"295px"}
          mb={2}
          _last={{
            mb: 0,
          }}
        >
          <Avatar
            name={status.label}
            src={status.logoUrl}
            size={"sm"}
            rounded={"sm"}
            className={Styles.squareAvatar}
          />
          <Box display={"flex"} flexDirection={"column"} ml={2} flexGrow={1}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"nowrap"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box fontWeight={500}>{status.label}</Box>
              <Box
                width="10px"
                height="10px"
                bg={`${!!status.activity.lastReceived ? "green" : "red"}.500`}
                rounded="full"
              />
            </Box>
            <Box fontSize={"xs"} color={"gray.400"}>
              {!status.activity.lastReceived && "No leads received"}
              {!!status.activity.lastReceived &&
                `Last lead received ${moment(
                  status.activity.lastReceived
                ).fromNow()}`}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
