import { Box } from "@chakra-ui/react";
import { FormControlV2 } from "components";
import { ReapitRelatedContactsRenderer } from "components/select-input/custom-option-renderers/ReapitRelatedContactsRenderer";
import ReapitRelatedContactsOption from "components/select-input/custom-options/ReapitRelatedContactsOption";
import { IGetOptionPropsData } from "components/select-input/SelectInputOptionsController";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { FC, useMemo } from "react";
import { Control } from "react-hook-form";
import { TReapitContactData } from "types/reapit.type";
import { TSelectOption } from "types/select-input.type";
import { TReapitSyncFormFieldValues as TFieldValues } from "../reapit-sync-form-field.type";

interface IProps {
  control: Control<TFieldValues>;
  reapitRelatedContactsOptions: TSelectOption<
    string,
    {
      email: string;
      phone: string;
      matchedWith: string;
      name: string;
    }
  >[];
}

export const ExistingRecordsForm: FC<IProps> = ({
  control,
  reapitRelatedContactsOptions,
}) => {
  const getReapitRelatedContactDescription = useMemo(() => {
    const optionsLength = reapitRelatedContactsOptions.length;
    if (optionsLength < 2) {
      return (
        <span>
          <strong>No existing records</strong> have been identified. Sync'ing
          this lead will create a new Reapit contact record.
        </span>
      );
    }
    if (optionsLength === 2) {
      return (
        <span>
          <strong>1 other record</strong> with similar or identical contact
          details has been identified in your Reapit system. You can choose to
          update an existing record or create a new one.
        </span>
      );
    }
    return (
      <span>
        <strong>{optionsLength - 1} other records</strong> with similar or
        identical contact details have been identified in your Reapit system.
        You can choose to update an existing record or create a new one.
      </span>
    );
  }, [reapitRelatedContactsOptions]);

  return (
    <>
      <Box
        width={"full"}
        py={2}
        px={5}
        mt={2}
        mb={2}
        background={"leadpro.50"}
        fontSize={"sm"}
      >
        Existing Records
      </Box>
      <Box mb={5} px={4}>
        <FormControlV2<TFieldValues>
          name={"contactId"}
          control={control}
          type={FormControlsTypeEnum.SINGLE_SELECT}
          description={<Box mb={2}>{getReapitRelatedContactDescription}</Box>}
          additionalProps={{
            options: reapitRelatedContactsOptions,
            optionComponent: (
              optionProp: IGetOptionPropsData<string, TReapitContactData>
            ) => <ReapitRelatedContactsOption optionProp={optionProp} />,
            optionsRenderer: (
              optionProps: IGetOptionPropsData<string, TReapitContactData>[]
            ) => <ReapitRelatedContactsRenderer optionProps={optionProps} />,
          }}
        />
      </Box>
    </>
  );
};
