import { Flex, Box } from "@chakra-ui/react";
import { achievementsConfig } from "./achievements-config";
import React, { FC } from "react";
import { isAchieved } from "./is-achieved";
import { AchievementBadge } from "./AchievementBadge";

interface IProps {
  noOfProcessedLeads: number;
}

export const AchievementsProgress: FC<IProps> = ({ noOfProcessedLeads }) => {
  return (
    <Flex justifyContent={"center"} mb={8}>
      {achievementsConfig.map(({ threshold, badge }, index) => (
        <Box key={index}>
          <AchievementBadge
            threshold={threshold}
            image={badge}
            completed={isAchieved(threshold, noOfProcessedLeads)}
          />
        </Box>
      ))}
    </Flex>
  );
};
