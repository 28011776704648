import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { LEAD_FILTER_ICON_SIZE } from "constants/lead-filter-icon-size";
import { Box, HStack } from "@chakra-ui/react";
import { LeadResponderEmailFilterSimpleMenu } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenu";
import { LeadResponderEmailSimpleFilterTypes } from "../LeadResponderEmailFilterMenu/LeadResponderEmailFilterSimpleMenuItem";
import { AcccountLeadResponderEmailsFiltersStore } from "store/UserAccounts/UserAccount/AccountLeadResponder/AccountLeadResponderEmailsFilters.store";
import { TAccountOfficeData } from "types/account-office.type";

interface IProps {
  emailFiltersStore: AcccountLeadResponderEmailsFiltersStore;
  accountOfficesDataMap: Record<number, TAccountOfficeData>;
}

export const FiltersLeadResponderEmailsOfficeHeader: FC<IProps> = observer(
  ({ emailFiltersStore, accountOfficesDataMap }) => {
    const {
      pendingFilter,
      togglePendingFilterByType,
      activeFilter,
    } = emailFiltersStore;

    const accountOfficeOptions = useMemo(() => {
      return Object.values(accountOfficesDataMap).map(office => {
        return {
          value: office.id,
          label: <Box>{office.name}</Box>,
        };
      });
    }, [accountOfficesDataMap]);

    return (
      <>
        <HStack spacing={2}>
          <Box
            fontSize={"sm"}
            color={"gray.500"}
            fontWeight={500}
            py={2}
            w={"100%"}
          >
            Office
          </Box>
        </HStack>
        <LeadResponderEmailFilterSimpleMenu
          optionLabel={"Office"}
          icon={
            <FontAwesomeIcon icon={faFilter} fontSize={LEAD_FILTER_ICON_SIZE} />
          }
          availableOptions={accountOfficeOptions}
          selectedOptions={pendingFilter.offices}
          appliedOptions={activeFilter?.offices}
          handleFilterItemToggle={(value: number) =>
            togglePendingFilterByType(
              value,
              LeadResponderEmailSimpleFilterTypes.OFFICES
            )
          }
          filterStore={emailFiltersStore}
        />
      </>
    );
  }
);
